import { Box, Popover, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCurrency } from "../services/api";
import CurrencyToggle from "./CurrencyToggle";
import LanguageToogle from "./LanguageToggle";

export default function SettingNavbar(props) {
    const [translate,] = useTranslation("global");
    const [datacurrency, setDatacurrency] = useState([]);

    useEffect(() => {
        getDataCurrency()


    }, [])

    async function getDataCurrency() {
        try {
            const res = await getCurrency()
            setDatacurrency(res?.data.data.attributes.usd_idr);
        } catch (e) {
            console.log(e);
        }
    }
    return (
        <Popover
            PaperProps={{
                style: {
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    borderRadius: 0
                }
            }}
            sx={{ mt: '32px', zIndex: 99999, '& .MuiBackdrop-root': { backgroundColor: 'rgba(0, 0, 0, 0.2)' } }}
            id="menu-appbar"

            anchorEl={props.anchorElSetting}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={Boolean(props.open)}
            onClose={props.onClose}
        >

            <Box sx={{ borderRadius: '8px', p: '16px', background: 'white', }}>
                <Typography className="w-500" sx={{ color: '#56617B', fontSize: 12, mb: '6px' }}>
                    {translate('status.bahasa')}
                </Typography>
                <LanguageToogle></LanguageToogle>
                <Typography className="w-500" sx={{ color: '#56617B', fontSize: 12, mb: '6px', mt: '6px' }}>
                    {translate('status.matauang')}
                </Typography>
                <CurrencyToggle></CurrencyToggle>
                <Typography className="w-500" sx={{ color: '#56617B', fontSize: 12, mt: 1 }}>
                    1 USD = Rp          {(datacurrency).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                </Typography>
            </Box>
        </Popover>
    )
}
