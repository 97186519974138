import { IconButton, InputAdornment } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Button, CircularProgress, CssBaseline, Grid, Link, MenuItem, Modal, Select, Stack, TextField, Typography } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { styled } from '@mui/system';
import "firebase/analytics";
import firebase from "firebase/app";
import "firebase/auth";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactInputVerificationCode from 'react-input-verification-code';
import Swal from 'sweetalert2';
import visibilityOffIcon from "../assets/eye-icon-close.svg";
import visibilityIcon from "../assets/eye-icon.svg";
import Facebookicon from "../assets/facebook-ic-color.png";
import Googleicon from "../assets/google-ic-color.png";
import heroLoginImg from "../assets/hero-login.webp";
import LogoIndomine from "../assets/logo-color.png";
import verifikasiIcon from "../assets/verifikasi-ic.png";
import { confirmOTP, getCity, getProvince, loginFromFirebase, sendEmailOTP, signUp, signUpFromFirebase } from "../services/api";
import "../services/firebase";
import { validationEmail, validationNumber } from '../services/primary';

const theme = createTheme()

export default function Signup() {
  const [showPassword, setShowPassword] = useState(false);
  const [province, setProvince] = useState([])
  const [city, setCity] = useState([])
  const [name, setName] = useState("")
  const [cityId, setCityId] = useState("")
  const [postCode, setPostCode] = useState("")
  const [provinceId, setProvinceId] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [email, setEmail] = useState("")
  const [address, setAddress] = useState("")
  const [password, setPassword] = useState("")
  const [otp, setOtp] = useState("")
  const [isLoadingRegister, setIsLoadingRegister] = useState(false)
  const [isLoadingVerification, setIsLoadingVerification] = useState(false)
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [translate,] = useTranslation("global");

  const [isvalidationEmail, setvalidationEmail] = useState(false)

  useEffect(() => {
    getDataProvince()
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault()
    let validatePhone = validationNumber(phoneNumber)
    if (!validatePhone) {

      Swal.fire({
        icon: 'error',
        title: 'Registration Failed',
        text: `Phone Number must in Number`,
      })
      return
    }
    setIsLoadingRegister(true)
    const dataSignup = {
      name: name,
      phone_number: phoneNumber,
      username: email,
      alamat: address,
      provinsi: provinceId,
      city: cityId,
      kode_pos: postCode,
      email: email,
      password: password,
    }
    const response = await signUp(dataSignup)
    setIsLoadingRegister(false)
    if (response.status === 200) {

      setOpen(true);
    } else {
      let message = typeof (response.message) === 'object' || response.message === undefined ? "" : response?.message
      Swal.fire({
        icon: 'error',
        title: 'Registration Failed',
        text: `${message}`,
      })
    }
  }

  const VerticalDivider = styled('div')({
    width: '1px',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    height: '100%',
    position: 'absolute',
    //left: 'calc(20% + 2px)',
    left: '46px',
    top: '0',
    bottom: '0',
    zIndex: '1',
  });

  const submitOTP = async (event) => {
    event.preventDefault()
    setIsLoadingVerification(true)
    const response = await confirmOTP({ otp: otp, email: email });
    setIsLoadingVerification(false)
    if (response.status === 200) {
      setOpen(false);
      Swal.fire({
        icon: 'success',
        title: 'Registration Success',
        text: 'Account has been active, please login',
      }).then(() => {
        window.location.href = "/signin"
      })
    } else {
      setOpen(false);
      let message = typeof (response.message) === 'object' || response.message === undefined ? "" : response?.message

      Swal.fire({
        icon: 'error',
        title: 'OTP Failed',
        text: `${message}`,
      })

    }
  }

  const sendOTP = async (event) => {
    event.preventDefault()
    setIsLoadingVerification(true)
    await sendEmailOTP({ email: email });
    setIsLoadingVerification(false)
  }


  const handleChangeNoHp = (event) => {
    const newValue = event.replace(/[^0-9]/g, "");
    // Cek apakah karakter pertama adalah angka nol
    if (newValue.length === 0 || (newValue.length === 1 && newValue === '0')) {
      setPhoneNumber('');
    } else {
      setPhoneNumber(newValue);
    }
  };

  const registerWithFirebase = async (e) => {
    const responAPI = await signUpFromFirebase(e)
    if (responAPI !== undefined && responAPI.status === 200) {
      loginWithTokenFirebase(e)
    } else if (responAPI.message !== 'Internal Server Error' && responAPI.status === 500) {
      loginWithTokenFirebase(e)
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: "Can't Login",
      })
    }
  }

  const loginWithTokenFirebase = async (e) => {
    const responAPI = await loginFromFirebase(e)

    if (responAPI.status === 200) {

      localStorage.setItem("token", responAPI.jwt)
      localStorage.setItem("profilePicture", responAPI.data?.profile_picture?.url ?? 'null')
      localStorage.setItem("id_user", responAPI.data?.id ?? 'null')
      window.location.href = "/"
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Failed Login',
        text: `${responAPI?.message}`,
      })

    }
  }

  const registerWithGoogle = async () => {
    firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider()).then((result) => {
      let token = result.user.Aa
      registerWithFirebase({ token_firebase: token })
    }).catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: `${error.message}`,
      })
    })
  }

  const registerWithFacebook = async () => {
    firebase.auth().signInWithPopup(new firebase.auth.FacebookAuthProvider()).then((result) => {
      let token = result.user.Aa

      registerWithFirebase({ token_firebase: token })
    }).catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: `${error.message}`,
      })
    })
  }

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };


  async function getDataProvince() {
    const response = await getProvince();
    setProvince(response.data.data)
  }

  async function getDataCity(e) {
    const response = await getCity(e);
    setCity(response.data.data)
  }


  const handleProvince = (e) => {
    setProvinceId(e)
    getDataCity(e)
  }

  const handleState = (e, y) => {
    const list_field = ["name", "phoneNumber", "email", "password", "address", "provinceId", "cityId", "postCode"]
    const list_setFields = [setName, setPhoneNumber, setEmail, setPassword, setAddress, setProvinceId, setCityId, setPostCode]
    const check_index = list_field.indexOf(y)
    if (y === "provinceId") {
      handleProvince(e)
    } else if (y === "phoneNumber") {
      handleChangeNoHp(e)
    } else {
      list_setFields[check_index](e)
    }
  }

  return (
    <ThemeProvider theme={theme} >
      <Grid container component="main" >
        <CssBaseline />
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '320px', sm: '446px', md: '446px', lg: '446px' },
            // height: { xs: '452px', sm: '550px', md: '550px', lg: '550px' },
            bgcolor: 'background.paper',
            borderRadius: '8px',
            boxShadow: 24,
            p: { xs: '16px', sm: '40px', md: '40px', lg: '40px' },
          }}>
            <Box sx={{ textAlign: 'center' }}>
              <Box component="img" src={verifikasiIcon} sx={{ width: { xs: '100px', sm: '120px', lg: '120px' }, height: { xs: '100px', sm: '120px', lg: '120px' }, }}>

              </Box>
            </Box>


            <Typography className="w-700" sx={{ marginTop: '24px', textAlign: 'center', marginBottom: '8px', lineHeight: 'normal', fontSize: { xs: 18, sm: 18, md: 24, lg: 24 } }} > {translate('login-register.verifikasi-email')}</Typography>
            <p className="w-500" style={{ textAlign: 'center', color: '#56617B', fontSize: 14, lineHeight: '26px' }} id="child-modal-description">
              {translate('login-register.label-verifikasi1')} <br></br> <b className="w-700" style={{ color: '#081130', fontSize: '14px', lineHeight: '26px' }}>{email} </b>  {translate('login-register.label-verifikasi2')}
            </p>
            <Stack direction={'row'} spacing={1} className="signup" marginTop={1}>
              <ReactInputVerificationCode placeholder={""} onChange={(e) => setOtp(e)} length={6} />
            </Stack>
            <p className="w-500" style={{ fontSize: 16, lineHeight: '22px', textAlign: 'center', color: '#56617B', marginTop: '24px' }} id="child-modal-description">
              {translate('login-register.resend-code')} <b className="w-700" onClick={sendOTP} style={{ color: '#2457B9', fontSize: 16, lineHeight: 'normal', cursor: 'pointer' }}> {translate('login-register.btn-resend')}</b>
            </p>
            <Stack sx={{ mt: { xs: '32px', sm: '32px', md: '52px', lg: '52px', xl: '52px' } }} direction={'row'} justifyContent={'center'} >
              <Button
                className="w-500"
                fullWidth
                onClick={handleClose}
                variant="outlined"
                sx={{
                  mr: '16px',
                  width: '160px',
                  height: '48px',
                  fontSize: 16,
                  lineHeight: '22px',
                  textTransform: "none", border: '1px solid var(--primarry-blue, #2457B9)',
                  p: { xs: '10px 12px', lg: '12px 16px', md: '12px 16px' },
                  borderRadius: '8px',
                  color: '#2457B9'
                }}
              >
                {translate('jual-beli.btn-cancel')}
              </Button>
              <Button
                className="w-500"
                type="submit"
                fullWidth
                variant="contained"
                onClick={(e) => submitOTP(e)}
                sx={{
                  p: { xs: '10px 12px', lg: '12px 16px', md: '12px 16px' },
                  width: '160px',
                  height: '48px',
                  fontSize: 16, lineHeight: '22px', textTransform: 'none',
                  background: ' linear-gradient(270deg, #2457B9 15%, #2860CD 100%)', borderRadius: '8px', boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.12), 0px -1px 0px 0px rgba(0, 0, 0, 0.20) inset',
                }}
              >
                {isLoadingVerification ? <CircularProgress
                  sx={{
                    color: (theme) =>
                      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                  }}
                  size={20}
                  thickness={4}
                  value={100}
                /> : 'OK'}
              </Button>
            </Stack>
          </Box>
        </Modal>
        <Grid item xs={12} sm={12} md={6} lg={6} elevation={6} square>
          <Box
            sx={{
              mt: { xs: '24px', sm: '24px', md: '52px', lg: '52px' },
              ml: { xs: '16px', sm: '32px', md: '60px', lg: '120px' },
              mr: { xs: '16px', sm: '32px', },
              display: "flex",
              flexDirection: "column",

            }}
          >
            <Grid container direction="row" sx={{ mb: { lg: '96px', xs: '52px', sm: '52px', md: '52px' }, }} alignItems="center" justifyContent={'space-between'}>
              <Stack direction={'row'} alignItems={'center'}>
                <Box
                  component="img"
                  sx={{

                    height: 28,
                  }}
                  alt="logo-img"
                  src={LogoIndomine}
                />
                <Typography className="w-700" sx={{ color: '#081130', fontSize: 24, ml: '10px' }}>
                  IndoMine
                </Typography>

              </Stack>
              <Hidden smDown>
                <Stack direction={'row'} justifyContent="end" alignItems={'center'}>
                  <Typography
                    className="w-500"
                    sx={{ fontSize: 14, color: '#56617B', mr: '8px' }}

                  >
                    {translate('login-register.btn-to-login')}

                  </Typography>
                  <Link href="/signin" className="w-700" sx={{ color: '#2457B9', fontSize: 14, textDecoration: 'none' }}>
                    {translate('login-register.btn-masuk')}
                  </Link>{" "}
                </Stack>
              </Hidden>
            </Grid>
            <Typography className="w-700" sx={{ color: '#081130', fontSize: 32, }}>
              {translate('login-register.register-akun')}
            </Typography>
            <Typography className="w-500" sx={{ color: '#56617B', fontSize: 16, mt: 1, mb: 1, }}>
              {translate('login-register.subtitle-register')}
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: '32px', maxWidth: { lg: '430px', sm: '100%', xs: '100%' } }}
            >
              <Typography className="w-500" sx={{ color: '#081130', fontSize: 14, }}>
                {translate('login-register.nama')}
              </Typography>
              <TextField
                className="box-input-email"
                sx={{
                  mt: '4px', input: {
                    fontSize: '14px', fontFamily: 'Satoshi-500'
                  },
                }}
                value={name}
                onChange={(e) => handleState(e.target.value, "name")}
                fullWidth
                id="email"
                placeholder={translate('login-register.placeholder-name')}
                name="email"
                autoComplete="email"

              />
              <Typography className="w-500" sx={{ color: '#081130', fontSize: 14, }}>
                {translate('login-register.phone')}
              </Typography>
              <TextField

                sx={{
                  mt: '4px', input: {
                    fontSize: '14px', fontFamily: 'Satoshi-500'
                  },
                }}
                className="box-input-email"
                value={phoneNumber}
                onChange={(e) => {
                  handleState(e.target.value, "phoneNumber")

                }}

                fullWidth
                id="phone"
                placeholder="xxxx-xxxx-xxxx"
                name="phone"
                autoComplete="phone"

                InputProps={{
                  startAdornment: <InputAdornment position="start"> <Typography className="w-700" sx={{ color: '#56617B', fontSize: 14, mr: '24px' }}>
                    +62
                  </Typography> <VerticalDivider sx={{ ml: '12px' }} /> </InputAdornment>
                }}
              />
              <Typography className="w-500" sx={{ color: '#081130', fontSize: 14, }}>
                Email
              </Typography>
              <TextField
                onChange={(e) => {
                  handleState(e.target.value, "email")
                  if (validationEmail(email ? email : "")) {
                    setvalidationEmail(true)
                  } else {
                    setvalidationEmail(false)
                  }
                }
                }
                error={isvalidationEmail}
                sx={{
                  mt: '4px',
                  input: {
                    fontSize: '14px', fontFamily: 'Satoshi-500'
                  },
                }}
                className="box-input-email"
                value={email}
                fullWidth
                id="email"
                placeholder={translate('login-register.placeholder-email')}
                name="email"
                autoComplete="email"

              />
              <Typography className="w-500" sx={{ color: '#081130', fontSize: 14, }}>
                Password
              </Typography>
              <TextField
                className="box-input-password"
                onChange={(e) => {
                  handleState(e.target.value, "password")
                }
                }
                // error={validationNull(password)}
                sx={{
                  mt: '4px', input: {
                    fontSize: '14px', fontFamily: 'Satoshi-500'
                  },
                }}
                value={password}
                fullWidth
                name="password"
                placeholder={translate('login-register.placeholder-password')}
                type={showPassword ? "text" : "password"} // <-- This is where the magic happens
                id="password"
                autoComplete="current-password"
                InputProps={{ // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        style={{ padding: 0 }}
                      >
                        {showPassword ? <img alt="eye-icon" style={{ width: '22px', height: '22px', paddingRight: 0 }} src={visibilityIcon}></img> : ''}
                        {!showPassword ? <img alt="eyeclose-icon" style={{ width: '20px', height: '20px', }} src={visibilityOffIcon}></img> : ''}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Typography className="w-500" sx={{ color: '#081130', fontSize: 14, }}>
                {translate('login-register.address')}
              </Typography>
              <TextField
                className="box-input-email"
                onChange={(e) => handleState(e.target.value, "address")}
                sx={{
                  mt: '4px', input: {
                    fontSize: '14px', fontFamily: 'Satoshi-500'
                  },
                }}
                value={address}
                fullWidth
                id="email"
                placeholder={translate('login-register.placeholder-address')}
                name="email"
                autoComplete="email"

              />

              <Grid container direction={{ xs: 'column', sm: 'row', md: 'row', lg: 'row', xl: 'row' }} spacing={2}>
                <Grid item xs>
                  <Typography className="w-500" sx={{ color: '#081130', fontSize: 14, }}>
                    {translate('login-register.provinsi')}
                  </Typography>

                  <Select fullWidth
                    IconComponent={() => (
                      <KeyboardArrowDownIcon sx={{ mr: '16px', width: '20px', height: '20px', fill: '#56617B' }} />
                    )}

                    style={{ border: '1px solid var(--grey-stroke, #e8ebf2)', marginTop: '4px', borderRadius: '8px', }}

                    value={provinceId}
                    onChange={(e) => handleState(e.target.value, "provinceId")}//onChange={handleChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value="">
                      <Typography className="w-400" style={{ color: '#8792AB', fontSize: 14 }}>Pilih Provinsi</Typography>
                    </MenuItem>
                    {province.map((option, i) => (

                      <MenuItem key={option.i} value={option.id}>
                        {option.attributes.name}
                      </MenuItem>
                    ))}
                  </Select>


                </Grid>
                <Grid item xs>
                  <Typography className="w-500" sx={{ color: '#081130', fontSize: 14, }}>
                    Kota
                  </Typography>

                  <Select fullWidth
                    style={{ border: '1px solid var(--grey-stroke, #e8ebf2)', marginTop: '4px', borderRadius: '8px', }}
                    IconComponent={() => (
                      <KeyboardArrowDownIcon sx={{ mr: '16px', width: '20px', height: '20px', fill: '#56617B' }} />
                    )}
                    value={cityId}
                    sx={{ mt: '4px' }}
                    onChange={(e) => handleState(e.target.value, "cityId")}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value="">
                      <Typography className="w-400" style={{ color: '#8792AB', fontSize: 14 }}>Pilih Kota</Typography>
                    </MenuItem>
                    {city.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.attributes.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
              <Grid container direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' }} spacing={2}>
                <Grid item xs sx={{ mt: '24px', }}>
                  <Typography className="w-500" sx={{ color: '#081130', fontSize: 14 }}>
                    {translate('login-register.kodepos')}
                  </Typography>
                  <TextField
                    type="number"
                    className="box-input-email"
                    onChange={(e) => handleState(e.target.value, "postCode")}
                    sx={{
                      mt: '4px',
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                        display: "none",
                      },
                      "& input[type=number]": {
                        MozAppearance: "textfield",
                      },
                      input: {
                        fontSize: '14px', fontFamily: 'Satoshi-500'
                      },
                    }}
                    value={postCode}
                    fullWidth
                    id="email"
                    placeholder={translate('login-register.placeholder-kodepos')}
                    name="email"
                    autoComplete="email"

                  />
                </Grid>
                <Grid item xs >

                </Grid>
              </Grid>
              <Button
                className="w-500"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: '52px', background: ' linear-gradient(270deg, #2457B9 15%, #2860CD 100%)', borderRadius: '8px', boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.12), 0px -1px 0px 0px rgba(0, 0, 0, 0.20) inset', padding: '12px 16px', textTransform: 'none' }}
              >
                {isLoadingRegister ? <CircularProgress
                  sx={{
                    color: (theme) =>
                      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                  }}
                  size={20}
                  thickness={4}
                  value={100}
                /> : `${translate('login-register.btn-daftar')}`}
              </Button>
              <Stack justifyContent={'center'} direction={'row'} sx={{ mt: '32px', mb: '32px' }} alignItems={'center'}>
                <Divider style={{ backgroundColor: "#E8EBF2", width: '80px', height: '1px', }} />
                <Typography className="w-500" align="center" sx={{ mr: '12px', ml: '12px', color: '#56617B', fontSize: '14px' }}> {translate('login-register.or')}
                </Typography>
                <Divider style={{ backgroundColor: "#E8EBF2", width: '80px', height: '1px', }} />
              </Stack>
              <Grid container spacing={2}>
                <Grid item xs>
                  <Button
                    // type="submit"
                    onClick={(e) => registerWithGoogle()}
                    fullWidth
                    variant="outlined"
                    startIcon={<Box
                      component="img"
                      sx={{

                        height: 20,
                      }}
                      alt="logo-img"
                      src={Googleicon}
                    />}
                    sx={{
                      mt: 3, mb: 2, textTransform: "none", border: '1px solid var(--primarry-blue, #2457B9)',
                      p: '12px 16px',
                      borderRadius: '8px',
                      color: '#2457B9'
                    }}
                  >
                    <Typography
                      className="w-500"
                      sx={{ fontSize: 14, color: '#2457B9' }}

                    >
                      Google

                    </Typography>
                  </Button>
                </Grid>
                <Grid item xs>
                  <Button
                    // type="submit"
                    onClick={(e) => registerWithFacebook()}
                    fullWidth
                    variant="outlined"
                    startIcon={<Box
                      component="img"
                      sx={{

                        height: 20,
                      }}
                      alt="logo-img"
                      src={Facebookicon}
                    />}
                    sx={{
                      mt: 3, mb: 2, textTransform: "none", border: '1px solid var(--primarry-blue, #2457B9)',
                      p: '12px 16px',
                      borderRadius: '8px',
                      color: '#2457B9'
                    }}
                  >
                    <Typography
                      className="w-500"
                      sx={{ fontSize: 14, color: '#2457B9' }}

                    >
                      Facebook

                    </Typography>
                  </Button>
                </Grid>
              </Grid>
              <Hidden mdUp>
                <Stack direction={'row'} sx={{ mt: '136px', mb: '32px' }} justifyContent={{ xs: 'center', sm: 'center', md: 'left' }} alignItems={'center'}>
                  <Typography
                    className="w-500"
                    sx={{ fontSize: 14, color: '#56617B', mr: '8px' }}

                  >
                    {translate('login-register.btn-to-login')}

                  </Typography>
                  <Link href="/signin" className="w-700" sx={{ color: '#2457B9', fontSize: 14, textDecoration: 'none' }}>
                    {translate('login-register.btn-masuk')}
                  </Link>{" "}
                </Stack>
              </Hidden>
            </Box>
          </Box>
        </Grid>
        <Hidden smDown>
          <Grid
            item
            xs={false}
            sm={false}
            md={6}
            lg={6}
            sx={{

              backgroundImage: `linear-gradient(180deg, rgba(8, 34, 86, 0.6) 16.83%, rgba(8, 34, 86, 0) 60.9%), url(${heroLoginImg})`,
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <Typography className="w-700" sx={{ color: 'white', fontSize: 48, mt: '186px', ml: '61px', width: '600px', lineHeight: '72px' }}>
              {translate('login-register.hero-text1')} <br />  {translate('login-register.hero-text2')}
            </Typography>
            <Divider style={{ marginLeft: '61px', backgroundColor: "#E8EBF2", width: '80px', height: '3px', marginTop: '24px', }} />
          </Grid>
        </Hidden>
      </Grid>
    </ThemeProvider>
  )
}
