import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Checkbox, FormControlLabel, Grid, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { React, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { getCity } from '../../services/api';
import IconInfo from "../../assets/icon-info.png";

function MenuAddress(props) {
  const [translate,] = useTranslation("global");
  const [city, setCity] = useState([])

  async function getDataCityByProvince(e) {
    const response = await getCity(e);
    setCity(response.data.data)
  }

  useEffect(() => {
    getDataCityByProvince(props.item.id_provinsi)
  }, [props.item.id_provinsi])

  useEffect(() => {
    getDataCityByProvince(props.item.id_provinsi)
  }, [])

  const [validationDetailAddress, setValidationDetailAddress] = useState(false);
  const [validationLabel, setValidationLabel] = useState(false);
  const [validationProvince, setValidationProvince] = useState(false);
  const [validationCity, setValidationCity] = useState(false);
  const [validationPostCode, setValidationPostCode] = useState(false);
  const [validationIsCurrentAddress, setValidationIsCurrentAddress] = useState(false);

  useEffect(() => {

    if (props.triggerValidation >= 1) {

      if (!props.item.detail_alamat) {
        setValidationDetailAddress(true)
      }

      if (!props.item.label_alamat) {
        setValidationLabel(true)
      }

      if (!props.item.id_provinsi) {
        setValidationProvince(true)
      }

      if (!props.item.id_kota) {
        setValidationCity(true)
      }

      if (!props.item.kode_pos) {
        setValidationPostCode(true)
      }

      if (!props.item.alamat_sekarang) {
        setValidationIsCurrentAddress(true)
      }
    }

  }, [props.triggerValidation])

  useEffect(() => {

    if (props.triggerValidation >= 1) {

      if (!props.item.id_provinsi) {
        setValidationProvince(true)
      } else {
        setValidationProvince(false)
      }

      if (!props.item.id_kota) {
        setValidationCity(true)
      } else {
        setValidationCity(false)
      }
    }

  }, [props.triggerValidation, props.item.id_provinsi, props.item.id_kota])

  return (
    <Box key={`${props.i}_address`} sx={{ border: '1px solid var(--grey-stroke, #E8EBF2)', borderRadius: '12px', p: '16px', mb: 2 }}>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <h6 style={{ fontSize: 16 }} className=' w-700 mb-16'> {translate('profile.address')}</h6>
        {props.i !== 0 && <CloseIcon onClick={(e) => props.handleDeleteAddress(props.i)} sx={{ height: 24, width: 24, cursor: 'pointer' }} ></CloseIcon>}
      </Stack>
      <p className='c-08 s-1419 w-500 mb4'>{translate('status.alamatlengkap')}</p>
      <TextField sx={{
        width: '100%', input: {
          fontSize: '14px', fontFamily: 'Satoshi-500'
        },
      }}
        placeholder={translate('status.placeholderalamatperusahaan')}
        value={props.item.detail_alamat}
        // onChange={(e) => props.handleChangeAlamatLengkap(e, props.i)}
        error={validationDetailAddress}
        onChange={(e) => {
          if (e.target.value && e.target.value !== "") {
            props.handleChangeAlamatLengkap(e, props.i)
            setValidationDetailAddress(false);
          } else {
            props.handleChangeAlamatLengkap(e, props.i)
            setValidationDetailAddress(true);
          }
        }}
      />
      <Grid container justifyContent={'space-between'} spacing={2}>
        <Grid item xs>
          <p className='c-08 s-1419 w-500 mb4 mt-24'>{translate('status.labelalamat')}</p>
          <Select IconComponent={() => (
            <KeyboardArrowDownIcon sx={{ mr: '16px', width: '20px', height: '20px', fill: '#56617B' }} />
          )}
            MenuProps={{
              PaperProps: {
                sx: {
                  height: '352px',
                  '& .MuiMenuItem-root': {
                  },
                },
              },
            }}
            style={{ border: '1px solid var(--grey-stroke, #e8ebf2)', marginTop: '4px', borderRadius: '8px', width: '100%' }}
            value={props.item.label_alamat}
            // onChange={(e) => props.handleChangeLabel(e, props.i)}
            error={validationLabel}
            onChange={(e) => {
              props.handleChangeLabel(e, props.i)
              setValidationLabel(false);
            }}
            displayEmpty >
            <MenuItem value="" style={{ display: "none" }}>
              <p className='w-400 s-1420 c-87'>{translate('status.labelalamat')}</p>
            </MenuItem>
            {props.label_alamat.map((alamat, index) => (
              <MenuItem key={alamat.id} value={alamat.attributes.value}>{alamat.attributes.value} </MenuItem>))}
          </Select>
        </Grid>
        <Grid item xs>
          <p className='c-08 s-1419 w-500 mb4 mt-24'>{translate('login-register.provinsi')}</p>
          <Select IconComponent={() => (
            <KeyboardArrowDownIcon sx={{ mr: '16px', width: '20px', height: '20px', fill: '#56617B' }} />
          )}
            MenuProps={{
              PaperProps: {
                sx: {
                  height: '352px',

                  '& .MuiMenuItem-root': {
                  },
                },
              },
            }}
            style={{ border: '1px solid var(--grey-stroke, #e8ebf2)', marginTop: '4px', borderRadius: '8px', width: '100%' }} value={props.item.id_provinsi} onChange={(e) => props.changeProvince(e, props.i)} displayEmpty >
            <MenuItem value="" style={{ display: "none" }}>
              <p className='w-400 s-1420 c-87'>{translate('login-register.placeholder-provinsi')}</p>
            </MenuItem>
            {props.listOfProvince.map((provinsi, index) => (
              <MenuItem key={provinsi} value={provinsi.id}>{provinsi.attributes?.name} </MenuItem>))}
          </Select>
          {validationProvince ? (
            <Box sx={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
              <img alt="Nikel-icon" src={IconInfo} height={20}></img>
              <p className="s-1419 w-500" style={{ marginLeft: "5px", color: "#D32F2F" }}>
                {translate("title.validation")}
              </p>
            </Box>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      <Grid container justifyContent={'space-between'} spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <p className='c-08 s-1419 w-500 mb4 mt-24'>{translate('login-register.kota')}</p>
          <Select IconComponent={() => (
            <KeyboardArrowDownIcon sx={{ mr: '16px', width: '20px', height: '20px', fill: '#56617B' }} />
          )}
            MenuProps={{
              PaperProps: {
                sx: {
                  height: '352px',
                  '& .MuiMenuItem-root': {
                  },
                },
              },
            }}
            style={{ border: '1px solid var(--grey-stroke, #e8ebf2)', borderRadius: '8px', width: '100%' }}
            value={props.item.id_kota}
            onChange={(e) => props.changeCity(e, props.i)} displayEmpty >
            <MenuItem value="" style={{ display: "none" }}>
              <p className='w-400 s-1420 c-87'>{translate('login-register.placeholder-city')} </p>
            </MenuItem>
            {city.map((kota, index) => (
              <MenuItem value={kota.id} key={index}>{kota.attributes?.name} </MenuItem>))}
          </Select>
          {validationCity ? (
            <Box sx={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
              <img alt="Nikel-icon" src={IconInfo} height={20}></img>
              <p className="s-1419 w-500" style={{ marginLeft: "5px", color: "#D32F2F" }}>
                {translate("title.validation")}
              </p>
            </Box>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <p className='c-08 s-1419 w-500 mb4 mt-24'>{translate('login-register.kodepos')}</p>
          <TextField sx={{
            width: '100%',
            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
              display: "none",
            },
            "& input[type=number]": {
              MozAppearance: "textfield",
            }, input: {
              color: '#8792AB', fontSize: '14px', fontFamily: 'Satoshi-500'
            },
          }}
            type='number'
            placeholder={translate('login-register.placeholder-kodepos')} value={props.item.kode_pos}
            error={validationPostCode}
            onChange={(e) => {
              if (e.target.value && e.target.value !== "") {
                props.handleChangeKodepos(e, props.i)
                setValidationPostCode(false);
              } else {
                props.handleChangeKodepos(e, props.i)
                setValidationPostCode(true);
              }
            }}
          // onChange={(e) => props.handleChangeKodepos(e, props.i)}
          />
        </Grid>
      </Grid>

      <FormControlLabel control={<Checkbox sx={{
        color: "#E8EBF2",
        '&.Mui-checked': {
          color: "#3BB56C",
        },
      }} checked={props.item.alamat_sekarang} onChange={(e) => props.handleCurrentAddress(e, props.i)} />} label={<Typography className='w-500'>{translate('status.alamatsekarang')}</Typography>} className='c-08 s-1419 w-500 mt-24' />
    </Box>
  );
}

export default function MenuFormAddress(props) {
  const [translate,] = useTranslation("global");

  console.log(props)
  return (
    <Grid item xs={12} sm={12} md={4} lg={4} className='form-input'>
      {props.arrayaddress.map((item, i) => (
        <MenuAddress triggerValidation={props.triggerValidation} item={item} i={i} listKota={props.listKota} id_provinsi={props.id_provinsi} changeProvince={props.changeProvince} listOfProvince={props.listOfProvince} id_city={props.id_city} listOfcity={props.listOfcity} kode_pos={props.kode_pos} setKode_pos={props.setKode_pos} CurrentAddress={props.CurrentAddress} setCurrentAddress={props.setCurrentAddress} label_alamat={props.label_alamat} idlabelalamat={props.idlabelalamat} setidlabelalamat={props.setidlabelalamat} handleAddAddress={props.handleAddAddress} handleDeleteAddress={props.handleDeleteAddress} handleChangeAlamatLengkap={props.handleChangeAlamatLengkap} handleChangeLabel={props.handleChangeLabel} changeCity={props.changeCity} handleChangeKodepos={props.handleChangeKodepos} handleCurrentAddress={props.handleCurrentAddress} />
      ))}
      <Stack onClick={() => props.handleAddAddress()} direction={'row'} alignItems={'center'} sx={{ cursor: 'pointer', mt: 2 }}>
        <AddIcon sx={{
          fontSize: 20, color: "#2457B9"
        }} />
        <Typography className="w-700" style={{ fontSize: 16, color: '#2457B9' }}>
          {translate('status.tambahdata')}
        </Typography>
      </Stack>
    </Grid>
  );
}