import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CompanyDefault from '@mui/icons-material/Business';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Hidden from '@mui/material/Hidden';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { default as CallCompanyIcon } from "../assets/call-company-ic.png";
import alertKontaktIc from "../assets/icons/alert.svg";
import EmailCompanyIcon from "../assets/mail-company-ic.png";
import { searchTrader } from "../services/api.js";
import { config } from '../services/constants';
import { format } from '../services/primary';
import ModalShare from "./ModalShare";

export default function TableTrader(props) {
  const [productList, setProductList] = useState([]);
  const [translate,] = useTranslation("global");
  const [anchorElShare, setAnchorElShare] = useState(null);
  const [product_id, setProduct_id] = useState(null);
  const [user_trader_phone, setuser_trader_phone] = useState(null);

  const handleOpenShareMenu = (event, id, traderphone) => {
    setuser_trader_phone(traderphone);
    setProduct_id(id);
    setAnchorElShare(event.currentTarget);
  };
  const handleCloseShareMenu = () => {
    setAnchorElShare(null);
  };

  const columns = [
    {
      headerClassName: 'super-app-theme--header', field: 'name', headerName: translate('jual-beli.nama-trader'), width: 400, sortable: false, renderCell: (params) => (
        <Grid container sx={{ display: 'flex', cursor: 'pointer' }}
          onClick={(e) => {
            window.location.href = `/detailtrader?type=5&id_detail=${params.row.user_id}`
          }}
          justifyContent={'start'} alignItems={'center'}>
          {params.row?.user_image ?

            <img style={{ borderRadius: '50%', marginRight: 6 }} alt="profile-icon" src={config.baseURL + params.row.user_image} height={32} width={32}></img> :

            <Avatar sx={{ bgcolor: '#1976d2', mr: 1, height: 32, width: 32 }} aria-label="recipe">
            </Avatar>}

          <Stack direction="column">
            <Typography
              className="w-700"
              sx={{ fontSize: 14, ml: 1, }}
            >
              {params.row.name}
            </Typography>
            <Typography
              className="w-500"
              sx={{ fontSize: 14, ml: 1, color: '#56617B' }}
            >
              {params.row.kota} {params.row.kota && params.row.provinsi !== null ? ',' : ''}  {params.row.provinsi}
            </Typography>
          </Stack>
        </Grid>
      ),
    },
    {
      headerClassName: 'super-app-theme--header', field: 'phone_number', headerName: translate('page_explore.kontak'), width: 300, sortable: false, renderCell: (params) => (
        params.row.phone_number === null && params.row.email === null ?

          <Stack onClick={(e) => {
            window.location.href = `/detailtrader?type=5&id_detail=${params.row.user_id}`
          }} direction="row" sx={{ mb: '2px', cursor: 'pointer' }} alignItems={'center'}>
            <Box sx={{ width: '32px', height: '32px', background: '#D9D9D9', borderRadius: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
              <img alt="Nikel-icon" src={alertKontaktIc} height={18}></img>
            </Box>
            <Typography
              className="w-500"
              sx={{ fontSize: 14, ml: 1, color: '#8792AB' }}
            >
              {translate('status.kontak-empty')}
            </Typography>

          </Stack> :
          <Grid
            container
            justifyContent="left"
            alignItems="left"
            direction="column"
            spacing={1}
            sx={{ cursor: 'pointer' }}
            onClick={(e) => {
              window.location.href = `/detailtrader?type=5&id_detail=${params.row.user_id}`
            }}
          >
            <Stack direction="row" sx={{ mb: '2px' }} alignItems={'center'}>
              <img alt="Nikel-icon" src={CallCompanyIcon} height={18}></img>
              <Typography
                className="w-500"
                sx={{ fontSize: 14, ml: 1, color: params.row.phone_number === null || params.row.phone_number === "" ? '#8792AB' : '#56617B' }}
              >
                {params.row.phone_number === null ? translate('status.nomor-empty') : params.row.phone_number === undefined ? "Private" : params.row.phone_number === "" ? "Private" : format(params.row.phone_number)}


              </Typography>

            </Stack>
            <Stack direction="row" alignItems={'center'}>
              <img alt="Nikel-icon" src={EmailCompanyIcon} height={18}></img>
              <Typography
                className="w-500"
                sx={{ fontSize: 14, ml: 1, color: params.row.email === null || params.row.email === "" ? '#8792AB' : '#56617B' }}
              >
                {params.row.email === null ? translate('status.email-empty') : params.row.email === undefined ? "Private" : params.row.email === "" ? "Private" : params.row.email}

              </Typography>

            </Stack>
          </Grid>
      ),
    },
    {
      headerClassName: 'last-theme--header', field: 'company_list', headerName: translate('page_explore.perusahaan'), width: 180, sortable: false, renderCell: (params) => (
        <Grid
          container
          sx={{ cursor: 'pointer', display: 'flex' }}
          onClick={(e) => {
            window.location.href = `/detailtrader?type=5&id_detail=${params.row.user_id}`
          }}
          justifyContent={'center'} alignItems={'center'}>
          <Grid
            container
            justifyContent="left"
            alignItems="center"
            direction="row"
          >
            {(params?.row.company_list && params?.row.company_list.length > 0) ?
              <Stack direction={'row'}>
                {params.row.company_list.map((company, i) => {
                  if (i < 4) return (
                    company.company_image ?
                      <Avatar className="w-500" src={config.baseURL + company.company_image} style={{ backgroundColor: '#1976d2', marginLeft: -10 }} aria-label="recipe">
                        <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
                      </Avatar> :
                      <Avatar className="w-500" style={{ backgroundColor: '#1976d2', marginLeft: -10 }} aria-label="recipe">
                        <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
                      </Avatar>
                  )
                })}
                {(params?.row.company_list && params?.row.company_list.length > 4) && <Avatar className="w-500" style={{ backgroundColor: '#1976d2', marginLeft: -10, fontSize: 16 }} aria-label="recipe">
                  +{params.row.company_list.length - 4}
                </Avatar>}
              </Stack>

              :
              <Stack direction={'row'} style={{ marginLeft: -10 }}>
                <Typography
                  className="w-500"
                  sx={{ fontSize: 14, ml: 1, color: '#081130' }}
                >
                  No company listed
                </Typography>
              </Stack>}
          </Grid>
        </Grid>
      ),
    },

    {
      headerClassName: 'last-theme--header', field: 'action', headerName: ' ', sortable: false, width: 100, renderCell: (params) => (
        <Grid
          container
          justifyContent="end"
          alignItems="center"
          direction="row"
        >

          <IconButton size="small" onClick={(e) => handleOpenShareMenu(e, params.row.user_id, params.row.phone_number)}>
            <MoreHorizIcon sx={{ fontSize: 18, }} />

          </IconButton>
          <ModalShare page={"detailtrader"} type={5} id={product_id} user_trader_phone={user_trader_phone} anchorEl={anchorElShare} open={anchorElShare} onClose={handleCloseShareMenu}></ModalShare>
          <IconButton size="small" onClick={(e) => {
            window.location.href = `/detailtrader?type=5&id_detail=${params.row.user_id}`
          }}>
            <ArrowForwardIosIcon
              sx={{ fontSize: 18 }} />
          </IconButton>
        </Grid>
      ),
    },
  ];

  useEffect(() => {
    getProduct();
  }, []);

  async function getProduct() {
    try {
      const response = await searchTrader({
        page: 1, company_id: "", id_location: "", search_text: ""
      });
      setProductList(response.data.data.data.data);
    } catch (e) {
      console.log(e);
    }
  }

  const dataTrader = props.dataTrader ? props.dataTrader : productList
  return (
    <Hidden lgDown>
      <div style={{}} className="ex-ui">
        <DataGrid
          autoHeight
          sx={{
            borderRadius: '8px',
            '& .super-app-theme--header': {
              borderRight: 1,
              borderColor: '#E8EBF2',
              fontFamily: 'Satoshi-500 !important',
              color: '#081130 !important',
              outline: "none !important",
            },
            '& .last-theme--header': {

              borderColor: '#E8EBF2',
              fontFamily: 'Satoshi-500 !important',
              color: '#081130 !important',
              outline: "none !important",
              justifyContent: 'start'
            },
            '.MuiDataGrid-iconButtonContainer': {
              visibility: 'visible',
            },
            '.MuiDataGrid-sortIcon': {
              opacity: 'inherit !important',
            },
            '.MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
          }}
          rows={dataTrader}
          sortingOrder={['desc', 'asc']}
          disableColumnMenu
          disableSelectionOnClick={true}
          disableRowSelectionOnClick
          components={{
            ColumnSortedAscendingIcon: UnfoldMoreIcon,
            ColumnSortedDescendingIcon: UnfoldMoreIcon
          }}
          getRowId={(row) => row.user_id}
          columns={columns}
        />
      </div>
    </Hidden>
  )
}
