import CloseIcon from '@mui/icons-material/Close';
import { Box, Drawer, Grid, IconButton, Modal, Stack, Typography } from "@mui/material";
import * as React from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import beliBatubaraIcon from "../assets/beli-batubara-ico.png";
import beliNikelIcon from "../assets/beli-nikel-ico.png";
import beliTongkangIcon from "../assets/beli-tongkang-ico.png";
import freightCharterIcon from "../assets/freight-charter-ic.png";
import jualBatubaraIcon from "../assets/jual-batubara-ico.png";
import jualNikelIcon from "../assets/jual-nikel-ico.png";
import jualTongkangIcon from "../assets/jual-tongkang-ico.png";
import menyewaTongkangIcon from "../assets/menyewa-tongkang-ico.png";
import sewaTongkangIcon from "../assets/sewa-tongkang-ico.png";
import timeCharterIcon from "../assets/time-charter-ic.png";

function DrawerBuatPermintaan(props) {
    const [translate,] = useTranslation("global");
    const [isOpenModalSewaTongkang, setisOpenModalSewaTongkang] = React.useState(false)
    const [page, setPage] = React.useState("")

    const handleCloseModal = () => {
        setisOpenModalSewaTongkang(false);
    };



    return (
        <div>
            <Drawer
                className="box-drawer"
                sx={{ zIndex: 999999, }}
                anchor="bottom"
                open={Boolean(props.open)}
                onClose={props.close}
            >
                <Box sx={{ p: '16px' }}>
                    <Stack direction={'row'} justifyContent={'space-between'} sx={{ height: '24px' }}>
                        <Typography id="modal-modal-title" className='w-700' sx={{ fontSize: '18px', color: '#081130', textAlign: 'start' }}>
                            {translate('navbar.title-permintaan')}
                        </Typography>
                        <IconButton onClick={() => {
                            props.close();
                        }} size="small" >
                            <CloseIcon />
                        </IconButton>
                    </Stack>

                    {/* <Typography id="modal-modal-description" className='w-700' sx={{ mt: '20px', color: '#56617B', fontSize: '14px', textAlign: 'start' }}>
                    Lorem ipsum dolor sit amet consectetur. Id sit egestas nec pretium.
                </Typography> */}

                    <Grid container spacing={2} direction={'row'} sx={{ mt: '8px' }}>
                        <Grid item xs={6}>
                            <Box onClick={(e) => { window.location.href = `/jual-batubara` }} sx={{ border: 1, color: '#E8EBF2', borderRadius: '8px', justifyContent: 'center' }}>
                                <Box sx={{ mt: '14.22px', justifyContent: 'center', display: 'flex' }}>
                                    <img alt="Batubara-icon" style={{ width: '64px', height: '64px', }} src={jualBatubaraIcon}></img>
                                </Box>

                                <Typography className='w-700' sx={{ fontSize: '14px', color: '#081130', textAlign: 'center', mt: '8px', mb: '14.22px' }}>
                                    {translate('page_explore.btn-jual-batubara')}
                                </Typography>

                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Box onClick={(e) => { window.location.href = `/beli-batubara` }} sx={{ border: 1, color: '#E8EBF2', borderRadius: '8px', justifyContent: 'center' }}>
                                <Box sx={{ mt: '14.22px', justifyContent: 'center', display: 'flex' }}>
                                    <img alt="Batubara-icon" style={{ width: '64px', height: '64px', }} src={beliBatubaraIcon}></img>
                                </Box>

                                <Typography className='w-700' sx={{ fontSize: '14px', color: '#081130', textAlign: 'center', mt: '8px', mb: '14.22px' }}>
                                    {translate('permintaan.btn-beli-batubara')}
                                </Typography>

                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Box onClick={(e) => { window.location.href = `/jual-nikel` }} sx={{ border: 1, color: '#E8EBF2', borderRadius: '8px', justifyContent: 'center' }}>
                                <Box sx={{ mt: '14.22px', justifyContent: 'center', display: 'flex' }}>
                                    <img alt="Batubara-icon" style={{ width: '64px', height: '64px', }} src={jualNikelIcon}></img>
                                </Box>

                                <Typography className='w-700' sx={{ fontSize: '14px', color: '#081130', textAlign: 'center', mt: '8px', mb: '14.22px' }}>
                                    {translate('page_explore.btn-jual-nikel')}
                                </Typography>

                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Box onClick={(e) => { window.location.href = `/beli-nikel` }} sx={{ border: 1, color: '#E8EBF2', borderRadius: '8px', justifyContent: 'center' }}>
                                <Box sx={{ mt: '14.22px', justifyContent: 'center', display: 'flex' }}>
                                    <img alt="Batubara-icon" style={{ width: '64px', height: '64px', }} src={beliNikelIcon}></img>
                                </Box>

                                <Typography className='w-700' sx={{ fontSize: '14px', color: '#081130', textAlign: 'center', mt: '8px', mb: '14.22px' }}>
                                    {translate('permintaan.btn-beli-nikel')}
                                </Typography>

                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Box onClick={(e) => {
                                setPage("disewakan")
                                setisOpenModalSewaTongkang(true)
                                props.close();

                            }} sx={{ border: 1, color: '#E8EBF2', borderRadius: '8px', justifyContent: 'center' }}>
                                <Box sx={{ mt: '14.22px', justifyContent: 'center', display: 'flex' }}>
                                    <img alt="Batubara-icon" style={{ width: '64px', height: '64px', }} src={menyewaTongkangIcon}></img>
                                </Box>

                                <Typography className='w-700' sx={{ fontSize: '14px', color: '#081130', textAlign: 'center', mt: '8px', mb: '14.22px' }}>
                                    {translate('page_explore.btn-menyewakan-tongkang')}
                                </Typography>

                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Box onClick={(e) => {
                                setPage("sewa")
                                setisOpenModalSewaTongkang(true)
                                props.close();

                            }} sx={{ border: 1, color: '#E8EBF2', borderRadius: '8px', justifyContent: 'center' }}>
                                <Box sx={{ mt: '14.22px', justifyContent: 'center', display: 'flex' }}>
                                    <img alt="Batubara-icon" style={{ width: '64px', height: '64px', }} src={sewaTongkangIcon}></img>
                                </Box>

                                <Typography className='w-700' sx={{ fontSize: '14px', color: '#081130', textAlign: 'center', mt: '8px', mb: '14.22px' }}>
                                    {translate('permintaan.btn-sewa-tongkang')}
                                </Typography>

                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Box onClick={(e) => { window.location.href = `/jual-tongkang` }} sx={{ border: 1, color: '#E8EBF2', borderRadius: '8px', justifyContent: 'center' }}>
                                <Box sx={{ mt: '14.22px', justifyContent: 'center', display: 'flex' }}>
                                    <img alt="Batubara-icon" style={{ width: '64px', height: '64px', }} src={jualTongkangIcon}></img>
                                </Box>

                                <Typography className='w-700' sx={{ fontSize: '14px', color: '#081130', textAlign: 'center', mt: '8px', mb: '14.22px' }}>
                                    {translate('page_explore.btn-jual-tongkang')}
                                </Typography>

                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Box onClick={(e) => { window.location.href = `/beli-tongkang` }} sx={{ border: 1, color: '#E8EBF2', borderRadius: '8px', justifyContent: 'center' }}>
                                <Box sx={{ mt: '14.22px', justifyContent: 'center', display: 'flex' }}>
                                    <img alt="Batubara-icon" style={{ width: '64px', height: '64px', }} src={beliTongkangIcon}></img>
                                </Box>

                                <Typography className='w-700' sx={{ fontSize: '14px', color: '#081130', textAlign: 'center', mt: '8px', mb: '14.22px' }}>
                                    {translate('permintaan.btn-beli-tongkang')}
                                </Typography>

                            </Box>
                        </Grid>

                    </Grid>
                </Box>
            </Drawer>
            <ModalPermintaanSewaTongkang page={page} open={isOpenModalSewaTongkang} close={handleCloseModal}></ModalPermintaanSewaTongkang>
        </div>
    );
}

function ModalPermintaanSewaTongkang(props) {
    const navigate = useNavigate()
    const [translate,] = useTranslation("global");
    const style_sub = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        borderRadius: '12px',
        bgcolor: 'background.paper',
        boxShadow: 24,
        paddingBottom: '12px',
        paddingLeft: '12px',
        paddingRight: '12px',
        "&:focus": {
            outline: "none"
        },
    };
    return (
        <div>
            <Modal
                open={props.open}
                onClose={props.close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ zIndex: 100000 }}
            >
                {props.page === "sewa" ?
                    <Box sx={style_sub}>

                        <Box sx={{ display: 'flex', mt: '24px' }}>
                            <Typography id="modal-modal-title" className='w-700' sx={{ width: '100%', fontSize: '14px', color: '#081130', textAlign: 'center', lineHeight: 'normal' }}>
                                {translate('permintaan.btn-sewa-tongkang')}
                            </Typography>
                            <IconButton onClick={() => {
                                props.close();
                            }} size="small" >
                                <CloseIcon sx={{ width: '12px', height: '12px' }} />
                            </IconButton>
                        </Box>
                        <Grid container spacing={2} direction={'row'} sx={{ mt: 1 }}>
                            <Grid item xs={6}>
                                <Box onClick={(e) => {
                                    navigate(`/sewa-tongkang`, { state: { detail: 'time-charter' } })
                                    window.location.reload()
                                }} sx={{ border: 1, color: '#E8EBF2', borderRadius: '8px', cursor: 'pointer' }}>
                                    <Box sx={{ mt: '18.5px', justifyContent: 'center', display: 'flex' }}>
                                        <img alt="Nikel-icon" style={{ width: '50px', height: '50px' }} src={timeCharterIcon}></img>
                                    </Box>
                                    <Typography className='w-700' sx={{ fontSize: '14px', color: '#081130', textAlign: 'center', mt: '16px', mb: '18.5px' }}>
                                        Time Charter
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box onClick={(e) => {
                                    navigate(`/sewa-tongkang`, { state: { detail: 'freight-charter' } })
                                    window.location.reload()
                                }} sx={{ border: 1, color: '#E8EBF2', borderRadius: '8px', cursor: 'pointer' }}>
                                    <Box sx={{ mt: '18.5px', justifyContent: 'center', display: 'flex' }}>
                                        <img alt="Nikel-icon" style={{ width: '50px', height: '50px' }} src={freightCharterIcon}></img>
                                    </Box>
                                    <Typography className='w-700' sx={{ fontSize: '14px', color: '#081130', textAlign: 'center', mt: '16px', mb: '18.5px' }}>
                                        Freight Charter
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box> :
                    <Box sx={style_sub}>

                        <Box sx={{ mt: '24px' }}>
                            <Stack direction={"row"} sx={{ alignItems: "center", justifyContent: "space-between" }}>
                                <Typography id="modal-modal-title" className='w-700' sx={{ width: '100%', fontSize: '14px', color: '#081130', textAlign: 'center', lineHeight: 'normal' }}>
                                    {translate('page_explore.btn-menyewakan-tongkang')}
                                </Typography>
                                <IconButton onClick={() => {
                                    props.close();
                                }} size="small" >
                                    <CloseIcon sx={{ width: '12px', height: '12px' }} />
                                </IconButton>
                            </Stack>
                        </Box>
                        <Grid container spacing={1} direction={'row'} sx={{ mt: 1 }}>
                            <Grid item xs={6}>
                                <Box onClick={(e) => {
                                    navigate(`/tongkang-disewakan`, { state: { detail: 'time-charter' } })
                                    window.location.reload()
                                }} sx={{ border: 1, color: '#E8EBF2', borderRadius: '8px', cursor: 'pointer' }}>
                                    <Box sx={{ mt: '18.5px', justifyContent: 'center', display: 'flex' }}>
                                        <img alt="Nikel-icon" style={{ width: '50px', height: '50px' }} src={timeCharterIcon}></img>
                                    </Box>
                                    <Typography className='w-700' sx={{ fontSize: '14px', color: '#081130', textAlign: 'center', mt: '16px', mb: '18.5px' }}>
                                        Time Charter
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box onClick={(e) => {
                                    navigate(`/tongkang-disewakan`, { state: { detail: 'freight-charter' } })
                                    window.location.reload()
                                }} sx={{ border: 1, color: '#E8EBF2', borderRadius: '8px', cursor: 'pointer' }}>
                                    <Box sx={{ mt: '18.5px', justifyContent: 'center', display: 'flex' }}>
                                        <img alt="Nikel-icon" style={{ width: '50px', height: '50px' }} src={freightCharterIcon}></img>
                                    </Box>
                                    <Typography className='w-700' sx={{ fontSize: '14px', color: '#081130', textAlign: 'center', mt: '16px', mb: '18.5px' }}>
                                        Freight Charter
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>}

            </Modal>
        </div>
    );
}

export { DrawerBuatPermintaan, ModalPermintaanSewaTongkang };
