import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Modal, Stack, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";

export default function ModalSuccessReport(props) {

    const [translate,] = useTranslation("global");

    return (
        <div>
            <Modal
                open={props.open}
                onClose={props.close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ zIndex: 100000 }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '304px', sm: 343, md: 343, lg: 500, xl: 500 },
                    borderRadius: '8px',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 0,
                    "&:focus": {
                        outline: "none"
                    },
                }}>

                    <Stack direction={'row'} alignItems={'center'} justifyContent={'right'} sx={{ mb: '8px', mt: '10px', mr: '10px' }}>
                        <IconButton onClick={() => {
                            props.close();
                        }} sx={{ height: '24px', width: '24px' }}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>

                    <Box sx={{ ml: '32px', mr: '32px' }}>
                        <Typography id="modal-modal-title" className='w-700' sx={{ textAlign: 'center', fontSize: '20px', color: '#081130' }}>
                            {translate('status.successcreatereport')}
                        </Typography>

                        <Typography className='w-500' onClick={() => {
                            props.close();
                        }} sx={{ mb: '24px', mt: '24px', textAlign: 'center', fontSize: '16px', color: '#2457B9', cursor: 'pointer' }}>
                            {translate('navbar.keluar')}
                        </Typography>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}