import axios from 'axios';
import { config } from './constants';



let value
let list_id_location
let language = localStorage.getItem('lang') === 'null' || localStorage.getItem('lang') === 'id' ? 'id' : localStorage.getItem('lang')
let currency = localStorage.getItem('currency') === 'null' ? 'idr' : localStorage.getItem('currency')


function check_null_value(e) {
    if (e === "" || e === undefined) {
        value = 0
    } else {
        value = e
    }
    return value
}

function check_value_empty(e) {
    if (e === "" || e === 0 || e === undefined) {
        value = ""
    } else {
        value = e
    }
    return value
}

function check_value(e) {
    if (e === "" || e === 0 || e === undefined) {
        value = ""
    } else {
        value = encodeURIComponent(e)
    }
    return value
}

const check_id = (e) => {
    if (e.length > 0) {
        list_id_location = e.join(",")
    } else {
        list_id_location = ""
    }
    return list_id_location
}

let token = ""
let bearerToken = {
    headers: {
        'Authorization': 'Bearer ' + localStorage.getItem("token")
    }
}

if (localStorage.getItem("token") !== null) {
    token = bearerToken
}



// User Website
export const login = async (data) => {
    let dataLogin
    await axios.post(`${config.baseURL}/api/auth-service/login`, data).then((res) => {
        dataLogin = res.data
    }).catch((err) => {
        dataLogin = err.response.data
    })
    return dataLogin
};

export const editUser = async (id, data) => {
    let dataUser
    await axios.put(`${config.baseURL}/api/service-user/update-user`, data, token).then((res) => {
        dataUser = res
    }).catch((err) => {
        dataUser = err.response.data
    })
    return dataUser
};

export const deleteUserBackground = async (id) => {
    let data
    await axios.delete(`${config.baseURL}/api/service-user/delete-photo-background`, token).then((res) => {
        data = res.data
    }).catch((err) => {
        data = err.response
    })
    return data
};

export const loginFromGoogle = async (data) => {
    let dataLogin
    await axios.post(`${config.baseURL}/api/auth-firebase/login`, data).then((res) => {
        dataLogin = res.data
    }).catch((err) => {
        dataLogin = err.message
    })
    return dataLogin
};

export const loginFromFacebook = async (data) => {
    let dataLogin
    await axios.post(`${config.baseURL}/api/auth-firebase/login`, data).then((res) => {
        dataLogin = res.data
    }).catch((err) => {
        dataLogin = err.message
    })
    return dataLogin
};

export const loginFromFirebase = async (data) => {
    let dataLogin
    await axios.post(`${config.baseURL}/api/auth-firebase/login`, data).then((res) => {
        dataLogin = res.data
    }).catch((err) => {
        dataLogin = undefined
    })
    return dataLogin
};

export const loginFromPhoneNumber = async (data) => {
    let dataLogin
    await axios.post(`${config.baseURL}/api/auth-firebase/login-phone-verify`, data).then((res) => {
        dataLogin = res.data
    }).catch((err) => {
        dataLogin = undefined
    })
    return dataLogin
};

export const loginFromPhoneNumberFirebase = async (data) => {
    let dataLogin
    await axios.post(`${config.baseURL}/api/auth-firebase/login-phone`, data).then((res) => {
        dataLogin = res.data
    }).catch((err) => {
        dataLogin = undefined
    })
    return dataLogin
};


export const signUp = async (params) => {
    let data
    await axios.post(`${config.baseURL}/api/auth-service/register`, params).then((res) => {
        data = res.data
    }).catch((err) => {
        data = err.response.data
    })
    return data
};

export const signUpFromGoogle = async (params) => {
    let data
    await axios.post(`${config.baseURL}/api/auth-firebase/register`, params).then((res) => {
        data = res.data
    }).catch((err) => {
        data = undefined
    })
    return data
};

export const signUpFromFacebook = async (params) => {
    let data
    await axios.post(`${config.baseURL}/api/auth-firebase/register`, params).then((res) => {
        data = res.data
    }).catch((err) => {
        data = undefined
    })
    return data
};

export const signUpFromFirebase = async (params) => {
    let data
    await axios.post(`${config.baseURL}/api/auth-firebase/register`, params).then((res) => {
        data = res.data
    }).catch((err) => {
        data = err
    })
    return data
};


export const getEmailConfirm = async (confirmation_code) => {
    return axios.get(`${config.baseURL}/api/auth/email-confirmation?confirmation=${confirmation_code}`)
};

export const getDetailUserLogin = async (params) => {
    return axios.get(`${config.baseURL}/api/users/me?populate[0]=picture_path&populate[1]=background_picture_path&populate[2]=provinsi&populate[3]=city`, token)
};

export const getDetailUserSetting = async (params) => {
    return axios.get(`${config.baseURL}/api/service-user/detail-user/settings`, token)
};

export const sendEmailOTP = async (params) => {
    let data
    await axios.post(`${config.baseURL}/api/auth-service/send-otp`, params).then((res) => {
        data = res.data
    }).catch((err) => {
        data = err.response.data
    })
    return data
};


export const confirmOTP = async (params) => {
    let data
    await axios.post(`${config.baseURL}/api/auth-service/confirmation`, params).then((res) => {
        data = res.data
    }).catch((err) => {
        data = err.response.data
    })
    return data
};

export const sendCodeResetPassword = async (params) => {
    let data
    await axios.post(`${config.baseURL}/api/auth-service/send-code-reset`, params).then((res) => {
        data = res.data
    }).catch((err) => {
        data = err.response.data
    })
    return data
};

export const resetPassword = async (params) => {
    let data
    await axios.post(`${config.baseURL}/api/auth-service/reset-password`, params).then((res) => {
        data = res.data
    }).catch((err) => {
        data = err.response.data
    })
    return data
};

export const changePasswordUser = async (params) => {
    let data
    await axios.put(`${config.baseURL}/api/service-user/update-password-user`, params, token).then((res) => {
        data = res
    }).catch((err) => {
        data = err.response.data
    })
    return data
};

export const getSecurityQuestion = async (lang) => {
    return await axios.get(`${config.baseURL}/api/service-user/security-question?lang=${lang}`, token)
};


// Menu Permintaan 
export const getCoalRequest = async (params) => {
    return axios.get(`${config.baseURL}/api/service-permintaan/batubara?size=${check_value(params.page) * 10}&page=1&max_calorie=${check_value(params.max_calorie)}&min_calorie=${check_value(params.min_calorie)}&max_total_sulfur=${check_value(params.max_total_sulfur)}&min_total_sulfur=${check_value(params.min_total_sulfur)}&min_ash=${check_value(params.min_ash)}&max_ash=${check_value(params.max_ash)}&quantity=${check_value(params.quantity)}&min_price=${check_value(params.min_price)}&max_price=${check_value(params.max_price)}&request_start_laycan=${check_value(params.request_start_laycan)}&request_end_laycan=${check_value(params.request_end_laycan)}&id_location=${check_id(params.id_location)}&search_text=${check_value(params.search_text)}&currency=${currency}`, token)
};

export const getNickelRequest = async (params) => {
    return axios.get(`${config.baseURL}/api/service-permintaan/nikel?size=${check_value(params.page) * 10}&page=1&min_kadar_ni=${check_value(params.min_kadar_ni)}&max_kadar_ni=${check_value(params.max_kadar_ni)}&min_kadar_air=${check_value(params.min_kadar_air)}&max_kadar_air=${check_value(params.max_kadar_air)}&min_kadar_fe=${check_value(params.min_kadar_fe)}&max_kadar_fe=${check_value(params.max_kadar_fe)}&min_si_mg_ratio=${check_value(params.min_si_mg_ratio)}&max_si_mg_ratio=${check_value(params.max_si_mg_ratio)}&quantity=${check_value(params.quantity)}&min_price=${check_value(params.min_price)}&max_price=${check_value(params.max_price)}&request_start_laycan=${check_value(params.request_start_laycan)}&request_end_laycan=${check_value(params.request_end_laycan)}&id_location=${check_id(params.id_location)}&search_text=${check_value(params.search_text)}&currency=${currency}`, token)
};

export const buyBarge = async (params) => {
    return axios.get(`${config.baseURL}/api/service-permintaan/tongkang-pembelian?size=${check_value(params.page) * 10}&page=1&ukuran_tongkang=${check_value(params.ukuran_tongkang)}&max_price=${check_value(params.max_price)}&min_price=${check_value(params.min_price)}&tahun_buat=${check_value(params.tahun_buat)}&id_pelabuhan_muat=${check_value(params.pelabuhan_muat)}&search_text=${check_value(params.search_text)}&currency=${currency}`, token)
};

export const rentBarge = async (params) => {
    return axios.get(`${config.baseURL}/api/service-permintaan/tongkang-penyewaan?size=${check_value(params.page) * 10}&page=1&type_tongkang=${check_value(params.type_tongkang)}&ukuran_tongkang=${check_value(params.ukuran_tongkang)}&max_price=${check_value(params.max_price)}&min_price=${check_value(params.min_price)}&tahun_buat=${check_value(params.tahun_buat)}&id_pelabuhan_muat=${check_value(params.pelabuhan_muat)}&id_pelabuhan_bongkar=${check_value(params.pelabuhan_bongkar)}&search_text=${check_value(params.search_text)}&currency=${currency}`, token)
};

export const addPermintaan = async (params) => {
    let respone
    let tokenValue = localStorage.getItem("token")
    await axios.post(`${config.baseURL}/api/permintaan-lists`, {
        "data": {
            type_product: params.type_product,
            id_product: params.id_product,
            token: tokenValue
        }
    }, token).then((res) => {
        respone = res
    }).catch((err) => {
        respone = err.response
    })
    return respone
};

// Menu Notification
export const getNotification = async (params, lang) => {
    let data
    await axios.get(`${config.baseURL}/api/service-notification?size=10&page=${params.page}&lang=${lang}`, token).then((res) => {
        data = res.data
    }).catch((err) => {
        data = undefined
    })
    return data
};

export const readNotification = async (params) => {
    let respone
    await axios.put(`${config.baseURL}/api/notifications/${params.id}`, {
        "data": {
            is_read: true,
        }
    }, token).then((res) => {
        respone = res
    }).catch((err) => {
        respone = err.response
    })
    return respone
};

export const checkNotification = async () => {
    let data
    await axios.get(`${config.baseURL}/api/service-notification/check-notification`, token).then((res) => {
        data = res.data
    }).catch((err) => {
        data = undefined
    })
    return data
};

export const readAllNotificationService = async () => {
    let data
    await axios.get(`${config.baseURL}/api/service-notification/service-read-all-notification`, token).then((res) => {
        data = res.data
    }).catch((err) => {
        data = undefined
    })
    return data
};

// export const readAllNotification = async () => {
//     let data
//     await axios.get(`${config.baseURL}/api/service-notification/read-all-notification`, token).then((res) => {
//         data = res.data
//     }).catch((err) => {
//         data = undefined
//     })
//     return data
// };

// Menu Keyword
export const getKeyword = async () => {
    let data
    await axios.get(`${config.baseURL}/api/service-keyword`, token).then((res) => {
        data = res.data
    }).catch((err) => {
        data = undefined
    })
    return data
};

export const addKeyword = async (params) => {
    let data
    await axios.post(`${config.baseURL}/api/service-keyword`, params, token).then((res) => {
        data = res.data
    }).catch((err) => {
        data = err.response.data
    })
    return data
};

export const deleteKeyword = async (params) => {
    let data
    await axios.delete(`${config.baseURL}/api/service-keyword?id_keyword=${params.id_keyword}&all=${params.all}`, token).then((res) => {
        data = res.data
    }).catch((err) => {
        data = err.response.data
    })
    return data
};

// Menu Listing Saya
export const getListingBatubara = async (params) => {
    return axios.get(`${config.baseURL}/api/listing-product/batubara?size=10&page=${parseInt(params.page)}&max_calorie=${check_value(params.max_calorie)}&min_calorie=${check_value(params.min_calorie)}&max_total_sulfur=${check_value(params.max_total_sulfur)}&min_total_sulfur=${check_value(params.min_total_sulfur)}&min_ash=${check_value(params.min_ash)}&max_ash=${check_value(params.max_ash)}&quantity=${check_value(params.quantity)}&min_price=${check_value(params.min_price)}&max_price=${check_value(params.max_price)}&id_location=&calorie_orderby=&total_sulfur_orderby=&ash_orderby=&quantity_orderby=&price_orderby=&search_text=${check_value(params.search_text)}&id_listing_status=${check_value(params.id_listing_status)}&currency=${currency}`, token)
};

export const getListingNikel = async (params) => {
    return axios.get(`${config.baseURL}/api/listing-product/nikel?size=10&page=${parseInt(params.page)}&min_kadar_ni=${check_value(params.min_kadar_ni)}&max_kadar_ni=${check_value(params.max_kadar_ni)}&min_kadar_air=${check_value(params.min_kadar_air)}&max_kadar_air=${check_value(params.max_kadar_air)}&min_kadar_fe=${check_value(params.min_kadar_fe)}&max_kadar_fe=${check_value(params.max_kadar_fe)}&min_si_mg_ratio=${check_value(params.min_si_mg_ratio)}&max_si_mg_ratio=${check_value(params.max_si_mg_ratio)}&quantity=${check_value(params.quantity)}&min_price=${check_value(params.min_price)}&max_price=${check_value(params.max_price)}&id_location=&kadar_ni_orderby=&kadar_air_orderby=&kadar_fe_orderby=&si_mg_ratio_orderby=&quantity_orderby=&price_orderby=&search_text=${check_value(params.search_text)}&id_listing_status=${check_value(params.id_listing_status)}&currency=${currency}`, token)
};

export const getListingTongkang = async (params) => {
    return axios.get(`${config.baseURL}/api/listing-product/tongkang?size=10&page=${parseInt(params.page)}&type_tongkang=${check_value(params.type_tongkang)}&ukuran_tongkang=${check_value(params.ukuran_tongkang)}&max_price=${check_value(params.max_price)}&min_price=${check_value(params.min_price)}&search_text=${check_value(params.search_text)}&id_listing_status=${check_value(params.id_listing_status)}&currency=${currency}`, token)
};

// Menu Penawaran 
export const searchCoal = async (params) => {
    return axios.get(`${config.baseURL}/api/search-product/batubara?size=${parseInt(params.page) * 10}&page=1&max_calorie=${check_value(params.max_calorie)}&min_calorie=${check_value(params.min_calorie)}&max_total_sulfur=${check_value(params.max_total_sulfur)}&min_total_sulfur=${check_value(params.min_total_sulfur)}&min_ash=${check_value(params.min_ash)}&max_ash=${check_value(params.max_ash)}&quantity=${check_value(params.quantity)}&min_price=${check_value(params.min_price)}&max_price=${check_value(params.max_price)}&id_location=${check_id(params.id_location)}&request_start_laycan=${check_value(params.request_start_laycan)}&request_end_laycan=${check_value(params.request_end_laycan)}&request_trader_id=${check_value(params.request_trader_id)}&request_port=${check_value(params.request_port)}&calorie_orderby=&total_sulfur_orderby=&ash_orderby=&quantity_orderby=&price_orderby=&search_text=${check_value(params.search_text)}&currency=${currency}`, token)
};

export const searchNickel = async (params) => {
    return axios.get(`${config.baseURL}/api/search-product/nikel?size=10&page=${parseInt(params.page)}&min_kadar_ni=${check_value(params.min_kadar_ni)}&max_kadar_ni=${check_value(params.max_kadar_ni)}&min_kadar_air=${check_value(params.min_kadar_air)}&max_kadar_air=${check_value(params.max_kadar_air)}&min_kadar_fe=${check_value(params.min_kadar_fe)}&max_kadar_fe=${check_value(params.max_kadar_fe)}&min_si_mg_ratio=${check_value(params.min_si_mg_ratio)}&max_si_mg_ratio=${check_value(params.max_si_mg_ratio)}&quantity=${check_value(params.quantity)}&min_price=${check_value(params.min_price)}&max_price=${check_value(params.max_price)}&id_location=${check_id(params.id_location)}&request_start_laycan=${check_value(params.request_start_laycan)}&request_port=${check_value(params.request_port)}&request_end_laycan=${check_value(params.request_end_laycan)}&request_trader_id=${check_value(params.request_trader_id)}&kadar_ni_orderby=&kadar_air_orderby=&kadar_fe_orderby=&si_mg_ratio_orderby=&quantity_orderby=&price_orderby=&search_text=${check_value(params.search_text)}&currency=${currency}`, token)
};
export const searchBarge = async (params) => {
    return axios.get(`${config.baseURL}/api/search-product/tongkang/jual?size=10&page=${parseInt(params.page)}&ukuran_tongkang=${check_value(params.ukuran_tongkang)}&max_price=${check_value(params.max_price)}&min_price=${check_value(params.min_price)}&request_year=${check_value(params.request_year)}&search_text=${check_value(params.search_text)}&currency=${currency}`, token)

};

export const searchRentBarge = async (params) => {
    return axios.get(`${config.baseURL}/api/search-product/tongkang/menyewakan?size=10&page=${parseInt(params.page)}&ukuran_tongkang=${check_value(params.ukuran_tongkang)}&max_price=${check_value(params.max_price)}&min_price=${check_value(params.min_price)}&request_pelabuhan_muat=${check_value(params.request_pelabuhan_muat)}&request_pelabuhan_bongkar=${check_value(params.request_pelabuhan_bongkar)}&request_year=${check_value(params.request_year)}&search_text=${check_value(params.search_text)}&currency=${currency}`, token)

};


export const searchCompany = async (params) => {
    return axios.get(`${config.baseURL}/api/search-product/explore/perusahaan?size=10&page=${parseInt(params.page)}&min_total_listing=${check_value(params.min_total_listing)}&max_total_listing=${check_value(params.max_total_listing)}&min_total_staff=${check_value(params.min_total_staff)}&max_total_staff=${check_value(params.max_total_staff)}&bidang_usaha=${check_value(params.bidang_usaha)}&id_location=${check_id(params.id_location)}&name_company_orderby=asc&kontak_orderby=&bidang_usaha_orderby=&search_text=${check_value(params.search_text)}`, token)
};
export const searchTrader = async (params) => {
    return axios.get(`${config.baseURL}/api/search-product/explore/trader?size=10&page=${parseInt(params.page)}&company_id=${check_value(params.company_id)}&id_location=${check_id(params.id_location)}&search_text=${check_value(params.search_text)}`, token)

};




// Location 
export const getProvince = async (name) => {
    if (name === undefined) {
        name = ''
    }
    const data = await axios.get(`${config.baseURL}/api/locations?filters[name][$contains]=${name}`)
    return data
};

// Location Filter
export const getProvinceFilter = async (name, orderFilter) => {
    if (name === undefined) {
        name = ''
    }
    const data = await axios.get(`${config.baseURL}/api/locations?filters[name][$contains]=${name}&sort[0]=${orderFilter}`)
    return data
};

export const getCity = async (province_id) => {
    return axios.get(`${config.baseURL}/api/cities?filters[provinsi][id][$eq]=${province_id}&populate[0]=provinsi`)
};

export const getCityAll = async () => {
    return axios.get(`${config.baseURL}/api/cities`)
};

// Product
export const getProductList = async (data) => {
    return axios.get(`${config.baseURL}/api/search-product/${data}`, token)
};

// Port
export const getPortByLocation = async (params) => {
    return axios.get(`${config.baseURL}/api/ports?filters[name][$contains]=&filters[is_deleted][$eq]=false&pagination[page]=${parseInt(params.page)}&pagination[pageSize]=12&populate[0]=location`)
};
// Port Filter Provinsi
export const getPortFilterLocation = async (params) => {
    return axios.get(`${config.baseURL}/api/service-port/get-port?search_text=${check_value_empty(params.name)}&page=${params.page}&size=15&id_location=${check_value_empty(params.id_location)}`, token)
};


// Port
export const getPort = async () => {
    return axios.get(`${config.baseURL}/api/ports?populate[0]=location&filters[name][$contains]=`)
};

// Get Trader
export const getAllUserTrader = async (params) => {
    return axios.get(`${config.baseURL}/api/search-product/explore/trader?size=20&page=1&search_text=${params}`, token)
};

// Product List
export const getDetailBatubara = async (id, lang) => {
    let checkToken = localStorage.getItem("token")
    let data
    if (checkToken === null) {
        data = await axios.get(`${config.baseURL}/api/service-product/detail-batubara?id=${id}&lang=${lang}&currency=${currency}`)
    } else {
        data = await axios.get(`${config.baseURL}/api/service-product/detail-batubara?id=${id}&lang=${lang}&currency=${currency}`, token)
    }
    return data
};

export const getDetailNikel = async (id, lang) => {
    let checkToken = localStorage.getItem("token")
    let data
    if (checkToken === null) {
        data = await axios.get(`${config.baseURL}/api/service-product/detail-nikel?id=${id}&lang=${lang}&currency=${currency}`)
    } else {
        data = await axios.get(`${config.baseURL}/api/service-product/detail-nikel?id=${id}&lang=${lang}&currency=${currency}`, token)
    }
    return data
};

export const getDetailTongkang = async (id, lang) => {
    let checkToken = localStorage.getItem("token")
    let data
    if (checkToken === null) {
        data = await axios.get(`${config.baseURL}/api/service-product/detail-tongkang?id=${id}&lang=${lang}&currency=${currency}`)
    } else {
        data = await axios.get(`${config.baseURL}/api/service-product/detail-tongkang?id=${id}&lang=${lang}&currency=${currency}`, token)
    }
    return data
};

export const getDetailCompanyById = async (id, lang) => {
    return axios.get(`${config.baseURL}/api/service-company/detail?id=${id}&lang=${lang}`, token)
};

export const getDetailCompany = async (name, lang) => {
    return axios.get(`${config.baseURL}/api/service-company/detail-name?name=${name}&lang=${lang}`, token)
};

export const getDetailTrader = async (id, lang) => {
    return axios.get(`${config.baseURL}/api/service-user/detail-user?id_user=${id}&lang=${lang}`, token)
};

export const getAllCompany = async () => {
    return axios.get(`${config.baseURL}/api/service-company/list-perusahaan?search_name=&display_all=true`, token)
};

export const getCompanyListing = async () => {
    return axios.get(`${config.baseURL}/api/service-company/list-perusahaan?search_name=`, token)
};

export const getCompany = async () => {
    return axios.get(`${config.baseURL}/api/new-companies?filters[name][$contains]=`, token)
};

export const getSearchCompany = async (params) => {
    return axios.get(`${config.baseURL}/api/new-companies?filters[name][$contains]=${check_value_empty(params.name)}&filters[is_deleted][$eq]=0&filters[status][$eq]=Approve&populate=*&pagination[page]=${params.page}&pagination[pageSize]=10`, token)
};

export const getAllFlags = async (params) => {
    return axios.get(`${config.baseURL}/api/benderas?populate[0]=logo&filters[name][$contains]=&pagination[page]=1&pagination[pageSize]=100`)
};

export const postBatubara = async (params, file) => {
    let formData = new FormData();
    formData.append('data', JSON.stringify(params));
    for (let i = 0; i < file.length; i++) {
        formData.append('files.batubara_documents', file[i]);
    }
    let data
    await axios.post(`${config.baseURL}/api/batubara-products`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
    }).then((res) => {
        data = res
    }).catch((err) => {
        data = err.response.data
    })
    return data
};

export const postNikel = async (params, file) => {
    let formData = new FormData();
    formData.append('data', JSON.stringify(params));
    for (let i = 0; i < file.length; i++) {
        formData.append('files.nikel_documents', file[i]);
    }
    let data
    await axios.post(`${config.baseURL}/api/nikel-products`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
    }).then((res) => {
        data = res
    }).catch((err) => {
        data = err.response.data
    })
    return data
};

export const postTongkang = async (params, file, image) => {
    let formData = new FormData();
    formData.append('data', JSON.stringify(params));
    for (let i = 0; i < file.length; i++) {
        formData.append('files.tongkang_documents', file[i]);
    }
    for (let i = 0; i < image.length; i++) {
        formData.append('files.tongkang_pictures', image[i]);
    }
    let data
    await axios.post(`${config.baseURL}/api/tongkang-products`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
    }).then((res) => {
        data = res
    }).catch((err) => {
        data = err.response.data
    })
    return data
};


export const editBatubara = async (params, id) => {
    let formData = new FormData();
    formData.append('data', JSON.stringify(params));
    let data
    await axios.put(`${config.baseURL}/api/batubara-products/${id}`, formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            }
        }).then((res) => {
            data = res
        }).catch((err) => {
            data = err.response
        })
    return data
};

export const editNikel = async (params, id) => {
    let formData = new FormData();
    formData.append('data', JSON.stringify(params));
    let data
    await axios.put(`${config.baseURL}/api/nikel-products/${id}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
    }).then((res) => {
        data = res
    }).catch((err) => {
        data = err.response
    })
    return data
};

export const editTongkang = async (params, id, file, image) => {
    let formData = new FormData();
    formData.append('data', JSON.stringify(params));
    for (let i = 0; i < file.length; i++) {
        formData.append('files.tongkang_documents', file[i]);
    }
    for (let i = 0; i < image.length; i++) {
        formData.append('files.tongkang_pictures', image[i]);
    }
    let data
    await axios.put(`${config.baseURL}/api/tongkang-products/${id}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
    }).then((res) => {
        data = res
    }).catch((err) => {
        data = err.response
    })
    return data
};

export const deleteBatubara = async (id) => {
    let data
    await axios.delete(`${config.baseURL}/api/batubara-products/${id}`, token).then((res) => {
        data = res
    }).catch((err) => {
        data = err.response
    })
    return data
};

export const deleteNikel = async (id) => {
    let data
    await axios.delete(`${config.baseURL}/api/nikel-products/${id}`, token).then((res) => {
        data = res
    }).catch((err) => {
        data = err.response
    })
    return data
};

export const deleteTongkang = async (id) => {
    let data
    await axios.delete(`${config.baseURL}/api/tongkang-products/${id}`, token).then((res) => {
        data = res
    }).catch((err) => {
        data = err.response
    })
    return data
};

export const deleteCompany = async (id) => {
    let data
    await axios.delete(`${config.baseURL}/api/companies/${id}`, token).then((res) => {
        data = res
    }).catch((err) => {
        data = err.response
    })
    return data
};

// Company Service
export const getStaffCompany = async (params) => {
    let data
    await axios.get(`${config.baseURL}/api/service-company/staff?size=10&page=${params.page}&id_company=${params.id_company}&search_name_staff=${check_value(params.search_text)}`, token).then((res) => {
        data = res
    }).catch((err) => {
        data = err.response
    })
    return data
};

export const getBatubaraCompany = async (params) => {
    let data
    await axios.get(`${config.baseURL}/api/service-company/batubara?size=10&page=${params.page}&id_company=${params.id_company}&search_text_batubara=${check_value(params.search_text)}&currency=${currency}`, token).then((res) => {
        data = res
    }).catch((err) => {
        data = err.response
    })
    return data
};

export const getNikelCompany = async (params) => {
    let data
    await axios.get(`${config.baseURL}/api/service-company/nikel?size=10&page=${params.page}&id_company=${params.id_company}&search_text_nikel=${check_value(params.search_text)}&currency=${currency}`, token).then((res) => {
        data = res
    }).catch((err) => {
        data = err.response
    })
    return data
};

export const getTongkangCompany = async (params) => {
    let data
    await axios.get(`${config.baseURL}/api/service-company/tongkang?size=10&page=${params.page}&id_company=${params.id_company}&search_text_tongkang=${check_value(params.search_text)}&currency=${currency}`, token).then((res) => {
        data = res
    }).catch((err) => {
        data = err.response
    })
    return data
};

// Add Favorite
export const addFavorite = async (params) => {
    let data
    await axios.post(`${config.baseURL}/api/service-favorite`, params, token).then((res) => {
        data = res.data
    }).catch((err) => {
        data = undefined
    })
    return data
};

export const deleteFavorite = async (params) => {
    let data
    await axios.delete(`${config.baseURL}/api/service-favorite?type=${params.type_product}&id_relation=${params.id_product}`, token).then((res) => {
        data = res.data
    }).catch((err) => {
        data = undefined
    })
    return data
};

export const checkFavorite = async (params) => {
    let data
    await axios.get(`${config.baseURL}/api/service-favorite?type=${params.type_product}&id_relation=${params.id_product}`, token).then((res) => {
        data = res.data
    }).catch((err) => {
        data = undefined
    })
    return data
};

//Comments
export const getComment = async (params) => {
    return axios.get(`${config.baseURL}/api/comment-service/comment?size=5&page=${params.page}&type_product=${params.type_product}&id_product=${params.id_product}`, token)
};

export const getReply = async (params) => {
    return axios.get(`${config.baseURL}/api/comment-service/reply?size=2&page=${params.page}&id_comment=${params.id_comment}`, token)
};

export const addComment = async (params) => {
    let respone
    let tokenValue = localStorage.getItem("token")
    await axios.post(`${config.baseURL}/api/comments`, {
        "data": {
            value: params.value,
            type_product: params.type_product,
            id_product: params.id_product,
            token: tokenValue
        }
    }, token).then((res) => {
        respone = res
    }).catch((err) => {
        respone = err.response
    })
    return respone
};

export const addReply = async (params) => {
    let respone
    let tokenValue = localStorage.getItem("token")
    await axios.post(`${config.baseURL}/api/reply-comments`, {
        "data": {
            value: params.value,
            comment_id: params.comment_id,
            token: tokenValue
        }
    }, token).then((res) => {
        respone = res
    }).catch((err) => {
        respone = err.response
    })
    return respone
};

export const addLikeComment = async (params) => {
    let respone
    let tokenValue = localStorage.getItem("token")
    await axios.post(`${config.baseURL}/api/like-comments`, {
        "data": {
            comment_id: params.comment_id,
            token: tokenValue
        }
    }, token).then((res) => {
        respone = res
    }).catch((err) => {
        respone = err.response
    })
    return respone
};

export const deleteLikeComment = async (params) => {
    let respone
    await axios.delete(`${config.baseURL}/api/like-comments/${params.id}`, token).then((res) => {
        respone = res
    }).catch((err) => {
        respone = err.response
    })
    return respone
};

// Rekomendasi Perusahaan 
export const getCompanyRecomendations = async (params) => {
    let data
    await axios.get(`${config.baseURL}/api/new-companies?filters[bidang_perusahaan][$eq]=${check_value(params.bidang_perusahaan)}&populate=*`, token).then((res) => {
        data = res
    }).catch((err) => {
        data = err.response
    })
    return data
};

// Menu Detail Trader Favotire 
export const searchFavoriteCoal = async (params) => {
    return axios.get(`${config.baseURL}/api/service-user/favorite-batubara?size=${parseInt(params.page) * 10}&page=1&user_id=${check_value(params.user_id)}&max_calorie=${check_value(params.max_calorie)}&min_calorie=${check_value(params.min_calorie)}&max_total_sulfur=${check_value(params.max_total_sulfur)}&min_total_sulfur=${check_value(params.min_total_sulfur)}&min_ash=${check_value(params.min_ash)}&max_ash=${check_value(params.max_ash)}&quantity=${check_value(params.quantity)}&min_price=${check_value(params.min_price)}&max_price=${check_value(params.max_price)}&id_location=${check_id(params.id_location)}&calorie_orderby=&total_sulfur_orderby=&ash_orderby=&quantity_orderby=&price_orderby=&search_text=${check_value(params.search_text)}&currency=${currency}`, token)
};

export const searchFavoriteNickel = async (params) => {
    return axios.get(`${config.baseURL}/api/service-user/favorite-nikel?size=${parseInt(params.page) * 10}&page=1&user_id=${check_value(params.user_id)}&min_kadar_ni=${check_value(params.min_kadar_ni)}&max_kadar_ni=${check_value(params.max_kadar_ni)}&min_kadar_air=${check_value(params.min_kadar_air)}&max_kadar_air=${check_value(params.max_kadar_air)}&min_kadar_fe=${check_value(params.min_kadar_fe)}&max_kadar_fe=${check_value(params.max_kadar_fe)}&min_si_mg_ratio=${check_value(params.min_si_mg_ratio)}&max_si_mg_ratio=${check_value(params.max_si_mg_ratio)}&quantity=${check_value(params.quantity)}&min_price=${check_value(params.min_price)}&max_price=${check_value(params.max_price)}&id_location=${check_id(params.id_location)}&kadar_ni_orderby=&kadar_air_orderby=&kadar_fe_orderby=&si_mg_ratio_orderby=&quantity_orderby=&price_orderby=&search_text=${check_value(params.search_text)}&currency=${currency}`, token)
};
export const searchFavoriteBarge = async (params) => {
    return axios.get(`${config.baseURL}/api/service-user/favorite-tongkang?size=${parseInt(params.page) * 10}&page=1&user_id=${check_value(params.user_id)}&type_tongkang=${check_value(params.type_tongkang)}&ukuran_tongkang=${check_value(params.ukuran_tongkang)}&max_quantity_muatan=${check_value(params.max_quantity_muatan)}&min_quantity_muatan=${check_value(params.min_quantity_muatan)}&max_price=${check_value(params.max_price)}&min_price=${check_value(params.min_price)}&search_text=${check_value(params.search_text)}&label=${check_value(params.label)}&currency=${currency}`, token)
};

export const getUserDocument = async (params) => {
    let data
    await axios.get(`${config.baseURL}/api/service-user/user-documents?size=8&page=${params.page}&id_user=${params.id_user}&search_text=${params.search_text}`, token).then((res) => {
        data = res
    }).catch((err) => {
        data = err.response
    })
    return data
};

export const getUserCompany = async (params) => {
    let data
    await axios.get(`${config.baseURL}/api/service-user/user-company?size=5&page=${params.page}&id_user=${params.id_user}`, token).then((res) => {
        data = res
    }).catch((err) => {
        data = err.response
    })
    return data
};

//Get Bendera
export const getBenderaFlag = async (params) => {
    let data
    await axios.get(`${config.baseURL}/api/benderas?populate[0]=logo&filters[name][$contains]=${check_value_empty(params.name)}&pagination[page]=${check_value_empty(params.page)}&pagination[pageSize]=20`, token).then((res) => {
        data = res
    }).catch((err) => {
        data = err.response
    })
    return data
};

//Add Buat Permintaan
export const addBuatPermintaanBatubara = async (params, file) => {
    let respone
    let tokenValue = localStorage.getItem("token")
    let formData = new FormData();
    for (let i = 0; i < file.length; i++) {
        formData.append('files.batubara_documents', file[i]);
    }

    formData.append('data', JSON.stringify({
        calorie: check_null_value(params.calorie),
        total_sulfur: check_null_value(params.total_sulfur),
        ASH: check_null_value(params.ASH),
        quantity: check_null_value(params.quantity),
        price: check_null_value(params.price),
        id_loading_port: (params.id_loading_port),
        id_sumber_batu: params.id_company_listing,
        description: params.description,
        start_laycan_date: params.start_laycan_date,
        end_laycan_date: params.end_laycan_date,
        // id_user_trader_select: params.id_user_trader_select,
        // create_port: params.create_port,
        currency: params.currency,
        token: tokenValue
    }));

    await axios.post(`${config.baseURL}/api/buat-permintaan-batubaras`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${tokenValue}`
        }
    }).then((res) => {
        respone = res
    }).catch((err) => {
        respone = err.response
    })
    return respone
};

//Edit Buat Permintaan
export const editBuatPermintaanBatubara = async (params, file, id) => {
    let respone
    let tokenValue = localStorage.getItem("token")
    let formData = new FormData();
    for (let i = 0; i < file.length; i++) {
        formData.append('files.batubara_documents', file[i]);
    }

    formData.append('data', JSON.stringify({
        calorie: check_null_value(params.calorie),
        total_sulfur: check_null_value(params.total_sulfur),
        ASH: check_null_value(params.ASH),
        quantity: check_null_value(params.quantity),
        price: check_null_value(params.price),
        id_loading_port: (params.id_loading_port),
        id_sumber_batu: params.id_company_listing,
        description: params.description,
        start_laycan_date: params.start_laycan_date,
        end_laycan_date: params.end_laycan_date,
        currency: params.currency,
        token: tokenValue
    }));

    await axios.put(`${config.baseURL}/api/buat-permintaan-batubaras/${id}`, params, token).then((res) => {
        respone = res
    }).catch((err) => {
        respone = err.response
    })
    return respone
};

export const deletePermintaanBatubara = async (params,id) => {
    let data
    await axios.put(`${config.baseURL}/api/buat-permintaan-batubaras/${id}`,params, token).then((res) => {
        data = res.data
    }).catch((err) => {
        data = err.response.data
    })
    return data
};

export const addBuatPermintaanNikel = async (params, file) => {
    let respone
    let tokenValue = localStorage.getItem("token")
    let formData = new FormData();
    for (let i = 0; i < file.length; i++) {
        formData.append('files.nikel_documents', file[i]);
    }

    formData.append('data', JSON.stringify({
        kadar_ni: check_null_value(params.kadar_ni),
        kadar_fe: check_null_value(params.kadar_fe),
        kadar_air: check_null_value(params.kadar_air),
        si_mg_ratio: check_null_value(params.si_mg_ratio),
        quantity: check_null_value(params.quantity),
        price: check_null_value(params.price),
        id_loading_port: params.id_loading_port,
        id_sumber_batu: params.id_company_listing,
        description: params.description,
        start_laycan_date: params.start_laycan_date,
        end_laycan_date: params.end_laycan_date,
        // id_user_trader_select: params.id_user_trader_select,
        // create_port: params.create_port,
        currency: params.currency,
        token: tokenValue
    }));

    await axios.post(`${config.baseURL}/api/buat-permintaan-nikels`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${tokenValue}`
        }
    }).then((res) => {
        respone = res
    }).catch((err) => {
        respone = err.response
    })
    return respone
};



export const addBuatPermintaanTongkang = async (params, file) => {
    let respone
    let tokenValue = localStorage.getItem("token")
    let formData = new FormData();
    for (let i = 0; i < file.length; i++) {
        formData.append('files.tongkang_documents', file[i]);
    }

    formData.append('data', JSON.stringify({
        quantity_muatan: check_null_value(params.quantity_muatan),
        type_tongkang: check_null_value(params.type_tongkang),
        price: check_null_value(params.price),
        id_tongkang_location: params.id_tongkang_location,
        id_pelabuhan_muat: params.id_pelabuhan_muat,
        id_area_muat: params.id_area_muat,
        id_area_bongkar: params.id_area_bongkar,
        id_pelabuhan_bongkar: params.id_pelabuhan_bongkar,
        id_company_listing: params.id_company_listing,
        description: params.description,
        tahun_buat: check_null_value(params.tahun_buat),
        jenis_muatan: params.jenis_muatan,
        ukuran_tongkang: params.ukuran_tongkang,
        currency: params.currency,
        token: tokenValue
    }));

    await axios.post(`${config.baseURL}/api/buat-permintaan-tongkangs`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${tokenValue}`
        }
    }).then((res) => {
        respone = res
    }).catch((err) => {
        respone = err.response
    })
    return respone
};

export const getRealTimePrice = async () => {
    let data
    await axios.get(`${config.baseURL}/api/realtime-prices`).then((res) => {
        data = res
    }).catch((err) => {
        data = err.response
    })
    return data
};

export const getRealTimePricesLatest = async (params) => {
    let data
    await axios.get(`${config.baseURL}/api/service-ici-price/get-latest?currency=${params.currency}`).then((res) => {
        data = res
    }).catch((err) => {
        data = err.response
    })
    return data
};

export const getRealTimePriceLatestData = async (params) => {
    let data
    await axios.get(`${config.baseURL}/api/service-ici-price/get-data?page=${params.page}&size=${params.size}&currency=${params.currency}&short_name=${params.short_name}`).then((res) => {
        data = res
    }).catch((err) => {
        data = err.response
    })
    return data
};

export const getRealTimePriceAll = async (params) => {
    let data
    await axios.get(`${config.baseURL}/api/realtime-prices-all?currency=${params.currency}`).then((res) => {
        data = res
    }).catch((err) => {
        data = err.response
    })
    return data
};

export const GetValidationPriceQuantity = async (params) => {
    let data
    await axios.get(`${config.baseURL}/api/service-product/validation-range?currency=${params.currency}`).then((res) => {
        data = res
    }).catch((err) => {
        data = err.response
    })
    return data
};

export const getInformationAdmin = async () => {
    let data
    await axios.get(`${config.baseURL}/api/information-admin`).then((res) => {
        data = res
    }).catch((err) => {
        data = err.response
    })
    return data
};

//Company
export const createCompany = async (params) => {

    let data
    await axios.post(`${config.baseURL}/api/service-company/create-company`, params, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
    }).then((res) => {
        data = res
    }).catch((err) => {
        data = err.response.data
    })
    return data
};

export const invitedTraderToJoin = async (params) => {
    let data
    await axios.post(`${config.baseURL}/api/service-company/invited-join`, params, token).then((res) => {
        data = res.data
    }).catch((err) => {
        data = err.response.data
    })
    return data
};

export const RemoveStaff = async (params) => {
    let data
    await axios.post(`${config.baseURL}/api/service-company/remove-staff`, params, token).then((res) => {
        data = res.data
    }).catch((err) => {
        data = err.response.data
    })
    return data
};

export const RequestJoinCompany = async (params) => {
    let data
    await axios.post(`${config.baseURL}/api/service-company/request-join`, params, token).then((res) => {
        data = res.data
    }).catch((err) => {
        data = err.response.data
    })
    return data
};

export const RequestLeaveCompany = async (params) => {
    let data
    await axios.post(`${config.baseURL}/api/service-company/exit-company`, params, token).then((res) => {
        data = res.data
    }).catch((err) => {
        data = err.response.data
    })
    return data
};

export const GetAllRequestJoinbyIDCompany = async (params) => {
    let data
    await axios.get(`${config.baseURL}/api/service-company/get-all-request?page=${params.page}&size=10&id_company=${params.id_company}`, token).then((res) => {
        data = res.data
    }).catch((err) => {
        data = err.response.data
    })
    return data
};

export const ActionOwnerCompany = async (params) => {
    let data
    await axios.post(`${config.baseURL}/api/service-company/action-owner`, params, token).then((res) => {
        data = res.data
    }).catch((err) => {
        data = err.response.data
    })
    return data
};

export const getDireksiPosisi = async () => {
    let data
    await axios.get(`${config.baseURL}/api/service-company/company-direksi-posisis?lang=${language}`).then((res) => {
        data = res
    }).catch((err) => {
        data = err.response
    })
    return data
};

export const getJenisPerusahaan = async () => {
    let data
    await axios.get(`${config.baseURL}/api/service-company/company-jenis-perusahaans?lang=${language}`).then((res) => {
        data = res
    }).catch((err) => {
        data = err.response
    })
    return data
};

export const getJenisSaham = async () => {
    let data
    await axios.get(`${config.baseURL}/api/service-company/company-saham-jenis-sahams?lang=${language}`).then((res) => {
        data = res
    }).catch((err) => {
        data = err.response
    })
    return data
};

export const getLabelAlamat = async () => {
    let data
    await axios.get(`${config.baseURL}/api/service-company/company-address-labels?lang=${language}`).then((res) => {
        data = res
    }).catch((err) => {
        data = err.response
    })
    return data
};

export const createClaimCompany = async (params) => {
    let data
    await axios.post(`${config.baseURL}/api/service-company/claim-company`, params, token).then((res) => {
        data = res.data
    }).catch((err) => {
        data = err.response
    })
    return data
};

//Edit Perusahaan
export const UpdateCompany = async (params) => {
    let data
    await axios.put(`${config.baseURL}/api/service-company/update-company`, params, token).then((res) => {
        data = res.data
    }).catch((err) => {
        data = err.response.data
    })
    return data
};

export const UpdateCompanyInfo = async (params) => {
    let data
    await axios.put(`${config.baseURL}/api/service-company/update-company-info`, params, token).then((res) => {
        data = res.data
    }).catch((err) => {
        data = err.response.data
    })
    return data
};

export const UpdateCompanyAddress = async (params) => {
    let data
    await axios.put(`${config.baseURL}/api/service-company/update-company-address`, params, token).then((res) => {
        data = res.data
    }).catch((err) => {
        data = err.response.data
    })
    return data
};

export const UpdateCompanyDireksi = async (params) => {
    let data
    await axios.put(`${config.baseURL}/api/service-company/update-company-direksi`, params, token).then((res) => {
        data = res.data
    }).catch((err) => {
        data = err.response.data
    })
    return data
};

export const UpdateCompanySaham = async (params) => {
    let data
    await axios.put(`${config.baseURL}/api/service-company/update-company-saham`, params, token).then((res) => {
        data = res.data
    }).catch((err) => {
        data = err.response.data
    })
    return data
};

export const UpdateCompanyPerizinan = async (params) => {
    let data
    await axios.put(`${config.baseURL}/api/service-company/update-company-perizinan`, params, token).then((res) => {
        data = res.data
    }).catch((err) => {
        data = err.response.data
    })
    return data
};

export const deleteCompanyBackground = async (id) => {
    let data
    await axios.delete(`${config.baseURL}/api/service-company/delete-photo-background?id_company=${id}`, token).then((res) => {
        data = res.data
    }).catch((err) => {
        data = err.response.data
    })
    return data
};

export const actionTrader = async (params) => {
    let data
    await axios.post(`${config.baseURL}/api/service-company/action-trader`, params, token).then((res) => {
        data = res.data
    }).catch((err) => {
        data = err.response.data
    })
    return data
};

export const reportListing = async (params) => {
    let data
    await axios.post(`${config.baseURL}/api/report-lists`, params, token).then((res) => {
        data = res
    }).catch((err) => {
        data = err.response.data
    })
    return data
};

export const cancelClaimCompany = async (params) => {
    let data
    await axios.post(`${config.baseURL}/api/service-company/cancel-claim-company`, params, token).then((res) => {
        data = res.data
    }).catch((err) => {
        data = err.response.data
    })
    return data
};

export const uploadUserDocuments = async (params) => {
    let data
    await axios.post(`${config.baseURL}/api/service-user/upload-documents`, params, token).then((res) => {
        data = res
    }).catch((err) => {
        data = err.response.data
    })
    return data
};

export const getCurrency = async () => {
    return axios.get(`${config.baseURL}/api/currency`)
};

// Menu Explore 
export const searchExploreCoal = async (params) => {
    return axios.get(`${config.baseURL}/api/search-product/explore/batubara?size=10&page=${parseInt(params.page)}&max_calorie=${check_value(params.max_calorie)}&min_calorie=${check_value(params.min_calorie)}&max_total_sulfur=${check_value(params.max_total_sulfur)}&min_total_sulfur=${check_value(params.min_total_sulfur)}&min_ash=${check_value(params.min_ash)}&max_ash=${check_value(params.max_ash)}&quantity=${check_value(params.quantity)}&min_price=${check_value(params.min_price)}&max_price=${check_value(params.max_price)}&id_location=${check_id(params.id_location)}&request_start_laycan=${check_value(params.request_start_laycan)}&request_end_laycan=${check_value(params.request_end_laycan)}&request_trader_id=${check_value(params.request_trader_id)}&request_port=${check_value(params.request_port)}&calorie_orderby=&total_sulfur_orderby=&ash_orderby=&quantity_orderby=&price_orderby=&search_text=${check_value(params.search_text)}&currency=${currency}`, token)
};

export const searchExploreNickel = async (params) => {
    return axios.get(`${config.baseURL}/api/search-product/explore/nikel?size=10&page=${parseInt(params.page)}&min_kadar_ni=${check_value(params.min_kadar_ni)}&max_kadar_ni=${check_value(params.max_kadar_ni)}&min_kadar_air=${check_value(params.min_kadar_air)}&max_kadar_air=${check_value(params.max_kadar_air)}&min_kadar_fe=${check_value(params.min_kadar_fe)}&max_kadar_fe=${check_value(params.max_kadar_fe)}&min_si_mg_ratio=${check_value(params.min_si_mg_ratio)}&max_si_mg_ratio=${check_value(params.max_si_mg_ratio)}&quantity=${check_value(params.quantity)}&min_price=${check_value(params.min_price)}&max_price=${check_value(params.max_price)}&id_location=${check_id(params.id_location)}&request_start_laycan=${check_value(params.request_start_laycan)}&request_port=${check_value(params.request_port)}&request_end_laycan=${check_value(params.request_end_laycan)}&request_trader_id=${check_value(params.request_trader_id)}&kadar_ni_orderby=&kadar_air_orderby=&kadar_fe_orderby=&si_mg_ratio_orderby=&quantity_orderby=&price_orderby=&search_text=${check_value(params.search_text)}&currency=${currency}`, token)
};
export const searchExploreBarge = async (params) => {
    return axios.get(`${config.baseURL}/api/search-product/explore/tongkang?size=10&page=${parseInt(params.page)}&type_tongkang=${check_value(params.type_tongkang)}&ukuran_tongkang=${check_value(params.ukuran_tongkang)}&max_price=${check_value(params.max_price)}&min_price=${check_value(params.min_price)}&request_pelabuhan_muat=${check_value(params.request_pelabuhan_muat)}&request_pelabuhan_bongkar=${check_value(params.request_pelabuhan_bongkar)}&request_year=${check_value(params.request_year)}&search_text=${check_value(params.search_text)}&currency=${currency}&label=${params.label}`, token)

};

// Detail Permintaan 
export const getDetailPermintaanBatubara = async (id, lang) => {
    let checkToken = localStorage.getItem("token")
    let data
    if (checkToken === null) {
        data = await axios.get(`${config.baseURL}/api/service-permintaan/detail-batubara?id=${id}&lang=${lang}&currency=${currency}`)
    } else {
        data = await axios.get(`${config.baseURL}/api/service-permintaan/detail-batubara?id=${id}&lang=${lang}&currency=${currency}`, token)
    }
    return data
};

export const getDetailPermintaanNikel = async (id, lang) => {
    let checkToken = localStorage.getItem("token")
    let data
    if (checkToken === null) {
        data = await axios.get(`${config.baseURL}/api/service-permintaan/detail-nikel?id=${id}&lang=${lang}&currency=${currency}`)
    } else {
        data = await axios.get(`${config.baseURL}/api/service-permintaan/detail-nikel?id=${id}&lang=${lang}&currency=${currency}`, token)
    }
    return data
};

export const getDetailPermintaanTongkang = async (id, lang) => {
    let checkToken = localStorage.getItem("token")
    let data
    if (checkToken === null) {
        data = await axios.get(`${config.baseURL}/api/service-permintaan/detail-tongkang?id=${id}&lang=${lang}&currency=${currency}`)
    } else {
        data = await axios.get(`${config.baseURL}/api/service-permintaan/detail-tongkang?id=${id}&lang=${lang}&currency=${currency}`, token)
    }
    return data
};

export const getCompanySumberBatu = async (params) => {
    return axios.get(`${config.baseURL}/api/service-company/company-sumber-batu?search=${check_value_empty(params.name)}&page=${params.page}&size=15`, token)
};

export const getRandomCompany = async (params) => {
    return axios.get(`${config.baseURL}/api/service-company/random-company?page=${params.page}&size=10`, token)
};

// Get Information
export const getFooterInformation = async (params) => {
    return axios.get(`${config.baseURL}/api/information-admin`)
};