import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useTranslation } from "react-i18next";
import CompanySuccessIcon from "../assets/create-company-icon.svg";
export default function ModalSuccessCreateCompany(props) {
    const [translate,] = useTranslation("global");
    return (
        <div>
            <Modal
                open={props.open}
                onClose={props.close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '304px', sm: 343, md: 343, lg: 415, xl: 415 },
                    borderRadius: '8px',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: { xs: '16px', sm: '24px', md: '24px', lg: '24px', xl: '24px' },
                }}>
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <IconButton size="small" onClick={() => {
                            props.close();
                        }} >
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div style={{ justifyContent: 'center', display: 'flex' }}>
                        <img alt="hubungitrader-icon" style={{ width: '140px', height: '140px', }} src={CompanySuccessIcon}></img>
                    </div>
                    <Typography id="modal-modal-title" className='w-700' sx={{ fontSize: '20px', color: '#081130', textAlign: 'center', mt: '24px' }}>
                        {translate('status.successcreatecompany')}
                    </Typography>
                    <Typography id="modal-modal-description" className='w-500' sx={{ mt: '16px', color: '#56617B', fontSize: '16px', textAlign: 'center' }}>
                        {translate('status.successsubcreatecompany')}
                    </Typography>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: '32px' }}>
                        <Button
                            onClick={(e) => {
                                props.ok()
                            }}
                            size="large"
                            className="w-500"
                            variant="contained"
                            style={{
                                width: '186px',
                                background: '#2457B9',
                                textTransform: 'none',
                                paddingTop: '12px',
                                paddingBottom: '12px'
                            }}
                        >
                            OK
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}