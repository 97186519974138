import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, Grid, IconButton, Modal, Stack, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";

export default function ModalDetailPerizinan(props) {
    const [translate,] = useTranslation("global");
    return (
        <div>
            <Modal
                open={Boolean(props.open)}
                onClose={props.close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ zIndex: 100000 }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '304px', sm: 343, md: 343, lg: 500, xl: 500 },
                    borderRadius: '8px',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 0,
                    "&:focus": {
                        outline: "none"
                    },
                }}>
                    <Box sx={{ height: { xs: '400px', sm: '400px', md: '100%', lg: '100%', xl: '100%' }, overflowY: { xs: 'scroll', sm: 'scroll', md: 'hidden', lg: 'hidden', xl: 'hidden' } }}>
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ p: '20px' }}>
                            <Typography id="modal-modal-title" className='w-700' sx={{ fontSize: '18px', color: '##222525', textAlign: 'center', }}>
                                {translate('status.detailperizinan')}
                            </Typography>
                            <IconButton size="small" >
                                <CloseIcon onClick={() => {
                                    props.close();
                                }} />
                            </IconButton>
                        </Stack>

                        <Divider orientation="horizontal" sx={{ backgroundColor: '#E8EBF2' }} />
                        <Grid container direction={'row'}>
                            <Grid item lg={6}>
                                <Stack direction={'column'} sx={{ ml: '20px', mb: '24px', mt: '20px' }}>
                                    <Typography id="modal-modal-title" className='w-400' sx={{ flexGrow: 1, fontSize: '12px', color: '#56617B' }}>
                                        {translate('status.jenisperizinan')}
                                    </Typography>
                                    <Typography id="modal-modal-title" className='w-500' sx={{ fontSize: '14px', color: '#081130' }}>
                                        {props.detailPerizinan.nama_perizinan}
                                    </Typography>
                                </Stack>

                                <Stack direction={'column'} sx={{ ml: '20px', mb: '24px' }}>
                                    <Typography id="modal-modal-title" className='w-400' sx={{ fontSize: '12px', color: '#56617B' }}>
                                        {translate('status.tahapankegiatan')}
                                    </Typography>
                                    <Typography id="modal-modal-title" className='w-500' sx={{ fontSize: '14px', color: '#081130' }}>
                                        {props.detailPerizinan.tahap_kegiatan}
                                    </Typography>
                                </Stack>

                                <Stack direction={'column'} sx={{ ml: '20px', mb: '24px', mr: '137px' }}>
                                    <Typography id="modal-modal-title" className='w-400' sx={{ fontSize: '12px', color: '#56617B' }}>
                                        {translate('status.komoditas')}
                                    </Typography>
                                    <Typography id="modal-modal-title" className='w-500' sx={{ fontSize: '14px', color: '#081130' }}>
                                        {props.detailPerizinan.komoditas}
                                    </Typography>
                                </Stack>

                                <Stack direction={'column'} sx={{ ml: '20px', mb: '24px' }}>
                                    <Typography id="modal-modal-title" className='w-400' sx={{ fontSize: '12px', color: '#56617B' }}>
                                        {translate('status.tanggalmulai')}
                                    </Typography>
                                    {props.detailPerizinan.start_date && <Typography id="modal-modal-title" className='w-500' sx={{ fontSize: '14px', color: '#081130' }}>
                                        {new Intl.DateTimeFormat('id', { year: 'numeric', month: 'short', day: '2-digit' }).format(new Date(props.detailPerizinan.start_date))}
                                    </Typography>}
                                </Stack>

                                <Stack direction={'column'} sx={{ ml: '20px', mb: '24px' }}>
                                    <Typography id="modal-modal-title" className='w-400' sx={{ fontSize: '12px', color: '#56617B' }}>
                                        {translate('status.tahapancnc')}
                                    </Typography>
                                    <Typography id="modal-modal-title" className='w-500' sx={{ fontSize: '14px', color: '#081130' }}>
                                        {props.detailPerizinan.tahapan_cnc}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item lg={6}>
                                <Stack direction={'column'} sx={{ ml: '20px', mb: '24px', mt: '20px' }}>
                                    <Typography id="modal-modal-title" className='w-400' sx={{ flexGrow: 1, fontSize: '12px', color: '#56617B' }}>
                                        {translate('status.noperizinan')}
                                    </Typography>
                                    <Typography id="modal-modal-title" className='w-500' sx={{ fontSize: '14px', color: '#081130' }}>
                                        {props.detailPerizinan.nomor_perizinan}
                                    </Typography>
                                </Stack>

                                <Stack direction={'column'} sx={{ ml: '20px', mb: '24px' }}>
                                    <Typography id="modal-modal-title" className='w-400' sx={{ fontSize: '12px', color: '#56617B' }}>
                                        {translate('status.kodewiup')}
                                    </Typography>
                                    <Typography id="modal-modal-title" className='w-500' sx={{ fontSize: '14px', color: '#081130' }}>
                                        {props.detailPerizinan.kode_WIUP}
                                    </Typography>
                                </Stack>

                                <Stack direction={'column'} sx={{ ml: '20px', mb: '24px', mr: '137px' }}>
                                    <Typography id="modal-modal-title" className='w-400' sx={{ fontSize: '12px', color: '#56617B' }}>
                                        {translate('status.luas')}
                                    </Typography>
                                    <Typography id="modal-modal-title" className='w-500' sx={{ fontSize: '14px', color: '#081130' }}>
                                        {props.detailPerizinan.luas}
                                    </Typography>
                                </Stack>

                                <Stack direction={'column'} sx={{ ml: '20px', mb: '24px' }}>
                                    <Typography id="modal-modal-title" className='w-400' sx={{ fontSize: '12px', color: '#56617B' }}>
                                        {translate('status.tanggalberakhir')}
                                    </Typography>
                                    {props.detailPerizinan.end_date && <Typography id="modal-modal-title" className='w-500' sx={{ fontSize: '14px', color: '#081130' }}>
                                        {new Intl.DateTimeFormat('id', { year: 'numeric', month: 'short', day: '2-digit' }).format(new Date(props.detailPerizinan.end_date))}
                                    </Typography>}
                                </Stack>

                                <Stack direction={'column'} sx={{ ml: '20px', mb: '24px' }}>
                                    <Typography id="modal-modal-title" className='w-400' sx={{ fontSize: '12px', color: '#56617B' }}>
                                        {translate('status.lokasi')}
                                    </Typography>
                                    <Typography id="modal-modal-title" className='w-500' sx={{ fontSize: '14px', color: '#081130' }}>
                                        {props.detailPerizinan.detail_location}
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}