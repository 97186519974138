import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { config } from "../../services/constants";
import { simpleCaseCapitalize } from "../../services/primary";
import IconInfo from "../../assets/icon-info.png";

export default function MenuFormDetailCompany(props) {
  const [translate] = useTranslation("global");

  console.log(props)

  const [validationProfileImg, setValidationProfileImg] = useState(false);
  const [validationNIB, setValidationNIB] = useState(false);
  const [validationCompanyType, setValidationCompanyType] = useState(false);
  const [validationCompanyName, setValidationCompanyName] = useState(false);
  const [validationAlternativeName, setValidationAlternativeName] =
    useState(false);
  const [validationCompanyField, setValidationCompanyField] = useState(false);
  const [validationEmail, setValidationEmail] = useState(false);
  const [validationPhoneNumber, setValidationPhoneNumber] = useState(false);

  useEffect(() => {

    if (props.triggerValidation >= 1) {

      if (!props.nib) {
        setValidationNIB(true)
      }

      if (!props.idjenisperusahaan) {
        setValidationCompanyType(true)
      }

      if (!props.name) {
        setValidationCompanyName(true)
      }

      if (!props.alternative_name) {
        setValidationAlternativeName(true)
      }

      if (!props.selecttype) {
        setValidationCompanyField(true)
      }

      if (!props.phone_number) {
        setValidationPhoneNumber(true)
      }

      if (!props.email) {
        setValidationEmail(true)
      }
    }

  }, [props.triggerValidation])

  useEffect(() => {
    if (props.triggerValidation >= 1) {
      if (props.urlprofile === "" || !props.urlprofile) {
        setValidationProfileImg(true)
      } else {
        setValidationProfileImg(false)
      }
    }
  }, [props.triggerValidation, props.urlprofile])

  useEffect(() => {
    if (props.phone_number.includes("+62")) {
      let newFormat = props.phone_number.slice(3, props.phone_number.length)
      props.setPhone_number(newFormat)
    }
  }, [props.phone_number])

  return (
    <Grid item xs={12} sm={12} md={4} lg={4} className="form-input">
      <Box>
        <p className="c-08 s-1419 w-500 mb4 ">{translate("navbar.profile")}</p>
        <div>
          {props.urlprofile !== "" ? (
            <div
              style={{
                backgroundImage: `url(${config.baseURL + props.urlprofile})`,
                height: "140px",
                width: "140px",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                position: "relative",
                borderRadius: "1000px",
              }}
            ></div>
          ) : (
            <div
              style={{
                backgroundImage: `url(${props.DefaultProfileImg})`,
                height: "140px",
                width: "140px",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                position: "relative",
                borderRadius: "1000px",
              }}
            ></div>
          )}
          <label for="uploadImage">
            <img
              className="pointer"
              style={{ position: "relative", bottom: 40, right: -100 }}
              alt="Nikel-icon"
              src={props.UploadProfileIcon}
              height={36}
            ></img>
          </label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => props.handleChangeFileProfile(e)}
            id="uploadImage"
            style={{ display: "none" }}
          />
        </div>
      </Box>
      {validationProfileImg ? (
        <Box sx={{ display: "flex", alignItems: "center", marginTop: "-5px", marginBottom: "30px" }}>
          <img alt="Nikel-icon" src={IconInfo} height={20}></img>
          <p className="s-1419 w-500" style={{ marginLeft: "5px", color: "#D32F2F" }}>
            {translate("title.validation_profile")}
          </p>
        </Box>
      ) : (
        <></>
      )}
      <p className="c-08 s-1419 w-500 mb4">{translate("status.nib")}</p>
      <TextField
        type="number"
        className="w-400 s-1420"
        error={validationNIB}
        placeholder={translate("status.placeholdernib")}
        value={props.nib}
        onChange={(e) => {
          if (e.target.value && e.target.value !== "") {
            props.setnib(e.target.value);
            setValidationNIB(false);
          } else {
            props.setnib(e.target.value);
            setValidationNIB(true);
          }
        }}
        sx={{
          width: "100%",
          mb: "24px",
          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
          {
            display: "none",
          },
          "& input[type=number]": {
            MozAppearance: "textfield",
          },
          input: {
            fontSize: "14px",
            fontFamily: "Satoshi-500",
          },
        }}
      />
      <Grid
        container
        justifyContent="space-between"
        direction="row"
        spacing={2}
      >
        <Grid item xs={12} lg={6}>
          <p className="c-08 s-1419 w-500 mb4">
            {translate("status.jenisperusahaan")}
          </p>
          <Select
            IconComponent={() => (
              <KeyboardArrowDownIcon
                sx={{
                  mr: "16px",
                  width: "20px",
                  height: "20px",
                  fill: "#56617B",
                }}
              />
            )}
            MenuProps={{
              PaperProps: {
                sx: {
                  height: "352px",
                  "& .MuiMenuItem-root": {},
                },
              },
            }}
            sx={{
              border: "1px solid var(--grey-stroke, #e8ebf2)",
              borderRadius: "8px",
              width: "100%",
            }}
            error={validationCompanyType}
            value={props.idjenisperusahaan}
            onChange={(e) => {
              props.setIdPerusahaan(e.target.value);
              setValidationCompanyType(false);
            }}
            displayEmpty
          >
            <MenuItem value="" style={{ display: "none" }}>
              <p className="w-400 s-1420 c-87">
                {translate("status.jenisperusahaan")}
              </p>
            </MenuItem>
            {props.jenisperusahaan.map((item, i) => (
              <MenuItem key={item.id} value={item.attributes.value}>
                {item.attributes.value}{" "}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} lg={6}>
          <p className="c-08 s-1419 w-500 mb4">
            {translate("page_explore.namaperusahaan")}
          </p>
          <TextField
            className="w-400 s-1420"
            placeholder={translate("status.placeholder-nama-perusahaan")}
            value={props.name}
            error={validationCompanyName}
            onChange={(e) => {
              if (e.target.value && e.target.value !== "") {
                let data = simpleCaseCapitalize(e.target.value);
                props.setName(data);
                setValidationCompanyName(false);
              } else {
                let data = simpleCaseCapitalize(e.target.value);
                props.setName(data);
                setValidationCompanyName(true);
              }
            }}
            sx={{
              width: "100%",
              mb: "24px",
              input: {
                fontSize: "14px",
                fontFamily: "Satoshi-500",
              },
            }}
          />
        </Grid>
      </Grid>

      <Box
        sx={{
          mb: "24px",
        }}
      >
        <p className="c-08 s-1419 w-500 mb4">Alternative name</p>
        <TextField
          sx={{
            width: "100%",
            input: {
              fontSize: "14px",
              fontFamily: "Satoshi-500",
            },
          }}
          placeholder={`${translate(
            "status.placeholder-nama-alternative"
          )}, ${translate("status.info-nama-alternative")}`}
          value={props.alternative_name}
          error={validationAlternativeName}
          onChange={(e) => {
            if (e.target.value && e.target.value !== "") {
              props.setalternativename(e.target.value)
              setValidationAlternativeName(false);
            } else {
              props.setalternativename(e.target.value)
              setValidationAlternativeName(true);
            }
          }}
        />
        {/* <div style={{ display: "flex", color: "#56617b", alignItems: 'center' }}>
          <InfoOutlinedIcon color='disabled' sx={{ fontSize: 20, marginRight: "5px" }} />
          <p style={{ fontSize: 13, color: "rgba(0, 0, 0, 0.40)" }} className='w-500'>{translate('status.info-nama-alternative')}</p>
        </div> */}
      </Box>
      <Box>
        <p className="c-08 s-1419 w-500 mb4 mt-10">
          {translate("page_explore.bidangperusahan")}
        </p>
        <Stack
          direction={{ xs: "column", sm: "column", md: "row" }}
          className="box-group-select"
        >
          {props.type_list.map((item, i) => (
            <FormControl
              className={
                JSON.stringify(props.selecttype).includes(
                  item.bidang_perusahaan
                )
                  ? "form-radio-perusahaan-select"
                  : "form-radio-perusahaan"
              }
            >
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
              >
                <FormControlLabel
                  key={i}
                  onChange={(e) => {
                    props.handleChangeBidangPerusahaan(e, i)
                    setValidationCompanyField(false)
                  }}
                  value={item.bidang_perusahaan}
                  control={
                    <Radio
                      checked={JSON.stringify(props.selecttype).includes(
                        item.bidang_perusahaan
                      )}
                      sx={{
                        color: "#E8EBF2",

                        "&.Mui-checked": { color: "#3BB56C" },
                      }}
                    />
                  }
                  label={
                    <Typography
                      className="w-400"
                      sx={{ fontSize: 14, color: "#081130", marginBottom: "5px" }}
                    >
                      {item.label}
                    </Typography>
                  }
                  className="w-500 s-1419"
                />
              </RadioGroup>
            </FormControl>
          ))}
        </Stack>
        {validationCompanyField ? (
          <Box sx={{ display: "flex", alignItems: "center", marginTop: "-5px", marginBottom: "30px" }}>
            <img alt="Nikel-icon" src={IconInfo} height={20}></img>
            <p className="s-1419 w-500" style={{ marginLeft: "5px", color: "#D32F2F" }}>
              {translate("title.validation")}
            </p>
          </Box>
        ) : (
          <></>
        )}
      </Box>
      <Grid container justifyContent={"space-between"} spacing={2}>
        <Grid item xs={12} lg={6}>
          <Box>
            <p className="c-08 s-1419 w-500 mb4">
              {translate("profile.phone")}
            </p>
            <TextField
              sx={{
                width: "100%",
                mb: "24px",
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                {
                  display: "none",
                },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
                input: {
                  fontSize: "14px",
                  fontFamily: "Satoshi-500",
                },
              }}
              type="number"
              placeholder="xxxx-xxxx-xxxx"
              value={props.phone_number}
              error={validationPhoneNumber}
              onChange={(e) => {
                if (e.target.value && e.target.value !== "") {
                  props.setPhone_number(e.target.value)
                  setValidationPhoneNumber(false);
                } else {
                  props.setPhone_number(e.target.value)
                  setValidationPhoneNumber(true);
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+62</InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box>
            <p className="c-08 s-1419 w-500 mb4">Email</p>
            <TextField
              sx={{
                width: "100%",
                mb: "24px",
                input: {
                  fontSize: "14px",
                  fontFamily: "Satoshi-500",
                },
              }}
              placeholder={translate("status.placeholder-email-company")}
              value={props.email}
              // onChange={(e) => props.setEmail(e.target.value)}
              error={validationEmail}
              onChange={(e) => {
                if (e.target.value && e.target.value !== "") {
                  props.setEmail(e.target.value)
                  setValidationEmail(false);
                } else {
                  props.setEmail(e.target.value)
                  setValidationEmail(true);
                }
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Box>
        <p className="c-08 s-1419 w-500 mb4">Website (optional)</p>
        <TextField
          sx={{
            width: "100%",
            mb: "24px",
            input: {
              fontSize: "14px",
              fontFamily: "Satoshi-500",
            },
          }}
          placeholder="www.website.com"
          value={props.website}
          onChange={(e) => props.setWebsite(e.target.value)}
        />
      </Box>

      <Box>
        <p className="c-08 s-1419 w-500 mb4 ">
          {translate("detail-explore.deskripsi")} (optional)
        </p>
        <TextField
          className="box-deskripsi-company"
          multiline
          rows={3}
          onChange={(e) => props.setDesc(e.target.value)}
          value={props.description}
          sx={{
            width: "100%",
            p: 0,
            input: {
              fontSize: "14px",
              fontFamily: "Satoshi-500",
            },
          }}
          placeholder={translate("status.placeholder-deskripsi-perusahaan")}
        />
      </Box>
    </Grid>
  );
}
