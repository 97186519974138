import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Button, FormControl, Grid, InputAdornment, MenuItem, Select, TextField, Typography } from "@mui/material";
import React from 'react';
import { useTranslation } from "react-i18next";
import { GlobalState } from "../services/global";
import { VerticalDividerLeft } from '../services/primary';

export default function MenuFilterTongkang(props) {
  const [translate,] = useTranslation("global");
  const { currency } = React.useContext(GlobalState)


  return (
    <Box>
      <Box sx={{ border: 1, color: '#E8EBF2', borderRadius: 2, }}>
        <Grid
          container
          direction="row"
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{ pl: '16px', pt: '19px', pr: '16px', pb: '19px' }}
        >
          <Typography className="w-700" sx={{ fontSize: 18, color: '#081130' }}>
            Filter
          </Typography>
          <Button className="w-500" onClick={(e) => props.resetFilterTongkang(e)} sx={{ fontSize: 16, color: '#56617B', textTransform: 'none' }} variant="text"> Reset filter</Button>
        </Grid>

        <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
          <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '4px' }}>
            {translate('page_explore.tongkang')}
          </Typography>
          <Grid
            container
            direction={{ xs: 'column', sm: 'column', md: 'row' }}
            spacing={1}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
              <Button onClick={(e) => props.handleFilterTongkang("", "type_tongkang")}
                value={props.filterTongkang["type_tongkang"]} sx={{
                  "&:hover": {
                    borderColor: props.filterTongkang["type_tongkang"] === '' ? '#3BB56C' : '#E8EBF2',
                  },
                  textTransform: 'none', borderColor: props.filterTongkang["type_tongkang"] === '' ? '#3BB56C' : '#E8EBF2',
                  color: props.filterTongkang["type_tongkang"] === '' ? '#3BB56C' : 'black', justifyContent: 'left', width: '100%', borderRadius: '8px', p: '14px 12px'
                }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterTongkang["type_tongkang"] === '' ? '#3BB56C' : '#E8EBF2' }} />}>
                {translate('page_explore.semua')}
              </Button>
            </Grid>
            <Grid sx={{ mt: { xs: '8px', sm: '8px', md: '8px', lg: '8px', xl: 0 } }} item xs={12} sm={12} md={12} lg={12} xl={6}>
              <Button onClick={(e) => props.handleFilterTongkang("1", "type_tongkang")}
                value={props.filterTongkang["type_tongkang"]} sx={{
                  "&:hover": {
                    borderColor: props.filterTongkang["type_tongkang"] === '1' || props.filterTongkang["type_tongkang"] === 1 ? '#3BB56C' : '#E8EBF2',
                  }, textTransform: 'none', borderColor: props.filterTongkang["type_tongkang"] === '1' || props.filterTongkang["type_tongkang"] === 1 ? '#3BB56C' : '#E8EBF2', color: props.filterTongkang["type_tongkang"] === '1' || props.filterTongkang["type_tongkang"] === 1 ? '#3BB56C' : 'black', justifyContent: 'left', width: '100%', borderRadius: '8px', p: '14px 12px'
                }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterTongkang["type_tongkang"] === '1' || props.filterTongkang["type_tongkang"] === 1 ? '#3BB56C' : '#E8EBF2', }} />}>
                {translate('page_explore.penjualan')}
              </Button>
            </Grid>

          </Grid>
          <Grid
            container
            direction="row"
            justifyContent={'space-between'}
            sx={{ mt: '8px' }}
            spacing={1}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
              <Button onClick={(e) => { props.handleFilterTongkang("2", "type_tongkang"); props.handleFilterSewaTongkang("time", "tipe_sewa") }}
                value={props.filterTongkang["type_tongkang"]} sx={{
                  "&:hover": {
                    borderColor: props.filterTongkang["type_tongkang"] === '2' || props.filterTongkang["type_tongkang"] === '3' || props.filterTongkang["type_tongkang"] === 2 || props.filterTongkang["type_tongkang"] === 3 ? '#3BB56C' : '#E8EBF2',
                  }, textTransform: 'none', justifyContent: 'left', borderColor: props.filterTongkang["type_tongkang"] === '2' || props.filterTongkang["type_tongkang"] === '3' || props.filterTongkang["type_tongkang"] === 2 || props.filterTongkang["type_tongkang"] === 3 ? '#3BB56C' : '#E8EBF2', color: props.filterTongkang["type_tongkang"] === '2' || props.filterTongkang["type_tongkang"] === '3' || props.filterTongkang["type_tongkang"] === 2 || props.filterTongkang["type_tongkang"] === 3 ? '#3BB56C' : 'black', width: '100%', borderRadius: '8px', p: '14px 12px'
                }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterTongkang["type_tongkang"] === '2' || props.filterTongkang["type_tongkang"] === '3' || props.filterTongkang["type_tongkang"] === 2 || props.filterTongkang["type_tongkang"] === 3 ? '#3BB56C' : '#E8EBF2', }} />}>
                {translate('page_explore.penyewaan')}
              </Button>
            </Grid>
            {props.page === 'explore' && <Grid sx={{ mt: { xs: '8px', sm: '8px', md: '8px', lg: '8px', xl: 0 } }} item xs={12} sm={12} md={12} lg={12} xl={6}>
              <Button onClick={(e) => props.handleFilterTongkang("4", "type_tongkang")}
                value={props.filterTongkang["type_tongkang"]} sx={{
                  "&:hover": {
                    borderColor: props.filterTongkang["type_tongkang"] === '4' || props.filterTongkang["type_tongkang"] === 4 ? '#3BB56C' : '#E8EBF2',
                  }, textTransform: 'none', borderColor: props.filterTongkang["type_tongkang"] === '4' || props.filterTongkang["type_tongkang"] === 4 ? '#3BB56C' : '#E8EBF2', color: props.filterTongkang["type_tongkang"] === '4' || props.filterTongkang["type_tongkang"] === 4 ? '#3BB56C' : 'black', justifyContent: 'left', width: '100%', borderRadius: '8px', p: '14px 12px'
                }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterTongkang["type_tongkang"] === '4' || props.filterTongkang["type_tongkang"] === 4 ? '#3BB56C' : '#E8EBF2', }} />}>
                {translate('page_explore.pembelian')}
              </Button>
            </Grid>}
          </Grid>
        </Box>
        {props.filterTongkang["type_tongkang"] === '2' || props.filterTongkang["type_tongkang"] === '3' || props.filterTongkang["type_tongkang"] === 2 || props.filterTongkang["type_tongkang"] === 3 ? <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
          <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
            {translate('permintaan.tipe-sewa')}
          </Typography>
          <Grid
            container
            direction="column"
            justifyContent={'space-between'}
            sx={{ mt: 1, pl: 2, pb: 2 }}
            spacing={2}
          >

            <Button onClick={(e) => {
              props.handleFilterSewaTongkang("time", "tipe_sewa")
              props.handleFilterTongkang("2", "type_tongkang")
            }}
              value={props.filterSewaTongkang["tipe_sewa"]} sx={{
                "&:hover": {
                  borderColor: props.filterSewaTongkang["tipe_sewa"] === 'time' ? '#3BB56C' : '#E8EBF2',
                }, textTransform: 'none', justifyContent: 'left', borderColor: props.filterSewaTongkang["tipe_sewa"] === 'time' ? '#3BB56C' : '#E8EBF2', color: props.filterSewaTongkang["tipe_sewa"] === 'time' ? '#3BB56C' : 'black', mb: 1, borderRadius: '8px', p: '14px 12px'
              }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterSewaTongkang["tipe_sewa"] === 'time' ? '#3BB56C' : '#E8EBF2', }} />}>
              Time charter
            </Button>
            <Button onClick={(e) => {
              props.handleFilterSewaTongkang("freight", "tipe_sewa")
              props.handleFilterTongkang("3", "type_tongkang")
            }}
              value={props.filterSewaTongkang["tipe_sewa"]} sx={{
                "&:hover": {
                  borderColor: props.filterSewaTongkang["tipe_sewa"] === 'freight' ? '#3BB56C' : '#E8EBF2',
                }, textTransform: 'none', justifyContent: 'left', borderColor: props.filterSewaTongkang["tipe_sewa"] === 'freight' ? '#3BB56C' : '#E8EBF2', color: props.filterSewaTongkang["tipe_sewa"] === 'freight' ? '#3BB56C' : 'black', borderRadius: '8px', p: '14px 12px'
              }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterSewaTongkang["tipe_sewa"] === 'freight' ? '#3BB56C' : '#E8EBF2', }} />}>
              Freight charter
            </Button>
          </Grid>
        </Box> : ''}

        {props.page === 'explore' && <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
          <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '4px' }}>
            Label
          </Typography>
          <Grid
            container
            direction={{ xs: 'column', sm: 'column', md: 'row' }}
            spacing={1}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
              <Button onClick={(e) => props.handleFilterTongkang("", "label")}
                value={props.filterTongkang["label"]} sx={{
                  "&:hover": {
                    borderColor: props.filterTongkang["label"] === '' ? '#3BB56C' : '#E8EBF2',
                  },
                  textTransform: 'none', borderColor: props.filterTongkang["label"] === '' ? '#3BB56C' : '#E8EBF2',
                  color: props.filterTongkang["label"] === '' ? '#3BB56C' : 'black', justifyContent: 'left', width: '100%', borderRadius: '8px', p: '14px 12px'
                }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterTongkang["label"] === '' ? '#3BB56C' : '#E8EBF2' }} />}>
                {translate('page_explore.semua')}
              </Button>
            </Grid>
            <Grid sx={{ mt: { xs: '8px', sm: '8px', md: '8px', lg: '8px', xl: 0 } }} item xs={12} sm={12} md={12} lg={12} xl={6}>
              <Button onClick={(e) => props.handleFilterTongkang("penawaran", "label")}
                value={props.filterTongkang["label"]} sx={{
                  "&:hover": {
                    borderColor: props.filterTongkang["label"] === 'penawaran' ? '#3BB56C' : '#E8EBF2',
                  }, textTransform: 'none', borderColor: props.filterTongkang["label"] === 'penawaran' ? '#3BB56C' : '#E8EBF2', color: props.filterTongkang["label"] === 'penawaran' ? '#3BB56C' : 'black', justifyContent: 'left', width: '100%', borderRadius: '8px', p: '14px 12px'
                }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterTongkang["label"] === 'penawaran' ? '#3BB56C' : '#E8EBF2', }} />}>
                {translate('navbar.penawaran')}
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent={'space-between'}
            sx={{ mt: '8px' }}
            spacing={1}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
              <Button onClick={(e) => { props.handleFilterTongkang("permintaan", "label"); }}
                value={props.filterTongkang["label"]} sx={{
                  "&:hover": {
                    borderColor: props.filterTongkang["label"] === 'permintaan' ? '#3BB56C' : '#E8EBF2',
                  }, textTransform: 'none', justifyContent: 'left', borderColor: props.filterTongkang["label"] === 'permintaan' ? '#3BB56C' : '#E8EBF2', color: props.filterTongkang["label"] === 'permintaan' ? '#3BB56C' : 'black', width: '100%', borderRadius: '8px', p: '14px 12px'
                }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterTongkang["label"] === 'permintaan' ? '#3BB56C' : '#E8EBF2', }} />}>
                {translate('navbar.permintaan')}
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
            </Grid>
          </Grid>
        </Box>}

        <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
          <Typography className="w-500" sx={{ fontSize: 16, color: '#081130', mb: '2px' }}>
            {translate('page_explore.spesifikasi')}
          </Typography>
          <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
            {translate('page_explore.ukurantongkang')}
          </Typography>
          <FormControl sx={{ mt: 1, width: '100%' }}>
            <Select
              IconComponent={() => (
                <KeyboardArrowDownIcon sx={{ mr: '16px', width: '20px', height: '20px', fill: '#56617B' }} />
              )}
              style={{ border: '1px solid var(--grey-stroke, #e8ebf2)', marginTop: '4px', borderRadius: '8px', }}
              onChange={(e) => props.handleFilterTongkang(e.target.value, "ukuran_tongkang")}
              value={props.filterTongkang["ukuran_tongkang"]}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="">
                <Typography className="w-400" style={{ color: '#8792AB', fontSize: 14 }}> {translate('page_explore.pilihukurantongkang')}</Typography>
              </MenuItem>
              <MenuItem value={"180 FT"}>180 feet</MenuItem>
              <MenuItem value={"200 FT"}>200 feet</MenuItem>
              <MenuItem value={"230 FT"}>230 feet</MenuItem>
              <MenuItem value={"250 FT"}>250 feet</MenuItem>
              <MenuItem value={"300 FT"}>300 feet</MenuItem>
              <MenuItem value={"310 FT"}>310 feet</MenuItem>
              <MenuItem value={"320 FT"}>320 feet</MenuItem>
              <MenuItem value={"330 FT"}>330 feet</MenuItem>
              <MenuItem value={0}>{translate('status.lainnya')}</MenuItem>
            </Select>
            {props.inputukurangtongkang === true ? <TextField
              className="box-input-filter"
              type="number"
              size="small"
              placeholder={translate('status.placeholderukurantongkang')}
              onChange={(e) => props.handleFilterUkuranTongkang(e.target.value, "ukuran_tongkang")}
              value={props.filterTongkang["ukuran_tongkang"]}
              sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                  display: "none",
                },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
                width: '100%', input: {
                  fontSize: '14px', fontFamily: 'Satoshi-500'
                },
              }}
            /> : ''}
          </FormControl>
        </Box>

        {/* <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
          <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '6px' }}>
            {translate('page_explore.quantitymuatan')}
          </Typography>
          <Grid
            container
            direction="row"
            justifyContent={'space-between'}
            spacing={2}
          >
            <Grid item xs={6}>
              <TextField
                className="box-input-filter"
                type="number"
                size="small"
                placeholder="Min"
                onChange={(e) => props.handleFilterTongkang(e.target.value, "min_quantity_muatan")}
                value={props.filterTongkang["min_quantity_muatan"]}
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                    display: "none",
                  },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                  width: '100%', input: {
                    fontSize: '14px', fontFamily: 'Satoshi-500'
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="box-input-filter"
                type="number"
                size="small"
                placeholder="Maks"
                onChange={(e) => props.handleFilterTongkang(e.target.value, "max_quantity_muatan")}
                value={props.filterTongkang["max_quantity_muatan"]}
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                    display: "none",
                  },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                  width: '100%', input: {
                    color: '#8792AB', fontSize: '14px', fontFamily: 'Satoshi-500'
                  },
                }}
              />
            </Grid>
          </Grid>
        </Box> */}

        <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
          <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
            {translate('page_explore.hargatongkang')}
          </Typography>
          <TextField
            className="box-input-filter"
            type="number"
            size="small"
            placeholder={translate('status.min-harga')}
            onChange={(e) => props.handleFilterTongkang(e.target.value, "min_price")}
            value={props.filterTongkang["min_price"]}
            sx={{
              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                display: "none",
              },
              "& input[type=number]": {
                MozAppearance: "textfield",
              },
              width: '100%', input: {
                fontSize: '14px', fontFamily: 'Satoshi-500'
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <VerticalDividerLeft sx={{ ml: '12px' }} />
                  {currency === 'idr' && <Typography className="w-700" sx={{ fontSize: 14, color: '#56617B', mr: '24px' }}>
                    Rp
                  </Typography>}
                  {currency === 'usd' && <Typography className="w-700" sx={{ fontSize: 16, color: '#56617B', mr: '24px' }}>
                    $
                  </Typography>}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            className="box-input-filter"
            type="number"
            size="small"
            placeholder={translate('status.max-harga')}
            onChange={(e) => props.handleFilterTongkang(e.target.value, "max_price")}
            value={props.filterTongkang["max_price"]}
            sx={{
              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                display: "none",
              },
              "& input[type=number]": {
                MozAppearance: "textfield",
              },
              width: '100%', input: {
                fontSize: '14px', fontFamily: 'Satoshi-500'
              }, mt: 1
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <VerticalDividerLeft sx={{ ml: '12px' }} />
                  {currency === 'idr' && <Typography className="w-700" sx={{ fontSize: 14, color: '#56617B', mr: '24px' }}>
                    Rp
                  </Typography>}
                  {currency === 'usd' && <Typography className="w-700" sx={{ fontSize: 16, color: '#56617B', mr: '24px' }}>
                    $
                  </Typography>}
                </InputAdornment>
              ),
            }}
          />
        </Box>

        {props.page === 'listing' && <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
          <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
            Listing status
          </Typography>
          <Grid
            container
            // sx={{ mt: 1 }}
            direction={{ xs: 'column', sm: 'column', md: 'row' }}
            justifyContent={'space-between'}
            spacing={1}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
              <Button className="w-500" onClick={(e) => props.handleFilterTongkang("", "id_listing_status")}
                value={props.filterTongkang["id_listing_status"]} sx={{
                  lineHeight: 'normal',
                  "&:hover": {
                    borderColor: props.filterTongkang["id_listing_status"] === '' ? '#3BB56C' : '#E8EBF2',
                  }, textTransform: 'none', justifyContent: 'left', borderColor: props.filterTongkang["id_listing_status"] === '' ? '#3BB56C' : '#E8EBF2', color: props.filterTongkang["id_listing_status"] === '' ? '#3BB56C' : 'black', borderRadius: '8px', p: '14px 16px'
                }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterTongkang["id_listing_status"] === '' ? '#3BB56C' : '#E8EBF2', }} />}>
                {translate('my-listing.btn-all')}
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
              <Button className="w-500" onClick={(e) => props.handleFilterTongkang("1", "id_listing_status")}
                value={props.filterTongkang["id_listing_status"]} sx={{
                  lineHeight: 'normal',
                  "&:hover": {
                    borderColor: props.filterTongkang["id_listing_status"] === '1' ? '#3BB56C' : '#E8EBF2',
                  }, textTransform: 'none', borderColor: props.filterTongkang["id_listing_status"] === '1' ? '#3BB56C' : '#E8EBF2', justifyContent: 'left', color: props.filterTongkang["id_listing_status"] === '1' ? '#3BB56C' : 'black', borderRadius: '8px', p: '14px 16px'
                }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterTongkang["id_listing_status"] === '1' ? '#3BB56C' : '#E8EBF2', }} />}>
                {translate('my-listing.btn-publish')}
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            direction={{ xs: 'column', sm: 'column', md: 'row' }}
            justifyContent={'space-between'}
            sx={{ mt: '2px' }}
            spacing={1}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
              <Button className="w-500" onClick={(e) => props.handleFilterTongkang("2", "id_listing_status")}
                value={props.filterTongkang["id_listing_status"]} sx={{
                  lineHeight: 'normal',
                  "&:hover": {
                    borderColor: props.filterTongkang["id_listing_status"] === '2' ? '#3BB56C' : '#E8EBF2',
                  }, textTransform: 'none', justifyContent: 'left', borderColor: props.filterTongkang["id_listing_status"] === '2' ? '#3BB56C' : '#E8EBF2', color: props.filterTongkang["id_listing_status"] === '2' ? '#3BB56C' : 'black', borderRadius: '8px', p: '14px 16px'
                }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterTongkang["id_listing_status"] === '2' ? '#3BB56C' : '#E8EBF2', }} />}>
                {translate('my-listing.btn-unpublish')}
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
              <Button className="w-500" onClick={(e) => props.handleFilterTongkang("3", "id_listing_status")}
                value={props.filterTongkang["id_listing_status"]} sx={{
                  lineHeight: 'normal',
                  "&:hover": {
                    borderColor: props.filterTongkang["id_listing_status"] === '3' ? '#3BB56C' : '#E8EBF2',
                  }, textTransform: 'none', justifyContent: 'left', borderColor: props.filterTongkang["id_listing_status"] === '3' ? '#3BB56C' : '#E8EBF2', color: props.filterTongkang["id_listing_status"] === '3' ? '#3BB56C' : 'black', borderRadius: '8px', p: '14px 16px'
                }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterTongkang["id_listing_status"] === '3' ? '#3BB56C' : '#E8EBF2', }} />}>
                {translate('my-listing.btn-draft')}
              </Button>
            </Grid>
          </Grid>
        </Box>}
      </Box>
    </Box>
  );
}