import { Grid } from '@material-ui/core';
import PDFViewer from 'pdf-viewer-reactjs';
import PropTypes from "prop-types";
import React from 'react';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { useTranslation } from "react-i18next";
import doc_icon2 from "../assets/doc-icon.svg";
import pdfIcon from "../assets/pdf-ic.png";

function DisplayPDF() {
  const list_document = [1, 2]
  const [translate,] = useTranslation("global");

  return (
    <Grid container direction='row' justifyContent='space-between' style={{ flexWrap: "nowrap" }}>
      {list_document.map((item, i) => (
        <div key={i} className='flex column doc_'>
          <a href="https://arxiv.org/pdf/quant-ph/0410100.pdf"
            target="_blank"
            rel="noreferrer" className='box-document border-box' >
            <PDFViewer document={{ url: 'https://arxiv.org/pdf/quant-ph/0410100.pdf', }} />
          </a>
          <div className='doc-desc flex'>
            <img src={doc_icon2} alt="" className='mr8' />
            <div className='flex column'>
              <p className='w-500 s-1419 c-08'>Dokumen CO...</p>
              <div className='flex alignC'>
                <p className='w-500 s-1216 c-56'>Doc</p>
                &nbsp;
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="#818CA7" />
                </svg> &nbsp;
                <a style={{ textDecoration: 'none' }} href="https://arxiv.org/pdf/quant-ph/0410100.pdf" target="_blank"
                  rel="noreferrer" className='c-24 s-1216 w-500'>
                  {translate('profile.unduh')}
                </a>
              </div>
            </div>
          </div>
        </div>))}
    </Grid>
  )
}

function DisplayPDFFile(props) {
  let { url, name, ext, key } = props;
  let newName = name

  if (name.length >= 9) {
    newName = name.slice(0, 8)
  }

  const pdfUrl2 = React.useMemo(() => ({ uri: url }), [url]);
  let render = React.useMemo(() => (
    <DocViewer documents={[pdfUrl2]}
      pluginRenderers={DocViewerRenderers}
    />
  ), [pdfUrl2]);
  const [translate,] = useTranslation("global");

  return (
    <Grid container direction='row' justifyContent='space-between' style={{ flexWrap: "nowrap" }}>
      <div key={key} className='flex column' style={{ width: '100%' }}>
        <a href={url}
          target="_blank"
          rel="noreferrer" className='box-document border-box' >
          {render}
        </a>
        <div className='doc-desc flex' style={{ paddingTop: "10px", paddingBottom: "10px" }}>
          <img style={{ height: '28px', width: '28px' }} src={ext === '.pdf' ? pdfIcon : doc_icon2} alt="" className='mr8' />
          <div className='flex column'>
            <p className='w-500 s-1419 c-08'>{`${newName}...`}</p>
            <div className='flex alignC'>
              <p className='w-500 s-1216 c-56'>{ext === '.pdf' ? 'PDF' : ext === '.xlsx' ? 'XLSX' : 'DOC'}</p>
              &nbsp;
              <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="2" cy="2" r="2" fill="#818CA7" />
              </svg> &nbsp;
              <a href={url} style={{ textDecoration: 'none' }} target="_blank"
                rel="noreferrer" className='c-24 s-1216 w-500'>{translate('profile.unduh')}</a>
            </div>
          </div>
        </div>
      </div>
    </Grid>
  )
}

DisplayPDFFile.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string,
  ext: PropTypes.string,
  key: PropTypes.any,
};

export { DisplayPDF, DisplayPDFFile };
