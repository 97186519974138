import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Fab } from "@mui/material";
import MuiToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { createTheme } from '@mui/material/styles';
import { styled } from '@mui/system';

export const VerticalDivider = styled('div')({

    width: '1px',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    height: '100%',
    position: 'absolute',
    right: '36px',
    top: '0',
    bottom: '0',
    zIndex: '1',
});

export const VerticalDividerLeft = styled('div')({
    width: '1px',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    height: '100%',
    position: 'absolute',
    left: '36px',
    top: '0',
    bottom: '0',
    zIndex: '1',
});

export function ArrowDownComponent() {
    return (
        <KeyboardArrowDownIcon sx={{ mr: '16px', width: '20px', height: '20px', fill: '#56617B' }} />
    );
}

export function ArrowDownPriceComponent() {
    return (
        <KeyboardArrowDownIcon sx={{ width: '20px', height: '20px', fill: '#56617B', position: 'absolute', right: 0, pointerEvents: 'none' }} />
    );
}

export function CustomNextArrow(props) {
    const { className, onClick } = props;
    return (
        <Fab onClick={onClick} className={className} sx={{ border: 1, color: 'white !important', background: '#2550A2', "&:hover": { color: "#2550A2 !important" } }} aria-label="edit"><ArrowForwardIcon /></Fab>
    );
}

export function CustomPrevArrow(props) {
    const { className, onClick } = props;
    return (
        <Fab onClick={onClick} className={className} sx={{ border: 1, color: 'white !important', background: '#2550A2', "&:hover": { color: "#2550A2 !important" } }} aria-label="edit"><ArrowBackIcon /></Fab>
    );
}

export const settingsHomePage = {
    variableWidth: true,
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: false,
    speed: 500,
    slidesToShow: 4,
    centerMode: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
        {
            breakpoint: 1821,
            settings: {
                slidesToShow: 3
            }
        },
        {
            breakpoint: 1320,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 960,
            settings: {
                slidesToShow: 1
            }
        },
        {
            breakpoint: 900,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 640,
            settings: {
                arrows: false,
                centerMode: true,
                slidesToShow: 1
            }
        }
    ]
};

export const VerticalDividerQuantity = styled('div')({
    width: '1px',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    height: '100%',
    position: 'absolute',
    right: '56px',
    top: '0',
    bottom: '0',
    zIndex: '1',
});

export const VerticalDividerPrice = styled('div')({
    width: '1px',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    height: '100%',
    position: 'absolute',
    right: '82px',
    top: '0',
    bottom: '0',
    zIndex: '1',
});

export function DateIcon(props) {
    return (
        <div></div>
    );
}

export function numberConvert(x) {
    if (x !== undefined) {
        return x.toLocaleString(x);
    }
}

export function convertText(x) {
    if (x !== undefined) {
        x = x.replaceAll(" ", "-")
        x = x.replaceAll("--", "-")
        return x
        // return x.split(" ").join("");
    }
}

export function validationEmail(e) {

    if (e !== undefined && e.length > 0) {

        let formatted = "";
        formatted = !e.includes('@');
        return formatted
    }
}
export function validationNumber(e) {
    if (e !== undefined) {
        if (e === '') {
            e = 'false'
        }
        let number = Number(e)
        return (typeof (number) === 'number' && !isNaN(number))
    }
}


export function formatDate(x) {
    if (x !== undefined) {
        const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
        const gmtdate = new Date(x).toLocaleString('id-ID', options);
        const newdate = gmtdate.split("/").reverse().join("-");

        return newdate;
    }
}

export function simpleCaseCapitalize(e) {
    if (e !== undefined) {
        let newCase = ''
        let eachWord = e.split(" ")
        for (let i = 0; i < eachWord.length; i++) {
            let capitalize = ""
            if (i !== 0) {
                capitalize = " "
            }
            let someCase = eachWord[i];
            for (let n = 0; n < someCase.length; n++) {
                if (n === 0) {
                    capitalize = capitalize + someCase[n].toUpperCase()
                } else {
                    capitalize = capitalize + someCase[n]
                }
            }
            newCase = newCase + capitalize
        }


        return newCase
    }
}



export function format(s) {
    if (s !== undefined) {
        let formattedphone = "";
        if (s.length > 10) {
            formattedphone = s.toString().replace(/^(.{3})(.{4})(.{4})(.*)$/, "$1 $2 $3 $4");
        } else if (s.length === 10) {
            formattedphone = s.toString().replace(/^(.{3})(.{4})(.{3})$/, "$1 $2 $3");
        } else {
            formattedphone = s.toString().replace(/^(.{3})(.{4})(.*)$/, "$1 $2 $3");
        }
        return formattedphone
    }
}

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        margin: theme.spacing(0.5),
        border: 0,
        '&.Mui-disabled': {
            border: 0,
        },
        '&:not(:first-of-type)': {
            borderRadius: '8px',
        },
        '&:first-of-type': {
            borderRadius: '8px',
        },
    },
}));

export const theme = createTheme({
    palette: {
        text: {
            primary: '#FFFFFF',
        },
    },
});

export function capitalizeEachWord(e) {
    if (typeof (e) == "string") {
        let newCase = ''
        let eachWord = e.split(" ")
        for (let i = 0; i < eachWord.length; i++) {
            let capitalize = ""
            if (i !== 0) {
                capitalize = " "
            }
            let someCase = eachWord[i];
            if (someCase !== '') {
                if (i === 0) {
                    if (someCase.length <= 3 && someCase.includes(".")) {
                        capitalize = capitalize + someCase
                    } else {
                        for (let n = 0; n < someCase.length; n++) {
                            if (n === 0) {
                                capitalize = capitalize + someCase[n].toUpperCase()
                            } else {
                                capitalize = capitalize + someCase[n].toLowerCase()
                            }
                        }
                    }
                } else {
                    for (let n = 0; n < someCase.length; n++) {
                        if (n === 0) {
                            capitalize = capitalize + someCase[n].toUpperCase()
                        } else {
                            capitalize = capitalize + someCase[n].toLowerCase()
                        }
                    }
                }
            } else {
                capitalize = ''
            }

            newCase = newCase + capitalize
        }

        let newEachWord = newCase.split(" ")

        if (newEachWord[0] === "Pt") {
            newEachWord[0] = "PT"
        }

        newEachWord = newEachWord.join(" ")

        return newEachWord

    } else {
        return e
    }
}

export const ToggleButton = styled(MuiToggleButton)({
    "&.Mui-selected, &.Mui-selected:hover": {
        color: "#2457B9",
        backgroundColor: '#FFFFFF'
    }
});

export const settings = {
    arrows: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1821,
            settings: {
                slidesToShow: 3
            }
        },
        {
            breakpoint: 1320,
            settings: {
                centerMode: true,
                slidesToShow: 2
            }
        },
        {
            breakpoint: 960,
            settings: {
                centerMode: true,
                slidesToShow: 1
            }
        },
        {
            breakpoint: 900,
            settings: {
                centerMode: true,
                slidesToShow: 2
            }
        },
        {
            breakpoint: 640,
            settings: {
                arrows: false,
                centerMode: true,
                slidesToShow: 1
            }
        }
    ]

};

export const settingsnavbar = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    // centerMode: true,
    responsive: [
        {
            breakpoint: 1821,
            settings: {
                slidesToShow: 1
            }
        },
        {
            breakpoint: 1320,
            settings: {
                slidesToShow: 1
            }
        },
        {
            breakpoint: 960,
            settings: {
                slidesToShow: 1
            }
        },
        {
            breakpoint: 900,
            settings: {
                arrows: false,
                // centerMode: true,
                slidesToShow: 1
            }
        },

    ]
};

export const settings_penawaran_tongkang = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: false,
    speed: 500,
    slidesToShow: 3,
    centerMode: false,
    prevArrow: <Fab sx={{ color: 'black !important', background: 'white', "&:hover": { color: "#2550A2 !important" }, alignItems: 'center', justifyContent: 'center' }} aria-label="edit"><ArrowBackIosIcon /></Fab>,
    nextArrow: <Fab sx={{ color: 'black !important', background: 'white', "&:hover": { color: "#2550A2 !important" } }} aria-label="edit"><ArrowForwardIosIcon /></Fab>,
    responsive: [
        {
            breakpoint: 1821,
            settings: {
                slidesToShow: 3
            }
        },
        {
            breakpoint: 1200,
            settings: {
                arrows: false,
                slidesToShow: 3
            }
        },
        {
            breakpoint: 960,
            settings: {
                arrows: false,
                centerMode: true,
                slidesToShow: 2
            }
        },
        {
            breakpoint: 900,
            settings: {
                arrows: false,
                centerMode: true,
                slidesToShow: 2
            }
        },
        {
            breakpoint: 640,
            settings: {
                arrows: false,
                // centerMode: true,
                slidesToShow: 1
            }
        }
    ]

};

export const settingsRecommendasiTongkang = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: false,
    speed: 500,
    slidesToShow: 4,
    centerMode: false,
    prevArrow: <Fab sx={{ color: 'black !important', background: 'white', "&:hover": { color: "#2550A2 !important" }, alignItems: 'center', justifyContent: 'center' }} aria-label="edit"><ArrowBackIosIcon /></Fab>,
    nextArrow: <Fab sx={{ color: 'black !important', background: 'white', "&:hover": { color: "#2550A2 !important" } }} aria-label="edit"><ArrowForwardIosIcon /></Fab>,
    responsive: [
        {
            breakpoint: 1821,
            settings: {
                slidesToShow: 4
            }
        },
        {
            breakpoint: 1200,
            settings: {
                arrows: false,
                slidesToShow: 3
            }
        },
        {
            breakpoint: 960,
            settings: {
                arrows: false,
                centerMode: true,
                slidesToShow: 2
            }
        },
        {
            breakpoint: 900,
            settings: {
                arrows: false,
                centerMode: true,
                slidesToShow: 2
            }
        },
        {
            breakpoint: 640,
            settings: {
                arrows: false,
                // centerMode: true,
                slidesToShow: 1
            }
        }
    ]
};



export const settingsCompanyHomePage = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 4,
    // centerMode: true,
    responsive: [
        {
            breakpoint: 1821,
            settings: {
                slidesToShow: 3
            }
        },
        {
            breakpoint: 1320,
            settings: {
                slidesToShow: 2
            }
        },
        {
            breakpoint: 960,
            settings: {
                slidesToShow: 1
            }
        },
        {
            breakpoint: 900,
            settings: {
                arrows: false,
                // centerMode: true,
                slidesToShow: 1
            }
        },

    ]
};
