import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Grid, Hidden, Stack, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import inviteStaffIcon from "../assets/company/invite-staff-ic.svg";
import LocationIcon from "../assets/company/location-blue.svg";
import perusahaanIcon from "../assets/company/profile-company-ic.svg";
import DefaultProfileImg from "../assets/default-img-profile.png";
import doc_icon from "../assets/doc.svg";
import calendarIcon from '../assets/material-symbols_calendar-today.svg';
import UploadProfileIcon from "../assets/uploadprofile-ic.png";
import Footer from "../components/Footer";
import NavBar from "../components/Navbar";
import MenuFormAddress from "../components/company/MenuFormAddress";
import MenuFormAktaCompany from "../components/company/MenuFormAktaCompany";
import MenuFormDetailCompany from "../components/company/MenuFormDetailCompany";
import MenuFormDireksi from "../components/company/MenuFormDireksi";
import MenuFormNPWP from "../components/company/MenuFormNPWP";
import MenuFormPerizinan from "../components/company/MenuFormPerizinan";
import MenuPemegangSaham from "../components/company/MenuPemegangSaham";
import { UpdateCompanyAddress, UpdateCompanyDireksi, UpdateCompanyInfo, UpdateCompanyPerizinan, UpdateCompanySaham, getAllFlags, getAllUserTrader, getCity, getDireksiPosisi, getJenisPerusahaan, getJenisSaham, getLabelAlamat, getProvince } from "../services/api.js";
import { config } from '../services/constants';
import { DateIcon, capitalizeEachWord, convertText } from '../services/primary';

export default function EditCompany() {
  const location = useLocation();
  const navigate = useNavigate()
  const [translate] = useTranslation("global");
  const [name, setName] = useState("")
  const [nib, setnib] = useState("")
  const [email, setEmail] = useState("")
  const [website, setWebsite] = useState("")
  const [description, setDesc] = useState("")
  const [phone_number, setPhone_number] = useState("")
  const [bidang_perusahaan, setBidang_perusahaan] = useState("")
  const [id_provinsi] = useState("")
  const [id_city] = useState("")
  const [kode_pos, setKode_pos] = useState("")
  const [imgprofile, setImgProfile] = useState("")
  const [urlprofile, setUrlprofile] = useState("")
  const [akta, setUploadAkta] = useState([])
  const [npwp, setUploadnpwp] = useState([])
  const [listOfProvince, setListProvince] = useState([])
  const [listOfcity, setListCity] = useState([])
  const [traderList, setTraderList] = useState([])
  const [selecttype, setselecttype] = useState('')
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [alternative_name, setalternativename] = useState("")
  const [openModal, setOpenModal] = useState(false);
  const [disable, setDisable] = useState(false)

  const [id_bendera] = useState("")
  const [flagList, setFlagList] = useState([]);
  const [CurrentAddress, setCurrentAddress] = useState(false);
  const [direksi, setDireksi] = useState([{ profile_picture: "", name: "", position: "", start_period: "", end_period: "" }])
  const [pemegangsaham, setpemegangsaham] = useState([{ jenis_saham: '', name: '', id_negara: '', persentase: '' }])
  const [arrayaddress, setarrayAddress] = useState([{ detail_alamat: "", label_alamat: "", id_provinsi: '', id_kota: '', kode_pos: '', alamat_sekarang: false }])
  const [perizinan, setperizinan] = useState([{ nama_perizinan: "", nomor_perizinan: "", kode_wiup: "", tahap_kegiatan: "", komoditas: "", luas: "", start_date: "", end_date: "", tahapan_cnc: "", detail_location: "", url_path_documents_perizinan: "", name_path_documents_perizinan: "", ext_path_documents_perizinan: "", modi_id: "", jenis_operasi: "" }])

  const [idjenisperusahaan, setIdPerusahaan] = useState("")
  const [jenisperusahaan, setjenisperusahaan] = useState([])
  const [posisiperusahaan, setposisiperusahaan] = useState([])
  const [urldireksi,] = useState([])
  const [jenissaham, setjenissaham] = useState([])
  const [id_posisi, setidposisi] = useState("")
  const [label_alamat, setLabel_alamat] = useState([])
  const [idlabelalamat, setidlabelalamat] = useState("")

  const [openStartDate, setopenStartDate] = useState(false);
  const [openEndDate, setopenEndDate] = useState(false);
  const [startdate, setstartdate] = React.useState('');
  const [enddate, setenddate] = React.useState('');

  const [isOpenUploadAkta, setOpenUploadAkta] = React.useState(false)
  const [akta_nomor, setakta_nomor] = useState("")
  const [akta_description, setakta_description] = useState("")
  const [isOpenUploadNpwp, setOpenUploadNpwp] = React.useState(false)
  const [npwp_nomor, setnpwp_nomor] = useState("")
  const [npwp_name, setnpwpname] = useState("")
  const [npwp_description, setnpwp_description] = useState("")

  const [x] = useState(location?.state === null ? null : location.state.data)
  const [page] = useState(location?.state === null ? null : location.state.page)

  const [triggerValidation, settriggerValidation] = useState(0);

  useEffect(() => {

    getDataProvince();
    getTraderAll()
    getFlags()
    getDataDireksiPosisi()
    getDataJenisPerusahaan()
    getDataJenisSaham()
    getDatagetLabelAlamat()
    updateField()
  }, []);

  async function getDataProvince() {
    const response = await getProvince();
    setListProvince(response.data.data)
  }

  async function getDataDireksiPosisi() {
    const response = await getDireksiPosisi();
    setposisiperusahaan(response.data.data)
  }

  async function getDataJenisPerusahaan() {
    const response = await getJenisPerusahaan();
    setjenisperusahaan(response.data.data)
  }

  async function getDataJenisSaham() {
    const response = await getJenisSaham();
    setjenissaham(response.data.data)
  }

  async function getDatagetLabelAlamat() {
    const response = await getLabelAlamat();
    setLabel_alamat(response.data.data)
  }

  async function getDataCity(e) {
    const response = await getCity(parseInt(e));
    setListCity(response.data?.data)
  }

  async function getAllDataCity(e) {
    const response = await getAllDataCity();
    setListCity(response.data?.data)
  }

  async function getTraderAll() {
    try {
      const response = await getAllUserTrader("");
      setTraderList(response.data.data.data.data);
    } catch (e) {
      console.log(e);
    }
  }

  function updateField() {

    if (x !== null) {
      setarrayAddress(x.alamat_list)
      setnib(x.nomor_induk_berusaha)
      setIdPerusahaan(x.jenis_perusahaan)
      setName(x.name)
      setBidang_perusahaan(x.bidang_perusahaan)
      setselecttype(x.bidang_perusahaan)
      setPhone_number(x.phone_number)
      setEmail(x.email)
      setWebsite(x.website)
      setUploadAkta(x.akta_path_documents)
      setakta_nomor(x.akta_nomor)
      setakta_description(x.akta_description)
      setUploadnpwp(x.npwp_path_documents)
      setnpwp_nomor(x.npwp_nomor)
      setnpwp_description(x.npwp_description)
      setnpwpname(x.npwp_nama)
      setpemegangsaham(x.pemilik_saham_list)
      setDireksi(x.direksi_list)
      handlePerizinan(x.perizinan_list)
      setUrlprofile(x.picture_path.url)
      setalternativename(x.alternative_name)
    }
  }

  const handlePerizinan = (e) => {

    let perizinanArray = []
    for (let i = 0; i < e.length; i++) {
      let newPerizinanValue = e[i]
      newPerizinanValue.url_path_documents_perizinan = e[i]?.path_documents[0]?.url;
      newPerizinanValue.name_path_documents_perizinan = e[i]?.path_documents[0]?.name;
      newPerizinanValue.ext_path_documents_perizinan = e[i]?.path_documents[0]?.ext;
      perizinanArray.push(newPerizinanValue)
    }

    setperizinan(perizinanArray)
  }

  const handleChangeBidangPerusahaan = (e, i) => {
    setBidang_perusahaan(e.target.value)
    setselecttype(e.target.value)
  }

  async function handleChangeFileProfile(e) {
    const file = e.target.files[0]
    let tokenValue = localStorage.getItem("token")

    let formData = new FormData();
    formData.append('files', file);
    let response = await axios.post(`${config.baseURL}/api/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${tokenValue}`
      },

    })


    setImgProfile(response?.data[0].url)
    setUrlprofile(response?.data[0].url)
  }

  //direksi function
  const handleAddDireksi = () => {
    const datadireksi = [...direksi, { profile_picture: "", name: "", position: "", start_period: "", end_period: "" }]
    setDireksi(datadireksi)
  }

  let handleChangeDireksi = (e, i) => {
    let newDireksiValues = [...direksi];
    newDireksiValues[i].name = e.target.value;
    setDireksi(newDireksiValues);
  }

  function handleChangeStartPeriode(e, i) {
    setstartdate(e)
    let newDireksiValues = [...direksi];
    newDireksiValues[i].start_period = e;
    setDireksi(newDireksiValues);
  }

  function handleChangeEndPeriode(e, i) {
    setenddate(e)
    let newDireksiValues = [...direksi];
    newDireksiValues[i].end_period = e;
    setDireksi(newDireksiValues);
  }

  function handleChangePosisiDireksi(e, i) {
    setidposisi(e.target.value)
    let newDireksiValues = [...direksi];
    newDireksiValues[i].position = e.target.value;
    setDireksi(newDireksiValues);
  }

  const handleDeleteDireksi = (i) => {
    const deletedireksi = [...direksi]
    deletedireksi.splice(i, 1)
    setDireksi(deletedireksi)
  }

  async function handleChangeImgDireksi(e, i) {
    let tokenValue = localStorage.getItem("token")

    const file = e.target.files[0]
    let formData = new FormData();
    formData.append('files', file);
    let response = await axios.post(`${config.baseURL}/api/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${tokenValue}`
      },
    })

    let newDireksiValues = [...direksi];

    newDireksiValues[i].profile_picture = response?.data[0].url;
    setDireksi(newDireksiValues);
  }

  //direksi pemegang saham
  const handleAddPemegangSaham = () => {
    const datapemegangsaham = [...pemegangsaham, { jenis_saham: '', name: '', id_negara: '', persentase: '' }]
    setpemegangsaham(datapemegangsaham)
  }

  const handleChangePemegangSaham = (e, i) => {
    let newSahamValues = [...pemegangsaham];
    newSahamValues[i].name = e.target.value;
    setpemegangsaham(newSahamValues);
  }

  function handleChangeJenisSaham(e, i) {
    let newSahamValues = [...pemegangsaham];
    newSahamValues[i].jenis_saham = e.target.value;
    setpemegangsaham(newSahamValues);
  }

  const handleDeletePemegangSaham = (i) => {
    const deletepemegangsaham = [...pemegangsaham]
    deletepemegangsaham.splice(i)
    setpemegangsaham(deletepemegangsaham)
  }

  function handleSelectbendera(e, i) {

    let newSahamValues = [...pemegangsaham];
    newSahamValues[i].id_negara = e.id;
    setpemegangsaham(newSahamValues);
  }

  //STEP 2 Upload Document

  const handleOpenModalUploadAkta = () => {
    setOpenUploadAkta(true)
  };

  const handleCloseModalUploadAkta = (params, fileQ) => {
    if (params === 'submit') {
      // let newArray = []
      // let fileArrayCombine = newArray.concat(fileQ)
      setUploadAkta(fileQ)
    }
    setOpenUploadAkta(false);
  };

  const handleOpenModalUploadNpwp = () => {
    setOpenUploadNpwp(true)
  };

  const handleCloseModalUploadNpwp = (params, fileQ) => {
    if (params === 'submit') {
      // let newArray = []
      // let fileArrayCombine = newArray.concat(fileQ)
      setUploadnpwp(fileQ)
    }
    setOpenUploadNpwp(false);
  };

  const handleChangePercentaseSaham = (e, i) => {
    let newSahamValues = [...pemegangsaham];
    newSahamValues[i].persentase = e.target.value;
    setpemegangsaham(newSahamValues);
  }

  async function handleChangeFilePerizinan(e, i) {
    let newPerizinanValue = [...perizinan];


    newPerizinanValue[i].url_path_documents_perizinan = e.url;
    newPerizinanValue[i].name_path_documents_perizinan = e.name;
    newPerizinanValue[i].ext_path_documents_perizinan = e.ext;

    setperizinan(newPerizinanValue)
  }

  const handleChangeNamaPerizinan = (e, i) => {
    let newPerizinanValue = [...perizinan];
    newPerizinanValue[i].nama_perizinan = e.target.value;
    setperizinan(newPerizinanValue);
  }

  const handleChangeNomorPerizinan = (e, i) => {
    let newPerizinanValue = [...perizinan];
    newPerizinanValue[i].nomor_perizinan = e.target.value;
    setperizinan(newPerizinanValue);
  }

  const handleChangeKodeWIUP = (e, i) => {
    let newPerizinanValue = [...perizinan];
    newPerizinanValue[i].kode_wiup = e.target.value;
    setperizinan(newPerizinanValue);
  }

  const handleChangeTahapanKegiatan = (e, i) => {
    let newPerizinanValue = [...perizinan];
    newPerizinanValue[i].tahap_kegiatan = e.target.value;
    setperizinan(newPerizinanValue);
  }

  const handleChangeKomoditas = (e, i) => {
    let newPerizinanValue = [...perizinan];
    newPerizinanValue[i].komoditas = e.target.value;
    setperizinan(newPerizinanValue);
  }

  const handleChangeLuas = (e, i) => {
    let newPerizinanValue = [...perizinan];
    newPerizinanValue[i].luas = e.target.value;
    setperizinan(newPerizinanValue);
  }

  const handleChangeTahapanCNC = (e, i) => {
    let newPerizinanValue = [...perizinan];
    newPerizinanValue[i].tahapan_cnc = e.target.value;
    setperizinan(newPerizinanValue);
  }

  const handleChangeLokasi = (e, i) => {
    let newPerizinanValue = [...perizinan];
    newPerizinanValue[i].detail_location = e.target.value;
    setperizinan(newPerizinanValue);
  }

  function handleChangeStartPerizinan(e, i) {
    setstartdate(e)
    let newPerizinanValue = [...perizinan];
    newPerizinanValue[i].start_date = e;
    setDireksi(newPerizinanValue);
  }

  function handleChangeEndPerizinan(e, i) {
    let newPerizinanValue = [...perizinan];
    newPerizinanValue[i].end_date = e;
    setDireksi(newPerizinanValue);
  }

  const handleAddPerizinan = () => {
    const dataperizinan = [...perizinan, { nama_perizinan: "", nomor_perizinan: "", kode_wiup: "", tahap_kegiatan: "", komoditas: "", luas: "", start_date: "", end_date: "", tahapan_cnc: "", detail_location: "", url_path_documents_perizinan: "", name_path_documents_perizinan: "", ext_path_documents_perizinan: "", modi_id: "", jenis_operasi: "" }]
    setperizinan(dataperizinan)
  }

  const handleDeletePerizinan = (i) => {
    const deleteperizinan = [...perizinan]
    deleteperizinan[i] = { nama_perizinan: "", nomor_perizinan: "", kode_wiup: "", tahap_kegiatan: "", komoditas: "", luas: "", start_date: "", end_date: "", tahapan_cnc: "", detail_location: "", url_path_documents_perizinan: "", name_path_documents_perizinan: "", ext_path_documents_perizinan: "", modi_id: "", jenis_operasi: "" }
    setperizinan(deleteperizinan)
  }

  //STEP 3 Alamat
  let handleAddAddress = () => {
    const dataaddress = [...arrayaddress, { detail_alamat: "", label_alamat: "", id_provinsi: '', id_kota: '', kode_pos: '', alamat_sekarang: false }]
    setarrayAddress(dataaddress)
  }

  let handleDeleteAddress = (i) => {
    const deleteaddress = [...arrayaddress]
    deleteaddress.splice(i, 1)
    setarrayAddress(deleteaddress)
  }

  function handleChangeAlamatLengkap(e, i) {
    let newAddress = [...arrayaddress];
    newAddress[i].detail_alamat = e.target.value;
    setarrayAddress(newAddress);
  }
  function handleChangeLabel(e, i) {
    let newAddress = [...arrayaddress];
    newAddress[i].label_alamat = e.target.value;
    setarrayAddress(newAddress);
  }

  const changeProvince = (e, i) => {
    let newAddress = [...arrayaddress];
    newAddress[i].id_provinsi = e.target.value;
    setarrayAddress(newAddress);
    getDataCity(newAddress[i].id_provinsi)
  }

  const changeCity = (e, i) => {
    let newAddress = [...arrayaddress];
    newAddress[i].id_kota = e.target.value;
    setarrayAddress(newAddress);
  }

  function handleChangeKodepos(e, i) {
    let newAddress = [...arrayaddress];
    newAddress[i].kode_pos = e.target.value;
    setarrayAddress(newAddress);
  }

  function handleCurrentAddress(e, i) {

    let newAddress = [...arrayaddress];
    newAddress[i].alamat_sekarang = e.target.checked;
    setarrayAddress(newAddress);

  }


  async function submitUpdateCompanyInfo(e) {

    let validation = triggerValidation
    settriggerValidation(validation + 1)

    const data = {
      id_company: x.id,
      nomor_induk_berusaha: nib.toString(),
      picture_path: imgprofile,
      jenis_perusahaan: idjenisperusahaan,
      name: name,
      bidang_perusahaan: bidang_perusahaan,
      email: email,
      website: website,
      phone_number: phone_number.indexOf("+62") === 0 ? phone_number : "+62" + phone_number,
      description: description,
      akta_nomor: akta_nomor,
      akta_description: akta_description,
      akta_path_documents: JSON.stringify(akta),
      npwp_nama: npwp_name,
      npwp_nomor: npwp_nomor,
      npwp_description: npwp_description,
      npwp_path_documents: JSON.stringify(npwp),
      alternative_name: alternative_name
    }

    let validationCount = 0

    if (
      data.nomor_induk_berusaha === '' ||
      data.picture_path === '' ||
      data.jenis_perusahaan === "" ||
      data.name === '' ||
      data.bidang_perusahaan === '' ||
      data.phone_number === '+62' ||
      data.email === '' ||
      data.alternative_name === '') {
      validationCount++
    }

    if (data.akta_nomor === '' ||
      data.akta_description === '' ||
      data.akta_path_documents === '[]' ||
      data.npwp_nomor === '' ||
      data.npwp_description === '' ||
      data.npwp_path_documents === '[]' ||
      data.npwp_nama === '') {
      validationCount++
    }

    if (validationCount > 0) {
      Swal.fire({
        icon: 'error',
        title: translate('status.sorry'),
        text: translate('status.Fieldkosong'),
      })
    } else {
      setIsLoadingSubmit(true)
      setDisable(true)

      const res = await UpdateCompanyInfo(data)
      if (res.status === 200) {
        Swal.fire({
          icon: 'success',
          title: translate('status.success'),
          text: translate('status.successeditcompany'),
        }).then(function () {
          window.location.href = `/detail/${capitalizeEachWord(convertText(x.name))}`
        });

      } else {
        Swal.fire({
          icon: 'error',
          title: translate('status.failed'),
          text: translate('status.subtitlefailed'),
        })
      }
      setIsLoadingSubmit(false)
      setDisable(false)
      settriggerValidation(0)
    }


  }

  async function submitUpdateCompanyAddress(e) {
    const data = {
      id_company: x.id,
      address: JSON.stringify(arrayaddress),
      id_deleted_address: ''
    }

    let validation = triggerValidation
    settriggerValidation(validation + 1)

    let validationCount = 0
    let newAddress = JSON.parse(data.address)

    if (newAddress.length === 0) {
      validationCount++
    }

    for (let i = 0; i < newAddress.length; i++) {
      const element = newAddress[i];
      if (
        element.detail_alamat === '' ||
        element.label_alamat === '' ||
        element.id_provinsi === '' ||
        element.kode_pos === '' ||
        element.id_kota === ''
      ) {
        validationCount++
      }
    }

    if (validationCount > 0) {
      Swal.fire({
        icon: 'error',
        title: translate('status.sorry'),
        text: translate('status.Fieldkosong'),
      })
    } else {
      setIsLoadingSubmit(true)
      setDisable(true)

      const res = await UpdateCompanyAddress(data)
      if (res.status === 200) {
        Swal.fire({
          icon: 'success',
          title: translate('status.success'),
          text: translate('status.successupdatealamat'),
        }).then(function () {
          window.location.href = `/detail/${capitalizeEachWord(convertText(x.name))}`
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: translate('status.failed'),
          text: translate('status.subtitlefailed'),
        })
      }
      setIsLoadingSubmit(false)
      setDisable(false)
      settriggerValidation(0)
    }
  }

  async function submitUpdateCompanyDireksi(e) {
    const data = {
      id_company: x.id,
      direksi: JSON.stringify(direksi),
      id_deleted_direksi: ''
    }

    let validation = triggerValidation
    settriggerValidation(validation + 1)

    let validationCount = 0
    let newDireksi = JSON.parse(data.direksi)

    if (
      newDireksi.length === 0) {
      validationCount++
    }

    for (let i = 0; i < newDireksi.length; i++) {
      const element = newDireksi[i];
      if (
        element.profile_picture === '' ||
        element.name === '' ||
        element.position === '' ||
        element.start_period === "" ||
        element.end_period) {
        validationCount++
      }
    }

    if (validationCount > 0) {
      Swal.fire({
        icon: 'error',
        title: translate('status.sorry'),
        text: translate('status.Fieldkosong'),
      })
    } else {
      settriggerValidation(0)
      setIsLoadingSubmit(true)
      setDisable(true)

      const res = await UpdateCompanyDireksi(data)
      if (res.status === 200) {
        Swal.fire({
          icon: 'success',
          title: translate('status.success'),
          text: translate('status.successupdatedireksi'),
        }).then(function () {
          window.location.href = `/detail/${capitalizeEachWord(convertText(x.name))}`
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: translate('status.failed'),
          text: translate('status.subtitlefailed'),
        })
      }
      setIsLoadingSubmit(false)
      setDisable(false)
    }
  }

  async function submitUpdateCompanySaham(e) {
    const data = {
      id_company: x.id,
      saham: JSON.stringify(pemegangsaham),
      id_deleted_saham: ''
    }

    let validation = triggerValidation
    settriggerValidation(validation + 1)

    let validationCount = 0
    let newSaham = JSON.parse(data.saham)

    if (newSaham.length === 0) {
      validationCount++
    }

    for (let i = 0; i < newSaham.length; i++) {
      const element = newSaham[i];
      if (
        element.jenis_saham === '' ||
        element.name === '' ||
        element.id_negara === '' ||
        element.persentase === ''
      ) {
        validationCount++
      }
    }

    if (validationCount > 0) {
      Swal.fire({
        icon: 'error',
        title: translate('status.sorry'),
        text: translate('status.Fieldkosong'),
      })
    } else {
      settriggerValidation(0)
      setIsLoadingSubmit(true)
      setDisable(true)

      const res = await UpdateCompanySaham(data)
      if (res.status === 200) {
        Swal.fire({
          icon: 'success',
          title: translate('status.success'),
          text: translate('status.successupdatepemegangsaham'),
        }).then(function () {
          window.location.href = `/detail/${capitalizeEachWord(convertText(x.name))}`
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: translate('status.failed'),
          text: translate('status.subtitlefailed'),
        })
      }
      setIsLoadingSubmit(false)
      setDisable(false)
    }
  }

  async function submitUpdateCompanyPerizinan(e) {
    const data = {
      id_company: x.id,
      perizinan: JSON.stringify(perizinan),
      id_deleted_perizinan: ''

    }
    setIsLoadingSubmit(true)
    setDisable(true)

    const res = await UpdateCompanyPerizinan(data)
    if (res.status === 200) {
      Swal.fire({
        icon: 'success',
        title: translate('status.success'),
        text: translate('status.successupdateprizinan'),
      }).then(function () {
        window.location.href = `/detail/${capitalizeEachWord(convertText(x.name))}`
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: translate('status.failed'),
        text: translate('status.subtitlefailed'),
      })
    }
    setIsLoadingSubmit(false)
    setDisable(false)
  }



  const type_list = [{ bidang_perusahaan: 1, label: translate('page_explore.penambang') }, { bidang_perusahaan: 2, label: translate('page_explore.trader') }, { bidang_perusahaan: 3, label: translate('page_explore.pelayaran') }]
  let list_trader = []
  traderList.map((item) => {
    return (list_trader.push({
      id: item.user_id, label: item.name, email: item.email
    }))
  })

  async function getFlags() {
    try {
      const response = await getAllFlags()
      setFlagList(response.data?.data);
    } catch (e) {
      console.log(e);
    }
  }


  return (
    <div>
      <div className='fixed-header'>
        <NavBar /></div>
      <Box className="flex-wrap-center width-100" >
        <Box className="content-inner" sx={{ mt: { xs: 8, sm: 8, md: 11, lg: 11, xl: 11 }, ml: { xs: '24px', lg: '80px', }, mr: { xs: '24px', lg: '80px', }, }}>
          {/* navigation Section */}
          <Hidden only={['xs', 'sm']}>
            <Grid container direction="row" alignItems='center' className='mt-18'>
              <Button href="/profile" className="w-500" sx={{ fontSize: 16, mb: 1, color: '#2457B9', textTransform: 'none' }} variant="text"> {translate('navbar.profile')}</Button>
              <Typography className="w-500" sx={{ fontSize: 16, mb: 1, mr: 1, color: '#56617B' }}>
                /
              </Typography>
              <Button onClick={(e) => { navigate(`/explore`, { state: { detail: 'company' } }) }} className="w-500" sx={{ fontSize: 16, mb: 1, color: '#2457B9', textTransform: 'none' }} variant="text">  {translate('page_explore.perusahaan')}</Button>
              <Typography className="w-500" sx={{ fontSize: 16, mb: 1, mr: 1, color: '#56617B' }}>
                /
              </Typography>
              {page === 'profile' &&
                <Typography className="w-500" sx={{ fontSize: 16, mb: 1, color: '#56617B' }}>

                  {translate('status.editprofileperusahaan')}
                </Typography>}
              {page === 'alamat' &&
                <Typography className="w-500" sx={{ fontSize: 16, mb: 1, color: '#56617B' }}>
                  {translate('status.editdetailalamat')}
                </Typography>}
              {page === 'perizinan' &&
                <Typography className="w-500" sx={{ fontSize: 16, mb: 1, color: '#56617B' }}>
                  {translate('status.editperizinan')}
                </Typography>}
              {page === 'direksi' &&
                <Typography className="w-500" sx={{ fontSize: 16, mb: 1, color: '#56617B' }}>
                  {translate('status.editdireksi')}
                </Typography>}
              {page === 'pemegangsaham' &&
                <Typography className="w-500" sx={{ fontSize: 16, mb: 1, color: '#56617B' }}>
                  {translate('status.editpemegangsaham')}
                </Typography>}
            </Grid>
          </Hidden>
          <Hidden only={['md', 'lg', 'xl']}>
            <Grid container direction="row" alignItems='center' className='mt-18'>
              <Button href="/profile" className="w-500" sx={{ fontSize: 14, mb: 1, color: '#2457B9', textTransform: 'none' }} variant="text"> {translate('navbar.profile')}</Button>
              <Typography className="w-500" sx={{ fontSize: 14, mb: 1, color: '#56617B' }}>
                /
              </Typography>
              <Button onClick={(e) => { navigate(`/explore`, { state: { detail: 'company' } }) }} className="w-500" sx={{ fontSize: 14, mb: 1, color: '#2457B9', textTransform: 'none' }} variant="text">  {translate('page_explore.perusahaan')}</Button>
              <Typography className="w-500" sx={{ fontSize: 14, mb: 1, mr: 1, color: '#56617B' }}>
                /
              </Typography>
              <Button className="w-500" sx={{ fontSize: 16, mb: 1, color: '#2457B9', textTransform: 'none' }} variant="text">  {translate('page_explore.perusahaan')}</Button>
              <Typography className="w-500" sx={{ fontSize: 16, mb: 1, mr: 1, color: '#56617B' }}>
                /
              </Typography>
              {page === 'profile' &&
                <Typography className="w-500" sx={{ fontSize: 16, mb: 1, color: '#56617B' }}>
                  {translate('status.editprofileperusahaan')}
                </Typography>}
              {page === 'alamat' &&
                <Typography className="w-500" sx={{ fontSize: 16, mb: 1, color: '#56617B' }}>
                  {translate('status.editdetailalamat')}
                </Typography>}
              {page === 'perizinan' &&
                <Typography className="w-500" sx={{ fontSize: 16, mb: 1, color: '#56617B' }}>
                  {translate('status.editperizinan')}
                </Typography>}
              {page === 'direksi' &&
                <Typography className="w-500" sx={{ fontSize: 16, mb: 1, color: '#56617B' }}>
                  {translate('status.editdireksi')}
                </Typography>}
              {page === 'pemegangsaham' &&
                <Typography className="w-500" sx={{ fontSize: 16, mb: 1, color: '#56617B' }}>
                  {translate('status.editpemegangsaham')}
                </Typography>}
            </Grid>
          </Hidden>


          {/* Edit Profile Perusahaan */}
          {page === 'profile' ? <div>
            <Grid container direction="column" alignItems="center" justifyContent="center" className='mt-18'>
              <img src={perusahaanIcon} alt='perusahaan-icon' />
              <Typography className="w-700" sx={{ fontSize: 32, mt: '16px', color: '#081130', mb: '32px' }}>
                {translate('status.profilecompany')}
              </Typography>
            </Grid>

            <Grid container direction="row" justifyContent='space-between' sx={{ mb: 2 }} >
              <Grid item xs={12} sm={12} md={3} lg={3} >

              </Grid>
              <MenuFormDetailCompany triggerValidation={triggerValidation} urlprofile={urlprofile} idjenisperusahaan={idjenisperusahaan} setIdPerusahaan={setIdPerusahaan} nib={nib} setnib={setnib} name={name} setName={setName} handleChangeFileProfile={handleChangeFileProfile} type_list={type_list} selecttype={selecttype} handleChangeBidangPerusahaan={handleChangeBidangPerusahaan} phone_number={phone_number} setPhone_number={setPhone_number} email={email} setEmail={setEmail} website={website} setWebsite={setWebsite} description={description} setDesc={setDesc} imgprofile={imgprofile} UploadProfileIcon={UploadProfileIcon} DefaultProfileImg={DefaultProfileImg} jenisperusahaan={jenisperusahaan} alternative_name={alternative_name} setalternativename={setalternativename} ></MenuFormDetailCompany>
              <Grid item xs={3} className='wd-252'>
              </Grid>
            </Grid>

            {/* Akta Perusahaan Section */}
            <Grid container direction='row' justifyContent='space-between' sx={{ mb: 2 }}>
              <Grid item xs={12} sm={12} md={3} lg={3} >

              </Grid>
              <MenuFormAktaCompany triggerValidation={triggerValidation} isOpenUploadAkta={isOpenUploadAkta} handleCloseModalUploadAkta={handleCloseModalUploadAkta} setakta_nomor={setakta_nomor} akta_nomor={akta_nomor} akta_description={akta_description} setakta_description={setakta_description} handleOpenModalUploadAkta={() => handleOpenModalUploadAkta()} akta={akta} doc_icon={doc_icon} setUploadAkta={setUploadAkta} ></MenuFormAktaCompany>
              <Grid item xs={3} className='wd-252'>
              </Grid>
            </Grid>

            {/* Surat NPWP Section */}
            <Grid container direction='row' justifyContent='space-between'>
              <Grid item xs={12} sm={12} md={3} lg={3} >

              </Grid>
              <MenuFormNPWP triggerValidation={triggerValidation} npwp_nomor={npwp_nomor} setnpwp_nomor={setnpwp_nomor} npwp_name={npwp_name} setnpwpname={setnpwpname} npwp_description={npwp_description} setnpwp_description={setnpwp_description} isOpenUploadNpwp={isOpenUploadNpwp} handleCloseModalUploadNpwp={handleCloseModalUploadNpwp} handleOpenModalUploadNpwp={handleOpenModalUploadNpwp} doc_icon={doc_icon} npwp={npwp} setUploadnpwp={setUploadnpwp} ></MenuFormNPWP>
              <Grid item xs={3} className='wd-252'>
              </Grid>
            </Grid>

            <Hidden smDown>
              <Grid className='mb-104 in-container' container justifyContent='space-between' direction='row'>
                <Button
                  onClick={() => navigate(-1)}
                  size="large"
                  className="w-500"
                  variant="text"
                  style={{
                    color: '#2457B9',
                    textTransform: 'none',
                    padding: '12px 16px',
                    borderRadius: '8px',
                    height: '44px'
                  }}
                >
                  {translate('jual-beli.btn-cancel')}
                </Button>
                <Button
                  onClick={(e) => { submitUpdateCompanyInfo() }}
                  size="large"
                  className="w-500"
                  variant="contained"
                  sx={{
                    background: ' #2457B9',
                    textTransform: 'none',
                    padding: '12px 16px',
                    borderRadius: '8px',
                    height: '44px'
                  }}
                >
                  {translate('status.simpanperubahan')}
                </Button>
              </Grid>
            </Hidden>
            <Hidden smUp>
              <Grid spacing={1} container justifyContent='space-between' direction='row' style={{ marginBottom: '80px' }}>
                <Grid item xs={6}>
                  <Button
                    href='/'
                    fullWidth
                    size="small"
                    className="w-500"
                    variant="outlined"
                    sx={{
                      color: '#2457B9',
                      border: '1px solid var(--primarry-blue, #2457B9)',
                      textTransform: 'none',
                      padding: '12px 16px',
                      borderRadius: '8px',
                      height: '44px'
                    }}
                  >
                    {translate('jual-beli.btn-cancel')}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    onClick={(e) => { submitUpdateCompanyInfo() }}
                    size="small"
                    className="w-500"
                    variant="contained"
                    sx={{
                      background: ' #2457B9',
                      textTransform: 'none',
                      padding: '12px 16px',
                      borderRadius: '8px',
                      height: '44px'
                    }}
                  >
                    {translate('status.simpanperubahan')}
                  </Button>
                </Grid>
              </Grid>
            </Hidden>
          </div> : ''}
          {/* Edit Detail Alamat */}
          {page === 'alamat' ? <div>
            <Grid container direction="column" alignItems="center" justifyContent="center" className='mt-18'>
              <img src={LocationIcon} alt='perusahaan-icon' />
              <Typography className="w-700" sx={{ fontSize: 32, mt: '16px', color: '#081130', mb: '32px' }}>
                {translate('status.detailalamat')}
              </Typography>
            </Grid>
            <Grid container direction='row' justifyContent='space-between'>
              <Grid item xs={12} sm={12} md={3} lg={3}>

              </Grid>
              <MenuFormAddress triggerValidation={triggerValidation} arrayaddress={arrayaddress} id_provinsi={id_provinsi} changeProvince={changeProvince} listOfProvince={listOfProvince} listKota={x.alamat_list} id_city={id_city} listOfcity={listOfcity} kode_pos={kode_pos} setKode_pos={setKode_pos} CurrentAddress={CurrentAddress} setCurrentAddress={setCurrentAddress} label_alamat={label_alamat} idlabelalamat={idlabelalamat} setidlabelalamat={setidlabelalamat} handleAddAddress={handleAddAddress} handleDeleteAddress={handleDeleteAddress} handleChangeAlamatLengkap={handleChangeAlamatLengkap} handleChangeLabel={handleChangeLabel} changeCity={changeCity} handleChangeKodepos={handleChangeKodepos} handleCurrentAddress={handleCurrentAddress}></MenuFormAddress>
              <Grid item xs={3} className='wd-252'>
              </Grid>
            </Grid>
            <Grid container>
              <Grid xs={12} className='line mt-24 mb-32' />
            </Grid>
            <Hidden smDown>
              <Grid className='mb-104 in-container' container justifyContent='space-between' direction='row'>
                <Button
                  onClick={() => navigate(-1)}
                  size="large"
                  className="w-500"
                  variant="text"
                  style={{
                    color: '#2457B9',
                    textTransform: 'none',
                    padding: '12px 16px',
                    borderRadius: '8px',
                    height: '44px'
                  }}
                >
                  {translate('jual-beli.btn-cancel')}
                </Button>
                <Button
                  onClick={(e) => { submitUpdateCompanyAddress() }}
                  size="large"
                  className="w-500"
                  variant="contained"
                  sx={{
                    background: ' #2457B9',
                    textTransform: 'none',
                    padding: '12px 16px',
                    borderRadius: '8px',
                    height: '44px'
                  }}
                >
                  {translate('status.simpanperubahan')}
                </Button>
              </Grid>
            </Hidden>
            <Hidden smUp>
              <Grid spacing={1} container justifyContent='space-between' direction='row' style={{ marginBottom: '80px' }}>
                <Grid item xs={6}>
                  <Button
                    href='/'
                    fullWidth
                    size="small"
                    className="w-500"
                    variant="outlined"
                    sx={{
                      color: '#2457B9',
                      border: '1px solid var(--primarry-blue, #2457B9)',
                      textTransform: 'none',
                      padding: '12px 16px',
                      borderRadius: '8px',
                      height: '44px'
                    }}
                  >
                    {translate('jual-beli.btn-cancel')}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    onClick={(e) => { submitUpdateCompanyAddress() }}
                    size="small"
                    className="w-500"
                    variant="contained"
                    sx={{
                      background: ' #2457B9',
                      textTransform: 'none',
                      padding: '12px 16px',
                      borderRadius: '8px',
                      height: '44px'
                    }}
                  >
                    {translate('status.simpanperubahan')}
                  </Button>
                </Grid>
              </Grid>
            </Hidden>
          </div> : ''}
          {/* Perizinan */}
          {page === 'perizinan' ? <div>
            <Grid container direction="column" alignItems="center" justifyContent="center" className='mt-18'>
              <img src={LocationIcon} alt='perusahaan-icon' />
              <Typography className="w-700" sx={{ fontSize: 32, mt: '16px', color: '#081130', mb: '32px' }}>
                {translate('status.perizinan')}
              </Typography>
            </Grid>
            <Grid container direction='row' justifyContent='space-between'>
              <Grid item xs={12} sm={12} md={3} lg={3} >

              </Grid>

              {/* <MenuFormPerizinan perizinan={perizinan} delete={handleDeletePerizinan} handleChangeFilePerizinan={handleChangeFilePerizinan} handleChangeNamaPerizinan={handleChangeNamaPerizinan} handleChangeNomorPerizinan={handleChangeNomorPerizinan} handleChangeKodeWIUP={handleChangeKodeWIUP} handleChangeTahapanKegiatan={handleChangeTahapanKegiatan} handleChangeKomoditas={handleChangeKomoditas} handleChangeLuas={handleChangeLuas} calendarIcon={calendarIcon} handleChangeTahapanCNC={handleChangeTahapanCNC} handleChangeLokasi={handleChangeLokasi} handleChangeStartPerizinan={handleChangeStartPerizinan} handleChangeEndPerizinan={handleChangeEndPerizinan} handleAddPerizinan={handleAddPerizinan} doc_icon={doc_icon} AdapterDayjs={AdapterDayjs} DateIcon={DateIcon}></MenuFormPerizinan> */}
              <MenuFormPerizinan perizinan={perizinan} handleDeletePerizinan={handleDeletePerizinan} handleChangeFilePerizinan={handleChangeFilePerizinan} handleChangeNamaPerizinan={handleChangeNamaPerizinan} handleChangeNomorPerizinan={handleChangeNomorPerizinan} handleChangeKodeWIUP={handleChangeKodeWIUP} handleChangeTahapanKegiatan={handleChangeTahapanKegiatan} handleChangeKomoditas={handleChangeKomoditas} handleChangeLuas={handleChangeLuas} calendarIcon={calendarIcon} handleChangeTahapanCNC={handleChangeTahapanCNC} handleChangeLokasi={handleChangeLokasi} handleChangeStartPerizinan={handleChangeStartPerizinan} handleChangeEndPerizinan={handleChangeEndPerizinan} handleAddPerizinan={handleAddPerizinan} doc_icon={doc_icon} AdapterDayjs={AdapterDayjs} DateIcon={DateIcon}></MenuFormPerizinan>
              <Grid item xs={3} className='wd-252'>
              </Grid>
            </Grid>
            <Grid container>
              <Grid xs={12} className='line mt-24 mb-32' />
            </Grid>
            <Hidden smDown>
              <Grid className='mb-104 in-container' container justifyContent='space-between' direction='row'>
                <Button
                  onClick={() => navigate(-1)}
                  size="large"
                  className="w-500"
                  variant="text"
                  style={{
                    color: '#2457B9',
                    textTransform: 'none',
                    padding: '12px 16px',
                    borderRadius: '8px',
                    height: '44px'
                  }}
                >
                  {translate('jual-beli.btn-cancel')}
                </Button>
                <Button
                  onClick={(e) => { submitUpdateCompanyPerizinan() }}
                  size="large"
                  className="w-500"
                  variant="contained"
                  sx={{
                    background: ' #2457B9',
                    textTransform: 'none',
                    padding: '12px 16px',
                    borderRadius: '8px',
                    height: '44px'
                  }}
                >
                  {translate('status.simpanperubahan')}
                </Button>
              </Grid>
            </Hidden>
            <Hidden smUp>
              <Grid spacing={1} container justifyContent='space-between' direction='row' style={{ marginBottom: '80px' }}>
                <Grid item xs={6}>
                  <Button
                    href='/'
                    fullWidth
                    size="small"
                    className="w-500"
                    variant="outlined"
                    sx={{
                      color: '#2457B9',
                      border: '1px solid var(--primarry-blue, #2457B9)',
                      textTransform: 'none',
                      padding: '12px 16px',
                      borderRadius: '8px',
                      height: '44px'
                    }}
                  >
                    {translate('jual-beli.btn-cancel')}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    onClick={(e) => { submitUpdateCompanyPerizinan() }}
                    size="small"
                    className="w-500"
                    variant="contained"
                    sx={{
                      background: ' #2457B9',
                      textTransform: 'none',
                      padding: '12px 16px',
                      borderRadius: '8px',
                      height: '44px'
                    }}
                  >
                    {translate('status.simpanperubahan')}
                  </Button>
                </Grid>
              </Grid>
            </Hidden>
          </div> : ''}
          {/* Direksi */}
          {page === 'direksi' ? <div>
            <Grid container direction="column" alignItems="center" justifyContent="center" className='mt-18'>
              <img src={inviteStaffIcon} alt='perusahaan-icon' />
              <Typography className="w-700" sx={{ fontSize: 32, mt: '16px', color: '#081130', mb: '32px' }}>
                {translate('status.direksi')}
              </Typography>
            </Grid>
            <Grid container direction="row" justifyContent='space-between' >
              <Grid item xs={12} sm={12} md={3} lg={3} >

              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} className='form-input'>
                {direksi.map((item, i) => (
                  <MenuFormDireksi triggerValidation={triggerValidation} item={item} i={i} direksi={direksi} handleDeleteDireksi={handleDeleteDireksi} handleChangeImgDireksi={handleChangeImgDireksi} posisiperusahaan={posisiperusahaan} handleChangeDireksi={handleChangeDireksi} handleAddDireksi={handleAddDireksi} AdapterDayjs={AdapterDayjs} DateIcon={DateIcon} openStartDate={openStartDate} openEndDate={openEndDate} startdate={startdate} handleChangeStartPeriode={handleChangeStartPeriode} enddate={enddate} handleChangeEndPeriode={handleChangeEndPeriode} setopenEndDate={setopenEndDate} setopenStartDate={setopenStartDate} DefaultProfileImg={DefaultProfileImg} UploadProfileIcon={UploadProfileIcon} calendarIcon={calendarIcon} urldireksi={urldireksi} id_posisi={id_posisi} handleChangePosisiDireksi={handleChangePosisiDireksi}></MenuFormDireksi>
                ))}
                <Stack onClick={() => handleAddDireksi()} direction={'row'} alignItems={'center'} sx={{ cursor: 'pointer', mt: 2 }}>
                  <AddIcon sx={{
                    fontSize: 20, color: "#2457B9"
                  }} />
                  <Typography className="w-700" style={{ fontSize: 16, color: '#2457B9' }}>
                    {translate('status.tambahdata')}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={3} className='wd-252'>
              </Grid>
            </Grid>
            <Grid container>
              <Grid xs={12} className='line mt-24 mb-56' />
            </Grid>

            <Hidden smDown>
              <Grid className='mb-104 in-container' container justifyContent='space-between' direction='row'>
                <Button
                  onClick={() => navigate(-1)}
                  size="large"
                  className="w-500"
                  variant="text"
                  style={{
                    color: '#2457B9',
                    textTransform: 'none',
                    padding: '12px 16px',
                    borderRadius: '8px',
                    height: '44px'
                  }}
                >
                  {translate('jual-beli.btn-cancel')}
                </Button>
                <Button
                  onClick={(e) => { submitUpdateCompanyDireksi() }}
                  size="large"
                  className="w-500"
                  variant="contained"
                  sx={{
                    background: ' #2457B9',
                    textTransform: 'none',
                    padding: '12px 16px',
                    borderRadius: '8px',
                    height: '44px'
                  }}
                >
                  {translate('status.simpanperubahan')}
                </Button>
              </Grid>
            </Hidden>
            <Hidden smUp>
              <Grid spacing={1} container justifyContent='space-between' direction='row' style={{ marginBottom: '80px' }}>
                <Grid item xs={6}>
                  <Button
                    href='/'
                    fullWidth
                    size="small"
                    className="w-500"
                    variant="outlined"
                    sx={{
                      color: '#2457B9',
                      border: '1px solid var(--primarry-blue, #2457B9)',
                      textTransform: 'none',
                      padding: '12px 16px',
                      borderRadius: '8px',
                      height: '44px'
                    }}
                  >
                    {translate('jual-beli.btn-cancel')}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    onClick={(e) => { submitUpdateCompanyDireksi() }}
                    size="small"
                    className="w-500"
                    variant="contained"
                    sx={{
                      background: ' #2457B9',
                      textTransform: 'none',
                      padding: '12px 16px',
                      borderRadius: '8px',
                      height: '44px'
                    }}
                  >
                    {translate('status.simpanperubahan')}
                  </Button>
                </Grid>
              </Grid>
            </Hidden>
          </div> : ''}
          {/* Pemegang Saham */}
          {page === 'pemegangsaham' ? <div>
            <Grid container direction="column" alignItems="center" justifyContent="center" className='mt-18'>
              <img src={inviteStaffIcon} alt='perusahaan-icon' />
              <Typography className="w-700" sx={{ fontSize: 32, mt: '16px', color: '#081130', mb: '32px' }}>
                {translate('status.pemegangsaham')}
              </Typography>
            </Grid>
            <Grid container direction="row" justifyContent='space-between' >
              <Grid item xs={12} sm={12} md={3} lg={3} >

              </Grid>
              <MenuPemegangSaham triggerValidation={triggerValidation} pemegangsaham={pemegangsaham} handleDeletePemegangSaham={handleDeletePemegangSaham} jenissaham={jenissaham} handleChangePemegangSaham={handleChangePemegangSaham} id_bendera={id_bendera} handleSelectbendera={handleSelectbendera} flagList={flagList} handleAddPemegangSaham={handleAddPemegangSaham} handleChangeJenisSaham={handleChangeJenisSaham} handleChangePercentaseSaham={handleChangePercentaseSaham} ></MenuPemegangSaham>
              <Grid item xs={3} className='wd-252'>
              </Grid>
            </Grid>
            <Grid container>
              <Grid xs={12} className='line mt-24 mb-32' />
            </Grid>

            <Hidden smDown>
              <Grid className='mb-104 in-container' container justifyContent='space-between' direction='row'>
                <Button
                  onClick={() => navigate(-1)}
                  size="large"
                  className="w-500"
                  variant="text"
                  style={{
                    color: '#2457B9',
                    textTransform: 'none',
                    padding: '12px 16px',
                    borderRadius: '8px',
                    height: '44px'
                  }}
                >
                  {translate('jual-beli.btn-cancel')}
                </Button>
                <Button
                  onClick={(e) => { submitUpdateCompanySaham() }}
                  size="large"
                  className="w-500"
                  variant="contained"
                  sx={{
                    background: ' #2457B9',
                    textTransform: 'none',
                    padding: '12px 16px',
                    borderRadius: '8px',
                    height: '44px'
                  }}
                >
                  {translate('status.simpanperubahan')}
                </Button>
              </Grid>
            </Hidden>
            <Hidden smUp>
              <Grid spacing={1} container justifyContent='space-between' direction='row' style={{ marginBottom: '80px' }}>
                <Grid item xs={6}>
                  <Button
                    href='/'
                    fullWidth
                    size="small"
                    className="w-500"
                    variant="outlined"
                    sx={{
                      color: '#2457B9',
                      border: '1px solid var(--primarry-blue, #2457B9)',
                      textTransform: 'none',
                      padding: '12px 16px',
                      borderRadius: '8px',
                      height: '44px'
                    }}
                  >
                    {translate('jual-beli.btn-cancel')}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    onClick={(e) => { submitUpdateCompanySaham() }}
                    size="small"
                    className="w-500"
                    variant="contained"
                    sx={{
                      background: ' #2457B9',
                      textTransform: 'none',
                      padding: '12px 16px',
                      borderRadius: '8px',
                      height: '44px'
                    }}
                  >
                    {translate('status.simpanperubahan')}
                  </Button>
                </Grid>
              </Grid>
            </Hidden>
          </div> : ''}
        </Box>
      </Box>
      <Footer />

      {/* <ModalSuccessCreateCompany ok={handleOkModal} open={openModal} close={handleCloseModal}></ModalSuccessCreateCompany> */}
    </div >
  )
}
