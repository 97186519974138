import Divider from "@material-ui/core/Divider";
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Button, FormControl, Grid, InputAdornment, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import Drawer from '@mui/material/Drawer';
import React from 'react';
import { useTranslation } from "react-i18next";
import { GlobalState } from "../services/global";
import { VerticalDividerLeft } from '../services/primary';

export default function DrawerFilterTongkang(props) {
  const [translate,] = useTranslation("global");
  const { currency } = React.useContext(GlobalState)

  return (
    <Box sx={{ flexGrow: 1, display: { xs: "flex", sm: 'flex', md: "flex" }, zIndex: 9999 }}>
      <Drawer
        className="box-filter-drawer"
        anchor="bottom"
        open={Boolean(props.open)}
        onClose={props.close}
      >
        <Box >
          <Stack
            direction="row"
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{ pl: '16px', pt: '19px', pr: 2, pb: '19px' }}
          >
            <Typography className="w-700" sx={{ fontSize: 18, color: '#081130' }}>
              Filter
            </Typography>
            <Stack direction={'row'} alignItems={'center'}>
              <Button className="w-500" onClick={(e) => {

                props.resetFilterTongkang('mobile')

              }} sx={{ fontSize: 16, color: '#2457B9', textTransform: 'none' }} variant="text"> Reset filter</Button>
              <Divider orientation="vertical" style={{ marginTop: 10, marginBottom: 10, marginLeft: 10, marginRight: 10 }} flexItem />
              <CloseIcon onClick={props.close} />
            </Stack>
          </Stack>



          <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
            <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
              {translate('page_explore.tongkang')}
            </Typography>
            <Grid
              container
              sx={{ mt: 1 }}
              direction="row"
              justifyContent={'space-between'}
              spacing={1}
            >
              <Grid item xs={6}>
                <Button onClick={(e) => props.handleFilterTongkang("", "type_tongkang", 'mobile')}
                  value={props.filterTongkang["type_tongkang"]} sx={{
                    "&:hover": {
                      borderColor: props.filterTongkang["type_tongkang"] === '' ? '#3BB56C' : '#E8EBF2',
                    }, textTransform: 'none', borderColor: props.filterTongkang["type_tongkang"] === '' ? '#3BB56C' : '#E8EBF2', color: props.filterTongkang["type_tongkang"] === '' ? '#3BB56C' : 'black', justifyContent: 'left', width: '100%', borderRadius: '8px', p: '14px 12px'
                  }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterTongkang["type_tongkang"] === '' ? '#3BB56C' : '#E8EBF2' }} />}>
                  {translate('page_explore.semua')}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button onClick={(e) => props.handleFilterTongkang("1", "type_tongkang", 'mobile')}
                  value={props.filterTongkang["type_tongkang"]}
                  sx={{
                    "&:hover": {
                      borderColor: props.filterTongkang["type_tongkang"] === '1' || props.filterTongkang["type_tongkang"] === 1 ? '#3BB56C' : '#E8EBF2',
                    },
                    textTransform: 'none', borderColor: props.filterTongkang["type_tongkang"] === '1' || props.filterTongkang["type_tongkang"] === 1 ? '#3BB56C' : '#E8EBF2', color: props.filterTongkang["type_tongkang"] === '1' || props.filterTongkang["type_tongkang"] === 1 ? '#3BB56C' : 'black', justifyContent: 'left', width: '100%', borderRadius: '8px', p: '14px 12px'
                  }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterTongkang["type_tongkang"] === '1' || props.filterTongkang["type_tongkang"] === 1 ? '#3BB56C' : '#E8EBF2', }} />}>
                  {translate('page_explore.penjualan')}
                </Button>
              </Grid>


            </Grid>

            <Grid
              container
              direction="row"
              justifyContent={'space-between'}
              sx={{ mt: 1 }}
              spacing={1}
            >
              <Grid item xs={6}>
                <Button onClick={(e) => { props.handleFilterTongkang("2", "type_tongkang", 'mobile'); props.handleFilterSewaTongkang("time", "tipe_sewa") }}
                  value={props.filterTongkang["type_tongkang"]} sx={{
                    "&:hover": {
                      borderColor: props.filterTongkang["type_tongkang"] === '2' || props.filterTongkang["type_tongkang"] === '3' || props.filterTongkang["type_tongkang"] === 2 || props.filterTongkang["type_tongkang"] === 3 ? '#3BB56C' : '#E8EBF2',
                    },
                    textTransform: 'none', justifyContent: 'left', borderColor: props.filterTongkang["type_tongkang"] === '2' || props.filterTongkang["type_tongkang"] === '3' || props.filterTongkang["type_tongkang"] === 2 || props.filterTongkang["type_tongkang"] === 3 ? '#3BB56C' : '#E8EBF2', color: props.filterTongkang["type_tongkang"] === '2' || props.filterTongkang["type_tongkang"] === '3' || props.filterTongkang["type_tongkang"] === 2 || props.filterTongkang["type_tongkang"] === 3 ? '#3BB56C' : 'black', width: '100%', borderRadius: '8px', p: '14px 12px'
                  }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterTongkang["type_tongkang"] === '2' || props.filterTongkang["type_tongkang"] === '3' || props.filterTongkang["type_tongkang"] === 2 || props.filterTongkang["type_tongkang"] === 3 ? '#3BB56C' : '#E8EBF2', }} />}>
                  {translate('page_explore.penyewaan')}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button onClick={(e) => props.handleFilterTongkang("4", "type_tongkang", 'mobile')}
                  value={props.filterTongkang["type_tongkang"]} sx={{
                    "&:hover": {
                      borderColor: props.filterTongkang["type_tongkang"] === '4' || props.filterTongkang["type_tongkang"] === 4 ? '#3BB56C' : '#E8EBF2',
                    }, textTransform: 'none', borderColor: props.filterTongkang["type_tongkang"] === '4' || props.filterTongkang["type_tongkang"] === 4 ? '#3BB56C' : '#E8EBF2', color: props.filterTongkang["type_tongkang"] === '4' || props.filterTongkang["type_tongkang"] === 4 ? '#3BB56C' : 'black', justifyContent: 'left', width: '100%', borderRadius: '8px', p: '14px 12px'
                  }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterTongkang["type_tongkang"] === '4' || props.filterTongkang["type_tongkang"] === 4 ? '#3BB56C' : '#E8EBF2' }} />}>
                  {translate('page_explore.pembelian')}
                </Button>
              </Grid>
            </Grid>
          </Box>
          {props.filterTongkang["type_tongkang"] === '2' || props.filterTongkang["type_tongkang"] === '3' || props.filterTongkang["type_tongkang"] === 2 || props.filterTongkang["type_tongkang"] === 3 ? <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
            <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
              {translate('permintaan.tipe-sewa')}
            </Typography>
            <Grid
              container
              direction="column"
              justifyContent={'space-between'}
              sx={{ mt: 1, pl: 2, pb: 2 }}
              spacing={2}
            >
              <Button onClick={(e) => {
                props.handleFilterSewaTongkang("time", "tipe_sewa")
                props.handleFilterTongkang("2", "type_tongkang", 'mobile')
              }}
                value={props.filterSewaTongkang["tipe_sewa"]} sx={{
                  "&:hover": {
                    borderColor: props.filterSewaTongkang["tipe_sewa"] === 'time' ? '#3BB56C' : '#E8EBF2',
                  }, textTransform: 'none', justifyContent: 'left', borderColor: props.filterSewaTongkang["tipe_sewa"] === 'time' ? '#3BB56C' : '#E8EBF2', color: props.filterSewaTongkang["tipe_sewa"] === 'time' ? '#3BB56C' : 'black', mb: 1, borderRadius: '8px', p: '14px 12px'
                }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterSewaTongkang["tipe_sewa"] === 'time' ? '#3BB56C' : '#E8EBF2', }} />}>
                Time charter
              </Button>
              <Button onClick={(e) => {
                props.handleFilterSewaTongkang("freight", "tipe_sewa")
                props.handleFilterTongkang("3", "type_tongkang", 'mobile')
              }}
                value={props.filterSewaTongkang["tipe_sewa"]} sx={{
                  "&:hover": {
                    borderColor: props.filterSewaTongkang["tipe_sewa"] === 'freight' ? '#3BB56C' : '#E8EBF2',
                  },
                  textTransform: 'none', justifyContent: 'left', borderColor: props.filterSewaTongkang["tipe_sewa"] === 'freight' ? '#3BB56C' : '#E8EBF2', color: props.filterSewaTongkang["tipe_sewa"] === 'freight' ? '#3BB56C' : 'black', borderRadius: '8px', p: '14px 12px'
                }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterSewaTongkang["tipe_sewa"] === 'freight' ? '#3BB56C' : '#E8EBF2', }} />}>
                Freight charter
              </Button>
            </Grid>

          </Box> : ''}
          {props.page === 'explore' && <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
            <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '4px' }}>
              Label
            </Typography>
            <Grid
              container
              direction={{ xs: 'column', sm: 'column', md: 'row' }}
              spacing={1}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                <Button onClick={(e) => props.handleFilterTongkang("", "label", 'mobile')}
                  value={props.filterTongkang["label"]} sx={{
                    "&:hover": {
                      borderColor: props.filterTongkang["label"] === '' ? '#3BB56C' : '#E8EBF2',
                    },
                    textTransform: 'none', borderColor: props.filterTongkang["label"] === '' ? '#3BB56C' : '#E8EBF2',
                    color: props.filterTongkang["label"] === '' ? '#3BB56C' : 'black', justifyContent: 'left', width: '100%', borderRadius: '8px', p: '14px 12px'
                  }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterTongkang["label"] === '' ? '#3BB56C' : '#E8EBF2' }} />}>
                  {translate('page_explore.semua')}
                </Button>
              </Grid>
              <Grid sx={{ mt: { xs: '8px', sm: '8px', md: '8px', lg: '8px', xl: 0 } }} item xs={12} sm={12} md={12} lg={12} xl={6}>
                <Button onClick={(e) => props.handleFilterTongkang("penawaran", "label", 'mobile')}
                  value={props.filterTongkang["label"]} sx={{
                    "&:hover": {
                      borderColor: props.filterTongkang["label"] === 'penawaran' ? '#3BB56C' : '#E8EBF2',
                    }, textTransform: 'none', borderColor: props.filterTongkang["label"] === 'penawaran' ? '#3BB56C' : '#E8EBF2', color: props.filterTongkang["label"] === 'penawaran' ? '#3BB56C' : 'black', justifyContent: 'left', width: '100%', borderRadius: '8px', p: '14px 12px'
                  }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterTongkang["label"] === 'penawaran' ? '#3BB56C' : '#E8EBF2', }} />}>
                  {translate('navbar.penawaran')}
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent={'space-between'}
              sx={{ mt: '8px' }}
              spacing={1}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                <Button onClick={(e) => { props.handleFilterTongkang("permintaan", "label", 'mobile'); }}
                  value={props.filterTongkang["label"]} sx={{
                    "&:hover": {
                      borderColor: props.filterTongkang["label"] === 'permintaan' ? '#3BB56C' : '#E8EBF2',
                    }, textTransform: 'none', justifyContent: 'left', borderColor: props.filterTongkang["label"] === 'permintaan' ? '#3BB56C' : '#E8EBF2', color: props.filterTongkang["label"] === 'permintaan' ? '#3BB56C' : 'black', width: '100%', borderRadius: '8px', p: '14px 12px'
                  }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterTongkang["label"] === 'permintaan' ? '#3BB56C' : '#E8EBF2', }} />}>
                  {translate('navbar.permintaan')}
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
              </Grid>
            </Grid>
          </Box>}

          <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
            <Typography className="w-500" sx={{ fontSize: 16, color: '#081130', mb: '2px' }}>
              {translate('page_explore.spesifikasi')}
            </Typography>
            <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
              {translate('page_explore.ukurantongkang')}
            </Typography>
            <FormControl sx={{ mt: 1, width: '100%' }}>
              <Select
                IconComponent={() => (
                  <KeyboardArrowDownIcon sx={{ mr: '16px', width: '20px', height: '20px', fill: '#56617B' }} />
                )}
                style={{ border: '1px solid var(--grey-stroke, #e8ebf2)', marginTop: '4px', borderRadius: '8px', }}
                onChange={(e) => props.handleFilterTongkang(e.target.value, "ukuran_tongkang", 'mobile')}
                value={props.filterTongkang["ukuran_tongkang"]}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value="">
                  <Typography className="w-400" style={{ color: '#8792AB', fontSize: 14 }}> {translate('page_explore.pilihukurantongkang')}</Typography>
                </MenuItem>
                <MenuItem value={180}>180 feet</MenuItem>
                <MenuItem value={230}>230 feet</MenuItem>
                <MenuItem value={300}>300 feet</MenuItem>
                <MenuItem value={310}>310 feet</MenuItem>
                <MenuItem value={320}>320 feet</MenuItem>
                <MenuItem value={330}>330 feet</MenuItem>
                <MenuItem value={0}>{translate('status.lainnya')}</MenuItem>
              </Select>
              {props.inputukurangtongkang === true ? <TextField
                className="box-input-filter"
                type="number"
                size="small"
                placeholder={translate('status.placeholderukurantongkang')}
                onChange={(e) => props.handleFilterUkuranTongkang(e.target.value, "ukuran_tongkang", 'mobile')}
                value={props.filterTongkang["ukuran_tongkang"]}
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                    display: "none",
                  },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                  width: '100%', input: {
                    fontSize: '14px', fontFamily: 'Satoshi-500'
                  },
                }}
              /> : ''}
            </FormControl>
          </Box>
          {/* <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
            <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '6px' }}>
              {translate('page_explore.quantitymuatan')}
            </Typography>
            <Grid
              container
              direction="row"
              justifyContent={'space-between'}
              spacing={2}
            >
              <Grid item xs={6}>
                <TextField
                  className="box-input-filter"
                  type="number"
                  size="small"
                  placeholder="Min"
                  onChange={(e) => props.handleFilterTongkang(e.target.value, "min_quantity_muatan", 'mobile')}
                  value={props.filterTongkang["min_quantity_muatan"]}
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                      display: "none",
                    },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                    width: '100%', input: {
                      fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                  }}
                />

              </Grid>
              <Grid item xs={6}>
                <TextField
                  className="box-input-filter"
                  type="number"
                  size="small"
                  placeholder="Maks"
                  onChange={(e) => props.handleFilterTongkang(e.target.value, "max_quantity_muatan", 'mobile')}
                  value={props.filterTongkang["max_quantity_muatan"]}
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                      display: "none",
                    },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                    width: '100%', input: {
                      fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Box> */}

          <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
            <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
              {translate('page_explore.hargatongkang')}
            </Typography>
            <TextField
              className="box-input-filter"
              type="number"
              size="small"
              placeholder={translate('status.min-harga')}
              onChange={(e) => props.handleFilterTongkang(e.target.value, "min_price", 'mobile')}
              value={props.filterTongkang["min_price"]}
              sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                  display: "none",
                },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
                width: '100%', input: {
                  fontSize: '14px', fontFamily: 'Satoshi-500'
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <VerticalDividerLeft sx={{ ml: '12px' }} />
                    {currency === 'idr' && <Typography className="w-700" sx={{ fontSize: 14, color: '#56617B', mr: '24px' }}>
                      Rp
                    </Typography>}
                    {currency === 'usd' && <Typography className="w-700" sx={{ fontSize: 16, color: '#56617B', mr: '24px' }}>
                      $
                    </Typography>}
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              className="box-input-filter"
              type="number"
              size="small"
              placeholder={translate('status.max-harga')}
              onChange={(e) => props.handleFilterTongkang(e.target.value, "max_price", 'mobile')}
              value={props.filterTongkang["max_price"]}
              sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                  display: "none",
                },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
                width: '100%', input: {
                  fontSize: '14px', fontFamily: 'Satoshi-500'
                }, mt: 1
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <VerticalDividerLeft sx={{ ml: '12px' }} />
                    {currency === 'idr' && <Typography className="w-700" sx={{ fontSize: 14, color: '#56617B', mr: '24px' }}>
                      Rp
                    </Typography>}
                    {currency === 'usd' && <Typography className="w-700" sx={{ fontSize: 16, color: '#56617B', mr: '24px' }}>
                      $
                    </Typography>}
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          {props.page === 'listing' && <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
            <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
              Listing status
            </Typography>
            <Grid
              container
              // sx={{ mt: 1 }}
              direction="row"
              justifyContent={'space-between'}
              spacing={1}
            >
              <Grid item xs={6}>
                <Button onClick={(e) => props.handleFilterTongkang("", "id_listing_status", 'mobile')}
                  value={props.filterTongkang["id_listing_status"]} sx={{
                    "&:hover": {
                      borderColor: props.filterTongkang["id_listing_status"] === '' ? '#3BB56C' : '#E8EBF2',
                    }, textTransform: 'none', justifyContent: 'left', borderColor: props.filterTongkang["id_listing_status"] === '' ? '#3BB56C' : '#E8EBF2', color: props.filterTongkang["id_listing_status"] === '' ? '#3BB56C' : 'black', borderRadius: '8px', p: '14px 16px'
                  }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterTongkang["id_listing_status"] === '' ? '#3BB56C' : '#E8EBF2', }} />}>
                  {translate('my-listing.btn-all')}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button onClick={(e) => props.handleFilterTongkang("1", "id_listing_status", 'mobile')}
                  value={props.filterTongkang["id_listing_status"]} sx={{
                    "&:hover": {
                      borderColor: props.filterTongkang["id_listing_status"] === '1' ? '#3BB56C' : '#E8EBF2',
                    }, textTransform: 'none', borderColor: props.filterTongkang["id_listing_status"] === '1' ? '#3BB56C' : '#E8EBF2', justifyContent: 'left', color: props.filterTongkang["id_listing_status"] === '1' ? '#3BB56C' : 'black', borderRadius: '8px', p: '14px 16px'
                  }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterTongkang["id_listing_status"] === '1' ? '#3BB56C' : '#E8EBF2', }} />}>
                  {translate('my-listing.btn-publish')}
                </Button>
              </Grid>


            </Grid>
            <Grid
              container
              direction="row"
              justifyContent={'space-between'}
              sx={{ mt: '2px' }}
              spacing={1}
            >
              <Grid item xs={6}>
                <Button onClick={(e) => props.handleFilterTongkang("2", "id_listing_status", 'mobile')}
                  value={props.filterTongkang["id_listing_status"]} sx={{
                    "&:hover": {
                      borderColor: props.filterTongkang["id_listing_status"] === '2' ? '#3BB56C' : '#E8EBF2',
                    }, textTransform: 'none', justifyContent: 'left', borderColor: props.filterTongkang["id_listing_status"] === '2' ? '#3BB56C' : '#E8EBF2', color: props.filterTongkang["id_listing_status"] === '2' ? '#3BB56C' : 'black', borderRadius: '8px', p: '14px 16px'
                  }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterTongkang["id_listing_status"] === '2' ? '#3BB56C' : '#E8EBF2', }} />}>
                  {translate('my-listing.btn-unpublish')}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button onClick={(e) => props.handleFilterTongkang("3", "id_listing_status", 'mobile')}
                  value={props.filterTongkang["id_listing_status"]} sx={{
                    "&:hover": {
                      borderColor: props.filterTongkang["id_listing_status"] === '3' ? '#3BB56C' : '#E8EBF2',
                    }, textTransform: 'none', justifyContent: 'left', borderColor: props.filterTongkang["id_listing_status"] === '3' ? '#3BB56C' : '#E8EBF2', color: props.filterTongkang["id_listing_status"] === '3' ? '#3BB56C' : 'black', borderRadius: '8px', p: '14px 16px'
                  }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterTongkang["id_listing_status"] === '3' ? '#3BB56C' : '#E8EBF2', }} />}>
                  {translate('my-listing.btn-draft')}
                </Button>
              </Grid>
            </Grid>
          </Box>}
        </Box>
        <Divider orientation="horizontal" style={{ marginTop: 10, marginBottom: 10, marginLeft: 16, marginRight: 16 }} />
        <Stack direction={'row'} spacing={1} sx={{ ml: 2, mr: 2, mb: 2 }}>

          <Button
            className="w-500"
            onClick={(e) => {
              props.resetFilterTongkang()
              props.handleCloseModalFilter()
            }}
            fullWidth
            variant="outlined"
            sx={{
              textTransform: "none", border: '1px solid var(--primarry-blue, #2457B9)',
              p: '12px 16px',
              borderRadius: '8px',
              color: '#2457B9'
            }}
          >

            {translate('jual-beli.btn-cancel')}
          </Button>
          <Button
            className="w-500"
            onClick={(e) => {
              props.Filter('tongkang')
              props.handleCloseModalFilter()
            }}
            fullWidth
            variant="contained"
            sx={{ mt: '52px', background: ' linear-gradient(270deg, #2457B9 15%, #2860CD 100%)', borderRadius: '8px', boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.12), 0px -1px 0px 0px rgba(0, 0, 0, 0.20) inset', padding: '12px 16px', textTransform: 'none' }}
          >
            {translate('page_explore.btn-filter')}
          </Button>
        </Stack>

      </Drawer>

    </Box>
  );
}