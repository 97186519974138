
import CloseIcon from '@mui/icons-material/Close';
import { Box, Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import laycanIcon from "../assets/laycan-ic.png";
import SizetongkangIcon from "../assets/sizetongkang-ic.png";

export default function CardPermintaanBeliTongkang(props) {
  const [translate,] = useTranslation("global");

  return (
    <Box>
      <Card sx={{ borderRadius: 2, marginTop: '16px', mb: "40px", padding: { sm: '20px', xs: '16px' }, border: '2px solid #2457B9' }}>
        <CardContent sx={{ padding: '0px !important' }}>
          <Box>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ marginBottom: "16px" }}
            >
              <Typography
                className="w-700"
                sx={{ color: '#081130', fontSize: { sm: '18px', xs: '16px' } }}
              >
                {Number(props.data.type_tongkang) === 4 ? translate('status.titlepembeliantongkang') : translate('status.titlepermintaantongkang')}
              </Typography>
              <CloseIcon onClick={() => { props.resetFunc() }} sx={{ color: '#56617B' }} />
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={0}
              sx={{ marginBottom: "16px" }}
            >
              <Stack direction={'row'} sx={{ textAlign: "right", alignItems: "center", display: 'flex' }}  >
                {props.data.price === '' ? (
                  <Typography
                    sx={{ fontSize: { sm: '20px', xs: '18px' }, color: '#081130', mr: 1 }}
                    className="w-700"
                  >
                    {props.data.currency === 'idr' ? 'Rp' : '$'} -
                  </Typography>
                ) : (
                  <Typography
                    sx={{ fontSize: { sm: '20px', xs: '18px' }, color: '#081130', mr: 1 }}
                    className="w-700"
                  >
                    {props.data.currency === 'idr' ? 'Rp' : '$'} {new Intl.NumberFormat().format(Number(props.data.price))}
                  </Typography>
                )}
              </Stack>
            </Stack>

            <Grid
              container
              direction="row"
              alignItems="left"
              justifyContent="left"
              spacing={{ xs: '12px', sm: '20px' }}
              sx={{ marginBottom: "16px" }}
            >
              <Grid item lg={2.4} md={4} xs={6}>
                <Box sx={{ background: "#F4F6FA", borderRadius: 2, padding: { sm: "12px 12px 12px 16px", xs: '12px' } }}>
                  <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>
                    <Typography className="w-700" sx={{ color: '#081130', fontSize: { sm: '20px', xs: '18px' }, }}>
                      {props?.data?.ukuran_tongkang === "" ? "-" : props?.data?.ukuran_tongkang}
                    </Typography>
                    <img alt="ukurantongkang-icon" src={SizetongkangIcon} height={20} width={20}></img>
                  </Stack>
                  <Typography className="w-500" sx={{ color: '#56617B', fontSize: { sm: '14px', xs: '12px' } }} >
                    {translate('page_explore.ukurantongkang')}
                  </Typography>
                </Box>
              </Grid>
              {/* <Grid item lg={2.4} md={4} xs={6}>
                <Box sx={{ background: "#F4F6FA", borderRadius: 2, padding: { sm: "12px 12px 12px 16px", xs: '12px' } }}>
                  <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>
                    <Typography className="w-700" sx={{ color: '#081130', fontSize: { sm: '20px', xs: '18px' }, }}>
                      {props?.data?.jenis_muatan === "" ? "-" : props?.data?.jenis_muatan}
                    </Typography>
                    <img alt="laycan-icon" src={jenismuatanIcon} height={20} width={20}></img>
                  </Stack>
                  <Typography className="w-500" sx={{ color: '#56617B', fontSize: { sm: '14px', xs: '12px' } }} >
                    {translate('jual-beli.jenismuatan')}
                  </Typography>
                </Box>
              </Grid> */}
              <Grid item lg={2.4} md={4} xs={6}>
                <Box sx={{ background: "#F4F6FA", borderRadius: 2, padding: { sm: "12px 12px 12px 16px", xs: '12px' } }}>
                  <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>
                    <Typography className="w-700" sx={{ color: '#081130', fontSize: { sm: '20px', xs: '18px' }, }}>
                      {props?.data?.tahun_buat === "" ? "-" : props?.data?.tahun_buat}
                    </Typography>
                    <img alt="laycan-icon" src={laycanIcon} height={20} width={20}></img>
                  </Stack>
                  <Typography className="w-500" sx={{ color: '#56617B', fontSize: { sm: '14px', xs: '12px' } }} >
                    {translate('permintaan.tahunbuat')}
                  </Typography>
                </Box>
              </Grid>
              {/* <Grid item lg={2.4} md={4} xs={6}>
                <Box sx={{ background: "#F4F6FA", borderRadius: 2, padding: { sm: "12px 12px 12px 16px", xs: '12px' } }}>
                  <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>
                    <Typography className="w-700" sx={{ color: '#081130', fontSize: { sm: '20px', xs: '18px' }, }}>
                      {props.data.bendera_name === '' ? '-' : props.data.bendera_name}
                    </Typography>
                    <img alt="flag-icon" src={flagIcon} height={20}></img>
                  </Stack>
                  <Typography className="w-500" sx={{ color: '#56617B', fontSize: { sm: '14px', xs: '12px' } }} >
                    {translate('permintaan.bendera')}
                  </Typography>
                </Box>
              </Grid> */}
              {/* <Grid item lg={2.4} md={4} xs={6}>
                <Box sx={{ background: "#F4F6FA", borderRadius: 2, padding: { sm: "12px 12px 12px 16px", xs: '12px' } }}>
                  <Typography className="w-700" sx={{ color: '#081130', fontSize: { sm: '20px', xs: '18px' }, }}>
                    {new Intl.NumberFormat().format(Number(props.data.quantity_muatan))}
                  </Typography>
                  <Typography className="w-500" sx={{ color: '#56617B', fontSize: { sm: '14px', xs: '12px' } }} >
                    {translate('page_explore.quantitymuatan')}
                  </Typography>
                </Box>
              </Grid> */}
            </Grid>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="start"
              spacing={1}
            >
              <Typography
                className="w-500 s-1622"
                sx={{ color: "#56617B" }}
              >
                {translate('title.location-tongkang')}
              </Typography>
              <Typography
                className="w-700 s-1622"
                sx={{ color: "#081130", }}
              >
                {props?.data?.tongkang_location_name === '' ? '-' : props?.data?.tongkang_location_name}
              </Typography>
            </Stack>
          </Box>
        </CardContent>
      </Card>
      <Stack direction={'row'} alignItems={'center'}>
        <Typography
          className="w-700 s-1824"
          sx={{ color: "#081130", mr: 1, }}
        >
          {translate('status.hasilpencarian')}
        </Typography>
        <Box sx={{ background: "#F4F6FA", borderRadius: '6px', padding: '4px' }}>
          <Typography
            className="w-700 s-1419"
            sx={{ color: "#56617B", }}
          >
            {props.result}
          </Typography>
        </Box>
      </Stack>
    </Box>
  )
}
