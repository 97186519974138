import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, FormControlLabel, Grid, IconButton, Modal, Radio, RadioGroup, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2';
import ModalSuccessReport from "../components/ModalSuccessReport";
import { reportListing } from "../services/api";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: 343, lg: 472 },
    borderRadius: '8px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    paddingBottom: '25px',

};

export default function ModalReportListing(props) {
    const [note, setNote] = useState("")
    const [translate,] = useTranslation("global");
    const [listCategoryReport] = useState([{ id: 'Offensive Content', name: 'Offensive Content' }, { id: 'Fraud', name: 'Fraud' }, { id: 'Duplicate ad', name: 'Duplicate ad' }, { id: 'Product already sold', name: 'Product already sold' }, { id: 'Other', name: 'Other' }])
    const [value, setValue] = React.useState('');
    const [isactive, setisactive] = React.useState(true);
    const [openModalReport, setOpenModalReport] = useState(false);

    const handleOpenModalReport = () => {
        setOpenModalReport(true)
    }

    const handleCloseModal = () => {
        setOpenModalReport(false)
    }


    const SubmitReportListing = async () => {

        try {
            let params =
            {
                "data": {
                    "reason": value ? value : "",
                    "notes": value !== "Other" ? value : note,
                    "url_website": props.url_website,
                    "type": props.type,
                    "id_relation": props.id,
                    token: localStorage.getItem("token")
                }
            }

            const responAPI = await reportListing(params)
            if (responAPI.status === 200) {
                handleOpenModalReport()
                setValue("")
                setNote("")
            } else {
                setValue("")
                setNote("")
                Swal.fire({
                    icon: 'error',
                    title: translate('status.failed'),
                    text: translate('status.subtitlefailed'),
                })
            }
        } catch (error) {

        }

    }

    return (
        <div>
            <Modal
                open={props.open}
                onClose={props.close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ zIndex: 100000 }}
            >
                <Box sx={style}>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} sx={{ mt: 2, mb: 2 }}>
                        <Typography className='w-700' sx={{ fontSize: 16, color: '#081130', mb: { xs: '4px' }, ml: "22px" }}>{translate("detail-explore.laporkanlisting")}</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'right', mr: '16px' }}>
                            <IconButton size="small" >
                                <CloseIcon sx={{ width: '24px', height: '24px' }} onClick={() => {
                                    props.close();
                                }} />
                            </IconButton>
                        </Box>
                    </Stack>


                    <Box sx={{ ml: "22px", mr: { xs: '21px', lg: '43px' }, mb: { xs: '16px', lg: '24px' } }}>
                        <Typography className='w-700' sx={{ fontSize: { xs: '14px', lg: '14px' }, color: '#081130', mb: { xs: '4px' } }}>{translate("title.sub-laporkanlisting")}</Typography>


                        {listCategoryReport.map((item, i) => (
                            <Grid key={item.id} alignItems='center' style={{ width: "100%", display: "flex", flexDirection: 'row' }} className='box'>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                >
                                    <FormControlLabel key={item.id}
                                        onChange={(e) => {
                                            setValue(e.target.value)
                                            setisactive(false)
                                        }} value={item.id} control={<Radio checked={value.includes(item.id)} sx={{
                                            color: "#E8EBF2",

                                            '&.Mui-checked': { color: "#3BB56C", },
                                        }} />} label={<Typography className='w-400' sx={{ fontSize: 14, color: '#081130' }}>{item.name}</Typography>} className='w-500 s-1419' />
                                </RadioGroup>


                            </Grid>))}



                        <Typography className='w-700' sx={{ fontSize: { xs: '14px', lg: '14px' }, color: '#081130', mb: { xs: '4px' }, mt: "4px" }}>
                            {translate("title.title-reason")}
                        </Typography>

                        <TextField
                            className='w-500'
                            sx={{
                                input: {
                                    fontSize: '14px', fontFamily: 'Satoshi-500'
                                },
                            }}
                            onChange={(e) => setNote(e.target.value)} value={note}
                            InputProps={{
                                sx: {
                                    fieldset: { borderColor: 'var(--grey-stroke, #E8EBF2)', borderWidth: '1px solid' },
                                    p: '14px 16px', fontFamily: 'Satoshi-500', fontWeight: '400', fontSize: '14px', borderRadius: '8px'
                                },
                            }}
                            id="outlined-multiline-static"
                            fullWidth
                            multiline
                            rows={4}
                            placeholder={translate('title.placeholder-reason')}
                        />



                    </Box>

                    <Box sx={{ ml: { xs: '21px', lg: '41px' }, mr: { xs: '21px', lg: '41px' }, display: 'flex', justifyContent: 'right', }}>


                        <Button
                            disabled={isactive}
                            onClick={() => {
                                props.close();
                                SubmitReportListing()
                            }} variant="contained" sx={{
                                fontSize: 14, borderRadius: '8px', textTransform: 'none', background: ' #2457B9',
                                p: '12px 16px', height: { xs: '44px', lg: '48px' }, width: { xs: '148px', lg: '186px' },
                                "&.Mui-disabled": {
                                    background: "#8792ab",
                                    color: "#c0c0c0"
                                }
                            }}>
                            <Typography className='w-500' sx={{ height: '22px', fontSize: 14, color: '#FFFFFF', textAlign: 'center' }}>
                                {translate("title.report")}
                            </Typography>
                        </Button>

                    </Box>



                </Box>
            </Modal>
            <ModalSuccessReport open={openModalReport} close={handleCloseModal}></ModalSuccessReport>
        </div>
    );
}