import Divider from "@material-ui/core/Divider";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, Stack, Typography } from "@mui/material";
import Drawer from '@mui/material/Drawer';
import { React } from 'react';
import { useTranslation } from "react-i18next";

export default function DrawerFilterCompany(props) {
  const [translate,] = useTranslation("global");

  return (
    <Box sx={{ flexGrow: 1, display: { xs: "flex", sm: 'flex', md: "flex" }, }}>

      <Drawer
        className="box-filter-drawer"
        anchor="bottom"
        open={Boolean(props.open)}
        onClose={props.close}
      >
        <Box >
          <Stack
            direction="row"
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{ pl: '16px', pt: '19px', pr: 2, pb: '19px' }}
          >
            <Typography className="w-700" sx={{ fontSize: 18, color: '#081130' }}>
              Filter
            </Typography>
            <Stack direction={'row'} alignItems={'center'}>
              <Button className="w-500"
                onClick={(e) => {
                  props.handleLocationReset('company')
                  props.resetFilterPerusahaan('mobile')

                }}
                sx={{ fontSize: 16, color: '#2457B9', textTransform: 'none' }} variant="text"> Reset filter</Button>

              <Divider orientation="vertical" style={{ marginTop: 10, marginBottom: 10, marginLeft: 10, marginRight: 10 }} flexItem />
              <CloseIcon onClick={props.close} />
            </Stack>
          </Stack>

          {/* <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
            <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '6px' }}>
              {translate('page_explore.totallisting')}
            </Typography>
            <Grid
              container
              direction="row"
              justifyContent={'space-between'}
              spacing={2}
            >
              <Grid item xs={6}>
                <TextField
                  className="box-input-filter"
                  type="number"
                  size="small"
                  placeholder="Min"
                  onChange={(e) => props.handleFilterPerusahaan(e.target.value, 'mobile', "min_total_listing")}
                  value={props.filterPerusahaan["min_total_listing"]}
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                      display: "none",
                    },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                    width: '100%', input: {
                      color: '#8792AB', fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                  }}
                />

              </Grid>
              <Grid item xs={6}>
                <TextField
                  className="box-input-filter"
                  type="number"
                  size="small"
                  placeholder="Maks"
                  onChange={(e) => props.handleFilterPerusahaan(e.target.value, 'mobile', "max_total_listing")}
                  value={props.filterPerusahaan["max_total_listing"]}
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                      display: "none",
                    },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                    width: '100%', input: {
                      color: '#8792AB', fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                  }}

                />
              </Grid>
            </Grid>

          </Box>
          <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
            <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '6px' }}>
              Staff
            </Typography>

            <Grid
              container
              direction="row"
              justifyContent={'space-between'}
              spacing={2}
            >
              <Grid item xs={6}>

                <TextField
                  className="box-input-filter"
                  type="number"
                  size="small"
                  placeholder="Min"
                  onChange={(e) => props.handleFilterPerusahaan(e.target.value, 'mobile', "min_total_staff")}
                  value={props.filterPerusahaan["min_total_staff"]}
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                      display: "none",
                    },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                    width: '100%',  input: {
                    color: '#8792AB', fontSize: '14px', fontFamily: 'Satoshi-500'
                  },
                  }}
                />

              </Grid>
              <Grid item xs={6}>
                <TextField
                  className="box-input-filter"
                  type="number"
                  size="small"
                  placeholder="Maks"
                  onChange={(e) => props.handleFilterPerusahaan(e.target.value, 'mobile', "max_total_staff")}
                  value={props.filterPerusahaan["max_total_staff"]}
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                      display: "none",
                    },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                    width: '100%', input: {
                      color: '#8792AB', fontSize: '14px',

                    },
                  }}

                />
              </Grid>
            </Grid>
          </Box> */}

          <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>

            <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
              {translate('page_explore.bidangperusahan')}
            </Typography>
            <Grid
              container
              direction="column"
              justifyContent={'space-between'}
              sx={{ mt: 1, pl: 2, pb: 2 }}
              spacing={2}
            >

              <Button onClick={(e) => props.handleFilterPerusahaan("", 'mobile', "bidang_usaha")}
                value={props.filterPerusahaan["bidang_usaha"]} sx={{
                  "&:hover": {
                    borderColor: props.filterPerusahaan["bidang_usaha"] === '' ? '#3BB56C' : '#E8EBF2',
                  }, textTransform: 'none', borderColor: props.filterPerusahaan["bidang_usaha"] === '' ? '#3BB56C' : '#E8EBF2', color: props.filterPerusahaan["bidang_usaha"] === '' ? '#3BB56C' : 'black', mb: 1, justifyContent: 'left', borderRadius: '8px', p: '14px 12px'
                }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterPerusahaan["bidang_usaha"] === '' ? '#3BB56C' : '#E8EBF2', }} />}>
                {translate('page_explore.semua')}
              </Button>
              <Button onClick={(e) => props.handleFilterPerusahaan("1", 'mobile', "bidang_usaha")}
                value={props.filterPerusahaan["bidang_usaha"]} sx={{
                  "&:hover": {
                    borderColor: props.filterPerusahaan["bidang_usaha"] === '1' ? '#3BB56C' : '#E8EBF2',
                  }, textTransform: 'none', justifyContent: 'left', borderColor: props.filterPerusahaan["bidang_usaha"] === '1' ? '#3BB56C' : '#E8EBF2', color: props.filterPerusahaan["bidang_usaha"] === '1' ? '#3BB56C' : 'black', mb: 1, borderRadius: '8px', p: '14px 12px'
                }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterPerusahaan["bidang_usaha"] === '1' ? '#3BB56C' : '#E8EBF2' }} />}>
                {translate('page_explore.penambang')}
              </Button>
              <Button onClick={(e) => props.handleFilterPerusahaan("2", 'mobile', "bidang_usaha")}
                value={props.filterPerusahaan["bidang_usaha"]} sx={{
                  "&:hover": {
                    borderColor: props.filterPerusahaan["bidang_usaha"] === '2' ? '#3BB56C' : '#E8EBF2',
                  }, textTransform: 'none', justifyContent: 'left', borderColor: props.filterPerusahaan["bidang_usaha"] === '2' ? '#3BB56C' : '#E8EBF2', color: props.filterPerusahaan["bidang_usaha"] === '2' ? '#3BB56C' : 'black', mb: 1, borderRadius: '8px', p: '14px 12px'
                }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterPerusahaan["bidang_usaha"] === '2' ? '#3BB56C' : '#E8EBF2', }} />}>
                Trader
              </Button>
              <Button onClick={(e) => props.handleFilterPerusahaan(3, 'mobile', "bidang_usaha")}
                value={props.filterPerusahaan["bidang_usaha"]} sx={{
                  "&:hover": {
                    borderColor: props.filterPerusahaan["bidang_usaha"] === '3' ? '#3BB56C' : '#E8EBF2',
                  }, textTransform: 'none', justifyContent: 'left', borderColor: props.filterPerusahaan["bidang_usaha"] === '3' ? '#3BB56C' : '#E8EBF2', color: props.filterPerusahaan["bidang_usaha"] === '3' ? '#3BB56C' : 'black', mb: 1, borderRadius: '8px', p: '14px 12px'
                }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterPerusahaan["bidang_usaha"] === '3' ? '#3BB56C' : '#E8EBF2', }} />}>
                {translate('page_explore.pelayaran')}
              </Button>
            </Grid>
          </Box>

          <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
            <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
              {translate('page_explore.lokasi')}
            </Typography>
            <FormGroup className="location-label">
              {props.dataProvince.map((option, i) => {
                if (i < 5) return (
                  <FormControlLabel onChange={(e) => props.handleFilterPerusahaan(e, "popuplocation")} key={i} sx={{ fontSize: 14, color: '#081130' }} control={<Checkbox checked={props.selectedItems.includes(option.id)} sx={{
                    color: "#E8EBF2",
                    '&.Mui-checked': {
                      color: "#3BB56C",
                    },
                  }} />} label={option.attributes.name} value={option.id} />)
              })}
            </FormGroup>
            {props.limitProvince < 10 &&
              <Grid
                sx={{ mt: 1 }}
                container
                justifyContent="left"
                alignItems="center"
                direction="row"
              >
                <Typography onClick={(e) => {
                  props.setLimitProvince(5)
                  props.handleOpenModalLocationResponsive("company")
                }}
                  className="w-700"
                  sx={{ fontSize: 14, color: "#2457B9", mr: 1, cursor: "pointer" }}
                >
                  {translate('page_explore.lihatselengkapnya')}
                </Typography>
                <ArrowForwardIosIcon sx={{ fontSize: 12, color: "#2457B9" }} />
              </Grid>}
          </Box>
        </Box>
        <Divider orientation="horizontal" style={{ marginTop: 10, marginBottom: 10, marginLeft: 16, marginRight: 16 }} />
        <Stack direction={'row'} spacing={1} sx={{ ml: 2, mr: 2, mb: 2 }}>

          <Button
            className="w-500"
            onClick={(e) => {
              props.handleLocationReset('company')
              props.resetFilterPerusahaan()
              props.handleCloseModalFilter()
            }}

            fullWidth
            variant="outlined"
            sx={{
              textTransform: "none", border: '1px solid var(--primarry-blue, #2457B9)',
              p: '12px 16px',
              borderRadius: '8px',
              color: '#2457B9'
            }}
          >

            {translate('jual-beli.btn-cancel')}
          </Button>
          <Button className="w-500"
            onClick={(e) => {
              props.Filter('company')
              props.handleCloseModalFilter()
            }}
            fullWidth
            variant="contained"
            sx={{ background: ' linear-gradient(270deg, #2457B9 15%, #2860CD 100%)', borderRadius: '8px', boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.12), 0px -1px 0px 0px rgba(0, 0, 0, 0.20) inset', padding: '12px 16px', textTransform: 'none' }}
          >

            {translate('page_explore.btn-filter')}
          </Button>
        </Stack>

      </Drawer>

    </Box>
  );
}