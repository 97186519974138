import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Card, Grid, IconButton, Stack, Typography } from "@mui/material";
import Drawer from '@mui/material/Drawer';
import { React, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import delete_doc from "../assets/hapus-doc-ic.svg";
import { DisplayPDFFile } from '../components/DisplayPDF';
import { config } from '../services/constants';

export default function DrawerHapusDoc(props) {
  const [translate,] = useTranslation("global");
  const [fileDoc, setFileDoc] = useState(props?.file_doc)

  const deleteFileonArrayDrawer = (index) => {
    let newFileDocument = fileDoc.concat([])
    if (index > -1) {
      newFileDocument.splice(index, 1);
    }
    setFileDoc(newFileDocument);
  }

  const cancelDelete = () => {
    setFileDoc(props?.file_doc)
  }

  useEffect(() => {
    setFileDoc(props?.file_doc)
  }, [props?.file_doc])

  return (
    <Drawer
      className="box-drawer-upload-doc-form"
      sx={{ zIndex: 999999, }}
      anchor="bottom"
      open={Boolean(props.open)}
      onClose={props.close}
    >
      <div style={{ height: '420px', overflowY: 'auto', marginBottom: '56px' }}>
        <Box sx={{ p: '16px' }}>
          <Stack direction={'row'} justifyContent={'space-between'} sx={{ mb: '8px' }}>
            <Typography id="modal-modal-title" className='w-700' sx={{ fontSize: '18px', color: '#081130', textAlign: 'start' }}>
              {translate(props.title)}
            </Typography>
            <IconButton size="small" onClick={() => {
              cancelDelete()
              props.close();
            }} >
              <CloseIcon />
            </IconButton>
          </Stack>
          {(fileDoc !== undefined && fileDoc.length > 0) && <Grid sx={{ mt: '8px' }} container direction={'row'} spacing={2}>
            {fileDoc.map((item, i) => (
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Card sx={{ borderRadius: 2, background: 'black', position: 'relative' }}>
                  <div style={{ position: 'absolute', right: '0%', display: 'flex', }}>
                    <IconButton onClick={() => {
                      deleteFileonArrayDrawer(i)
                      // props.delete(i)
                    }} alt='' style={{ marginRight: '8px', cursor: 'pointer', background: 'transparent' }} aria-label="settings " sx={{ background: 'rgba(0, 0, 0, 0.1)', }}>
                      <img style={{ height: '24px', width: '24px' }} src={delete_doc} alt='' />
                    </IconButton>
                  </div>
                  <DisplayPDFFile
                    url={`${config.baseURL}${item.url}`}
                    key={i}
                    ext={`${item.ext}`}
                    name={`${item.name}`}
                  />
                </Card>
              </Grid>
            ))}
          </Grid>}

        </Box>

      </div>
      <Box sx={{ position: 'fixed', bottom: 0, width: '100%', p: 0, background: 'white', mt: '8px' }}>
        <Grid container spacing={2} direction={'row'} sx={{ pl: 2, pr: 2, pb: 2 }}>
          <Grid item xs={6}>
            <Button
              fullWidth
              onClick={() => {
                cancelDelete()
                props.close();
              }}
              className="w-500"
              variant="outlined"
              sx={{ p: '12px 16px 12px 16px', height: '44px', fontSize: 14, borderRadius: '8px', textTransform: 'none' }}>
              {translate('jual-beli.btn-cancel')}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              onClick={() => { props.submit(fileDoc); props.close(); }}
              className="w-500"
              variant="contained"
              sx={{ p: '12px 16px 12px 16px', height: '44px', fontSize: 14, borderRadius: '8px', textTransform: 'none', background: ' #2457B9' }}>
              Submit
            </Button>
          </Grid>
        </Grid></Box>
    </Drawer>
  );
}