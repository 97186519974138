import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Drawer, IconButton, Stack, Typography } from "@mui/material";
import * as React from 'react';
import { useTranslation } from "react-i18next";

export default function DrawerpublishListing(props) {
    const [translate,] = useTranslation("global");
    return (
        <div>
            <Drawer
                className="box-drawer-more"
                sx={{ zIndex: 999, '& .MuiBackdrop-root': { backgroundColor: 'rgba(0, 0, 0, 0.2)' } }}
                anchor="bottom"
                open={Boolean(props.open)}
                onClose={props.close}
            >
                <Box sx={{ p: '16px' }}>
                    <Stack sx={{ mb: '24px' }} direction={'row'} justifyContent={'space-between'}>
                        <Typography id="modal-modal-title" className='w-700' sx={{ fontSize: '18px', color: '#081130', textAlign: 'start' }}>
                            {translate('jual-beli.simpan')}
                        </Typography>
                        <IconButton size="small" >
                            <CloseIcon onClick={() => {
                                props.close();
                            }} />
                        </IconButton>
                    </Stack>
                    <Box sx={{ justifyContent: 'center', }}>
                        <Button
                            onClick={() => {
                                props.close();
                                props.SubmitResponsivePublish();
                            }}
                            type="submit"
                            fullWidth
                            className="w-500"
                            variant="contained"
                            sx={{ background: ' linear-gradient(270deg, #2457B9 15%, #2860CD 100%)', borderRadius: '8px', boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.12), 0px -1px 0px 0px rgba(0, 0, 0, 0.20) inset;', padding: '12px 16px' }}
                        >
                            <Typography className="w-500" style={{ textTransform: 'capitalize' }}>
                                {translate('jual-beli.simpanpublish')}
                            </Typography>
                        </Button>
                    </Box>
                    <Box sx={{ justifyContent: 'center', }}>
                        <Button
                            fullWidth
                            variant="outlined"
                            onClick={() => {
                                props.close();
                                props.SubmitResponsiveDraft()
                            }}
                            sx={{
                                mt: '16px', mb: 2, textTransform: "none", border: '1px solid var(--primarry-blue, #2457B9)',
                                p: '12px 16px',
                                borderRadius: '8px',
                                color: '#2457B9'
                            }}
                        >
                            <Typography
                                className="w-500"
                                sx={{ fontSize: 14, color: '#2457B9' }}
                            >
                                {translate('jual-beli.simpandraft')}
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            </Drawer>
        </div>
    );
}