import CompanyDefault from '@mui/icons-material/Business'
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import { Avatar, Box, Card, CardContent, CardHeader, Grid, Hidden, IconButton, Stack, Typography } from "@mui/material"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import callCompanyIcon from "../assets/call-company-ic.png"
import mailCompanyIcon from "../assets/mail-company-ic.png"
import pelayarIcon from "../assets/pelayar-ic.png"
import penambangIcon from "../assets/penambang-ic.png"
import traderIcon from "../assets/trader-ic.png"
import { config } from '../services/constants'
import { capitalizeEachWord, convertText, format } from '../services/primary'
import ModalShare from "./ModalShare"

export default function CardCompanyHomepage({ data, page }) {
  const [translate] = useTranslation("global");
  const [product_id, setProduct_id] = useState(null);
  const [dataperusahaan, setdataperusahaan] = useState(null);
  const [user_trader_phone, setuser_trader_phone] = useState(null);
  const [anchorElShare, setAnchorElShare] = useState(null);

  let data_
  if (Array.isArray(data) === false) {
    data_ = [data]
  } else {
    data_ = data
  }

  const handleOpenShareMenu = (event, id, traderphone, data_perusahaan) => {
    setuser_trader_phone(traderphone);
    setProduct_id(id);
    setdataperusahaan(data_perusahaan);
    setAnchorElShare(event.currentTarget);
  };
  const handleCloseShareMenu = () => {
    setAnchorElShare(null);
  };

  const GotoDetailCompany = (id_company, name_company) => {
    let filter_name = capitalizeEachWord(convertText(name_company))
    window.location.href = `/detail/${filter_name}?`
  };

  return (
    <React.Fragment>
      {data_.map((item) => (
        <Box key={item.company_id} >
          <Card
            sx={{
              position: 'relative',
              height: '186px',
              border: '1px solid #E8EBF2',
              borderRadius: '8px',
              boxShadow: 'none',
              padding: "20px",
              mr: { lg: '8px', xs: '12px' },
              mt: 0,
              ml: 0,
              mb: 0,
            }}
          >
            <IconButton sx={{ position: 'absolute', right: '4%', top: { md: '13% !important', xs: '8% !important' }, zIndex: 1, }} aria-label="settings" onClick={(e) => handleOpenShareMenu(e, item.company_id, item.phone_number, item)} >
              <MoreHorizIcon />

            </IconButton>
            <CardHeader
              sx={{ padding: "0px", mb: "16px" }}
              avatar={
                item.company_image ?
                  <Box onClick={(e) => {
                    GotoDetailCompany(item?.company_id, item?.name)
                  }}
                    sx={{ width: { md: '60px', xs: '40px' }, height: { md: '60px', xs: '40px' }, cursor: 'pointer' }}>
                    <img className="company-photo width-100 br-100 img-cover"
                      alt="Perusahaan-icon"
                      src={config.baseURL + item.company_image}
                    ></img>
                  </Box> :
                  <Avatar
                    onClick={(e) => {
                      GotoDetailCompany(item?.company_id, item?.name)
                    }}
                    className="company-photo w-500"
                    sx={{
                      cursor: 'pointer',
                      bgcolor: '#1976d2',
                      mr: { md: '12px', xs: '4px' },
                      height: { md: '60px', xs: '40px' },
                      width: { md: '60px', xs: '40px' }
                    }} aria-label="recipe">
                    <CompanyDefault></CompanyDefault>
                  </Avatar>

              }

              title={
                <>
                  <Hidden smDown>
                    <Typography onClick={(e) => {
                      GotoDetailCompany(item?.company_id, item?.name)
                    }} className="w-700 s-1824" sx={{ color: '#081130 !important', cursor: 'pointer' }}>
                      {item?.name.length > 25 ? capitalizeEachWord(item?.name).slice(0, 25) + '...' : capitalizeEachWord(item?.name)}
                    </Typography>
                  </Hidden>
                  <Hidden smUp>
                    <Typography onClick={(e) => {
                      GotoDetailCompany(item?.company_id, item?.name)
                    }} className="w-700 s-1824" sx={{ color: '#081130 !important', cursor: 'pointer' }}>
                      {item?.name.length > 16 ? capitalizeEachWord(item?.name).slice(0, 16) + '...' : capitalizeEachWord(item?.name)}
                    </Typography>
                  </Hidden>
                </>


              }
              subheader={
                <Stack direction={'row'}>
                  {item.kota !== null || item.provinsi !== null &&
                    <Typography className="w-500 s-1622">
                      {item.kota}, {item.provinsi}
                    </Typography>}
                </Stack>}
            />

            <CardContent
              onClick={(e) => {
                GotoDetailCompany(item?.company_id, item?.name)
              }}
              sx={{
                cursor: 'pointer',
                padding: "12px 16px !important",
                background: "#F4F6FA",
                borderRadius: "6px"
              }}
            >
              <Stack style={{ textAlign: "left", color: "#56617B" }} spacing={1}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="left"
                >
                  {item.bidang_perusahaan === 1 ? <img alt="Nikel-icon" src={penambangIcon} height={18}></img> : ''}
                  {item.bidang_perusahaan === 2 ? <img alt="Nikel-icon" src={traderIcon} height={18}></img> : ''}
                  {item.bidang_perusahaan === 3 ? <img alt="Nikel-icon" src={pelayarIcon} height={18}></img> : ''}
                  <Typography
                    className="w-500 s-1422"
                    sx={{ ml: 1 }}
                  >
                    {item.bidang_perusahaan === 1 ? translate('page_explore.penambang') : ''}
                    {item.bidang_perusahaan === 2 ? translate('page_explore.trader') : ''}
                    {item.bidang_perusahaan === 3 ? translate('page_explore.pelayaran') : ''}
                  </Typography>
                </Grid>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="left"
                >
                  <img alt="Nikel-icon" src={callCompanyIcon} height={16}></img>
                  <Typography className="w-500 s-1422" sx={{ fontSize: 14, ml: 1 }}>
                    {item.phone_number === null || item.phone_number === "" ? translate('status.nomor-empty') : format(item.phone_number)}
                  </Typography>
                </Grid>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="left"
                >
                  <img alt="Nikel-icon" src={mailCompanyIcon} height={16}></img>
                  <Typography className="w-500 s-1422" sx={{ fontSize: 14, ml: 1 }}>
                    {item.email === null || item.email === "" ? translate('status.email-empty') : item.email}
                  </Typography>
                </Grid>
              </Stack>
            </CardContent>
          </Card>
        </Box>

      ))}

      <ModalShare page={'company'} type={4} id={product_id} data={dataperusahaan} user_trader_phone={user_trader_phone} anchorEl={anchorElShare} open={anchorElShare} onClose={handleCloseShareMenu} />
    </React.Fragment>
  )
}
