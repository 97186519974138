import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { IconButton } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { getBenderaFlag } from '../services/api';
import { config } from '../services/constants';
export default function FormNegara(props) {
    const [translate,] = useTranslation("global");
    const [benderaList, setBenderaList] = useState([])
    const [benderaSelect, setBenderaSelect] = useState({ id: parseInt(props.currentValue) })
    const [page, setPage] = useState(1)
    const [value, setValue] = useState("")

    async function handleChangeInput(e) {
        getAllFlag('', 1)
        try {
            setValue(e)
            setPage(1)
            await getAllFlag(e)
        } catch (error) {

        }
    }

    async function getAllFlag(name, pageQ) {
        try {
            let valuePage = page

            if (pageQ === "" || pageQ === undefined) {
                valuePage = 1
            } else {
                valuePage = pageQ
                setPage(pageQ)
            }


            if (valuePage !== 1) {
                const response = await getBenderaFlag({ name: name, page: valuePage })
                let currentFlag = benderaList
                setBenderaList(currentFlag.concat(response.data?.data));
            } else {
                const response = await getBenderaFlag({ name: name, page: valuePage })

                setBenderaList(response.data?.data);
            }
        } catch (e) {

        }
    }



    async function changeValue(event, values) {

        try {
            if (values === null) {
                setBenderaSelect(values)
                props.select({ id: "" })
                props.selectName("")
            } else {
                setBenderaSelect(values)
                props.select(values)
                props.selectName(values.attributes.name)
            }
        } catch (e) {

        }
    }

    useEffect(() => {
        getAllFlag(props.name, 1)
    }, [props.name])

    useEffect(() => {
        setBenderaSelect({ id: props.currentValue })

    }, [props.currentValue])



    return (
        <>
            <Autocomplete

                style={{
                    width: "100%",
                    border: "1px solid var(--grey-stroke, #e8ebf2)",
                    marginTop: "4px",
                    borderRadius: "8px"
                }}
                id="combo-box-demo"
                onChange={changeValue}
                options={benderaList.map((x, i) => (x))}
                onInputChange={async (event, newInputValue) => await handleChangeInput(newInputValue)}
                getOptionLabel={(option) => option.attributes.name}
                renderOption={(props, option) => {

                    let url = option?.attributes?.logo?.data === null ? '' : option?.attributes?.logo?.data[0].attributes.url

                    return (
                        <div {...props}>
                            <IconButton sx={{ mr: '24px' }}>
                                <img style={{ borderRadius: "100%", objectFit: "cover" }} alt="img-icon" src={config.baseURL + url} height={28} width={28}></img>
                            </IconButton>
                            {option.attributes.name}
                        </div>)
                }
                }
                ListboxProps={{
                    role: "list-box",
                    onScroll: async (event) => {
                        const listboxNode = event.currentTarget;
                        if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                            await getAllFlag(value, Number(page) + 1)
                        }
                    }
                }}
                popupIcon={<KeyboardArrowDownIcon sx={{ width: '20px', height: '20px', fill: '#56617B' }} />}
                renderInput={(params) => {
                    if (benderaSelect?.id) {
                        benderaList.map(x => {
                            if (x.id === benderaSelect.id) {
                                params.inputProps.value = x.attributes.name
                                benderaSelect.attributes = x.attributes
                            }
                        })
                        let url = benderaSelect?.attributes?.logo?.data === null ? '' : benderaSelect?.attributes?.logo?.data[0].attributes.url
                        params.InputProps.startAdornment = (
                            <IconButton sx={{ mr: '12px', p: 0 }}>
                                <img style={{ borderRadius: "100%", objectFit: "cover" }} alt="img-icon" src={config.baseURL + url} height={28} width={28}></img>
                            </IconButton>
                        )


                        return <TextField {...params} variant="outlined" />
                    } else {

                        return <TextField {...params} variant="outlined" placeholder={translate('status.placeholder-negara')} sx={{
                            fontSize: "14px", input: {
                                fontSize: '14px', fontFamily: 'Satoshi-500'
                            },
                        }} />
                    }

                }}
            />
        </>
    );
}