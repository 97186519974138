import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { Avatar, Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import Hidden from '@mui/material/Hidden';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PenawaranIcon from "../assets/icons/penawaran-ic.svg";
import PermintaanIcon from "../assets/icons/permintaan-ic.svg";
import { addFavorite, deleteFavorite, searchCoal } from "../services/api.js";
import { config } from '../services/constants';
import { GlobalState } from "../services/global";
import { numberConvert } from '../services/primary';
import Love from "./Love";
import ModalShare from "./ModalShare";

export default function TableCoal(props) {
  let page = props.page
  const { currency } = React.useContext(GlobalState)
  const navigate = useNavigate()
  const [productList, setProductList] = useState([]);
  const [product_id, setProduct_id] = useState(null);
  const [user_trader_phone, setuser_trader_phone] = useState(null);
  const [status_listing, setstatus_listing] = useState(null);
  const [isSold, setisSold] = useState(0);
  const [translate,] = useTranslation("global");
  const [anchorElShare, setAnchorElShare] = useState(null);
  const [isLogin,] = useState(localStorage.getItem('token') !== null ? true : false);
  const [label, setLabel] = useState('penawaran');

  const handleOpenShareMenu = (event, id, traderphone, label) => {
    setLabel(label)
    setuser_trader_phone(traderphone);
    setProduct_id(id);
    setAnchorElShare(event.currentTarget);
  };

  const handleOpenShareMenuListing = (event, id, statuslisting, is_Sold) => {
    setisSold(is_Sold)
    setstatus_listing(statuslisting);
    setProduct_id(id);
    setAnchorElShare(event.currentTarget);
  };

  const handleCloseShareMenu = () => {
    setAnchorElShare(null);
  };



  const setFavorite = (e, y) => {
    if (props.coalList !== undefined && localStorage.getItem("token") !== null) {
      addFavorite({ type: e, id_relation: parseInt(y) })
      if (page === 'profile') {
        props.getCoalList();
      }

    }
  }
  const removeFavorite = (e, y) => {
    deleteFavorite({ type_product: e, id_product: parseInt(y) })
    if (page === 'profile') {
      props.getCoalList();
    }
  }
  let finalColumn = [];
  // Tabel Home 
  const columns = [
    {
      headerClassName: 'super-app-theme--header', field: 'calorie', headerName: 'Gar', width: 100, sortable: false, renderCell: (params) => (

        <Grid
          container justifyContent={'end'} alignItems={'center'}
          sx={{ display: 'flex', cursor: 'pointer' }}
          onClick={(e) => { navigate(`/penawaran/detail?type=1&id_detail=${params.id}`) }}
        >
          <Typography
            className="w-700"
            sx={{ fontSize: 14, ml: 1, }}
          >
            {(params.row.calorie).toLocaleString(undefined, { maximumFractionDigits: 2 })}
          </Typography>
        </Grid>
      ),
    },
    {
      headerClassName: 'super-app-theme--header', field: 'total_sulfur', headerName: translate('page_explore.total-sulfur') + ' (%)', width: 100, sortable: false, renderCell: (params) => (

        <Grid
          container justifyContent={'end'} alignItems={'center'}
          sx={{ display: 'flex', cursor: 'pointer' }}
          onClick={(e) => { navigate(`/penawaran/detail?type=1&id_detail=${params.id}`) }}
        >
          <Typography
            className="w-700"
            sx={{ fontSize: 14, ml: 1, }}
          >
            {(params.row.total_sulfur).toLocaleString(undefined, { maximumFractionDigits: 2 })}
          </Typography>
        </Grid>

      ),
    },
    {
      headerClassName: 'super-app-theme--header', field: 'ash', headerName: 'Ash' + ' (%)', width: 100, sortable: false, renderCell: (params) => (
        <Grid
          container justifyContent={'end'} alignItems={'center'}
          sx={{ display: 'flex', cursor: 'pointer' }}
          onClick={(e) => { navigate(`/penawaran/detail?type=1&id_detail=${params.id}`) }}
        >
          <Typography
            className="w-700"
            sx={{ fontSize: 14, ml: 1, }}
          >
            {(params.row.ash).toLocaleString(undefined, { maximumFractionDigits: 2 })}
          </Typography>
        </Grid>

      ),
    },

    {
      headerClassName: 'super-app-theme--header', field: 'quantity', headerName: 'Quantity (MT)', width: 140, sortable: false, renderCell: (params) => (
        <Grid container sx={{ display: 'flex', cursor: 'pointer' }} justifyContent={'end'} alignItems={'center'}
          onClick={(e) => { navigate(`/penawaran/detail?type=1&id_detail=${params.id}`) }}>
          <Typography
            className="w-700"
            sx={{ fontSize: 14, ml: 1, color: '#081130' }}
          >
            {(params.row.quantity).toLocaleString(undefined, { maximumFractionDigits: 2 })}
          </Typography>

        </Grid>
      ),
    },
    {
      headerClassName: 'super-app-theme--header', field: 'price', headerName: currency === 'idr' ? 'Rp  (/MT)' : 'USD  (/MT)', width: 140, sortable: false, renderCell: (params) => (
        <Grid container
          onClick={(e) => { navigate(`/penawaran/detail?type=1&id_detail=${params.id}`) }} sx={{ display: 'flex', cursor: 'pointer' }} justifyContent={'end'} alignItems={'center'}>
          <Typography
            className="w-700"
            sx={{ fontSize: 14, ml: 1 }}
          >

            {numberConvert(params.row.price)}
          </Typography>

        </Grid>
      ),
    },
    {
      headerClassName: 'super-app-theme--header', field: 'location', headerName: translate('title.location'), width: 270, sortable: false, renderCell: (params) => (
        <Grid container sx={{ display: 'flex', cursor: 'pointer' }} justifyContent={'end'} alignItems={'center'}
          onClick={(e) => { navigate(`/penawaran/detail?type=1&id_detail=${params.id}`) }}>
          <Typography
            className="w-500"
            sx={{ fontSize: 14, color: '#081130' }}
          >

            {(params?.row.location_name && params?.row.location_name.length > 25) ? params?.row.location_name.slice(0, 25) + '...' : params?.row.location_name}
          </Typography>

        </Grid>
      ),
    },
    {
      headerClassName: 'last-theme--header', field: 'user_name', headerName: 'Trader', width: 310, sortable: false, renderCell: (params) => (
        <Grid container sx={{ display: 'flex' }} justifyContent={'left'} alignItems={'center'}>
          {params.row.user_url_image === null ?
            <Avatar onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${params.row.user_id}` }} sx={{ bgcolor: 'blue', mr: 1, height: 32, width: 32, cursor: 'pointer' }} aria-label="recipe" />
            :
            <img onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${params.row.user_id}` }} style={{ borderRadius: '50%', marginRight: 6, cursor: 'pointer', height: '32px', width: '32px' }} src={config.baseURL + params.row.user_url_image} alt={config.baseURL + params.row.user_url_image}></img>}
          <Typography
            className="w-500"
            onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${params.row.user_id}` }}
            sx={{ fontSize: 14, ml: 1, color: '#081130', cursor: 'pointer' }}
          >
            {(params?.row.user_name && params?.row.user_name.length > 25) ? params?.row.user_name.slice(0, 25) + '...' : params?.row.user_name}

          </Typography>

        </Grid>
      ),
    },
    {
      headerClassName: 'last-theme--header', field: 'action', headerName: ' ', sortable: false, width: 120, renderCell: (params) => (
        <Grid
          container
          justifyContent="end"
          alignItems="center"
          direction="row"
        >
          {isLogin &&
            <Love type='table' condition={params?.row?.user_favorite_id} remove={() => removeFavorite(1, params?.row?.product_id)} favorite={() => setFavorite(1, params?.row?.product_id)} />}

          {page !== '' ?
            <>
              <IconButton aria-label="more" onClick={(e) => handleOpenShareMenu(e, params.id, params.row.phone_number)} size="small">
                <MoreHorizIcon sx={{ fontSize: 18, }} />
              </IconButton>
              <ModalShare label={"penawaran"} type={1} id={product_id} user_trader_phone={user_trader_phone} anchorEl={anchorElShare} open={anchorElShare} onClose={handleCloseShareMenu}></ModalShare>
            </>
            : ''}

          <IconButton sx={{ pl: 0 }} aria-label="more" onClick={(e) => { navigate(`/penawaran/detail?type=1&id_detail=${params.id}`) }} size="small">
            <ArrowForwardIosIcon sx={{ fontSize: 18 }} />
          </IconButton>
        </Grid>
      ),
    },
  ];
  // Tabel Explore 
  const columnsExplorePage = [
    {
      headerClassName: 'super-app-theme--header', field: 'calorie', headerName: 'Gar', width: 70, sortable: false, renderCell: (params) => (
        <Grid container sx={{ display: 'flex', cursor: 'pointer' }} justifyContent={'end'} alignItems={'center'}
          onClick={(e) => {
            if (params.row.label === 'penawaran') {
              navigate(`/penawaran/detail?type=1&id_detail=${params.id}`)
            } else {
              navigate(`/permintaan/detail?type=1&id_detail=${params.id}`)
            }
          }}
        >
          <Typography
            className="w-700"
            sx={{ fontSize: 13, color: '#081130' }}
          >
            {(params.row.calorie).toLocaleString(undefined, { maximumFractionDigits: 2 })}
          </Typography>
        </Grid>

      ),
    },
    {
      headerClassName: 'super-app-theme--header', field: 'total_sulfur', headerName: translate('page_explore.total-sulfur') + ' (%)', width: 80, sortable: false, renderCell: (params) => (
        <Grid container sx={{ display: 'flex', cursor: 'pointer' }} justifyContent={'end'} alignItems={'center'}
          onClick={(e) => {
            if (params.row.label === 'penawaran') {
              navigate(`/penawaran/detail?type=1&id_detail=${params.id}`)
            } else {
              navigate(`/permintaan/detail?type=1&id_detail=${params.id}`)
            }
          }}
        >

          <Typography
            className="w-700"
            sx={{ fontSize: 13, color: '#081130', }}
          >
            {(params.row.total_sulfur).toLocaleString(undefined, { maximumFractionDigits: 2 })}
          </Typography>
        </Grid>
      ),
    },
    {
      headerClassName: 'super-app-theme--header', field: 'ash', headerName: 'Ash' + ' (%)', width: 80, sortable: false, renderCell: (params) => (

        <Grid container sx={{ display: 'flex', cursor: 'pointer' }} justifyContent={'end'} alignItems={'center'}
          onClick={(e) => {
            if (params.row.label === 'penawaran') {
              navigate(`/penawaran/detail?type=1&id_detail=${params.id}`)
            } else {
              navigate(`/permintaan/detail?type=1&id_detail=${params.id}`)
            }
          }}
        >

          <Typography
            className="w-700"
            sx={{ fontSize: 13, color: '#081130' }}
          >
            {(params.row.ash).toLocaleString(undefined, { maximumFractionDigits: 2 })}

          </Typography>
        </Grid>

      ),
    },

    {
      headerClassName: 'super-app-theme--header', field: 'quantity', headerName: 'Quantity (MT)', width: 110, sortable: false, renderCell: (params) => (
        <Grid
          container
          sx={{ cursor: 'pointer', display: 'flex' }}
          onClick={(e) => {
            if (params.row.label === 'penawaran') {
              navigate(`/penawaran/detail?type=1&id_detail=${params.id}`)
            } else {
              navigate(`/permintaan/detail?type=1&id_detail=${params.id}`)
            }
          }}
          justifyContent={'flex-end'} alignItems={'center'}>
          <Typography
            className="w-700"
            sx={{ fontSize: 13, ml: 1, color: '#081130' }}
          >
            {(params.row.quantity).toLocaleString(undefined, { maximumFractionDigits: 2 })}
          </Typography>
        </Grid>
      ),
    },

    {
      headerClassName: 'super-app-theme--header', field: 'price', headerName: currency === 'idr' ? 'Rp  (/MT)' : 'USD  (/MT)', width: 100, sortable: false, renderCell: (params) => (
        <Grid container
          sx={{ cursor: 'pointer', display: 'flex' }}
          onClick={(e) => {
            if (params.row.label === 'penawaran') {
              navigate(`/penawaran/detail?type=1&id_detail=${params.id}`)
            } else {
              navigate(`/permintaan/detail?type=1&id_detail=${params.id}`)
            }
          }} justifyContent={'flex-end'} alignItems={'center'}>
          <Typography
            className="w-700"
            sx={{ fontSize: 13 }}
          >
            {numberConvert(params.row.price)}
          </Typography>

        </Grid>
      ),
    },
    {
      headerClassName: 'super-app-theme--header', field: 'location', headerName: translate('title.location'), width: 160, sortable: false, renderCell: (params) => (
        <Grid container sx={{ display: 'flex', cursor: 'pointer' }} justifyContent={'end'} alignItems={'center'}
          onClick={(e) => {
            if (params.row.label === 'penawaran') {
              navigate(`/penawaran/detail?type=1&id_detail=${params.id}`)
            } else {
              navigate(`/permintaan/detail?type=1&id_detail=${params.id}`)
            }
          }}

        >
          <Typography
            className="w-500"
            sx={{ fontSize: 14, color: '#081130' }}
          >

            {(params?.row.location_name && params?.row.location_name.length > 18) ? params?.row.location_name.slice(0, 18) + '...' : params?.row.location_name}
          </Typography>

        </Grid>
      ),
    },
    {
      headerClassName: 'last-theme--header', field: 'user_name', headerName: 'Trader', width: 200, sortable: false, renderCell: (params) => (
        <Grid container sx={{ display: 'flex' }} justifyContent={'left'} alignItems={'center'}>
          {params.row.user_url_image === null ?
            <Avatar onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${params.row.user_id}` }} sx={{ bgcolor: 'blue', mr: 1, height: 32, width: 32, cursor: 'pointer' }} aria-label="recipe" />
            : <img onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${params.row.user_id}` }} style={{ borderRadius: '50%', marginRight: 6, cursor: 'pointer' }} src={config.baseURL + params.row.user_url_image} height={32} width={32} alt={config.baseURL + params.row.user_url_image}></img>}
          <Typography
            className="w-500"
            onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${params.row.user_id}` }}
            sx={{ fontSize: 13, ml: 1, color: '#081130', cursor: 'pointer' }}
          >
            {(params?.row.user_name && params?.row.user_name.length > 18) ? params?.row.user_name.slice(0, 18) + '...' : params?.row.user_name}
          </Typography>

        </Grid>
      ),
    },
    {
      headerClassName: 'last-theme--header', field: 'action', headerName: ' ', width: 190, sortable: false, renderCell: (params) => (
        <>
          {params.row.label === 'penawaran' && <Grid
            container
            alignItems="center"
            direction="row"
          >
            {params.row.label === 'penawaran' && <Box
              sx={{
                background: "rgba(180, 202, 243, 0.22);",
                borderRadius: '300px',
                p: '2px 12px',
                height: '24px',
                alignItems: 'center',
                display: 'flex',
                width: '80px'
              }}
            >
              <Stack direction={'row'} alignItems={'center'}>
                <img alt="icon" src={PenawaranIcon} height={18}></img>
                <Typography className="w-500" sx={{ color: '#2457B9', fontSize: 12, ml: '4px' }} >
                  {translate('navbar.penawaran')}
                </Typography>

              </Stack>
            </Box>}
            <Stack direction={'row'} sx={{ ml: '4px' }}>
              {isLogin &&
                <Love type='table' condition={params?.row?.user_favorite_id} remove={() => removeFavorite(1, params?.row?.product_id)} favorite={() => setFavorite(1, params?.row?.product_id)} />}

              {page !== '' ?
                <>
                  <IconButton onClick={(e) => handleOpenShareMenu(e, params.id, params.row.phone_number, params.row.label)} size="small" sx={{ p: 0 }}>
                    <MoreHorizIcon sx={{ fontSize: 18, }} />

                  </IconButton>
                  <ModalShare page={"explore"} label={label} type={1} id={product_id} user_trader_phone={user_trader_phone} anchorEl={anchorElShare} open={anchorElShare} onClose={handleCloseShareMenu}></ModalShare>
                </>
                : ''}

              <IconButton onClick={(e) => { navigate(`/penawaran/detail?type=1&id_detail=${params.id}`) }} size="small" sx={{ p: 0 }}>
                <ArrowForwardIosIcon sx={{ fontSize: 18 }} />
              </IconButton>
            </Stack>
          </Grid>}
          {params.row.label === 'permintaan' && <Grid
            container
            alignItems="center"
            direction="row"
          >

            {params.row.label === 'permintaan' && <Box
              sx={{
                background: "rgba(139, 239, 179, 0.25);",
                borderRadius: '300px',
                p: '2px 12px',
                height: '24px',
                alignItems: 'center',
                display: 'flex',
                width: '80px'
              }}
            >
              <Stack direction={'row'} alignItems={'center'}>
                <img alt="icon" src={PermintaanIcon} height={18}></img>
                <Typography className="w-500" sx={{ color: '#3BB56C', fontSize: 12, ml: '4px' }} >
                  {translate('navbar.permintaan')}
                </Typography>

              </Stack>
            </Box>}
            <Stack direction={'row'} sx={{ ml: '4px' }}>
              {isLogin &&
                <Love type='table' condition={params?.row?.user_favorite_id} remove={() => removeFavorite(5, params?.row?.product_id)} favorite={() => setFavorite(5, params?.row?.product_id)} />}

              {page !== '' ?
                <IconButton onClick={(e) => handleOpenShareMenu(e, params.id, params.row.phone_number, params.row.label)} size="small" sx={{ p: 0 }}>
                  <MoreHorizIcon sx={{ fontSize: 18, }} />
                </IconButton>
                : ''}
              <ModalShare page={"explore"} label={label} type={1} id={product_id} user_trader_phone={user_trader_phone} anchorEl={anchorElShare} open={anchorElShare} onClose={handleCloseShareMenu}></ModalShare>

              <IconButton onClick={(e) => { navigate(`/permintaan/detail?type=1&id_detail=${params.id}`) }} size="small" sx={{ p: 0 }}>
                <ArrowForwardIosIcon sx={{ fontSize: 18 }} />
              </IconButton>
            </Stack>
          </Grid>}
        </>
      ),
    },

  ];
  // Tabel Penawaran 
  const columnsPenawaranPage = [
    {
      headerClassName: 'super-app-theme--header', field: 'calorie', headerName: 'Gar', align: 'right', width: 80, sortable: false, renderCell: (params) => (

        <Grid container sx={{ display: 'flex', cursor: 'pointer' }} justifyContent={'end'} alignItems={'center'}
          onClick={(e) => { navigate(`/penawaran/detail?type=1&id_detail=${params.id}`) }}
        >

          <Typography
            className="w-700"
            sx={{ fontSize: 14, color: '#081130' }}
          >
            {(params.row.calorie).toLocaleString(undefined, { maximumFractionDigits: 2 })}
          </Typography>
        </Grid>

      ),
    },
    {
      headerClassName: 'super-app-theme--header', field: 'total_sulfur', headerName: translate('page_explore.total-sulfur') + ' (%)', width: 80, sortable: false, renderCell: (params) => (

        <Grid container sx={{ display: 'flex', cursor: 'pointer' }} justifyContent={'end'} alignItems={'center'}
          onClick={(e) => { navigate(`/penawaran/detail?type=1&id_detail=${params.id}`) }}
        >

          <Typography
            className="w-700"
            sx={{ fontSize: 14, color: '#081130', }}
          >
            {(params.row.total_sulfur).toLocaleString(undefined, { maximumFractionDigits: 2 })}
          </Typography>
        </Grid>
      ),
    },
    {
      headerClassName: 'super-app-theme--header', field: 'ash', headerName: 'Ash' + ' (%)', width: 80, sortable: false, renderCell: (params) => (

        <Grid container sx={{ display: 'flex', cursor: 'pointer' }} justifyContent={'end'} alignItems={'center'}
          onClick={(e) => { navigate(`/penawaran/detail?type=1&id_detail=${params.id}`) }}
        >

          <Typography
            className="w-700"
            sx={{ fontSize: 14, color: '#081130' }}
          >
            {(params.row.ash).toLocaleString(undefined, { maximumFractionDigits: 2 })}

          </Typography>
        </Grid>

      ),
    },

    {
      headerClassName: 'super-app-theme--header', field: 'quantity', headerName: 'Quantity (MT)', width: 120, sortable: false, renderCell: (params) => (
        <Grid container sx={{ display: 'flex', cursor: 'pointer' }}

          onClick={(e) => { navigate(`/penawaran/detail?type=1&id_detail=${params.id}`) }}
          justifyContent={'flex-end'} alignItems={'center'}>
          <Typography
            className="w-700"
            sx={{ fontSize: 14, color: '#081130' }}
          >
            {(params.row.quantity).toLocaleString(undefined, { maximumFractionDigits: 2 })}
          </Typography>
        </Grid>
      ),
    },

    {
      headerClassName: 'super-app-theme--header', field: 'price', headerName: currency === 'idr' ? 'Rp  (/MT)' : 'USD  (/MT)', width: 120, sortable: false, renderCell: (params) => (
        <Grid container sx={{ display: 'flex', cursor: 'pointer' }}

          onClick={(e) => { navigate(`/penawaran/detail?type=1&id_detail=${params.id}`) }} justifyContent={'flex-end'} alignItems={'center'}>
          <Typography
            className="w-700"
            sx={{ fontSize: 14, ml: 1 }}
          >
            {numberConvert(params.row.price)}
          </Typography>

        </Grid>
      ),
    },
    {
      headerClassName: 'super-app-theme--header', field: 'location', headerName: translate('title.location'), width: 200, sortable: false, renderCell: (params) => (
        <Grid container sx={{ display: 'flex', cursor: 'pointer' }} justifyContent={'end'} alignItems={'center'}
          onClick={(e) => { navigate(`/penawaran/detail?type=1&id_detail=${params.id}`) }}>
          <Typography
            className="w-500"
            sx={{ fontSize: 14, color: '#081130' }}
          >

            {(params?.row.location_name && params?.row.location_name.length > 18) ? params?.row.location_name.slice(0, 18) + '...' : params?.row.location_name}
          </Typography>

        </Grid>
      ),
    },
    {
      headerClassName: 'last-theme--header', field: 'user_name', headerName: 'Trader', width: 200, sortable: false, renderCell: (params) => (
        <Grid container sx={{ display: 'flex' }} justifyContent={'left'} alignItems={'center'}>
          {params.row.user_url_image === null ?
            <Avatar onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${params.row.user_id}` }} sx={{ bgcolor: 'blue', mr: 1, height: 32, width: 32, cursor: 'pointer' }} aria-label="recipe" />
            : <img onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${params.row.user_id}` }} style={{ borderRadius: '50%', marginRight: 6, cursor: 'pointer' }} src={config.baseURL + params.row.user_url_image} height={32} width={32} alt={config.baseURL + params.row.user_url_image}></img>}
          <Typography
            className="w-500"
            onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${params.row.user_id}` }}
            sx={{ fontSize: 14, ml: 1, color: '#081130', cursor: 'pointer' }}
          >
            {(params?.row.user_name && params?.row.user_name.length > 18) ? params?.row.user_name.slice(0, 18) + '...' : params?.row.user_name}
          </Typography>

        </Grid>
      ),
    },
    {
      headerClassName: 'last-theme--header', field: 'action', headerName: ' ', width: 110, sortable: false, renderCell: (params) => (
        <Grid
          container
          justifyContent="end"
          alignItems="center"
          direction="row"
        >
          {isLogin &&
            <Love type='table' condition={params?.row?.user_favorite_id} remove={() => removeFavorite(1, params?.row?.product_id)} favorite={() => setFavorite(1, params?.row?.product_id)} />}
          {page !== '' ?
            <IconButton onClick={(e) => handleOpenShareMenu(e, params.id, params.row.phone_number)} size="small">
              <MoreHorizIcon sx={{ fontSize: 18, }} />
            </IconButton>
            : ''}
          <ModalShare label={'penawaran'} type={1} id={product_id} user_trader_phone={user_trader_phone} anchorEl={anchorElShare} open={anchorElShare} onClose={handleCloseShareMenu}></ModalShare>
          <IconButton sx={{ pl: 0 }} onClick={(e) => { navigate(`/penawaran/detail?type=1&id_detail=${params.id}`) }} size="small">
            <ArrowForwardIosIcon sx={{ fontSize: 18 }} />
          </IconButton>
        </Grid>
      ),
    },

  ];
  // Tabel Listing 
  const columnsListingPage = [
    {
      headerClassName: 'super-app-theme--header', field: 'calorie', headerName: 'Gar', width: 90, sortable: false, renderCell: (params) => (

        <Grid container sx={{ display: 'flex', cursor: 'pointer' }} justifyContent={'end'} alignItems={'center'}
          onClick={(e) => { navigate(`/listing/detail?type=1&id_detail=${params.id}`) }}
        >

          <Typography
            className="w-700"
            sx={{ fontSize: 14, color: '#081130' }}
          >
            {(params.row.calorie).toLocaleString(undefined, { maximumFractionDigits: 2 })}

          </Typography>
        </Grid>

      ),
    },
    {
      headerClassName: 'super-app-theme--header', field: 'total_sulfur', headerName: translate('page_explore.total-sulfur') + ' (%)', width: 90, sortable: false, renderCell: (params) => (
        <Grid container sx={{ display: 'flex', cursor: 'pointer' }} justifyContent={'end'} alignItems={'center'}
          onClick={(e) => { navigate(`/listing/detail?type=1&id_detail=${params.id}`) }}
        >

          <Typography
            className="w-700"
            sx={{ fontSize: 14, ml: 1, color: '#081130' }}
          >
            {(params.row.total_sulfur).toLocaleString(undefined, { maximumFractionDigits: 2 })}

          </Typography>
        </Grid>

      ),
    },
    {
      headerClassName: 'super-app-theme--header', field: 'ash', headerName: 'Ash' + ' (%)', width: 90, sortable: false, renderCell: (params) => (
        <Grid container sx={{ display: 'flex', cursor: 'pointer' }} justifyContent={'end'} alignItems={'center'}
          onClick={(e) => { navigate(`/listing/detail?type=1&id_detail=${params.id}`) }}
        >

          <Typography
            className="w-700"
            sx={{ fontSize: 14, ml: 1, color: '#081130' }}
          >
            {(params.row.ash).toLocaleString(undefined, { maximumFractionDigits: 2 })}
          </Typography>
        </Grid>

      ),
    },

    {
      headerClassName: 'super-app-theme--header', field: 'quantity', headerName: 'Quantity (MT)', width: 120, sortable: false, renderCell: (params) => (
        <Grid
          container
          onClick={(e) => { navigate(`/listing/detail?type=1&id_detail=${params.id}`) }}
          sx={{ display: 'flex', cursor: 'pointer' }} justifyContent={'end'} alignItems={'center'}>
          <Typography
            className="w-700"
            sx={{ fontSize: 14, ml: 1, color: '#081130' }}
          >
            {(params.row.quantity).toLocaleString(undefined, { maximumFractionDigits: 2 })}
          </Typography>
        </Grid>
      ),
    },
    {
      headerClassName: 'super-app-theme--header', field: 'price', headerName: currency === 'idr' ? 'Rp  (/MT)' : 'USD  (/MT)', width: 120, sortable: false, renderCell: (params) => (
        <Grid container
          onClick={(e) => { navigate(`/listing/detail?type=1&id_detail=${params.id}`) }}
          sx={{ display: 'flex', cursor: 'pointer' }} justifyContent={'end'} alignItems={'center'}>

          <Typography
            className="w-700"
            sx={{ fontSize: 14, ml: 1 }}
          >
            {numberConvert(params.row.price)}
          </Typography>

        </Grid>
      ),
    },
    {
      headerClassName: 'super-app-theme--header', field: 'location', headerName: 'Created Date', width: 120, sortable: false, renderCell: (params) => (
        <Grid container sx={{ display: 'flex', cursor: 'pointer' }} justifyContent={'end'} alignItems={'center'}
          onClick={(e) => { navigate(`/listing/detail?type=1&id_detail=${params.id}`) }}
        >
          <Typography
            className="w-500"
            sx={{ fontSize: 14, color: '#081130' }}
          >

            {params?.row.created_at ? `${new Date(params?.row.created_at).toLocaleDateString('id')}` : '-'}
          </Typography>

        </Grid>
      ),
    },
    {
      headerClassName: 'last-theme--header', field: 'status_listing', headerName: 'Status', width: 120, sortable: false, justifyContent: 'left', renderCell: (params) => (
        <Grid container
          sx={{ cursor: 'pointer' }}
          onClick={(e) => { navigate(`/listing/detail?type=1&id_detail=${params.id}`) }}
          justifyContent={'left'}>
          <Box
            sx={{
              background: "#F4F6FA",
              borderRadius: '300px',
              p: '2px 12px',
              width: '80px',
              height: '24px'

            }}
          > <Stack direction={'row'} alignItems={'center'}>

              {params.row.status_listing === 1 && params.row.is_sold === "0" ? (
                <>
                  <Box sx={{ borderRadius: '50%', background: '#3BB56C', width: '10px', height: '10px', mr: 1 }}>
                  </Box>
                  <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 14 }} >
                    Publish
                  </Typography>
                </>

              ) : (
                <></>
              )}

              {params.row.status_listing === 2 || params.row.is_sold === "1" ? (
                <>
                  <Box sx={{ borderRadius: '50%', background: '#F7CE46', width: '10px', height: '10px', mr: 1 }}>
                  </Box>
                  <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 14 }} >
                    Unpublish
                  </Typography>
                </>
              ) : (
                <></>
              )}
              {params.row.status_listing === 3 ? (
                <>
                  <Box sx={{ borderRadius: '50%', background: '#56617B', width: '10px', height: '10px', mr: 1 }}>
                  </Box>
                  <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 14 }} >
                    Draft
                  </Typography>
                </>
              ) : (
                <></>
              )}

            </Stack>
          </Box>
        </Grid>
      ),
    },
    {
      headerClassName: 'last-theme--header', field: 'label', headerName: 'Label ', width: 140, sortable: false, renderCell: (params) => (
        <>
          {params.row.label === 'penawaran' && <Grid
            container
            alignItems="center"
            direction="row"
          >
            {params.row.label === 'penawaran' && <Box
              sx={{
                background: "rgba(180, 202, 243, 0.22);",
                borderRadius: '300px',
                p: '2px 12px',
                height: '24px',
                alignItems: 'center',
                display: 'flex',
                width: '80px'
              }}
            >
              <Stack direction={'row'} alignItems={'center'}>
                <img alt="icon" src={PenawaranIcon} height={18}></img>
                <Typography className="w-500" sx={{ color: '#2457B9', fontSize: 12, ml: '4px' }} >
                  {translate('navbar.penawaran')}
                </Typography>

              </Stack>
            </Box>}

          </Grid>}
          {params.row.label === 'permintaan' && <Grid
            container
            alignItems="center"
            direction="row"
          >

            {params.row.label === 'permintaan' && <Box
              sx={{
                background: "rgba(139, 239, 179, 0.25);",
                borderRadius: '300px',
                p: '2px 12px',
                height: '24px',
                alignItems: 'center',
                display: 'flex',
                width: '80px'
              }}
            >
              <Stack direction={'row'} alignItems={'center'}>
                <img alt="icon" src={PermintaanIcon} height={18}></img>
                <Typography className="w-500" sx={{ color: '#3BB56C', fontSize: 12, ml: '4px' }} >
                  {translate('navbar.permintaan')}
                </Typography>

              </Stack>
            </Box>}

          </Grid>}
        </>
      ),
    },
    {
      headerClassName: 'last-theme--header', field: 'action', headerName: ' ', sortable: false, width: 100, renderCell: (params) => (
        <Grid
          container
          justifyContent="end"
          alignItems="center"
          direction="row"
        >
          {isLogin &&
            <Love type='table' condition={params?.row?.user_favorite_id} remove={() => removeFavorite(1, params?.row?.product_id)} favorite={() => setFavorite(1, params?.row?.product_id)} />}
          {page !== '' ?
            <IconButton onClick={(e) => handleOpenShareMenuListing(e, params.row.product_id, params.row.status_listing, params.row.is_sold)} size="small">
              <MoreHorizIcon sx={{ fontSize: 18, }} />

            </IconButton>

            : ''}
          <ModalShare page={'listing'} type='1' page_detail='coal' isSold={isSold} status_listing={status_listing} id={product_id} anchorEl={anchorElShare} open={anchorElShare} onClose={handleCloseShareMenu}></ModalShare>

          <IconButton sx={{ p: 0 }} onClick={(e) => { navigate(`/listing/detail?type=1&id_detail=${params.id}`) }} size="small">
            <ArrowForwardIosIcon sx={{ fontSize: 18 }} />
          </IconButton>
        </Grid>
      ),
    },
  ];
  // Tabel Profile 
  const columnsProfilePage = [
    {
      headerClassName: 'super-app-theme--header', field: 'calorie', headerName: 'Gar', align: 'right', width: 90, sortable: false, renderCell: (params) => (
        <Grid container sx={{ display: 'flex', cursor: 'pointer' }} justifyContent={'end'} alignItems={'center'}
          onClick={(e) => {
            if (params.row.label === 'penawaran') {
              navigate(`/penawaran/detail?type=1&id_detail=${params.id}`)
            } else {
              navigate(`/permintaan/detail?type=1&id_detail=${params.id}`)
            }
          }}
        >

          <Typography
            className="w-700"
            sx={{ fontSize: 14, color: '#081130' }}
          >
            {(params.row.calorie).toLocaleString(undefined, { maximumFractionDigits: 2 })}
          </Typography>
        </Grid>

      ),
    },
    {
      headerClassName: 'super-app-theme--header', field: 'total_sulfur', headerName: translate('page_explore.total-sulfur') + ' (%)', width: 90, sortable: false, renderCell: (params) => (

        <Grid container sx={{ display: 'flex', cursor: 'pointer' }} justifyContent={'end'} alignItems={'center'}
          onClick={(e) => {
            if (params.row.label === 'penawaran') {
              navigate(`/penawaran/detail?type=1&id_detail=${params.id}`)
            } else {
              navigate(`/permintaan/detail?type=1&id_detail=${params.id}`)
            }
          }}
        >

          <Typography
            className="w-700"
            sx={{ fontSize: 14, color: '#081130', }}
          >
            {(params.row.total_sulfur).toLocaleString(undefined, { maximumFractionDigits: 2 })}
          </Typography>
        </Grid>
      ),
    },
    {
      headerClassName: 'super-app-theme--header', field: 'ash', headerName: 'Ash (%)', width: 90, sortable: false, renderCell: (params) => (
        <Grid container sx={{ display: 'flex', cursor: 'pointer' }} justifyContent={'end'} alignItems={'center'}
          onClick={(e) => {
            if (params.row.label === 'penawaran') {
              navigate(`/penawaran/detail?type=1&id_detail=${params.id}`)
            } else {
              navigate(`/permintaan/detail?type=1&id_detail=${params.id}`)
            }
          }}
        >

          <Typography
            className="w-700"
            sx={{ fontSize: 14, color: '#081130' }}
          >
            {(params.row.ash).toLocaleString(undefined, { maximumFractionDigits: 2 })}

          </Typography>
        </Grid>

      ),
    },

    {
      headerClassName: 'super-app-theme--header', field: 'quantity', headerName: 'Quantity (MT)', width: 110, sortable: false, renderCell: (params) => (
        <Grid
          container
          sx={{ cursor: 'pointer', display: 'flex' }}
          onClick={(e) => {
            if (params.row.label === 'penawaran') {
              navigate(`/penawaran/detail?type=1&id_detail=${params.id}`)
            } else {
              navigate(`/permintaan/detail?type=1&id_detail=${params.id}`)
            }
          }}
          justifyContent={'flex-end'} alignItems={'center'}>
          <Typography
            className="w-700"
            sx={{ fontSize: 14, color: '#081130' }}
          >
            {(params.row.quantity).toLocaleString(undefined, { maximumFractionDigits: 2 })}
          </Typography>

        </Grid>
      ),
    },

    {
      headerClassName: 'super-app-theme--header', field: 'price', headerName: currency === 'idr' ? 'Rp  (/MT)' : 'USD  (/MT)', width: 110, sortable: false, renderCell: (params) => (
        <Grid container
          sx={{ cursor: 'pointer', display: 'flex' }}
          onClick={(e) => {
            if (params.row.label === 'penawaran') {
              navigate(`/penawaran/detail?type=1&id_detail=${params.id}`)
            } else {
              navigate(`/permintaan/detail?type=1&id_detail=${params.id}`)
            }
          }} justifyContent={'end'} alignItems={'center'}>

          <Typography
            className="w-700"
            sx={{ fontSize: 14 }}
          >
            {numberConvert(params.row.price)}
          </Typography>
        </Grid>
      ),
    },
    {
      headerClassName: 'super-app-theme--header', field: 'location', headerName: translate('title.location'), width: 140, sortable: false, renderCell: (params) => (
        <Grid container sx={{ display: 'flex', cursor: 'pointer' }} justifyContent={'end'} alignItems={'center'}
          onClick={(e) => { navigate(`/listing/detail?type=1&id_detail=${params.id}`) }}
        >
          <Typography
            className="w-500"
            sx={{ fontSize: 14, color: '#081130' }}
          >

            {(params?.row.location_name && params?.row.location_name.length > 16) ? params?.row.location_name.slice(0, 16) + '...' : params?.row.location_name}
          </Typography>

        </Grid>
      ),
    },
    {
      headerClassName: 'last-theme--header', field: 'user_name', headerName: 'Trader', width: 190, sortable: false, renderCell: (params) => (
        <Grid container sx={{ display: 'flex' }} justifyContent={'left'} alignItems={'center'}>
          {params.row.user_url_image === null ?
            <Avatar onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${params.row.user_id}` }} sx={{ bgcolor: 'blue', mr: 1, height: 32, width: 32, cursor: 'pointer' }} aria-label="recipe" />
            : <img onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${params.row.user_id}` }} style={{ borderRadius: '50%', marginRight: 6, cursor: 'pointer' }} src={config.baseURL + params.row.user_url_image} height={32} width={32} alt={config.baseURL + params.row.user_url_image}></img>}
          <Typography
            className="w-500"
            onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${params.row.user_id}` }}
            sx={{ fontSize: 14, ml: 1, color: '#081130', cursor: 'pointer' }}
          >
            {(params?.row.user_name && params?.row.user_name.length > 16) ? params?.row.user_name.slice(0, 16) + '...' : params?.row.user_name}
          </Typography>

        </Grid>
      ),
    },
    {
      headerClassName: 'last-theme--header', field: 'action', headerName: ' ', sortable: false, width: 195, renderCell: (params) => (
        <>
          {params.row.label === 'penawaran' && <Grid
            container

            alignItems="center"
            direction="row"
          >
            <Box
              sx={{
                background: "rgba(180, 202, 243, 0.22);",
                borderRadius: '300px',
                p: '2px 10px',
                height: '24px',
                display: 'flex',
                alignItems: 'center'

              }}
            >
              <Stack direction={'row'} alignItems={'center'}>
                <img alt="icon" src={PenawaranIcon} height={18}></img>
                <Typography className="w-500" sx={{ color: '#2457B9', fontSize: 12, ml: '4px' }} >
                  {translate('navbar.penawaran')}
                </Typography>

              </Stack>
            </Box>
            {isLogin &&
              <Love type='table' condition={params?.row?.user_favorite_id} remove={() => removeFavorite(1, params?.row?.product_id)} favorite={() => setFavorite(1, params?.row?.product_id)} />}

            {page !== '' ?

              <IconButton onClick={(e) => handleOpenShareMenu(e, params.id, params.row.phone_number)} sx={{ p: 0 }} size="small">
                <MoreHorizIcon sx={{ fontSize: 18, }} />

              </IconButton>

              : ''}
            <ModalShare label={'penawaran'} type={1} id={product_id} user_trader_phone={user_trader_phone} anchorEl={anchorElShare} open={anchorElShare} onClose={handleCloseShareMenu}></ModalShare>
            <IconButton onClick={(e) => { navigate(`/penawaran/detail?type=1&id_detail=${params.id}`) }} sx={{ p: 0 }} size="small">
              <ArrowForwardIosIcon sx={{ fontSize: 18 }} />
            </IconButton>
          </Grid>}

          {params.row.label === 'permintaan' && <Grid
            container
            // justifyContent="end"
            alignItems="center"
            direction="row"
          >

            <Box
              sx={{
                background: "rgba(139, 239, 179, 0.25);",
                borderRadius: '300px',
                p: '2px 10px',
                height: '24px',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Stack direction={'row'} alignItems={'center'}>
                <img alt="icon" src={PermintaanIcon} height={18}></img>
                <Typography className="w-500" sx={{ color: '#3BB56C', fontSize: 12, ml: '4px' }} >
                  {translate('navbar.permintaan')}
                </Typography>

              </Stack>
            </Box>
            {isLogin &&
              <Love type='table' condition={params?.row?.user_favorite_id} remove={() => removeFavorite(5, params?.row?.product_id)} favorite={() => setFavorite(5, params?.row?.product_id)} />}

            {page !== '' ?

              <IconButton onClick={(e) => handleOpenShareMenu(e, params.id, params.row.phone_number)} size="small" sx={{ p: 0 }}>
                <MoreHorizIcon sx={{ fontSize: 18, }} />

              </IconButton>

              : ''}
            <ModalShare label={'permintaan'} type={1} id={product_id} user_trader_phone={user_trader_phone} anchorEl={anchorElShare} open={anchorElShare} onClose={handleCloseShareMenu}></ModalShare>
            <IconButton onClick={(e) => { navigate(`/permintaan/detail?type=1&id_detail=${params.id}`) }} size="small" sx={{ p: 0 }}>
              <ArrowForwardIosIcon sx={{ fontSize: 18 }} />
            </IconButton>
          </Grid>}
        </>
      ),
    },

  ];

  if (page === 'home') {
    finalColumn = columns
  } else if (page === 'explore') {
    finalColumn = columnsExplorePage
  } else if (page === 'penawaran') {
    finalColumn = columnsPenawaranPage
  } else if (page === 'profile') {
    finalColumn = columnsProfilePage
  } else if (page === 'listing') {

    finalColumn = columnsListingPage
  }
  useEffect(() => {
    getProduct();
  }, []);
  async function getProduct() {
    try {

      const response = await searchCoal({
        page: 1,
        min_calorie: '', max_calorie: '', min_total_sulfur: '', max_total_sulfur: '', min_ash: '',
        max_ash: '', quantity: '', min_price: '', max_price: '', id_location: "", search_text: ""
      });

      setProductList(response.data.data.data.data);
    } catch (e) {
      console.log(e);
    }
  }
  return (
    <Hidden lgDown>
      <div className="ex-ui">
        <DataGrid
          autoHeight
          sx={{
            borderRadius: '8px',
            borderColor: '#E8EBF2',
            '& .super-app-theme--header': {
              borderRight: 1,
              borderColor: '#E8EBF2',
              fontFamily: 'Satoshi-500 !important',
              color: '#081130 !important',
              outline: "none !important",
              justifyContent: 'end'
            },
            '& .last-theme--header': {
              borderColor: '#E8EBF2',
              fontFamily: 'Satoshi-500 !important',
              color: '#081130 !important',
              outline: "none !important",
              justifyContent: 'end'
            },
            '.MuiDataGrid-iconButtonContainer': {
              visibility: 'visible',
            },
            '.MuiDataGrid-sortIcon': {
              opacity: 'inherit !important',
            },
            '.MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            "&.MuiDataGrid-root .MuiDataGrid-cell": {
              justifyContent: 'end',
            },
            ".super-app-theme--header .MuiDataGrid-columnHeaderTitleContainer": {
              justifyContent: 'end',
              pl: '4px'
            },
            ".last-theme--header .MuiDataGrid-columnHeaderTitleContainer": {
              justifyContent: 'start',
              pl: '4px'
            }
          }}
          rows={props.coalList ? props.coalList : productList}
          disableSelectionOnClick
          sortingOrder={['desc', 'asc']}
          disableColumnMenu
          components={{
            ColumnSortedAscendingIcon: UnfoldMoreIcon,
            ColumnSortedDescendingIcon: UnfoldMoreIcon
          }}
          getRowId={(row) => row.product_id}
          columns={finalColumn}
        />
      </div>
    </Hidden>
  )
}