import { Typography } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { Box, Button, Grid, Hidden, Paper, Stack } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ThemeProvider } from '@mui/material/styles';
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { TelegramShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import ShareIcon from "../assets/Share-ici.png";
import Arrowdownic from "../assets/arrow-down-ic.svg";
import Arrowunchangedic from "../assets/arrow-unchanged-ic.svg";
import Arrowupic from "../assets/arrow-up-ic.png";
import copyIc from "../assets/copy-ic.png";
import telegramIcon from "../assets/telegram-circle-grey-ic.png";
import twitterIcon from "../assets/twitter-circle-grey-ic.png";
import waIcon from "../assets/wa-circle-grey-ic.png";
import Footer from "../components/Footer";
import ModalShareListing from "../components/ModalShareListing";
import NavBar from "../components/Navbar";
import { getRealTimePriceLatestData, getRealTimePricesLatest } from "../services/api";
import { config } from "../services/constants";
import { GlobalState } from "../services/global";
import { StyledToggleButtonGroup, ToggleButton, theme } from '../services/primary';

export default function Realtimeprice() {
    const store = localStorage.getItem('currency')
    const [translate,] = useTranslation("global");
    const { currency, setcurrency } = React.useContext(GlobalState)
    const [realtimePrice, setRealtimePrice] = useState([]);
    const [realtimePriceHistory, setRealtimePriceHistory] = useState([]);
    const [realtimePriceCharts, setrealtimePriceCharts] = useState([]);
    const [statebtnhistory, setstatebtnhistory] = useState("ICI 1")
    const [statebtncharts, setstatebtncharts] = useState("ICI 1")
    const [data_charts, setdata_charts] = useState("")
    const [copyUrl, setCopyUrl] = useState(false)
    const [isOpenModalShare, setOpenModalShare] = useState(false)
    const [currencystate, setcurrencystate] = useState(currency ?? "usd")

    useEffect(() => {
        getDataRealTimePrice(currencystate)
        getDataRealTimeHistoryPrice(currencystate, statebtnhistory)
        getDataRealTimeChartsPrice(currencystate, statebtncharts)
    }, [])

    const handleChange = (event) => {
        setcurrencystate(event.target.value)
        getDataRealTimePrice(event.target.value)
        getDataRealTimeHistoryPrice(event.target.value, statebtnhistory)
        getDataRealTimeChartsPrice(event.target.value, statebtncharts)
    };

    const closeShareModal = () => {
        setOpenModalShare(false);
    }

    async function getDataRealTimeHistoryPrice(currency_state, short_name) {
        const data = {
            currency: currency_state,
            page: 1,
            size: 5,
            short_name: short_name
        }
        try {
            const res = await getRealTimePriceLatestData(data)
            setRealtimePriceHistory(res?.data.data.data);

        } catch (e) {
            console.log(e);
        }
    }

    async function getDataRealTimeChartsPrice(currency_state, short_name) {
        const data = {
            currency: currency_state,
            page: 1,
            size: 5,
            short_name: short_name
        }
        try {
            const res = await getRealTimePriceLatestData(data)
            setrealtimePriceCharts(res?.data);
            const price = res?.data?.data?.data.map(price => price.latest).reverse();
            const date = res?.data?.data?.data.map(date => date.date).reverse();

            let max = 0
            let min = Number(price[0])

            for (let i = 0; i < price.length; i++) {
                const element = price[i];
                if (Number(element) > max) {
                    max = Number(element)
                }
            }

            for (let i = 0; i < price.length; i++) {
                const element = price[i];
                if (Number(element) < min) {
                    min = Number(element)
                }
            }

            setdata_charts({

                series: [{
                    name: translate('page_explore.harga'),
                    data: price
                }],
                options: {

                    chart: {
                        toolbar: {
                            show: false
                        },
                        type: 'line',
                        height: 350,
                        zoom: {
                            enabled: false
                        }
                    },
                    grid: {
                        row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on 
                            opacity: 0.5
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'straight',
                        width: 0.8,
                        color: "#2457B9"
                    },
                    fill: {
                        color: "#2457B9",
                        type: "gradient",
                        gradient: {
                            shadeIntensity: 1,
                            opacityFrom: 0.7,
                            opacityTo: 0.7,
                            stops: [0, 90, 100]
                        }
                    },
                    tooltip: {
                        marker: {
                            show: false,
                        },
                    },
                    labels: date,

                    yaxis: {
                        opposite: false,
                        line: false,
                        min: min - (1 / 100 * max),
                        max: max + (1 / 100 * max)
                    },
                    legend: {
                        horizontalAlign: 'left'
                    }
                },


            });


        } catch (e) {
            console.log(e);
        }
    }

    async function getDataRealTimePrice(currency_state) {
        const data = {
            currency: currency_state
        }
        try {
            const res = await getRealTimePricesLatest(data)
            setRealtimePrice(res?.data.data);

        } catch (e) {
            console.log(e);
        }
    }

    function copyLink(e) {
        setCopyUrl(true)
        setTimeout(() => {
            setCopyUrl(false)
        }, 1000);
    }
    const copyClipboard = <div className="copyToClipboard" style={{ opacity: copyUrl ? "1" : "0" }}>✔ Copied to clipboard</div>

    return (
        <div className="detail-explore width-100" >
            <div className="flex-wrap-center relative width-100">
                <div className="container-nav relative width-100">
                    <div className='fixed-header'>
                        <NavBar></NavBar>
                    </div>
                </div>
                <Box className="flex-wrap-center width-100" sx={{ mt: { xs: 6, sm: 6, md: 11, lg: 11, xl: 11 }, }}>
                    <div className="content-inner">
                        <Grid
                            sx={{ mt: '16px' }}
                            container
                            direction="row"
                            alignItems={'center'}
                        >
                            <Button href="/" className="w-500" sx={{ fontSize: 16, color: '#2457B9', textTransform: 'none', pl: 0 }} variant="text">  {translate('page_explore.home')}</Button>

                            <Typography className="w-500" sx={{ fontSize: 16, mb: 1, color: '#56617B', pr: "8px !important" }}>
                                / {translate('title.ici-hargaterbaru')}
                            </Typography>

                        </Grid>

                        <Grid
                            container
                            direction="row"
                            rowSpacing={{ xs: 0, lg: 7 }}
                            columnSpacing={{ xs: 0, lg: 7 }}
                        >
                            {/* leftside Section */}
                            <Grid lg={7} xs={12} item sx={{ pt: { xs: 0, lg: "40px" } }} >
                                {/* Header Section */}
                                <Hidden mdDown>
                                    <Stack direction={'row'} spacing={1} justifyContent={'space-between'} sx={{ mt: 1 }}>
                                        <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                            <Typography className="w-700" style={{ fontSize: 18, color: '#081130' }}> {translate('navbar.hargaterbaru')}</Typography>
                                            <Box sx={{ background: "#F4F6FA", borderRadius: 4, padding: 1 }}>
                                                {realtimePriceCharts && <Stack direction={"row"} alignItems={"center"} spacing={1}>

                                                    <Typography className="w-700"
                                                        style={{ color: "#081130", fontSize: 14 }}
                                                    >
                                                        {realtimePriceCharts?.dateLatest?.date}
                                                    </Typography>

                                                </Stack>}
                                            </Box>
                                            <Paper
                                                elevation={0}
                                                sx={{
                                                    display: 'flex',

                                                    flexWrap: 'wrap',
                                                    borderRadius: '8px',
                                                    background: '#F4F6FA'
                                                }}
                                            >
                                                <ThemeProvider theme={theme}>
                                                    <StyledToggleButtonGroup
                                                        size="small"
                                                        value={currencystate}
                                                        exclusive
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                        }}
                                                        aria-label="text alignment"
                                                    >
                                                        <ToggleButton value="idr" className="w-500" sx={{ pl: 2, pr: '10px' }} >
                                                            IDR</ToggleButton>
                                                        <ToggleButton value="usd" className="w-500" sx={{ pl: 2, pr: '10px' }}>
                                                            USD</ToggleButton>

                                                    </StyledToggleButtonGroup>
                                                </ThemeProvider>

                                            </Paper>

                                        </Stack>

                                    </Stack>
                                </Hidden>
                                <Hidden mdUp>
                                    <Typography className="w-700"
                                        style={{ color: "#081130", fontSize: 16, mb: 2 }}
                                    >
                                        {translate('title.hargaterbaru')}
                                    </Typography>

                                    {realtimePriceCharts && <Stack direction={"row"} alignItems={"center"} spacing={1} sx={{ mt: 1 }}>

                                        <Typography className="w-700"
                                            style={{ color: "#081130", fontSize: 12 }}
                                        >
                                            {realtimePriceCharts?.dateLatest?.date}
                                        </Typography>
                                        <Typography className="w-700"
                                            style={{ color: "#081130", fontSize: 12 }}
                                        >
                                            {realtimePriceCharts?.dateLatest?.hour}
                                        </Typography>
                                        <Paper
                                            elevation={0}
                                            sx={{
                                                display: 'flex',

                                                flexWrap: 'wrap',
                                                borderRadius: '8px',
                                                background: '#F4F6FA'
                                            }}
                                        >
                                            <ThemeProvider theme={theme}>
                                                <StyledToggleButtonGroup
                                                    size="small"
                                                    value={currencystate}
                                                    exclusive
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                    }}
                                                    aria-label="text alignment"
                                                >
                                                    <ToggleButton value="idr" className="w-500" sx={{ pl: 1, pr: 1 }} >
                                                        IDR</ToggleButton>
                                                    <ToggleButton value="usd" className="w-500" sx={{ pl: 1, pr: 1 }}>
                                                        USD</ToggleButton>

                                                </StyledToggleButtonGroup>
                                            </ThemeProvider>

                                        </Paper>
                                        <Box onClick={(e) => setOpenModalShare(true)}>
                                            <img alt="share-icon" src={ShareIcon} height={'44px'} width={'44px'}></img>
                                        </Box>
                                    </Stack>}


                                </Hidden>
                                <Stack sx={{ mt: 2 }} >
                                    {(realtimePrice && realtimePrice.length > 0) && <Grid container direction={"row"} spacing={1} sx={{ mb: 2, mt: 1 }}>
                                        <Box className="tab-scroll-x">
                                            <Grid item sx={{ mr: 1 }}>
                                                <Box
                                                    onClick={() => {
                                                        setstatebtncharts("ICI 1")
                                                        getDataRealTimeChartsPrice(currencystate, "ICI 1")
                                                    }}
                                                    sx={{ background: statebtncharts === "ICI 1" ? "#CFDAF0" : "#F4F6FA", borderRadius: 4, padding: 1, width: "120px", borderColor: statebtncharts === "ICI 1" ? "#2457B9 !important" : "#E8EBF2 !important", border: 1, cursor: "pointer" }} >
                                                    <Stack spacing={1}>
                                                        <Typography className="w-700"
                                                            style={{ color: "#081130", fontSize: 12 }}
                                                        >
                                                            {realtimePrice[0].currency === "IDR" ? "Rp" : "$"} {realtimePrice[0].latest_formatted}

                                                        </Typography>
                                                        <Stack direction={"row"} spacing={1} justifyContent={"space-between"} alignItems={"center"}>
                                                            <Typography className="w-00"
                                                                style={{ color: "#081130", fontSize: 10 }}
                                                            >
                                                                {realtimePrice[0].shortName}
                                                            </Typography>
                                                            <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                                                {realtimePrice[0].arrowChange.includes('Increase') && <Typography
                                                                    className="roboto-w-500"
                                                                    style={{ color: "#3BB56C", fontSize: 10 }}
                                                                >
                                                                    {realtimePrice[0].change}
                                                                </Typography>}
                                                                {realtimePrice[0].arrowChange.includes('Decrease') && <Typography
                                                                    className="roboto-w-500"
                                                                    style={{ color: "#ED3131", fontSize: 10 }}
                                                                >
                                                                    {realtimePrice[0].change}
                                                                </Typography>}
                                                                {realtimePrice[0].arrowChange.includes('Unchanged') && <Typography
                                                                    className="roboto-w-500"
                                                                    style={{ fontSize: 10 }}
                                                                >
                                                                    {realtimePrice[0].change}
                                                                </Typography>}
                                                                {realtimePrice[0].arrowChange.includes('Increase') && <Box
                                                                    component="img"
                                                                    sx={{
                                                                        height: 14,
                                                                    }}
                                                                    alt="logo-img"
                                                                    src={Arrowupic}
                                                                />}
                                                                {realtimePrice[0].arrowChange.includes('Decrease') && <Box
                                                                    component="img"
                                                                    sx={{
                                                                        height: 14,
                                                                    }}
                                                                    alt="logo-img"
                                                                    src={Arrowdownic}
                                                                />}
                                                                {realtimePrice[0].arrowChange.includes('Unchanged') && <Box
                                                                    component="img"
                                                                    sx={{
                                                                        height: 14,
                                                                    }}
                                                                    alt="logo-img"
                                                                    src={Arrowunchangedic}
                                                                />}
                                                            </Stack>
                                                        </Stack>

                                                    </Stack>
                                                </Box>
                                            </Grid>
                                            <Grid item sx={{ mr: 1 }}>
                                                <Box
                                                    onClick={() => {
                                                        setstatebtncharts("ICI 2")
                                                        getDataRealTimeChartsPrice(currencystate, "ICI 2")
                                                    }}
                                                    sx={{ background: statebtncharts === "ICI 2" ? "#CFDAF0" : "#F4F6FA", borderRadius: 4, padding: 1, width: "120px", borderColor: statebtncharts === "ICI 2" ? "#2457B9 !important" : "#E8EBF2 !important", border: 1, cursor: "pointer" }} >
                                                    <Stack spacing={1}>
                                                        <Typography className="w-700"
                                                            style={{ color: "#081130", fontSize: 12 }}
                                                        > {realtimePrice[1].currency === "IDR" ? "Rp" : "$"} {realtimePrice[1].latest_formatted}

                                                        </Typography>
                                                        <Stack direction={"row"} spacing={1} justifyContent={"space-between"} alignItems={"center"}>
                                                            <Typography className="w-00"
                                                                style={{ color: "#081130", fontSize: 10 }}
                                                            >
                                                                {realtimePrice[1].shortName}
                                                            </Typography>
                                                            <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                                                {realtimePrice[1].arrowChange.includes('Increase') && <Typography
                                                                    className="roboto-w-500"
                                                                    style={{ color: "#3BB56C", fontSize: 10 }}
                                                                >
                                                                    {realtimePrice[1].change}
                                                                </Typography>}
                                                                {realtimePrice[1].arrowChange.includes('Decrease') && <Typography
                                                                    className="roboto-w-500"
                                                                    style={{ color: "#ED3131", fontSize: 10 }}
                                                                >
                                                                    {realtimePrice[1].change}
                                                                </Typography>}
                                                                {realtimePrice[1].arrowChange.includes('Unchanged') && <Typography
                                                                    className="roboto-w-500"
                                                                    style={{ fontSize: 10 }}
                                                                >
                                                                    {realtimePrice[1].change}
                                                                </Typography>}
                                                                {realtimePrice[1].arrowChange.includes('Increase') && <Box
                                                                    component="img"
                                                                    sx={{
                                                                        height: 14,
                                                                    }}
                                                                    alt="logo-img"
                                                                    src={Arrowupic}
                                                                />}
                                                                {realtimePrice[1].arrowChange.includes('Decrease') && <Box
                                                                    component="img"
                                                                    sx={{
                                                                        height: 14,
                                                                    }}
                                                                    alt="logo-img"
                                                                    src={Arrowdownic}
                                                                />}
                                                                {realtimePrice[1].arrowChange.includes('Unchanged') && <Box
                                                                    component="img"
                                                                    sx={{
                                                                        height: 14,
                                                                    }}
                                                                    alt="logo-img"
                                                                    src={Arrowunchangedic}
                                                                />}
                                                            </Stack>
                                                        </Stack>

                                                    </Stack>
                                                </Box>
                                            </Grid>
                                            <Grid item sx={{ mr: 1 }}>
                                                <Box
                                                    onClick={() => {
                                                        setstatebtncharts("ICI 3")
                                                        getDataRealTimeChartsPrice(currencystate, "ICI 3")
                                                    }}
                                                    sx={{ background: statebtncharts === "ICI 3" ? "#CFDAF0" : "#F4F6FA", borderRadius: 4, padding: 1, width: "120px", borderColor: statebtncharts === "ICI 3" ? "#2457B9 !important" : "#E8EBF2 !important", border: 1, cursor: "pointer" }} >
                                                    <Stack spacing={1}>
                                                        <Typography className="w-700"
                                                            style={{ color: "#081130", fontSize: 12 }}
                                                        > {realtimePrice[2].currency === "IDR" ? "Rp" : "$"} {realtimePrice[2].latest_formatted}

                                                        </Typography>
                                                        <Stack direction={"row"} spacing={1} justifyContent={"space-between"} alignItems={"center"}>
                                                            <Typography className="w-00"
                                                                style={{ color: "#081130", fontSize: 10 }}
                                                            >
                                                                {realtimePrice[2].shortName}
                                                            </Typography>
                                                            <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                                                {realtimePrice[2].arrowChange.includes('Increase') && <Typography
                                                                    className="roboto-w-500"
                                                                    style={{ color: "#3BB56C", fontSize: 10 }}
                                                                >
                                                                    {realtimePrice[2].change}
                                                                </Typography>}
                                                                {realtimePrice[2].arrowChange.includes('Decrease') && <Typography
                                                                    className="roboto-w-500"
                                                                    style={{ color: "#ED3131", fontSize: 10 }}
                                                                >
                                                                    {realtimePrice[2].change}
                                                                </Typography>}
                                                                {realtimePrice[2].arrowChange.includes('Unchanged') && <Typography
                                                                    className="roboto-w-500"
                                                                    style={{ fontSize: 10 }}
                                                                >
                                                                    {realtimePrice[2].change}
                                                                </Typography>}
                                                                {realtimePrice[2].arrowChange.includes('Increase') && <Box
                                                                    component="img"
                                                                    sx={{
                                                                        height: 14,
                                                                    }}
                                                                    alt="logo-img"
                                                                    src={Arrowupic}
                                                                />}
                                                                {realtimePrice[2].arrowChange.includes('Decrease') && <Box
                                                                    component="img"
                                                                    sx={{
                                                                        height: 14,
                                                                    }}
                                                                    alt="logo-img"
                                                                    src={Arrowdownic}
                                                                />}
                                                                {realtimePrice[2].arrowChange.includes('Unchanged') && <Box
                                                                    component="img"
                                                                    sx={{
                                                                        height: 14,
                                                                    }}
                                                                    alt="logo-img"
                                                                    src={Arrowunchangedic}
                                                                />}
                                                            </Stack>
                                                        </Stack>

                                                    </Stack>
                                                </Box>
                                            </Grid>
                                            <Grid item sx={{ mr: 1 }}>
                                                <Box
                                                    onClick={() => {
                                                        setstatebtncharts("ICI 4")
                                                        getDataRealTimeChartsPrice(currencystate, "ICI 4")
                                                    }}
                                                    sx={{ background: statebtncharts === "ICI 4" ? "#CFDAF0" : "#F4F6FA", borderRadius: 4, padding: 1, width: "120px", borderColor: statebtncharts === "ICI 4" ? "#2457B9 !important" : "#E8EBF2 !important", border: 1, cursor: "pointer" }} >
                                                    <Stack spacing={1}>
                                                        <Typography className="w-700"
                                                            style={{ color: "#081130", fontSize: 12 }}
                                                        > {realtimePrice[3].currency === "IDR" ? "Rp" : "$"} {realtimePrice[3].latest_formatted}

                                                        </Typography>
                                                        <Stack direction={"row"} spacing={1} justifyContent={"space-between"} alignItems={"center"}>
                                                            <Typography className="w-00"
                                                                style={{ color: "#081130", fontSize: 10 }}
                                                            >
                                                                {realtimePrice[3].shortName}
                                                            </Typography>
                                                            <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                                                {realtimePrice[3].arrowChange.includes('Increase') && <Typography
                                                                    className="roboto-w-500"
                                                                    style={{ color: "#3BB56C", fontSize: 10 }}
                                                                >
                                                                    {realtimePrice[3].change}
                                                                </Typography>}
                                                                {realtimePrice[3].arrowChange.includes('Decrease') && <Typography
                                                                    className="roboto-w-500"
                                                                    style={{ color: "#ED3131", fontSize: 10 }}
                                                                >
                                                                    {realtimePrice[3].change}
                                                                </Typography>}
                                                                {realtimePrice[3].arrowChange.includes('Unchanged') && <Typography
                                                                    className="roboto-w-500"
                                                                    style={{ fontSize: 10 }}
                                                                >
                                                                    {realtimePrice[3].change}
                                                                </Typography>}
                                                                {realtimePrice[3].arrowChange.includes('Increase') && <Box
                                                                    component="img"
                                                                    sx={{
                                                                        height: 14,
                                                                    }}
                                                                    alt="logo-img"
                                                                    src={Arrowupic}
                                                                />}
                                                                {realtimePrice[3].arrowChange.includes('Decrease') && <Box
                                                                    component="img"
                                                                    sx={{
                                                                        height: 14,
                                                                    }}
                                                                    alt="logo-img"
                                                                    src={Arrowdownic}
                                                                />}
                                                                {realtimePrice[3].arrowChange.includes('Unchanged') && <Box
                                                                    component="img"
                                                                    sx={{
                                                                        height: 14,
                                                                    }}
                                                                    alt="logo-img"
                                                                    src={Arrowunchangedic}
                                                                />}
                                                            </Stack>
                                                        </Stack>

                                                    </Stack>
                                                </Box>
                                            </Grid>
                                            <Grid item sx={{ mr: 1 }}>
                                                <Box
                                                    onClick={() => {
                                                        setstatebtncharts("ICI 5")
                                                        getDataRealTimeChartsPrice(currencystate, "ICI 5")
                                                    }}
                                                    sx={{ background: statebtncharts === "ICI 5" ? "#CFDAF0" : "#F4F6FA", borderRadius: 4, padding: 1, width: "120px", borderColor: statebtncharts === "ICI 5" ? "#2457B9 !important" : "#E8EBF2 !important", border: 1, cursor: "pointer" }} >
                                                    <Stack spacing={1}>
                                                        <Typography className="w-700"
                                                            style={{ color: "#081130", fontSize: 12 }}
                                                        > {realtimePrice[4].currency === "IDR" ? "Rp" : "$"} {realtimePrice[4].latest_formatted}

                                                        </Typography>
                                                        <Stack direction={"row"} spacing={1} justifyContent={"space-between"} alignItems={"center"}>
                                                            <Typography className="w-00"
                                                                style={{ color: "#081130", fontSize: 10 }}
                                                            >
                                                                {realtimePrice[4].shortName}
                                                            </Typography>
                                                            <Stack direction={"row"} alignItems={"center"} spacing={1}>
                                                                {realtimePrice[4].arrowChange.includes('Increase') && <Typography
                                                                    className="roboto-w-500"
                                                                    style={{ color: "#3BB56C", fontSize: 10 }}
                                                                >
                                                                    {realtimePrice[4].change}
                                                                </Typography>}
                                                                {realtimePrice[4].arrowChange.includes('Decrease') && <Typography
                                                                    className="roboto-w-500"
                                                                    style={{ color: "#ED3131", fontSize: 10 }}
                                                                >
                                                                    {realtimePrice[4].change}
                                                                </Typography>}
                                                                {realtimePrice[4].arrowChange.includes('Unchanged') && <Typography
                                                                    className="roboto-w-500"
                                                                    style={{ fontSize: 10 }}
                                                                >
                                                                    {realtimePrice[4].change}
                                                                </Typography>}
                                                                {realtimePrice[4].arrowChange.includes('Increase') && <Box
                                                                    component="img"
                                                                    sx={{
                                                                        height: 14,
                                                                    }}
                                                                    alt="logo-img"
                                                                    src={Arrowupic}
                                                                />}
                                                                {realtimePrice[4].arrowChange.includes('Decrease') && <Box
                                                                    component="img"
                                                                    sx={{
                                                                        height: 14,
                                                                    }}
                                                                    alt="logo-img"
                                                                    src={Arrowdownic}
                                                                />}
                                                                {realtimePrice[4].arrowChange.includes('Unchanged') && <Box
                                                                    component="img"
                                                                    sx={{
                                                                        height: 14,
                                                                    }}
                                                                    alt="logo-img"
                                                                    src={Arrowunchangedic}
                                                                />}
                                                            </Stack>
                                                        </Stack>

                                                    </Stack>
                                                </Box>
                                            </Grid>
                                        </Box>
                                    </Grid>}

                                    {data_charts && <Chart options={data_charts.options} series={data_charts.series} type="area" height={400} ></Chart>}

                                </Stack>
                            </Grid>
                            {/* rightside Section */}
                            <Grid lg={5} xs={12} item sx={{ pt: { xs: 0, lg: "40px" } }} >
                                <Hidden mdDown>
                                    <Stack sx={{ mt: 1 }} direction={"row"} justifyContent={"space-between"} spacing={1}>
                                        <Box sx={{ background: '#F4F6FA', border: '1px solid #E8EBF2', borderRadius: '8px', pt: '8px', pl: '8px', pr: '8px', pb: '8px' }}>
                                            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                                <Typography className="w-700" sx={{ color: '#56617B', fontSize: 14, }}>
                                                    {(`${config.staging}/icirealtimeprice/`).slice(0, 42) + '...'}
                                                </Typography>
                                                <img alt="copy-icon" src={copyIc} className="pointer" height={20} onClick={(e) => {
                                                    navigator.clipboard.writeText(`I've found this on #Indomine. Follow link for details : ${config.staging}/icirealtimeprice/`)

                                                    copyLink()
                                                }}></img>
                                            </Stack>
                                        </Box>
                                        <Stack sx={{ mt: '16px' }} spacing={1} direction={'row'} justifyContent={'space-evenly'} alignItems={'center'}>
                                            <TwitterShareButton url={`I've found this on #Indomine. Follow link for details : ${config.staging}/icirealtimeprice/`}>
                                                <img alt="twitter-icon" src={twitterIcon} height={'30px'} width={'30px'}></img>
                                            </TwitterShareButton>
                                            <WhatsappShareButton url={`I've found this on #Indomine. Follow link for details : ${config.staging}/icirealtimeprice/`}>
                                                <img alt="wa-icon" src={waIcon} height={'30px'} width={'30px'}></img>
                                            </WhatsappShareButton>
                                            <TelegramShareButton url={`I've found this on #Indomine. Follow link for details : ${config.staging}/icirealtimeprice/`}>
                                                <img alt="tele-icon" src={telegramIcon} height={'30px'} width={'30px'}></img>
                                            </TelegramShareButton>

                                        </Stack>
                                    </Stack>
                                </Hidden>

                                <Box sx={{ mb: 2 }}>
                                    <Stack sx={{ mt: 2 }}>
                                        <Typography className="w-700" style={{ fontSize: 18, color: '#081130' }}>    {translate('title.detailhargaterbaru')}</Typography>
                                        <TableContainer sx={{ mt: 1 }} component={Paper}>
                                            <Table sx={{ maxWidth: "100%", minWidth: 240 }} size="small" aria-label="a dense table">
                                                <TableHead className="w-500" sx={{ background: "#F4F6FA", color: "#2457B9" }}>
                                                    <TableRow>
                                                        <TableCell className="w-500" sx={{ color: "#2457B9" }}>NAME</TableCell>
                                                        <TableCell className="w-500" sx={{ color: "#2457B9" }}>UNITS</TableCell>
                                                        <TableCell className="w-500" sx={{ color: "#2457B9" }}>LATEST</TableCell>
                                                        <TableCell align="right" className="w-500" sx={{ color: "#2457B9" }}>CHANGE</TableCell>

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {realtimePrice.map((data) => (
                                                        <TableRow
                                                            key={data.name}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell className="w-500" sx={{ color: "#081130", fontSize: 10 }} component="th" scope="row">
                                                                {data.name}
                                                            </TableCell>
                                                            <TableCell className="w-500" sx={{ color: "#081130", fontSize: 10 }}>{data.units}</TableCell>
                                                            <TableCell className="w-500" sx={{ color: "#081130", fontSize: 10 }}>{data.currency === "IDR" ? "Rp" : "$"} {data.latest_formatted}</TableCell>
                                                            {data.arrowChange.includes('Increase') && <TableCell className="w-500" sx={{ color: "#3BB56C", fontSize: 10, }}>
                                                                <Stack direction={"row"} alignItems={"center"} justifyContent={"right"} >
                                                                    {data.change}
                                                                    <Box
                                                                        component="img"
                                                                        sx={{
                                                                            height: 12,
                                                                            ml: 1
                                                                        }}
                                                                        alt="logo-img"
                                                                        src={Arrowupic}
                                                                    />
                                                                </Stack>

                                                            </TableCell>}
                                                            {data.arrowChange.includes('Decrease') && <TableCell className="w-500" sx={{ color: "#ED3131", fontSize: 10, }}>
                                                                <Stack direction={"row"} alignItems={"center"} justifyContent={"right"} >
                                                                    {data.change}
                                                                    <Box
                                                                        component="img"
                                                                        sx={{
                                                                            height: 12,
                                                                            ml: 1
                                                                        }}
                                                                        alt="logo-img"
                                                                        src={Arrowdownic}
                                                                    />
                                                                </Stack>

                                                            </TableCell>}
                                                            {data.arrowChange.includes('Unchanged') && <TableCell className="w-500" sx={{ fontSize: 10, }}>
                                                                <Stack direction={"row"} alignItems={"center"} justifyContent={"right"} >
                                                                    {data.change}
                                                                    <Box
                                                                        component="img"
                                                                        sx={{
                                                                            height: 12,
                                                                            ml: 1
                                                                        }}
                                                                        alt="logo-img"
                                                                        src={Arrowunchangedic}
                                                                    />
                                                                </Stack>

                                                            </TableCell>}

                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Stack>
                                    <Divider orientation="horizontal" style={{ height: 1, marginTop: 16, marginBottom: 16 }} flexItem />
                                    {/* Button History Section */}
                                    <Stack direction={"row"} spacing={1} sx={{ mb: 1 }}>
                                        <Box onClick={() => {
                                            setstatebtnhistory("ICI 1")
                                            getDataRealTimeHistoryPrice(currencystate, "ICI 1")
                                        }} sx={{
                                            cursor: "pointer",
                                            background: statebtnhistory === "ICI 1" ? "#2457B9" : "#FFF",
                                            borderRadius: "10px", padding: 1, width: "50px", borderColor: statebtnhistory === "ICI 1" ? "none" : "#E8EBF2 !important", border: statebtnhistory === "ICI 1" ? "none" : 1,
                                        }}>
                                            <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={1}>

                                                <Typography className="w-500"
                                                    style={{ color: statebtnhistory === "ICI 1" ? "#FFF" : "#081130", fontSize: 12 }}
                                                >
                                                    ICI 1
                                                </Typography>

                                            </Stack>
                                        </Box>
                                        <Box onClick={() => {
                                            setstatebtnhistory("ICI 2")
                                            getDataRealTimeHistoryPrice(currencystate, "ICI 2")
                                        }} sx={{
                                            cursor: "pointer",
                                            background: statebtnhistory === "ICI 2" ? "#2457B9" : "#FFF",
                                            borderRadius: "10px", padding: 1, width: "50px", borderColor: statebtnhistory === "ICI 2" ? "none" : "#E8EBF2 !important", border: statebtnhistory === "ICI 2" ? "none" : 1,
                                        }}>
                                            <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={1}>

                                                <Typography className="w-500"
                                                    style={{ color: statebtnhistory === "ICI 2" ? "#FFF" : "#081130", fontSize: 12 }}
                                                >
                                                    ICI 2
                                                </Typography>

                                            </Stack>
                                        </Box>
                                        <Box onClick={() => {
                                            setstatebtnhistory("ICI 3")
                                            getDataRealTimeHistoryPrice(currencystate, "ICI 3")
                                        }} sx={{
                                            cursor: "pointer",
                                            background: statebtnhistory === "ICI 3" ? "#2457B9" : "#FFF",
                                            borderRadius: "10px", padding: 1, width: "50px", borderColor: statebtnhistory === "ICI 3" ? "none" : "#E8EBF2 !important", border: statebtnhistory === "ICI 3" ? "none" : 1,
                                        }}>
                                            <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={1}>

                                                <Typography className="w-500"
                                                    style={{ color: statebtnhistory === "ICI 3" ? "#FFF" : "#081130", fontSize: 12 }}
                                                >
                                                    ICI 3
                                                </Typography>

                                            </Stack>
                                        </Box>
                                        <Box onClick={() => {
                                            setstatebtnhistory("ICI 4")
                                            getDataRealTimeHistoryPrice(currencystate, "ICI 4")
                                        }} sx={{
                                            cursor: "pointer",
                                            background: statebtnhistory === "ICI 4" ? "#2457B9" : "#FFF",
                                            borderRadius: "10px", padding: 1, width: "50px", borderColor: statebtnhistory === "ICI 4" ? "none" : "#E8EBF2 !important", border: statebtnhistory === "ICI 4" ? "none" : 1,
                                        }}>
                                            <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={1}>

                                                <Typography className="w-500"
                                                    style={{ color: statebtnhistory === "ICI 4" ? "#FFF" : "#081130", fontSize: 12 }}
                                                >
                                                    ICI 4
                                                </Typography>

                                            </Stack>
                                        </Box>
                                        <Box onClick={() => {
                                            setstatebtnhistory("ICI 5")
                                            getDataRealTimeHistoryPrice(currencystate, "ICI 5")
                                        }} sx={{
                                            cursor: "pointer",
                                            background: statebtnhistory === "ICI 5" ? "#2457B9" : "#FFF",
                                            borderRadius: "10px", padding: 1, width: "50px", borderColor: statebtnhistory === "ICI 5" ? "none" : "#E8EBF2 !important", border: statebtnhistory === "ICI 5" ? "none" : 1,
                                        }}>
                                            <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={1}>

                                                <Typography className="w-500"
                                                    style={{ color: statebtnhistory === "ICI 5" ? "#FFF" : "#081130", fontSize: 12 }}
                                                >
                                                    ICI 5
                                                </Typography>

                                            </Stack>
                                        </Box>

                                    </Stack>

                                    <Stack >
                                        <Typography className="w-700" style={{ fontSize: 18, color: '#081130', mb: 2, mt: 2 }}>  {translate('title.history-harga')}</Typography>

                                        <TableContainer sx={{ mt: 1 }} component={Paper}>
                                            <Table sx={{ maxWidth: "100%", minWidth: 240, }} size="small" aria-label="a dense table">
                                                <TableHead className="w-500" sx={{ background: "#F4F6FA", color: "#2457B9" }}>
                                                    <TableRow>
                                                        <TableCell className="w-500" sx={{ color: "#2457B9" }}>TANGGAL</TableCell>

                                                        <TableCell align="center" className="w-500" sx={{ color: "#2457B9" }}>LATEST</TableCell>
                                                        <TableCell align="right" className="w-500" sx={{ color: "#2457B9" }}>CHANGE</TableCell>

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {realtimePriceHistory.map((data) => (
                                                        <TableRow
                                                            key={data.date}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell className="w-500" sx={{ color: "#081130", fontSize: 10 }} component="th" scope="row">
                                                                {data.date}
                                                            </TableCell>

                                                            <TableCell align="center" className="w-500" sx={{ color: "#081130", fontSize: 10 }}>{data.currency === "IDR" ? "Rp" : "$"}  {data.latest_formatted}</TableCell>
                                                            {data.arrowChange.includes('Increase') && <TableCell className="w-500" sx={{ color: "#3BB56C", fontSize: 10, }}>
                                                                <Stack direction={"row"} alignItems={"center"} justifyContent={"right"} >
                                                                    {data.change}
                                                                    <Box
                                                                        component="img"
                                                                        sx={{
                                                                            height: 12,
                                                                            ml: 1
                                                                        }}
                                                                        alt="logo-img"
                                                                        src={Arrowupic}
                                                                    />
                                                                </Stack>

                                                            </TableCell>}

                                                            {data.arrowChange.includes('Decrease') && <TableCell className="w-500" sx={{ color: "#ED3131", fontSize: 10, }}>
                                                                <Stack direction={"row"} alignItems={"center"} justifyContent={"right"} >
                                                                    {data.change}
                                                                    <Box
                                                                        component="img"
                                                                        sx={{
                                                                            height: 12,
                                                                            ml: 1
                                                                        }}
                                                                        alt="logo-img"
                                                                        src={Arrowdownic}
                                                                    />
                                                                </Stack>

                                                            </TableCell>}
                                                            {data.arrowChange.includes('Unchanged') && <TableCell className="w-500" sx={{ fontSize: 10, }}>
                                                                <Stack direction={"row"} alignItems={"center"} justifyContent={"right"} >
                                                                    {data.change}
                                                                    <Box
                                                                        component="img"
                                                                        sx={{
                                                                            height: 12,
                                                                            ml: 1
                                                                        }}
                                                                        alt="logo-img"
                                                                        src={Arrowunchangedic}
                                                                    />
                                                                </Stack>

                                                            </TableCell>}


                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Stack>
                                </Box>



                            </Grid>
                        </Grid>

                    </div>
                    <Hidden mdUp>
                        <ModalShareListing type={"icirealtime"} open={isOpenModalShare} close={closeShareModal}></ModalShareListing>
                    </Hidden>
                    <Footer></Footer>
                </Box>
            </div >


        </div >
    )
}
