import { Calendar } from "@hassanmojab/react-modern-calendar-datepicker";
import {
    Box,
} from "@mui/material";
import dayjs from 'dayjs';
import React, { useEffect, useState } from "react";
import "react-modern-calendar-datepicker/lib/DatePicker.css";

export default function DateRangePickerCustom(props) {
    const [selectedDayRange, setSelectedDayRange] = useState({
        from: null,
        to: null
    });

    const myCustomLocaleIndonesia = {
        // months list by order
        months: [
            'Januari',
            'Februari',
            'Maret',
            'April',
            'Mei',
            'June',
            'Juli',
            'Augustus',
            'September',
            'Oktober',
            'November',
            'Desember',
        ],

        // week days by order
        weekDays: [
            {
                name: 'Minggu', // used for accessibility 
                short: 'Mi', // displayed at the top of days' rows
                isWeekend: true, // is it a formal weekend or not?
            },
            {
                name: 'Senin',
                short: 'Se',
            },
            {
                name: 'Selasa',
                short: 'Se',
            },
            {
                name: 'Rabu',
                short: 'Ra',
            },
            {
                name: 'Kamis',
                short: 'Ka',
            },
            {
                name: "Jum'at",
                short: 'Ju',
            },
            {
                name: 'Sabtu',
                short: 'Sa',
                isWeekend: true,
            },
        ],

        // just play around with this number between 0 and 6
        weekStartingIndex: 0,

        // return a { year: number, month: number, day: number } object
        getToday(gregorainTodayObject) {
            return gregorainTodayObject;
        },

        // return a native JavaScript date here
        toNativeDate(date) {
            return new Date(date.year, date.month - 1, date.day);
        },

        // return a number for date's month length
        getMonthLength(date) {
            return new Date(date.year, date.month, 0).getDate();
        },

        // return a transformed digit to your locale
        transformDigit(digit) {
            return digit;
        },

        // texts in the date picker
        nextMonth: 'Next Month',
        previousMonth: 'Previous Month',
        openMonthSelector: 'Open Month Selector',
        openYearSelector: 'Open Year Selector',
        closeMonthSelector: 'Close Month Selector',
        closeYearSelector: 'Close Year Selector',
        defaultPlaceholder: 'Select...',

        // for input range value
        from: 'from',
        to: 'to',


        // used for input value when multi dates are selected
        digitSeparator: ',',

        // if your provide -2 for example, year will be 2 digited
        yearLetterSkip: 0,

        // is your language rtl or ltr?
        isRtl: false,
    }

    useEffect(() => {

        let start
        let end
        if (selectedDayRange.from) {
            start = `${selectedDayRange.from.year}-${selectedDayRange.from.month}-${selectedDayRange.from.day}`
            props.setStart(dayjs(start))
        } else {
            props.setStart("")
        }

        if (selectedDayRange.to) {
            end = `${selectedDayRange.to.year}-${selectedDayRange.to.month}-${selectedDayRange.to.day}`
            props.setEnd(dayjs(end))
        } else {
            props.setEnd("")
        }

    }, [selectedDayRange])

    return (
        <Box>
            {/* <Stack direction="column" alignItems="flex-start" spacing={6}> */}
            <Calendar
                value={selectedDayRange}
                onChange={setSelectedDayRange}
                locale={myCustomLocaleIndonesia}
            />
            {/* </Stack> */}
        </Box>
    )
}
