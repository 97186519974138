import Divider from "@material-ui/core/Divider";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { React } from 'react';
import { useTranslation } from "react-i18next";
import searchIcon from "../assets/search-explore-ic.png";

export default function MenuFilterCompany(props) {
  const [translate,] = useTranslation("global");

  return (
    <Box>
      <Box sx={{ border: 1, color: '#E8EBF2', borderRadius: 2, }}>
        <Grid
          container
          direction="row"
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{ pl: '16px', pt: '19px', pr: 2, pb: '19px' }}
        >
          <Typography className="w-700" sx={{ fontSize: 18, color: '#081130' }}>
            Filter
          </Typography>
          <Button className="w-500" onClick={(e) => {
            props.handleLocationReset('company')
            props.resetFilterPerusahaan(e)
          }} sx={{ fontSize: 16, color: '#56617B', textTransform: 'none' }} variant="text"> Reset filter</Button>

        </Grid>

        <Box sx={{ pl: '16px', pr: '16px', }}>
          <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
            {translate('page_explore.bidangperusahan')}
          </Typography>
          <Grid
            container
            direction="column"
            justifyContent={'space-between'}
            sx={{ mt: 1, pl: 2, pb: 2 }}
            spacing={2}
          >

            <Button onClick={(e) => props.handleFilterPerusahaan("", "bidang_usaha")}
              value={props.filterPerusahaan["bidang_usaha"]} sx={{
                "&:hover": {
                  borderColor: props.filterPerusahaan["bidang_usaha"] === '' ? '#3BB56C' : '#E8EBF2',
                }, textTransform: 'none', borderColor: props.filterPerusahaan["bidang_usaha"] === '' ? '#3BB56C' : '#E8EBF2', color: props.filterPerusahaan["bidang_usaha"] === '' ? '#3BB56C' : 'black', mb: 1, justifyContent: 'left', borderRadius: '8px', p: '14px 12px'
              }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterPerusahaan["bidang_usaha"] === '' ? '#3BB56C' : '#E8EBF2', }} />}>
              {translate('page_explore.semua')}
            </Button>
            <Button onClick={(e) => props.handleFilterPerusahaan("1", "bidang_usaha")}
              value={props.filterPerusahaan["bidang_usaha"]} sx={{
                "&:hover": {
                  borderColor: props.filterPerusahaan["bidang_usaha"] === '1' ? '#3BB56C' : '#E8EBF2',
                }, textTransform: 'none', justifyContent: 'left', borderColor: props.filterPerusahaan["bidang_usaha"] === '1' ? '#3BB56C' : '#E8EBF2', color: props.filterPerusahaan["bidang_usaha"] === '1' ? '#3BB56C' : 'black', mb: 1, borderRadius: '8px', p: '14px 12px'
              }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterPerusahaan["bidang_usaha"] === '1' ? '#3BB56C' : '#E8EBF2' }} />}>
              {translate('page_explore.penambang')}
            </Button>
            <Button onClick={(e) => props.handleFilterPerusahaan("2", "bidang_usaha")}
              value={props.filterPerusahaan["bidang_usaha"]} sx={{
                "&:hover": {
                  borderColor: props.filterPerusahaan["bidang_usaha"] === '2' ? '#3BB56C' : '#E8EBF2',
                }, textTransform: 'none', justifyContent: 'left', borderColor: props.filterPerusahaan["bidang_usaha"] === '2' ? '#3BB56C' : '#E8EBF2', color: props.filterPerusahaan["bidang_usaha"] === '2' ? '#3BB56C' : 'black', mb: 1, borderRadius: '8px', p: '14px 12px'
              }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterPerusahaan["bidang_usaha"] === '2' ? '#3BB56C' : '#E8EBF2', }} />}>
              Trader
            </Button>
            <Button onClick={(e) => props.handleFilterPerusahaan('3', "bidang_usaha")}
              value={props.filterPerusahaan["bidang_usaha"]} sx={{
                "&:hover": {
                  borderColor: props.filterPerusahaan["bidang_usaha"] === '3' ? '#3BB56C' : '#E8EBF2',
                }, textTransform: 'none', justifyContent: 'left', borderColor: props.filterPerusahaan["bidang_usaha"] === '3' ? '#3BB56C' : '#E8EBF2', color: props.filterPerusahaan["bidang_usaha"] === '3' ? '#3BB56C' : 'black', mb: 1, borderRadius: '8px', p: '14px 12px'
              }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterPerusahaan["bidang_usaha"] === '3' ? '#3BB56C' : '#E8EBF2', }} />}>
              {translate('page_explore.pelayaran')}
            </Button>
          </Grid>

        </Box>

        <Box sx={{ pl: '24px', pr: '16px', pb: '19px' }}>
          <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
            {translate('page_explore.lokasi')}
          </Typography>
          <FormGroup className="location-label">
            {props.dataProvince.map((option, i) => {
              if (i < 7) return (
                <FormControlLabel onChange={(e) => props.handleFilterPerusahaan(e, "location")} key={i} sx={{ fontSize: 14, color: '#081130' }} control={<Checkbox checked={props.selectedItems.includes(option.id)} sx={{
                  color: "#E8EBF2",
                  '&.Mui-checked': {
                    color: "#3BB56C",
                  },
                }} />} label={option.attributes.name} value={option.id} />)
            })}
          </FormGroup>
          {props.limitProvince < 10 &&
            <Grid
              sx={{ mt: 1 }}
              container
              justifyContent="left"
              alignItems="center"
              direction="row"
            >
              <Typography onClick={(e) => {
                props.setLimitProvince(7)
                props.setShowAllCompany(true)
              }}
                className="w-700"
                sx={{ fontSize: 14, color: "#2457B9", mr: 1, cursor: "pointer" }}
              >
                {translate('status.lihatdaerahlainnya')}
              </Typography>
              <ArrowForwardIosIcon sx={{ fontSize: 12, color: "#2457B9" }} />
            </Grid>}
        </Box>
      </Box>
      <Grid className="box-location" style={{ opacity: props.showAllCompany ? "1" : "", height: props.showAllCompany ? "520px" : "0px", width: props.showAllCompany ? "591px" : "0px" }}>
        <Stack direction={'row'} spacing={2} alignItems={'center'} sx={{ mb: 2 }}>
          <Typography className="w-700" sx={{ fontSize: 16, color: '#081130', mb: '2px' }}>
            {translate('page_explore.lokasi')}
          </Typography>
          <TextField
            className="search-filter-box"
            type="text"
            size="small"
            placeholder={translate('page_explore.search-lokasi')}
            onChange={(e) => props.handleSearchValueLocation(e.target.value)}
            value={props.searchValueLocation}
            onKeyDown={(e) => props.handleKeyDownLocation(e, 'company')}
            sx={{
              width: '100%', input: {
                fontSize: '14px', fontFamily: 'Satoshi-500'
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img alt="search-icon" src={searchIcon} height={20} width={20}></img>
                </InputAdornment>
              ),
            }}
          />
          <Divider orientation="vertical" style={{ marginLeft: 12, height: 20, width: '1px', }} />
          <IconButton onClick={() => {
            props.handleLocationReset('company')
            props.setLimitProvince(7)
            props.setShowAllCompany(false)
          }} size="small">
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider orientation="horizontal" style={{ height: 1, marginBottom: 10, marginTop: 10 }} flexItem />
        <div style={{ height: '360px', overflowY: 'scroll' }}>
          <FormGroup >
            {props.dataProvince.length > 0 ?
              props.dataProvince.map((option, i) => (
                <FormControlLabel onChange={(e) => props.handleFilterPerusahaan(e, "location")} key={i} sx={{ fontSize: 14, color: '#081130' }} control={<Checkbox checked={props.selectedItems.includes(option.id)} sx={{
                  color: "#E8EBF2",
                  '&.Mui-checked': {
                    color: "#3BB56C",
                  },
                }} />} label={option.attributes.name} value={option.id} />)
              )
              : translate('status.lokasinotfound')}
          </FormGroup>
        </div>
        <Stack direction={'row'} justifyContent={'end'} sx={{ mt: 1 }}>
          <Button
            onClick={(e) => props.handleLocationReset('company')}
            className="w-500"
            variant="text"
            sx={{
              fontSize: 16,
              color: "#2457B9",
              height: '40px',
              padding: '10px 12px',
              textTransform: "none",
              mr: '16px'
            }}
          >
            Reset
          </Button>
          <Button
            onClick={(e) => props.handleLocationSumbit('company')}
            className="button-blue-contained w-500"
            variant="contained"
            sx={{
              fontSize: 14,
              height: '40px',
              padding: '10px 12px',
            }}
          >
            {translate('status.terapkan')}
          </Button>
        </Stack>

      </Grid>
    </Box>
  );
}