import Paper from '@mui/material/Paper';
import { ThemeProvider } from '@mui/material/styles';
import * as React from 'react';
import { useTranslation } from "react-i18next";
import enIcon from "../assets/icons/eng-icon.svg";
import idIcon from "../assets/icons/id-icon.svg";
import { GlobalState } from "../services/global";
import { StyledToggleButtonGroup, ToggleButton, theme } from '../services/primary';


export default function LanguageToggle() {
    const store = localStorage.getItem('lang')
    const { lang, setLang } = React.useContext(GlobalState);
    const [translate, i18n] = useTranslation("global");

    const handleChange = (event, language) => {
        i18n.changeLanguage(language);

        setLang(language);
        localStorage.setItem('lang', language);
    };

    return (
        <div>
            <Paper
                elevation={0}
                sx={{
                    display: 'flex',

                    flexWrap: 'wrap',
                    borderRadius: '8px',
                    background: '#F4F6FA'
                }}
            > <ThemeProvider theme={theme}>
                    <StyledToggleButtonGroup
                        size="small"
                        value={lang == null ? store : lang}
                        exclusive
                        onChange={handleChange}
                        aria-label="text alignment"
                    >
                        <ToggleButton value="id" className="w-500"  >  <img src={idIcon} alt='' style={{ marginRight: '4px', }} /> ID</ToggleButton>
                        <ToggleButton value="en" className="w-500"><img style={{ marginRight: '4px' }} src={enIcon} alt='' />EN</ToggleButton>

                    </StyledToggleButtonGroup>
                </ThemeProvider>
            </Paper>
        </div>
    );
}