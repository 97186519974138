import Divider from "@material-ui/core/Divider";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, Stack, Typography } from "@mui/material";
import Drawer from '@mui/material/Drawer';
import { React } from 'react';
import { useTranslation } from "react-i18next";
import FormCompany from "./FormCompany";

export default function DrawerFilterTrader(props) {
  const [translate,] = useTranslation("global");

  return (
    <Box sx={{ flexGrow: 1, display: { xs: "flex", sm: 'flex', md: "flex" }, }}>

      <Drawer
        className="box-filter-drawer-trader"
        anchor="bottom"

        open={Boolean(props.open)}
        onClose={props.close}
      >
        <Box >
          <Stack
            direction="row"
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{ pl: '16px', pt: '19px', pr: 2, pb: '19px' }}
          >
            <Typography className="w-700" sx={{ fontSize: 18, color: '#081130' }}>
              Filter
            </Typography>
            <Stack direction={'row'} alignItems={'center'}>
              <Button className="w-500" onClick={(e) => {
                props.handleLocationReset('trader')
                props.resetFilterTrader('mobile')

              }} sx={{ fontSize: 16, color: '#2457B9', textTransform: 'none' }} variant="text"> Reset filter</Button>

              <Divider orientation="vertical" style={{ marginTop: 10, marginBottom: 10, marginLeft: 10, marginRight: 10 }} flexItem />
              <CloseIcon onClick={props.close} />
            </Stack>
          </Stack>
          <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
            <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
              {translate('page_explore.namaperusahaan')}
            </Typography>


            <FormCompany select={(e) => { props.handleFilterTrader(e.id, 'mobile', "company_id") }} currentValue={props.currentValueCompanyId} placeholder={translate('page_explore.pilihnamaperusahaan')} />

          </Box>

          <Box sx={{ pl: 2, pr: 2, mt: 1, mb: 2 }}>
            <Typography className="w-700" sx={{ fontSize: 14, color: '#081130' }}>
              {translate('page_explore.lokasi')}
            </Typography>
            <FormGroup className="location-label">
              {props.dataProvince.map((option, i) => {
                if (i < 7) return (
                  <FormControlLabel onChange={(e) => props.handleFilterTrader(e, "popuplocation")} key={i} sx={{ fontSize: 14, color: '#081130' }} control={<Checkbox checked={props.selectedItems.includes(option.id)} sx={{
                    color: "#E8EBF2",
                    '&.Mui-checked': {
                      color: "#3BB56C",
                    },
                  }} />} label={option.attributes.name} value={option.id} />)
              })}
            </FormGroup>
            {props.limitProvince < 10 &&
              <Grid
                sx={{ mt: 1 }}
                container
                justifyContent="left"
                alignItems="center"
                direction="row"
              >
                <Typography onClick={(e) => {
                  props.setLimitProvince(5)
                  props.handleOpenModalLocationResponsive("trader")
                }}
                  className="w-700"
                  sx={{ fontSize: 14, color: "#2457B9", mr: 1, cursor: "pointer" }}
                >
                  {translate('page_explore.lihatselengkapnya')}
                </Typography>
                <ArrowForwardIosIcon sx={{ fontSize: 14, color: "#2457B9" }} />
              </Grid>}
          </Box>
        </Box>
        <Divider orientation="horizontal" style={{ marginTop: 10, marginBottom: 10, marginLeft: 16, marginRight: 16 }} />
        <Stack direction={'row'} spacing={1} sx={{ ml: 2, mr: 2, mb: 2 }}>

          <Button className="w-500"
            onClick={(e) => {
              props.handleLocationReset('trader')
              props.resetFilterTrader()
              props.handleCloseModalFilter()
            }}
            fullWidth
            variant="outlined"
            sx={{
              textTransform: "none", border: '1px solid var(--primarry-blue, #2457B9)',
              p: '12px 16px',
              borderRadius: '8px',
              color: '#2457B9'
            }}
          >

            {translate('jual-beli.btn-cancel')}
          </Button>
          <Button className="w-500"
            onClick={(e) => {
              props.Filter('trader')
              props.handleCloseModalFilter()
            }}
            fullWidth
            variant="contained"
            sx={{ background: ' linear-gradient(270deg, #2457B9 15%, #2860CD 100%)', borderRadius: '8px', boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.12), 0px -1px 0px 0px rgba(0, 0, 0, 0.20) inset', padding: '12px 16px', textTransform: 'none' }}
          >

            {translate('page_explore.btn-filter')}
          </Button>
        </Stack>

      </Drawer>

    </Box>
  );
}