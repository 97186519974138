import CloseIcon from '@mui/icons-material/Close';
import { Box, Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import Ashicon from "../assets/ash-ic.png";
import Calendericon from "../assets/calender-dark-ic.png";
import Calorieicon from "../assets/calorie-ic.png";
import Sulfuricon from "../assets/sulfur-ic.png";

export default function CardPermintaanCoal(props) {
  const [translate,] = useTranslation("global");

  return (
    <div>
      <Card sx={{ borderRadius: 2, marginTop: '16px', mb: "40px", padding: { sm: '20px', xs: '16px' }, border: '2px solid #2457B9' }}>
        <CardContent sx={{ padding: '0px !important' }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between">
            <Typography
              className="w-700"
              sx={{ color: '#081130', fontSize: { sm: '18px', xs: '16px' } }}
            >
              {translate('status.titlepermintaanbatubara')}
            </Typography>
            <CloseIcon onClick={() => { props.resetFunc() }} sx={{ color: '#56617B', width: '24px', height: '24px' }} />
          </Stack>

          <Stack
            container
            direction="row"
            justifyContent="space-between"
            spacing={0}
            sx={{ mt: '16px', mb: '16px', flexWrap: 'wrap-reverse' }}
          >
            <Stack
              direction={'row'}
              alignItems={'center'}
              spacing={1}
            >
              <Typography
                sx={{ fontSize: 14, color: '#56617B' }}
                className="w-500"
              >
                Laycan
              </Typography>
              <img alt="calender-icon" src={Calendericon} height={20} width={20}></img>
              {props.data.start_laycan_date === "" ? (
                <Typography
                  sx={{ fontSize: 16, color: '#424242', ml: 1 }}
                  className="w-700"
                >

                </Typography>
              ) : (
                <Typography
                  sx={{ fontSize: 16, color: '#424242', ml: 1 }}
                  className="w-700"
                >
                  {`${new Date(props.data.start_laycan_date).toLocaleDateString('en-AU')}`}
                </Typography>
              )}

              <Typography
                sx={{ fontSize: { sm: '16px', xs: '12px' }, color: '#424242' }}
                className="w-700"
              >
                -
              </Typography>

              {props.data.end_laycan_date === "" ? (
                <Typography
                  sx={{ fontSize: 16, color: '#424242', ml: 1 }}
                  className="w-700"
                >

                </Typography>
              ) : (
                <Typography
                  sx={{ fontSize: 16, color: '#424242', ml: 1 }}
                  className="w-700"
                >
                  {`${new Date(props.data.end_laycan_date).toLocaleDateString('en-AU')}`}
                </Typography>
              )}
            </Stack>

            <Stack direction={'row'} sx={{ textAlign: "right", alignItems: "center", display: 'flex' }}  >
              {props.data.price === '' ? (
                <Typography
                  sx={{ fontSize: { sm: '20px', xs: '18px' }, color: '#081130', mr: 1 }}
                  className="w-700"
                >
                  {props.data.currency === 'idr' ? 'Rp' : '$'} -
                </Typography>
              ) : (
                <Typography
                  sx={{ fontSize: { sm: '20px', xs: '18px' }, color: '#081130', mr: 1 }}
                  className="w-700"
                >
                  {props.data.currency === 'idr' ? 'Rp' : '$'} {new Intl.NumberFormat().format(props.data.price)}
                </Typography>
              )}

              <Typography
                sx={{ fontSize: { sm: '16px', xs: '12px' }, color: '#56617B' }}
                className="w-500"
              >
                /ton
              </Typography>
            </Stack>
          </Stack>
          <Grid
            container
            direction="row"
            alignItems="left"
            justifyContent="space-between"
            spacing={{ xs: '12px', sm: '20px' }}
          >
            <Grid item md={3} xs={6}>
              <Box sx={{ background: "#F4F6FA", borderRadius: 2, padding: { sm: "12px 12px 12px 16px", xs: '12px' } }}>
                <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} >

                  <Typography className="w-700" sx={{ color: '#081130', fontSize: { sm: '20px', xs: '18px' }, }}>
                    {new Intl.NumberFormat().format(props.data.calorie)}
                  </Typography>
                  <img alt="Nikel-icon" src={Calorieicon} height={20}></img>
                </Stack>
                <Typography className="w-500" sx={{ color: '#56617B', fontSize: { sm: '14px', xs: '12px' } }} >
                  Gar
                </Typography>
              </Box>

            </Grid>
            <Grid item md={3} xs={6}>
              <Box sx={{ background: "#F4F6FA", borderRadius: 2, padding: { sm: "12px 12px 12px 16px", xs: '12px' } }}>
                <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>

                  <Typography className="w-700" sx={{ color: '#081130', fontSize: { sm: '20px', xs: '18px' }, }}>
                    {new Intl.NumberFormat().format(props.data.total_sulfur)}
                  </Typography>
                  <img alt="Nikel-icon" src={Sulfuricon} height={20}></img>
                </Stack>
                <Typography className="w-500" sx={{ color: '#56617B', fontSize: { sm: '14px', xs: '12px' } }} >
                  {translate('page_explore.total-sulfur')}
                </Typography>
              </Box>

            </Grid>
            <Grid item md={3} xs={6}>
              <Box sx={{ background: "#F4F6FA", borderRadius: 2, padding: { sm: "12px 12px 12px 16px", xs: '12px' } }}>
                <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>

                  <Typography className="w-700" sx={{ color: '#081130', fontSize: { sm: '20px', xs: '18px' }, }}>
                    {new Intl.NumberFormat().format(props.data.ASH)}
                  </Typography>
                  <img alt="Nikel-icon" src={Ashicon} height={20}></img>
                </Stack>
                <Typography className="w-500" sx={{ color: '#56617B', fontSize: { sm: '14px', xs: '12px' } }} >
                  Ash
                </Typography>
              </Box>
            </Grid>
            <Grid item md={3} xs={6}>
              <Box sx={{ background: "#F4F6FA", borderRadius: 2, padding: { sm: "12px 12px 12px 16px", xs: '12px' } }}>
                <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} alignItems={'center'} spacing={1}>
                  {props.data.quantity === '' ? (
                    <Typography className="w-700" sx={{ color: '#081130', fontSize: 20, lineHeight: 'normal' }}>
                      -
                    </Typography>
                  ) : (
                    <Typography className="w-700" sx={{ color: '#081130', fontSize: 20, lineHeight: 'normal' }}>
                      {new Intl.NumberFormat().format(props.data.quantity)}
                    </Typography>
                  )}
                  <Typography className="w-500" sx={{ color: '#56617B', fontSize: { sm: '14px', xs: '12px' } }} >
                    MT
                  </Typography>
                </Stack>
                <Typography className="w-500" sx={{ color: '#56617B', fontSize: { sm: '14px', xs: '12px' } }} >
                  Quantity
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Stack
            sx={{ mt: '16px' }}
            alignItems="center"
            direction="row"
          >
            <Typography
              className="w-500"
              sx={{ fontSize: { sm: '16px', xs: '12px' }, color: "#56617B", mr: 1 }}
            >
              Loading port
            </Typography>
            {props.data.name_loading_port === '' ? (
              <Typography
                className="w-700"
                sx={{ fontSize: { sm: '16px', xs: '14px' }, color: "#424242", mr: 1 }}
              >
                -
              </Typography>
            ) : (
              <Typography
                className="w-700"
                sx={{ fontSize: { sm: '16px', xs: '14px' }, color: "#424242", mr: 1 }}
              >
                {props.data.name_loading_port}
              </Typography>
            )}
          </Stack>
        </CardContent>
      </Card>
      <Stack direction={'row'} alignItems={'center'} sx={{ mt: '40px', mb: '16px' }}>
        <Typography
          className="w-700"
          sx={{ fontSize: 16, color: "#081130", mr: 1, }}
        >
          {translate('status.hasilpencarian')}
        </Typography>
        <Box sx={{ background: "#F4F6FA", borderRadius: '6px', padding: '4px' }}>
          <Typography
            className="w-700"
            sx={{ fontSize: 16, color: "#56617B", }}
          >
            {props.result}
          </Typography>
        </Box>
      </Stack>
    </div>
  )

}
