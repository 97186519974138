import { Menu, MenuItem, Stack, Typography } from "@mui/material";
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2';
import IconLogout from "../assets/logout-grey.svg";
import reportIcon from "../assets/reportflag-ic.png";
import { reportListing } from "../services/api";
import ModalRemoveStaff from "./ModalRemoveStaff";
import ModalSuccessReport from "./ModalSuccessReport";

export default function ModalStaffOption(props) {
    const [openModal, setOpenModal] = useState(false);
    const [openModalReport, setOpenModalReport] = useState(false);
    const [translate,] = useTranslation("global");

    const handleCloseModal = () => {
        setOpenModal(false)
        setOpenModalReport(false)
    }
    const handleOpenModal = () => {
        setOpenModal(true)
    }
    const handleOpenModalReport = () => {
        setOpenModalReport(true)
    }

    const SubmitReportListing = async () => {
        try {
            let params =
            {
                "data": {
                    "reason": '',
                    "type": 5,
                    "id_relation": props.userId,
                    token: localStorage.getItem("token")
                }
            }

            const responAPI = await reportListing(params)
            if (responAPI.status === 200) {
                handleOpenModalReport();
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Failed',
                    text: `${responAPI?.error?.message}`,
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Menu
            sx={{ zIndex: 99999, borderRadius: '8px' }}
            id="menu-appbar"
            elevation={1}
            anchorEl={props.anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={Boolean(props.open)}
            onClose={props.onClose}
        >

            <div>
                <MenuItem sx={{ p: '10px 16px' }} onClick={(e) => {
                    props.onClose();
                    handleOpenModal();
                }}>
                    <Stack direction={'row'} alignItems={'center'}>
                        <img alt="img-icon" src={IconLogout} height={14} width={14}></img>
                        <Typography className="w-500" sx={{ fontSize: '14px' }} textAlign="center">{translate('status.keluarkan')}</Typography>
                    </Stack>

                </MenuItem>
                <MenuItem sx={{ p: '10px 16px' }} onClick={() => {
                    props.onClose();
                    SubmitReportListing();
                }}>
                    <img alt="img-icon" src={reportIcon} height={14} width={14}></img>
                    <Typography className="w-500" sx={{ fontSize: '14px' }} textAlign="center">{translate('detail-explore.laporkanlisting')}</Typography>
                </MenuItem>
            </div>
            <ModalRemoveStaff id_company={props.id_company} name_company={props.name_company} id_user_staff={props.userId} datatrader={props.datatrader} open={openModal} close={handleCloseModal}></ModalRemoveStaff>

            <ModalSuccessReport open={openModalReport} close={handleCloseModal}></ModalSuccessReport>
        </Menu>
    )
}
