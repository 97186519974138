import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Avatar, Box, Card, CardContent, CardHeader, Grid, Hidden, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import defaultbannericon from "../assets/icons/default-tongkang.png";
import { addFavorite, deleteFavorite } from "../services/api.js";
import { config } from '../services/constants';
import { GlobalState } from "../services/global";
import { numberConvert } from '../services/primary';
import DrawerMore from "./DrawerMore";
import Love from "./Love";
import ModalShare from "./ModalShare";


export default function CardTongkangListDefault(props) {
  const data = props.data
  const [product_id, setProduct_id] = useState(null);
  const [user_trader_phone, setuser_trader_phone] = useState(null);
  const [translate,] = useTranslation("global");
  const [anchorElShare, setAnchorElShare] = useState(null);
  const [status_listing, setstatus_listing] = useState(null);
  const [isSold, setisSold] = useState(0);
  const [isLogin,] = useState(localStorage.getItem('token') !== null ? true : false);
  const { currency } = React.useContext(GlobalState)

  const setFavorite = (e, y) => {
    if (props.getTongkang !== undefined) {
      addFavorite({ type: 3, id_relation: parseInt(y) })
      // props.getTongkang();
    }
  }

  const removeFavorite = (e, y) => {
    deleteFavorite({ type_product: 3, id_product: parseInt(y) })
    // props.getTongkang();
  }

  let route
  if (props.page === 'listing') {
    route = '/listing/detail'
  } else {
    route = '/penawaran/detail'
  }
  const handleOpenShareMenu = (event, id, traderphone) => {
    setuser_trader_phone(traderphone);
    setProduct_id(id);
    setAnchorElShare(event.currentTarget);
  };

  const handleOpenShareMenuListing = (event, id, statuslisting, is_Sold) => {
    setisSold(is_Sold)
    setstatus_listing(statuslisting);
    setProduct_id(id);
    setAnchorElShare(event.currentTarget);
  };

  const handleCloseShareMenu = () => {
    setAnchorElShare(null);
  };

  return (
    <>
      <Hidden smDown>
        <Card sx={{ borderRadius: 2, height: '382px', position: 'relative' }}>
          {
            isLogin && <>
              {props.page !== 'detailperusahaan' &&
                <Love type='tongkang' condition={data?.user_favorite_id} remove={() => removeFavorite(3, data?.product_id)} favorite={() => setFavorite(3, data?.product_id)} />}
            </>
          }
          <CardHeader
            onClick={(e) => {
              window.location.href = `${route}?type=3&id_detail=${data.product_id}`
            }}
            sx={{
              backgroundImage: data.product_image === null ? `url(${defaultbannericon})` : `linear-gradient(180deg, rgba(8, 34, 86, 0.6) 16.83%, rgba(8, 34, 86, 0) 60.9%), url(${config.baseURL}${data.product_image})`,
              height: 120,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              position: "relative",
              cursor: 'pointer',

            }}
            avatar={
              <Box sx={{
                position: "absolute",
                top: '10%'
              }}>
                <Stack direction={'row'} >
                  <Box
                    sx={{
                      background: "#F4F6FA",
                      borderRadius: 4,
                      p: '2px 12px',
                      mr: '4px'
                    }}
                  >
                    <Stack direction={'row'} alignItems={'center'} >
                      {data.type_tongkang === 1 ? (
                        <>
                          <Box sx={{ borderRadius: '50%', background: '#F7CE46', width: '10px', height: '10px', mr: '4px' }}>
                          </Box>
                          <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 12 }} >
                            {translate('status.jual')}
                          </Typography>
                        </>

                      ) : data.type_tongkang === 2 ? (
                        <>
                          <Box sx={{ borderRadius: '50%', background: '#F55C24', width: '10px', height: '10px', mr: '4px' }}>
                          </Box>
                          <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 12 }} >
                            Time Charter
                          </Typography>
                        </>
                      ) : data.type_tongkang === 3 ? (
                        <>
                          <Box sx={{ borderRadius: '50%', background: '#883BE7', width: '10px', height: '10px', mr: '4px' }}>
                          </Box>
                          <Typography className="w-500" sx={{ color: '#818CA7', fontSize: '12px' }} >
                            Freight Charter
                          </Typography>
                        </>
                      ) : (
                        <></>
                      )}

                    </Stack>
                  </Box>
                  {props.page === 'listing' && <Box
                    sx={{
                      background: "#F4F6FA",
                      borderRadius: 4,
                      p: '2px 12px'
                    }}
                  > <Stack direction={'row'} alignItems={'center'} >
                      {data.status_listing === 1 && data.is_sold === "0" ? (
                        <>
                          <Box sx={{ borderRadius: '50%', background: '#3BB56C', width: '10px', height: '10px', mr: '4px', }}>
                          </Box>
                          <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 14 }} >
                            Publish
                          </Typography>
                        </>
                      ) : data.status_listing === 2 || data.is_sold === "1" ? (
                        <>
                          <Box sx={{ borderRadius: '50%', background: '#F7CE46', width: '10px', height: '10px', mr: '4px' }}>
                          </Box>
                          <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 14 }} >
                            Unpublish
                          </Typography>
                        </>
                      ) : data.status_listing === 3 ? (
                        <>
                          <Box sx={{ borderRadius: '50%', background: '#56617B', width: '10px', height: '10px', mr: '4px' }}>
                          </Box>
                          <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 14 }} >
                            Draft
                          </Typography>
                        </>
                      ) : (
                        <></>
                      )}
                    </Stack>
                  </Box>}
                </Stack>
              </Box>
            }

          >

          </CardHeader>
          <CardContent>
            <Box>
              <Grid
                container
                direction="row"
                alignItems="left"
                justifyContent="left"
                spacing={0}
              >
                <Grid item xs={8} sx={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    window.location.href = `${route}?type=3&id_detail=${data.product_id}`
                  }}>
                  <Typography className="w-700" sx={{ color: '#081130', fontSize: 16, }}>
                    {data.nama_tug_boat}
                  </Typography>
                </Grid>
                {props.page === 'listing' ?
                  <Grid sx={{ textAlign: "right" }} item xs={4}>
                    <MoreHorizIcon sx={{ cursor: 'pointer' }} onClick={(e) => handleOpenShareMenuListing(e, data.product_id, data.status_listing, data.is_sold)} />
                    <ModalShare page={'listing'} type={3} isSold={isSold} id={product_id} status_listing={status_listing} anchorEl={anchorElShare} open={anchorElShare} onClose={handleCloseShareMenu}></ModalShare>
                  </Grid>
                  : <Grid sx={{ textAlign: "right" }} item xs={4}>
                    <MoreHorizIcon sx={{ cursor: 'pointer' }} onClick={(e) => handleOpenShareMenu(e, data.product_id, data.phone_number)} />
                    <ModalShare page={'tongkang'} type={3} label={'penawaran'} id={product_id} user_trader_phone={user_trader_phone} anchorEl={anchorElShare} open={anchorElShare} onClose={handleCloseShareMenu}></ModalShare>
                  </Grid>}
              </Grid>

              <Box
                sx={{ cursor: 'pointer' }}
                onClick={(e) => {
                  window.location.href = `${route}?type=3&id_detail=${data.product_id}`
                }}
              >
                <Typography className="w-500" sx={{ color: '#56617B', fontSize: 14, mb: 1 }} >
                  {data.nama_tongkang}
                </Typography>

                <Grid
                  container
                  direction="row"
                  alignItems="left"
                  justifyContent="left"
                  spacing={0}
                >
                  <Grid item xs={7}>
                    <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                      {translate('page_explore.harga')}
                    </Typography>
                  </Grid>
                  <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                      {translate('page_explore.ukurantongkang')}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  alignItems="left"
                  justifyContent="left"
                  spacing={0}
                >
                  <Grid item xs={7}>
                    {currency === 'idr' && <Typography className="w-700" sx={{ color: '#081130', fontSize: 14, }}>
                      Rp {numberConvert(data.price)}
                    </Typography>}
                    {currency === 'usd' && <Typography className="w-700" sx={{ color: '#081130', fontSize: 14, }}>
                      $ {numberConvert(data.price)}
                    </Typography>}
                  </Grid>
                  <Grid item xs={5}>
                    <Typography className="w-700" sx={{ color: '#081130', fontSize: 14, }}>
                      {data.ukuran_name}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box
              sx={{
                background: "#F4F6FA",
                borderRadius: "6px",
                padding: "8px 12px",
                mt: data.status_listing === 3 ? 4 : 3.5,
                height: '100%'
              }}
            >
              <Typography
                sx={{ fontSize: 10, mb: 1, color: '#818CA7', textTransform: 'uppercase' }}
                className="w-500"
              >
                {translate('page_explore.listingoleh')}
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="left"
                spacing="8px"
              >
                {data.user_url_image === null ?
                  <Avatar onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${data.user_id}` }} sx={{ bgcolor: 'blue', width: '36px', height: '36px', mr: '6px', cursor: 'pointer' }} aria-label="recipe" />
                  : <img onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${data.user_id}` }} style={{ borderRadius: '50%', marginRight: 6, cursor: 'pointer' }} src={config.baseURL + data.user_url_image} height={36} width={36}></img>}
                <Stack spacing={0}>

                  <Typography
                    sx={{ fontSize: { xs: 10, md: 12 }, color: '#081130' }}
                    className="w-500 s-1216"
                  >
                    {data.company_name_listing === null ? 'No company listed' : data.company_name_listing}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </CardContent>
        </Card>
      </Hidden>
      <Hidden smUp>
        <Card sx={{ borderRadius: 2, height: '382px', position: 'relative' }}>
          {
            isLogin && <>
              {props.page !== 'detailperusahaan' &&
                <Love type='tongkang' condition={data?.user_favorite_id} remove={() => removeFavorite(3, data?.product_id)} favorite={() => setFavorite(3, data?.product_id)} />}
            </>
          }
          <CardHeader
            onClick={(e) => {
              window.location.href = `${route}?type=3&id_detail=${data.product_id}`
            }}
            sx={{
              backgroundImage: data.product_image === null ? `url(${defaultbannericon})` : `linear-gradient(180deg, rgba(8, 34, 86, 0.6) 16.83%, rgba(8, 34, 86, 0) 60.9%), url(${config.baseURL}${data.product_image})`,
              height: 120,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              position: "relative",
              cursor: 'pointer',

            }}
            avatar={
              <Box sx={{
                position: "absolute",
                top: '10%'
              }}>
                <Stack direction={'row'} >
                  <Box
                    sx={{
                      background: "#F4F6FA",
                      borderRadius: 4,
                      p: '2px 12px',
                      mr: '4px'
                    }}
                  >
                    <Stack direction={'row'} alignItems={'center'} >
                      {data.type_tongkang === 1 ? (
                        <>
                          <Box sx={{ borderRadius: '50%', background: '#F7CE46', width: '10px', height: '10px', mr: '4px' }}>
                          </Box>
                          <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 12 }} >
                            {translate('status.jual')}
                          </Typography>
                        </>

                      ) : data.type_tongkang === 2 ? (
                        <>
                          <Box sx={{ borderRadius: '50%', background: '#F55C24', width: '10px', height: '10px', mr: '4px' }}>
                          </Box>
                          <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 12 }} >
                            Time Charter
                          </Typography>
                        </>
                      ) : data.type_tongkang === 3 ? (
                        <>
                          <Box sx={{ borderRadius: '50%', background: '#883BE7', width: '10px', height: '10px', mr: '4px' }}>
                          </Box>
                          <Typography className="w-500" sx={{ color: '#818CA7', fontSize: '12px' }} >
                            Freight Charter
                          </Typography>
                        </>
                      ) : (
                        <></>
                      )}

                    </Stack>
                  </Box>
                  {props.page === 'listing' && <Box
                    sx={{
                      background: "#F4F6FA",
                      borderRadius: 4,
                      p: '2px 12px'
                    }}
                  > <Stack direction={'row'} alignItems={'center'} >
                      {data.status_listing === 1 && data.is_sold === 0 ? (
                        <>
                          <Box sx={{ borderRadius: '50%', background: '#3BB56C', width: '10px', height: '10px', mr: '4px', }}>
                          </Box>
                          <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 14 }} >
                            Publish
                          </Typography>
                        </>
                      ) : data.status_listing === 2 || data.is_sold === 1 ? (
                        <>
                          <Box sx={{ borderRadius: '50%', background: '#F7CE46', width: '10px', height: '10px', mr: '4px' }}>
                          </Box>
                          <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 14 }} >
                            Unpublish
                          </Typography>
                        </>
                      ) : data.status_listing === 3 ? (
                        <>
                          <Box sx={{ borderRadius: '50%', background: '#56617B', width: '10px', height: '10px', mr: '4px' }}>
                          </Box>
                          <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 14 }} >
                            Draft
                          </Typography>
                        </>
                      ) : (
                        <></>
                      )}
                    </Stack>
                  </Box>}
                </Stack>
              </Box>
            }

          >

          </CardHeader>
          <CardContent>
            <Box>
              <Grid
                container
                direction="row"
                alignItems="left"
                justifyContent="left"
                spacing={0}
              >
                <Grid item xs={8} sx={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    window.location.href = `${route}?type=3&id_detail=${data.product_id}`
                  }}>
                  <Typography className="w-700" sx={{ color: '#081130', fontSize: 16, }}>
                    {data.nama_tug_boat}
                  </Typography>
                </Grid>
                {props.page === 'listing' ?
                  <Grid sx={{ textAlign: "right" }} item xs={4}>
                    <MoreHorizIcon sx={{ cursor: 'pointer' }} onClick={(e) => handleOpenShareMenuListing(e, data.product_id, data.status_listing, data.is_sold)} />

                    <DrawerMore is_sold={isSold} type={'listing'} page_status="3" page_detail='tongkang' page={'listing'} id={product_id} status_listing={status_listing} open={anchorElShare} close={handleCloseShareMenu}>
                    </DrawerMore>

                  </Grid>
                  : <Grid sx={{ textAlign: "right" }} item xs={4}>
                    <MoreHorizIcon sx={{ cursor: 'pointer' }} onClick={(e) => handleOpenShareMenu(e, data.product_id, data.phone_number)} />
                    <DrawerMore type={3} page={'explore'} label={'penawaran'} id={product_id} user_trader_phone={user_trader_phone} open={anchorElShare} close={handleCloseShareMenu}>
                    </DrawerMore>
                  </Grid>}
              </Grid>

              <Box
                sx={{ cursor: 'pointer' }}
                onClick={(e) => {
                  window.location.href = `${route}?type=3&id_detail=${data.product_id}`
                }}
              >
                <Typography className="w-500" sx={{ color: '#56617B', fontSize: 14, mb: 1 }} >
                  {data.nama_tongkang}
                </Typography>
                <Grid
                  container
                  direction="row"
                  alignItems="left"
                  justifyContent="left"
                  spacing={0}
                >
                  <Grid item xs={7}>
                    <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                      {translate('page_explore.harga')}
                    </Typography>
                  </Grid>
                  <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                      {translate('page_explore.ukurantongkang')}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  alignItems="left"
                  justifyContent="left"
                  spacing={0}
                >
                  <Grid item xs={7}>
                    {currency === 'idr' && <Typography className="w-700" sx={{ color: '#081130', fontSize: 14, }}>
                      Rp {numberConvert(data.price)}
                    </Typography>}
                    {currency === 'usd' && <Typography className="w-700" sx={{ color: '#081130', fontSize: 14, }}>
                      $ {numberConvert(data.price)}
                    </Typography>}
                  </Grid>
                  <Grid item xs={5}>
                    <Typography className="w-700" sx={{ color: '#081130', fontSize: 14, }}>
                      {data.ukuran_name}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box
              sx={{
                background: "#F4F6FA",
                borderRadius: "6px",
                padding: "8px 12px",

                mt: 4,
                height: '100%'
              }}
            >
              <Typography
                sx={{ fontSize: 10, mb: 1, color: '#818CA7', textTransform: 'uppercase' }}
                className="w-500"
              >
                {translate('page_explore.listingoleh')}
              </Typography>
              <Stack

                direction="row"
                alignItems="center"
                justifyContent="left"
                spacing="8px"
              >
                {data.user_url_image === null ?
                  <Avatar onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${data.user_id}` }} sx={{ bgcolor: 'blue', width: '36px', height: '36px', mr: '6px', cursor: 'pointer' }} aria-label="recipe" />
                  : <img onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${data.user_id}` }} style={{ borderRadius: '50%', marginRight: 6, cursor: 'pointer' }} src={config.baseURL + data.user_url_image} height={36} width={36}></img>}
                <Stack spacing={0}>

                  <Typography
                    sx={{ fontSize: { xs: 10, md: 12 }, color: '#081130' }}
                    className="w-500 s-1216"
                  >
                    {data.company_name_listing === null ? '  No Company listed' : data.company_name_listing}
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </CardContent>
        </Card>

      </Hidden>
    </>
  )
}
