import CloseIcon from '@mui/icons-material/Close';
import { Button, Checkbox, Divider, FormControlLabel, FormGroup, Grid, IconButton, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function ModalOtentikasiTwoFactor(props) {
    const [translate,] = useTranslation("global");
    const [isCheck, setCheck] = useState(false);
    const handleChange = (e) => {
        setCheck(e.target.checked);
    };
    return (
        <div>
            <Modal
                open={props.open}
                onClose={props.close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '304px', sm: 343, md: 343, lg: 500, xl: 500 },
                    borderRadius: '8px',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: { xs: '16px', sm: '24px', md: '24px', lg: '24px', xl: '24px' },
                    "&:focus": {
                        outline: "none"
                    },
                }}>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                        <Typography id="modal-modal-title" className='w-700' sx={{ fontSize: { xs: '18px', sm: '24px' }, color: '#081130', textAlign: 'center', }}>
                            {translate('status.titleotentikasi')}
                        </Typography>
                        <IconButton size="small" >
                            <CloseIcon onClick={() => {
                                props.close();
                            }} />
                        </IconButton>
                    </Stack>

                    <Typography id="modal-modal-description" className='w-500' sx={{ mt: '14px', color: '#56617B', fontSize: '16px', textAlign: 'start' }}>
                        {translate('status.subotentikasi')}
                    </Typography>
                    <FormGroup >
                        <FormControlLabel className="w-500" sx={{ fontSize: 14, color: '#081130' }} control={<Checkbox checked={isCheck ? isCheck : false}
                            onChange={handleChange} sx={{
                                color: "#E8EBF2",
                                '&.Mui-checked': {
                                    color: "#3BB56C",
                                },
                            }} />} label={<Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mt: 2 }} >   {translate('status.placeholderotentikasi')}</Typography>} />

                    </FormGroup>
                    <Divider orientation="horizontal" style={{ marginTop: 24, marginBottom: 24, }} flexItem />
                    <Grid container justifyContent={{ xs: 'space-between', sm: 'space-between', md: 'space-between', lg: 'end', xl: 'end' }} direction='row' sx={{ mb: 0 }}>

                        <Button
                            onClick={() => {
                                props.close();
                            }}
                            size="large"
                            className="w-500"
                            variant="outlined"
                            sx={{
                                mr: { xs: '0', sm: '0', md: '0', lg: '16px', xl: '16px' },
                                width: { xs: '147px', sm: '147px', md: '147px', lg: '124px', xl: '124px' },
                                color: '#2457B9',
                                textTransform: 'none',
                                height: '40px',
                                padding: '10px 12px',
                                borderColor: '#2457B9',
                                borderRadius: '8px'
                            }}
                        >
                            {translate('jual-beli.btn-cancel')}
                        </Button>

                        {isCheck === true ? <Button
                            onClick={() => {
                                props.HandleOtentikasi();
                                props.close();
                            }}
                            size="large"
                            className="w-500"
                            variant="contained"
                            sx={{
                                width: { xs: '147px', sm: '147px', md: '147px', lg: '124px', xl: '124px' },
                                background: ' #2457B9',
                                textTransform: 'none',
                                height: '40px',
                                padding: '10px 12px',
                                borderRadius: '8px',
                            }}
                        >
                            OK
                        </Button> : <Button
                            disabled
                            size="large"
                            className="w-500"
                            variant="contained"
                            sx={{
                                width: { xs: '147px', sm: '147px', md: '147px', lg: '124px', xl: '124px' },
                                height: '40px',
                                borderRadius: '8px',
                                background: ' #2457B9',
                                textTransform: 'none',
                                padding: '10px 12px',
                            }}
                        >
                            OK
                        </Button>}
                    </Grid>
                </Box>
            </Modal>
        </div >
    );
}