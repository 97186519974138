import CloseIcon from '@mui/icons-material/Close';
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';
import { Box, Drawer, IconButton, Stack, Typography } from "@mui/material";
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import callIcon from "../assets/call-company-ic.png";
import visibilityIcon from "../assets/eye-icon.svg";
import reporticon from "../assets/reportflag-ic.png";
import shareIcon from "../assets/share-listing-ic.png";
import soldIcon from "../assets/sold-grey-icon.png";
import deleteIcon from "../assets/trash-ic.png";
import unpublishIcon from "../assets/unpublish-ic.png";
import ModalHapusListing from "../components/ModalHapusListing";
import ModalShareListing from "../components/ModalShareListing";
import ModalUnpublish from "../components/ModalUnpublish";
import { editBatubara, editNikel, editTongkang, getDetailBatubara, getDetailNikel, getDetailTongkang } from "../services/api";
import ModalReportListing from './ModalReportListing';
import ModalShareListingCompany from './ModalShareListingCompany';

export default function DrawerMore(props) {
    const navigate = useNavigate();
    const [isOpenModalHapus, setOpenModalHapus] = React.useState(false)
    const [isOpenModalUnpublish, setOpenModalUnpublish] = React.useState(false)
    const [status, setstatus] = React.useState(false)
    const [openModalReport, setOpenModalReport] = useState(false);
    const [isLogin] = useState(localStorage.getItem('token') !== null ? true : false);
    const [translate,] = useTranslation("global");
    const [openShareListing, setopenShareListing] = useState(false);
    const [openShareListingCompany, setopenShareListingCompany] = useState(false);
    const [label, setLabel] = useState(false);


    const handleOpenModal = (state) => {
        if (state === 'hapus') {
            setOpenModalHapus(true);
        } else if (state === 'unpublish') {
            setOpenModalUnpublish(true);
            setstatus('unpublish');
        } else if (state === 'publish') {
            setOpenModalUnpublish(true);
            setstatus('publish');
        }
    };

    const handleOpenModalShareCompany = (label) => {
        setLabel(label);
        setopenShareListingCompany(true)
    }

    const handleOpenModalShare = (label) => {
        setLabel(label);
        setopenShareListing(true)
    }

    const handleOpenModalReport = () => {
        setOpenModalReport(true)
    }

    const handleCloseModal = () => {
        setOpenModalReport(false)
        setopenShareListing(false)
        setopenShareListingCompany(false)
    }

    // let type = '';
    let is_sold = 0;

    if (props.is_sold === false) {
        is_sold = 0
    } else if (props.is_sold === true) {
        is_sold = 1
    } else {
        is_sold = props.is_sold
    }

    // if (props.type === 'coal') {
    //     type = 1
    // } else if (props.type === 'nikel') {
    //     type = 2
    // } else if (props.type === 'tongkang') {
    //     type = 3
    // } else if (props.type === 'company') {
    //     type = 4
    // } else if (props.type === 'trader') {
    //     type = 5
    // } else if (props.type === 'permintaan') {
    //     type = props.page_detail
    // } else if (props.type === 'listing') {
    //     type = props.page_detail
    // } else {
    //     type = props.type
    // }

    const handleRedirectDraft = () => {
        if (props.type === 'listing') {
            if (props.page_detail === 'coal') {
                getDataDetailBatubara(props.id)
            } else if (props.page_detail === 'nikel') {
                getDataDetailNikel(props.id)
            } else {
                getDataDetailTongkang(props.id)
            }
        }
    }

    const getDataDetailBatubara = async (id) => {
        try {
            let lang = localStorage.getItem('lang') === 'null' || localStorage.getItem('lang') === 'id' ? 'id' : localStorage.getItem('lang')
            let id_user = localStorage.getItem("id_user")
            const responAPI = await getDetailBatubara(id, lang)
            if (Number(id_user) === Number(responAPI.data.data.user_id)) {

                navigate('/jual-batubara', { state: { page: 'edit-batubara', data: responAPI.data.data } })
            } else {
                navigate('/signin', { state: { detail: 'listing', type: 1, id_relation: id } })
            }
        } catch (error) {

            console.log(error)
        }
    }

    const getDataDetailNikel = async (id) => {
        try {
            let lang = localStorage.getItem('lang') === 'null' || localStorage.getItem('lang') === 'id' ? 'id' : localStorage.getItem('lang')
            let id_user = localStorage.getItem("id_user")
            const responAPI = await getDetailNikel(id, lang)
            if (Number(id_user) === Number(responAPI.data.data.user_id)) {

                navigate('/jual-nikel', { state: { page: 'edit-nikel', data: responAPI.data.data } })
            } else {
                navigate('/signin', { state: { detail: 'listing', type: 2, id_relation: id } })
            }
        } catch (error) {

            console.log(error)
        }
    }

    const getDataDetailTongkang = async (id) => {
        try {
            let lang = localStorage.getItem('lang') === 'null' || localStorage.getItem('lang') === 'id' ? 'id' : localStorage.getItem('lang')
            let id_user = localStorage.getItem("id_user")
            const responAPI = await getDetailTongkang(id, lang)
            if (Number(id_user) === Number(responAPI.data.data.user_id)) {

                if (responAPI.data.data.type_tongkang === 1) {
                    navigate('/jual-tongkang', { state: { page: 'edit-tongkang', data: responAPI.data.data } })
                } else {
                    navigate('/tongkang-disewakan', { state: { page: 'edit-tongkang', data: responAPI.data.data } })
                }


            } else {
                navigate('/signin', { state: { detail: 'listing', type: 3, id_relation: id } })
            }
        } catch (error) {

            console.log(error)
        }
    }

    const ActionTandaiTerjual = async (id, page_detail) => {

        if (page_detail === 'coal') {
            try {
                let params = {
                    is_sold: "1",
                    token: localStorage.getItem("token")
                }
                const responAPI = await editBatubara(params, id)
                if (responAPI.status === 200) {
                    navigate(`/listing/detail`, { state: { detail: props.page_detail, id: props.id, data: props.data } })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: translate('status.failed'),
                        text: translate('status.subtitlefailed'),
                    })
                }

            } catch (error) {

            }
        } else if (page_detail === 'nikel') {
            try {
                let params = {
                    is_sold: "1",
                    token: localStorage.getItem("token")
                }
                const responAPI = await editNikel(params, id)
                if (responAPI.status === 200) {
                    navigate(`/listing/detail`, { state: { detail: props.page_detail, id: props.id, data: props.data } })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: translate('status.failed'),
                        text: translate('status.subtitlefailed'),
                    })
                }

            } catch (error) {

            }
        } else {
            try {
                let params = {
                    is_sold: "1",
                    token: localStorage.getItem("token")
                }
                const responAPI = await editTongkang(params, id, [], [])
                if (responAPI.status === 200) {
                    navigate(`/listing/detail`, { state: { detail: props.page_detail, id: props.id, data: props.data } })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: translate('status.failed'),
                        text: translate('status.subtitlefailed'),
                    })
                }

            } catch (error) {

            }
        }
    }

    const SubmitReportListing = async () => {
        handleOpenModalReport();

    }

    const handleCloseModalHapus = () => {
        setOpenModalHapus(false);
    };

    const handleCloseModalUnpublish = () => {
        setOpenModalUnpublish(false);
    };

    return (
        <div>
            <Drawer
                className="box-drawer-more"
                sx={{ zIndex: 999, '& .MuiBackdrop-root': { backgroundColor: 'rgba(0, 0, 0, 0.2)' } }}
                anchor="bottom"
                open={Boolean(props.open)}
                onClose={props.close}
            >
                {props.page === "permintaan" && <Box sx={{ p: '16px' }}>
                    <Stack sx={{ ml: 2, mb: 1 }} direction={'row'} justifyContent={'space-between'}>
                        <Typography id="modal-modal-title" className='w-700' sx={{ fontSize: '18px', color: '#081130', textAlign: 'start' }}>
                            More
                        </Typography>
                        <IconButton onClick={() => {
                            props.close();
                        }} sx={{ width: '24px', height: '24px' }} size="small" >
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    {isLogin && (props?.data?.user_favorite_id !== null ? <Box sx={{ justifyContent: 'center', p: '20px' }}>
                        <Stack
                            onClick={() => {
                                props.close();
                                props.removeFavorite(props.id)
                            }} sx={{ cursor: 'pointer' }} direction={'row'} spacing={1} alignItems={'center'} >

                            <IconButton size="small"><FavoriteTwoToneIcon sx={{ fontSize: 18, fill: "red", width: '24px', height: '24px' }} /> </IconButton>
                            <Typography className='w-500' sx={{ fontSize: '14px', color: '#081130', lineHeight: 'normal' }}>
                                {translate('status.hapusfavorite')}
                            </Typography>
                        </Stack>
                    </Box> : <></>)}

                    {isLogin && (props?.data?.user_favorite_id === null ? <Box sx={{ justifyContent: 'center', p: '20px' }}>
                        <Stack
                            onClick={() => {
                                props.close();
                                props.setFavorite(props.id)

                            }} sx={{ cursor: 'pointer' }} direction={'row'} spacing={1} alignItems={'center'} >

                            <IconButton size="small"> <FavoriteBorderIcon sx={{ fontSize: 18, width: '24px', height: '24px' }} /> </IconButton>
                            <Typography className='w-500' sx={{ fontSize: '14px', color: '#081130', lineHeight: 'normal' }}>
                                {translate('detail-explore.tambahkanfavorit')}
                            </Typography>
                        </Stack>
                    </Box> : <></>)}



                    {isLogin && <Box sx={{ justifyContent: 'center', p: '20px' }}>
                        <Stack
                            onClick={() => {
                                props.close();
                                SubmitReportListing();
                            }} sx={{ cursor: 'pointer', }} direction={'row'} spacing={1} alignItems={'center'} >

                            <img alt="report-icon" src={reporticon} height={24} width={24}></img>
                            <Typography className='w-500' sx={{ fontSize: '14px', color: '#081130', lineHeight: 'normal' }}>
                                {translate('detail-explore.laporkanlisting')}
                            </Typography>
                        </Stack>
                    </Box>}

                </Box>}

                {props.page === "listing" && <Box sx={{ p: '16px' }}>
                    <Stack sx={{ ml: 2, mb: 1 }} direction={'row'} justifyContent={'space-between'}>
                        <Typography id="modal-modal-title" className='w-700' sx={{ fontSize: '18px', color: '#081130', textAlign: 'start' }}>
                            More
                        </Typography>
                        <IconButton size="small" onClick={() => {
                            props.close();
                        }} >
                            <CloseIcon sx={{ width: '24px', height: '24px' }} />
                        </IconButton>
                    </Stack>
                    {is_sold === 0 ?
                        <Box sx={{ justifyContent: 'center', p: '20px' }}>
                            <Stack onClick={() => {
                                ActionTandaiTerjual(props.id, props.type)
                                props.close();
                            }} sx={{ cursor: 'pointer' }} direction={'row'} spacing={2} alignItems={'center'} >

                                <img alt="report-icon" src={soldIcon} height={24} width={24}></img>
                                <Typography className='w-500' sx={{ fontSize: '14px', color: '#081130', lineHeight: 'normal' }}>
                                    {translate('status.tandaiterjual')}
                                </Typography>
                            </Stack>
                        </Box> : <></>}

                    {props.status_listing === 1 || is_sold === 1 ?
                        <Box sx={{ justifyContent: 'center', p: '20px' }}>
                            <Stack onClick={(e) => {
                                props.close();
                                handleOpenModalShare('')

                            }} sx={{ cursor: 'pointer' }} direction={'row'} spacing={2} alignItems={'center'}>
                                <img alt="report-icon" src={shareIcon} height={24} width={24}></img>
                                <Typography className='w-500' sx={{ fontSize: '14px', color: '#081130', lineHeight: 'normal' }}>
                                    {translate('detail-explore.bagikanlisting')}
                                </Typography>
                            </Stack>
                        </Box> : <></>}

                    {is_sold === 0 && props.status_listing === 1 ?
                        <Box sx={{ justifyContent: 'center', p: '20px' }}>

                            <Stack onClick={() => {
                                props.close();
                                handleOpenModal('unpublish')
                            }} sx={{ cursor: 'pointer' }} direction={'row'} spacing={2} alignItems={'center'} >
                                <img alt="report-icon" src={unpublishIcon} height={24} width={24}></img>
                                <Typography className='w-500' sx={{ fontSize: '14px', color: '#081130', lineHeight: 'normal' }}>
                                    Unpublish listing
                                </Typography>
                            </Stack>
                        </Box> : <></>}

                    {is_sold === 0 && props.status_listing === 2 ?
                        <Box sx={{ justifyContent: 'center', p: '20px' }}>


                            <Stack onClick={() => {
                                props.close();
                                handleOpenModal('publish')
                            }} sx={{ cursor: 'pointer' }} direction={'row'} spacing={2} alignItems={'center'} >
                                <img alt="report-icon" src={visibilityIcon} height={24} width={24}></img>
                                <Typography className='w-500' sx={{ fontSize: '14px', color: '#081130', lineHeight: 'normal' }}>
                                    Publish listing
                                </Typography>
                            </Stack>
                        </Box> : <></>}
                    {is_sold === 0 && props.status_listing === 3 ?
                        <Box sx={{ justifyContent: 'center', p: '20px' }}>


                            <Stack onClick={() => {
                                props.close();
                                handleRedirectDraft();

                            }} sx={{ cursor: 'pointer' }} direction={'row'} spacing={2} alignItems={'center'} >
                                <img alt="report-icon" src={visibilityIcon} height={24} width={24}></img>
                                <Typography className='w-500' sx={{ fontSize: '14px', color: '#081130', lineHeight: 'normal' }}>
                                    Publish listing
                                </Typography>
                            </Stack>
                        </Box> : <></>}

                    <Box sx={{ justifyContent: 'center', p: '20px' }}>
                        <Stack onClick={() => {
                            props.close();
                            handleOpenModal('hapus')
                        }} sx={{ cursor: 'pointer' }} direction={'row'} spacing={2} alignItems={'center'} >

                            <img alt="report-icon" src={deleteIcon} height={24} width={24}></img>
                            <Typography className='w-500' sx={{ fontSize: '14px', color: '#ED3131', lineHeight: 'normal' }}>
                                {translate('detail-explore.hapuslisting')}
                            </Typography>
                        </Stack>
                    </Box>
                </Box>}

                {props.page === "explore" && <Box sx={{ p: '16px' }}>
                    <Stack sx={{ ml: 2, mb: 1 }} direction={'row'} justifyContent={'space-between'}>
                        <Typography id="modal-modal-title" className='w-700' sx={{ fontSize: '18px', color: '#081130', textAlign: 'start' }}>
                            More
                        </Typography>
                        <IconButton onClick={() => {
                            props.close();
                        }} size="small" >
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <Box sx={{ justifyContent: 'center', p: '20px' }}>
                        <Stack sx={{ cursor: 'pointer' }} onClick={(e) => {
                            props.close();
                            window.open(`https://api.whatsapp.com/send?phone=+62${props.user_trader_phone}`)
                        }} direction={'row'} spacing={2} alignItems={'center'} >

                            <img alt="report-icon" src={callIcon} height={24} width={24}></img>
                            <Typography className='w-500' sx={{ fontSize: '14px', color: '#081130', lineHeight: 'normal' }}>
                                {translate('detail-explore.hubungitrader')}
                            </Typography>
                        </Stack>
                    </Box>
                    {isLogin && <Box sx={{ justifyContent: 'center', p: '20px' }}>
                        <Stack
                            onClick={() => {
                                props.close();
                                SubmitReportListing();
                            }}
                            sx={{ cursor: 'pointer' }} direction={'row'} spacing={2} alignItems={'center'} >

                            <img alt="report-icon" src={reporticon} height={24} width={24}></img>
                            <Typography className='w-500' sx={{ fontSize: '14px', color: '#081130', lineHeight: 'normal' }}>
                                {translate('detail-explore.laporkanlisting')}
                            </Typography>
                        </Stack>
                    </Box>}

                    <Box sx={{ justifyContent: 'center', p: '20px' }}>
                        {props.type === 1 || props.type === 2 || props.type === 3 ? <Stack onClick={(e) => {
                            props.close();
                            handleOpenModalShare(props.label);

                        }} sx={{ cursor: 'pointer' }} direction={'row'} spacing={2} alignItems={'center'}>
                            <img alt="report-icon" src={shareIcon} height={24} width={24}></img>

                            <Typography className='w-500' sx={{ fontSize: '14px', color: '#081130', lineHeight: 'normal' }}>
                                {translate('detail-explore.bagikanlisting')}
                            </Typography>
                        </Stack> : <></>}
                        {props.type === 4 ? <Stack onClick={(e) => {
                            props.close();
                            handleOpenModalShareCompany('company');

                        }} sx={{ cursor: 'pointer' }} direction={'row'} spacing={2} alignItems={'center'}>
                            <img alt="report-icon" src={shareIcon} height={24} width={24}></img>

                            <Typography className='w-500' sx={{ fontSize: '14px', color: '#081130', lineHeight: 'normal' }}>
                                {translate('detail-explore.bagikanperusahaan')}
                            </Typography>
                        </Stack> : <></>}
                        {props.type === 5 ? <Stack onClick={(e) => {
                            props.close();
                            handleOpenModalShare('trader');

                        }} sx={{ cursor: 'pointer' }} direction={'row'} spacing={2} alignItems={'center'}>
                            <img alt="report-icon" src={shareIcon} height={24} width={24}></img>

                            <Typography className='w-500' sx={{ fontSize: '14px', color: '#081130', lineHeight: 'normal' }}>
                                {translate('detail-explore.bagikanprofile')}
                            </Typography>
                        </Stack> : <></>}
                    </Box>
                </Box>}

                {props.page === "company" && <Box sx={{ p: '16px' }}>
                    <Stack sx={{ ml: 2, mb: 1 }} direction={'row'} justifyContent={'space-between'}>
                        <Typography id="modal-modal-title" className='w-700' sx={{ fontSize: '18px', color: '#081130', textAlign: 'start' }}>
                            More
                        </Typography>
                        <IconButton onClick={() => {
                            props.close();
                        }} size="small" >
                            <CloseIcon />
                        </IconButton>
                    </Stack>

                    {isLogin && <Box sx={{ justifyContent: 'center', p: '20px' }}>
                        <Stack
                            onClick={() => {
                                props.close();
                                SubmitReportListing();
                            }}
                            sx={{ cursor: 'pointer' }} direction={'row'} spacing={2} alignItems={'center'} >

                            <img alt="report-icon" src={reporticon} height={24} width={24}></img>
                            {props.type === 1 || props.type === 2 || props.type === 3 && <Typography className='w-500' sx={{ fontSize: '14px', color: '#081130', lineHeight: 'normal' }}>
                                {translate('detail-explore.laporkanlisting')}
                            </Typography>}
                            {props.type === 4 && <Typography className='w-500' sx={{ fontSize: '14px', color: '#081130', lineHeight: 'normal' }}>
                                {translate('detail-explore.laporkanperusahaan')}
                            </Typography>}
                            {props.type === 5 && <Typography className='w-500' sx={{ fontSize: '14px', color: '#081130', lineHeight: 'normal' }}>
                                {translate('detail-explore.laporkantrader')}
                            </Typography>}
                        </Stack>
                    </Box>}

                    <Box sx={{ justifyContent: 'center', p: '20px' }}>
                        {props.type === 1 || props.type === 2 || props.type === 3 ? <Stack onClick={(e) => {
                            props.close();
                            handleOpenModalShare(props.label);

                        }} sx={{ cursor: 'pointer' }} direction={'row'} spacing={2} alignItems={'center'}>
                            <img alt="report-icon" src={shareIcon} height={24} width={24}></img>

                            <Typography className='w-500' sx={{ fontSize: '14px', color: '#081130', lineHeight: 'normal' }}>
                                {translate('detail-explore.bagikanlisting')}
                            </Typography>
                        </Stack> : <></>}
                        {props.type === 4 ? <Stack onClick={(e) => {
                            props.close();
                            handleOpenModalShareCompany('company');

                        }} sx={{ cursor: 'pointer' }} direction={'row'} spacing={2} alignItems={'center'}>
                            <img alt="report-icon" src={shareIcon} height={24} width={24}></img>

                            <Typography className='w-500' sx={{ fontSize: '14px', color: '#081130', lineHeight: 'normal' }}>
                                {translate('detail-explore.bagikanperusahaan')}
                            </Typography>
                        </Stack> : <></>}
                        {props.type === 5 ? <Stack onClick={(e) => {
                            props.close();
                            handleOpenModalShare('trader');

                        }} sx={{ cursor: 'pointer' }} direction={'row'} spacing={2} alignItems={'center'}>
                            <img alt="report-icon" src={shareIcon} height={24} width={24}></img>

                            <Typography className='w-500' sx={{ fontSize: '14px', color: '#081130', lineHeight: 'normal' }}>
                                {translate('detail-explore.bagikanprofile')}
                            </Typography>
                        </Stack> : <></>}
                    </Box>
                </Box>}
            </Drawer>
            <ModalUnpublish page_detail={props.page_detail} id={props.id} status={status} open={isOpenModalUnpublish} close={handleCloseModalUnpublish}></ModalUnpublish>
            <ModalHapusListing page_detail={props.page_detail} id={props.id} open={isOpenModalHapus} close={handleCloseModalHapus}></ModalHapusListing>

            <ModalShareListing label={label} page={props.page} type={props.type} id={props.id} open={openShareListing} close={handleCloseModal}></ModalShareListing>
            <ModalShareListingCompany data={props.data} label={label} page={props.page} type={props.type} id={props.id} open={openShareListingCompany} close={handleCloseModal}></ModalShareListingCompany>
            <ModalReportListing open={openModalReport} close={handleCloseModal} type={props.type} id={props.id} ></ModalReportListing>
        </div>
    );
}