import { Box, Card, Grid, Hidden, Stack, TextField, Typography } from "@mui/material";
import { React, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import DrawerUploadDoc from '../../components/DrawerUploadDocSingleLayer';
import ModalUnggahDoc from '../../components/ModalUnggahDocBaruSingleLayer';
import { DisplayPDFCompany } from '../DisplayPDFCompany';
import DropZoneUnggah from "../DropZoneUnggahSingle";
import IconInfo from "../../assets/icon-info.png";

export default function MenuFormNPWP(props) {
  const [translate] = useTranslation("global");

  const [validationNoNPWP, setValidationNoNPWP] = useState(false);
  const [validationDesc, setValidationDesc] = useState(false);
  const [validationFile, setValidationFile] = useState(false);
  const [validationName, setValidationName] = useState(false);

  useEffect(() => {

    if (props.triggerValidation >= 1) {

      if (!props.npwp_nomor) {
        setValidationNoNPWP(true)
      }

      if (!props.npwp_description) {
        setValidationDesc(true)
      }

      if (!props.npwp_name) {
        setValidationName(true)
      }

    }

  }, [props.triggerValidation])

  useEffect(() => {
    if (props.triggerValidation >= 1) {
      if (props.npwp.length === 0) {
        setValidationFile(true)
      } else {
        setValidationFile(false)
      }
    }
    console.log(props)
  }, [props.triggerValidation, props.npwp])

  return (
    <Grid item xs={12} sm={12} md={4} lg={4} className='form-input'>

      {props.npwp.length === 0 ? (
        <></>
      ) : (
        <Box >
          <Stack direction={'row'} justifyContent={'space-between'}>
            <p className='c-08 s-1419 w-500 mb4 '>{translate('status.suratnpwp')}</p>
            <Typography sx={{ cursor: 'pointer' }} onClick={(e) => {
              props.handleOpenModalUploadNpwp();
            }} className=' w-500 c-24 mb4 mr4 '>{translate('jual-beli.unggah-dokument')}</Typography>
          </Stack>

          <Grid style={{ marginTop: "5px", marginBottom: "10px" }} container direction={'row'}>
            {props.npwp.map((item, i) => (
              <Grid key={item} item xs={12} sm={12} md={12} lg={12} >
                <Card sx={{ borderRadius: 2 }}>
                  <DisplayPDFCompany
                    url={`${item.url_display}`}
                    key={item}
                    ext={`${item.ext}`}
                    name={`${item.name}`}
                    delete={() => props.setUploadnpwp([])}
                  />
                </Card>
              </Grid>
            ))}
          </Grid>

          <Stack direction={'row'} spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={6}>
              <p className='c-08 s-1419 w-500 mb4'>{translate('status.nonpwp')}</p>
              <TextField type='number' sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                  display: "none",
                },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                }, width: '100%', mb: '24px', input: {
                  fontSize: '14px', fontFamily: 'Satoshi-500'
                },
              }}
                placeholder={translate('status.nonpwp')}
                value={props.npwp_nomor}
                // onChange={(e) => props.setnpwp_nomor(e.target.value)}
                error={validationNoNPWP}
                onChange={(e) => {
                  if (e.target.value && e.target.value !== "") {
                    props.setnpwp_nomor(e.target.value)
                    setValidationNoNPWP(false);
                  } else {
                    props.setnpwp_nomor(e.target.value)
                    setValidationNoNPWP(true);
                  }
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <p className='c-08 s-1419 w-500 mb4'>{translate('login-register.nama')}</p>
              <TextField type='text' sx={{
                width: '100%', mb: '24px', input: {
                  fontSize: '14px', fontFamily: 'Satoshi-500'
                },
              }}
                placeholder={translate('login-register.nama')}
                value={props.npwp_name}
                // onChange={(e) => props.setnpwpname(e.target.value)}
                error={validationName}
                onChange={(e) => {
                  if (e.target.value && e.target.value !== "") {
                    props.setnpwpname(e.target.value)
                    setValidationName(false);
                  } else {
                    props.setnpwpname(e.target.value)
                    setValidationName(true);
                  }
                }}
              />
            </Grid>


          </Stack>

          <p className='c-08 s-1419 w-500 mb4 '>{translate('detail-explore.deskripsi')}</p>
          <TextField className='box-deskripsi-company' multiline rows={3}
            // onChange={(e) => props.setnpwp_description(e.target.value)}
            value={props.npwp_description}
            error={validationDesc}
            onChange={(e) => {
              if (e.target.value && e.target.value !== "") {
                props.setnpwp_description(e.target.value)
                setValidationDesc(false);
              } else {
                props.setnpwp_description(e.target.value)
                setValidationDesc(true);
              }
            }}
            sx={{
              width: '100%', p: 0, input: {
                fontSize: '14px', fontFamily: 'Satoshi-500'
              },
            }} placeholder={translate('jual-beli.placeholder-deskripsi')}
          />
        </Box>
      )}
      {props.npwp.length === 0 &&
        <>
          <Box>
            <p className='c-08 s-1419 w-500 mb4 '>{translate('status.suratnpwp')}</p>
            <DropZoneUnggah handleUpload={props.handleCloseModalUploadNpwp} />
          </Box>
          {validationFile ? (
            <Box sx={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
              <img alt="Nikel-icon" src={IconInfo} height={20}></img>
              <p className="s-1419 w-500" style={{ marginLeft: "5px", color: "#D32F2F" }}>
                {translate("title.validation_file")}
              </p>
            </Box>
          ) : (
            <></>
          )}
        </>
      }
      <Hidden lgDown><ModalUnggahDoc open={props.isOpenUploadNpwp} close={props.handleCloseModalUploadNpwp}></ModalUnggahDoc>
      </Hidden>

      <Hidden lgUp><DrawerUploadDoc open={props.isOpenUploadNpwp} close={props.handleCloseModalUploadNpwp}></DrawerUploadDoc>
      </Hidden>

    </Grid>
  );
}