import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid, IconButton, Modal, Typography } from "@mui/material";
import * as React from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import beliBatubaraIcon from "../assets/beli-batubara-ico.png";
import beliNikelIcon from "../assets/beli-nikel-ico.png";
import beliTongkangIcon from "../assets/beli-tongkang-ico.png";
import freightCharterIcon from "../assets/freight-charter-ic.png";
import jualBatubaraIcon from "../assets/jual-batubara-ico.png";
import jualNikelIcon from "../assets/jual-nikel-ico.png";
import jualTongkangIcon from "../assets/jual-tongkang-ico.png";
import menyewaTongkangIcon from "../assets/menyewa-tongkang-ico.png";
import sewaTongkang from "../assets/sewa-tongkang-ico.png";
import timeCharterIcon from "../assets/time-charter-ic.png";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1088,
    borderRadius: '12px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    paddingBottom: '32px',
    paddingLeft: '32px',
    paddingRight: '24px',
    "&:focus": {
        outline: "none"
    },
};

const style_sub = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    borderRadius: '12px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    paddingBottom: '32px',
    paddingLeft: '32px',
    paddingRight: '24px',
    "&:focus": {
        outline: "none"
    },
};

function ModalPermintaan(props) {

    const [translate,] = useTranslation("global");
    const [isOpenModalSewaTongkang, setisOpenModalSewaTongkang] = React.useState(false)
    const [page, setPage] = React.useState("")

    const handleCloseModal = () => {
        setisOpenModalSewaTongkang(false);
    };

    return (
        <div>
            <Modal
                open={props.open}
                onClose={props.close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ zIndex: 100000 }}
            >
                <Box sx={style}>

                    <Box sx={{ display: 'flex', mt: '24px' }}>
                        <Typography id="modal-modal-title" className='w-700' sx={{ width: '100%', fontSize: '28px', color: '#081130', textAlign: 'center', lineHeight: 'normal' }}>
                            {translate('navbar.title-permintaan')}
                        </Typography>
                        <IconButton onClick={() => {
                            props.close();
                        }} size="small" >
                            <CloseIcon sx={{ width: '24px', height: '24px' }} />
                        </IconButton>
                    </Box>

                    {/* <Box sx={{ display: 'flex', justifyContent: 'center', mt: '12px' }}>
                        <Typography id="modal-modal-description" className='w-500' sx={{ color: '#56617B', fontSize: '14px', textAlign: 'center', lineHeight: '22px', width: '776px' }}>
                            Lorem ipsum dolor sit amet consectetur. Id sit egestas nec pretium. Congue a tortor ac lobortis ac velit tortor cursus risus. Sed pulvinar semper faucibus a nec amet. Sit urna morbi adipiscing est quisque.
                        </Typography></Box> */}

                    <Grid container spacing={2} direction={'row'} sx={{ mt: 3 }}>

                        <Grid item lg={3}>
                            <Box onClick={(e) => { window.location.href = `/jual-batubara` }} sx={{ border: 1, color: '#E8EBF2', borderRadius: '8px', justifyContent: 'center', cursor: 'pointer' }}>
                                <Box sx={{ mt: '18.5px', justifyContent: 'center', display: 'flex' }}>
                                    <img alt="Batubara-icon" style={{ width: '100px', height: '100px', }} src={jualBatubaraIcon}></img>
                                </Box>
                                <Typography className='w-700' sx={{ fontSize: '20px', color: '#081130', textAlign: 'center', mt: '16px', mb: '18.5px' }}>
                                    {translate('page_explore.btn-jual-batubara')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item lg={3}>
                            <Box onClick={(e) => { window.location.href = `/beli-batubara` }} sx={{ border: 1, color: '#E8EBF2', borderRadius: '8px', cursor: 'pointer' }}>
                                <Box sx={{ mt: '18.5px', justifyContent: 'center', display: 'flex' }}>
                                    <img alt="Nikel-icon" style={{ width: '100px', height: '100px' }} src={beliBatubaraIcon}></img>
                                </Box>
                                <Typography className='w-700' sx={{ fontSize: '20px', color: '#081130', textAlign: 'center', mt: '16px', mb: '18.5px' }}>
                                    {translate('permintaan.btn-beli-batubara')}
                                </Typography>

                            </Box>
                        </Grid>
                        <Grid item lg={3}>
                            <Box onClick={(e) => { window.location.href = `/jual-nikel` }} sx={{ border: 1, color: '#E8EBF2', borderRadius: '8px', cursor: 'pointer' }}>
                                <Box sx={{ mt: '18.5px', justifyContent: 'center', display: 'flex' }}>
                                    <img alt="Nikel-icon" style={{ width: '100px', height: '100px' }} src={jualNikelIcon}></img>
                                </Box>
                                <Typography className='w-700' sx={{ fontSize: '20px', color: '#081130', textAlign: 'center', mt: '16px', mb: '18.5px' }}>
                                    {translate('page_explore.btn-jual-nikel')}
                                </Typography>

                            </Box>
                        </Grid>
                        <Grid item lg={3}>
                            <Box onClick={(e) => { window.location.href = `/beli-nikel` }} sx={{ border: 1, color: '#E8EBF2', borderRadius: '8px', cursor: 'pointer' }}>
                                <Box sx={{ mt: '18.5px', justifyContent: 'center', display: 'flex' }}>
                                    <img alt="Nikel-icon" style={{ width: '100px', height: '100px' }} src={beliNikelIcon}></img>
                                </Box>
                                <Typography className='w-700' sx={{ fontSize: '20px', color: '#081130', textAlign: 'center', mt: '16px', mb: '18.5px' }}>
                                    {translate('permintaan.btn-beli-nikel')}
                                </Typography>

                            </Box>
                        </Grid>

                        <Grid item lg={3}>
                            <Box onClick={(e) => {
                                setPage("disewakan")
                                setisOpenModalSewaTongkang(true)
                                props.close();

                            }} sx={{ border: 1, color: '#E8EBF2', borderRadius: '8px', cursor: 'pointer' }}>
                                <Box sx={{ mt: '18.5px', justifyContent: 'center', display: 'flex' }}>
                                    <img alt="Nikel-icon" style={{ width: '100px', height: '100px' }} src={menyewaTongkangIcon}></img>
                                </Box>
                                <Typography className='w-700' sx={{ fontSize: '20px', color: '#081130', textAlign: 'center', mt: '16px', mb: '18.5px' }}>
                                    {translate('page_explore.btn-menyewakan-tongkang')}
                                </Typography>

                            </Box>
                        </Grid>
                        <Grid item lg={3}>
                            <Box onClick={(e) => {
                                setPage("sewa")
                                setisOpenModalSewaTongkang(true)
                                props.close();

                            }} sx={{ border: 1, color: '#E8EBF2', borderRadius: '8px', cursor: 'pointer' }}>
                                <Box sx={{ mt: '18.5px', justifyContent: 'center', display: 'flex' }}>
                                    <img alt="Nikel-icon" style={{ width: '100px', height: '100px' }} src={sewaTongkang}></img>
                                </Box>
                                <Typography className='w-700' sx={{ fontSize: '20px', color: '#081130', textAlign: 'center', mt: '16px', mb: '18.5px' }}>
                                    {translate('permintaan.btn-sewa-tongkang')}
                                </Typography>

                            </Box>
                        </Grid>
                        <Grid item lg={3}>
                            <Box onClick={(e) => { window.location.href = `/jual-tongkang` }} sx={{ border: 1, color: '#E8EBF2', borderRadius: '8px', cursor: 'pointer' }}>
                                <Box sx={{ mt: '18.5px', justifyContent: 'center', display: 'flex' }}>
                                    <img alt="Nikel-icon" style={{ width: '100px', height: '100px' }} src={jualTongkangIcon}></img>
                                </Box>
                                <Typography className='w-700' sx={{ fontSize: '20px', color: '#081130', textAlign: 'center', mt: '16px', mb: '18.5px' }}>
                                    {translate('page_explore.btn-jual-tongkang')}
                                </Typography>

                            </Box>
                        </Grid>
                        <Grid item lg={3}>
                            <Box onClick={(e) => { window.location.href = `/beli-tongkang` }} sx={{ border: 1, color: '#E8EBF2', borderRadius: '8px', cursor: 'pointer' }}>
                                <Box sx={{ mt: '18.5px', justifyContent: 'center', display: 'flex' }}>
                                    <img alt="Nikel-icon" style={{ width: '100px', height: '100px' }} src={beliTongkangIcon}></img>
                                </Box>
                                <Typography className='w-700' sx={{ fontSize: '20px', color: '#081130', textAlign: 'center', mt: '16px', mb: '18.5px' }}>
                                    {translate('permintaan.btn-beli-tongkang')}
                                </Typography>

                            </Box>
                        </Grid>

                    </Grid>
                </Box>
            </Modal>
            <ModalPermintaanSewaTongkang page={page} open={isOpenModalSewaTongkang} close={handleCloseModal}></ModalPermintaanSewaTongkang>
        </div>
    );
}

function ModalPermintaanSewaTongkang(props) {
    const navigate = useNavigate()
    const [translate,] = useTranslation("global");
    return (
        <div>
            <Modal
                open={props.open}
                onClose={props.close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ zIndex: 100000 }}
            >
                {props.page === "sewa" ?
                    <Box sx={style_sub}>

                        <Box sx={{ display: 'flex', mt: '24px' }}>
                            <Typography id="modal-modal-title" className='w-700' sx={{ width: '100%', fontSize: '28px', color: '#081130', textAlign: 'center', lineHeight: 'normal' }}>
                                {translate('permintaan.btn-sewa-tongkang')}
                            </Typography>
                            <IconButton onClick={() => {
                                props.close();
                            }} size="small" >
                                <CloseIcon sx={{ width: '24px', height: '24px' }} />
                            </IconButton>
                        </Box>
                        <Grid container spacing={2} direction={'row'} sx={{ mt: 3 }}>
                            <Grid item lg={6}>
                                <Box onClick={(e) => {
                                    navigate(`/sewa-tongkang`, { state: { detail: 'time-charter' } })
                                    window.location.reload()
                                }} sx={{ border: 1, color: '#E8EBF2', borderRadius: '8px', cursor: 'pointer' }}>
                                    <Box sx={{ mt: '18.5px', justifyContent: 'center', display: 'flex' }}>
                                        <img alt="Nikel-icon" style={{ width: '100px', height: '100px' }} src={timeCharterIcon}></img>
                                    </Box>
                                    <Typography className='w-700' sx={{ fontSize: '20px', color: '#081130', textAlign: 'center', mt: '16px', mb: '18.5px' }}>
                                        Time Charter
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item lg={6}>
                                <Box onClick={(e) => {
                                    navigate(`/sewa-tongkang`, { state: { detail: 'freight-charter' } })
                                    window.location.reload()
                                }} sx={{ border: 1, color: '#E8EBF2', borderRadius: '8px', cursor: 'pointer' }}>
                                    <Box sx={{ mt: '18.5px', justifyContent: 'center', display: 'flex' }}>
                                        <img alt="Nikel-icon" style={{ width: '100px', height: '100px' }} src={freightCharterIcon}></img>
                                    </Box>
                                    <Typography className='w-700' sx={{ fontSize: '20px', color: '#081130', textAlign: 'center', mt: '16px', mb: '18.5px' }}>
                                        Freight Charter
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box> :
                    <Box sx={style_sub}>

                        <Box sx={{ display: 'flex', mt: '24px' }}>
                            <Typography id="modal-modal-title" className='w-700' sx={{ width: '100%', fontSize: '28px', color: '#081130', textAlign: 'center', lineHeight: 'normal' }}>
                                {translate('page_explore.btn-menyewakan-tongkang')}
                            </Typography>
                            <IconButton onClick={() => {
                                props.close();
                            }} size="small" >
                                <CloseIcon sx={{ width: '24px', height: '24px' }} />
                            </IconButton>
                        </Box>
                        <Grid container spacing={2} direction={'row'} sx={{ mt: 3 }}>
                            <Grid item lg={6}>
                                <Box onClick={(e) => {
                                    navigate(`/tongkang-disewakan`, { state: { detail: 'time-charter' } })
                                    window.location.reload()
                                }} sx={{ border: 1, color: '#E8EBF2', borderRadius: '8px', cursor: 'pointer' }}>
                                    <Box sx={{ mt: '18.5px', justifyContent: 'center', display: 'flex' }}>
                                        <img alt="Nikel-icon" style={{ width: '100px', height: '100px' }} src={timeCharterIcon}></img>
                                    </Box>
                                    <Typography className='w-700' sx={{ fontSize: '20px', color: '#081130', textAlign: 'center', mt: '16px', mb: '18.5px' }}>
                                        Time Charter
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item lg={6}>
                                <Box onClick={(e) => {
                                    navigate(`/tongkang-disewakan`, { state: { detail: 'freight-charter' } })
                                    window.location.reload()
                                }} sx={{ border: 1, color: '#E8EBF2', borderRadius: '8px', cursor: 'pointer' }}>
                                    <Box sx={{ mt: '18.5px', justifyContent: 'center', display: 'flex' }}>
                                        <img alt="Nikel-icon" style={{ width: '100px', height: '100px' }} src={freightCharterIcon}></img>
                                    </Box>
                                    <Typography className='w-700' sx={{ fontSize: '20px', color: '#081130', textAlign: 'center', mt: '16px', mb: '18.5px' }}>
                                        Freight Charter
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>}

            </Modal>
        </div>
    );
}

export { ModalPermintaan, ModalPermintaanSewaTongkang };
