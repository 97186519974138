import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Grid, InputAdornment, MenuItem, Select, Stack, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import dayjs from 'dayjs';
import { React, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { config } from '../../services/constants';
import IconInfo from "../../assets/icon-info.png";

export default function MenuFormDireksi(props) {
  const [translate,] = useTranslation("global");
  const [openStartDate, setOpenStartDate] = useState(false)
  const [openEndDate, setOpenEndDate] = useState(false)

  const [validationProfileImg, setValidationProfileImg] = useState(false);
  const [validationName, setValidationName] = useState(false);
  const [validationPosition, setValidationPosition] = useState(false);
  const [validationStartPeriod, setValidationStartPeriod] = useState(false);
  const [validationEndPeriod, setValidationEndPeriod] = useState(false);

  useEffect(() => {

    if (props.triggerValidation >= 1) {

      if (!props.item.name) {
        setValidationName(true)
      }

      if (!props.item.position) {
        setValidationPosition(true)
      }

      if (!props.item.start_period) {
        setValidationStartPeriod(true)
      }

      if (!props.item.end_period) {
        setValidationEndPeriod(true)
      }

    }

  }, [props.triggerValidation])

  useEffect(() => {
    if (props.triggerValidation >= 1) {
      if (props.item.profile_picture === "" || !props.item.profile_picture) {
        setValidationProfileImg(true)
      } else {
        setValidationProfileImg(false)
      }
    }
  }, [props.triggerValidation, props.item.profile_picture])

  return (
    <Box key={props.item} sx={{ border: '1px solid var(--grey-stroke, #E8EBF2)', borderRadius: '12px', p: '16px', mb: '16px' }}>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <h6 style={{ fontSize: 16 }} className=' w-700 mb-16'>{translate('status.direksi')}</h6>
        {props.i !== 0 && <CloseIcon onClick={(e) => props.handleDeleteDireksi(props.i)} sx={{ height: 24, width: 24, cursor: 'pointer' }} ></CloseIcon>}
      </Stack>

      <p className='c-08 s-1419 w-500 mb4 '>{translate('navbar.profile')}</p>
      <div>
        {props.item.profile_picture !== "" ?
          <div
            style={{
              backgroundImage: `url(${config.baseURL + props.item.profile_picture})`, height: '48px', width: '48px',
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              position: "relative",
              borderRadius: '1000px',
            }}
          >
          </div> :
          <div
            style={{
              backgroundImage: `url(${props.DefaultProfileImg})`, height: '48px', width: '48px',
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              position: "relative",
              borderRadius: '1000px',
            }}
          >
          </div>}
        <label for={`${props.i}`}>
          <img className='pointer' style={{ position: 'relative', bottom: 22, right: -34 }} alt="direksi-icon" src={props.UploadProfileIcon} height={24} width={24}></img>
        </label>
        <input type="file" accept="image/*" onChange={(e) => { props.handleChangeImgDireksi(e, props.i) }} id={`${props.i}`} style={{ display: "none" }} />
      </div>
      {validationProfileImg ? (
        <Box sx={{ display: "flex", alignItems: "center", marginTop: "-5px", marginBottom: "30px" }}>
          <img alt="Nikel-icon" src={IconInfo} height={20}></img>
          <p className="s-1419 w-500" style={{ marginLeft: "5px", color: "#D32F2F" }}>
            {translate("title.validation_profile")}
          </p>
        </Box>
      ) : (
        <></>
      )}
      <Box>
        <p className='c-08 s-1419 w-500 mb4'>{translate('login-register.nama')}</p>
        <TextField sx={{
          width: '100%', mb: '24px', input: {
            fontSize: '14px', fontFamily: 'Satoshi-500'
          },
        }}
          placeholder={translate('login-register.nama')}
          value={props.item.name}
          error={validationName}
          onChange={(e) => {
            if (e.target.value && e.target.value !== "") {
              props.handleChangeDireksi(e, props.i)
              setValidationName(false);
            } else {
              props.handleChangeDireksi(e, props.i)
              setValidationName(true);
            }
          }}
        />
      </Box>

      <Box>
        <p className='c-08 s-1419 w-500 mb4'>{translate('status.selectposisi')}</p>
        <Select IconComponent={() => (
          <KeyboardArrowDownIcon sx={{ mr: '16px', width: '20px', height: '20px', fill: '#56617B' }} />
        )}
          MenuProps={{
            PaperProps: {
              sx: {
                height: '352px',
                '& .MuiMenuItem-root': {
                },
              },
            },
          }}
          style={{ border: '1px solid var(--grey-stroke, #e8ebf2)', borderRadius: '8px', width: '100%' }} value={props.item.position}
          error={validationPosition}
          onChange={(e) => {
            props.handleChangePosisiDireksi(e, props.i)
            setValidationPosition(false);
          }}
          // onChange={(e) => props.handleChangePosisiDireksi(e, props.i)}
          displayEmpty >
          <MenuItem value="" style={{ display: "none" }}>
            <p className='w-400 s-1420 c-87'>{translate('status.selectposisi')}</p>
          </MenuItem>
          {props.posisiperusahaan.map((item, i) => (
            <MenuItem key={item.id} value={item.attributes.value}>{item.attributes.value} </MenuItem>))}
        </Select>
      </Box>
      <Grid sx={{ mt: 1 }} container justifyContent={'space-between'} spacing={2}>
        <Grid item xs>
          <p className='c-08 s-1419 w-500 mb4'>{translate('status.startperiode')}</p>
          <LocalizationProvider dateAdapter={props.AdapterDayjs} >
            <DatePicker
              sx={{ width: '100%' }}
              slots={{
                openPickerIcon: props.DateIcon
              }}
              open={openStartDate}
              onClose={() => setOpenStartDate(false)}
              maxDate={props.item.end_period ? dayjs(props.item.end_period) : null}
              slotProps={{
                textField: {
                  placeholder: translate('status.pilihperiode'),
                  InputProps: {
                    startAdornment:
                      <InputAdornment position="start">
                        <img src={props.calendarIcon} onClick={() => setOpenStartDate(true)} alt='' style={{ marginRight: '8px', cursor: 'pointer' }} />
                      </InputAdornment>
                  }
                }
              }}
              value={props.item.start_period ? dayjs(props.item.start_period) : null}
              onChange={(newValue) => {
                props.handleChangeStartPeriode(newValue, props.i);
                setValidationStartPeriod(false)
              }}
            />
          </LocalizationProvider>
          {validationStartPeriod ? (
            <Box sx={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
              <img alt="Nikel-icon" src={IconInfo} height={20}></img>
              <p className="s-1419 w-500" style={{ marginLeft: "5px", color: "#D32F2F" }}>
                {translate("title.validation")}
              </p>
            </Box>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xs>
          <p className='c-08 s-1419 w-500 mb4'>{translate('status.endperiode')}</p>
          <LocalizationProvider dateAdapter={props.AdapterDayjs} >
            <DatePicker
              sx={{ width: '100%' }}
              slots={{
                openPickerIcon: props.DateIcon
              }}
              open={openEndDate}
              minDate={props.item.start_period ? dayjs(props.item.start_period) : null}
              onClose={() => setOpenEndDate(false)}
              slotProps={{
                textField: {
                  placeholder: translate('status.pilihperiode'),
                  InputProps: {
                    startAdornment:
                      <InputAdornment position="start">
                        <img src={props.calendarIcon} onClick={() => setOpenEndDate(true)} alt='' style={{ marginRight: '8px', cursor: 'pointer' }} />
                      </InputAdornment>
                  }
                }
              }}
              maxDate={props.item.start_date !== null ? props.item.start_date : null}
              value={props.item.end_period ? dayjs(props.item.end_period) : null}
              onChange={(newValue) => {
                props.handleChangeEndPeriode(newValue, props.i);
                setValidationEndPeriod(false)
              }}
            />
          </LocalizationProvider>
          {validationEndPeriod ? (
            <Box sx={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
              <img alt="Nikel-icon" src={IconInfo} height={20}></img>
              <p className="s-1419 w-500" style={{ marginLeft: "5px", color: "#D32F2F" }}>
                {translate("title.validation")}
              </p>
            </Box>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}