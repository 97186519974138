import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Snackbar, SnackbarContent, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from "react-i18next";
import successdraftIcon from "../assets/icons/success-ic-draft.svg";
export default function SnackBarDraft(props) {
    const [translate,] = useTranslation("global");

    return (
        <Box sx={{ width: 500, position: 'absolute', zIndex: 99999 }}>
            <Snackbar
                autoHideDuration={3000}
                anchorOrigin={{ vertical: props.position ? props.position : 'bottom', horizontal: 'center' }}
                open={props.open}
                onClose={props.close}

                key={{ vertical: 'top', horizontal: 'center' }}

            >
                <SnackbarContent sx={{ background: 'white', color: '#424242CC', borderLeft: '2px solid #3BB56C' }}

                    message={<React.Fragment>
                        <Stack direction={'row'} alignItems={'start'}>
                            <img src={successdraftIcon} alt='' />
                            <Stack direction={'column'}>
                                <Typography
                                    className="w-700"
                                    sx={{ fontSize: 16, ml: 1, color: '#081130' }}
                                >

                                    {translate('status.successdraft')}
                                </Typography>
                                <Typography
                                    className="w-500"
                                    sx={{ fontSize: 12, ml: 1, color: '#56617B' }}
                                >
                                    {translate('status.subsuccessdraft')}
                                </Typography>
                            </Stack>
                            <IconButton

                                aria-label="close"
                                color="inherit"
                                sx={{ p: 0.5, justifyContent: 'center' }}
                                onClick={props.close}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Stack>
                    </React.Fragment>}
                >

                </SnackbarContent >

            </Snackbar>
        </Box>
    );
}
