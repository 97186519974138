import { Person } from "@material-ui/icons"
import CompanyDefault from '@mui/icons-material/Business'
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import { Avatar, Card, CardContent, Grid, Stack, Typography } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import callCompanyIcon from "../assets/call-company-ic.png"
import mailCompanyIcon from "../assets/mail-company-ic.png"
import DrawerMore from "../components/DrawerMore"
import { config } from "../services/constants"
import { format } from '../services/primary'

export default function CardTraderList(props) {
  const [user_trader_phone, setuser_trader_phone] = useState(null);
  const [product_id, setProduct_id] = useState(null);
  const [anchorElShare, setAnchorElShare] = useState(null);
  const [translate,] = useTranslation("global");

  const handleOpenShareMenu = (event, id, traderphone) => {
    setuser_trader_phone(traderphone);
    setProduct_id(id);
    setAnchorElShare(event.currentTarget);
  };

  const handleCloseShareMenu = () => {
    setAnchorElShare(null);
  };

  return (
    <Card sx={{ boxShadow: 'none', border: 1, borderColor: '#E8EBF2', borderRadius: '8px' }}>
      <CardContent
      >
        <Grid
          container
          direction="row"
          alignItems="left"
          justifyContent="left"
          spacing={0}
        >
          <Grid onClick={(e) => {
            window.location.href = `/detailtrader?type=5&id_detail=${props?.dataTrader?.user_id}`
          }} item xs={8}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="left"
              spacing={0}
            >
              {props.dataTrader?.user_image ?

                <img style={{ borderRadius: '50%', marginRight: 6 }} alt="company_image-icon" onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${props.dataTrader?.user_id}` }} src={config.baseURL + props.dataTrader?.user_image} width={32} height={32}></img> :

                <Avatar onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${props.dataTrader?.user_id}` }} sx={{ bgcolor: '#1976d2', height: '32px', width: '32px', mr: '8px', cursor: 'pointer' }} aria-label="recipe">
                  <Person />
                </Avatar>}


              <Stack style={{ textAlign: "left", color: "white" }} spacing={0}>
                <Typography
                  onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${props.dataTrader?.user_id}` }}
                  sx={{ fontSize: 16, color: '#081130', cursor: 'pointer' }}
                  className="w-700"
                >
                  {props?.dataTrader?.name ?? '-'}
                </Typography>
                <Typography
                  sx={{ fontSize: 12, color: '#56617B' }}
                  className="w-500"
                >
                  {props?.dataTrader?.kota === null || props?.dataTrader?.provinsi === null ? `${props?.dataTrader?.kota ?? ""}${props?.dataTrader?.provinsi ?? ""}` : `${props?.dataTrader?.kota ?? ""}, ${props?.dataTrader?.provinsi ?? ""}`}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid sx={{ textAlign: "right", display: 'flex', alignItems: 'center', justifyContent: 'end' }} item xs={4}>

            <MoreHorizIcon onClick={(e) => handleOpenShareMenu(e, props.dataTrader?.user_id, props.dataTrader?.phone_number)} sx={{ width: '24px', height: '24px', cursor: 'pointer' }} />
          </Grid>
        </Grid>
        <Stack onClick={(e) => {
          window.location.href = `/detailtrader?type=5&id_detail=${props?.dataTrader?.user_id}`
        }} sx={{ p: '16px', mt: '16px', background: "#F4F6FA", borderRadius: '6px', textAlign: "left", color: "#56617B" }} spacing={1}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="left"
          >
            <img alt="Nikel-icon" src={callCompanyIcon} height={16}></img>
            <Typography className="w-500" sx={{ fontSize: 14, ml: 1 }}>

              {props?.dataTrader?.phone_number === null ? translate('status.nomor-empty') : props?.dataTrader?.phone_number === undefined ?
                "Private" : props?.dataTrader?.phone_number === "" ? "Private" : '+62' + ' ' + format(props?.dataTrader?.phone_number)}


            </Typography>
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="left"
          >
            <img alt="Nikel-icon" src={mailCompanyIcon} height={16}></img>
            <Typography className="w-500" sx={{ fontSize: 14, ml: 1 }}>
              {props?.dataTrader?.email === null ? translate('status.nomor-empty') : props?.dataTrader?.email === undefined ? "Private" : props?.dataTrader?.email === "" ? "Private" : props?.dataTrader?.email}

            </Typography>

          </Grid>

          {props?.dataTrader?.company_list.length > 0 ?
            <Stack direction={'row'}>
              {props?.dataTrader?.company_list.map((item, index) => {
                if (index < 4) return (
                  <>
                    {index === 0 ? (
                      item?.company_image ?
                        <Avatar src={`${config.baseURL + item?.company_image}`} sx={{ bgcolor: '#1976d2', width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                          <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
                        </Avatar> :
                        <Avatar sx={{ bgcolor: '#1976d2', width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                          <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
                        </Avatar>
                    ) : (
                      item?.company_image ?
                        <Avatar src={`${config.baseURL + item?.company_image}`} sx={{ bgcolor: '#1976d2', ml: -1, width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                          <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
                        </Avatar> :
                        <Avatar sx={{ bgcolor: '#1976d2', ml: -1, width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                          <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
                        </Avatar>
                    )}
                  </>
                )
              })}
              {(props?.dataTrader?.company_list && props?.dataTrader?.company_list.length > 4) && <Avatar className="w-500" style={{ marginLeft: -6, width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                +{props?.dataTrader?.company_list.length - 4}
              </Avatar>}
            </Stack>

            :
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="left"
            >

              <Typography className="w-500" sx={{ fontSize: 14, ml: 1 }}>
                No Company listed
              </Typography>
            </Grid>}

        </Stack>
      </CardContent>
      <DrawerMore type={5} page={'explore'} id={product_id} user_trader_phone={user_trader_phone} open={anchorElShare} close={handleCloseShareMenu}></DrawerMore>
    </Card>
  )
}
