import Divider from "@material-ui/core/Divider";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Button, Grid, Hidden, Stack, Typography } from "@mui/material";
import * as React from 'react';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-multi-carousel/lib/styles.css";
import { useLocation } from 'react-router-dom';
import filtericon from "../assets/filter-ic.png";
import searchIcon from "../assets/search-explore-ic.png";
import CardListCoal from "../components/CardListCoal";
import CardListNikel from "../components/CardListNikel";
import CardPermintaanBeliTongkang from "../components/CardPermintaanBeliTongkang";
import CardPermintaanCoal from "../components/CardPermintaanCoal";
import CardPermintaanNikel from "../components/CardPermintaanNikel";
import CardPermintaanSewaTongkang from "../components/CardPermintaanSewaTongkang";
import CardTongkangListDefault from "../components/CardTongkangListDefault";
import DrawerFilterCoal from "../components/DrawerFilterCoal";
import DrawerFilterLocation from "../components/DrawerFilterLocation";
import DrawerFilterNikel from "../components/DrawerFilterNikel";
import DrawerPenawaranFilterTongkang from "../components/DrawerPenawaranFilterTongkang";
import Footer from "../components/Footer";
import MenuFilterCoal from "../components/MenuFilterCoal";
import MenuFilterNikel from "../components/MenuFilterNikel";
import MenuPenawaranFilterTongkang from "../components/MenuPenawaranFilterTongkang";
import NavBar from "../components/Navbar";
import SearchBarExplore from "../components/SearchbarExplore";
import TabelCoal from "../components/TableCoal";
import TabelNikel from "../components/TableNikel";
import { getProvinceFilter, searchBarge, searchCoal, searchNickel, searchRentBarge } from "../services/api.js";

function Penawaranpage() {
  const location = useLocation();

  const [tongkangList, setTongkangList] = useState([]);
  const [sewatongkangList, setsewaTongkangList] = useState([]);
  const [coalList, setCoalList] = useState([]);
  const [nickelList, setNickelList] = useState([])
  const [dataProvince, setDataProvince] = useState([]);
  const [limitProvince, setLimitProvince] = useState(5)
  const [showAllCoal, setShowAllCoal] = useState(false)
  const [showAllNikel, setShowAllNikel] = useState(false)
  const [locationId, setLocationId] = useState([])
  const [searchbutton,] = useState('')
  const [searchValue, setSearchValue] = useState(location.state?.search === undefined ? "" : location.state?.search)
  const [totalBatubara, setTotalBatubara] = useState('')
  const [totalNikel, setTotalNikel] = useState('')
  const [totalTongkang, setTotalTongkang] = useState('')
  const [totalsewaTongkang, setTotalsewaTongkang] = useState('')
  const [activeTab, setActiveTab] = useState(location.state?.detail === undefined ? "coal" : location.state?.detail);
  const [isLogin] = useState(localStorage.getItem('token') !== null ? true : false);
  const [openPermintaanCoal, setOpenPermintaanCoal] = useState(location.state?.request === true && location.state?.detail === 'coal' ? true : false)
  const [openPermintaanNikel, setOpenPermintaanNikel] = useState(location.state?.request === true && location.state?.detail === 'nikel' ? true : false)
  const [openPermintaanTongkang, setOpenPermintaanTongkang] = useState(location.state?.request === true && location.state?.detail === 'tongkang' ? true : false)
  const [openPermintaanSewaTongkang, setOpenPermintaansewaTongkang] = useState(location.state?.request === true && location.state?.detail === 'renttongkang' ? true : false)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)
  const [currentPageNikel, setCurrentPageNikel] = useState(1)
  const [pageCountNikel, setPageCountNikel] = useState(1)
  const [currentPageBarge, setCurrentPageBarge] = useState(1)
  const [pageCountBarge, setPageCountBarge] = useState(1)
  const [currentPageRentBarge, setCurrentPageRentBarge] = useState(1)
  const [pageCountRentBarge, setPageCountRentBarge] = useState(1)

  const [dataProvinceNikel, setDataProvinceNikel] = useState([]);
  const [searchValueLocation, setSearchValueLocation] = useState('')

  const handleClosePermintaanCoal = () => {
    resetFilterBatubara()
    setOpenPermintaanCoal(false)
  };

  const handleClosePermintaanNikel = () => {
    resetFilterNikel()
    setOpenPermintaanNikel(false)
  };

  const handleClosePermintaanTongkang = () => {
    resetFilterTongkang()
    setOpenPermintaanTongkang(false)
  };

  const handleClosePermintaansewaTongkang = () => {
    resetFilterSewaTongkang()
    setOpenPermintaansewaTongkang(false)
  };

  const [filterBatubara, setFilterBatubara] = useState({
    page: 1,
    min_calorie: location.state?.request === undefined || location.state?.detail !== 'coal' ? '' : location.state?.request_data?.calorie,
    max_calorie: location.state?.request === undefined || location.state?.detail !== 'coal' ? '' : location.state?.request_data?.calorie,
    min_total_sulfur: location.state?.request === undefined || location.state?.detail !== 'coal' ? '' : location.state?.request_data?.total_sulfur,
    max_total_sulfur: location.state?.request === undefined || location.state?.detail !== 'coal' ? '' : location.state?.request_data?.total_sulfur,
    min_ash: location.state?.request === undefined || location.state?.detail !== 'coal' ? '' : location.state?.request_data?.ASH,
    max_ash: location.state?.request === undefined || location.state?.detail !== 'coal' ? '' : location.state?.request_data?.ASH,
    quantity: location.state?.request === undefined || location.state?.detail !== 'coal' ? '' : location.state?.request_data?.quantity,
    min_price: location.state?.request === undefined || location.state?.detail !== 'coal' ? '' : location.state?.request_data?.price,
    max_price: location.state?.request === undefined || location.state?.detail !== 'coal' ? '' : location.state?.request_data?.price,
    id_location: [],
    request_trader_id: location.state?.request === undefined || location.state?.detail !== 'coal' ? '' : location.state?.request_data?.id_user_trader_select,
    request_start_laycan: location.state?.request === undefined || location.state?.detail !== 'coal' || location.state?.request_data?.start_laycan_date === null ? '' : location.state?.request_data?.start_laycan_date,
    request_end_laycan: location.state?.request === undefined || location.state?.detail !== 'coal' || location.state?.request_data?.end_laycan_date === null ? '' : location.state?.request_data?.end_laycan_date,
    request_port: location.state?.request === undefined || location.state?.detail !== 'coal' ? '' : location.state?.request_data?.name_loading_port,
    search_text: location.state?.search === undefined ? "" : location.state?.search
  });

  const [filterNikel, setFilterNikel] = useState({
    page: 1,
    min_kadar_ni: location.state?.request === undefined || location.state?.detail !== 'nikel' ? '' : location.state?.request_data?.kadar_ni,
    max_kadar_ni: location.state?.request === undefined || location.state?.detail !== 'nikel' ? '' : location.state?.request_data?.kadar_ni,
    min_kadar_air: location.state?.request === undefined || location.state?.detail !== 'nikel' ? '' : location.state?.request_data?.kadar_air,
    max_kadar_air: location.state?.request === undefined || location.state?.detail !== 'nikel' ? '' : location.state?.request_data?.kadar_air,
    min_kadar_fe: location.state?.request === undefined || location.state?.detail !== 'nikel' ? '' : location.state?.request_data?.kadar_fe,
    max_kadar_fe: location.state?.request === undefined || location.state?.detail !== 'nikel' ? '' : location.state?.request_data?.kadar_fe,
    min_si_mg_ratio: location.state?.request === undefined || location.state?.detail !== 'nikel' ? '' : location.state?.request_data?.si_mg_ratio,
    max_si_mg_ratio: location.state?.request === undefined || location.state?.detail !== 'nikel' ? '' : location.state?.request_data?.si_mg_ratio,
    quantity: location.state?.request === undefined || location.state?.detail !== 'nikel' ? '' : location.state?.request_data?.quantity,
    min_price: location.state?.request === undefined || location.state?.detail !== 'nikel' ? '' : location.state?.request_data?.price,
    max_price: location.state?.request === undefined || location.state?.detail !== 'nikel' ? '' : location.state?.request_data?.price,
    request_trader_id: location.state?.request === undefined || location.state?.detail !== 'nikel' ? '' : location.state?.request_data?.id_user_trader_select,
    request_start_laycan: location.state?.request === undefined || location.state?.detail !== 'nikel' ? '' : location.state?.request_data?.start_laycan_date === null ? '' : location.state?.request_data?.start_laycan_date,
    request_end_laycan: location.state?.request === undefined || location.state?.detail !== 'nikel' ? '' : location.state?.request_data?.end_laycan_date === null ? '' : location.state?.request_data?.end_laycan_date,
    id_location: [],
    request_port: location.state?.request === undefined || location.state?.detail !== 'nikel' ? '' : location.state?.request_data?.name_loading_port,
    search_text: location.state?.search === undefined ? "" : location.state?.search
  })
  const [filterTongkang, setFilterTongkang] = useState({
    page: 1,
    type_tongkang: location.state?.request === undefined || location.state?.detail !== 'tongkang' ? '' : location.state?.request_data?.type_tongkang,
    ukuran_tongkang: location.state?.request === undefined || location.state?.detail !== 'tongkang' ? '' : location.state?.request_data?.ukuran_tongkang,
    // max_quantity_muatan: location.state?.request === undefined || location.state?.detail !== 'tongkang' ? '' : location.state?.request_data?.quantity_muatan,
    // min_quantity_muatan: location.state?.request === undefined || location.state?.detail !== 'tongkang' ? '' : location.state?.request_data?.quantity_muatan,
    max_price: location.state?.request === undefined || location.state?.detail !== 'tongkang' ? '' : location.state?.request_data?.price,
    min_price: location.state?.request === undefined || location.state?.detail !== 'tongkang' ? '' : location.state?.request_data?.price,
    tahun_buat: location.state?.request === undefined || location.state?.detail !== 'tongkang' ? '' : location.state?.request_data?.tahun_buat,
    request_pelabuhan_muat: location.state?.request === undefined || location.state?.detail !== 'tongkang' ? '' : location.state?.request_data?.id_pelabuhan_muat,
    request_pelabuhan_bongkar: location.state?.request === undefined || location.state?.detail !== 'tongkang' ? '' : location.state?.request_data?.id_pelabuhan_bongkar,
    request_bendera: location.state?.request === undefined || location.state?.detail !== 'tongkang' ? '' : location.state?.request_data?.bendera_name,
    request_year: location.state?.request === undefined || location.state?.detail !== 'tongkang' ? '' : location.state?.request_data?.tahun_buat,
    search_text: location.state?.search === undefined ? "" : location.state?.search,
    start_laycan_date: location.state?.start_laycan_date === undefined ? "" : location.state?.start_laycan_date,
    end_laycan_date: location.state?.end_laycan_date === undefined ? "" : location.state?.end_laycan_date,

  })

  const [filterSewaTongkang, setFilterSewaTongkang] = useState({
    page: 1,
    type_tongkang: location.state?.request === undefined || location.state?.detail !== 'renttongkang' ? '' : location.state?.request_data?.type_tongkang,
    ukuran_tongkang: location.state?.request === undefined || location.state?.detail !== 'renttongkang' ? '' : location.state?.request_data?.ukuran_tongkang,

    ukuran_tongkang_lainnya: location.state?.request === undefined ? "" : location.state?.request_data?.ukuran_tongkang_lainnya,
    max_quantity_muatan: location.state?.request === undefined || location.state?.detail !== 'renttongkang' ? '' : location.state?.request_data?.quantity_muatan,
    min_quantity_muatan: location.state?.request === undefined || location.state?.detail !== 'renttongkang' ? '' : location.state?.request_data?.quantity_muatan,
    max_price: location.state?.request === undefined || location.state?.detail !== 'renttongkang' ? '' : location.state?.request_data?.price,
    min_price: location.state?.request === undefined || location.state?.detail !== 'renttongkang' ? '' : location.state?.request_data?.price,
    tahun_buat: location.state?.request === undefined || location.state?.detail !== 'renttongkang' ? '' : location.state?.request_data?.tahun_buat,
    request_pelabuhan_muat: location.state?.request === undefined || location.state?.detail !== 'renttongkang' ? '' : location.state?.request_data?.id_pelabuhan_muat,
    request_pelabuhan_bongkar: location.state?.request === undefined || location.state?.detail !== 'renttongkang' ? '' : location.state?.request_data?.id_pelabuhan_bongkar,
    request_bendera: location.state?.request === undefined || location.state?.detail !== 'renttongkang' ? '' : location.state?.request_data?.bendera_name,
    request_year: location.state?.request === undefined || location.state?.detail !== 'renttongkang' ? '' : location.state?.request_data?.tahun_buat,
    search_text: location.state?.search === undefined ? "" : location.state?.search,
    start_laycan_date: location.state?.start_laycan_date === undefined ? "" : location.state?.start_laycan_date,
    end_laycan_date: location.state?.end_laycan_date === undefined ? "" : location.state?.end_laycan_date,

  })

  const [translate,] = useTranslation("global");
  const [modalCoalFilter, setmodalCoalFilter] = React.useState(null)
  const [modalCoalLocation, setmodalCoalLocation] = React.useState(null)
  const [modalNikelFilter, setmodalNikelFilter] = React.useState(null)
  const [modalNikelLocation, setmodalNikelLocation] = React.useState(null)
  const [modalTongkangFilter, setmodalTongkangFilter] = React.useState(null)
  const [modalsewaTongkangFilter, setmodalsewaTongkangFilter] = React.useState(null)
  const [selectedItems, setSelectedItems] = useState([])
  const [inputukurangtongkang, setinputukurantongkang] = React.useState(false)
  const [inputukurangsewatongkang, setinputukuransewatongkang] = React.useState(false)

  useEffect(() => {
    getCoalList(filterBatubara);
    getNickelList(filterNikel);
    getTongkangList(filterTongkang);
    getSewaTongkangList(filterSewaTongkang);
    getDataProvinceCoal(searchValueLocation)
    getDataProvinceNikel(searchValueLocation)

  }, []);

  const handleKeyDownLocation = (event, e) => {
    if (event.key === 'Enter') {
      event.target.blur();
      // 👇 Get input value
      if (e === "coal") {
        getDataProvinceCoal(searchValueLocation);
      } else if (e === "nikel") {
        getDataProvinceNikel(searchValueLocation)
      }
    }
  };
  const handleSearchValueLocation = (e) => {
    if (e === '') {
      getDataProvinceCoal('');
      getDataProvinceNikel('')
      setSearchValueLocation('')
    } else {
      setSearchValueLocation(e)
    }
  };

  const handleKeyDown = (event, value) => {
    if (event.key === 'Enter') {
      filterBatubara["page"] = 1
      filterNikel["page"] = 1
      filterTongkang["page"] = 1
      filterSewaTongkang["page"] = 1
      setSearchValue(value)
      event.target.blur();
      // 👇 Get input value
      getCoalList(filterBatubara, value);
      getNickelList(filterNikel, value);
      getTongkangList(filterTongkang, value);
      getSewaTongkangList(filterSewaTongkang, value);
    }
  };

  const handleSearchValue = (e) => {
    if (e === '') {
      filterBatubara["page"] = 1
      filterNikel["page"] = 1
      filterTongkang["page"] = 1
      filterSewaTongkang["page"] = 1
      getCoalList(filterBatubara, '');
      getNickelList(filterNikel, '');
      getTongkangList(filterTongkang, '');
      getSewaTongkangList(filterSewaTongkang, '');
      setSearchValue('')
    } else {
      setSearchValue(e)
    }
  };

  //  Functions to handle Tab Switching
  const handleTab = (stateTab) => {
    // update the state to Tab
    if (stateTab === "trader" && !isLogin) {
      localStorage.clear()
      window.location.href = `/signin`
    }
    setActiveTab(stateTab);
    setLimitProvince(4)
    setLocationId([])
    setSelectedItems([])
    filterBatubara["id_location"] = []
    setFilterBatubara({ ...filterBatubara })
    getCoalList(filterBatubara)
    filterNikel["id_location"] = []
    setFilterNikel({ ...filterNikel })
    getNickelList(filterNikel)
    getTongkangList(filterTongkang)
    getSewaTongkangList(filterSewaTongkang)
    setSearchValueLocation('')
    if (stateTab === "coal") {
      getDataProvinceCoal('');
    } else if (stateTab === "nikel") {
      getDataProvinceNikel('')
    }
  };

  const handleLocationSumbit = (e) => {
    if (e === "coal") {
      getCoalList(filterBatubara)
      setLimitProvince(5)
      setShowAllCoal(false)
    } else if (e === "nikel") {
      getNickelList(filterNikel)
      setLimitProvince(5)
      setShowAllNikel(false)
    }
  }
  const handleLocationReset = (e) => {
    if (e === "coal") {
      setSearchValueLocation('')
      setSelectedItems([])
      filterBatubara["id_location"] = []
      setFilterBatubara({ ...filterBatubara })
      getCoalList(filterBatubara)
      getDataProvinceCoal('')

    } else if (e === "nikel") {
      setSearchValueLocation('')
      setSelectedItems([])
      filterNikel["id_location"] = []
      setFilterNikel({ ...filterNikel })
      getNickelList(filterNikel)
      getDataProvinceNikel('')
    }
  }
  const handleFilterBatubara = (e, y, option) => {
    filterBatubara["page"] = 1
    if (y === "location") {
      let isSelected = e.target.checked;
      let value = parseInt(e.target.value);
      if (isSelected) {
        setSelectedItems([...selectedItems, value])
      } else {
        setSelectedItems((prevData) => {
          return prevData.filter((id) => {
            return id !== value
          })
        })
      }

      const check = [...locationId]
      const index = check.indexOf(e.target.value);
      if (index === -1) {
        check.push(e.target.value)
      } else {
        check.splice(index, 1)
      }
      setLocationId(check)
      filterBatubara["id_location"] = check
      setFilterBatubara({ ...filterBatubara })
      getCoalList(filterBatubara)
    } else if (y === "popuplocation") {
      let isSelected = e.target.checked;
      let value = parseInt(e.target.value);
      if (isSelected) {
        setSelectedItems([...selectedItems, value])
      } else {
        setSelectedItems((prevData) => {
          return prevData.filter((id) => {
            return id !== value
          })
        })
      }

      const check = [...locationId]
      const index = check.indexOf(e.target.value);
      if (index === -1) {
        check.push(e.target.value)
      } else {
        check.splice(index, 1)
      }
      setLocationId(check)
      filterBatubara["id_location"] = check
      setFilterBatubara({ ...filterBatubara })
    } else if (y === 'mobile') {
      filterBatubara[option] = e
      setFilterBatubara({ ...filterBatubara })

    } else {
      filterBatubara[y] = e
      setFilterBatubara({ ...filterBatubara })
      getCoalList(filterBatubara)
    }

  }
  const handleFilterNikel = (e, y, option) => {
    filterNikel["page"] = 1
    if (y === "location") {
      let isSelected = e.target.checked;
      let value = parseInt(e.target.value);
      if (isSelected) {
        setSelectedItems([...selectedItems, value])
      } else {
        setSelectedItems((prevData) => {
          return prevData.filter((id) => {
            return id !== value
          })
        })
      }
      const check = [...locationId]
      const index = check.indexOf(e.target.value);
      if (index === -1) {
        check.push(e.target.value)
      } else {
        check.splice(index, 1)
      }
      setLocationId(check)
      filterNikel["id_location"] = check
      setFilterNikel({ ...filterNikel })
      getNickelList(filterNikel)
    } else if (y === "popuplocation") {
      let isSelected = e.target.checked;
      let value = parseInt(e.target.value);
      if (isSelected) {
        setSelectedItems([...selectedItems, value])
      } else {
        setSelectedItems((prevData) => {
          return prevData.filter((id) => {
            return id !== value
          })
        })
      }

      const check = [...locationId]
      const index = check.indexOf(e.target.value);
      if (index === -1) {
        check.push(e.target.value)
      } else {
        check.splice(index, 1)
      }
      setLocationId(check)
      filterNikel["id_location"] = check
      setFilterNikel({ ...filterNikel })
    } else if (y === 'mobile') {
      filterNikel[option] = e
      setFilterNikel({ ...filterNikel })

    } else {
      filterNikel[y] = e
      setFilterNikel({ ...filterNikel })
      getNickelList(filterNikel)
    }

  }

  const handleFilterTongkang = (e, y, option) => {
    filterTongkang["page"] = 1
    if (e !== 0) {
      setinputukurantongkang(false)
      if (option === 'mobile') {
        filterTongkang[y] = e
        setFilterTongkang({ ...filterTongkang })
      } else {
        filterTongkang[y] = e
        setFilterTongkang({ ...filterTongkang })
        getTongkangList(filterTongkang)
      }
    }
    else {
      setinputukurantongkang(true)
    }
  }

  const handleFilterSewaTongkang = (e, y, option) => {
    filterSewaTongkang["page"] = 1
    if (e !== 0) {
      setinputukuransewatongkang(false)
      if (option === 'mobile') {
        filterSewaTongkang[y] = e
        setFilterSewaTongkang({ ...filterSewaTongkang })
      } else {
        filterSewaTongkang[y] = e
        setFilterSewaTongkang({ ...filterSewaTongkang })
        getSewaTongkangList(filterSewaTongkang)
      }
    }
    else {
      setinputukuransewatongkang(true)
    }
  }

  const handleFilterUkuranTongkang = (e, y, option) => {
    filterTongkang["page"] = 1
    if (option === 'mobile') {
      filterTongkang[y] = e
      setFilterTongkang({ ...filterTongkang })
    } else {
      filterTongkang[y] = e
      setFilterTongkang({ ...filterTongkang })
      getTongkangList(filterTongkang)
    }
  }

  const handleFilterUkuransewaTongkang = (e, y, option) => {
    filterSewaTongkang["page"] = 1
    if (option === 'mobile') {
      filterSewaTongkang[y] = e
      setFilterSewaTongkang({ ...filterSewaTongkang })
    } else {
      filterSewaTongkang[y] = e
      setFilterSewaTongkang({ ...filterSewaTongkang })
      getSewaTongkangList(filterSewaTongkang)
    }
  }

  async function getTongkangList(event, searchValueQ) {
    try {
      if (searchValueQ !== undefined) {
        event["search_text"] = searchValueQ
      } else {
        event["search_text"] = searchValue
      }
      if (event.page === 1) {
        setTongkangList([])
        const response = await searchBarge(event)
        setTongkangList(response.data?.data.data.data);
        setTotalTongkang(response.data?.data?.totalItemTongkangJual)
        setCurrentPageBarge(response.data?.data?.data?.currentPage)
        setPageCountBarge(response.data?.data?.data?.pageCount)
      } else {
        const response = await searchBarge(event)
        setTotalTongkang(response.data?.data?.totalItemTongkangJual)
        setCurrentPageBarge(response.data?.data?.data?.currentPage)
        setPageCountBarge(response.data?.data?.data?.pageCount)
        let dataArray = response.data?.data?.data?.data
        let oldArray = tongkangList
        let newArray = oldArray.concat(dataArray)
        const result = newArray.reduce((unique, o) => {
          if (!unique.some(obj => obj.product_id === o.product_id && obj.code_product === o.code_product)) {
            unique.push(o);
          }
          return unique;
        }, []);
        setTongkangList(result)
      }

    } catch (e) {
      console.log(e);
    }
  }

  async function getSewaTongkangList(event, searchValueQ) {
    try {
      if (searchValueQ !== undefined) {
        event["search_text"] = searchValueQ
      } else {
        event["search_text"] = searchValue
      }
      if (event.page === 1) {
        setsewaTongkangList([])
        const response = await searchRentBarge(event)
        setsewaTongkangList(response.data?.data.data.data);
        setTotalsewaTongkang(response.data?.data?.totalItemTongkangMenyewakan)
        setCurrentPageRentBarge(response.data?.data?.data?.currentPage)
        setPageCountRentBarge(response.data?.data?.data?.pageCount)
      } else {
        const response = await searchRentBarge(event)
        setTotalsewaTongkang(response.data?.data?.totalItemTongkangMenyewakan)
        setCurrentPageRentBarge(response.data?.data?.data?.currentPage)
        setPageCountRentBarge(response.data?.data?.data?.pageCount)
        let dataArray = response.data?.data?.data?.data
        let oldArray = tongkangList
        let newArray = oldArray.concat(dataArray)
        const result = newArray.reduce((unique, o) => {
          if (!unique.some(obj => obj.product_id === o.product_id && obj.code_product === o.code_product)) {
            unique.push(o);
          }
          return unique;
        }, []);
        setTotalsewaTongkang(result)
      }

    } catch (e) {
      console.log(e);
    }
  }

  async function getDataProvinceCoal(name) {
    try {
      const response = await getProvinceFilter(name === undefined ? '' : name, 'order_batubara:asc')
      setDataProvince(response.data?.data);
    } catch (e) {
      console.log(e);
    }
  }

  async function getDataProvinceNikel(name) {
    try {
      const response = await getProvinceFilter(name === undefined ? '' : name, 'order_nikel:asc')
      setDataProvinceNikel(response.data?.data);
    } catch (e) {
      console.log(e);
    }
  }

  async function getCoalList(event, searchValueQ) {
    try {
      if (searchValueQ !== undefined) {
        event["search_text"] = searchValueQ
      } else {
        event["search_text"] = searchValue
      }
      if (event.page === 1) {
        setCoalList([])
        const response = await searchCoal(event)
        setCoalList(response.data?.data?.data?.data);
        setTotalBatubara(response.data?.data?.totalItemBatubara)
        setCurrentPage(response.data?.data?.data?.currentPage)
        setPageCount(response.data?.data?.data?.pageCount)
      } else {
        const response = await searchCoal(event)
        setTotalBatubara(response.data?.data?.totalItemBatubara)
        setCurrentPage(response.data?.data?.data?.currentPage)
        setPageCount(response.data?.data?.data?.pageCount)
        let dataArray = response.data?.data?.data?.data

        let oldArray = coalList
        let newArray = oldArray.concat(dataArray)
        const result = newArray.reduce((unique, o) => {
          if (!unique.some(obj => obj.product_id === o.product_id && obj.code_product === o.code_product)) {
            unique.push(o);
          }
          return unique;
        }, []);
        setCoalList(result)
      }

    } catch (e) {
      console.log(e);
    }
  }

  async function getNickelList(event, searchValueQ) {
    try {
      if (searchValueQ !== undefined) {
        event["search_text"] = searchValueQ
      } else {
        event["search_text"] = searchValue
      }
      if (event.page === 1) {
        setNickelList([])
        const response = await searchNickel(event)
        setNickelList(response.data?.data?.data?.data);
        setTotalNikel(response.data?.data?.totalItemNikel)
        setCurrentPageNikel(response.data?.data?.data?.currentPage)
        setPageCountNikel(response.data?.data?.data?.pageCount)
      } else {
        const response = await searchNickel(event)

        setTotalNikel(response.data?.data?.totalItemNikel)
        setCurrentPageNikel(response.data?.data?.data?.currentPage)
        setPageCountNikel(response.data?.data?.data?.pageCount)
        let dataArray = response.data?.data?.data?.data

        let oldArray = nickelList
        let newArray = oldArray.concat(dataArray)
        const result = newArray.reduce((unique, o) => {
          if (!unique.some(obj => obj.product_id === o.product_id && obj.code_product === o.code_product)) {
            unique.push(o);
          }
          return unique;
        }, []);
        setNickelList(result);
      }

    } catch (e) {
      console.log(e);
    }
  }

  async function setFavoriteBatubara() {
    setTimeout(() => {
      getCoalList(filterBatubara)
    }, 150);
  }

  async function setFavoriteNikel() {
    setTimeout(() => {
      getNickelList(filterNikel)
    }, 150);
  }

  async function setFavoriteTongkang() {
    setTimeout(() => {
      getTongkangList(filterTongkang)
    }, 150);
  }

  // Reset Filter 
  function resetFilterBatubara(e) {
    const default_value = {
      page: 1,
      min_calorie: '', max_calorie: '', min_total_sulfur: '', max_total_sulfur: '', min_ash: '',
      max_ash: '', quantity: '', min_price: '', max_price: '', id_location: "", search_text: ""
    }
    setSearchValueLocation('')
    setSelectedItems([])
    setLocationId([])
    setFilterBatubara(default_value)
    if (e !== 'mobile') {
      getCoalList(default_value)
    }
  }

  function Filter(e) {
    if (e === 'coal') {
      setFilterBatubara({ ...filterBatubara })
      getCoalList(filterBatubara)
    } else if (e === 'nikel') {
      setFilterNikel({ ...filterNikel })
      getNickelList(filterNikel)
    } else if (e === 'tongkang') {
      setFilterTongkang({ ...filterTongkang })
      getTongkangList(filterTongkang)
    }
  }

  function resetFilterNikel(e) {
    const default_value = {
      page: 1,
      min_kadar_ni: '', max_kadar_ni: '', min_kadar_air: '', max_kadar_air: '', min_kadar_fe: '', max_kadar_fe: '',
      min_si_mg_ratio: '', max_si_mg_ratio: '', quantity: '', min_price: '', max_price: '', id_location: "", search_text: ""
    }
    setFilterNikel(default_value)
    setSearchValueLocation('')
    setSelectedItems([])
    setLocationId([])
    if (e !== 'mobile') {
      getNickelList(default_value)
    }
  }

  function resetFilterTongkang(e) {
    const default_value = {
      page: 1,
      type_tongkang: "", ukuran_tongkang: '', max_quantity_muatan: '', min_quantity_muatan: '', max_price: '', min_price: '',
      bendera: "", tahun_buat: "", search_text: "", start_laycan_date: "", end_laycan_date: "",
    }
    setFilterTongkang(default_value)
    setSearchValueLocation('')
    if (e !== 'mobile') {
      getTongkangList(default_value)
    }

  }

  function resetFilterSewaTongkang(e) {
    const default_value = {
      page: 1,
      type_tongkang: "", ukuran_tongkang: '', max_quantity_muatan: '', min_quantity_muatan: '', max_price: '', min_price: '',
      bendera: "", tahun_buat: "", search_text: "", start_laycan_date: "", end_laycan_date: "",
    }
    setFilterSewaTongkang(default_value)
    setSearchValueLocation('')
    if (e !== 'mobile') {
      getSewaTongkangList(default_value)
    }

  }

  const handleOpenModalFilter = (state) => {
    if (state === 'coal') {
      setmodalCoalFilter(state);
    } else if (state === 'nikel') {
      setmodalNikelFilter(state)
    } else if (state === 'tongkang') {
      setmodalTongkangFilter(state)
    } else if (state === 'renttongkang') {
      setmodalsewaTongkangFilter(state)
    }
  };
  const handleCloseModalFilter = () => {
    setmodalCoalFilter(null);
    setmodalNikelFilter(null);
    setmodalTongkangFilter(null);
    setmodalsewaTongkangFilter(null);

  };

  const handleOpenModalLocationResponsive = (state) => {
    if (state === 'coal') {
      setmodalCoalLocation(state);
    } else if (state === 'nikel') {
      setmodalNikelLocation(state)
    }

  };
  const handleCloseModalLocationResponsive = () => {
    setmodalCoalLocation(null);
    setmodalNikelLocation(null);

  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'auto'
      /* you can also use 'auto' behaviour 
         in place of 'smooth' */
    });
  }



  return (
    <div>
      <div className='fixed-header'>
        <NavBar></NavBar></div>
      <Box className="flex-wrap-center width-100" >
        <Box className="content-inner" sx={{ mt: { xs: 6, sm: 6, md: 11, lg: 11, xl: 11 }, ml: { xs: '24px', lg: '80px', }, mr: { xs: '24px', lg: '80px', }, }}>
          <Grid
            container
            sx={{ mt: '16px', }}
            direction="row"
            alignItems={'center'}
          >
            <Button href="/" className="w-500" sx={{ fontSize: 16, mb: 1, color: '#2457B9', textTransform: 'none', pl: 0 }} variant="text">  {translate('page_explore.home')}</Button>

            <Typography className="w-500" sx={{ fontSize: 16, mb: 1, mr: 1, color: '#56617B' }}>
              /
            </Typography>
            <Typography className="w-500" sx={{ fontSize: 16, mb: 1, color: '#56617B' }}>
              {translate('navbar.penawaran')}
            </Typography>
          </Grid>

          <Grid

            container
            direction="row"
            spacing={4}
            sx={{ mb: 2, }}
          >
            {/* leftside Section */}
            <Grid item xs={12} lg={2.8}>
              <Typography className="w-700" sx={{ fontSize: 32, mb: { xs: 0, lg: '16px', sm: 0, md: '16px', xl: '16px' } }}>
                {translate('navbar.penawaran')}
              </Typography>
              {/* filter Coal Section */}
              {activeTab === 'coal' ?
                <Hidden lgDown>
                  <MenuFilterCoal page={'default'} handleLocationReset={handleLocationReset} resetFilterBatubara={resetFilterBatubara} handleFilterBatubara={handleFilterBatubara} filterBatubara={filterBatubara} dataProvince={dataProvince} selectedItems={selectedItems} limitProvince={limitProvince} setLimitProvince={setLimitProvince} setShowAllCoal={setShowAllCoal} handleSearchValueLocation={handleSearchValueLocation} handleKeyDownLocation={handleKeyDownLocation} handleLocationSumbit={handleLocationSumbit} showAllCoal={showAllCoal} searchValueLocation={searchValueLocation} >

                  </MenuFilterCoal>
                </Hidden> : null}
              {/* filter Nikel Section */}
              {activeTab === 'nikel' ?
                <Hidden lgDown>
                  <MenuFilterNikel page={'default'} handleLocationReset={handleLocationReset} resetFilterNikel={resetFilterNikel} handleFilterNikel={handleFilterNikel} filterNikel={filterNikel} dataProvince={dataProvinceNikel} selectedItems={selectedItems} limitProvince={limitProvince} setLimitProvince={setLimitProvince} setShowAllNikel={setShowAllNikel} handleSearchValueLocation={handleSearchValueLocation} handleKeyDownLocation={handleKeyDownLocation} handleLocationSumbit={handleLocationSumbit} showAllNikel={showAllNikel} searchValueLocation={searchValueLocation}></MenuFilterNikel>
                </Hidden> : null}
              {/* filter Tongkang Section */}
              {activeTab === 'tongkang' ?
                <Hidden lgDown>
                  <MenuPenawaranFilterTongkang page={'tongkang'} handleFilterUkuranTongkang={handleFilterUkuranTongkang} resetFilterTongkang={resetFilterTongkang} handleFilterTongkang={handleFilterTongkang} filterTongkang={filterTongkang} inputukurangtongkang={inputukurangtongkang}></MenuPenawaranFilterTongkang>
                </Hidden> : null}

              {/* filter Tongkang Section */}
              {activeTab === 'renttongkang' ?
                <Hidden lgDown>
                  <MenuPenawaranFilterTongkang page={'renttongkang'} handleFilterUkuranTongkang={handleFilterUkuransewaTongkang} resetFilterTongkang={resetFilterSewaTongkang} handleFilterTongkang={handleFilterSewaTongkang} filterTongkang={filterSewaTongkang} inputukurangtongkang={inputukurangsewatongkang}></MenuPenawaranFilterTongkang>
                </Hidden> : null}

            </Grid>

            {/* rightside Section */}
            <Grid item xs={12} lg={9.2}>
              {/* Tab Button Section */}
              <Box sx={{ marginBottom: '22px' }} className="tab-scroll-x">
                <Stack direction='row' alignItems={'center'}>
                  <Button
                    className="w-500"
                    sx={{
                      minWidth: 'auto',
                      height: '40px',
                      p: '8px 12px',
                      lineHeight: '22px',
                      width: { xs: '180px', md: 'fit-content' },
                      borderRadius: '8px',
                      background: activeTab === 'coal' ? "#081130" : '',
                      textTransform: 'none',
                      color: activeTab === 'coal' ? "white" : '#081130',
                      fontSize: 16, "&:hover": {
                        backgroundColor: activeTab === 'coal' ? "#081130" : "#56617B"
                      }
                    }}
                    onClick={() => {
                      handleTab("coal");
                    }}
                    variant={activeTab === 'coal' ? "contained" : 'text'}
                  >
                    {translate('page_explore.btn-jual-batubara')}
                    <Box
                      sx={{
                        background: activeTab === 'coal' ? "white" : '#D1D7E6',
                        borderRadius: '6px',
                        p: '4px',
                        ml: '8px',
                      }}
                    >
                      <Typography
                        className="w-500"
                        sx={{ color: activeTab === 'coal' ? "#081130" : '#56617B', fontSize: 12, lineHeight: 'normal' }}
                      >
                        {totalBatubara}
                      </Typography>
                    </Box>
                  </Button>
                  <Divider orientation="vertical" style={{ width: '1px', height: '16px', marginLeft: '12px', marginRight: '12px' }} />
                  <Button
                    className='w-500'
                    onClick={() => {
                      handleTab("nikel");
                    }}
                    sx={{
                      minWidth: 'auto',
                      height: '40px',
                      p: '8px 12px',
                      lineHeight: '22px',
                      width: { xs: '180px', md: 'fit-content' }, borderRadius: '8px',
                      background: activeTab === 'nikel' ? "#081130" : '', textTransform: 'none',
                      color: activeTab === 'nikel' ? "white" : '#081130', fontSize: 16, "&:hover": {
                        backgroundColor: activeTab === 'nikel' ? "#081130" : "#56617B"
                      }
                    }}
                    variant={activeTab === 'nikel' ? "contained" : 'text'}
                  >
                    {translate('page_explore.btn-jual-nikel')}
                    <Box
                      sx={{
                        background: activeTab === 'nikel' ? "white" : '#D1D7E6',
                        borderRadius: '6px',
                        p: '4px',
                        ml: '8px',
                      }}
                    >
                      <Typography
                        className="w-500"
                        sx={{ color: activeTab === 'nikel' ? "#081130" : '#56617B', fontSize: 12, lineHeight: 'normal' }}
                      >
                        {totalNikel}
                      </Typography>
                    </Box>
                  </Button>
                  <Divider orientation="vertical" style={{ width: '1px', height: '16px', marginLeft: '12px', marginRight: '12px' }} />
                  <Button
                    className='w-500'
                    onClick={() => {
                      handleTab("tongkang");
                    }}
                    sx={{
                      minWidth: 'auto',
                      height: '40px',
                      p: '8px 12px',
                      borderRadius: '8px',
                      lineHeight: '22px',
                      width: { xs: '180px', md: 'fit-content' },
                      background: activeTab === 'tongkang' ? "#081130" : '', textTransform: 'none',
                      color: activeTab === 'tongkang' ? "white" : '#081130', fontSize: 16, "&:hover": {
                        backgroundColor: activeTab === 'tongkang' ? "#081130" : "#56617B"
                      }
                    }}
                    variant={activeTab === 'tongkang' ? "contained" : 'text'}
                  >
                    {translate('page_explore.btn-jual-tongkang')}
                    <Box
                      sx={{
                        background: activeTab === 'tongkang' ? "white" : '#D1D7E6',
                        borderRadius: '6px',
                        p: '4px',
                        ml: '8px',
                      }}
                    >
                      <Typography
                        className="w-500"
                        sx={{ color: activeTab === 'tongkang' ? "#081130" : '#56617B', fontSize: 12, lineHeight: 'normal' }}
                      >
                        {totalTongkang}
                      </Typography>
                    </Box>
                  </Button>
                  <Divider orientation="vertical" style={{ width: '1px', height: '16px', marginLeft: '12px', marginRight: '12px' }} />
                  <Button
                    className='w-500'
                    onClick={() => {
                      handleTab("renttongkang");
                    }}
                    sx={{
                      minWidth: 'auto',
                      height: '40px',
                      p: '8px 12px',
                      borderRadius: '8px',
                      lineHeight: '22px',
                      width: { xs: '240px', md: 'fit-content' },
                      background: activeTab === 'renttongkang' ? "#081130" : '', textTransform: 'none',
                      color: activeTab === 'renttongkang' ? "white" : '#081130', fontSize: 16, "&:hover": {
                        backgroundColor: activeTab === 'renttongkang' ? "#081130" : "#56617B"
                      }
                    }}
                    variant={activeTab === 'renttongkang' ? "contained" : 'text'}
                  >
                    {translate('page_explore.btn-menyewa-tongkang')}
                    <Box
                      sx={{
                        background: activeTab === 'renttongkang' ? "white" : '#D1D7E6',
                        borderRadius: '6px',
                        p: '4px',
                        ml: '8px',
                      }}
                    >
                      <Typography
                        className="w-500"
                        sx={{ color: activeTab === 'renttongkang' ? "#081130" : '#56617B', fontSize: 12, lineHeight: 'normal' }}
                      >
                        {totalsewaTongkang}
                      </Typography>
                    </Box>
                  </Button>

                </Stack>
              </Box>
              {/* Search Section */}
              <Box sx={{ width: { lg: '1000px', xs: '100%' } }}>
                <Stack
                  direction="row"
                  alignItems='center'
                  justifyContent={'space-between'}

                  sx={{ mb: 2, pt: 0 }}
                >
                  {activeTab === 'coal' ?
                    <Grid className="searchtrader-explore-page" item xs>
                      <SearchBarExplore text={translate('page_explore.search-batubara')} searchValue={searchValue} handleSearchValue={handleSearchValue} handleKeyDown={handleKeyDown} searchIcon={searchIcon} />

                    </Grid> : ''}

                  {activeTab === 'nikel' ?
                    <Grid item className="searchtrader-explore-page" xs>
                      <SearchBarExplore text={translate('page_explore.search-nikel')} searchValue={searchValue} handleSearchValue={handleSearchValue} handleKeyDown={handleKeyDown} searchIcon={searchIcon} />

                    </Grid> : ''}
                  {activeTab === 'tongkang' ?
                    <Grid item className="searchtrader-explore-page" xs>
                      <SearchBarExplore text={translate('page_explore.search-tongkang')} searchValue={searchValue} handleSearchValue={handleSearchValue} handleKeyDown={handleKeyDown} searchIcon={searchIcon} />

                    </Grid> : ''}
                  {activeTab === 'renttongkang' ?
                    <Grid item className="searchtrader-explore-page" xs>
                      <SearchBarExplore text={translate('page_explore.search-tongkang')} searchValue={searchValue} handleSearchValue={handleSearchValue} handleKeyDown={handleKeyDown} searchIcon={searchIcon} />

                    </Grid> : ''}

                  {activeTab === 'coal' ?
                    <Hidden lgUp>
                      <Grid item>
                        <img onClick={() => {
                          handleOpenModalFilter("coal");
                        }} alt="filter-icon" src={filtericon} height={44} width={44}></img>
                      </Grid>
                    </Hidden> : null}
                  {activeTab === 'nikel' ?
                    <Hidden lgUp>
                      <Grid item>
                        <img onClick={() => {
                          handleOpenModalFilter("nikel");
                        }} alt="filter-icon" src={filtericon} height={44} width={44}></img>
                      </Grid>
                    </Hidden> : null}
                  {activeTab === 'tongkang' ?
                    <Hidden lgUp>
                      <Grid item>
                        <img onClick={() => {
                          handleOpenModalFilter("tongkang");
                        }} alt="filter-icon" src={filtericon} height={44} width={44}></img>
                      </Grid>
                    </Hidden> : null}
                  {activeTab === 'renttongkang' ?
                    <Hidden lgUp>
                      <Grid item>
                        <img onClick={() => {
                          handleOpenModalFilter("renttongkang");
                        }} alt="filter-icon" src={filtericon} height={44} width={44}></img>
                      </Grid>
                    </Hidden> : null}
                </Stack>

                {activeTab === 'coal' ?
                  <Box sx={{ mb: 2, }}>
                    {openPermintaanCoal === true ? (
                      <CardPermintaanCoal data={location.state?.request_data} result={totalBatubara} resetFunc={() => { handleClosePermintaanCoal() }} />
                    ) : (
                      <></>
                    )}
                    <TabelCoal page={'penawaran'} filter={searchbutton} getCoalList={(e) => setFavoriteBatubara()} coalList={coalList} />
                  </Box> : null}
                {activeTab === 'coal' ?
                  <Hidden lgUp>
                    <Grid columnSpacing={2.5} container direction={'row'}>
                      {(coalList && coalList.length > 0) ?
                        coalList?.map(data => (
                          <CardListCoal key={data.user_id} page={'penawaran'} filter={searchbutton} getCoalList={(e) => setFavoriteBatubara()} coalList={data} />
                        ))
                        :
                        <p className="w-500 ml-22" >  {translate('permintaan.datanotfound')}</p>
                      }
                    </Grid>
                  </Hidden> : null}
                {activeTab === 'nikel' ?
                  <Box sx={{ my: 2 }}>
                    {openPermintaanNikel === true ? (
                      <CardPermintaanNikel data={location.state?.request_data} result={totalNikel} resetFunc={() => { handleClosePermintaanNikel() }} />
                    ) : (
                      <></>
                    )}
                    <TabelNikel page={'penawaran'} nickelList={nickelList} getNikel={(e) => setFavoriteNikel()} />
                  </Box> : null}
                {activeTab === 'nikel' ?
                  <Hidden lgUp>
                    <Grid columnSpacing={2.5} container direction={'row'}>
                      {(nickelList && nickelList.length > 0) ?
                        nickelList?.map(data => (
                          <CardListNikel key={data.user_id} page={'penawaran'} nickelList={data} getNikel={(e) => setFavoriteNikel()} />
                        ))
                        :
                        <p className="w-500 ml-22" >  {translate('permintaan.datanotfound')}</p>
                      }
                    </Grid>
                  </Hidden>
                  : null}

                {activeTab === 'tongkang' ?
                  <Box sx={{ mb: 2 }}>
                    {openPermintaanTongkang === true ? (
                      <>
                        {location.state?.request_data.type_tongkang === 4 ? (
                          <CardPermintaanBeliTongkang data={location.state?.request_data} result={totalTongkang} resetFunc={() => { handleClosePermintaanTongkang() }} />
                        ) : (
                          <CardPermintaanSewaTongkang data={location.state?.request_data} result={totalTongkang} resetFunc={() => { handleClosePermintaanTongkang() }} />
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    <Grid columnSpacing={2.5} container direction={'row'}>
                      {(tongkangList && tongkangList.length > 0) ?
                        tongkangList.map(tongkang => (
                          <Grid sx={{ mt: 2 }} item md={4} sm={6} xs={12}>
                            <CardTongkangListDefault key={tongkang.product_id} data={tongkang} getTongkang={(e) => setFavoriteTongkang()} />
                          </Grid>
                        ))
                        :
                        <p className="w-500 ml-22" >  {translate('permintaan.datanotfound')}</p>
                      }
                    </Grid>
                  </Box> : null}

                {activeTab === 'renttongkang' ?
                  <Box sx={{ mb: 2 }}>
                    {openPermintaanSewaTongkang === true ? (
                      <>
                        {location.state?.request_data.type_tongkang === 1 ? (
                          <CardPermintaanBeliTongkang data={location.state?.request_data} result={totalsewaTongkang} resetFunc={() => { handleClosePermintaansewaTongkang() }} />
                        ) : (
                          <CardPermintaanSewaTongkang data={location.state?.request_data} result={totalsewaTongkang} resetFunc={() => { handleClosePermintaansewaTongkang() }} />
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    <Grid columnSpacing={2.5} container direction={'row'}>
                      {(sewatongkangList && sewatongkangList.length > 0) ?
                        sewatongkangList.map(tongkang => (
                          <Grid sx={{ mt: 2 }} item md={4} sm={6} xs={12}>
                            <CardTongkangListDefault key={tongkang.product_id} data={tongkang} getTongkang={(e) => setFavoriteTongkang()} />
                          </Grid>
                        )) :
                        <p className="w-500 ml-22" >  {translate('permintaan.datanotfound')}</p>
                      }
                    </Grid>
                  </Box> : null}


                {(activeTab === "coal" && coalList !== undefined && (parseInt(pageCount) !== parseInt(currentPage)) && (parseInt(pageCount) > parseInt(currentPage))) &&
                  <Grid sx={{ mt: 2, mb: 8 }} className="pointer" container justifyContent="center" alignItems="center" direction="row" onClick={(e) => {
                    handleFilterBatubara(filterBatubara["page"] + 1, "page")
                    // setTimeout(() => {
                    //   scrollToBottom()
                    // }, 1000)
                  }
                  }>
                    <Typography className="w-500" sx={{ fontSize: 16, color: "#2457B9", mr: 1 }}>
                      {translate('page_explore.lihatlebihbanyak')}
                    </Typography>
                    <KeyboardArrowDownIcon sx={{ fontSize: 16, color: "#2457B9" }} />
                  </Grid>}

                {(activeTab === "nikel" && nickelList !== undefined && (parseInt(pageCountNikel) !== parseInt(currentPageNikel)) && (parseInt(pageCountNikel) > parseInt(currentPageNikel))) &&
                  <Grid sx={{ mt: 2, mb: 8 }} className="pointer" container justifyContent="center" alignItems="center" direction="row" onClick={(e) => {
                    handleFilterNikel(filterNikel["page"] + 1, "page")
                    // setTimeout(() => {
                    //   scrollToBottom()
                    // }, 1000)
                  }}>
                    <Typography className="w-500" sx={{ fontSize: 16, color: "#2457B9", mr: 1 }}>
                      {translate('page_explore.lihatlebihbanyak')}
                    </Typography>
                    <KeyboardArrowDownIcon sx={{ fontSize: 16, color: "#2457B9" }} />
                  </Grid>}

                {(activeTab === "tongkang" && tongkangList !== undefined && (parseInt(pageCountBarge) !== parseInt(currentPageBarge)) && (parseInt(pageCountBarge) > parseInt(currentPageBarge))) &&
                  <Grid sx={{ mt: 2, mb: 8 }} className="pointer" container justifyContent="center" alignItems="center" direction="row" onClick={(e) => {
                    handleFilterTongkang(filterTongkang["page"] + 1, "page")
                    // setTimeout(() => {
                    //   scrollToBottom()
                    // }, 1000)
                  }}>
                    <Typography className="w-500" sx={{ fontSize: 16, color: "#2457B9", mr: 1 }}>
                      {translate('page_explore.lihatlebihbanyak')}
                    </Typography>
                    <KeyboardArrowDownIcon sx={{ fontSize: 16, color: "#2457B9" }} />
                  </Grid>}

                {(activeTab === "renttongkang" && sewatongkangList !== undefined && (parseInt(pageCountRentBarge) !== parseInt(currentPageRentBarge)) && (parseInt(pageCountRentBarge) > parseInt(currentPageRentBarge))) &&
                  <Grid sx={{ mt: 2, mb: 8 }} className="pointer" container justifyContent="center" alignItems="center" direction="row" onClick={(e) => {
                    handleFilterTongkang(filterTongkang["page"] + 1, "page")
                    // setTimeout(() => {
                    //   scrollToBottom()
                    // }, 1000)
                  }}>
                    <Typography className="w-500" sx={{ fontSize: 16, color: "#2457B9", mr: 1 }}>
                      {translate('page_explore.lihatlebihbanyak')}
                    </Typography>
                    <KeyboardArrowDownIcon sx={{ fontSize: 16, color: "#2457B9" }} />
                  </Grid>}


              </Box>
            </Grid>
            {/* modal filter  Responsive Section */}
            <Hidden lgUp>
              {/* modal filter Coal Responsive Section */}
              {activeTab === 'coal' && <DrawerFilterCoal page={'default'} open={modalCoalFilter} close={handleCloseModalFilter} handleLocationReset={handleLocationReset} resetFilterBatubara={resetFilterBatubara} handleFilterBatubara={handleFilterBatubara} filterBatubara={filterBatubara} dataProvince={dataProvince} selectedItems={selectedItems} limitProvince={limitProvince} setLimitProvince={setLimitProvince} handleOpenModalLocationResponsive={handleOpenModalLocationResponsive} handleCloseModalFilter={handleCloseModalFilter} Filter={Filter}>
              </DrawerFilterCoal>}
              {/* modal filter Nikel Responsive Section */}
              {activeTab === 'nikel' && <DrawerFilterNikel page={'default'} open={modalNikelFilter} close={handleCloseModalFilter} handleLocationReset={handleLocationReset} resetFilterNikel={resetFilterNikel} handleFilterNikel={handleFilterNikel} filterNikel={filterNikel} dataProvince={dataProvinceNikel} selectedItems={selectedItems} limitProvince={limitProvince} setLimitProvince={setLimitProvince} handleOpenModalLocationResponsive={handleOpenModalLocationResponsive} handleCloseModalFilter={handleCloseModalFilter} Filter={Filter}>
              </DrawerFilterNikel>}
              {/* modal filter Tongkang Responsive Section */}
              {activeTab === 'tongkang' && <DrawerPenawaranFilterTongkang page={'default'} open={modalTongkangFilter} close={handleCloseModalFilter} handleLocationReset={handleLocationReset} resetFilterTongkang={resetFilterTongkang} handleFilterTongkang={handleFilterTongkang} filterTongkang={filterTongkang} handleFilterSewaTongkang={handleFilterSewaTongkang} handleFilterUkuranTongkang={handleFilterUkuranTongkang} handleCloseModalFilter={handleCloseModalFilter} Filter={Filter} inputukurangtongkang={inputukurangtongkang}>
              </DrawerPenawaranFilterTongkang>}

              {/* modal filter Tongkang Responsive Section */}
              {activeTab === 'renttongkang' && <DrawerPenawaranFilterTongkang page={'default'} open={modalsewaTongkangFilter} close={handleCloseModalFilter} handleLocationReset={handleLocationReset} resetFilterTongkang={resetFilterSewaTongkang} handleFilterTongkang={handleFilterSewaTongkang} filterTongkang={filterSewaTongkang} handleFilterUkuranTongkang={handleFilterUkuransewaTongkang} handleCloseModalFilter={handleCloseModalFilter} Filter={Filter} inputukurangtongkang={inputukurangsewatongkang}>
              </DrawerPenawaranFilterTongkang>}

              {/* modal filter Location Responsive Section */}
              {activeTab === 'coal' && <DrawerFilterLocation type={'coal'} open={modalCoalLocation} close={handleCloseModalLocationResponsive} handleLocationReset={handleLocationReset} handleSearchValueLocation={handleSearchValueLocation} handleKeyDownLocation={handleKeyDownLocation} dataProvince={dataProvince}
                handleFilter={handleFilterBatubara} selectedItems={selectedItems} handleLocationSumbit={handleLocationSumbit} searchValueLocation={searchValueLocation}
              ></DrawerFilterLocation>}

              {activeTab === 'nikel' && <DrawerFilterLocation type={'nikel'} open={modalNikelLocation} close={handleCloseModalLocationResponsive} handleLocationReset={handleLocationReset} handleSearchValueLocation={handleSearchValueLocation} handleKeyDownLocation={handleKeyDownLocation} dataProvince={dataProvinceNikel}
                handleFilter={handleFilterNikel} selectedItems={selectedItems} handleLocationSumbit={handleLocationSumbit} searchValueLocation={searchValueLocation}
              ></DrawerFilterLocation>}


            </Hidden>
          </Grid>
        </Box >

      </Box >
      <Footer />
    </div >
  )
}

export default Penawaranpage
