
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CompanyDefault from '@mui/icons-material/Business';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PersonIcon from '@mui/icons-material/Person';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { Avatar, Box, Button, Grid, Hidden, IconButton, Stack, Typography } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { default as CallCompanyIcon } from "../assets/call-company-ic.png";
import alertKontaktIc from "../assets/icons/alert.svg";
import { default as EmailCompanyIcon } from "../assets/mail-company-ic.png";
import pelayarIcon from "../assets/pelayar-ic.png";
import penambangIcon from "../assets/penambang-ic.png";
import iconbelumadaperusahaan from "../assets/profile-iconbelumadaperusahaan.svg";
import traderIcon from "../assets/trader-ic.png";
import { searchCompany } from "../services/api.js";
import { config } from '../services/constants';
import { capitalizeEachWord, convertText, format } from '../services/primary';
import ModalShare from "./ModalShare";
let page = '';

export default function TableCompany(props) {
  page = props.page
  const navigate = useNavigate()
  const [productList, setProductList] = useState([]);
  const [translate,] = useTranslation("global");
  const [anchorElShare, setAnchorElShare] = useState(null);
  const [product_id, setProduct_id] = useState(null);
  const [dataperusahaan, setdataperusahaan] = useState(null);
  const [user_trader_phone, setuser_trader_phone] = useState(null);
  const [isLogin] = useState(localStorage.getItem('token') !== null ? true : false);
  const [userid] = useState(localStorage.getItem("id_user") !== null ? localStorage.getItem("id_user") : null);

  const handleCloseShareMenu = () => {
    setAnchorElShare(null);
  };

  const handleOpenShareMenu = (event, id, traderphone, data) => {
    setuser_trader_phone(traderphone);
    setProduct_id(id);
    setdataperusahaan(data);
    setAnchorElShare(event.currentTarget);

  };

  const GotoClaimCompany = (idcompany) => {
    if (isLogin) {
      window.location.href = `/claim-perusahaan?id_company=${idcompany}`
    } else {
      window.location.href = `/signin`
    }

  };

  const GotoDetailCompany = (id_company, name_company) => {
    let filter_name = capitalizeEachWord(convertText(name_company))
    window.location.href = `/detail/${filter_name}`
  };


  const columns = [
    {
      headerClassName: 'super-app-theme--header', field: 'name', headerName: translate('page_explore.namaperusahaan'), width: 320, sortable: false, renderCell: (params) => (
        <Grid container onClick={(e) => {
          GotoDetailCompany(params?.row.id, params?.row.name)
        }} sx={{ display: 'flex', cursor: 'pointer' }} justifyContent={'start'} alignItems={'center'}>
          {params.row?.company_image ? <img style={{ borderRadius: '50%', marginRight: 6 }} alt="company_image-icon" src={config.baseURL + params.row.company_image} width={32} height={32} ></img> : <Avatar sx={{ bgcolor: '#1976d2', mr: 1, height: 32, width: 32, }} aria-label="recipe">
            <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
          </Avatar>
          }
          <Stack direction="column" >
            <Typography
              className="w-700"
              sx={{ fontSize: 14, ml: 1, color: '#081130' }}
            >
              {(params?.row.name && params?.row.name.length > 20) ? capitalizeEachWord(params?.row.name).slice(0, 20) + '...' : capitalizeEachWord(params?.row.name)}

            </Typography>
            <Typography
              className="w-500"
              sx={{ fontSize: 14, ml: 1, color: '#56617B', width: "50px" }}
            >

              {params.row.kota} {params.row.kota && params.row.provinsi !== null ? ',' : ''}  {params.row.provinsi}
            </Typography>
          </Stack >
        </Grid >
      ),
    },
    {
      headerClassName: 'super-app-theme--header', field: 'phone_number', headerName: translate('page_explore.kontak'), width: 220, sortable: false, renderCell: (params) => (
        params.row.phone_number === null || params.row.phone_number === "" && params.row.email === null || params.row.email === "" ?

          <Stack onClick={(e) => {
            GotoDetailCompany(params?.row.id, params?.row.name)
          }} direction="row" sx={{ cursor: 'pointer', ml: "4px" }} alignItems={'center'}>
            <Box sx={{ width: '32px', height: '32px', background: '#D9D9D9', borderRadius: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
              <img alt="Nikel-icon" src={alertKontaktIc} height={18}></img>
            </Box>
            <Typography
              className="w-500"
              sx={{ fontSize: 14, ml: 1, color: '#8792AB' }}
            >
              {translate('status.kontak-empty')}
            </Typography>

          </Stack> :
          <Grid
            container
            onClick={(e) => {
              GotoDetailCompany(params?.row.id, params?.row.name)
            }}

            sx={{ cursor: 'pointer', ml: "4px" }}
            justifyContent="left"
            alignItems="left"
            direction="column"
          // spacing={1}
          >
            <Stack direction="row" alignItems={'center'}>
              <img alt="Nikel-icon" src={CallCompanyIcon} height={18}></img>
              <Typography
                className="w-500"
                sx={{ fontSize: 14, ml: 1, color: params.row.phone_number === null || params.row.phone_number === "" ? '#8792AB' : '#56617B' }}
              >
                {params.row.phone_number === null || params.row.phone_number === "" ? translate('status.nomor-empty') : format(params.row.phone_number)}
              </Typography>

            </Stack>
            <Stack direction="row" alignItems={'center'}>
              <img alt="Nikel-icon" src={EmailCompanyIcon} height={18}></img>
              <Typography
                className="w-500"
                sx={{ fontSize: 14, ml: 1, color: params.row.email === null || params.row.email === "" ? '#8792AB' : '#56617B' }}
              >
                {params.row.email === null || params.row.email === "" ? translate('status.nomor-empty') : params.row.email}
              </Typography>

            </Stack>
          </Grid>
      ),
    },
    {
      headerClassName: 'super-app-theme--header', field: 'bidang_perusahaan', headerName: translate('page_explore.bidangusaha'), width: 140, sortable: false, renderCell: (params) =>
      (
        <Grid sx={{ display: 'flex' }} justifyContent={'center'} alignItems={'center'}>

          <Grid
            container
            justifyContent="left"
            alignItems="center"
            direction="row"
            onClick={(e) => {
              GotoDetailCompany(params?.row.id, params?.row.name)
            }}
            sx={{ cursor: 'pointer' }}
          >
            {params.row.bidang_perusahaan === 1 ? <img alt="Nikel-icon" src={penambangIcon} height={18}></img> : ''}
            {params.row.bidang_perusahaan === 2 ? <img alt="Nikel-icon" src={traderIcon} height={18}></img> : ''}
            {params.row.bidang_perusahaan === 3 ? <img alt="Nikel-icon" src={pelayarIcon} height={18}></img> : ''}

            <Typography
              className="w-700"
              sx={{ fontSize: 14, ml: 1, }}
            >
              {params.row.bidang_perusahaan === 1 ? translate('page_explore.penambang') : ''}
              {params.row.bidang_perusahaan === 2 ? translate('page_explore.trader') : ''}
              {params.row.bidang_perusahaan === 3 ? translate('page_explore.pelayaran') : ''}
            </Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      headerClassName: 'last-theme--header', field: 'status_claim', headerName: 'Status Claim', width: 230, sortable: false, renderCell: (params) => (
        <Stack direction={'row'} alignItems={'center'}>
          {params.row.verify_status === 'Unverified' ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  background: "#F4F6FA",
                  borderRadius: '300px',
                  p: '2px 12px',
                  // width: '80px',
                  height: '24px',
                  mr: '6px'
                }}
              > <Stack direction={'row'} alignItems={'center'}>


                  <Box sx={{ borderRadius: '50%', background: '#56617B', width: '10px', height: '10px', mr: 1 }}>
                  </Box>
                  <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 12 }} >
                    {translate('status.' + params.row.verify_status)}
                  </Typography>

                </Stack>

              </Box>
              <Button className="button-blue-outlined w-500"
                onClick={(e) => {
                  GotoClaimCompany(params.row.company_id)
                }}
                sx={{ fontSize: 14, p: '4px 8px' }} variant="outlined">     {translate('status.claim')}</Button>
            </>
          ) : (
            <></>
          )}
          {params.row.verify_status === 'Verifikasi Sedang Direview' ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  background: "#FCEEC5",
                  borderRadius: '300px',
                  p: '2px 12px',
                  // width: '80px',
                  height: '24px',
                  mr: '12px'
                }}
              > <Stack direction={'row'} alignItems={'center'}>


                  <Box sx={{ borderRadius: '50%', background: '#F7CE46', width: '10px', height: '10px', mr: 1 }}>
                  </Box>
                  <Typography className="w-500" sx={{ color: '#081130', fontSize: 12 }} >
                    {translate('status.' + params.row.verify_status)}
                  </Typography>

                </Stack>

              </Box>

            </>
          ) : (
            <></>
          )}
          {params.row.verify_status === 'Verified' ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  background: 'var(--tint-green, rgba(59, 181, 108, 0.25));',
                  borderRadius: '300px',
                  p: '2px 12px',
                  // width: '80px',
                  height: '24px',
                  mr: '12px'
                }}
              > <Stack direction={'row'} alignItems={'center'}>


                  <Box sx={{ borderRadius: '50%', background: '#3BB56C', width: '10px', height: '10px', mr: 1 }}>
                  </Box>
                  <Typography className="w-500" sx={{ color: '#081130', fontSize: 12 }} >
                    {translate('status.' + params.row.verify_status)}
                  </Typography>



                </Stack>

              </Box>

            </>
          ) : (
            <></>
          )}

        </Stack>
      ),
    },

    {
      headerClassName: 'last-theme--header', field: 'action', headerName: ' ', sortable: false, width: 80, renderCell: (params) =>
      (
        <Grid
          sx={{ display: 'flex' }}
          container
          justifyContent="end"
          alignItems="center"
          direction="row"
        >
          <IconButton onClick={(e) => handleOpenShareMenu(e, params.row.company_id, params.row.phone_number, params.row)} size="small">
            <MoreHorizIcon sx={{ fontSize: 18, }} />

          </IconButton>
          <ModalShare page={"company"} type={'4'} id={product_id} data={dataperusahaan} user_trader_phone={user_trader_phone} anchorEl={anchorElShare} open={anchorElShare} onClose={handleCloseShareMenu}></ModalShare>
          <IconButton size="small"
            onClick={(e) => {
              GotoDetailCompany(params?.row.id, params?.row.name)
            }}>

            <ArrowForwardIosIcon

              sx={{ fontSize: 18 }} />
          </IconButton>
        </Grid>
      ),
    },
  ];

  const columnprofile = [
    {
      headerClassName: 'super-app-theme--header', field: 'company_name', headerName: translate('page_explore.namaperusahaan'), width: 400, sortable: false, renderCell: (params) => (
        <Grid
          container
          justifyContent="left"
          alignItems="center"
          direction="row"
          sx={{ cursor: 'pointer' }}
          onClick={(e) => {
            GotoDetailCompany(params?.row.company_id, params?.row.company_name)
          }}
        >
          <Avatar src={`${config.baseURL + params?.row?.company_url_image}`} sx={{ bgcolor: '#1976d2', }} aria-label="recipe">
            <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
          </Avatar>
          <Stack direction="column">
            <Typography
              className="w-700"
              sx={{ fontSize: 14, ml: 1, }}
            >
              {(params?.row.company_name && params?.row.company_name.length > 25) ? capitalizeEachWord(params?.row?.company_name).slice(0, 25) + '...' : capitalizeEachWord(params?.row?.company_name)}

            </Typography>
            {params?.row?.type === 'join' ? (
              <>
                <Typography
                  className="w-500"
                  sx={{ fontSize: 14, ml: 1, color: '#56617B' }}
                >
                  {translate('status.bergabungpada')} {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(params?.row?.date_information))}
                </Typography>
              </>
            ) : (
              <></>
            )}
            {params?.row?.type === 'created' ? (
              <>
                {userid.includes(params?.row?.company_user_id) ? <Typography
                  className="w-500"
                  sx={{ fontSize: 14, ml: 1, color: '#56617B' }}
                >
                  {translate('status.dibuatanda')} {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(params?.row?.date_information))}
                </Typography>
                  : <Typography
                    className="w-500"
                    sx={{ fontSize: 14, ml: 1, color: '#56617B' }}
                  >

                    {translate('status.dibuatpada')} {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(params?.row?.date_information))}
                  </Typography>}
              </>
            ) : (
              <></>
            )}

            {params?.row?.type === 'request' ? (
              <>
                <Typography
                  className="w-500"
                  sx={{ fontSize: 14, ml: 1, color: '#56617B' }}
                >
                  {translate('status.mengajukanpada')} {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(params?.row?.date_information))}
                </Typography>
              </>
            ) : (
              <></>
            )}

          </Stack>

        </Grid>
      ),
    },
    {
      headerClassName: 'super-app-theme--header', field: 'company_phone_number', headerName: translate('page_explore.kontak'), width: 320, sortable: false, renderCell: (params) => (


        <Grid
          container
          justifyContent="left"
          alignItems="center"
          direction="row"
          sx={{ cursor: 'pointer' }}
          onClick={(e) => {
            GotoDetailCompany(params?.row.company_id, params?.row.company_name)
          }}
        >
          <Stack direction="column">
            <img alt="Nikel-icon" src={CallCompanyIcon} height={18}></img>
            <img alt="Nikel-icon" src={EmailCompanyIcon} height={18}></img>
          </Stack>
          <Stack direction="column">
            <Typography
              className="w-500"
              sx={{ fontSize: 14, ml: 1, color: '#56617B' }}
            >
              {params.row.company_phone_number === null ? '-' : params.row.company_phone_number}
            </Typography>
            <Typography
              className="w-500"
              sx={{ fontSize: 14, ml: 1, color: '#56617B' }}
            >
              {params.row.company_email}
            </Typography>
          </Stack>


        </Grid>
      ),
    },
    {
      headerClassName: 'super-app-theme--header', field: 'bidang_perusahaan', headerName: translate('page_explore.bidangusaha'), width: 170, sortable: false, renderCell: (params) =>


      (
        <Grid container sx={{ cursor: 'pointer', display: 'flex' }}
          onClick={(e) => {
            GotoDetailCompany(params?.row.company_id, params?.row.company_name)
          }} justifyContent={'center'} alignItems={'center'}>

          <Grid
            container
            justifyContent="left"
            alignItems="center"
            direction="row"

          >
            {params.row.bidang_perusahaan === 1 ? <img alt="Nikel-icon" src={penambangIcon} height={18}></img> : ''}
            {params.row.bidang_perusahaan === 2 ? <img alt="Nikel-icon" src={traderIcon} height={18}></img> : ''}
            {params.row.bidang_perusahaan === 3 ? <img alt="Nikel-icon" src={pelayarIcon} height={18}></img> : ''}

            <Typography
              className="w-700"
              sx={{ fontSize: 14, ml: 1, }}
            >

              {params.row.bidang_perusahaan === 1 ? translate('page_explore.penambang') : ''}
              {params.row.bidang_perusahaan === 2 ? translate('page_explore.trader') : ''}
              {params.row.bidang_perusahaan === 3 ? translate('page_explore.pelayaran') : ''}
            </Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      headerClassName: 'super-app-theme--header', field: 'staff', headerName: 'Staff', width: 160, sortable: false, renderCell: (params) => (


        <Grid container sx={{ cursor: 'pointer', display: 'flex' }}
          onClick={(e) => {
            GotoDetailCompany(params?.row.company_id, params?.row.company_name)
          }} justifyContent={'center'} alignItems={'center'}>
          <Grid
            container
            justifyContent="left"
            alignItems="center"
            direction="row"


          >
            <Stack direction={'row'}>
              {params.row.all_staff.map((item, index) => {
                if (index < 4) return (
                  <>
                    {index === 0 ? (
                      <Avatar src={`${config.baseURL + item?.url_image_user}`} sx={{ bgcolor: '#1976d2', }} aria-label="recipe">
                        <PersonIcon></PersonIcon>
                      </Avatar>
                    ) : (
                      <Avatar src={`${config.baseURL + item?.url_image_user}`} sx={{ bgcolor: '#1976d2', ml: -2 }} aria-label="recipe">
                        <PersonIcon></PersonIcon>
                      </Avatar>
                    )}
                  </>
                )
              })}
              {(params.row.all_staff && params.row.all_staff.length > 4) ? <Avatar className="w-500" style={{ marginLeft: -10, fontSize: 16 }} aria-label="recipe">
                +{params.row.all_staff.length - 4}
              </Avatar> : '-'}
            </Stack>
          </Grid>
        </Grid>
      ),
    },
    {
      headerClassName: 'last-theme--header', field: 'status_staff', headerName: 'Status', width: 160, sortable: false, renderCell: (params) => (
        <Grid container sx={{ cursor: 'pointer' }}
          onClick={(e) => {
            GotoDetailCompany(params?.row.company_id, params?.row.company_name)
          }}

        >
          {params.row.status_company === 'Approve' ? (
            <Box sx={{
              background: "var(--tint-green, rgba(59, 181, 108, 0.25));",
              borderRadius: '300px',
              p: '2px 12px',
              // width: '100px',
              height: '24px'

            }}>
              <Stack direction={'row'} alignItems={'center'}>
                <Box sx={{ borderRadius: '50%', background: '#3BB56C', width: '10px', height: '10px', mr: 1 }}>
                </Box>
                <Typography className="w-500" sx={{ color: '#081130', fontSize: 14 }} >
                  {translate('status.active')}

                </Typography>
              </Stack>
            </Box>
          ) : (
            <></>
          )}
          {params.row.status_company === 'Dalam Review' ? (
            <Box sx={{
              background: "var(--tint-yellow, rgba(247, 206, 70, 0.30));",
              borderRadius: '300px',
              p: '2px 12px',
              // width: '80px',
              height: '24px'

            }}>
              <Stack direction={'row'} alignItems={'center'}>
                <Box sx={{ borderRadius: '50%', background: '#F7CE46', width: '10px', height: '10px', mr: 1 }}>
                </Box>
                <Typography className="w-500" sx={{ color: '#081130', fontSize: 14 }} >
                  {translate('status.pending')}
                </Typography>
              </Stack>
            </Box>
          ) : (
            <></>
          )}
          {params.row.status_company === 'Reject' ? (
            <Box sx={{
              background: "var(--tint-orange, rgba(255, 90, 82, 0.20));",
              borderRadius: '300px',
              p: '2px 12px',
              // width: '80px',
              height: '24px'

            }}>
              <Stack direction={'row'} alignItems={'center'}>
                <Box sx={{ borderRadius: '50%', background: '#F55C24', width: '10px', height: '10px', mr: 1 }}>
                </Box>
                <Typography className="w-500" sx={{ color: '#081130', fontSize: 14 }} >
                  {translate('status.reject')}
                </Typography>
              </Stack>
            </Box>
          ) : (
            <></>
          )}

        </Grid>


      ),
    },

    {
      headerClassName: 'last-theme--header', field: 'action', headerName: ' ', sortable: false, width: 120, renderCell: (params) =>

      (
        <Grid
          container
          justifyContent="end"
          alignItems="center"
          direction="row"
        >
          {userid.includes(params?.row?.company_user_id) ? <></>

            : <IconButton onClick={(e) => handleOpenShareMenu(e, params.row.company_id, params.row.company_phone_number, params.row)} size="small">
              <MoreHorizIcon sx={{ fontSize: 18, }} />

            </IconButton>}
          <ModalShare page={'mycompany'} type={4} id={product_id} data={dataperusahaan} user_trader_phone={user_trader_phone} anchorEl={anchorElShare} open={anchorElShare} onClose={handleCloseShareMenu}></ModalShare>
          <IconButton
            onClick={(e) => {
              GotoDetailCompany(params?.row.company_id, params?.row.company_name)
            }}
            size="small">
            <ArrowForwardIosIcon sx={{ fontSize: 18 }} />
          </IconButton>
        </Grid>
      ),

    },
  ];

  const columnsdetailtrader = [
    {
      headerClassName: 'super-app-theme--header', field: 'company_name', headerName: translate('page_explore.namaperusahaan'), width: 400, sortable: false, renderCell: (params) => (
        <Grid
          container
          justifyContent="left"
          alignItems="center"
          direction="row"
          sx={{ cursor: 'pointer' }}
          onClick={(e) => {
            GotoDetailCompany(params?.row.company_id, params?.row.company_name)
          }}
        >
          <Avatar src={`${config.baseURL + params?.row?.company_url_image}`} sx={{ bgcolor: '#1976d2', }} aria-label="recipe">
            <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
          </Avatar>
          <Stack direction="column">
            <Typography
              className="w-700"
              sx={{ fontSize: 14, ml: 1, }}
            >
              {(params?.row.company_name && params?.row.company_name.length > 25) ? capitalizeEachWord(params?.row?.company_name).slice(0, 25) + '...' : capitalizeEachWord(params?.row?.company_name)}
            </Typography>
            {params?.row?.type === 'join' ? (
              <>
                <Typography
                  className="w-500"
                  sx={{ fontSize: 14, ml: 1, color: '#56617B' }}
                >
                  {translate('status.bergabungpada')} {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(params?.row?.date_information))}
                </Typography>
              </>
            ) : (
              <></>
            )}
            {params?.row?.type === 'created' ? (
              <>
                {userid.includes(params?.row?.company_user_id) ? <Typography
                  className="w-500"
                  sx={{ fontSize: 14, ml: 1, color: '#56617B' }}
                >
                  {translate('status.dibuatanda')} {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(params?.row?.date_information))}
                </Typography> :
                  <Typography
                    className="w-500"
                    sx={{ fontSize: 14, ml: 1, color: '#56617B' }}
                  >

                    {translate('status.dibuatpada')}  {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(params?.row?.date_information))}
                  </Typography>}

              </>
            ) : (
              <></>
            )}
            {params?.row?.type === 'request' ? (
              <>
                <Typography
                  className="w-500"
                  sx={{ fontSize: 14, ml: 1, color: '#56617B' }}
                >
                  {translate('status.mengajukanpada')}  {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(params?.row?.date_information))}
                </Typography>
              </>
            ) : (
              <></>
            )}
          </Stack>
        </Grid>
      ),
    },
    {
      headerClassName: 'super-app-theme--header', field: 'company_phone_number', headerName: translate('page_explore.kontak'), width: 320, sortable: false, renderCell: (params) => (
        <Grid
          container
          justifyContent="left"
          alignItems="center"
          direction="row"
          sx={{ cursor: 'pointer' }}
          onClick={(e) => {
            GotoDetailCompany(params?.row.company_id, params?.row.company_name)
          }}
        >
          <Stack direction="column">
            <img alt="Nikel-icon" src={CallCompanyIcon} height={18}></img>
            <img alt="Nikel-icon" src={EmailCompanyIcon} height={18}></img>
          </Stack>
          <Stack direction="column">
            <Typography
              className="w-500"
              sx={{ fontSize: 14, ml: 1, color: '#56617B' }}
            >
              {params.row.company_phone_number === null ? '-' : params.row.company_phone_number}
            </Typography>
            <Typography
              className="w-500"
              sx={{ fontSize: 14, ml: 1, color: '#56617B' }}
            >
              {params.row.company_email}
            </Typography>
          </Stack>
        </Grid>
      ),
    },
    {
      headerClassName: 'super-app-theme--header', field: 'bidang_perusahaan', headerName: translate('page_explore.bidangusaha'), width: 170, sortable: false, renderCell: (params) =>
      (
        <Grid container sx={{ cursor: 'pointer', display: 'flex' }}
          onClick={(e) => { navigate(`/${capitalizeEachWord(convertText(params?.row.name))}`, { state: { detail: 'company', id: params?.row.company_id } }) }} justifyContent={'center'} alignItems={'center'}>
          <Grid
            container
            justifyContent="left"
            alignItems="center"
            direction="row"


          >
            {params.row.bidang_perusahaan === 1 ? <img alt="Nikel-icon" src={penambangIcon} height={18}></img> : ''}
            {params.row.bidang_perusahaan === 2 ? <img alt="Nikel-icon" src={traderIcon} height={18}></img> : ''}
            {params.row.bidang_perusahaan === 3 ? <img alt="Nikel-icon" src={pelayarIcon} height={18}></img> : ''}

            <Typography
              className="w-700"
              sx={{ fontSize: 14, ml: 1, }}
            >
              {params.row.bidang_perusahaan === 1 ? translate('page_explore.penambang') : ''}
              {params.row.bidang_perusahaan === 2 ? translate('page_explore.trader') : ''}
              {params.row.bidang_perusahaan === 3 ? translate('page_explore.pelayaran') : ''}
            </Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      headerClassName: 'super-app-theme--header', field: 'staff', headerName: 'Staff', width: 160, sortable: false, renderCell: (params) => (
        <Grid container sx={{ cursor: 'pointer', display: 'flex' }}
          onClick={(e) => {
            GotoDetailCompany(params?.row.company_id, params?.row.company_name)
          }} justifyContent={'center'} alignItems={'center'}>

          <Grid
            container
            justifyContent="left"
            alignItems="center"
            direction="row"


          >
            <Stack direction={'row'}>
              {params.row.all_staff.map((item, index) => {
                if (index < 4) return (
                  <>
                    {index === 0 ? (
                      <Avatar src={`${config.baseURL + item?.url_image_user}`} sx={{ bgcolor: '#1976d2', }} aria-label="recipe">
                        <PersonIcon></PersonIcon>
                      </Avatar>
                    ) : (
                      <Avatar src={`${config.baseURL + item?.url_image_user}`} sx={{ bgcolor: '#1976d2', ml: -2 }} aria-label="recipe">
                        <PersonIcon></PersonIcon>
                      </Avatar>
                    )}
                  </>
                )
              })}
              {(params.row.all_staff && params.row.all_staff.length > 4) ? <Avatar className="w-500" style={{ marginLeft: -10, fontSize: 16 }} aria-label="recipe">
                +{params.row.all_staff.length - 4}
              </Avatar> : '-'}
            </Stack>
          </Grid>
        </Grid>
      ),
    },
    {
      headerClassName: 'last-theme--header', field: 'status_staff', headerName: 'Status', width: 160, sortable: false, renderCell: (params) => (
        <Grid container sx={{ cursor: 'pointer' }}
          onClick={(e) => {
            GotoDetailCompany(params?.row.company_id, params?.row.company_name)
          }}>
          {params.row.status_company === 'Approve' ? (
            <Box sx={{
              background: "var(--tint-green, rgba(59, 181, 108, 0.25));",
              borderRadius: '300px',
              p: '2px 12px',
              height: '24px'
            }}>
              <Stack direction={'row'} alignItems={'center'}>
                <Box sx={{ borderRadius: '50%', background: '#3BB56C', width: '10px', height: '10px', mr: 1 }}>
                </Box>
                <Typography className="w-500" sx={{ color: '#081130', fontSize: 14 }} >
                  {translate('status.active')}
                </Typography>
              </Stack>
            </Box>
          ) : (
            <></>
          )}
          {params.row.status_company === 'Dalam Review' ? (
            <Box sx={{
              background: "var(--tint-yellow, rgba(247, 206, 70, 0.30));",
              borderRadius: '300px',
              p: '2px 12px',

              height: '24px'

            }}>
              <Stack direction={'row'} alignItems={'center'}>
                <Box sx={{ borderRadius: '50%', background: '#F7CE46', width: '10px', height: '10px', mr: 1 }}>
                </Box>
                <Typography className="w-500" sx={{ color: '#081130', fontSize: 14 }} >
                  {translate('status.pending')}
                </Typography>
              </Stack>
            </Box>
          ) : (
            <></>
          )}
          {params.row.status_company === 'Reject' ? (
            <Box sx={{
              background: "var(--tint-orange, rgba(255, 90, 82, 0.20));",
              borderRadius: '300px',
              p: '2px 12px',

              height: '24px'

            }}>
              <Stack direction={'row'} alignItems={'center'}>
                <Box sx={{ borderRadius: '50%', background: '#F55C24', width: '10px', height: '10px', mr: 1 }}>
                </Box>
                <Typography className="w-500" sx={{ color: '#081130', fontSize: 14 }} >
                  {translate('status.reject')}
                </Typography>
              </Stack>
            </Box>
          ) : (
            <></>
          )}

        </Grid>
      ),
    },
    {
      headerClassName: 'last-theme--header', field: 'action', headerName: ' ', sortable: false, width: 120, renderCell: (params) =>
      (
        <Grid
          container
          justifyContent="end"
          alignItems="center"
          direction="row"
        >
          <IconButton onClick={(e) => handleOpenShareMenu(e, params.row.company_id, params.row.company_phone_number, params.row)} size="small">
            <MoreHorizIcon sx={{ fontSize: 18, }} />

          </IconButton>
          <ModalShare page={'company'} type={4} id={product_id} data={dataperusahaan} user_trader_phone={user_trader_phone} anchorEl={anchorElShare} open={anchorElShare} onClose={handleCloseShareMenu}></ModalShare>
          <IconButton onClick={(e) => {
            GotoDetailCompany(params?.row.company_id, params?.row.company_name)
          }} size="small">
            <ArrowForwardIosIcon sx={{ fontSize: 18 }} />
          </IconButton>
        </Grid>
      ),
    },
  ];

  useEffect(() => {
    getProduct();
  }, []);

  async function getProduct() {
    try {
      const response = await searchCompany({
        page: 1, min_total_listing: '', max_total_listing: '',
        min_total_staff: '', max_total_staff: '', bidang_usaha: "", id_location: "", search_text: ""
      });
      setProductList(response.data.data.data.data);
    } catch (e) {
      console.log(e);
    }
  }
  return (
    page === 'explore'
      ?
      <Hidden lgDown>
        <div className="ex-ui">
          <DataGrid
            autoHeight
            sx={{
              borderRadius: '8px',
              '& .super-app-theme--header': {
                borderRight: 1,
                borderColor: '#E8EBF2',
                fontFamily: 'Satoshi-500 !important',
                color: '#081130 !important',
                outline: "none !important"
              },
              '& .last-theme--header': {

                borderColor: '#E8EBF2',
                fontFamily: 'Satoshi-500 !important',
                color: '#081130 !important',
                outline: "none !important",
                justifyContent: 'start'
              },
              '.MuiDataGrid-iconButtonContainer': {
                visibility: 'visible',
              },
              '.MuiDataGrid-sortIcon': {
                opacity: 'inherit !important',
              },
              '.MuiDataGrid-columnSeparator': {
                display: 'none',
              },
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
            }}
            rows={props.companyList ? props.companyList : productList}
            sortingOrder={['desc', 'asc']}
            disableColumnMenu
            disableSelectionOnClick={true}
            disableRowSelectionOnClick
            components={{
              ColumnSortedAscendingIcon: UnfoldMoreIcon,
              ColumnSortedDescendingIcon: UnfoldMoreIcon,
            }}
            getRowId={(row) => row.company_id}
            columns={columns}
          />
        </div>

      </Hidden>
      : page === 'detailprofile'
        ?
        <Hidden smDown>
          <div className="ex-ui" style={{ height: `${props?.data?.data?.length === 0 ? '400px' : 'auto'}` }}>
            <DataGrid
              height={props?.data?.data?.length === 0 ? '300px' : 'auto'}
              sx={{
                borderRadius: '8px',
                '& .super-app-theme--header': {
                  borderRight: 1,
                  borderColor: '#E8EBF2',
                  fontFamily: 'Satoshi-500 !important',
                  color: '#081130 !important',
                  outline: "none !important"
                },
                '& .last-theme--header': {

                  borderColor: '#E8EBF2',
                  fontFamily: 'Satoshi-500 !important',
                  color: '#081130 !important',
                  outline: "none !important",
                  justifyContent: 'start'
                },
                '.MuiDataGrid-iconButtonContainer': {
                  visibility: 'visible',
                },
                '.MuiDataGrid-sortIcon': {
                  opacity: 'inherit !important',
                },
                '.MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                  outline: "none !important",
                },
              }}
              rows={props.data.data}
              sortingOrder={['desc', 'asc']}
              disableColumnMenu
              disableSelectionOnClick
              components={{
                ColumnSortedAscendingIcon: UnfoldMoreIcon,
                ColumnSortedDescendingIcon: UnfoldMoreIcon,
                NoRowsOverlay: () => (
                  <Stack alignItems="center" sx={{ pt: 4 }}>
                    <img alt="profile-iconbelumadaperusahaan" src={iconbelumadaperusahaan} height={120} width={132}></img>
                    <Typography className="w-700" sx={{ color: '#081130', fontSize: 18, mt: 1, }}>
                      {translate('status.belumadaperusahaan')}

                    </Typography>
                    <Typography textAlign='center' width='100%' className="w-500" sx={{ color: '#56617B', fontSize: { xs: 12, md: 14 }, mt: 1, }}>
                      {translate('status.subemptycompany')}
                    </Typography>
                    <Stack direction='row' spacing={2} sx={{ mt: 4, mb: 6 }}>
                      <Button
                        href='/buat-perusahaan'
                        className="w-500 button-blue-outlined"
                        variant="outlined" sx={{ width: '166px', p: '10px 12px 10px 12px', height: '40px', textTransform: 'capitalize' }}>
                        <Typography className="w-500" sx={{ fontSize: 14 }}>
                          {translate('status.buatperusahaan')}
                        </Typography></Button>
                      <Button className="w-500 button-blue-outlined" variant="outlined" sx={{ width: '166px', p: '10px 12px 10px 12px', height: '40px', }}
                        onClick={() => { navigate(`/explore`, { state: { detail: 'company' } }) }}
                      >
                        <Typography className="w-500" sx={{ fontSize: 14 }}>
                          {translate('status.gabungperusahaan')}
                        </Typography></Button></Stack>
                  </Stack>
                ),
              }}
              getRowId={(row) => row.company_id}
              columns={columnprofile}
            />
          </div>
        </Hidden>
        :
        <Hidden smDown>
          <div className="ex-ui">
            <DataGrid
              autoHeight
              sx={{
                borderRadius: '8px',
                '& .super-app-theme--header': {
                  borderRight: 1,
                  borderColor: '#E8EBF2',
                  fontFamily: 'Satoshi-500 !important',
                  color: '#081130 !important',
                  outline: "none !important"
                },
                '& .last-theme--header': {

                  borderColor: '#E8EBF2',
                  fontFamily: 'Satoshi-500 !important',
                  color: '#081130 !important',
                  outline: "none !important",
                  justifyContent: 'start'
                },
                '.MuiDataGrid-iconButtonContainer': {
                  visibility: 'visible',
                },
                '.MuiDataGrid-sortIcon': {
                  opacity: 'inherit !important',
                },
                '.MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                  outline: "none !important",
                },
              }}
              rows={props.data.data}
              sortingOrder={['desc', 'asc']}
              disableColumnMenu
              disableSelectionOnClick={true}
              disableRowSelectionOnClick
              components={{
                ColumnSortedAscendingIcon: UnfoldMoreIcon,
                ColumnSortedDescendingIcon: UnfoldMoreIcon
              }}
              getRowId={(row) => row.company_id}
              columns={columnsdetailtrader}
            />
          </div>
        </Hidden>
  )
}
