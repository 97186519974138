import { CameraAlt } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Avatar, Box, Button, Card, CircularProgress, Divider, FormControlLabel, Grid, Hidden, InputAdornment, ListItemText, Menu, MenuItem, Stack, Switch, TextField, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { default as CallCompanyIcon } from "../assets/call-company-ic.png";
import companyIcon from "../assets/company-ic.png";
import defaultbannericon from "../assets/default-img-profile.png";
import EditProfileIcon from "../assets/editprofile-ic.png";
import favorite_ic from "../assets/favorite_ic.png";
import filtericon from "../assets/filter-ic.png";
import locationCompanyIcon from "../assets/location-company-ic.png";
import { default as MailCompanyIcon } from "../assets/mail-company-ic.png";
import person from "../assets/person-inactive-ic.png";
import searchIcon from "../assets/search-explore-ic.png";
import trash from "../assets/trash.png";
import unggahIcon from "../assets/unggah-ic.png";
import unggah from "../assets/unggah.png";
import CardCompanyDetail from "../components/CardCompanyDetail";
import CardListCoalExplore from "../components/CardListCoalExplore";
import CardListNikelExplore from "../components/CardListNikelExplore";
import CardTongkangListExplore from "../components/CardTongkangListExplore";
import { DisplayPDFFile } from "../components/DisplayPDF";
import DrawerFilterCoal from "../components/DrawerFilterCoal";
import DrawerFilterLocation from "../components/DrawerFilterLocation";
import DrawerFilterNikel from "../components/DrawerFilterNikel";
import DrawerFilterTongkang from "../components/DrawerFilterTongkang";
import DrawerUploadDoc from "../components/DrawerUploadDocProfile";
import Footer from "../components/Footer";
import MenuFilterCoal from "../components/MenuFilterCoal";
import MenuFilterNikel from "../components/MenuFilterNikel";
import MenuFilterTongkang from "../components/MenuFilterTongkang";
import ModalUploadKredi from "../components/ModalUnggahDocKredibilitas";
import NavBar from "../components/Navbar";
import SearchBarExplore from "../components/SearchbarExplore";
import TabelCoal from "../components/TableCoal";
import TabelCompany from "../components/TableCompany";
import TabelNikel from "../components/TableNikel";
import { deleteUserBackground, editUser, getDetailTrader, getProvinceFilter, getUserCompany, getUserDocument, searchFavoriteBarge, searchFavoriteCoal, searchFavoriteNickel } from "../services/api";
import { config } from "../services/constants";

function ProfilePage() {
  const navigate = useNavigate();
  const [limitProvince, setLimitProvince] = useState(5)
  const [showAllCoal, setShowAllCoal] = useState(false)
  const [showAllNikel, setShowAllNikel] = useState(false)
  const [dataProvince, setDataProvince] = useState([]);
  const [dataUser, setDataUser] = useState([]);
  const [dataUserProfile, setDataUserProfile] = useState([]);
  const [dataUserCompany, setDataUserCompany] = useState([]);
  const [dataUserCompanyPage, setDataUserCompanyPage] = useState(1);
  const [dataUserCompanyTotalPage, setDataUserCompanyTotalPage] = useState();
  const [dataUserDocument, setDataUserDocument] = useState([]);
  const [dataUserDocumentPage, setDataUserDocumentPage] = useState(1);
  const [dataUserDocumentTotalPage, setDataUserDocumentTotalPage] = useState();
  const [translate,] = useTranslation("global");
  const [profileImg, setProfileImg] = useState("")
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [coalFavoriteList, setCoalFavoriteList] = useState([]);
  const [nickelFavoriteList, setNickelFavoriteList] = useState([]);
  const [tongkangFavoriteList, setTongkangFavoriteList] = useState([]);
  const [tongkangFavoriteList_, setTongkangFavoriteList_] = useState([]);
  const [locationId, setLocationId] = useState([])
  const location = useLocation();
  const [modalCoalFilter, setmodalCoalFilter] = React.useState(null)
  const [modalCoalLocation, setmodalCoalLocation] = React.useState(null)
  const [modalNikelFilter, setmodalNikelFilter] = React.useState(null)
  const [modalNikelLocation, setmodalNikelLocation] = React.useState(null)
  const [modalTongkangFilter, setmodalTongkangFilter] = React.useState(null)
  const [searchValue, setSearchValue] = useState('')
  const [filterDocuments, setFilterDocuments] = useState('');
  const [isLoadingDocument, setIsLoadingDocument] = useState(false);
  const [isLoadingDocumentMore, setIsLoadingDocumentMore] = useState(false);
  const [selectedItems, setSelectedItems] = useState([])

  const [anchorElAddCompany, setanchorElAddCompany] = React.useState(null);
  const [dataProvinceNikel, setDataProvinceNikel] = useState([]);
  const [searchValueLocation, setSearchValueLocation] = useState('')
  const [requestHideEmail, setrequestHideEmail] = useState(false);
  const [requestHidePhone, setrequestHidePhone] = useState(false);

  const [filterBatubara, setFilterBatubara] = useState({
    page: 1,
    user_id: dataUser.id,
    min_calorie: "", max_calorie: "", min_total_sulfur: "", max_total_sulfur: "", min_ash: "",
    max_ash: "", quantity: "", min_price: "", max_price: "", id_location: [], search_text: `${searchValue}`
  });

  const [filterNikel, setFilterNikel] = useState({
    page: 1, user_id: dataUser.id,
    min_kadar_ni: "", max_kadar_ni: "", min_kadar_air: "", max_kadar_air: "", min_kadar_fe: "", max_kadar_fe: "",
    min_si_mg_ratio: "", max_si_mg_ratio: "", quantity: "", min_price: "", max_price: "", id_location: [], search_text: `${searchValue}`,
  })
  const [filterTongkang, setFilterTongkang] = useState({
    page: 1, user_id: dataUser.id,
    type_tongkang: "", ukuran_tongkang: "", max_quantity_muatan: "", min_quantity_muatan: "", max_price: "", min_price: "",
    bendera: "", tahun_buat: "", search_text: `${searchValue}`,
    label: '',
  })

  const handleKeyDown = (event, value) => {
    if (event.key === 'Enter') {
      filterBatubara["page"] = 1
      filterNikel["page"] = 1
      filterTongkang["page"] = 1
      setSearchValue(value)
      event.target.blur();
      getCoalList(filterBatubara, value);
      getNickelList(filterNikel, value);
      getTongkangList(filterTongkang, value);
    }
  };

  const handleKeyDownLocation = (event, e) => {
    if (event.key === 'Enter') {
      event.target.blur();
      if (e === "coal") {
        getDataProvinceCoal(searchValueLocation);
      } else if (e === "nikel") {
        getDataProvinceNikel(searchValueLocation)
      }
    }
  };

  const handleSearchValue = (e) => {
    if (e === '') {
      filterBatubara["page"] = 1
      filterNikel["page"] = 1
      filterTongkang["page"] = 1
      getCoalList(filterBatubara, '');
      getNickelList(filterNikel, '');
      getTongkangList(filterTongkang, '');
      setSearchValue('')
    } else {
      setSearchValue(e)
    }
  };

  const handleSearchValueLocation = (e) => {
    if (e === '') {
      getDataProvinceCoal('');
      getDataProvinceNikel('')
      setSearchValueLocation('')
    } else {
      setSearchValueLocation(e)
    }
  };

  const handleLocationReset = (e) => {
    if (e === "coal") {
      setSearchValueLocation('')
      setSelectedItems([])
      filterBatubara["id_location"] = []
      setLocationId([])
      setFilterBatubara({ ...filterBatubara })
      getCoalList(filterBatubara)
      getDataProvinceCoal('')

    } else if (e === "nikel") {
      setSearchValueLocation('')
      setSelectedItems([])
      setLocationId([])
      filterNikel["id_location"] = []
      setFilterNikel({ ...filterNikel })
      getNickelList(filterNikel)
      getDataProvinceNikel('')
    }
  }

  function Filter(e) {
    if (e === 'coal') {
      setFilterBatubara({ ...filterBatubara })
      getCoalList(filterBatubara)
    } else if (e === 'nikel') {
      setFilterNikel({ ...filterNikel })
      getNickelList(filterNikel)
    } else if (e === 'tongkang') {
      setFilterTongkang({ ...filterTongkang })
      getTongkangList(filterTongkang)
    }
  }

  const handleLocationSumbit = (e) => {
    if (e === "coal") {
      getCoalList(filterBatubara)
      setLimitProvince(5)
      setShowAllCoal(false)
    } else if (e === "nikel") {
      getNickelList(filterNikel)
      setLimitProvince(5)
      setShowAllNikel(false)
    }
  }

  useEffect(() => {
    getDataUser();
    getDataProvinceCoal(searchValueLocation)
    getDataProvinceNikel(searchValueLocation)
    getCoalList(filterBatubara, '');
    getNickelList(filterNikel, '');
    getTongkangList(filterTongkang, '');
  }, []);

  useEffect(() => {
    getDataUser();
  }, [localStorage.getItem('lang')]);

  async function getDataUser(event) {
    try {
      let lang = localStorage.getItem('lang') === 'null' || localStorage.getItem('lang') === 'id' ? 'id' : localStorage.getItem('lang')
      const response = await getDetailTrader(localStorage.getItem("id_user"), lang)
      const dataUserCompany = await getUserCompany({ id_user: response.data.data.id, page: 1 })
      setIsLoadingDocument(true)
      const dataUserDocuments = await getUserDocument({ id_user: response.data.data.id, search_text: filterDocuments, page: 1 })
      setIsLoadingDocument(false)
      setDataUserDocument(dataUserDocuments.data.data.data)
      setDataUserDocumentPage(dataUserDocuments.data.data.currentPage)
      setDataUserDocumentTotalPage(dataUserDocuments.data.data.pageCount)
      setDataUserCompany(dataUserCompany.data.data.data)
      setDataUserCompanyPage(dataUserCompany.data.data.currentPage)
      setDataUserCompanyTotalPage(dataUserCompany.data.data.pageCount)
      setDataUser(response.data.data ? response.data.data : "");
      setrequestHideEmail(response.data.data.show_email)
      setrequestHidePhone(response.data.data.show_phone)
      setDataUserProfile(response.data.data)
      setProfileImg(response.data.data.picture_path.url)
    } catch (e) {
      console.log(e);
    }
  }

  async function getCoalList(event, searchValueQ) {
    try {
      if (searchValueQ !== undefined) {
        event["search_text"] = searchValueQ
      } else {
        event["search_text"] = searchValue
      }
      const response = await searchFavoriteCoal(event)
      setCoalFavoriteList(response.data?.data);
    } catch (e) {
      console.log(e);
    }
  }

  async function getNickelList(event, searchValueQ) {
    try {
      if (searchValueQ !== undefined) {
        event["search_text"] = searchValueQ
      } else {
        event["search_text"] = searchValue
      }
      const response = await searchFavoriteNickel(event)
      setNickelFavoriteList(response.data?.data);
    } catch (e) {
      console.log(e);
    }
  }

  async function getTongkangList(event, searchValueQ) {
    try {
      if (searchValueQ !== undefined) {
        event["search_text"] = searchValueQ
      } else {
        event["search_text"] = searchValue
      }
      const response = await searchFavoriteBarge(event)
      setTongkangFavoriteList(response.data?.data.data);
      setTongkangFavoriteList_(response.data?.data);
    } catch (e) {
      console.log(e);
    }
  }

  async function getDataProvinceCoal(name) {
    try {
      const response = await getProvinceFilter(name === undefined ? '' : name, 'order_batubara:asc')
      setDataProvince(response.data?.data);
    } catch (e) {
      console.log(e);
    }
  }

  async function getDataProvinceNikel(name) {
    try {
      const response = await getProvinceFilter(name === undefined ? '' : name, 'order_nikel:asc')
      setDataProvinceNikel(response.data?.data);
    } catch (e) {
      console.log(e);
    }
  }

  async function deleteBackground() {
    try {
      const response = await deleteUserBackground()
      setTimeout(() => {
        getDataUser()
        handleCloseUserMenu()
      }, 400);
    } catch (e) {
      console.log(e);
    }
  }

  const handleOpenModalFilter = (state) => {
    if (state === 'coal') {
      setmodalCoalFilter(state);
    } else if (state === 'nikel') {
      setmodalNikelFilter(state)
    } else if (state === 'tongkang') {
      setmodalTongkangFilter(state)
    }
  };


  const handleCloseModalFilter = () => {
    setmodalCoalFilter(null);
    setmodalNikelFilter(null);
    setmodalTongkangFilter(null);
  };

  const handleOpenModalLocationResponsive = (state) => {
    if (state === 'coal') {
      setmodalCoalLocation(state);
    } else if (state === 'nikel') {
      setmodalNikelLocation(state)
    }

  };
  const handleCloseModalLocationResponsive = () => {
    setmodalCoalLocation(null);
    setmodalNikelLocation(null);

  };

  const handleFilterBatubara = (e, y, option) => {
    if (y === "location") {
      let isSelected = e.target.checked;
      let value = parseInt(e.target.value);
      if (isSelected) {
        setSelectedItems([...selectedItems, value])
      } else {
        setSelectedItems((prevData) => {
          return prevData.filter((id) => {
            return id !== value
          })
        })
      }
      const check = [...locationId]
      const index = check.indexOf(e.target.value);
      if (index === -1) {
        check.push(e.target.value)
      } else {
        check.splice(index, 1)
      }
      setLocationId(check)
      filterBatubara["id_location"] = check
      setFilterBatubara({ ...filterBatubara })
      getCoalList(filterBatubara)
    } else if (y === "popuplocation") {
      let isSelected = e.target.checked;
      let value = parseInt(e.target.value);
      if (isSelected) {
        setSelectedItems([...selectedItems, value])
      } else {
        setSelectedItems((prevData) => {
          return prevData.filter((id) => {
            return id !== value
          })
        })
      }

      const check = [...locationId]
      const index = check.indexOf(e.target.value);
      if (index === -1) {
        check.push(e.target.value)
      } else {
        check.splice(index, 1)
      }
      setLocationId(check)
      filterBatubara["id_location"] = check
      setFilterBatubara({ ...filterBatubara })
    } else if (y === 'mobile') {
      filterBatubara[option] = e
      setFilterBatubara({ ...filterBatubara })

    } else {
      filterBatubara[y] = e
      setFilterBatubara({ ...filterBatubara })
      getCoalList(filterBatubara)
    }

  }

  const handleFilterNikel = (e, y, option) => {
    if (y === "location") {
      let isSelected = e.target.checked;
      let value = parseInt(e.target.value);
      if (isSelected) {
        setSelectedItems([...selectedItems, value])
      } else {
        setSelectedItems((prevData) => {
          return prevData.filter((id) => {
            return id !== value
          })
        })
      }
      const check = [...locationId]
      const index = check.indexOf(e.target.value);
      if (index === -1) {
        check.push(e.target.value)
      } else {
        check.splice(index, 1)
      }
      setLocationId(check)
      filterNikel["id_location"] = check
      setFilterNikel({ ...filterNikel })
      getNickelList(filterNikel)
    } else if (y === "popuplocation") {
      let isSelected = e.target.checked;
      let value = parseInt(e.target.value);
      if (isSelected) {
        setSelectedItems([...selectedItems, value])
      } else {
        setSelectedItems((prevData) => {
          return prevData.filter((id) => {
            return id !== value
          })
        })
      }

      const check = [...locationId]
      const index = check.indexOf(e.target.value);
      if (index === -1) {
        check.push(e.target.value)
      } else {
        check.splice(index, 1)
      }
      setLocationId(check)
      filterNikel["id_location"] = check
      setFilterNikel({ ...filterNikel })
    } else if (y === 'mobile') {
      filterNikel[option] = e
      setFilterNikel({ ...filterNikel })

    } else {
      filterNikel[y] = e
      setFilterNikel({ ...filterNikel })
      getNickelList(filterNikel)
    }

  }

  const handleFilterTongkang = (e, y, option) => {

    if (e !== 0) {
      setinputukurantongkang(false)
      if (option === 'mobile') {
        filterTongkang[y] = e
        setFilterTongkang({ ...filterTongkang })
      } else {
        filterTongkang[y] = e
        setFilterTongkang({ ...filterTongkang })
        getTongkangList(filterTongkang)
      }
    }
    else {
      setinputukurantongkang(true)
    }
  }

  // Reset Filter 
  function resetFilterBatubara(e) {

    const default_value = {
      page: 1,
      user_id: dataUser.id,
      min_calorie: "", max_calorie: "", min_total_sulfur: "", max_total_sulfur: "", min_ash: "",
      max_ash: "", quantity: "", min_price: "", max_price: "", id_location: "", search_text: ""
    }
    setFilterBatubara(default_value)
    setSearchValueLocation('')
    setSelectedItems([])
    setLocationId([])
    getCoalList(default_value)
  }

  function resetFilterNikel(e) {
    const default_value = {
      page: 1,
      user_id: dataUser.id, min_kadar_ni: "", max_kadar_ni: "", min_kadar_air: "", max_kadar_air: "", min_kadar_fe: "", max_kadar_fe: "",
      min_si_mg_ratio: "", max_si_mg_ratio: "", quantity: "", min_price: "", max_price: "", id_location: "", search_text: ""
    }
    setFilterNikel(default_value)
    setSearchValueLocation('')
    setSelectedItems([])
    setLocationId([])
    getNickelList(default_value)
  }

  function resetFilterTongkang(e) {
    const default_value = {
      page: 1,
      user_id: dataUser.id, type_tongkang: "", ukuran_tongkang: "", max_quantity_muatan: "", min_quantity_muatan: "", max_price: "", min_price: "",
      bendera: "", tahun_buat: "", search_text: ""
    }
    setFilterTongkang(default_value)
    getTongkangList(default_value)
  }

  const [activeTabTrader, setActiveTabTrader] = useState(location.state?.detail === undefined ? "profile" : location.state?.detail);;
  const [activeTabFavorit, setActiveTabFavorit] = useState('coal');

  const handleTabFavorit = (state) => {
    setActiveTabFavorit(state);
  };

  const handleTabTrader = (state) => {
    setActiveTabTrader(state);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  async function setFavoriteBatubara() {
    setTimeout(() => {
      getCoalList(filterBatubara)
    }, 150);
  }

  async function setFavoriteNikel() {
    setTimeout(() => {
      getNickelList(filterNikel)
    }, 150);
  }

  async function setFavoriteTongkang() {
    setTimeout(() => {
      getTongkangList(filterTongkang)
    }, 150);
  }

  const handleKeyDownDocuments = async (event) => {
    if (event.key === 'Enter') {
      let id_user = localStorage.getItem("id_user")
      const dataUserDocuments = await getUserDocument({ id_user: id_user, search_text: filterDocuments, page: 1 })
      setDataUserDocument(dataUserDocuments.data.data.data)
      setDataUserDocumentPage(dataUserDocuments.data.data.currentPage)
      setDataUserDocumentTotalPage(dataUserDocuments.data.data.pageCount)
    }
  };

  const getDataDocuments = async (page) => {
    if (page === 1) {
      setIsLoadingDocument(true)
    } else {
      setIsLoadingDocumentMore(true)
    }
    let id_user = localStorage.getItem("id_user")
    const dataUserDocuments = await getUserDocument({ id_user: id_user, search_text: filterDocuments, page: page })
    if (page === 1) {
      setDataUserDocument(dataUserDocuments.data.data.data)
    } else {
      setDataUserDocument(dataUserDocument.concat(dataUserDocuments.data.data.data))
    }
    setDataUserDocumentPage(dataUserDocuments.data.data.currentPage)
    setDataUserDocumentTotalPage(dataUserDocuments.data.data.pageCount)
    setIsLoadingDocument(false)
    setIsLoadingDocumentMore(false)
  }

  const [isLoadingCompany, setIsLoadingCompany] = useState(false)

  const getDataCompany = async (page) => {
    setIsLoadingCompany(true)
    let id_user = localStorage.getItem("id_user")
    const dataUserCompanies = await getUserCompany({ id_user: id_user, page: page })
    if (page === 1) {
      setDataUserCompany(dataUserCompanies.data.data.data)
    } else {
      setDataUserCompany(dataUserCompany.concat(dataUserCompanies.data.data.data))
    }
    setDataUserCompanyPage(dataUserCompanies.data.data.currentPage)
    setDataUserCompanyTotalPage(dataUserCompanies.data.data.pageCount)
    setIsLoadingCompany(false)
  }

  const handleSearchValueDocuments = async (e) => {
    if (e === '') {
      setIsLoadingDocument(true)
      setFilterDocuments('')
      let id_user = localStorage.getItem("id_user")
      const dataUserDocuments = await getUserDocument({ id_user: id_user, search_text: '', page: 1 })
      setDataUserDocument(dataUserDocuments.data.data.data)
      setDataUserDocumentPage(dataUserDocuments.data.data.currentPage)
      setDataUserDocumentTotalPage(dataUserDocuments.data.data.pageCount)
      setIsLoadingDocument(false)
    } else {
      setFilterDocuments(e)
    }
  };

  function uploadImage(event) {
    const file = event.target.files[0]
    let formData = new FormData();
    formData.append('files', file);
    formData.append('ref', "plugin::users-permissions.user");
    formData.append('refId', dataUser.id);
    formData.append('field', 'background_picture_path');
    formData.append('source', 'users-permissions');
    axios.post(`${config.baseURL}/api/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      }
    }).then((res) => {
      getDataUser()
      handleCloseUserMenu()
    }).catch((err) => {
      console.log(err);
    })
  }

  function slicePhoneNumber(e) {
    let field = ""
    if (e !== undefined && e !== null) {
      const test = e
      const b = (test.length - 3) / 3
      field = test.slice(0, 3) + " " + test.slice(3, 3 + Math.floor(b)) + " " + test.slice((3 + Math.floor(b)), (3 + Math.floor(b) + Math.ceil(b))) + " " + test.slice((3 + Math.floor(b) + Math.ceil(b)), test.length)
    }
    return field
  }

  const styles_in = {
    input: {
      height: '44px',
      fontSize: '14px', fontFamily: 'Satoshi-400',
      borderRadius: '8px',


    },
  };

  const newNickelList = nickelFavoriteList.data
  const [isOpenUploadDoc, setOpenUploadDoc] = React.useState(false)

  const handleOpenModal = (state) => {
    if (state === 'uploaddoc') {
      setOpenUploadDoc(true)
    }
  };

  const handleCloseModal = (params) => {
    if (params === 'submit') {
      getDataDocuments(1)
    }
    setOpenUploadDoc(false);
  };

  const handleCloseAddCompany = () => {
    setanchorElAddCompany(null);
  };
  const handleOpenAddCompany = (event) => {
    setanchorElAddCompany(event.currentTarget);
  };

  const handleFilterSewaTongkang = (e, y) => {
    filterSewaTongkang[y] = e
    setFilterSewaTongkang({ ...filterSewaTongkang })
  }

  const [filterSewaTongkang, setFilterSewaTongkang] = useState({
    page: 1,
    tipe_sewa: location.state?.request !== undefined && location.state?.detail === 'tongkang' && (Number(location.state?.request_data?.type_tongkang) === 2 || Number(location.state?.request_data?.type_tongkang) === 3) ? (Number(location.state?.request_data?.type_tongkang) === 2 ? 'time' : 'freight') : '',
    ukuran_tongkang: '', max_quantity_muatan: '', min_quantity_muatan: '', max_price: '', min_price: '',
    bendera: "", search_text: location.state?.search === undefined ? "" : location.state?.search, pelabuhan_muat: "", pelabuhan_bongkar: ""
  })

  const [inputukurangtongkang, setinputukurantongkang] = React.useState(false)
  const handleFilterUkuranTongkang = (e, y, option) => {

    if (option === 'mobile') {
      filterTongkang[y] = e
      setFilterTongkang({ ...filterTongkang })
    } else {
      filterTongkang[y] = e
      setFilterTongkang({ ...filterTongkang })
      getTongkangList(filterTongkang)
    }
  }

  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  async function submitProfile(type, value) {

    if (type === 'email') {
      setrequestHideEmail(value)
      const data = {
        show_email: value,
      }
      const res = await editUser(dataUser.id, data)

      if (res.status === 200) {

        getDataUser()
        Swal.fire({
          icon: 'success',
          title: translate('status.success'),
          text: value ? translate('title.successshowemail') : translate('title.successhiddenemail'),
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: translate('status.failed'),
          text: translate('status.subtitlefailed'),
        })
      }
    } else {
      setrequestHidePhone(value)
      const data = {

        show_phone: value,
      }

      const res = await editUser(dataUser.id, data)
      if (res.status === 200) {

        getDataUser()
        Swal.fire({
          icon: 'success',
          title: translate('status.success'),
          text: value ? translate('title.successshowphone') : translate('title.successhiddenphone'),
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: translate('status.failed'),
          text: translate('status.subtitlefailed'),
        })
      }
    }

  }

  return (
    <Box>
      <Box className='fixed-header'>
        <NavBar></NavBar></Box>
      <Box className="flex-wrap-center width-100" >
        <Box className="content-inner" sx={{ mt: { xs: 10, sm: 10, md: 12, lg: 16, xl: 12 }, ml: { xs: '24px', }, mr: { xs: '24px', }, }}>
          <Grid container spacing={0} sx={{ pb: '20px' }}>
            <Grid item xs={12} sx={{ marginBottom: 0 }}>
              {/* Header Section */}
              <Box
                sx={{
                  overflow: "hidden",
                  border: "1px solid #EAEAEA",
                  borderRadius: "10px",
                  mb: 2,
                }}
              >
                {/* Background Cover Section */}
                <Box
                  sx={{
                    position: "relative",
                  }}
                >
                  {dataUser.background_picture_path?.url === undefined ? (
                    <Box
                      sx={{
                        width: "100%",
                        height: { xs: 120, sm: 120, md: 200, lg: 350 },
                        objectFit: "cover",
                        backgroundColor: "#F4F6FA",
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Hidden mdDown><Stack
                        spacing={2}
                        sx={{
                          width: "auto",
                          height: "200px",
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}>
                        <Avatar
                          alt="Avatar"
                          variant="circular"
                          src={defaultbannericon}
                          sx={{ width: 100, height: 100 }}
                        />
                        <Typography
                          className="w-700"
                          sx={{ fontSize: 18, color: '#424242' }}
                        > {translate('status.imageempty')}
                        </Typography>
                      </Stack></Hidden>
                    </Box>
                  ) : (
                    <Box
                      component="img"
                      src={config.baseURL + dataUser.background_picture_path?.url}
                      sx={{
                        width: "100%",
                        height: { xs: 120, sm: 120, md: 200, lg: 350 },
                        objectFit: "cover"
                      }}
                    />
                  )}


                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{
                      position: "absolute",
                      bottom: -50,
                      left: 15,
                      width: 'auto',
                      height: 100,
                    }}
                  >
                    <Avatar
                      alt="Avatar"
                      variant="circular"
                      src={profileImg === undefined || profileImg.length === 0 ? '' : config.baseURL + profileImg}
                      sx={{ width: { xs: 90, lg: 160 }, height: { xs: 90, lg: 160 }, border: '4px solid white', bgcolor: 'blue', }}

                    />
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "4%",
                      right: "2%",
                    }}
                  >

                    <Button
                      size="medium"
                      variant="contained" onClick={handleOpenUserMenu}
                      sx={{ backgroundColor: '#FFFFFF', p: '10px', minWidth: '36px', height: '36px', textTransform: 'none', borderRadius: '8px' }}
                    >
                      <CameraAlt sx={{ color: '#8792AB', width: '16px', height: '16px' }} />

                    </Button>
                    <Menu sx={{ mt: '45px', zIndex: 9, transition: "0.3s" }}
                      id="menu-appbar" anchorEl={anchorElUser}
                      anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                      keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right', }}
                      open={Boolean(anchorElUser)} onClose={handleCloseUserMenu}>
                      <MenuItem>
                        <ListItemText>    <Typography className="w-500" sx={{ fontSize: '12px' }} textAlign="center">Recommended ( 1300 x 350px )</Typography></ListItemText>
                      </MenuItem>
                      <MenuItem sx={{
                        pb: .6, pt: .6, "&:hover": {
                          backgroundColor: "#F4F6FA !important", borderRadius: "0px !important"
                        }
                      }} style={{ backgroundColor: "white", borderBottom: "1px solid #E8EBF2", borderRadius: "5px 5px 0px 0px" }}>
                        <label for="upload">
                          <Stack direction={'row'} alignItems={'center'}>
                            <img alt="img-icon" src={unggah} height={14} width={14}></img>
                            <Typography className="w-500" sx={{ fontSize: '14px', ml: "4px" }} textAlign="center">{translate('status.unggahfoto')}</Typography>
                          </Stack>
                        </label>
                      </MenuItem>

                      <MenuItem sx={{
                        pb: .6, pt: .6, "&:hover": {
                          backgroundColor: "#F4F6FA !important", borderRadius: "0px !important"
                        }
                      }} style={{ backgroundColor: "white", borderRadius: "0px 0px 5px 5px" }} onClick={(e) => deleteBackground()}>
                        <Stack direction={'row'} alignItems={'center'}>
                          <img alt="img-icon" src={trash} height={14} width={14}></img>
                          <Typography className="w-500" sx={{ fontSize: '14px', ml: 0.2 }} textAlign="center">{translate('status.hapusfoto')}</Typography>
                        </Stack>
                      </MenuItem>

                    </Menu>

                    <input type="file" id="upload" style={{ display: "none" }} accept="image/png, image/gif, image/jpeg" onChange={(e) => uploadImage(e)} />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "16px",
                    marginRight: "16px",
                  }}
                >
                  <Button
                    size="medium" onClick={() => window.location.href = "/setting"}
                    variant="outlined"

                    sx={{ padding: '10px 12px 10px 12px', minWidth: '36px', maxWidth: '160px', height: '40px', textTransform: 'none', borderRadius: '8px', }}
                  >
                    <img src={EditProfileIcon} height={"20px"} />
                    <Hidden smDown>
                      <Typography className="w-500" sx={{ ml: 1, fontSize: 14, color: '#2457B9' }}>
                        Edit Profile
                      </Typography></Hidden>
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    ml: { xs: 1, sm: '20%', lg: '16%', md: '18%' }, mb: 2, mt: { xs: 1, sm: -4, md: -4 }
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontSize: { xs: 18, sm: 18, md: 18, lg: 32 },
                        color: "#000",
                        mb: 1, pl: 0
                      }}
                      className="w-700"
                    >
                      {dataUser.name}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: { xs: 'column', md: 'row' } }}>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <img alt="Nikel-icon" src={locationCompanyIcon} height={18}></img>
                      <Typography
                        className="w-500"
                        sx={{ fontSize: 14, color: "#081130", mr: 1, ml: 1, mb: 1 }}
                      >
                        {`${dataUser.city ?? ""}${dataUser.city ? "," : ""} ${dataUser.provinsi ?? ""}`}
                      </Typography>
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <img alt="Nikel-icon" src={companyIcon} height={18}></img>
                      <Typography
                        className="w-500"
                        sx={{ fontSize: 14, color: "#081130", mr: 1, ml: 1 }}
                      >
                        {dataUserCompany.length === 0 ? 'Freelance' : translate('status.tergabungdiperusahaan')}
                      </Typography>
                    </Box>


                  </Box>
                </Box>
              </Box>

              {/* Tab Button Section */}
              <Stack
                spacing={0}
                direction="row"
                divider={<Divider orientation="vertical" style={{ backgroundColor: '#D1D7E6', height: '16px', marginTop: 10, marginBottom: 10, marginLeft: '12px', marginRight: '12px' }} flexItem />}
                sx={{
                  overflowX: 'auto',
                  '&::-webkit-scrollbar': {
                    width: 0,
                    height: 0
                  },
                }}
              >
                <Button
                  className="w-500"
                  sx={{
                    height: '40px',
                    padding: '8px 12px 8px 12px',
                    borderRadius: '8px',
                    flexShrink: 0,
                    background: activeTabTrader === "profile" ? "#081130" : "",
                    fontSize: { xs: 14, lg: 16 },
                    "&:hover": {
                      backgroundColor: "#56617B",
                    },
                    color: activeTabTrader === 'profile' ? "white" : '#081130'
                    , textTransform: "none",
                  }}
                  onClick={() => {
                    handleTabTrader("profile");
                  }}
                  variant={activeTabTrader === 'profile' ? "contained" : 'text'}
                >
                  Detail Profile
                </Button>

                <Button
                  className="w-500"
                  onClick={() => {
                    handleTabTrader("kredibilitas");
                  }}
                  sx={{
                    height: '40px',
                    padding: '8px 12px 8px 12px',
                    borderRadius: '8px',
                    flexShrink: 0,
                    background: activeTabTrader === 'kredibilitas' ? "#081130" : '', textTransform: 'none',
                    color: activeTabTrader === 'kredibilitas' ? "white" : '#081130', fontSize: { xs: 14, lg: 16 }, "&:hover": {
                      backgroundColor: "#56617B"
                    }
                  }}
                  variant={activeTabTrader === 'kredibilitas' ? "contained" : 'text'}
                >  {translate('profile.kredibilitas')}</Button>

                <Button
                  className="w-500"
                  onClick={() => {
                    handleTabTrader("favorit");
                  }}
                  sx={{
                    height: '40px',
                    padding: '8px 12px 8px 12px',
                    borderRadius: '8px',
                    flexShrink: 0,
                    background: activeTabTrader === 'favorit' ? "#081130" : '', textTransform: 'none',
                    color: activeTabTrader === 'favorit' ? "white" : '#081130', fontSize: { xs: 14, lg: 16 }, "&:hover": {
                      backgroundColor: "#56617B"
                    }
                  }}
                  variant={activeTabTrader === 'favorit' ? "contained" : 'text'}
                >
                  {translate('navbar.favorit')}
                </Button>

                <Button
                  className="w-500"
                  onClick={() => {
                    handleTabTrader("company");
                  }}
                  sx={{
                    height: '40px',
                    padding: '8px 12px 8px 12px',
                    borderRadius: '8px',
                    flexShrink: 0,
                    background: activeTabTrader === 'company' ? "#081130" : '', textTransform: 'none',
                    color: activeTabTrader === 'company' ? "white" : '#081130', fontSize: { xs: 14, lg: 16 }, "&:hover": {
                      backgroundColor: "#56617B"
                    }
                  }}
                  variant={activeTabTrader === 'company' ? "contained" : 'text'}
                >
                  {translate('profile.perusahaansaya')}
                </Button>
              </Stack>

              {/* Detail Profile Section */}
              {activeTabTrader === 'profile' ?
                <Grid container spacing={2} sx={{ mt: 1, mb: "80px" }}>
                  <Grid item xs={12} md={6} lg={6}>
                    <Box sx={{ border: 1, color: '#E8EBF2', height: '340px', borderRadius: "12px" }}>
                      <Box sx={{ p: '22px 20px 85px 20px' }}>
                        <Typography className="w-700" sx={{ color: '#081130', fontSize: 18, mb: '27px', }}>
                          {translate('profile.tentangsaya')}
                        </Typography>
                        <Typography className="w-500" sx={{ color: '#56617B', fontSize: 14 }}>
                          {dataUserProfile.about}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <Box sx={{ border: 1, color: '#E8EBF2', height: '340px', borderRadius: "12px", m: 0, mt: 0 }}>
                      <Box sx={{ p: '22px 20px 20px 20px' }}>
                        <Typography className="w-700" sx={{ color: '#081130', fontSize: { xs: 16, md: 18 }, mt: '0px', mb: '16px' }}>
                          {translate('profile.kontak')}
                        </Typography>
                        <Box
                          sx={{
                            borderRadius: "10px",
                            p: "12px",
                            objectFit: "cover",
                            backgroundColor: "#F4F6FA",

                          }}
                        >
                          <Stack direction="row" justifyContent={"space-between"} >
                            <Stack direction="row">
                              <img alt="call-icon" style={{ marginRight: 14 }} src={CallCompanyIcon} height={24}></img>
                              <Stack direction="column" >
                                <Typography className="w-500" sx={{ color: '#56617B', fontSize: { xs: 12, sm: 12, md: 14, lg: 14 } }}>
                                  {translate('profile.phone')}
                                </Typography>
                                <Typography className="w-500" sx={{ color: '#081130', fontSize: { xs: 14, md: 16 }, mt: 0, }}>
                                  +62 {slicePhoneNumber(dataUser.phone_number)}
                                </Typography>
                              </Stack>
                            </Stack>
                            <FormControlLabel onChange={(e) => {

                              submitProfile("phone", e.target.checked)
                            }}
                              control={<IOSSwitch checked={requestHidePhone} sx={{ m: 1 }} />}
                            />
                          </Stack>

                          <Stack direction="row" justifyContent={"space-between"} sx={{ mt: '24px' }} >
                            <Stack direction="row">
                              <img alt="Nikel-icon" style={{ marginRight: 14 }} src={MailCompanyIcon} height={24}></img>
                              <Stack direction="column" >
                                <Typography className="w-500" sx={{ color: '#56617B', fontSize: { xs: 12, md: 14 }, }}>
                                  Email
                                </Typography>
                                <Typography className="w-500" sx={{ color: '#081130', fontSize: { xs: 14, md: 16 }, mt: 0, }}>
                                  {dataUser.email}
                                </Typography>
                              </Stack>
                            </Stack>
                            <FormControlLabel onChange={(e) => {

                              submitProfile("email", e.target.checked)
                            }}
                              control={<IOSSwitch checked={requestHideEmail} sx={{ m: 1 }} />}

                            />
                          </Stack>
                        </Box>
                        <Typography className="w-700" sx={{ color: '#081130', fontSize: 18, mt: '24px', mb: 2 }}>
                          {translate('profile.address')}
                        </Typography>
                        <Stack direction="row" >
                          <img alt="Nikel-icon" src={locationCompanyIcon} style={{ marginRight: 14 }} height={24}></img>
                          <Stack direction="column" >
                            <Typography className="w-500" sx={{ color: '#56617B', fontSize: { xs: 12, md: 14 }, }}>
                              {translate('profile.address')}
                            </Typography>
                            <Typography className="w-500" sx={{ color: '#081130', fontSize: { xs: 14, md: 16 }, mt: 0, }}>
                              {dataUser.alamat}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Box>

                    </Box>
                  </Grid>
                </Grid>
                : ''}
              {/* Kredibilitas Section */}
              {activeTabTrader === 'kredibilitas' ?
                <Box sx={{
                  //border: 1
                  border: { xs: 0, lg: 1 }
                  , color: '#E8EBF2', borderRadius: 2, p: { xs: 0, lg: 2 }, mt: 2
                }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={8} sx={{ //backgroundColor: 'blue' 
                    }}>
                      <Typography className="w-700" sx={{ color: '#081130', fontSize: 18, mt: 0, }}>
                        {translate('jual-beli.dokumen')}
                      </Typography>
                    </Grid>
                    <Grid item xs={9} lg={2.5} justifyContent={"flex-end"} sx={{ //backgroundColor: 'grey' 
                    }}>
                      <TextField
                        fullWidth
                        sx={{
                          input: {
                            color: '#8792AB', fontSize: '14px', fontFamily: 'Satoshi-400'
                          },
                        }}
                        placeholder={translate('profile.search-doc')}
                        // label="Search"
                        value={filterDocuments}
                        onChange={(e) => { handleSearchValueDocuments(e.target.value) }}
                        onKeyDown={(e) => handleKeyDownDocuments(e)}
                        InputProps={{
                          className: "w-400",
                          style: styles_in.input,
                          startAdornment: (
                            <InputAdornment position="start">
                              <img alt="search-icon" src={searchIcon} height={'20px'} width={'20px'}></img>
                              {/* <SearchIcon /> */}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={3} lg={1.5} sx={{ //backgroundColor: 'red' 
                    }}>
                      <Button size="large" variant='outlined'
                        fullWidth
                        onClick={(e) => {
                          handleOpenModal('uploaddoc');
                        }}
                        sx={{ borderRadius: '8px', height: '44px', mb: 0, textTransform: 'none', mr: 5, maxWidth: '100%', }}>
                        <img alt="Upload" src={unggahIcon} height={'20px'} />
                        <Hidden smDown><Typography className="w-400" sx={{ ml: 1, fontSize: 16, color: '#2457B9' }}>
                          {translate('profile.unggah')}
                        </Typography></Hidden>

                      </Button>

                      <Hidden lgDown><ModalUploadKredi open={isOpenUploadDoc} close={handleCloseModal}></ModalUploadKredi>
                      </Hidden>
                      <Hidden lgUp>
                        <DrawerUploadDoc open={isOpenUploadDoc} close={handleCloseModal}>

                        </DrawerUploadDoc>
                      </Hidden>

                    </Grid>
                  </Grid>

                  {/*CARD*/}
                  {isLoadingDocument ? (
                    <div style={{ paddingTop: "40px", textAlign: "center" }}>
                      <CircularProgress
                        sx={{
                          color: (theme) =>
                            theme.palette.grey[theme.palette.mode === 'dark' ? 200 : 800],
                        }}
                        size={30}
                        thickness={4}
                        value={100}
                      />
                    </div>
                  ) : (
                    <>
                      <Grid container direction={'row'} spacing={2} sx={{ marginTop: '10px' }}>
                        {dataUserDocument.map((item, i) => (
                          <Grid item xs={6} sm={6} md={3} lg={3}>
                            <Card sx={{ borderRadius: 2 }}>
                              <DisplayPDFFile
                                url={`${config.baseURL}${item.url}`}
                                key={i}
                                ext={`${item.ext}`}
                                name={`${item.name}`}
                              />
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                      {dataUserDocumentTotalPage > dataUserDocumentPage ? (
                        <>
                          <Grid sx={{ mt: 3, mb: 2 }} className="pointer" container justifyContent="center" alignItems="center" direction="row" onClick={() => { getDataDocuments(Number(dataUserDocumentPage) + 1) }}>
                            {isLoadingDocumentMore ? (
                              <div style={{ paddingTop: "40px", textAlign: "center" }}>
                                <CircularProgress
                                  sx={{
                                    color: (theme) =>
                                      theme.palette.grey[theme.palette.mode === 'dark' ? 200 : 800],
                                  }}
                                  size={30}
                                  thickness={4}
                                  value={100}
                                />
                              </div>
                            ) : (
                              <>
                                <Typography className="w-500" sx={{ fontSize: 16, color: "#2457B9", mr: 1 }}>
                                  {translate('page_explore.lihatlebihbanyak')}
                                </Typography>
                                <KeyboardArrowDownIcon sx={{ fontSize: 16, color: "#2457B9" }} />
                              </>
                            )}
                          </Grid>
                        </>
                      ) : (<></>)}
                    </>
                  )}
                </Box>

                : ''}
              {/* Favorit Section */}
              {activeTabTrader === 'favorit' ?
                <Box width='100%'>
                  <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Hidden only={['xs', 'sm', 'md']}> <Grid item xs={12} lg={2.8}>
                      {/* filter Coal Section */}
                      {activeTabFavorit === 'coal' ? <Hidden smDown>
                        <MenuFilterCoal page={'profile'} handleLocationReset={handleLocationReset} resetFilterBatubara={resetFilterBatubara} handleFilterBatubara={handleFilterBatubara} filterBatubara={filterBatubara} dataProvince={dataProvince} selectedItems={selectedItems} limitProvince={limitProvince} setLimitProvince={setLimitProvince} setShowAllCoal={setShowAllCoal} handleSearchValueLocation={handleSearchValueLocation} handleKeyDownLocation={handleKeyDownLocation} handleLocationSumbit={handleLocationSumbit} showAllCoal={showAllCoal} searchValueLocation={searchValueLocation} >

                        </MenuFilterCoal>
                      </Hidden> : null}

                      {/* filter Nikel Section */}
                      {activeTabFavorit === 'nikel' ? <Hidden smDown>
                        <MenuFilterNikel page={'profile'} handleLocationReset={handleLocationReset} resetFilterNikel={resetFilterNikel} handleFilterNikel={handleFilterNikel} filterNikel={filterNikel} dataProvince={dataProvinceNikel} selectedItems={selectedItems} limitProvince={limitProvince} setLimitProvince={setLimitProvince} setShowAllNikel={setShowAllNikel} handleSearchValueLocation={handleSearchValueLocation} handleKeyDownLocation={handleKeyDownLocation} handleLocationSumbit={handleLocationSumbit} showAllNikel={showAllNikel} searchValueLocation={searchValueLocation}></MenuFilterNikel>
                      </Hidden> : null}
                      {/* filter Tongkang Section */}
                      {activeTabFavorit === 'tongkang' ?
                        <Hidden lgDown>
                          <MenuFilterTongkang page={'explore'} resetFilterTongkang={resetFilterTongkang} handleFilterTongkang={handleFilterTongkang} filterTongkang={filterTongkang} handleFilterSewaTongkang={handleFilterSewaTongkang} filterSewaTongkang={filterSewaTongkang} inputukurangtongkang={inputukurangtongkang}></MenuFilterTongkang>
                        </Hidden> : null}


                    </Grid></Hidden>
                    <Grid item xs={12} lg={9.2}> {/* Right Section*/}
                      <Grid container direction={{
                        xs: 'column-reverse',
                        sm: 'column-reverse',
                        md: 'column-reverse',
                        lg: 'row',
                        xl: 'row',
                      }} spacing={2} sx={{ mb: 0 }} >

                        <Grid item xs={12} md={7} lg={7} xl={8} sx={{ mb: 0, height: '100%' }}> {/*Search Bar*/}

                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={10} sm={10} md={11} lg={12}>
                              <SearchBarExplore text={activeTabFavorit === 'coal' ? translate('page_explore.search-batubara') :
                                activeTabFavorit === 'nikel' ? translate('page_explore.search-nikel') : translate('page_explore.search-tongkang')} searchValue={searchValue} handleSearchValue={handleSearchValue} handleKeyDown={handleKeyDown} searchIcon={searchIcon} />

                            </Grid>
                            {activeTabFavorit === 'coal' ? <Hidden only={['lg', 'xl']}>
                              <Grid item xs={1} md={1}>
                                <Button onClick={() => {
                                  handleOpenModalFilter("coal");
                                }} fullWidth>
                                  <img alt="filter-icon" src={filtericon} height={42} width={42}></img>
                                </Button>
                              </Grid></Hidden> : <></>}
                            {activeTabFavorit === 'nikel' ? <Hidden only={['lg', 'xl']}>
                              <Grid item xs={1} md={1}>
                                <Button onClick={() => {
                                  handleOpenModalFilter("nikel");
                                }} fullWidth>
                                  <img alt="filter-icon" src={filtericon} height={42} width={42}></img>
                                </Button>
                              </Grid></Hidden> : <></>}
                            {activeTabFavorit === 'tongkang' ? <Hidden only={['lg', 'xl']}>
                              <Grid item xs={1} md={1}>
                                <Button onClick={() => {
                                  handleOpenModalFilter("tongkang");
                                }} fullWidth>
                                  <img alt="filter-icon" src={filtericon} height={42} width={42}></img>
                                </Button>
                              </Grid></Hidden> : <></>}
                          </Grid>

                        </Grid>
                        <Grid item xs={12} md={5} lg={5} xl={4} sx={{ height: '100%', flexDirection: 'row' }} >
                          <Box display="flex" flexDirection="row" sx={{
                            backgroundColor: '#ECEFF2', borderRadius: '8px'
                            , height: '44px'
                          }}>
                            <Button
                              fullWidth
                              //className="w-700"
                              sx={{
                                m: "2px", p: '12px 24px', maxWidth: { xl: '110px' }, borderRadius: '8px', background: activeTabFavorit === 'coal' ? "white" : '#ECEFF2', justifyContent: 'center', textTransform: 'none'
                                , "&:hover": {
                                  backgroundColor: "white"
                                }
                              }}
                              onClick={() => {
                                handleTabFavorit("coal");
                              }}
                              variant={activeTabFavorit === 'coal' ? "text" : 'text'}
                            >
                              <Typography
                                //className="w-700"
                                className={activeTabFavorit === 'coal' ? "w-700" : 'w-500'}
                                sx={{ color: activeTabFavorit === 'coal' ? '#101213' : '#56617B', fontSize: 14 }}>
                                {translate('page_explore.batubara')}
                              </Typography>

                            </Button>

                            <Button
                              fullWidth
                              onClick={() => {
                                handleTabFavorit("nikel");
                              }}
                              sx={{
                                m: "2px", p: '12px 24px', maxWidth: { xl: '110px' }, borderRadius: '8px', background: activeTabFavorit === 'nikel' ? "white" : '#ECEFF2', textTransform: 'none',
                                fontSize: 14, fontWeight: activeTabFavorit === 'nikel' ? 700 : 500, "&:hover": {
                                  backgroundColor: "white"
                                }
                              }}
                              variant={activeTabFavorit === 'nikel' ? "text" : 'text'}
                            >
                              <Typography
                                //className="w-700"
                                className={activeTabFavorit === 'nikel' ? "w-700" : 'w-500'}
                                sx={{ color: activeTabFavorit === 'nikel' ? '#101213' : '#56617B', fontSize: 14 }}>
                                {translate('page_explore.nikel')}
                              </Typography>

                            </Button>

                            <Button
                              fullWidth
                              onClick={() => {
                                handleTabFavorit("tongkang");
                              }}
                              sx={{
                                m: "2px", p: '12px 24px', maxWidth: { xl: '110px' }, borderRadius: '8px', background: activeTabFavorit === 'tongkang' ? "white" : '#ECEFF2', textTransform: 'none'
                                , fontSize: 14, fontWeight: activeTabFavorit === 'tongkang' ? 700 : 500, "&:hover": {
                                  backgroundColor: "white"
                                }
                              }}
                              variant={activeTabFavorit === 'tongkang' ? "text" : 'text'}
                            >
                              <Typography
                                //className="w-700" 
                                className={activeTabFavorit === 'tongkang' ? "w-700" : 'w-500'}
                                sx={{ color: activeTabFavorit === 'tongkang' ? '#101213' : '#56617B', fontSize: 14 }}>
                                {translate('page_explore.tongkang')}
                              </Typography>

                            </Button></Box>
                        </Grid>


                      </Grid>
                      {activeTabFavorit === 'coal' ? <Box sx={{ mt: 2, mr: 10, mb: 2, width: '100%' }}><TabelCoal page={'profile'} getCoalList={(e) => setFavoriteBatubara()} coalList={coalFavoriteList.data}></TabelCoal></Box> : null}
                      {activeTabFavorit === 'coal' ? <Hidden lgUp><Box sx={{ mt: 2, mr: 10, mb: 2, width: '100%' }}>
                        {coalFavoriteList?.data === undefined ? (
                          <div>

                          </div>
                        ) : (
                          <div>
                            <Grid spacing={2} container direction={'row'}>
                              {coalFavoriteList?.data?.map(e => (
                                <CardListCoalExplore key={e.product_id} page={'profile'} getCoalList={(e) => setFavoriteBatubara()} coalList={e}></CardListCoalExplore>

                              ))}
                            </Grid>

                          </div>
                        )}

                      </Box></Hidden> : null}
                      {activeTabFavorit === 'nikel' ? <Box sx={{ mt: 2, mr: 10, mb: 2, width: "100%" }}><TabelNikel page={'profile'} nickelList={newNickelList} getNikel={(e) => setFavoriteNikel()}></TabelNikel></Box> : null}
                      {activeTabFavorit === 'nikel' ? <Hidden lgUp><Box sx={{ mt: 2, mr: 10, mb: 2, width: '100%' }}>
                        {newNickelList[0] === undefined ? (
                          <div>

                          </div>
                        ) : (
                          <div>
                            <Grid spacing={2} container direction={'row'}>
                              {newNickelList?.map(e => (
                                <CardListNikelExplore key={e.product_id} page={'profile'} nickelList={e} getNikel={(e) => setFavoriteNikel()}></CardListNikelExplore>
                              ))}
                            </Grid>
                          </div>
                        )}
                      </Box></Hidden> : null}
                      {activeTabFavorit === 'tongkang' ? <Box sx={{ mb: 2, mt: 0 }}>
                        <Grid container direction={'row'} spacing={3} >
                          {tongkangFavoriteList?.map(tongkang => (
                            <Grid sx={{ mt: 2 }} item lg={4} md={6} xs={12}>
                              <CardTongkangListExplore page={'profile'} key={tongkang.product_id} data={tongkang} getTongkang={(e) => setFavoriteTongkang()}></CardTongkangListExplore>
                            </Grid>
                          ))}
                        </Grid>
                      </Box> : null}


                      {(activeTabFavorit === "coal" && coalFavoriteList !== undefined && (parseInt(coalFavoriteList.pageCount) !== parseInt(coalFavoriteList.currentPage)) && (parseInt(coalFavoriteList.pageCount) > parseInt(coalFavoriteList.currentPage))) &&
                        <Grid sx={{ mt: 2, mb: 8 }} className="pointer" container justifyContent="center" alignItems="center" direction="row" onClick={(e) => handleFilterBatubara(filterBatubara["page"] + 1, "page")}>
                          <Typography className="w-500" sx={{ fontSize: 16, color: "#2457B9", mr: 1 }}>
                            {translate('page_explore.lihatlebihbanyak')}
                          </Typography>
                          <KeyboardArrowDownIcon sx={{ fontSize: 16, color: "#2457B9" }} />
                        </Grid>}

                      {(activeTabFavorit === "nikel" && nickelFavoriteList !== undefined && (parseInt(nickelFavoriteList.pageCount) !== parseInt(nickelFavoriteList.currentPage)) && (parseInt(nickelFavoriteList.pageCount) > parseInt(nickelFavoriteList.currentPage))) &&
                        <Grid sx={{ mt: 2, mb: 8 }} className="pointer" container justifyContent="center" alignItems="center" direction="row" onClick={(e) => handleFilterNikel(filterNikel["page"] + 1, "page")}>
                          <Typography className="w-500" sx={{ fontSize: 16, color: "#2457B9", mr: 1 }}>
                            {translate('page_explore.lihatlebihbanyak')}
                          </Typography>
                          <KeyboardArrowDownIcon sx={{ fontSize: 16, color: "#2457B9" }} />
                        </Grid>}

                      {(activeTabFavorit === "tongkang" && tongkangFavoriteList_ !== undefined && (parseInt(tongkangFavoriteList_.pageCount) !== parseInt(tongkangFavoriteList_.currentPage)) && (parseInt(tongkangFavoriteList_.pageCount) > parseInt(tongkangFavoriteList_.currentPage))) &&
                        <Grid sx={{ mt: 2, mb: 8 }} className="pointer" container justifyContent="center" alignItems="center" direction="row" onClick={(e) => handleFilterTongkang(filterTongkang["page"] + 1, "page")}>
                          <Typography className="w-500" sx={{ fontSize: 16, color: "#2457B9", mr: 1 }}>
                            {translate('page_explore.lihatlebihbanyak')}
                          </Typography>
                          <KeyboardArrowDownIcon sx={{ fontSize: 16, color: "#2457B9" }} />
                        </Grid>}
                    </Grid>
                  </Grid>
                </Box>

                : ''}
              {/* My Company Section */}
              {activeTabTrader === 'company' ?
                (
                  <>
                    {dataUserCompany.length === 0 ? (
                      <>
                        <Box sx={{ mt: 2, mb: 2 }}>
                          <Box display="flex" justifyContent="space-between" sx={{ mb: 2 }}>
                            <Typography className="w-700" sx={{ color: '#081130', fontSize: 18 }}>
                              {translate('profile.perusahaansaya')}
                            </Typography>

                            <Button
                              onClick={handleOpenAddCompany}
                              className='w-500 button-blue-contained'
                              size="medium"
                              variant="contained"
                              sx={{ textTransform: 'none', p: '10px 12px' }}>
                              <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                <Typography className='w-500' sx={{ ml: 0, fontSize: 14 }}>
                                  {translate('status.tambahperusahaan')}
                                </Typography>
                                <KeyboardArrowDownIcon sx={{ fontSize: 16, color: "#FFF", ml: 1 }} />
                              </Stack>

                            </Button>
                          </Box>
                          <TabelCompany page='detailprofile' data={{ data: [] }}></TabelCompany>

                        </Box>
                      </>
                    ) : (
                      <>
                        <Box>
                          <Hidden lgDown>
                            <Box sx={{ mt: 2 }}>

                              <Box display="flex" justifyContent="space-between" sx={{ mb: 2 }}>
                                <Typography className="w-700" sx={{ color: '#081130', fontSize: 18 }}>
                                  {translate('profile.perusahaansaya')}
                                </Typography>

                                <Button
                                  onClick={handleOpenAddCompany}
                                  className='w-500 button-blue-contained'
                                  // href='/buat-perusahaan'
                                  size="medium"
                                  variant="contained"
                                  sx={{ textTransform: 'none', p: '10px 12px' }}>
                                  <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Typography className='w-500' sx={{ ml: 0, fontSize: 14 }}>
                                      {translate('status.tambahperusahaan')}
                                    </Typography>
                                    <KeyboardArrowDownIcon sx={{ fontSize: 16, color: "#FFF", ml: 1 }} />
                                  </Stack>

                                </Button>
                              </Box>

                              <TabelCompany page='detailprofile' data={dataUserCompany[0] !== undefined ? { data: dataUserCompany } : { data: [] }}></TabelCompany>
                              {dataUserCompanyPage < dataUserCompanyTotalPage ? (
                                <div>
                                  {isLoadingCompany ? (
                                    <div style={{ paddingTop: "40px", textAlign: "center" }}>
                                      <CircularProgress
                                        sx={{
                                          color: (theme) =>
                                            theme.palette.grey[theme.palette.mode === 'dark' ? 200 : 800],
                                        }}
                                        size={30}
                                        thickness={4}
                                        value={100}
                                      />
                                    </div>
                                  ) : (
                                    <Grid sx={{ mt: 2, mb: 2 }} className="pointer" container justifyContent="center" alignItems="center" direction="row"
                                      onClick={() => getDataCompany(Number(dataUserCompanyPage) + 1)}
                                    >
                                      <Typography className="w-500" sx={{ fontSize: 16, color: "#2457B9", mr: 1 }}>
                                        {translate('page_explore.lihatlebihbanyak')}
                                      </Typography>
                                      <KeyboardArrowDownIcon sx={{ fontSize: 16, color: "#2457B9" }} />
                                    </Grid>
                                  )}
                                </div>

                              ) : (
                                <div></div>
                              )}

                            </Box>
                          </Hidden>
                          <Hidden lgUp>
                            <Box sx={{ p: 0, }}>

                              <Box display="flex" justifyContent="space-between" alignItems={'center'} sx={{ mb: 2, mt: 2, }}>
                                <Typography className="w-700" sx={{ color: '#081130', fontSize: 18 }}>
                                  {translate('profile.perusahaansaya')}
                                </Typography>

                                <Button
                                  onClick={handleOpenAddCompany}
                                  className='w-500 button-blue-contained'
                                  // href='/buat-perusahaan'
                                  size="medium"
                                  variant="contained"
                                  sx={{ textTransform: 'none', p: '8px 10px' }}>
                                  <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Typography className='w-500' sx={{ ml: 0, fontSize: 14 }}>
                                      {translate('status.tambahperusahaan')}
                                    </Typography>
                                    <KeyboardArrowDownIcon sx={{ fontSize: 16, color: "#FFF", ml: 1 }} />
                                  </Stack>

                                </Button>
                              </Box>
                              {dataUserCompany[0] === undefined ? (
                                <div>
                                </div>
                              ) : (
                                <>
                                  <Grid container spacing={2} direction={'row'}>
                                    {dataUserCompany.map((e) =>
                                      <Grid item xs={12} md={6}>
                                        <CardCompanyDetail data={e}></CardCompanyDetail>
                                      </Grid>
                                    )}

                                  </Grid>
                                  {dataUserCompanyPage < dataUserCompanyTotalPage ? (
                                    <div>
                                      {isLoadingCompany ? (
                                        <div style={{ paddingTop: "40px", textAlign: "center" }}>
                                          <CircularProgress
                                            sx={{
                                              color: (theme) =>
                                                theme.palette.grey[theme.palette.mode === 'dark' ? 200 : 800],
                                            }}
                                            size={30}
                                            thickness={4}
                                            value={100}
                                          />
                                        </div>
                                      ) : (
                                        <Grid sx={{ mt: 2, mb: 2 }} className="pointer" container justifyContent="center" alignItems="center" direction="row"
                                          onClick={() => getDataCompany(Number(dataUserCompanyPage) + 1)}
                                        >
                                          <Typography className="w-500" sx={{ fontSize: 16, color: "#2457B9", mr: 1 }}>
                                            {translate('page_explore.lihatlebihbanyak')}
                                          </Typography>
                                          <KeyboardArrowDownIcon sx={{ fontSize: 16, color: "#2457B9" }} />
                                        </Grid>
                                      )}
                                    </div>

                                  ) : (
                                    <div></div>
                                  )}
                                </>
                              )}
                            </Box>
                          </Hidden>
                        </Box>
                      </>
                    )}
                    <Menu className="menu-joincompany"
                      sx={{ mt: '24px', zIndex: 99999, }}
                      id="menu-appbar"
                      anchorEl={anchorElAddCompany}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={Boolean(anchorElAddCompany)}
                      onClose={handleCloseAddCompany}
                    >
                      <MenuItem sx={{ pb: 0, pt: 0, height: '43px', pl: '2px', mr: '2px' }} onClick={() => window.location.href = "/buat-perusahaan"}>
                        <Stack direction={'row'} alignItems={'center'} >
                          <img style={{ marginRight: '2px' }} alt="img-icon" src={person} height={14} width={14}></img>
                          <Typography className="w-500" sx={{ fontSize: '13px', }} textAlign="center">     {translate('status.buatperusahaan')}</Typography>
                        </Stack>
                      </MenuItem>
                      <Divider orientation="horizontal" style={{ backgroundColor: '#D1D7E6', marginTop: 2, marginBottom: 2 }} flexItem />
                      <MenuItem sx={{ pb: 0, pt: 0, height: '43px', pl: '2px', pr: '2px' }} onClick={() => { navigate(`/explore`, { state: { detail: 'company' } }) }}>
                        <Stack direction={'row'} alignItems={'center'}>
                          <img style={{ marginRight: '2px' }} alt="img-icon" src={favorite_ic} height={14} width={14}></img>
                          <Typography className="w-500" sx={{ fontSize: '13px', }} textAlign="center"> {translate('status.gabungperusahaan')}</Typography>
                        </Stack>
                      </MenuItem>

                    </Menu>
                  </>
                )
                : ''}
            </Grid>
          </Grid>
        </Box></Box > <Footer />


      {/* modal filter  Responsive Section */}
      <Hidden lgUp>
        {/* modal filter Coal Responsive Section */}
        {activeTabFavorit === 'coal' && <DrawerFilterCoal page={'profile'} open={modalCoalFilter} close={handleCloseModalFilter} handleLocationReset={handleLocationReset} resetFilterBatubara={resetFilterBatubara} handleFilterBatubara={handleFilterBatubara} filterBatubara={filterBatubara} dataProvince={dataProvince} selectedItems={selectedItems} limitProvince={limitProvince} setLimitProvince={setLimitProvince} handleOpenModalLocationResponsive={handleOpenModalLocationResponsive} handleCloseModalFilter={handleCloseModalFilter} Filter={Filter}>
        </DrawerFilterCoal>}
        {/* modal filter Nikel Responsive Section */}
        {activeTabFavorit === 'nikel' && <DrawerFilterNikel page={'profile'} open={modalNikelFilter} close={handleCloseModalFilter} handleLocationReset={handleLocationReset} resetFilterNikel={resetFilterNikel} handleFilterNikel={handleFilterNikel} filterNikel={filterNikel} dataProvince={dataProvinceNikel} selectedItems={selectedItems} limitProvince={limitProvince} setLimitProvince={setLimitProvince} handleOpenModalLocationResponsive={handleOpenModalLocationResponsive} handleCloseModalFilter={handleCloseModalFilter} Filter={Filter}>
        </DrawerFilterNikel>}
        {/* modal filter Tongkang Responsive Section */}
        {activeTabFavorit === 'tongkang' && <DrawerFilterTongkang page={'explore'} open={modalTongkangFilter} close={handleCloseModalFilter} handleLocationReset={handleLocationReset} resetFilterTongkang={resetFilterTongkang} handleFilterTongkang={handleFilterTongkang} filterTongkang={filterTongkang} handleFilterSewaTongkang={handleFilterSewaTongkang} handleFilterUkuranTongkang={handleFilterUkuranTongkang} handleCloseModalFilter={handleCloseModalFilter} Filter={Filter} inputukurangtongkang={inputukurangtongkang}>
        </DrawerFilterTongkang>}

        {/* modal filter Location Responsive Section */}
        {activeTabFavorit === 'coal' && <DrawerFilterLocation type={'coal'} open={modalCoalLocation} close={handleCloseModalLocationResponsive} handleLocationReset={handleLocationReset} handleSearchValueLocation={handleSearchValueLocation} handleKeyDownLocation={handleKeyDownLocation} dataProvince={dataProvince}
          handleFilter={handleFilterBatubara} selectedItems={selectedItems} handleLocationSumbit={handleLocationSumbit} searchValueLocation={searchValueLocation}
        ></DrawerFilterLocation>}

        {activeTabFavorit === 'nikel' && <DrawerFilterLocation type={'nikel'} open={modalNikelLocation} close={handleCloseModalLocationResponsive} handleLocationReset={handleLocationReset} handleSearchValueLocation={handleSearchValueLocation} handleKeyDownLocation={handleKeyDownLocation} dataProvince={dataProvinceNikel}
          handleFilter={handleFilterNikel} selectedItems={selectedItems} handleLocationSumbit={handleLocationSumbit} searchValueLocation={searchValueLocation}
        ></DrawerFilterLocation>}
      </Hidden>

    </Box >
  );
}

export default ProfilePage;
