import { Button, Grid, Stack, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import deleteicon from "../assets/hapus-doc-ic.svg";
import pdfIcon from "../assets/pdf-ic.png";
import { config } from "../services/constants";
import CircularWithValueLabel from "./CircularProgress";

function DisplayPDFUpload(props) {
  const [isUploadFailed, setisUploadFailed] = useState(false)
  const [fileState, setFileState] = useState(props.data)
  const [maxsize, setmaxsize] = useState(false)
  const controller = new AbortController();

  let newName = props.data.name

  if (props.data?.name?.length >= 9) {
    newName = props.data.name.slice(0, 8)

  }

  const pdfUrl2 = React.useMemo(() => ({ uri: fileState.url_display }), [fileState]);

  const uploadForm = async (fileUpload, file) => {
    let tokenValue = localStorage.getItem("token")
    let newFileDocument = file
    if (fileUpload.size < 24000000) {
      let formData = new FormData();
      formData.append('files', fileUpload);
      let response = await axios.post(`${config.baseURL}/api/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${tokenValue}`
        },
        signal: controller.signal,
        onUploadProgress: (event) => {

          const progress = Math.round((100 * event.loaded) / event.total)
          newFileDocument = {
            ...newFileDocument,
            progress: `${progress}`,
          }
          setFileState(newFileDocument)
        },
      })

      if (response?.status === 200) {
        newFileDocument = {
          ...newFileDocument,
          url: `${response.data[0].url}`,
          url_display: `${config.baseURL}${response.data[0].url}`,
          id_upload: response.data[0].id
        }
        setFileState(newFileDocument)
        props.update(newFileDocument, props.indexed)
      } else {
        newFileDocument = {
          ...newFileDocument,
          progress: '-1',
        }
        setFileState(newFileDocument)
        setisUploadFailed(true)
      }
    } else {
      setmaxsize(true)
    }
  };

  useEffect(() => {
    if (fileState.progress === 0) {
      uploadForm(props.dataUpload, props.data)
    }
    setFileState(props.data)
  }, [props.data])

  let render = React.useMemo(() => (
    <DocViewer documents={[pdfUrl2]}
      pluginRenderers={DocViewerRenderers}
    />
  ), [pdfUrl2]);

  return (
    <Grid container direction='row' justifyContent='space-between' style={{ flexWrap: "nowrap" }}>
      <div key={props.indexed} className='flex column' style={{ width: '100%', position: 'relative' }}>
        <a href={props.data.url_display}
          target="_blank"
          rel="noreferrer" className='box-document border-box' >
          {/* <PDFViewer scale={0.8} document={pdfUrl} /> */}
          {fileState.url_display !== "" ? (
            <>
              {render}
            </>
          ) : (
            <>
              <div></div>
            </>
          )
          }
        </a>

        <div style={{ position: 'absolute', display: 'flex', height: '60%', width: '100%', alignItems: 'center', justifyContent: 'center', background: 'rgba(0, 0, 0, 0.12)' }}>
          {fileState.progress !== "100" && fileState.progress !== "-1" && !maxsize &&
            <CircularWithValueLabel value={fileState.progress} ></CircularWithValueLabel>
          }

          {isUploadFailed && <Button
            onClick={() => {
              setisUploadFailed(false)
              uploadForm(props.dataUpload, props.data)
            }}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ p: '10px 12px', width: { xs: '70%', sm: '129px' }, height: '40px', borderRadius: '8px', backgroundColor: '#FFF' }}
          >
            <Typography className="w-500" sx={{ textTransform: 'none', fontSize: 14, color: '#2457B9' }}> Upload Ulang
            </Typography>
          </Button>}

          {fileState.progress === "100" &&

            <img onClick={() => {
              props.delete()
            }} style={{ height: '24px', width: '24px', cursor: 'pointer' }} src={deleteicon} ></img>
          }

        </div>

        <div className='doc-desc-company flex' style={{ paddingTop: "10px", paddingBottom: "10px", paddingLeft: '10px', justifyContent: 'space-between' }}>
          <Stack direction={'row'}>
            {props.type !== 'img' && <img style={{ height: '28px', width: '28px' }} src={pdfIcon} alt="" className='mr8' />}
            <Stack>
              <p className='w-500 s-1419 c-08'>{`${newName}...`}</p>
              <div className='flex '>

                <p className='w-500 s-1216 c-56'>{props.data.size}</p>

                &nbsp;
                {/* <a style={{ textDecoration: 'none' }} target="_blank"
                  rel="noreferrer" className='c-24 s-1216 w-500'>Download</a> */}
              </div>
            </Stack>
          </Stack>

        </div>

      </div>
    </Grid >
  )
}

export { DisplayPDFUpload };

