import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Grid, Hidden, IconButton, Modal, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2';
import joinCompanyIcon from "../assets/company/joincompany.svg";
import LeaveCompanyIcon from "../assets/company/leavecompany.svg";
import { RequestJoinCompany, RequestLeaveCompany } from "../services/api";
import { capitalizeEachWord, convertText } from '../services/primary';

export default function ModalJoinCompany(props) {
    const [translate,] = useTranslation("global");

    const SubmitJoinCompany = async () => {

        try {
            let params = {
                id_company: props.id_company,

            }
            const responAPI = await RequestJoinCompany(params)
            if (responAPI.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: translate('status.successjoincompany')
                }).then(function () {
                    window.location.href = `/detail/${capitalizeEachWord(convertText(props.name_company))}`
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: translate('status.failed'),
                    text: translate('status.subtitlefailed'),
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const SubmitLeaveCompany = async () => {

        try {
            let params = {
                id_company: props.id_company,
            }
            const responAPI = await RequestLeaveCompany(params)
            if (responAPI.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: translate('status.successleavecompany')
                }).then(function () {
                    window.location.href = `/detail/${capitalizeEachWord(convertText(props.name_company))}`
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: translate('status.failed'),
                    text: translate('status.subtitlefailed'),
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div>
            <Modal
                open={props.open}
                onClose={props.close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    width: { xs: '304px', sm: 343, md: 343, lg: 472, xl: 472 },
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: '8px',
                    bgcolor: 'background.paper',
                    "&:focus": {
                        outline: "none"
                    },
                    boxShadow: 24,
                    p: { xs: '16px', sm: '24px', md: '24px', lg: '24px', xl: '24px' },
                }}>
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <IconButton size="small" >
                            <CloseIcon onClick={() => {
                                props.close();
                            }} />
                        </IconButton>
                    </div>
                    {props.isJoinCompany ? <div style={{ justifyContent: 'center', display: 'flex', marginBottom: '24px' }}>
                        <img alt="hubungitrader-icon" style={{ width: '140px', height: '140px', }} src={LeaveCompanyIcon}></img>
                    </div> :
                        <div style={{ justifyContent: 'center', display: 'flex', marginBottom: '24px' }}>
                            <img alt="hubungitrader-icon" style={{ width: '140px', height: '140px', }} src={joinCompanyIcon}></img>
                        </div>}

                    {props.isJoinCompany ? <Typography id="modal-modal-title" className='w-700' sx={{ fontSize: '20px', color: '#081130', textAlign: 'center', mt: '24px' }}>
                        {translate('status.titleleavecompany')}
                    </Typography>
                        : <Typography id="modal-modal-title" className='w-700' sx={{ fontSize: '20px', color: '#081130', textAlign: 'center', mt: '24px' }}>
                            {translate('status.titlejoincompany')}
                        </Typography>}

                    <Grid spacing={2} container justifyContent='space-between' direction='row' sx={{ mb: '16px', mt: '32px' }}>
                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                onClick={(e) => {
                                    props.close();
                                }}
                                size="large"
                                className="w-500 button-blue-outlined"
                                variant="outlined"
                                sx={{
                                    p: '12px 16px'

                                }}
                            >
                                {translate('jual-beli.btn-cancel')}
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Hidden smDown>
                                {props.isJoinCompany ? <Button
                                    fullWidth
                                    onClick={(e) => {
                                        props.close();
                                        SubmitLeaveCompany();
                                    }}
                                    size="large"
                                    className="w-500"
                                    variant="contained"
                                    style={{
                                        background: '#ED3131',
                                        textTransform: 'none',
                                        padding: '12px 16px'

                                    }}
                                >
                                    {translate('status.tinggalkan')}
                                </Button> : <Button
                                    fullWidth
                                    onClick={(e) => {
                                        props.close();
                                        SubmitJoinCompany();
                                    }}
                                    size="large"
                                    className="w-500 button-blue-contained"
                                    variant="contained"
                                    sx={{
                                        p: '12px 16px'

                                    }}
                                >
                                    {translate('status.confirmjoin')}
                                </Button>}
                            </Hidden>
                            <Hidden smUp>
                                {props.isJoinCompany ? <Button
                                    fullWidth
                                    onClick={(e) => {
                                        props.close();
                                        SubmitLeaveCompany();
                                    }}
                                    size="large"
                                    className="w-500"
                                    variant="contained"
                                    style={{
                                        background: '#ED3131',
                                        textTransform: 'none',
                                        padding: '12px 16px'

                                    }}
                                >
                                    {translate('status.tinggalkan')}
                                </Button> : <Button
                                    fullWidth
                                    onClick={(e) => {
                                        props.close();
                                        SubmitJoinCompany();
                                    }}
                                    size="large"
                                    className="w-500 button-blue-contained"
                                    variant="contained"
                                    sx={{
                                        p: '12px 16px'

                                    }}
                                >
                                    {translate('status.join')}
                                </Button>}
                            </Hidden>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}