
import CloseIcon from '@mui/icons-material/Close';
import { Box, Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import Airicon from "../assets/air-ic.png";
import Calendericon from "../assets/calender-dark-ic.png";
import Feicon from "../assets/fe-ic.png";
import Niicon from "../assets/ni-ic.png";
import Ratioicon from "../assets/ratio-ic.png";

export default function CardPermintaanNikel(props) {
  const [translate,] = useTranslation("global");
  return (
    <Box>
      <Card sx={{ borderRadius: 2, marginTop: '16px', mb: "40px", padding: { sm: '20px', xs: '16px' }, border: '2px solid #2457B9' }}>
        <CardContent sx={{ padding: '0px !important' }}>
          <Box>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                className="w-700"
                sx={{ color: '#081130', fontSize: { sm: '18px', xs: '16px' } }}
              >
                {translate('status.titlepermintaannikel')}
              </Typography>
              <CloseIcon onClick={() => { props.resetFunc() }} sx={{ color: '#56617B', width: '24px', height: '24px' }} />
            </Stack>
            <Stack
              container
              direction="row"
              justifyContent="space-between"
              spacing={0}
              sx={{ mt: '16px', mb: '16px', flexWrap: 'wrap-reverse' }}
            >
              <Stack
                direction={'row'}
                alignItems={'center'}
                spacing={1}
              >
                <Typography
                  sx={{ fontSize: 14, color: '#56617B' }}
                  className="w-500"
                >
                  Laycan
                </Typography>
                <img alt="calender-icon" src={Calendericon} height={20} width={20}></img>
                {props.data.start_laycan_date === "" ? (
                  <Typography
                    sx={{ fontSize: 16, color: '#424242', ml: 1 }}
                    className="w-700"
                  >
                  </Typography>
                ) : (
                  <Typography
                    sx={{ fontSize: 16, color: '#424242', ml: 1 }}
                    className="w-700"
                  >
                    {`${new Date(props.data.start_laycan_date).toLocaleDateString('en-AU')}`}
                  </Typography>
                )}

                <Typography
                  sx={{ fontSize: { sm: '16px', xs: '12px' }, color: '#424242' }}
                  className="w-700"
                >
                  -
                </Typography>

                {props.data.end_laycan_date === "" ? (
                  <Typography
                    sx={{ fontSize: 16, color: '#424242', ml: 1 }}
                    className="w-700"
                  >
                  </Typography>
                ) : (
                  <Typography
                    sx={{ fontSize: 16, color: '#424242', ml: 1 }}
                    className="w-700"
                  >
                    {`${new Date(props.data.end_laycan_date).toLocaleDateString('en-AU')}`}
                  </Typography>
                )}
              </Stack>
              <Stack direction={'row'} sx={{ textAlign: "right", alignItems: "center", display: 'flex', marginBottom: { sm: '0', xs: '12px' } }}  >
                {props.data.price === '' ? (
                  <Typography
                    sx={{ fontSize: { sm: '20px', xs: '18px' }, color: '#081130', mr: 1 }}
                    className="w-700"
                  >
                    {props.data.currency === 'idr' ? 'Rp' : '$'} -
                  </Typography>
                ) : (
                  <Typography
                    sx={{ fontSize: { sm: '20px', xs: '18px' }, color: '#081130', mr: 1 }}
                    className="w-700"
                  >
                    {props.data.currency === 'idr' ? 'Rp' : '$'} {new Intl.NumberFormat().format(props.data.price)}
                  </Typography>
                )}

                <Typography
                  sx={{ fontSize: { sm: '16px', xs: '12px' }, color: '#56617B' }}
                  className="w-500"
                >
                  /ton
                </Typography>

                {props.data.end_laycan_date === "" ? (
                  <Typography
                    sx={{ fontSize: 16, color: '#424242', ml: 1 }}
                    className="w-700"
                  >
                    (-)
                  </Typography>
                ) : (
                  <Typography
                    sx={{ fontSize: 16, color: '#424242', ml: 1 }}
                    className="w-700"
                  >
                    {`${new Date(props.data.end_laycan_date).toLocaleDateString('en-AU')}`}
                  </Typography>
                )}
              </Stack>
            </Stack>
            <Grid
              marginTop={1}
              container
              direction="row"
              alignItems="left"
              justifyContent="left"
              spacing={{ xs: '12px', sm: '20px' }}
            >
              <Grid item lg={2.4} md={4} xs={6}>
                <Box sx={{ background: "#F4F6FA", borderRadius: 2, padding: { sm: "12px 12px 12px 16px", xs: '12px' } }}>
                  <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>
                    <Typography className="w-700" sx={{ color: '#081130', fontSize: { sm: '20px', xs: '18px' }, }}>
                      {new Intl.NumberFormat().format(props.data.kadar_ni)}
                    </Typography>
                    <img alt="Nikel-icon" src={Niicon} height={20}></img>
                  </Stack>
                  <Typography className="w-500" sx={{ color: '#56617B', fontSize: { sm: '14px', xs: '12px' } }} >
                    {translate('page_explore.kadar-ni')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={2.4} md={4} xs={6}>
                <Box sx={{ background: "#F4F6FA", borderRadius: 2, padding: { sm: "12px 12px 12px 16px", xs: '12px' } }}>
                  <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>

                    <Typography className="w-700" sx={{ color: '#081130', fontSize: { sm: '20px', xs: '18px' }, }}>
                      {new Intl.NumberFormat().format(props.data.kadar_fe)}
                    </Typography>
                    <img alt="Nikel-icon" src={Feicon} height={20}></img>
                  </Stack>
                  <Typography className="w-500" sx={{ color: '#56617B', fontSize: { sm: '14px', xs: '12px' } }} >
                    {translate('page_explore.kadar-fe')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={2.4} md={4} xs={6}>
                <Box sx={{ background: "#F4F6FA", borderRadius: 2, padding: { sm: "12px 12px 12px 16px", xs: '12px' } }}>
                  <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>

                    <Typography className="w-700" sx={{ color: '#081130', fontSize: { sm: '20px', xs: '18px' }, }}>
                      {new Intl.NumberFormat().format(props.data.kadar_air)}
                    </Typography>
                    <img alt="Nikel-icon" src={Airicon} height={20}></img>
                  </Stack>
                  <Typography className="w-500" sx={{ color: '#56617B', fontSize: { sm: '14px', xs: '12px' } }} >
                    {translate('page_explore.kadar-air')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={2.4} md={4} xs={6}>
                <Box sx={{ background: "#F4F6FA", borderRadius: 2, padding: { sm: "12px 12px 12px 16px", xs: '12px' } }}>
                  <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>

                    <Typography className="w-700" sx={{ color: '#081130', fontSize: { sm: '20px', xs: '18px' }, }}>
                      {new Intl.NumberFormat().format(props.data.si_mg_ratio)}
                    </Typography>
                    <img alt="Nikel-icon" src={Ratioicon} height={20}></img>
                  </Stack>
                  <Typography className="w-500" sx={{ color: '#56617B', fontSize: { sm: '14px', xs: '12px' } }} >
                    Si/Mg
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={2.4} md={4} xs={6}>
                <Box sx={{ background: "#F4F6FA", borderRadius: 2, padding: { sm: "12px 12px 12px 16px", xs: '12px' } }}>
                  <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>
                    {props.data.quantity === '' ? (
                      <Typography className="w-700" sx={{ color: '#081130', fontSize: { sm: '20px', xs: '18px' }, }}>
                        -
                      </Typography>
                    ) : (
                      <Typography className="w-700" sx={{ color: '#081130', fontSize: { sm: '20px', xs: '18px' }, }}>
                        {new Intl.NumberFormat().format(props.data.quantity)}
                      </Typography>
                    )}
                    <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                      MT
                    </Typography>
                  </Stack>
                  <Typography className="w-500" sx={{ color: '#56617B', fontSize: { sm: '14px', xs: '12px' } }} >
                    Quantity
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Stack
              sx={{ mt: '16px' }}
              alignItems="center"
              direction="row"
            >
              <Typography
                className="w-500"
                sx={{ fontSize: { sm: '16px', xs: '12px' }, color: "#56617B", mr: 1 }}
              >
                Loading port
              </Typography>
              {props.data.name_loading_port === '' ? (
                <Typography
                  className="w-700"
                  sx={{ fontSize: { sm: '16px', xs: '14px' }, color: "#424242", mr: 1 }}
                >
                  -
                </Typography>
              ) : (
                <Typography
                  className="w-700"
                  sx={{ fontSize: { sm: '16px', xs: '14px' }, color: "#424242", mr: 1 }}
                >
                  {props.data.name_loading_port}
                </Typography>
              )}
            </Stack>
          </Box>
        </CardContent>
      </Card>
      <Stack direction={'row'} alignItems={'center'} sx={{ mt: '40px', mb: '16px' }}>
        <Typography
          className="w-700"
          sx={{ fontSize: 16, color: "#081130", mr: 1, }}
        >
          {translate('status.hasilpencarian')}
        </Typography>
        <Box sx={{ background: "#F4F6FA", borderRadius: '6px', padding: '4px' }}>
          <Typography
            className="w-700"
            sx={{ fontSize: 16, color: "#56617B", }}
          >
            {props.result}
          </Typography>
        </Box>
      </Stack>
    </Box>
  )

}
