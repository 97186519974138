import { Hidden, IconButton, InputAdornment } from "@material-ui/core"
import Divider from "@material-ui/core/Divider"
import { Box, Stack } from '@mui/material'
import Button from "@mui/material/Button"
import CircularProgress from '@mui/material/CircularProgress'
import CssBaseline from "@mui/material/CssBaseline"
import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { ThemeProvider, createTheme } from "@mui/material/styles"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import Swal from 'sweetalert2'
import visibilityOffIcon from "../assets/eye-icon-close.svg"
import visibilityIcon from "../assets/eye-icon.svg"
import heroLoginImg from "../assets/hero-login.webp"
import LogoIndomine from "../assets/logo-color.png"
import { resetPassword } from "../services/api"
const theme = createTheme()

export default function ResetPasswordpage() {
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);
  const handleMouseDownPassword2 = () => setShowPassword2(!showPassword2);
  const [translate,] = useTranslation("global");

  const [searchParams,] = useSearchParams();

  const handleSubmit = async (event) => {
    event.preventDefault()
    setIsLoading(true)
    const data = new FormData(event.currentTarget)
    const newData = {
      id: searchParams.get("id"),
      reset_code: searchParams.get("reset_code"),
      new_password: data.get("confirm_password"),
    }

    if (data.get("confirm_password") !== data.get("new_password")) {
      setIsLoading(false)
      Swal.fire({
        icon: 'error',
        title: translate('status.failed'),
        text: `New Password and Confirmation Password not match!`,
      })
      return
    }

    const response = await resetPassword(newData);
    setIsLoading(false)
    if (response.status === 200) {
      Swal.fire({
        icon: 'success',
        title: translate('status.success'),
        text: 'Please Login Again',
      }).then(() => {
        window.location.href = "/signin"
      })
    } else {
      Swal.fire({
        icon: 'error',
        title: translate('status.failed'),
        text: `${response.data?.message ?? response.message}`,
      })
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main">
        <CssBaseline />

        <Grid item xs={12} sm={12} md={6} lg={6} elevation={6} square>

          <Box
            sx={{
              mt: { xs: '24px', sm: '24px', md: '52px', lg: '52px' },
              ml: { xs: '16px', sm: '32px', md: '60px', lg: '120px' },
              mr: { xs: '16px', sm: '32px', },
              display: "flex",
              flexDirection: "column",

            }}
          >
            <Stack sx={{ mb: { lg: '141px', xs: '52px', sm: '52px', md: '52px' }, }} direction={'row'} alignItems={'center'}>
              <Box
                component="img"
                sx={{

                  height: 28,
                }}
                alt="logo-img"
                src={LogoIndomine}
              />
              <Typography className="w-700" sx={{ color: '#081130', fontSize: 24, ml: '10px' }}>
                IndoMine
              </Typography>

            </Stack>

            <Typography className="w-700" sx={{ color: '#081130', fontSize: 32 }}>
              {translate('login-register.title-reset')}
            </Typography>
            <Typography className="w-500" sx={{ color: '#56617B', fontSize: 16, mt: '12px', maxWidth: { lg: '430px', sm: '100%', xs: '100%' } }}>
              {translate('login-register.subtitle-reset')}
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ maxWidth: { lg: '430px', sm: '100%', xs: '100%' }, mt: '32px' }}
            >
              <Typography className="w-500" sx={{ color: '#081130', fontSize: 14, }}>
                {translate('login-register.new-password')}
              </Typography>
              <TextField
                sx={{
                  mt: '4px', mb: '32px', input: {
                    fontSize: '14px', fontFamily: 'Satoshi-500'
                  },
                }}
                className="box-input-password"
                fullWidth
                name="new_password"
                placeholder={translate('login-register.placeholder-password')}
                type={showPassword ? "text" : "password"} // <-- This is where the magic happens
                id="new_password"
                autoComplete="current-password"
                InputProps={{ // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        style={{ padding: 0 }}
                      >
                        {showPassword ? <img alt="eye-icon" style={{ width: '22px', height: '22px', }} src={visibilityIcon}></img> : ''}
                        {!showPassword ? <img alt="eyeclose-icon" style={{ width: '20px', height: '20px', }} src={visibilityOffIcon}></img> : ''}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Typography className="w-500" sx={{ color: '#081130', fontSize: 14 }}>
                {translate('login-register.confirm-password')}
              </Typography>
              <TextField
                sx={{
                  mt: '4px', input: {
                    fontSize: '14px', fontFamily: 'Satoshi-500'
                  },
                }}
                fullWidth
                className="box-input-password"
                name="confirm_password"
                placeholder={translate('login-register.placeholder-password')}
                type={showPassword2 ? "text" : "password"} // <-- This is where the magic happens
                id="confirm_password"
                autoComplete="current-password"
                InputProps={{ // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword2}
                        onMouseDown={handleMouseDownPassword2}
                        style={{ padding: 0 }}
                      >
                        {showPassword2 ? <img alt="eye-icon" style={{ width: '22px', height: '22px', }} src={visibilityIcon}></img> : ''}
                        {!showPassword2 ? <img alt="eyeclose-icon" style={{ width: '20px', height: '20px', }} src={visibilityOffIcon}></img> : ''}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />


              <Button
                className="w-500"
                type="submit"
                fullWidth
                variant="contained"
                onClick={() => {
                }}
                sx={{
                  mt: 3, mb: 2, background: ' linear-gradient(270deg, #2457B9 15%, #2860CD 100%)', borderRadius: '8px', boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.12), 0px -1px 0px 0px rgba(0, 0, 0, 0.20) inset', padding: '12px 16px', textTransform: 'none'
                }}
              >
                {isLoading ? <CircularProgress
                  sx={{
                    color: (theme) =>
                      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                  }}
                  size={20}
                  thickness={4}
                  value={100}
                /> : `${translate('login-register.btn-reset-password')}`}
              </Button>

              <Stack direction={'row'} sx={{ mt: '284px', mb: '32px' }} justifyContent={{ xs: 'center', sm: 'center', md: 'left' }} alignItems={'center'}>
                <Typography
                  className="w-500"
                  sx={{ fontSize: 14, color: '#56617B', mr: '8px' }}

                >
                  {translate('login-register.btn-to-daftar')}

                </Typography>
                <Link href="/signup" className="w-700" sx={{ color: '#2457B9', fontSize: 14, textDecoration: 'none' }}>
                  {translate('login-register.btn-daftar')}
                </Link>{" "}
              </Stack>
            </Box>
          </Box>
        </Grid>
        <Hidden smDown>
          <Grid
            item
            xs={false}
            sm={false}
            md={6}
            lg={6}
            sx={{

              backgroundImage: `linear-gradient(180deg, rgba(8, 34, 86, 0.6) 16.83%, rgba(8, 34, 86, 0) 60.9%), url(${heroLoginImg})`,
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <Typography className="w-700" sx={{ color: 'white', fontSize: 48, mt: '186px', ml: 6, lineHeight: '72px' }}>
              {translate('login-register.hero-text1')} <br />  {translate('login-register.hero-text2')}
            </Typography>
            <Divider style={{ marginLeft: '61px', backgroundColor: "#E8EBF2", width: '80px', height: '3px', marginTop: '24px', }} />
          </Grid>
        </Hidden>

      </Grid>
    </ThemeProvider>
  )
}
