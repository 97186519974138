import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid, Stack, TextField, Typography } from "@mui/material";
import { React } from 'react';
import { useTranslation } from "react-i18next";
export default function MenuInviteStaff(props) {
  const [translate,] = useTranslation("global");
  return (
    <Grid item xs={12} sm={12} md={4} lg={4} className='form-input'>
      {props.staff.map((item, i) => (
        <Box key={item} sx={{ border: '1px solid #E8EBF2', borderRadius: 2, p: '16px', mb: 2 }}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography className="w-700" sx={{ fontSize: 16, color: '#081130', mb: '12px' }}>
              Staff
            </Typography>
            {i !== 0 && <CloseIcon onClick={(e) => props.handleDeleteStaff(i)} sx={{ height: 24, width: 24, cursor: 'pointer' }} ></CloseIcon>}
          </Stack>
          <p className='c-08 s-1419 w-500 mb4'>{translate('login-register.nama')} </p>
          <TextField name='name' id="outlined-start-adornment" sx={{
            width: '100%', mb: '24px', input: {
              fontSize: '14px', fontFamily: 'Satoshi-500'
            },
          }}
            placeholder={translate('login-register.placeholder-name')} value={item.name_staff || ""} onChange={(e) => props.handleChangeNameStaff(e, i)}
          />
          <p className='c-08 s-1419 w-500 mb4'>{translate('status.emailstaff')}</p>
          <TextField name='email' id="outlined-start-adornment" sx={{
            width: '100%', mb: '24px', input: {
              fontSize: '14px', fontFamily: 'Satoshi-500'
            },
          }}
            placeholder={translate('status.placeholder-email-staff')} value={item.email_staff || ""} onChange={(e) => props.handleChangeEmailStaff(e, i)}
          />

        </Box>
      ))}
      <Stack onClick={() => props.handleAddStaff()} direction={'row'} alignItems={'center'} sx={{ cursor: 'pointer', mt: 2 }}>
        <AddIcon sx={{
          fontSize: 20, color: "#2457B9"
        }} />
        <Typography className="w-700" style={{ fontSize: 16, color: '#2457B9' }}>
          {translate('status.invitestafflain')}
        </Typography>
      </Stack>
    </Grid>
  );
}