import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Card, Grid, Hidden, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import dayjs from 'dayjs';
import { React, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { config } from '../../services/constants';
import { DisplayPDFCompany } from '../DisplayPDFCompany';
import DrawerUploadDoc from '../DrawerUploadDocSingleLayerPerizinan';
import DropZoneUnggah from '../DropZoneUnggahSinglePerizinan';
import ModalUnggahDoc from '../ModalUnggahDocBaruSingleLayerPerizinan';

function DateComponent(props) {
  const [translate] = useTranslation("global");
  const [openStartDate, setOpenStartDate] = useState(false)
  const [openEndDate, setOpenEndDate] = useState(false)

  return (
    <Grid container justifyContent='space-between' direction='row' spacing={1} sx={{ mb: '6px' }}>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <p className='c-08 s-1419 w-500 mb4'>{translate('status.tanggalmulai')}</p>
        <LocalizationProvider dateAdapter={props.AdapterDayjs} >
          <DatePicker
            sx={{ width: '100%' }}
            slots={{
              openPickerIcon: props.DateIcon
            }}
            open={openStartDate}
            onClose={() => setOpenStartDate(false)}
            slotProps={{
              textField: {
                placeholder: translate('status.pilihperiode'),
                InputProps: {
                  startAdornment:
                    <InputAdornment position="start">
                      <img src={props.calendarIcon} onClick={() => setOpenStartDate(true)} alt='' style={{ marginRight: '8px', cursor: 'pointer' }} />
                    </InputAdornment>
                }
              }
            }}
            maxDate={props.item.end_date ? dayjs(props.item.end_date) : null}
            value={props.item.start_date ? dayjs(props.item.start_date) : null}
            onChange={(e) => { props.handleChangeStartPerizinan(e, props.i); }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <p className='c-08 s-1419 w-500 mb4'>{translate('status.tanggalberakhir')}</p>
        <LocalizationProvider dateAdapter={props.AdapterDayjs} >
          <DatePicker
            sx={{ width: '100%' }}
            slots={{
              openPickerIcon: props.DateIcon
            }}
            open={openEndDate}
            onClose={() => setOpenEndDate(false)}
            minDate={props.item.start_date ? dayjs(props.item.start_date) : null}
            slotProps={{
              textField: {
                placeholder: translate('status.pilihperiode'),
                InputProps: {
                  startAdornment:
                    <InputAdornment position="start">
                      <img src={props.calendarIcon} onClick={() => setOpenEndDate(true)} alt='' style={{ marginRight: '8px', cursor: 'pointer' }} />
                    </InputAdornment>
                }
              }
            }}
            value={props.item.end_date ? dayjs(props.item.end_date) : null}
            onChange={(e) => { props.handleChangeEndPerizinan(e, props.i); }}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  )
}

function CardPerizinan(props) {

  const [openUnggah, setOpenUnggah] = useState(false)
  const [translate] = useTranslation("global");

  return (


    <>
      <Box key={`${props.i}_box`} sx={{ border: '1px solid var(--grey-stroke, #E8EBF2)', borderRadius: '12px', p: '16px', mb: '16px' }}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <h6 style={{ fontSize: 16 }} className=' w-700 mb-16'>{translate('status.perizinan')}</h6>
          <CloseIcon onClick={(e) => props.handleRemovePerizinan(props.i)} sx={{ height: 24, width: 24, cursor: 'pointer' }} ></CloseIcon>
        </Stack>
        {props.item.url_path_documents_perizinan === "" ? (
          <></>
        ) : (
          <Box>
            <Grid style={{ marginTop: "5px", marginBottom: "10px" }} container direction={'row'}>
              <Grid key={`${props.i}_grid`} item xs={12} sm={12} md={12} lg={12} >
                <Card sx={{ borderRadius: 2 }}>
                  <DisplayPDFCompany
                    url={`${config.baseURL}${props.item.url_path_documents_perizinan}`}
                    key_pdf={`${props.i}_pdf`}
                    ext={`${props.item.ext_path_documents_perizinan}`}
                    name={`${props.item.name_path_documents_perizinan}`}
                    delete={() => props.delete(props.i)}
                  />
                </Card>
              </Grid>
            </Grid>
            <Grid container justifyContent='space-between' direction='row' spacing={1} sx={{ mb: '6px' }}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <p className='c-08 s-1419 w-500 mb4'>{translate('status.namaperizinan')}</p>
                <TextField className='w-500' type='text' sx={{
                  width: '100%', input: {
                    fontSize: '14px', fontFamily: 'Satoshi-500'
                  },
                }}
                  placeholder={translate('login-register.nama')}
                  value={props.item.nama_perizinan} onChange={(e) => props.handleChangeNamaPerizinan(e, props.i)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <p className='c-08 s-1419 w-500 mb4'>{translate('status.noperizinan')}</p>
                <TextField className='w-500' type='number' sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                    display: "none",
                  },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  }, width: '100%', input: {
                    fontSize: '14px', fontFamily: 'Satoshi-500'
                  },
                }}
                  placeholder={translate('status.noperizinan')}
                  value={props.item.nomor_perizinan} onChange={(e) => props.handleChangeNomorPerizinan(e, props.i)}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent='space-between' direction='row' spacing={1} sx={{ mb: '6px' }}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <p className='c-08 s-1419 w-500 mb4'>{translate('status.kodewiup')}</p>
                <TextField className='w-500' type='number' sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                    display: "none",
                  },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  }, width: '100%', input: {
                    fontSize: '14px', fontFamily: 'Satoshi-500'
                  },
                }}
                  placeholder={translate('status.kodewiup')}
                  value={props.item.kode_wiup} onChange={(e) => props.handleChangeKodeWIUP(e, props.i)}

                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <p className='c-08 s-1419 w-500 mb4'>{translate('status.tahapankegiatan')}</p>
                <TextField className='w-500' type='text' sx={{
                  width: '100%', input: {
                    fontSize: '14px', fontFamily: 'Satoshi-500'
                  },
                }}
                  placeholder={translate('status.tahapankegiatan')}
                  value={props.item.tahap_kegiatan} onChange={(e) => props.handleChangeTahapanKegiatan(e, props.i)}

                />
              </Grid>
            </Grid>
            <Grid container justifyContent='space-between' direction='row' spacing={1} sx={{ mb: '6px' }}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <p className='c-08 s-1419 w-500 mb4'>{translate('status.komoditas')}</p>
                <TextField className='w-500' type='text' sx={{
                  width: '100%', input: {
                    fontSize: '14px', fontFamily: 'Satoshi-500'
                  },
                }}
                  placeholder={translate('status.komoditas')}
                  value={props.item.komoditas} onChange={(e) => props.handleChangeKomoditas(e, props.i)}

                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <p className='c-08 s-1419 w-500 mb4'>{translate('status.luas')}</p>
                <TextField className='w-500' step='any' type='number' sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                    display: "none",
                  },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  }, width: '100%', input: {
                    fontSize: '14px', fontFamily: 'Satoshi-500'
                  },
                }}
                  placeholder={translate('status.luas')}

                  value={props.item.luas} onChange={(e) => props.handleChangeLuas(e, props.i)}

                />
              </Grid>
            </Grid>

            <DateComponent calendarIcon={props.calendarIcon} item={props.item} AdapterDayjs={props.AdapterDayjs} handleChangeStartPerizinan={props.handleChangeStartPerizinan} handleChangeEndPerizinan={props.handleChangeEndPerizinan} i={props.i} DateIcon={props.DateIcon} />

            <Grid container justifyContent='space-between' direction='row' spacing={1} sx={{ mb: '6px' }}>
              {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <p className='c-08 s-1419 w-500 mb4'>{translate('status.tahapancnc')}</p>
                <TextField className='w-500' type='text' sx={{
                  width: '100%', mb: '24px', input: {
                    fontSize: '14px', fontFamily: 'Satoshi-500'
                  },
                }}
                  placeholder={translate('status.tahapancnc')}
                  value={props.item.tahapan_cnc} onChange={(e) => props.handleChangeTahapanCNC(e, props.i)}

                />
              </Grid> */}
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <p className='c-08 s-1419 w-500 mb4'>{translate('status.lokasi')}</p>
                <TextField className='w-500' type='text' sx={{
                  width: '100%', mb: '24px', input: {
                    fontSize: '14px', fontFamily: 'Satoshi-500'
                  },
                }}
                  placeholder={translate('status.lokasi')}
                  value={props.item.detail_location} onChange={(e) => props.handleChangeLokasi(e, props.i)}

                />
              </Grid>
            </Grid>
          </Box>
        )}
        {props.item.url_path_documents_perizinan === "" &&
          <>
            <DropZoneUnggah indexed={props.i} handleUpload={props.handleChangeFilePerizinan} />
          </>
        }
      </Box>


      <Hidden lgDown><ModalUnggahDoc indexed={props.i} open={openUnggah} close={props.handleChangeFilePerizinan} closeModal={() => setOpenUnggah(false)}></ModalUnggahDoc>
      </Hidden>

      <Hidden lgUp><DrawerUploadDoc indexed={props.i} open={openUnggah} close={props.handleChangeFilePerizinan} closeModal={() => setOpenUnggah(false)}></DrawerUploadDoc>
      </Hidden>
    </>
  )
}

export default function MenuFormPerizinan(props) {

  const [translate] = useTranslation("global");
  const [perizinan, setPerizinan] = useState(props.perizinan)

  useEffect(() => {
    setPerizinan(props.perizinan)
  }, [props.perizinan])

  return (
    <Grid item xs={12} sm={12} md={4} lg={4} className='form-input'>
      {perizinan.map((item, i) => (
        <CardPerizinan handleRemovePerizinan={props.handleRemovePerizinan} item={item} i={i} delete={props.handleDeletePerizinan} handleChangeFilePerizinan={props.handleChangeFilePerizinan} handleChangeNamaPerizinan={props.handleChangeNamaPerizinan} handleChangeNomorPerizinan={props.handleChangeNomorPerizinan} handleChangeKodeWIUP={props.handleChangeKodeWIUP} handleChangeTahapanKegiatan={props.handleChangeTahapanKegiatan} handleChangeKomoditas={props.handleChangeKomoditas} handleChangeLuas={props.handleChangeLuas} calendarIcon={props.calendarIcon} handleChangeTahapanCNC={props.handleChangeTahapanCNC} handleChangeLokasi={props.handleChangeLokasi} handleChangeStartPerizinan={props.handleChangeStartPerizinan} handleChangeEndPerizinan={props.handleChangeEndPerizinan} handleAddPerizinan={props.handleAddPerizinan} doc_icon={props.doc_icon} AdapterDayjs={props.AdapterDayjs} DateIcon={props.DateIcon} />
      ))}
      <Stack onClick={() => props.handleAddPerizinan()} direction={'row'} alignItems={'center'} sx={{ cursor: 'pointer', mt: 2 }}>
        <AddIcon sx={{
          fontSize: 20, color: "#2457B9"
        }} />
        <Typography className="w-700" style={{ fontSize: 16, color: '#2457B9' }}>
          {translate('status.tambahdata')}
        </Typography>
      </Stack>
    </Grid>
  );
}

