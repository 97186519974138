import { Box, IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import recentIcon from "../assets/recent.png"
import searchIcon from "../assets/search-explore-ic.png"
import trashIcon from "../assets/trash.png"
import { addKeyword, deleteKeyword, getKeyword } from "../services/api"

export default function SearchNavbar(props) {
    const [translate,] = useTranslation("global");
    const [searchValue, setSearchValue] = useState('');
    const [searchData, setSearchData] = useState([]);
    const navigate = useNavigate()

    const deleteKeywordUser = async (id, all) => {
        try {
            if (all === true) {
                let params = {
                    id_keyword: id,
                    all: 'true'
                }
                let response = await deleteKeyword(params)
                if (response.status === 200) {
                    await getUserKeyword()
                }
            } else {
                let params = {
                    id_keyword: id
                }
                let response = await deleteKeyword(params)
                if (response.status === 200) {
                    await getUserKeyword()
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getUserKeyword = async () => {
        try {
            if (localStorage.getItem("token") !== null) {
                let responAPI = await getKeyword()

                setSearchData(responAPI.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleKeyDown = async (event) => {
        if (event.key === 'Enter') {
            event.target.blur();

            let params = { value: searchValue }
            let response = await addKeyword(params)
            let path = window.location.pathname
            if (searchValue.includes("coal") || searchValue.includes("batubara")) {
                navigate(`/explore`, { state: { detail: 'coal', search: `${searchValue}` } })
                if (path === '/explore') {
                    window.location.reload()
                }
            } else if (searchValue.includes("nickel") || searchValue.includes("nikel")) {
                navigate(`/explore`, { state: { detail: 'nikel', search: `${searchValue}` } })
                if (path === '/explore') {
                    window.location.reload()
                }
            } else if (searchValue.includes("barge") || searchValue.includes("tongkang")) {
                navigate(`/explore`, { state: { detail: 'tongkang', search: `${searchValue}` } })
                if (path === '/explore') {
                    window.location.reload()
                }
            } else if (searchValue.includes("company") || searchValue.includes("perusahaan")) {
                navigate(`/explore`, { state: { detail: 'company', search: `${searchValue}` } })
                if (path === '/explore') {
                    window.location.reload()
                }
            } else if (searchValue.includes("trader") || searchValue.includes("orang")) {
                navigate(`/explore`, { state: { detail: 'trader', search: `${searchValue}` } })
                if (path === '/explore') {
                    window.location.reload()
                }
            } else {
                navigate(`/explore`, { state: { detail: 'coal', search: `${searchValue}` } })
                if (path === '/explore') {
                    window.location.reload()
                }
            }
            getUserKeyword()
        }
    }

    const handleClick = async (e) => {
        let path = window.location.pathname
        if (e.includes("coal") || e.includes("batubara")) {
            navigate(`/explore`, { state: { detail: 'coal', search: `${e}` } })
            if (path === '/explore') {
                window.location.reload()
            }
        } else if (e.includes("nickel") || e.includes("nikel")) {
            navigate(`/explore`, { state: { detail: 'nikel', search: `${e}` } })
            if (path === '/explore') {
                window.location.reload()
            }
        } else if (e.includes("barge") || e.includes("tongkang")) {
            navigate(`/explore`, { state: { detail: 'tongkang', search: `${e}` } })
            if (path === '/explore') {
                window.location.reload()
            }
        } else if (e.includes("company") || e.includes("perusahaan")) {
            navigate(`/explore`, { state: { detail: 'company', search: `${e}` } })
            if (path === '/explore') {
                window.location.reload()
            }
        } else if (e.includes("trader") || e.includes("orang")) {
            navigate(`/explore`, { state: { detail: 'trader', search: `${e}` } })
            if (path === '/explore') {
                window.location.reload()
            }
        } else {
            navigate(`/explore`, { state: { detail: 'coal', search: `${e}` } })
            if (path === '/explore') {
                window.location.reload()
            }
        }
        getUserKeyword()
    }

    useEffect(() => {
        getUserKeyword()
    }, [])

    return (
        <Box sx={{ borderRadius: '8px', p: '16px', background: 'white' }}>
            <TextField
                className="search-filter-box"

                type="search"
                placeholder={translate('homepage.placeholder-search')}
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
                sx={{
                    width: '1064px', fontSize: 8, bgcolor: 'white', borderRadius: 2, input: {
                        fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                }}

                InputProps={{
                    style: {
                        paddingRight: '10px',
                    },
                    startAdornment: (
                        <InputAdornment position="start">
                            <img alt="search-icon" src={searchIcon} height={20} width={20}></img>
                        </InputAdornment>
                    ),
                }}
                onKeyDown={handleKeyDown}
            />
            <Stack sx={{ mt: '22px' }} direction={'row'} justifyContent={'space-between'}>
                <Typography className="w-700" sx={{ color: '#818CA7', fontSize: 12, }}>
                    {translate('navbar.terakhirdicari')}
                </Typography>
                <Typography onClick={() => { deleteKeywordUser(1, true) }} className="w-700" sx={{ color: '#818CA7', fontSize: 12, cursor: 'pointer' }}>
                    {translate('navbar.hapuspencarian')}
                </Typography>
            </Stack>
            {searchData.map((item) => (
                <Stack key={item.keyword_id} direction={'row'} justifyContent={'space-between'}>
                    <Stack direction={'row'} alignItems={'center'} className='pointer'>
                        <IconButton >
                            <img style={{ borderRadius: "100%", objectFit: "cover" }} alt="img-icon" src={recentIcon} height={20} width={20}></img>
                        </IconButton>
                        <Typography className="w-700" sx={{ color: '#081130', fontSize: 14, }} onClick={() => { handleClick(item.value) }}>
                            {item.value}
                        </Typography>
                    </Stack>

                    <IconButton onClick={() => { deleteKeywordUser(item.keyword_id, false) }} sx={{ mr: '24px' }}>
                        <img style={{ borderRadius: "100%", objectFit: "cover" }} alt="img-icon" src={trashIcon} height={20} width={20}></img>
                    </IconButton>
                </Stack>
            ))}
        </Box>
    )
}
