import Divider from "@material-ui/core/Divider";
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, CircularProgress, Grid, Hidden, Stack, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import stepcompleteIcon from "../assets/company/step-complete-ic.png";
import step1Icon from "../assets/company/step1-icon.png";
import step2IconActive from "../assets/company/step2-active-ic.png";
import step2Icon from "../assets/company/step2-inactive-ic.png";
import step3IconActive from "../assets/company/step3-active-ic.png";
import step3Icon from "../assets/company/step3-inactive-ic.png";
import step4IconActive from "../assets/company/step5-active-ic.png";
import step4Icon from "../assets/company/step5-inactive-ic.png";
import DefaultProfileImg from "../assets/default-img-profile.png";
import doc_icon from "../assets/doc.svg";
import calendarIcon from '../assets/material-symbols_calendar-today.svg';
import perusahaanIcon from "../assets/perusahaan.svg";
import UploadProfileIcon from "../assets/uploadprofile-ic.png";
import Footer from "../components/Footer";
import ModalBatalForm from '../components/ModalBatalForm';
import ModalSuccessCreateCompany from '../components/ModalSuccessCreateCompany';
import NavBar from "../components/Navbar";
import MenuFormAddress from "../components/company/MenuFormAddress";
import MenuFormAktaCompany from "../components/company/MenuFormAktaCompany";
import MenuFormDetailCompany from "../components/company/MenuFormDetailCompany";
import MenuFormDireksi from "../components/company/MenuFormDireksi";
import MenuFormNPWP from "../components/company/MenuFormNPWP";
import MenuFormPerizinan from "../components/company/MenuFormPerizinan";
import MenuInviteStaff from '../components/company/MenuInviteStaff.js';
import MenuPemegangSaham from "../components/company/MenuPemegangSaham";
import { UpdateCompany, createCompany, getAllFlags, getAllUserTrader, getCity, getDireksiPosisi, getJenisPerusahaan, getJenisSaham, getLabelAlamat, getProvince } from "../services/api.js";
import { config } from '../services/constants';
import { DateIcon, capitalizeEachWord, convertText } from '../services/primary';

export default function CreateCompany() {
  const navigate = useNavigate()
  const location = useLocation();
  const [translate] = useTranslation("global");
  const [step, setStep] = React.useState('step1');
  const [name, setName] = useState("")
  const [alternative_name, setalternativename] = useState("")
  const [nib, setnib] = useState("")
  const [email, setEmail] = useState("")
  const [website, setWebsite] = useState("")
  const [description, setDesc] = useState("")
  const [phone_number, setPhone_number] = useState("")
  const [bidang_perusahaan, setBidang_perusahaan] = useState("")
  const [id_provinsi] = useState("")
  const [id_city] = useState("")
  const [kode_pos, setKode_pos] = useState("")
  const [imgprofile, setImgProfile] = useState("")
  const [urlprofile, setUrlprofile] = useState("")
  const [akta, setUploadAkta] = useState([])
  const [npwp, setUploadnpwp] = useState([])
  const [listOfProvince, setListProvince] = useState([])
  const [listOfcity, setListCity] = useState([])
  const [traderList, setTraderList] = useState([])
  const [id_company, setId_company] = useState("")
  const [name_company, setname_company] = useState("")
  const [selecttype, setselecttype] = useState('')
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [isLoadingInviteNanti, setIsLoadingInviteNanti] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [disable, setDisable] = useState(false)

  const [id_bendera] = useState("")
  const [flagList, setFlagList] = useState([]);
  const [CurrentAddress, setCurrentAddress] = useState(false);
  const [direksi, setDireksi] = useState([{ profile_picture: "", name: "", position: "", start_period: "", end_period: "" }])
  const [pemegangsaham, setpemegangsaham] = useState([{ jenis_saham: '', name: '', id_negara: '', persentase: '' }])
  const [arrayaddress, setarrayAddress] = useState([{ detail_alamat: "", label_alamat: "", id_provinsi: '', id_kota: '', kode_pos: '', alamat_sekarang: false }])
  const [perizinan, setperizinan] = useState([{ nama_perizinan: "", nomor_perizinan: "", kode_wiup: "", tahap_kegiatan: "", komoditas: "", luas: "", start_date: "", end_date: "", tahapan_cnc: "", detail_location: "", url_path_documents_perizinan: "", name_path_documents_perizinan: "", ext_path_documents_perizinan: "", modi_id: "", jenis_operasi: "" }])
  const [staff, setStaff] = useState([{ name_staff: "", email_staff: "" }])

  const [idjenisperusahaan, setIdPerusahaan] = useState("")
  const [jenisperusahaan, setjenisperusahaan] = useState([])
  const [posisiperusahaan, setposisiperusahaan] = useState([])
  const [urldireksi,] = useState([])
  const [jenissaham, setjenissaham] = useState([])
  const [id_posisi, setidposisi] = useState("")
  const [docperizinan, setUploadPerizinan] = useState([])
  const [urlPerizinan, setUrlPerizinan] = useState([])

  const [label_alamat, setLabel_alamat] = useState([])
  const [idlabelalamat, setidlabelalamat] = useState("")
  const [openStartDate, setopenStartDate] = useState(false);
  const [openEndDate, setopenEndDate] = useState(false);
  const [startdate, setstartdate] = React.useState('');
  const [enddate, setenddate] = React.useState('');

  const [isOpenUploadAkta, setOpenUploadAkta] = React.useState(false)
  const [akta_nomor, setakta_nomor] = useState("")
  const [akta_description, setakta_description] = useState("")
  const [isOpenUploadNpwp, setOpenUploadNpwp] = React.useState(false)
  const [npwp_nomor, setnpwp_nomor] = useState("")
  const [npwp_name, setnpwpname] = useState("")
  const [npwp_description, setnpwp_description] = useState("")
  const [x] = useState(location?.state === null ? null : location.state.data)
  const [page] = useState(location?.state === null ? null : location.state.page)
  const [alamat_list, setalamatList] = useState([])
  const [openModalBatalForm, setOpenModalBatalForm] = useState(false);
  const [id_deleted_perizinan, setid_deleted_perizinan] = useState("");
  const [id_deleted_direksi, setid_deleted_direksi] = useState("");
  const [id_deleted_saham, setid_deleted_saham] = useState("");
  const [id_deleted_address, setid_deleted_address] = useState("");

  const [triggerValidation, settriggerValidation] = useState(0);

  useEffect(() => {
    getDataProvince();
    getTraderAll()
    getFlags()
    getDataDireksiPosisi()
    getDataJenisPerusahaan()
    getDataJenisSaham()
    getDatagetLabelAlamat()
    updateField();

  }, []);

  function updateField() {

    if (x !== null) {
      setarrayAddress(x?.alamat_list)
      setDesc(x?.description)
      setalamatList(x?.alamat_list)
      setnib(x?.nomor_induk_berusaha)
      setIdPerusahaan(x?.jenis_perusahaan)
      setName(x.name)
      setalternativename(x.alternative_name)
      setBidang_perusahaan(x.bidang_perusahaan)
      setselecttype(x.bidang_perusahaan)
      setPhone_number(x.phone_number)
      setEmail(x.email)
      setWebsite(x.website)
      setUploadAkta(x.akta_path_documents)
      setakta_nomor(x.akta_nomor)
      setakta_description(x.akta_description)
      setUploadnpwp(x.npwp_path_documents)
      setnpwp_nomor(x.npwp_nomor)
      setnpwp_description(x.npwp_description)
      setnpwpname(x.npwp_nama)
      setpemegangsaham(x.pemilik_saham_list)
      setDireksi(x.direksi_list)
      setImgProfile(x.picture_path.url)
      setUrlprofile(x.picture_path.url)
      let newPerizinan = []
      if (x.perizinan_list) {
        for (let i = 0; i < x.perizinan_list.length; i++) {
          let element = x.perizinan_list[i];

          element = {
            ...element,
            url_path_documents_perizinan: element.path_documents[0]?.url,
            name_path_documents_perizinan: element.path_documents[0]?.name,
            ext_path_documents_perizinan: element.path_documents[0]?.ext,
          }
          newPerizinan.push(element)
        }
      }
      setperizinan(newPerizinan)
    }
  }

  const handleOpenBatalForm = () => {
    setOpenModalBatalForm(true)
  };

  async function getDataProvince() {
    const response = await getProvince();
    setListProvince(response.data.data)
  }

  async function getDataDireksiPosisi() {
    const response = await getDireksiPosisi();
    setposisiperusahaan(response.data.data)
  }

  async function getDatagetLabelAlamat() {
    const response = await getLabelAlamat();
    setLabel_alamat(response.data.data)
  }

  async function getDataJenisPerusahaan() {
    const response = await getJenisPerusahaan();
    setjenisperusahaan(response.data.data)
  }

  async function getDataJenisSaham() {
    const response = await getJenisSaham();
    setjenissaham(response.data.data)
  }

  async function getDataCity(e) {
    const response = await getCity(parseInt(e));
    setListCity(response.data?.data)
  }

  async function getTraderAll() {
    try {
      const response = await getAllUserTrader("");
      setTraderList(response.data.data.data.data);
    } catch (e) {
      console.log(e);
    }
  }

  const handleStep = (state) => {
    setStep(state);
  };

  const handleChangeBidangPerusahaan = (e, i) => {
    setBidang_perusahaan(e.target.value)
    setselecttype(e.target.value)
  }

  async function handleChangeFileProfile(e) {
    const file = e.target.files[0]
    let tokenValue = localStorage.getItem("token")

    let formData = new FormData();
    formData.append('files', file);
    let response = await axios.post(`${config.baseURL}/api/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${tokenValue}`
      },
    })


    setImgProfile(response?.data[0].url)
    setUrlprofile(response?.data[0].url)
  }

  //direksi function
  const handleAddDireksi = () => {
    const datadireksi = [...direksi, { profile_picture: "", name: "", position: "", start_period: "", end_period: "" }]
    setDireksi(datadireksi)
  }

  let handleChangeDireksi = (e, i) => {
    let newDireksiValues = [...direksi];
    newDireksiValues[i].name = e.target.value;
    setDireksi(newDireksiValues);
  }

  function handleChangeStartPeriode(e, i) {
    setstartdate(e)
    let newDireksiValues = [...direksi];
    newDireksiValues[i].start_period = e;
    setDireksi(newDireksiValues);
  }

  function handleChangeEndPeriode(e, i) {
    setenddate(e)
    let newDireksiValues = [...direksi];
    newDireksiValues[i].end_period = e;
    setDireksi(newDireksiValues);
  }

  function handleChangePosisiDireksi(e, i) {
    setidposisi(e.target.value)
    let newDireksiValues = [...direksi];
    newDireksiValues[i].position = e.target.value;
    setDireksi(newDireksiValues);
  }

  const handleDeleteDireksi = (i) => {
    const deletedireksi = [...direksi]
    if (direksi[i].id) {
      let list_delete = id_deleted_direksi
      if (list_delete === "") {
        setid_deleted_direksi(`${direksi[i].id}`)
      } else {
        setid_deleted_direksi(list_delete + `,${direksi[i].id}`)
      }
    }
    deletedireksi.splice(i, 1)
    setDireksi(deletedireksi)
  }

  async function handleChangeImgDireksi(e, i) {
    let tokenValue = localStorage.getItem("token")

    const file = e.target.files[0]
    let formData = new FormData();
    formData.append('files', file);
    let response = await axios.post(`${config.baseURL}/api/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${tokenValue}`
      },
    })

    let newDireksiValues = [...direksi];

    newDireksiValues[i].profile_picture = response?.data[0].url;
    setDireksi(newDireksiValues);
  }

  //direksi pemegang saham
  const handleAddPemegangSaham = () => {
    const datapemegangsaham = [...pemegangsaham, { jenis_saham: '', name: '', id_negara: '', persentase: '' }]
    setpemegangsaham(datapemegangsaham)
  }

  const handleChangePemegangSaham = (e, i) => {
    let newSahamValues = [...pemegangsaham];
    newSahamValues[i].name = e.target.value;
    setpemegangsaham(newSahamValues);
  }

  function handleChangeJenisSaham(e, i) {
    let newSahamValues = [...pemegangsaham];
    newSahamValues[i].jenis_saham = e.target.value;
    setpemegangsaham(newSahamValues);
  }

  const handleDeletePemegangSaham = (i) => {
    const deletepemegangsaham = [...pemegangsaham]
    if (pemegangsaham[i].id) {
      let list_delete = id_deleted_saham
      if (list_delete === "") {
        setid_deleted_saham(`${pemegangsaham[i].id}`)
      } else {
        setid_deleted_saham(list_delete + `,${pemegangsaham[i].id}`)
      }
    }
    deletepemegangsaham.splice(i)
    setpemegangsaham(deletepemegangsaham)
  }

  function handleSelectbendera(e, i) {
    let newSahamValues = [...pemegangsaham];
    newSahamValues[i].id_negara = e.id;
    setpemegangsaham(newSahamValues);
  }

  //STEP 2 Upload Document

  const handleOpenModalUploadAkta = () => {
    setOpenUploadAkta(true)
  };

  const handleCloseModalUploadAkta = (params, fileQ) => {
    if (params === 'submit') {
      setUploadAkta(fileQ)
    }
    setOpenUploadAkta(false);
  };

  const handleOpenModalUploadNpwp = () => {
    setOpenUploadNpwp(true)
  };

  const handleCloseModalUploadNpwp = (params, fileQ) => {
    if (params === 'submit') {
      // let newArray = []
      // let fileArrayCombine = newArray.concat(fileQ)
      setUploadnpwp(fileQ)
    }
    setOpenUploadNpwp(false);
  };

  const handleChangePercentaseSaham = (e, i) => {
    let newSahamValues = [...pemegangsaham];
    newSahamValues[i].persentase = e.target.value;
    setpemegangsaham(newSahamValues);
  }

  async function handleChangeFilePerizinan(e, i) {

    let newPerizinanValue = [...perizinan];
    newPerizinanValue[i].url_path_documents_perizinan = e.url;
    newPerizinanValue[i].name_path_documents_perizinan = e.name;
    newPerizinanValue[i].ext_path_documents_perizinan = e.ext;

    setperizinan(newPerizinanValue)
  }

  const handleChangeNamaPerizinan = (e, i) => {
    let newPerizinanValue = [...perizinan];
    newPerizinanValue[i].nama_perizinan = e.target.value;
    setperizinan(newPerizinanValue);
  }

  const handleChangeNomorPerizinan = (e, i) => {
    let newPerizinanValue = [...perizinan];
    newPerizinanValue[i].nomor_perizinan = e.target.value;
    setperizinan(newPerizinanValue);
  }

  const handleChangeKodeWIUP = (e, i) => {
    let newPerizinanValue = [...perizinan];
    newPerizinanValue[i].kode_wiup = e.target.value;
    setperizinan(newPerizinanValue);
  }

  const handleChangeTahapanKegiatan = (e, i) => {
    let newPerizinanValue = [...perizinan];
    newPerizinanValue[i].tahap_kegiatan = e.target.value;
    setperizinan(newPerizinanValue);
  }

  const handleChangeKomoditas = (e, i) => {
    let newPerizinanValue = [...perizinan];
    newPerizinanValue[i].komoditas = e.target.value;
    setperizinan(newPerizinanValue);
  }

  const handleChangeLuas = (e, i) => {
    let newPerizinanValue = [...perizinan];
    newPerizinanValue[i].luas = e.target.value;
    setperizinan(newPerizinanValue);
  }

  const handleChangeTahapanCNC = (e, i) => {
    let newPerizinanValue = [...perizinan];
    newPerizinanValue[i].tahapan_cnc = e.target.value;
    setperizinan(newPerizinanValue);
  }

  const handleChangeLokasi = (e, i) => {
    let newPerizinanValue = [...perizinan];
    newPerizinanValue[i].detail_location = e.target.value;
    setperizinan(newPerizinanValue);
  }

  function handleChangeStartPerizinan(e, i) {
    setstartdate(e)
    let newPerizinanValue = [...perizinan];
    newPerizinanValue[i].start_date = e;
    setperizinan(newPerizinanValue);
  }

  function handleChangeEndPerizinan(e, i) {
    let newPerizinanValue = [...perizinan];
    newPerizinanValue[i].end_date = e;
    setperizinan(newPerizinanValue);
  }

  const handleAddPerizinan = () => {
    const dataperizinan = [...perizinan, { nama_perizinan: "", nomor_perizinan: "", kode_wiup: "", tahap_kegiatan: "", komoditas: "", luas: "", start_date: "", end_date: "", tahapan_cnc: "", detail_location: "", url_path_documents_perizinan: "", name_path_documents_perizinan: "", ext_path_documents_perizinan: "", modi_id: "", jenis_operasi: "" }]
    setperizinan(dataperizinan)
  }

  const handleDeletePerizinan = (i) => {
    const deleteperizinan = [...perizinan]
    console.log(perizinan[i].id)
    deleteperizinan[i] = { nama_perizinan: "", nomor_perizinan: "", kode_wiup: "", tahap_kegiatan: "", komoditas: "", luas: "", start_date: "", end_date: "", tahapan_cnc: "", detail_location: "", url_path_documents_perizinan: "", name_path_documents_perizinan: "", ext_path_documents_perizinan: "", modi_id: "", jenis_operasi: "" }
    setperizinan(deleteperizinan)
  }

  const handleRemovePerizinan = (i) => {
    const deleteperizinan = [...perizinan]
    if (perizinan[i].id) {
      let list_delete = id_deleted_perizinan
      if (list_delete === "") {
        setid_deleted_perizinan(`${perizinan[i].id}`)
      } else {
        setid_deleted_perizinan(list_delete + `,${perizinan[i].id}`)
      }
    }
    deleteperizinan.splice(i, 1)
    setperizinan(deleteperizinan)
  }

  //STEP 3 Alamat
  let handleAddAddress = () => {
    const dataaddress = [...arrayaddress, { detail_alamat: "", label_alamat: "", id_provinsi: '', id_kota: '', kode_pos: '', alamat_sekarang: false }]
    setarrayAddress(dataaddress)
  }

  let handleDeleteAddress = (i) => {
    const deleteaddress = [...arrayaddress]
    if (arrayaddress[i].id) {
      let list_delete = id_deleted_address
      if (list_delete === "") {
        setid_deleted_address(`${arrayaddress[i].id}`)
      } else {
        setid_deleted_address(list_delete + `,${arrayaddress[i].id}`)
      }
    }
    deleteaddress.splice(i, 1)
    setarrayAddress(deleteaddress)
  }

  function handleChangeAlamatLengkap(e, i) {
    let newAddress = [...arrayaddress];
    newAddress[i].detail_alamat = e.target.value;
    setarrayAddress(newAddress);
  }
  function handleChangeLabel(e, i) {
    let newAddress = [...arrayaddress];
    newAddress[i].label_alamat = e.target.value;
    setarrayAddress(newAddress);
  }

  const changeProvince = (e, i) => {
    let newAddress = [...arrayaddress];
    newAddress[i].id_provinsi = e.target.value;
    setarrayAddress(newAddress);
    getDataCity(newAddress[i].id_provinsi)
  }

  const changeCity = (e, i) => {
    let newAddress = [...arrayaddress];
    newAddress[i].id_kota = e.target.value;
    setarrayAddress(newAddress);
  }

  function handleChangeKodepos(e, i) {
    let newAddress = [...arrayaddress];
    newAddress[i].kode_pos = e.target.value;
    setarrayAddress(newAddress);
  }

  function handleCurrentAddress(e, i) {
    let newAddress = [...arrayaddress];
    newAddress[i].alamat_sekarang = e.target.checked;
    setarrayAddress(newAddress);

  }

  //Step 5 Invite Staff
  let handleChangeNameStaff = (e, i) => {
    let newStaffValues = [...staff];
    newStaffValues[i].name_staff = e.target.value;
    setStaff(newStaffValues);
  }

  let handleChangeEmailStaff = (e, i) => {
    let newStaffValues = [...staff];
    newStaffValues[i].email_staff = e.target.value;
    setStaff(newStaffValues);
  }

  let handleAddStaff = () => {
    const dataStaff = [...staff, { name_staff: "", email_staff: "" }]
    setStaff(dataStaff)
  }

  let handleDeleteStaff = (i) => {
    const deletestaff = [...staff]
    deletestaff.splice(i, 1)
    setStaff(deletestaff)
  }



  async function submit(e, stepstatus) {

    let data = {
      nomor_induk_berusaha: nib,
      picture_path: imgprofile,
      jenis_perusahaan: idjenisperusahaan,
      name: name,
      bidang_perusahaan: bidang_perusahaan,
      email: email, website: website,
      phone_number: phone_number.indexOf("+62") === 0 ? phone_number : "+62" + phone_number,
      description: description,
      akta_nomor: akta_nomor,
      akta_description: akta_description,
      akta_path_documents: JSON.stringify(akta),
      npwp_nama: npwp_name,
      npwp_nomor: npwp_nomor,
      npwp_description: npwp_description,
      npwp_path_documents: JSON.stringify(npwp),
      kode_perusahaan: '',
      address: JSON.stringify(arrayaddress),
      direksi: JSON.stringify(direksi),
      saham: JSON.stringify(pemegangsaham),
      perizinan: JSON.stringify(perizinan),
      invite_staff: JSON.stringify(staff),
      alternative_name: alternative_name
    }

    if (stepstatus === 'step1') {

      let validation = triggerValidation
      settriggerValidation(validation + 1)

      let validationCount = 0
      let newDireksi = JSON.parse(data.direksi)
      let newSaham = JSON.parse(data.saham)

      if (
        data.nomor_induk_berusaha === '' ||
        data.picture_path === '' ||
        data.jenis_perusahaan === "" ||
        data.name === '' ||
        data.bidang_perusahaan === '' ||
        data.phone_number === '+62' ||
        data.email === '' ||
        data.alternative_name === '' ||
        newDireksi.length === 0 ||
        newSaham.length === 0) {
        validationCount++
        console.log(validationCount)
      }

      for (let i = 0; i < newDireksi.length; i++) {
        const element = newDireksi[i];
        if (
          element.profile_picture === '' ||
          element.name === '' ||
          element.position === '' ||
          element.start_period === "" ||
          element.end_period === "") {
          validationCount++
          console.log(validationCount)
        }
      }

      for (let i = 0; i < newSaham.length; i++) {
        const element = newSaham[i];
        if (
          element.jenis_saham === '' ||
          element.name === '' ||
          element.id_negara === '' ||
          element.persentase === ''
        ) {
          validationCount++
          console.log(validationCount)
        }
      }
      console.log(validationCount)
      if (validationCount > 0) {
        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('status.Fieldkosong'),
        })
      } else {
        handleStep("step2")
        settriggerValidation(0)
      }

    } else if (stepstatus === 'step2') {

      let validation = triggerValidation
      settriggerValidation(validation + 1)

      if (
        data.akta_nomor === '' ||
        data.akta_description === '' ||
        data.akta_path_documents === '[]' ||
        data.npwp_nomor === '' ||
        data.npwp_description === '' ||
        data.npwp_path_documents === '[]' ||
        data.npwp_nama === '') {
        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('status.Fieldkosong'),
        })
      } else {
        // const [perizinan, setperizinan] = useState([{ nama_perizinan: "", nomor_perizinan: "", kode_wiup: "", tahap_kegiatan: "", komoditas: "", luas: "", start_date: "", end_date: "", tahapan_cnc: "", detail_location: "", url_path_documents_perizinan: "", name_path_documents_perizinan: "", ext_path_documents_perizinan: "", modi_id: "", jenis_operasi: "" }])
        // if (data.perizinan[0].nama_perizinan === "")
        handleStep("step3")
        settriggerValidation(0)
      }

    } else if (stepstatus === 'step3') {

      let validation = triggerValidation
      settriggerValidation(validation + 1)

      let validationCount = 0
      let newAddress = JSON.parse(data.address)

      if (newAddress.length === 0) {
        validationCount++
      }

      for (let i = 0; i < newAddress.length; i++) {
        const element = newAddress[i];
        if (
          element.detail_alamat === '' ||
          element.label_alamat === '' ||
          element.id_provinsi === '' ||
          element.kode_pos === '' ||
          element.id_kota === ''
        ) {
          validationCount++
        }
      }

      if (validationCount > 0) {
        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('status.Fieldkosong'),
        })
      } else {
        handleStep("step4")
        settriggerValidation(0)
      }

      // if (
      //   data.address[0].detail_alamat === '' ||
      //   data.address[0].label_alamat === '' ||
      //   data.address[0].id_provinsi ||
      //   data.address[0].id_kota ||
      //   data.address[0].kode_pos === '') {
      //   Swal.fire({
      //     icon: 'error',
      //     title: translate('status.sorry'),
      //     text: translate('status.Fieldkosong'),
      //   })
      // } else {
      //   handleStep("step4")
      // }

    } else {
      if (stepstatus === 'final-inviteNanti') {
        setStaff([]);
        setIsLoadingInviteNanti(true)
      }

      if (stepstatus === 'final-submit') {
        setIsLoadingSubmit(true)
      }

      setDisable(true)

      const res = await createCompany(data)
      if (res.status === 200) {
        setOpenModal(true)
        setId_company(res?.data?.data?.id)
        setname_company(res?.data?.data?.name)

      } else {
        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: `${res?.error?.message}`,
        })
      }

      if (stepstatus === 'final-inviteNanti') {
        setIsLoadingInviteNanti(false)
      }

      if (stepstatus === 'final-submit') {
        setIsLoadingSubmit(false)
      }
      setDisable(false)
    }
  }

  async function submitEditCompany(e, stepstatus) {

    let data = {
      id_company: x.id,
      is_revision: x.status === "Approve" ? false : true,
      nomor_induk_berusaha: nib,
      picture_path: imgprofile,
      jenis_perusahaan: idjenisperusahaan,
      name: name,
      bidang_perusahaan: bidang_perusahaan,
      email: email, website: website,
      phone_number: phone_number.indexOf("+62") === 0 ? phone_number : "+62" + phone_number,
      description: description,
      akta_nomor: akta_nomor,
      akta_description: akta_description,
      akta_path_documents: JSON.stringify(akta),
      npwp_nama: npwp_name,
      npwp_nomor: npwp_nomor,
      npwp_description: npwp_description,
      npwp_path_documents: JSON.stringify(npwp),
      kode_perusahaan: '',
      address: JSON.stringify(arrayaddress),
      direksi: JSON.stringify(direksi),
      saham: JSON.stringify(pemegangsaham),
      perizinan: JSON.stringify(perizinan),
      invite_staff: JSON.stringify(staff),
      alternative_name: alternative_name,
      id_deleted_perizinan: id_deleted_perizinan,
      id_deleted_address: id_deleted_address,
      id_deleted_saham: id_deleted_saham,
      id_deleted_direksi: id_deleted_direksi
    }

    if (stepstatus === 'step1') {

      let validation = triggerValidation
      settriggerValidation(validation + 1)

      let validationCount = 0
      let newDireksi = JSON.parse(data.direksi)
      let newSaham = JSON.parse(data.saham)

      if (
        data.nomor_induk_berusaha === '' ||
        data.picture_path === '' ||
        data.jenis_perusahaan === "" ||
        data.name === '' ||
        data.bidang_perusahaan === '' ||
        data.phone_number === '+62' ||
        data.email === '' ||
        data.alternative_name === '' ||
        data.saham.length === 0 ||
        data.direksi.length === 0) {
        validationCount++
      }

      for (let i = 0; i < newDireksi.length; i++) {
        const element = newDireksi[i];
        if (
          element.profile_picture === '' ||
          element.name === '' ||
          element.position === '' ||
          element.start_period === "" ||
          element.end_period === "") {
          validationCount++
        }
      }

      for (let i = 0; i < newSaham.length; i++) {
        const element = newSaham[i];
        if (
          element.jenis_saham === '' ||
          element.name === '' ||
          element.id_negara === '' ||
          element.persentase === ''
        ) {
          validationCount++
        }
      }

      if (validationCount > 0) {
        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('status.Fieldkosong'),
        })
      } else {
        handleStep("step2")
        settriggerValidation(0)
      }

    } else if (stepstatus === 'step2') {

      let validation = triggerValidation
      settriggerValidation(validation + 1)

      if (
        data.akta_nomor === '' ||
        data.akta_description === '' ||
        data.akta_path_documents === '[]' ||
        data.npwp_nomor === '' ||
        data.npwp_description === '' ||
        data.npwp_path_documents === '[]' ||
        data.npwp_nama === '') {
        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('status.Fieldkosong'),
        })
      } else {
        handleStep("step3")
        settriggerValidation(0)
      }

    } else if (stepstatus === 'step3') {

      let validation = triggerValidation
      settriggerValidation(validation + 1)

      let validationCount = 0
      let newAddress = JSON.parse(data.address)

      if (newAddress.length === 0) {
        validationCount++
      }

      for (let i = 0; i < newAddress.length; i++) {
        const element = newAddress[i];
        if (
          element.detail_alamat === "" ||
          element.label_alamat === '' ||
          element.id_provinsi === '' ||
          element.kode_pos === '' ||
          element.id_kota === ''
        ) {
          validationCount++
        }
      }

      if (validationCount > 0) {
        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('status.Fieldkosong'),
        })
      } else {
        handleStep("step4")
        settriggerValidation(0)
      }

    } else {
      if (stepstatus === 'final-inviteNanti') {
        setStaff([]);
        setIsLoadingInviteNanti(true)
      }

      if (stepstatus === 'final-submit') {
        setIsLoadingSubmit(true)
      }

      setDisable(true)

      const res = await UpdateCompany(data)
      if (res.status === 200) {
        Swal.fire({
          icon: 'success',
          title: translate('status.success'),
          text: translate('status.successeditcompany'),
        }).then(function () {
          window.location.href = `/detail/${capitalizeEachWord(convertText(x.name))}`
        });

      } else {
        Swal.fire({
          icon: 'error',
          title: translate('status.failed'),
          text: translate('status.subtitlefailed'),
        })
      }

      if (stepstatus === 'final-inviteNanti') {
        setIsLoadingInviteNanti(false)
      }

      if (stepstatus === 'final-submit') {
        setIsLoadingSubmit(false)
      }
      setDisable(false)
    }
  }

  const handleCloseModalBatalForm = () => {
    setOpenModalBatalForm(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false)
    window.location.href = `/detail/${capitalizeEachWord(convertText(name_company))}`
  }

  const handleOkModal = () => {
    setOpenModal(false)
    window.location.href = `/detail/${capitalizeEachWord(convertText(name_company))}`
  }


  const type_list = [{ bidang_perusahaan: 1, label: translate('page_explore.penambang') }, { bidang_perusahaan: 2, label: translate('page_explore.trader') }, { bidang_perusahaan: 3, label: translate('page_explore.pelayaran') }]
  let list_trader = []
  traderList.map((item) => {
    return (list_trader.push({
      id: item.user_id, label: item.name, email: item.email
    }))
  })


  async function getFlags() {
    try {
      const response = await getAllFlags()
      setFlagList(response.data?.data);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div>
      <div className='fixed-header'>
        <NavBar /></div>
      <Box className="flex-wrap-center width-100" >
        <Box className="content-inner" sx={{ mt: { xs: 8, sm: 8, md: 11, lg: 11, xl: 11 }, ml: { xs: '24px', lg: '80px', }, mr: { xs: '24px', lg: '80px', }, }}>
          {/* navigation Section */}
          <Hidden only={['xs', 'sm']}>
            <Grid container direction="row" alignItems='center' className='mt-18'>
              <Button href="/profile" sx={{ fontSize: { xs: 14, sm: 14, md: 16, lg: 16 } }} className="w-500 mb8 c-24 txt-none-tranform" variant="text"> Profile</Button>
              <Typography className="w-500 mb8 mr8 c-56 s-1622">
                /
              </Typography>

              <Typography className="w-500 mb8 c-56 s-1622">
                {translate('status.buatperusahaan')}
              </Typography>
            </Grid>
          </Hidden>
          <Hidden only={['md', 'lg', 'xl']}>
            <Grid container direction="row" alignItems='center' className='mt-18'>
              <Button href="/profile" sx={{ fontSize: { xs: 14, sm: 14, md: 16, lg: 16 } }} className="w-500 mb8 c-24 txt-none-tranform s-1420" variant="text"> Profile</Button>
              <Typography className="w-500 mb8  c-56 s-1422">
                /
              </Typography>
              <Button onClick={(e) => { navigate(`/explore`, { state: { detail: 'company' } }) }} className="w-500 mb8 c-24 txt-none-tranform s-1420" variant="text"> {translate('page_explore.perusahaan')}</Button>
              <Typography className="w-500 mb8 mr8 c-56 s-1422">
                /
              </Typography>
              <Typography className="w-500 mb8  c-56 s-1422">
                {translate('status.buatperusahaan')}
              </Typography>
            </Grid>
          </Hidden>

          {/* title Section */}
          <Grid container direction="column" alignItems="center" justifyContent="center" className='mt-18'>
            <img src={perusahaanIcon} alt='perusahaan-icon' />
            <Typography className="w-700 mt-16 mb-32 c-08" sx={{ fontSize: { xs: 24, lg: 32 } }}>
              {translate('status.buatperusahaan')}
            </Typography>
          </Grid>
          {/* stepbar Section */}
          <Hidden smDown>
            <Box className="tab-scroll-x" style={{ justifyContent: 'center', display: 'flex', marginBottom: '52px', minWidth: '700px' }}>
              <Stack className='relative' direction="row" alignItems={'center'} justifyContent="center" spacing={9}>
                <Stack direction="column" alignItems={'center'} sx={{}}>
                  {
                    step === 'step1' ?
                      <img alt="step-icon" src={step1Icon} height={58} width={58}></img> :
                      <img alt="step-icon" src={stepcompleteIcon} height={58} width={58}></img>
                  }
                  <Typography className="w-400 c-56 mt8 s-1622">
                    STEP 1
                  </Typography>
                  <Typography className="w-700 c-08 s-1622">
                    {translate('status.detailperusahaan')}
                  </Typography>
                </Stack>
                <Divider orientation="horizontal" style={{ height: '1px', width: '100px', position: 'absolute', top: '29px', left: '46px' }} />
                <Stack direction="column" alignItems={'center'} justifyContent={'center'} sx={{}}>
                  {
                    step === 'step1' &&
                    <img alt="step-icon" src={step2Icon} height={58} width={58}></img>
                  }
                  {
                    step === 'step2' &&
                    <img alt="step-icon" src={step2IconActive} height={58} width={58}></img>
                  }
                  {
                    step === 'step3' || step === 'step4' ?
                      <img alt="step-icon" src={stepcompleteIcon} height={58} width={58}></img> : <></>
                  }
                  <Typography className="w-400 c-56 mt8 s-1622">
                    STEP 2
                  </Typography>
                  <Typography className="w-700 c-08 s-1622">
                    {translate('status.uploaddokumen')}
                  </Typography>
                </Stack>
                <Divider orientation="horizontal" style={{ height: '1px', width: '100px', position: 'absolute', top: '29px', }} />
                <Stack direction="column" alignItems={'center'} justifyContent={'center'} sx={{}}>
                  {
                    step === 'step1' || step === 'step2' ?
                      <img alt="step-icon" src={step3Icon} height={58} width={58}></img> : <></>
                  }
                  {
                    step === 'step3' &&
                    <img alt="step-icon" src={step3IconActive} height={58} width={58}></img>
                  }
                  {
                    step === 'step4' &&
                    <img alt="step-icon" src={stepcompleteIcon} height={58} width={58}></img>
                  }
                  <Typography className="w-400 c-56 mt8 s-1622">
                    STEP 3
                  </Typography>
                  <Typography className="w-700 c-08 s-1622">
                    {translate('status.detailalamat')}
                  </Typography>
                </Stack>
                <Divider orientation="horizontal" style={{ height: '1px', width: '100px', position: 'absolute', top: '29px', right: '80px' }} />
                <Stack direction="column" alignItems={'center'} justifyContent={'center'} sx={{}}>
                  {
                    step === 'step1' || step === 'step2' || step === 'step3' ?
                      <img alt="step-icon" src={step4Icon} height={58} width={58}></img> : <></>
                  }
                  {
                    step === 'step4' &&
                    <img alt="step-icon" src={step4IconActive} height={58} width={58}></img>
                  }

                  <Typography className="w-400 c-56 mt8 s-1622">
                    STEP 4
                  </Typography>
                  <Typography className="w-700 c-08 s-1622">
                    Invite Staff
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Hidden>
          <Hidden smUp>
            <Box className="tab-scroll-x" style={{ display: 'flex', marginBottom: '52px', }}>
              <Stack className='relative' direction="row" alignItems={'center'} justifyContent="center"  >
                <Stack direction="column" alignItems={'center'} sx={{ width: '150px' }}>
                  {
                    step === 'step1' ?
                      <img alt="step-icon" src={step1Icon} height={48} width={48}></img> :
                      <img alt="step-icon" src={stepcompleteIcon} height={48} width={48}></img>
                  }
                  <Typography className="w-400 c-56 mt8 s-1420">
                    STEP 1
                  </Typography>
                  <Typography className="w-700 c-08 s-1420">
                    {translate('status.detailperusahaan')}
                  </Typography>
                </Stack>
                <Divider orientation="horizontal" style={{ height: '1px', width: '70px', position: 'absolute', top: '29px', left: '110px' }} />
                <Stack direction="column" alignItems={'center'} justifyContent={'center'} sx={{ width: '150px' }}>
                  {
                    step === 'step1' &&
                    <img alt="step-icon" src={step2Icon} height={48} width={48}></img>
                  }
                  {
                    step === 'step2' &&
                    <img alt="step-icon" src={step2IconActive} height={48} width={48}></img>
                  }
                  {
                    step === 'step3' || step === 'step4' ?
                      <img alt="step-icon" src={stepcompleteIcon} height={48} width={48}></img> : <></>
                  }
                  <Typography className="w-400 c-56 mt8 s-1420">
                    STEP 2
                  </Typography>
                  <Typography className="w-700 c-08 s-1420">
                    {translate('status.uploaddokumen')}
                  </Typography>
                </Stack>
                <Divider orientation="horizontal" style={{ height: '1px', width: '70px', position: 'absolute', top: '29px', }} />
                <Stack direction="column" alignItems={'center'} justifyContent={'center'} sx={{ width: '150px' }}>
                  {
                    step === 'step1' || step === 'step2' ?
                      <img alt="step-icon" src={step3Icon} height={48} width={48}></img> : <></>
                  }
                  {
                    step === 'step3' &&
                    <img alt="step-icon" src={step3IconActive} height={48} width={48}></img>
                  }
                  {
                    step === 'step4' &&
                    <img alt="step-icon" src={stepcompleteIcon} height={48} width={48}></img>
                  }
                  <Typography className="w-400 c-56 mt8 s-1420">
                    STEP 3
                  </Typography>
                  <Typography className="w-700 c-08 s-1420">
                    {translate('status.detailalamat')}
                  </Typography>
                </Stack>
                <Divider orientation="horizontal" style={{ height: '1px', width: '70px', position: 'absolute', top: '29px', right: '110px' }} />
                <Stack direction="column" alignItems={'center'} justifyContent={'center'} sx={{ width: '150px' }}>
                  {
                    step === 'step1' || step === 'step2' || step === 'step3' ?
                      <img alt="step-icon" src={step4Icon} height={48} width={48}></img> : <></>
                  }
                  {
                    step === 'step4' &&
                    <img alt="step-icon" src={step4IconActive} height={48} width={48}></img>
                  }

                  <Typography className="w-400 c-56 mt8 s-1420">
                    STEP 4
                  </Typography>
                  <Typography className="w-700 c-08 s-1420">
                    Invite Staff
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Hidden>
          {/* STEP1 */}
          {step === 'step1' ? <div>
            {/* Detail perusahaan Section */}
            <Grid container direction="row" justifyContent='space-between' >
              <Grid item xs={12} sm={12} md={3} lg={3} >
                <h6 style={{ fontSize: 24 }} className=' w-700 mb-16'>   {translate('status.detailperusahaan')}</h6>
                <p className='c-56 w-400 s-1421 mb-16'> {translate('status.subdetailperusahaan')}</p>
              </Grid>
              <MenuFormDetailCompany triggerValidation={triggerValidation} urlprofile={urlprofile} idjenisperusahaan={idjenisperusahaan} setIdPerusahaan={setIdPerusahaan} nib={nib} setnib={setnib} name={name} setName={setName} handleChangeFileProfile={handleChangeFileProfile} type_list={type_list} selecttype={selecttype} handleChangeBidangPerusahaan={handleChangeBidangPerusahaan} phone_number={phone_number} setPhone_number={setPhone_number} email={email} setEmail={setEmail} website={website} setWebsite={setWebsite} description={description} setDesc={setDesc} imgprofile={imgprofile} UploadProfileIcon={UploadProfileIcon} DefaultProfileImg={DefaultProfileImg} jenisperusahaan={jenisperusahaan} alternative_name={alternative_name} setalternativename={setalternativename} ></MenuFormDetailCompany>
              <Grid item xs={3} className='wd-252'>
              </Grid>
            </Grid>
            <Grid container className='line mt-24 mb-32'>

            </Grid>
            {/* Direksi Section */}
            <Grid container direction="row" justifyContent='space-between' >
              <Grid item xs={12} sm={12} md={3} lg={3} >
                <h6 style={{ fontSize: 24 }} className=' w-700 mb-16'> {translate('status.direksi')}</h6>
                <p className='c-56 w-400 s-1421 mb-16'>{translate('status.subdetailperusahaan')}</p>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} className='form-input'>
                {direksi.map((item, i) => (
                  <MenuFormDireksi triggerValidation={triggerValidation} item={item} i={i} direksi={direksi} handleDeleteDireksi={handleDeleteDireksi} handleChangeImgDireksi={handleChangeImgDireksi} posisiperusahaan={posisiperusahaan} handleChangeDireksi={handleChangeDireksi} handleAddDireksi={handleAddDireksi} AdapterDayjs={AdapterDayjs} DateIcon={DateIcon} openStartDate={openStartDate} openEndDate={openEndDate} startdate={startdate} handleChangeStartPeriode={handleChangeStartPeriode} enddate={enddate} handleChangeEndPeriode={handleChangeEndPeriode} setopenEndDate={setopenEndDate} setopenStartDate={setopenStartDate} DefaultProfileImg={DefaultProfileImg} UploadProfileIcon={UploadProfileIcon} calendarIcon={calendarIcon} urldireksi={urldireksi} id_posisi={id_posisi} handleChangePosisiDireksi={handleChangePosisiDireksi}></MenuFormDireksi>
                ))}
                <Stack onClick={() => handleAddDireksi()} direction={'row'} alignItems={'center'} sx={{ cursor: 'pointer', mt: 2 }}>
                  <AddIcon sx={{
                    fontSize: 20, color: "#2457B9"
                  }} />
                  <Typography className="w-700" style={{ fontSize: 16, color: '#2457B9' }}>
                    {translate('status.tambahdata')}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={3} className='wd-252'>
              </Grid>
            </Grid>
            <Grid container className='line mt-24 mb-56'>

            </Grid>
            {/* Pemegang Saham Section */}
            <Grid container direction="row" justifyContent='space-between' >
              <Grid item xs={12} sm={12} md={3} lg={3} >
                <h6 style={{ fontSize: 24 }} className=' w-700 mb-16'>{translate('status.pemegangsaham')}</h6>
                <p className='c-56 w-400 s-1421 mb-16'>{translate('status.subdetailperusahaan')}</p>
              </Grid>
              <MenuPemegangSaham triggerValidation={triggerValidation} pemegangsaham={pemegangsaham} handleDeletePemegangSaham={handleDeletePemegangSaham} jenissaham={jenissaham} handleChangePemegangSaham={handleChangePemegangSaham} id_bendera={id_bendera} handleSelectbendera={handleSelectbendera} flagList={flagList} handleAddPemegangSaham={handleAddPemegangSaham} handleChangeJenisSaham={handleChangeJenisSaham} handleChangePercentaseSaham={handleChangePercentaseSaham} ></MenuPemegangSaham>
              <Grid item xs={3} className='wd-252'>
              </Grid>
            </Grid>
            <Grid container className='line mt-24 mb-32'>

            </Grid>
            <Hidden smDown>
              <Grid className='mb-104 in-container' container justifyContent='space-between' direction='row'>
                <Button
                  onClick={() => {
                    handleOpenBatalForm();
                  }}
                  size="large"
                  className="w-500"
                  variant="text"
                  sx={{
                    borderRadius: '8px',
                    color: ' #2457B9',
                    textTransform: 'none',
                    p: '12px 16px',
                    height: '44px'
                  }}
                >
                  {translate('jual-beli.btn-cancel')}
                </Button>
                {page === 'edit-perusahaan' ? <Button
                  onClick={(e) => { submitEditCompany(e, 'step1') }}
                  size="large"
                  className="button-blue-contained w-500"
                  variant="contained"

                >
                  {translate('status.selanjutnya')}
                </Button> : <Button
                  onClick={(e) => { submit(e, 'step1') }}
                  size="large"
                  className="button-blue-contained w-500"
                  variant="contained"

                >
                  {translate('status.selanjutnya')}
                </Button>}
              </Grid>
            </Hidden>
            <Hidden smUp>
              <Grid spacing={1} container justifyContent='space-between' direction='row' style={{ marginBottom: '80px' }}>
                <Grid item xs={6}>
                  <Button
                    onClick={() => {
                      handleOpenBatalForm();
                    }}
                    fullWidth
                    size="small"
                    className="button-blue-outlined w-500"
                    variant="outlined"

                  >
                    {translate('jual-beli.btn-cancel')}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  {page === 'edit-perusahaan' ? <Button
                    fullWidth
                    onClick={(e) => { submitEditCompany(e, 'step1') }}
                    size="large"
                    className="button-blue-contained w-500"
                    variant="contained"

                  >
                    {translate('status.selanjutnya')}
                  </Button> : <Button
                    fullWidth
                    onClick={(e) => { submit(e, 'step1') }}
                    size="large"
                    className="button-blue-contained w-500"
                    variant="contained"

                  >
                    {translate('status.selanjutnya')}
                  </Button>}
                </Grid>
              </Grid>
            </Hidden>
          </div> : ''}
          {/* STEP2 */}
          {step === 'step2' ? <div>
            {/* Akta Perusahaan Section */}
            <Grid container direction='row' justifyContent='space-between'>
              <Grid item xs={12} sm={12} md={3} lg={3} >
                <h6 style={{ fontSize: 24 }} className=' w-700 mb-16'>  {translate('status.akta')}</h6>
                <p className='c-56 w-400 s-1421 mb-16'>{translate('jual-beli.subtitle-dokumen-perusahaan')}</p>
              </Grid>
              <MenuFormAktaCompany triggerValidation={triggerValidation} isOpenUploadAkta={isOpenUploadAkta} handleCloseModalUploadAkta={handleCloseModalUploadAkta} setakta_nomor={setakta_nomor} akta_nomor={akta_nomor} akta_description={akta_description} setakta_description={setakta_description} handleOpenModalUploadAkta={() => handleOpenModalUploadAkta()} akta={akta} doc_icon={doc_icon} setUploadAkta={setUploadAkta} ></MenuFormAktaCompany>
              <Grid item xs={3} className='wd-252'>
              </Grid>
            </Grid>
            <Grid container className='line mt-24 mb-32'>

            </Grid>
            {/* Surat NPWP Section */}
            <Grid container direction='row' justifyContent='space-between'>
              <Grid item xs={12} sm={12} md={3} lg={3} >
                <h6 style={{ fontSize: 24 }} className=' w-700 mb-16'>  {translate('status.suratnpwp')}</h6>
                <p className='c-56 w-400 s-1421 mb-16'>{translate('jual-beli.subtitle-dokumen-perusahaan')}</p>
              </Grid>
              <MenuFormNPWP triggerValidation={triggerValidation} npwp_nomor={npwp_nomor} setnpwp_nomor={setnpwp_nomor} npwp_name={npwp_name} setnpwpname={setnpwpname} npwp_description={npwp_description} setnpwp_description={setnpwp_description} isOpenUploadNpwp={isOpenUploadNpwp} handleCloseModalUploadNpwp={handleCloseModalUploadNpwp} handleOpenModalUploadNpwp={handleOpenModalUploadNpwp} doc_icon={doc_icon} npwp={npwp} setUploadnpwp={setUploadnpwp} ></MenuFormNPWP>
              <Grid item xs={3} className='wd-252'>
              </Grid>
            </Grid>
            <Grid container className='line mt-24 mb-32'>

            </Grid>
            {/* Perizinan Section */}
            <Grid container direction='row' justifyContent='space-between'>
              <Grid item xs={12} sm={12} md={3} lg={3} >
                <h6 style={{ fontSize: 24 }} className=' w-700 mb-16'>{translate('status.perizinan')}</h6>
                <p className='c-56 w-400 s-1421 mb-16'>{translate('jual-beli.subtitle-dokumen-perusahaan')}</p>
              </Grid>

              <MenuFormPerizinan handleRemovePerizinan={handleRemovePerizinan} perizinan={perizinan} handleDeletePerizinan={handleDeletePerizinan} handleChangeFilePerizinan={handleChangeFilePerizinan} handleChangeNamaPerizinan={handleChangeNamaPerizinan} handleChangeNomorPerizinan={handleChangeNomorPerizinan} handleChangeKodeWIUP={handleChangeKodeWIUP} handleChangeTahapanKegiatan={handleChangeTahapanKegiatan} handleChangeKomoditas={handleChangeKomoditas} handleChangeLuas={handleChangeLuas} calendarIcon={calendarIcon} handleChangeTahapanCNC={handleChangeTahapanCNC} handleChangeLokasi={handleChangeLokasi} handleChangeStartPerizinan={handleChangeStartPerizinan} handleChangeEndPerizinan={handleChangeEndPerizinan} handleAddPerizinan={handleAddPerizinan} doc_icon={doc_icon} AdapterDayjs={AdapterDayjs} DateIcon={DateIcon}></MenuFormPerizinan>
              <Grid item xs={3} className='wd-252'>
              </Grid>
            </Grid>
            <Grid container className='line mt-24 mb-32'>

            </Grid>
            <Hidden smDown>
              <Grid className='mb-104 in-container' container justifyContent='space-between' direction='row'>
                <Button
                  onClick={() => {
                    handleOpenBatalForm();
                  }}
                  size="large"
                  className="w-500"
                  variant="text"
                  style={{
                    color: '#2457B9',
                    textTransform: 'none',
                    padding: '12px 16px',
                    borderRadius: '8px',
                    height: '44px'
                  }}
                >
                  {translate('jual-beli.btn-cancel')}
                </Button>
                {page === 'edit-perusahaan' ? <Button
                  onClick={(e) => { submitEditCompany(e, 'step2') }}
                  size="large"
                  className="button-blue-contained w-500"
                  variant="contained"

                >
                  {translate('status.selanjutnya')}
                </Button> : <Button
                  onClick={(e) => { submit(e, 'step2') }}
                  size="large"
                  className="button-blue-contained w-500"
                  variant="contained"

                >
                  {translate('status.selanjutnya')}
                </Button>}
              </Grid>
            </Hidden>
            <Hidden smUp>
              <Grid spacing={1} container justifyContent='space-between' direction='row' style={{ marginBottom: '80px' }}>
                <Grid item xs={6}>
                  <Button
                    onClick={() => {
                      handleOpenBatalForm();
                    }}
                    fullWidth
                    size="small"
                    className="button-blue-outlined w-500"
                    variant="outlined"

                  >
                    {translate('jual-beli.btn-cancel')}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  {page === 'edit-perusahaan' ?
                    <Button
                      fullWidth
                      onClick={(e) => { submitEditCompany(e, 'step2') }}
                      size="large"
                      className="button-blue-contained w-500"
                      variant="contained"
                    >
                      {translate('status.selanjutnya')}
                    </Button> : <Button
                      fullWidth
                      onClick={(e) => { submit(e, 'step2') }}
                      size="large"
                      className="button-blue-contained w-500"
                      variant="contained"

                    >
                      {translate('status.selanjutnya')}
                    </Button>}
                </Grid>
              </Grid>
            </Hidden>
          </div> : ''}
          {/* STEP3 */}
          {step === 'step3' ? <div>
            {/* Alamat perusahaan Section */}
            <Grid container direction='row' justifyContent='space-between'>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <h6 style={{ fontSize: 24 }} className=' w-700 mb-16'> {translate('status.detailalamat')}</h6>
                <p className='c-56 w-400 s-1421 mb-16'>{translate('status.subdetailperusahaan')}</p>
              </Grid>
              <MenuFormAddress triggerValidation={triggerValidation} arrayaddress={arrayaddress} listKota={alamat_list} id_provinsi={id_provinsi} changeProvince={changeProvince} listOfProvince={listOfProvince} id_city={id_city} listOfcity={listOfcity} kode_pos={kode_pos} setKode_pos={setKode_pos} CurrentAddress={CurrentAddress} setCurrentAddress={setCurrentAddress} label_alamat={label_alamat} idlabelalamat={idlabelalamat} setidlabelalamat={setidlabelalamat} handleAddAddress={handleAddAddress} handleDeleteAddress={handleDeleteAddress} handleChangeAlamatLengkap={handleChangeAlamatLengkap} handleChangeLabel={handleChangeLabel} changeCity={changeCity} handleChangeKodepos={handleChangeKodepos} handleCurrentAddress={handleCurrentAddress}></MenuFormAddress>
              <Grid item xs={3} className='wd-252'>
              </Grid>
            </Grid>
            <Grid container className='line mt-24 mb-32'>

            </Grid>
            <Hidden smDown>
              <Grid className='mb-104 in-container' container justifyContent='space-between' direction='row'>
                <Button
                  onClick={() => navigate(-1)}
                  size="large"
                  className="w-500"
                  variant="text"
                  style={{
                    color: '#2457B9',
                    textTransform: 'none',
                    padding: '12px 16px',
                    borderRadius: '8px',
                    height: '44px'
                  }}
                >
                  {translate('jual-beli.btn-cancel')}
                </Button>
                {page === 'edit-perusahaan' ?
                  <Button
                    onClick={(e) => { submitEditCompany(e, 'step3') }}
                    size="large"
                    className="button-blue-contained w-500"
                    variant="contained"
                  >
                    {translate('status.selanjutnya')}
                  </Button> : <Button
                    onClick={(e) => { submit(e, 'step3') }}
                    size="large"
                    className="button-blue-contained w-500"
                    variant="contained"

                  >
                    {translate('status.selanjutnya')}
                  </Button>}
              </Grid>
            </Hidden>
            <Hidden smUp>
              <Grid spacing={1} container justifyContent='space-between' direction='row' style={{ marginBottom: '80px' }}>
                <Grid item xs={6}>
                  <Button
                    onClick={() => {
                      handleOpenBatalForm();
                    }}
                    fullWidth
                    size="small"
                    className="button-blue-outlined w-500"
                    variant="outlined"

                  >
                    {translate('jual-beli.btn-cancel')}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  {page === 'edit-perusahaan' ? <Button
                    fullWidth
                    onClick={(e) => { submitEditCompany(e, 'step3') }}
                    size="large"
                    className="button-blue-contained w-500"
                    variant="contained"

                  >
                    {translate('status.selanjutnya')}
                  </Button> : <Button
                    fullWidth
                    onClick={(e) => { submit(e, 'step3') }}
                    size="large"
                    variant="contained"
                    className="button-blue-contained w-500"

                  >
                    {translate('status.selanjutnya')}
                  </Button>}
                </Grid>
              </Grid>
            </Hidden>
          </div> : ''}
          {/* STEP4 */}
          {step === 'step4' ? <div>
            {/* Detail perusahaan Section */}
            <Grid container direction='row' justifyContent='space-between' >
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Typography className="w-700 s-2422 mb-16 c-08 ">
                  Invite Staff
                </Typography>
                <p className='c-56 w-400 s-1421 mb-16'> {translate('status.subdetailperusahaan')}</p>
              </Grid>
              <MenuInviteStaff staff={staff} handleDeleteStaff={handleDeleteStaff} handleChangeNameStaff={handleChangeNameStaff} handleChangeEmailStaff={handleChangeEmailStaff} handleAddStaff={handleAddStaff}></MenuInviteStaff>
              <Grid item xs={3} className='wd-252'>
              </Grid>
            </Grid>
            <Grid container className='line mt-24 mb-32'>

            </Grid>
            <Hidden smDown>
              <Grid className='mb-104 in-container' container justifyContent='space-between' direction='row'>
                <Button
                  onClick={() => navigate(-1)}
                  size="large"
                  className="w-500"
                  variant="text"
                  style={{
                    color: '#2457B9',
                    textTransform: 'none',
                    padding: '12px 16px',
                    borderRadius: '8px',
                    height: '44px'
                  }}
                >
                  {translate('jual-beli.btn-cancel')}
                </Button>
                <Stack direction={'row'}>
                  {page === 'edit-perusahaan' ? <Button
                    onClick={(e) => {
                      submitEditCompany(e, 'final-inviteNanti')
                    }}
                    disabled={disable}
                    size="large"
                    className="button-blue-outlined w-500 mr-16 "
                    variant="outlined"

                  >
                    {isLoadingInviteNanti ? (<CircularProgress
                      sx={{
                        color: (theme) =>
                          theme.palette.grey[theme.palette.mode === 'dark' ? 200 : 800],
                      }}
                      size={20}
                      thickness={4}
                      value={100}
                    />) : <> {translate('status.invitenanti')}</>}
                  </Button> : <Button
                    onClick={(e) => {
                      submit(e, 'final-inviteNanti')
                    }}
                    disabled={disable}
                    size="large"
                    className="button-blue-outlined w-500 mr-16 "
                    variant="outlined"

                  >
                    {isLoadingInviteNanti ? (<CircularProgress
                      sx={{
                        color: (theme) =>
                          theme.palette.grey[theme.palette.mode === 'dark' ? 200 : 800],
                      }}
                      size={20}
                      thickness={4}
                      value={100}
                    />) : <>{translate('status.invitenanti')}</>}
                  </Button>}
                  {page === 'edit-perusahaan' ? <Button
                    onClick={(e) => {
                      submitEditCompany(e, 'final-submit')
                    }}
                    disabled={disable}
                    size="large"
                    className="button-blue-contained w-500 mb-16"
                    variant="contained"

                  >
                    {isLoadingSubmit ? (<CircularProgress
                      sx={{
                        color: (theme) =>
                          theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                      }}
                      size={20}
                      thickness={4}
                      value={100}
                    />) : <>{translate('status.simpanperusahaan')}</>}

                  </Button> : <Button
                    onClick={(e) => {
                      submit(e, 'final-submit')
                    }}
                    disabled={disable}
                    size="large"
                    className="button-blue-contained w-500 mb-16"
                    variant="contained"

                  >
                    {isLoadingSubmit ? (<CircularProgress
                      sx={{
                        color: (theme) =>
                          theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                      }}
                      size={20}
                      thickness={4}
                      value={100}
                    />) : <>{translate('status.simpanperusahaan')}</>}

                  </Button>}

                </Stack>
              </Grid>
            </Hidden>
            <Hidden smUp>
              {page === 'edit-perusahaan' ?
                <Button
                  fullWidth
                  onClick={(e) => {
                    submitEditCompany(e, 'step2-submit')
                  }}
                  disabled={disable}
                  size="small"
                  className="button-blue-contained w-500 mb-16"
                  variant="contained"

                >
                  {isLoadingSubmit ? (<CircularProgress
                    sx={{
                      color: (theme) =>
                        theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                    }}
                    size={20}
                    thickness={4}
                    value={100}
                  />) : <>Submit</>}
                </Button> : <Button
                  fullWidth
                  onClick={(e) => {
                    submit(e, 'step2-submit')
                  }}
                  disabled={disable}
                  size="small"
                  className="button-blue-contained w-500"
                  variant="contained"

                >
                  {isLoadingSubmit ? (<CircularProgress
                    sx={{
                      color: (theme) =>
                        theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                    }}
                    size={20}
                    thickness={4}
                    value={100}
                  />) : <>Submit</>}
                </Button>}
              <Grid spacing={1} container justifyContent='space-between' direction='row' style={{ marginBottom: '80px' }}>
                <Grid item xs={6}>

                  <Button
                    fullWidth
                    onClick={() => {
                      handleOpenBatalForm();
                    }}
                    size="small"
                    className="w-500"
                    variant="text"
                    style={{
                      color: '#2457B9',
                      textTransform: 'none',
                      padding: '12px 16px',
                      borderRadius: '8px',
                      height: '44px'
                    }}
                  >
                    {translate('jual-beli.btn-cancel')}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  {page === 'edit-perusahaan' ? <Button
                    fullWidth
                    onClick={(e) => {
                      submitEditCompany(e, 'step2-inviteNanti')
                    }}
                    disabled={disable}
                    size="small"

                    variant="outlined"
                    className="button-blue-outlined w-500 wd-180"
                  >
                    {isLoadingInviteNanti ? (<CircularProgress
                      sx={{
                        color: (theme) =>
                          theme.palette.grey[theme.palette.mode === 'dark' ? 200 : 800],
                      }}
                      size={20}
                      thickness={4}
                      value={100}
                    />) : <>{translate('status.invitenanti')}</>}
                  </Button> : <Button
                    fullWidth
                    onClick={(e) => {
                      submit(e, 'step2-inviteNanti')
                    }}
                    disabled={disable}
                    size="small"
                    className="button-blue-outlined w-500 wd-180"
                    variant="outlined"

                  >
                    {isLoadingInviteNanti ? (<CircularProgress
                      sx={{
                        color: (theme) =>
                          theme.palette.grey[theme.palette.mode === 'dark' ? 200 : 800],
                      }}
                      size={20}
                      thickness={4}
                      value={100}
                    />) : <>{translate('status.invitenanti')}</>}
                  </Button>}
                </Grid>
              </Grid>
            </Hidden>
          </div> : ''}
        </Box>
      </Box>
      <Footer />
      <ModalBatalForm open={openModalBatalForm} close={handleCloseModalBatalForm}></ModalBatalForm>
      <ModalSuccessCreateCompany ok={handleOkModal} open={openModal} close={handleCloseModal}></ModalSuccessCreateCompany>
    </div >
  )
}
