import Divider from "@material-ui/core/Divider";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import SearchIcon from "@mui/icons-material/Search";
import {
  AppBar,
  Avatar,
  Badge, Box, Button, Hidden,
  IconButton,
  InputAdornment,
  Menu, MenuItem,
  Popover,
  Stack,
  TextField,
  Toolbar, Typography
} from "@mui/material";

import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import Arrowdownic from "../assets/arrow-down-ic.svg";
import Arrowunchangedic from "../assets/arrow-unchanged-ic.svg";
import Arrowupic from "../assets/arrow-up-ic.png";
import CalenderDark from "../assets/calender-dark-ic.png";
import Calenderic from "../assets/calender-ic.png";
import favorite_ic from "../assets/favorite_ic.png";
import settingIcon from "../assets/icons/setting-nav-icon.svg";
import settingIconGrey from "../assets/icons/setting-navbar-grey-icon.svg";
import IndomineBlue from "../assets/logo-indomine-blue.svg";
import LogoIndomine from "../assets/logo.svg";
import IconLogout from "../assets/logout-navbar-ic.svg";
import person from "../assets/person-inactive-ic.png";
import profileDefault from "../assets/profile-default.jpg";
import searchIcon from "../assets/search-explore-ic.png";
import setting_ic from "../assets/setting_ic.png";
import { addKeyword, checkNotification, getDetailTrader, getRealTimePricesLatest } from "../services/api";
import { config } from "../services/constants";
import { GlobalState } from "../services/global";
import { DrawerBuatPermintaan } from "./DrawerBuatPermintaan";
import LanguageToogle from "./LanguageToggle";
import ModalNotif from "./ModalNotif";
import { ModalPermintaan } from "./ModalPermintaan";
import Realtimeprice from "./Realtimeprice";
import SearchNavbar from "./SearchNavbar";
import SettingNavbar from "./SettingNavbar";

function NavBarHome() {
  const navigate = useNavigate()

  const [anchorElNav, setAnchorElNav] = React.useState(null)
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElSearch, setanchorElSearch] = React.useState(null);
  const [anchorElNotif, setanchorElNotif] = React.useState(null);
  const [btnSearch, setbtnSearch] = React.useState(false);
  const [btnNotif, setbtnNotif] = React.useState(false);
  const [notifDot, setNotifDot] = React.useState(true)
  const isLogin = localStorage.getItem('token') !== null ? true : false;
  const [translate,] = useTranslation("global");

  const [dataUser,] = useState([])
  const [position, setPosition] = useState(true)
  const [realtimePrice, setRealtimePrice] = useState([]);
  const [date, setDate] = useState(null);
  const pages_before_login = [{ path: '/', page: 'navbar.home' }, { path: '/penawaran', page: "navbar.penawaran" }]
  const pages_after_login = [{ path: '/', page: 'navbar.home' }, { path: '/explore', page: "navbar.explore" }, { path: '/penawaran', page: "navbar.penawaran" }, { path: '/permintaan', page: "navbar.permintaan" }, { path: '/listing', page: "navbar.mylisting" }]
  const pages_after_login_responsive = [{ path: '/', page: 'navbar.home' }, { path: '/explore', page: "navbar.explore" }, { path: '/permintaan', page: "navbar.permintaan" }, { path: '/penawaran', page: "navbar.penawaran" }, { path: '/listing', page: "navbar.mylisting" }, { path: '/profile', page: "navbar.favorit" }, { path: '/setting', page: "navbar.setting" }]
  const [isOpenModal, setOpenModal] = React.useState(false)
  const [anchorElPrice, setanchorElPrice] = React.useState(null);
  const [anchorElSetting, setanchorElSetting] = React.useState(null);
  const { currency, setcurrency } = React.useContext(GlobalState)

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }
  useEffect(() => {

    window.addEventListener('scroll', scrollHandler)
    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }

  })

  useEffect(() => {
    getCheckNotification()
    getDataUser()

  }, [])

  useEffect(() => {
    getDataRealTimePrice()
    getCheckNotification()
  }, [btnNotif])

  const location = useLocation();

  async function getCheckNotification() {
    if (localStorage.getItem("token") !== null) {
      const res = await checkNotification()
      if (res.data.notification_non_read === '0' || res.data.notification_non_read === 0) {
        setNotifDot(true)
      } else {
        setNotifDot(false)
      }
    }
  }

  const handleOpenSettingMenu = (event) => {
    setanchorElSetting(event.currentTarget);
  };

  const handleCloseSettingMenu = () => {
    setanchorElSetting(null);
  };

  async function getDataRealTimePrice() {
    const data = {
      currency: currency
    }
    try {
      const res = await getRealTimePricesLatest(data)
      setRealtimePrice(res?.data.data);
      setDate(res?.data.data[0].date);
    } catch (e) {
      console.log(e);
    }
  }
  const RedirectFavorite = () => {
    navigate(`/profile`, { state: { detail: 'favorit' } })
  }

  const handleOpenSearchMenu = (event) => {

    setanchorElSearch(event.currentTarget);
    setbtnSearch(true)

  };

  const handleCloseSearchMenu = () => {
    setanchorElSearch(null);
    setbtnSearch(false)
  };

  async function getDataUser() {
    if (localStorage.getItem("token") !== null && localStorage.getItem("id_user") !== null) {
      let lang = localStorage.getItem('lang') === 'null' || localStorage.getItem('lang') === 'id' ? 'id' : localStorage.getItem('lang')
      const res = await getDetailTrader(localStorage.getItem("id_user"), lang)
      dataUser.push(res.data.data)
    }
  }

  const scrollHandler = (event) => {
    let offset = window.pageYOffset
    if (offset === 0) {
      setPosition(true)
    } else {
      setPosition(false)
    }
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenNotifMenu = (event) => {
    setanchorElNotif(event.currentTarget);
    setbtnNotif(true)
  };

  const handleCloseNotifMenu = () => {
    setanchorElNotif(null);
    setbtnNotif(false)
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const Logout = (e) => {
    localStorage.clear()
    setTimeout(() => {
      window.location.href = "/"
    }, 500);
  }

  const handleChangeValue = (event) => {
    setSearchValue(event.target.value)
  }

  const [searchValue, setSearchValue] = useState('');

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter') {
      event.target.blur();

      let params = { value: searchValue }
      if (localStorage.getItem("token") !== null) {
        await addKeyword(params)
      }
      let path = window.location.pathname
      if (searchValue.includes("coal") || searchValue.includes("batubara")) {
        navigate(`/explore`, { state: { detail: 'coal', search: `${searchValue}` } })
        if (path === '/explore') {
          window.location.reload()
        }
      } else if (searchValue.includes("nickel") || searchValue.includes("nikel")) {
        navigate(`/explore`, { state: { detail: 'nikel', search: `${searchValue}` } })
        if (path === '/explore') {
          window.location.reload()
        }
      } else if (searchValue.includes("barge") || searchValue.includes("tongkang")) {
        navigate(`/explore`, { state: { detail: 'tongkang', search: `${searchValue}` } })
        if (path === '/explore') {
          window.location.reload()
        }
      } else if (searchValue.includes("company") || searchValue.includes("perusahaan")) {
        navigate(`/explore`, { state: { detail: 'company', search: `${searchValue}` } })
        if (path === '/explore') {
          window.location.reload()
        }
      } else if (searchValue.includes("trader") || searchValue.includes("orang")) {
        navigate(`/explore`, { state: { detail: 'trader', search: `${searchValue}` } })
        if (path === '/explore') {
          window.location.reload()
        }
      } else {
        navigate(`/explore`, { state: { detail: 'coal', search: `${searchValue}` } })
        if (path === '/explore') {
          window.location.reload()
        }
      }

    }
  }

  let pages
  let pagesresponsive
  if (isLogin) {
    pages = pages_after_login
    pagesresponsive = pages_after_login_responsive
  } else {
    pages = pages_before_login
    pagesresponsive = pages_before_login
  }

  let image_user
  let name
  dataUser.map((item) => {
    image_user = item.picture_path?.url !== undefined ? config.baseURL + item.picture_path?.url : profileDefault
    name = item.name
    // image_user = config.baseURL + item.background_picture_path.url
  })
  const handleOpenPriceMenu = (event) => {
    setanchorElPrice(event.currentTarget);

  };

  const handleClosePriceMenu = () => {
    setanchorElPrice(null);

  };

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    // centerMode: true,
    responsive: [
      {
        breakpoint: 1821,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 1320,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 900,
        settings: {
          arrows: false,
          // centerMode: true,
          slidesToShow: 1
        }
      },

    ]
  };

  return (
    <AppBar
      sx={{ paddingLeft: { md: '54px', xs: '16px' }, paddingRight: { md: '54px', xs: '6px' } }}
      className={`${position ? "" : "trans-header"} cust-header`}
      position="static" onScroll={scrollHandler}
      style={{ background: position ? "transparent" : "white", boxShadow: position ? "none" : "0px 2px 4px rgba(0, 0, 0, 0.08)" }}
    >
      <Box sx={{ width: '100%', }}>
        <Toolbar disableGutters   >

          <Box
            onClick={() => window.location.href = "/"}
            component="img"
            sx={{
              // objectFit: 'fill',


              width: { xs: 60, lg: 105 },

              cursor: 'pointer'
            }}
            alt="logo-img"
            src={position ? LogoIndomine : IndomineBlue}
          />
          {/* dekstop Section */}
          {/* buttonbar dekstop Section */}
          <Hidden lgDown>
            <Box sx={{ flexGrow: 1, ml: '32px', display: { xs: "none", md: "flex", lg: "flex" } }}>
              {pages.map((page) => (
                <Box key={page.path}>
                  {page.path === location.pathname ?
                    <Button
                      className="w-700"
                      href={page.path}
                      onClick={handleCloseNavMenu}
                      sx={{
                        fontSize: 16,
                        mr: 1,
                        ml: 1,
                        my: 2,
                        color: "white",
                        display: "block",
                        textTransform: "none",
                      }}
                    >
                      {translate(page.page)}
                    </Button> :
                    <Button
                      className="w-500"
                      href={page.path}

                      onClick={handleCloseNavMenu}
                      sx={{
                        fontSize: 16,
                        mr: 1,
                        ml: 1,
                        my: 2,
                        color: "rgba(255, 255, 255, 0.80)",
                        display: "block",
                        textTransform: "none",
                      }}
                    >
                      {translate(page.page)}
                    </Button>
                  }
                </Box>
              ))}
            </Box>

            {/* box info Section */}
            <Stack direction='row' alignItems={'center'} spacing="24px">
              <div style={{ width: 230 }}>
                {realtimePrice && <Box onClick={handleOpenPriceMenu} className="box-slide width-100">

                  <Box className="border-box"
                    sx={{
                      background: position ? "rgba(8, 17, 48, 0.3)" : "white",
                      backdropFilter: position ? "" : " blur(4px)",
                      // width: 180,
                      border: position ? "" : " 1px solid #E8EBF2",
                      borderRadius: "8px",
                      pl: 2,
                      pr: 2,
                      pt: '8px',
                      pb: '8px',

                    }}
                  >
                    <Slider {...settings}>
                      {realtimePrice.map((item, i) => (
                        <div key={item.id} >
                          <Stack
                            spacing={1}
                            direction="row"
                            alignItems="center"
                            justifyContent="left"
                          >
                            <Box
                              component="img"
                              sx={{
                                height: 12,
                              }}
                              alt="logo-img"
                              src={position ? Calenderic : CalenderDark}
                            />
                            {item.date !== undefined &&
                              <Typography className="w-500"
                                sx={{ color: position ? "white" : "#56617B", fontSize: 10 }}
                              >
                                {item.date}
                                {/* {new Intl.DateTimeFormat('id', { year: 'numeric', month: 'short', day: '2-digit' }).format(new Date(item.date))} */}
                              </Typography>}
                          </Stack>
                          <Stack
                            spacing={1}
                            direction="row"
                            alignItems="center"
                            justifyContent="left"
                          >
                            <Typography className="w-700 s-1216"
                              sx={{ color: position ? "white" : " #081130", fontSize: 10 }}
                            >
                              {item.shortName}
                            </Typography>
                            <Typography className="w-700 s-1216"
                              sx={{ color: position ? "white" : " #081130", fontSize: 10, }}
                            >
                              {currency == 'idr' ? 'Rp' : '$'} {item.latest_formatted}
                            </Typography>
                            {item.arrowChange.includes('Decrease') && <Box
                              component="img"
                              sx={{
                                height: 12,
                                width: 12
                              }}
                              alt="logo-img"
                              src={Arrowdownic}
                            />}
                            {item.arrowChange.includes('Increase') && <Box
                              component="img"
                              sx={{
                                height: 12,
                                width: 12
                              }}
                              alt="logo-img"
                              src={Arrowupic}
                            />}
                            {item.arrowChange.includes('Unchanged') && <Box
                              component="img"
                              sx={{
                                height: 12,
                                width: 12
                              }}
                              alt="logo-img"
                              src={Arrowunchangedic}
                            />}
                            {item.arrowChange.includes('Increase') && <Typography
                              className="w-700"
                              sx={{ color: "#3BB56C", fontSize: 10, }}
                            >
                              {item.change}
                            </Typography>}
                            {item.arrowChange.includes('Decrease') && <Typography
                              className="w-700"
                              sx={{ color: '#ED3131', fontSize: 10, }}
                            >
                              {item.change}
                            </Typography>}
                            {item.arrowChange.includes('Unchanged') && <Typography
                              className="w-700"
                              sx={{ fontSize: 10, }}
                            >
                              {item.change}
                            </Typography>}

                          </Stack>
                        </div>
                      ))}
                    </Slider>

                  </Box>

                </Box>}
              </div>
              <Box sx={{ display: { xs: "none", md: "flex", lg: "flex", xl: 'flex' } }}>
                <Stack direction='row' alignItems={'center'} spacing="24px">
                  <Divider orientation="vertical" style={{ background: '#E6E6E6', margin: '13px 0' }} flexItem />
                  {isLogin === true ?
                    <Stack direction='row' alignItems={'center'} spacing="18px">
                      <IconButton
                        // sx={{ pl: 0, pr: 0 }}
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenSettingMenu}
                        color="black"
                      >
                        <img alt="settings-icon" src={position ? settingIcon : settingIconGrey}></img>
                      </IconButton>
                      <Avatar onClick={handleOpenSearchMenu} sx={{ bgcolor: btnSearch === true ? '#3BB56C' : 'transparent', padding: '0px', height: '48px', width: '48px', cursor: 'pointer' }}>
                        <SearchIcon style={{ fill: position || btnSearch === true ? 'white' : '#56617B' }} />
                      </Avatar>

                      <Popover
                        PaperProps={{
                          style: {
                            backgroundColor: "transparent",
                            boxShadow: "none",
                            borderRadius: 0
                          }
                        }}
                        sx={{ mt: '50px', zIndex: 99999, '& .MuiBackdrop-root': { backgroundColor: 'rgba(0, 0, 0, 0.2)' } }}
                        id="menu-appbar"
                        anchorEl={anchorElSearch}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        open={Boolean(anchorElSearch)}
                        onClose={handleCloseSearchMenu}
                      >
                        <Box
                          sx={{
                            position: "relative",
                            mt: "10px",
                            "&::before": {
                              backgroundColor: "white",
                              content: '""',
                              display: "block",
                              position: "absolute",
                              width: '24px',
                              height: '24px',
                              top: -6,
                              transform: "rotate(45deg)",
                              left: { xs: "calc(87% - 8px)", sm: "calc(81% - 8px)", md: "calc(76% - 8px)", lg: "calc(68% - 8px)", xl: "calc(68% - 8px)" }

                            }
                          }}
                        />
                        <SearchNavbar />
                      </Popover>
                      <SettingNavbar anchorElSetting={anchorElSetting} open={anchorElSetting} onClose={handleCloseSettingMenu}></SettingNavbar>
                      <Avatar onClick={handleOpenNotifMenu} sx={{ bgcolor: btnNotif === true ? '#3BB56C' : 'transparent', padding: '0px', height: '48px', width: '48px', cursor: 'pointer' }}>

                        <Badge color="error" variant="dot" invisible={notifDot}>
                          <NotificationsNoneIcon sx={{ fill: position || btnNotif === true ? 'white' : '#56617B' }} />
                        </Badge>
                      </Avatar>

                      <ModalNotif anchorEl={anchorElNotif} open={anchorElNotif} onClose={handleCloseNotifMenu}></ModalNotif>

                      <IconButton onClick={handleOpenUserMenu} sx={{ padding: '0px' }}>
                        {image_user && <img style={{ borderRadius: "100%", objectFit: "cover" }} alt="img-icon" src={image_user} height={44} width={44}></img>}
                      </IconButton>

                      <Menu className="menu-user"
                        sx={{ padding: '0', mt: '45px', zIndex: 99 }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                      >

                        <MenuItem sx={{ pb: 0, pt: 0, height: '43px' }} onClick={() => window.location.href = "/profile"}>
                          <Stack direction={'row'} alignItems={'center'}>
                            <img alt="img-icon" src={person} height={14} width={14}></img>
                            <Typography className="w-500" sx={{ fontSize: '14px' }} textAlign="center">{translate('navbar.profile')}</Typography>
                          </Stack>
                        </MenuItem>
                        <MenuItem sx={{ pb: 0, pt: 0, height: '43px' }} onClick={() => { navigate(`/profile`, { state: { detail: 'favorit' } }) }}>
                          <Stack direction={'row'} alignItems={'center'}>
                            <img alt="img-icon" src={favorite_ic} height={14} width={14}></img>
                            <Typography className="w-500" sx={{ fontSize: '14px' }} textAlign="center">{translate('navbar.favorit')}</Typography>
                          </Stack>
                        </MenuItem>
                        <MenuItem sx={{ pb: 0, pt: 0, height: '43px' }} onClick={() => window.location.href = "/setting"}>
                          <img alt="img-icon" src={setting_ic} height={14} width={14}></img>
                          <Typography className="w-500" sx={{ fontSize: '14px' }} textAlign="center">{translate('navbar.setting')}</Typography>
                        </MenuItem>
                        <Divider orientation="horizontal" style={{ height: '1px' }} flexItem />
                        <MenuItem sx={{ pb: 0, pt: 0, height: '43px' }} onClick={(e) => Logout()}>
                          <img alt="img-icon" src={IconLogout} height={20} width={20}></img>
                          <Typography className="w-500" sx={{ fontSize: '14px', color: "red" }} textAlign="center">{translate('navbar.keluar')}</Typography>
                        </MenuItem>
                      </Menu>
                      <Button
                        onClick={(e) => handleOpenModal()}
                        className="w-500"
                        variant="contained"
                        sx={{

                          fontSize: '14px',
                          background: 'linear-gradient(270deg, #2457B9 15%, #2860CD 100%)',
                          padding: "10px 12px",
                          borderRadius: "8px",
                          textTransform: "none",
                        }}
                      >
                        {translate('navbar.btn-buat-permintaan')}
                      </Button>

                    </Stack> :
                    <Stack direction='row' alignItems={'center'} spacing="24px">
                      <IconButton
                        // sx={{ pl: 0, pr: 0 }}
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenSettingMenu}
                        color="black"
                      >
                        <img alt="settings-icon" src={position ? settingIcon : settingIconGrey}></img>
                      </IconButton>
                      <SettingNavbar anchorElSetting={anchorElSetting} open={anchorElSetting} onClose={handleCloseSettingMenu}></SettingNavbar>
                      <Button
                        href="/signin"
                        className="w-400 s-1622 btn-signin"
                        variant="outlined"
                        sx={{
                          width: '120px',
                          padding: '8px',
                          borderRadius: "8px",
                          textTransform: "none",
                        }}
                      >
                        {translate('navbar.masuk')}
                      </Button>
                      <Button
                        href="/signup"
                        className="w-400 s-1622 button-blue-contained"
                        variant="contained"
                        style={{
                          width: "120px",
                          padding: "12px 16px",
                          borderRadius: "8px",
                          textTransform: "none",
                        }}
                      >
                        {translate('navbar.daftar')}
                      </Button>
                      <Hidden lgUp>
                        <Box sx={{ display: { xs: "flex", md: "flex", sm: 'flex', lg: "flex", xl: 'none' }, }}>
                          <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNotifMenu}
                            color="white"
                          >
                            <Badge color="error" variant="dot" invisible={notifDot}>
                              <NotificationsNoneIcon />
                            </Badge>
                          </IconButton>
                        </Box>
                        <Box sx={{ display: { xs: "flex", md: "flex", sm: 'flex', lg: "flex", xl: 'none' } }}>
                          <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="white"
                          >
                            <MenuIcon />
                          </IconButton>
                        </Box>
                      </Hidden>
                    </Stack>
                  }


                </Stack>
                <ModalPermintaan open={isOpenModal} close={handleCloseModal} />
              </Box>
            </Stack>
          </Hidden>
          {/* responsive menu Section */}
          <Realtimeprice realtimePrice={realtimePrice} date={date} anchorEl={anchorElPrice} open={anchorElPrice} onClose={handleClosePriceMenu}></Realtimeprice>
          <Hidden lgUp >
            {/* modal menu responsive Section */}
            <Box sx={{ flexGrow: 1, display: { xs: "flex", sm: 'flex', md: "flex" }, }}>

              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                // fullWidth={true}
                PaperProps={{
                  style: {
                    width: '100%',
                    borderRadius: '8px'
                  }
                }}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  width: "100%",
                  zIndex: 99999,
                  display: { xs: "block", md: "block", sm: "block" },
                }}
              >
                <Stack direction="row" sx={{ margin: "8px 16px 24px 16px" }} justifyContent={'space-between'} alignItems={'center'}>
                  <Stack spacing={1} direction={'row'} alignItems={'center'}>
                    <Box component="img"
                      sx={{
                        width: 105,
                        // height: 28,
                      }}
                      alt="logo-img"
                      src={IndomineBlue}
                    />
                    {/* <Typography className="w-700" sx={{ color: '#081130', fontSize: 21, }}>
                      IndoMine
                    </Typography> */}
                  </Stack>
                  <CloseIcon onClick={handleCloseNavMenu} />
                </Stack>
                {isLogin === true ?
                  <Stack direction={'row'} sx={{ margin: '0 0 24px 16px' }}>
                    <img className="img-cover" style={{ borderRadius: "100%" }} alt="img-icon" src={image_user} height={52} width={52}></img>
                    <Stack direction={'column'} sx={{ ml: '12px' }}>
                      <Typography className="w-700 s-1824" sx={{ color: '#081130', mb: '5px' }}>
                        {name}
                      </Typography>
                      <Stack
                        justifyContent="left"
                        alignItems="center"
                        direction="row"
                      >
                        <Typography
                          className="w-700 s-1419"
                          onClick={() => { navigate(`/profile`) }}
                          sx={{ color: "#2457B9", mr: 1, fontWeight: 700, cursor: "pointer" }}
                        >
                          {translate('title.lihatprofile')}
                        </Typography>
                        <ArrowForwardIosIcon sx={{ fontSize: 14, color: "#2457B9", cursor: "pointer" }} />
                      </Stack>
                    </Stack>
                  </Stack> : null}

                <Box sx={{ margin: '0 16px 4px 16px' }}>
                  <TextField
                    className="search-filter-box"
                    id="search"
                    type="search"
                    placeholder="Search"
                    value={searchValue}
                    onChange={(e) => handleChangeValue(e)}
                    onKeyDown={handleKeyDown}
                    sx={{
                      width: '100%', input: {
                        fontSize: '14px', fontFamily: 'Satoshi-500'
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img alt="search-icon" src={searchIcon} height={20} width={20}></img>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                {pagesresponsive.map((page) => (
                  <Box key={page.path} sx={{ margin: '0 0 4px 0' }}>
                    <MenuItem onClick={handleCloseNavMenu}
                      sx={{ padding: '0' }}
                    >
                      {page.path === location.pathname ?
                        <Button
                          size="large"
                          className="w-700 s-1419"
                          href={page.path}
                          variant="text"
                          // fullWidth
                          onClick={(e) => {
                            handleCloseNavMenu()
                            if (page.path === '/profile') {
                              RedirectFavorite()
                            }
                          }}
                          sx={{
                            color: "#2457B9",
                            display: "block",
                            textTransform: "none",
                            padding: '16px 20px'
                          }}
                        >
                          {translate(page.page)}
                        </Button> :
                        <Button
                          className="w-700 s-1419"
                          href={page.path}
                          size="large"
                          variant="text"
                          // fullWidth
                          onClick={(e) => {
                            handleCloseNavMenu()
                            if (page.path === '/profile') {
                              RedirectFavorite()
                            }
                          }}
                          sx={{
                            color: "#081130",
                            display: "block",
                            textTransform: "none",
                            padding: '16px 20px'
                          }}
                        >
                          {translate(page.page)}
                        </Button>
                      }
                    </MenuItem>
                  </Box>
                ))}
                <Box sx={{ ml: 2, mb: 2, width: '120px' }}>
                  <LanguageToogle></LanguageToogle>
                </Box>
                {isLogin === true ?

                  <Box sx={{ margin: '0 16px 8px 16px' }}>
                    <Button
                      onClick={(e) => {
                        handleCloseNavMenu()
                        handleOpenModal()
                      }}
                      fullWidth
                      className="w-500"
                      variant="contained"
                      size="medium"
                      sx={{
                        background: 'linear-gradient(270deg, #2457B9 15%, #2860CD 100%)',
                        fontSize: '14px',
                        marginRight: '10px',
                        textTransform: "none",
                        padding: '10px 12px',
                        borderRadius: '8px',
                      }}
                    >
                      {translate('navbar.btn-buat-permintaan')}
                    </Button>

                    <DrawerBuatPermintaan open={isOpenModal} close={handleCloseModal} />
                    <Divider orientation="horizontal" style={{ margin: '16px 0' }} />
                    <Stack
                      direction={'row'}
                      justifyContent={'center'}
                    >
                      <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{ cursor: "pointer", mt: 1, mb: 1 }} onClick={(e) => Logout()}>
                        <Typography className="w-700" sx={{ fontSize: '14px', color: "red", mr: 1 }} textAlign="center">{translate('navbar.keluar')}</Typography>
                        <img style={{ marginTop: 2 }} alt="img-icon" src={IconLogout} height={20} width={20}></img>
                      </Stack>

                    </Stack>
                  </Box> :
                  <Box sx={{ margin: '0 16px' }}>
                    <Divider orientation="horizontal" style={{ marginTop: 10, marginBottom: 10 }} />
                    <Button
                      className="w-500 s-1420"
                      href="/signup"
                      type="submit"
                      fullWidth
                      variant="contained"
                      size="medium"
                      sx={{ margin: '8px 0', padding: '12px 16px', borderRadius: '8px', background: '#2457B9', textTransform: 'none' }}
                    >
                      {translate('navbar.daftar')}
                    </Button>
                    <Button
                      className="w-500 s-1420"
                      href="/signin"
                      type="submit"
                      fullWidth
                      variant="outlined"
                      size="medium"
                      sx={{ margin: '8px 0', padding: '12px 16px', borderRadius: '8px', textTransform: 'none' }}
                    >
                      {translate('navbar.masuk')}
                    </Button>
                  </Box>}
              </Menu>

            </Box>
            <Stack direction={'row'} alignItems={'center'}>
              {/* box info Section */}
              {realtimePrice && <Box onClick={(e) => { window.location.href = `/icirealtimeprice` }} style={{ width: 180, marginLeft: '6px', marginRight: '6px', alignItems: 'center', }}>
                <Box onClick={handleOpenPriceMenu} className="box-slide width-100">

                  <Box className="border-box"
                    sx={{
                      background: position ? "rgba(8, 17, 48, 0.3)" : "white",
                      backdropFilter: position ? "" : " blur(4px)",
                      border: position ? "" : " 1px solid #E8EBF2",
                      width: 180,
                      borderRadius: 2,
                      pl: '12px',
                      pr: '12px',
                      pt: '8px',
                      pb: '8px',

                    }}
                  >
                    <Slider {...settings}>
                      {realtimePrice.map((item, i) => (
                        <div key={item.id} >
                          <Stack

                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Typography className="w-700 s-1216"
                              sx={{ color: position ? "white" : '#56617B', fontSize: { xs: 8, sm: 8, md: 8, lg: 12 }, }}
                            >
                              {item.shortName}
                            </Typography>
                            <Typography className="w-700 s-1216"
                              sx={{ color: position ? "white" : '#56617B', fontSize: { xs: 8, sm: 8, md: 8, lg: 12 }, }}
                            >
                              {currency == 'idr' ? 'Rp' : '$'} {item.latest_formatted}
                            </Typography>

                            {item.arrowChange.includes('Increase') && <Box
                              component="img"
                              sx={{
                                height: 9,
                                width: 9
                              }}
                              alt="logo-img"
                              src={Arrowupic}
                            />}
                            {item.arrowChange.includes('Decrease') && <Box
                              component="img"
                              sx={{
                                height: 9,
                                width: 9
                              }}
                              alt="logo-img"
                              src={Arrowdownic}
                            />}
                            {item.arrowChange.includes('Unchanged') && <Box
                              component="img"
                              sx={{
                                height: 9,
                                width: 9
                              }}
                              alt="logo-img"
                              src={Arrowunchangedic}
                            />}
                            {item.arrowChange.includes('Increase') && <Typography
                              className="w-700"
                              sx={{ color: item.arrowChange.includes('Decrease') ? '#ED3131' : "#3BB56C", fontSize: { xs: 8, sm: 8, md: 8, lg: 12 }, }}
                            >
                              {item.change}
                            </Typography>}
                            {item.arrowChange.includes('Decrease') && <Typography
                              className="w-700"
                              sx={{ color: '#ED3131', fontSize: { xs: 8, sm: 8, md: 8, lg: 12 }, }}
                            >
                              {item.change}
                            </Typography>}
                            {item.arrowChange.includes('Unchanged') && <Typography
                              className="w-700"
                              sx={{ fontSize: { xs: 8, sm: 8, md: 8, lg: 12 }, }}
                            >
                              {item.change}
                            </Typography>}
                          </Stack>
                        </div>
                      ))}
                    </Slider>
                  </Box>

                </Box>
              </Box>}
              <IconButton
                size="large"
                sx={{ pl: 0, pr: 0 }}
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenSettingMenu}
                color="black"
              >
                <img alt="settings-icon" src={position ? settingIcon : settingIconGrey}></img>
              </IconButton>
              <SettingNavbar anchorElSetting={anchorElSetting} open={anchorElSetting} onClose={handleCloseSettingMenu}></SettingNavbar>
              <Box sx={{ display: { xs: "flex", md: "flex", sm: 'flex', lg: "flex", xl: 'none' }, }}>
                <Avatar onClick={handleOpenNotifMenu} sx={{ bgcolor: btnNotif === true ? '#3BB56C' : 'transparent', padding: '0px', height: '40px', width: '40px', cursor: 'pointer' }}>
                  <Badge sx={{ width: '20px', height: '20px' }} color="error" variant="dot" invisible={notifDot}>
                    <NotificationsNoneIcon sx={{ fill: position || btnNotif === true ? 'white' : '#56617B', width: '20px', height: '20px' }} />
                  </Badge>
                </Avatar>

                <ModalNotif anchorEl={anchorElNotif} open={anchorElNotif} onClose={handleCloseNotifMenu}></ModalNotif>
              </Box>
              <Box sx={{ display: { xs: "flex", md: "flex", sm: 'flex', lg: "flex", xl: 'none' } }}>
                <IconButton
                  sx={{ pl: 0, pr: 0 }}
                  size="medium"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="white"
                >
                  <MenuIcon sx={{ fill: position ? 'white' : '#56617B' }} />
                </IconButton>
              </Box>
            </Stack>
          </Hidden>
        </Toolbar>
      </Box>
    </AppBar>
  )
}
export default NavBarHome
