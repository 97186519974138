import Divider from "@material-ui/core/Divider";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CloseIcon from '@mui/icons-material/Close';
import PercentIcon from '@mui/icons-material/Percent';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import Drawer from '@mui/material/Drawer';
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import DateRangePickerCustom from '../components/DateRangePickerCustom';
import { GlobalState } from "../services/global";
import { VerticalDivider, VerticalDividerLeft, VerticalDividerQuantity, formatDate } from '../services/primary';
import Value from "../services/value.json";
export default function DrawerFilterCoal(props) {
  const [translate,] = useTranslation("global");
  const { currency } = React.useContext(GlobalState)
  const [validationminCalorie, setValidationminCalorie] = useState(false)
  const [validationmaksCalorie, setValidationmaksCalorie] = useState(false)
  const [validationminSulfur, setValidationminSulfur] = useState(false)
  const [validationmaksSulfur, setValidationmaksSulfur] = useState(false)
  const [validationminAsh, setValidationminAsh] = useState(false)
  const [validationmaksAsh, setValidationmaksAsh] = useState(false)


  return (
    <Box sx={{ flexGrow: 1, display: { xs: "flex", sm: 'flex', md: "flex" }, }}>
      <Drawer
        className="box-filter-drawer"
        anchor="bottom"
        open={Boolean(props.open)}
        onClose={props.close}
      >
        <Box >
          <Stack
            direction="row"
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{ pl: '16px', pt: '19px', pr: '16px', pb: '19px' }}
          >
            <Typography className="w-700" sx={{ fontSize: 18, color: '#081130' }}>
              Filter
            </Typography>
            <Stack direction={'row'} alignItems={'center'}>
              {props.page === 'default' ? <Button className="w-500" onClick={(e) => {
                props.handleLocationReset('coal')
                props.resetFilterBatubara('mobile')
              }} sx={{ fontSize: 16, color: '#2457B9', textTransform: 'none' }} variant="text"> Reset filter</Button> : <Button className="w-500" onClick={(e) => {

                props.resetFilterBatubara('mobile')
              }} sx={{ fontSize: 16, color: '#2457B9', textTransform: 'none' }} variant="text"> Reset filter</Button>}

              <Divider orientation="vertical" style={{ marginTop: 10, marginBottom: 10, marginLeft: 10, marginRight: 10 }} flexItem />
              <CloseIcon onClick={props.close} />
            </Stack>
          </Stack>
          <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
            <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
              Gar
            </Typography>
            <Grid
              container
              direction="row"
              justifyContent={'space-between'}
              spacing={2}
            >
              <Grid item xs={6}>
                <TextField
                  className="box-input-filter"
                  type="number"
                  size="small"
                  placeholder="Min"
                  error={validationminCalorie}
                  FormHelperTextProps={{ style: { fontSize: 10 } }}
                  helperText={validationminCalorie ? translate('status.minvalue') + ' ' + Value.Gar.min.toString() : ''}
                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    props.handleFilterBatubara(e.target.value, 'mobile', "min_calorie")
                    if (value < parseInt(Value.Gar.min)) {
                      setValidationminCalorie(true)
                    } else {
                      setValidationminCalorie(false)
                    }
                  }}
                  value={props.filterBatubara["min_calorie"]}
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                      display: "none",
                    },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                    width: '100%', input: {
                      fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                  }}

                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  className="box-input-filter"
                  type="number"
                  size="small"
                  placeholder="Maks"
                  error={validationmaksCalorie}
                  FormHelperTextProps={{ style: { fontSize: 10 } }}
                  helperText={validationmaksCalorie ? translate('status.maksvalue') + ' ' + Value.Gar.maks.toString() : ''}
                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    props.handleFilterBatubara(e.target.value, "mobile", "max_calorie")
                    if (value > parseInt(Value.Gar.maks)) {
                      setValidationmaksCalorie(true)
                    } else {
                      setValidationmaksCalorie(false)
                    }
                  }}
                  value={props.filterBatubara["max_calorie"]}
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                      display: "none",
                    },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                    width: '100%', input: {
                      fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                  }}

                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
            <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
              {translate('page_explore.total-sulfur')} (%)
            </Typography>
            <Grid
              container
              direction="row"
              justifyContent={'space-between'}
              spacing={2}
            >
              <Grid item xs={6}>
                <TextField
                  className="box-input-filter"
                  type="number"
                  size="small"
                  placeholder="Min"
                  error={validationminSulfur}
                  FormHelperTextProps={{ style: { fontSize: 10 } }}
                  helperText={validationminSulfur ? translate('status.minvalue') + ' ' + Value.Sulphur.min.toString() : ''}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value);
                    props.handleFilterBatubara(e.target.value, "mobile", "min_total_sulfur")
                    if (value < parseFloat(Value.Sulphur.min)) {
                      setValidationminSulfur(true)
                    } else {
                      setValidationminSulfur(false)
                    }
                  }}
                  value={props.filterBatubara["min_total_sulfur"]}
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                      display: "none",
                    },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                    width: '100%', input: {
                      fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <VerticalDivider />
                        <PercentIcon sx={{ color: '#56617B', fontSize: '16px' }} />
                      </InputAdornment>
                    ),
                  }}
                />

              </Grid>
              <Grid item xs={6}>
                <TextField
                  className="box-input-filter"
                  type="number"
                  size="small"
                  placeholder="Maks"
                  error={validationmaksSulfur}
                  FormHelperTextProps={{ style: { fontSize: 10 } }}
                  helperText={validationmaksSulfur ? translate('status.maksvalue') + ' ' + Value.Sulphur.maks.toString() : ''}
                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    props.handleFilterBatubara(e.target.value, "mobile", "max_total_sulfur")
                    if (value > parseInt(Value.Sulphur.maks)) {
                      setValidationmaksSulfur(true)
                    } else {
                      setValidationmaksSulfur(false)
                    }
                  }}
                  value={props.filterBatubara["max_total_sulfur"]}
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                      display: "none",
                    },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                    width: '100%', input: {
                      fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <VerticalDivider />
                        <PercentIcon sx={{ color: '#56617B', fontSize: '16px' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
            <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
              Ash (%)
            </Typography>
            <Grid
              container
              direction="row"
              justifyContent={'space-between'}
              spacing={2}
            >
              <Grid item xs={6}>
                <TextField
                  className="box-input-filter"
                  type="number"
                  size="small"
                  placeholder="Min"
                  error={validationminAsh}
                  FormHelperTextProps={{ style: { fontSize: 10 } }}
                  helperText={validationminAsh ? translate('status.minvalue') + ' ' + Value.Ash.min.toString() : ''}
                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    props.handleFilterBatubara(e.target.value, "mobile", "min_ash")
                    if (value < parseInt(Value.Ash.min)) {
                      setValidationminAsh(true)
                    } else {
                      setValidationminAsh(false)
                    }
                  }}
                  value={props.filterBatubara["min_ash"]}
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                      display: "none",
                    },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                    width: '100%', input: {
                      fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <VerticalDivider />
                        <PercentIcon sx={{ color: '#56617B', fontSize: '16px' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  className="box-input-filter"
                  type="number"
                  size="small"
                  placeholder="Maks"
                  error={validationmaksAsh}
                  FormHelperTextProps={{ style: { fontSize: 10 } }}
                  helperText={validationmaksAsh ? translate('status.maksvalue') + ' ' + Value.Ash.maks.toString() : ''}
                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    props.handleFilterBatubara(e.target.value, "mobile", "max_ash")
                    if (value > parseInt(Value.Ash.maks)) {
                      setValidationmaksAsh(true)
                    } else {
                      setValidationmaksAsh(false)
                    }
                  }}
                  value={props.filterBatubara["max_ash"]}
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                      display: "none",
                    },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                    width: '100%', input: {
                      fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <VerticalDivider />
                        <PercentIcon sx={{ color: '#56617B', fontSize: '16px' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
            <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
              Quantity
            </Typography>
            <TextField
              className="box-input-filter"
              type="number"
              size="small"
              placeholder={translate('jual-beli.placeholder-quantity')}
              onChange={(e) => props.handleFilterBatubara(e.target.value, "mobile", "quantity")}
              value={props.filterBatubara["quantity"]}
              sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                  display: "none",
                },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
                width: '100%', input: {
                  fontSize: '14px', fontFamily: 'Satoshi-500'
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <VerticalDividerQuantity />
                    <Typography className="w-700" sx={{ fontSize: 14, color: '#56617B' }}>
                      MT
                    </Typography>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
            <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
              {translate('page_explore.harga-batubara')}
            </Typography>
            <TextField
              className="box-input-filter"
              type="number"
              size="small"
              placeholder={translate('status.min-harga')}
              onChange={(e) => props.handleFilterBatubara(e.target.value, "mobile", "min_price")}
              value={props.filterBatubara["min_price"]}
              sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                  display: "none",
                },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
                width: '100%', input: {
                  fontSize: '14px', fontFamily: 'Satoshi-500'
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <VerticalDividerLeft sx={{ ml: '12px' }} />
                    {currency === 'idr' && <Typography className="w-700" sx={{ fontSize: 14, color: '#56617B', mr: '24px' }}>
                      Rp
                    </Typography>}
                    {currency === 'usd' && <Typography className="w-700" sx={{ fontSize: 16, color: '#56617B', mr: '24px' }}>
                      $
                    </Typography>}
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              className="box-input-filter"
              type="number"
              size="small"
              placeholder={translate('status.max-harga')}
              onChange={(e) => props.handleFilterBatubara(e.target.value, "mobile", "max_price")}
              value={props.filterBatubara["max_price"]}
              sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                  display: "none",
                },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
                width: '100%', input: {
                  fontSize: '14px', fontFamily: 'Satoshi-500'
                }, mt: '8px'
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <VerticalDividerLeft sx={{ ml: '12px' }} />
                    {currency === 'idr' && <Typography className="w-700" sx={{ fontSize: 14, color: '#56617B', mr: '24px' }}>
                      Rp
                    </Typography>}
                    {currency === 'usd' && <Typography className="w-700" sx={{ fontSize: 16, color: '#56617B', mr: '24px' }}>
                      $
                    </Typography>}
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          {/* Date Picker Terbaru */}
          {props.page === 'default' && <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
            <p className='c-08 s-1419 w-500 mb4'>Laycan Date</p>
            <DateRangePickerCustom setStart={(e) =>

              props.handleFilterBatubara(e ? formatDate(e) : e, 'mobile', "request_start_laycan")


            } setEnd={(e) =>

              props.handleFilterBatubara(e ? formatDate(e) : e, 'mobile', "request_end_laycan")
            } />
          </Box>}

          {(props.page === 'default' || props.page === "profile") && <Box sx={{ pl: 2, pr: 2, mt: 1, mb: 2 }}>
            <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
              {translate('page_explore.lokasi')}
            </Typography>
            <FormGroup className="location-label" >
              {props.dataProvince.map((option, i) => {
                if (i < 5) return (
                  <FormControlLabel onChange={(e) => props.handleFilterBatubara(e, "popuplocation")} key={i} sx={{ fontSize: 14, color: '#081130' }} control={<Checkbox checked={props.selectedItems.includes(option.id)} sx={{
                    color: "#E8EBF2",
                    '&.Mui-checked': {
                      color: "#3BB56C",
                    },
                  }} />} label={option.attributes.name} value={option.id} />)
              })}
            </FormGroup>
            {props.limitProvince < 10 &&
              <Grid
                sx={{ mt: 1 }}
                container
                justifyContent="left"
                alignItems="center"
                direction="row"
              >
                <Typography onClick={(e) => {
                  props.setLimitProvince(5)
                  props.handleOpenModalLocationResponsive("coal")
                }}
                  className="w-700"
                  sx={{ fontSize: 14, color: "#2457B9", mr: 1, cursor: "pointer" }}
                >
                  {translate('page_explore.lihatselengkapnya')}
                </Typography>
                <ArrowForwardIosIcon sx={{ fontSize: 12, color: "#2457B9" }} />
              </Grid>}
          </Box>}

          {props.page === 'listing' && <Box sx={{ pl: '16px', pr: '16px', pb: '19px', mb: '16px' }}>
            <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
              {translate('my-listing.listingstatus')}
            </Typography>
            <Grid
              container

              direction="row"
              justifyContent={'space-between'}
              spacing={1}
            >
              <Grid item xs={6}>
                <Button
                  className="w-500"
                  onClick={(e) => props.handleFilterBatubara("", "id_listing_status", 'mobile')}
                  value={props.filterBatubara["id_listing_status"]} sx={{
                    lineHeight: 'normal',
                    "&:hover": {
                      borderColor: props.filterBatubara["id_listing_status"] === '' ? '#3BB56C' : '#E8EBF2',
                    },
                    textTransform: 'none', borderColor: props.filterBatubara["id_listing_status"] === '' ? '#3BB56C' : '#E8EBF2', color: props.filterBatubara["id_listing_status"] === '' ? '#3BB56C' : 'black', justifyContent: 'left', borderRadius: '8px', p: '14px 16px'
                  }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterBatubara["id_listing_status"] === '' ? '#3BB56C' : '#E8EBF2', }} />}>
                  <Typography className="w-500" sx={{ fontSize: 14, lineHeight: 'normal' }}>
                    {translate('my-listing.btn-all')}
                  </Typography>

                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  className="w-500"
                  onClick={(e) => props.handleFilterBatubara("1", "id_listing_status", 'mobile')}
                  value={props.filterBatubara["id_listing_status"]} sx={{
                    lineHeight: 'normal',
                    "&:hover": {
                      borderColor: props.filterBatubara["id_listing_status"] === '1' ? '#3BB56C' : '#E8EBF2',
                    },
                    textTransform: 'none', borderColor: props.filterBatubara["id_listing_status"] === '1' ? '#3BB56C' : '#E8EBF2', color: props.filterBatubara["id_listing_status"] === '1' ? '#3BB56C' : 'black', justifyContent: 'left', borderRadius: '8px', p: '14px 16px'
                  }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterBatubara["id_listing_status"] === '1' ? '#3BB56C' : '#E8EBF2', }} />}>
                  {translate('my-listing.btn-publish')}
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent={'space-between'}
              sx={{ mt: '2px' }}
              spacing={1}
            >
              <Grid item xs={6}>
                <Button
                  className="w-500"
                  onClick={(e) => props.handleFilterBatubara("2", "id_listing_status", 'mobile')}
                  value={props.filterBatubara["id_listing_status"]} sx={{
                    lineHeight: 'normal',
                    "&:hover": {
                      borderColor: props.filterBatubara["id_listing_status"] === '2' ? '#3BB56C' : '#E8EBF2',
                    }, textTransform: 'none', borderColor: props.filterBatubara["id_listing_status"] === '2' ? '#3BB56C' : '#E8EBF2', color: props.filterBatubara["id_listing_status"] === '2' ? '#3BB56C' : 'black', justifyContent: 'left', borderRadius: '8px', p: '14px 16px'
                  }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterBatubara["id_listing_status"] === '2' ? '#3BB56C' : '#E8EBF2', }} />}>
                  {translate('my-listing.btn-unpublish')}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  className="w-500"
                  onClick={(e) => props.handleFilterBatubara("3", "id_listing_status", 'mobile')}
                  value={props.filterBatubara["id_listing_status"]} sx={{
                    lineHeight: 'normal',
                    "&:hover": {
                      borderColor: props.filterBatubara["id_listing_status"] === '3' ? '#3BB56C' : '#E8EBF2',
                    }, textTransform: 'none', borderColor: props.filterBatubara["id_listing_status"] === '3' ? '#3BB56C' : '#E8EBF2', color: props.filterBatubara["id_listing_status"] === '3' ? '#3BB56C' : 'black', justifyContent: 'left', borderRadius: '8px', p: '14px 16px'
                  }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterBatubara["id_listing_status"] === '3' ? '#3BB56C' : '#E8EBF2', }} />}>
                  {translate('my-listing.btn-draft')}
                </Button>
              </Grid>
            </Grid>
          </Box>}

        </Box>
        <Divider orientation="horizontal" style={{ marginTop: 10, marginBottom: 10, marginLeft: 16, marginRight: 16 }} />
        <Stack direction={'row'} spacing={1} sx={{ ml: 2, mr: 2, mb: 2 }}>
          {props.page === 'default' ? <Button
            className="w-500"
            onClick={(e) => {
              props.handleLocationReset('coal')
              props.resetFilterBatubara()
              props.handleCloseModalFilter()
            }}
            fullWidth
            variant="outlined"
            sx={{
              textTransform: "none", border: '1px solid var(--primarry-blue, #2457B9)',
              p: '12px 16px',
              borderRadius: '8px',
              color: '#2457B9'
            }}
          >
            {translate('jual-beli.btn-cancel')}
          </Button> : <Button
            className="w-500"
            onClick={(e) => {

              props.resetFilterBatubara()
              props.handleCloseModalFilter()
            }}
            fullWidth
            variant="outlined"
            sx={{
              textTransform: "none", border: '1px solid var(--primarry-blue, #2457B9)',
              p: '12px 16px',
              borderRadius: '8px',
              color: '#2457B9'
            }}
          >
            {translate('jual-beli.btn-cancel')}
          </Button>}
          <Button
            className="button-blue-contained w-500"
            onClick={(e) => {
              props.Filter('coal')
              props.handleCloseModalFilter()
            }}
            fullWidth
            variant="contained"
            sx={{ padding: '12px 16px', textTransform: 'none' }}
          >
            {translate('page_explore.btn-filter')}
          </Button>
        </Stack>
      </Drawer>
    </Box>
  );
}