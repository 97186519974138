const dev = {
    env: 'DEV',
    baseURL: 'https://indomine-cms.cranium.id',
    // baseURL: 'http://localhost:1337',
    staging: 'https://indomine.cranium.id',
    // staging: 'http://localhost:3000',
    appId: '961618248228390'
}
const qa = {
    env: 'QA',
    baseURL: ''
}
const prod = {
    env: 'PROD',
    baseURL: 'https://cms.indomine.com',
    staging: 'https://indomine.com',
    appId: '961618248228390'
}

const config = {
    ...prod
    //...qa
    // ...dev
}

export { config };
