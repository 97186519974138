import CloseIcon from '@mui/icons-material/Close';
import { Box, Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import jenismuatanIcon from "../assets/jenismuatan-ic.png";
import laycanIcon from "../assets/laycan-ic.png";
import positionActiveIcon from "../assets/position-active-ic.png";
import positionInactiveIcon from "../assets/position-inactive-ic.png";
import SizetongkangIcon from "../assets/sizetongkang-ic.png";

export default function CardPermintaanSewaTongkang(props) {
  const [translate,] = useTranslation("global");
  const [x, setX] = React.useState([])


  useEffect(() => {
    let filter = props?.data?.ukuran_tongkang.split(",");
    setX(filter.filter(item => item));
  }, []);



  return (
    <Box>
      {/* Sewa tongkang TimeCharter Section */}
      {Number(props.data.type_tongkang) === 2 && <Card sx={{ borderRadius: 2, marginTop: '16px', mb: "40px", padding: { sm: '20px', xs: '16px' }, border: '2px solid #2457B9' }}>
        <CardContent sx={{ padding: '0px !important' }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              className="w-700"
              sx={{ color: '#081130', fontSize: { sm: '18px', xs: '16px' } }}
            >
              {Number(props.data.type_tongkang) === 2 || Number(props.data.type_tongkang) === 3 ? translate('status.titlesewatongkang') : translate('status.titlepermintaantongkang')}
            </Typography>
            <CloseIcon onClick={() => { props.resetFunc() }} sx={{ color: '#56617B' }} />
          </Stack>
          <Stack
            direction="row"
            spacing={0}
            sx={{ mt: '21px', mb: '16px', justifyContent: { md: 'space-between', xs: 'start' } }}
          >
            <Stack direction={'row'} alignItems={'center'}>
            </Stack>
            <Stack direction={'row'} sx={{ textAlign: "right", alignItems: "center", display: 'flex' }}  >
              {props.data.price === '' ? (
                <Typography
                  sx={{ fontSize: { sm: '20px', xs: '18px' }, color: '#081130', mr: 1 }}
                  className="w-700"
                >
                  {props.data.currency === 'idr' ? 'Rp' : '$'} -
                </Typography>
              ) : (
                <Typography
                  sx={{ fontSize: { sm: '20px', xs: '18px' }, color: '#081130', mr: 1 }}
                  className="w-700"
                >
                  {props.data.currency === 'idr' ? 'Rp' : '$'} {new Intl.NumberFormat().format(Number(props.data.price))}
                </Typography>
              )}

              <Typography
                sx={{ fontSize: { sm: '16px', xs: '12px' }, color: '#56617B' }}
                className="w-500"
              >
                {Number(props.data.type_tongkang) === 2 ? '/month' : '/MT'}
              </Typography>
            </Stack>
          </Stack>
          <Grid
            marginTop={1}
            container
            direction="row"
            alignItems="left"
            justifyContent="left"
            spacing={{ xs: '12px', sm: '20px' }}
            sx={{ marginBottom: "16px" }}
          >
            <Grid item lg={2.4} md={4} xs={6}>
              <Box sx={{ background: "#F4F6FA", borderRadius: 2, padding: { sm: "12px 12px 12px 16px", xs: '12px' } }}>
                <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>
                  <Typography className="w-700" sx={{ color: '#081130', fontSize: { sm: '20px', xs: '18px' }, }}>
                    {props?.data?.ukuran_tongkang === "" ? "-" : props?.data?.ukuran_tongkang}
                  </Typography>
                  <img alt="ukurantongkang-icon" src={SizetongkangIcon} height={20} width={20}></img>
                </Stack>
                <Typography className="w-500" sx={{ color: '#56617B', fontSize: { sm: '14px', xs: '12px' } }} >
                  {translate('page_explore.ukurantongkang')}
                </Typography>
              </Box>
            </Grid>
            {Number(props.data.type_tongkang) === 3 && <Grid item lg={2.4} md={4} xs={6}>
              <Box sx={{ background: "#F4F6FA", borderRadius: 2, padding: { sm: "12px 12px 12px 16px", xs: '12px' } }}>
                <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>
                  <Typography className="w-700" sx={{ color: '#081130', fontSize: { sm: '20px', xs: '18px' } }}>
                    {props?.data?.jenis_muatan === "" ? "-" : props?.data?.jenis_muatan}
                  </Typography>
                  <img alt="laycan-icon" src={jenismuatanIcon} height={20} width={20}></img>
                </Stack>
                <Typography className="w-500" sx={{ color: '#56617B', fontSize: { sm: '14px', xs: '12px' } }} >
                  {translate('jual-beli.jenismuatan')}
                </Typography>
              </Box>
            </Grid>}
            <Grid item lg={2.4} md={4} xs={6}>
              <Box sx={{ background: "#F4F6FA", borderRadius: 2, padding: { sm: "12px 12px 12px 16px", xs: '12px' } }}>
                <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>
                  <Typography className="w-700" sx={{ color: '#081130', fontSize: { sm: '20px', xs: '18px' } }}>
                    {props?.data?.tahun_buat === "" ? "-" : props?.data?.tahun_buat}
                  </Typography>
                  <img alt="laycan-icon" src={laycanIcon} height={20} width={20}></img>
                </Stack>
                <Typography className="w-500" sx={{ color: '#56617B', fontSize: { sm: '14px', xs: '12px' } }} >
                  {translate('permintaan.tahunbuat')}
                </Typography>
              </Box>
            </Grid>

            {Number(props.data.type_tongkang) === 3 && <Grid item lg={2.4} md={4} xs={6}>
              <Box sx={{ background: "#F4F6FA", borderRadius: 2, padding: { sm: "12px 12px 12px 16px", xs: '12px' } }}>
                <Typography className="w-700" sx={{ color: '#081130', fontSize: { sm: '20px', xs: '18px' } }}>
                  {new Intl.NumberFormat().format(Number(props.data.quantity_muatan))}
                </Typography>
                <Typography className="w-500" sx={{ color: '#56617B', fontSize: { sm: '14px', xs: '12px' } }} >
                  {translate('page_explore.quantitymuatan')}
                </Typography>
              </Box>
            </Grid>}
          </Grid>

          {Number(props.data.type_tongkang) === 3 && <div>
            <Grid container direction='row' alignItems='center' sx={{ mt: 2 }}>
              <img style={{ marginRight: 10 }} alt="Nikel-icon" src={positionInactiveIcon} height={20}></img>
              <Typography
                className="w-500"
                sx={{ fontSize: 14, color: "#56617B", mr: 1 }}
              >
                Loading Area
              </Typography>
              <Typography
                className="w-700"
                sx={{ fontSize: 16, color: "#081130", }}
              >
                {props?.data?.area_muat_name === '' ? '-' : props?.data?.area_muat_name}
              </Typography>
            </Grid>
            <Grid container direction='row' alignItems='center' sx={{ mt: 1 }}>
              <img style={{ marginRight: 10 }} alt="Nikel-icon" src={positionActiveIcon} height={20}></img>
              <Typography
                className="w-500"
                sx={{ fontSize: 14, color: "#56617B", mr: 1 }}
              >
                Loading Port
              </Typography>
              <Typography
                className="w-700"
                sx={{ fontSize: 16, color: "#081130", fontWeight: 700 }}
              >
                {props?.data?.pelabuhan_muat_name === '' ? '-' : props?.data?.pelabuhan_muat_name}
              </Typography>
            </Grid>
            <Grid container direction='row' alignItems='center' sx={{ mt: 2 }}>
              <img style={{ marginRight: 10 }} alt="Nikel-icon" src={positionInactiveIcon} height={20}></img>
              <Typography
                className="w-500"
                sx={{ fontSize: 14, color: "#56617B", mr: 1 }}
              >
                Discharge Area
              </Typography>
              <Typography
                className="w-700"
                sx={{ fontSize: 16, color: "#081130", }}
              >
                {props?.data?.area_bongkar_name === '' ? '-' : props?.data?.area_bongkar_name}
              </Typography>
            </Grid>
            <Grid container direction='row' alignItems='center' sx={{ mt: 1 }}>
              <img style={{ marginRight: 10 }} alt="Nikel-icon" src={positionActiveIcon} height={20}></img>
              <Typography
                className="w-500"
                sx={{ fontSize: 14, color: "#56617B", mr: 1 }}
              >
                Discharge Port
              </Typography>
              <Typography
                className="w-700"
                sx={{ fontSize: 16, color: "#081130", fontWeight: 700 }}
              >
                {props?.data?.pelabuhan_bongkar_name === '' ? '-' : props?.data?.pelabuhan_bongkar_name}
              </Typography>
            </Grid>
          </div>}
          {Number(props.data.type_tongkang) === 2 && <Stack
            direction="row"
            alignItems="center"
            justifyContent="start"
            spacing={1}
          >
            <Typography
              className="w-500 s-1622"
              sx={{ color: "#56617B" }}
            >
              Loading Area
            </Typography>
            <Typography
              className="w-700 s-1622"
              sx={{ color: "#081130", }}
            >
              {props?.data?.area_muat_name === '' ? '-' : props?.data?.area_muat_name}
            </Typography>
          </Stack>}
        </CardContent>
      </Card>}
      {/* Sewa tongkang FreightCharter Section */}
      {Number(props.data.type_tongkang) === 3 &&
        <Card sx={{ borderRadius: 2, marginTop: '16px', mb: "40px", padding: { sm: '20px', xs: '16px' }, border: '2px solid #2457B9' }}>
          <CardContent sx={{ padding: '0px !important' }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                className="w-700"
                sx={{ color: '#081130', fontSize: { sm: '18px', xs: '16px' } }}
              >
                {translate('status.titlesewatongkang')}
              </Typography>
              <CloseIcon onClick={() => { props.resetFunc() }} sx={{ color: '#56617B' }} />
            </Stack>
            <Stack
              direction="row"
              spacing={0}
              sx={{ mt: '21px', mb: '16px', justifyContent: { md: 'space-between', xs: 'start' } }}
            >
              <Stack direction={'row'} alignItems={'center'}>
              </Stack>
              <Stack direction={'row'} sx={{ textAlign: "left", alignItems: "center", display: 'flex' }}  >
                {props.data.price === '' ? (
                  <Typography
                    sx={{ fontSize: { sm: '20px', xs: '18px' }, color: '#081130', mr: 1 }}
                    className="w-700"
                  >
                    {props.data.currency === 'idr' ? 'Rp' : '$'} -
                  </Typography>
                ) : (
                  <Typography
                    sx={{ fontSize: { sm: '20px', xs: '18px' }, color: '#081130', mr: 1 }}
                    className="w-700"
                  >
                    {props.data.currency === 'idr' ? 'Rp' : '$'} {new Intl.NumberFormat().format(Number(props.data.price))}
                  </Typography>
                )}

                <Typography
                  sx={{ fontSize: { sm: '16px', xs: '12px' }, color: '#56617B' }}
                  className="w-500"
                >
                  {Number(props.data.type_tongkang) === 2 ? '/month' : '/MT'}
                </Typography>
              </Stack>
            </Stack>

            <Typography className="w-500" sx={{ color: '#56617B', fontSize: { sm: '14px', xs: '12px' }, mb: 1 }} >
              {translate('page_explore.ukurantongkang')}
            </Typography>

            <Grid container sx={{ mb: 1 }} spacing={1} >
              {
                x.map((data) => {

                  return (
                    <Grid item>
                      <Box sx={{ background: "#F4F6FA", borderRadius: 2, padding: { sm: "12px 12px 12px 16px", xs: '12px' } }}>
                        <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>
                          <Typography className="w-700" sx={{ color: '#081130', fontSize: { sm: '20px', xs: '18px' }, }}>
                            {data}
                          </Typography>

                        </Stack>

                      </Box>
                    </Grid>
                  )

                })}

            </Grid>
            <Grid

              container
              direction="row"
              alignItems="left"
              justifyContent="left"
              spacing={{ xs: '12px', sm: '20px' }}
              sx={{ marginBottom: "16px", mt: 2 }}
            >

              {Number(props.data.type_tongkang) === 3 && <Grid item lg={2.4} md={4} xs={6}>
                <Box sx={{ background: "#F4F6FA", borderRadius: 2, padding: { sm: "12px 12px 12px 16px", xs: '12px' } }}>
                  <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>
                    <Typography className="w-700" sx={{ color: '#081130', fontSize: { sm: '20px', xs: '18px' } }}>
                      {props?.data?.jenis_muatan === "" ? "-" : props?.data?.jenis_muatan}
                    </Typography>
                    <img alt="laycan-icon" src={jenismuatanIcon} height={20} width={20}></img>
                  </Stack>
                  <Typography className="w-500" sx={{ color: '#56617B', fontSize: { sm: '14px', xs: '12px' } }} >
                    {translate('jual-beli.jenismuatan')}
                  </Typography>
                </Box>
              </Grid>}
              <Grid item lg={2.4} md={4} xs={6}>
                <Box sx={{ background: "#F4F6FA", borderRadius: 2, padding: { sm: "12px 12px 12px 16px", xs: '12px' } }}>
                  <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>
                    <Typography className="w-700" sx={{ color: '#081130', fontSize: { sm: '20px', xs: '18px' } }}>
                      {props?.data?.tahun_buat === "" ? "-" : props?.data?.tahun_buat}
                    </Typography>
                    <img alt="laycan-icon" src={laycanIcon} height={20} width={20}></img>
                  </Stack>
                  <Typography className="w-500" sx={{ color: '#56617B', fontSize: { sm: '14px', xs: '12px' } }} >
                    {translate('permintaan.tahunbuat')}
                  </Typography>
                </Box>
              </Grid>

              {Number(props.data.type_tongkang) === 3 && <Grid item lg={2.4} md={4} xs={6}>
                <Box sx={{ background: "#F4F6FA", borderRadius: 2, padding: { sm: "12px 12px 12px 16px", xs: '12px' } }}>
                  <Typography className="w-700" sx={{ color: '#081130', fontSize: { sm: '20px', xs: '18px' } }}>
                    {new Intl.NumberFormat().format(Number(props.data.quantity_muatan))}
                  </Typography>
                  <Typography className="w-500" sx={{ color: '#56617B', fontSize: { sm: '14px', xs: '12px' } }} >
                    {translate('page_explore.quantitymuatan')}
                  </Typography>
                </Box>
              </Grid>}
            </Grid>

            {Number(props.data.type_tongkang) === 3 && <div>
              <Grid container direction='row' alignItems='center' sx={{ mt: 2 }}>
                <img style={{ marginRight: 10 }} alt="Nikel-icon" src={positionInactiveIcon} height={20}></img>
                <Typography
                  className="w-500"
                  sx={{ fontSize: 14, color: "#56617B", mr: 1 }}
                >
                  Loading Area
                </Typography>
                <Typography
                  className="w-700"
                  sx={{ fontSize: 16, color: "#081130", }}
                >
                  {props?.data?.area_muat_name === '' ? '-' : props?.data?.area_muat_name}
                </Typography>
              </Grid>
              <Grid container direction='row' alignItems='center' sx={{ mt: 1 }}>
                <img style={{ marginRight: 10 }} alt="Nikel-icon" src={positionActiveIcon} height={20}></img>
                <Typography
                  className="w-500"
                  sx={{ fontSize: 14, color: "#56617B", mr: 1 }}
                >
                  Loading Port
                </Typography>
                <Typography
                  className="w-700"
                  sx={{ fontSize: 16, color: "#081130", fontWeight: 700 }}
                >
                  {props?.data?.pelabuhan_muat_name === '' ? '-' : props?.data?.pelabuhan_muat_name}
                </Typography>
              </Grid>
              <Grid container direction='row' alignItems='center' sx={{ mt: 2 }}>
                <img style={{ marginRight: 10 }} alt="Nikel-icon" src={positionInactiveIcon} height={20}></img>
                <Typography
                  className="w-500"
                  sx={{ fontSize: 14, color: "#56617B", mr: 1 }}
                >
                  Discharge Area
                </Typography>
                <Typography
                  className="w-700"
                  sx={{ fontSize: 16, color: "#081130", }}
                >
                  {props?.data?.area_bongkar_name === '' ? '-' : props?.data?.area_bongkar_name}
                </Typography>
              </Grid>
              <Grid container direction='row' alignItems='center' sx={{ mt: 1 }}>
                <img style={{ marginRight: 10 }} alt="Nikel-icon" src={positionActiveIcon} height={20}></img>
                <Typography
                  className="w-500"
                  sx={{ fontSize: 14, color: "#56617B", mr: 1 }}
                >
                  Discharge Port
                </Typography>
                <Typography
                  className="w-700"
                  sx={{ fontSize: 16, color: "#081130", fontWeight: 700 }}
                >
                  {props?.data?.pelabuhan_bongkar_name === '' ? '-' : props?.data?.pelabuhan_bongkar_name}
                </Typography>
              </Grid>
            </div>}
            {Number(props.data.type_tongkang) === 2 && <Stack
              direction="row"
              alignItems="center"
              justifyContent="start"
              spacing={1}
            >
              <Typography
                className="w-500 s-1622"
                sx={{ color: "#56617B" }}
              >
                Loading Area
              </Typography>
              <Typography
                className="w-700 s-1622"
                sx={{ color: "#081130", }}
              >
                {props?.data?.area_muat_name === '' ? '-' : props?.data?.area_muat_name}
              </Typography>
            </Stack>}
          </CardContent>
        </Card>}

      <Stack direction={'row'} alignItems={'center'} sx={{ mt: '40px', }}>
        <Typography className="w-700"
          sx={{ fontSize: 16, color: "#081130", mr: 1, }}
        >
          {translate('status.hasilpencarian')}
        </Typography>
        <Box sx={{ background: "#F4F6FA", borderRadius: '6px', padding: '4px' }}>
          <Typography
            className="w-700"
            sx={{ fontSize: 16, color: "#56617B", }}
          >
            {props.result}
          </Typography>
        </Box>
      </Stack>
    </Box>
  )
}
