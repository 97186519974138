
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { Avatar, Grid, Hidden, IconButton, Typography } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { config } from '../services/constants';
import { GlobalState } from "../services/global";
export default function TableCoalCompany(props) {
  let { data, } = props;

  const [translate,] = useTranslation("global");
  const [productList, setProductList] = useState(data);
  const { currency } = React.useContext(GlobalState)
  useEffect(() => {

    setProductList(data)
  }, [data])

  const columnsExplorePage = [
    {
      headerClassName: 'super-app-theme--header', field: 'calorie', headerName: 'Gar', width: 90, sortable: false, renderCell: (params) => (
        <Grid container onClick={(e) => {
          window.location.href = `/penawaran/detail?type=1&id_detail=${params.id}`
        }} sx={{ display: 'flex', cursor: 'pointer' }} justifyContent={'center'} alignItems={'center'}>



          <Typography
            className="w-700"
            sx={{ fontSize: 14, ml: 1, color: '#081130' }}
          >
            {(params.row.calorie).toLocaleString(undefined, { maximumFractionDigits: 2 })}

          </Typography>

        </Grid>
      ),
    },
    {
      headerClassName: 'super-app-theme--header', field: 'total_sulfur', headerName: translate('page_explore.total-sulfur') + ' (%)', width: 90, sortable: false, renderCell: (params) => (
        <Grid container onClick={(e) => {
          window.location.href = `/penawaran/detail?type=1&id_detail=${params.id}`
        }} sx={{ display: 'flex', cursor: 'pointer' }} justifyContent={'center'} alignItems={'center'}>


          <Typography
            className="w-700"
            sx={{ fontSize: 14, ml: 1, color: '#081130' }}
          >
            {(params.row.total_sulfur).toLocaleString(undefined, { maximumFractionDigits: 2 })}
          </Typography>

        </Grid>
      ),
    },
    {
      headerClassName: 'super-app-theme--header', field: 'ash', headerName: 'Ash' + ' (%)', width: 90, sortable: false, renderCell: (params) => (
        <Grid container onClick={(e) => {
          window.location.href = `/penawaran/detail?type=1&id_detail=${params.id}`
        }} sx={{ display: 'flex', cursor: 'pointer' }} justifyContent={'center'} alignItems={'center'}>


          <Typography
            className="w-700"
            sx={{ fontSize: 14, ml: 1, color: '#081130' }}
          >
            {(params.row.ash).toLocaleString(undefined, { maximumFractionDigits: 2 })}
          </Typography>

        </Grid>
      ),
    },

    {
      headerClassName: 'super-app-theme--header', field: 'price', headerName: currency === 'idr' ? 'Rp  (/MT)' : 'USD  (/MT)', width: 100, sortable: false, renderCell: (params) => (
        <Grid container onClick={(e) => {
          window.location.href = `/penawaran/detail?type=1&id_detail=${params.id}`
        }} sx={{ display: 'flex', cursor: 'pointer' }} justifyContent={'end'} alignItems={'center'}>

          <Typography
            className="w-700"
            sx={{ fontSize: 14, ml: 1, color: '#081130' }}
          >
            {(params.row.price).toLocaleString(undefined, { maximumFractionDigits: 2 })}

          </Typography>

        </Grid>
      ),
    },
    {
      headerClassName: 'super-app-theme--header', field: 'location', headerName: translate('title.location'), width: 180, sortable: false, renderCell: (params) => (
        <Grid container sx={{ display: 'flex', cursor: 'pointer' }} justifyContent={'end'} alignItems={'center'}
          onClick={(e) => { window.location.href = `/penawaran/detail?type=1&id_detail=${params.id}` }}
        >
          <Typography
            className="w-500"
            sx={{ fontSize: 14, color: '#081130' }}
          >

            {(params?.row.location_name && params?.row.location_name.length > 18) ? params?.row.location_name.slice(0, 18) + '...' : params?.row.location_name}
          </Typography>

        </Grid>
      ),
    },

    {
      headerClassName: 'last-theme--header', field: 'trader', headerName: 'Trader', width: 270, renderCell: (params) => (
        <Grid sx={{ display: 'flex' }} justifyContent={'center'} alignItems={'center'}>
          {params.row.user_image === null || params.row.user_image === '' ? <Avatar sx={{ bgcolor: 'blue', mr: 1, height: 32, width: 32 }} aria-label="recipe">

          </Avatar> : <img style={{ borderRadius: '50%', marginRight: 6 }} src={config.baseURL + params.row.user_image} height={32} width={32}></img>}
          <Typography
            className="w-500"
            sx={{ fontSize: 14, ml: 1, color: '#081130' }}
          >
            {(params?.row.user_name && params?.row.user_name.length > 18) ? params?.row.user_name.slice(0, 20) + '...' : params?.row.user_name}
          </Typography>

        </Grid>
      ),
    },
    {
      headerClassName: 'last-theme--header', field: 'action', headerName: ' ', sortable: false, width: 80, renderCell: (params) => (
        <Grid
          container
          justifyContent="end"
          alignItems="center"
        // direction="row"
        >
          <IconButton size="small">
            <ArrowForwardIosIcon onClick={(e) => {
              window.location.href = `/penawaran/detail?type=1&id_detail=${params.id}`
            }} sx={{ fontSize: 18 }}

            />
          </IconButton>
        </Grid>
      ),
    },
  ];

  return (
    <Hidden mdDown>
      <div className="ex-ui">
        <DataGrid
          autoHeight
          sx={{
            borderRadius: '8px',
            '& .super-app-theme--header': {
              borderRight: 1,
              borderColor: '#E8EBF2',
              fontFamily: 'Satoshi-500 !important',
              color: '#081130 !important',
              outline: "none !important",
              justifyContent: 'end'
            },
            '& .last-theme--header': {
              borderColor: '#E8EBF2',
              fontFamily: 'Satoshi-500 !important',
              color: '#081130 !important',
              outline: "none !important",
              justifyContent: 'end'
            },
            '.MuiDataGrid-iconButtonContainer': {
              visibility: 'visible',
            },
            '.MuiDataGrid-sortIcon': {
              opacity: 'inherit !important',
            },
            '.MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            ".super-app-theme--header .MuiDataGrid-columnHeaderTitleContainer": {
              justifyContent: 'end',
              pl: '4px'
            },
            ".last-theme--header .MuiDataGrid-columnHeaderTitleContainer": {
              justifyContent: 'start',
              pl: '4px'
            }
          }}
          rows={productList}
          disableSelectionOnClick={true}
          disableRowSelectionOnClick
          sortingOrder={['desc', 'asc']}
          disableColumnMenu
          components={{
            ColumnSortedAscendingIcon: UnfoldMoreIcon,
            ColumnSortedDescendingIcon: UnfoldMoreIcon
          }}
          getRowId={(row) => row.product_id}
          columns={columnsExplorePage}
        />
      </div>
    </Hidden>
  )
}

TableCoalCompany.propTypes = {
  data: PropTypes.any,
  nav: PropTypes.any
};