import Divider from "@material-ui/core/Divider";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CloseIcon from '@mui/icons-material/Close';
import PercentIcon from '@mui/icons-material/Percent';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import Drawer from '@mui/material/Drawer';
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import DateRangePickerCustom from '../components/DateRangePickerCustom';
import { GlobalState } from "../services/global";
import { VerticalDivider, VerticalDividerLeft, formatDate } from '../services/primary';
import Value from "../services/value.json";

export default function DrawerFilterNikel(props) {
  const [translate,] = useTranslation("global");
  const { currency } = React.useContext(GlobalState)
  const [validationminNi, setValidationminNi] = useState(false)
  const [validationminFe, setValidationminFe] = useState(false)
  const [validationminAir, setValidationminAir] = useState(false)
  const [validationminRatio, setValidationminRatio] = useState(false)
  const [validationmaksNi, setValidationmaksNi] = useState(false)
  const [validationmaksFe, setValidationmaksFe] = useState(false)
  const [validationmaksAir, setValidationmaksAir] = useState(false)
  const [validationmaksRatio, setValidationmaksRatio] = useState(false)

  return (
    <Box sx={{ flexGrow: 1, display: { xs: "flex", sm: 'flex', md: "flex" }, }}>

      <Drawer
        className="box-filter-drawer"
        anchor="bottom"
        open={Boolean(props.open)}
        onClose={props.close}
      >

        <Box >
          <Stack
            direction="row"
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{ pl: '16px', pt: '19px', pr: 2, pb: '19px' }}
          >
            <Typography className="w-700" sx={{ fontSize: 18, color: '#081130' }}>
              Filter
            </Typography>
            <Stack direction={'row'} alignItems={'center'}>
              {props.page === 'default' ? <Button className="w-500" onClick={(e) => {
                props.handleLocationReset('nikel')
                props.resetFilterNikel('mobile')

              }} sx={{ fontSize: 16, color: '#2457B9', textTransform: 'none' }} variant="text"> Reset filter</Button> : <Button className="w-500" onClick={(e) => {

                props.resetFilterNikel('mobile')

              }} sx={{ fontSize: 16, color: '#2457B9', textTransform: 'none' }} variant="text"> Reset filter</Button>}

              <Divider orientation="vertical" style={{ marginTop: 10, marginBottom: 10, marginLeft: 10, marginRight: 10 }} flexItem />
              <CloseIcon onClick={props.close} />
            </Stack>
          </Stack>

          <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
            <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
              {translate('page_explore.kadar-ni')}
            </Typography>
            <Grid
              container
              direction="row"
              justifyContent={'space-between'}
              spacing={2}
            >
              <Grid item xs={6}>
                <TextField
                  className="box-input-filter"
                  type="number"
                  size="small"
                  placeholder="Min"
                  error={validationminNi}
                  FormHelperTextProps={{ style: { fontSize: 10 } }}
                  helperText={validationminNi ? translate('status.minvalue') + ' ' + Value.Ni.min.toString() : ''}
                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    props.handleFilterNikel(e.target.value, 'mobile', "min_kadar_ni")

                    if (value < parseInt(Value.Ni.min)) {
                      setValidationminNi(true)
                    } else {
                      setValidationminNi(false)
                    }
                  }}
                  value={props.filterNikel["min_kadar_ni"]}
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                      display: "none",
                    },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                    width: '100%', input: {
                      fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <VerticalDivider />
                        <PercentIcon sx={{ color: '#56617B', fontSize: '16px' }} />
                      </InputAdornment>
                    ),
                  }}
                />

              </Grid>
              <Grid item xs={6}>
                <TextField
                  className="box-input-filter"
                  type="number"
                  size="small"
                  placeholder="Maks"
                  error={validationmaksNi}
                  FormHelperTextProps={{ style: { fontSize: 10 } }}
                  helperText={validationmaksNi ? translate('status.maksvalue') + ' ' + Value.Ni.maks.toString() : ''}
                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    props.handleFilterNikel(e.target.value, 'mobile', "max_kadar_ni")
                    if (value > parseInt(Value.Ni.maks)) {
                      setValidationmaksNi(true)
                    } else {
                      setValidationmaksNi(false)
                    }
                  }}
                  value={props.filterNikel["max_kadar_ni"]}
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                      display: "none",
                    },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                    width: '100%', input: {
                      fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <VerticalDivider />
                        <PercentIcon sx={{ color: '#56617B', fontSize: '16px' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
            <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
              {translate('page_explore.kadar-fe')}
            </Typography>
            <Grid
              container
              direction="row"
              justifyContent={'space-between'}
              spacing={2}
            >
              <Grid item xs={6}>
                <TextField
                  className="box-input-filter"
                  type="number"
                  size="small"
                  placeholder="Min"
                  error={validationminFe}
                  FormHelperTextProps={{ style: { fontSize: 10 } }}
                  helperText={validationminFe ? translate('status.minvalue') + ' ' + Value.Fe.min.toString() : ''}
                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    props.handleFilterNikel(e.target.value, 'mobile', "min_kadar_fe")
                    if (value < parseInt(Value.Fe.min)) {
                      setValidationminFe(true)
                    } else {
                      setValidationminFe(false)
                    }
                  }}
                  value={props.filterNikel["min_kadar_fe"]}
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                      display: "none",
                    },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                    width: '100%', input: {
                      fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <VerticalDivider />
                        <PercentIcon sx={{ color: '#56617B', fontSize: '16px' }} />
                      </InputAdornment>
                    ),
                  }}
                />

              </Grid>
              <Grid item xs={6}>
                <TextField
                  className="box-input-filter"
                  type="number"
                  size="small"
                  placeholder="Maks"
                  error={validationmaksFe}
                  FormHelperTextProps={{ style: { fontSize: 10 } }}
                  helperText={validationmaksFe ? translate('status.maksvalue') + ' ' + Value.Fe.maks.toString() : ''}
                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    props.handleFilterNikel(e.target.value, 'mobile', "max_kadar_fe")
                    if (value > parseInt(Value.Fe.maks)) {
                      setValidationmaksFe(true)
                    } else {
                      setValidationmaksFe(false)
                    }
                  }}
                  value={props.filterNikel["max_kadar_fe"]}
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                      display: "none",
                    },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                    width: '100%', input: {
                      fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <VerticalDivider />
                        <PercentIcon sx={{ color: '#56617B', fontSize: '16px' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
            <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
              {translate('page_explore.kadar-air')}
            </Typography>
            <Grid
              container
              direction="row"
              justifyContent={'space-between'}
              spacing={2}
            >
              <Grid item xs={6}>
                <TextField
                  className="box-input-filter"
                  type="number"
                  size="small"
                  placeholder="Min"
                  error={validationminAir}
                  FormHelperTextProps={{ style: { fontSize: 10 } }}
                  helperText={validationminAir ? translate('status.minvalue') + ' ' + Value.Moisture.min.toString() : ''}
                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    props.handleFilterNikel(e.target.value, 'mobile', "min_kadar_air")
                    if (value < parseInt(Value.Moisture.min)) {
                      setValidationminAir(true)
                    } else {
                      setValidationminAir(false)
                    }
                  }}
                  value={props.filterNikel["min_kadar_air"]}
                  sx={{
                    width: '100%', input: {
                      fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <VerticalDivider />
                        <PercentIcon sx={{ color: '#56617B', fontSize: '16px' }} />
                      </InputAdornment>
                    ),
                  }}
                />

              </Grid>
              <Grid item xs={6}>
                <TextField
                  className="box-input-filter"
                  type="number"
                  size="small"
                  placeholder="Maks"
                  error={validationmaksAir}
                  FormHelperTextProps={{ style: { fontSize: 10 } }}
                  helperText={validationmaksAir ? translate('status.maksvalue') + ' ' + Value.Moisture.maks.toString() : ''}
                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    props.handleFilterNikel(e.target.value, 'mobile', "max_kadar_air")
                    if (value > parseInt(Value.Moisture.maks)) {
                      setValidationmaksAir(true)
                    } else {
                      setValidationmaksAir(false)
                    }
                  }}
                  value={props.filterNikel["max_kadar_air"]}
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                      display: "none",
                    },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                    width: '100%', input: {
                      fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <VerticalDivider />
                        <PercentIcon sx={{ color: '#56617B', fontSize: '16px' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
            <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
              Si/Mg
            </Typography>
            <Grid
              container
              direction="row"
              justifyContent={'space-between'}
              spacing={2}
            >
              <Grid item xs={6}>
                <TextField
                  className="box-input-filter"
                  type="number"
                  size="small"
                  placeholder="Min"
                  error={validationminRatio}
                  FormHelperTextProps={{ style: { fontSize: 10 } }}
                  helperText={validationminRatio ? translate('status.minvalue') + ' ' + Value["Si/Mg"].min.toString() : ''}
                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    props.handleFilterNikel(e.target.value, 'mobile', "min_si_mg_ratio")
                    if (value < parseInt(Value["Si/Mg"].min)) {
                      setValidationminRatio(true)
                    } else {
                      setValidationminRatio(false)
                    }
                  }}
                  value={props.filterNikel["min_si_mg_ratio"]}
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                      display: "none",
                    },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                    width: '100%', input: {
                      fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <VerticalDivider />
                        <PercentIcon sx={{ color: '#56617B', fontSize: '16px' }} />
                      </InputAdornment>
                    ),
                  }}
                />

              </Grid>
              <Grid item xs={6}>
                <TextField
                  className="box-input-filter"
                  type="number"
                  size="small"
                  placeholder="Maks"
                  error={validationmaksRatio}
                  FormHelperTextProps={{ style: { fontSize: 10 } }}
                  helperText={validationmaksRatio ? translate('status.maksvalue') + ' ' + Value["Si/Mg"].maks.toString() : ''}
                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    props.handleFilterNikel(e.target.value, 'mobile', "max_si_mg_ratio")
                    if (value > parseInt(Value["Si/Mg"].maks)) {
                      setValidationmaksRatio(true)
                    } else {
                      setValidationmaksRatio(false)
                    }
                  }}
                  value={props.filterNikel["max_si_mg_ratio"]}
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                      display: "none",
                    },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                    width: '100%', input: {
                      fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <VerticalDivider />
                        <PercentIcon sx={{ color: '#56617B', fontSize: '16px' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
            <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
              {translate('page_explore.harga-nikel')}
            </Typography>
            <TextField
              className="box-input-filter"
              type="number"
              size="small"
              placeholder={translate('status.min-harga')}
              onChange={(e) => props.handleFilterNikel(e.target.value, 'mobile', "min_price")}
              value={props.filterNikel["min_price"]}
              sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                  display: "none",
                },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
                width: '100%', input: {
                  fontSize: '14px', fontFamily: 'Satoshi-500'
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <VerticalDividerLeft sx={{ ml: '12px' }} />
                    {currency === 'idr' && <Typography className="w-700" sx={{ fontSize: 14, color: '#56617B', mr: '24px' }}>
                      Rp
                    </Typography>}
                    {currency === 'usd' && <Typography className="w-700" sx={{ fontSize: 16, color: '#56617B', mr: '24px' }}>
                      $
                    </Typography>}
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              className="box-input-filter"
              type="number"
              size="small"
              placeholder={translate('status.max-harga')}
              onChange={(e) => props.handleFilterNikel(e.target.value, 'mobile', "max_price")}
              value={props.filterNikel["max_price"]}
              sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                  display: "none",
                },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
                width: '100%', input: {
                  fontSize: '14px', fontFamily: 'Satoshi-500'
                }, mt: 1
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <VerticalDividerLeft sx={{ ml: '12px' }} />
                    {currency === 'idr' && <Typography className="w-700" sx={{ fontSize: 14, color: '#56617B', mr: '24px' }}>
                      Rp
                    </Typography>}
                    {currency === 'usd' && <Typography className="w-700" sx={{ fontSize: 16, color: '#56617B', mr: '24px' }}>
                      $
                    </Typography>}
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          {/* Date Picker Terbaru */}
          {props.page === 'default' && <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
            <p className='c-08 s-1419 w-500 mb4'>Laycan Date</p>
            <DateRangePickerCustom setStart={(e) =>

              props.handleFilterNikel(e ? formatDate(e) : e, 'mobile', "request_start_laycan")


            } setEnd={(e) =>

              props.handleFilterNikel(e ? formatDate(e) : e, 'mobile', "request_end_laycan")
            } />
          </Box>}

          {(props.page === 'default' || props.page === "profile") && <Box sx={{ pl: 2, pr: 2, mt: 1, mb: 2 }}>
            <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
              {translate('page_explore.lokasi')}
            </Typography>
            <FormGroup className="location-label">
              {props.dataProvince.map((option, i) => {
                if (i < 5) return (
                  <FormControlLabel onChange={(e) => props.handleFilterNikel(e, "popuplocation")} key={i} sx={{ fontSize: 14, color: '#081130' }} control={<Checkbox checked={props.selectedItems.includes(option.id)} sx={{
                    color: "#E8EBF2",
                    '&.Mui-checked': {
                      color: "#3BB56C",
                    },
                  }} />} label={option.attributes.name} value={option.id} />)
              })}
            </FormGroup>
            {props.limitProvince < 10 &&
              <Grid
                sx={{ mt: 1 }}
                container
                justifyContent="left"
                alignItems="center"
                direction="row"
              >
                <Typography onClick={(e) => {
                  props.setLimitProvince(5)
                  props.handleOpenModalLocationResponsive("nikel")
                }}
                  className="w-700"
                  sx={{ fontSize: 14, color: "#2457B9", mr: 1, cursor: "pointer" }}
                >
                  {translate('page_explore.lihatselengkapnya')}
                </Typography>
                <ArrowForwardIosIcon sx={{ fontSize: 12, color: "#2457B9" }} />
              </Grid>}
          </Box>}
          {props.page === 'listing' && <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
            <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
              {translate('my-listing.listingstatus')}
            </Typography>
            <Grid
              container
              // sx={{ mt: 1 }}
              direction="row"
              justifyContent={'space-between'}
              spacing={1}
            >
              <Grid item xs={6}>
                <Button className="w-500" onClick={(e) => props.handleFilterNikel("", "id_listing_status", 'mobile')}
                  value={props.filterNikel["id_listing_status"]} sx={{
                    lineHeight: 'normal',
                    "&:hover": {
                      borderColor: props.filterNikel["id_listing_status"] === '' ? '#3BB56C' : '#E8EBF2',
                    },
                    textTransform: 'none', borderColor: props.filterNikel["id_listing_status"] === '' ? '#3BB56C' : '#E8EBF2', color: props.filterNikel["id_listing_status"] === '' ? '#3BB56C' : 'black', justifyContent: 'left', borderRadius: '8px', p: '14px 16px'
                  }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterNikel["id_listing_status"] === '' ? '#3BB56C' : '#E8EBF2', }} />}>
                  {translate('my-listing.btn-all')}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button className="w-500" onClick={(e) => props.handleFilterNikel("1", "id_listing_status", 'mobile')}
                  value={props.filterNikel["id_listing_status"]} sx={{
                    lineHeight: 'normal',
                    "&:hover": {
                      borderColor: props.filterNikel["id_listing_status"] === '1' ? '#3BB56C' : '#E8EBF2',
                    },
                    textTransform: 'none', borderColor: props.filterNikel["id_listing_status"] === '1' ? '#3BB56C' : '#E8EBF2', color: props.filterNikel["id_listing_status"] === '1' ? '#3BB56C' : 'black', justifyContent: 'left', borderRadius: '8px', p: '14px 16px'
                  }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterNikel["id_listing_status"] === '1' ? '#3BB56C' : '#E8EBF2', }} />}>
                  {translate('my-listing.btn-publish')}
                </Button>
              </Grid>


            </Grid>
            <Grid
              container
              direction="row"
              justifyContent={'space-between'}
              sx={{ mt: '2px' }}
              spacing={1}
            >
              <Grid item xs={6}>
                <Button className="w-500" onClick={(e) => props.handleFilterNikel("2", "id_listing_status", 'mobile')}
                  value={props.filterNikel["id_listing_status"]} sx={{
                    lineHeight: 'normal',
                    "&:hover": {
                      borderColor: props.filterNikel["id_listing_status"] === '2' ? '#3BB56C' : '#E8EBF2',
                    }, textTransform: 'none', borderColor: props.filterNikel["id_listing_status"] === '2' ? '#3BB56C' : '#E8EBF2', color: props.filterNikel["id_listing_status"] === '2' ? '#3BB56C' : 'black', justifyContent: 'left', borderRadius: '8px', p: '14px 16px'
                  }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterNikel["id_listing_status"] === '2' ? '#3BB56C' : '#E8EBF2', }} />}>
                  {translate('my-listing.btn-unpublish')}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button className="w-500" onClick={(e) => props.handleFilterNikel("3", "id_listing_status", 'mobile')}
                  value={props.filterNikel["id_listing_status"]} sx={{
                    lineHeight: 'normal',
                    "&:hover": {
                      borderColor: props.filterNikel["id_listing_status"] === '3' ? '#3BB56C' : '#E8EBF2',
                    },
                    textTransform: 'none', borderColor: props.filterNikel["id_listing_status"] === '3' ? '#3BB56C' : '#E8EBF2', color: props.filterNikel["id_listing_status"] === '3' ? '#3BB56C' : 'black', justifyContent: 'left', borderRadius: '8px', p: '14px 16px'
                  }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: props.filterNikel["id_listing_status"] === '3' ? '#3BB56C' : '#E8EBF2', }} />}>
                  {translate('my-listing.btn-draft')}
                </Button>
              </Grid>


            </Grid>


          </Box>}
        </Box>
        <Divider orientation="horizontal" style={{ marginTop: 10, marginBottom: 10, marginLeft: 16, marginRight: 16 }} />
        <Stack direction={'row'} spacing={1} sx={{ ml: 2, mr: 2, mb: 2 }}>
          {props.page === 'default' ? <Button
            onClick={(e) => {
              props.handleLocationReset('nikel')
              props.resetFilterNikel()
              props.handleCloseModalFilter()
            }}
            fullWidth
            variant="outlined"
            sx={{
              textTransform: "none", border: '1px solid var(--primarry-blue, #2457B9)',
              p: '12px 16px',
              borderRadius: '8px',
              color: '#2457B9'
            }}
          >
            {translate('jual-beli.btn-cancel')}
          </Button> : <Button
            onClick={(e) => {

              props.resetFilterNikel()
              props.handleCloseModalFilter()
            }}
            fullWidth
            variant="outlined"
            sx={{
              textTransform: "none", border: '1px solid var(--primarry-blue, #2457B9)',
              p: '12px 16px',
              borderRadius: '8px',
              color: '#2457B9'
            }}
          >
            {translate('jual-beli.btn-cancel')}
          </Button>}
          <Button onClick={(e) => {
            props.Filter('nikel')
            props.handleCloseModalFilter()
          }}
            // type="submit"
            fullWidth
            variant="contained"
            sx={{ background: ' linear-gradient(270deg, #2457B9 15%, #2860CD 100%)', borderRadius: '8px', boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.12), 0px -1px 0px 0px rgba(0, 0, 0, 0.20) inset', padding: '12px 16px', textTransform: 'none' }}
          >

            {translate('page_explore.btn-filter')}
          </Button>
        </Stack>

      </Drawer>

    </Box>
  );
}