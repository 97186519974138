import Paper from '@mui/material/Paper';
import { ThemeProvider } from '@mui/material/styles';
import * as React from 'react';
import { GlobalState } from "../services/global";
import { StyledToggleButtonGroup, theme, ToggleButton } from '../services/primary';

export default function CurrencyToggle() {
    const store = localStorage.getItem('currency')
    const { currency, setcurrency } = React.useContext(GlobalState)

    const handleChange = (event, state) => {
        setcurrency(state);
        localStorage.setItem('currency', state);
        window.location.reload();
    };

    return (
        <div>
            <Paper
                elevation={0}
                sx={{
                    display: 'flex',

                    flexWrap: 'wrap',
                    borderRadius: '8px',
                    background: '#F4F6FA'
                }}
            >
                <ThemeProvider theme={theme}>
                    <StyledToggleButtonGroup
                        size="small"
                        value={currency == null ? store : currency}
                        exclusive
                        onChange={handleChange}
                        aria-label="text alignment"
                    >
                        <ToggleButton value="idr" className="w-500" sx={{ pl: 2, pr: '10px' }} >
                            IDR</ToggleButton>
                        <ToggleButton value="usd" className="w-500" sx={{ pl: 2, pr: '10px' }}>
                            USD</ToggleButton>

                    </StyledToggleButtonGroup>
                </ThemeProvider>

            </Paper>
        </div>
    );
}