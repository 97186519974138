import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';
import { Divider, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import callIcon from "../assets/call-company-ic.png";
import visibilityIcon from "../assets/eye-icon.svg";
import LogoutIC from "../assets/logout-grey.svg";
import reportIcon from "../assets/reportflag-ic.png";
import shareIcon from "../assets/share-listing-ic.png";
import soldIcon from "../assets/sold-grey-icon.png";
import deleteIcon from "../assets/trash-ic.png";
import unpublishIcon from "../assets/unpublish-ic.png";
import ModalHapusListing from "../components/ModalHapusListing";
import ModalReportListing from "../components/ModalReportListing";
import ModalUnpublish from "../components/ModalUnpublish";
import { RequestLeaveCompany, editBatubara, editNikel, editTongkang, getDetailBatubara, getDetailNikel, getDetailTongkang } from "../services/api";
import { config } from "../services/constants";
import { capitalizeEachWord, convertText } from '../services/primary';

export default function ModalShare(props) {
    const navigate = useNavigate()
    const [isOpenModalHapus, setOpenModalHapus] = React.useState(false)
    const [isOpenModalUnpublish, setOpenModalUnpublish] = React.useState(false)
    const [status, setstatus] = React.useState(false)
    const [openModalReport, setOpenModalReport] = useState(false);
    const [isLogin] = useState(localStorage.getItem('token') !== null ? true : false);
    const [translate,] = useTranslation("global");
    const [detailData, setDetailData] = useState([])
    const [url_website, seturl_website] = useState([])


    const handleOpenModal = (state) => {
        if (state === 'hapus') {
            setOpenModalHapus(true);
        } else if (state === 'unpublish') {
            setOpenModalUnpublish(true);
            setstatus('unpublish');
        } else if (state === 'publish') {
            setOpenModalUnpublish(true);
            setstatus('publish');
        }
    }

    const SubmitLeaveCompany = async (id_company) => {

        try {
            let params = {
                id_company: id_company,
            }
            const responAPI = await RequestLeaveCompany(params)
            if (responAPI.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: translate('status.successleavecompany')
                }).then(function () {
                    navigate(`/profile`, { state: { detail: 'company' } })
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: translate('status.failed'),
                    text: translate('status.subtitlefailed'),
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleOpenModalReport = () => {
        setOpenModalReport(true)
    }

    const handleCloseModal = () => {
        setOpenModalReport(false)
    }


    const handleRedirectDraft = () => {
        if (props.type === 'listing') {
            if (props.page_detail === 'coal') {
                getDataDetailBatubara(props.id)
            } else if (props.page_detail === 'nikel') {
                getDataDetailNikel(props.id)
            } else {
                getDataDetailTongkang(props.id)
            }
        }
    }

    const getDataDetailBatubara = async (id) => {
        try {
            let lang = localStorage.getItem('lang') === 'null' || localStorage.getItem('lang') === 'id' ? 'id' : localStorage.getItem('lang')
            let id_user = localStorage.getItem("id_user")
            const responAPI = await getDetailBatubara(id, lang)
            if (Number(id_user) === Number(responAPI.data.data.user_id)) {
                setDetailData(responAPI.data.data)
                navigate('/jual-batubara', { state: { page: 'edit-batubara', data: responAPI.data.data } })
            } else {
                navigate('/signin', { state: { detail: 'listing', type: 1, id_relation: id } })
            }
        } catch (error) {

            console.log(error)
        }
    }

    const getDataDetailNikel = async (id) => {
        try {
            let lang = localStorage.getItem('lang') === 'null' || localStorage.getItem('lang') === 'id' ? 'id' : localStorage.getItem('lang')
            let id_user = localStorage.getItem("id_user")
            const responAPI = await getDetailNikel(id, lang)
            if (Number(id_user) === Number(responAPI.data.data.user_id)) {
                setDetailData(responAPI.data.data)
                navigate('/jual-nikel', { state: { page: 'edit-batubara', data: responAPI.data.data } })
            } else {
                navigate('/signin', { state: { detail: 'listing', type: 2, id_relation: id } })
            }
        } catch (error) {

            console.log(error)
        }
    }

    const getDataDetailTongkang = async (id) => {
        try {
            let lang = localStorage.getItem('lang') === 'null' || localStorage.getItem('lang') === 'id' ? 'id' : localStorage.getItem('lang')
            let id_user = localStorage.getItem("id_user")
            const responAPI = await getDetailTongkang(id, lang)
            if (Number(id_user) === Number(responAPI.data.data.user_id)) {
                setDetailData(responAPI.data.data)
                if (responAPI.data.data.type_tongkang === 1) {
                    navigate('/jual-tongkang', { state: { page: 'edit-batubara', data: responAPI.data.data } })
                } else {
                    navigate('/tongkang-disewakan', { state: { page: 'edit-batubara', data: responAPI.data.data } })
                }


            } else {
                navigate('/signin', { state: { detail: 'listing', type: 3, id_relation: id } })
            }
        } catch (error) {

            console.log(error)
        }
    }

    const ActionTandaiTerjual = async (id, page_detail) => {
        if (page_detail === 'coal' || page_detail === '1') {
            try {
                let params = {
                    is_sold: "1",
                    token: localStorage.getItem("token")
                }
                const responAPI = await editBatubara(params, id)
                if (responAPI.status === 200) {
                    navigate(`/listing/detail`, { state: { detail: props.page_detail, id: props.id, data: props.data } })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: translate('status.failed'),
                        text: translate('status.subtitlefailed'),
                    })
                }


            } catch (error) {

            }
        } else if (page_detail === 'nikel' || page_detail === '2') {
            try {
                let params = {
                    is_sold: "1",
                    token: localStorage.getItem("token")
                }
                const responAPI = await editNikel(params, id)
                if (responAPI.status === 200) {
                    navigate(`/listing/detail`, { state: { detail: props.page_detail, id: props.id, data: props.data } })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: translate('status.failed'),
                        text: translate('status.subtitlefailed'),
                    })
                }


            } catch (error) {

            }
        } else {
            try {
                let params = {
                    is_sold: "1",
                    token: localStorage.getItem("token")
                }
                const responAPI = await editTongkang(params, id)
                if (responAPI.status === 200) {
                    navigate(`/listing/detail`, { state: { detail: props.page_detail, id: props.id, data: props.data } })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: translate('status.failed'),
                        text: translate('status.subtitlefailed'),
                    })
                }


            } catch (error) {

            }
        }
    }

    const SubmitReportListing = async () => {
        handleOpenModalReport();
    }

    const handleCloseModalHapus = () => {
        setOpenModalHapus(false);
    };

    const handleCloseModalUnpublish = () => {
        setOpenModalUnpublish(false);
    };

    return (
        <div>
            <Menu
                sx={{ zIndex: 99999, borderRadius: '8px' }}
                id="menu-appbar"
                elevation={1}
                anchorEl={props.anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(props.open)}
                onClose={props.onClose}
            >
                {props.page === 'permintaan' ?
                    <div>
                        {isLogin && (props.data.user_favorite_id !== null ?
                            <MenuItem sx={{ p: '10px 16px' }} onClick={() => {
                                props.onClose();
                                props.removeFavorite(props.id)
                            }}>
                                <Stack
                                    sx={{ cursor: 'pointer', p: 0 }} direction={'row'} spacing={2} alignItems={'center'} >

                                    <IconButton sx={{ p: 0 }} size="small"><FavoriteTwoToneIcon sx={{ fontSize: 18, fill: "red", width: '24px', height: '24px' }} /> </IconButton>
                                    <Typography className='w-500' sx={{ fontSize: '14px', color: '#081130', lineHeight: 'normal' }}>
                                        {translate('status.hapusfavorite')}
                                    </Typography>
                                </Stack>
                            </MenuItem> : <></>)
                        }
                        {isLogin && (props.data.user_favorite_id === null ?
                            <MenuItem sx={{ p: '10px 16px' }} onClick={() => {
                                props.onClose();
                                props.setFavorite(props.id)
                            }}>
                                <Stack
                                    sx={{ cursor: 'pointer', p: 0 }} direction={'row'} spacing={2} alignItems={'center'} >

                                    <IconButton sx={{ p: 0 }} size="small"> <FavoriteBorderIcon sx={{ fontSize: 18, width: '24px', height: '24px' }} /> </IconButton>
                                    <Typography className='w-500' sx={{ fontSize: '14px', color: '#081130', lineHeight: 'normal' }}>
                                        {translate('detail-explore.tambahkanfavorit')}
                                    </Typography>
                                </Stack>
                            </MenuItem> : <></>)
                        }


                        {isLogin && <MenuItem sx={{ p: '10px 16px' }} onClick={() => {
                            props.onClose();
                            seturl_website(`${config.staging}/permintaan/detail?type=${props.type}&id_detail=${props.id}`)
                            SubmitReportListing()
                        }}>
                            <img alt="img-icon" src={reportIcon} height={14} width={14}></img>
                            <Typography className="w-500" sx={{ fontSize: '14px' }} textAlign="center">{translate('detail-explore.laporkanlisting')}</Typography>
                        </MenuItem>}
                    </div>
                    : props.page === 'listing' ?
                        <div>
                            {props.isSold === 0 && <MenuItem sx={{ pt: '8px', pb: '8px' }} onClick={() => { ActionTandaiTerjual(props.id, props.type) }}>
                                <Stack direction={'row'} alignItems={'center'}>
                                    <img alt="img-icon" src={soldIcon} height={14} width={14}></img>
                                    <Typography className="w-500" sx={{ fontSize: '14px', ml: 1 }} textAlign="center">{translate('detail-explore.tandaiterjual')}</Typography>
                                </Stack>

                            </MenuItem>}

                            {props.status_listing === 1 || props.isSold === 1 ? <MenuItem sx={{ pt: '8px', pb: '8px' }} onClick={(e) => {
                                props.onClose();
                                navigator.clipboard.writeText(`${config.staging}/listing/detail?type=${props.type}&id_detail=${props.id}`)
                            }}>
                                <Stack direction={'row'} alignItems={'center'}>
                                    <img alt="img-icon" src={shareIcon} height={14} width={14}></img>
                                    <Typography className="w-500" sx={{ fontSize: '14px', ml: 1 }} textAlign="center">{translate('detail-explore.bagikanlisting')}</Typography>
                                </Stack>

                            </MenuItem> : <></>}

                            {props.status_listing === 1 && props.isSold === 0 && <MenuItem sx={{ pt: '8px', pb: 0 }} onClick={() => {
                                props.onClose();
                                handleOpenModal('unpublish')
                            }}>
                                <img alt="img-icon" src={unpublishIcon} height={14} width={14}></img>
                                <Typography className="w-500" sx={{ fontSize: '14px', ml: 1 }} textAlign="center">Unpublish listing</Typography>

                            </MenuItem>}
                            {props.status_listing === 2 && props.isSold === 0 && <MenuItem sx={{ pt: '8px', pb: 0 }} onClick={() => {
                                props.onClose();
                                handleOpenModal('publish')
                            }}>
                                <img alt="img-icon" src={visibilityIcon} height={14} width={14}></img>
                                <Typography className="w-500" sx={{ fontSize: '14px', ml: 1 }} textAlign="center">Publish listing</Typography>
                            </MenuItem>}
                            {props.status_listing === 3 && props.isSold === 0 && <MenuItem sx={{ pt: '8px', pb: 0 }} onClick={() => {
                                props.onClose();
                                handleRedirectDraft()
                            }}>
                                <img alt="img-icon" src={visibilityIcon} height={14} width={14}></img>
                                <Typography className="w-500" sx={{ fontSize: '14px', ml: 1 }} textAlign="center">Publish listing</Typography>
                            </MenuItem>}

                            <Divider orientation="horizontal" flexItem />
                            <MenuItem sx={{ pt: 0, pb: 0 }} onClick={() => {
                                props.onClose();
                                handleOpenModal('hapus')
                            }}>
                                <img alt="img-icon" src={deleteIcon} height={14} width={14}></img>
                                <Typography className="w-500" sx={{ fontSize: '14px', ml: 1, color: '#ED3131' }} textAlign="center">{translate('detail-explore.hapuslisting')}</Typography>
                            </MenuItem>
                            <ModalUnpublish page_detail={props.page_detail} id={props.id} status={status} open={isOpenModalUnpublish} close={handleCloseModalUnpublish}></ModalUnpublish>
                            <ModalHapusListing id={props.id} page_detail={props.page_detail} open={isOpenModalHapus} close={handleCloseModalHapus}></ModalHapusListing>
                        </div>
                        : props.page === 'mycompany' ?
                            <div >

                                {isLogin && <MenuItem onClick={() => {
                                    props.onClose();
                                    SubmitLeaveCompany(props.id)
                                }} sx={{ p: '10px 16px' }} >
                                    <img alt="img-icon" src={LogoutIC} height={19} width={19}></img>
                                    <Typography className="w-500" sx={{ fontSize: '14px' }} textAlign="center">  {translate('status.keluarperusahaan')}</Typography>
                                </MenuItem>}

                            </div>
                            :
                            <div>

                                {props.page !== 'company' && props.page !== "detailtrader" ?
                                    <MenuItem sx={{ p: '10px 16px' }} onClick={() => {
                                        props.onClose();
                                        window.open(`https://api.whatsapp.com/send?phone=+62${props.user_trader_phone}`)

                                    }} >
                                        <Stack direction={'row'} alignItems={'center'}>
                                            <img alt="img-icon" src={callIcon} height={19} width={19}></img>
                                            <Typography className="w-500" sx={{ fontSize: '14px' }} textAlign="center">     {translate('detail-explore.hubungitrader')}</Typography>
                                        </Stack>
                                    </MenuItem> : <></>}


                                {props.type == 1 || props.type == 2 || props.type == 3 ? <MenuItem sx={{ p: '10px 16px' }} onClick={(e) => {

                                    props.onClose();
                                    navigator.clipboard.writeText(`${config.staging}/${props.label}/detail?type=${props.type}&id_detail=${props.id}`)
                                }}>
                                    <Stack direction={'row'} alignItems={'center'}>
                                        <img alt="img-icon" src={shareIcon} height={19} width={19}></img>
                                        <Typography className="w-500" sx={{ fontSize: '14px' }} textAlign="center">{translate('detail-explore.bagikanlisting')}</Typography>
                                    </Stack>
                                </MenuItem> : <></>}
                                {props.type == 4 ? <MenuItem sx={{ p: '10px 16px' }} onClick={(e) => {
                                    props.onClose();
                                    navigator.clipboard.writeText(`${config.staging}/detail/${capitalizeEachWord(convertText(props.data?.name))}`)
                                }}>
                                    <Stack direction={'row'} alignItems={'center'}>
                                        <img alt="img-icon" src={shareIcon} height={19} width={19}></img>
                                        <Typography className="w-500" sx={{ fontSize: '14px' }} textAlign="center">{translate('detail-explore.bagikanperusahaan')}</Typography>
                                    </Stack>
                                </MenuItem> : <></>}
                                {props.type == 5 ? <MenuItem sx={{ p: '10px 16px' }} onClick={(e) => {
                                    props.onClose();
                                    navigator.clipboard.writeText(`${config.staging}/detailtrader?type=${props.type}&id_detail=${props.id}`)
                                }}>
                                    <Stack direction={'row'} alignItems={'center'}>
                                        <img alt="img-icon" src={shareIcon} height={19} width={19}></img>
                                        <Typography className="w-500" sx={{ fontSize: '14px' }} textAlign="center">{translate('detail-explore.bagikanprofile')}</Typography>
                                    </Stack>
                                </MenuItem> : <></>}

                                {isLogin && <MenuItem onClick={() => {
                                    props.onClose();
                                    if (props.type == 1 || props.type == 2 || props.type == 3) {
                                        seturl_website(`${config.staging}/${props.label}/detail?type=${props.type}&id_detail=${props.id}`)
                                    } else if (props.type == 4) {
                                        seturl_website(`${config.staging}/detail/${capitalizeEachWord(convertText(props.data?.name))}`)
                                    } else {
                                        seturl_website(`${config.staging}/detailtrader?type=${props.type}&id_detail=${props.id}`)
                                    }

                                    SubmitReportListing()
                                }} sx={{ p: '10px 16px' }} >
                                    <img alt="img-icon" src={reportIcon} height={19} width={19}></img>
                                    <Typography className="w-500" sx={{ fontSize: '14px' }} textAlign="center">{translate('detail-explore.laporkanlisting')}</Typography>
                                </MenuItem>}

                            </div>
                }
                {/* <ModalSuccessReport open={openModalReport} close={handleCloseModal}></ModalSuccessReport> */}
                <ModalReportListing url_website={url_website} open={openModalReport} close={handleCloseModal} page={props.type} type={props.type} id={props.id} ></ModalReportListing>
            </Menu>

        </div>

    )
}
