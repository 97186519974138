import Divider from "@material-ui/core/Divider";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CallIcon from '@mui/icons-material/Call';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Avatar, Box, Button, Card, Grid, Hidden, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-multi-carousel/lib/styles.css";
import { useLocation, useSearchParams } from 'react-router-dom';
import {
  FacebookMessengerShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";
import Slider from "react-slick";
import { default as callCompanyIcon } from "../assets/call-company-ic.png";
import copyIc from "../assets/copy-ic.png";
import Airicon from "../assets/detail/air-ic-detail.svg";
import Ashicon from "../assets/detail/ash-ic-detail.svg";
import Calorieicon from "../assets/detail/calorie-ic-detail.svg";
import Feicon from "../assets/detail/fe-ic-detail.svg";
import Niicon from "../assets/detail/ni-ic-detail.svg";
import Ratioicon from "../assets/detail/ratio-ic-detail.svg";
import SizetongkangIcon from "../assets/detail/sizetongkang-ic-detail.svg";
import Sulfuricon from "../assets/detail/sulfur-ic-detail.svg";
import fbIcon from "../assets/fb-circle-grey-ic.png";
import PenawaranIcon from "../assets/icons/penawaran-ic.svg";
import laycanIcon from "../assets/laycan-ic.png";
import locationCompanyIcon from "../assets/location-company-ic.png";
import { default as mailCompanyIcon } from "../assets/mail-company-ic.png";
import pelayarIcon from "../assets/pelayar-ic.png";
import { default as PenambangIcon } from "../assets/penambang-ic.png";
import reporticon from "../assets/reportflag-ic.png";
import telegramIcon from "../assets/telegram-circle-grey-ic.png";
import traderIcon from "../assets/trader-ic.png";
import twitterIcon from "../assets/twitter-circle-grey-ic.png";
import waIcon from "../assets/wa-circle-grey-ic.png";
import CardBeforeLogin from "../components/CardBeforeLogin";
import CardRecomendationTongkangList from "../components/CardRecomendationTongkangList";
import { DisplayPDFFile } from '../components/DisplayPDF';
import DrawerMore from "../components/DrawerMore";
import Footer from "../components/Footer";
import { Komentar } from "../components/KomentarSection";
import ModalReportListing from "../components/ModalReportListing";
import ModalShareListing from "../components/ModalShareListing";
import NavBar from "../components/Navbar";
import { addFavorite, checkFavorite, deleteFavorite, getDetailBatubara, getDetailNikel, getDetailTongkang, searchBarge } from "../services/api";
import { config } from "../services/constants";
import { GlobalState } from "../services/global";
import { capitalizeEachWord, convertText, format, numberConvert, settingsRecommendasiTongkang, settings_penawaran_tongkang } from '../services/primary';

function DetailPenawaranpage() {
  const { state } = useLocation();
  const [location, setLocation] = useState({
    state: {
      id: '',
      detail: ''
    }
  })

  const [detailTongkang, setDetailTongkang] = useState([])
  const [materialTongkang, setMaterialTongkang] = useState([])
  const [detailNikel, setDetailNikel] = useState([])
  const [detailBatubara, setDetailBatubara] = useState([])
  const [imageTongkang, setImageTongkang] = useState([])
  const [docNikel, setDocNikel] = useState([])
  const [docBatubara, setDocBatubara] = useState([])
  const [docTongkang, setDocTongkang] = useState([])
  const [position, setPosition] = useState(true)
  const [searchParams,] = useSearchParams();
  const { currency } = React.useContext(GlobalState)
  const [isOpenMore, setOpenMore] = React.useState(false)
  const [isOpenModalShare, setOpenModalShare] = React.useState(false)
  const [tongkangList, setTongkangList] = useState([]);
  const [openModalReport, setOpenModalReport] = useState(false)
  const isLogin = localStorage.getItem('token') !== null ? true : false;
  const [url_website, seturl_website] = useState([])
  const [type_data, settype_data] = useState([])

  const handleOpenModal = (state) => {
    if (state === 'more') {
      setOpenMore(true);
    }
  };

  const handleShareModal = () => {
    setOpenModalShare(true);
  }
  const closeShareModal = () => {
    setOpenModalShare(false);
  }

  // const handleOpenModalReport = () => {
  //   setOpenModalReport(true);
  // }

  const handleCloseModal = () => {
    setOpenMore(false);
    setOpenModalReport(false);
  };

  useEffect(() => {
    let type = searchParams.get("type")
    let id_detail = searchParams.get("id_detail")
    settype_data(type)
    getRecomendationTongkangList()

    if (state?.detail === 'coal' || type === '1') {
      if (id_detail !== null) {
        setLocation({
          state: {
            id: id_detail,
            detail: 'coal'
          }
        })
        setActiveTab('coal')
        getDataDetailBatubara(id_detail)
      } else {
        setLocation({
          state: {
            id: state.id,
            detail: state.detail
          }
        })
        setActiveTab(state.detail)
        getDataDetailBatubara(state.id)
      }
    }

    if (state?.detail === 'nikel' || type === '2') {
      if (id_detail !== null) {
        setLocation({
          state: {
            id: id_detail,
            detail: 'nikel'
          }
        })
        setActiveTab('nikel')
        getDataDetailNikel(id_detail)
      } else {
        setLocation({
          state: {
            id: state.id,
            detail: state.detail
          }
        })
        setActiveTab(state.detail)
        getDataDetailNikel(state.id)
      }
    }

    if (state?.detail === 'tongkang' || type === '3') {
      if (id_detail !== null) {
        setLocation({
          state: {
            id: id_detail,
            detail: 'tongkang'
          }
        })
        setActiveTab('tongkang')
        getDataDetailTongkang(id_detail)
      } else {
        setLocation({
          state: {
            id: state.id,
            detail: state.detail
          }
        })
        setActiveTab(state.detail)
        getDataDetailTongkang(state.id)
      }
    }

  }, [])

  useEffect(() => {
    let type = searchParams.get("type")
    let id_detail = searchParams.get("id_detail")
    settype_data(type)
    if (state?.detail === 'coal' || type === '1') {
      if (id_detail !== null) {
        setLocation({
          state: {
            id: id_detail,
            detail: 'coal'
          }
        })
        setActiveTab('coal')
        getDataDetailBatubara(id_detail)
      } else {
        setLocation({
          state: {
            id: state.id,
            detail: state.detail
          }
        })
        setActiveTab(state.detail)
        getDataDetailBatubara(state.id)
      }
    }

    if (state?.detail === 'nikel' || type === '2') {
      if (id_detail !== null) {
        setLocation({
          state: {
            id: id_detail,
            detail: 'nikel'
          }
        })
        setActiveTab('nikel')
        getDataDetailNikel(id_detail)
      } else {
        setLocation({
          state: {
            id: state.id,
            detail: state.detail
          }
        })
        setActiveTab(state.detail)
        getDataDetailNikel(state.id)
      }
    }

    if (state?.detail === 'tongkang' || type === '3') {
      if (id_detail !== null) {
        setLocation({
          state: {
            id: id_detail,
            detail: 'tongkang'
          }
        })
        setActiveTab('tongkang')
        getDataDetailTongkang(id_detail)
      } else {
        setLocation({
          state: {
            id: state.id,
            detail: state.detail
          }
        })
        setActiveTab(state.detail)
        getDataDetailTongkang(state.id)
      }
    }

  }, [localStorage.getItem('lang')])

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler)
    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
  })

  const scrollHandler = (event) => {
    let offset = window.pageYOffset
    if (offset > 200) {
      setPosition(true)
    } else {
      setPosition(false)
    }
  };

  const getDataDetailBatubara = async (id) => {
    try {
      let lang = localStorage.getItem('lang') === 'null' || localStorage.getItem('lang') === 'id' ? 'id' : localStorage.getItem('lang')
      const responAPI = await getDetailBatubara(id, lang)
      await favoriteCheck(1, id)
      setDetailBatubara(responAPI.data.data)
      setDocBatubara(responAPI.data.data.batubara_documents === null ? [] : responAPI.data.data.batubara_documents)
    } catch (error) {
      setDetailBatubara("")
      console.log(error)
    }
  }

  const getDataDetailNikel = async (id) => {
    try {
      let lang = localStorage.getItem('lang') === 'null' || localStorage.getItem('lang') === 'id' ? 'null' : localStorage.getItem('lang')
      const responAPI = await getDetailNikel(id, lang)
      setDetailNikel(responAPI.data.data)
      setDocNikel(responAPI.data.data.nikel_documents === null ? [] : responAPI.data.data.nikel_documents)
      await favoriteCheck(2, id)
    } catch (error) {
      setDetailNikel("")
      console.log(error)
    }
  }

  const getDataDetailTongkang = async (id) => {
    try {
      let lang = localStorage.getItem('lang') === 'null' || localStorage.getItem('lang') === 'id' ? 'null' : localStorage.getItem('lang')
      const responAPI = await getDetailTongkang(id, lang)
      await favoriteCheck(3, id)
      setDetailTongkang(responAPI.data.data)
      setMaterialTongkang(responAPI.data.data.jenis_muatan === null ? [] : responAPI.data.data.jenis_muatan.split(","))
      setImageTongkang(responAPI.data.data.path_tongkang_pictures === null ? [] : responAPI.data.data.path_tongkang_pictures)
      setDocTongkang(responAPI.data.data.tongkang_documents === null ? [] : responAPI.data.data.tongkang_documents)
    } catch (error) {
      setDetailTongkang("")
      console.log(error)
    }
  }

  async function getRecomendationTongkangList() {
    try {
      const response = await searchBarge({
        page: 1,
        type_tongkang: "", ukuran_tongkang: '', max_quantity_muatan: '', min_quantity_muatan: '', max_price: '', min_price: '',
        bendera: "", tahun_buat: "", search_text: ""
      });
      setTongkangList(response.data?.data.data.data);
    } catch (e) {
      console.log(e);
    }
  }
  const [checkingFavorite, setCheckingFavorite] = useState(false);
  const favoriteCheck = async (type_product, id) => {

    if (isLogin) {
      try {

        let params = {
          id_product: id,
          type_product: type_product
        }
        const responAPI = await checkFavorite(params)
        let is_favorite = responAPI.data.is_favorite
        setCheckingFavorite(is_favorite)
      } catch (error) {
        console.log(error)
      }
    }

  }

  const setFavorite = async (e) => {
    if (isLogin) {
      let response = await addFavorite({ type: e, id_relation: parseInt(location.state.id) })

      if (response.status === 200) {
        setCheckingFavorite(true)
      } else {
        window.location.href = "/signin"
      }
    } else {
      window.location.href = "/signin"
    }
  }

  const removeFavorite = async (e) => {

    if (isLogin) {
      let response = await deleteFavorite({ type_product: parseInt(e), id_product: parseInt(location.state.id) })

      if (response.status === 200) {
        setCheckingFavorite(false)
      } else {
        window.location.href = "/signin"
      }
    } else {
      window.location.href = "/signin"
    }
  }

  const [activeTab, setActiveTab] = useState(location.state?.detail);
  const [copyUrl, setCopyUrl] = useState(false)
  const [translate,] = useTranslation("global");
  const [limitdescription, setLimitdescription] = useState(true)

  function copyLink(e) {
    setCopyUrl(true)
    setTimeout(() => {
      setCopyUrl(false)
    }, 1000);
  }

  const copyClipboard = <div className="copyToClipboard" style={{ opacity: copyUrl ? "1" : "0" }}>✔ Copied to clipboard</div>

  const handleNavigate = (id_company, name_company) => {
    let filter_name = capitalizeEachWord(convertText(name_company))
    window.location.href = `/detail/${filter_name}`
  };

  const SubmitReportListing = async () => {
    let type = searchParams.get("type")
    seturl_website(`${config.staging}/penawaran/detail?type=${type}&id_detail=${location.state.id}`)
    setOpenModalReport(true)
    // try {
    //   let params =
    //   {
    //     "data": {
    //       "reason": '',
    //       "type": type,
    //       "id_relation": location.state.id,
    //       token: localStorage.getItem("token")
    //     }
    //   }

    //   const responAPI = await reportListing(params)
    //   if (responAPI.status === 200) {
    //     handleOpenModalReport();
    //   } else {
    //     Swal.fire({
    //       icon: 'error',
    //       title: translate('status.failed'),
    //       text: translate('status.subtitlefailed'),
    //     })
    //   }
    // } catch (error) {
    //   console.log(error)
    // }
  }

  return (
    <div className="detail-explore width-100" >
      <div className="flex-wrap-center relative width-100">
        <div className="container-nav relative width-100">
          <div className='fixed-header'>
            <NavBar></NavBar>
          </div>
        </div>
        <Box className="flex-wrap-center width-100" sx={{ mt: { xs: 6, sm: 6, md: 11, lg: 11, xl: 11 }, }}>
          <div className="content-inner">

            <Grid
              sx={{ mt: '16px' }}
              container
              direction="row"
              alignItems={'center'}
            >
              <Button href="/" className="w-500" sx={{ fontSize: 16, mb: 1, color: '#2457B9', textTransform: 'none', pl: 0 }} variant="text">  {translate('page_explore.home')}</Button>

              <Typography className="w-500" sx={{ fontSize: 16, mb: 1, color: '#56617B' }}>
                /
              </Typography>
              <Button href="/penawaran" className="w-500" sx={{ fontSize: 16, mb: 1, color: '#2457B9', textTransform: 'none' }} variant="text">       {translate('navbar.penawaran')}</Button>
              <Typography className="w-500" sx={{ fontSize: 16, mb: 1, mr: 1, color: '#2457B9' }}>
                /
              </Typography>
              {activeTab === 'coal' ? <Typography className="w-500" sx={{ fontSize: 16, mb: 1 }}>
                {translate('detail-explore.detailbatubara')}
              </Typography> : null}
              {activeTab === 'nikel' ? <Typography className="w-500" sx={{ fontSize: 16, mb: 1 }}>
                {translate('detail-explore.detailnikel')}
              </Typography> : null}
              {activeTab === 'tongkang' ? <Typography className="w-500" sx={{ fontSize: 16, mb: 1 }}>
                {translate('detail-explore.detailtongkang')}
              </Typography> : null}


            </Grid>
            <Grid
              container
              direction="row"
              rowSpacing={{ xs: 5, lg: 7 }}
              columnSpacing={{ xs: 5, lg: 7 }}
            >
              {/* leftside Section */}
              <Grid lg={8.4} xs={12} item >
                {/* coal Section */}
                {activeTab === 'coal' ?
                  <Box>
                    <Box sx={{ mb: 2, }}>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={0}
                      >
                        <Grid item >

                          <Stack direction={'row'} spacing={1}>
                            <Typography
                              sx={{ fontSize: 18, color: '#081130' }}
                              className="w-700"
                            >
                              {detailBatubara.code_product}
                            </Typography>
                            <Box
                              sx={{
                                background: "rgba(180, 202, 243, 0.22);",
                                borderRadius: '300px',
                                p: '2px 12px',
                                height: '24px'

                              }}
                            >
                              <Stack direction={'row'} alignItems={'center'}>
                                <img alt="icon" src={PenawaranIcon} height={18}></img>
                                <Typography className="w-500" sx={{ color: '#2457B9', fontSize: 14, ml: '4px' }} >
                                  {translate('navbar.penawaran')}
                                </Typography>

                              </Stack>
                            </Box>
                          </Stack>
                          <Grid
                            container
                            justifyContent="left"
                            alignItems="center"
                            direction="row"
                          >

                            <Typography
                              className="w-500"
                              sx={{ fontSize: { xs: 12, sm: 12, md: 14, lg: 14, xl: 14 }, color: "#56617B", mr: '6px' }}
                            >
                              {translate('page_explore.listingoleh')}:
                            </Typography>
                            {
                              detailBatubara?.user_picture?.url !== undefined ? (

                                <img style={{ marginRight: '8px', borderRadius: "100%" }} alt="img-icon" src={config.baseURL + detailBatubara.user_picture.url} height={20} width={20}></img>
                              )
                                : (
                                  <Avatar sx={{ bgcolor: 'blue', width: '20px', height: '20px', mr: '6px' }} aria-label="recipe">
                                  </Avatar>
                                )
                            }
                            {detailBatubara.user_name &&
                              <Typography
                                className="w-500"
                                sx={{ fontSize: { xs: 12, sm: 12, md: 14, lg: 14, xl: 14 }, color: "#081130", mr: '6px' }}
                              >
                                {detailBatubara.user_name}
                              </Typography>}
                            <Box sx={{ borderRadius: '50%', background: 'var(--grey-placeholder, #8792AB);', width: '3px', height: '3px', mr: 1 }}>
                            </Box>
                            <Hidden smUp>
                              <Typography
                                className="w-500"
                                sx={{ fontSize: { xs: 12, sm: 12, md: 14, lg: 14, xl: 14 }, color: "#56617B", mr: '6px' }}
                              >
                                {translate('detail-explore.post')}
                              </Typography>
                            </Hidden>
                            <Hidden smDown>
                              <Typography
                                className="w-500"
                                sx={{ fontSize: { xs: 12, sm: 12, md: 14, lg: 14, xl: 14 }, color: "#56617B", mr: '6px' }}
                              >
                                {translate('detail-explore.postdate')}
                              </Typography>
                            </Hidden>

                            {detailBatubara.createdAt &&
                              <Typography
                                className="w-500"
                                sx={{ fontSize: { xs: 12, sm: 12, md: 14, lg: 14, xl: 14 }, color: "#081130", mr: '6px' }}
                              >
                                {`${new Date(detailBatubara.createdAt).toLocaleDateString('id')}`}
                              </Typography>}
                          </Grid>
                        </Grid>

                        <Grid sx={{ textAlign: "left", alignItems: "center", display: 'flex', mt: 1, mb: 1 }} item >
                          <Typography
                            sx={{ fontSize: '24px', color: '#081130', mr: 1 }}
                            className="w-700"
                          >
                            {currency === 'idr' ? 'Rp' : '$'}  {numberConvert(parseInt(detailBatubara?.price))}
                          </Typography>
                          <Typography
                            sx={{ fontSize: 14, color: '#56617B' }}
                            className="w-500"
                          >
                            /ton
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider style={{ backgroundColor: "#E8EBF2", width: '100%', marginTop: '24px', marginBottom: '24px' }} />

                      <Grid
                        container
                        direction="row"
                        alignItems="left"
                        justifyContent="left"
                        spacing={2}
                      >
                        <Grid item md={3} xs={6}>
                          <Box sx={{ background: "#F4F6FA", borderRadius: '8px', padding: 2 }}>
                            <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>

                              {detailBatubara.calorie && <Typography className="w-700" sx={{ color: '#081130', fontSize: { md: '20px', xs: '18px' }, }}>

                                {(detailBatubara.calorie).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                              </Typography>}
                              <img alt="Nikel-icon" src={Calorieicon} height={24} width={24}></img>
                            </Stack>
                            <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                              Gar
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item md={3} xs={6}>
                          <Box sx={{ background: "#F4F6FA", borderRadius: '8px', padding: 2 }}>
                            <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>

                              {detailBatubara.total_sulfur && <Typography className="w-700" sx={{ color: '#081130', fontSize: { md: '20px', xs: '18px' }, }}>
                                {(detailBatubara.total_sulfur).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                              </Typography>}
                              <img alt="Nikel-icon" src={Sulfuricon} height={24} width={24}></img>
                            </Stack>
                            <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                              {translate('page_explore.total-sulfur')}
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item md={3} xs={6}>
                          <Box sx={{ background: "#F4F6FA", borderRadius: '8px', padding: 2 }}>
                            <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>

                              {detailBatubara.ASH && <Typography className="w-700" sx={{ color: '#081130', fontSize: { md: '20px', xs: '18px' }, }}>
                                {(detailBatubara.ASH).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                              </Typography>}
                              <img alt="Nikel-icon" src={Ashicon} height={24} width={24}></img>
                            </Stack>
                            <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                              Ash
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item md={3} xs={6}>
                          <Box sx={{ background: "#F4F6FA", borderRadius: '8px', padding: 2 }}>
                            <Stack direction={'row'} alignItems={'center'}>

                              {detailBatubara.quantity && <Typography className="w-700" sx={{ color: '#081130', fontSize: { md: '20px', xs: '18px' }, mr: '8px' }}>
                                {(detailBatubara.quantity).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                              </Typography>}
                              <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                                MT
                              </Typography>
                            </Stack>
                            <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                              Quantity
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>

                      {/* SumberBatu Section */}
                      <Typography
                        sx={{ fontSize: 14, color: '#818CA7', mt: '16px' }}
                        className="w-500"
                      >
                        {translate('status.sumberbatu')}
                      </Typography>
                      <Typography
                        sx={{ fontSize: 14, color: '#081130', }}
                        className="w-700"
                      >
                        {detailBatubara.sumber_batu?.jenis_perusahaan} {capitalizeEachWord(detailBatubara.sumber_batu?.name)}
                      </Typography>
                      {/* Loading Port Section */}
                      <Typography
                        sx={{ fontSize: 14, color: '#818CA7', mt: '16px' }}
                        className="w-500"
                      >
                        {translate('detail-explore.lokasiloadingport')}
                      </Typography>
                      <Typography
                        sx={{ fontSize: 14, color: '#081130', }}
                        className="w-700"
                      >
                        {capitalizeEachWord(detailBatubara.loading_port?.name)}
                      </Typography>
                      {/* provinsi Section */}
                      <Typography
                        sx={{ fontSize: 14, color: '#818CA7', mt: '16px' }}
                        className="w-500"
                      >
                        {translate('login-register.provinsi')}
                      </Typography>
                      <Typography
                        sx={{ fontSize: 14, color: '#081130', }}
                        className="w-700"
                      >
                        {detailBatubara.location?.name}
                      </Typography>
                      {/* Laycandate Section */}
                      <Typography
                        sx={{ fontSize: 14, color: '#818CA7', mt: '16px' }}
                        className="w-500"
                      >
                        Laycan date
                      </Typography>
                      <Typography
                        sx={{ fontSize: 14, color: '#081130', }}
                        className="w-700"
                      >
                        {`${new Date(detailBatubara.laycan_date).toLocaleDateString('id')}`}

                      </Typography>

                      <Typography
                        sx={{ fontSize: 14, mt: 1, color: '#818CA7' }}
                        className="w-500"
                      >
                        {translate('detail-explore.deskripsi')}
                      </Typography>
                      {limitdescription && detailBatubara?.description?.length > 300 ?
                        <p className="w-700" style={{ color: '#081130', fontSize: 14, lineHeight: '26px' }} id="child-modal-description">
                          {detailBatubara.description.slice(0, 300)}...<b onClick={() => {
                            setLimitdescription(false);
                          }} className="w-700" style={{ cursor: 'pointer', color: '#2457B9', fontSize: '14px', lineHeight: 'normal', marginLeft: '8px' }}>Selengkapnya</b>
                        </p> : <Typography sx={{ fontSize: 14, color: '#081130' }}
                          className="w-700"> {detailBatubara.description}</Typography>}



                      <Divider style={{ backgroundColor: "#E8EBF2", width: '100%', marginTop: '24px', marginBottom: '24px' }} />
                      <Typography
                        sx={{ fontSize: 18, color: '#081130', mb: '16px' }}
                        className="w-700"
                      >

                        {translate('page_explore.document')}
                      </Typography>
                      <Grid container direction={'row'} spacing={2}>
                        {docBatubara.map((item, i) => (
                          <Grid item xs={6} sm={6} md={3} lg={3} xl={3} sx={{ overflowY: 'auto' }}>
                            <Card sx={{ borderRadius: 2 }}>
                              <DisplayPDFFile
                                url={`${config.baseURL}${item.url}`}
                                key={i}
                                ext={`${item.ext}`}
                                name={`${item.name}`}
                              />
                            </Card>
                          </Grid>
                        ))}
                      </Grid>

                      <Divider style={{ backgroundColor: "#E8EBF2", width: '100%', marginTop: '24px', marginBottom: '24px' }} />
                    </Box>
                    <Hidden lgUp>
                      <Box >
                        {(detailBatubara.company_listing !== undefined && detailBatubara.company_listing !== null) &&
                          <Box sx={{ color: '#E8EBF2', pt: 2 }}>
                            <Typography className="w-700" sx={{ color: '#081130', fontSize: 18, mt: 1, }}>
                              {translate('detail-explore.tentangperusahaan')}
                            </Typography>
                            <Box sx={{ mt: 2, }}>
                              <Grid
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent="left"
                              >
                                {detailBatubara.company_listing?.picture_path?.url !== undefined &&
                                  <Avatar sx={{ bgcolor: 'blue', mr: '16px' }} aria-label="recipe" src={config.baseURL + detailBatubara.company_listing?.picture_path?.url}>
                                  </Avatar>
                                }
                                <Stack style={{ textAlign: "left", color: "white" }} spacing={0}>
                                  <Typography
                                    sx={{ fontSize: 16, color: '#081130' }}
                                    className="w-700"
                                  >
                                    {detailBatubara.company_listing.jenis_perusahaan} {capitalizeEachWord(detailBatubara.company_listing.name)}

                                  </Typography>
                                  <Grid className="pointer" onClick={(e) => handleNavigate(detailBatubara.company_listing.id, detailBatubara.company_listing.name)}
                                    container
                                    justifyContent="left"
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <Typography
                                      className="w-500"
                                      sx={{ fontSize: 16, color: "#2457B9", mr: 1 }}
                                    >
                                      {translate('detail-explore.lihatdetailperusahaan')}
                                    </Typography>
                                    <ArrowForwardIosIcon sx={{ fontSize: 12, color: "#2457B9", pt: '3px' }} />
                                  </Grid>
                                </Stack>
                              </Grid>
                            </Box>

                            <Box sx={{ borderRadius: '6px', mt: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0 }, mb: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0 }, background: { xs: "#F4F6FA", sm: "#F4F6FA", md: "#F4F6FA", lg: "none", xl: "none" }, padding: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0 } }}>
                              <Box sx={{ pr: 2, mb: 2, mt: { xs: 0, sm: 0, md: 0, lg: 1, xl: 1 } }}>
                                {detailBatubara.company_listing.bidang_perusahaan === 1 ? (
                                  <Grid
                                    container
                                    justifyContent="left"
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <img alt="penambang-icon" src={PenambangIcon} height={24}></img>
                                    <Typography
                                      className="w-500"
                                      sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                    >
                                      {translate('page_explore.penambang')}
                                    </Typography>
                                  </Grid>
                                ) : detailBatubara.company_listing.bidang_perusahaan === 2 ? (
                                  <Grid
                                    container
                                    justifyContent="left"
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <img alt="penambang-icon" src={traderIcon} height={24}></img>
                                    <Typography
                                      className="w-500"
                                      sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                    >
                                      Trader
                                    </Typography>

                                  </Grid>
                                ) : detailBatubara.company_listing.bidang_perusahaan === 3 ? (
                                  <Grid
                                    container
                                    justifyContent="left"
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <img alt="penambang-icon" src={pelayarIcon} height={24}></img>
                                    <Typography
                                      className="w-500"
                                      sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                    >
                                      {translate('page_explore.pelayaran')}
                                    </Typography>

                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              </Box>
                              <Box sx={{ pr: 2, mt: 1, mb: 2 }}>
                                {detailBatubara.company_listing.alamat && <Grid
                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <img alt="call-icon" src={locationCompanyIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                  >
                                    {detailBatubara.company_listing.alamat[0].detail_alamat}
                                  </Typography>

                                </Grid>}
                              </Box>
                              <Box sx={{ pr: 2, mt: 1, mb: 2 }}>
                                {detailBatubara.company_listing.phone_number &&
                                  <Grid
                                    container
                                    justifyContent="left"
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <img alt="Nikel-icon" src={callCompanyIcon} height={24}></img>
                                    <Typography
                                      className="w-500"
                                      sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                    >
                                      {format(detailBatubara.company_listing.phone_number)}
                                    </Typography>
                                  </Grid>}
                              </Box>
                              <Box sx={{ pr: 2, mt: 1, mb: 2 }}>
                                {detailBatubara.company_listing.email &&
                                  <Grid
                                    container
                                    justifyContent="left"
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <img alt="Nikel-icon" src={mailCompanyIcon} height={24}></img>
                                    <Typography
                                      className="w-500"
                                      sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                    >
                                      {detailBatubara.company_listing.email}
                                    </Typography>
                                  </Grid>}
                              </Box>
                            </Box>
                            <Divider style={{ backgroundColor: "#E8EBF2", width: '100%', marginTop: '24px', marginBottom: '24px' }} />
                          </Box>}
                      </Box>
                    </Hidden>
                    <Box sx={{ mb: 2 }}>
                      <Komentar id_product={parseInt(location.state.id)} type_product={1} />
                    </Box>
                  </Box>
                  : null}
                {/* nikel Section */}
                {activeTab === 'nikel' ?
                  <Box>
                    <Box sx={{ mb: 2 }}>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={0}
                      >
                        <Grid item >
                          <Stack direction={'row'} spacing={1}>
                            <Typography
                              sx={{ fontSize: 18, color: '#081130' }}
                              className="w-700"
                            >
                              {detailNikel.code_product}
                            </Typography>
                            <Box
                              sx={{
                                background: "rgba(180, 202, 243, 0.22);",
                                borderRadius: '300px',
                                p: '2px 12px',
                                height: '24px'

                              }}
                            >
                              <Stack direction={'row'} alignItems={'center'}>
                                <img alt="icon" src={PenawaranIcon} height={18}></img>
                                <Typography className="w-500" sx={{ color: '#2457B9', fontSize: 14, ml: '4px' }} >
                                  {translate('navbar.penawaran')}
                                </Typography>

                              </Stack>
                            </Box>
                          </Stack>

                          <Grid
                            container
                            justifyContent="left"
                            alignItems="center"
                            direction="row"
                          >
                            <Typography
                              className="w-500"
                              sx={{ fontSize: { xs: 12, sm: 12, md: 14, lg: 14, xl: 14 }, color: "#56617B", mr: '6px' }}
                            >
                              {translate('page_explore.listingoleh')}:
                            </Typography>
                            {
                              detailNikel?.user_picture?.url !== undefined ? (
                                <img style={{ marginRight: '8px', borderRadius: "100%" }} alt="img-icon" src={config.baseURL + detailNikel.user_picture.url} height={20} width={20}></img>
                              )
                                : (
                                  <Avatar sx={{ bgcolor: 'blue', width: '20px', height: '20px', mr: '6px' }} aria-label="recipe">
                                  </Avatar>
                                )
                            }
                            <Typography
                              className="w-500"
                              sx={{ fontSize: { xs: 12, sm: 12, md: 14, lg: 14, xl: 14 }, color: "#081130", mr: 1 }}
                            >
                              {detailNikel.user_name}
                            </Typography>
                            <Box sx={{ borderRadius: '50%', background: 'var(--grey-placeholder, #8792AB);', width: '3px', height: '3px', mr: 1 }}>
                            </Box>
                            <Hidden smUp>
                              <Typography
                                className="w-500"
                                sx={{ fontSize: { xs: 12, sm: 12, md: 14, lg: 14, xl: 14 }, color: "#56617B", mr: '6px' }}
                              >
                                {translate('detail-explore.post')}
                              </Typography>
                            </Hidden>
                            <Hidden smDown>
                              <Typography
                                className="w-500"
                                sx={{ fontSize: { xs: 12, sm: 12, md: 14, lg: 14, xl: 14 }, color: "#56617B", mr: '6px' }}
                              >
                                {translate('detail-explore.postdate')}
                              </Typography>
                            </Hidden>
                            {detailNikel.createdAt && <Typography
                              className="w-500"
                              sx={{ fontSize: { xs: 12, sm: 12, md: 14, lg: 14, xl: 14 }, color: "#081130", mr: '6px' }}
                            >
                              {`${new Date(detailNikel.createdAt).toLocaleDateString('id')}`}
                            </Typography>}
                          </Grid>
                        </Grid>
                        <Grid direction={'row'} sx={{ textAlign: "left", alignItems: "center", display: 'flex', mt: 1, mb: 1 }} item >
                          <Typography
                            sx={{ fontSize: '24px', color: '#081130', mr: 1 }}
                            className="w-700"
                          >
                            {currency === 'idr' ? 'Rp' : '$'}  {numberConvert(parseInt(detailNikel?.price))}

                          </Typography>
                          <Typography
                            sx={{ fontSize: 14, color: '#56617B' }}
                            className="w-500"
                          >
                            /ton
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider style={{ backgroundColor: "#E8EBF2", width: '100%', marginTop: '24px', marginBottom: '24px' }} />
                      <Grid
                        container
                        direction="row"
                        alignItems="left"
                        justifyContent="left"
                        spacing={2}
                      >
                        <Grid item xs={6} md={3} >
                          <Box sx={{ background: "#F4F6FA", borderRadius: '8px', padding: 2 }}>
                            <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>
                              {detailNikel.kadar_ni && <Typography className="w-700" sx={{ color: '#081130', fontSize: { md: '20px', xs: '18px' }, }}>
                                {(detailNikel.kadar_ni).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                              </Typography>}
                              <img alt="Nikel-icon" src={Niicon} height={24} width={24}></img>
                            </Stack>
                            <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                              {translate('page_explore.kadar-ni')}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6} md={3}  >
                          <Box sx={{ background: "#F4F6FA", borderRadius: '8px', padding: 2 }}>
                            <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>

                              {detailNikel.kadar_fe && <Typography className="w-700" sx={{ color: '#081130', fontSize: { md: '20px', xs: '18px' }, }}>
                                {(detailNikel.kadar_fe).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                              </Typography>}
                              <img alt="Nikel-icon" src={Feicon} height={24} width={24}></img>
                            </Stack>
                            <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                              {translate('page_explore.kadar-fe')}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6} md={3}  >
                          <Box sx={{ background: "#F4F6FA", borderRadius: '8px', padding: 2 }}>
                            <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>
                              {detailNikel.kadar_air && <Typography className="w-700" sx={{ color: '#081130', fontSize: { md: '20px', xs: '18px' }, }}>

                                {(detailNikel.kadar_air).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                              </Typography>}
                              <img alt="Nikel-icon" src={Airicon} height={24} width={24}></img>
                            </Stack>
                            <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                              {translate('page_explore.kadar-air')}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6} md={3}  >
                          <Box sx={{ background: "#F4F6FA", borderRadius: '8px', padding: 2 }}>
                            <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>

                              {detailNikel.si_mg_ratio && <Typography className="w-700" sx={{ color: '#081130', fontSize: { md: '20px', xs: '18px' }, }}>

                                {(detailNikel.si_mg_ratio).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                              </Typography>}
                              <img alt="Nikel-icon" src={Ratioicon} height={24} width={24}></img>
                            </Stack>
                            <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                              Si/Mg
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6} md={3} >
                          <Box sx={{ background: "#F4F6FA", borderRadius: '8px', padding: 2 }}>
                            <Stack direction={'row'} alignItems={'center'} >
                              {detailNikel.quantity && <Typography className="w-700" sx={{ color: '#081130', fontSize: { md: '20px', xs: '18px' }, mr: '8px' }}>
                                {(detailNikel.quantity).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                              </Typography>}
                              <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                                MT
                              </Typography>
                            </Stack>
                            <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                              Quantity
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>

                      {/* SumberBatu Section */}
                      <Typography
                        sx={{ fontSize: 14, color: '#818CA7', mt: '16px' }}
                        className="w-500"
                      >
                        {translate('status.sumberbatu')}
                      </Typography>
                      <Typography
                        sx={{ fontSize: 14, color: '#081130', }}
                        className="w-700"
                      >
                        {detailNikel.sumber_batu?.jenis_perusahaan} {capitalizeEachWord(detailNikel.sumber_batu?.name)}
                      </Typography>
                      {/* Loading Port Section */}
                      <Typography
                        sx={{ fontSize: 14, color: '#818CA7', mt: '16px' }}
                        className="w-500"
                      >
                        {translate('detail-explore.lokasiloadingport')}
                      </Typography>
                      <Typography
                        sx={{ fontSize: 14, color: '#081130', }}
                        className="w-700"
                      >
                        {capitalizeEachWord(detailNikel.loading_port?.name)}

                      </Typography>
                      {/* provinsi Section */}
                      <Typography
                        sx={{ fontSize: 14, color: '#818CA7', mt: '16px' }}
                        className="w-500"
                      >
                        {translate('login-register.provinsi')}
                      </Typography>
                      <Typography
                        sx={{ fontSize: 14, color: '#081130', }}
                        className="w-700"
                      >
                        {detailNikel.location?.name}
                      </Typography>
                      {/* Laycandate Section */}
                      <Typography
                        sx={{ fontSize: 14, color: '#818CA7', mt: '16px' }}
                        className="w-500"
                      >
                        Laycan date
                      </Typography>
                      <Typography
                        sx={{ fontSize: 14, color: '#081130', }}
                        className="w-700"
                      >
                        {`${new Date(detailNikel.laycan_date).toLocaleDateString('id')}`}

                      </Typography>

                      <Typography
                        sx={{ fontSize: 14, color: '#818CA7', mt: '24px' }}
                        className="w-500"
                      >
                        {translate('detail-explore.deskripsi')}
                      </Typography>
                      {limitdescription && detailNikel?.description?.length > 300 ?
                        <p className="w-700" style={{ color: '#081130', fontSize: 14, lineHeight: '26px' }} id="child-modal-description">
                          {detailNikel.description.slice(0, 300)}...<b onClick={() => {
                            setLimitdescription(false);
                          }} className="w-700" style={{ cursor: 'pointer', color: '#2457B9', fontSize: '14px', lineHeight: 'normal', marginLeft: '8px' }}>Selengkapnya</b>
                        </p> : <Typography sx={{ fontSize: 14, color: '#081130' }}
                          className="w-700"> {detailNikel.description}</Typography>}
                      <Divider style={{ backgroundColor: "#E8EBF2", width: '100%', marginTop: '24px', marginBottom: '24px' }} />
                      <Typography
                        sx={{ fontSize: 18, color: '#081130', mb: '16px' }}
                        className="w-700"
                      >
                        {translate('page_explore.document')}
                      </Typography>
                      <Grid container direction={'row'} spacing={2}>
                        {docNikel.map((item, i) => (
                          <Grid item xs={6} sm={6} md={3} lg={3} xl={3} >
                            <Card sx={{ borderRadius: 2 }}>
                              <DisplayPDFFile
                                url={`${config.baseURL}${item.url}`}
                                key={i}
                                ext={`${item.ext}`}
                                name={`${item.name}`}
                              />
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                      <Divider style={{ backgroundColor: "#E8EBF2", width: '100%', marginTop: '24px', marginBottom: '24px' }} />
                    </Box>
                    <Hidden lgUp>
                      <Box >
                        {(detailNikel.company_listing !== null && detailNikel.company_listing !== undefined) &&
                          <Box sx={{ color: '#E8EBF2', }}>
                            <Typography className="w-700" sx={{ color: '#081130', fontSize: 18, mt: 1, }}>
                              {translate('detail-explore.tentangperusahaan')}
                            </Typography>
                            <Box sx={{ mt: 2, }}>
                              <Grid
                                container
                                direction="row"
                                alignItems="left"
                                justifyContent="left"
                              >
                                {detailNikel.company_listing?.picture_path?.url !== undefined &&
                                  <Avatar sx={{ bgcolor: 'blue', mr: '16px' }} aria-label="recipe" src={config.baseURL + detailNikel.company_listing?.picture_path?.url}>
                                  </Avatar>
                                }
                                <Stack style={{ textAlign: "left", color: "white" }} spacing={0}>
                                  <Typography
                                    sx={{ fontSize: 16, color: '#081130' }}
                                    className="w-700"
                                  >
                                    {detailNikel.company_listing.jenis_perusahaan} {capitalizeEachWord(detailNikel.company_listing.name)}
                                  </Typography>
                                  <Grid className="pointer"
                                    container onClick={(e) => handleNavigate(detailNikel.company_listing.id, detailNikel.company_listing.name)}
                                    justifyContent="left"
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <Typography
                                      className="w-500"
                                      sx={{ fontSize: 16, color: "#2457B9", mr: 1 }}
                                    >
                                      {translate('detail-explore.lihatdetailperusahaan')}
                                    </Typography>
                                    <ArrowForwardIosIcon sx={{ fontSize: 12, color: "#2457B9", pt: '3px' }} />
                                  </Grid>
                                </Stack>
                              </Grid>
                            </Box>
                            <Box sx={{ borderRadius: '6px', mt: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0 }, mb: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0 }, background: { xs: "#F4F6FA", sm: "#F4F6FA", md: "#F4F6FA", lg: "none", xl: "none" }, padding: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0 } }}>
                              <Box sx={{ pr: 2, mb: 2, mt: { xs: 0, sm: 0, md: 0, lg: 1, xl: 1 } }}>
                                {detailNikel.company_listing.bidang_perusahaan === 1 ? (
                                  <Grid
                                    container
                                    justifyContent="left"
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <img alt="penambang-icon" src={PenambangIcon} height={24}></img>
                                    <Typography
                                      className="w-500"
                                      sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                    >
                                      {translate('page_explore.penambang')}
                                    </Typography>

                                  </Grid>
                                ) : detailNikel.company_listing.bidang_perusahaan === 2 ? (
                                  <Grid
                                    container
                                    justifyContent="left"
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <img alt="penambang-icon" src={traderIcon} height={24}></img>
                                    <Typography
                                      className="w-500"
                                      sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                    >
                                      Trader
                                    </Typography>
                                  </Grid>
                                ) : detailNikel.company_listing.bidang_perusahaan === 3 ? (
                                  <Grid
                                    container
                                    justifyContent="left"
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <img alt="penambang-icon" src={pelayarIcon} height={24}></img>
                                    <Typography
                                      className="w-500"
                                      sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                    >
                                      {translate('page_explore.pelayaran')}
                                    </Typography>
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              </Box>
                              <Box sx={{ pr: 2, mt: 1, mb: 2 }}>
                                {detailNikel.company_listing.alamat &&
                                  <Grid
                                    container
                                    justifyContent="left"
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <img alt="call-icon" src={locationCompanyIcon} height={24}></img>
                                    <Typography
                                      className="w-500"
                                      sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                    >
                                      {detailNikel.company_listing.alamat[0].detail_alamat}
                                    </Typography>
                                  </Grid>}
                              </Box>
                              <Box sx={{ pr: 2, mt: 1, mb: 2 }}>
                                {detailNikel.company_listing.phone_number &&
                                  <Grid
                                    container
                                    justifyContent="left"
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <img alt="Nikel-icon" src={callCompanyIcon} height={24}></img>
                                    <Typography
                                      className="w-500"
                                      sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                    >
                                      {format(detailNikel.company_listing.phone_number)}
                                    </Typography>
                                  </Grid>}
                              </Box>
                              <Box sx={{ pr: 2, mt: 1, mb: 2 }}>
                                {detailNikel.company_listing.email &&
                                  <Grid
                                    container
                                    justifyContent="left"
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <img alt="Nikel-icon" src={mailCompanyIcon} height={24}></img>
                                    <Typography
                                      className="w-500"
                                      sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                    >
                                      {detailNikel.company_listing.email}
                                    </Typography>
                                  </Grid>}
                              </Box>
                            </Box>
                            <Divider style={{ backgroundColor: "#E8EBF2", width: '100%', marginTop: '24px', marginBottom: '24px' }} />
                          </Box>}
                      </Box>
                    </Hidden>
                    <Box sx={{ mb: 2, }}>
                      <Komentar id_product={parseInt(location.state.id)} type_product={2} />
                    </Box>
                  </Box>
                  : null}
                {/* tongkang Section */}
                {activeTab === 'tongkang' ?
                  <Box>
                    <Box sx={{ mb: 2 }}>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={0}
                      >
                        <Grid item >
                          {/* Code tongkang Section */}
                          <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            <Typography
                              sx={{ fontSize: { xs: 20, sm: 20, md: 28, lg: 28, xl: 28 }, color: '#081130' }}
                              className="w-700"
                            >
                              {detailTongkang?.code_product}
                            </Typography>
                            <Box
                              sx={{
                                top: '10%',
                                background: "#F4F6FA",
                                borderRadius: 4,
                                pr: 2,
                                pl: 2,
                                pb: '2px',
                                pt: '2px',

                              }}
                            >  <Stack direction={'row'} alignItems={'center'}>
                                {detailTongkang.type_tongkang === 1 ? (
                                  <>
                                    <Box sx={{ borderRadius: '50%', background: '#F7CE46', width: '10px', height: '10px', mr: 1 }}>
                                    </Box>
                                    <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 14 }} >
                                      {translate('status.jual')}
                                    </Typography>
                                  </>
                                ) : (
                                  <></>
                                )}

                                {detailTongkang.type_tongkang === 2 ? (
                                  <>
                                    <Box sx={{ borderRadius: '50%', background: '#F55C24', width: '10px', height: '10px', mr: 1 }}>
                                    </Box>
                                    <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 14 }} >
                                      Time Charter
                                    </Typography>
                                  </>
                                ) : (
                                  <></>
                                )}
                                {detailTongkang.type_tongkang === 3 ? (
                                  <>
                                    <Box sx={{ borderRadius: '50%', background: '#883BE7', width: '10px', height: '10px', mr: 1 }}>
                                    </Box>
                                    <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 14 }} >
                                      Freight Charter
                                    </Typography>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Stack>
                            </Box>
                          </Stack>
                          {/* Listing oleh Section */}
                          <Grid
                            container
                            justifyContent="left"
                            alignItems="center"
                            direction="row"
                          >

                            <Typography
                              className="w-500"
                              sx={{ fontSize: { xs: 12, sm: 12, md: 14, lg: 14, xl: 14 }, color: "#56617B", mr: '6px' }}
                            >
                              {translate('page_explore.listingoleh')}:
                            </Typography>
                            {
                              detailTongkang?.user_picture?.url !== undefined ? (
                                <img style={{ marginRight: '8px', borderRadius: "100%" }} alt="img-icon" src={config.baseURL + detailTongkang.user_picture.url} height={20} width={20}></img>
                              )
                                : (
                                  <Avatar sx={{ bgcolor: 'blue', width: '20px', height: '20px', mr: '6px' }} aria-label="recipe">
                                  </Avatar>
                                )
                            }
                            <Typography
                              className="w-500"
                              sx={{ fontSize: { xs: 12, sm: 12, md: 14, lg: 14, xl: 14 }, color: "#081130", mr: 1 }}
                            >
                              {detailTongkang.user_name}
                            </Typography>
                            <Box sx={{ borderRadius: '50%', background: 'var(--grey-placeholder, #8792AB);', width: '3px', height: '3px', mr: 1 }}>
                            </Box>
                            <Hidden smUp>
                              <Typography
                                className="w-500"
                                sx={{ fontSize: { xs: 12, sm: 12, md: 14, lg: 14, xl: 14 }, color: "#56617B", mr: '6px' }}
                              >
                                {translate('detail-explore.post')}
                              </Typography>
                            </Hidden>
                            <Hidden smDown>
                              <Typography
                                className="w-500"
                                sx={{ fontSize: { xs: 12, sm: 12, md: 14, lg: 14, xl: 14 }, color: "#56617B", mr: '6px' }}
                              >
                                {translate('detail-explore.postdate')}
                              </Typography>
                            </Hidden>
                            {detailTongkang.createdAt && <Typography
                              className="w-500"
                              sx={{ fontSize: { xs: 12, sm: 12, md: 14, lg: 14, xl: 14 }, color: "#081130", mr: '6px' }}
                            >
                              {`${new Date(detailTongkang.createdAt).toLocaleDateString('id')}`}
                            </Typography>}
                          </Grid>
                        </Grid>

                        <Grid direction={'row'} sx={{ textAlign: "left", alignItems: "center", display: 'flex', mt: 1, mb: 1 }} item >
                          <Typography
                            sx={{ fontSize: '24px', color: '#081130', mr: 1 }}
                            className="w-700"
                          >
                            {currency === 'idr' ? 'Rp' : '$'}  {numberConvert(parseInt(detailTongkang?.price))}

                          </Typography>
                          <Typography
                            sx={{ fontSize: 14, color: '#56617B' }}
                            className="w-500"
                          >
                            {detailTongkang.type_tongkang === 1 && "/set"}
                            {detailTongkang.type_tongkang === 2 && "/month"}
                            {detailTongkang.type_tongkang === 3 && "/MT"}
                          </Typography>
                        </Grid>

                      </Grid>
                      {/* Nama tongkang Section */}
                      <Box>
                        <Divider style={{ backgroundColor: "#E8EBF2", width: '100%', marginTop: '24px', marginBottom: '24px' }} />
                        <Typography
                          sx={{ fontSize: '24px', color: '#081130' }}
                          className="w-700"
                        >
                          {detailTongkang.nama_tug_boat}
                        </Typography>
                        <Typography
                          sx={{ fontSize: 18, color: '#56617B', mb: '24px' }}
                          className="w-700"
                        >
                          {detailTongkang.nama_tongkang}
                        </Typography>
                      </Box>
                      {/* Image tongkang Section */}
                      <div className="slider-detail-tongkang">
                        <Box className="box-slide width-100">
                          <Slider {...settings_penawaran_tongkang}>
                            {
                              imageTongkang.map((item) => (
                                <Grid item >
                                  <Box sx={{ padding: { sm: '2', xs: 1 } }}>
                                    <img height={154} style={{ borderRadius: '8px' }} alt="Nikel-icon" src={config.baseURL + item.url} className="img-fluid"></img>
                                  </Box>
                                </Grid>
                              ))
                            }
                            {
                              imageTongkang.map((item) => (
                                <Grid item>
                                  <Box sx={{ padding: { sm: '2', xs: 1 } }}>
                                    <img height={154} style={{ borderRadius: '8px' }} alt="Nikel-icon" src={config.baseURL + item.url} className="img-fluid"></img>
                                  </Box>
                                </Grid>
                              ))
                            }
                            {
                              imageTongkang.map((item) => (
                                <Grid item>
                                  <Box sx={{ padding: { sm: '2', xs: 1 } }}>
                                    <img height={154} style={{ borderRadius: '8px' }} alt="Nikel-icon" src={config.baseURL + item.url} className="img-fluid"></img>
                                  </Box>
                                </Grid>
                              ))
                            }
                            {
                              imageTongkang.map((item) => (
                                <Grid item>
                                  <Box sx={{ padding: { sm: '2', xs: 1 } }}>
                                    <img height={154} style={{ borderRadius: '8px' }} alt="Nikel-icon" src={config.baseURL + item.url} className="img-fluid"></img>
                                  </Box>
                                </Grid>
                              ))
                            }
                          </Slider>
                        </Box>
                      </div>
                      {/* Box detail tongkang Section */}
                      <Grid
                        marginTop={'24px'}
                        marginBottom={'24px'}
                        container
                        direction="row"
                        alignItems="left"
                        justifyContent="left"
                        spacing={2}
                      >
                        <Grid item xs={6} md={3}>
                          <Box sx={{ background: "#F4F6FA", borderRadius: '8px', padding: 2 }}>
                            <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>
                              <Stack direction={'row'} alignItems={'center'}>
                                {detailTongkang.ukuran_tongkang && <Typography className="w-700" sx={{ color: '#081130', fontSize: { md: '20px', xs: '18px' } }}>

                                  {(detailTongkang.ukuran_tongkang).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                </Typography>}
                                <Typography className="w-700" sx={{ color: '#56617B', fontSize: { md: '12px', xs: '12px' }, ml: 1 }}>
                                  FT
                                </Typography>
                              </Stack>

                              <img alt="sizetongkang-icon" src={SizetongkangIcon} height={24} width={24}></img>
                            </Stack>
                            <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                              {translate('page_explore.ukurantongkang')}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6} md={3} >
                          <Box sx={{ background: "#F4F6FA", borderRadius: '8px', padding: 2 }}>
                            <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>

                              <Typography className="w-700" sx={{ color: '#081130', fontSize: { md: '20px', xs: '18px' } }}>
                                {detailTongkang.tahun_buat}
                              </Typography>
                              <img alt="Nikel-icon" src={laycanIcon} height={24} width={24}></img>
                            </Stack>
                            <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                              {translate('permintaan.tahunbuat')}
                            </Typography>
                          </Box>
                        </Grid>

                      </Grid>
                      {/* Jenis muatan tongkang Section */}
                      {(materialTongkang.length > 0 && detailTongkang.type_tongkang === 3) &&
                        <div>

                          <Typography
                            sx={{ fontSize: 14, color: '#56617B', mb: '2px' }}
                            className="w-500"
                          >

                            {translate('detail-explore.jenismuatanditerima')}
                          </Typography>
                          <Stack direction={'row'} sx={{ gap: '10px', mb: 2 }}>
                            {materialTongkang?.map((item) => (
                              <Box sx={{ background: "#818CA7", borderRadius: 4, padding: '2px 12px' }}>
                                <Typography
                                  sx={{ fontSize: 14, color: '#FFFFFF' }}
                                  className="w-500"
                                >
                                  {item}
                                </Typography>
                              </Box>
                            ))}
                          </Stack>
                        </div>}
                      <Box>
                        {/* Lokasi tongkang Section */}
                        {(detailTongkang.type_tongkang === 2 || detailTongkang?.type_tongkang === 1) && <div>
                          <Typography
                            sx={{ fontSize: 14, color: '#56617B' }}
                            className="w-500"
                          >
                            {translate('title.location-tongkang')}
                          </Typography>
                          <Typography
                            sx={{ fontSize: 14, color: '#081130', mt: '4px' }}
                            className="w-700"
                          >
                            {detailTongkang?.tongkang_location ? detailTongkang?.tongkang_location?.name : "-"}
                          </Typography>

                        </div>}
                        {/* Lokasi tongkang Freight Charter Section */}
                        {detailTongkang.type_tongkang === 3 &&
                          <div>
                            <Typography
                              sx={{ fontSize: 14, color: '#56617B' }}
                              className="w-500"
                            >
                              Loading Area
                            </Typography>
                            <Typography
                              sx={{ fontSize: 14, color: '#081130', mt: '4px' }}
                              className="w-700"
                            >
                              {detailTongkang?.area_muat ? detailTongkang?.area_muat?.name : "-"}
                            </Typography>

                            <Typography
                              sx={{ fontSize: 14, color: '#56617B' }}
                              className="w-500"
                            >
                              Discharge Area
                            </Typography>
                            <Typography
                              sx={{ fontSize: 14, color: '#081130', mt: '4px' }}
                              className="w-700"
                            >
                              {detailTongkang?.area_bongkar ? detailTongkang?.area_bongkar?.name : "-"}
                            </Typography>


                            <Typography
                              sx={{ fontSize: 14, color: '#56617B', mt: '4px' }}
                              className="w-500"
                            >
                              Loading Port
                            </Typography>
                            <Typography
                              sx={{ fontSize: 14, color: '#081130', mt: '4px' }}
                              className="w-700"
                            >
                              {detailTongkang?.pelabuhan_muat ? detailTongkang?.pelabuhan_muat?.name : "-"}
                            </Typography> <Typography
                              sx={{ fontSize: 14, color: '#56617B' }}
                              className="w-500"
                            >
                              Discharge Port
                            </Typography>
                            <Typography
                              sx={{ fontSize: 14, color: '#081130', mt: '4px' }}
                              className="w-700"
                            >
                              {detailTongkang?.pelabuhan_bongkar ? detailTongkang?.pelabuhan_bongkar?.name : "-"}
                            </Typography>

                          </div>}
                        <Typography
                          sx={{ fontSize: 14, color: '#56617B', mt: '4px' }}
                          className="w-500"
                        >
                          {translate('detail-explore.deskripsi')}
                        </Typography>

                        {limitdescription && detailTongkang?.description?.length > 300 ?
                          <p className="w-700" style={{ color: '#081130', fontSize: 14, lineHeight: '26px' }} id="child-modal-description">
                            {detailTongkang.description.slice(0, 300)}...<b onClick={() => {
                              setLimitdescription(false);
                            }} className="w-700" style={{ cursor: 'pointer', color: '#2457B9', fontSize: '14px', lineHeight: 'normal', marginLeft: '8px' }}>Selengkapnya</b>
                          </p> : <Typography sx={{ fontSize: 14, color: '#081130' }}
                            className="w-700"> {detailTongkang.description ? detailTongkang?.description : "-"}</Typography>}
                        <Divider style={{ backgroundColor: "#E8EBF2", width: '100%', marginTop: '24px', marginBottom: '24px' }} />
                        <Typography
                          sx={{ fontSize: 18, color: '#081130', mb: '16px' }}
                          className="w-700"
                        >

                          {translate('page_explore.document')}
                        </Typography>
                        <Grid container direction={'row'} spacing={2}>
                          {docTongkang.map((item, i) => (
                            <Grid item xs={6} sm={6} md={3} lg={3} xl={3} sx={{ overflowY: 'auto' }}>
                              <Card sx={{ borderRadius: 2 }}>
                                <DisplayPDFFile
                                  url={`${config.baseURL}${item.url}`}
                                  key={i}
                                  ext={`${item.ext}`}
                                  name={`${item.name}`}
                                />
                              </Card>
                            </Grid>
                          ))}
                        </Grid>

                        <Divider style={{ backgroundColor: "#E8EBF2", width: '100%', marginTop: '24px', marginBottom: '24px' }} />
                      </Box>
                    </Box>
                    <Hidden lgUp>
                      <Divider style={{ backgroundColor: "#E8EBF2", width: '100%', marginTop: '24px', marginBottom: '24px' }} />
                      <Box >
                        {(detailTongkang.company_listing !== null && detailTongkang.company_listing !== undefined) &&
                          <Box sx={{ color: '#E8EBF2', pt: 2 }}>
                            <Typography className="w-700" sx={{ color: '#081130', fontSize: 18, mt: 1, }}>
                              {translate('detail-explore.tentangperusahaan')}
                            </Typography>

                            <Box sx={{ mt: 2, }}>
                              <Grid
                                container
                                direction="row"
                                alignItems="left"
                                justifyContent="left"

                              >
                                {detailTongkang.company_listing?.picture_path?.url !== undefined &&
                                  <Avatar sx={{ bgcolor: 'blue', mr: '16px' }} aria-label="recipe" src={config.baseURL + detailTongkang.company_listing?.picture_path?.url}>
                                  </Avatar>
                                }
                                <Stack style={{ textAlign: "left", color: "white" }} spacing={0}>
                                  {detailTongkang.company_listing.name &&
                                    <Typography
                                      sx={{ fontSize: 16, color: '#081130' }}
                                      className="w-700"
                                    >
                                      {detailTongkang.company_listing.jenis_perusahaan} {capitalizeEachWord(detailTongkang.company_listing.name)}
                                    </Typography>}
                                  <Grid className="pointer"
                                    container onClick={(e) => handleNavigate(detailTongkang.company_listing.id, detailTongkang.company_listing.name)}
                                    justifyContent="left"
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <Typography
                                      className="w-500"
                                      sx={{ fontSize: 16, color: "#2457B9", mr: 1 }}
                                    >
                                      {translate('detail-explore.lihatdetailperusahaan')}
                                    </Typography>
                                    <ArrowForwardIosIcon sx={{ fontSize: 12, color: "#2457B9", pt: '3px' }} />
                                  </Grid>
                                </Stack>
                              </Grid>
                            </Box>

                            <Box sx={{ mt: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0 }, mb: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0 }, background: { xs: "#F4F6FA", sm: "#F4F6FA", md: "#F4F6FA", lg: "none", xl: "none" }, borderRadius: '6px', padding: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0 } }}>
                              <Box sx={{ pr: 2, mb: 2, mt: { xs: 0, sm: 0, md: 0, lg: 1, xl: 1 } }}>
                                {detailTongkang.company_listing.bidang_perusahaan === 1 ? (
                                  <Grid
                                    container
                                    justifyContent="left"
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <img alt="penambang-icon" src={PenambangIcon} height={24}></img>
                                    <Typography
                                      className="w-500"
                                      sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                    >
                                      {translate('page_explore.penambang')}
                                    </Typography>
                                  </Grid>
                                ) : detailTongkang.company_listing.bidang_perusahaan === 2 ? (
                                  <Grid

                                    container
                                    justifyContent="left"
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <img alt="penambang-icon" src={traderIcon} height={24}></img>
                                    <Typography
                                      className="w-500"
                                      sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                    >
                                      Trader
                                    </Typography>
                                  </Grid>
                                ) : detailTongkang.company_listing.bidang_perusahaan === 3 ? (
                                  <Grid
                                    container
                                    justifyContent="left"
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <img alt="penambang-icon" src={pelayarIcon} height={24}></img>
                                    <Typography
                                      className="w-500"
                                      sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                    >
                                      {translate('page_explore.pelayaran')}
                                    </Typography>
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              </Box>
                              <Box sx={{ pr: 2, mt: 1, mb: 2 }}>
                                {detailTongkang.company_listing.alamat && <Grid
                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <img alt="call-icon" src={locationCompanyIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                  >
                                    {detailTongkang.company_listing.alamat[0].detail_alamat}
                                  </Typography>
                                </Grid>}
                              </Box>
                              <Box sx={{ pr: 2, mt: 1, mb: 2 }}>
                                {detailTongkang.company_listing.phone_number &&
                                  <Grid
                                    container
                                    justifyContent="left"
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <img alt="Nikel-icon" src={callCompanyIcon} height={24}></img>
                                    <Typography
                                      className="w-500"
                                      sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                    >
                                      {format(detailTongkang.company_listing.phone_number)}
                                    </Typography>
                                  </Grid>}
                              </Box>
                              <Box sx={{ pr: 2, mt: 1, mb: 2 }}>
                                {detailTongkang.company_listing.email &&
                                  <Grid
                                    container
                                    justifyContent="left"
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <img alt="Nikel-icon" src={mailCompanyIcon} height={24}></img>
                                    <Typography
                                      className="w-500"
                                      sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                    >
                                      {detailTongkang.company_listing.email}
                                    </Typography>
                                  </Grid>}
                              </Box>
                            </Box>
                          </Box>}

                      </Box>
                    </Hidden>
                    {/* <Divider style={{ backgroundColor: "#E8EBF2", width: '100%', marginTop: '24px', marginBottom: '24px' }} /> */}
                    <Box sx={{ mb: 2, }}>
                      <Komentar id_product={parseInt(location.state.id)} type_product={3} />
                    </Box>
                  </Box>
                  : null}
              </Grid>
              {/* rightside Section */}
              <Grid lg={3.6} xs={12} item>
                <CardBeforeLogin type={1}></CardBeforeLogin>
                {/* detail batubara Section */}
                {activeTab === 'coal' ?
                  <Box sx={{ width: { xs: '100%', sm: '100%', md: '100%', lg: 375, xl: 375 }, }} >
                    {isLogin && <Hidden lgDown>
                      <Box sx={{ border: 1, color: '#E8EBF2', borderRadius: '12px', pt: 2 }}>
                        {(detailBatubara.company_listing !== undefined && detailBatubara.company_listing !== null) &&
                          <Box>
                            <Typography className="w-700" sx={{ color: '#081130', fontSize: 18, mt: 1, pl: 2 }}>
                              {translate('detail-explore.tentangperusahaan')}
                            </Typography>
                            <Box sx={{ pl: 2, pr: 2, mt: 2, }}>
                              <Grid
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent="left"
                              >
                                {detailBatubara.company_listing?.picture_path?.url !== undefined &&

                                  <Avatar sx={{ bgcolor: 'blue', mr: '16px' }} aria-label="recipe" src={config.baseURL + detailBatubara.company_listing?.picture_path?.url}>

                                  </Avatar>
                                }
                                <Stack style={{ textAlign: "left", color: "white" }} spacing={0}>
                                  <Typography
                                    sx={{ fontSize: 16, color: '#081130' }}
                                    className="w-700"
                                  >
                                    {detailBatubara.company_listing.jenis_perusahaan}  {capitalizeEachWord(detailBatubara.company_listing.name)}
                                  </Typography>
                                  <Grid className="pointer" onClick={(e) => handleNavigate(detailBatubara.company_listing.id, detailBatubara.company_listing.name)}
                                    container
                                    justifyContent="left"
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <Typography
                                      className="w-500"
                                      sx={{ fontSize: 16, color: "#2457B9", mr: 1 }}
                                    >
                                      {translate('detail-explore.lihatdetailperusahaan')}
                                    </Typography>
                                    <ArrowForwardIosIcon sx={{ fontSize: 12, color: "#2457B9", pt: '3px' }} />
                                  </Grid>
                                </Stack>
                              </Grid>
                            </Box>
                            <Box sx={{ ml: 2, mr: 2, mt: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0 }, mb: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0 }, padding: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0 } }}>
                              <Box sx={{ pr: 2, mb: 2, mt: { xs: 0, sm: 0, md: 0, lg: 1, xl: 1 } }}>
                                {detailBatubara.company_listing.bidang_perusahaan === 1 ? (
                                  <Grid
                                    container
                                    justifyContent="left"
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <img alt="penambang-icon" src={PenambangIcon} height={24}></img>
                                    <Typography
                                      className="w-500"
                                      sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                    >
                                      {translate('page_explore.penambang')}
                                    </Typography>
                                  </Grid>
                                ) : detailBatubara.company_listing.bidang_perusahaan === 2 ? (
                                  <Grid
                                    container
                                    justifyContent="left"
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <img alt="penambang-icon" src={traderIcon} height={24}></img>
                                    <Typography
                                      className="w-500"
                                      sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                    >
                                      Trader
                                    </Typography>
                                  </Grid>
                                ) : detailBatubara.company_listing.bidang_perusahaan === 3 ? (
                                  <Grid
                                    container
                                    justifyContent="left"
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <img alt="penambang-icon" src={pelayarIcon} height={24}></img>
                                    <Typography
                                      className="w-500"
                                      sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                    >
                                      {translate('page_explore.pelayaran')}
                                    </Typography>
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              </Box>

                              <Box sx={{ pr: 2, mt: 1, mb: 2 }}>
                                {detailBatubara.company_listing.alamat && <Grid
                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <img alt="call-icon" src={locationCompanyIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                  >
                                    {detailBatubara.company_listing.alamat[0].detail_alamat}
                                  </Typography>
                                </Grid>}
                              </Box>
                              <Box sx={{ pr: 2, mt: 1, mb: 2 }}>
                                {detailBatubara.company_listing.phone_number &&
                                  <Grid
                                    container
                                    justifyContent="left"
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <img alt="Nikel-icon" src={callCompanyIcon} height={24}></img>
                                    <Typography
                                      className="w-500"
                                      sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                    >
                                      {format(detailBatubara.company_listing.phone_number)}
                                    </Typography>
                                  </Grid>}
                              </Box>
                              <Box sx={{ pr: 2, mt: 1, mb: 2 }}>
                                {detailBatubara.company_listing.email &&
                                  <Grid
                                    container
                                    justifyContent="left"
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <img alt="Nikel-icon" src={mailCompanyIcon} height={24}></img>
                                    <Typography
                                      className="w-500"
                                      sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                    >
                                      {detailBatubara.company_listing.email}
                                    </Typography>
                                  </Grid>}
                              </Box>
                            </Box>
                            <Divider style={{ backgroundColor: "#E8EBF2", marginTop: '32px', marginBottom: '32px', marginLeft: '16px', marginRight: '16px' }} />
                          </Box>}

                        <Hidden lgDown>

                          <Box sx={{ pl: 2, pr: 2, mb: 2 }}>
                            <Button className="w-500" onClick={(e) => {
                              window.open(`https://api.whatsapp.com/send?phone=+62${detailBatubara?.phone_number}`)
                            }} size="small" sx={{ background: '#2457B9', mb: 1, textTransform: 'none', p: '12px 16px', borderRadius: '8px', fontSize: '16px', lineHeight: '22px' }} variant="contained" fullWidth startIcon={<CallIcon sx={{ height: '20px', width: '20px' }} />}>
                              {translate('detail-explore.hubungitrader')}
                            </Button>
                            {checkingFavorite === true ?
                              <Button className="w-500" onClick={() => removeFavorite(1)} sx={{
                                textTransform: "none", border: '1px solid var(--primarry-blue, #2457B9)',
                                p: '12px 16px',
                                borderRadius: '8px',
                                fontSize: '16px',
                                lineHeight: '22px',
                                color: '#2457B9'
                              }} size="small" variant="outlined" fullWidth startIcon={<FavoriteTwoToneIcon sx={{ fill: "red" }} />}>
                                {translate('status.hapusfavorite')}
                              </Button>
                              :
                              <Button className="w-500" onClick={() => setFavorite(1)} sx={{
                                textTransform: "none", border: '1px solid var(--primarry-blue, #2457B9)',
                                p: '12px 16px',
                                borderRadius: '8px',
                                fontSize: '16px',
                                lineHeight: '22px',
                                color: '#2457B9'
                              }} size="small" variant="outlined" fullWidth startIcon={<FavoriteBorderIcon />}>
                                {translate('detail-explore.tambahkanfavorit')}
                              </Button>
                            }
                          </Box>
                          <Box sx={{ pl: 2, pr: 2, mt: 1, mb: 2 }}>
                            <Grid
                              container
                              justifyContent="left"
                              alignItems="center"
                              direction="row"
                              sx={{ cursor: 'pointer' }}
                              onClick={() => {
                                SubmitReportListing();
                              }}
                            >
                              <img alt="Nikel-icon" src={reporticon} height={24}></img>
                              <Typography
                                className="w-500"
                                sx={{ fontSize: 16, color: "#56617B", ml: 1, }}
                              >
                                {translate('detail-explore.laporkanlisting')}
                              </Typography>
                            </Grid>
                          </Box>
                        </Hidden>
                      </Box>
                    </Hidden>}
                    <Hidden lgDown>
                      <Box sx={{ width: 355, }}>
                        <Grid container display="flex" justifyContent="space-between" direction={"row"}>
                          <Typography
                            className="w-700"
                            sx={{ fontSize: 18, color: '#081130', mt: '24px', mb: '12px' }}
                          >
                            {translate('detail-explore.bagikanlisting')}
                          </Typography>
                          {copyClipboard}
                        </Grid>
                        <Box sx={{ background: '#F4F6FA', border: '1px solid #E8EBF2', borderRadius: '8px', pt: '16px', pl: '16px', pr: '16px', pb: '16px' }}>
                          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography className="w-700" sx={{ color: '#56617B', fontSize: 14, }}>
                              {(`${config.staging}/penawaran/detail?type=1&id_detail=${location.state.id}`).slice(0, 42) + '...'}
                            </Typography>
                            <img alt="copy-icon" src={copyIc} className="pointer" height={20} onClick={(e) => {
                              navigator.clipboard.writeText(`${config.staging}/penawaran/detail?type=1&id_detail=${location.state.id}`)
                              copyLink()
                            }}></img>
                          </Stack>
                        </Box>
                        <Stack sx={{ mt: '16px' }} direction={'row'} justifyContent={'space-evenly'} alignItems={'center'}>
                          <TwitterShareButton url={`${config.staging}/penawaran/detail?type=1&id_detail=${location.state.id}`}>
                            <img alt="twitter-icon" src={twitterIcon} height={'40px'} width={'40px'}></img>
                          </TwitterShareButton>
                          <WhatsappShareButton url={`${config.staging}/penawaran/detail?type=1&id_detail=${location.state.id}`}>
                            <img alt="wa-icon" src={waIcon} height={'40px'} width={'40px'}></img>
                          </WhatsappShareButton>
                          <TelegramShareButton url={`${config.staging}/penawaran/detail?type=1&id_detail=${location.state.id}`}>
                            <img alt="tele-icon" src={telegramIcon} height={'40px'} width={'40px'}></img>
                          </TelegramShareButton>
                          <FacebookMessengerShareButton appId={`${config.appId}`} url={`${config.staging}/penawaran/detail?type=1&id_detail=${location.state.id}`}>
                            <img alt="fb-icon" src={fbIcon} height={'40px'} width={'40px'}></img>
                          </FacebookMessengerShareButton>
                        </Stack>
                      </Box>
                    </Hidden>
                  </Box>
                  : ''}
                {/* detail nikel Section */}
                {activeTab === 'nikel' ?
                  <Box sx={{ width: { xs: '100%', sm: '100%', md: '100%', lg: 355, xl: 355 }, }} >
                    {isLogin && <Hidden lgDown>
                      <Box sx={{ border: 1, color: '#E8EBF2', borderRadius: '12px', pt: 2 }}>
                        {(detailNikel.company_listing !== null && detailNikel.company_listing !== undefined) &&
                          <Box>
                            <Typography className="w-700" sx={{ color: '#081130', fontSize: 18, mt: 1, pl: 2 }}>
                              {translate('detail-explore.tentangperusahaan')}
                            </Typography>
                            <Box sx={{ pl: 2, pr: 2, mt: 2, }}>
                              <Grid
                                container
                                direction="row"
                                alignItems="left"
                                justifyContent="left"
                              >
                                {detailNikel.company_listing?.picture_path?.url !== undefined &&

                                  <Avatar sx={{ bgcolor: 'blue', mr: '16px' }} aria-label="recipe" src={config.baseURL + detailNikel.company_listing?.picture_path?.url}>
                                  </Avatar>
                                }
                                <Stack style={{ textAlign: "left", color: "white" }} spacing={0}>
                                  <Typography
                                    sx={{ fontSize: 16, color: '#081130' }}
                                    className="w-700"
                                  >
                                    {detailNikel.company_listing.jenis_perusahaan} {capitalizeEachWord(detailNikel.company_listing.name)}
                                  </Typography>
                                  <Grid className="pointer"
                                    containeronClick={(e) => handleNavigate(detailNikel.company_listing.id, detailNikel.company_listing.name)}
                                    justifyContent="left"
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <Typography
                                      className="w-500"
                                      sx={{ fontSize: 16, color: "#2457B9", mr: 1 }}
                                    >
                                      {translate('detail-explore.lihatdetailperusahaan')}
                                    </Typography>
                                    <ArrowForwardIosIcon sx={{ fontSize: 12, color: "#2457B9", pt: '3px' }} />
                                  </Grid>
                                </Stack>
                              </Grid>
                            </Box>

                            <Box sx={{ ml: 2, mr: 2, mt: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0 }, mb: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0 }, pl: 2, pr: 2, pt: 2 }}>
                              <Box sx={{ pr: 2, mb: 2 }}>
                                {detailNikel.company_listing.bidang_perusahaan === 1 ? (
                                  <Grid
                                    container
                                    justifyContent="left"
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <img alt="penambang-icon" src={PenambangIcon} height={24}></img>
                                    <Typography
                                      className="w-500"
                                      sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                    >
                                      {translate('page_explore.penambang')}
                                    </Typography>
                                  </Grid>
                                ) : detailNikel.company_listing.bidang_perusahaan === 2 ? (
                                  <Grid
                                    container
                                    justifyContent="left"
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <img alt="penambang-icon" src={traderIcon} height={24}></img>
                                    <Typography
                                      className="w-500"
                                      sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                    >
                                      Trader
                                    </Typography>
                                  </Grid>
                                ) : detailNikel.company_listing.bidang_perusahaan === 3 ? (
                                  <Grid
                                    container
                                    justifyContent="left"
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <img alt="penambang-icon" src={pelayarIcon} height={24}></img>
                                    <Typography
                                      className="w-500"
                                      sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                    >
                                      {translate('page_explore.pelayaran')}
                                    </Typography>
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              </Box>
                              <Box sx={{ pr: 2, mt: 1, mb: 2 }}>
                                {detailNikel.company_listing.alamat &&
                                  <Grid
                                    container
                                    justifyContent="left"
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <img alt="call-icon" src={locationCompanyIcon} height={24}></img>
                                    <Typography
                                      className="w-500"
                                      sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                    >
                                      {detailNikel.company_listing.alamat[0].detail_alamat}
                                    </Typography>
                                  </Grid>}
                              </Box>
                              <Box sx={{ pr: 2, mt: 1, mb: 2 }}>
                                {detailNikel.company_listing.phone_number &&
                                  <Grid
                                    container
                                    justifyContent="left"
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <img alt="Nikel-icon" src={callCompanyIcon} height={24}></img>
                                    <Typography
                                      className="w-500"
                                      sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                    >
                                      {format(detailNikel.company_listing.phone_number)}
                                    </Typography>
                                  </Grid>}
                              </Box>
                              <Box sx={{ pr: 2, mt: 1, mb: 2 }}>
                                {detailNikel.company_listing.email &&
                                  <Grid
                                    container
                                    justifyContent="left"
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <img alt="Nikel-icon" src={mailCompanyIcon} height={24}></img>
                                    <Typography
                                      className="w-500"
                                      sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                    >
                                      {detailNikel.company_listing.email}
                                    </Typography>
                                  </Grid>}
                              </Box>
                            </Box>
                            <Divider style={{ backgroundColor: "#E8EBF2", marginTop: '32px', marginBottom: '32px', marginLeft: '16px', marginRight: '16px' }} />
                          </Box>}
                        <Hidden lgDown>
                          <Box sx={{ pl: 2, pr: 2, mb: 2 }}>
                            <Button className="w-500" onClick={(e) => {
                              window.open(`https://api.whatsapp.com/send?phone=+62${detailNikel?.phone_number}`)
                            }} size="small" sx={{ background: '#2457B9', mb: 1, textTransform: 'none', p: '12px 16px', borderRadius: '8px', fontSize: '16px', lineHeight: '22px' }} variant="contained" fullWidth startIcon={<CallIcon />}>
                              {translate('detail-explore.hubungitrader')}
                            </Button>
                            {checkingFavorite === true ?
                              <Button className="w-500" onClick={() => removeFavorite(2)} sx={{
                                textTransform: "none", border: '1px solid var(--primarry-blue, #2457B9)',
                                p: '12px 16px',
                                borderRadius: '8px',
                                fontSize: '16px',
                                lineHeight: '22px',
                                color: '#2457B9'
                              }} size="small" variant="outlined" fullWidth startIcon={<FavoriteTwoToneIcon sx={{ fill: "red" }} />}>
                                {translate('status.hapusfavorite')}
                              </Button>
                              :
                              <Button className="w-500" onClick={() => setFavorite(2)} sx={{
                                textTransform: "none", border: '1px solid var(--primarry-blue, #2457B9)',
                                p: '12px 16px',
                                borderRadius: '8px',
                                fontSize: '16px',
                                lineHeight: '22px',
                                color: '#2457B9'
                              }} size="small" variant="outlined" fullWidth startIcon={<FavoriteBorderIcon />}>
                                {translate('detail-explore.tambahkanfavorit')}
                              </Button>
                            }
                          </Box>

                          <Box sx={{ pl: 2, pr: 2, mt: 1, mb: 2 }}>
                            <Grid
                              container
                              justifyContent="left"
                              alignItems="center"
                              direction="row"
                              sx={{ cursor: 'pointer' }}
                              onClick={() => {
                                SubmitReportListing();
                              }}
                            >
                              <img alt="Nikel-icon" src={reporticon} height={24}></img>
                              <Typography
                                className="w-500"
                                sx={{ fontSize: 16, color: "#56617B", ml: 1, }}
                              >
                                {translate('detail-explore.laporkanlisting')}
                              </Typography>
                            </Grid>
                          </Box>
                        </Hidden>
                      </Box>
                    </Hidden>}
                    <Hidden lgDown>
                      <Box sx={{ width: 355, }}>
                        <Grid container display="flex" justifyContent="space-between" direction={"row"}>
                          <Typography
                            className="w-700"
                            sx={{ fontSize: 18, color: '#081130', mt: '24px', mb: '12px' }}
                          >
                            {translate('detail-explore.bagikanlisting')}
                          </Typography>
                          {copyClipboard}
                        </Grid>
                        <Box sx={{ background: '#F4F6FA', border: '1px solid #E8EBF2', borderRadius: '8px', pt: '16px', pl: '16px', pr: '16px', pb: '16px' }}>
                          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography className="w-700" sx={{ color: '#56617B', fontSize: 14, }}>
                              {(`${config.staging}/penawaran/detail?type=2&id_detail=${location.state.id}`).slice(0, 42) + '...'}
                            </Typography>
                            <img alt="copy-icon" className="pointer" src={copyIc} height={20} onClick={(e) => {
                              navigator.clipboard.writeText(`${config.staging}/penawaran/detail?type=2&id_detail=${location.state.id}`)
                              copyLink()
                            }}></img>
                          </Stack>
                        </Box>
                        <Stack sx={{ mt: '16px' }} direction={'row'} justifyContent={'space-evenly'} alignItems={'center'}>
                          <TwitterShareButton url={`${config.staging}/penawaran/detail?type=2&id_detail=${location.state.id}`}>
                            <img alt="twitter-icon" src={twitterIcon} height={'40px'} width={'40px'}></img>
                          </TwitterShareButton>
                          <WhatsappShareButton url={`${config.staging}/penawaran/detail?type=2&id_detail=${location.state.id}`}>
                            <img alt="wa-icon" src={waIcon} height={'40px'} width={'40px'}></img>
                          </WhatsappShareButton>
                          <TelegramShareButton url={`${config.staging}/penawaran/detail?type=2&id_detail=${location.state.id}`}>
                            <img alt="tele-icon" src={telegramIcon} height={'40px'} width={'40px'}></img>
                          </TelegramShareButton>
                          <FacebookMessengerShareButton appId={`${config.appId}`} url={`${config.staging}/penawaran/detail?type=2&id_detail=${location.state.id}`}>
                            <img alt="fb-icon" src={fbIcon} height={'40px'} width={'40px'}></img>
                          </FacebookMessengerShareButton>
                        </Stack>
                      </Box>
                    </Hidden>
                  </Box>
                  : ''}
                {/* detail tongkang Section */}
                {activeTab === 'tongkang' ?
                  <Box sx={{ width: { xs: '100%', sm: '100%', md: '100%', lg: 355, xl: 355 }, }} >
                    {isLogin && <Hidden lgDown>
                      <Box sx={{ border: 1, color: '#E8EBF2', borderRadius: '12px', pt: 2 }}>
                        {(detailTongkang.company_listing !== null && detailTongkang.company_listing !== undefined) &&
                          <Box>
                            <Typography className="w-700" sx={{ color: '#081130', fontSize: 18, mt: 1, pl: 2 }}>
                              {translate('detail-explore.tentangperusahaan')}
                            </Typography>

                            <Box sx={{ pl: 2, pr: 2, mt: 2, }}>
                              <Grid
                                container
                                direction="row"
                                alignItems="left"
                                justifyContent="left"

                              >
                                {detailTongkang.company_listing?.picture_path?.url !== undefined &&

                                  <Avatar sx={{ bgcolor: 'blue', mr: '16px' }} aria-label="recipe" src={config.baseURL + detailTongkang.company_listing?.picture_path?.url}>

                                  </Avatar>
                                }

                                <Stack style={{ textAlign: "left", color: "white" }} spacing={0}>
                                  {detailTongkang.company_listing.name &&
                                    <Typography
                                      sx={{ fontSize: 16, color: '#081130' }}
                                      className="w-700"
                                    >
                                      {detailTongkang.company_listing.jenis_perusahaan}
                                      {capitalizeEachWord(detailTongkang.company_listing.name)}
                                    </Typography>}
                                  <Grid className="pointer"
                                    container onClick={(e) => handleNavigate(detailTongkang.company_listing.id, detailTongkang.company_listing.name)}
                                    justifyContent="left"
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <Typography
                                      className="w-500"
                                      sx={{ fontSize: 16, color: "#2457B9", mr: 1 }}
                                    >
                                      {translate('detail-explore.lihatdetailperusahaan')}
                                    </Typography>
                                    <ArrowForwardIosIcon sx={{ fontSize: 12, color: "#2457B9", pt: '3px' }} />
                                  </Grid>
                                </Stack>
                              </Grid>
                            </Box>

                            <Box sx={{ ml: 2, mr: 2, mt: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0 }, mb: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0 }, pl: 2, pr: 2, pt: 2 }}>
                              <Box sx={{ pr: 2, mb: 2 }}>
                                {detailTongkang.company_listing.bidang_perusahaan === 1 ? (
                                  <Grid
                                    container
                                    justifyContent="left"
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <img alt="penambang-icon" src={PenambangIcon} height={24}></img>
                                    <Typography
                                      className="w-500"
                                      sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                    >
                                      {translate('page_explore.penambang')}
                                    </Typography>

                                  </Grid>
                                ) : detailTongkang.company_listing.bidang_perusahaan === 2 ? (
                                  <Grid
                                    container
                                    justifyContent="left"
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <img alt="penambang-icon" src={traderIcon} height={24}></img>
                                    <Typography
                                      className="w-500"
                                      sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                    >
                                      Trader
                                    </Typography>

                                  </Grid>
                                ) : detailTongkang.company_listing.bidang_perusahaan === 3 ? (
                                  <Grid
                                    container
                                    justifyContent="left"
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <img alt="penambang-icon" src={pelayarIcon} height={24}></img>
                                    <Typography
                                      className="w-500"
                                      sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                    >
                                      {translate('page_explore.pelayaran')}
                                    </Typography>
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              </Box>

                              <Box sx={{ pr: 2, mt: 1, mb: 2 }}>
                                {detailTongkang.company_listing.alamat && <Grid
                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <img alt="call-icon" src={locationCompanyIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                  >
                                    {detailTongkang.company_listing.alamat[0].detail_alamat}
                                  </Typography>
                                </Grid>}
                              </Box>
                              <Box sx={{ pr: 2, mt: 1, mb: 2 }}>
                                {detailTongkang.company_listing.phone_number &&
                                  <Grid
                                    container
                                    justifyContent="left"
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <img alt="Nikel-icon" src={callCompanyIcon} height={24}></img>
                                    <Typography
                                      className="w-500"
                                      sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                    >
                                      {format(detailTongkang.company_listing.phone_number)}
                                    </Typography>

                                  </Grid>}
                              </Box>
                              <Box sx={{ pr: 2, mt: 1, mb: 2 }}>
                                {detailTongkang.company_listing.email &&
                                  <Grid
                                    container
                                    justifyContent="left"
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <img alt="Nikel-icon" src={mailCompanyIcon} height={24}></img>
                                    <Typography
                                      className="w-500"
                                      sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                    >
                                      {detailTongkang.company_listing.email}
                                    </Typography>

                                  </Grid>}
                              </Box>
                            </Box>
                            <Divider style={{ backgroundColor: "#E8EBF2", marginTop: '32px', marginBottom: '32px', marginLeft: '16px', marginRight: '16px' }} />
                          </Box>}
                        <Hidden lgDown>
                          <Box sx={{ pl: 2, pr: 2, mb: 2 }}>
                            <Button className="w-500" onClick={(e) => {
                              window.open(`https://api.whatsapp.com/send?phone=+62${detailTongkang?.phone_number}`)
                            }} size="small" sx={{ background: '#2457B9', mb: 1, textTransform: 'none', p: '12px 16px', borderRadius: '8px', fontSize: '16px', lineHeight: '22px' }} variant="contained" fullWidth startIcon={<CallIcon />}>
                              {translate('detail-explore.hubungitrader')}
                            </Button>

                            {checkingFavorite === true ?
                              <Button className="w-500" onClick={() => removeFavorite(3)} sx={{
                                textTransform: "none", border: '1px solid var(--primarry-blue, #2457B9)',
                                p: '12px 16px',
                                borderRadius: '8px',
                                fontSize: '16px',
                                lineHeight: '22px',
                                color: '#2457B9'
                              }} size="small" variant="outlined" fullWidth startIcon={<FavoriteTwoToneIcon sx={{ fill: "red" }} />}>
                                {translate('status.hapusfavorite')}
                              </Button>
                              :
                              <Button className="w-500" onClick={() => setFavorite(3)} sx={{
                                textTransform: "none", border: '1px solid var(--primarry-blue, #2457B9)',
                                p: '12px 16px',
                                borderRadius: '8px',
                                fontSize: '16px',
                                lineHeight: '22px',
                                color: '#2457B9'
                              }} size="small" variant="outlined" fullWidth startIcon={<FavoriteBorderIcon />}>
                                {translate('detail-explore.tambahkanfavorit')}
                              </Button>
                            }
                          </Box>

                          <Box sx={{ pl: 2, pr: 2, mt: 1, mb: 2 }}>
                            <Grid
                              container
                              justifyContent="left"
                              alignItems="center"
                              direction="row"
                              sx={{ cursor: 'pointer' }}
                              onClick={() => {
                                SubmitReportListing();
                              }}
                            >
                              <img alt="Nikel-icon" src={reporticon} height={24}></img>
                              <Typography
                                className="w-500"
                                sx={{ fontSize: 16, color: "#56617B", ml: 1, }}
                              >
                                {translate('detail-explore.laporkanlisting')}
                              </Typography>

                            </Grid>
                          </Box>
                        </Hidden>
                      </Box>
                    </Hidden>}
                    <Hidden lgDown>
                      <Box sx={{ width: 355, }}>
                        <Grid container display="flex" justifyContent="space-between" direction={"row"}>
                          <Typography
                            className="w-700"
                            sx={{ fontSize: 18, color: '#081130', mt: '24px', mb: '12px' }}
                          >
                            {translate('detail-explore.bagikanlisting')}
                          </Typography>
                          {copyClipboard}
                        </Grid>
                        <Box sx={{ background: '#F4F6FA', border: '1px solid #E8EBF2', borderRadius: '8px', pt: '16px', pl: '16px', pr: '16px', pb: '16px' }}>
                          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography className="w-700" sx={{ color: '#56617B', fontSize: 14, }}>
                              {(`${config.staging}/penawaran/detail?type=3&id_detail=${location.state.id}`).slice(0, 42) + '...'}
                            </Typography>
                            <img alt="copy-icon" className="pointer" src={copyIc} height={20} onClick={(e) => {
                              navigator.clipboard.writeText(`${config.staging}/penawaran/detail?type=3&id_detail=${location.state.id}`)
                              copyLink()
                            }}></img>
                          </Stack>
                        </Box>
                        <Stack sx={{ mt: '16px' }} direction={'row'} justifyContent={'space-evenly'} alignItems={'center'}>
                          <TwitterShareButton url={`${config.staging}/penawaran/detail?type=3&id_detail=${location.state.id}`}>
                            <img alt="twitter-icon" src={twitterIcon} height={'40px'} width={'40px'}></img>
                          </TwitterShareButton>
                          <WhatsappShareButton url={`${config.staging}/penawaran/detail?type=3&id_detail=${location.state.id}`}>
                            <img alt="wa-icon" src={waIcon} height={'40px'} width={'40px'}></img>
                          </WhatsappShareButton>
                          <TelegramShareButton url={`${config.staging}/penawaran/detail?type=3&id_detail=${location.state.id}`}>
                            <img alt="tele-icon" src={telegramIcon} height={'40px'} width={'40px'}></img>
                          </TelegramShareButton>
                          <FacebookMessengerShareButton appId={`${config.appId}`} url={`${config.staging}/penawaran/detail?type=3&id_detail=${location.state.id}`}>
                            <img alt="fb-icon" src={fbIcon} height={'40px'} width={'40px'}></img>
                          </FacebookMessengerShareButton>
                        </Stack>
                      </Box>
                    </Hidden>
                  </Box>
                  : ''}
              </Grid>
            </Grid>
            {activeTab === 'tongkang' &&
              <div className="recomendation-tongkang">
                <Typography className="w-700" sx={{ color: '#081130', fontSize: 18, mt: 1, mb: '24px', }}>
                  {translate('detail-explore.rekomendasitongkang')}
                </Typography>
                <div className="box-slide width-100">
                  <Box
                    sx={{ mr: '4px', width: '100%', mb: '32px' }}
                  >
                    <Slider {...settingsRecommendasiTongkang}>
                      {tongkangList.map(tongkang => (
                        <Box sx={{ mr: '4px', ml: '4px' }}>
                          <CardRecomendationTongkangList page={'home'} key={tongkang.product_id} data={tongkang} /> </Box>
                      ))}
                    </Slider>
                  </Box>
                </div>
              </div>}
          </div>
          {/* Floating Action Button Section */}
          {isLogin && <Hidden lgUp>
            <Grid sx={{ pl: { xs: '16px', sm: '46px', md: '78px', }, pr: { xs: '16px', sm: '46px', md: '78px', } }} onScroll={scrollHandler} container direction="row" className={`${position ? "disable-freeze-grid-new" : "freeze-grid-new"}`}>
              <Grid alignItems={'center'} lg={8} xs={12} item sx={{ width: activeTab === 'trader' ? '1280px' : '840px' }}>
                {/* coal Section */}
                {activeTab === 'coal' ?
                  <Box sx={{ mb: 2, }} >
                    <Hidden lgUp>
                      <Grid container direction={'row'} spacing={1} alignItems={'center'}>
                        <Grid item xs={8}>
                          <Button
                            onClick={(e) => {
                              window.open(`https://api.whatsapp.com/send?phone=+62${detailBatubara?.phone_number}`)
                            }}
                            fullWidth
                            className="w-500"
                            size="large" sx={{ background: '#2457B9', textTransform: 'none', fontSize: '14px', padding: '12px 16px', borderRadius: '8px' }}
                            variant="contained" startIcon={<CallIcon />}>
                            {translate('detail-explore.hubungitrader')}
                          </Button>

                        </Grid>
                        <Grid item xs={2}>
                          <Box sx={{ border: '1px solid #56617B', cursor: 'pointer', borderRadius: '8px', p: '12px', display: 'flex', justifyContent: 'center' }} >
                            {checkingFavorite === true ?
                              <FavoriteTwoToneIcon onClick={() => removeFavorite(1)} sx={{ fill: "red", width: '20px', height: '20px', cursor: 'pointer' }} />
                              :
                              <FavoriteBorderIcon onClick={() => setFavorite(1)} sx={{ width: '20px', height: '20px', cursor: 'pointer' }} />
                            }
                          </Box>
                        </Grid>
                        <Grid item xs={2} >
                          <Box onClick={(e) => handleOpenModal('more')} sx={{ border: '1px solid #56617B', cursor: 'pointer', borderRadius: '8px', p: '12px', display: 'flex', justifyContent: 'center' }} > <MoreHorizIcon sx={{ width: '20px', height: '20px' }} /> </Box>
                          <DrawerMore page="permintaan" type={1} id={detailBatubara?.id} share={handleShareModal} open={isOpenMore} close={handleCloseModal}>

                          </DrawerMore>
                          <ModalShareListing open={isOpenModalShare} close={closeShareModal}></ModalShareListing>
                        </Grid>
                      </Grid>
                    </Hidden>
                  </Box> : null}
                {/* nikel Section */}
                {activeTab === 'nikel' ?
                  <Box sx={{ mb: 2, }} >
                    <Hidden lgUp>
                      <Grid container direction={'row'} spacing={1} alignItems={'center'}>
                        <Grid item xs={8}>
                          <Button
                            onClick={(e) => {
                              window.open(`https://api.whatsapp.com/send?phone=+62${detailNikel?.phone_number}`)
                            }}
                            fullWidth
                            className="w-500"
                            size="large" sx={{ background: '#2457B9', textTransform: 'none', fontSize: '14px', padding: '12px 16px', borderRadius: '8px' }}
                            variant="contained" startIcon={<CallIcon />}>
                            {translate('detail-explore.hubungitrader')}
                          </Button>

                        </Grid>
                        <Grid item xs={2} >

                          <Box sx={{ border: '1px solid #56617B', cursor: 'pointer', borderRadius: '8px', p: '12px', display: 'flex', justifyContent: 'center' }} >
                            {checkingFavorite === true ?
                              <FavoriteTwoToneIcon onClick={() => removeFavorite(1)} sx={{ fill: "red", width: '20px', height: '20px', cursor: 'pointer' }} />
                              :
                              <FavoriteBorderIcon onClick={() => setFavorite(1)} sx={{ width: '20px', height: '20px', cursor: 'pointer' }} />
                            }</Box>
                        </Grid>
                        <Grid item xs={2} >
                          <Box onClick={(e) => handleOpenModal('more')} sx={{ border: '1px solid #56617B', cursor: 'pointer', borderRadius: '8px', p: '12px', display: 'flex', justifyContent: 'center' }} > <MoreHorizIcon sx={{ width: '20px', height: '20px' }} /> </Box>
                          <DrawerMore page="permintaan" type={2} id={detailNikel.id} share={handleShareModal} open={isOpenMore} close={handleCloseModal}>
                          </DrawerMore>
                          <ModalShareListing open={isOpenModalShare} close={closeShareModal}></ModalShareListing>
                        </Grid>
                      </Grid>
                    </Hidden>
                  </Box> : null}
                {/* tongkang Section */}
                {activeTab === 'tongkang' ?
                  <Box sx={{ mb: 2, }}  >
                    <Hidden lgUp>
                      <Grid container direction={'row'} spacing={1} alignItems={'center'}>
                        <Grid item xs={8}>
                          <Button
                            onClick={(e) => {
                              window.open(`https://api.whatsapp.com/send?phone=+62${detailTongkang?.phone_number}`)
                            }}
                            fullWidth
                            className="w-500"
                            size="large" sx={{ background: '#2457B9', textTransform: 'none', fontSize: '14px', padding: '12px 16px', borderRadius: '8px' }}
                            variant="contained" startIcon={<CallIcon />}>
                            {translate('detail-explore.hubungitrader')}
                          </Button>

                        </Grid>
                        <Grid item xs={2} >

                          <Box sx={{ border: '1px solid #56617B', cursor: 'pointer', borderRadius: '8px', p: '12px', display: 'flex', justifyContent: 'center' }} > {checkingFavorite === true ?
                            <FavoriteTwoToneIcon onClick={() => removeFavorite(1)} sx={{ fill: "red", width: '20px', height: '20px', cursor: 'pointer' }} />
                            :
                            <FavoriteBorderIcon onClick={() => setFavorite(1)} sx={{ width: '20px', height: '20px', cursor: 'pointer' }} />
                          } </Box>
                        </Grid>
                        <Grid item xs={2} >
                          <Box onClick={(e) => handleOpenModal('more')} sx={{ border: '1px solid #56617B', cursor: 'pointer', borderRadius: '8px', p: '12px', display: 'flex', justifyContent: 'center' }} > <MoreHorizIcon sx={{ width: '20px', height: '20px' }} /> </Box>
                          <DrawerMore page="permintaan" type={3} id={detailTongkang.id} share={handleShareModal} open={isOpenMore} close={handleCloseModal}>
                          </DrawerMore>
                          <ModalShareListing open={isOpenModalShare} close={closeShareModal}></ModalShareListing>
                        </Grid>
                      </Grid>
                    </Hidden>
                  </Box> : null}
              </Grid>
            </Grid>
          </Hidden>}
          <Footer></Footer>
        </Box>
      </div >
      <ModalReportListing url_website={url_website} open={openModalReport} close={handleCloseModal} page={type_data} type={type_data} id={location.state.id} ></ModalReportListing>

    </div >
  )
}

export default DetailPenawaranpage
