import Divider from "@material-ui/core/Divider"
import Hidden from "@material-ui/core/Hidden"
import { Box, Button, Grid, Stack, Typography } from "@mui/material"
import * as React from "react"
import { useTranslation } from "react-i18next"
import callIcon from "../assets/call.png"
import fbIcon from "../assets/fb-ic.png"
import igIcon from "../assets/ig-ic.png"
import locationIcon from "../assets/location.png"
import LogoIndomine from "../assets/logo.png"
import mailIcon from "../assets/mail.png"
import rentIcon from "../assets/rent-ic.png"
import sellIcon from "../assets/sell-ic.png"
import twitterIcon from "../assets/twitter-ic.png"
import waIcon from "../assets/wa-circle-grey-ic.png"
import Copyrighticon from "../assets/© Copyright 2022 Indomine.png"
import { DrawerBuatPermintaan } from "./DrawerBuatPermintaan"
import { ModalPermintaan, ModalPermintaanSewaTongkang } from "./ModalPermintaan"
import { ModalPermintaanSewaTongkang as ModalPermintaanSewaTongkang2 } from "./DrawerBuatPermintaan"
import { getFooterInformation } from "../services/api"
import { useEffect } from "react"

function Footer() {
  const [translate,] = useTranslation("global");
  const [isOpenModal, setOpenModal] = React.useState(false)
  const isLogin = localStorage.getItem('token') !== null ? true : false;

  const [titleIn, setTitleIn] = React.useState("")
  const [titleEn, setTitleEn] = React.useState("")
  const [subtitleIn, setSubTitleIn] = React.useState("")
  const [subtitleEn, setSubTitleEn] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [phone, setPhone] = React.useState("")
  const [location, setLocation] = React.useState("")
  const [facebookLink, setFacebook] = React.useState("")
  const [twitterLink, setTwitter] = React.useState("")
  const [instagramLink, setInstagram] = React.useState("")
  const [isOpenModalSewaTongkang, setisOpenModalSewaTongkang] = React.useState(false)

  const handleCloseModalPermintaan = () => {
    setisOpenModalSewaTongkang(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  async function getDataUser() {
    // if (localStorage.getItem("token") !== null && localStorage.getItem("id_user") !== null) {
    //   const res = await getFooterInformation()
    //   setTitleIn(res.data.data.attributes?.title_footer_indonesia ?? "Mulai menjual batubara, nikel dan menyewakan tongkang sekarang!")
    //   setTitleEn(res.data.data.attributes?.title_footer_english ?? "Began to sell coal, nickel and rent a barge now!")
    //   setSubTitleIn(res.data.data.attributes?.subtitle_footer_indonesia ?? "Mulai menjual batubara, nikel dan menyewakan tongkang sekarang!")
    //   setSubTitleEn(res.data.data.attributes?.subtitle_footer_english ?? "Began to sell coal, nickel and rent a barge now!")
    //   setEmail(res.data.data.attributes?.email_admin ?? "admin@indomine.com")
    //   setPhone(res.data.data.attributes?.phone_number ?? "(526) 373-0026")
    //   setLocation(res.data.data.attributes?.location ?? "Jl Pluit Mas V Bl D/8 A, Jakarta Indonesia, 14450")
    //   setFacebook(res.data.data.attributes?.facebook ?? "#")
    //   setTwitter(res.data.data.attributes?.twitter ?? "#")
    //   setInstagram(res.data.data.attributes?.instagram ?? "#")
    //   console.log(res)
    //   // dataUser.push(res.data.data)
    // }
    const res = await getFooterInformation()
    setTitleIn(res.data.data.attributes?.title_footer_indonesia ?? "Mulai menjual batubara, nikel dan menyewakan tongkang sekarang!")
    setTitleEn(res.data.data.attributes?.title_footer_english ?? "Began to sell coal, nickel and rent a barge now!")
    setSubTitleIn(res.data.data.attributes?.subtitle_footer_indonesia ?? "Mulai menjual batubara, nikel dan menyewakan tongkang sekarang!")
    setSubTitleEn(res.data.data.attributes?.subtitle_footer_english ?? "Began to sell coal, nickel and rent a barge now!")
    setEmail(res.data.data.attributes?.email_admin ?? "admin@indomine.com")
    setPhone(res.data.data.attributes?.phone_number ?? "(526) 373-0026")
    setLocation(res.data.data.attributes?.location ?? "Jl Pluit Mas V Bl D/8 A, Jakarta Indonesia, 14450")
    setFacebook(res.data.data.attributes?.facebook ?? "#")
    setTwitter(res.data.data.attributes?.twitter ?? "#")
    setInstagram(res.data.data.attributes?.instagram ?? "#")
    console.log(res)
  }

  useEffect(() => {
    getDataUser()
  }, [])

  return (
    <Box className='flex-center width-100' sx={{ textAlign: "center", background: "#0F2948", paddingTop: 6 }}>
      <Box className="flex-wrap-center" sx={{ width: { md: "calc(100% - 108px)", xs: "calc(100% - 32px)" }, }}>
        <Grid sx={{ maxWidth: { md: '692px', xs: '100%' } }}>
          <Box>
            <Typography
              sx={{
                textAlign: { sm: 'center', xs: 'start' },
                color: "white",
                mb: 2,
                fontSize: {
                  lg: 36,
                  md: 36,
                  sm: 36,
                  xs: 23
                },
              }}
              className="w-700"
            >
              {/* {translate('footer.title1')} {translate('footer.title2')} */}
              {localStorage.getItem('lang') === "en" ? `${titleEn}` : `${titleIn}`}
            </Typography>
          </Box>
          <Grid
            container
            direction="row"
            alignItems="center"
            paddingBottom={4}
            justifyContent="center"
            spacing={1}
          >
            <Grid item sm={6} xs={12} sx={{ textAlign: { sm: "right", xs: 'center' } }}>
              {isLogin ? <Button
                className="w-500"
                onClick={handleOpenModal}
                variant="contained"
                sx={{
                  width: { sm: 'fit-content', xs: '100%' },
                  height: { sm: '54px', xs: '40px' },
                  background: ' linear-gradient(270deg, #2457B9 15%, #2860CD 100%)',
                  borderRadius: '8px',
                  textTransform: 'none'
                }}
                startIcon={
                  <Box
                    component="img"
                    sx={{
                      height: 18,
                    }}
                    alt="logo-img"
                    src={sellIcon}
                  />
                }
                style={{ paddingLeft: 30, paddingRight: 30, background: ' linear-gradient(270deg, #2457B9 15%, #2860CD 100%)', borderRadius: '8px' }}
              >
                {translate('footer.btn1')}
              </Button> : <Button
                className="w-500"
                href="/signin"
                variant="contained"
                sx={{
                  width: { sm: 'fit-content', xs: '100%' },
                  height: { sm: '54px', xs: '40px' },
                  background: ' linear-gradient(270deg, #2457B9 15%, #2860CD 100%)',
                  borderRadius: '8px',
                  textTransform: 'none'
                }}
                startIcon={
                  <Box
                    component="img"
                    sx={{
                      height: 18,
                    }}
                    alt="logo-img"
                    src={sellIcon}
                  />
                }
                style={{ paddingLeft: 30, paddingRight: 30, background: ' linear-gradient(270deg, #2457B9 15%, #2860CD 100%)', borderRadius: '8px' }}
              >
                {translate('footer.btn1')}
              </Button>}
              <Hidden mdDown>
                <ModalPermintaan open={isOpenModal} close={handleCloseModal}></ModalPermintaan>
              </Hidden>
              <Hidden lgUp>
                <DrawerBuatPermintaan open={isOpenModal} close={handleCloseModal}></DrawerBuatPermintaan>
              </Hidden>
            </Grid>
            <Grid item sm={6} xs={12} sx={{ textAlign: { sm: "left", xs: 'center' } }}>
              <Button
                className="w-500"
                onClick={(e) => {
                  // window.location.href = `/tongkang-disewakan`
                  setisOpenModalSewaTongkang(true)
                }}
                variant="contained"
                sx={{
                  width: { sm: 'fit-content', xs: '100%' },
                  height: { sm: '54px', xs: '40px' },
                  background: ' linear-gradient(270deg, #2457B9 15%, #2860CD 100%)',
                  borderRadius: '8px',
                  textTransform: 'none'
                }}
                startIcon={
                  <Box
                    component="img"
                    sx={{
                      height: 18,
                    }}
                    alt="logo-img"
                    src={rentIcon}
                  />
                }
                style={{ paddingLeft: 30, paddingRight: 30, }}
              >
                {translate('footer.btn2')}
              </Button>
            </Grid>
            <Hidden mdDown>
              <ModalPermintaanSewaTongkang page={"disewakan"} open={isOpenModalSewaTongkang} close={handleCloseModalPermintaan}></ModalPermintaanSewaTongkang>
            </Hidden>
            <Hidden lgUp>
              <ModalPermintaanSewaTongkang2 page={"disewakan"} open={isOpenModalSewaTongkang} close={handleCloseModalPermintaan}></ModalPermintaanSewaTongkang2>
            </Hidden>
          </Grid>
        </Grid>
        <Divider className="width-100" variant="middle" style={{ background: '#273E5A', marginTop: 10, marginRight: '0 !important', marginLeft: '0 !important' }} />
        <Grid container spacing={2} paddingTop={6}>
          <Grid item lg={8} sx={{ paddingLeft: { md: 0, xs: 4 } }}>
            <Box style={{ textAlign: "left" }}>
              <Box
                component="img"
                onClick={() => window.location.href = "/"}
                sx={{
                  height: 30,
                  cursor: "pointer"
                }}
                alt="logo-img"
                src={LogoIndomine}
              />
              <Typography
                sx={{
                  width: '250px',
                  textAlign: "left",
                  color: "white",
                  fontSize: { sm: '16px', xs: '14px' },
                  opacity: 0.7,
                  marginTop: "10px"
                }}
                className="w-500"
              >
                {/* {translate('footer.footer-subtitle')} */}
                {localStorage.getItem('lang') === "en" ? `${subtitleEn}` : `${subtitleIn}`}
              </Typography>
            </Box>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              sx={{ mt: 2 }} style={{ textAlign: "left", color: "white" }}
              spacing={2}>

              <Typography onClick={() => window.location.href = "/"} className="w-700" sx={{ fontSize: 14, cursor: 'pointer' }}>{translate('navbar.home')}
              </Typography>

              <Typography onClick={() => window.location.href = "/explore"} className="w-700" sx={{ fontSize: 14, cursor: 'pointer' }}>
                {translate('navbar.explore')}
              </Typography>
              {isLogin &&
                <>
                  <Typography onClick={() => window.location.href = "/penawaran"} className="w-700" sx={{ fontSize: 14, cursor: 'pointer' }}>
                    {translate('navbar.penawaran')}
                  </Typography>
                  <Typography onClick={() => window.location.href = "/permintaan"} className="w-700" sx={{ fontSize: 14, cursor: 'pointer' }}>
                    {translate('navbar.permintaan')}
                  </Typography>
                  <Typography onClick={() => window.location.href = "/listing"} className="w-700" sx={{ fontSize: 14, cursor: 'pointer' }}>
                    {translate('navbar.mylisting')}
                  </Typography>
                </>
              }
            </Stack>
          </Grid>
          <Grid item lg={4}>
            <Stack style={{ textAlign: "left", color: "white" }} spacing={2}>
              <Grid container alignItems="center" direction="row">
                <Grid item xs={0.9} sm={0.9} md={0.9} lg={0.9} xl={0.9}>
                  <img alt="Nikel-icon" src={mailIcon} height={18}></img>
                </Grid>
                <Grid item xs={11} sm={11.1} md={11.1} lg={11.1} xl={11.1} >
                  <Typography className="w-500" sx={{ fontSize: 14 }}>
                    {/* indomine@business.com */}
                    {email}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container alignItems="center" direction="row">
                <Grid item xs={0.9} sm={0.9} md={0.9} lg={0.9} xl={0.9}>
                  <img alt="Nikel-icon" src={callIcon} height={18}></img>
                </Grid>
                <Grid item xs={11.1} sm={11.1} md={11.1} lg={11.1} xl={11.1} >
                  <Typography sx={{ fontSize: 14 }} className="w-500">
                    {/* (526) 373-0026 */}
                    {phone}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container alignItems="center" direction="row">
                <Grid item xs={0.9} sm={0.9} md={0.9} lg={0.9} xl={0.9}>
                  <img alt="Nikel-icon" src={locationIcon} height={18}></img>
                </Grid>
                <Grid item xs={11.1} sm={11.1} md={11.1} lg={11.1} xl={11.1}>
                  <Typography sx={{ fontSize: 14, lineHeight: 'normal' }} className="w-500">
                    {/* Jl Pluit Mas V Bl D/8 A, Jakarta Indonesia, 14450 */}
                    {location}
                  </Typography>
                </Grid>

              </Grid>
            </Stack>
          </Grid>
        </Grid>

        <Grid
          container
          alignItems="center"
          paddingTop={4}
          // paddingBottom={2}
          paddingRight={4}
          spacing={2}

        >
          <Grid item xs={12} lg={6}>
            <Box style={{ textAlign: "left" }}>
              <Typography sx={{ fontSize: 14, lineHeight: 'normal', justifyContent: "left", display: "flex", color: "#FFFFFF", mb: 1 }} className="w-500">
                Follow the Indomine • Batubara channel on WhatsApp:

              </Typography>
              <img
                onClick={(e) => {
                  window.open(`https://whatsapp.com/channel/0029VaIecw6Ae5VszCiYdE2g`)
                }}
                style={{ marginBottom: 1, cursor: 'pointer' }}
                alt="Nikel-icon"

                src={waIcon}
                height={32}
              ></img>

            </Box>
          </Grid>
          <Grid
            item
            justifyContent="right"
            flexDirection="row" xs={6} sx={{ textAlign: "right" }}>


          </Grid>
        </Grid>
        <Divider className="width-100" variant="middle" style={{ background: '#273E5A', marginTop: 30 }} />
        <Grid
          container
          alignItems="center"
          paddingTop={4}
          paddingBottom={2}
          paddingRight={4}
          spacing={2}

        >
          <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
            <Box style={{ textAlign: "left", display: "flex", alignItems: "center", paddingTop: "3px" }}>
              <img alt="Nikel-icon" src={Copyrighticon} height={12}></img>
            </Box>
            <Typography onClick={() => window.location.href = "/kebijakanprivasi"} className="w-500" sx={{ fontSize: 14, cursor: 'pointer', marginLeft: "10px", color: "#FFFFFF" }}>{translate('navbar.kebijakan-privasi')}
            </Typography>
            <Typography onClick={() => window.location.href = "/ketentuan-umum"} className="w-500" sx={{ fontSize: 14, cursor: 'pointer', marginLeft: "10px", color: "#FFFFFF" }}>{translate('navbar.ketentuan-umum')}
            </Typography>
          </Grid>
          <Grid
            item
            justifyContent="right"
            flexDirection="row" xs={6} sx={{ textAlign: "right" }}>
            <img
              alt="Nikel-icon"
              style={{ marginRight: 8 }}
              src={fbIcon}
              height={32}
              onClick={() => {
                if (facebookLink) {
                  window.open(`${facebookLink}`, '_blank')
                }
              }}
            ></img>
            <img
              alt="Nikel-icon"
              style={{ marginRight: 8 }}
              src={twitterIcon}
              height={32}
              onClick={() => {
                if (twitterLink) {
                  window.open(`${twitterLink}`, '_blank')
                }
              }}
            ></img>
            <img
              alt="Nikel-icon"
              src={igIcon}
              height={32}
              onClick={() => {
                if (instagramLink) {
                  window.open(`${instagramLink}`, '_blank')
                }
              }}
            ></img>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default Footer
