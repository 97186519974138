import axios from "axios";
import * as React from "react";
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import "./App.css";
import NonLoginRedirect from "./components/NonLoginRedirect";
import BeliBatubara from './pages/BeliBatubara';
import BeliNikel from './pages/BeliNikel';
import BeliTongkang from './pages/BeliTongkang';
import Company from "./pages/Company";
import CreateCompany from "./pages/CreateCompany";
import DetailListingpage from "./pages/DetailListingpage";
import DetailPenawaranpage from "./pages/DetailPenawaranpage";
import DetailPermintaanpage from "./pages/DetailPermintaanpage";
import DetailPerusahaan from "./pages/DetailPerusahaan";
import DetailTrader from "./pages/DetailTrader";
import EditCompany from "./pages/EditCompany";
import ErrorPage from "./pages/ErrorPage";
import Explorepage from "./pages/Explorepage";
import Homepage from "./pages/Homepage";
import JualBatubara from './pages/JualBatubara';
import JualNikel from './pages/JualNikel';
import JualTongkang from './pages/JualTongkang';
import Listingpage from './pages/Listingpage';
import MenyewakanTongkang from './pages/MenyewakanTongkang';
import Penawaranpage from "./pages/Penawaranpage";
import PermintaanPage from "./pages/Permintaanpage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ProfilePage from './pages/Profilepage';
import Realtimepricepage from "./pages/Realtimepricepage";
import ResetPassword from './pages/ResetPasswordpage';
import SettingPage from './pages/Settingpage';
import SewaTongkang from "./pages/SewaTongkang";
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
import TermsCondition from "./pages/TermsCondition";
import { GlobalState } from './services/global';
import "./styles/dekstop.css";

function App() {

  const [lang, setLang] = React.useState(localStorage.getItem('lang') === null ? 'id' : localStorage.getItem('lang'));
  const [currency, setcurrency] = React.useState(localStorage.getItem('currency') === null ? 'usd' : localStorage.getItem('currency'));
  const [islogin, setLogin] = React.useState(localStorage.getItem('token') !== null ? true : false);


  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {

      let api = error.config?.url

      if ((401 === error.response?.status || 403 === error.response?.status) && !api.includes("login")) {
        localStorage.clear();
        if (!localStorage.getItem('token')) {
          window.location.assign('/signin');
        }
      } else if (error.response?.data?.status === 500 && error.response?.data?.message === 'Email has been used') {
        return error.response
      } else {
        return error.response
      }
    }
  );

  return (
    <Router>
      <GlobalState.Provider value={{ lang, setLang, currency, setcurrency }}>
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route path="*" element={<ErrorPage />} />
          <Route exact path="/kebijakanprivasi" element={<PrivacyPolicy />} />
          <Route exact path="/ketentuan-umum" element={<TermsCondition />} />
          <Route exact path="/explore" element={<Explorepage />} />
          <Route exact path="/penawaran" element={<Penawaranpage />} />
          <Route exact path="/penawaran/detail" element={<DetailPenawaranpage />} />
          <Route exact path="/listing/detail" element={<DetailListingpage />} />
          <Route exact path="/detailtrader" element={<DetailTrader />} />
          <Route exact path="detail/:companyname" element={<DetailPerusahaan />} />
          <Route exact path="/permintaan/detail" element={!islogin ? <NonLoginRedirect /> : <DetailPermintaanpage />} />
          <Route exact path="/profile" element={!islogin ? <NonLoginRedirect /> : <ProfilePage />} />
          <Route exact path="/setting" element={!islogin ? <NonLoginRedirect /> : <SettingPage />} />
          <Route exact path="/permintaan" element={!islogin ? <NonLoginRedirect /> : <PermintaanPage />} />
          <Route exact path="/listing" element={!islogin ? <NonLoginRedirect /> : <Listingpage />} />
          <Route exact path="/beli-batubara" element={!islogin ? <NonLoginRedirect /> : <BeliBatubara />} />
          <Route exact path="/beli-nikel" element={!islogin ? <NonLoginRedirect /> : <BeliNikel />} />
          <Route exact path="/beli-tongkang" element={!islogin ? <NonLoginRedirect /> : <BeliTongkang />} />
          <Route exact path="/sewa-tongkang" element={!islogin ? <NonLoginRedirect /> : <SewaTongkang />} />
          <Route exact path="/invite-staff" element={!islogin ? <NonLoginRedirect /> : <Company />} />
          <Route exact path="/claim-perusahaan" element={!islogin ? <NonLoginRedirect /> : <Company />} />
          <Route exact path="/edit-perusahaan" element={!islogin ? <NonLoginRedirect /> : <EditCompany />} />
          <Route exact path="/buat-perusahaan" element={!islogin ? <NonLoginRedirect /> : <CreateCompany />} />
          <Route exact path="/jual-batubara" element={!islogin ? <NonLoginRedirect /> : <JualBatubara />} />
          <Route exact path="/jual-nikel" element={!islogin ? <NonLoginRedirect /> : <JualNikel />} />
          <Route exact path="/jual-tongkang" element={!islogin ? <NonLoginRedirect /> : <JualTongkang />} />
          <Route exact path="/tongkang-disewakan" element={!islogin ? <NonLoginRedirect /> : <MenyewakanTongkang />} />
          <Route exact path="/resetpassword" element={<ResetPassword />} />
          <Route exact path="/signin" element={<Signin />} />
          <Route exact path="/forgetpassword" element={<Signin />} />
          <Route exact path="/signup" element={<Signup />} />
          <Route exact path="/icirealtimeprice" element={<Realtimepricepage />} />
        </Routes>
      </GlobalState.Provider>
    </Router>

  );
}

export default App;
