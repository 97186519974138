import Divider from "@material-ui/core/Divider";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Button, Grid, Hidden, Stack, Typography } from "@mui/material";
import * as React from 'react';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-multi-carousel/lib/styles.css";
import { useLocation } from 'react-router-dom';
import filtericon from "../assets/filter-ic.png";
import searchIcon from "../assets/search-explore-ic.png";
import CardListCoal from "../components/CardListCoal";
import CardListNikel from "../components/CardListNikel";
import CardTongkangListDefault from "../components/CardTongkangListDefault";
import DrawerFilterCoal from "../components/DrawerFilterCoal";
import DrawerFilterNikel from "../components/DrawerFilterNikel";
import DrawerFilterTongkang from "../components/DrawerFilterTongkang";
import Footer from "../components/Footer";
import MenuFilterCoal from "../components/MenuFilterCoal";
import MenuFilterNikel from "../components/MenuFilterNikel";
import MenuFilterTongkang from "../components/MenuFilterTongkang";
import NavBar from "../components/Navbar";
import SearchBarExplore from "../components/SearchbarExplore";
import TabelCoal from "../components/TableCoal";
import TabelNikel from "../components/TableNikel";
import { getListingBatubara, getListingNikel, getListingTongkang } from "../services/api";

function Listingpage() {
  const location = useLocation();
  const [searchValue, setSearchValue] = useState(location.state?.search === undefined ? "" : location.state?.search)
  const [tongkangList, setTongkangList] = useState([]);
  const [coalList, setCoalList] = useState([]);
  const [nickelList, setNickelList] = useState([]);
  const [filterBatubara, setFilterBatubara] = useState({
    page: 1,
    min_calorie: '', max_calorie: '', min_total_sulfur: '', max_total_sulfur: '', min_ash: '', id_listing_status: "",
    max_ash: '', quantity: '', min_price: '', max_price: '', search_text: ""
  });
  const [filterNikel, setFilterNikel] = useState({
    page: 1,
    min_kadar_ni: '', max_kadar_ni: '', min_kadar_air: '', max_kadar_air: '', min_kadar_fe: '', max_kadar_fe: '', id_listing_status: "",
    min_si_mg_ratio: '', max_si_mg_ratio: '', quantity: '', min_price: '', max_price: '', id_location: "", search_text: ""
  })
  const [filterTongkang, setFilterTongkang] = useState({
    page: 1,
    type_tongkang: "", ukuran_tongkang: '', max_price: '', min_price: '',
    bendera: "", tahun_buat: "", search_text: "", id_listing_status: ""
  })

  const [filterSewaTongkang, setFilterSewaTongkang] = useState({
    page: 1,
    tipe_sewa: "", ukuran_tongkang: '', max_price: '', min_price: '',
    bendera: "", search_text: "", pelabuhan_muat: "", pelabuhan_bongkar: ""
  })
  const [modalCoalFilter, setmodalCoalFilter] = React.useState(null)
  const [modalNikelFilter, setmodalNikelFilter] = React.useState(null)
  const [modalTongkangFilter, setmodalTongkangFilter] = React.useState(null)
  const [inputukurangtongkang, setinputukurantongkang] = React.useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)
  const [currentPageNikel, setCurrentPageNikel] = useState(1)
  const [pageCountNikel, setPageCountNikel] = useState(1)
  const [currentPageBarge, setCurrentPageBarge] = useState(1)
  const [pageCountBarge, setPageCountBarge] = useState(1)
  const [totalBatubara, setTotalBatubara] = useState('')
  const [totalNikel, setTotalNikel] = useState('')
  const [totalTongkang, setTotalTongkang] = useState('')

  useEffect(() => {
    getCoalList(filterBatubara);
    getNickelList(filterNikel);
    getTongkangList(filterTongkang);
  }, []);

  const [activeTab, setActiveTab] = useState(location.state?.detail === undefined ? "coal" : location.state?.detail);
  //  Functions to handle Tab Switching
  const handleTab = (stateTab) => {
    // update the state to Tab
    setActiveTab(stateTab);
    setFilterBatubara({ ...filterBatubara })
    getCoalList(filterBatubara)
    setFilterNikel({ ...filterNikel })
    getNickelList(filterNikel)
    setFilterTongkang({ ...filterTongkang })
    getTongkangList(filterTongkang)
  };

  const [translate,] = useTranslation("global");

  const handleFilterBatubara = (e, y, option) => {
    filterBatubara["page"] = 1
    if (option === 'mobile') {
      filterBatubara[y] = e
      setFilterBatubara({ ...filterBatubara })
    } else {
      filterBatubara[y] = e
      setFilterBatubara({ ...filterBatubara })
      getCoalList(filterBatubara)
    }
  }

  const handleFilterNikel = (e, y, option) => {
    filterNikel['page'] = 1
    if (option === 'mobile') {
      filterNikel[y] = e
      setFilterNikel({ ...filterNikel })
    } else {
      filterNikel[y] = e
      setFilterNikel({ ...filterNikel })
      getNickelList(filterNikel)
    }
  }

  const handleFilterTongkang = (e, y, option) => {
    filterTongkang['page'] = 1
    if (e !== 0) {
      setinputukurantongkang(false)
      if (option === 'mobile') {
        filterTongkang[y] = e
        setFilterTongkang({ ...filterTongkang })
      } else {
        filterTongkang[y] = e
        setFilterTongkang({ ...filterTongkang })
        getTongkangList(filterTongkang)
      }
    } else {
      setinputukurantongkang(true)
    }
  }

  const handleFilterSewaTongkang = (e, y) => {
    filterSewaTongkang["page"] = 1
    filterSewaTongkang[y] = e
    setFilterSewaTongkang({ ...filterSewaTongkang })
  }

  async function getTongkangList(event, searchValueQ) {
    try {
      if (searchValueQ !== undefined) {
        event["search_text"] = searchValueQ
      } else {
        event["search_text"] = searchValue
      }
      if (event.page === 1) {
        setTongkangList([])
        const response = await getListingTongkang(event)
        setTongkangList(response.data?.data.data.data);
        setTotalTongkang(response.data?.data?.totalItemTongkang)
        setCurrentPageBarge(response.data?.data?.data?.currentPage)
        setPageCountBarge(response.data?.data?.data?.pageCount)
      } else {
        const response = await getListingTongkang(event)
        setTotalTongkang(response.data?.data?.totalItemTongkang)
        setCurrentPageBarge(response.data?.data?.data?.currentPage)
        setPageCountBarge(response.data?.data?.data?.pageCount)
        let dataArray = response.data?.data?.data?.data
        let oldArray = tongkangList
        let newArray = oldArray.concat(dataArray)
        const result = newArray.reduce((unique, o) => {
          if (!unique.some(obj => obj.product_id === o.product_id && obj.code_product === o.code_product)) {
            unique.push(o);
          }
          return unique;
        }, []);
        setTongkangList(result)
      }

    } catch (e) {
      console.log(e);
    }
  }

  async function getCoalList(event, searchValueQ) {
    try {
      if (searchValueQ !== undefined) {
        event["search_text"] = searchValueQ
      } else {
        event["search_text"] = searchValue
      }
      if (event.page === 1) {
        setCoalList([])
        const response = await getListingBatubara(event)
        setCoalList(response.data?.data?.data?.data);
        setTotalBatubara(response.data?.data?.totalItemBatubara)
        setCurrentPage(response.data?.data?.data?.currentPage)
        setPageCount(response.data?.data?.data?.pageCount)
      } else {
        const response = await getListingBatubara(event)
        setTotalBatubara(response.data?.data?.totalItemBatubara)
        setCurrentPage(response.data?.data?.data?.currentPage)
        setPageCount(response.data?.data?.data?.pageCount)
        let dataArray = response.data?.data?.data?.data

        let oldArray = coalList
        let newArray = oldArray.concat(dataArray)
        const result = newArray.reduce((unique, o) => {
          if (!unique.some(obj => obj.product_id === o.product_id && obj.code_product === o.code_product)) {
            unique.push(o);
          }
          return unique;
        }, []);
        setCoalList(result)
      }
    } catch (e) {
      console.log(e);
    }
  }

  const handleFilterUkuranTongkang = (e, y, option) => {

    if (option === 'mobile') {
      filterTongkang[y] = e
      setFilterTongkang({ ...filterTongkang })
    } else {
      filterTongkang[y] = e
      setFilterTongkang({ ...filterTongkang })
      getTongkangList(filterTongkang)
    }
  }

  async function getNickelList(event, searchValueQ) {
    try {
      if (searchValueQ !== undefined) {
        event["search_text"] = searchValueQ
      } else {
        event["search_text"] = searchValue
      }
      if (event.page === 1) {
        setNickelList([])
        const response = await getListingNikel(event)
        setNickelList(response.data?.data?.data?.data);
        setTotalNikel(response.data?.data?.totalItemNikel)
        setCurrentPageNikel(response.data?.data?.data?.currentPage)
        setPageCountNikel(response.data?.data?.data?.pageCount)
      } else {
        const response = await getListingNikel(event)

        setTotalNikel(response.data?.data?.totalItemNikel)
        setCurrentPageNikel(response.data?.data?.data?.currentPage)
        setPageCountNikel(response.data?.data?.data?.pageCount)
        let dataArray = response.data?.data?.data?.data

        let oldArray = nickelList
        let newArray = oldArray.concat(dataArray)
        const result = newArray.reduce((unique, o) => {
          if (!unique.some(obj => obj.product_id === o.product_id && obj.code_product === o.code_product)) {
            unique.push(o);
          }
          return unique;
        }, []);
        setNickelList(result);
      }

    } catch (e) {
      console.log(e);
    }
  }

  // const newListingNickel = nickelList.data !== undefined ? nickelList.data.data : ""
  function resetFilterBatubara(e) {
    const default_value = {
      page: 1,
      min_calorie: '', max_calorie: '', min_total_sulfur: '', max_total_sulfur: '', min_ash: '', id_listing_status: "",
      max_ash: '', quantity: '', min_price: '', max_price: '', search_text: ""
    }
    setFilterBatubara(default_value)
    if (e !== 'mobile') {
      getCoalList(default_value)
    }
  }

  function resetFilterNikel(e) {
    const default_value = {
      page: 1,
      min_kadar_ni: '', max_kadar_ni: '', min_kadar_air: '', max_kadar_air: '', min_kadar_fe: '', max_kadar_fe: '', id_listing_status: "",
      min_si_mg_ratio: '', max_si_mg_ratio: '', quantity: '', min_price: '', max_price: '', id_location: "", search_text: ""
    }
    setFilterNikel(default_value)
    if (e !== 'mobile') {
      getNickelList(default_value)
    }
  }

  function resetFilterTongkang(e) {
    const default_value = {
      page: 1,
      type_tongkang: "", ukuran_tongkang: '', max_quantity_muatan: '', min_quantity_muatan: '', max_price: '', min_price: '',
      bendera: "", tahun_buat: "", search_text: "", id_listing_status: ""
    }
    setFilterTongkang(default_value)
    if (e !== 'mobile') {
      getTongkangList(default_value)
    }
  }

  const handleSearchValue = (e) => {
    if (e === '') {
      filterBatubara["page"] = 1
      filterNikel["page"] = 1
      filterTongkang["page"] = 1
      getCoalList(filterBatubara, '');
      getNickelList(filterNikel, '');
      getTongkangList(filterTongkang, '');
      setSearchValue('')
    } else {
      setSearchValue(e)
    }
  };

  async function setFavoriteBatubara() {
    setTimeout(() => {
      getCoalList(filterBatubara)
    }, 150);
  }

  async function setFavoriteNikel() {
    setTimeout(() => {
      getNickelList(filterNikel)
    }, 150);
  }

  async function setFavoriteTongkang() {
    setTimeout(() => {
      getTongkangList(filterTongkang)
    }, 150);
  }
  const handleOpenModalFilter = (state) => {
    if (state === 'coal') {
      setmodalCoalFilter(state);
    } else if (state === 'nikel') {
      setmodalNikelFilter(state)
    } else if (state === 'tongkang') {
      setmodalTongkangFilter(state)
    }
  };

  const handleKeyDown = (event, value) => {
    if (event.key === 'Enter') {
      filterBatubara["page"] = 1
      filterNikel["page"] = 1
      filterTongkang["page"] = 1
      setSearchValue(value)
      event.target.blur();
      // 👇 Get input value
      getCoalList(filterBatubara, value);
      getNickelList(filterNikel, value);
      getTongkangList(filterTongkang, value);
    }
  };

  function Filter(e) {
    if (e === 'coal') {
      setFilterBatubara({ ...filterBatubara })
      getCoalList(filterBatubara)
    } else if (e === 'nikel') {
      setFilterNikel({ ...filterNikel })
      getNickelList(filterNikel)
    } else if (e === 'tongkang') {
      setFilterTongkang({ ...filterTongkang })
      getTongkangList(filterTongkang)
    }
  }

  const handleCloseModalFilter = () => {
    setmodalCoalFilter(null);
    setmodalNikelFilter(null);
    setmodalTongkangFilter(null);
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'auto'
      /* you can also use 'auto' behaviour 
         in place of 'smooth' */
    });
  }


  return (
    <div>
      <div className='fixed-header'>
        <NavBar></NavBar>
      </div>
      <Box className="flex-wrap-center width-100" >
        <Box className="content-inner" sx={{ mt: { xs: 6, sm: 6, md: 11, lg: 11, xl: 11 }, ml: { xs: '24px', lg: '80px', }, mr: { xs: '24px', lg: '80px', }, }}>
          <Grid
            sx={{ mt: '16px', }}
            container
            direction="row"
            alignItems={'center'}
          >
            <Button href="/" className="w-500" sx={{ fontSize: 16, mb: 1, color: '#2457B9', textTransform: 'none', pl: 0 }} variant="text">   {translate('my-listing.home')}</Button>

            <Typography className="w-500" sx={{ fontSize: 16, mb: 1, mr: 1, color: '#56617B' }}>
              /
            </Typography>
            <Typography className="w-500" sx={{ fontSize: 16, mb: 1 }}>
              {translate('my-listing.mylisting')}
            </Typography>
          </Grid>
          <Grid
            container
            direction="row"
            spacing={4}
            sx={{ mb: 2 }}
          >
            {/* leftside Section */}
            <Grid item xs={12} lg={2.8}>
              <Typography className="w-700" sx={{ fontSize: 32, mb: { xs: 0, lg: '16px', sm: 0, md: '16px', xl: '16px' } }}>
                {translate('my-listing.mylisting')}
              </Typography>
              {/* filter Coal Section */}
              {activeTab === 'coal' ?
                <Hidden lgDown>
                  <MenuFilterCoal page={'listing'} resetFilterBatubara={resetFilterBatubara} handleFilterBatubara={handleFilterBatubara} filterBatubara={filterBatubara}></MenuFilterCoal>
                </Hidden> : null}
              {/* filter Nikel Section */}
              {activeTab === 'nikel' ? <Hidden lgDown>
                <MenuFilterNikel page={'listing'} resetFilterNikel={resetFilterNikel} handleFilterNikel={handleFilterNikel} filterNikel={filterNikel}></MenuFilterNikel>
              </Hidden> : null}
              {/* filter Tongkang Section */}
              {activeTab === 'tongkang' ? <Hidden lgDown>
                <MenuFilterTongkang page={'listing'} handleFilterUkuranTongkang={handleFilterUkuranTongkang} resetFilterTongkang={resetFilterTongkang} handleFilterTongkang={handleFilterTongkang} filterTongkang={filterTongkang} handleFilterSewaTongkang={handleFilterSewaTongkang} filterSewaTongkang={filterSewaTongkang} inputukurangtongkang={inputukurangtongkang}></MenuFilterTongkang>
              </Hidden> : null}
            </Grid>

            {/* rightside Section */}
            <Grid item xs={12} lg={9.2}>
              {/* Tab Button Section */}
              <div style={{ marginBottom: '22px' }} className="tab-scroll-x">
                <Stack direction="row" alignItems={'center'}>
                  <Button
                    className="w-500"
                    sx={{
                      height: '40px',
                      p: '8px 12px',
                      lineHeight: '22px',
                      width: { xs: '100%', sm: 'fit-content' }, borderRadius: '8px',
                      background: activeTab === 'coal' ? "#081130" : '', textTransform: 'none',
                      color: activeTab === 'coal' ? "white" : '#081130', fontSize: 16, "&:hover": {
                        backgroundColor: activeTab === 'coal' ? "#081130" : "#56617B"
                      }
                    }}
                    onClick={() => {
                      handleTab("coal");
                    }}
                    variant={activeTab === 'coal' ? "contained" : 'text'}
                  >
                    {translate('page_explore.batubara')}
                    <Box
                      sx={{
                        background: activeTab === 'coal' ? "white" : '#D1D7E6',
                        borderRadius: '6px',
                        p: '4px',
                        ml: '8px',
                      }}
                    >
                      <Typography
                        className="w-500"
                        sx={{ color: activeTab === 'coal' ? "#081130" : '#56617B', fontSize: 12, lineHeight: 'normal' }}
                      >
                        {totalBatubara}

                      </Typography>
                    </Box>
                  </Button>
                  <Divider orientation="vertical" style={{ marginTop: 10, marginBottom: 10, marginLeft: 10, marginRight: 10 }} flexItem />
                  <Button
                    className='w-500'
                    onClick={() => {
                      handleTab("nikel");
                    }}
                    sx={{
                      height: '40px',
                      p: '8px 12px',
                      lineHeight: '22px',
                      width: { xs: '100%', sm: 'fit-content' }, borderRadius: '8px',
                      background: activeTab === 'nikel' ? "#081130" : '', textTransform: 'none',
                      color: activeTab === 'nikel' ? "white" : '#081130', fontSize: 16, "&:hover": {
                        backgroundColor: activeTab === 'nikel' ? "#081130" : "#56617B"
                      }
                    }}
                    variant={activeTab === 'nikel' ? "contained" : 'text'}
                  >
                    {translate('page_explore.nikel')}
                    <Box
                      sx={{
                        background: activeTab === 'nikel' ? "white" : '#D1D7E6',
                        borderRadius: '6px',
                        p: '4px',
                        ml: '8px',
                      }}
                    >
                      <Typography
                        className="w-500"
                        sx={{ color: activeTab === 'nikel' ? "#081130" : '#56617B', fontSize: 12, lineHeight: 'normal' }}
                      >
                        {totalNikel}

                      </Typography>
                    </Box>
                  </Button>
                  <Divider orientation="vertical" style={{ marginTop: 10, marginBottom: 10, marginLeft: 10, marginRight: 10 }} flexItem />
                  <Button
                    className='w-500'
                    onClick={() => {
                      handleTab("tongkang");
                    }}
                    sx={{
                      height: '40px',
                      p: '8px 12px',
                      borderRadius: '8px',
                      lineHeight: '22px',
                      width: { xs: '100%', sm: 'fit-content' },
                      background: activeTab === 'tongkang' ? "#081130" : '', textTransform: 'none',
                      color: activeTab === 'tongkang' ? "white" : '#081130', fontSize: 16, "&:hover": {
                        backgroundColor: activeTab === 'tongkang' ? "#081130" : "#56617B"
                      }
                    }}
                    variant={activeTab === 'tongkang' ? "contained" : 'text'}
                  >
                    {translate('page_explore.tongkang')}
                    <Box
                      sx={{
                        background: activeTab === 'tongkang' ? "white" : '#D1D7E6',
                        borderRadius: '6px',
                        p: '4px',
                        ml: '8px',
                      }}
                    >
                      <Typography
                        className="w-500"
                        sx={{ color: activeTab === 'tongkang' ? "#081130" : '#56617B', fontSize: 12, lineHeight: 'normal' }}
                      >
                        {totalTongkang}

                      </Typography>
                    </Box>

                  </Button>
                </Stack>
              </div>
              {/* Search Section */}
              <Box className="test-search" sx={{ width: { lg: '1000px', xs: '100%' } }}>
                <Stack justifyContent={'space-between'} direction="row" sx={{ mb: 2, pt: 0 }} alignItems='center'  >
                  {activeTab === 'coal' ?
                    <Grid item className="searchtrader-explore-page" xs>
                      <SearchBarExplore text={translate('page_explore.search-batubara')} searchValue={searchValue} handleSearchValue={handleSearchValue} handleKeyDown={handleKeyDown} searchIcon={searchIcon} />

                    </Grid> : ''}

                  {activeTab === 'nikel' ?
                    <Grid item className="searchtrader-explore-page" xs>
                      <SearchBarExplore text={translate('page_explore.search-nikel')} searchValue={searchValue} handleSearchValue={handleSearchValue} handleKeyDown={handleKeyDown} searchIcon={searchIcon} />

                    </Grid> : ''}

                  {activeTab === 'tongkang' ?
                    <Grid item className="searchtrader-explore-page" xs>
                      <SearchBarExplore text={translate('page_explore.search-tongkang')} searchValue={searchValue} handleSearchValue={handleSearchValue} handleKeyDown={handleKeyDown} searchIcon={searchIcon} />

                    </Grid> : ''}

                  {activeTab === 'coal' ? <Hidden lgUp>
                    <Grid item >
                      <img onClick={() => {
                        handleOpenModalFilter("coal");
                      }} alt="filter-icon" src={filtericon} height={42} width={42}></img>
                    </Grid>
                  </Hidden> : null}
                  {activeTab === 'nikel' ? <Hidden lgUp>
                    <Grid item >
                      <img onClick={() => {
                        handleOpenModalFilter("nikel");
                      }} alt="filter-icon" src={filtericon} height={42} width={42}></img>
                    </Grid>
                  </Hidden> : null}
                  {activeTab === 'tongkang' ? <Hidden lgUp>
                    <Grid item >
                      <img onClick={() => {
                        handleOpenModalFilter("tongkang");
                      }} alt="filter-icon" src={filtericon} height={42} width={42}></img>
                    </Grid>
                  </Hidden> : null}

                </Stack>


                {activeTab === 'coal' ?
                  <Box sx={{ mb: 2 }}>
                    <TabelCoal page={'listing'} getCoalList={(e) => setFavoriteBatubara()} coalList={coalList} ></TabelCoal>
                  </Box> : null}
                {activeTab === 'coal' ?
                  <Hidden lgUp>
                    <Grid columnSpacing={2.5} container direction={'row'}>
                      {(coalList && coalList.length > 0) ?
                        coalList?.map(data => (
                          <CardListCoal key={data.product_id} page={'listing'} getCoalList={(e) => setFavoriteBatubara()} coalList={data} />
                        ))
                        :
                        <p className="w-500 ml-22" >  {translate('permintaan.datanotfound')}</p>
                      }
                    </Grid>

                  </Hidden> : null}
                {activeTab === 'nikel' ?
                  <Box sx={{ mb: 2 }}>
                    <TabelNikel page={'listing'} nickelList={nickelList} getNikel={(e) => setFavoriteNikel()}></TabelNikel>
                  </Box> : null}
                {activeTab === 'nikel' ?
                  <Hidden lgUp>
                    <Grid columnSpacing={2.5} container direction={'row'}>
                      {(nickelList && nickelList.length > 0) ?
                        nickelList?.map(data => (
                          <CardListNikel key={data.product_id} page={'listing'} nickelList={data} getNikel={(e) => setFavoriteNikel()} />
                        ))
                        :
                        <p className="w-500 ml-22" >  {translate('permintaan.datanotfound')}</p>
                      }
                    </Grid>
                  </Hidden> : null}
                {activeTab === 'tongkang' ?
                  <Box sx={{ mb: 2 }}>
                    <Grid container direction={'row'} spacing={3}>
                      {(tongkangList && tongkangList.length > 0) ?
                        tongkangList?.map(tongkang => (
                          <Grid item xs={12} sm={6} md={4}>
                            <CardTongkangListDefault page={'listing'} key={tongkang.product_id} data={tongkang} getTongkang={(e) => setFavoriteTongkang()}></CardTongkangListDefault>
                          </Grid>
                        ))
                        :
                        <p className="w-500 ml-22 mt-24" >  {translate('permintaan.datanotfound')}</p>
                      }
                    </Grid>
                  </Box> : null}
                {(activeTab === "coal" && coalList !== undefined && (parseInt(pageCount) !== parseInt(currentPage)) && (parseInt(pageCount) > parseInt(currentPage))) &&
                  <Grid sx={{ mt: 2, mb: 8, cursor: 'pointer' }} container justifyContent="center" alignItems="center" direction="row" onClick={(e) => {
                    handleFilterBatubara(filterBatubara["page"] + 1, "page")
                    // setTimeout(() => {
                    //   scrollToBottom()
                    // }, 1000)
                  }}>
                    <Typography
                      className="w-500"
                      sx={{ fontSize: 16, color: "#2457B9", mr: 1 }}
                    >
                      {translate('page_explore.lihatlebihbanyak')}
                    </Typography>
                    <KeyboardArrowDownIcon sx={{ fontSize: 16, color: "#2457B9" }} />
                  </Grid>}
                {(activeTab === "nikel" && nickelList !== undefined && (parseInt(pageCountNikel) !== parseInt(currentPageNikel)) && (parseInt(pageCountNikel) > parseInt(currentPageNikel))) &&
                  <Grid sx={{ mt: 2, mb: 8, cursor: 'pointer' }} container justifyContent="center" alignItems="center" direction="row" onClick={(e) => {
                    handleFilterNikel(filterNikel["page"] + 1, "page")
                    // setTimeout(() => {
                    //   scrollToBottom()
                    // }, 1000)
                  }}>
                    <Typography
                      className="w-500"
                      sx={{ fontSize: 16, color: "#2457B9", mr: 1 }}
                    >
                      {translate('page_explore.lihatlebihbanyak')}
                    </Typography>
                    <KeyboardArrowDownIcon sx={{ fontSize: 16, color: "#2457B9" }} />
                  </Grid>}
                {(activeTab === "tongkang" && tongkangList !== undefined && (parseInt(pageCountBarge) !== parseInt(currentPageBarge)) && (parseInt(pageCountBarge) > parseInt(currentPageBarge))) &&
                  <Grid sx={{ mt: 2, mb: 8, cursor: 'pointer' }} container justifyContent="center" alignItems="center" direction="row" onClick={(e) => {
                    handleFilterTongkang(filterTongkang["page"] + 1, "page")
                    // setTimeout(() => {
                    //   scrollToBottom()
                    // }, 1000)
                  }}>
                    <Typography
                      className="w-500"
                      sx={{ fontSize: 16, color: "#2457B9", mr: 1 }}
                    >
                      {translate('page_explore.lihatlebihbanyak')}
                    </Typography>
                    <KeyboardArrowDownIcon sx={{ fontSize: 16, color: "#2457B9" }} />
                  </Grid>}
              </Box>
            </Grid>
            {/* modal filter  Responsive Section */}
            <Hidden lgUp>
              {/* modal filter Coal Responsive Section */}
              {activeTab === 'coal' && <DrawerFilterCoal page={'listing'} open={modalCoalFilter} close={handleCloseModalFilter} resetFilterBatubara={resetFilterBatubara} handleFilterBatubara={handleFilterBatubara} filterBatubara={filterBatubara} handleCloseModalFilter={handleCloseModalFilter} Filter={Filter}>
              </DrawerFilterCoal>}
              {/* modal filter Nikel Responsive Section */}
              {activeTab === 'nikel' && <DrawerFilterNikel page={'listing'} open={modalNikelFilter} close={handleCloseModalFilter} resetFilterNikel={resetFilterNikel} handleFilterNikel={handleFilterNikel} filterNikel={filterNikel} handleCloseModalFilter={handleCloseModalFilter} Filter={Filter}>
              </DrawerFilterNikel>}
              {/* modal filter Tongkang Responsive Section */}
              {activeTab === 'tongkang' && <DrawerFilterTongkang page={'listing'} open={modalTongkangFilter} close={handleCloseModalFilter} resetFilterTongkang={resetFilterTongkang} handleFilterTongkang={handleFilterTongkang} filterTongkang={filterTongkang} handleFilterSewaTongkang={handleFilterSewaTongkang} handleFilterUkuranTongkang={handleFilterUkuranTongkang} handleCloseModalFilter={handleCloseModalFilter} Filter={Filter} inputukurangtongkang={inputukurangtongkang}>
              </DrawerFilterTongkang>}
            </Hidden>
          </Grid>
        </Box>
      </Box>
      <Footer></Footer>
    </div >
  )
}

export default Listingpage
