import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Hidden, IconButton, Modal, Stack, Typography } from "@mui/material";
import React from 'react';
import { useTranslation } from "react-i18next";
import CompanyListing from "../assets/company-listing.svg";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: 343, lg: 500 },
    borderRadius: '8px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    paddingBottom: '25px',
    "&:focus": {
        outline: "none"
    },
};

export default function ModalBatalForm(props) {
    const [translate,] = useTranslation("global");
    return (
        <div>
            <Modal
                open={props.open}
                onClose={props.close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ zIndex: 100000 }}
            >
                <Box sx={style}>

                    <Box sx={{ display: 'flex', justifyContent: 'right', mr: '16px', mt: '16px' }}>
                        <IconButton onClick={() => {
                            props.close();
                        }} size="small" >
                            <CloseIcon sx={{ width: '24px', height: '24px' }} />
                        </IconButton>
                    </Box>

                    <Box sx={{ justifyContent: 'center', display: 'flex' }}>
                        <img alt="listing-icon" style={{ width: { xs: '100px', lg: '120px' }, height: { xs: '100px', lg: '120px' }, }} src={CompanyListing}></img>
                    </Box>

                    <Box sx={{ justifyContent: 'center', display: 'flex' }}>
                        <Typography className='w-700' sx={{ mb: { xs: '16px', lg: '24px' }, mt: 2, width: { xs: '300px', lg: '342px' }, fontSize: '18px', color: '#081130', textAlign: 'center' }}>
                            {translate('status.titlebatallisting')}
                        </Typography>
                    </Box>

                    <Box sx={{ ml: { xs: '21px', lg: '41px' }, mr: { xs: '21px', lg: '41px' }, mb: '29' }}>
                        <Hidden smDown>
                            <Stack direction={'row'} spacing={2}>
                                <Button href='/' className='button-blue-outlined' onClick={() => {
                                    props.close();
                                }} variant="outlined" sx={{ p: '12px 16px', height: { xs: '44px', lg: '48px' }, width: { xs: '148px', lg: '200px' } }}>
                                    <Typography className='w-500' sx={{ height: '22px', fontSize: { xs: '14px', lg: '16px' }, color: '#2457B9', textAlign: 'center', }}>
                                        {translate('status.batalpembuatan')}
                                    </Typography>
                                </Button>
                                <Button className='button-blue-contained' onClick={() => {
                                    props.close();

                                }} variant="contained" sx={{ p: '12px 16px', height: { xs: '44px', lg: '48px' }, width: { xs: '148px', lg: '200px' } }}>
                                    <Typography className='w-500' sx={{ height: '22px', fontSize: { xs: '14px', lg: '16px' }, color: '#FFFFFF', textAlign: 'center' }}>
                                        {translate('status.lanjutpembuatan')}
                                    </Typography>
                                </Button>
                            </Stack>
                        </Hidden>
                        <Hidden smUp>
                            <Stack direction={'row'} spacing={2}>
                                <Button href='/' className='button-blue-outlined' onClick={() => {
                                    props.close();
                                }} variant="outlined" sx={{ p: '12px 16px', height: { xs: '44px', lg: '48px' }, width: { xs: '148px', lg: '200px' } }}>
                                    <Typography className='w-500' sx={{ height: '22px', fontSize: { xs: '14px', lg: '16px' }, color: '#2457B9', textAlign: 'center', }}>
                                        {translate('jual-beli.btn-cancel')}
                                    </Typography>
                                </Button>
                                <Button className='button-blue-contained' onClick={() => {
                                    props.close();

                                }} variant="contained" sx={{ p: '12px 16px', height: { xs: '44px', lg: '48px' }, width: { xs: '148px', lg: '200px' } }}>
                                    <Typography className='w-500' sx={{ height: '22px', fontSize: { xs: '14px', lg: '16px' }, color: '#FFFFFF', textAlign: 'center' }}>
                                        {translate('status.lanjut')}
                                    </Typography>
                                </Button>
                            </Stack>
                        </Hidden>
                    </Box>



                </Box>
            </Modal>

        </div>
    );
}