import Divider from "@material-ui/core/Divider";
import CallIcon from '@mui/icons-material/Call';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Avatar, Box, Button, Card, Grid, Hidden, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-multi-carousel/lib/styles.css";
import { useLocation, useSearchParams } from 'react-router-dom';
import { default as CallCompanyIcon } from "../assets/call-company-ic.png";
import companyIcon from "../assets/company-ic.png";
import filtericon from "../assets/filter-ic.png";
import HeroCompanyImg from "../assets/hero-company.png";
import locationCompanyIcon from "../assets/location-company-ic.png";
import { default as MailCompanyIcon } from "../assets/mail-company-ic.png";
import searchIcon from "../assets/search-explore-ic.png";
import CardCompanyDetail from "../components/CardCompanyDetail";
import CardListCoalExplore from "../components/CardListCoalExplore";
import CardListNikelExplore from "../components/CardListNikelExplore";
import CardTongkangListExplore from "../components/CardTongkangListExplore";
import { DisplayPDFFile } from '../components/DisplayPDF';
import DrawerFilterCoal from "../components/DrawerFilterCoal";
import DrawerFilterLocation from "../components/DrawerFilterLocation";
import DrawerFilterNikel from "../components/DrawerFilterNikel";
import DrawerFilterTongkang from "../components/DrawerFilterTongkang";
import Footer from "../components/Footer";
import MenuFilterCoal from "../components/MenuFilterCoal";
import MenuFilterNikel from "../components/MenuFilterNikel";
import MenuFilterTongkang from "../components/MenuFilterTongkang";
import ModalHubungiTrader from "../components/ModalHubungiTrader";
import ModalShare from "../components/ModalShare";
import NavBar from "../components/Navbar";
import SearchBarExplore from "../components/SearchbarExplore";
import TabelCoal from "../components/TableCoal";
import TabelCompany from "../components/TableCompany";
import TabelNikel from "../components/TableNikel";
import { getDetailTrader, getProvince, getUserCompany, getUserDocument, searchFavoriteBarge, searchFavoriteCoal, searchFavoriteNickel } from "../services/api";
import { config } from "../services/constants";


function DetailTrader() {
  const { state } = useLocation();
  const [location, setLocation] = useState({
    state: {
      id: '',
      detail: ''
    }
  })
  const [isLogin] = useState(localStorage.getItem('token') !== null ? true : false);
  const [modalHubungi, setModalHubungi] = useState(false)
  const [detailTrader, setDetailTrader] = useState([])
  const [position, setPosition] = useState(true)
  const [searchParams,] = useSearchParams();
  const [isOpenModal, setOpenModal] = useState(false)
  const [selectedItems, setSelectedItems] = useState([])
  const [searchValueLocation, setSearchValueLocation] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [anchorElShare, setAnchorElShare] = useState(null);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleOpenShareMenu = (event) => {

    setAnchorElShare(event.currentTarget);
  };
  const handleCloseShareMenu = () => {
    setAnchorElShare(null);
  };

  const handleLocationSumbit = (e) => {
    if (e === "coal") {
      getCoalList(filterBatubara)
      setLimitProvince(4)
      setShowAllCoal(false)
    } else if (e === "nikel") {
      getNickelList(filterNikel)
      setLimitProvince(4)
      setShowAllNikel(false)
    }
  }

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    let type = searchParams.get("type")
    let id_detail = searchParams.get("id_detail")
    getCoalList(filterBatubara, '');
    getNickelList(filterNikel, '');
    getTongkangList(filterTongkang, '');
    getdatauserDocument();
    getDataProvince()
    getdataUserCompany()

    if (state?.detail === 'trader' || type === '5') {
      if (id_detail !== null) {
        setLocation({
          state: {
            id: id_detail,
            detail: 'trader'
          }
        })
        setActiveTab('trader')
        getDataDetailTrader(id_detail)
      } else {
        setLocation({
          state: {
            id: state.id,
            detail: state.detail
          }
        })
        setActiveTab(state.detail)
        getDataDetailTrader(state.id)
      }
    }
  }, [])

  useEffect(() => {
    let type = searchParams.get("type")
    let id_detail = searchParams.get("id_detail")
    getCoalList(filterBatubara, '');
    getNickelList(filterNikel, '');
    getTongkangList(filterTongkang, '');
    getDataProvince()
    getdatauserDocument();
    getdataUserCompany()

    if (state?.detail === 'trader' || type === '5') {
      if (id_detail !== null) {
        setLocation({
          state: {
            id: id_detail,
            detail: 'trader'
          }
        })
        setActiveTab('trader')
        getDataDetailTrader(id_detail)
      } else {
        setLocation({
          state: {
            id: state.id,
            detail: state.detail
          }
        })
        setActiveTab(state.detail)
        getDataDetailTrader(state.id)
      }
    }
  }, [localStorage.getItem('lang')])

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler)
    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
  })

  const scrollHandler = (event) => {
    let offset = window.pageYOffset
    if (offset > 200) {
      setPosition(true)
    } else {
      setPosition(false)
    }
  };

  function Filter(e) {
    if (e === 'coal') {
      setFilterBatubara({ ...filterBatubara })
      getCoalList(filterBatubara)
    } else if (e === 'nikel') {
      setFilterNikel({ ...filterNikel })
      getNickelList(filterNikel)
    } else if (e === 'tongkang') {
      setFilterTongkang({ ...filterTongkang })
      getTongkangList(filterTongkang)
    }
  }

  const handleKeyDown = (event, value) => {
    if (event.key === 'Enter') {
      filterBatubara["page"] = 1
      filterNikel["page"] = 1
      filterTongkang["page"] = 1
      setSearchValue(value)
      event.target.blur();
      // 👇 Get input value
      getCoalList(filterBatubara, value);
      getNickelList(filterNikel, value);
      getTongkangList(filterTongkang, value);
    }
  };

  const handleKeyDownLocation = (event, action) => {
    if (event.key === 'Enter') {
      event.target.blur();
      // 👇 Get input value
      getDataProvince(searchValueLocation);
    }
  };

  const getDataDetailTrader = async (id) => {
    try {
      let lang = localStorage.getItem('lang') === 'null' || localStorage.getItem('lang') === 'id' ? 'id' : localStorage.getItem('lang')
      const responAPI = await getDetailTrader(id, lang)
      setDetailTrader(responAPI.data.data)
    } catch (error) {
      setDetailTrader(state.data)
      console.log(error)
    }
  }

  const [activeTab, setActiveTab] = useState(location.state?.detail);
  const [activeTabTrader, setActiveTabTrader] = useState('profile');
  const [activeTabFavorit, setActiveTabFavorit] = useState('coal');
  const [translate,] = useTranslation("global");
  const [modalCoalFilter, setmodalCoalFilter] = React.useState(null)
  const [modalCoalLocation, setmodalCoalLocation] = React.useState(null)
  const [modalNikelFilter, setmodalNikelFilter] = React.useState(null)
  const [modalNikelLocation, setmodalNikelLocation] = React.useState(null)
  const [modalTongkangFilter, setmodalTongkangFilter] = React.useState(null)
  const [coalFavoriteList, setCoalFavoriteList] = useState([]);
  const [nickelFavoriteList, setNickelFavoriteList] = useState([]);
  const [tongkangFavoriteList, setTongkangFavoriteList] = useState([]);
  const [tongkangFavoriteList_, setTongkangFavoriteList_] = useState([]);
  const [dataProvince, setDataProvince] = useState([]);
  const [dataUserCompany, setdataUserCompany] = useState([]);
  const [dataUserDocument, setdataUserDocument] = useState([]);
  const [showAllCoal, setShowAllCoal] = useState(false)
  const [showAllNikel, setShowAllNikel] = useState(false)
  const [limitProvince, setLimitProvince] = useState(4)
  const [locationId, setLocationId] = useState([])
  const newNickelList = nickelFavoriteList.data
  const [filterBatubara, setFilterBatubara] = useState({
    page: 1, user_id: state?.id,
    min_calorie: "", max_calorie: "", min_total_sulfur: "", max_total_sulfur: "", min_ash: "",
    max_ash: "", quantity: "", min_price: "", max_price: "", id_location: [], search_text: `${searchValue}`
  });

  const [filterNikel, setFilterNikel] = useState({
    page: 1, user_id: state?.id,
    min_kadar_ni: "", max_kadar_ni: "", min_kadar_air: "", max_kadar_air: "", min_kadar_fe: "", max_kadar_fe: "",
    min_si_mg_ratio: "", max_si_mg_ratio: "", quantity: "", min_price: "", max_price: "", id_location: [], search_text: `${searchValue}`
  })
  const [filterTongkang, setFilterTongkang] = useState({
    page: 1, user_id: state?.id,
    type_tongkang: "", ukuran_tongkang: "", max_quantity_muatan: "", min_quantity_muatan: "", max_price: "", min_price: "",
    bendera: "", tahun_buat: "", search_text: `${searchValue}`
  })

  //  Functions to handle Tab Switching
  const handleTabFavorit = (state) => {
    setActiveTabFavorit(state);
    setLimitProvince(4)
    setLocationId([])
  };

  const handleTabTrader = (state) => {
    setActiveTabTrader(state);
  };

  const handleSearchValue = (e) => {
    if (e === '') {
      filterBatubara["page"] = 1
      filterNikel["page"] = 1
      filterTongkang["page"] = 1
      getCoalList(filterBatubara, '');
      getNickelList(filterNikel, '');
      getTongkangList(filterTongkang, '');
      setSearchValue('')
    } else {
      setSearchValue(e)
    }
  };

  const handleOpenModalFilter = (state) => {
    if (state === 'coal') {
      setmodalCoalFilter(state);
    } else if (state === 'nikel') {
      setmodalNikelFilter(state)
    } else if (state === 'tongkang') {
      setmodalTongkangFilter(state)
    }

  };
  const handleCloseModalFilter = () => {
    setmodalCoalFilter(null);
    setmodalNikelFilter(null);
    setmodalTongkangFilter(null);
  };

  const handleOpenModalLocationResponsive = (state) => {
    if (state === 'coal') {
      setmodalCoalLocation(state);
    } else if (state === 'nikel') {
      setmodalNikelLocation(state)
    }

  };
  const handleCloseModalLocationResponsive = () => {
    setmodalCoalLocation(null);
    setmodalNikelLocation(null);

  };

  async function getDataProvince(name) {
    try {
      const response = await getProvince(name === undefined ? '' : name)
      setDataProvince(response.data?.data);
    } catch (e) {
      console.log(e);
    }
  }

  async function getCoalList(event, searchValueQ) {
    try {
      if (searchValueQ !== undefined) {
        event["search_text"] = searchValueQ
      } else {
        event["search_text"] = searchValue
      }
      const response = await searchFavoriteCoal(event)
      setCoalFavoriteList(response.data?.data);
    } catch (e) {
      console.log(e);
    }
  }

  async function getNickelList(event, searchValueQ) {
    try {
      if (searchValueQ !== undefined) {
        event["search_text"] = searchValueQ
      } else {
        event["search_text"] = searchValue
      }
      const response = await searchFavoriteNickel(event)
      setNickelFavoriteList(response.data?.data);
    } catch (e) {
      console.log(e);
    }
  }

  async function getTongkangList(event, searchValueQ) {
    try {
      if (searchValueQ !== undefined) {
        event["search_text"] = searchValueQ
      } else {
        event["search_text"] = searchValue
      }
      const response = await searchFavoriteBarge(event)
      setTongkangFavoriteList(response.data?.data.data);
      setTongkangFavoriteList_(response.data?.data);
    } catch (e) {
      console.log(e);
    }
  }

  async function setFavoriteBatubara() {
    setTimeout(() => {
      getCoalList(filterBatubara)
    }, 150);
  }

  async function setFavoriteNikel() {
    setTimeout(() => {
      getNickelList(filterNikel)
    }, 150);
  }

  async function setFavoriteTongkang() {
    setTimeout(() => {
      getTongkangList(filterTongkang)
    }, 150);
  }

  // Reset Filter 
  function resetFilterBatubara(e) {
    const default_value = {
      page: 1,
      user_id: state?.id, min_calorie: "", max_calorie: "", min_total_sulfur: "", max_total_sulfur: "", min_ash: "",
      max_ash: "", quantity: "", min_price: "", max_price: "", id_location: "", search_text: ""
    }
    setFilterBatubara(default_value)
    handleLocationReset('coal')
    getCoalList(default_value)
  }
  function resetFilterNikel(e) {
    const default_value = {
      page: 1,
      user_id: state?.id, min_kadar_ni: "", max_kadar_ni: "", min_kadar_air: "", max_kadar_air: "", min_kadar_fe: "", max_kadar_fe: "",
      min_si_mg_ratio: "", max_si_mg_ratio: "", quantity: "", min_price: "", max_price: "", id_location: "", search_text: ""
    }
    setFilterNikel(default_value)
    handleLocationReset('nikel')
    getNickelList(default_value)
  }

  const handleLocationReset = (e) => {
    if (e === "coal") {
      setSelectedItems([])
      filterBatubara["id_location"] = []
      setLocationId([])
      setFilterBatubara({ ...filterBatubara })
      getCoalList(filterBatubara)

    } else if (e === "nikel") {
      setSelectedItems([])
      setLocationId([])
      filterNikel["id_location"] = []
      setFilterNikel({ ...filterNikel })
      getNickelList(filterNikel)
    }
  }

  const handleSearchValueLocation = (e) => {
    if (e === '') {
      getDataProvince('');
      setSearchValueLocation('')
    } else {
      setSearchValueLocation(e)
    }
  };

  function resetFilterTongkang(e) {
    const default_value = {
      page: 1,
      user_id: state?.id, type_tongkang: "", ukuran_tongkang: "", max_quantity_muatan: "", min_quantity_muatan: "", max_price: "", min_price: "",
      bendera: "", tahun_buat: "", search_text: ""
    }
    setFilterTongkang(default_value)
    getTongkangList(default_value)
  }

  const handleFilterBatubara = (e, y, option) => {
    if (y === "location") {
      let isSelected = e.target.checked;
      let value = parseInt(e.target.value);

      if (isSelected) {
        setSelectedItems([...selectedItems, value])
      } else {
        setSelectedItems((prevData) => {
          return prevData.filter((id) => {
            return id !== value
          })
        })
      }

      const check = [...locationId]
      const index = check.indexOf(e.target.value);
      if (index === -1) {
        check.push(e.target.value)
      } else {
        check.splice(index, 1)
      }
      setLocationId(check)
      filterBatubara["id_location"] = check
      setFilterBatubara({ ...filterBatubara })
      getCoalList(filterBatubara)
    } else if (y === "popuplocation") {
      let isSelected = e.target.checked;
      let value = parseInt(e.target.value);
      if (isSelected) {
        setSelectedItems([...selectedItems, value])
      } else {
        setSelectedItems((prevData) => {
          return prevData.filter((id) => {
            return id !== value
          })
        })
      }

      const check = [...locationId]
      const index = check.indexOf(e.target.value);
      if (index === -1) {
        check.push(e.target.value)
      } else {
        check.splice(index, 1)
      }
      setLocationId(check)
      filterBatubara["id_location"] = check
      setFilterBatubara({ ...filterBatubara })
    } else if (y === 'mobile') {
      filterBatubara[option] = e
      setFilterBatubara({ ...filterBatubara })

    } else {
      filterBatubara[y] = e
      setFilterBatubara({ ...filterBatubara })
      getCoalList(filterBatubara)
    }

  }

  const handleFilterNikel = (e, y, option) => {
    if (y === "location") {
      let isSelected = e.target.checked;
      let value = parseInt(e.target.value);
      if (isSelected) {
        setSelectedItems([...selectedItems, value])
      } else {
        setSelectedItems((prevData) => {
          return prevData.filter((id) => {
            return id !== value
          })
        })
      }
      const check = [...locationId]
      const index = check.indexOf(e.target.value);
      if (index === -1) {
        check.push(e.target.value)
      } else {
        check.splice(index, 1)
      }
      setLocationId(check)
      filterNikel["id_location"] = check
      setFilterNikel({ ...filterNikel })
      getNickelList(filterNikel)
    } else if (y === "popuplocation") {
      let isSelected = e.target.checked;
      let value = parseInt(e.target.value);
      if (isSelected) {
        setSelectedItems([...selectedItems, value])
      } else {
        setSelectedItems((prevData) => {
          return prevData.filter((id) => {
            return id !== value
          })
        })
      }

      const check = [...locationId]
      const index = check.indexOf(e.target.value);
      if (index === -1) {
        check.push(e.target.value)
      } else {
        check.splice(index, 1)
      }
      setLocationId(check)
      filterNikel["id_location"] = check
      setFilterNikel({ ...filterNikel })
    } else if (y === 'mobile') {
      filterNikel[option] = e
      setFilterNikel({ ...filterNikel })

    } else {
      filterNikel[y] = e
      setFilterNikel({ ...filterNikel })
      getNickelList(filterNikel)
    }

  }
  const handleFilterTongkang = (e, y, option) => {

    if (e !== 0) {
      setinputukurantongkang(false)
      if (option === 'mobile') {
        filterTongkang[y] = e
        setFilterTongkang({ ...filterTongkang })
      } else {
        filterTongkang[y] = e
        setFilterTongkang({ ...filterTongkang })
        getTongkangList(filterTongkang)
      }
    }
    else {
      setinputukurantongkang(true)
    }
  }

  const handleFilterSewaTongkang = (e, y) => {
    filterSewaTongkang[y] = e
    setFilterSewaTongkang({ ...filterSewaTongkang })
  }

  const [filterSewaTongkang, setFilterSewaTongkang] = useState({
    page: 1,
    tipe_sewa: location.state?.request !== undefined && location.state?.detail === 'tongkang' && (Number(location.state?.request_data?.type_tongkang) === 2 || Number(location.state?.request_data?.type_tongkang) === 3) ? (Number(location.state?.request_data?.type_tongkang) === 2 ? 'time' : 'freight') : '',
    ukuran_tongkang: '', max_quantity_muatan: '', min_quantity_muatan: '', max_price: '', min_price: '',
    bendera: "", search_text: location.state?.search === undefined ? "" : location.state?.search, pelabuhan_muat: "", pelabuhan_bongkar: ""
  })

  const [inputukurangtongkang, setinputukurantongkang] = React.useState(false)
  const handleFilterUkuranTongkang = (e, y, option) => {
    if (option === 'mobile') {
      filterTongkang[y] = e
      setFilterTongkang({ ...filterTongkang })
    } else {
      filterTongkang[y] = e
      setFilterTongkang({ ...filterTongkang })
      getTongkangList(filterTongkang)
    }
  }

  const getdatauserDocument = async () => {
    let id_detail = searchParams.get("id_detail")
    try {
      let params = {
        id_user: id_detail,
        page: 1,
        search_text: ''
      }
      const responAPI = await getUserDocument(params)
      setdataUserDocument(responAPI.data.data)
    } catch (error) {
      setdataUserDocument(state?.data)
      console.log(error)
    }
  }

  const getdataUserCompany = async () => {
    let id_detail = searchParams.get("id_detail")
    try {
      let params = {
        id_user: id_detail,
        page: 1
      }
      const responAPI = await getUserCompany(params)
      setdataUserCompany(responAPI.data.data)
    } catch (error) {
      setdataUserCompany(state?.data)
      console.log(error)
    }
  }

  function format(s) {
    let formattedphone = "";
    if (s?.length > 10) {
      formattedphone = s.toString().replace(/^(.{3})(.{4})(.{4})(.*)$/, "$1 $2 $3 $4");
    } else if (s?.length === 10) {
      formattedphone = s.toString().replace(/^(.{3})(.{4})(.{3})$/, "$1 $2 $3");
    } else {
      formattedphone = s.toString().replace(/^(.{3})(.{4})(.*)$/, "$1 $2 $3");
    }
    return formattedphone
  }

  const styles_in = {
    input: {
      height: '44px',
      fontSize: '14px',
      borderRadius: '8px',
      fontWeight: 400,
      fontFamily: 'Satoshi-400'

    },
  };

  return (
    <div className="detail-explore width-100" >
      <div className="flex-wrap-center relative width-100">
        <div className="container-nav relative width-100">
          <div className='fixed-header'>
            <NavBar></NavBar>
          </div>
        </div>
        <Box className="flex-wrap-center width-100" sx={{ mt: { xs: 6, sm: 6, md: 11, lg: 11, xl: 11 }, }}>
          <div className="content-inner">
            {activeTab !== 'trader' ?
              <Grid
                sx={{ mt: '16px' }}
                container
                direction="row"
                alignItems={'center'}
              >
                <Button href="/" className="w-500" sx={{ fontSize: 16, mb: 1, color: '#2457B9', textTransform: 'none', pl: 0 }} variant="text">  {translate('page_explore.home')}</Button>

                <Typography className="w-500" sx={{ fontSize: 16, mb: 1, color: '#56617B' }}>
                  /
                </Typography>
                <Button href="/explore" className="w-500" sx={{ fontSize: 16, mb: 1, color: '#2457B9', textTransform: 'none' }} variant="text">       Explore</Button>
                <Typography className="w-500" sx={{ fontSize: 16, mb: 1, mr: 1, color: '#2457B9' }}>
                  /
                </Typography>
                {activeTab === 'coal' ? <Typography className="w-500" sx={{ fontSize: 16, mb: 1 }}>
                  {translate('detail-explore.detailbatubara')}
                </Typography> : null}
                {activeTab === 'nikel' ? <Typography className="w-500" sx={{ fontSize: 16, mb: 1 }}>
                  {translate('detail-explore.detailnikel')}
                </Typography> : null}
                {activeTab === 'tongkang' ? <Typography className="w-500" sx={{ fontSize: 16, mb: 1 }}>
                  {translate('detail-explore.detailtongkang')}
                </Typography> : null}
                {activeTab === 'company' ? <Typography className="w-500" sx={{ fontSize: 16, mb: 1 }}>
                  {translate('detail-explore.detailperusahaan')}
                </Typography> : null}

              </Grid> : <></>}
            <Grid
              container
              direction="row"
              rowSpacing={{ xs: activeTab === 'trader' ? 2 : 5, lg: activeTab === 'trader' ? 2 : 7 }}
              columnSpacing={{ xs: 5, lg: 7 }}
              sx={{ mb: 2 }}
            >
              {/* rightside Section */}
              <Grid lg={12} xs={12} item>
                {/* detail trader Section */}
                {activeTab === 'trader' ?
                  <Box sx={{ mb: 2 }}>
                    <Box sx={{
                      overflow: "hidden", // menyembunyikan potongan
                      border: "1px solid #EAEAEA",
                      borderRadius: "10px",
                      mb: 2,
                    }}>
                      <Box
                        sx={{
                          position: "relative",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Box
                          component="img"
                          src={detailTrader?.background_picture_path?.url !== undefined ? `${config.baseURL + detailTrader?.background_picture_path?.url}` : HeroCompanyImg}
                          sx={{
                            maxHeight: "300px",
                            minHeight: "200px",
                            width: "100%",
                            height: "auto",
                            objectFit: "cover"
                          }}
                        />

                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          sx={{
                            position: "absolute",
                            bottom: -80,
                            left: "15px",
                            width: "160px",
                            height: "160px",
                          }}
                        >
                          <Grid container >
                            <Grid item xs={8} md={10} lg={12}>
                              <Avatar
                                alt="Avatar"
                                variant="circular"
                                src={detailTrader?.picture_path?.url !== undefined ? `${config.baseURL + detailTrader?.picture_path?.url}` : ''}
                                sx={{ width: { lg: "160px", md: '120px', xs: '90px' }, height: { lg: "160px", md: '120px', xs: '90px' }, bgcolor: 'blue', border: '4px solid white', }}
                              />
                            </Grid>
                          </Grid>
                        </Box>

                      </Box>
                      <Stack direction={'row'} justifyContent={'space-between'}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",

                            ml: { xs: 1, sm: '20%', lg: '16%', md: '18%' },
                            mb: 2, mt: { xs: 6, sm: 1, md: 1 }
                          }}
                        >
                          <Box>
                            <Typography
                              sx={{ fontSize: "32px", color: "#081130", mt: 0, pl: 0 }}
                              className="w-700"
                            >
                              {detailTrader?.name}
                            </Typography>
                          </Box>
                          <Box sx={{ display: "flex", flexDirection: { xs: 'column', md: 'row' } }}>
                            {detailTrader.city !== null || detailTrader.provinsi !== null && <Box sx={{ display: "flex", flexDirection: "row" }}>
                              <img alt="Nikel-icon" src={locationCompanyIcon} height={18}></img>

                              <Typography
                                className="w-500"
                                sx={{ fontSize: 14, color: "#081130", mr: 1, ml: 1 }}
                              >
                                {detailTrader?.city}, {detailTrader?.provinsi}
                              </Typography>
                            </Box>}

                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                              <img alt="Nikel-icon" src={companyIcon} height={18}></img>
                              <Typography
                                className="w-500"
                                sx={{ fontSize: 14, color: "#081130", mr: 1, ml: 1 }}
                              >
                                {dataUserCompany?.itemCount === 0 ? 'Freelance' : translate('status.tergabungdiperusahaan')}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Hidden lgDown>
                          <Stack direction={'row'} alignItems={'center'} sx={{ mr: '27px' }}>
                            <MoreHorizIcon onClick={(e) => handleOpenShareMenu(e)} sx={{ width: '20px', height: '20px', cursor: 'pointer', mr: '24px', fill: '#56617B' }} />
                            <Button onClick={(e) => handleOpenModal()} size="small" sx={{ background: '#2457B9', mb: 1, textTransform: 'none', p: '12px 10px', height: '40px' }} variant="contained" fullWidth>
                              <CallIcon />
                              <Typography className="w-500" sx={{ fontSize: 14, ml: '8px' }}>  {translate('detail-explore.hubungitrader')}</Typography>
                            </Button>
                            <ModalHubungiTrader user_trader_phone={detailTrader?.phone_number} type={'listing'} open={isOpenModal} close={handleCloseModal}></ModalHubungiTrader>
                          </Stack>
                        </Hidden>
                      </Stack>
                    </Box>
                    {/* Tab Button Section */}
                    <div className="tab-scroll-x" style={{ marginTop: 11, }}>
                      <Stack direction="row" justifyContent="left">
                        <Button
                          className="w-500"
                          sx={{
                            width: '150px',
                            background: activeTabTrader === 'profile' ? "#081130" : '', textTransform: 'none',
                            color: activeTabTrader === 'profile' ? "white" : '#081130', fontSize: 16, "&:hover": {
                              backgroundColor: "#56617B"
                            }
                          }}
                          onClick={() => {
                            handleTabTrader("profile");
                          }}
                          variant={activeTabTrader === 'profile' ? "contained" : 'text'}
                        >
                          {translate('profile.detail-profile')}
                        </Button>
                        <Divider orientation="vertical" style={{ marginTop: 10, marginBottom: 10, marginLeft: 10, marginRight: 10 }} flexItem />
                        <Button
                          className="w-500"
                          onClick={() => {
                            handleTabTrader("kredibilitas");
                          }}
                          sx={{
                            background: activeTabTrader === 'kredibilitas' ? "#081130" : '', textTransform: 'none',
                            color: activeTabTrader === 'kredibilitas' ? "white" : '#081130', fontSize: 16, "&:hover": {
                              backgroundColor: "#56617B"
                            }
                          }}
                          variant={activeTabTrader === 'kredibilitas' ? "contained" : 'text'}
                        >
                          {translate('profile.kredibilitas')}
                        </Button>
                        <Divider orientation="vertical" style={{ marginTop: 10, marginBottom: 10, marginLeft: 10, marginRight: 10 }} flexItem />
                        <Button
                          className="w-500"
                          onClick={() => {
                            handleTabTrader("favorit");
                          }}
                          sx={{
                            background: activeTabTrader === 'favorit' ? "#081130" : '', textTransform: 'none',
                            color: activeTabTrader === 'favorit' ? "white" : '#081130', fontSize: 16, "&:hover": {
                              backgroundColor: "#56617B"
                            }
                          }}
                          variant={activeTabTrader === 'favorit' ? "contained" : 'text'}
                        >
                          {translate('profile.favorit')}
                        </Button>
                        <Divider orientation="vertical" style={{ marginTop: 10, marginBottom: 10, marginLeft: 10, marginRight: 10 }} flexItem />
                        <Button
                          onClick={() => {
                            handleTabTrader("company");
                          }}
                          sx={{
                            background: activeTabTrader === 'company' ? "#081130" : '', textTransform: 'none',
                            color: activeTabTrader === 'company' ? "white" : '#081130', fontSize: 16, "&:hover": {
                              backgroundColor: "#56617B"
                            }
                          }}
                          variant={activeTabTrader === 'company' ? "contained" : 'text'}
                        >
                          {translate('profile.perusahaan')}
                        </Button>
                      </Stack>
                    </div>
                    {/* Tab Profile Detail Trader Section */}
                    {activeTabTrader === 'profile' ?
                      <Grid container direction={'row'} spacing={2}>
                        <Grid item xs={12} md={6} lg={6}>
                          <Box sx={{ border: 1, color: '#E8EBF2', height: '305px', borderRadius: 2, p: 2, mt: 2 }}>
                            <Typography className="w-700" sx={{ color: '#081130', fontSize: 18, mt: '22px', }}>
                              {translate('profile.tentangsaya')}
                            </Typography>
                            <Typography className="w-700" sx={{ color: '#56617B', fontSize: 14, mt: '26px', }}>
                              {detailTrader?.about}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <Box sx={{ border: 1, color: '#E8EBF2', height: '305px', borderRadius: 2, p: 2, mt: 2, }}>
                            <Typography className="w-700" sx={{ color: '#081130', fontSize: 18, mt: '22px', mb: 1 }}>
                              {translate('profile.kontak')}
                            </Typography>
                            <Stack direction="row" >
                              <img alt="Nikel-icon" style={{ marginRight: 14 }} src={CallCompanyIcon} height={24}></img>
                              <Stack direction="column" >
                                <Typography className="w-500" sx={{ color: '#56617B', fontSize: 14, }}>
                                  {translate('profile.phone')}
                                </Typography>
                                <Typography className="w-500" sx={{ color: '#081130', fontSize: 16, mt: 1, }}>
                                  {detailTrader?.phone_number === null ? translate('status.nomor-empty') : detailTrader?.phone_number === undefined ? "Private" : detailTrader?.phone_number === "" ? "Private" : format(detailTrader?.phone_number)}


                                </Typography>
                              </Stack>
                            </Stack>
                            <Stack direction="row" sx={{ mt: 1 }} >
                              <img alt="Nikel-icon" style={{ marginRight: 14 }} src={MailCompanyIcon} height={24}></img>
                              <Stack direction="column" >
                                <Typography className="w-500" sx={{ color: '#56617B', fontSize: 14, }}>
                                  Email
                                </Typography>
                                <Typography className="w-500" sx={{ color: '#081130', fontSize: 16, mt: 1, }}>
                                  {detailTrader?.email === null ? translate('status.email-empty') : detailTrader?.email === undefined ? "Private" : detailTrader?.email === "" ? "Private" : detailTrader?.email}

                                </Typography>
                              </Stack>
                            </Stack>
                            <Typography className="w-700" sx={{ color: '#081130', fontSize: 18, mt: '22px', mb: 1 }}>
                              {translate('profile.address')}
                            </Typography>
                            <Stack direction="row" >
                              <img alt="Nikel-icon" style={{ marginRight: 14 }} src={locationCompanyIcon} height={24}></img>
                              <Stack direction="column" >
                                <Typography className="w-500" sx={{ color: '#56617B', fontSize: 14, }}>
                                  {translate('profile.address')}
                                </Typography>
                                {detailTrader?.city && detailTrader.provinsi !== null ? <Typography className="w-500" sx={{ color: '#081130', fontSize: 16, mt: 1, }}>
                                  {detailTrader?.city} {detailTrader.provinsi && ','} {detailTrader?.provinsi}

                                </Typography> : <Typography className="w-500" sx={{ color: '#081130', fontSize: 16, mt: 1, }}>
                                  {detailTrader?.alamat}
                                </Typography>}
                              </Stack>
                            </Stack>
                          </Box>
                        </Grid>
                      </Grid>
                      : ''}
                    {/* Tab Kredibilitas Detail Trader Section */}
                    {activeTabTrader === 'kredibilitas' ?
                      <Box sx={{ border: 1, color: '#E8EBF2', borderRadius: 2, p: 2, mt: 2 }}>
                        <Grid container spacing={2} justifyContent={'space-between'}>
                          <Grid item xs={12} lg={8}>
                            <Typography className="w-700" sx={{ color: '#081130', fontSize: 18, mt: 1, }}>
                              {translate('profile.dokumenkredibilitas')}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} lg={2.5} justifyContent={"flex-end"} >
                            <TextField
                              fullWidth
                              className="search-filter-box"
                              id="outlined-start-adornmen"
                              type="text"
                              size="small"
                              placeholder={translate('profile.search')}
                              // label="Search"
                              // value={searchTerm}
                              // onChange={handleChange}
                              sx={{
                                mr: '0px',
                                input: {
                                  fontSize: '14px', fontFamily: 'Satoshi-500'
                                },
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img alt="search-icon" src={searchIcon} height={20} width={20}></img>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid container direction={'row'} spacing={2} sx={{ mt: 2 }}>
                          {dataUserDocument?.data.map((item, i) => (
                            <Grid item lg={2} xs={6} md={3}>
                              <Card sx={{ borderRadius: 2 }}>
                                <DisplayPDFFile
                                  url={`${config.baseURL}${item.url}`}
                                  key={i}
                                  ext={`${item.ext}`}
                                  name={`${item.name}`}
                                />
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                      </Box> : ''}

                    {/* Tab Favorit Detail Trader Section */}
                    {activeTabTrader === 'favorit' ?
                      <Box width='100%'>
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                          <Hidden lgDown>
                            <Grid item xs={3} sm={3} md={3} lg={3} >
                              {/* filter Coal Section */}
                              {activeTabFavorit === 'coal' ? <Hidden smDown>
                                <MenuFilterCoal page={'default'} handleLocationReset={handleLocationReset} resetFilterBatubara={resetFilterBatubara} handleFilterBatubara={handleFilterBatubara} filterBatubara={filterBatubara} dataProvince={dataProvince} selectedItems={selectedItems} limitProvince={limitProvince} setLimitProvince={setLimitProvince} setShowAllCoal={setShowAllCoal} handleSearchValueLocation={handleSearchValueLocation} handleKeyDownLocation={handleKeyDownLocation} handleLocationSumbit={handleLocationSumbit} showAllCoal={showAllCoal} searchValueLocation={searchValueLocation} >

                                </MenuFilterCoal>
                              </Hidden> : null}

                              {/* filter Nikel Section */}
                              {activeTabFavorit === 'nikel' ? <Hidden smDown>
                                <MenuFilterNikel page={'default'} handleLocationReset={handleLocationReset} resetFilterNikel={resetFilterNikel} handleFilterNikel={handleFilterNikel} filterNikel={filterNikel} dataProvince={dataProvince} selectedItems={selectedItems} limitProvince={limitProvince} setLimitProvince={setLimitProvince} setShowAllNikel={setShowAllNikel} handleSearchValueLocation={handleSearchValueLocation} handleKeyDownLocation={handleKeyDownLocation} handleLocationSumbit={handleLocationSumbit} showAllNikel={showAllNikel} searchValueLocation={searchValueLocation}></MenuFilterNikel>
                              </Hidden> : null}
                              {/* filter Tongkang Section */}
                              {activeTabFavorit === 'tongkang' ?
                                <Hidden lgDown>
                                  <MenuFilterTongkang page={'default'} resetFilterTongkang={resetFilterTongkang} handleFilterTongkang={handleFilterTongkang} filterTongkang={filterTongkang} handleFilterSewaTongkang={handleFilterSewaTongkang} filterSewaTongkang={filterSewaTongkang} inputukurangtongkang={inputukurangtongkang}></MenuFilterTongkang>
                                </Hidden> : null}
                            </Grid></Hidden>
                          <Grid item xs={12} sm={12} md={12} lg={9}> {/* Right Section*/}
                            <Grid container direction={{
                              xs: 'column-reverse',
                              sm: 'column-reverse',
                              md: 'column-reverse',
                              lg: 'row',
                              xl: 'row',
                            }} spacing={2} sx={{ mb: 0 }} >
                              <Grid item xs={12} md={7} lg={7} xl={8} sx={{ mb: 0, height: '100%' }}> {/*Search Bar*/}
                                <Grid container spacing={2} alignItems="center">
                                  <Grid item xs={10} sm={10} md={11} lg={12}>
                                    <SearchBarExplore text={activeTabFavorit === 'coal' ? translate('page_explore.search-batubara') :
                                      activeTabFavorit === 'nikel' ? translate('page_explore.search-nikel') : translate('page_explore.search-tongkang')} searchValue={searchValue} handleSearchValue={handleSearchValue} handleKeyDown={handleKeyDown} searchIcon={searchIcon} />
                                  </Grid>
                                  {activeTabFavorit === 'coal' ? <Hidden only={['lg', 'xl']}>
                                    <Grid item xs={1} md={1}>
                                      <Button onClick={() => {
                                        handleOpenModalFilter("coal");
                                      }} fullWidth>
                                        <img alt="filter-icon" src={filtericon} height={42} width={42}></img>
                                      </Button>
                                    </Grid></Hidden> : <></>}
                                  {activeTabFavorit === 'nikel' ? <Hidden only={['lg', 'xl']}>
                                    <Grid item xs={1} md={1}>
                                      <Button onClick={() => {
                                        handleOpenModalFilter("nikel");
                                      }} fullWidth>
                                        <img alt="filter-icon" src={filtericon} height={42} width={42}></img>
                                      </Button>
                                    </Grid></Hidden> : <></>}
                                  {activeTabFavorit === 'tongkang' ? <Hidden only={['lg', 'xl']}>
                                    <Grid item xs={1} md={1}>
                                      <Button onClick={() => {
                                        handleOpenModalFilter("tongkang");
                                      }} fullWidth>
                                        <img alt="filter-icon" src={filtericon} height={42} width={42}></img>
                                      </Button>
                                    </Grid></Hidden> : <></>}
                                </Grid>

                              </Grid>
                              <Grid item direction={'row'} xs={12} sm={12} md={12} lg={5} xl={4} sx={{ height: '100%' }} >
                                <Box display="flex" flexDirection="row" sx={{
                                  backgroundColor: '#ECEFF2', borderRadius: '8px'
                                  , height: '44px'
                                }}>
                                  <Button
                                    fullWidth
                                    sx={{
                                      m: "2px", p: '12px 24px', maxWidth: { xl: '110px' }, borderRadius: '8px', background: activeTabFavorit === 'coal' ? "white" : '#ECEFF2', justifyContent: 'center', textTransform: 'none'
                                      , "&:hover": {
                                        backgroundColor: "white"
                                      }
                                    }}
                                    onClick={() => {
                                      handleTabFavorit("coal");
                                    }}
                                    variant={activeTabFavorit === 'coal' ? "text" : 'text'}
                                  >
                                    <Typography
                                      className={activeTabFavorit === 'coal' ? "w-700" : 'w-500'}
                                      sx={{ color: activeTabFavorit === 'coal' ? '#101213' : '#56617B', fontSize: 14 }}>
                                      {translate('page_explore.batubara')}
                                    </Typography>
                                  </Button>
                                  <Button
                                    fullWidth
                                    onClick={() => {
                                      handleTabFavorit("nikel");
                                    }}
                                    sx={{
                                      m: "2px", p: '12px 24px', maxWidth: { xl: '110px' }, borderRadius: '8px', background: activeTabFavorit === 'nikel' ? "white" : '#ECEFF2', textTransform: 'none',
                                      fontSize: 14, fontWeight: activeTabFavorit === 'nikel' ? 700 : 500, "&:hover": {
                                        backgroundColor: "white"
                                      }
                                    }}
                                    variant={activeTabFavorit === 'nikel' ? "text" : 'text'}
                                  >
                                    <Typography
                                      className={activeTabFavorit === 'nikel' ? "w-700" : 'w-500'}
                                      sx={{ color: activeTabFavorit === 'nikel' ? '#101213' : '#56617B', fontSize: 14 }}>
                                      {translate('page_explore.nikel')}
                                    </Typography>
                                  </Button>
                                  <Button
                                    fullWidth
                                    onClick={() => {
                                      handleTabFavorit("tongkang");
                                    }}
                                    sx={{
                                      m: "2px", p: '12px 24px', maxWidth: { xl: '110px' }, borderRadius: '8px', background: activeTabFavorit === 'tongkang' ? "white" : '#ECEFF2', textTransform: 'none'
                                      , fontSize: 14, fontWeight: activeTabFavorit === 'tongkang' ? 700 : 500, "&:hover": {
                                        backgroundColor: "white"
                                      }
                                    }}
                                    variant={activeTabFavorit === 'tongkang' ? "text" : 'text'}
                                  >
                                    <Typography
                                      className={activeTabFavorit === 'tongkang' ? "w-700" : 'w-500'}
                                      sx={{ color: activeTabFavorit === 'tongkang' ? '#101213' : '#56617B', fontSize: 14 }}>
                                      {translate('page_explore.tongkang')}
                                    </Typography>
                                  </Button></Box>
                              </Grid>


                            </Grid>
                            {activeTabFavorit === 'coal' ? <Box sx={{ mt: 2, mr: 10, mb: 2, width: '100%' }}><TabelCoal page={'profile'} getCoalList={(e) => setFavoriteBatubara()} coalList={coalFavoriteList.data}></TabelCoal></Box> : null}
                            {activeTabFavorit === 'coal' ? <Hidden mdUp><Box sx={{ mt: 2, mr: 10, mb: 2, width: '100%' }}>
                              {coalFavoriteList?.data === undefined ? (
                                <div>
                                </div>
                              ) : (
                                <div>
                                  {coalFavoriteList?.data?.map(e => (
                                    <CardListCoalExplore page={'profile'} getCoalList={(e) => setFavoriteBatubara()} coalList={e}></CardListCoalExplore>
                                  ))}
                                </div>
                              )}
                            </Box></Hidden> : null}
                            {activeTabFavorit === 'nikel' ? <Box sx={{ mt: 2, mr: 10, mb: 2, width: "100%" }}><TabelNikel page={'profile'} nickelList={newNickelList} getNikel={(e) => setFavoriteNikel()}></TabelNikel></Box> : null}
                            {activeTabFavorit === 'nikel' ? <Hidden mdUp><Box sx={{ mt: 2, mr: 10, mb: 2, width: '100%' }}>
                              {newNickelList[0] === undefined ? (
                                <div>
                                </div>
                              ) : (
                                <div>
                                  {newNickelList?.map(e => (
                                    <CardListNikelExplore page={'profile'} nickelList={e} getNikel={(e) => setFavoriteNikel()}></CardListNikelExplore>
                                  ))}
                                </div>
                              )}
                            </Box></Hidden> : null}
                            {activeTabFavorit === 'tongkang' ? <Box sx={{ mb: 2, mt: 0 }}>
                              <Grid container direction={'row'} spacing={3} >
                                {tongkangFavoriteList?.map(tongkang => (
                                  <Grid sx={{ mt: 2 }} item lg={4} md={6} xs={12}>
                                    <CardTongkangListExplore page={'profile'} key={tongkang.product_id} data={tongkang} getTongkang={(e) => setFavoriteTongkang()}></CardTongkangListExplore>
                                  </Grid>
                                ))}
                              </Grid>
                            </Box> : null}
                            {(activeTabFavorit === "coal" && coalFavoriteList !== undefined && (parseInt(coalFavoriteList.pageCount) !== parseInt(coalFavoriteList.currentPage)) && (parseInt(coalFavoriteList.pageCount) > parseInt(coalFavoriteList.currentPage))) &&
                              <Grid sx={{ mt: 2, mb: 8 }} className="pointer" container justifyContent="center" alignItems="center" direction="row" onClick={(e) => handleFilterBatubara(filterBatubara["page"] + 1, "page")}>
                                <Typography className="w-500" sx={{ fontSize: 16, color: "#2457B9", mr: 1 }}>
                                  {translate('page_explore.lihatlebihbanyak')}
                                </Typography>
                                <KeyboardArrowDownIcon sx={{ fontSize: 16, color: "#2457B9" }} />
                              </Grid>}

                            {(activeTabFavorit === "nikel" && nickelFavoriteList !== undefined && (parseInt(nickelFavoriteList.pageCount) !== parseInt(nickelFavoriteList.currentPage)) && (parseInt(nickelFavoriteList.pageCount) > parseInt(nickelFavoriteList.currentPage))) &&
                              <Grid sx={{ mt: 2, mb: 8 }} className="pointer" container justifyContent="center" alignItems="center" direction="row" onClick={(e) => handleFilterNikel(filterNikel["page"] + 1, "page")}>
                                <Typography className="w-500" sx={{ fontSize: 16, color: "#2457B9", mr: 1 }}>
                                  {translate('page_explore.lihatlebihbanyak')}
                                </Typography>
                                <KeyboardArrowDownIcon sx={{ fontSize: 16, color: "#2457B9" }} />
                              </Grid>}

                            {(activeTabFavorit === "tongkang" && tongkangFavoriteList_ !== undefined && (parseInt(tongkangFavoriteList_.pageCount) !== parseInt(tongkangFavoriteList_.currentPage)) && (parseInt(tongkangFavoriteList_.pageCount) > parseInt(tongkangFavoriteList_.currentPage))) &&
                              <Grid sx={{ mt: 2, mb: 8 }} className="pointer" container justifyContent="center" alignItems="center" direction="row" onClick={(e) => handleFilterTongkang(filterTongkang["page"] + 1, "page")}>
                                <Typography className="w-500" sx={{ fontSize: 16, color: "#2457B9", mr: 1 }}>
                                  {translate('page_explore.lihatlebihbanyak')}
                                </Typography>
                                <KeyboardArrowDownIcon sx={{ fontSize: 16, color: "#2457B9" }} />
                              </Grid>}
                          </Grid>
                        </Grid>
                      </Box>

                      : ''}
                    {/* Tab Perusahaan Detail Trader Section */}
                    {activeTabTrader === 'company' ?
                      <Box>
                        <Hidden lgDown>
                          <Box sx={{ mt: 2 }}>
                            <Grid container direction={'row'}>
                              <Grid item xs={10}>
                                <Typography className="w-700" sx={{ color: '#081130', fontSize: 18, mb: 2, }}>
                                  {translate('profile.perusahaansaya')}
                                </Typography>
                              </Grid>
                              <Grid item xs={2}>
                              </Grid>
                            </Grid>

                            <TabelCompany page='detailtrader' data={dataUserCompany.data[0] !== undefined ? dataUserCompany : { data: [] }}></TabelCompany>
                          </Box>
                        </Hidden>
                        <Hidden lgUp>
                          <Box sx={{ p: 2, }}>
                            <Typography className="w-700" sx={{ color: '#081130', fontSize: 18, mt: 1, mb: '16px' }}>
                              {translate('profile.perusahaansaya')}
                            </Typography>
                            {dataUserCompany?.itemCount === 0 ? (
                              <div>
                              </div>
                            ) : (
                              <div>
                                {dataUserCompany.data.map((e) =>
                                  <CardCompanyDetail data={e}></CardCompanyDetail>
                                )}
                              </div>
                            )}
                          </Box>
                        </Hidden>
                      </Box>
                      : ''}
                  </Box> : null}
              </Grid>
            </Grid>

          </div>
          {/* Floating Action Button Section */}
          {isLogin && <Hidden lgUp>
            <Grid sx={{ pl: { xs: '16px', sm: '46px', md: '78px', }, pr: { xs: '16px', sm: '46px', md: '78px', } }} onScroll={scrollHandler} container direction="row" className={`${position ? "disable-freeze-grid-new" : "freeze-grid-new"}`}>
              <Grid alignItems={'center'} lg={8} xs={12} item sx={{ width: activeTab === 'trader' ? '1280px' : '840px' }}>
                {activeTab === 'trader' ?
                  <Box >
                    <Hidden lgUp>
                      <Button
                        onClick={(e) => handleOpenModal()}
                        fullWidth
                        className="w-500"
                        size="large" sx={{ background: ' linear-gradient(270deg, #2457B9 15%, #2860CD 100%)', borderRadius: '8px', boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.12), 0px -1px 0px 0px rgba(0, 0, 0, 0.20) inset', padding: '12px 16px', textTransform: 'none' }}
                        variant="contained" startIcon={<CallIcon />}>
                        {translate('detail-explore.hubungitrader')}
                      </Button>
                      <ModalHubungiTrader user_trader_phone={detailTrader?.phone_number} type={'listing'} open={isOpenModal} close={handleCloseModal}></ModalHubungiTrader>
                    </Hidden>
                  </Box> : null}
              </Grid>
            </Grid>
          </Hidden>}
          <Footer></Footer>
        </Box>
      </div>

      <ModalHubungiTrader user_trader_phone={detailTrader?.phone_number} type={activeTab} id={location.state.id} open={modalHubungi} close={() => { setModalHubungi(false) }} />
      <ModalShare page={'detailtrader'} type={5} id={location.state.id} anchorEl={anchorElShare} open={anchorElShare} onClose={handleCloseShareMenu}></ModalShare>

      {/* modal filter  Responsive Section */}
      <Hidden lgUp>
        {/* modal filter Coal Responsive Section */}
        {activeTabFavorit === 'coal' && <DrawerFilterCoal page={'default'} open={modalCoalFilter} close={handleCloseModalFilter} handleLocationReset={handleLocationReset} resetFilterBatubara={resetFilterBatubara} handleFilterBatubara={handleFilterBatubara} filterBatubara={filterBatubara} dataProvince={dataProvince} selectedItems={selectedItems} limitProvince={limitProvince} setLimitProvince={setLimitProvince} handleOpenModalLocationResponsive={handleOpenModalLocationResponsive} handleCloseModalFilter={handleCloseModalFilter} Filter={Filter}>
        </DrawerFilterCoal>}
        {/* modal filter Nikel Responsive Section */}
        {activeTabFavorit === 'nikel' && <DrawerFilterNikel page={'default'} open={modalNikelFilter} close={handleCloseModalFilter} handleLocationReset={handleLocationReset} resetFilterNikel={resetFilterNikel} handleFilterNikel={handleFilterNikel} filterNikel={filterNikel} dataProvince={dataProvince} selectedItems={selectedItems} limitProvince={limitProvince} setLimitProvince={setLimitProvince} handleOpenModalLocationResponsive={handleOpenModalLocationResponsive} handleCloseModalFilter={handleCloseModalFilter} Filter={Filter}>
        </DrawerFilterNikel>}
        {/* modal filter Tongkang Responsive Section */}
        {activeTabFavorit === 'tongkang' && <DrawerFilterTongkang page={'default'} open={modalTongkangFilter} close={handleCloseModalFilter} handleLocationReset={handleLocationReset} resetFilterTongkang={resetFilterTongkang} handleFilterTongkang={handleFilterTongkang} filterTongkang={filterTongkang} handleFilterSewaTongkang={handleFilterSewaTongkang} handleFilterUkuranTongkang={handleFilterUkuranTongkang} handleCloseModalFilter={handleCloseModalFilter} Filter={Filter} inputukurangtongkang={inputukurangtongkang}>
        </DrawerFilterTongkang>}

        {/* modal filter Location Responsive Section */}
        {activeTabFavorit === 'coal' && <DrawerFilterLocation type={'coal'} open={modalCoalLocation} close={handleCloseModalLocationResponsive} handleLocationReset={handleLocationReset} handleSearchValueLocation={handleSearchValueLocation} handleKeyDownLocation={handleKeyDownLocation} dataProvince={dataProvince}
          handleFilter={handleFilterBatubara} selectedItems={selectedItems} handleLocationSumbit={handleLocationSumbit} searchValueLocation={searchValueLocation}
        ></DrawerFilterLocation>}

        {activeTabFavorit === 'nikel' && <DrawerFilterLocation type={'nikel'} open={modalNikelLocation} close={handleCloseModalLocationResponsive} handleLocationReset={handleLocationReset} handleSearchValueLocation={handleSearchValueLocation} handleKeyDownLocation={handleKeyDownLocation} dataProvince={dataProvince}
          handleFilter={handleFilterNikel} selectedItems={selectedItems} handleLocationSumbit={handleLocationSumbit} searchValueLocation={searchValueLocation}
        ></DrawerFilterLocation>}
      </Hidden>
    </div>
  )
}

export default DetailTrader
