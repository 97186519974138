import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import * as React from 'react';
import { useTranslation } from "react-i18next";
import klaimCompanyIcon from "../assets/klaim-company-ico.png";


export default function ModalKlaimPerusahaan(props) {
    const [translate,] = useTranslation("global");
    return (
        <div>
            <Modal
                open={props.open}
                onClose={props.close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ zIndex: 100000 }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '304px', sm: 343, md: 343, lg: 500, xl: 500 },
                    borderRadius: '8px',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: { xs: '16px', sm: '24px', md: '24px', lg: '24px', xl: '24px' },
                    "&:focus": {
                        outline: "none"
                    },
                }}>

                    <Box sx={{ position: 'fixed', top: 16, right: 16 }}>
                        <IconButton size="small" >
                            <CloseIcon sx={{ width: '24px', height: '24px' }} onClick={() => {
                                props.close();
                            }} />
                        </IconButton>
                    </Box>

                    <Box sx={{ mt: { xs: '16px', lg: '44px' }, justifyContent: 'center', display: 'flex' }}>
                        <img alt="Batubara-icon" style={{ width: { xs: '100px', lg: '120px' }, height: { xs: '100px', lg: '120px' }, }} src={klaimCompanyIcon}></img>
                    </Box>

                    <Box sx={{ mt: '24px', mb: { xs: '16px', lg: '12px' }, justifyContent: 'center', display: 'flex' }}><Typography className='w-700' sx={{ height: '48px', width: { xs: '300px', lg: '300px' }, fontSize: { xs: '18px', lg: '20px' }, color: '#081130', textAlign: 'center' }}>
                        {translate('status.titleclaim')}
                    </Typography></Box>

                    <Box sx={{ justifyContent: 'center', display: 'flex', ml: { xs: '22px', lg: '43px' }, mr: { xs: '21px', lg: '43px' }, mb: { xs: '24px', lg: '38px' } }}>
                        <Typography className='w-500' sx={{ textAlign: 'center', fontSize: { xs: '14px', lg: '16px' }, color: '#081130', mb: { xs: '0px' }, width: '302px' }}>
                            {translate('status.proccesclaim')}
                        </Typography>
                    </Box>

                    <Box sx={{ justifyContent: 'center', display: 'flex', mb: { xs: '25px', lg: '33px' } }}>
                        <Button variant="contained" bgcolor="#2457B9" sx={{ p: '12px 16px', borderRadius: '8px', textTransform: 'none', height: { xs: '44px', lg: '48px' }, width: { xs: '148px', lg: '186px' } }} onClick={() => {
                            props.close();
                        }} >
                            <Typography className='w-500' sx={{ height: '22px', fontSize: { xs: '14px', lg: '16px' }, color: '#FFFFFF', textAlign: 'center' }}>
                                OK
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}