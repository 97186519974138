import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Grid, IconButton, Modal, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from "react-i18next";
import hubungitraderIcon from "../assets/hubungitrader-ic.svg";


export default function ModalHubungiTrader(props) {
    const [translate,] = useTranslation("global");

    return (
        <div>
            <Modal
                sx={{ '& .MuiBackdrop-root': { backgroundColor: 'rgba(0, 0, 0, 0.2)' } }}
                open={props.open}
                onClose={props.close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backdrop: 'transparent',
                    width: { xs: '304px', sm: 343, md: 343, lg: 415, xl: 415 },
                    borderRadius: '8px',
                    bgcolor: 'background.paper',
                    p: { xs: '16px', sm: '24px', md: '24px', lg: '24px', xl: '24px' },
                    "&:focus": {
                        outline: "none"
                    },
                }}>
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <IconButton size="small" onClick={() => {
                            props.close();
                        }}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div style={{ justifyContent: 'center', display: 'flex' }}>
                        <img alt="hubungitrader-icon" style={{ width: '140px', height: '140px', }} src={hubungitraderIcon}></img>
                    </div>
                    <Typography id="modal-modal-title" className='w-700' sx={{ fontSize: '20px', color: '#081130', textAlign: 'center', mt: '24px' }}>
                        {translate('detail-explore.hubungitrader')}
                    </Typography>

                    <Typography id="modal-modal-description" className='w-500' sx={{ mt: '16px', color: '#56617B', fontSize: '16px', textAlign: 'center' }}>
                        {props.type === 'coal' ? translate('status.titleaddlistingbatubara') :
                            props.type === 'nikel' ? translate('status.titleaddlistingnikel') :
                                props.type === 'tongkang' ? translate('status.titleaddlistingtongkang') :
                                    ''
                        }
                    </Typography>
                    <Grid spacing={1} container justifyContent='space-between' direction='row' sx={{ mb: '16px', mt: '36px' }}>
                        <Grid item xs={6}>
                            <Button
                                onClick={() => {
                                    props.close();
                                }}
                                fullWidth
                                size="large"
                                className="w-500"
                                variant="outlined"
                                sx={{
                                    color: '#2457B9',
                                    textTransform: "none", border: '1px solid var(--primarry-blue, #2457B9)',
                                    p: '12px 16px',
                                    borderRadius: '8px',
                                    lineHeight: 'normal',
                                    fontSize: 14

                                }}
                            >
                                {translate('jual-beli.btn-cancel')}
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                onClick={() => {
                                    props.close();
                                    window.open(`https://api.whatsapp.com/send?phone=+62${props.user_trader_phone}`)
                                }}

                                size="large"
                                className="w-500"
                                variant="contained"
                                sx={{
                                    background: ' #2457B9',
                                    textTransform: 'none',
                                    p: '12px 16px', borderRadius: '8px',
                                    lineHeight: 'normal',
                                    fontSize: 14
                                }}
                            >
                                {translate('detail-explore.hubungitrader')}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}