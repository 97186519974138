import { Box, Button, CircularProgress, Divider, Popover, Stack, Typography } from "@mui/material"
import Pagination from '@mui/material/Pagination'
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import checkIcon from "../assets/check-ic.png"
import perusahaanIcon from "../assets/perusahaan.svg"
import profileDefault from "../assets/profile-default.jpg"
import removeStaff from "../assets/remove-staff.png"
import { actionTrader, getNotification, readAllNotificationService, readNotification } from "../services/api"
import { config } from "../services/constants"

export default function ModalNotif(props) {
    const [translate,] = useTranslation("global");
    const navigate = useNavigate()
    const [notif, setNotif] = useState([])
    const [totalPage, setTotalPage] = useState()
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(true)
    let lang = localStorage.getItem('lang') === 'null' || localStorage.getItem('lang') === 'id' ? 'id' : localStorage.getItem('lang')

    const getNotif = async (page) => {
        try {
            setIsLoading(true)
            setCurrentPage(page)
            let params = {
                page: page
            }
            let responAPI = await getNotification(params, lang)
            if (responAPI.status === 200) {
                setNotif(responAPI.data.data)
                setTotalPage(responAPI.data.pageCount)
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    const getNotifNonLoading = async (page) => {
        try {
            setCurrentPage(page)
            let params = {
                page: page
            }
            let responAPI = await getNotification(params, lang)
            if (responAPI.status === 200) {
                setNotif(responAPI.data.data)
                setTotalPage(responAPI.data.pageCount)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleChange = (event, value) => {
        getNotifNonLoading(value);
    };

    const actionLink = async (data, read) => {
        try {

            if (read === true) {
                await readNotif(data?.notif_id)
            }
            if (data?.notif_url !== undefined) {
                if (data?.notif_url?.type === 'detail-listing') {
                    if (window.location.pathname.includes("/listing")) {
                        navigate(`${data?.notif_url?.url}`, { state: { detail: `${data?.notif_url?.detail}`, id: `${data?.notif_url?.id}` } })
                        window.location.reload()
                    } else {
                        navigate(`${data?.notif_url?.url}`, { state: { detail: `${data?.notif_url?.detail}`, id: `${data?.notif_url?.id}` } })
                    }
                } else if (data?.notif_url?.type === 'detail-explore') {
                    window.location.href = `${data?.notif_url?.url}`
                } else if (data?.notif_url?.type === 'url') {
                    window.location.replace(`${data?.notif_url?.url}`);
                } else {
                    window.location.href = `#`
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const actionInvitation = async (id_company, actionInvitation, notification_id) => {
        try {
            let params = {
                id_company: id_company,
                action: actionInvitation,
                notification_id: notification_id
            }
            await actionTrader(params)
            getNotifNonLoading(currentPage)
        } catch (error) {
            console.log(error)
        }
    }

    const readNotif = async (id, all) => {
        try {
            let params = {
                id: id
            }
            let responAPI = await readNotification(params)
            if (all === false || all === undefined) {
                getNotifNonLoading(currentPage)
            }

        } catch (error) {
            console.log(error)
        }
    }

    const readAllNotif = async () => {
        try {
            await readAllNotificationService()
            // for (let i = 0; i < notif.length; i++) {
            //     const e = notif[i];
            //     await readNotif(e.notif_id, true)
            // }
            getNotifNonLoading(currentPage)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (Boolean(props.open) === true) {
            getNotif(1)
        }
    }, [props.open])

    localStorage.getItem("token")
    return (
        <Popover
            PaperProps={{
                style: {
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    borderRadius: 0
                }
            }}
            sx={{ mt: '40px', zIndex: 99999, '& .MuiBackdrop-root': { backgroundColor: 'rgba(0, 0, 0, 0.2)' } }}
            id="menu-appbar"
            anchorEl={props.anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={Boolean(props.open)}
            onClose={props.onClose}
        >
            {isLoading ? (
                <>
                    <Box sx={{ borderRadius: '8px', pt: '6px', pb: '16px', width: '400px', background: 'white' }}>
                        <div style={{ textAlign: "center" }}>
                            <CircularProgress
                                sx={{
                                    color: (theme) =>
                                        theme.palette.grey[theme.palette.mode === 'dark' ? 200 : 800],
                                }}
                                size={20}
                                thickness={4}
                                value={100}
                            />
                        </div>
                    </Box>

                </>
            ) : (
                <div>
                    <Box
                        sx={{
                            position: "relative",
                            mt: "10px",
                            "&::before": {
                                backgroundColor: "white",
                                content: '""',
                                display: "block",
                                position: "absolute",
                                width: '24px',
                                height: '24px',
                                top: -6,
                                transform: "rotate(45deg)",
                                left: { xs: "calc(84% - 8px)", sm: "calc(86% - 8px)", md: "calc(75% - 8px)", lg: "calc(49% - 8px)", xl: "calc(49% - 8px)" }
                            }
                        }}
                    />

                    <Box sx={{ borderRadius: '8px', pt: '6px', pb: '16px', width: { lg: '400px' }, background: 'white' }}>
                        <Stack sx={{ pl: '16px', pr: '16px', mb: '16px' }} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography className="w-700" sx={{ color: '#424242', fontSize: 20 }}>
                                {translate('navbar.notifikasi')}
                            </Typography>
                            <Button
                                onClick={() => { readAllNotif() }}
                                className="w-500"
                                variant="text"
                                style={{
                                    color: '#2457B9',
                                    textTransform: "none",
                                }}
                            >
                                {translate('navbar.tandaisemua')}
                            </Button>
                        </Stack>
                        <div style={{ height: '400px', overflowY: 'scroll' }}>
                            {notif.map((item) => (
                                <>
                                    <Divider orientation="horizontal" style={{ height: '1px', background: '#E6E6E6' }} flexItem />

                                    {item?.notif_isRead === false ? (
                                        <div>
                                            {item?.notif_template_id === 20 ? (
                                                <Stack onClick={() => { actionLink(item, true) }} className="pointer" direction={'row'} sx={{ background: '#F4F6FA', p: '16px' }} >
                                                    {`${item.notif_icon}` === 'Success' ? (
                                                        <Box sx={{ mr: '16px' }}>
                                                            <img alt="img-icon" src={checkIcon} height={36} width={36}></img>
                                                        </Box>
                                                    ) : `${item.notif_icon}` === 'company' ? (
                                                        <Box sx={{ mr: '16px' }}>
                                                            <img alt="img-icon" src={perusahaanIcon} height={36} width={36}></img>
                                                        </Box>
                                                    ) : `${item.notif_icon}` === 'null' || `${item.notif_icon}` === "" ? (
                                                        <Box sx={{ mr: '16px' }}>
                                                            <img style={{ borderRadius: "100%", objectFit: "cover" }} alt="img-icon" src={profileDefault} height={36} width={36}></img>
                                                        </Box>
                                                    ) : `${item.notif_icon}` === 'Remove Staff' ? (
                                                        <Box sx={{ mr: '16px' }}>
                                                            <img style={{ borderRadius: "100%", objectFit: "cover" }} alt="img-icon" src={removeStaff} height={36} width={36}></img>
                                                        </Box>
                                                    ) : (
                                                        <Box sx={{ mr: '16px' }}>
                                                            <img style={{ borderRadius: "100%", objectFit: "cover" }} alt="img-icon" src={`${config.baseURL}${item.notif_icon}`} height={36} width={36}></img>
                                                        </Box>
                                                    )}
                                                    <Stack direction={'column'}>
                                                        <Typography className="w-500" sx={{ color: '#56617B', fontSize: 14 }}>
                                                            {item.notif_text}
                                                        </Typography>
                                                        {item.notif_status === "active" ? (
                                                            <Box direction={'row'} sx={{ mt: '8px' }}>
                                                                <Button
                                                                    onClick={(e) => actionInvitation(item.notif_company_id, "accept", item.notif_id)}
                                                                    className="w-500"
                                                                    variant="contained"
                                                                    sx={{
                                                                        fontSize: '12px',
                                                                        background: 'linear-gradient(270deg, #2457B9 15%, #2860CD 100%)',
                                                                        padding: "5px 5px",
                                                                        borderRadius: "8px",
                                                                        textTransform: "none",
                                                                    }}
                                                                >
                                                                    Terima
                                                                </Button>
                                                                <Button
                                                                    onClick={(e) => actionInvitation(item.notif_company_id, "reject", item.notif_id)}
                                                                    className="w-500"
                                                                    variant="contained"
                                                                    sx={{

                                                                        fontSize: '12px',
                                                                        background: 'linear-gradient(270deg, #2457B9 15%, #2860CD 100%)',
                                                                        padding: "5px 5px",
                                                                        borderRadius: "8px",
                                                                        textTransform: "none",
                                                                        ml: "10px"
                                                                    }}
                                                                >
                                                                    Tolak
                                                                </Button>
                                                            </Box>
                                                        ) : item.notif_status === "expired" ? (
                                                            <Typography className="w-500" sx={{ color: '#56617B', fontSize: 12, mt: '8px' }}>
                                                                Undangan ini sudah kadaluwarsa
                                                            </Typography>
                                                        ) : item.notif_status === "accept" ? (
                                                            <Typography className="w-500" sx={{ color: '#56617B', fontSize: 12, mt: '8px' }}>
                                                                Anda sudah menerima atau accept undangan ini
                                                            </Typography>
                                                        ) : item.notif_status === "reject" ? (
                                                            <Typography className="w-500" sx={{ color: '#56617B', fontSize: 12, mt: '8px' }}>
                                                                Anda sudah menolak atau reject undangan ini
                                                            </Typography>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        <Typography className="w-500" sx={{ color: '#56617B', fontSize: 12, mt: '8px' }}>
                                                            {item?.notif_time?.date === undefined ? item?.notif_time : item?.notif_time?.date}
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                            ) : (
                                                <Stack onClick={() => { actionLink(item, true) }} className="pointer" direction={'row'} sx={{ background: '#F4F6FA', p: '16px' }} >
                                                    {`${item.notif_icon}` === 'Success' ? (
                                                        <Box sx={{ mr: '16px' }}>
                                                            <img alt="img-icon" src={checkIcon} height={36} width={36}></img>
                                                        </Box>
                                                    ) : `${item.notif_icon}` === 'company' ? (
                                                        <Box sx={{ mr: '16px' }}>
                                                            <img alt="img-icon" src={perusahaanIcon} height={36} width={36}></img>
                                                        </Box>
                                                    ) : `${item.notif_icon}` === 'null' || `${item.notif_icon}` === "" ? (
                                                        <Box sx={{ mr: '16px' }}>
                                                            <img style={{ borderRadius: "100%", objectFit: "cover" }} alt="img-icon" src={profileDefault} height={36} width={36}></img>
                                                        </Box>
                                                    ) : `${item.notif_icon}` === 'Remove Staff' ? (
                                                        <Box sx={{ mr: '16px' }}>
                                                            <img style={{ borderRadius: "100%", objectFit: "cover" }} alt="img-icon" src={removeStaff} height={36} width={36}></img>
                                                        </Box>
                                                    ) : (
                                                        <Box sx={{ mr: '16px' }}>
                                                            <img style={{ borderRadius: "100%", objectFit: "cover" }} alt="img-icon" src={`${config.baseURL}${item.notif_icon}`} height={36} width={36}></img>
                                                        </Box>
                                                    )}
                                                    <Stack direction={'column'}>
                                                        <Typography className="w-500" sx={{ color: '#56617B', fontSize: 14 }}>
                                                            {item.notif_text}
                                                        </Typography>
                                                        <Typography className="w-500" sx={{ color: '#56617B', fontSize: 12, mt: '8px' }}>
                                                            {item?.notif_time?.date === undefined ? item?.notif_time : item?.notif_time?.date}
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                            )}
                                        </div>
                                    ) : (
                                        <div>
                                            {item?.notif_template_id === 20 ? (
                                                <Stack onClick={() => { actionLink(item, true) }} className="pointer" direction={'row'} sx={{ background: '#FFFFFF', p: '16px' }} >
                                                    {`${item.notif_icon}` === 'Success' ? (
                                                        <Box sx={{ mr: '16px' }}>
                                                            <img alt="img-icon" src={checkIcon} height={36} width={36}></img>
                                                        </Box>
                                                    ) : `${item.notif_icon}` === 'company' ? (
                                                        <Box sx={{ mr: '16px' }}>
                                                            <img alt="img-icon" src={perusahaanIcon} height={36} width={36}></img>
                                                        </Box>
                                                    ) : `${item.notif_icon}` === 'null' || `${item.notif_icon}` === "" ? (
                                                        <Box sx={{ mr: '16px' }}>
                                                            <img style={{ borderRadius: "100%", objectFit: "cover" }} alt="img-icon" src={profileDefault} height={36} width={36}></img>
                                                        </Box>
                                                    ) : `${item.notif_icon}` === 'Remove Staff' ? (
                                                        <Box sx={{ mr: '16px' }}>
                                                            <img style={{ borderRadius: "100%", objectFit: "cover" }} alt="img-icon" src={removeStaff} height={36} width={36}></img>
                                                        </Box>
                                                    ) : (
                                                        <Box sx={{ mr: '16px' }}>
                                                            <img style={{ borderRadius: "100%", objectFit: "cover" }} alt="img-icon" src={`${config.baseURL}${item.notif_icon}`} height={36} width={36}></img>
                                                        </Box>
                                                    )}
                                                    <Stack direction={'column'}>
                                                        <Typography className="w-500" sx={{ color: '#56617B', fontSize: 14 }}>
                                                            {item.notif_text}
                                                        </Typography>
                                                        {item.notif_status === "active" ? (
                                                            <Box direction={'row'} sx={{ mt: '8px' }}>
                                                                <Button
                                                                    onClick={(e) => actionInvitation(item.notif_company_id, "accept", item.notif_id)}
                                                                    className="w-500"
                                                                    variant="contained"
                                                                    sx={{
                                                                        fontSize: '12px',
                                                                        background: 'linear-gradient(270deg, #2457B9 15%, #2860CD 100%)',
                                                                        padding: "5px 5px",
                                                                        borderRadius: "8px",
                                                                        textTransform: "none",
                                                                    }}
                                                                >
                                                                    Terima
                                                                </Button>
                                                                <Button
                                                                    onClick={(e) => actionInvitation(item.notif_company_id, "reject", item.notif_id)}
                                                                    className="w-500"
                                                                    variant="contained"
                                                                    sx={{

                                                                        fontSize: '12px',
                                                                        background: 'linear-gradient(270deg, #2457B9 15%, #2860CD 100%)',
                                                                        padding: "5px 5px",
                                                                        borderRadius: "8px",
                                                                        textTransform: "none",
                                                                        ml: "10px"
                                                                    }}
                                                                >
                                                                    Tolak
                                                                </Button>
                                                            </Box>
                                                        ) : item.notif_status === "expired" ? (
                                                            <Typography className="w-500" sx={{ color: '#56617B', fontSize: 12, mt: '8px' }}>
                                                                Undangan ini sudah kadaluwarsa
                                                            </Typography>
                                                        ) : item.notif_status === "accept" ? (
                                                            <Typography className="w-500" sx={{ color: '#56617B', fontSize: 12, mt: '8px' }}>
                                                                Anda sudah menerima atau accept undangan ini
                                                            </Typography>
                                                        ) : item.notif_status === "reject" ? (
                                                            <Typography className="w-500" sx={{ color: '#56617B', fontSize: 12, mt: '8px' }}>
                                                                Anda sudah menolak atau reject undangan ini
                                                            </Typography>
                                                        ) : (
                                                            <></>
                                                        )}
                                                        <Typography className="w-500" sx={{ color: '#56617B', fontSize: 12, mt: '8px' }}>
                                                            {item?.notif_time?.date === undefined ? item?.notif_time : item?.notif_time?.date}
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                            ) : (
                                                <Stack onClick={() => { actionLink(item, false) }} className="pointer" direction={'row'} sx={{ background: '#FFFFFF', p: '16px' }} >
                                                    {`${item.notif_icon}` === 'Success' ? (
                                                        <Box sx={{ mr: '16px' }}>
                                                            <img alt="img-icon" src={checkIcon} height={36} width={36}></img>
                                                        </Box>
                                                    ) : `${item.notif_icon}` === 'company' ? (
                                                        <Box sx={{ mr: '16px' }}>
                                                            <img alt="img-icon" src={perusahaanIcon} height={36} width={36}></img>
                                                        </Box>
                                                    ) : `${item.notif_icon}` === 'null' || `${item.notif_icon}` === "" ? (
                                                        <Box sx={{ mr: '16px' }}>
                                                            <img style={{ borderRadius: "100%", objectFit: "cover" }} alt="img-icon" src={profileDefault} height={36} width={36}></img>
                                                        </Box>
                                                    ) : `${item.notif_icon}` === 'Remove Staff' ? (
                                                        <Box sx={{ mr: '16px' }}>
                                                            <img style={{ borderRadius: "100%", objectFit: "cover" }} alt="img-icon" src={removeStaff} height={36} width={36}></img>
                                                        </Box>
                                                    ) : (
                                                        <Box sx={{ mr: '16px' }}>
                                                            <img style={{ borderRadius: "100%", objectFit: "cover" }} alt="img-icon" src={`${config.baseURL}${item.notif_icon}`} height={36} width={36}></img>
                                                        </Box>
                                                    )}
                                                    <Stack direction={'column'}>
                                                        <Typography className="w-500" sx={{ color: '#56617B', fontSize: 14 }}>
                                                            {item.notif_text}
                                                        </Typography>
                                                        <Typography className="w-500" sx={{ color: '#56617B', fontSize: 12, mt: '8px' }}>
                                                            {item?.notif_time?.date === undefined ? item?.notif_time : item?.notif_time?.date}
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                            )}
                                        </div>
                                    )}
                                </>
                            ))}
                        </div>
                        <div style={{ display: "flex" }}>
                            <Pagination style={{ paddingTop: "24px", marginLeft: "auto", marginRight: "auto" }} color="primary" count={totalPage} size="small" onChange={handleChange} />
                        </div>
                    </Box>
                </div >
            )
            }
        </Popover >
    )
}
