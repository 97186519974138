import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getProvince } from '../services/api';

export default function FormProvince(props) {

    const [provinceList, setprovinceList] = useState([])
    const [provinceSelect, setprovinceSelect] = useState({ id: parseInt(props.currentValue) })
    const [translate,] = useTranslation("global");

    async function handleChangeInput(e) {
        try {
            await getDataProvince()
        } catch (error) {

        }
    }

    async function getDataProvince() {
        try {
            const response = await getProvince()
            setprovinceList(response.data?.data);

        } catch (e) {

        }
    }

    async function changeValue(event, values) {
        try {
            if (values === null) {
                setprovinceSelect(values)
                props.select({ id: "" })
                props.selectName("")
            } else {
                setprovinceSelect(values)
                props.select(values)
                props.selectName(values.name)
            }
        } catch (e) {

        }
    }

    useEffect(() => {
        getDataProvince()
        setprovinceSelect({ id: props.currentValue })
    }, [props.currentValue])


    return (

        <Autocomplete

            style={{
                width: "100%",
                border: "1px solid var(--grey-stroke, #e8ebf2)",
                marginTop: "4px",
                borderRadius: "8px"
            }}
            onChange={changeValue}
            options={provinceList.map((x, i) => (x))}
            onInputChange={async (event, newInputValue) => await handleChangeInput(newInputValue)}
            getOptionLabel={(option) => option.attributes.name}
            renderOption={(props, option) => {

                return (
                    <div {...props}>
                        {option.attributes.name}
                    </div>)
            }
            }

            popupIcon={<KeyboardArrowDownIcon sx={{ width: '20px', height: '20px', fill: '#56617B' }} />}
            renderInput={(params) => {
                if (provinceSelect?.id) {

                    provinceList.map(x => {
                        if (x.id === provinceSelect.id) {
                            params.inputProps.value = x.attributes.name
                        }
                    })
                    return <TextField error={props.validation} {...params} variant="outlined" />
                } else {

                    return <TextField {...params} error={props.validation} variant="outlined" placeholder={props.placeholder} sx={{
                        color: "#56617B", fontSize: "14px", input: {
                            fontSize: '14px', fontFamily: 'Satoshi-500'
                        },
                    }} />
                }

            }}
        />

    );
}