
import Divider from "@material-ui/core/Divider";
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, CircularProgress, Drawer, IconButton, InputAdornment, Link, Stack, TextField, Typography } from "@mui/material";
import "firebase/analytics";
import firebase from "firebase/app";
import "firebase/auth";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Swal from 'sweetalert2';
import visibilityOffIcon from "../assets/eye-icon-close.svg";
import visibilityIcon from "../assets/eye-icon.svg";
import Facebookicon from "../assets/facebook-ic-color.png";
import Googleicon from "../assets/google-ic-color.png";
import { login, loginFromFirebase, signUpFromFirebase } from "../services/api";
import "../services/firebase";

export default function DrawerLogin(props) {
  const { state } = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [translate] = useTranslation("global");
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);

  const handleSubmit = async (event, condition) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    if (condition === "login") {
      const dataLogin = {
        identifier: data.get("identifier"),
        password: data.get("password"),
      }
      setIsLoadingLogin(true)
      const response = await login(dataLogin);
      setIsLoadingLogin(false)
      if (response.status === 200) {

        localStorage.setItem("token", response.jwt)
        localStorage.setItem("profilePicture", response.profile_picture?.url ?? 'null')
        localStorage.setItem("id_user", response.data?.id ?? 'null')
        // localStorage.setItem("id_user", response.data?.id ?? 'null')
        if (state?.detail === 'listing') {
          window.location.href = `/listing/detail?type=${state.type}&id_detail=${state.id_relation}`
        } else {
          window.location.href = "/"
        }

      } else {
        Swal.fire({
          icon: 'error',
          title: 'Failed Login',
          text: `${response?.message}`,
        })

      }
    }
  }

  const registerWithFirebase = async (e) => {
    const responAPI = await signUpFromFirebase(e)

    if (responAPI !== undefined && responAPI.status === 200) {
      loginWithTokenFirebase(e)
    } else if (responAPI.message !== 'Internal Server Error' && responAPI.status === 500) {
      loginWithTokenFirebase(e)
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: "Can't Login",
      })
    }
  }

  const loginWithTokenFirebase = async (e) => {
    const responAPI = await loginFromFirebase(e)

    if (responAPI.status === 200) {
      localStorage.setItem("token", responAPI.jwt)
      localStorage.setItem("profilePicture", responAPI.data?.profile_picture?.url ?? 'null')
      localStorage.setItem("id_user", responAPI.data?.id ?? 'null')
      if (state?.detail === 'listing') {
        window.location.href = `/listing/detail?type=${state.type}&id_detail=${state.id_relation}`
      } else {
        window.location.href = "/"
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Failed Login',
        text: `${responAPI?.message}`,
      })

    }
  }

  const loginWithGoogle = async () => {
    firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider()).then((result) => {
      let token = result.user.Aa
      registerWithFirebase({ token_firebase: token })
    }).catch(error => {
      // Swal.fire({
      //   icon: 'error',
      //   title: 'Failed',
      //   text: `${error.message}`,
      // })
    })
  }

  const loginWithFacebook = async () => {

    firebase.auth().signInWithPopup(new firebase.auth.FacebookAuthProvider()).then((result) => {
      let token = result.user.Aa

      registerWithFirebase({ token_firebase: token })
    }).catch(error => {
      // Swal.fire({
      //   icon: 'error',
      //   title: 'Failed',
      //   text: `${error.message}`,
      // })
    })
  }

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <Drawer
      className="box-drawer-upload-doc"
      sx={{ zIndex: 999999, }}
      anchor="bottom"
      open={Boolean(props.open)}
      onClose={props.close}
    >
      <Box sx={{ m: '16px' }}>
        <Box sx={{ justifyContent: 'right', display: 'flex' }}>
          <IconButton size="small" >
            <CloseIcon sx={{ width: '24px', height: '24px' }} onClick={() => {
              props.close();
            }} />
          </IconButton>
        </Box>

        <Box sx={{ mt: '12px', mb: '24px', justifyContent: 'center', display: 'flex' }}>
          {props.type === 1 && <Typography className='w-700' sx={{ height: '54px', width: '343px', fontSize: '20px', color: '#081130', textAlign: 'center' }}>
            {translate('status.title-drawer-company')}
          </Typography>}
          {props.type === 4 && <Typography className='w-700' sx={{ height: '54px', width: '343px', fontSize: '20px', color: '#081130', textAlign: 'center' }}>
            {translate('status.title-drawer-trader')}
          </Typography>}
        </Box>

        <Box component="form"
          noValidate
          onSubmit={(e) => handleSubmit(e, "login")} sx={{ mb: '24px' }}>
          <Typography className='w-500' sx={{ mb: '4px', fontSize: '14px', color: '#081130' }}>
            Email
          </Typography>

          <TextField
            InputProps={{
              sx: {
                fieldset: { borderColor: 'var(--grey-stroke, #E8EBF2)', borderWidth: '1px solid' },
                p: '14px 16px', fontFamily: 'Satoshi', fontWeight: '400', fontSize: '14px', borderRadius: '8px', height: '48px', mb: '24px', color: '#8792AB',
                input: {
                  fontSize: '14px', fontFamily: 'Satoshi-500'
                },
              },
            }}
            fullWidth
            id="identifier"
            placeholder={translate('login-register.placeholder-email')}
            name="identifier"
            autoComplete="email"
            autoFocus
          />

          <Typography className='w-500' sx={{ fontSize: { xs: '14px', lg: '14px' }, color: '#081130', mb: { xs: '4px' }, width: '302px' }}>
            Password
          </Typography>

          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    style={{ padding: 0 }}

                  >
                    {showPassword ? <img alt="eye-icon" style={{ width: '22px', height: '22px', paddingRight: 0 }} src={visibilityIcon}></img> : ''}
                    {!showPassword ? <img alt="eyeclose-icon" style={{ width: '20px', height: '20px', }} src={visibilityOffIcon}></img> : ''}
                  </IconButton>
                </InputAdornment>
              ),
              sx: {
                fieldset: { borderColor: 'var(--grey-stroke, #E8EBF2)', borderWidth: '1px solid' },
                p: '14px 16px', fontFamily: 'Satoshi', fontWeight: '400', fontSize: '14px', borderRadius: '8px', height: '48px', mb: '16px', input: {
                  color: '#8792AB', fontSize: '14px', fontFamily: 'Satoshi-500'
                },
              },
            }}
            fullWidth
            id="identifier"
            placeholder={translate('login-register.placeholder-password')}
            name="password"
            autoComplete="email"
            type={showPassword ? "text" : "password"}
          //autoFocus
          />

          <Typography onClick={() => {
            props.close();
            window.location.href = `/forgetpassword`
          }} className='w-500' sx={{ fontSize: '16px', color: '#2457B9', mb: '56px', cursor: 'pointer', }}>
            {translate('login-register.forget-password')}
          </Typography>
          <Button type="submit" variant="contained" fullWidth bgcolor="#2457B9" sx={{ p: '16px 20px', borderRadius: '8px', textTransform: 'none', height: { xs: '44px', lg: '54px' } }}>
            <Typography className='w-500' sx={{ height: '22px', fontSize: { xs: '14px', lg: '16px' }, color: '#FFFFFF', textAlign: 'center' }}>
              {isLoadingLogin ? <CircularProgress
                sx={{
                  color: (theme) =>
                    theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                }}
                size={20}
                thickness={4}
                value={100}
              /> : translate('login-register.btn-masuk')}
            </Typography>
          </Button>

          <Stack justifyContent={'center'} direction={'row'} sx={{ mt: '32px', mb: '32px' }} alignItems={'center'}>
            <Divider style={{ backgroundColor: "#E8EBF2", width: '80px', height: '1px', }} />
            <Typography className="w-500" align="center" sx={{ mr: '12px', ml: '12px', color: '#56617B', fontSize: '16px' }}> {translate('login-register.or')}
            </Typography>
            <Divider style={{ backgroundColor: "#E8EBF2", width: '80px', height: '1px', }} />
          </Stack>


          <Stack direction={'row'} spacing={2} sx={{ mb: '32px' }}>
            <Button
              onClick={(e) => {
                props.close();
                loginWithGoogle()
              }}
              fullWidth
              variant="outlined"
              startIcon={<Box
                component="img"
                sx={{

                  height: 20,
                }}
                alt="logo-img"
                src={Googleicon}
              />}
              sx={{
                mt: 0, mb: 0, textTransform: "none", border: '1px solid var(--primarry-blue, #2457B9)',
                p: '12px 16px',
                borderRadius: '8px',
                color: '#2457B9', height: '48px'
              }}
            >
              <Typography
                className="w-500"
                sx={{ fontSize: 16, color: '#2457B9' }}

              >
                Google

              </Typography>
            </Button>


            <Button
              onClick={(e) => {
                props.close();
                loginWithFacebook()
              }}
              fullWidth
              variant="outlined"
              startIcon={<Box
                component="img"
                sx={{

                  height: 20,
                }}
                alt="logo-img"
                src={Facebookicon}
              />}
              sx={{
                mt: 0, mb: 0, textTransform: "none", border: '1px solid var(--primarry-blue, #2457B9)',
                p: '12px 16px',
                borderRadius: '8px',
                color: '#2457B9', height: '48px'
              }}
            >
              <Typography
                className="w-500"
                sx={{ fontSize: 16, color: '#2457B9' }}

              >
                Facebook

              </Typography>

            </Button>
          </Stack>


          <Stack direction={'row'} justifyContent={{ xs: 'center', sm: 'center', md: 'left', lg: 'center' }} alignItems={'center'}>
            <Typography
              className="w-500"
              sx={{ fontSize: 16, color: '#56617B', mr: '8px' }}

            >
              {translate('login-register.btn-to-daftar')}

            </Typography>
            <Link href="/signup" className="w-700" sx={{ color: '#2457B9', fontSize: 16, textDecoration: 'none' }}>
              {translate('login-register.btn-daftar')}
            </Link>{" "}
          </Stack>

        </Box>

      </Box>
    </Drawer>
  );
}