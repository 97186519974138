import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import NavBar from "../components/Navbar";
export default function ErrorPage() {
    const [translate] = useTranslation("global");
    return (
        <div className="homepage">
            <div className="container-nav relative width-100">
                <div className='fixed-header'>
                    <NavBar />
                </div>
            </div>
            <Box className="flex-wrap-center width-100">
                <Box className="w-500 content-inner" sx={{ mt: { xs: 6, sm: 6, md: 12, lg: 12, xl: 12 }, ml: { xs: '24px', lg: '80px', }, mr: { xs: '24px', lg: '80px', }, mb: 4, justifyContent: 'center', alignItems: 'center', height: "500px", display: 'flex' }}>
                    <div>
                        <h2 style={{ marginBottom: '12px' }}><strong>404</strong></h2>
                        <p style={{ marginBottom: '12px' }}>
                            {translate('status.pagenotfound')}
                        </p>
                    </div>
                </Box>
            </Box>
        </div>
    )
}
