import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Avatar, IconButton } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getSearchCompany } from '../services/api';
import { config } from '../services/constants';

export default function FormCompany(props) {

    const [companyList, setCompanyList] = useState([])
    const [companySelect, setCompanySelect] = useState({ id: props.currentValue })
    const [page, setPage] = useState(1)
    const [value, setValue] = useState("")
    const [translate,] = useTranslation("global");

    async function handleChangeInput(e) {
        try {
            setValue(e)
            setPage(1)
            await getAllCompany(e)
        } catch (error) {

        }
    }

    async function getAllCompany(name, pageQ) {
        try {
            let valuePage = page
            if (pageQ === "" || pageQ === undefined) {
                valuePage = 1
            } else {
                valuePage = pageQ
                setPage(pageQ)
            }

            if (valuePage !== 1) {
                const response = await getSearchCompany({ name: name, page: valuePage })
                let currentCompany = companyList
                setCompanyList(currentCompany.concat(response.data?.data));
            } else {
                const response = await getSearchCompany({ name: name, page: valuePage })
                setCompanyList(response.data?.data);
            }
        } catch (e) {

        }
    }

    async function changeValue(event, values) {
        try {
            if (values === null) {
                setCompanySelect(values)
                props.select({ id: "" })
                props.selectName("")
            } else {
                setCompanySelect(values)
                props.select(values)
                props.selectName(values.attributes.name)
            }
        } catch (e) {

        }
    }

    useEffect(() => {
        getAllCompany()
    }, [])


    return (
        <>
            <Autocomplete

                style={{
                    width: "100%",
                    border: "1px solid var(--grey-stroke, #e8ebf2)",
                    marginTop: "4px",
                    borderRadius: "8px"
                }}

                onChange={changeValue}
                options={companyList.map((x, i) => (x))}
                onInputChange={async (event, newInputValue) => await handleChangeInput(newInputValue)}
                getOptionLabel={(option) => option.attributes.name}
                renderOption={(props, option) => {
                    let url = option?.attributes?.picture_path === null ? '' : option?.attributes?.picture_path
                    return (
                        <div {...props}>
                            {url === "" ? (
                                <>
                                    <Avatar sx={{ bgcolor: 'blue', mr: "12px", height: 28, width: 28, fontSize: 12 }} aria-label="recipe">
                                        PT
                                    </Avatar>{option.attributes.name}
                                </>
                            ) : (
                                <>
                                    <IconButton sx={{ mr: '12px', p: 0 }}>
                                        <img style={{ borderRadius: "100%", objectFit: "cover" }} alt="img-icon" src={config.baseURL + url} height={28} width={28}></img>
                                    </IconButton>{option.attributes.name}
                                </>
                            )}
                        </div>)
                }
                }
                ListboxProps={{
                    role: "list-box",
                    onScroll: async (event) => {
                        const listboxNode = event.currentTarget;
                        if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                            await getAllCompany(value, Number(page) + 1)
                        }
                    }
                }}
                popupIcon={<KeyboardArrowDownIcon sx={{ width: '20px', height: '20px', fill: '#56617B' }} />}
                renderInput={(params) => {
                    if (companySelect?.id) {
                        companyList.map(x => {
                            if (x.id === companySelect.id) {
                                params.inputProps.value = x.attributes.name
                            }
                        })
                        let url = companySelect?.attributes?.picture_path === null ? '' : companySelect?.attributes?.picture_path
                        params.InputProps.startAdornment = (
                            <>
                                {url === "" ? (
                                    <>
                                        <Avatar sx={{ bgcolor: 'blue', mr: "12px", height: 28, width: 28, }} aria-label="recipe">
                                            PT
                                        </Avatar>
                                    </>
                                ) : (
                                    <>
                                        <IconButton sx={{ mr: '12px', p: 0 }}>
                                            <img style={{ borderRadius: "100%", objectFit: "cover" }} alt="img-icon" src={config.baseURL + url} height={28} width={28}></img>
                                        </IconButton>
                                    </>
                                )}
                            </>
                        )

                        return <TextField {...params} variant="outlined" />
                    } else {

                        return <TextField {...params} variant="outlined" placeholder={props.placeholder} sx={{
                            fontSize: "14px", input: {
                                fontSize: '14px', fontFamily: 'Satoshi-500'
                            },
                        }} />
                    }

                }}
            />
        </>
    );
}