import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, IconButton, Modal, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2';
import jualBatubaraIcon from "../assets/klaim-staff-cancel.png";
import { RemoveStaff } from "../services/api";
import { capitalizeEachWord, convertText } from '../services/primary';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: 343, lg: 472 },
    borderRadius: '8px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    paddingBottom: '25px',

};

export default function ModalRemoveStaff(props) {
    const [reason, setReason] = useState("")
    const [translate,] = useTranslation("global");
    const SubmitRemoveStaff = async () => {

        try {
            let params = {
                id_company: props.id_company,
                id_user_staff: props.id_user_staff,
                reason: reason
            }
            const responAPI = await RemoveStaff(params)
            if (responAPI.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: `${responAPI?.message}`,
                }).then(function () {
                    window.location.href = `/detail/${capitalizeEachWord(convertText(props.name_company))}`
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: translate('status.failed'),
                    text: translate('status.subtitlefailed'),
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div>
            <Modal
                open={props.open}
                onClose={props.close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ zIndex: 100000 }}
            >
                <Box sx={style}>

                    <Box sx={{ display: 'flex', justifyContent: 'right', mr: '16px', mt: '16px' }}>
                        <IconButton size="small" >
                            <CloseIcon sx={{ width: '24px', height: '24px' }} onClick={() => {
                                props.close();
                            }} />
                        </IconButton>
                    </Box>

                    <Box sx={{ mt: '4px', justifyContent: 'center', display: 'flex' }}>
                        <img alt="Batubara-icon" style={{ width: { xs: '100px', lg: '120px' }, height: { xs: '100px', lg: '120px' }, }} src={jualBatubaraIcon}></img>
                    </Box>

                    <Box sx={{ mt: '24px', mb: { lg: '50px' }, justifyContent: 'center', display: 'flex' }}>
                        {props.datatrader && <Typography className='w-700' sx={{ mb: { xs: '16px' }, height: '48px', width: { xs: '300px', lg: '342px' }, fontSize: '18px', color: '#081130', textAlign: 'center' }}>
                            {translate('status.titleremovestaff')} {props.datatrader.name}?
                        </Typography>}
                    </Box>

                    <Box sx={{ ml: { xs: '22px', lg: '43px' }, mr: { xs: '21px', lg: '43px' }, mb: { xs: '16px', lg: '24px' } }}>
                        <Typography className='w-500' sx={{ fontSize: { xs: '14px', lg: '14px' }, color: '#081130', mb: { xs: '4px' } }}>
                            {translate('status.alasandikeluarkan')}
                        </Typography>

                        <TextField
                            onChange={(e) => setReason(e.target.value)} value={reason}
                            InputProps={{
                                sx: {
                                    fieldset: { borderColor: 'var(--grey-stroke, #E8EBF2)', borderWidth: '1px solid' },
                                    p: '14px 16px', fontFamily: 'Satoshi', fontWeight: '400', fontSize: '14px', borderRadius: '8px', input: {
                                        fontSize: '14px', fontFamily: 'Satoshi-500'
                                    },
                                },
                            }}
                            id="outlined-multiline-static"
                            fullWidth
                            multiline
                            rows={4}
                            placeholder={translate('status.placeholder-removestaff')}
                        />



                    </Box>

                    <Box sx={{ ml: { xs: '21px', lg: '41px' }, mr: { xs: '21px', lg: '41px' }, mb: '29' }}>
                        <Stack direction={'row'} spacing={2}>
                            <Button className='button-blue-outlined' onClick={() => {
                                props.close();
                            }} variant="outlined" sx={{ p: '12px 16px', height: { xs: '44px', lg: '48px' }, width: { xs: '148px', lg: '186px' } }}>
                                <Typography className='w-500' sx={{ height: '22px', fontSize: { xs: '14px', lg: '16px' }, color: '#2457B9', textAlign: 'center', }}>
                                    {translate('jual-beli.btn-cancel')}
                                </Typography>
                            </Button>
                            <Button className='button-blue-contained' onClick={() => {
                                props.close();
                                SubmitRemoveStaff()
                            }} variant="contained" sx={{ p: '12px 16px', height: { xs: '44px', lg: '48px' }, width: { xs: '148px', lg: '186px' } }}>
                                <Typography className='w-500' sx={{ height: '22px', fontSize: { xs: '14px', lg: '16px' }, color: '#FFFFFF', textAlign: 'center' }}>
                                    {translate('status.keluarkan')}
                                </Typography>
                            </Button>
                        </Stack>
                    </Box>



                </Box>
            </Modal>
        </div>
    );
}