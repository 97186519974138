import Divider from "@material-ui/core/Divider";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CallIcon from '@mui/icons-material/Call';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Avatar, Box, Button, Grid, Hidden, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-multi-carousel/lib/styles.css";
import { useSearchParams, useNavigate } from 'react-router-dom';
import { FacebookShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import Slider from "react-slick";
import Swal from 'sweetalert2';
import ModalHapusPermintaan from "../components/ModalHapusPermintaan";
import Airicon from "../assets/air-ic.png";
import Ashicon from "../assets/ash-ic.png";
import { default as callCompanyIcon } from "../assets/call-company-ic.png";
import Calorieicon from "../assets/calorie-ic.png";
import copyIc from "../assets/copy-ic.png";
import fbIcon from "../assets/fb-circle-grey-ic.png";
import Feicon from "../assets/fe-ic.png";
import deleteIcon from "../assets/trash-ic.png";
import editIcon from "../assets/edit-listing-ic.png";
import PermintaanIcon from "../assets/icons/permintaan-ic.svg";
import laycanIcon from "../assets/laycan-ic.png";
import locationCompanyIcon from "../assets/location-company-ic.png";
import { default as mailCompanyIcon } from "../assets/mail-company-ic.png";
import Niicon from "../assets/ni-ic.png";
import pelayarIcon from "../assets/pelayar-ic.png";
import { default as PenambangIcon } from "../assets/penambang-ic.png";
import Ratioicon from "../assets/ratio-ic.png";
import reporticon from "../assets/reportflag-ic.png";
import SizetongkangIcon from "../assets/sizetongkang-ic.png";
import Sulfuricon from "../assets/sulfur-ic.png";
import telegramIcon from "../assets/telegram-circle-grey-ic.png";
import traderIcon from "../assets/trader-ic.png";
import twitterIcon from "../assets/twitter-circle-grey-ic.png";
import waIcon from "../assets/wa-circle-grey-ic.png";
import CardRecomendationTongkangList from "../components/CardRecomendationTongkangList";
import DrawerMore from "../components/DrawerMore";
import Footer from "../components/Footer";
import { Komentar } from "../components/KomentarSection";
import ModalReportListing from "../components/ModalReportListing";
import ModalShareListing from "../components/ModalShareListing";
import NavBar from "../components/Navbar";
import { addFavorite, checkFavorite, deleteFavorite, getDetailPermintaanBatubara, getDetailPermintaanNikel, getDetailPermintaanTongkang, searchBarge } from "../services/api";
import { config } from "../services/constants";
import { GlobalState } from "../services/global";
import { capitalizeEachWord, convertText, numberConvert, settingsRecommendasiTongkang } from '../services/primary';

function DetailPermintaanpage() {
  const [location, setLocation] = useState({
    state: {
      id: '',
      detail: ''
    }
  })
  const [detailTongkang, setDetailTongkang] = useState([])
  const [materialTongkang, setMaterialTongkang] = useState([])
  const [detailNikel, setDetailNikel] = useState([])
  const { currency } = React.useContext(GlobalState)
  const [translate] = useTranslation("global");
  const navigate = useNavigate()
  const [detailBatubara, setDetailBatubara] = useState([])
  const [imageTongkang, setImageTongkang] = useState([])
  const [docNikel, setDocNikel] = useState([])
  const [docBatubara, setDocBatubara] = useState([])
  const [tongkangList, setTongkangList] = useState([]);
  const [isOpenMore, setOpenMore] = React.useState(false)
  const [copyUrl, setCopyUrl] = useState(false)
  const [isOpenModalShare, setOpenModalShare] = React.useState(false)
  const [position, setPosition] = useState(true)
  const [searchParams,] = useSearchParams();
  const [activeTab, setActiveTab] = useState();
  const [openModalReport, setOpenModalReport] = useState(false)
  const [x, setX] = React.useState([])
  const [url_website, seturl_website] = useState([])
  const [type_data, settype_data] = useState([])
  const [isOpenModalHapus, setOpenModalHapus] = React.useState(false)

  useEffect(() => {
    let type = searchParams.get("type")
    settype_data(type)
    let id_detail = searchParams.get("id_detail")
    if (type === '1') {
      setActiveTab('coal')
      setLocation({
        state: {
          id: id_detail,
          detail: 'coal'
        }
      })
      getDataDetailBatubara()
    }
    if (type === '2') {
      setActiveTab('nikel')
      setLocation({
        state: {
          id: id_detail,
          detail: 'nikel'
        }
      })
      getDataDetailNikel()
    }
    if (type === '3') {
      setActiveTab('tongkang')
      setLocation({
        state: {
          id: id_detail,
          detail: 'tongkang'
        }
      })
      getDataDetailTongkang()
      getTongkangList()
    }

  }, [])

  useEffect(() => {
    let type = searchParams.get("type")
    settype_data(type)
    let id_detail = searchParams.get("id_detail")
    if (type === '1') {
      setActiveTab('coal')
      setLocation({
        state: {
          id: id_detail,
          detail: 'coal'
        }
      })
      getDataDetailBatubara()
    }
    if (type === '2') {
      setActiveTab('nikel')
      setLocation({
        state: {
          id: id_detail,
          detail: 'nikel'
        }
      })
      getDataDetailNikel()
    }
    if (type === '3') {
      setActiveTab('tongkang')
      setLocation({
        state: {
          id: id_detail,
          detail: 'tongkang'
        }
      })
      getDataDetailTongkang()
      getTongkangList()
    }

  }, [localStorage.getItem('lang')])

  const handleShareModal = () => {
    setOpenModalShare(true);

  }
  const closeShareModal = () => {
    setOpenModalShare(false);
  }

  const handleCloseModalHapus = () => {
    setOpenModalHapus(false);
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler)
    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
  })

  const scrollHandler = (event) => {
    let offset = window.pageYOffset
    if (offset > 200) {
      setPosition(true)
    } else {
      setPosition(false)
    }
  };

  async function getTongkangList() {
    try {
      const response = await searchBarge({
        page: 1,
        type_tongkang: "", ukuran_tongkang: '', max_quantity_muatan: '', min_quantity_muatan: '', max_price: '', min_price: '',
        bendera: "", tahun_buat: "", search_text: ""
      });
      setTongkangList(response.data?.data.data.data);
    } catch (e) {
      console.log(e);
    }
  }

  const getDataDetailBatubara = async () => {
    let id_detail = searchParams.get("id_detail")

    try {
      let lang = localStorage.getItem('lang') === 'null' || localStorage.getItem('lang') === 'id' ? 'id' : localStorage.getItem('lang')
      const responAPI = await getDetailPermintaanBatubara(id_detail, lang)
      await favoriteCheck(5)
      setDetailBatubara(responAPI.data.data)
      setDocBatubara(responAPI.data.data.batubara_documents === null ? [] : responAPI.data.data.batubara_documents)
    } catch (error) {
      setDetailBatubara("")
      console.log(error)
    }
  }


  const getDataDetailNikel = async () => {
    let id_detail = searchParams.get("id_detail")
    try {
      let lang = localStorage.getItem('lang') === 'null' || localStorage.getItem('lang') === 'id' ? 'id' : localStorage.getItem('lang')
      const responAPI = await getDetailPermintaanNikel(id_detail, lang)
      setDetailNikel(responAPI.data.data)
      setDocNikel(responAPI.data.data.nikel_documents === null ? [] : responAPI.data.data.nikel_documents)
      await favoriteCheck(6)
    } catch (error) {
      setDetailNikel("")
      console.log(error)
    }
  }



  const getDataDetailTongkang = async () => {
    let id_detail = searchParams.get("id_detail")
    try {
      let lang = localStorage.getItem('lang') === 'null' || localStorage.getItem('lang') === 'id' ? 'id' : localStorage.getItem('lang')
      const responAPI = await getDetailPermintaanTongkang(id_detail, lang)
      await favoriteCheck(7)
      setDetailTongkang(responAPI.data.data)
      let filter = responAPI.data.data.ukuran_tongkang_ft.split(",");
      setX(filter.filter(item => item));

      setMaterialTongkang(responAPI.data.data.jenis_muatan === null ? [] : responAPI.data.data.jenis_muatan.split(","))
      setImageTongkang(responAPI.data.data.tongkang_pictures === null ? [] : responAPI.data.data.tongkang_pictures)
    } catch (error) {
      setDetailTongkang("")
      console.log(error)
    }
  }

  const [checkingFavorite, setCheckingFavorite] = useState(false);

  const favoriteCheck = async (type_product) => {
    let id_detail = searchParams.get("id_detail")

    try {
      let params = {
        id_product: id_detail,
        type_product: type_product
      }
      const responAPI = await checkFavorite(params)
      let is_favorite = responAPI.data.is_favorite
      setCheckingFavorite(is_favorite)
    } catch (error) {
      console.log(error)
    }
  }

  const setFavorite = (e) => {
    let id_detail = searchParams.get("id_detail")
    addFavorite({ type: e, id_relation: parseInt(id_detail) })
    setCheckingFavorite(true)
  }

  const removeFavorite = (e) => {
    let id_detail = searchParams.get("id_detail")
    deleteFavorite({ type_product: parseInt(e), id_product: parseInt(id_detail) })
    setCheckingFavorite(false)
  }

  const handleOpenModal = (state) => {
    if (state === 'more') {
      setOpenMore(true);
    }else if (state === 'hapus') {
      setOpenModalHapus(true);
    }
  };

  // const handleOpenModalReport = () => {
  //   setOpenModalReport(true);
  // }

  const handleCloseModal = () => {
    setOpenMore(false);
    setOpenModalReport(false);
  };

  const handleNavigate = (id_company, name_company) => {
    let filter_name = capitalizeEachWord(convertText(name_company))
    window.location.href = `/detail/${filter_name}`
  };


  const SubmitReportListing = async () => {

    let type = searchParams.get("type")
    seturl_website(`${config.staging}/permintaan/detail?type=${type}&id_detail=${location.state.id}`)
    setOpenModalReport(true)
    // try {
    //   let params =
    //   {
    //     "data": {
    //       "reason": '',
    //       "type": type,
    //       "id_relation": location.state.id,
    //       token: localStorage.getItem("token")
    //     }
    //   }

    //   const responAPI = await reportListing(params)
    //   if (responAPI.status === 200) {
    //     handleOpenModalReport();
    //   } else {
    //     Swal.fire({
    //       icon: 'error',
    //       title: translate('status.failed'),
    //       text: translate('status.subtitlefailed'),
    //     })
    //   }
    // } catch (error) {
    //   console.log(error)
    // }
  }

  function copyLink(e) {
    setCopyUrl(true)
    setTimeout(() => {
      setCopyUrl(false)
    }, 1000);
  }

  const url_copy = window.location.href
  const copyClipboard = <div className="copyToClipboard" style={{ opacity: copyUrl ? "1" : "0" }}>✔ Copied to clipboard</div>
  return (
    <div className="detail-explore width-100">
      <div className="flex-wrap-center relative width-100">
        <div className="container-nav relative width-100">
          <div className='fixed-header'>
            <NavBar></NavBar>
          </div>
        </div>
        <Box className="flex-wrap-center width-100" sx={{ mt: { xs: 6, sm: 6, md: 11, lg: 11, xl: 11 }, }}>
          <div className="content-inner">
            {activeTab !== 'trader' ?
              <Stack
                sx={{ mt: '16px' }}
                alignItems={'center'}
                direction="row"
              >
                <Button href="/" className="w-500" sx={{ fontSize: 16, mb: 1, color: '#2457B9', textTransform: 'none', pl: 0 }} variant="text">  {translate('page_explore.home')}</Button>
                <Typography className="w-500" sx={{ fontSize: 16, mb: 1, color: '#56617B' }}>
                  /
                </Typography>
                <Button href="/permintaan" className="w-500" sx={{ fontSize: 16, mb: 1, color: '#2457B9', textTransform: 'none' }} variant="text">       {translate('navbar.permintaan')}</Button>

                <Typography className="w-500" sx={{ fontSize: 16, mb: 1, mr: 1, color: '#2457B9' }}>
                  /
                </Typography>
                {activeTab === 'coal' ? <Typography className="w-500" sx={{ fontSize: 16, mb: 1 }}>
                  {translate('detail-explore.detailbatubara')}
                </Typography> : null}
                {activeTab === 'nikel' ? <Typography className="w-500" sx={{ fontSize: 16, mb: 1 }}>
                  {translate('detail-explore.detailnikel')}
                </Typography> : null}
                {activeTab === 'tongkang' ? <Typography className="w-500" sx={{ fontSize: 16, mb: 1 }}>
                  {translate('detail-explore.detailtongkang')}
                </Typography> : null}

              </Stack> : ''}
            <Grid
              className="test-grid"
              container
              direction="row"
              rowSpacing={{ xs: 5, lg: 7 }}
              columnSpacing={{ xs: 5, lg: 7 }}
              sx={{ mb: 2 }}
            >
              {/* leftside Section */}
              <Grid lg={8.4} xs={12} item sx={{ width: activeTab === 'trader' ? '1280px' : '840px' }}>
                {/* coal Section */}
                {activeTab === 'coal' ?
                  <Box sx={{ mb: 2, }}>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={0}
                    >
                      <Grid item >

                        <Stack direction={'row'} spacing={1}>
                          <Typography
                            sx={{ fontSize: 18, color: '#081130' }}
                            className="w-700"
                          >
                            {detailBatubara.code_product}
                          </Typography>
                          <Box
                            sx={{
                              background: "rgba(139, 239, 179, 0.25);",
                              borderRadius: '300px',
                              p: '2px 12px',
                              width: '100px',
                              height: '24px'
                            }}
                          >
                            <Stack direction={'row'} alignItems={'center'}>
                              <img alt="icon" src={PermintaanIcon} height={18}></img>
                              <Typography className="w-500" sx={{ color: '#3BB56C', fontSize: 14, ml: '4px' }} >
                                {translate('navbar.permintaan')}
                              </Typography>

                            </Stack>
                          </Box>

                        </Stack>

                        <Grid
                          container
                          justifyContent="left"
                          alignItems="center"
                          direction="row"
                        >
                          <Typography
                            className="w-500"
                            sx={{ fontSize: 14, color: "#56617B", mr: 1 }}
                          >
                            {translate('page_explore.listingoleh')} :
                          </Typography>
                          <Avatar sx={{ bgcolor: 'blue', width: '20px', height: '20px', mr: 1 }} aria-label="recipe">

                          </Avatar>
                          {detailBatubara.user_name &&
                            <Typography
                              className="w-500"
                              sx={{ fontSize: 14, color: "#081130", mr: 1 }}
                            >
                              {detailBatubara.user_name}
                            </Typography>}
                          <Typography
                            className="w-500"
                            sx={{ fontSize: 14, color: "#56617B", mr: 1 }}
                          >
                            {translate('detail-explore.permintaanpada')}
                          </Typography>
                          {detailBatubara.createdAt &&
                            <Typography
                              className="w-500"
                              sx={{ fontSize: 14, color: "#081130", mr: 1 }}
                            >
                              {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(detailBatubara.createdAt))}
                            </Typography>}
                        </Grid>
                      </Grid>
                      <Grid sx={{ textAlign: "left", alignItems: "center", display: 'flex', mt: 1, mb: 1 }} item >

                        <Typography
                          sx={{ fontSize: '24px', color: '#081130', mr: 1 }}
                          className="w-700"
                        >
                          {currency === 'idr' ? 'Rp' : '$'} {numberConvert(parseInt(detailBatubara?.price))}
                        </Typography>
                        <Typography
                          sx={{ fontSize: 14, color: '#56617B' }}
                          className="w-500"
                        >
                          /ton
                        </Typography>
                      </Grid>

                    </Grid>
                    <Divider style={{ backgroundColor: "#E8EBF2", width: '100%', marginTop: '24px', marginBottom: '24px' }} />

                    <Grid
                      container
                      direction="row"
                      alignItems="left"
                      justifyContent="left"
                      spacing={2}
                    >
                      <Grid item xs={6} md={3}>
                        <Box sx={{ background: "#F4F6FA", borderRadius: 4, padding: 2 }}>
                          <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>

                            {detailBatubara.calorie && <Typography className="w-700" sx={{ color: '#081130', fontSize: { md: '20px', xs: '18px' }, }}>
                              {(detailBatubara.calorie).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                            </Typography>}
                            <img alt="Nikel-icon" src={Calorieicon} height={20}></img>
                          </Stack>
                          <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                            Gar
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item xs={6} md={3}>
                        <Box sx={{ background: "#F4F6FA", borderRadius: 4, padding: 2 }}>
                          <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>
                            {detailBatubara.total_sulfur && <Typography className="w-700" sx={{ color: '#081130', fontSize: { md: '20px', xs: '18px' }, }}>
                              {(detailBatubara.total_sulfur).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                            </Typography>}
                            <img alt="Nikel-icon" src={Sulfuricon} height={20}></img>
                          </Stack>
                          <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                            {translate('page_explore.total-sulfur')}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item xs={6} md={3}>
                        <Box sx={{ background: "#F4F6FA", borderRadius: 4, padding: 2 }}>
                          <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>

                            {detailBatubara.ASH && <Typography className="w-700" sx={{ color: '#081130', fontSize: { md: '20px', xs: '18px' }, }}>
                              {(detailBatubara.ASH).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                            </Typography>}
                            <img alt="Nikel-icon" src={Ashicon} height={20}></img>
                          </Stack>
                          <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                            Ash
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item xs={6} md={3}>
                        <Box sx={{ background: "#F4F6FA", borderRadius: 4, padding: 2 }}>
                          <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>

                            {detailBatubara.quantity && <Typography className="w-700" sx={{ color: '#081130', fontSize: { md: '20px', xs: '18px' }, display: 'inline', wordBreak: "break-all", }}>
                              {(detailBatubara.quantity).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                            </Typography>}
                            <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                              MT
                            </Typography>
                          </Stack>
                          <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                            Quantity
                          </Typography>
                        </Box>

                      </Grid>
                    </Grid>
                    <Typography
                      sx={{ fontSize: 14, color: '#818CA7', mt: '24px' }}
                      className="w-500"
                    >
                      {translate('detail-explore.lokasiloadingport')}
                    </Typography>

                    <Typography
                      sx={{ fontSize: 14, color: '#081130', }}
                      className="w-700"
                    >
                      {detailBatubara?.loading_port ? capitalizeEachWord(detailBatubara?.loading_port.name) : '-'}

                    </Typography>
                    <Typography
                      sx={{ fontSize: 14, mt: 1, color: '#818CA7' }}
                      className="w-500"
                    >
                      {translate('jual-beli.nama-tambang')}
                    </Typography>

                    <Typography
                      sx={{ fontSize: 14, color: '#081130' }}
                      className="w-700"
                    >
                      {detailBatubara?.company_listing ?
                        detailBatubara?.company_listing.jenis_perusahaan + ' ' + capitalizeEachWord(detailBatubara?.company_listing.name) : '-'}
                    </Typography>
                    <Typography
                      sx={{ fontSize: 14, mt: 1, color: '#818CA7' }}
                      className="w-500"
                    >
                      {translate('jual-beli.nama-trader')}
                    </Typography>

                    <Typography
                      sx={{ fontSize: 14, color: '#081130' }}
                      className="w-700"
                    >
                      {detailBatubara?.user_trader_name ?
                        capitalizeEachWord(detailBatubara?.user_trader_name) : '-'}
                    </Typography>

                    <Typography
                      sx={{ fontSize: 14, mt: 1, color: '#818CA7' }}
                      className="w-500"
                    >
                      Laycan Date
                    </Typography>

                    <Typography
                      sx={{ fontSize: 14, color: '#081130' }}
                      className="w-700"
                    >
                      {detailBatubara?.start_laycane_date ?
                        `${new Date(detailBatubara.start_laycane_date).toLocaleDateString('en-AU')}` : '-'} -
                      {detailBatubara?.end_laycane_date ?
                        `${new Date(detailBatubara.end_laycane_date).toLocaleDateString('en-AU')}` : '-'}
                    </Typography>
                    <Divider style={{ backgroundColor: "#E8EBF2", width: '100%', marginTop: '24px' }} />

                  </Box> : null}
                {/* nikel Section */}
                {activeTab === 'nikel' ?
                  <Box sx={{ mb: 2 }}>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={0}
                    >
                      <Grid item >

                        <Stack direction={'row'} spacing={1}>
                          <Typography
                            sx={{ fontSize: 18, color: '#081130' }}
                            className="w-700"
                          >
                            {detailNikel.code_product}
                          </Typography>
                          <Box
                            sx={{
                              background: "rgba(139, 239, 179, 0.25);",
                              borderRadius: '300px',
                              p: '2px 12px',
                              width: '100px',
                              height: '24px'
                            }}
                          >
                            <Stack direction={'row'} alignItems={'center'}>
                              <img alt="icon" src={PermintaanIcon} height={18}></img>
                              <Typography className="w-500" sx={{ color: '#3BB56C', fontSize: 14, ml: '4px' }} >
                                {translate('navbar.permintaan')}
                              </Typography>

                            </Stack>
                          </Box>

                        </Stack>


                        <Grid
                          container
                          justifyContent="left"
                          alignItems="center"
                          direction="row"
                        >
                          <Typography
                            className="w-500"
                            sx={{ fontSize: 14, color: "#56617B", mr: 1 }}
                          >
                            {translate('page_explore.listingoleh')} :
                          </Typography>
                          {
                            detailNikel?.user_picture?.url !== undefined ? (
                              <img style={{ marginRight: '8px', borderRadius: "100%" }} alt="img-icon" src={config.baseURL + detailNikel.user_picture.url} height={20} width={20}></img>
                            )
                              : (
                                <Avatar sx={{ bgcolor: 'blue', width: '20px', height: '20px', mr: 1 }} aria-label="recipe">
                                </Avatar>
                              )
                          }

                          <Typography
                            className="w-500"
                            sx={{ fontSize: 14, color: "#081130", mr: 1 }}
                          >
                            {detailNikel.user_name}
                          </Typography>
                          <Typography
                            className="w-500"
                            sx={{ fontSize: 14, color: "#56617B", mr: 1 }}
                          >
                            {translate('detail-explore.permintaanpada')}
                          </Typography>
                          <Typography
                            className="w-500"
                            sx={{ fontSize: 14, color: "#081130", mr: 1 }}
                          >
                            {`${new Date(detailNikel.createdAt).toLocaleDateString('en-AU')}`}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid sx={{ textAlign: "left", alignItems: "center", display: 'flex', mt: 1, mb: 1 }} item >
                        <Typography
                          sx={{ fontSize: '24px', color: '#081130', mr: 1 }}
                          className="w-700"
                        >
                          {currency === 'idr' ? 'Rp' : '$'} {numberConvert(parseInt(detailNikel?.price))}
                        </Typography>
                        <Typography
                          sx={{ fontSize: 14, color: '#56617B' }}
                          className="w-500"
                        >
                          /ton
                        </Typography>
                      </Grid>

                    </Grid>
                    <Divider style={{ backgroundColor: "#E8EBF2", width: '100%', marginTop: '24px', marginBottom: '24px' }} />
                    <Grid
                      container
                      direction="row"
                      alignItems="left"
                      justifyContent="left"
                      spacing={2}
                    >
                      <Grid item xs={6} md={3} >
                        <Box sx={{ background: "#F4F6FA", borderRadius: 4, padding: 2 }}>
                          <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>
                            {detailNikel.kadar_ni && <Typography className="w-700" sx={{ color: '#081130', fontSize: { md: '20px', xs: '18px' }, }}>
                              {(detailNikel.kadar_ni).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                            </Typography>}
                            <img alt="Nikel-icon" src={Niicon} height={20}></img>
                          </Stack>
                          <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                            {translate('page_explore.kadar-ni')}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6} md={3}  >
                        <Box sx={{ background: "#F4F6FA", borderRadius: 4, padding: 2 }}>
                          <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>

                            {detailNikel.kadar_fe && <Typography className="w-700" sx={{ color: '#081130', fontSize: { md: '20px', xs: '18px' }, }}>
                              {(detailNikel.kadar_fe).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                            </Typography>}
                            <img alt="Nikel-icon" src={Feicon} height={20}></img>
                          </Stack>
                          <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                            {translate('page_explore.kadar-fe')}
                          </Typography>
                        </Box>


                      </Grid>
                      <Grid item xs={6} md={3}  >
                        <Box sx={{ background: "#F4F6FA", borderRadius: 4, padding: 2 }}>
                          <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>
                            {detailNikel.kadar_air && <Typography className="w-700" sx={{ color: '#081130', fontSize: { md: '20px', xs: '18px' }, }}>
                              {(detailNikel.kadar_air).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                            </Typography>}
                            <img alt="Nikel-icon" src={Airicon} height={20}></img>
                          </Stack>
                          <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                            {translate('page_explore.kadar-air')}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6} md={3}  >
                        <Box sx={{ background: "#F4F6FA", borderRadius: 4, padding: 2 }}>
                          <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>
                            {detailNikel.si_mg_ratio && <Typography className="w-700" sx={{ color: '#081130', fontSize: { md: '20px', xs: '18px' }, }}>
                              {(detailNikel.si_mg_ratio).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                            </Typography>}
                            <img alt="Nikel-icon" src={Ratioicon} height={20}></img>
                          </Stack>
                          <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                            Si/Mg
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6} md={3} >
                        <Box sx={{ background: "#F4F6FA", borderRadius: 4, padding: 2 }}>
                          <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>

                            {detailNikel.quantity && <Typography className="w-700" sx={{ color: '#081130', fontSize: { md: '20px', xs: '18px' }, display: 'inline', wordBreak: "break-all", }}>
                              {(detailNikel.quantity).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                            </Typography>}
                            <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                              MT
                            </Typography>
                          </Stack>
                          <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                            Quantity
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Typography
                      sx={{ fontSize: 14, color: '#818CA7', mt: '24px' }}
                      className="w-500"
                    >
                      {translate('detail-explore.lokasiloadingport')}
                    </Typography>

                    <Typography
                      sx={{ fontSize: 14, color: '#081130', }}
                      className="w-700"
                    >
                      {detailNikel?.loading_port ? capitalizeEachWord(detailNikel?.loading_port.name) : '-'}

                    </Typography>
                    <Typography
                      sx={{ fontSize: 14, mt: 1, color: '#818CA7' }}
                      className="w-500"
                    >
                      {translate('jual-beli.nama-tambang')}
                    </Typography>

                    <Typography
                      sx={{ fontSize: 14, color: '#081130' }}
                      className="w-700"
                    >
                      {detailNikel?.company_listing ?
                        detailNikel?.company_listing.jenis_perusahaan + ' ' + capitalizeEachWord(detailNikel?.company_listing.name) : '-'}
                    </Typography>
                    <Typography
                      sx={{ fontSize: 14, mt: 1, color: '#818CA7' }}
                      className="w-500"
                    >
                      {translate('jual-beli.nama-trader')}
                    </Typography>

                    <Typography
                      sx={{ fontSize: 14, color: '#081130' }}
                      className="w-700"
                    >
                      {detailNikel?.user_trader_name ?
                        capitalizeEachWord(detailNikel?.user_trader_name) : '-'}
                    </Typography>

                    <Typography
                      sx={{ fontSize: 14, mt: 1, color: '#818CA7' }}
                      className="w-500"
                    >
                      Laycan Date
                    </Typography>

                    <Typography
                      sx={{ fontSize: 14, color: '#081130' }}
                      className="w-700"
                    >
                      {detailNikel?.start_laycane_date ?
                        `${new Date(detailNikel.start_laycane_date).toLocaleDateString('en-AU')}` : '-'} -
                      {detailNikel?.end_laycane_date ?
                        `${new Date(detailNikel.end_laycane_date).toLocaleDateString('en-AU')}` : '-'}
                    </Typography>
                    <Divider style={{ backgroundColor: "#E8EBF2", width: '100%', marginTop: '24px' }} />


                  </Box> : null}
                {/* tongkang Section */}
                {activeTab === 'tongkang' ?
                  <Box sx={{ mb: 2 }}>

                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={0}
                    >
                      <Grid item >
                        <Stack direction={'row'} alignItems="center">

                          <Typography
                            sx={{ fontSize: 28, color: '#081130', mr: 1 }}
                            className="w-700"
                          >
                            {detailTongkang.code_product}
                          </Typography>
                          <Box
                            sx={{
                              top: '10%',
                              background: "#F4F6FA",
                              borderRadius: 4,
                              pr: 2,
                              pl: 2,
                              pb: '2px',
                              pt: '2px',

                            }}
                          >
                            <Stack direction={'row'} alignItems={'center'}>
                              {detailTongkang.type_tongkang === 1 ? (
                                <>
                                  <Box sx={{ borderRadius: '50%', background: '#F7CE46', width: '10px', height: '10px', mr: '4px' }}>
                                  </Box>
                                  <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 12 }} >
                                    {translate('status.jual')}
                                  </Typography>
                                </>

                              ) : detailTongkang.type_tongkang === 2 ? (
                                <>
                                  <Box sx={{ borderRadius: '50%', background: '#F55C24', width: '10px', height: '10px', mr: '4px' }}>
                                  </Box>
                                  <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 12 }} >
                                    Time Charter
                                  </Typography>
                                </>
                              ) : detailTongkang.type_tongkang === 3 ? (
                                <>
                                  <Box sx={{ borderRadius: '50%', background: '#883BE7', width: '10px', height: '10px', mr: '4px' }}>
                                  </Box>
                                  <Typography className="w-500" sx={{ color: '#818CA7', fontSize: '12px' }} >
                                    Freight Charter
                                  </Typography>
                                </>
                              ) : detailTongkang.type_tongkang === 4 ? (
                                <>
                                  <Box sx={{ borderRadius: '50%', background: '#2860CD', width: '10px', height: '10px', mr: '4px' }}>
                                  </Box>
                                  <Typography className="w-500" sx={{ color: '#818CA7', fontSize: '12px' }} >
                                    {translate('status.beli')}
                                  </Typography>
                                </>
                              ) : (
                                <></>
                              )}
                            </Stack>
                          </Box>
                        </Stack>

                        <Grid
                          container
                          justifyContent="left"
                          alignItems="center"
                          direction="row"
                        >

                          <Typography
                            className="w-500"
                            sx={{ fontSize: 14, color: "#56617B", mr: 1 }}
                          >
                            {translate('page_explore.listingoleh')} :
                          </Typography>
                          {
                            detailTongkang?.user_picture?.url !== undefined ? (
                              <img style={{ marginRight: '8px', borderRadius: "100%" }} alt="img-icon" src={config.baseURL + detailTongkang.user_picture.url} height={20} width={20}></img>
                            )
                              : (
                                <Avatar sx={{ bgcolor: 'blue', width: '20px', height: '20px', mr: 1 }} aria-label="recipe">
                                </Avatar>
                              )
                          }
                          <Typography
                            className="w-500"
                            sx={{ fontSize: 14, color: "#081130", mr: 1 }}
                          >
                            {detailTongkang.user_name}
                          </Typography>
                          <Typography
                            className="w-500"
                            sx={{ fontSize: 14, color: "#56617B", mr: 1 }}
                          >
                            {translate('detail-explore.permintaanpada')} :
                          </Typography>
                          <Typography
                            className="w-500"
                            sx={{ fontSize: 14, color: "#081130", mr: 1 }}
                          >
                            {`${new Date(detailTongkang.createdAt).toLocaleDateString('en-AU')}`}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid direction={'row'} sx={{ textAlign: "left", alignItems: "center", display: 'flex', mt: 1, mb: 1 }} item >
                        <Typography
                          sx={{ fontSize: '24px', color: '#081130', mr: 1 }}
                          className="w-700"
                        >
                          {currency === 'idr' ? 'Rp' : '$'} {numberConvert(parseInt(detailTongkang?.price))}
                        </Typography>
                        <Typography
                          sx={{ fontSize: 14, color: '#56617B' }}
                          className="w-500"
                        >
                          /month
                        </Typography>
                      </Grid>

                    </Grid>
                    <Box>
                      <Divider style={{ backgroundColor: "#E8EBF2", width: '100%', marginTop: '24px', marginBottom: '24px' }} />
                      <Typography
                        sx={{ fontSize: '24px', color: '#081130' }}
                        className="w-700"
                      >
                        {detailTongkang.nama_tug_boat}
                      </Typography>
                      <Typography
                        sx={{ fontSize: 18, color: '#56617B', mb: '24px' }}
                        className="w-700"
                      >
                        {detailTongkang.nama_tongkang}
                      </Typography>
                    </Box>
                    <Typography className="w-500" sx={{ color: '#56617B', fontSize: 14, mt: 1, mb: 1 }} >
                      {translate('page_explore.ukurantongkang')}
                    </Typography>
                    {detailTongkang.type_tongkang === 3 && <Grid container spacing={1}>

                      {
                        x.map((data) => {

                          return (
                            <Grid item>
                              <Box sx={{ background: "#F4F6FA", borderRadius: 4, padding: 2 }}>
                                <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>
                                  <Stack direction={'row'} alignItems={'center'}>
                                    <Typography className="w-700" sx={{ color: '#081130', fontSize: 18 }}>
                                      {data}
                                    </Typography>

                                  </Stack>

                                </Stack>

                              </Box>
                            </Grid>
                          )

                        })}
                    </Grid>}

                    <Grid
                      sx={{ mt: '24px', mb: '24px' }}

                      container
                      direction="row"
                      alignItems="left"
                      justifyContent="left"
                      spacing={2}
                    >

                      {detailTongkang.type_tongkang === 2 && <Grid item xs={6} md={3}>
                        <Box sx={{ background: "#F4F6FA", borderRadius: 4, padding: 2 }}>
                          <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>
                            <Stack direction={'row'} alignItems={'center'}>
                              {detailTongkang.ukuran_tongkang && <Typography className="w-700" sx={{ color: '#081130', fontSize: { md: '20px', xs: '18px' } }}>
                                {(detailTongkang.ukuran_tongkang).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                              </Typography>}
                              <Typography className="w-700" sx={{ color: '#56617B', fontSize: { md: '12px', xs: '12px' }, ml: 1 }}>
                                FT
                              </Typography>
                            </Stack>

                            <img alt="Nikel-icon" src={SizetongkangIcon} height={20}></img>
                          </Stack>
                          <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                            {translate('page_explore.ukurantongkang')}
                          </Typography>
                        </Box>
                      </Grid>
                      }


                      <Grid item xs={6} md={3} >
                        <Box sx={{ background: "#F4F6FA", borderRadius: 4, padding: 2 }}>
                          <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>
                            <Typography className="w-700" sx={{ color: '#081130', fontSize: { md: '20px', xs: '18px' } }}>
                              {detailTongkang.tahun_buat ? detailTongkang.tahun_buat : '-'}
                            </Typography>
                            <img alt="Nikel-icon" src={laycanIcon} height={20}></img>
                          </Stack>
                          <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                            {translate('permintaan.tahunbuat')}
                          </Typography>
                        </Box>
                      </Grid>
                      {/* Quantity muatan Section */}
                      {(detailTongkang?.quantity_muatan !== 0 && detailTongkang?.type_tongkang === 3) &&
                        <Grid item xs={6} md={3}  >
                          <Box sx={{ background: "#F4F6FA", borderRadius: 4, padding: 2 }}>
                            <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>

                              {detailTongkang.quantity_muatan && <Typography className="w-700" sx={{ color: '#081130', fontSize: { md: '20px', xs: '18px' }, display: 'inline', wordBreak: "break-all", }}>
                                {(detailTongkang.quantity_muatan).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                              </Typography>}
                              <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                                MT
                              </Typography>
                            </Stack>
                            <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                              Quantity
                            </Typography>
                          </Box>
                        </Grid>
                      }
                    </Grid>

                    {/* Jenis muatan Section */}
                    {(materialTongkang.length > 0 && detailTongkang?.type_tongkang === 3) &&
                      <div>
                        <Typography
                          sx={{ fontSize: 14, color: '#818CA7', mb: '2px' }}
                          className="w-500"
                        >

                          {translate('detail-explore.jenismuatanditerima')}
                        </Typography>
                        <Stack direction={'row'} sx={{ gap: '10px', mb: 2 }}>
                          {materialTongkang?.map((item) => (
                            <Box sx={{ background: "#818CA7", borderRadius: 4, padding: '2px 12px' }}>
                              <Typography
                                sx={{ fontSize: 14, color: '#FFFFFF' }}
                                className="w-500"
                              >
                                {item}
                              </Typography>
                            </Box>
                          ))}
                        </Stack>
                      </div>}
                    <Box>
                      <Typography
                        sx={{ fontSize: 14, color: '#56617B' }}
                        className="w-500"
                      >
                        Loading Area
                      </Typography>
                      <Typography
                        sx={{ fontSize: 14, color: '#081130', mt: '4px' }}
                        className="w-700"
                      >
                        {detailTongkang?.area_muat ? capitalizeEachWord(detailTongkang?.area_muat.name) : '-'}

                      </Typography>
                      {/* Discharge Area Section */}
                      {(detailTongkang?.area_bongkar && detailTongkang?.type_tongkang === 3) &&

                        <div>
                          <Typography
                            sx={{ fontSize: 14, color: '#56617B', mt: '4px' }}
                            className="w-500"
                          >
                            Discharge Area
                          </Typography>
                          <Typography
                            sx={{ fontSize: 14, color: '#081130', mt: '4px' }}
                            className="w-700"
                          >
                            {detailTongkang?.area_bongkar ? capitalizeEachWord(detailTongkang?.area_bongkar.name) : '-'}

                          </Typography>
                        </div>
                      }

                      {/* Pelabuhan Muat Section */}
                      {(detailTongkang?.pelabuhan_muat && detailTongkang?.type_tongkang === 3) &&

                        <div>
                          <Typography
                            sx={{ fontSize: 14, color: '#56617B', mt: '4px' }}
                            className="w-500"
                          >
                            Loading Port
                          </Typography>
                          <Typography
                            sx={{ fontSize: 14, color: '#081130', mt: '4px' }}
                            className="w-700"
                          >
                            {detailTongkang?.pelabuhan_muat ? capitalizeEachWord(detailTongkang?.pelabuhan_muat.name) : '-'}

                          </Typography>
                        </div>
                      }
                      {/* Pelabuhan bongkar Section */}
                      {(detailTongkang?.pelabuhan_bongkar && detailTongkang?.type_tongkang === 3) &&

                        <div>
                          <Typography
                            sx={{ fontSize: 14, color: '#56617B', mt: '4px' }}
                            className="w-500"
                          >
                            Discharge Port
                          </Typography>
                          <Typography
                            sx={{ fontSize: 14, color: '#081130', mt: '4px' }}
                            className="w-700"
                          >
                            {detailTongkang?.pelabuhan_bongkar ? capitalizeEachWord(detailTongkang?.pelabuhan_bongkar.name) : '-'}

                          </Typography>
                        </div>
                      }

                      <Divider style={{ backgroundColor: "#E8EBF2", width: '100%', marginTop: '24px', marginBottom: '24px' }} />
                    </Box>
                  </Box> : null}
              </Grid>
              {/* rightside Section */}
              <Grid lg={3.6} xs={12} item>
                {activeTab === 'coal' ?
                  <Box >
                    <Box sx={{ border: 1, color: '#E8EBF2', borderRadius: 2, pt: 2 }}>
                      {(detailBatubara.company_listing !== undefined && detailBatubara.company_listing !== null) &&
                        <Box>
                          <Typography className="w-700" sx={{ color: '#081130', fontSize: 18, mt: 1, pl: 2 }}>
                            {translate('detail-explore.tentangperusahaan')}
                          </Typography>
                          <Box sx={{ pl: 2, pr: 2, mt: 2, }}>
                            <Grid
                              container
                              direction="row"
                              alignItems="center"
                              justifyContent="left"

                            >
                              {detailBatubara.company_listing?.picture_path?.url !== undefined &&
                                <Avatar sx={{ bgcolor: 'blue', mr: '16px' }} aria-label="recipe" src={config.baseURL + detailBatubara.company_listing?.picture_path?.url}>
                                </Avatar>
                              }
                              <Stack style={{ textAlign: "left", color: "white" }} spacing={0}>
                                <Typography
                                  sx={{ fontSize: 16, color: '#081130' }}
                                  className="w-700"
                                >
                                  {detailBatubara.company_listing.jenis_perusahaan} {capitalizeEachWord(detailBatubara.company_listing.name)}
                                </Typography>
                                <Grid className="pointer" onClick={(e) => handleNavigate(detailBatubara.company_listing.id, detailBatubara.company_listing.name)}
                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 16, color: "#2457B9", mr: 1 }}
                                  >
                                    {translate('detail-explore.lihatdetailperusahaan')}
                                  </Typography>
                                  <ArrowForwardIosIcon sx={{ fontSize: 12, color: "#2457B9", pt: '3px' }} />
                                </Grid>
                              </Stack>
                            </Grid>
                          </Box>
                          <Box sx={{ ml: 2, mr: 2, mt: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0 }, mb: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0 }, background: { xs: "#F4F6FA", sm: "#F4F6FA", md: "#F4F6FA", lg: "none", xl: "none" }, borderRadius: 4, padding: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0 } }}>
                            <Box sx={{ pr: 2, mb: 2, mt: { xs: 0, sm: 0, md: 0, lg: 1, xl: 1 } }}>
                              {detailBatubara.company_listing.bidang_perusahaan === 1 ? (
                                <Grid
                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <img alt="penambang-icon" src={PenambangIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                  >
                                    {translate('page_explore.penambang')}
                                  </Typography>

                                </Grid>
                              ) : detailBatubara.company_listing.bidang_perusahaan === 2 ? (
                                <Grid
                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <img alt="penambang-icon" src={traderIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                  >
                                    Trader
                                  </Typography>

                                </Grid>
                              ) : detailBatubara.company_listing.bidang_perusahaan === 3 ? (
                                <Grid
                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <img alt="penambang-icon" src={pelayarIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                  >
                                    {translate('page_explore.pelayaran')}
                                  </Typography>
                                </Grid>
                              ) : (
                                <></>
                              )}
                            </Box>
                            <Box sx={{ pr: 2, mt: 1, mb: 2 }}>
                              {detailBatubara.company_listing &&
                                <Stack
                                  container
                                  // justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <img alt="call-icon" src={locationCompanyIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                  >
                                    {detailBatubara.company_listing?.alamat[0]?.detail_alamat}
                                  </Typography>

                                </Stack>}
                            </Box>
                            <Box sx={{ pr: 2, mt: 1, mb: 2 }}>
                              {detailBatubara.company_listing.phone_number &&
                                <Grid

                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <img alt="Nikel-icon" src={callCompanyIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                  >
                                    {detailBatubara.company_listing.phone_number}
                                  </Typography>

                                </Grid>}
                            </Box>
                            <Box sx={{ pr: 2, mt: 1, mb: 2 }}>
                              {detailBatubara.company_listing.email &&
                                <Grid
                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <img alt="Nikel-icon" src={mailCompanyIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                  >
                                    {detailBatubara.company_listing.email}
                                  </Typography>
                                </Grid>}
                            </Box>
                          </Box>
                        </Box>}
                      <Hidden lgDown>
                        <Box sx={{ pl: 2, pr: 2, mb: 2 }}>
                          {detailBatubara?.id !== null && Number(detailBatubara?.user_id) == Number(localStorage.getItem("id_user")) ? <Button className="w-500" size="medium" onClick={(e) => navigate('/beli-batubara', { state: { page: 'edit-batubara', data: detailBatubara } })} sx={{
                            fontSize: 14,
                            p: '12px 16px',
                            border: '1px solid var(--primarry-blue, #2457B9)',
                            lineHeight: '22px',
                            background: '#2457B9', mb: 2,
                            borderRadius: '8px', boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.12), 0px -1px 0px 0px rgba(0, 0, 0, 0.20) inset;',
                            textTransform: 'none'
                          }} variant="contained" fullWidth startIcon={<img alt="Nikel-icon" src={editIcon} height={24}></img>}>
                            {translate('permintaan.btn-edit_permintaan')}
                          </Button> : <></>}
                          <Button  className="w-500" onClick={(e) => {
                            window.open(`https://api.whatsapp.com/send?phone=+62${detailBatubara?.phone_number}`)
                          }} size="medium" sx={{ background: '#2457B9', mb: 2, textTransform: 'none', fontSize: '14px',lineHeight: '22px', padding: '12px 16px', borderRadius: '8px' }} variant="contained" fullWidth startIcon={<CallIcon />}>
                            {translate('detail-explore.hubungitrader')}
                          </Button>
                          {checkingFavorite === true ?
                            <Button className="w-500" onClick={() => removeFavorite(5)} sx={{
                              textTransform: 'none', border: '1px solid var(--primarry-blue, #2457B9)',
                              p: '12px 16px',lineHeight: '22px',mb:2,
                              borderRadius: '8px',
                              color: '#2457B9'
                            }} size="medium" variant="outlined" fullWidth startIcon={<FavoriteTwoToneIcon sx={{ fill: "red" }} />}>
                              {translate('status.hapusfavorite')}
                            </Button>
                            :
                            <Button className="w-500" onClick={() => setFavorite(5)} sx={{
                              textTransform: 'none', border: '1px solid var(--primarry-blue, #2457B9)',
                              p: '12px 16px', lineHeight:'22px',mb:2,
                              borderRadius: '8px',
                              color: '#2457B9'
                            }} size="medium" variant="outlined" fullWidth startIcon={<FavoriteBorderIcon />}>
                              {translate('detail-explore.tambahkanfavorit')}
                            </Button>
                          }
                           {detailBatubara?.id !== null && Number(detailBatubara?.user_id) == Number(localStorage.getItem("id_user"))  && <Button className="w-500" onClick={() => {
                            handleOpenModal("hapus", detailBatubara?.id);
                          }} sx={{
                            "&:hover": {
                              borderColor: '#ED3131',
                            },
                            textTransform: "none", border: '1px solid var(--primarry-blue, #ED3131)',
                            p: '12px 16px',
                            borderRadius: '8px',
                            fontSize: 16,
                            lineHeight: '22px',
                            color: '#ED3131'
                          }} size="medium" variant="outlined" fullWidth startIcon={<img alt="Nikel-icon" src={deleteIcon} height={24}></img>}>
                            {translate('status.hapus')}
                          </Button>}
                          <ModalHapusPermintaan type={'coal'} id={detailBatubara.id} open={isOpenModalHapus} close={handleCloseModalHapus}></ModalHapusPermintaan>
                        </Box>

                        <Box sx={{ pl: 2, pr: 2, mt: 1, mb: 2 }}>
                          <Grid
                            container
                            justifyContent="left"
                            alignItems="center"
                            direction="row"
                            sx={{ cursor: 'pointer' }}
                            onClick={() => {
                              SubmitReportListing()
                            }}
                          >
                            <img alt="report-icon" src={reporticon} height={24}></img>
                            <Typography
                              className="w-500"
                              sx={{ fontSize: 14, color: "#56617B", ml: 1, }}
                            >
                              {translate('detail-explore.laporkanlisting')}
                            </Typography>
                          </Grid>
                        </Box>
                      </Hidden>
                    </Box>
                    <Hidden lgDown>
                      <Grid container display="flex" justifyContent="space-between" direction={"row"} >
                        <Typography
                          className="w-700"
                          sx={{ fontSize: 18, color: '#081130', mt: '24px', mb: '12px' }}
                        >
                          {translate('detail-explore.bagikanlisting')}
                        </Typography>
                        {copyClipboard}
                      </Grid>
                      <Box sx={{ background: '#F4F6FA', border: '1px solid #E8EBF2', borderRadius: '8px', pt: '16px', pl: '16px', pr: '16px', pb: '16px' }}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                          <Typography className="w-700" sx={{ color: '#56617B', fontSize: 14, }}>
                            {(`${url_copy}`).slice(0, 42) + '...'}
                          </Typography>
                          <img style={{ cursor: 'pointer' }} alt="copy-icon" src={copyIc} height={20} onClick={(e) => {
                            navigator.clipboard.writeText(`${url_copy}`)
                            copyLink()
                          }}></img>
                        </Stack>
                      </Box>
                      <Stack sx={{ mt: '16px' }} direction={'row'} justifyContent={'space-evenly'} alignItems={'center'}>
                        <TwitterShareButton url={`${url_copy}`}><img alt="twitter-icon" src={twitterIcon} height={'40px'} width={'40px'} /></TwitterShareButton>
                        <WhatsappShareButton url={`${url_copy}`}><img alt="wa-icon" src={waIcon} height={'40px'} width={'40px'} /> </WhatsappShareButton>
                        <TelegramShareButton url={`${url_copy}`}> <img alt="tele-icon" src={telegramIcon} height={'40px'} width={'40px'} /></TelegramShareButton>
                        <FacebookShareButton url={`${url_copy}`} ><img alt="fb-icon" src={fbIcon} height={'40px'} width={'40px'} /></FacebookShareButton>
                      </Stack>
                    </Hidden>
                  </Box>
                  : ''}
                {activeTab === 'nikel' ?
                  <Box >

                    <Box sx={{ border: { xs: "none", sm: "none", md: "none", lg: 1, xl: 1 }, color: '#E8EBF2', borderRadius: 2, pt: 2 }}>
                      {(detailNikel.company_listing !== null && detailNikel.company_listing !== undefined) &&
                        <Box>
                          <Typography className="w-700" sx={{ color: '#081130', fontSize: 18, mt: 1, pl: 2 }}>
                            {translate('detail-explore.tentangperusahaan')}
                          </Typography>

                          <Box sx={{ pl: 2, pr: 2, mt: 2, }}>
                            <Grid
                              container
                              direction="row"
                              alignItems="left"
                              justifyContent="left"

                            >
                              {detailNikel.company_listing?.picture_path?.url !== undefined &&
                                <Avatar sx={{ bgcolor: 'blue', mr: '16px' }} aria-label="recipe" src={config.baseURL + detailNikel.company_listing?.picture_path?.url}>
                                </Avatar>
                              }
                              <Stack style={{ textAlign: "left", color: "white" }} spacing={0}>
                                <Typography
                                  sx={{ fontSize: 16, color: '#081130' }}
                                  className="w-700"
                                >
                                  {detailNikel.company_listing.jenis_perusahaan} {capitalizeEachWord(detailNikel.company_listing.name)}
                                </Typography>
                                <Grid className="pointer"
                                  container onClick={(e) => handleNavigate(detailNikel.company_listing.id, detailNikel.company_listing.name)}
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 16, color: "#2457B9", mr: 1 }}
                                  >
                                    {translate('detail-explore.lihatdetailperusahaan')}
                                  </Typography>
                                  <ArrowForwardIosIcon sx={{ fontSize: 12, color: "#2457B9", pt: '3px' }} />
                                </Grid>
                              </Stack>
                            </Grid>
                          </Box>
                          <Box sx={{ ml: 2, mr: 2, mt: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0 }, mb: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0 }, background: { xs: "#F4F6FA", sm: "#F4F6FA", md: "#F4F6FA", lg: "none", xl: "none" }, borderRadius: 4, padding: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0 } }}>
                            <Box sx={{ pr: 2, mb: 2, mt: { xs: 0, sm: 0, md: 0, lg: 1, xl: 1 } }}>
                              {detailNikel.company_listing.bidang_perusahaan === 1 ? (
                                <Grid
                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <img alt="penambang-icon" src={PenambangIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                  >
                                    {translate('page_explore.penambang')}
                                  </Typography>

                                </Grid>
                              ) : detailNikel.company_listing.bidang_perusahaan === 2 ? (
                                <Grid
                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <img alt="penambang-icon" src={traderIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                  >
                                    Trader
                                  </Typography>

                                </Grid>
                              ) : detailNikel.company_listing.bidang_perusahaan === 3 ? (
                                <Grid
                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <img alt="penambang-icon" src={pelayarIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                  >
                                    {translate('page_explore.pelayaran')}
                                  </Typography>

                                </Grid>
                              ) : (
                                <></>
                              )}
                            </Box>
                            <Box sx={{ pr: 2, mt: 1, mb: 2 }}>
                              {detailNikel.company_listing &&
                                <Grid
                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <img alt="call-icon" src={locationCompanyIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                  >
                                    {detailNikel.company_listing?.alamat[0]?.detail_alamat}
                                  </Typography>
                                </Grid>}
                            </Box>
                            <Box sx={{ pr: 2, mt: 1, mb: 2 }}>
                              {detailNikel.company_listing.phone_number &&
                                <Grid
                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <img alt="Nikel-icon" src={callCompanyIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                  >
                                    {detailNikel.company_listing.phone_number}
                                  </Typography>
                                </Grid>}
                            </Box>
                            <Box sx={{ pr: 2, mt: 1, mb: 2 }}>
                              {detailNikel.company_listing.email &&
                                <Grid
                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <img alt="Nikel-icon" src={mailCompanyIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                  >
                                    {detailNikel.company_listing.email}
                                  </Typography>
                                </Grid>}
                            </Box>
                          </Box>
                        </Box>}
                      <Hidden lgDown>
                        <Box sx={{ pl: 2, pr: 2, mb: 2 }}>
                          <Button className="w-500" onClick={(e) => {
                            window.open(`https://api.whatsapp.com/send?phone=+62${detailNikel?.phone_number}`)
                          }} size="medium" sx={{ background: '#2457B9', mb: 1, textTransform: 'none', fontSize: '14px', padding: '12px 16px', borderRadius: '8px' }} variant="contained" fullWidth startIcon={<CallIcon />}>
                            {translate('detail-explore.hubungitrader')}
                          </Button>

                          {checkingFavorite === true ?
                            <Button className="w-500" onClick={() => removeFavorite(6)} sx={{
                              textTransform: 'none', border: '1px solid var(--primarry-blue, #2457B9)',
                              p: '12px 16px',
                              borderRadius: '8px',
                              color: '#2457B9'
                            }} size="medium" variant="outlined" fullWidth startIcon={<FavoriteTwoToneIcon sx={{ fill: "red" }} />}>
                              {translate('status.hapusfavorite')}
                            </Button>
                            :
                            <Button className="w-500" onClick={() => setFavorite(6)} sx={{
                              textTransform: 'none', border: '1px solid var(--primarry-blue, #2457B9)',
                              p: '12px 16px',
                              borderRadius: '8px',
                              color: '#2457B9'
                            }} size="medium" variant="outlined" fullWidth startIcon={<FavoriteBorderIcon />}>
                              {translate('detail-explore.tambahkanfavorit')}
                            </Button>
                          }
                        </Box>

                        <Box sx={{ pl: 2, pr: 2, mt: 1, mb: 2 }}>
                          <Grid
                            container
                            justifyContent="left"
                            alignItems="center"
                            direction="row"
                            sx={{ cursor: 'pointer' }}
                            onClick={() => {
                              SubmitReportListing()
                            }}
                          >
                            <img alt="Nikel-icon" src={reporticon} height={24}></img>
                            <Typography
                              className="w-500"
                              sx={{ fontSize: 14, color: "#56617B", ml: 1, }}
                            >
                              {translate('detail-explore.laporkanlisting')}
                            </Typography>

                          </Grid>
                        </Box>
                      </Hidden>
                    </Box>
                    <Hidden lgDown>
                      <Grid container display="flex" justifyContent="space-between" direction={"row"}>
                        <Typography
                          className="w-700"
                          sx={{ fontSize: 18, color: '#081130', mt: '24px', mb: '12px' }}
                        >
                          {translate('detail-explore.bagikanlisting')}
                        </Typography>
                        {copyClipboard}
                      </Grid>
                      <Box sx={{ background: '#F4F6FA', border: '1px solid #E8EBF2', borderRadius: '8px', pt: '16px', pl: '16px', pr: '16px', pb: '16px' }}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                          <Typography className="w-700" sx={{ color: '#56617B', fontSize: 14, }}>
                            {(`${url_copy}`).slice(0, 42) + '...'}
                          </Typography>
                          <img alt="copy-icon" src={copyIc} height={20} onClick={(e) => {
                            navigator.clipboard.writeText(`${url_copy}`)
                            copyLink()
                          }}></img>
                        </Stack>
                      </Box>
                      <Stack sx={{ mt: '16px' }} direction={'row'} justifyContent={'space-evenly'} alignItems={'center'}>
                        <TwitterShareButton url={`${url_copy}`}><img alt="twitter-icon" src={twitterIcon} height={'40px'} width={'40px'} /></TwitterShareButton>
                        <WhatsappShareButton url={`${url_copy}`}><img alt="wa-icon" src={waIcon} height={'40px'} width={'40px'} /> </WhatsappShareButton>
                        <TelegramShareButton url={`${url_copy}`}> <img alt="tele-icon" src={telegramIcon} height={'40px'} width={'40px'} /></TelegramShareButton>
                        <FacebookShareButton url={`${url_copy}`} ><img alt="fb-icon" src={fbIcon} height={'40px'} width={'40px'} /></FacebookShareButton>
                      </Stack>
                    </Hidden>
                  </Box>
                  : ''}
                {activeTab === 'tongkang' ?
                  <Box >

                    <Box sx={{ border: 1, color: '#E8EBF2', borderRadius: 2, pt: 2 }}>
                      {(detailTongkang.company_listing !== null && detailTongkang.company_listing !== undefined) &&
                        <Box>
                          <Typography className="w-700" sx={{ color: '#081130', fontSize: 18, mt: 1, pl: 2 }}>
                            {translate('detail-explore.tentangperusahaan')}
                          </Typography>

                          <Box sx={{ pl: 2, pr: 2, mt: 2, }}>
                            <Grid
                              container
                              direction="row"
                              alignItems="left"
                              justifyContent="left"

                            >
                              {detailTongkang.company_listing?.picture_path?.url !== undefined &&

                                <Avatar sx={{ bgcolor: 'blue', mr: '16px' }} aria-label="recipe" src={config.baseURL + detailTongkang.company_listing?.picture_path?.url}>

                                </Avatar>
                              }

                              <Stack style={{ textAlign: "left", color: "white" }} spacing={0}>
                                {detailTongkang.company_listing.name &&
                                  <Typography
                                    sx={{ fontSize: 16, color: '#081130' }}
                                    className="w-700"
                                  >
                                    {detailTongkang.company_listing.jenis_perusahaan} {capitalizeEachWord(detailTongkang.company_listing.name)}
                                  </Typography>}
                                <Grid className="pointer"
                                  container onClick={(e) => handleNavigate(detailTongkang.company_listing.id, detailTongkang.company_listing.name)}
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 16, color: "#2457B9", mr: 1 }}
                                  >
                                    {translate('detail-explore.lihatdetailperusahaan')}
                                  </Typography>
                                  <ArrowForwardIosIcon sx={{ fontSize: 12, color: "#2457B9", pt: '3px' }} />
                                </Grid>
                              </Stack>
                            </Grid>
                          </Box>
                          <Box sx={{ ml: 2, mr: 2, mt: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0 }, mb: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0 }, background: { xs: "#F4F6FA", sm: "#F4F6FA", md: "#F4F6FA", lg: "none", xl: "none" }, borderRadius: 4, padding: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0 } }}>

                            <Box sx={{ pr: 2, mb: 2, mt: { xs: 0, sm: 0, md: 0, lg: 1, xl: 1 } }}>
                              {detailTongkang.company_listing.bidang_perusahaan === 1 ? (
                                <Grid
                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <img alt="penambang-icon" src={PenambangIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                  >
                                    {translate('page_explore.penambang')}
                                  </Typography>
                                </Grid>
                              ) : detailTongkang.company_listing.bidang_perusahaan === 2 ? (
                                <Grid

                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <img alt="penambang-icon" src={traderIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                  >
                                    Trader
                                  </Typography>

                                </Grid>
                              ) : detailTongkang.company_listing.bidang_perusahaan === 3 ? (
                                <Grid
                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <img alt="penambang-icon" src={pelayarIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                  >
                                    {translate('page_explore.pelayaran')}
                                  </Typography>
                                </Grid>
                              ) : (
                                <></>
                              )}
                            </Box>

                            <Box sx={{ pr: 2, mt: 1, mb: 2 }}>
                              {detailTongkang.company_listing &&
                                <Grid
                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <img alt="call-icon" src={locationCompanyIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                  >
                                    {detailTongkang.company_listing?.alamat[0]?.detail_alamat}
                                  </Typography>
                                </Grid>}
                            </Box>
                            <Box sx={{ pr: 2, mt: 1, mb: 2 }}>
                              {detailTongkang.company_listing.phone_number &&
                                <Grid
                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <img alt="Nikel-icon" src={callCompanyIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                  >
                                    {detailTongkang.company_listing.phone_number}
                                  </Typography>

                                </Grid>}
                            </Box>
                            <Box sx={{ pr: 2, mt: 1, mb: 2 }}>
                              {detailTongkang.company_listing.email &&
                                <Grid
                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <img alt="Nikel-icon" src={mailCompanyIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                  >
                                    {detailTongkang.company_listing.email}
                                  </Typography>
                                </Grid>}
                            </Box>
                          </Box>
                        </Box>
                      }
                      <Hidden lgDown>
                        <Box sx={{ pl: 2, pr: 2, mb: 2 }}>
                          <Button className="w-500" onClick={(e) => {
                            window.open(`https://api.whatsapp.com/send?phone=+62${detailTongkang?.phone_number}`)
                          }} size="medium" sx={{ background: '#2457B9', mb: 1, textTransform: 'none', fontSize: '14px', padding: '12px 16px', borderRadius: '8px' }} variant="contained" fullWidth startIcon={<CallIcon />}>
                            {translate('detail-explore.hubungitrader')}
                          </Button>

                          {checkingFavorite === true ?
                            <Button className="w-500" onClick={() => removeFavorite(7)} sx={{
                              textTransform: 'none', border: '1px solid var(--primarry-blue, #2457B9)',
                              p: '12px 16px',
                              borderRadius: '8px',
                              color: '#2457B9'
                            }} size="medium" variant="outlined" fullWidth startIcon={<FavoriteTwoToneIcon sx={{ fill: "red" }} />}>
                              {translate('status.hapusfavorite')}
                            </Button>
                            :
                            <Button className="w-500" onClick={() => setFavorite(7)} sx={{
                              textTransform: 'none', border: '1px solid var(--primarry-blue, #2457B9)',
                              p: '12px 16px',
                              borderRadius: '8px',
                              color: '#2457B9'
                            }} size="medium" variant="outlined" fullWidth startIcon={<FavoriteBorderIcon />}>
                              {translate('detail-explore.tambahkanfavorit')}
                            </Button>
                          }
                        </Box>

                        <Box sx={{ pl: 2, pr: 2, mt: 1, mb: 2 }}>
                          <Grid
                            container
                            justifyContent="left"
                            alignItems="center"
                            direction="row"
                            sx={{ cursor: 'pointer' }}
                            onClick={() => {
                              SubmitReportListing()
                            }}
                          >
                            <img alt="Nikel-icon" src={reporticon} height={24}></img>
                            <Typography
                              className="w-500"
                              sx={{ fontSize: 14, color: "#56617B", ml: 1, }}
                            >
                              {translate('detail-explore.laporkanlisting')}
                            </Typography>

                          </Grid>
                        </Box>
                      </Hidden>
                    </Box>
                    <Hidden lgDown>
                      <Grid container display="flex" justifyContent="space-between" direction={"row"}>
                        <Typography
                          className="w-700"
                          sx={{ fontSize: 18, color: '#081130', mt: '24px', mb: '12px' }}
                        >
                          {translate('detail-explore.bagikanlisting')}
                        </Typography>
                        {copyClipboard}
                      </Grid>
                      <Box sx={{ background: '#F4F6FA', border: '1px solid #E8EBF2', borderRadius: '8px', pt: '16px', pl: '16px', pr: '16px', pb: '16px' }}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                          <Typography className="w-700" sx={{ color: '#56617B', fontSize: 14, }}>
                            {(`${url_copy}`).slice(0, 42) + '...'}
                          </Typography>
                          <img alt="copy-icon" src={copyIc} height={20} onClick={(e) => {
                            navigator.clipboard.writeText(`${url_copy}`)
                            copyLink()
                          }}></img>
                        </Stack>
                      </Box>
                      <Stack sx={{ mt: '16px' }} direction={'row'} justifyContent={'space-evenly'} alignItems={'center'}>
                        <TwitterShareButton url={`${url_copy}`}><img alt="twitter-icon" src={twitterIcon} height={'40px'} width={'40px'} /></TwitterShareButton>
                        <WhatsappShareButton url={`${url_copy}`}><img alt="wa-icon" src={waIcon} height={'40px'} width={'40px'} /> </WhatsappShareButton>
                        <TelegramShareButton url={`${url_copy}`}> <img alt="tele-icon" src={telegramIcon} height={'40px'} width={'40px'} /></TelegramShareButton>
                        <FacebookShareButton url={`${url_copy}`} ><img alt="fb-icon" src={fbIcon} height={'40px'} width={'40px'} /></FacebookShareButton>
                      </Stack>
                    </Hidden>
                  </Box>
                  : ''}

              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              sx={{ mb: 2 }}
            >
              <Grid lg={8} xs={12} item sx={{ width: activeTab === 'trader' ? '1280px' : '840px' }}>
                {/* coal Section */}
                {activeTab === 'coal' ?
                  <Box sx={{ mb: 2, }}>
                    <Komentar id_product={parseInt(location.state.id)} type_product={4} />
                  </Box> : null}
                {/* nikel Section */}
                {activeTab === 'nikel' ?
                  <Box sx={{ mb: 2, }}>
                    <Komentar id_product={parseInt(location.state.id)} type_product={5} />
                  </Box> : null}
                {/* tongkang Section */}
                {activeTab === 'tongkang' ?
                  <Box sx={{ mb: 2 }}>
                    <Komentar id_product={parseInt(location.state.id)} type_product={6} />
                  </Box> : null}

              </Grid>
            </Grid>

            {activeTab === 'tongkang' &&
              <div className="recomendation-tongkang">
                <Typography className="w-700" sx={{ color: '#081130', fontSize: 18, mt: 1, mb: '24px', }}>
                  {translate('detail-explore.rekomendasitongkang')}
                </Typography>
                <div className="box-slide width-100">
                  <Box
                    sx={{ mr: '4px', width: '100%', mb: '32px' }}
                  >
                    <Slider {...settingsRecommendasiTongkang}>
                      {tongkangList.map(tongkang => (

                        <CardRecomendationTongkangList page={'home'} key={tongkang.product_id} data={tongkang}></CardRecomendationTongkangList>
                      ))}
                    </Slider>
                  </Box>
                </div>
              </div>}
          </div>
          <ModalReportListing url_website={url_website} open={openModalReport} close={handleCloseModal} page={type_data} type={type_data} id={location.state.id} ></ModalReportListing>
          {/* <ModalSuccessReport open={openModalReport} close={handleCloseModal}></ModalSuccessReport> */}
          {/* Floating Action Button Section */}
          <Hidden lgUp>
            <Grid sx={{ pl: { xs: '16px', sm: '46px', md: '78px', }, pr: { xs: '16px', sm: '46px', md: '78px', } }} onScroll={scrollHandler} container direction="row" className={`${position ? "disable-freeze-grid-new" : "freeze-grid-new"}`}>
              <Grid alignItems={'center'} lg={8} xs={12} item sx={{ width: activeTab === 'trader' ? '1280px' : '840px' }}>
                {/* coal Section */}
                {activeTab === 'coal' ?
                  <Box sx={{ mb: 2, }}>
                    <Hidden lgUp>
                      <Grid container direction={'row'} spacing={1} alignItems={'center'}>
                        <Grid item xs={8}>
                          <Button
                            onClick={(e) => {
                              window.open(`https://api.whatsapp.com/send?phone=+62${detailBatubara?.phone_number}`)
                            }}
                            fullWidth
                            className="w-500"
                            size="large" sx={{ background: '#2457B9', textTransform: 'none', fontSize: '14px', padding: '12px 16px', borderRadius: '8px' }}
                            variant="contained" startIcon={<CallIcon />}>
                            {translate('detail-explore.hubungitrader')}
                          </Button>

                        </Grid>
                        <Grid item xs={2} >

                          <Box sx={{ border: '1px solid #56617B', cursor: 'pointer', borderRadius: '8px', p: '12px', display: 'flex', justifyContent: 'center' }} > {checkingFavorite === true ?
                            <FavoriteTwoToneIcon onClick={() => removeFavorite(5)} sx={{ fill: "red", width: '20px', height: '20px', cursor: 'pointer' }} />
                            :
                            <FavoriteBorderIcon onClick={() => setFavorite(5)} sx={{ width: '20px', height: '20px', cursor: 'pointer' }} />
                          } </Box>
                        </Grid>
                        <Grid item xs={2} >
                          <Box onClick={(e) => handleOpenModal('more')} sx={{ border: '1px solid #56617B', cursor: 'pointer', borderRadius: '8px', p: '12px', display: 'flex', justifyContent: 'center' }} > <MoreHorizIcon sx={{ width: '20px', height: '20px' }} /> </Box>
                          <DrawerMore type={1} id={detailBatubara.id} page="permintaan" share={handleShareModal} open={isOpenMore} close={handleCloseModal}>

                          </DrawerMore>
                          <ModalShareListing open={isOpenModalShare} close={closeShareModal}></ModalShareListing>
                        </Grid>
                      </Grid>
                    </Hidden>
                  </Box> : null}
                {/* nikel Section */}
                {activeTab === 'nikel' ?
                  <Box sx={{ mb: 2, }}>

                    <Hidden lgUp>
                      <Grid sx={{ mt: 2 }} container direction={'row'} spacing={1} alignItems={'center'}>
                        <Grid item xs={8}>
                          <Button
                            onClick={(e) => {
                              window.open(`https://api.whatsapp.com/send?phone=+62${detailNikel?.phone_number}`)
                            }}
                            fullWidth
                            className="w-500"
                            size="large" sx={{ background: '#2457B9', textTransform: 'none', fontSize: '14px', padding: '12px 16px', borderRadius: '8px' }}
                            variant="contained" startIcon={<CallIcon />}>
                            {translate('detail-explore.hubungitrader')}
                          </Button>

                        </Grid>
                        <Grid item xs={2} >

                          <Box sx={{ border: '1px solid #56617B', cursor: 'pointer', borderRadius: '8px', p: '12px', display: 'flex', justifyContent: 'center' }} > {checkingFavorite === true ?
                            <FavoriteTwoToneIcon onClick={() => removeFavorite(6)} sx={{ fill: "red", width: '20px', height: '20px', cursor: 'pointer' }} />
                            :
                            <FavoriteBorderIcon onClick={() => setFavorite(6)} sx={{ width: '20px', height: '20px', cursor: 'pointer' }} />
                          } </Box>
                        </Grid>
                        <Grid item xs={2} >
                          <Box onClick={(e) => handleOpenModal('more')} sx={{ border: '1px solid #56617B', cursor: 'pointer', borderRadius: '8px', p: '12px', display: 'flex', justifyContent: 'center' }} > <MoreHorizIcon sx={{ width: '20px', height: '20px' }} /> </Box>
                          <DrawerMore type={2} id={detailNikel.id} page="permintaan" share={handleShareModal} open={isOpenMore} close={handleCloseModal}>

                          </DrawerMore>
                          <ModalShareListing open={isOpenModalShare} close={closeShareModal}></ModalShareListing>
                        </Grid>
                      </Grid>
                    </Hidden>
                  </Box> : null}
                {/* tongkang Section */}
                {activeTab === 'tongkang' ?
                  <Box sx={{ mb: 2 }}>
                    <Hidden lgUp>
                      <Grid sx={{ mt: 2 }} container direction={'row'} spacing={1} alignItems={'center'}>
                        <Grid item xs={8}>
                          <Button
                            onClick={(e) => {
                              window.open(`https://api.whatsapp.com/send?phone=+62${detailTongkang?.phone_number}`)
                            }}
                            fullWidth
                            className="w-500"
                            size="large" sx={{ background: '#2457B9', textTransform: 'none', fontSize: '14px', padding: '12px 16px', borderRadius: '8px' }}
                            variant="contained" startIcon={<CallIcon />}>
                            {translate('detail-explore.hubungitrader')}
                          </Button>
                        </Grid>
                        <Grid item xs={2} >
                          <Box sx={{ border: '1px solid #56617B', cursor: 'pointer', borderRadius: '8px', p: '12px', display: 'flex', justifyContent: 'center' }} > {checkingFavorite === true ?
                            <FavoriteTwoToneIcon onClick={() => removeFavorite(7)} sx={{ fill: "red", width: '20px', height: '20px', cursor: 'pointer' }} />
                            :
                            <FavoriteBorderIcon onClick={() => setFavorite(7)} sx={{ width: '20px', height: '20px', cursor: 'pointer' }} />
                          }</Box>
                        </Grid>
                        <Grid item xs={2} >
                          <Box onClick={(e) => handleOpenModal('more')} sx={{ border: '1px solid #56617B', cursor: 'pointer', borderRadius: '8px', p: '12px', display: 'flex', justifyContent: 'center' }} > <MoreHorizIcon sx={{ width: '20px', height: '20px' }} /> </Box>
                          <DrawerMore type={3} id={detailTongkang.id} page="permintaan" share={handleShareModal} open={isOpenMore} close={handleCloseModal}>

                          </DrawerMore>
                          <ModalShareListing open={isOpenModalShare} close={closeShareModal}></ModalShareListing>
                        </Grid>
                      </Grid>
                    </Hidden>

                  </Box> : null}
              </Grid>
            </Grid>
          </Hidden>
          <Footer></Footer>
        </Box>
      </div>
    </div>
  )
}

export default DetailPermintaanpage
