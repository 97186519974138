import Divider from "@material-ui/core/Divider"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import CompanyDefault from '@mui/icons-material/Business'
import CallIcon from '@mui/icons-material/Call'
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import { Avatar, Box, Button, Card, CardContent, Grid, Hidden, Stack, Typography } from "@mui/material"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import Calendericon from "../assets/calender-dark-ic.png"
import divider from "../assets/icons/dash-divider.svg"
import dividerIcMobile from "../assets/icons/divider-mobile.png"
import positionActiveIcon from "../assets/position-active-ic.png"
import positionInactiveIcon from "../assets/position-inactive-ic.png"
import SizetongkangIcon from "../assets/sizetongkang-ic.png"
import DrawerMore from "../components/DrawerMore"
import ModalShareListing from "../components/ModalShareListing"
import { addFavorite, deleteFavorite } from "../services/api.js"
import { config } from '../services/constants'
import { GlobalState } from "../services/global"
import { capitalizeEachWord } from "../services/primary.js"
import ModalHubungiTrader from "./ModalHubungiTrader"
import ModalShare from "./ModalShare"

export default function CardRentTongkang(props) {
  const [translate,] = useTranslation("global");
  const [isOpenModal, setOpenModal] = useState(false)
  const [anchorElShare, setAnchorElShare] = useState(null);
  const [activeTab,] = useState("coal");
  const [isOpenMore, setOpenMore] = useState(false)
  const [isOpenModalShare, setOpenModalShare] = useState(false)
  const [phone, setPhonenumber] = useState(false)
  const navigate = useNavigate();
  const [product_id, setProduct_id] = useState(null);
  const [item,] = useState(props.RentBargeList);
  const [id_user] = useState(localStorage.getItem('id_user') !== null ? localStorage.getItem('id_user') : '');
  const { currency } = React.useContext(GlobalState)


  const handleCloseModal = () => {
    setOpenModal(false);
    setOpenMore(false);
  };

  const handleOpenShareMenu = (event, id) => {
    setProduct_id(id);
    setAnchorElShare(event.currentTarget);
  };

  const handleCloseShareMenu = () => {
    setAnchorElShare(null);
  };

  const handleOpenModal = (state, id) => {
    if (state === 'more') {
      setProduct_id(id);
      setOpenMore(true);
    }
    else {
      setPhonenumber(state);
      setOpenModal(true);
    }
  };

  const setFavorite = (y) => {
    if (props.getTongkang !== undefined) {
      addFavorite({ type: 7, id_relation: parseInt(y) })
      props.getTongkang();
    }
  }

  const removeFavorite = (y) => {
    deleteFavorite({ type_product: 7, id_product: parseInt(y) })
    props.getTongkang();
  }


  const handleShareModal = () => {
    setOpenModalShare(true);
  }
  const closeShareModal = () => {
    setOpenModalShare(false);
  }

  let filter = props.RentBargeList?.ukuran_name.split(",");
  let x = filter.filter(item => item);


  return (
    <div>
      {item.type_tongkang === 2 && <Card sx={{ borderRadius: 2, mb: 2, p: '16px', }}>
        <Hidden smDown>
          <CardContent>
            <Box>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={0}
              >
                <Grid item >
                  <Stack direction={'row'} alignItems="center">

                    <Typography
                      sx={{ fontSize: 18, color: '#081130', mr: 1 }}
                      className="w-700"
                    >
                      {item.permintaan_code}
                    </Typography>
                    <Box
                      sx={{
                        top: '10%',
                        background: "#F4F6FA",
                        borderRadius: 4,
                        pr: 2,
                        pl: 2,
                        pb: '2px',
                        pt: '2px',

                      }}
                    >
                      <Stack direction={'row'} alignItems={'center'}>
                        {item.type_tongkang === 2 ? (
                          <>
                            <Box sx={{ borderRadius: '50%', background: '#F55C24', width: '10px', height: '10px', mr: '4px' }}>
                            </Box>
                            <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 12 }} >
                              Time Charter
                            </Typography>
                          </>
                        ) : item.type_tongkang === 3 ? (
                          <>
                            <Box sx={{ borderRadius: '50%', background: '#883BE7', width: '10px', height: '10px', mr: '4px' }}>
                            </Box>
                            <Typography className="w-500" sx={{ color: '#818CA7', fontSize: '12px' }} >
                              Freight Charter
                            </Typography>
                          </>
                        ) : (
                          <></>
                        )}
                      </Stack>
                    </Box>
                  </Stack>

                </Grid>
                <Grid sx={{ textAlign: "right", alignItems: "center", display: 'flex' }} item >
                  {id_user !== item.user_id.toString() && <Button
                    onClick={(e) => handleOpenModal(item.user_trader_phone)}
                    className="w-500"
                    size="medium" sx={{ background: '#3BB56C', textTransform: 'none', fontSize: '14px', padding: '8px 12px', borderRadius: '8px' }}
                    variant="contained" startIcon={<CallIcon />}>
                    {translate('detail-explore.hubungitrader')}
                  </Button>}
                  <ModalHubungiTrader page={'permintaan'} user_trader_phone={phone} type={activeTab} open={isOpenModal} close={handleCloseModal}></ModalHubungiTrader>
                  <Divider orientation="vertical" style={{ marginTop: 10, marginBottom: 10, marginLeft: 10, marginRight: 10 }} flexItem />
                  <MoreHorizIcon onClick={(e) => handleOpenShareMenu(e, item.product_id)} sx={{ color: '#56617B', cursor: 'pointer' }} />
                  <ModalShare page={'permintaan'} type={3} id={product_id} anchorEl={anchorElShare} open={anchorElShare} onClose={handleCloseShareMenu} setFavorite={setFavorite} removeFavorite={removeFavorite} data={item}></ModalShare>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                sx={{ mt: 1 }}
                justifyContent="space-between"
                spacing={0}
              >
                <Grid direction={'column'} item >
                  <Typography
                    sx={{ fontSize: 12, mb: 1, color: '#818CA7', textTransform: 'uppercase' }}
                    className="w-500"
                  >
                    {translate('jual-beli.nama-trader')}
                  </Typography>
                  <Grid
                    container
                    direction="row"
                    alignItems="left"
                    justifyContent="left"
                    spacing={0}
                  >
                    <Grid item >
                      {item.user_trader_image ? <Avatar onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${item.user_id}` }} src={config.baseURL + item.user_trader_image} sx={{ bgcolor: '#1976d2', height: '48px', width: '48px', mr: 1, cursor: 'pointer' }} aria-label="recipe">

                      </Avatar> : <Avatar onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${item.user_id}` }} sx={{ bgcolor: '#1976d2', height: '48px', width: '48px', mr: 1, cursor: 'pointer' }} aria-label="recipe">

                      </Avatar>
                      }
                    </Grid>
                    <Grid item >
                      <Stack style={{ textAlign: "left", color: "white" }}>
                        <Typography
                          onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${item.user_id}` }}
                          sx={{ fontSize: 16, color: '#081130', cursor: 'pointer' }}
                          className="w-700"
                        >
                          {item.user_trader_name}
                        </Typography>
                        <Stack direction={'row'}>
                          {item.company_list.map((item, index) => {
                            if (index < 4) return (
                              <div key={item.company_id}>
                                {index === 0 ? (
                                  item?.company_image ?
                                    <Avatar src={`${config.baseURL + item?.company_image}`} sx={{ bgcolor: '#1976d2', width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                                      <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
                                    </Avatar> : <Avatar sx={{ bgcolor: '#1976d2', width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                                      <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
                                    </Avatar>

                                ) : (
                                  item?.company_image ?
                                    <Avatar src={`${config.baseURL + item?.company_image}`} sx={{ bgcolor: '#1976d2', ml: -1, width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                                      <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
                                    </Avatar> : <Avatar sx={{ bgcolor: '#1976d2', ml: -1, width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                                      <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
                                    </Avatar>
                                )}
                              </div>
                            )
                          })}
                          {item.company_list.length > 4 && <Avatar className="w-500" style={{ marginLeft: -6, width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                            +{item.company_list.length - 4}
                          </Avatar>}
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid direction={'row'} sx={{ textAlign: "right", alignItems: "center", display: 'flex' }} item >
                  <Typography
                    sx={{ fontSize: 24, color: '#081130', mr: 1 }}
                    className="w-700"
                  >
                    {currency === 'idr' ? 'Rp' : '$'}  {(item.price).toLocaleString(undefined, { maximumFractionDigits: 2 })}

                  </Typography>
                  <Typography
                    sx={{ fontSize: 16, color: '#56617B' }}
                    className="w-500"
                  >
                    {item.type_tongkang === 2 ? "/month" : "/MT"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                marginTop={1}
                container
                direction="row"
                alignItems="left"
                justifyContent="left"
                spacing={1}
              >
                <Grid item xs={4} >
                  {item.type_tongkang === 2 && <Box sx={{ background: "#F4F6FA", borderRadius: 4, padding: 2 }}>
                    <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>

                      <Stack direction={'row'} alignItems={'center'}>
                        {item.ukuran_tongkang &&
                          <Typography className="w-700" sx={{ color: '#081130', fontSize: 18 }}>
                            {item.ukuran_tongkang.match(/\d+/)[0]}
                          </Typography>}
                        <Typography className="w-700" sx={{ color: '#081130', fontSize: 18, ml: 1 }}>
                          FT
                        </Typography>
                      </Stack>


                      <img alt="Nikel-icon" src={SizetongkangIcon} height={20}></img>
                    </Stack>
                    <Typography className="w-500" sx={{ color: '#56617B', fontSize: 14 }} >
                      {translate('page_explore.ukurantongkang')}
                    </Typography>
                  </Box>}



                </Grid>


              </Grid>



              {item.area_muat_name && <Grid container direction='row' alignItems='center' sx={{ mt: 2 }}>
                <img style={{ marginRight: 10 }} alt="Nikel-icon" src={positionInactiveIcon} height={20}></img>
                <Typography
                  className="w-500"
                  sx={{ fontSize: 14, color: "#56617B", mr: 1 }}
                >
                  Loading Area
                </Typography>
                <Typography
                  className="w-700"
                  sx={{ fontSize: 16, color: "#081130", }}
                >
                  {item.area_muat_name ? item.area_muat_name : "-"}
                </Typography>
              </Grid>}

              <Grid
                marginTop={2}
                container
                direction="row"
                alignItems="left"
                justifyContent="space-between"
                spacing={0}
              >
                <Grid>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    direction="row"
                  >
                    <img alt="Nikel-icon" style={{ marginRight: 6 }} src={Calendericon} height={18}></img>
                    <Typography
                      className="w-700"
                      sx={{ fontSize: 14, color: "#56617B", mr: 1 }}
                    >
                      {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(item.permintaan_date))}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid >
                  <Grid className="pointer"
                    container
                    justifyContent="center"
                    alignItems="center"
                    direction="row"
                    onClick={(e) => { navigate(`/permintaan/detail?type=3&id_detail=${item.product_id}`) }}
                  >
                    <Typography
                      className="w-500"
                      sx={{ fontSize: 16, color: "#2457B9", mr: 1 }}
                    >
                      {translate('page_explore.lihatselengkapnya')}
                    </Typography>
                    <ArrowForwardIosIcon sx={{ fontSize: 14, color: "#2457B9" }} />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Hidden>
        <Hidden mdUp>
          <CardContent>
            <Box>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="end"
                spacing={0}
              >
                <Grid item xs={11}>
                  <Typography
                    sx={{ fontSize: 18, color: '#081130' }}
                    className="w-700"
                  >
                    {item.permintaan_code}
                  </Typography>
                  <Grid
                    container
                    justifyContent="left"
                    alignItems="center"
                    direction="row"
                  >
                    <img alt="Nikel-icon" style={{ marginRight: 6 }} src={Calendericon} height={18}></img>
                    <Typography
                      className="w-500"
                      sx={{ fontSize: 14, color: "#56617B", mr: 1 }}
                    >
                      {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(item.permintaan_date))}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid sx={{ textAlign: "right", }} item xs={1}>
                  <MoreHorizIcon onClick={(e) => handleOpenModal('more', item.product_id)} sx={{ cursor: 'pointer' }} />
                </Grid>
              </Grid>
              <Box onClick={(e) => { navigate(`/permintaan/detail?type=3&id_detail=${item.product_id}`) }}>
                <Grid direction={'row'} sx={{ textAlign: "left", alignItems: "center", display: 'flex', mt: 1, mb: 1 }} item xs={12}>
                  <Typography
                    sx={{ fontSize: 18, color: '#081130' }}
                    className="w-700"
                  >

                    {currency === 'idr' ? 'Rp' : '$'}  {(item.price).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                  </Typography>
                </Grid>

                <Grid

                  container
                  direction="row"
                  alignItems="left"
                  justifyContent="left"
                  spacing={1}
                >
                  <Grid item xs={6} sx={{ mb: 2 }}>
                    <Box sx={{ background: "#F4F6FA", borderRadius: 4, padding: 2 }}>
                      <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>

                        <Typography className="w-700" sx={{ color: '#081130', fontSize: 18, display: 'inline', wordBreak: "break-all", }}>
                          {item.ukuran_tongkang ? item.ukuran_tongkang : '-'}
                        </Typography>
                        <img alt="Nikel-icon" src={SizetongkangIcon} height={20}></img>
                      </Stack>
                      <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                        {translate('page_explore.ukurantongkang')}
                      </Typography>
                    </Box>
                  </Grid>

                  {(item.type_tongkang === 3 && item.quantity_muatan) && <Grid item xs={6}  >
                    <Box sx={{ background: "#F4F6FA", borderRadius: 4, padding: 2 }}>
                      <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>
                        <Typography className="w-700" sx={{ color: '#081130', fontSize: 18, display: 'inline', wordBreak: "break-all", }}>
                          {(item.quantity_muatan).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                        </Typography>
                        <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                          MT
                        </Typography>
                      </Stack>
                      <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                        {translate('page_explore.quantitymuatan')}
                      </Typography>
                    </Box>
                  </Grid>}

                </Grid>
                <Stack direction={'column'} sx={{ position: 'relative' }}>
                  <Grid container direction='row' alignItems='center' sx={{ mt: 2 }}>
                    <img style={{ marginRight: 6 }} alt="Nikel-icon" src={positionInactiveIcon} height={20}></img>
                    <Typography
                      className="w-500"
                      sx={{ fontSize: 14, color: "#56617B", mr: 1 }}
                    >
                      {translate('permintaan.pelabuhanmuatan')}
                    </Typography>
                  </Grid>

                  <Typography
                    sx={{ fontSize: 14, mb: 1, ml: 3, color: '#081130' }}
                    className="w-700"
                  >
                    {capitalizeEachWord(item.port_name_pelabuhan_muat)}
                  </Typography>
                  <img style={{ marginRight: 10, position: 'absolute', left: '10px', top: '34%' }} alt="Nikel-icon" src={dividerIcMobile} height={28} width={2}></img>
                  <Grid container direction='row' alignItems='center' sx={{ mt: 2 }}>
                    <img style={{ marginRight: 6 }} alt="Nikel-icon" src={positionActiveIcon} height={20}></img>
                    <Typography
                      className="w-500"
                      sx={{ fontSize: 14, color: "#56617B", mr: 1 }}
                    >
                      {translate('permintaan.pelabuhanbongkar')}
                    </Typography>
                  </Grid>

                  <Typography
                    sx={{ fontSize: 14, mb: 1, ml: 3, color: '#081130' }}
                    className="w-700"
                  >
                    {capitalizeEachWord(item.port_name_pelabuhan_bongkar)}
                  </Typography>
                </Stack>
                <Grid
                  marginTop={2}
                  container
                  direction="row"
                  alignItems="left"
                  justifyContent="space-between"
                  spacing={0}
                >
                  <Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    sx={{ mb: 1 }}
                    justifyContent="space-between"
                    spacing={0}
                  >
                    <Grid direction={'column'} item xs={9}>
                      <Typography
                        sx={{ fontSize: 10, mb: 1, color: '#818CA7', textTransform: 'uppercase' }}
                        className="w-500"
                      >
                        {translate('jual-beli.nama-trader')}
                      </Typography>
                      <Grid
                        container
                        direction="row"
                        alignItems="left"
                        justifyContent="left"
                        spacing={0}
                      >
                        {item.user_trader_image ?
                          <Avatar src={config.baseURL + item.user_trader_image} sx={{ bgcolor: '#1976d2', height: '48px', width: '48px', mr: 1 }} aria-label="recipe">
                          </Avatar> : <Avatar sx={{ bgcolor: '#1976d2', height: '48px', width: '48px', mr: 1 }} aria-label="recipe">
                          </Avatar>
                        }
                        <Stack style={{ textAlign: "left", color: "white" }} spacing={0}>
                          <Typography
                            onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${item.user_id}` }}
                            sx={{ fontSize: 16, color: '#081130', cursor: 'pointer' }}
                            className="w-700"
                          >
                            {item && item?.user_trader_name?.length > 20 ?
                              item?.user_trader_name.slice(0, 20) + '...'
                              : item?.user_trader_name}

                          </Typography>
                          {item.company_list.length > 0 ? <Stack direction={'row'}>
                            {item.company_list.map((item, index) => {
                              if (index < 4) return (
                                <div key={item.company_id}>
                                  {index === 0 ? (
                                    item?.company_image ?
                                      <Avatar src={`${config.baseURL + item?.company_image}`} sx={{ bgcolor: '#1976d2', width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                                        <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
                                      </Avatar> :
                                      <Avatar sx={{ bgcolor: '#1976d2', width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                                        <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
                                      </Avatar>
                                  ) : (
                                    item?.company_image ?
                                      <Avatar src={`${config.baseURL + item?.company_image}`} sx={{ bgcolor: '#1976d2', ml: -1, width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                                        <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
                                      </Avatar>
                                      : <Avatar sx={{ bgcolor: '#1976d2', ml: -1, width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                                        <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
                                      </Avatar>
                                  )}
                                </div>
                              )
                            })}
                            {item.company_list.length > 4 && <Avatar className="w-500" style={{ marginLeft: -6, width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                              +{item.company_list.length - 4}
                            </Avatar>}
                          </Stack>
                            :
                            <Typography className="w-500" sx={{ fontSize: 14, color: '#818CA7', }}>
                              Freelance
                            </Typography>}
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            {id_user !== item.user_id.toString() && <Button className="w-500" onClick={(e) => handleOpenModal(item.user_trader_phone)} size="large" sx={{ mt: '24px', background: '#3BB56C', textTransform: 'none', padding: '10px 12px', borderRadius: '8px', fontSize: '14px' }} variant="contained" fullWidth startIcon={<CallIcon />}>
              {translate('detail-explore.hubungitrader')}
            </Button>}
            <ModalHubungiTrader page={'permintaan'} user_trader_phone={phone} type={activeTab} open={isOpenModal} close={handleCloseModal}></ModalHubungiTrader>
            <DrawerMore type={'tongkang'} page="permintaan" id={product_id} share={handleShareModal} open={isOpenMore} close={handleCloseModal} setFavorite={setFavorite} removeFavorite={removeFavorite} data={item}>
            </DrawerMore>
            <ModalShareListing open={isOpenModalShare} close={closeShareModal}></ModalShareListing>
          </CardContent>
        </Hidden>
      </Card>}
      {item.type_tongkang === 3 && <Card sx={{ borderRadius: 2, mb: 2, p: '16px', }}>
        <Hidden smDown>
          <CardContent>
            <Box>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={0}
              >
                <Grid item >
                  <Stack direction={'row'} alignItems="center">

                    <Typography
                      sx={{ fontSize: 18, color: '#081130', mr: 1 }}
                      className="w-700"
                    >
                      {item.permintaan_code}
                    </Typography>
                    <Box
                      sx={{
                        top: '10%',
                        background: "#F4F6FA",
                        borderRadius: 4,
                        pr: 2,
                        pl: 2,
                        pb: '2px',
                        pt: '2px',

                      }}
                    >
                      <Stack direction={'row'} alignItems={'center'}>
                        {item.type_tongkang === 2 ? (
                          <>
                            <Box sx={{ borderRadius: '50%', background: '#F55C24', width: '10px', height: '10px', mr: '4px' }}>
                            </Box>
                            <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 12 }} >
                              Time Charter
                            </Typography>
                          </>
                        ) : item.type_tongkang === 3 ? (
                          <>
                            <Box sx={{ borderRadius: '50%', background: '#883BE7', width: '10px', height: '10px', mr: '4px' }}>
                            </Box>
                            <Typography className="w-500" sx={{ color: '#818CA7', fontSize: '12px' }} >
                              Freight Charter
                            </Typography>
                          </>
                        ) : (
                          <></>
                        )}
                      </Stack>
                    </Box>
                  </Stack>

                </Grid>
                <Grid sx={{ textAlign: "right", alignItems: "center", display: 'flex' }} item >
                  {id_user !== item.user_id.toString() && <Button
                    onClick={(e) => handleOpenModal(item.user_trader_phone)}
                    className="w-500"
                    size="medium" sx={{ background: '#3BB56C', textTransform: 'none', fontSize: '14px', padding: '8px 12px', borderRadius: '8px' }}
                    variant="contained" startIcon={<CallIcon />}>
                    {translate('detail-explore.hubungitrader')}
                  </Button>}
                  <ModalHubungiTrader page={'permintaan'} user_trader_phone={phone} type={activeTab} open={isOpenModal} close={handleCloseModal}></ModalHubungiTrader>
                  <Divider orientation="vertical" style={{ marginTop: 10, marginBottom: 10, marginLeft: 10, marginRight: 10 }} flexItem />
                  <MoreHorizIcon onClick={(e) => handleOpenShareMenu(e, item.product_id)} sx={{ color: '#56617B', cursor: 'pointer' }} />
                  <ModalShare page={'permintaan'} type={3} id={product_id} anchorEl={anchorElShare} open={anchorElShare} onClose={handleCloseShareMenu} setFavorite={setFavorite} removeFavorite={removeFavorite} data={item}></ModalShare>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                sx={{ mt: 1 }}
                justifyContent="space-between"
                spacing={0}
              >
                <Grid direction={'column'} item >
                  <Typography
                    sx={{ fontSize: 12, mb: 1, color: '#818CA7', textTransform: 'uppercase' }}
                    className="w-500"
                  >
                    {translate('jual-beli.nama-trader')}
                  </Typography>
                  <Grid
                    container
                    direction="row"
                    alignItems="left"
                    justifyContent="left"
                    spacing={0}
                  >
                    <Grid item >
                      {item.user_trader_image ? <Avatar onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${item.user_id}` }} src={config.baseURL + item.user_trader_image} sx={{ bgcolor: '#1976d2', height: '48px', width: '48px', mr: 1, cursor: 'pointer' }} aria-label="recipe">

                      </Avatar> : <Avatar onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${item.user_id}` }} sx={{ bgcolor: '#1976d2', height: '48px', width: '48px', mr: 1, cursor: 'pointer' }} aria-label="recipe">

                      </Avatar>
                      }
                    </Grid>
                    <Grid item >
                      <Stack style={{ textAlign: "left", color: "white" }}>
                        <Typography
                          onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${item.user_id}` }}
                          sx={{ fontSize: 16, color: '#081130', cursor: 'pointer' }}
                          className="w-700"
                        >
                          {item.user_trader_name}
                        </Typography>
                        <Stack direction={'row'}>
                          {item.company_list.map((item, index) => {
                            if (index < 4) return (
                              <div key={item.company_id}>
                                {index === 0 ? (
                                  item?.company_image ?
                                    <Avatar src={`${config.baseURL + item?.company_image}`} sx={{ bgcolor: '#1976d2', width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                                      <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
                                    </Avatar> : <Avatar sx={{ bgcolor: '#1976d2', width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                                      <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
                                    </Avatar>

                                ) : (
                                  item?.company_image ?
                                    <Avatar src={`${config.baseURL + item?.company_image}`} sx={{ bgcolor: '#1976d2', ml: -1, width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                                      <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
                                    </Avatar> : <Avatar sx={{ bgcolor: '#1976d2', ml: -1, width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                                      <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
                                    </Avatar>
                                )}
                              </div>
                            )
                          })}
                          {item.company_list.length > 4 && <Avatar className="w-500" style={{ marginLeft: -6, width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                            +{item.company_list.length - 4}
                          </Avatar>}
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid direction={'row'} sx={{ textAlign: "right", alignItems: "center", display: 'flex' }} item >
                  <Typography
                    sx={{ fontSize: 24, color: '#081130', mr: 1 }}
                    className="w-700"
                  >
                    {currency === 'idr' ? 'Rp' : '$'}  {(item.price).toLocaleString(undefined, { maximumFractionDigits: 2 })}

                  </Typography>
                  <Typography
                    sx={{ fontSize: 16, color: '#56617B' }}
                    className="w-500"
                  >
                    {item.type_tongkang === 2 ? "/month" : "/MT"}
                  </Typography>
                </Grid>
              </Grid>
              <Typography className="w-500" sx={{ color: '#56617B', fontSize: 14, mt: 1, mb: 1 }} >
                {translate('page_explore.ukurantongkang')}
              </Typography>

              <Grid container spacing={1}>

                {
                  x.map((data) => {

                    return (


                      <Grid item>
                        <Box sx={{ background: "#F4F6FA", borderRadius: 4, padding: 2 }}>
                          <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>
                            <Stack direction={'row'} alignItems={'center'}>
                              <Typography className="w-700" sx={{ color: '#081130', fontSize: 18 }}>
                                {data}
                              </Typography>

                            </Stack>
                            {/* <img alt="Nikel-icon" src={SizetongkangIcon} height={20}></img> */}
                          </Stack>

                        </Box>
                      </Grid>
                    )

                  })}
              </Grid>
              <Grid
                marginTop={1}
                container
                direction="row"
                alignItems="left"
                justifyContent="left"
                spacing={1}
              >


                {(item.type_tongkang === 3 && item.quantity_muatan !== 0) && <Grid item xs={3} >
                  <Box sx={{ background: "#F4F6FA", borderRadius: 4, padding: 2 }}>
                    <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>
                      <Typography className="w-700" sx={{ color: '#081130', fontSize: 20 }}>

                        {(item.quantity_muatan).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                      </Typography>
                      <Typography className="w-500" sx={{ color: '#56617B', fontSize: 14 }} >
                        MT
                      </Typography>
                    </Stack>
                    <Typography className="w-500" sx={{ color: '#56617B', fontSize: 14 }} >
                      {translate('page_explore.quantitymuatan')}
                    </Typography>
                  </Box>
                </Grid>}
              </Grid>

              {/* {item.area_muat_name && <Stack direction='row' alignItems='center' sx={{ mt: 2 }}>
                <img style={{ marginRight: 10 }} alt="Nikel-icon" src={positionInactiveIcon} height={20}></img>
                <Typography
                  className="w-500"
                  sx={{ fontSize: 14, color: "#56617B", mr: 1 }}
                >
                  Loading Area
                </Typography>
                <Typography
                  className="w-700"
                  sx={{ fontSize: 16, color: "#081130", }}
                >
                  {item.area_muat_name}
                </Typography>
              </Stack>} */}

              {/* {item.area_bongkar_name && <Stack direction='row' alignItems='center' sx={{ mt: 2 }}>
                <img style={{ marginRight: 10 }} alt="Nikel-icon" src={positionInactiveIcon} height={20}></img>
                <Typography
                  className="w-500"
                  sx={{ fontSize: 14, color: "#56617B", mr: 1 }}
                >
                  Discharge Area
                </Typography>
                <Typography
                  className="w-700"
                  sx={{ fontSize: 16, color: "#081130", }}
                >
                  {item.area_bongkar_name}
                </Typography>
              </Stack>} */}



              {(item.port_name_pelabuhan_muat || item.port_name_pelabuhan_bongkar) && <Stack direction={'column'} position={'relative'}  >
                <Grid container direction='row' alignItems='center' sx={{ mt: 2 }}>
                  <img style={{ marginRight: 10 }} alt="Nikel-icon" src={positionInactiveIcon} height={20}></img>
                  <Typography
                    className="w-500"
                    sx={{ fontSize: 14, color: "#56617B", mr: 1 }}
                  >
                    Loading Port
                  </Typography>
                  <Typography
                    className="w-700"
                    sx={{ fontSize: 16, color: "#081130", }}
                  >
                    {item.port_name_pelabuhan_muat ? capitalizeEachWord(item.port_name_pelabuhan_muat) : "-"}
                  </Typography>
                </Grid>
                <img style={{ marginRight: 10, position: 'absolute', left: '1%', top: '48%' }} alt="Nikel-icon" src={divider} height={20}></img>
                <Grid container direction='row' alignItems='center' sx={{ mt: 3 }}>
                  <img style={{ marginRight: 10 }} alt="Nikel-icon" src={positionActiveIcon} height={20}></img>
                  <Typography
                    className="w-500"
                    sx={{ fontSize: 14, color: "#56617B", mr: 1 }}
                  >
                    Discharge Port
                  </Typography>
                  <Typography
                    className="w-700"
                    sx={{ fontSize: 16, color: "#081130", fontWeight: 700 }}
                  >
                    {item.port_name_pelabuhan_bongkar ? capitalizeEachWord(item.port_name_pelabuhan_bongkar) : "-"}

                  </Typography>
                </Grid>
              </Stack>}

              <Grid
                marginTop={2}
                container
                direction="row"
                alignItems="left"
                justifyContent="space-between"
                spacing={0}
              >
                <Grid>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    direction="row"
                  >
                    <img alt="Nikel-icon" style={{ marginRight: 6 }} src={Calendericon} height={18}></img>
                    <Typography
                      className="w-700"
                      sx={{ fontSize: 14, color: "#56617B", mr: 1 }}
                    >
                      {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(item.permintaan_date))}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid >
                  <Grid className="pointer"
                    container
                    justifyContent="center"
                    alignItems="center"
                    direction="row"
                    onClick={(e) => { navigate(`/permintaan/detail?type=3&id_detail=${item.product_id}`) }}
                  >
                    <Typography
                      className="w-500"
                      sx={{ fontSize: 16, color: "#2457B9", mr: 1 }}
                    >
                      {translate('page_explore.lihatselengkapnya')}
                    </Typography>
                    <ArrowForwardIosIcon sx={{ fontSize: 14, color: "#2457B9" }} />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Hidden>
        <Hidden mdUp>
          <CardContent>
            <Box>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="end"
                spacing={0}
              >
                <Grid item xs={11}>
                  <Stack direction={'row'} alignItems="center">

                    <Typography
                      sx={{ fontSize: 18, color: '#081130', mr: 1 }}
                      className="w-700"
                    >
                      {item.permintaan_code}
                    </Typography>
                    <Box
                      sx={{
                        top: '10%',
                        background: "#F4F6FA",
                        borderRadius: 4,
                        pr: 2,
                        pl: 2,
                        pb: '2px',
                        pt: '2px',

                      }}
                    >
                      <Stack direction={'row'} alignItems={'center'}>
                        {item.type_tongkang === 2 ? (
                          <>
                            <Box sx={{ borderRadius: '50%', background: '#F55C24', width: '10px', height: '10px', mr: '4px' }}>
                            </Box>
                            <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 12 }} >
                              Time Charter
                            </Typography>
                          </>
                        ) : item.type_tongkang === 3 ? (
                          <>
                            <Box sx={{ borderRadius: '50%', background: '#883BE7', width: '10px', height: '10px', mr: '4px' }}>
                            </Box>
                            <Typography className="w-500" sx={{ color: '#818CA7', fontSize: '12px' }} >
                              Freight Charter
                            </Typography>
                          </>
                        ) : (
                          <></>
                        )}
                      </Stack>
                    </Box>
                  </Stack>
                  <Grid
                    container
                    justifyContent="left"
                    alignItems="center"
                    direction="row"
                  >
                    <img alt="Nikel-icon" style={{ marginRight: 6 }} src={Calendericon} height={18}></img>
                    <Typography
                      className="w-500"
                      sx={{ fontSize: 14, color: "#56617B", mr: 1 }}
                    >
                      {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(item.permintaan_date))}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid sx={{ textAlign: "right", }} item xs={1}>
                  <MoreHorizIcon onClick={(e) => handleOpenModal('more', item.product_id)} sx={{ cursor: 'pointer' }} />
                </Grid>
              </Grid>
              <Box onClick={(e) => { navigate(`/permintaan/detail?type=3&id_detail=${item.product_id}`) }}>
                <Grid direction={'row'} sx={{ textAlign: "left", alignItems: "center", display: 'flex', mt: 1, mb: 1 }} item xs={12}>
                  <Typography
                    sx={{ fontSize: 18, color: '#081130' }}
                    className="w-700"
                  >

                    {currency === 'idr' ? 'Rp' : '$'}  {(item.price).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                  </Typography>
                </Grid>
                <Typography className="w-500" sx={{ color: '#56617B', fontSize: 14, mt: 1, mb: 1 }} >
                  {translate('page_explore.ukurantongkang')}
                </Typography>

                {(x && x.length > 0) && <Grid container spacing={1} sx={{ mb: 1 }}>

                  {
                    x.map((data) => {

                      return (
                        <Grid item>
                          <Box sx={{ background: "#F4F6FA", borderRadius: 4, padding: 2 }}>
                            <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>
                              <Stack direction={'row'} alignItems={'center'}>
                                <Typography className="w-700" sx={{ color: '#081130', fontSize: 18 }}>
                                  {data}
                                </Typography>

                              </Stack>

                            </Stack>

                          </Box>
                        </Grid>
                      )

                    })}
                </Grid>}
                <Grid

                  container
                  direction="row"
                  alignItems="left"
                  justifyContent="left"
                  spacing={1}
                >


                  {(item.type_tongkang === 3 && item.quantity_muatan !== 0) && <Grid item xs={6}  >
                    <Box sx={{ background: "#F4F6FA", borderRadius: 4, padding: 2 }}>
                      <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>
                        <Typography className="w-700" sx={{ color: '#081130', fontSize: 18, display: 'inline', wordBreak: "break-all", }}>
                          {(item.quantity_muatan).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                        </Typography>
                        <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                          MT
                        </Typography>
                      </Stack>
                      <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                        {translate('page_explore.quantitymuatan')}
                      </Typography>
                    </Box>
                  </Grid>}

                </Grid>

                {(item.port_name_pelabuhan_muat || item.port_name_pelabuhan_bongkar) && <Stack direction={'column'} sx={{ position: 'relative' }}>
                  <Grid container direction='row' alignItems='center' sx={{ mt: 2 }}>
                    <img style={{ marginRight: 6 }} alt="Nikel-icon" src={positionInactiveIcon} height={20}></img>
                    <Typography
                      className="w-500"
                      sx={{ fontSize: 14, color: "#56617B", mr: 1 }}
                    >
                      Loading Port
                    </Typography>
                  </Grid>

                  <Typography
                    sx={{ fontSize: 14, mb: 1, ml: 3, color: '#081130' }}
                    className="w-700"
                  >
                    {item.port_name_pelabuhan_muat ? capitalizeEachWord(item.port_name_pelabuhan_muat) : "-"}
                  </Typography>
                  <img style={{ marginRight: 10, position: 'absolute', left: '10px', top: '34%' }} alt="Nikel-icon" src={dividerIcMobile} height={28} width={2}></img>
                  <Grid container direction='row' alignItems='center' sx={{ mt: 2 }}>
                    <img style={{ marginRight: 6 }} alt="Nikel-icon" src={positionActiveIcon} height={20}></img>
                    <Typography
                      className="w-500"
                      sx={{ fontSize: 14, color: "#56617B", mr: 1 }}
                    >
                      Discharge Port
                    </Typography>
                  </Grid>

                  <Typography
                    sx={{ fontSize: 14, mb: 1, ml: 3, color: '#081130' }}
                    className="w-700"
                  >
                    {item.port_name_pelabuhan_bongkar ? capitalizeEachWord(item.port_name_pelabuhan_bongkar) : "-"}
                  </Typography>
                </Stack>}
                <Grid
                  marginTop={2}
                  container
                  direction="row"
                  alignItems="left"
                  justifyContent="space-between"
                  spacing={0}
                >
                  <Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    sx={{ mb: 1 }}
                    justifyContent="space-between"
                    spacing={0}
                  >
                    <Grid direction={'column'} item xs={9}>
                      <Typography
                        sx={{ fontSize: 10, mb: 1, color: '#818CA7', textTransform: 'uppercase' }}
                        className="w-500"
                      >
                        {translate('jual-beli.nama-trader')}
                      </Typography>
                      <Grid
                        container
                        direction="row"
                        alignItems="left"
                        justifyContent="left"
                        spacing={0}
                      >
                        {item.user_trader_image ?
                          <Avatar src={config.baseURL + item.user_trader_image} sx={{ bgcolor: '#1976d2', height: '48px', width: '48px', mr: 1 }} aria-label="recipe">
                          </Avatar> : <Avatar sx={{ bgcolor: '#1976d2', height: '48px', width: '48px', mr: 1 }} aria-label="recipe">
                          </Avatar>
                        }
                        <Stack style={{ textAlign: "left", color: "white" }} spacing={0}>
                          <Typography
                            onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${item.user_id}` }}
                            sx={{ fontSize: 16, color: '#081130', cursor: 'pointer' }}
                            className="w-700"
                          >
                            {item && item?.user_trader_name?.length > 20 ?
                              item?.user_trader_name.slice(0, 20) + '...'
                              : item?.user_trader_name}

                          </Typography>
                          {item.company_list.length > 0 ? <Stack direction={'row'}>
                            {item.company_list.map((item, index) => {
                              if (index < 4) return (
                                <div key={item.company_id}>
                                  {index === 0 ? (
                                    item?.company_image ?
                                      <Avatar src={`${config.baseURL + item?.company_image}`} sx={{ bgcolor: '#1976d2', width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                                        <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
                                      </Avatar> :
                                      <Avatar sx={{ bgcolor: '#1976d2', width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                                        <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
                                      </Avatar>
                                  ) : (
                                    item?.company_image ?
                                      <Avatar src={`${config.baseURL + item?.company_image}`} sx={{ bgcolor: '#1976d2', ml: -1, width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                                        <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
                                      </Avatar>
                                      : <Avatar sx={{ bgcolor: '#1976d2', ml: -1, width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                                        <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
                                      </Avatar>
                                  )}
                                </div>
                              )
                            })}
                            {item.company_list.length > 4 && <Avatar className="w-500" style={{ marginLeft: -6, width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                              +{item.company_list.length - 4}
                            </Avatar>}
                          </Stack>
                            :
                            <Typography className="w-500" sx={{ fontSize: 14, color: '#818CA7', }}>
                              Freelance
                            </Typography>}
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            {id_user !== item.user_id.toString() && <Button className="w-500" onClick={(e) => handleOpenModal(item.user_trader_phone)} size="large" sx={{ mt: '24px', background: '#3BB56C', textTransform: 'none', padding: '10px 12px', borderRadius: '8px', fontSize: '14px' }} variant="contained" fullWidth startIcon={<CallIcon />}>
              {translate('detail-explore.hubungitrader')}
            </Button>}
            <ModalHubungiTrader page={'permintaan'} user_trader_phone={phone} type={activeTab} open={isOpenModal} close={handleCloseModal}></ModalHubungiTrader>
            <DrawerMore type={'tongkang'} page="permintaan" id={product_id} share={handleShareModal} open={isOpenMore} close={handleCloseModal} setFavorite={setFavorite} removeFavorite={removeFavorite} data={item}>
            </DrawerMore>
            <ModalShareListing open={isOpenModalShare} close={closeShareModal}></ModalShareListing>
          </CardContent>
        </Hidden>
      </Card>}
    </div>

  )
}
