import { Grid, Typography } from '@material-ui/core';
import { Stack } from "@mui/material";
import React from 'react';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import doc_icon2 from "../assets/doc-icon.svg";
import pdfIcon from "../assets/pdf-ic.png";
import { config } from '../services/constants';

function DisplayPDFCompany(props) {
  let newName = props.name
  let newUrl = props.url

  if (props.url.includes(`${config.baseURL}`)) {
    newUrl = props.url
  } else {
    newUrl = `${config.baseURL}${props.url}`
  }

  if (props.name.length >= 9) {
    newName = props.name.slice(0, 8)
  }
  const pdfUrl2 = React.useMemo(() => ({ uri: newUrl }), [newUrl]);
  let render = React.useMemo(() => (
    <DocViewer documents={[pdfUrl2]}
      pluginRenderers={DocViewerRenderers}
    />
  ), [pdfUrl2]);

  return (
    <Grid container direction='row' justifyContent='space-between' style={{ flexWrap: "nowrap" }}>
      <div key={props.key_pdf} className='flex column' style={{ width: '100%' }}>
        <a href={props.url}
          target="_blank"
          rel="noreferrer" className='box-document border-box' >
          {render}
        </a>
        <div className='doc-desc-company flex' style={{ paddingTop: "10px", paddingBottom: "10px", paddingLeft: '10px', justifyContent: 'space-between' }}>
          <Stack direction={'row'}>
            <img style={{ height: '28px', width: '28px' }} src={props.ext === '.pdf' ? pdfIcon : doc_icon2} alt="" className='mr8' />
            <Stack>
              <p className='w-500 s-1419 c-08'>{`${newName}...`}</p>
              <div className='flex '>
                <p className='w-500 s-1216 c-56'>{props.ext === '.pdf' ? 'PDF' : props.ext === '.xlsx' ? 'XLSX' : 'DOC'}</p>
                &nbsp;
                <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2" r="2" fill="#818CA7" />
                </svg> &nbsp;
                <a href={props.url} style={{ textDecoration: 'none' }} target="_blank"
                  rel="noreferrer" className='c-24 s-1216 w-500'>Download</a>
              </div>
            </Stack>
          </Stack>
          <Typography onClick={(e) => {
            props.delete()
          }} className='w-500' style={{ color: '#ED3131', fontSize: 14, marginRight: '20px', cursor: 'pointer' }}>Delete</Typography>
        </div>

      </div>
    </Grid>
  )
}

export { DisplayPDFCompany };

