import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Avatar, Box, Button, Card, FormControl, Grid, Hidden, IconButton, InputAdornment, MenuItem, Radio, Select, Stack, TextField, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import delete_doc from "../assets/hapus-doc-ic.svg";
import calendarIcon from '../assets/material-symbols_calendar-today.svg';
import nikel_icon from "../assets/nikel_icon.svg";
import { DisplayPDFFile } from '../components/DisplayPDF';
import DrawerHapusDoc from '../components/DrawerHapusDoc';
import DrawerUploadDoc from '../components/DrawerUploadDocLayer';
import DrawerpublishListing from "../components/DrawerpublishListing";
import DropZoneUnggah from '../components/DropZoneUnggah';
import Footer from "../components/Footer";
import FormCompanySumberBatu from "../components/FormCompanySumberBatu";
import FormLoadingPortLocation from '../components/FormLoadingPortLocation';
import ModalBatalForm from '../components/ModalBatalForm';
import ModalUnggahDoc from '../components/ModalUnggahDocBaruLayer';
import NavBar from "../components/Navbar";
import SnackBarDraft from '../components/SnackBarDraft';
import { GetValidationPriceQuantity, editNikel, getCompanyListing, postNikel } from "../services/api.js";
import { config } from '../services/constants';
import { GlobalState } from "../services/global";
import { DateIcon, VerticalDividerQuantity } from '../services/primary';
import Value from "../services/value.json";

export default function JualNikel() {
  const location = useLocation();
  const navigate = useNavigate()
  const [date, setDate] = React.useState('');
  const [translate,] = useTranslation("global");
  const [kadar_ni, setNi] = useState("")
  const [kadar_fe, setFe] = useState("")
  const [kadar_air, setAir] = useState("")
  const [si_mg_ratio, setSiMg] = useState("")
  const [quantity, setQuantity] = useState("")
  const [price, setPrice] = useState("")
  const [description, setDesc] = useState("")
  const [id_loading_port, setPort] = useState("")
  const [id_company_listing, setCompany] = useState("")
  const [companyList, setCompanyList] = useState([]);
  const [document, setDocument] = useState([])
  const [id, setId] = useState("")
  const [openStartDate, setopenStartDate] = useState(false);
  const [file, setFile] = useState([]);
  const [validationNi, setValidationNi] = useState(false)
  const [validationFe, setValidationFe] = useState(false)
  const [validationAir, setValidationAir] = useState(false)
  const [validationRatio, setValidationRatio] = useState(false)
  const [validationqty, setvalidationqty] = useState(false)
  const [validationprice, setvalidationprice] = useState(false)
  const [validationport, setvalidationport] = useState(false)
  const [validationlaycandate, setvalidationlaycandate] = useState(null)
  const [validationupload, setvalidationupload] = useState(false)
  const [validationsumberbatu, setvalidationsumberbatu] = useState(null)
  const [validationlocation, setvalidationlocation] = useState(null)
  const [isOpenModal, setOpenModal] = React.useState(false)
  const [isOpenDrawerDoc, setOpenDrawerDoc] = React.useState(false)
  const [isOpenUploadDoc, setOpenUploadDoc] = React.useState(false)
  const [x, setX] = useState(location?.state === null ? null : location.state.data)
  const [page] = useState(location?.state === null ? null : location.state.page)
  const [validationPriceQty, setvalidationPriceQty] = useState('')
  const [openModalBatalForm, setOpenModalBatalForm] = useState(false);
  const [selectcurrency, setselectcurrency] = React.useState('idr');
  const [listCurrency] = useState([{ id: 'idr', name: 'Rp' }, { id: 'usd', name: '$' }])
  const [isSuccessDraft, setisSuccessDraft] = useState(false);
  const [position, setPosition] = useState("center")
  const { currency, setcurrency } = React.useContext(GlobalState)

  const [id_sumber_batu, setidsumberbatu] = useState("")
  const [selectProvince, setSelectProvince] = useState("")
  const [selectProvinceName, setSelectProvinceName] = useState("")

  const handleSuccessDraft = () => {
    setisSuccessDraft(true)
  };


  const handleOpenModalUpload = () => {
    setOpenUploadDoc(true)
  };

  const handleOpenBatalForm = () => {
    setOpenModalBatalForm(true)
  };

  const handleCloseModalUpload = (params, fileQ) => {
    if (params === 'submit') {
      let fileArrayCombine = file.concat(fileQ)
      setFile(fileArrayCombine)
    }
    setOpenUploadDoc(false);
  };

  useEffect(() => {
    getCompany()
    updateField()
  }, []);

  async function getCompany() {
    try {
      const response = await getCompanyListing();
      setCompanyList(response.data?.data.data);
    } catch (e) {
      console.log(e);
    }
  }

  function handleSelectPort(e) {
    setPort(e.port_id)
    setSelectProvince(e.location_id)
    setSelectProvinceName(e.location_name)
  }

  function updateField() {
    if (x !== null) {
      setNi(x.kadar_ni)
      setAir(x.kadar_air)
      setSiMg(x.si_mg_ratio)
      setDesc(x.description)
      setDate(new Date(x.laycan_date))
      setSelectProvince(x.location === null ? null : x.location?.id)
      setPort(x.loading_port === null ? null : x.loading_port?.id)
      setId(x.id)
      setQuantity(x.quantity)
      setPrice(x.price)
      setidsumberbatu(x.sumber_batu === null ? null : x.sumber_batu?.id)
      setCompany(x.company_listing?.id)
      setFe(x.kadar_fe)
      setFile(x.nikel_documents)
      setcurrency(x.currency);
      setselectcurrency(x.currency);
      GetValidation(x.currency)
    } else {

      GetValidation(selectcurrency)
    }
  }

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setOpenModalBatalForm(false);
    setisSuccessDraft(false);

  };

  const SubmitResponsivePublish = () => {
    submit(1);
  };

  const SubmitResponsiveDraft = () => {
    setPosition('top')
    submitDraft(3);
  };

  const handleChangeCurrency = (e) => {
    if (e.target.value === 'idr') {
      setPrice((price * validationPriceQty.usd_idr));
    } else {
      setPrice((price / validationPriceQty.usd_idr))
    }
    setselectcurrency(e.target.value)
    GetValidation(e.target.value)
  };


  async function GetValidation(datacurrency) {
    const data = {
      currency: datacurrency
    }

    try {
      const response = await GetValidationPriceQuantity(data)
      setvalidationPriceQty(response.data?.data);
    } catch (e) {
      console.log(e);
    }
  }

  const deleteFileonArrayDrawer = (index) => {
    let newFileDocument = file.concat([])
    if (index > -1) {
      newFileDocument.splice(index, 1);
    }
    setFile(newFileDocument);
  }

  function handleChangeFileFromDeleteResponsive(fileQ, y) {
    setFile(fileQ);
  }

  const handleOpenDrawerDoc = (file) => {
    setDocument(file)
    setOpenDrawerDoc(true);
  };
  const handleCloseDrawerDoc = () => {
    setOpenDrawerDoc(false);
  };

  async function submit(e) {

    const data = {
      kadar_ni,
      kadar_fe,
      kadar_air,
      si_mg_ratio,
      quantity,
      price,
      id_loading_port,
      id_company_listing: id_company_listing,
      description,
      laycan_date: date !== '' ? (new Date(date)).toLocaleDateString('sv').substring(0, 10) : '', status_listing: e,
      token: localStorage.getItem("token"),
      path_documents: JSON.stringify(file),
      currency: selectcurrency,
      id_sumber_batu: id_sumber_batu,
      id_location: selectProvince,
    }

    if (x === null) {
      if (data.path_documents === '[]' || data.kadar_ni === "" || data.kadar_fe === "" || data.kadar_air === "" || data.si_mg_ratio === "" || data.quantity === "" || data.price === "" || data.id_loading_port === "" || data.laycan_date === "" || data.id_sumber_batu === '' || data.id_location === '') {
        data.kadar_ni === '' ? setValidationNi(true) : setValidationNi(false)
        data.kadar_fe === '' ? setValidationFe(true) : setValidationFe(false)
        data.kadar_air === '' ? setValidationAir(true) : setValidationAir(false)
        data.si_mg_ratio === '' ? setValidationRatio(true) : setValidationRatio(false)
        data.quantity === '' ? setvalidationqty(true) : setvalidationqty(false)
        data.price === '' ? setvalidationprice(true) : setvalidationprice(false)
        data.id_loading_port === '' ? setvalidationport(true) : setvalidationport(false)
        data.laycan_date === '' ? setvalidationlaycandate('') : setvalidationlaycandate(null)
        data.path_documents === '[]' ? setvalidationupload(true) : setvalidationupload(false)
        data.id_sumber_batu === '' ? setvalidationsumberbatu(true) : setvalidationsumberbatu(null)
        data.id_location === '' ? setvalidationlocation(true) : setvalidationlocation(null)
        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('status.Fieldkosong'),
        })
      } else if (data.kadar_ni > Value.Ni.maks || data.kadar_ni < Value.Ni.min) {
        setValidationNi(true)
        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('status.errorvaluelimit'),
        })

      } else if (data.kadar_fe > Value.Fe.maks || data.kadar_fe < Value.Fe.min) {
        setValidationFe(true)
        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('status.errorvaluelimit'),
        })

      } else if (data.kadar_air > Value.Moisture.maks || data.kadar_air < Value.Moisture.min) {
        setValidationAir(true)
        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('status.errorvaluelimit'),
        })

      } else if (data.si_mg_ratio > Value['Si/Mg'].maks || data.si_mg_ratio < Value['Si/Mg'].min) {
        setValidationRatio(true)
        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('status.errorvaluelimit'),
        })

      } else if (quantity > validationPriceQty.maximum_quantity || quantity < validationPriceQty.minimum_quantity) {
        setvalidationqty(true)
        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('status.errorqtylimit'),
        })

      } else if (price > validationPriceQty.maximum_prices || price < validationPriceQty.minimum_prices) {
        setvalidationprice(true)

        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('status.errorpricelimit'),
        })

      } else {
        const res = await postNikel(data, [])
        if (res.status === 200) {

          navigate(`/listing`, { state: { detail: 'nikel' } })
        } else {
          Swal.fire({
            icon: 'error',
            title: translate('status.failed'),
            text: translate('status.subtitlefailed'),
          })
        }
      }
    } else {
      if (data.path_documents === '[]' || data.kadar_ni === "" || data.kadar_fe === "" || data.kadar_air === "" || data.si_mg_ratio === "" || data.quantity === "" || data.price === "" || data.id_loading_port === "" || data.laycan_date === "" || data.id_sumber_batu === '' || data.id_location === '') {
        data.kadar_ni === '' ? setValidationNi(true) : setValidationNi(false)
        data.kadar_fe === '' ? setValidationFe(true) : setValidationFe(false)
        data.kadar_air === '' ? setValidationAir(true) : setValidationAir(false)
        data.si_mg_ratio === '' ? setValidationRatio(true) : setValidationRatio(false)
        data.quantity === '' ? setvalidationqty(true) : setvalidationqty(false)
        data.price === '' ? setvalidationprice(true) : setvalidationprice(false)
        data.id_loading_port === '' ? setvalidationport(true) : setvalidationport(false)
        data.laycan_date === '' ? setvalidationlaycandate('') : setvalidationlaycandate(null)
        data.path_documents === '[]' ? setvalidationupload(true) : setvalidationupload(false)
        data.id_sumber_batu === '' ? setvalidationsumberbatu(true) : setvalidationsumberbatu(null)
        data.id_location === '' ? setvalidationlocation(true) : setvalidationlocation(null)
        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('status.Fieldkosong'),
        })
      } else if (data.kadar_ni > Value.Ni.maks || data.kadar_ni < Value.Ni.min) {
        setValidationNi(true)
        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('status.errorvaluelimit'),
        })

      } else if (data.kadar_fe > Value.Fe.maks || data.kadar_fe < Value.Fe.min) {
        setValidationFe(true)
        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('status.errorvaluelimit'),
        })

      } else if (data.kadar_air > Value.Moisture.maks || data.kadar_air < Value.Moisture.min) {
        setValidationAir(true)
        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('status.errorvaluelimit'),
        })

      } else if (data.si_mg_ratio > Value['Si/Mg'].maks || data.si_mg_ratio < Value['Si/Mg'].min) {
        setValidationRatio(true)
        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('status.errorvaluelimit'),
        })

      } else if (quantity > validationPriceQty.maximum_quantity || quantity < validationPriceQty.minimum_quantity) {
        setvalidationqty(true)
        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('status.errorqtylimit'),
        })

      } else if (price > validationPriceQty.maximum_prices || price < validationPriceQty.minimum_prices) {
        setvalidationprice(true)

        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('status.errorpricelimit'),
        })

      } else {
        const res = await editNikel(data, id)
        if (res.data?.data !== null) {
          navigate(`/listing`, { state: { detail: 'nikel' } })
        } else {
          Swal.fire({
            icon: 'error',
            title: translate('status.failed'),
            text: translate('status.subtitlefailed'),
          })
        }
      }
    }
  }

  async function submitDraft(e) {

    const data = {
      kadar_ni: kadar_ni === "" ? 0 : kadar_ni,
      kadar_fe: kadar_fe === "" ? 0 : kadar_fe,
      kadar_air: kadar_air === "" ? 0 : kadar_air,
      si_mg_ratio: si_mg_ratio === "" ? 0 : si_mg_ratio,
      quantity: quantity === "" ? 0 : quantity,
      price: price === "" ? 0 : price,
      id_loading_port,
      id_company_listing: id_company_listing,
      description,
      laycan_date: date !== '' ? (new Date(date)).toLocaleDateString('sv').substring(0, 10) : new Date(Date.now), status_listing: e,
      token: localStorage.getItem("token"),
      path_documents: JSON.stringify(file),
      id_sumber_batu: id_sumber_batu,
      id_location: selectProvince,
      currency: selectcurrency
    }

    if (x === null) {

      const res = await postNikel(data, [])
      if (res.status === 200) {
        handleSuccessDraft()
        setNi('')
        setAir('')
        setSiMg('')
        setDesc('')
        setDate('')
        setPort('')
        setId('')
        setQuantity('')
        setPrice('')
        setCompany('')
        setFe('')
        setFile([])
        setidsumberbatu('')
        setSelectProvince('')

        setX(null)

      } else {
        Swal.fire({
          icon: 'error',
          title: translate('status.failed'),
          text: translate('status.subtitlefailed'),
        })
      }

    } else {
      const res = await editNikel(data, id)
      if (res.data?.data !== null) {
        handleSuccessDraft()
        setNi('')
        setAir('')
        setSiMg('')
        setDesc('')
        setDate('')
        setPort('')
        setId('')
        setQuantity('')
        setPrice('')
        setCompany('')
        setFe('')
        setFile([])
        setidsumberbatu('')
        setSelectProvince('')
        setX(null)
      } else {
        Swal.fire({
          icon: 'error',
          title: translate('status.failed'),
          text: translate('status.subtitlefailed'),
        })
      }
    }
  }

  return (
    <div>
      <div className='fixed-header'>
        <NavBar /></div>
      <Box className="flex-wrap-center width-100" >
        <Box className="content-inner" sx={{ mt: { xs: 6, sm: 6, md: 11, lg: 11, xl: 11 }, ml: { xs: '24px', lg: '80px', }, mr: { xs: '24px', lg: '80px', }, }}>
          {/* navigation Section */}
          {page === 'edit-nikel' ?
            <Grid container direction="row" alignItems='center' className='mt-18'>
              <Button href="/" className="w-500 mb8 c-24 txt-none-tranform pl-0" sx={{ fontSize: { xs: 14, sm: 14, md: 16, lg: 16 } }} variant="text"> {translate('navbar.home')}</Button>

              <Typography className="w-500 mb8 mr8 c-56" sx={{ fontSize: { xs: 14, sm: 14, md: 16, lg: 16 } }}>
                /
              </Typography>
              <Button href="/listing" className="w-500 mb8 c-24 txt-none-tranform" sx={{ fontSize: { xs: 14, sm: 14, md: 16, lg: 16 } }} variant="text"> {translate('navbar.mylisting')}</Button>


              <Typography className="w-500 mb8 mr8 c-56" sx={{ fontSize: { xs: 14, sm: 14, md: 16, lg: 16 } }}>
                /
              </Typography>
              <Typography className="w-500 mb8 c-56" sx={{ fontSize: { xs: 14, sm: 14, md: 16, lg: 16 } }}>
                {translate('detail-explore.detailnikel')}
              </Typography>
            </Grid> :
            <Grid container direction="row" alignItems='center' className='mt-18'>
              <Button href="/" className="w-500 mb8 c-24 txt-none-tranform pl-0" sx={{ fontSize: { xs: 14, sm: 14, md: 16, lg: 16 } }} variant="text"> {translate('navbar.home')}</Button>

              <Typography className="w-500 mb8 mr8 c-56" sx={{ fontSize: { xs: 14, sm: 14, md: 16, lg: 16 } }}>
                /
              </Typography>
              <Button href="/explore" className="w-500 mb8 c-24 txt-none-tranform" sx={{ fontSize: { xs: 14, sm: 14, md: 16, lg: 16 } }} variant="text"> {translate('navbar.explore')}</Button>


              <Typography className="w-500 mb8 mr8 c-56" sx={{ fontSize: { xs: 14, sm: 14, md: 16, lg: 16 } }}>
                /
              </Typography>
              <Typography className="w-500" sx={{ fontSize: { xs: 14, sm: 14, md: 16, lg: 16 }, mb: 1, color: '#56617B' }}>
                {translate('page_explore.btn-jual-nikel')}
              </Typography>
            </Grid>}
          {/* header Section */}
          <Grid container direction="row" justifyContent="space-between" className='mt-18'>
            <Grid item xs={12} sm={12} md={3} lg={3}>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} sx={{ display: "flex", justifyContent: "center", textAlign: "center", flexDirection: "column" }} >
              <Box style={{ textAlign: "center", justifyContent: "center", display: "flex" }}>
                <img src={nikel_icon} width={42} alt='' />
              </Box>

              <h3 className='w-700 c-08'>{translate('page_explore.btn-jual-nikel')}</h3>
              <p style={{ textAlign: "center" }} className='s-1622 mt-12 w-500 c-56 mb-52'>{translate('title.sub-form-jual')}</p>
            </Grid>
            <Grid item xs={3} className='wd-252'>
            </Grid>
          </Grid>


          {/* Form Spesifikasi Nikel Section */}
          <Grid container direction='row' justifyContent='space-between'>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Typography className="w-700" sx={{ fontSize: 24, mb: '16px', color: '#081130' }}>
                {translate('jual-beli.spesifikasi-nikel')}
              </Typography>
              <p className='c-56 w-400 s-1421 mb-16'>{translate('jual-beli.subtitle-spesifikasi-nikel')}</p>
            </Grid>
            {/* Form Section */}
            <Grid item xs={12} sm={12} md={4} lg={4} className='form-input'>
              {/* Form Kadar Ni Section */}
              <p className='c-08 s-1419 w-500 mb4'>{translate('page_explore.kadar-ni')}</p>
              <FormControl sx={{ width: '100%' }} variant="outlined">
                <TextField
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                      display: "none",
                    },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    }, input: {
                      fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                  }}
                  type='number'
                  error={validationNi}
                  helperText={validationNi ? translate('status.limitvalue') + ' ' + Value.Ni.min.toString() + ' - ' + Value.Ni.maks.toString() : ''}
                  className='box-input-filter' value={kadar_ni} onChange={(e) => {
                    const value = parseInt(e.target.value);
                    setNi(e.target.value)
                    if (value > parseInt(Value.Ni.maks)) {
                      setValidationNi(true)
                    } else if (value < parseInt(Value.Ni.min)) {
                      setValidationNi(true)
                    } else {
                      setValidationNi(false)
                    }
                  }}
                  InputProps={{

                    endAdornment: (
                      <InputAdornment position="end" >
                        <VerticalDividerQuantity />
                        <Typography className="w-700" sx={{ fontSize: 14, color: '#56617B', lineHeight: 'normal', textAlign: 'center', mr: 1 }}>
                          %
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  aria-describedby="outlined-weight-helper-text" placeholder={translate('jual-beli.placeholder-ni')}
                />
              </FormControl>
              {/* Form Kadar FE Section */}
              <p className='c-08 s-1419 w-500 mb4 mt-24'>{translate('page_explore.kadar-fe')}</p>
              <FormControl sx={{ width: '100%' }} variant="outlined">
                <TextField
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                      display: "none",
                    },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    }, input: {
                      fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                  }}
                  type='number'
                  error={validationFe}
                  helperText={validationFe ? translate('status.limitvalue') + ' ' + Value.Fe.min.toString() + ' - ' + Value.Fe.maks.toString() : ''}
                  className='box-input-filter' value={kadar_fe} onChange={(e) => {
                    const value = parseInt(e.target.value);
                    setFe(e.target.value)
                    if (value > parseInt(Value.Fe.maks)) {
                      setValidationFe(true)
                    } else if (value < parseInt(Value.Fe.min)) {
                      setValidationFe(true)
                    } else {
                      setValidationFe(false)
                    }
                  }}
                  InputProps={{

                    endAdornment: (
                      <InputAdornment position="end" >
                        <VerticalDividerQuantity />
                        <Typography className="w-700" sx={{ fontSize: 14, color: '#56617B', mr: 1 }}>
                          %
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  aria-describedby="outlined-weight-helper-text" placeholder={translate('jual-beli.placeholder-fe')}
                />
              </FormControl>
              {/* Form Kadar Air Section */}
              <p className='c-08 s-1419 w-500 mb4 mt-24'>{translate('page_explore.kadar-air')}</p>
              <FormControl sx={{ width: '100%' }} variant="outlined">
                <TextField
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                      display: "none",
                    },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    }, input: {
                      fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                  }}
                  type='number'
                  error={validationAir}
                  helperText={validationAir ? translate('status.limitvalue') + ' ' + Value.Moisture.min.toString() + ' - ' + Value.Moisture.maks.toString() : ''}
                  className='box-input-filter' value={kadar_air} onChange={(e) => {
                    const value = parseInt(e.target.value);
                    setAir(e.target.value)
                    if (value > parseInt(Value.Moisture.maks)) {
                      setValidationAir(true)
                    } else if (value < parseInt(Value.Moisture.min)) {
                      setValidationAir(true)
                    } else {
                      setValidationAir(false)
                    }
                  }}
                  InputProps={{

                    endAdornment: (
                      <InputAdornment position="end" >
                        <VerticalDividerQuantity />
                        <Typography className="w-700" sx={{ fontSize: 14, color: '#56617B', mr: 1 }}>
                          %
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  aria-describedby="outlined-weight-helper-text" placeholder={translate('jual-beli.placeholder-air')}
                />
              </FormControl>
              {/* Form Si/Mg Section */}
              <p className='c-08 s-1419 w-500 mb4 mt-24'>Si/Mg </p>
              <TextField error={validationRatio}
                helperText={validationRatio ? translate('status.limitvalue') + ' ' + Value['Si/Mg'].min.toString() + ' - ' + Value['Si/Mg'].maks.toString() : ''}
                className='box-input-filter' sx={{
                  width: '100%',
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                    display: "none",
                  },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  }, input: {
                    fontSize: '14px', fontFamily: 'Satoshi-500'
                  },
                }}
                type='number' value={si_mg_ratio} onChange={(e) => {
                  const value = parseInt(e.target.value);
                  setSiMg(e.target.value)
                  if (value > parseInt(Value['Si/Mg'].maks)) {
                    setValidationRatio(true)
                  } else if (value < parseInt(Value['Si/Mg'].min)) {
                    setValidationRatio(true)
                  } else {
                    setValidationRatio(false)
                  }
                }}
                variant="outlined" placeholder={translate('jual-beli.placeholder-ratio')} />
            </Grid>
            <Grid item xs={3} className='wd-252'>
            </Grid>
          </Grid>

          <Grid container className='line mt-24 mb-32'>

          </Grid>
          {/* Form Detail Batubara Section */}
          <Grid container direction='row' justifyContent='space-between'>
            <Grid item xs={12} sm={12} md={3} lg={3} >
              <Typography className="w-700" sx={{ fontSize: 24, mb: '16px', color: '#081130' }}>
                {translate('jual-beli.spesifikasi-nikel')}
              </Typography>
              <p className='c-56 w-400 s-1421 mb-16'>{translate('jual-beli.subtitle-detail-nikel')}</p>
            </Grid>
            {/* Form Section */}
            <Grid item xs={12} sm={12} md={4} lg={4} className='form-input'>
              {/* Form Quantity Section */}
              <p className='c-08 s-1419 w-500 mb4'>Quantity</p>
              <FormControl sx={{ width: '100%' }} variant="outlined">
                <TextField sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                    display: "none",
                  },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  }, input: {
                    fontSize: '14px', fontFamily: 'Satoshi-500'
                  },
                }}
                  type='number' error={validationqty}
                  helperText={validationqty ? translate('status.limitqty') + ' ' + validationPriceQty.minimum_quantity.toString() + ' - ' + validationPriceQty.maximum_quantity.toString() : ''}
                  className='box-input-filter' value={quantity} onChange={(e) => {
                    const value = parseInt(e.target.value);

                    setQuantity(value);
                    if (value > parseInt(validationPriceQty.maximum_quantity)) {
                      setvalidationqty(true)
                    } else if (value < parseInt(validationPriceQty.minimum_quantity)) {
                      setvalidationqty(true)
                    } else {
                      setvalidationqty(false)
                    }
                  }}
                  InputProps={{

                    endAdornment: (
                      <InputAdornment position="end" >
                        <VerticalDividerQuantity />
                        <Typography className="w-700" sx={{ fontSize: 14, color: '#56617B' }}>
                          MT
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  aria-describedby="outlined-weight-helper-text" placeholder={translate('jual-beli.placeholder-quantity')}
                />
              </FormControl>
              {/* Form Harga Section */}
              <p className='c-08 s-1419 w-500 mb4 mt-24'>{translate('page_explore.harga-nikel')}</p>
              <TextField sx={{

                width: '100%',
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                  display: "none",
                },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                }, input: {
                  fontSize: '14px', fontFamily: 'Satoshi-500'
                },
              }}
                type='number' error={validationprice}
                helperText={validationprice ? translate('status.limitharga') + ' ' + validationPriceQty.minimum_prices.toString() + ' - ' + validationPriceQty.maximum_prices.toString() : ''}
                value={price} onChange={(e) => {
                  const value = parseInt(e.target.value);

                  setPrice(value);
                  if (value > parseInt(validationPriceQty.maximum_prices)) {
                    setvalidationprice(true)
                  } else if (value < parseInt(validationPriceQty.minimum_prices)) {
                    setvalidationprice(true)
                  } else {
                    setvalidationprice(false)
                  }
                }}
                className='box-input-form'
                placeholder={validationPriceQty.minimum_prices + '-' + validationPriceQty.maximum_prices}
                InputProps={{

                  startAdornment: (
                    <InputAdornment position="start">
                      <Select
                        disableUnderline
                        variant='standard'
                        className='box-form-currency'
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              height: '120px',
                              '& .MuiMenuItem-root': {
                              },
                            },
                          },
                        }}
                        sx={{
                          "& fieldset": { border: 'none' },
                          boxShadow: "none",
                          ".MuiOutlinedInput-notchedOutline": { border: 0 },
                          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            border: 0,
                          },
                          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            border: 0,
                          },
                        }}
                        IconComponent={() => (
                          <KeyboardArrowDownIcon sx={{ width: '20px', height: '20px', fill: '#56617B', position: 'absolute', right: 0, pointerEvents: 'none' }} />
                        )}
                        value={selectcurrency} onChange={(e) => handleChangeCurrency(e)} displayEmpty >
                        {listCurrency.map((item, i) => (
                          <MenuItem key={item.id} value={item.id}>{item.name} </MenuItem>
                        ))}
                      </Select>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end" >
                      <VerticalDividerQuantity />
                      <Typography className="w-700" sx={{ fontSize: 14, color: '#56617B' }}>
                        {translate('status.hargapermt')}
                      </Typography>
                    </InputAdornment>
                  ),
                }} />
              {/* Form SumberBatu Section */}
              <p className='c-08 s-1419 w-500 mb4 mt-24'> {translate('status.sumberbatu')}</p>
              <FormCompanySumberBatu select={(e) => { setidsumberbatu(e.id) }} selectName={(e) => { }} currentValue={id_sumber_batu} />
              {/* Form Loading Port Section */}
              <p className='c-08 s-1419 w-500 mb4 mt-24'>Loading Port</p>
              <FormLoadingPortLocation select={(e) => handleSelectPort(e)} selectName={(e) => { }} currentValue={id_loading_port} placeholder={translate('jual-beli.placeholder-loadingport')} id_location={selectProvince} />
              {/* Form Provinsi Section */}
              <p className='c-08 s-1419 w-500 mb4 mt-24'>{translate('login-register.provinsi')}</p>
              <FormControl sx={{ width: '100%' }} variant="outlined">
                <TextField
                  disabled={true}
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                      display: "none",
                    },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    }, input: {
                      fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                  }}
                  type='text'

                  className="box-input-filter"
                  onChange={(e) => {

                  }}
                  value={selectProvinceName}

                  aria-describedby="outlined-weight-helper-text" placeholder={translate('login-register.placeholder-provinsi')}
                />
              </FormControl>


              {/* Form Laycandate Section */}
              <p className='c-08 s-1419 w-500 mb4 mt-24'>Laycan Date </p>
              <Grid container width="100%" className="laycan-date">
                <LocalizationProvider dateAdapter={AdapterDayjs} >
                  <DatePicker
                    open={openStartDate}
                    onClose={() => setopenStartDate(false)}
                    slotProps={{
                      textField: {
                        placeholder: translate('status.placeholder-tgl-laycan'),
                        InputProps: {
                          startAdornment:
                            <InputAdornment position="start">

                              <img src={calendarIcon} onClick={() => setopenStartDate(true)} alt='' style={{ marginRight: '8px', cursor: 'pointer' }} />
                            </InputAdornment>
                        }
                      }
                    }} slots={{
                      openPickerIcon: DateIcon
                    }} inputFormat="MMM dd yyyy"
                    value={date ? dayjs(date) : validationlaycandate} onChange={(e) => { setDate(e) }} />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid item xs={3} className='wd-252'>
            </Grid>
          </Grid>

          <Grid container className='line mt-24 mb-32'>

          </Grid>

          <Grid container direction='row' justifyContent='space-between'>
            <Grid item xs={12} sm={12} md={3} lg={3}>

              <Typography className="w-700" sx={{ fontSize: 24, mb: '16px', color: '#081130' }}>{translate('jual-beli.dokumen-nikel')}</Typography>
              <p className='c-56 w-400 s-1421 mb-16'>{translate('jual-beli.subtitle-dokumen-nikel')}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} >
              <Grid container direction='row' justifyContent='space-between'>
                <p className='c-08 s-1419 w-500 mb4'>{translate('jual-beli.dokumen')}</p>
                {file.length > 0 &&
                  <label for="upload2">
                    <p onClick={(e) => {
                      handleOpenModalUpload();
                    }} className='c-24 s-1419 w-500 mb4'>{translate('jual-beli.unggah-dokument')}</p>
                  </label>}
              </Grid>
              {/* <DisplayPDF responsive /> */}
              <Hidden smUp>
                {file.length === 0 ? (
                  <></>
                ) : file.length > 2 ? (
                  <Grid style={{ marginTop: "5px" }} container direction={'row'} spacing={2}>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <Card sx={{ borderRadius: 2, height: '150px', width: '150px' }}>
                        <DisplayPDFFile
                          url={`${config.baseURL}${file[0].url}`}
                          key={0}
                          ext={`${file[0].ext}`}
                          name={`${file[0].name}`}
                        />
                      </Card>
                    </Grid>

                    <Grid onClick={(e) => {
                      handleOpenDrawerDoc(file)

                    }} item xs={6} sm={6} md={6} lg={6}>
                      <Card sx={{ position: 'absolute', background: 'var(--overlay, rgba(5, 9, 16, 0.56));', zIndex: 9999, borderRadius: '8px', height: '150px', width: '150px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                        <Typography sx={{ fontSize: 36, color: '#FFFFFF' }} className='w-700'>+{file.length}</Typography>
                      </Card>
                      <Card sx={{ borderRadius: 2, position: 'relative', height: '150px', width: '150px' }}>
                        <DisplayPDFFile
                          url={`${config.baseURL}${file[1].url}`}
                          key={1}
                          ext={`${file[1].ext}`}
                          name={`${file[1].name}`}
                        />
                      </Card>
                    </Grid>

                  </Grid>
                ) : (
                  <Grid style={{ marginTop: "5px" }} container direction={'row'} spacing={2}>
                    {file.map((item, i) => (

                      <Grid item xs={6} sm={6} md={6} lg={6}>

                        <Card sx={{ borderRadius: 2 }}>
                          <DisplayPDFFile
                            url={`${config.baseURL}${item.url}`}
                            key={i}
                            ext={`${item.ext}`}
                            name={`${item.name}`}
                          />
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Hidden>

              {/* <DisplayPDF dekstop /> */}
              <Hidden smDown>
                {file.length === 0 ? (
                  <></>
                ) : (
                  <Grid style={{ marginTop: "5px" }} container direction={'row'} spacing={2}>
                    {file.map((item, i) => (

                      <Grid item xs={6} sm={6} md={6} lg={6}>

                        <Card sx={{ borderRadius: 2, position: 'relative' }}>
                          <div style={{ position: 'absolute', right: '0%', display: 'flex', }}>
                            <IconButton onClick={() => {
                              deleteFileonArrayDrawer(i)

                            }} alt='' style={{ marginRight: '8px', cursor: 'pointer', background: 'transparent' }} aria-label="settings " sx={{ background: 'rgba(0, 0, 0, 0.1)', }}>
                              <img style={{ height: '24px', width: '24px' }} src={delete_doc} alt='' />
                            </IconButton>
                          </div>
                          <DisplayPDFFile
                            url={`${config.baseURL}${item.url}`}
                            key={i}
                            ext={`${item.ext}`}
                            name={`${item.name}`}
                          />
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Hidden>
              {file.length === 0 &&

                <DropZoneUnggah handleUpload={handleCloseModalUpload} />
              }
              <Hidden lgDown><ModalUnggahDoc open={isOpenUploadDoc} close={handleCloseModalUpload}></ModalUnggahDoc>
              </Hidden>

              <Hidden lgUp><DrawerUploadDoc open={isOpenUploadDoc} close={handleCloseModalUpload}></DrawerUploadDoc>
              </Hidden>
              <p className='c-08 s-1419 w-500 mb4 mt-24'>{translate('detail-explore.deskripsi')}</p>
              <TextField className='box-text-area' multiline rows={3} value={description} onChange={(e) => setDesc(e.target.value)}

                sx={{
                  width: '100%', input: {
                    fontSize: '14px', fontFamily: 'Satoshi-500'
                  },
                }} placeholder={translate('jual-beli.placeholder-deskripsi')}
              />

              {companyList.length > 0 && <p className='c-56 s-1419 w-500 mb4 mt-24'>{translate('jual-beli.sublabel-checkbox')}</p>}
              {companyList.length > 0 ?
                <Grid container direction='column' className='selected-company'>
                  {
                    companyList.map((item, i) => (
                      <Grid key={i} alignItems='center' justifyContent='space-between' style={{ width: "100%", display: "flex", flexDirection: 'row' }} className='box'>
                        <div className='flex alignC'>
                          {item.url_image_company === '' || item.url_image_company === null ? <Avatar sx={{ bgcolor: 'blue', mr: 2 }} aria-label="recipe">PT</Avatar> :
                            <img src={config.baseURL + item.url_image_company} alt='' className='ic-company' />}
                          <p className='w-700 s-1824 c-08'>{item.name} </p>
                        </div>
                        <Radio sx={{
                          color: "#E8EBF2",
                          '&.Mui-checked': {
                            color: "#3BB56C",
                          },
                        }} value={item.id} checked={item.id == id_company_listing ? true : false} name="radio-buttons" onChange={(e) => setCompany(e.target.value)} inputProps={{ 'aria-label': 'A' }} />
                      </Grid>))}


                </Grid> : <Typography className="w-500" sx={{ fontSize: 16, mt: '8px', }}>{translate('status.belumtergabungperusahaan')}</Typography>}
            </Grid>
            <Grid item xs={3} className='wd-252'>
            </Grid>
          </Grid>

          <Grid container className='line mt-24 mb-56'>

          </Grid>
          <Hidden smDown>
            <Grid className='mb-104 in-container' container justifyContent='space-between' direction='row'>
              <Button

                onClick={() => {
                  handleOpenBatalForm();
                }}
                size="large"
                className="w-500"
                variant="text"
                style={{
                  marginBottom: '16px',
                  color: ' #2457B9',
                  textTransform: 'none'

                }}
              >
                {translate('jual-beli.btn-cancel')}
              </Button>

              <Stack direction={'row'}>
                <Button

                  onClick={(e) => { submitDraft(3); setPosition('center') }}
                  size="large"
                  className="w-500"
                  variant="outlined"
                  style={{
                    marginRight: '16px',
                    marginBottom: '16px',
                    borderColor: ' #2457B9',
                    textTransform: 'none'

                  }}
                >
                  {translate('jual-beli.simpandraft')}
                </Button>
                <Button onClick={(e) => { submit(1) }}
                  size="large"
                  className="w-500"
                  variant="contained"
                  style={{
                    marginBottom: '16px',
                    background: ' #2457B9',
                    textTransform: 'none'

                  }}
                >
                  Publish
                </Button>

              </Stack>

            </Grid>
          </Hidden>
          <Hidden smUp>
            <Grid spacing={1} container justifyContent='space-between' direction='row' style={{ marginBottom: '16px' }}>
              <Grid item xs={6}>
                <Button onClick={() => {
                  handleOpenBatalForm();
                }}
                  fullWidth
                  size="large"
                  className="w-500"
                  variant="outlined"
                  style={{
                    color: '#2457B9',
                    textTransform: 'none'

                  }}
                >
                  {translate('jual-beli.btn-cancel')}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  onClick={(e) => {
                    handleOpenModal()
                    // handleStep("step2")
                  }}
                  size="large"
                  className="w-500"
                  variant="contained"
                  style={{
                    background: ' #2457B9',
                    textTransform: 'none'

                  }}
                >
                  {translate('jual-beli.simpan')}
                </Button>
              </Grid>


            </Grid>
            <DrawerpublishListing SubmitResponsivePublish={SubmitResponsivePublish} SubmitResponsiveDraft={SubmitResponsiveDraft} open={isOpenModal} close={handleCloseModal}></DrawerpublishListing>
            <DrawerHapusDoc title={'jual-beli.dokumen-nikel'} file_doc={file} submit={(e, y) => { handleChangeFileFromDeleteResponsive(e, y) }} open={isOpenDrawerDoc} close={handleCloseDrawerDoc} ></DrawerHapusDoc>
          </Hidden>
        </Box>
      </Box>
      <Footer />
      <ModalBatalForm open={openModalBatalForm} close={handleCloseModal}></ModalBatalForm>
      <SnackBarDraft position={position} open={isSuccessDraft} close={handleCloseModal}></SnackBarDraft>
    </div>
  )
}
