import { Box } from "@mui/material";
import NavBar from "../components/Navbar";

export default function PrivacyPolicy() {

    return (
        <div className="homepage">
            <div className="container-nav relative width-100">
                <div className='fixed-header'>
                    <NavBar />
                </div>
            </div>
            <Box className="flex-wrap-center width-100">
                <Box className="w-500 content-inner" sx={{ mt: { xs: 6, sm: 6, md: 11, lg: 11, xl: 11 }, ml: { xs: '24px', lg: '80px', }, mr: { xs: '24px', lg: '80px', }, mb: 4 }}>

                    <h2 style={{ marginBottom: '12px', marginTop: "30px" }}><strong>  Kebijakan Privasi Pribadi Dan Data</strong></h2>

                    <p style={{ marginBottom: '12px' }}>Indomine ("Perusahaan", "Indomine",  atau "Kami") mempertimbangkan privasi dan perlindungan data pribadi klien adalah prioritas kami.</p>

                    <p style={{ marginBottom: '24px' }}>Untuk memastikan bahwa Anda merasa yakin tentang penyediaan informasi oleh kami terkait dengan informasi pribadi Anda dalam kaitan : berkomunikasi dengan, selama menggunakan layanan dan praktik, terkait dengan pengumpulan dan penggunaan data pribadi adalah diuraikan sebagaimana di bawah ini.</p>

                    <h3 style={{ marginBottom: '12px' }}>Tujuan</h3>
                    <p style={{ marginBottom: '12px' }}>Kami akan mengumpulkan, merekam, menyimpan, dan menggunakan data pribadi Anda, hanya untuk memungkinkan Anda untuk mengakses informasi terkait kegiatan membeli, sewa, menyewa, atau menjual properti atau hasil tambang melalui afiliasi dari Indomine.</p>

                    <p style={{ marginBottom: '12px' }}>Jika Anda mendaftar atau masuk menggunakan akun Google Anda: kami menerima alamat email yang terhubung ke akun Google Anda (untuk membuat akun Indomine Anda dan untuk mengirimi Anda pemberitahuan sistem dan pesan pemasaran), ID Google Anda dan nama lengkap Anda (digunakan sebagai nama pengguna Anda saat Anda menggunakan Layanan kami) dan URL ke gambar profil Anda (untuk membuat gambar profil akun Indomine Anda);</p>
                    <p style={{ marginBottom: '12px' }}>
                        Jika Anda mendaftar atau masuk menggunakan akun Facebook Anda: kami menerima alamat email yang terhubung ke akun Facebook Anda (untuk membuat akun Indomine Anda dan mengirimi Anda pemberitahuan sistem dan pesan pemasaran) dan informasi profil publik Anda termasuk ID Facebook, nama depan, dan nama belakang Anda (digunakan sebagai nama pengguna Anda saat Anda menggunakan Layanan kami) dan URL ke gambar profil Facebook Anda (untuk membuat gambar profil akun Indomine Anda);
                    </p>
                    <p style={{ marginBottom: '12px' }}>
                        Kecuali Anda keberatan, silahkan untuk memberitahukan kepada kami mengenai keberatan terkait.
                    </p>
                    <h3 style={{ marginBottom: '12px' }}>Privasi</h3>
                    <p style={{ marginBottom: '12px' }}>
                        Kami tidak akan membagi data pribadi Anda kepada siapa pun di luar pihak terkait Indomine tanpa persetujuan Anda. Dari waktu ke waktu, dimungkinkan apabila kami mengungkapkan data pribadi anda kepada rekanan bagian penjualan dan / atau petugas, atau afiliasi yang ditunjuk baik secara lokal, nasional, internasional oleh Indomine maupun kepada mitra yang ditunjuk untuk memungkinkan komunikasi dan memaksimalkan layanan.
                    </p>
                    <p style={{ marginBottom: '12px' }}>
                        Pihak ketiga diharuskan menjaga informasi rahasia dan tidak akan menggunakan informasi tersebut untuk tujuan lain selain untuk melaksanakan pelayanan terkait Indomine.
                    </p>

                    <h3 style={{ marginBottom: '12px' }}>
                        Keamanan
                    </h3>
                    <p style={{ marginBottom: '12px' }}>
                        Setiap data pribadi yang Anda berikan kepada kami dijamin keamanannya dalam database yang dimiliki oleh Indomine. Akses hanya dimiliki pihak berwenang dari karyawan dan agen terkait. Kami telah memberlakukan langkah-langkah administratif untuk memastikan bahwa akses karyawan Indomine dan agen pada data pribadi dan server kami hanya terbatas sejauh yang diperlukan untuk menjalankan fungsi kerjanya secara fungsional dan struktural.
                    </p>
                    <p style={{ marginBottom: '12px' }}>
                        Semua karyawan kami diminta untuk menandatangani perjanjian kerahasiaan di mana mereka setuju untuk memperlakukan data pribadi sebagai rahasia. Server yang menyimpan data pribadi Anda dilindungi baik dari akses fisik dan elektronik yang tidak sah. Langkah-langkah ini mencakup firewall elektronik dan berbagai tindakan perlindungan lainnya yang melibatkan pemindaian virus, instalasi patch keamanan, pengujian kerentanan, backup dan perencanaan pemulihan, pelatihan karyawan, audit keamanan, dan langkah-langkah lain yang dirancang untuk terus meningkatkan postur perlindungan data. Server tidak secara langsung terhubung ke Internet, dan karena data yang tersedia hanya untuk komputer yang berwenang.
                    </p>
                    <p style={{ marginBottom: '24px' }}>
                        Sementara kami berusaha untuk menyediakan keamanan yang sesuai dan melebihi standar industri, karena  sifat kerentanan yang ada pada Internet, kami tidak dapat memastikan atau menjamin keamanan menyeluruh dari semua informasi yang dikirim kepada kami.
                    </p>

                    <h3 >
                        Indomine dan Internet
                    </h3>
                    <p style={{ marginBottom: '24px' }}>Web Site</p>
                    <p style={{ marginBottom: '24px' }}>
                        Secara umum, Anda dapat menelusuri situs internet kami secara anonim namun terbatas. Beberapa layanan online kami memerlukan pengumpulan beberapa informasi identitas pribadi, seperti nama, alamat, dan bila perlu, informasi lainnya terkait kredibilitas Anda. Informasi ini akan digunakan untuk memproses aktivitas layanan Indomine, tetapi juga dapat digunakan untuk pengolahan data internal, seperti kompilasi informasi statistik tentang minat Anda dan penggunaan situs internet kami. Sebagai layanan kepada Anda, kami juga dapat menggunakan informasi ini untuk menyesuaikan konten yang kami tawarkan.
                    </p>


                    <h3 style={{ marginBottom: '12px' }}>Email</h3>
                    <p style={{ marginBottom: '24px' }}>
                        Dalam rangka untuk menyediakan Anda dengan pemberitahuan terkini mengenai aktivitas baru yang berkatian dengan Layanan kami, Indomine dapat mengirimkan email kepada Anda. Untuk memfasilitasi layanan ini, kami dapat merekam halaman tertentu yang Anda kunjungi di situs web kami. Setiap saat, Anda dapat menghentikan layanan ini melalui opsi pengaturan atau menghubungi kami di <a href="mailto:admin@indomine.com"> admin@indomine.com.</a>
                    </p>

                    <h3 style={{ marginBottom: '12px' }}>
                        Cookies
                    </h3>
                    <p style={{ marginBottom: '12px' }}>
                        Indomine juga dapat menggunakan teknologi Internetnya bersifat personalisasi yang disebut "cookie”. "Cookie” adalah file teks kecil yang ditempatkan pada hard disk komputer Anda yang mengidentifikasi software browser Anda ke situs Web. Cookies memungkinkan kami untuk mengidentifikasi ketika Anda mengunjungi, menelusuri, menutup halaman, dan masuk kembali ke situs kami beserta segala aktivitas yang anda lakukan pada kunjungan ke situs kami.
                    </p>
                    <p style={{ marginBottom: '24px' }}>
                        Jika Anda menonaktifkan atau menolak cookie, harap perhatikan bahwa beberapa bagian dari Layanan kami mungkin menjadi tidak dapat diakses atau tidak berfungsi dengan baik. Untuk informasi lebih lanjut tentang cookie yang kami gunakan, silakan lihat Kebijakan kami tentang Cookies dan Teknologi Sejenisnya.
                    </p>


                    <h3 style={{ marginBottom: '12px' }}>
                        Mencari, jual/beli, sewa/menyewa properti atau hasil tambang
                    </h3>
                    <p style={{ marginBottom: '12px' }}>
                        Indomine memberikan kesempatan untuk pemasang iklan untuk menyediakan informasi tentang info kontak terkait di layanan iklan yang dipasang untuk dilihat dan dihubungi, hanya kepada pengguna kami yang terafiliasi dan terdaftar.
                    </p>
                    <p style={{ marginBottom: '24px' }}>
                        Jika Anda tidak ingin dihubungi lebih lanjut dari pengirim, silakan balas ke pengirim dan meminta agar mereka berhenti mengirimkan korespondensi di masa depan.
                    </p>

                    <h3 style={{ marginBottom: '12px' }}>
                        Tautan ke situs web pihak ketiga
                    </h3>
                    <p style={{ marginBottom: '12px' }}>
                        Layanan kami mungkin berisi tautan ke situs web atau aplikasi pihak ketiga. Jika Anda mengklik salah satu tautan ini, harap diperhatikan bahwa masing-masing akan memiliki kebijakan privasinya sendiri. Kami tidak mengontrol situs web / aplikasi ini dan tidak bertanggung jawab atas kebijakan tersebut. Ketika Anda meninggalkan Layanan kami, kami mendorong Anda untuk membaca pemberitahuan privasi dari setiap situs web yang Anda kunjungi.
                    </p>
                    <p style={{ marginBottom: '24px' }}>
                        Harap dicatat, meskipun kami telah melakukan langkah-langkah yang kami terapkan untuk melindungi data Anda, transfer data melalui Internet atau jaringan terbuka lainnya tidak pernah sepenuhnya aman dan ada risiko bahwa data pribadi Anda dapat diakses oleh pihak ketiga yang tidak sah.
                    </p>

                    <h3 style={{ marginBottom: '12px' }}>
                        Pengecualian
                    </h3>
                    <p style={{ marginBottom: '24px' }}>
                        Meskipun kami tidak akan secara sukarela mengungkapkan data pribadi Anda. Kami hanya akan menggunakan data pribadi Anda saat hukum mengizinkan dan mengharuskan kami. Kami dapat mengungkapkan informasi tersebut jika atas perintah pengadilan, penyelidikan kepolisian, maupun atas perintah pemerintah atau aparat penegak hukum, sesuai proses hukum berlaku lainnya yang dibuktikan secara tertulis maupun jika dianggap perlu untuk melindungi hak atau kepemilikan Indomine.
                    </p>

                    <h3 style={{ marginBottom: '12px' }}>
                        Komunikasi Lanjutan
                    </h3>
                    <p style={{ marginBottom: '24px' }}>
                        Jika Anda memiliki pertanyaan sehubungan dengan penggunaan dan perlindungan data pribadi Anda, silakan hubungi kami di <a href="mailto:admin@indomine.com"> admin@indomine.com.</a>
                    </p>

                    <h3 style={{ marginBottom: '12px' }}>
                        Prosedur Penghapusan Data
                    </h3>
                    <p style={{ marginBottom: '24px' }}>
                        Anda dapat mengajukan permintaan penghapusan data dengan menghubungi tim dukungan kami melalui metode yang telah disediakan. Setelah permintaan diterima, kami akan memverifikasi identitas Anda untuk mencegah akses yang tidak sah. Setelah verifikasi selesai, data pribadi Anda akan dihapus dari sistem kami secara permanen dalam jangka waktu yang wajar.
                    </p>

                    <h3 style={{ marginBottom: '12px' }}>
                        Penghapusan Akun
                    </h3>
                    <p style={{ marginBottom: '24px' }}>
                        Anda memiliki opsi untuk menghapus akun dari platform kami kapan saja. Permintaan penghapusan akun dapat diajukan dengan menghubungi tim dukungan kami. Setelah akun dihapus, data pribadi Anda akan dihapus dari sistem kami, kecuali data yang harus tetap disimpan sesuai dengan hukum atau kebijakan bisnis kami.
                    </p>

                    <h3 style={{ marginBottom: '12px' }}>
                        Keamanan Anda Apakah Penting untuk Kami
                    </h3>
                    <p style={{ marginBottom: '12px' }}>
                        Scam- atau "phishing" – adalah tindakan menggunakan email resmi yang tampak menipu konsumen agar mengungkapkan informasi pribadi mereka. Email sering mengarahkan penerima ke "mirip" situs Web dari sebuah bisnis yang sah, menipu konsumen ke dalam pemikiran mereka menanggapi permintaan yang valid.
                    </p> <p style={{ marginBottom: '12px' }}>
                        Untuk menghindari terjebak dalam penipuan ini, ingatlah untuk :
                    </p>
                    <p style={{ marginBottom: '12px' }}>
                        Menjaga rahasia password Anda dan mengubahnya secara berkala.
                    </p>
                    <p style={{ marginBottom: '12px' }}>
                        Jangan pernah memberikan informasi akun penting Anda melalui email atau telepon, kecuali Anda memulai kontak.
                    </p>  <p style={{ marginBottom: '24px' }}>
                        Selalu mengakhiri sesi aman di Internet benar-benar dengan mengklik link "Log Out" dan dengan menutup jendela browser Anda sebelum Anda meninggalkan komputer.
                    </p>
                    <p style={{ marginBottom: '24px' }}>
                        Kami dapat mengubah pernyataan privasi ini dari waktu ke waktu. Kami akan memposting perubahan di halaman ini dan akan memberitahu Anda melalui e-mail atau melalui Platform kami. Jika Anda tidak setuju dengan perubahan, Anda dapat menutup akun Anda dengan pergi ke pengaturan akun dan pilih hapus akun.
                    </p>
                </Box>
            </Box>
        </div>
    )
}
