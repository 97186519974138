import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Modal, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from "react-i18next";
import { FacebookMessengerShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import copyIc from "../assets/copy-ic.png";
import fbIcon from "../assets/fb-circle-grey-ic.png";
import shareImg from "../assets/share-hero-img.svg";
import telegramIcon from "../assets/telegram-circle-grey-ic.png";
import twitterIcon from "../assets/twitter-circle-grey-ic.png";
import waIcon from "../assets/wa-circle-grey-ic.png";
import { config } from "../services/constants";
import { capitalizeEachWord, convertText } from '../services/primary';

export default function ModalShareListingCompany(props) {
    const [translate,] = useTranslation("global");



    return (
        <div>
            <Modal
                sx={{ '& .MuiBackdrop-root': { backgroundColor: 'rgba(0, 0, 0, 0.2)' } }}
                open={props.open}
                onClose={props.close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    zIndex: 9999999,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '304px', sm: 343, md: 343, lg: 415, xl: 415 },
                    borderRadius: '8px',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    "&:focus": {
                        outline: "none"
                    },
                    p: { xs: '16px', sm: '24px', md: '24px', lg: '24px', xl: '24px' },
                }}>
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <IconButton onClick={() => {
                            props.close();
                        }} size="small" >
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div style={{ justifyContent: 'center', display: 'flex' }}>
                        <img alt="hubungitrader-icon" style={{ width: '140px', height: '140px', }} src={shareImg}></img>
                    </div>
                    <Typography id="modal-modal-title" className='w-700' sx={{ fontSize: '20px', color: '#081130', textAlign: 'center', mt: '24px' }}>
                        {translate('status.sharelisting')}
                    </Typography>
                    <Stack sx={{ mt: '24px' }} direction={'row'} justifyContent={'space-evenly'} alignItems={'center'}>
                        <TwitterShareButton onClick={() => {
                            props.close();
                        }} url={`${config.staging}/detail/${capitalizeEachWord(convertText(props.data?.name))}`}><img alt="twitter-icon" src={twitterIcon} height={'40px'} width={'40px'} /></TwitterShareButton>
                        <WhatsappShareButton onClick={() => {
                            props.close();
                        }} url={`${config.staging}/detail/${capitalizeEachWord(convertText(props.data?.name))}`} ><img alt="wa-icon" src={waIcon} height={'40px'} width={'40px'} /> </WhatsappShareButton>
                        <TelegramShareButton onClick={() => {
                            props.close();
                        }} url={`${config.staging}/detail/${capitalizeEachWord(convertText(props.data?.name))}`}> <img alt="tele-icon" src={telegramIcon} height={'40px'} width={'40px'} /></TelegramShareButton>
                        <FacebookMessengerShareButton onClick={() => {
                            props.close();
                        }} url={`${config.staging}/detail/${capitalizeEachWord(convertText(props.data?.name))}`}><img alt="fb-icon" src={fbIcon} height={'40px'} width={'40px'} /></FacebookMessengerShareButton>
                    </Stack>
                    <Box sx={{ background: '#F4F6FA', border: '1px solid #E8EBF2', borderRadius: '8px', pt: '16px', pl: '16px', pr: '16px', pb: '16px', mt: '24px' }}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography className="w-700" sx={{ color: '#56617B', fontSize: 14, }}>
                                {(`${config.staging}/${convertText(props.data?.name)}`).slice(0, 34) + '...'}
                            </Typography>

                            <img alt="copy-icon" src={copyIc} height={20} onClick={(e) => {
                                props.close();
                                navigator.clipboard.writeText(`${config.staging}/${config.staging}/detail/${capitalizeEachWord(convertText(props.data?.name))}`)
                            }}></img>
                        </Stack>
                    </Box>

                </Box>
            </Modal>
        </div>
    );
}