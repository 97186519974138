import Divider from "@material-ui/core/Divider";
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Button, Drawer, FormControl, Grid, Hidden, InputAdornment, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import * as React from 'react';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-multi-carousel/lib/styles.css";
import { useLocation } from 'react-router-dom';
import filtericon from "../assets/filter-ic.png";
import searchIcon from "../assets/search-explore-ic.png";
import CardBuyCoal from "../components/CardBuyCoal";
import CardBuyNikel from "../components/CardBuyNikel";
import CardBuyTongkang from "../components/CardBuyTongkang";
import CardRentTongkang from "../components/CardRentTongkang";
import DrawerFilterCoal from "../components/DrawerFilterCoal";
import DrawerFilterLocation from "../components/DrawerFilterLocation";
import DrawerFilterNikel from "../components/DrawerFilterNikel";
import Footer from "../components/Footer";
import MenuFilterCoal from "../components/MenuFilterCoal";
import MenuFilterNikel from "../components/MenuFilterNikel";
import NavBar from "../components/Navbar";
import SearchBarExplore from "../components/SearchbarExplore";
import { buyBarge, getBenderaFlag, getCoalRequest, getNickelRequest, getPort, getProvinceFilter, rentBarge } from "../services/api.js";
import { GlobalState } from "../services/global";
import { VerticalDividerLeft } from '../services/primary';

function PermintaanPage() {
  const location = useLocation();
  const { currency } = React.useContext(GlobalState)
  const [buyBargeList, setListBuyBarge] = useState([]);
  const [rentBargeList, setListRentBarge] = useState([]);
  const [coalList, setCoalList] = useState([]);
  const [portList, setPortList] = useState([]);
  const [nickelList, setNickelList] = useState([]);
  const [dataProvince, setDataProvince] = useState([]);
  const [limitProvince, setLimitProvince] = useState(5)
  const [activeTab, setActiveTab] = useState("coal");
  const [totalBatubara, setTotalBatubara] = useState('')
  const [totalNikel, setTotalNikel] = useState('')
  const [totalBuyTongkang, setTotalBuyTongkang] = useState('')
  const [totalSewaTongkang, setTotaSewaTongkang] = useState('')
  const [filterBatubara, setFilterBatubara] = useState({
    page: 1,
    min_calorie: '', max_calorie: '', min_total_sulfur: '', max_total_sulfur: '', min_ash: '',
    max_ash: '', quantity: '', min_price: '', max_price: '', id_location: "", search_text: "",
    request_start_laycan: location.state?.request_data?.start_laycan_date === null ? '' : location.state?.request_data?.start_laycan_date,
    request_end_laycan: location.state?.request_data?.end_laycan_date === null ? '' : location.state?.request_data?.end_laycan_date,
  });
  const [filterNikel, setFilterNikel] = useState({
    page: 1,
    min_kadar_ni: '', max_kadar_ni: '', min_kadar_air: '', max_kadar_air: '', min_kadar_fe: '', max_kadar_fe: '',
    min_si_mg_ratio: '', max_si_mg_ratio: '', quantity: '', min_price: '', max_price: '', id_location: "", search_text: "",
    request_start_laycan: location.state?.request_data?.start_laycan_date === null ? '' : location.state?.request_data?.start_laycan_date,
    request_end_laycan: location.state?.request_data?.end_laycan_date === null ? '' : location.state?.request_data?.end_laycan_date,
  })
  const [filterBeliTongkang, setFilterBeliTongkang] = useState({
    page: 1,
    type_tongkang: "", ukuran_tongkang: '', max_price: '', min_price: '',
    tahun_buat: "", search_text: "", pelabuhan_muat: ""
  })
  const [filterSewaTongkang, setFilterSewaTongkang] = useState({
    page: 1,
    tipe_sewa: "", ukuran_tongkang: '', max_price: '', min_price: '',
    search_text: "", pelabuhan_muat: "", pelabuhan_bongkar: ""
  })

  const year = (new Date()).getFullYear();
  const list_years = Array.from(new Array(20), (val, index) => year - index);
  const newCoalList = coalList.data ? coalList.data.data : ""
  const newNickelList = nickelList.data ? nickelList.data.data : ""
  const newBuyBarge = buyBargeList ? buyBargeList : ""
  const newRentBargeList = rentBargeList ? rentBargeList : ""
  const [translate,] = useTranslation("global");
  const [modalCoalFilter, setmodalCoalFilter] = React.useState(null)
  const [modalCoalLocation, setmodalCoalLocation] = React.useState(null)
  const [modalNikelFilter, setmodalNikelFilter] = React.useState(null)
  const [modalNikelLocation, setmodalNikelLocation] = React.useState(null)
  const [modalTongkangFilter, setmodalTongkangFilter] = React.useState(null)
  const [modalRentTongkangFilter, setmodalRentTongkangFilter] = React.useState(null)
  const [showAllCoal, setShowAllCoal] = useState(false)
  const [showAllNikel, setShowAllNikel] = useState(false)
  const [benderaList, setBenderaList] = useState([])
  const [benderaName, setBenderaName] = useState('');
  const [selectedItems, setSelectedItems] = useState([])
  const [inputukurangtongkang, setinputukurantongkang] = React.useState(false)
  const [inputukuransewatongkang, setinputukuransewatongkang] = React.useState(false)
  const [locationId, setLocationId] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [dataProvinceNikel, setDataProvinceNikel] = useState([]);
  const [searchValueLocation, setSearchValueLocation] = useState('')

  useEffect(() => {
    getCoalList(filterBatubara);
    getNickelList(filterNikel);
    getListBuyBarge(filterBeliTongkang);
    getListRentBarge(filterSewaTongkang);

    getAllPort()
    getAllFlag()
    getDataProvinceCoal(searchValueLocation)
    getDataProvinceNikel(searchValueLocation)
  }, []);

  //  Functions to handle Tab Switching
  const handleTab = (stateTab) => {
    // update the state to Tab
    setActiveTab(stateTab);
    setLimitProvince(5)
    setLocationId([])
    setSelectedItems([])
    filterBatubara["id_location"] = []
    setFilterBatubara({ ...filterBatubara })
    getCoalList(filterBatubara)
    filterNikel["id_location"] = []
    setFilterNikel({ ...filterNikel })
    getNickelList(filterNikel)
    setSearchValueLocation('')
    if (stateTab === "coal") {
      getDataProvinceCoal('');
    } else if (stateTab === "nikel") {
      getDataProvinceNikel('')
    }
  };

  const handleSearchValueLocation = (e) => {
    if (e === '') {
      getDataProvinceCoal('');
      getDataProvinceNikel('')
      setSearchValueLocation('')
    } else {
      setSearchValueLocation(e)
    }
  };

  const handleKeyDownLocation = (event, e) => {
    if (event.key === 'Enter') {
      event.target.blur();
      // 👇 Get input value
      if (e === "coal") {
        getDataProvinceCoal(searchValueLocation);
      } else if (e === "nikel") {
        getDataProvinceNikel(searchValueLocation)
      }
    }
  };

  const handleLocationSumbit = (e) => {
    if (e === "coal") {
      getCoalList(filterBatubara)
      setLimitProvince(5)
      setShowAllCoal(false)
    } else if (e === "nikel") {
      getNickelList(filterNikel)
      setLimitProvince(5)
      setShowAllNikel(false)
    }

  }

  const handleLocationReset = (e) => {

    if (e === "coal") {
      setSearchValueLocation('')
      setSelectedItems([])
      filterBatubara["id_location"] = []
      setFilterBatubara({ ...filterBatubara })
      getCoalList(filterBatubara)
      getDataProvinceCoal('')

    } else if (e === "nikel") {

      setSearchValueLocation('')
      setSelectedItems([])
      filterNikel["id_location"] = []
      setFilterNikel({ ...filterNikel })
      getNickelList(filterNikel)
      getDataProvinceNikel('')
    }

  }

  const handleSearchValue = (e) => {
    if (e === '') {
      filterBatubara["page"] = 1
      filterNikel["page"] = 1
      filterBeliTongkang["page"] = 1
      filterSewaTongkang["page"] = 1
      getCoalList(filterBatubara, '');
      getNickelList(filterNikel, '');
      getListBuyBarge(filterBeliTongkang, '');
      getListRentBarge(filterSewaTongkang, '')
      setSearchValue('')
    } else {
      setSearchValue(e)
    }
  };

  const handleFilterBatubara = (e, y, option) => {
    if (y === "location") {
      let isSelected = e.target.checked;
      let value = parseInt(e.target.value);
      if (isSelected) {
        setSelectedItems([...selectedItems, value])
      } else {
        setSelectedItems((prevData) => {
          return prevData.filter((id) => {
            return id !== value
          })
        })
      }

      const check = [...locationId]
      const index = check.indexOf(e.target.value);
      if (index === -1) {
        check.push(e.target.value)
      } else {
        check.splice(index, 1)
      }
      setLocationId(check)
      filterBatubara["id_location"] = check
      setFilterBatubara({ ...filterBatubara })
      getCoalList(filterBatubara)
    } else if (y === "popuplocation") {
      let isSelected = e.target.checked;
      let value = parseInt(e.target.value);
      if (isSelected) {
        setSelectedItems([...selectedItems, value])
      } else {
        setSelectedItems((prevData) => {
          return prevData.filter((id) => {
            return id !== value
          })
        })
      }

      const check = [...locationId]
      const index = check.indexOf(e.target.value);
      if (index === -1) {
        check.push(e.target.value)
      } else {
        check.splice(index, 1)
      }
      setLocationId(check)
      filterBatubara["id_location"] = check
      setFilterBatubara({ ...filterBatubara })
    } else if (y === 'mobile') {
      filterBatubara[option] = e
      setFilterBatubara({ ...filterBatubara })

    } else {
      filterBatubara[y] = e
      setFilterBatubara({ ...filterBatubara })
      getCoalList(filterBatubara)
    }

  }

  const handleFilterNikel = (e, y, option) => {
    if (y === "location") {
      let isSelected = e.target.checked;
      let value = parseInt(e.target.value);
      if (isSelected) {
        setSelectedItems([...selectedItems, value])
      } else {
        setSelectedItems((prevData) => {
          return prevData.filter((id) => {
            return id !== value
          })
        })
      }
      const check = [...locationId]
      const index = check.indexOf(e.target.value);
      if (index === -1) {
        check.push(e.target.value)
      } else {
        check.splice(index, 1)
      }
      setLocationId(check)
      filterNikel["id_location"] = check
      setFilterNikel({ ...filterNikel })
      getNickelList(filterNikel)
    } else if (y === "popuplocation") {
      let isSelected = e.target.checked;
      let value = parseInt(e.target.value);
      if (isSelected) {
        setSelectedItems([...selectedItems, value])
      } else {
        setSelectedItems((prevData) => {
          return prevData.filter((id) => {
            return id !== value
          })
        })
      }

      const check = [...locationId]
      const index = check.indexOf(e.target.value);
      if (index === -1) {
        check.push(e.target.value)
      } else {
        check.splice(index, 1)
      }
      setLocationId(check)
      filterNikel["id_location"] = check
      setFilterNikel({ ...filterNikel })
    } else if (y === 'mobile') {
      filterNikel[option] = e
      setFilterNikel({ ...filterNikel })

    } else {
      filterNikel[y] = e
      setFilterNikel({ ...filterNikel })
      getNickelList(filterNikel)
    }

  }

  const handleFilterBeliTongkang = (e, y, option) => {
    if (e !== 0) {
      setinputukurantongkang(false)
      if (option === 'mobile') {
        filterBeliTongkang[y] = e
        setFilterBeliTongkang({ ...filterBeliTongkang })

      } else {
        filterBeliTongkang[y] = e
        setFilterBeliTongkang({ ...filterBeliTongkang })
        getListBuyBarge(filterBeliTongkang)
      }
    }
    else {
      setinputukurantongkang(true)
    }

  }

  const handleFilterSewaTongkang = (e, y, option) => {
    if (e !== 0) {
      setinputukuransewatongkang(false)
      if (option === 'mobile') {
        filterSewaTongkang[y] = e
        setFilterSewaTongkang({ ...filterSewaTongkang })
      } else {
        filterSewaTongkang[y] = e
        setFilterSewaTongkang({ ...filterSewaTongkang })
        if (y !== "tipe_sewa") {
          getListRentBarge(filterSewaTongkang)
        }
      }
    }
    else {
      setinputukuransewatongkang(true)
    }

  }

  async function getListBuyBarge(event, searchValueQ) {
    try {
      setIsLoading(true)
      if (searchValueQ !== undefined) {
        event["search_text"] = searchValueQ
      } else {
        event["search_text"] = searchValue
      }
      const response = await buyBarge(event)
      setListBuyBarge(response.data?.data.data.data);
      setTotalBuyTongkang(response.data?.data.totalItemTongkangPembelian);
      setIsLoading(false)
    } catch (e) {
      console.log(e);
    }
  }

  async function getListRentBarge(event, searchValueQ) {
    try {
      setIsLoading(true)
      if (searchValueQ !== undefined) {
        event["search_text"] = searchValueQ
      } else {
        event["search_text"] = searchValue
      }
      const response = await rentBarge(event)
      setListRentBarge(response.data?.data.data.data);
      setTotaSewaTongkang(response.data?.data.totalItemTongkangPenyewaan)
      setIsLoading(false)
    } catch (e) {
      console.log(e);
    }
  }

  async function getDataProvinceCoal(name) {
    try {
      const response = await getProvinceFilter(name === undefined ? '' : name, 'order_batubara:asc')
      setDataProvince(response.data?.data);
    } catch (e) {
      console.log(e);
    }
  }

  async function getDataProvinceNikel(name) {
    try {
      const response = await getProvinceFilter(name === undefined ? '' : name, 'order_nikel:asc')
      setDataProvinceNikel(response.data?.data);
    } catch (e) {
      console.log(e);
    }
  }

  async function getAllPort() {
    try {
      const response = await getPort()
      setPortList(response.data?.data);
    } catch (e) {
      console.log(e);
    }
  }

  async function getCoalList(event, searchValueQ) {
    try {
      setIsLoading(true)
      if (searchValueQ !== undefined) {
        event["search_text"] = searchValueQ
      } else {
        event["search_text"] = searchValue
      }
      const response = await getCoalRequest(event)
      setCoalList(response.data?.data);
      setTotalBatubara(response.data?.data?.totalItemBatubara)
      setIsLoading(false)
    } catch (e) {
      console.log(e);
    }
  }


  async function getNickelList(event, searchValueQ) {
    try {
      setIsLoading(true)
      if (searchValueQ !== undefined) {
        event["search_text"] = searchValueQ
      } else {
        event["search_text"] = searchValue
      }
      const response = await getNickelRequest(event)
      setNickelList(response.data?.data);
      setTotalNikel(response.data?.data?.totalItemNikel)
      setIsLoading(false)
    } catch (e) {
      console.log(e);
    }
  }


  // Reset Filter 
  function resetFilterBatubara(e) {
    const default_value = {
      page: 1,
      min_calorie: '', max_calorie: '', min_total_sulfur: '', max_total_sulfur: '', min_ash: '',
      max_ash: '', quantity: '', min_price: '', max_price: '', id_location: "", search_text: ""
    }
    setSearchValueLocation('')
    setSelectedItems([])
    setLocationId([])
    setFilterBatubara(default_value)
    if (e !== 'mobile') {
      getCoalList(default_value, '')
    }
  }

  function resetFilterNikel(e) {
    const default_value = {
      page: 1,
      min_kadar_ni: '', max_kadar_ni: '', min_kadar_air: '', max_kadar_air: '', min_kadar_fe: '', max_kadar_fe: '',
      min_si_mg_ratio: '', max_si_mg_ratio: '', quantity: '', min_price: '', max_price: '', id_location: "", search_text: ""
    }
    setSearchValueLocation('')
    setSelectedItems([])
    setLocationId([])
    setSearchValueLocation('')
    setFilterNikel(default_value)
    if (e !== 'mobile') {
      getNickelList(default_value)
    }
  }

  function resetFilterBeliTongkang(e) {
    const default_value = {
      page: 1,
      type_tongkang: "", ukuran_tongkang: '', max_price: '', min_price: '',
      tahun_buat: "", search_text: "", pelabuhan_muat: ""
    }
    setSearchValueLocation('')
    setFilterBeliTongkang(default_value)
    if (e !== 'mobile') {
      getListBuyBarge(default_value)
    }
  }

  function resetFilterSewaTongkang(e) {
    const default_value = {
      page: 1,
      tipe_sewa: "", ukuran_tongkang: '', max_price: '', min_price: '',
      search_text: "", pelabuhan_muat: "", pelabuhan_bongkar: ""
    }
    setFilterSewaTongkang(default_value)
    if (e !== 'mobile') {
      getListRentBarge(default_value)
    }
  }

  async function setFavoriteBatubara() {
    setTimeout(() => {
      getCoalList(filterBatubara)
    }, 150);
  }

  async function setFavoriteNikel() {
    setTimeout(() => {
      getNickelList(filterNikel)
    }, 150);
  }

  async function setFavoriteTongkang() {
    setTimeout(() => {
      getListRentBarge(filterSewaTongkang)
      getListBuyBarge(filterBeliTongkang)
    }, 150);
  }

  async function getAllFlag() {
    try {
      const response = await getBenderaFlag({ page: 1 })
      setBenderaList(response.data?.data);
    } catch (e) {
      console.log(e);
    }
  }

  const handleOpenModalFilter = (state) => {
    if (state === 'coal') {
      setmodalCoalFilter(state);
    } else if (state === 'nikel') {
      setmodalNikelFilter(state)
    } else if (state === 'tongkang') {
      setmodalTongkangFilter(state)
    } else if (state === 'renttongkang') {
      setmodalRentTongkangFilter(state)
    }

  };
  const handleCloseModalFilter = () => {
    setmodalCoalFilter(null);
    setmodalNikelFilter(null);
    setmodalTongkangFilter(null);
    setmodalRentTongkangFilter(null);
  };

  const handleOpenModalLocationResponsive = (state) => {
    if (state === 'coal') {
      setmodalCoalLocation(state);
    } else if (state === 'nikel') {
      setmodalNikelLocation(state)
    }

  };

  const handleFilterUkuranTongkang = (e, y, option) => {

    if (option === 'mobile') {
      filterBeliTongkang[y] = e
      setFilterBeliTongkang({ ...filterBeliTongkang })
    } else {
      filterBeliTongkang[y] = e
      setFilterBeliTongkang({ ...filterBeliTongkang })
      getListBuyBarge(filterBeliTongkang)
    }
  }

  const handleCloseModalLocationResponsive = () => {
    setmodalCoalLocation(null);
    setmodalNikelLocation(null);
  };

  const handleKeyDown = (event, value) => {
    if (event.key === 'Enter') {
      filterBatubara["page"] = 1
      filterNikel["page"] = 1
      filterBeliTongkang["page"] = 1
      filterSewaTongkang["page"] = 1
      setSearchValue(value)
      event.target.blur();
      // 👇 Get input value
      getCoalList(filterBatubara, value);
      getNickelList(filterNikel, value);
      getListBuyBarge(filterBeliTongkang, value)
      getListRentBarge(filterSewaTongkang, value)
    }
  };

  function Filter(e) {
    if (e === 'coal') {
      setFilterBatubara({ ...filterBatubara })
      getCoalList(filterBatubara)
    } else if (e === 'nikel') {
      setFilterNikel({ ...filterNikel })
      getNickelList(filterNikel)
    } else if (e === 'belitongkang') {
      setFilterBeliTongkang({ ...filterBeliTongkang })
      getListBuyBarge(filterBeliTongkang)
    } else if (e === 'sewatongkang') {
      setFilterSewaTongkang({ ...filterSewaTongkang })
      getListRentBarge(filterSewaTongkang)
    }


  }

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'auto'
      /* you can also use 'auto' behaviour 
         in place of 'smooth' */
    });
  }

  return (
    <div>
      <div className='fixed-header'>
        <NavBar></NavBar></div>

      <Box className="flex-wrap-center width-100" >
        <Box className="content-inner" sx={{ mt: { xs: 6, sm: 6, md: 11, lg: 11, xl: 11 }, ml: { xs: '24px', lg: '80px', }, mr: { xs: '24px', lg: '80px', }, }}>
          <Grid
            sx={{ mt: '16px', }}
            container
            direction="row"
            alignItems={'center'}
          >
            <Button href="/" className="w-500" sx={{ fontSize: 16, mb: 1, color: '#2457B9', textTransform: 'none', pl: 0 }} variant="text">  {translate('page_explore.home')}</Button>

            <Typography className="w-500" sx={{ fontSize: 16, mb: 1, mr: 1, color: '#56617B' }}>
              /
            </Typography>
            <Typography className="w-500" sx={{ fontSize: 16, mb: 1, color: '#56617B' }}>
              {translate('navbar.permintaan')}
            </Typography>
          </Grid>

          <Grid
            container
            direction="row"
            spacing={4}
            sx={{ mb: 2, }}
          >
            {/* leftside Section */}
            <Grid item lg={3} xl={3}>
              <Typography className="w-700" sx={{ fontSize: 32, mb: { xs: 0, lg: '16px', sm: 0, md: '16px', xl: '16px' } }}>
                {translate('navbar.permintaan')}
              </Typography>
              {/* filter Coal Section */}
              {activeTab === 'coal' ? <Hidden lgDown>
                <MenuFilterCoal page={'default'} handleLocationReset={handleLocationReset} resetFilterBatubara={resetFilterBatubara} handleFilterBatubara={handleFilterBatubara} filterBatubara={filterBatubara} dataProvince={dataProvince} selectedItems={selectedItems} limitProvince={limitProvince} setLimitProvince={setLimitProvince} setShowAllCoal={setShowAllCoal} handleSearchValueLocation={handleSearchValueLocation} handleKeyDownLocation={handleKeyDownLocation} handleLocationSumbit={handleLocationSumbit} showAllCoal={showAllCoal} searchValueLocation={searchValueLocation} >

                </MenuFilterCoal>
              </Hidden> : null}
              {/* filter Nikel Section */}
              {activeTab === 'nikel' ? <Hidden lgDown>
                <MenuFilterNikel page={'default'} handleLocationReset={handleLocationReset} resetFilterNikel={resetFilterNikel} handleFilterNikel={handleFilterNikel} filterNikel={filterNikel} dataProvince={dataProvinceNikel} selectedItems={selectedItems} limitProvince={limitProvince} setLimitProvince={setLimitProvince} setShowAllNikel={setShowAllNikel} handleSearchValueLocation={handleSearchValueLocation} handleKeyDownLocation={handleKeyDownLocation} handleLocationSumbit={handleLocationSumbit} showAllNikel={showAllNikel} searchValueLocation={searchValueLocation}></MenuFilterNikel>
              </Hidden> : null}
              {/* filter Tongkang Section */}
              {activeTab === 'tongkang' ? <Hidden lgDown>
                <Box sx={{ border: 1, color: '#E8EBF2', borderRadius: 2, }}>
                  <Grid
                    container
                    alignItems={'center'}
                    direction="row"
                    justifyContent={'space-between'}
                    sx={{ pl: '16px', pt: '19px', pr: 2, pb: '19px' }}
                  >
                    <Typography className="w-700" sx={{ fontSize: 18, color: '#081130' }}>
                      Filter
                    </Typography>
                    <Button className="w-500" onClick={(e) => resetFilterBeliTongkang(e)} sx={{ fontSize: 16, color: '#56617B', textTransform: 'none' }} variant="text"> Reset filter</Button>

                  </Grid>

                  <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
                    <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
                      {translate('page_explore.ukurantongkang')}
                    </Typography>
                    <FormControl sx={{ width: '100%' }}>
                      <Select
                        style={{ border: '1px solid var(--grey-stroke, #e8ebf2)', marginTop: '4px', borderRadius: '8px', }}
                        IconComponent={() => (
                          <KeyboardArrowDownIcon sx={{ mr: '16px', width: '20px', height: '20px', fill: '#56617B' }} />
                        )}
                        onChange={(e) => handleFilterBeliTongkang(e.target.value, "ukuran_tongkang")}
                        value={filterBeliTongkang["ukuran_tongkang"]}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem value="">
                          <Typography className="w-400" style={{ color: '#8792AB', fontSize: 14 }}>  {translate('page_explore.pilihukurantongkang')}</Typography>

                        </MenuItem>

                        <MenuItem value={180}>180 feet</MenuItem>
                        <MenuItem value={200}>200 feet</MenuItem>
                        <MenuItem value={230}>230 feet</MenuItem>
                        <MenuItem value={250}>250 feet</MenuItem>
                        <MenuItem value={300}>300 feet</MenuItem>
                        <MenuItem value={310}>310 feet</MenuItem>
                        <MenuItem value={320}>320 feet</MenuItem>
                        <MenuItem value={330}>330 feet</MenuItem>
                        <MenuItem value={0}>{translate('status.lainnya')}</MenuItem>
                      </Select>
                      {inputukurangtongkang === true ? <TextField
                        className="box-input-filter"
                        type="number"
                        size="small"
                        placeholder={translate('status.placeholderukurantongkang')}
                        onChange={(e) => handleFilterUkuranTongkang(e.target.value, "ukuran_tongkang")}
                        value={filterBeliTongkang["ukuran_tongkang"]}
                        sx={{
                          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                            display: "none",
                          },
                          "& input[type=number]": {
                            MozAppearance: "textfield",
                          },
                          width: '100%', input: {
                            fontSize: '14px', fontFamily: 'Satoshi-500'
                          },
                        }}

                      /> : ''}

                    </FormControl>

                  </Box>

                  {/* <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
                    <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
                      {translate('page_explore.quantitymuatan')}
                    </Typography>
                    <Grid
                      container
                      direction="row"
                      justifyContent={'space-between'}
                      spacing={2}
                    >
                      <Grid item xs={6}>
                        <TextField
                          className="box-input-filter"
                          type="number"
                          size="small"
                          placeholder="Min"
                          onChange={(e) => handleFilterBeliTongkang(e.target.value, "min_quantity_muatan")}
                          value={filterBeliTongkang["min_quantity_muatan"]}
                          sx={{
                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                              display: "none",
                            },
                            "& input[type=number]": {
                              MozAppearance: "textfield",
                            },
                            width: '100%', input: {
                              fontSize: '14px', fontFamily: 'Satoshi-500'
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          className="box-input-filter"
                          type="number"
                          size="small"
                          placeholder="Maks"
                          onChange={(e) => handleFilterBeliTongkang(e.target.value, "max_quantity_muatan")}
                          value={filterBeliTongkang["max_quantity_muatan"]}
                          sx={{
                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                              display: "none",
                            },
                            "& input[type=number]": {
                              MozAppearance: "textfield",
                            },
                            width: '100%', input: {
                              fontSize: '14px', fontFamily: 'Satoshi-500'
                            },
                          }}

                        />
                      </Grid>
                    </Grid>
                  </Box> */}

                  <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
                    <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
                      {translate('page_explore.hargatongkang')}
                    </Typography>
                    <TextField
                      className="box-input-filter"
                      type="number"
                      size="small"
                      placeholder={translate('status.min-harga')}
                      onChange={(e) => handleFilterBeliTongkang(e.target.value, "min_price")}
                      value={filterBeliTongkang["min_price"]}
                      sx={{
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                          display: "none",
                        },
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                        width: '100%', input: {
                          fontSize: '14px', fontFamily: 'Satoshi-500'
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <VerticalDividerLeft sx={{ ml: '12px' }} />
                            {currency === 'idr' && <Typography className="w-700" sx={{ fontSize: 14, color: '#56617B', mr: '24px' }}>
                              Rp
                            </Typography>}
                            {currency === 'usd' && <Typography className="w-700" sx={{ fontSize: 16, color: '#56617B', mr: '24px' }}>
                              $
                            </Typography>}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      className="box-input-filter"
                      type="number"
                      size="small"
                      placeholder={translate('status.max-harga')}
                      onChange={(e) => handleFilterBeliTongkang(e.target.value, "max_price")}
                      value={filterBeliTongkang["max_price"]}
                      sx={{
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                          display: "none",
                        },
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                        width: '100%', input: {
                          fontSize: '14px', fontFamily: 'Satoshi-500'
                        }, mt: 1
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <VerticalDividerLeft sx={{ ml: '12px' }} />
                            {currency === 'idr' && <Typography className="w-700" sx={{ fontSize: 14, color: '#56617B', mr: '24px' }}>
                              Rp
                            </Typography>}
                            {currency === 'usd' && <Typography className="w-700" sx={{ fontSize: 16, color: '#56617B', mr: '24px' }}>
                              $
                            </Typography>}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>

                  {/* <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
                    <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
                      {translate('permintaan.pelabuhanmuatan')}
                    </Typography>
                    <FormControl sx={{ width: '100%' }}>
                      <Select
                        IconComponent={() => (
                          <KeyboardArrowDownIcon sx={{ mr: '16px', width: '20px', height: '20px', fill: '#56617B' }} />
                        )}

                        style={{ border: '1px solid var(--grey-stroke, #e8ebf2)', marginTop: '4px', borderRadius: '8px', }}
                        placeholder="Pilih pelabuhan muat"
                        onChange={(e) => handleFilterBeliTongkang(e.target.value, "pelabuhan_muat")}
                        value={filterBeliTongkang["pelabuhan_muat"]}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              height: '352px',

                              '& .MuiMenuItem-root': {
                                padding: '8px',
                              },
                            },
                          },
                        }}
                      >
                        <MenuItem disabled value="" >
                          <Typography className="w-400" style={{ color: '#8792AB', fontSize: 14 }}>  {translate('permintaan.placeholder-pelabuhanmuatan')} </Typography>

                        </MenuItem>
                        {portList.map((item, i) => (
                          <MenuItem key={i} value={item.id}>{item.attributes.name} </MenuItem>
                        ))}

                      </Select>

                    </FormControl>

                  </Box> */}
                  {/* <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
                    <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
                      {translate('permintaan.bendera')}
                    </Typography>

                    <FormNegara select={(e) => { handleFilterBeliTongkang(e.id, "bendera") }} selectName={(e) => setBenderaName(e)} currentValue={filterBeliTongkang.bendera} />

                  </Box> */}
                  <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
                    <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
                      {translate('permintaan.tahunbuat')}
                    </Typography>
                    <FormControl sx={{ width: '100%' }}>
                      <Select
                        IconComponent={() => (
                          <KeyboardArrowDownIcon sx={{ mr: '16px', width: '20px', height: '20px', fill: '#56617B' }} />
                        )}

                        style={{ border: '1px solid var(--grey-stroke, #e8ebf2)', marginTop: '4px', borderRadius: '8px', }}
                        onChange={(e) => handleFilterBeliTongkang(e.target.value, "tahun_buat")}
                        value={filterBeliTongkang["tahun_buat"]}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              height: '352px',

                              '& .MuiMenuItem-root': {
                                padding: '8px',
                              },
                            },
                          },
                        }}
                      >
                        <MenuItem value="" disabled>
                          <Typography className="w-400" style={{ color: '#8792AB', fontSize: 14 }}> {translate('permintaan.placeholder-tahunbuat')}</Typography>

                        </MenuItem>
                        {list_years.map((item, i) => (
                          <MenuItem key={i} value={item}>{item} </MenuItem>))}
                      </Select>

                    </FormControl>

                  </Box>


                </Box>
              </Hidden> : null}
              {/* filter Rent Tongkang Section */}
              {activeTab === 'renttongkang' ? <Hidden lgDown>
                <Box sx={{ border: 1, color: '#E8EBF2', borderRadius: 2, }}>
                  <Grid
                    container
                    direction="row"
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    sx={{ pl: '16px', pt: '19px', pr: 2, pb: '19px' }}
                  >
                    <Typography className="w-700" sx={{ fontSize: 18, color: '#081130' }}>
                      Filter
                    </Typography>
                    <Button className="w-500" onClick={(e) => resetFilterSewaTongkang(e)} sx={{ fontSize: 16, color: '#56617B', textTransform: 'none' }} variant="text"> Reset filter</Button>

                  </Grid>

                  <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
                    <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
                      {translate('permintaan.tipe-sewa')}
                    </Typography>
                    <Grid
                      container
                      direction="column"
                      justifyContent={'space-between'}
                      sx={{ mt: 1, pl: 2, pb: 2 }}
                      spacing={2}
                    >

                      <Button className="w-500" onClick={(e) => {
                        handleFilterSewaTongkang("time", "tipe_sewa")
                        handleFilterSewaTongkang("2", "type_tongkang")
                      }}
                        value={filterSewaTongkang["tipe_sewa"]} sx={{
                          "&:hover": {
                            borderColor: filterSewaTongkang["tipe_sewa"] === 'time' ? '#3BB56C' : '#E8EBF2',
                          }, textTransform: 'none', justifyContent: 'left', borderColor: filterSewaTongkang["tipe_sewa"] === 'time' ? '#3BB56C' : '#E8EBF2', color: filterSewaTongkang["tipe_sewa"] === 'time' ? '#3BB56C' : 'black', mb: 1, borderRadius: '8px', p: '14px 12px'
                        }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: filterSewaTongkang["tipe_sewa"] === 'time' ? '#3BB56C' : '#E8EBF2', }} />}>
                        Time charter
                      </Button>
                      <Button className="w-500" onClick={(e) => {
                        handleFilterSewaTongkang("freight", "tipe_sewa")
                        handleFilterSewaTongkang("3", "type_tongkang")
                      }}
                        value={filterSewaTongkang["tipe_sewa"]} sx={{
                          "&:hover": {
                            borderColor: filterSewaTongkang["tipe_sewa"] === 'freight' ? '#3BB56C' : '#E8EBF2',
                          }, textTransform: 'none', justifyContent: 'left', borderColor: filterSewaTongkang["tipe_sewa"] === 'freight' ? '#3BB56C' : '#E8EBF2', color: filterSewaTongkang["tipe_sewa"] === 'freight' ? '#3BB56C' : 'black', mb: 1, borderRadius: '8px', p: '14px 12px'
                        }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: filterSewaTongkang["tipe_sewa"] === 'freight' ? '#3BB56C' : '#E8EBF2', }} />}>
                        Freight charter
                      </Button>

                    </Grid>

                  </Box>
                  <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
                    <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
                      {translate('page_explore.ukurantongkang')}
                    </Typography>
                    <FormControl sx={{ width: '100%' }}>
                      <Select
                        IconComponent={() => (
                          <KeyboardArrowDownIcon sx={{ mr: '16px', width: '20px', height: '20px', fill: '#56617B' }} />
                        )}

                        style={{ border: '1px solid var(--grey-stroke, #e8ebf2)', marginTop: '4px', borderRadius: '8px', }}
                        onChange={(e) => handleFilterSewaTongkang(e.target.value, "ukuran_tongkang")}
                        value={filterSewaTongkang["ukuran_tongkang"]}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem value="">
                          <Typography className="w-400" style={{ color: '#8792AB', fontSize: 14 }}> {translate('page_explore.pilihukurantongkang')}</Typography>
                        </MenuItem>
                        <MenuItem value={180}>180 feet</MenuItem>
                        <MenuItem value={200}>200 feet</MenuItem>
                        <MenuItem value={230}>230 feet</MenuItem>
                        <MenuItem value={250}>250 feet</MenuItem>
                        <MenuItem value={300}>300 feet</MenuItem>
                        <MenuItem value={310}>310 feet</MenuItem>
                        <MenuItem value={320}>320 feet</MenuItem>
                        <MenuItem value={330}>330 feet</MenuItem>
                        <MenuItem value={0}>{translate('status.lainnya')}</MenuItem>
                      </Select>
                      {inputukuransewatongkang === true ? <TextField
                        className="box-input-filter"
                        type="number"
                        size="small"
                        placeholder={translate('status.placeholderukurantongkang')}
                        onChange={(e) => handleFilterUkuranTongkang(e.target.value, "ukuran_tongkang")}
                        value={filterSewaTongkang["ukuran_tongkang"]}
                        sx={{
                          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                            display: "none",
                          },
                          "& input[type=number]": {
                            MozAppearance: "textfield",
                          },
                          width: '100%', input: {
                            fontSize: '14px', fontFamily: 'Satoshi-500'
                          },
                        }}

                      /> : ''}
                    </FormControl>
                  </Box>

                  {/* <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
                    <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
                      {translate('page_explore.quantitymuatan')}
                    </Typography>
                    <Grid
                      container
                      direction="row"
                      justifyContent={'space-between'}
                      spacing={2}
                    >
                      <Grid item xs={6}>

                        <TextField
                          className="box-input-filter"
                          type="number"
                          size="small"
                          placeholder="Min"
                          onChange={(e) => handleFilterSewaTongkang(e.target.value, "min_quantity_muatan")}
                          value={filterSewaTongkang["min_quantity_muatan"]}
                          sx={{
                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                              display: "none",
                            },
                            "& input[type=number]": {
                              MozAppearance: "textfield",
                            },
                            width: '100%', input: {
                              fontSize: '14px', fontFamily: 'Satoshi-500'
                            },
                          }}

                        />

                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          className="box-input-filter"
                          type="number"
                          size="small"
                          placeholder="Maks"
                          onChange={(e) => handleFilterSewaTongkang(e.target.value, "max_quantity_muatan")}
                          value={filterSewaTongkang["max_quantity_muatan"]}
                          sx={{
                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                              display: "none",
                            },
                            "& input[type=number]": {
                              MozAppearance: "textfield",
                            },
                            width: '100%', input: {
                              fontSize: '14px', fontFamily: 'Satoshi-500'
                            },
                          }}

                        />
                      </Grid>
                    </Grid>
                  </Box> */}

                  <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
                    <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
                      {translate('page_explore.hargatongkang')}
                    </Typography>

                    <TextField
                      className="box-input-filter"
                      type="number"
                      size="small"
                      placeholder={translate('status.min-harga')}
                      onChange={(e) => handleFilterSewaTongkang(e.target.value, "min_price")}
                      value={filterSewaTongkang["min_price"]}
                      sx={{
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                          display: "none",
                        },
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                        width: '100%', input: {
                          fontSize: '14px', fontFamily: 'Satoshi-500'
                        },
                      }}
                      InputProps={{

                        startAdornment: (
                          <InputAdornment position="start">
                            <VerticalDividerLeft sx={{ ml: '12px' }} />
                            {currency === 'idr' && <Typography className="w-700" sx={{ fontSize: 14, color: '#56617B', mr: '24px' }}>
                              Rp
                            </Typography>}
                            {currency === 'usd' && <Typography className="w-700" sx={{ fontSize: 16, color: '#56617B', mr: '24px' }}>
                              $
                            </Typography>}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      className="box-input-filter"
                      type="number"
                      size="small"
                      placeholder={translate('status.max-harga')}
                      onChange={(e) => handleFilterSewaTongkang(e.target.value, "max_price")}
                      value={filterSewaTongkang["max_price"]}
                      sx={{
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                          display: "none",
                        },
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                        width: '100%', input: {
                          fontSize: '14px', fontFamily: 'Satoshi-500'
                        }, mt: 1
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <VerticalDividerLeft sx={{ ml: '12px' }} />
                            {currency === 'idr' && <Typography className="w-700" sx={{ fontSize: 14, color: '#56617B', mr: '24px' }}>
                              Rp
                            </Typography>}
                            {currency === 'usd' && <Typography className="w-700" sx={{ fontSize: 16, color: '#56617B', mr: '24px' }}>
                              $
                            </Typography>}
                          </InputAdornment>
                        ),
                      }}
                    />

                  </Box>

                  {/* <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
                    <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
                      {translate('permintaan.pelabuhanmuatan')}
                    </Typography>
                    <FormControl sx={{ width: '100%' }}>
                      <Select
                        IconComponent={() => (
                          <KeyboardArrowDownIcon sx={{ mr: '16px', width: '20px', height: '20px', fill: '#56617B' }} />
                        )}

                        style={{ border: '1px solid var(--grey-stroke, #e8ebf2)', marginTop: '4px', borderRadius: '8px', }}
                        onChange={(e) => handleFilterSewaTongkang(e.target.value, "pelabuhan_muat")}
                        value={filterSewaTongkang["pelabuhan_muat"]}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem value="" disabled>
                          <Typography className="w-400" style={{ color: '#8792AB', fontSize: 14 }}> {translate('permintaan.placeholder-pelabuhanmuatan')}</Typography>

                        </MenuItem>
                        {portList.map((item, i) => (
                          <MenuItem key={i} value={item.id}>{item.attributes.name} </MenuItem>
                        ))}

                      </Select>

                    </FormControl>

                  </Box>
                  <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
                    <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
                      {translate('permintaan.pelabuhanbongkar')}
                    </Typography>
                    <FormControl sx={{ width: '100%' }}>
                      <Select
                        IconComponent={() => (
                          <KeyboardArrowDownIcon sx={{ mr: '16px', width: '20px', height: '20px', fill: '#56617B' }} />
                        )}

                        style={{ border: '1px solid var(--grey-stroke, #e8ebf2)', marginTop: '4px', borderRadius: '8px', }}
                        onChange={(e) => handleFilterSewaTongkang(e.target.value, "pelabuhan_bongkar")}
                        value={filterSewaTongkang["pelabuhan_bongkar"]}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem value="" disabled>
                          <Typography className="w-400" style={{ color: '#8792AB', fontSize: 14 }}>   {translate('permintaan.placeholder-pelabuhanbongkar')}</Typography>

                        </MenuItem>
                        {portList.map((item, i) => (
                          <MenuItem key={i} value={item.id}>{item.attributes.name} </MenuItem>
                        ))}
                      </Select>

                    </FormControl>

                  </Box> */}
                  {/* <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
                    <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
                      {translate('permintaan.bendera')}
                    </Typography>

                    <FormNegara select={(e) => { handleFilterSewaTongkang(e.id, "bendera") }} selectName={(e) => setBenderaName(e)} currentValue={filterSewaTongkang.bendera} />
                  </Box> */}



                </Box>
              </Hidden> : null}

            </Grid>
            {/* rightside Section */}
            <Grid item xs={12} lg={9} xl={9} >
              {/* Tab Button Section */}
              <Box style={{ marginBottom: '22px' }} className="tab-scroll-x">
                <Stack direction="row" alignItems={'center'}>
                  <Button
                    className="w-500"
                    sx={{
                      height: '40px',
                      p: '8px 12px',
                      lineHeight: '22px',
                      width: { xs: '180px', md: 'fit-content' },
                      borderRadius: '8px',
                      background: activeTab === 'coal' ? "#081130" : '', textTransform: 'none',
                      color: activeTab === 'coal' ? "white" : '#081130', fontSize: 16, "&:hover": {
                        backgroundColor: activeTab === 'coal' ? "#081130" : "#56617B"
                      }
                    }}
                    onClick={() => {
                      handleTab("coal");
                    }}
                    variant={activeTab === 'coal' ? "contained" : 'text'}
                  >
                    {translate('permintaan.btn-beli-batubara')}
                    <Box
                      sx={{
                        background: activeTab === 'coal' ? "white" : '#D1D7E6',
                        borderRadius: '6px',
                        p: '4px',
                        ml: '8px',
                      }}
                    >

                      <Typography
                        className="w-500"
                        sx={{ color: activeTab === 'coal' ? "#081130" : '#56617B', fontSize: 12, lineHeight: 'normal' }}
                      >
                        {totalBatubara}
                      </Typography>
                    </Box>

                  </Button>
                  <Divider orientation="vertical" style={{ width: '1px', height: '16px', marginLeft: '12px', marginRight: '12px' }} />
                  <Button
                    className='w-500'
                    onClick={() => {
                      handleTab("nikel");
                    }}
                    sx={{
                      height: '40px',
                      p: '8px 12px',
                      borderRadius: '8px',
                      lineHeight: '22px',
                      width: { xs: '180px', md: 'fit-content' },
                      background: activeTab === 'nikel' ? "#081130" : '', textTransform: 'none',
                      color: activeTab === 'nikel' ? "white" : '#081130', fontSize: 16, "&:hover": {
                        backgroundColor: activeTab === 'nikel' ? "#081130" : "#56617B"
                      }
                    }}
                    variant={activeTab === 'nikel' ? "contained" : 'text'}
                  >
                    {translate('permintaan.btn-beli-nikel')}
                    <Box
                      sx={{
                        background: activeTab === 'nikel' ? "white" : '#D1D7E6',
                        borderRadius: '6px',
                        p: '4px',
                        ml: '8px',
                      }}
                    >
                      <Typography
                        className="w-500"
                        sx={{ color: activeTab === 'nikel' ? "#081130" : '#56617B', fontSize: 12, lineHeight: 'normal' }}
                      >
                        {totalNikel}
                      </Typography>
                    </Box>
                  </Button>
                  <Divider orientation="vertical" style={{ width: '1px', height: '16px', marginLeft: '12px', marginRight: '12px' }} />
                  <Button
                    className='w-500'
                    onClick={() => {
                      handleTab("tongkang");
                    }}
                    sx={{
                      height: '40px',
                      p: '8px 12px',
                      borderRadius: '8px',
                      lineHeight: '22px',
                      width: { xs: '180px', md: 'fit-content' },
                      background: activeTab === 'tongkang' ? "#081130" : '', textTransform: 'none',
                      color: activeTab === 'tongkang' ? "white" : '#081130', fontSize: 16, "&:hover": {
                        backgroundColor: activeTab === 'tongkang' ? "#081130" : "#56617B"
                      }
                    }}
                    variant={activeTab === 'tongkang' ? "contained" : 'text'}
                  >
                    {translate('permintaan.btn-beli-tongkang')}
                    <Box
                      sx={{
                        background: activeTab === 'tongkang' ? "white" : '#D1D7E6',
                        borderRadius: '6px',
                        p: '4px',
                        ml: '8px',
                      }}
                    >
                      <Typography
                        className="w-500"
                        sx={{ color: activeTab === 'tongkang' ? "#081130" : '#56617B', fontSize: 12, lineHeight: 'normal' }}
                      >
                        {totalBuyTongkang}
                      </Typography>
                    </Box>
                  </Button>
                  <Divider orientation="vertical" style={{ width: '1px', height: '16px', marginLeft: '12px', marginRight: '12px' }} />
                  <Button
                    className='w-500'
                    onClick={() => {
                      handleTab("renttongkang");
                    }}
                    sx={{
                      height: '40px',
                      p: '8px 12px',
                      borderRadius: '8px',
                      lineHeight: '22px',
                      width: { xs: '180px', md: 'fit-content' },
                      background: activeTab === 'renttongkang' ? "#081130" : '', textTransform: 'none',
                      color: activeTab === 'renttongkang' ? "white" : '#081130', fontSize: 16, fontWeight: activeTab === 'renttongkang' ? 700 : 500, "&:hover": {
                        backgroundColor: activeTab === 'renttongkang' ? "#081130" : "#56617B"
                      }
                    }}
                    variant={activeTab === 'renttongkang' ? "contained" : 'text'}
                  >
                    {translate('permintaan.btn-sewa-tongkang')}
                    <Box
                      sx={{
                        background: activeTab === 'renttongkang' ? "white" : '#D1D7E6',
                        borderRadius: '6px',
                        p: '4px',
                        ml: '8px',
                      }}
                    >
                      <Typography
                        className="w-500"
                        sx={{ color: activeTab === 'renttongkang' ? "#081130" : '#56617B', fontSize: 12, lineHeight: 'normal' }}
                      >
                        {totalSewaTongkang}
                      </Typography>
                    </Box>
                  </Button>


                </Stack>
              </Box>
              {/* Search Section */}
              <Box className="test-search" sx={{ width: { lg: '955px', xs: '100%' } }}>
                <Stack direction="row" sx={{ mb: 2, pt: 0 }} justifyContent={'space-between'} alignItems='center' >
                  {activeTab === 'coal' ? <Grid item className="searchtrader-explore-page" xs>
                    <SearchBarExplore text={translate('page_explore.search-batubara')} searchValue={searchValue} handleSearchValue={handleSearchValue} handleKeyDown={handleKeyDown} searchIcon={searchIcon} />

                  </Grid> : null}

                  {activeTab === 'nikel' ? <Grid item className="searchtrader-explore-page" xs>
                    <SearchBarExplore text={translate('page_explore.search-nikel')} searchValue={searchValue} handleSearchValue={handleSearchValue} handleKeyDown={handleKeyDown} searchIcon={searchIcon} />

                  </Grid> : null}

                  {activeTab === 'tongkang' ? <Grid item className="searchtrader-explore-page" xs>
                    <SearchBarExplore text={translate('page_explore.search-tongkang')} searchValue={searchValue} handleSearchValue={handleSearchValue} handleKeyDown={handleKeyDown} searchIcon={searchIcon} />

                  </Grid> : null}

                  {activeTab === 'renttongkang' ? <Grid item className="searchtrader-explore-page" xs>
                    <SearchBarExplore text={translate('page_explore.search-tongkang')} searchValue={searchValue} handleSearchValue={handleSearchValue} handleKeyDown={handleKeyDown} searchIcon={searchIcon} />

                  </Grid> : null}


                  {activeTab === 'coal' ? <Hidden lgUp>
                    <Grid item >
                      <img onClick={() => {
                        handleOpenModalFilter("coal");
                      }} alt="filter-icon" src={filtericon} height={42} width={42}></img>
                    </Grid>
                  </Hidden> : null}
                  {activeTab === 'nikel' ? <Hidden lgUp>
                    <Grid item >
                      <img onClick={() => {
                        handleOpenModalFilter("nikel");
                      }} alt="filter-icon" src={filtericon} height={42} width={42}></img>
                    </Grid>
                  </Hidden> : null}
                  {activeTab === 'tongkang' ? <Hidden lgUp>
                    <Grid item >
                      <img onClick={() => {
                        handleOpenModalFilter("tongkang");
                      }} alt="filter-icon" src={filtericon} height={42} width={42}></img>
                    </Grid>
                  </Hidden> : null}
                  {activeTab === 'renttongkang' ? <Hidden lgUp>
                    <Grid item >
                      <img onClick={() => {
                        handleOpenModalFilter("renttongkang");
                      }} alt="filter-icon" src={filtericon} height={42} width={42}></img>
                    </Grid>
                  </Hidden> : null}
                </Stack>

                {activeTab === 'coal' ? <Box sx={{ mb: '12px' }}>
                  <div>
                    {(newCoalList && newCoalList.length > 0) ?
                      newCoalList.map((item) => (
                        <CardBuyCoal key={item.product_id} page={'permintaan'} getCoalList={() => setFavoriteBatubara()} coalList={item}></CardBuyCoal>))
                      :
                      <p className="w-500">  {translate('permintaan.datanotfound')}</p>
                    }
                  </div>
                </Box> : null}

                {activeTab === 'nikel' ? <Box sx={{ mb: 2 }}>
                  <div>
                    {(newNickelList && newNickelList.length > 0) ?
                      newNickelList.map((item) => (
                        <CardBuyNikel key={item.product_id} page={'permintaan'} NickelList={item} getNikel={(e) => setFavoriteNikel()}></CardBuyNikel>))
                      :
                      <p className="w-500">  {translate('permintaan.datanotfound')}</p>
                    }
                  </div>

                </Box> : null}
                {activeTab === 'tongkang' ? <Box sx={{ mb: 2 }}>
                  <div>
                    {(newBuyBarge && newBuyBarge.length > 0) ?
                      newBuyBarge.map((item) => (
                        <CardBuyTongkang key={item.product_id} page={'permintaan'} BuyBargeList={item} getTongkang={(e) => setFavoriteTongkang()}></CardBuyTongkang>))
                      :
                      <p className="w-500">  {translate('permintaan.datanotfound')}</p>
                    }
                  </div>

                </Box> : null}
                {activeTab === 'renttongkang' ? <Box sx={{ mb: 2 }}>
                  <div>
                    {(newRentBargeList && newRentBargeList.length > 0) ?
                      newRentBargeList.map((item) => (
                        <CardRentTongkang key={item.product_id} page={'permintaan'} RentBargeList={item} getTongkang={(e) => setFavoriteTongkang()}></CardRentTongkang>))

                      :
                      <p className="w-500">  {translate('permintaan.datanotfound')}</p>
                    }
                  </div>
                </Box> : null}



                {(activeTab === "coal" && coalList.data !== undefined && (parseInt(coalList.data.pageCount) !== parseInt(coalList.data.currentPage)) && (parseInt(coalList.data.pageCount) > parseInt(coalList.data.currentPage))) &&
                  <Grid sx={{ mt: 2, mb: 8 }} className="pointer" container justifyContent="center" alignItems="center" direction="row" onClick={(e) => {
                    handleFilterBatubara(filterBatubara["page"] + 1, "page")
                    // setTimeout(() => {
                    //   scrollToBottom()
                    // }, 1000)
                  }}>
                    <Typography className="w-500" sx={{ fontSize: 16, color: "#2457B9", mr: 1 }}>
                      {translate('page_explore.lihatlebihbanyak')}
                    </Typography>
                    <KeyboardArrowDownIcon sx={{ fontSize: 16, color: "#2457B9" }} />
                  </Grid>}

                {(activeTab === "nikel" && nickelList.data !== undefined && (parseInt(nickelList.data.pageCount) !== parseInt(nickelList.data.currentPage)) && (parseInt(nickelList.data.pageCount) > parseInt(nickelList.data.currentPage))) &&
                  <Grid sx={{ mt: 2, mb: 8 }} className="pointer" container justifyContent="center" alignItems="center" direction="row" onClick={(e) => {
                    handleFilterNikel(filterNikel["page"] + 1, "page")
                    // setTimeout(() => {
                    //   scrollToBottom()
                    // }, 1000)
                  }}>
                    <Typography className="w-500" sx={{ fontSize: 16, color: "#2457B9", mr: 1 }}>
                      {translate('page_explore.lihatlebihbanyak')}
                    </Typography>
                    <KeyboardArrowDownIcon sx={{ fontSize: 16, color: "#2457B9" }} />
                  </Grid>}

                {(activeTab === "tongkang" && newBuyBarge.data !== undefined && (parseInt(newBuyBarge.data.pageCount) !== parseInt(newBuyBarge.data.currentPage)) && (parseInt(newBuyBarge.data.pageCount) > parseInt(newBuyBarge.data.currentPage))) &&
                  <Grid sx={{ mt: 2, mb: 8, cursor: 'pointer' }} className="pointer" container justifyContent="center" alignItems="center" direction="row" onClick={(e) => {
                    handleFilterBeliTongkang(filterBeliTongkang["page"] + 1, "page")
                    // setTimeout(() => {
                    //   scrollToBottom()
                    // }, 1000)
                  }}>
                    <Typography className="w-500" sx={{ fontSize: 16, color: "#2457B9", mr: 1 }}>
                      {translate('page_explore.lihatlebihbanyak')}
                    </Typography>
                    <KeyboardArrowDownIcon sx={{ fontSize: 16, color: "#2457B9" }} />
                  </Grid>}
                {(activeTab === "renttongkang" && newRentBargeList.data !== undefined && (parseInt(newRentBargeList.data.pageCount) !== parseInt(newRentBargeList.data.currentPage)) && (parseInt(newRentBargeList.data.pageCount) > parseInt(newRentBargeList.data.currentPage))) &&
                  <Grid sx={{ mt: 2, mb: 8, cursor: 'pointer' }} className="pointer" container justifyContent="center" alignItems="center" direction="row" onClick={(e) => {
                    handleFilterSewaTongkang(filterSewaTongkang["page"] + 1, "page")
                    // setTimeout(() => {
                    //   scrollToBottom()
                    // }, 1000)
                  }}>
                    <Typography className="w-500" sx={{ fontSize: 16, color: "#2457B9", mr: 1 }}>
                      {translate('page_explore.lihatlebihbanyak')}
                    </Typography>
                    <KeyboardArrowDownIcon sx={{ fontSize: 16, color: "#2457B9" }} />
                  </Grid>}

              </Box>
            </Grid>

            {/* modal filter  Responsive Section */}
            <Hidden lgUp>
              {/* modal filter Coal Responsive Section */}
              {activeTab === 'coal' && <DrawerFilterCoal page={'default'} open={modalCoalFilter} close={handleCloseModalFilter} handleLocationReset={handleLocationReset} resetFilterBatubara={resetFilterBatubara} handleFilterBatubara={handleFilterBatubara} filterBatubara={filterBatubara} dataProvince={dataProvince} selectedItems={selectedItems} limitProvince={limitProvince} setLimitProvince={setLimitProvince} handleOpenModalLocationResponsive={handleOpenModalLocationResponsive} handleCloseModalFilter={handleCloseModalFilter} Filter={Filter}>
              </DrawerFilterCoal>}
              {/* modal filter Nikel Responsive Section */}
              {activeTab === 'nikel' && <DrawerFilterNikel page={'default'} open={modalNikelFilter} close={handleCloseModalFilter} handleLocationReset={handleLocationReset} resetFilterNikel={resetFilterNikel} handleFilterNikel={handleFilterNikel} filterNikel={filterNikel} dataProvince={dataProvinceNikel} selectedItems={selectedItems} limitProvince={limitProvince} setLimitProvince={setLimitProvince} handleOpenModalLocationResponsive={handleOpenModalLocationResponsive} handleCloseModalFilter={handleCloseModalFilter} Filter={Filter}>
              </DrawerFilterNikel>}

              {/* modal filter Beli Tongkang Responsive Section */}
              <Box sx={{ flexGrow: 1, display: { xs: "flex", sm: 'flex', md: "flex" }, }}>
                <Drawer
                  className="box-filter-drawer"
                  anchor="bottom"
                  open={Boolean(modalTongkangFilter)}
                  onClose={handleCloseModalFilter}
                >
                  <Box >
                    <Grid
                      container
                      alignItems={'center'}
                      direction="row"
                      justifyContent={'space-between'}
                      sx={{ pl: '16px', pt: '19px', pr: 2, pb: '19px' }}
                    >
                      <Typography className="w-700" sx={{ fontSize: 18, color: '#081130' }}>
                        Filter
                      </Typography>
                      <Stack direction={'row'} alignItems={'center'}>
                        <Button className="w-500" onClick={(e) => {
                          resetFilterBeliTongkang('mobile')
                        }} sx={{ fontSize: 16, color: '#2457B9', textTransform: 'none' }} variant="text"> Reset filter</Button>

                        <Divider orientation="vertical" style={{ marginTop: 10, marginBottom: 10, marginLeft: 10, marginRight: 10 }} flexItem />
                        <CloseIcon onClick={handleCloseModalFilter} />
                      </Stack>
                    </Grid>

                    <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
                      <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
                        {translate('page_explore.ukurantongkang')}
                      </Typography>
                      <FormControl sx={{ width: '100%' }}>
                        <Select
                          style={{ border: '1px solid var(--grey-stroke, #e8ebf2)', marginTop: '4px', borderRadius: '8px', }}
                          IconComponent={() => (
                            <KeyboardArrowDownIcon sx={{ mr: '16px', width: '20px', height: '20px', fill: '#56617B' }} />
                          )}
                          onChange={(e) => handleFilterBeliTongkang(e.target.value, "ukuran_tongkang", 'mobile')}
                          value={filterBeliTongkang["ukuran_tongkang"]}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          <MenuItem value="">
                            <Typography className="w-400" style={{ color: '#8792AB', fontSize: 14 }}>  {translate('page_explore.pilihukurantongkang')}</Typography>
                          </MenuItem>
                          <MenuItem value={180}>180 feet</MenuItem>
                          <MenuItem value={230}>230 feet</MenuItem>
                          <MenuItem value={300}>300 feet</MenuItem>
                          <MenuItem value={310}>310 feet</MenuItem>
                          <MenuItem value={320}>320 feet</MenuItem>
                          <MenuItem value={330}>330 feet</MenuItem>
                          <MenuItem value={0}>{translate('status.lainnya')}</MenuItem>
                        </Select>
                        {inputukurangtongkang === true ? <TextField
                          className="box-input-filter"
                          type="number"
                          size="small"
                          placeholder={translate('status.placeholderukurantongkang')}
                          onChange={(e) => handleFilterUkuranTongkang(e.target.value, "ukuran_tongkang", 'mobile')}
                          value={filterBeliTongkang["ukuran_tongkang"]}
                          sx={{
                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                              display: "none",
                            },
                            "& input[type=number]": {
                              MozAppearance: "textfield",
                            },
                            width: '100%', input: {
                              fontSize: '14px', fontFamily: 'Satoshi-500'
                            },
                          }}
                        /> : ''}
                      </FormControl>
                    </Box>

                    {/* <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
                      <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
                        {translate('page_explore.quantitymuatan')}
                      </Typography>
                      <Grid
                        container
                        direction="row"
                        justifyContent={'space-between'}
                        spacing={2}
                      >
                        <Grid item xs={6}>
                          <TextField
                            className="box-input-filter"
                            type="number"
                            size="small"
                            placeholder="Min"
                            onChange={(e) => handleFilterBeliTongkang(e.target.value, "min_quantity_muatan", 'mobile')}
                            value={filterBeliTongkang["min_quantity_muatan"]}
                            sx={{
                              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                display: "none",
                              },
                              "& input[type=number]": {
                                MozAppearance: "textfield",
                              },
                              width: '100%', input: {
                                fontSize: '14px', fontFamily: 'Satoshi-500'
                              },
                            }}

                          />

                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            className="box-input-filter"
                            type="number"
                            size="small"
                            placeholder="Maks"
                            onChange={(e) => handleFilterBeliTongkang(e.target.value, "max_quantity_muatan", 'mobile')}
                            value={filterBeliTongkang["max_quantity_muatan"]}
                            sx={{
                              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                display: "none",
                              },
                              "& input[type=number]": {
                                MozAppearance: "textfield",
                              },
                              width: '100%', input: {
                                fontSize: '14px', fontFamily: 'Satoshi-500'
                              },
                            }}

                          />
                        </Grid>


                      </Grid>


                    </Box> */}

                    <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
                      <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
                        {translate('page_explore.hargatongkang')}
                      </Typography>

                      <TextField
                        className="box-input-filter"
                        type="number"
                        size="small"
                        placeholder={translate('status.min-harga')}
                        onChange={(e) => handleFilterBeliTongkang(e.target.value, "min_price", 'mobile')}
                        value={filterBeliTongkang["min_price"]}
                        sx={{
                          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                            display: "none",
                          },
                          "& input[type=number]": {
                            MozAppearance: "textfield",
                          },
                          width: '100%', input: {
                            fontSize: '14px', fontFamily: 'Satoshi-500'
                          },
                        }}
                        InputProps={{

                          startAdornment: (
                            <InputAdornment position="start">
                              <VerticalDividerLeft sx={{ ml: '12px' }} />
                              {currency === 'idr' && <Typography className="w-700" sx={{ fontSize: 14, color: '#56617B', mr: '24px' }}>
                                Rp
                              </Typography>}
                              {currency === 'usd' && <Typography className="w-700" sx={{ fontSize: 16, color: '#56617B', mr: '24px' }}>
                                $
                              </Typography>}
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        className="box-input-filter"
                        type="number"
                        size="small"
                        placeholder={translate('status.max-harga')}
                        onChange={(e) => handleFilterBeliTongkang(e.target.value, "max_price", 'mobile')}
                        value={filterBeliTongkang["max_price"]}
                        sx={{
                          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                            display: "none",
                          },
                          "& input[type=number]": {
                            MozAppearance: "textfield",
                          },
                          width: '100%', input: {
                            fontSize: '14px', fontFamily: 'Satoshi-500'
                          }, mt: 1
                        }}
                        InputProps={{

                          startAdornment: (
                            <InputAdornment position="start">
                              <VerticalDividerLeft sx={{ ml: '12px' }} />
                              {currency === 'idr' && <Typography className="w-700" sx={{ fontSize: 14, color: '#56617B', mr: '24px' }}>
                                Rp
                              </Typography>}
                              {currency === 'usd' && <Typography className="w-700" sx={{ fontSize: 16, color: '#56617B', mr: '24px' }}>
                                $
                              </Typography>}
                            </InputAdornment>
                          ),
                        }}
                      />

                    </Box>

                    {/* <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
                      <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
                        {translate('permintaan.pelabuhanmuatan')}
                      </Typography>
                      <FormControl sx={{ width: '100%' }}>
                        <Select
                          IconComponent={() => (
                            <KeyboardArrowDownIcon sx={{ mr: '16px', width: '20px', height: '20px', fill: '#56617B' }} />
                          )}

                          style={{ border: '1px solid var(--grey-stroke, #e8ebf2)', marginTop: '4px', borderRadius: '8px', }}
                          placeholder="Pilih pelabuhan muat"
                          onChange={(e) => handleFilterBeliTongkang(e.target.value, "pelabuhan_muat", 'mobile')}
                          value={filterBeliTongkang["pelabuhan_muat"]}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                height: '352px',

                                '& .MuiMenuItem-root': {
                                  padding: '8px',
                                },
                              },
                            },
                          }}
                        >
                          <MenuItem disabled value="" >
                            <Typography className="w-400" style={{ color: '#8792AB', fontSize: 14 }}>  {translate('permintaan.placeholder-pelabuhanmuatan')} </Typography>

                          </MenuItem>
                          {portList.map((item, i) => (
                            <MenuItem key={i} value={item.id}>{item.attributes.name} </MenuItem>
                          ))}

                        </Select>

                      </FormControl>

                    </Box> */}
                    <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>


                    </Box>
                    <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
                      <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
                        {translate('permintaan.tahunbuat')}
                      </Typography>
                      <FormControl sx={{ width: '100%' }}>
                        <Select
                          IconComponent={() => (
                            <KeyboardArrowDownIcon sx={{ mr: '16px', width: '20px', height: '20px', fill: '#56617B' }} />
                          )}

                          style={{ border: '1px solid var(--grey-stroke, #e8ebf2)', marginTop: '4px', borderRadius: '8px', }}
                          onChange={(e) => handleFilterBeliTongkang(e.target.value, "tahun_buat", 'mobile')}
                          value={filterBeliTongkang["tahun_buat"]}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                height: '352px',

                                '& .MuiMenuItem-root': {
                                  padding: '8px',
                                },
                              },
                            },
                          }}
                        >
                          <MenuItem value="" disabled>
                            <Typography className="w-400" style={{ color: '#8792AB', fontSize: 14 }}> {translate('permintaan.placeholder-tahunbuat')}</Typography>

                          </MenuItem>
                          {list_years.map((item, i) => (
                            <MenuItem key={i} value={item}>{item} </MenuItem>))}
                        </Select>

                      </FormControl>

                    </Box>


                  </Box>
                  <Divider orientation="horizontal" style={{ marginTop: 10, marginBottom: 10, marginLeft: 16, marginRight: 16 }} />
                  <Stack direction={'row'} spacing={1} sx={{ ml: 2, mr: 2, mb: 2 }}>

                    <Button
                      className="w-500"
                      onClick={(e) => {

                        resetFilterBeliTongkang()
                        handleCloseModalFilter()
                      }}
                      fullWidth
                      variant="outlined"
                      sx={{
                        textTransform: "none", border: '1px solid var(--primarry-blue, #2457B9)',
                        p: '12px 16px',
                        borderRadius: '8px',
                        color: '#2457B9'
                      }}
                    >

                      {translate('jual-beli.btn-cancel')}
                    </Button>
                    <Button onClick={(e) => {
                      Filter('belitongkang')
                      handleCloseModalFilter()
                    }}
                      fullWidth
                      variant="contained"
                      sx={{ background: ' linear-gradient(270deg, #2457B9 15%, #2860CD 100%)', borderRadius: '8px', boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.12), 0px -1px 0px 0px rgba(0, 0, 0, 0.20) inset', padding: '12px 16px', textTransform: 'none' }}
                    >

                      {translate('page_explore.btn-filter')}
                    </Button>
                  </Stack>

                </Drawer>

              </Box>
              {/* modal filter RentTongkang Responsive Section */}
              <Box sx={{ flexGrow: 1, display: { xs: "flex", sm: 'flex', md: "flex" }, }}>

                <Drawer
                  className="box-filter-drawer"
                  anchor="bottom"
                  open={Boolean(modalRentTongkangFilter)}
                  onClose={handleCloseModalFilter}
                >

                  <Box >
                    <Grid
                      container
                      direction="row"
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      sx={{ pl: '16px', pt: '19px', pr: 2, pb: '19px' }}
                    >
                      <Typography className="w-700" sx={{ fontSize: 18, color: '#081130' }}>
                        Filter
                      </Typography>
                      <Stack direction={'row'} alignItems={'center'}>
                        <Button className="w-500" onClick={(e) => {

                          resetFilterSewaTongkang('mobile')

                        }} sx={{ fontSize: 16, color: '#2457B9', textTransform: 'none' }} variant="text"> Reset filter</Button>

                        <Divider orientation="vertical" style={{ marginTop: 10, marginBottom: 10, marginLeft: 10, marginRight: 10 }} flexItem />
                        <CloseIcon onClick={handleCloseModalFilter} />
                      </Stack>

                    </Grid>

                    <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
                      <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
                        {translate('permintaan.tipe-sewa')}
                      </Typography>
                      <Grid
                        container
                        direction="column"
                        justifyContent={'space-between'}
                        sx={{ mt: 1, pl: 2, pb: 2 }}
                        spacing={2}
                      >

                        <Button className="w-500" onClick={(e) => {
                          handleFilterSewaTongkang("time", "tipe_sewa", 'mobile')
                          handleFilterSewaTongkang("2", "type_tongkang", 'mobile')
                        }}
                          value={filterSewaTongkang["tipe_sewa"]} sx={{
                            "&:hover": {
                              borderColor: filterSewaTongkang["tipe_sewa"] === 'time' ? '#3BB56C' : '#E8EBF2',
                            }, textTransform: 'none', justifyContent: 'left', borderColor: filterSewaTongkang["tipe_sewa"] === 'time' ? '#3BB56C' : '#E8EBF2', color: filterSewaTongkang["tipe_sewa"] === 'time' ? '#3BB56C' : 'black', mb: 1, borderRadius: '8px', p: '14px 12px'
                          }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: filterSewaTongkang["tipe_sewa"] === 'time' ? '#3BB56C' : '#E8EBF2', }} />}>
                          Time charter
                        </Button>
                        <Button className="w-500" onClick={(e) => {
                          handleFilterSewaTongkang("freight", "tipe_sewa", 'mobile')
                          handleFilterSewaTongkang("3", "type_tongkang", 'mobile')
                        }}
                          value={filterSewaTongkang["tipe_sewa"]} sx={{
                            "&:hover": {
                              borderColor: filterSewaTongkang["tipe_sewa"] === 'freight' ? '#3BB56C' : '#E8EBF2',
                            }, textTransform: 'none', justifyContent: 'left', borderColor: filterSewaTongkang["tipe_sewa"] === 'freight' ? '#3BB56C' : '#E8EBF2', color: filterSewaTongkang["tipe_sewa"] === 'freight' ? '#3BB56C' : 'black', mb: 1, borderRadius: '8px', p: '14px 12px'
                          }} fullWidth variant="outlined" startIcon={<CircleOutlinedIcon sx={{ fill: filterSewaTongkang["tipe_sewa"] === 'freight' ? '#3BB56C' : '#E8EBF2', }} />}>
                          Freight charter
                        </Button>



                      </Grid>

                    </Box>
                    <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
                      <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
                        {translate('page_explore.ukurantongkang')}
                      </Typography>
                      <FormControl sx={{ width: '100%' }}>
                        <Select
                          IconComponent={() => (
                            <KeyboardArrowDownIcon sx={{ mr: '16px', width: '20px', height: '20px', fill: '#56617B' }} />
                          )}

                          style={{ border: '1px solid var(--grey-stroke, #e8ebf2)', marginTop: '4px', borderRadius: '8px', }}
                          onChange={(e) => handleFilterSewaTongkang(e.target.value, "ukuran_tongkang", 'mobile')}
                          value={filterSewaTongkang["ukuran_tongkang"]}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          <MenuItem value="">
                            <Typography className="w-400" style={{ color: '#8792AB', fontSize: 14 }}> {translate('page_explore.pilihukurantongkang')}</Typography>
                          </MenuItem>
                          <MenuItem value={180}>180 feet</MenuItem>
                          <MenuItem value={230}>230 feet</MenuItem>
                          <MenuItem value={300}>300 feet</MenuItem>
                          <MenuItem value={310}>310 feet</MenuItem>
                          <MenuItem value={320}>320 feet</MenuItem>
                          <MenuItem value={330}>330 feet</MenuItem>
                          <MenuItem value={0}>{translate('status.lainnya')}</MenuItem>
                        </Select>
                        {inputukuransewatongkang === true ? <TextField
                          className="box-input-filter"
                          type="number"
                          size="small"
                          placeholder={translate('status.placeholderukurantongkang')}
                          onChange={(e) => handleFilterUkuranTongkang(e.target.value, "ukuran_tongkang", 'mobile')}
                          value={filterSewaTongkang["ukuran_tongkang"]}
                          sx={{
                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                              display: "none",
                            },
                            "& input[type=number]": {
                              MozAppearance: "textfield",
                            },
                            width: '100%', input: {
                              fontSize: '14px', fontFamily: 'Satoshi-500'
                            },
                          }}

                        /> : ''}

                      </FormControl>

                    </Box>



                    {/* <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
                      <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
                        {translate('page_explore.quantitymuatan')}
                      </Typography>
                      <Grid
                        container
                        direction="row"
                        justifyContent={'space-between'}
                        spacing={2}
                      >
                        <Grid item xs={6}>

                          <TextField
                            className="box-input-filter"
                            type="number"
                            size="small"
                            placeholder="Min"
                            onChange={(e) => handleFilterSewaTongkang(e.target.value, "min_quantity_muatan", 'mobile')}
                            value={filterSewaTongkang["min_quantity_muatan"]}
                            sx={{
                              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                display: "none",
                              },
                              "& input[type=number]": {
                                MozAppearance: "textfield",
                              },
                              width: '100%', input: {
                                fontSize: '14px', fontFamily: 'Satoshi-500'
                              },
                            }}

                          />

                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            className="box-input-filter"
                            type="number"
                            size="small"
                            placeholder="Maks"
                            onChange={(e) => handleFilterSewaTongkang(e.target.value, "max_quantity_muatan", 'mobile')}
                            value={filterSewaTongkang["max_quantity_muatan"]}
                            sx={{
                              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                display: "none",
                              },
                              "& input[type=number]": {
                                MozAppearance: "textfield",
                              },
                              width: '100%', input: {
                                fontSize: '14px', fontFamily: 'Satoshi-500'
                              },
                            }}

                          />
                        </Grid>


                      </Grid>


                    </Box> */}

                    <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
                      <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
                        {translate('page_explore.hargatongkang')}
                      </Typography>


                      <TextField
                        className="box-input-filter"
                        type="number"
                        size="small"
                        placeholder={translate('status.min-harga')}
                        onChange={(e) => handleFilterSewaTongkang(e.target.value, "min_price", 'mobile')}
                        value={filterSewaTongkang["min_price"]}
                        sx={{
                          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                            display: "none",
                          },
                          "& input[type=number]": {
                            MozAppearance: "textfield",
                          },
                          width: '100%', input: {
                            fontSize: '14px', fontFamily: 'Satoshi-500'
                          },
                        }}
                        InputProps={{

                          startAdornment: (
                            <InputAdornment position="start">
                              <VerticalDividerLeft sx={{ ml: '12px' }} />
                              {currency === 'idr' && <Typography className="w-700" sx={{ fontSize: 14, color: '#56617B', mr: '24px' }}>
                                Rp
                              </Typography>}
                              {currency === 'usd' && <Typography className="w-700" sx={{ fontSize: 16, color: '#56617B', mr: '24px' }}>
                                $
                              </Typography>}
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        className="box-input-filter"
                        type="number"
                        size="small"
                        placeholder={translate('status.max-harga')}
                        onChange={(e) => handleFilterSewaTongkang(e.target.value, "max_price", 'mobile')}
                        value={filterSewaTongkang["max_price"]}
                        sx={{
                          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                            display: "none",
                          },
                          "& input[type=number]": {
                            MozAppearance: "textfield",
                          },
                          width: '100%', input: {
                            fontSize: '14px', fontFamily: 'Satoshi-500'
                          }, mt: 1
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <VerticalDividerLeft sx={{ ml: '12px' }} />
                              {currency === 'idr' && <Typography className="w-700" sx={{ fontSize: 14, color: '#56617B', mr: '24px' }}>
                                Rp
                              </Typography>}
                              {currency === 'usd' && <Typography className="w-700" sx={{ fontSize: 16, color: '#56617B', mr: '24px' }}>
                                $
                              </Typography>}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>

                    {/* <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
                      <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
                        {translate('permintaan.pelabuhanmuatan')}
                      </Typography>
                      <FormControl sx={{ width: '100%' }}>
                        <Select
                          IconComponent={() => (
                            <KeyboardArrowDownIcon sx={{ mr: '16px', width: '20px', height: '20px', fill: '#56617B' }} />
                          )}

                          style={{ border: '1px solid var(--grey-stroke, #e8ebf2)', marginTop: '4px', borderRadius: '8px', }}
                          onChange={(e) => handleFilterSewaTongkang(e.target.value, "pelabuhan_muat", 'mobile')}
                          value={filterSewaTongkang["pelabuhan_muat"]}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          <MenuItem value="" disabled>
                            <Typography className="w-400" style={{ color: '#8792AB', fontSize: 14 }}> {translate('permintaan.placeholder-pelabuhanmuatan')}</Typography>

                          </MenuItem>
                          {portList.map((item, i) => (
                            <MenuItem key={i} value={item.id}>{item.attributes.name} </MenuItem>
                          ))}

                        </Select>

                      </FormControl>

                    </Box>
                    <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
                      <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
                        {translate('permintaan.pelabuhanbongkar')}
                      </Typography>
                      <FormControl sx={{ width: '100%' }}>
                        <Select
                          IconComponent={() => (
                            <KeyboardArrowDownIcon sx={{ mr: '16px', width: '20px', height: '20px', fill: '#56617B' }} />
                          )}

                          style={{ border: '1px solid var(--grey-stroke, #e8ebf2)', marginTop: '4px', borderRadius: '8px', }}
                          onChange={(e) => handleFilterSewaTongkang(e.target.value, "pelabuhan_bongkar", 'mobile')}
                          value={filterSewaTongkang["pelabuhan_bongkar"]}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          <MenuItem value="" disabled>
                            <Typography className="w-400" style={{ color: '#8792AB', fontSize: 14 }}>   {translate('permintaan.placeholder-pelabuhanbongkar')}</Typography>

                          </MenuItem>
                          {portList.map((item, i) => (
                            <MenuItem key={i} value={item.id}>{item.attributes.name} </MenuItem>
                          ))}
                        </Select>

                      </FormControl>

                    </Box> */}
                    {/* <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
                      <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
                        {translate('permintaan.bendera')}
                      </Typography>
                      <FormControl sx={{ width: '100%' }}>
                        <Select
                          className={filterBeliTongkang["bendera"] !== "" ? 'select-flag' : 'placeholder-flag'}
                          IconComponent={() => (
                            <KeyboardArrowDownIcon sx={{ mr: '16px', width: '20px', height: '20px', fill: '#56617B' }} />
                          )}

                          style={{ border: '1px solid var(--grey-stroke, #e8ebf2)', marginTop: '4px', borderRadius: '8px', p: 0 }}
                          onChange={(e) => handleFilterSewaTongkang(e.target.value, "bendera", 'mobile')}
                          value={filterSewaTongkang["bendera"]}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          <MenuItem value="" disabled>
                            <Typography className="w-400" style={{ color: '#8792AB', fontSize: 14 }}>  {translate('permintaan.placeholder-bendera')}</Typography>

                          </MenuItem>
                          {benderaList.map((item, i) => {
                            let url = item?.attributes?.logo?.data === null ? '' : item?.attributes?.logo?.data[0].attributes.url
                            return (

                              <MenuItem sx={{ p: 0 }} className="w-500" key={i} value={item.id} onClick={() => { setBenderaName(item.attributes.name) }}>
                                <IconButton sx={{ mr: '8px' }}>
                                  <img style={{ borderRadius: "100%", objectFit: "cover" }} alt="img-icon" src={config.baseURL + url} height={28} width={28}></img>
                                </IconButton>{item.attributes.name}
                              </MenuItem>

                            )
                          })}
                        </Select>
                      </FormControl>
                      <FormNegara select={(e) => { handleFilterSewaTongkang(e.id, "bendera", "mobile") }} selectName={(e) => setBenderaName(e)} currentValue={filterSewaTongkang.bendera} />
                    </Box> */}

                  </Box>
                  <Divider orientation="horizontal" style={{ marginTop: 10, marginBottom: 10, marginLeft: 16, marginRight: 16 }} />
                  <Stack direction={'row'} spacing={1} sx={{ ml: 2, mr: 2 }}>

                    <Button className="w-500"
                      onClick={(e) => {

                        resetFilterSewaTongkang()
                        handleCloseModalFilter()
                      }}
                      fullWidth
                      variant="outlined"
                      sx={{
                        textTransform: "none", border: '1px solid var(--primarry-blue, #2457B9)',
                        p: '12px 16px',
                        borderRadius: '8px',
                        color: '#2457B9'
                      }}
                    >

                      {translate('jual-beli.btn-cancel')}
                    </Button>
                    <Button
                      className="w-500"
                      onClick={(e) => {
                        Filter('sewatongkang')
                        handleCloseModalFilter()
                      }}
                      fullWidth
                      variant="contained"
                      sx={{ background: ' linear-gradient(270deg, #2457B9 15%, #2860CD 100%)', borderRadius: '8px', boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.12), 0px -1px 0px 0px rgba(0, 0, 0, 0.20) inset', padding: '12px 16px', textTransform: 'none' }}
                    >

                      {translate('page_explore.btn-filter')}
                    </Button>
                  </Stack>

                </Drawer>

              </Box>

              {/* modal filter Location Responsive Section */}
              {activeTab === 'coal' && <DrawerFilterLocation type={'coal'} open={modalCoalLocation} close={handleCloseModalLocationResponsive} handleLocationReset={handleLocationReset} handleSearchValueLocation={handleSearchValueLocation} handleKeyDownLocation={handleKeyDownLocation} dataProvince={dataProvince}
                handleFilter={handleFilterBatubara} selectedItems={selectedItems} handleLocationSumbit={handleLocationSumbit} searchValueLocation={searchValueLocation}
              ></DrawerFilterLocation>}

              {activeTab === 'nikel' && <DrawerFilterLocation type={'nikel'} open={modalNikelLocation} close={handleCloseModalLocationResponsive} handleLocationReset={handleLocationReset} handleSearchValueLocation={handleSearchValueLocation} handleKeyDownLocation={handleKeyDownLocation} dataProvince={dataProvinceNikel}
                handleFilter={handleFilterNikel} selectedItems={selectedItems} handleLocationSumbit={handleLocationSumbit} searchValueLocation={searchValueLocation}
              ></DrawerFilterLocation>}
            </Hidden>
          </Grid>

        </Box >
      </Box >
      <Footer></Footer>
    </div >
  )
}


export default PermintaanPage
