import CompanyDefault from '@mui/icons-material/Business';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Avatar, Box, Card, CardContent, CardHeader, Grid, Hidden, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import defaultbannericon from "../assets/icons/default-tongkang.png";
import { addFavorite, deleteFavorite } from "../services/api.js";
import { config } from '../services/constants';
import { GlobalState } from "../services/global";
import { numberConvert } from '../services/primary';
import DrawerMore from "./DrawerMore";
import Love from "./Love";
import ModalShare from "./ModalShare";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export default function CardTongkangListExplore(props) {
  const data = props.data
  const [product_id, setProduct_id] = useState(null);
  const [user_trader_phone, setuser_trader_phone] = useState(null);
  const [translate,] = useTranslation("global");
  const [anchorElShare, setAnchorElShare] = useState(null);
  const [isLogin] = useState(localStorage.getItem('token') !== null ? true : false);
  const { currency } = React.useContext(GlobalState)
  const [label, setLabel] = useState('penawaran');
  const [ukuranTongkang, setUkuranTongkang] = useState([]);

  const setFavorite = (e, y) => {
    if (props.getTongkang !== undefined) {
      addFavorite({ type: e, id_relation: parseInt(y) })
      if (props.page === 'profile') {
        props.getTongkang();
      }
    }
  }

  const removeFavorite = (e, y) => {
    deleteFavorite({ type_product: e, id_product: parseInt(y) })
    if (props.page === 'profile') {
      props.getTongkang();
    }
  }

  const handleRoute = (label, product_id) => {
    if (label === 'penawaran') {
      window.location.href = `/penawaran/detail?type=3&id_detail=${product_id}`
    } else {
      window.location.href = `/permintaan/detail?type=3&id_detail=${product_id}`
    }
  }

  const handleOpenShareMenu = (event, id, traderphone, label) => {
    setLabel(label)
    setuser_trader_phone(traderphone);
    setProduct_id(id);
    setAnchorElShare(event.currentTarget);
  };

  const handleCloseShareMenu = () => {
    setAnchorElShare(null);
  };

  useEffect(() => {
    let arrayUkuranTongkang = props.data.ukuran_name.split(",")
    if (arrayUkuranTongkang[arrayUkuranTongkang.length - 1] === " FT") {
      arrayUkuranTongkang.splice((arrayUkuranTongkang.length - 1), 1)
    }
    setUkuranTongkang(arrayUkuranTongkang)
  }, [])

  return (
    <>
      <Hidden smDown>
        {data.label === 'penawaran' && <Card sx={{ borderRadius: 2, height: '382px', position: 'relative' }}>
          {
            isLogin &&
            <Love type='tongkang' condition={data?.user_favorite_id} remove={() => removeFavorite(3, data?.product_id)} favorite={() => setFavorite(3, data?.product_id)} />
          }

          <CardHeader
            onClick={(e) => {
              handleRoute(data.label, data.product_id);
            }}
            sx={{
              backgroundImage: data.product_image === null ? `url(${defaultbannericon})` : `linear-gradient(180deg, rgba(8, 34, 86, 0.6) 16.83%, rgba(8, 34, 86, 0) 60.9%), url(${config.baseURL}${data.product_image})`,
              height: 120,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              position: "relative",
              cursor: "pointer",
            }}
            avatar={
              <Box sx={{
                position: "absolute",
                top: '10%'
              }}>
                <Stack direction={'row'} >
                  <Box
                    sx={{
                      background: "#F4F6FA",
                      borderRadius: 4,
                      p: '2px 12px',
                      mr: '4px'
                    }}
                  >
                    <Stack direction={'row'} alignItems={'center'} >
                      {data.type_tongkang === 1 ? (
                        <>
                          <Box sx={{ borderRadius: '50%', background: '#F7CE46', width: '10px', height: '10px', mr: '4px' }}>
                          </Box>
                          <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 12 }} >
                            {translate('status.jual')}
                          </Typography>
                        </>

                      ) : data.type_tongkang === 2 ? (
                        <>
                          <Box sx={{ borderRadius: '50%', background: '#F55C24', width: '10px', height: '10px', mr: '4px' }}>
                          </Box>
                          <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 12 }} >
                            Time Charter
                          </Typography>
                        </>
                      ) : data.type_tongkang === 3 ? (
                        <>
                          <Box sx={{ borderRadius: '50%', background: '#883BE7', width: '10px', height: '10px', mr: '4px' }}>
                          </Box>
                          <Typography className="w-500" sx={{ color: '#818CA7', fontSize: '12px' }} >
                            Freight Charter
                          </Typography>
                        </>
                      ) : data.type_tongkang === 0 ? (
                        <>
                          <Box sx={{ borderRadius: '50%', background: '#2860CD', width: '10px', height: '10px', mr: '4px' }}>
                          </Box>
                          <Typography className="w-500" sx={{ color: '#818CA7', fontSize: '12px' }} >
                            {translate('status.beli')}
                          </Typography>
                        </>
                      ) : (
                        <></>
                      )}

                    </Stack>
                  </Box>

                </Stack>
              </Box>
            }

          >

          </CardHeader>
          <CardContent>
            <Box>
              <Grid
                container
                direction="row"
                alignItems="left"
                justifyContent="left"
                spacing={0}
              >
                <Grid item xs={8} sx={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    handleRoute(data.label, data.product_id);
                  }}>
                  <Typography className="w-700" sx={{ color: '#081130', fontSize: 16, }}>
                    {data.nama_tug_boat}
                  </Typography>
                </Grid>
                <Grid sx={{ textAlign: "right" }} item xs={4}>
                  <MoreHorizIcon sx={{ cursor: 'pointer' }} onClick={(e) => handleOpenShareMenu(e, data.product_id, data.phone_number)} />
                  <ModalShare type={'tongkang'} label={data.label} id={product_id} user_trader_phone={user_trader_phone} anchorEl={anchorElShare} open={anchorElShare} onClose={handleCloseShareMenu}></ModalShare>
                </Grid>
              </Grid>

              <Box
                sx={{ cursor: 'pointer' }}
                onClick={(e) => {
                  handleRoute(data.label, data.product_id);
                }}
              >
                <Typography className="w-500" sx={{ color: '#56617B', fontSize: 14, mb: 1 }} >
                  {data.nama_tongkang}
                </Typography>
                <Grid
                  container
                  direction="row"
                  alignItems="left"
                  justifyContent="left"
                  spacing={0}
                >
                  <Grid item xs={7}>
                    <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                      {translate('page_explore.harga')}
                    </Typography>
                  </Grid>
                  <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                      {translate('page_explore.ukurantongkang')}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  alignItems="left"
                  justifyContent="left"
                  spacing={0}
                >
                  <Grid item xs={7}>
                    {currency === 'idr' && <Typography className="w-700" sx={{ color: '#081130', fontSize: 14, }}>
                      Rp {numberConvert(data.price)}
                    </Typography>}
                    {currency === 'usd' && <Typography className="w-700" sx={{ color: '#081130', fontSize: 14, }}>
                      $ {numberConvert(data.price)}
                    </Typography>}
                  </Grid>
                  <Grid item xs={5}>
                    <Typography className="w-700" sx={{ color: '#081130', fontSize: 14, }}>
                      {data.ukuran_name}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box
              sx={{
                background: "#F4F6FA",
                borderRadius: "6px",
                padding: "8px 12px",
                mt: data.label === 'penawaran' ? 4 : 3.5,
                height: '100%'
              }}
            >
              <Typography
                sx={{ fontSize: 10, mb: 1, color: '#818CA7', textTransform: 'uppercase' }}
                className="w-500"
              >
                {translate('page_explore.listingoleh')}
              </Typography>
              <Stack

                direction="row"
                alignItems="center"
                justifyContent="left"
                spacing="8px"
              >
                {data.user_url_image ?
                  <img onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${data.user_id}` }} style={{ borderRadius: '50%', marginRight: 6, cursor: 'pointer' }} src={config.baseURL + data.user_url_image} height={36} width={36}></img> :
                  <Avatar onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${data.user_id}` }} sx={{ bgcolor: '#1976d2', width: '36px', height: '36px', mr: '6px', cursor: 'pointer' }} aria-label="recipe" />
                }
                <Stack spacing={0}>

                  {data?.company_list?.length > 0 ?
                    <Stack direction={'row'}>
                      {data.company_list.map((item, index) => {
                        if (index < 4) return (
                          <div key={item.company_id}>
                            {index === 0 ? (
                              <Avatar src={`${config.baseURL + item?.company_image}`} sx={{ bgcolor: '#1976d2', width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                                <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
                              </Avatar>
                            ) : (
                              <Avatar src={`${config.baseURL + item?.company_image}`} sx={{ bgcolor: '#1976d2', ml: -1, width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                                <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
                              </Avatar>
                            )}
                          </div>
                        )
                      })}
                      {data.company_list.length > 4 && <Avatar className="w-500" style={{ marginLeft: -6, width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                        +{data.company_list.length - 4}
                      </Avatar>}
                    </Stack> :

                    <Typography className="w-500" sx={{ fontSize: 14, ml: 1, color: '#818CA7', }}>
                      No Company listed
                    </Typography>}
                </Stack>
              </Stack>
            </Box>
          </CardContent>
        </Card>}
        {data.label === 'permintaan' && <Card sx={{ borderRadius: 2, height: '382px', position: 'relative' }}>

          {
            isLogin &&
            <Love type='tongkang' condition={data?.user_favorite_id} remove={() => removeFavorite(7, data?.product_id)} favorite={() => setFavorite(7, data?.product_id)} />
          }
          <CardHeader
            onClick={(e) => {
              handleRoute(data.label, data.product_id);
            }}
            sx={{
              backgroundImage: data.product_image === null ? `url(${defaultbannericon})` : `linear-gradient(180deg, rgba(8, 34, 86, 0.6) 16.83%, rgba(8, 34, 86, 0) 60.9%), url(${config.baseURL}${data.product_image})`,
              height: 120,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              position: "relative",
              cursor: "pointer",
            }}
            avatar={
              <Box sx={{
                position: "absolute",
                top: '10%'
              }}>
                <Stack direction={'row'} >
                  <Box
                    sx={{
                      background: "#F4F6FA",
                      borderRadius: 4,
                      p: '2px 12px',
                      mr: '4px'
                    }}
                  >
                    <Stack direction={'row'} alignItems={'center'} >
                      {data.type_tongkang === 1 ? (
                        <>
                          <Box sx={{ borderRadius: '50%', background: '#F7CE46', width: '10px', height: '10px', mr: '4px' }}>
                          </Box>
                          <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 12 }} >
                            {translate('status.jual')}
                          </Typography>
                        </>

                      ) : data.type_tongkang === 2 ? (
                        <>
                          <Box sx={{ borderRadius: '50%', background: '#F55C24', width: '10px', height: '10px', mr: '4px' }}>
                          </Box>
                          <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 12 }} >
                            Time Charter
                          </Typography>
                        </>
                      ) : data.type_tongkang === 3 ? (
                        <>
                          <Box sx={{ borderRadius: '50%', background: '#883BE7', width: '10px', height: '10px', mr: '4px' }}>
                          </Box>
                          <Typography className="w-500" sx={{ color: '#818CA7', fontSize: '12px' }} >
                            Freight Charter
                          </Typography>
                        </>
                      ) : data.type_tongkang === 4 ? (
                        <>
                          <Box sx={{ borderRadius: '50%', background: '#2860CD', width: '10px', height: '10px', mr: '4px' }}>
                          </Box>
                          <Typography className="w-500" sx={{ color: '#818CA7', fontSize: '12px' }} >
                            {translate('status.beli')}
                          </Typography>
                        </>
                      ) : (
                        <></>
                      )}

                    </Stack>
                  </Box>

                </Stack>
              </Box>
            }

          >

          </CardHeader>
          <CardContent>
            <Box>
              <Grid
                container
                direction="row"
                alignItems="left"
                justifyContent="left"
                spacing={0}
              >
                <Grid item xs={8} sx={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    handleRoute(data.label, data.product_id);
                  }}>
                  {/* <Typography className="w-700" sx={{ color: '#081130', fontSize: 16, }}>
                    {data.nama_tug_boat}
                  </Typography> */}
                  <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                    {translate('page_explore.harga')}
                  </Typography>
                  {currency === 'idr' && <Typography className="w-700" sx={{ color: '#081130', fontSize: 14, }}>
                    Rp {numberConvert(data.price)}
                  </Typography>}
                  {currency === 'usd' && <Typography className="w-700" sx={{ color: '#081130', fontSize: 14, }}>
                    $ {numberConvert(data.price)}
                  </Typography>}
                </Grid>
                <Grid sx={{ textAlign: "right" }} item xs={4}>
                  <MoreHorizIcon sx={{ cursor: 'pointer' }} onClick={(e) => handleOpenShareMenu(e, data.product_id, data.phone_number)} />
                  <ModalShare type={'tongkang'} label={data.label} id={product_id} user_trader_phone={user_trader_phone} anchorEl={anchorElShare} open={anchorElShare} onClose={handleCloseShareMenu}></ModalShare>
                </Grid>
              </Grid>

              <Box
                sx={{ cursor: 'pointer' }}
                onClick={(e) => {
                  handleRoute(data.label, data.product_id);
                }}
              >
                <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, marginTop: "8px" }} >
                  {translate('page_explore.ukurantongkang')}
                </Typography>
                <Box className="w-700" sx={{ color: '#081130', fontSize: 14, display: "flex", flexWrap: "wrap" }}>
                  {ukuranTongkang.map((item, index) => {
                    return (
                      <>
                        {index < 3 ? (
                          <div style={{ minWidth: "fit-content", borderRadius: "5px", marginTop: "5px", padding: "5px", marginRight: "5px", backgroundColor: "#F4F6FA" }}>{item}</div>
                        ) : (
                          <></>
                        )}
                      </>
                    )
                  })}
                  {ukuranTongkang.length > 3 ? (
                    <div style={{ width: "20px", height: "20px", borderRadius: "20px", marginTop: "5px", padding: "5px", marginRight: "5px", backgroundColor: "#F4F6FA", display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <ArrowForwardIosIcon onClick={(e) => {
                        handleRoute(data.label, data.product_id);
                      }} sx={{ fontSize: 12 }} />
                    </div>
                  ) : (
                    <></>
                  )}
                </Box>
                {/* <Typography className="w-500" sx={{ color: '#56617B', fontSize: 14, mb: 1 }} >
                  {data.nama_tongkang}
                </Typography>
                <Grid
                  container
                  direction="row"
                  alignItems="left"
                  justifyContent="left"
                  spacing={0}
                >
                  <Grid item xs={7}>
                    <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                      {translate('page_explore.harga')}
                    </Typography>
                  </Grid>
                  <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                      {translate('page_explore.ukurantongkang')}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  alignItems="left"
                  justifyContent="left"
                  spacing={0}
                >
                  <Grid item xs={7}>
                    {currency === 'idr' && <Typography className="w-700" sx={{ color: '#081130', fontSize: 14, }}>
                      Rp {numberConvert(data.price)}
                    </Typography>}
                    {currency === 'usd' && <Typography className="w-700" sx={{ color: '#081130', fontSize: 14, }}>
                      $ {numberConvert(data.price)}
                    </Typography>}
                  </Grid>
                  <Grid item xs={5}>
                    <Typography className="w-700" sx={{ color: '#081130', fontSize: 14, }}>
                      {data.ukuran_name}
                    </Typography>
                  </Grid>
                </Grid> */}
              </Box>
            </Box>
            <Box
              sx={{
                background: "#F4F6FA",
                borderRadius: "6px",
                padding: "8px 12px",
                mt: data.label === 'penawaran' ? 4 : 3.5,
                height: '100%'
              }}
            >
              <Typography
                sx={{ fontSize: 10, mb: 1, color: '#818CA7', textTransform: 'uppercase' }}
                className="w-500"
              >
                {translate('page_explore.listingoleh')}
              </Typography>
              <Stack

                direction="row"
                alignItems="center"
                justifyContent="left"
                spacing="8px"
              >
                {data.user_url_image ?
                  <img onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${data.user_id}` }} style={{ borderRadius: '50%', marginRight: 6, cursor: 'pointer' }} src={config.baseURL + data.user_url_image} height={36} width={36}></img> :
                  <Avatar onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${data.user_id}` }} sx={{ bgcolor: '#1976d2', width: '36px', height: '36px', mr: '6px', cursor: 'pointer' }} aria-label="recipe" />
                }
                <Stack spacing={0}>

                  {data.company_list.length > 0 ?
                    <Stack direction={'row'}>
                      {data.company_list.map((item, index) => {
                        if (index < 4) return (
                          <div key={item.company_id}>
                            {index === 0 ? (
                              item?.company_image ?
                                <Avatar src={`${config.baseURL + item?.company_image}`} sx={{ bgcolor: '#1976d2', width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                                  <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
                                </Avatar> :
                                <Avatar sx={{ bgcolor: '#1976d2', width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                                  <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
                                </Avatar>
                            ) : (
                              item?.company_image ?
                                <Avatar src={`${config.baseURL + item?.company_image}`} sx={{ bgcolor: '#1976d2', ml: -1, width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                                  <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
                                </Avatar> :
                                <Avatar sx={{ bgcolor: '#1976d2', ml: -1, width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                                  <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
                                </Avatar>
                            )}
                          </div>
                        )
                      })}
                      {data.company_list.length > 4 && <Avatar className="w-500" style={{ marginLeft: -6, width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                        +{data.company_list.length - 4}
                      </Avatar>}
                    </Stack> :

                    <Typography className="w-500" sx={{ fontSize: 14, ml: 1, color: '#818CA7', }}>
                      No Company listed
                    </Typography>}
                </Stack>
              </Stack>
            </Box>
          </CardContent>
        </Card>}
      </Hidden>
      <Hidden smUp>
        {data.label === 'penawaran' && <Card sx={{ borderRadius: 2, height: '382px', position: 'relative' }}>
          {
            isLogin &&
            <Love type='tongkang' condition={data?.user_favorite_id} remove={() => removeFavorite(3, data?.product_id)} favorite={() => setFavorite(3, data?.product_id)} />

          }

          <CardHeader
            onClick={(e) => {
              handleRoute(data.label, data.product_id);
            }}
            sx={{
              backgroundImage: data.product_image === null ? `url(${defaultbannericon})` : `linear-gradient(180deg, rgba(8, 34, 86, 0.6) 16.83%, rgba(8, 34, 86, 0) 60.9%), url(${config.baseURL}${data.product_image})`,
              height: 120,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              position: "relative",
              cursor: "pointer",
            }}
            avatar={
              <Box sx={{
                position: "absolute",
                top: '10%'
              }}>
                <Stack direction={'row'} >
                  <Box
                    sx={{
                      background: "#F4F6FA",
                      borderRadius: 4,
                      p: '2px 12px',
                      mr: '4px'
                    }}
                  >
                    <Stack direction={'row'} alignItems={'center'} >
                      {data.type_tongkang === 1 ? (
                        <>
                          <Box sx={{ borderRadius: '50%', background: '#F7CE46', width: '10px', height: '10px', mr: '4px' }}>
                          </Box>
                          <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 12 }} >
                            {translate('status.jual')}
                          </Typography>
                        </>

                      ) : data.type_tongkang === 2 ? (
                        <>
                          <Box sx={{ borderRadius: '50%', background: '#F55C24', width: '10px', height: '10px', mr: '4px' }}>
                          </Box>
                          <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 12 }} >
                            Time Charter
                          </Typography>
                        </>
                      ) : data.type_tongkang === 3 ? (
                        <>
                          <Box sx={{ borderRadius: '50%', background: '#883BE7', width: '10px', height: '10px', mr: '4px' }}>
                          </Box>
                          <Typography className="w-500" sx={{ color: '#818CA7', fontSize: '12px' }} >
                            Freight Charter
                          </Typography>
                        </>
                      ) : data.type_tongkang === 0 ? (
                        <>
                          <Box sx={{ borderRadius: '50%', background: '#2860CD', width: '10px', height: '10px', mr: '4px' }}>
                          </Box>
                          <Typography className="w-500" sx={{ color: '#818CA7', fontSize: '12px' }} >
                            {translate('status.beli')}
                          </Typography>
                        </>
                      ) : (
                        <></>
                      )}

                    </Stack>
                  </Box>

                </Stack>
              </Box>
            }

          >

          </CardHeader>
          <CardContent>
            <Box>
              <Grid
                container
                direction="row"
                alignItems="left"
                justifyContent="left"
                spacing={0}
              >
                <Grid item xs={8} sx={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    handleRoute(data.label, data.product_id);
                  }}>
                  <Typography className="w-700" sx={{ color: '#081130', fontSize: 16, }}>
                    {data.nama_tug_boat}
                  </Typography>
                </Grid>
                <Grid sx={{ textAlign: "right" }} item xs={4}>
                  <MoreHorizIcon sx={{ cursor: 'pointer' }} onClick={(e) => handleOpenShareMenu(e, data.product_id, data.phone_number, data.label)} />

                </Grid>
              </Grid>

              <Box
                sx={{ cursor: 'pointer' }}
                onClick={(e) => {
                  handleRoute(data.label, data.product_id);
                }}
              >
                <Typography className="w-500" sx={{ color: '#56617B', fontSize: 14, mb: 1 }} >
                  {data.nama_tongkang}
                </Typography>
                <Grid
                  container
                  direction="row"
                  alignItems="left"
                  justifyContent="left"
                  spacing={0}
                >
                  <Grid item xs={7}>
                    <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                      {translate('page_explore.harga')}
                    </Typography>
                  </Grid>
                  <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                      {translate('page_explore.ukurantongkang')}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  alignItems="left"
                  justifyContent="left"
                  spacing={0}
                >

                  <Grid item xs={7}>
                    {currency === 'idr' && <Typography className="w-700" sx={{ color: '#081130', fontSize: 14, }}>
                      Rp {numberConvert(data.price)}
                    </Typography>}
                    {currency === 'usd' && <Typography className="w-700" sx={{ color: '#081130', fontSize: 14, }}>
                      $ {numberConvert(data.price)}
                    </Typography>}
                  </Grid>
                  <Grid item xs={5}>
                    <Typography className="w-700" sx={{ color: '#081130', fontSize: 14, }}>
                      {data.ukuran_name}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box
              sx={{
                background: "#F4F6FA",
                borderRadius: "6px",
                padding: "8px 12px",
                mt: data.label === 'penawaran' ? 4 : 3.5,
                height: '100%'
              }}
            >
              <Typography
                sx={{ fontSize: 10, mb: 1, color: '#818CA7', textTransform: 'uppercase' }}
                className="w-500"
              >
                {translate('page_explore.listingoleh')}
              </Typography>
              <Stack

                direction="row"
                alignItems="center"
                justifyContent="left"
                spacing="8px"
              >
                {data.user_url_image ?
                  <img onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${data.user_id}` }} style={{ borderRadius: '50%', marginRight: 6, cursor: 'pointer' }} src={config.baseURL + data.user_url_image} height={36} width={36}></img> :
                  <Avatar onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${data.user_id}` }} sx={{ bgcolor: '#1976d2', width: '36px', height: '36px', mr: '6px', cursor: 'pointer' }} aria-label="recipe" />
                }
                <Stack spacing={0}>

                  {data.company_list.length > 0 ?
                    <Stack direction={'row'}>
                      {data.company_list.map((item, index) => {
                        if (index < 4) return (
                          <div key={item.company_id}>
                            {index === 0 ? (
                              item?.company_image ?
                                <Avatar src={`${config.baseURL + item?.company_image}`} sx={{ bgcolor: '#1976d2', width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                                  <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
                                </Avatar> :
                                <Avatar sx={{ bgcolor: '#1976d2', width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                                  <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
                                </Avatar>
                            ) : (
                              item?.company_image ?
                                <Avatar src={`${config.baseURL + item?.company_image}`} sx={{ bgcolor: '#1976d2', ml: -1, width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                                  <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
                                </Avatar> :
                                <Avatar sx={{ bgcolor: '#1976d2', ml: -1, width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                                  <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
                                </Avatar>
                            )}
                          </div>
                        )
                      })}
                      {data.company_list.length > 4 && <Avatar className="w-500" style={{ marginLeft: -6, width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                        +{data.company_list.length - 4}
                      </Avatar>}
                    </Stack> :

                    <Typography className="w-500" sx={{ fontSize: 14, ml: 1, color: '#818CA7', }}>
                      No Company listed
                    </Typography>}
                </Stack>
              </Stack>
            </Box>
          </CardContent>
        </Card>}
        {data.label === 'permintaan' && <Card sx={{ borderRadius: 2, height: '382px', position: 'relative' }}>

          {
            isLogin &&

            <Love type='tongkang' condition={data?.user_favorite_id} remove={() => removeFavorite(7, data?.product_id)} favorite={() => setFavorite(7, data?.product_id)} />
          }
          <CardHeader
            onClick={(e) => {
              handleRoute(data.label, data.product_id);
            }}
            sx={{
              backgroundImage: data.product_image === null ? `url(${defaultbannericon})` : `linear-gradient(180deg, rgba(8, 34, 86, 0.6) 16.83%, rgba(8, 34, 86, 0) 60.9%), url(${config.baseURL}${data.product_image})`,
              height: 120,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              position: "relative",
              cursor: "pointer",
            }}
            avatar={
              <Box sx={{
                position: "absolute",
                top: '10%'
              }}>
                <Stack direction={'row'} >
                  <Box
                    sx={{
                      background: "#F4F6FA",
                      borderRadius: 4,
                      p: '2px 12px',
                      mr: '4px'
                    }}
                  >
                    <Stack direction={'row'} alignItems={'center'} >
                      {data.type_tongkang === 1 ? (
                        <>
                          <Box sx={{ borderRadius: '50%', background: '#F7CE46', width: '10px', height: '10px', mr: '4px' }}>
                          </Box>
                          <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 12 }} >
                            {translate('status.jual')}
                          </Typography>
                        </>

                      ) : data.type_tongkang === 2 ? (
                        <>
                          <Box sx={{ borderRadius: '50%', background: '#F55C24', width: '10px', height: '10px', mr: '4px' }}>
                          </Box>
                          <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 12 }} >
                            Time Charter
                          </Typography>
                        </>
                      ) : data.type_tongkang === 3 ? (
                        <>
                          <Box sx={{ borderRadius: '50%', background: '#883BE7', width: '10px', height: '10px', mr: '4px' }}>
                          </Box>
                          <Typography className="w-500" sx={{ color: '#818CA7', fontSize: '12px' }} >
                            Freight Charter
                          </Typography>
                        </>
                      ) : data.type_tongkang === 4 ? (
                        <>
                          <Box sx={{ borderRadius: '50%', background: '#2860CD', width: '10px', height: '10px', mr: '4px' }}>
                          </Box>
                          <Typography className="w-500" sx={{ color: '#818CA7', fontSize: '12px' }} >
                            {translate('status.beli')}
                          </Typography>
                        </>
                      ) : (
                        <></>
                      )}

                    </Stack>
                  </Box>

                </Stack>
              </Box>
            }

          >

          </CardHeader>
          <CardContent>
            {/* <Box>
              <Grid
                container
                direction="row"
                alignItems="left"
                justifyContent="left"
                spacing={0}
              >
                <Grid item xs={8} sx={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    handleRoute(data.label, data.product_id);
                  }}>
                  <Typography className="w-700" sx={{ color: '#081130', fontSize: 16, }}>
                    {data.nama_tug_boat}
                  </Typography>
                </Grid>
                <Grid sx={{ textAlign: "right" }} item xs={4}>
                  <MoreHorizIcon sx={{ cursor: 'pointer' }} onClick={(e) => handleOpenShareMenu(e, data.product_id, data.phone_number, data.label)} />

                </Grid>
              </Grid>

              <Box
                sx={{ cursor: 'pointer' }}
                onClick={(e) => {
                  handleRoute(data.label, data.product_id);
                }}
              >
                <Typography className="w-500" sx={{ color: '#56617B', fontSize: 14, mb: 1 }} >
                  {data.nama_tongkang}
                </Typography>
                <Grid
                  container
                  direction="row"
                  alignItems="left"
                  justifyContent="left"
                  spacing={0}
                >
                  <Grid item xs={7}>
                    <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                      {translate('page_explore.harga')}
                    </Typography>
                  </Grid>
                  <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                      {translate('page_explore.ukurantongkang')}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  alignItems="left"
                  justifyContent="left"
                  spacing={0}
                >

                  <Grid item xs={5}>
                    {currency === 'idr' && <Typography className="w-700" sx={{ color: '#081130', fontSize: 14, }}>
                      Rp {numberConvert(data.price)}
                    </Typography>}
                    {currency === 'usd' && <Typography className="w-700" sx={{ color: '#081130', fontSize: 14, }}>
                      $ {numberConvert(data.price)}
                    </Typography>}
                  </Grid>
                </Grid>
              </Box>
            </Box> */}
            <Box>
              <Grid
                container
                direction="row"
                alignItems="left"
                justifyContent="left"
                spacing={0}
              >
                <Grid item xs={8} sx={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    handleRoute(data.label, data.product_id);
                  }}>
                  <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                    {translate('page_explore.harga')}
                  </Typography>
                  {currency === 'idr' && <Typography className="w-700" sx={{ color: '#081130', fontSize: 14, }}>
                    Rp {numberConvert(data.price)}
                  </Typography>}
                  {currency === 'usd' && <Typography className="w-700" sx={{ color: '#081130', fontSize: 14, }}>
                    $ {numberConvert(data.price)}
                  </Typography>}
                  <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, marginTop: "8px" }} >
                    {translate('page_explore.ukurantongkang')}
                  </Typography>
                  <Typography className="w-700" sx={{ color: '#081130', fontSize: 14, display: "flex" }}>
                    {ukuranTongkang.map((item, index) => {
                      return (
                        <>
                          {index < 4 ? (
                            <div style={{ minWidth: "fit-content", borderRadius: "5px", marginTop: "5px", padding: "5px", marginRight: "5px", backgroundColor: "#F4F6FA" }}>{item}</div>
                          ) : (
                            <></>
                          )}
                        </>
                      )
                    })}
                    {ukuranTongkang.length > 4 ? (
                      <div style={{ width: "20px", height: "20px", borderRadius: "20px", marginTop: "5px", padding: "5px", marginRight: "5px", backgroundColor: "#F4F6FA", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <ArrowForwardIosIcon onClick={(e) => {
                          handleRoute(data.label, data.product_id);
                        }} sx={{ fontSize: 12 }} />
                      </div>
                    ) : (
                      <></>
                    )}
                  </Typography>
                </Grid>
                <Grid sx={{ textAlign: "right" }} item xs={4}>
                  <MoreHorizIcon sx={{ cursor: 'pointer' }} onClick={(e) => handleOpenShareMenu(e, data.product_id, data.phone_number, data.label)} />
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                background: "#F4F6FA",
                borderRadius: "6px",
                padding: "8px 12px",
                mt: data.label === 'penawaran' ? 4 : 3.5,
                height: '100%'
              }}
            >
              <Typography
                sx={{ fontSize: 10, mb: 1, color: '#818CA7', textTransform: 'uppercase' }}
                className="w-500"
              >
                {translate('page_explore.listingoleh')}
              </Typography>
              <Stack

                direction="row"
                alignItems="center"
                justifyContent="left"
                spacing="8px"
              >
                {data.user_url_image ?
                  <img onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${data.user_id}` }} style={{ borderRadius: '50%', marginRight: 6, cursor: 'pointer' }} src={config.baseURL + data.user_url_image} height={36} width={36}></img> :
                  <Avatar onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${data.user_id}` }} sx={{ bgcolor: '#1976d2', width: '36px', height: '36px', mr: '6px', cursor: 'pointer' }} aria-label="recipe" />
                }
                <Stack spacing={0}>

                  {data.company_list.length > 0 ?
                    <Stack direction={'row'}>
                      {data.company_list.map((item, index) => {
                        if (index < 4) return (
                          <div key={item.company_id}>
                            {index === 0 ? (
                              item?.company_image ?
                                <Avatar src={`${config.baseURL + item?.company_image}`} sx={{ bgcolor: '#1976d2', width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                                  <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
                                </Avatar>
                                :
                                <Avatar sx={{ bgcolor: '#1976d2', width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                                  <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
                                </Avatar>
                            ) : (
                              item?.company_image ?
                                <Avatar src={`${config.baseURL + item?.company_image}`} sx={{ bgcolor: '#1976d2', ml: -1, width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                                  <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
                                </Avatar> :
                                <Avatar sx={{ bgcolor: '#1976d2', ml: -1, width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                                  <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
                                </Avatar>
                            )}
                          </div>
                        )
                      })}
                      {data.company_list.length > 4 && <Avatar className="w-500" style={{ marginLeft: -6, width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                        +{data.company_list.length - 4}
                      </Avatar>}
                    </Stack> :

                    <Typography className="w-500" sx={{ fontSize: 14, ml: 1, color: '#818CA7', }}>
                      No Company listed
                    </Typography>}
                </Stack>
              </Stack>
            </Box>
          </CardContent>
        </Card>}
        <DrawerMore type={3} page={'explore'} label={label} id={product_id} user_trader_phone={user_trader_phone} open={anchorElShare} close={handleCloseShareMenu}>
        </DrawerMore>
      </Hidden>
    </>
  )
}
