import SearchIcon from "@mui/icons-material/Search"
import { InputAdornment, TextField } from "@mui/material"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { addKeyword } from "../services/api"

export default function SearchBar() {
  const [translate,] = useTranslation("global");
  const handleChange = (event) => {
    setSearchValue(event.target.value)
  }

  const [searchValue, setSearchValue] = useState('');
  const navigate = useNavigate()

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter') {
      event.target.blur();

      let params = { value: searchValue }
      let response = await addKeyword(params)
      let path = window.location.pathname
      if (searchValue.includes("coal") || searchValue.includes("batubara")) {
        navigate(`/explore`, { state: { detail: 'coal', search: `${searchValue}` } })
        if (path === '/explore') {
          window.location.reload()
        }
      } else if (searchValue.includes("nickel") || searchValue.includes("nikel")) {
        navigate(`/explore`, { state: { detail: 'nikel', search: `${searchValue}` } })
        if (path === '/explore') {
          window.location.reload()
        }
      } else if (searchValue.includes("barge") || searchValue.includes("tongkang")) {
        navigate(`/explore`, { state: { detail: 'tongkang', search: `${searchValue}` } })
        if (path === '/explore') {
          window.location.reload()
        }
      } else if (searchValue.includes("company") || searchValue.includes("perusahaan")) {
        navigate(`/explore`, { state: { detail: 'company', search: `${searchValue}` } })
        if (path === '/explore') {
          window.location.reload()
        }
      } else if (searchValue.includes("trader") || searchValue.includes("orang")) {
        navigate(`/explore`, { state: { detail: 'trader', search: `${searchValue}` } })
        if (path === '/explore') {
          window.location.reload()
        }
      } else {
        navigate(`/explore`, { state: { detail: 'coal', search: `${searchValue}` } })
        if (path === '/explore') {
          window.location.reload()
        }
      }
    }
  }

  const handleClick = async (e) => {
    let path = window.location.pathname
    if (e.includes("coal") || e.includes("batubara")) {
      navigate(`/explore`, { state: { detail: 'coal', search: `${e}` } })
      if (path === '/explore') {
        window.location.reload()
      }
    } else if (e.includes("nickel") || e.includes("nikel")) {
      navigate(`/explore`, { state: { detail: 'nikel', search: `${e}` } })
      if (path === '/explore') {
        window.location.reload()
      }
    } else if (e.includes("barge") || e.includes("tongkang")) {
      navigate(`/explore`, { state: { detail: 'tongkang', search: `${e}` } })
      if (path === '/explore') {
        window.location.reload()
      }
    } else if (e.includes("company") || e.includes("perusahaan")) {
      navigate(`/explore`, { state: { detail: 'company', search: `${e}` } })
      if (path === '/explore') {
        window.location.reload()
      }
    } else if (e.includes("trader") || e.includes("orang")) {
      navigate(`/explore`, { state: { detail: 'trader', search: `${e}` } })
      if (path === '/explore') {
        window.location.reload()
      }
    } else {
      navigate(`/explore`, { state: { detail: 'coal', search: `${e}` } })
      if (path === '/explore') {
        window.location.reload()
      }
    }
  }

  return (

    <Grid container
      sx={{ mt: 4, mb: 4, }}
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={0}>
      <TextField

        type="search"
        className="w-400"
        placeholder={translate('homepage.placeholder-search')}
        value={searchValue}
        onChange={handleChange}
        sx={{
          width: { xs: '100%', md: 500 },
          bgcolor: '#fff',
          borderRadius: "8px", input: {
            fontSize: '14px', fontFamily: 'Satoshi-500'
          },
        }}
        InputProps={{
          style: { fontSize: 16 },
          endAdornment: (
            <InputAdornment
              position="end"
            >
              <Box
                style={{
                  backgroundColor: "#3BB56C",
                  alignItems: "center",
                  display: "flex",
                  borderRadius: 8,
                }}
                onClick={() => { handleClick(searchValue) }}
              >
                <SearchIcon className="pointer" style={{ color: "white", padding: 8 }} />
              </Box>
            </InputAdornment>
          ),
        }}
        onKeyDown={handleKeyDown}
      />
    </Grid>
  )
}
