import Divider from "@material-ui/core/Divider";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Button, Grid, Hidden, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import Batubaraicon from "../assets/batubara-ic.png";
import HeroImg from "../assets/hero-img.webp";
import Nikelicon from "../assets/nikel-ic.png";
import Perusahaanicon from "../assets/perusahaan-ic.png";
import Tongkangicon from "../assets/tongkang-ic.png";
import CardCompanyHomepage from "../components/CardCompanyHomepage";
import CardListCoal from "../components/CardListCoal";
import CardListNikel from "../components/CardListNikel";
import CardTongkangList from "../components/CardTongkangList";
import Footer from "../components/Footer";
import NavBarHome from "../components/NavbarHome";
import SearchBar from "../components/Searchbar";
import TabelCoal from "../components/TableCoal";
import TabelNikel from "../components/TableNikel";
import { getRandomCompany, searchBarge, searchCoal, searchNickel } from "../services/api.js";
import { settingsCompanyHomePage, settingsHomePage } from '../services/primary';

function Homepage() {
  const [isLogin] = useState(localStorage.getItem('token') !== null ? true : false);
  const [translate] = useTranslation("global");
  const [activeTab, setActiveTab] = useState("coal");
  const [productList, setProductList] = useState([]);
  const [tongkangList, setTongkangList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [nickelList, setNickelList] = useState([]);
  const [totalItemNikel, settotalItemNikel] = useState([]);
  const [currentPageCoal] = useState(1);
  const [currentPageNickel] = useState(1);
  const [currentPageCompany, setCurrentPageCompany] = useState(1);
  const [currentPageCoalMobile, setCurrentPageCoalMobile] = useState(1);
  const [currentPageNickelMobile, setCurrentPageNickelMobile] = useState(1);
  const navigate = useNavigate()

  useEffect(() => {
    getProduct();
    getTongkangList();
    getCompanyList();
    getNickelList()
  }, []);

  async function getProduct() {
    try {
      const response = await searchCoal({
        page: 1,
        min_calorie: '', max_calorie: '', min_total_sulfur: '', max_total_sulfur: '', min_ash: '',
        max_ash: '', quantity: '', min_price: '', max_price: '', id_location: "", search_text: ""
      });
      setProductList(response.data?.data);
    } catch (e) {
      console.log(e);
    }
  }

  async function getCompanyList() {
    try {
      const response = await getRandomCompany({
        page: 1
      });

      setCompanyList(response.data?.data.data);
    } catch (e) {
      console.log(e);
    }
  }

  async function getNickelList() {
    try {
      const response = await searchNickel({
        page: 1,
        min_kadar_ni: '', max_kadar_ni: '', min_kadar_air: '', max_kadar_air: '', min_kadar_fe: '', max_kadar_fe: '',
        min_si_mg_ratio: '', max_si_mg_ratio: '', quantity: '', min_price: '', max_price: '', id_location: "", search_text: ""
      });
      setNickelList(response.data?.data.data.data);
      settotalItemNikel(response.data?.data.totalItemNikel)
    } catch (e) {
      console.log(e);
    }
  }

  async function getTongkangList() {
    try {
      const response = await searchBarge({
        page: 1,
        type_tongkang: "", ukuran_tongkang: '', max_quantity_muatan: '', min_quantity_muatan: '', max_price: '', min_price: '',
        bendera: "", tahun_buat: "", search_text: ""
      });

      setTongkangList(response.data?.data.data.data);
    } catch (e) {
      console.log(e);
    }
  }

  //  Functions to handle Tab Switching
  const handleTab = (activeTab) => {
    // update the state 
    setActiveTab(activeTab);
  };

  async function setFavoriteTongkang() {
    setTimeout(() => {
      getTongkangList()
    }, 150);
  }

  async function setFavoriteBatubara() {
    setTimeout(() => {
      getProduct()
    }, 150);
  }

  async function setFavoriteNikel() {
    setTimeout(() => {
      getNickelList()
    }, 250);
  }

  const filterProduct = (field, limit) => {
    let new_data = []
    field.data?.data.map((item, i) => {
      if (i < parseInt(currentPageCoal * limit)) {
        return (new_data.push(item))
      }
    })
    return new_data
  }

  const filterNickel = (field, limit) => {
    let new_data = []
    field.map((item, i) => {
      if (i < parseInt(currentPageNickel * limit)) {
        return (new_data.push(item))
      }
    })
    return new_data
  }



  return (
    <div className="homepage">
      <div className="flex-wrap-center relative width-100">
        <div className="container-nav relative width-100">
          <div className='fixed-header'>
            <NavBarHome />
          </div>
        </div>
        <div className="banner width-100">
          <img className="img-banner img-cover width-100 img-fluid" src={HeroImg} alt={HeroImg}></img>
          <Box className="banner-title width-100 flex-center">
            <div className="content-inner relative">
              <div className="width-100 flex-wrap-center">
                <h1 className='s-4872 text-center w-700'>
                  {translate('homepage.hero-text1')} {translate('homepage.hero-text2')}
                </h1>
                <SearchBar />
                <div className="box-mine-category flex-center width-100">
                  <div className="mine-category">
                    {isLogin ? <div className="mine-inner" onClick={() => navigate('/explore', { state: { detail: 'coal' } })}>
                      <img alt="Batubara-icon" src={Batubaraicon}></img>
                      <p className="c-ff w-700 s-1419 text-center"> {translate('homepage.batubara')}</p>
                    </div> :
                      <div className="mine-inner" onClick={() => navigate('/signin')}>
                        <img alt="Batubara-icon" src={Batubaraicon}></img>
                        <p className="c-ff w-700 s-1419 text-center"> {translate('homepage.batubara')}</p>
                      </div>}
                  </div>
                  <div className="mine-category">
                    {isLogin ? <div className="mine-inner" onClick={() => navigate('/explore', { state: { detail: 'nikel' } })}>
                      <img alt="Nikel-icon" src={Nikelicon}></img>
                      <p className="c-ff w-700 s-1419 text-center"> {translate('homepage.nikel')}</p>
                    </div> :
                      <div className="mine-inner" onClick={() => navigate('/signin')}>
                        <img alt="Nikel-icon" src={Nikelicon}></img>
                        <p className="c-ff w-700 s-1419 text-center"> {translate('homepage.nikel')}</p>
                      </div>
                    }
                  </div>
                  <div className="mine-category">
                    {isLogin ? <div className="mine-inner" onClick={() => navigate('/explore', { state: { detail: 'tongkang' } })}>
                      <img
                        alt="Tongkang-icon"
                        src={Tongkangicon}
                      ></img>
                      <p className="c-ff w-700 s-1419 text-center"> {translate('homepage.tongkang')}</p>
                    </div> :
                      <div className="mine-inner" onClick={() => navigate('/signin')}>
                        <img
                          alt="Tongkang-icon"
                          src={Tongkangicon}
                        ></img>
                        <p className="c-ff w-700 s-1419 text-center"> {translate('homepage.tongkang')}</p>
                      </div>}
                  </div>
                  <div className="mine-category">
                    {isLogin ? <div className="mine-inner" onClick={() => navigate('/explore', { state: { detail: 'company' } })}>
                      <img
                        alt="Perusahaan-icon"
                        src={Perusahaanicon}

                      ></img>
                      <p className="c-ff w-700 s-1419 text-center"> {translate('homepage.perusahaan')}</p>
                    </div> : <div className="mine-inner" onClick={() => navigate('/signin')}>
                      <img
                        alt="Perusahaan-icon"
                        src={Perusahaanicon}

                      ></img>
                      <p className="c-ff w-700 s-1419 text-center"> {translate('homepage.perusahaan')}</p>
                    </div>}
                  </div>

                </div>
              </div>
            </div>
          </Box>
        </div>
      </div>
      <Box className="flex-wrap-center width-100">
        <div className="content-inner">
          <Grid container justifyContent="center">
            <Box className="in-container width-100" sx={{ mt: 8 }}>
              <p className="s-2838 w-700 c-08 mb-12"> {translate('homepage.title1')}</p>
              <p className="w-500 s-1622 c-56 mb-32"> {translate('homepage.subtitle1')}</p>
              <Grid container direction="row" alignItems="center" justifyContent="space-between">
                <Grid item lg={5} xs={12} sx={{ textAlign: "left" }}>
                  <Stack direction="row" spacing={2}>
                    <Button className="w-500 s-1622"
                      sx={{
                        padding: '8px 12px',
                        background: activeTab === 'coal' ? "#081130" : '',
                        textTransform: 'none',
                        borderRadius: '8px',
                        color: activeTab === 'coal' ? "white" : '#081130',
                        "&:hover": { backgroundColor: "#56617B" }
                      }}
                      onClick={() => {
                        handleTab("coal");
                      }}
                      variant={activeTab === 'coal' ? "contained" : 'text'}
                      endIcon={<Box
                        sx={{
                          background: activeTab === 'coal' ? "white" : '#D1D7E6',
                          borderRadius: 4,
                          padding: '4px',
                          ml: '2px',
                          display: 'flex',
                          alignItems: 'center'

                        }}
                      >
                        <p className="w-500 s-1216"
                          style={{ color: activeTab === 'coal' ? "#081130" : '#56617B' }}>
                          {productList.totalItemBatubara}
                        </p>
                      </Box>}>
                      {translate('homepage.batubara')}
                    </Button>
                    <Divider orientation="vertical" style={{ marginTop: 10, marginBottom: 10, }} flexItem />
                    <Button className="w-500 s-1622"
                      onClick={() => {
                        handleTab("nikel");
                      }}
                      sx={{
                        padding: '8px 12px',
                        background: activeTab === 'nikel' ? "#081130" : '',
                        textTransform: 'none',
                        borderRadius: '8px',
                        color: activeTab === 'nikel' ? "white" : '#081130',
                        "&:hover": { backgroundColor: "#56617B" }
                      }}
                      variant={activeTab === 'nikel' ? "contained" : 'text'}
                      endIcon={<Box
                        sx={{
                          background: activeTab === 'nikel' ? "white" : '#D1D7E6',
                          borderRadius: 4,
                          padding: '4px',
                          ml: '2px',
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <p className="w-500 s-1216" style={{ color: activeTab === 'nikel' ? "#081130" : '#56617B' }}>
                          {totalItemNikel}

                        </p>
                      </Box>}>
                      {translate('homepage.nikel')}
                    </Button>
                  </Stack>
                </Grid>
                <Hidden mdDown>
                  <Grid item lg={5}>
                    <Stack direction="row" spacing={0.5} justifyContent="end" alignItems="center">
                      {activeTab === 'coal' ?
                        <Stack direction="row" spacing={0.5} justifyContent="end">
                          <Typography className="w-500 c-08 s-1622">
                            {filterProduct(productList, 10 * currentPageCoal).length}
                          </Typography>
                          <Typography className="w-500 c-56 s-1622">
                            {translate('homepage.dari')}
                          </Typography>
                          <Typography className="w-500 c-08 s-1622">
                            {productList.totalItemBatubara}
                          </Typography>
                          <Typography className="w-500 c-56 s-1622">
                            {translate('homepage.title1-batubara')}
                          </Typography>
                        </Stack>
                        : ''}
                      {activeTab === 'nikel' ?
                        <Stack direction="row" spacing={0.5} justifyContent="end">
                          <Typography className="w-500" sx={{ fontSize: 16, color: '#081130' }}>
                            {filterNickel(nickelList, 10 * currentPageNickel).length}
                          </Typography>
                          <Typography className="w-500 c-56 s-1622">
                            {translate('homepage.dari')}
                          </Typography>
                          <Typography className="w-500 c-08 s-1622">
                            {totalItemNikel}
                          </Typography>
                          <Typography className="w-500 c-56 s-1622">
                            {translate('homepage.title1-nikel')}
                          </Typography>
                        </Stack>
                        : ''}
                      <Box sx={{ borderRight: 1, height: '40px', borderColor: '#E8EBF2', marginLeft: '30px !important' }} />
                    </Stack>
                  </Grid>
                  <Grid item lg={2} sx={{ textAlign: "right" }}>
                    {activeTab === 'coal' ? <Grid
                      container className="pointer"
                      justifyContent="right"
                      alignItems="center"
                      direction="row"
                    >
                      <Typography
                        className="w-500 c-24 s-1622 mr8"
                        onClick={() => navigate('/penawaran', { state: { detail: 'coal' } })}>
                        {translate('homepage.lihatlebihbanyak')}
                      </Typography>
                      <ArrowForwardIosIcon className="c-24 s-1419" />
                    </Grid> :
                      <Grid
                        container className="pointer"
                        justifyContent="right"
                        alignItems="center"
                        direction="row"
                      >

                        <Typography
                          className="w-500 c-24 s-1622 mr8"
                          onClick={() => navigate('/penawaran', { state: { detail: 'nikel' } })}>
                          {translate('homepage.lihatlebihbanyak')}
                        </Typography>
                        <ArrowForwardIosIcon className="c-24 s-1419" />
                      </Grid>}
                  </Grid>
                </Hidden>
              </Grid>
            </Box>
            <Box
              sx={{ mb: 4 }}
              className="in-container box-listing width-100"
            >
              {activeTab === 'coal' ?
                <Box className="box-table 2"
                  sx={{ mt: 2, mb: { md: 4, sm: 6, xs: 4 } }}
                >
                  <TabelCoal page={'home'} getCoalList={(e) => setFavoriteBatubara()} coalList={filterProduct(productList, 10 * currentPageCoal)} />
                </Box>
                :
                <Box className="box-table 2"
                  sx={{ mt: 2, mb: { md: 4, sm: 6, xs: 4 } }}
                >
                  <TabelNikel page={'home'} nickelList={filterNickel(nickelList, 10 * currentPageNickel)} getNikel={(e) => setFavoriteNikel()} />
                </Box>}
              {activeTab === 'coal' ?
                <Hidden lgUp>
                  <Box className="box-card 1 width-100" sx={{ mb: 4 }}>
                    <CardListCoal page={'home'} getCoalList={(e) => setFavoriteBatubara()} coalList={filterProduct(productList, 5 * currentPageCoalMobile)} />
                    <Grid
                      sx={{ mt: 2 }}
                      container className="pointer"
                      justifyContent="center"
                      alignItems="center"
                      direction="row"
                    >
                      <Typography onClick={(e) => {
                        if (currentPageCoalMobile > 1) {
                          navigate('/penawaran', { state: { detail: 'coal' } })
                        } else {
                          setCurrentPageCoalMobile(currentPageCoalMobile + 1)
                        }
                      }} className="w-500 c-24 mr8 s-1420">
                        {translate('homepage.lihatlebihbanyak')}
                      </Typography>
                      <ArrowForwardIosIcon className="c-24 s-1420 " />
                    </Grid>
                  </Box>
                </Hidden> :
                <Hidden lgUp>
                  <Box className="box-card 2">
                    <CardListNikel page={'home'} getNikel={(e) => setFavoriteNikel()} nickelList={filterNickel(nickelList, 5 * currentPageNickelMobile)} />
                    <Grid
                      sx={{ mt: 2 }}
                      container
                      justifyContent="center"
                      alignItems="center"
                      direction="row"
                    >
                      <Typography onClick={(e) => {
                        if (currentPageNickelMobile > 1) {
                          navigate('/penawaran', { state: { detail: 'nikel' } })
                        } else {
                          setCurrentPageNickelMobile(currentPageNickelMobile + 1)
                        }
                      }}
                        className="w-500 c-24 mr8 s-1420">
                        {translate('homepage.lihatlebihbanyak')}
                      </Typography>
                      <ArrowForwardIosIcon className="c-24 s-1420 " />
                    </Grid>
                  </Box>
                </Hidden>

              }</Box>
          </Grid>
        </div>

        {/* Tongkang Section */}
        <div className="flex-wrap-center width-100 ">
          <div className="box-slide width-100">
            <Box sx={{ background: "#2550A2", pt: { xs: 6, md: 10 }, pb: 10 }}>
              <Grid container direction="row" spacing={2} sx={{ pl: '20px', height: { md: "380px", sm: "600px", xs: "560px" } }}>
                <Grid item lg={3} md={4} xs={12} sx={{ p: '20px' }}>
                  <div className="flex-center width-100 mb100">
                    <Box sx={{ width: { lg: '75%', xs: '100%' }, }}>
                      <p className="w-700 s-2838 c-ff mb-12" >
                        {translate('homepage.title2')}
                      </p>
                      <p className="w-500 s-1622 c-ff">
                        {translate('homepage.subtitle2')}
                      </p>
                      <Grid className="pointer"
                        container onClick={() => navigate('/penawaran', { state: { detail: 'tongkang' } })}
                        sx={{ mt: { xs: 1, md: 4 }, mb: { xs: 1, md: 1 }, }}
                        justifyContent="left"
                        alignItems="center"
                        direction="row"
                      >
                        <Typography
                          className="w-500 s-1622 c-ff">
                          {translate('homepage.lihatlebihbanyak')}
                        </Typography>

                        <ArrowForwardIosIcon className="c-ff s-1420 ml8" />
                      </Grid>
                      <Box sx={{ borderBottom: '1px solid', borderColor: 'white', width: '149px' }}></Box>
                    </Box>
                  </div>
                </Grid>
                <Grid item lg={9} md={8} xs={12}>
                  <Slider {...settingsHomePage}>
                    {tongkangList.map(tongkang => (

                      <CardTongkangList page={'home'} key={tongkang.product_id} data={tongkang} getTongkang={(e) => setFavoriteTongkang()} />

                    ))}
                  </Slider>
                </Grid>
              </Grid>
            </Box>
          </div>

          {/* Company Section */}
          <div className="content-inner">
            <Box className="mine-container-company box-slide flex-wrap-center" style={{ marginTop: 40 }}>
              <Box className="width-100"
                sx={{
                  pb: 2
                }}
              >
                <h4> {translate('homepage.perusahaan')}</h4>
                <Hidden smDown>
                  <div className="flex width-100 space-between">
                    <p className="w-500 s-1622"> {translate('homepage.subtitle3')} </p>
                    {(companyList.length > parseInt(currentPageCompany * 6)) &&

                      <Grid xs={4}
                        item className="pointer "
                        justifyContent="right"
                        alignItems="center"

                      >
                        <Stack direction={'row'} alignItems="center">
                          <Typography
                            className="w-500 c-24 mr8 s-1622"
                            onClick={() => navigate('/explore', { state: { detail: 'company' } })}

                          >
                            {translate('homepage.lihatlebihbanyak')}
                          </Typography>
                          <ArrowForwardIosIcon className="c-24 s-1420 " onClick={(e) => setCurrentPageCompany(currentPageCompany + 1)} />
                        </Stack>
                      </Grid>
                    }
                  </div>
                </Hidden>
              </Box>

              <div className="box-slide width-100">
                <Slider {...settingsCompanyHomePage}>
                  {companyList.map((company, i) => {
                    if (i < parseInt(currentPageCompany * 6)) {
                      return (
                        <Box key={company.company_id} sx={{ mr: '4px', }}>
                          <CardCompanyHomepage page={'home'} data={company} />
                        </Box>
                      )
                    }
                  }
                  )}
                </Slider>
              </div>

              <Hidden smUp>
                {(companyList.length > parseInt(currentPageCompany * 6)) &&
                  <Grid
                    container className="pointer "
                    justifyContent="center"
                    alignItems="center"
                    direction="row"
                  >
                    <Typography
                      className="w-500 c-24 s-1622 mr8 "
                      onClick={() => navigate('/explore', { state: { detail: 'company' } })}>
                      {translate('homepage.lihatlebihbanyak')}
                    </Typography>
                    <ArrowForwardIosIcon className="c-24 s-1420 " onClick={(e) => setCurrentPageCompany(currentPageCompany + 1)} />
                  </Grid>}
              </Hidden>
            </Box>
          </div>


          <div className="width-100">
            <Footer></Footer>
          </div>
        </div>
      </Box >

    </div >
  )
}

export default Homepage
