import { useEffect } from "react"
export default function NonLoginRedirect() {
    useEffect(() => {
        window.location.href = '/signin'
        localStorage.clear()
    }, [])
    return (
        <></>
    )
}
