import Divider from "@material-ui/core/Divider";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { Avatar, Box, Button, Grid, Hidden, IconButton, Stack, Typography } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import locationCompanyIcon from "../assets/location-company-ic.png";
import EmptyStaffIcon from "../assets/staff-empty-ic.svg";
import { config } from '../services/constants';
import ModalStaffOption from "./ModalStaffOption";
export default function TableStaff(props) {
  let { data, id_company, name_company } = props;
  const [translate,] = useTranslation("global");
  const [listStaff, setListStaff] = useState(data);
  const [anchorElShare, setAnchorElShare] = useState(null);
  const [userId, setuserId] = useState(null);
  const [datatrader, setdatatrader] = useState(null);

  const handleOpenShareMenu = (event, user_id, data_trader) => {
    setuserId(user_id)
    setdatatrader(data_trader)
    setAnchorElShare(event.currentTarget);
  };

  const handleCloseShareMenu = () => {
    setAnchorElShare(null);
  };

  const columns = [
    {
      headerClassName: 'super-app-theme--header', field: 'name', headerName: translate('status.namastaff'), width: 280, renderCell: (params) => (
        <Grid sx={{ display: 'flex', cursor: 'pointer' }} onClick={(e) => {
          window.location.href = `/detailtrader?type=5&id_detail=${params.row.user_id}`
        }} justifyContent={'center'} alignItems={'center'}>
          {params.row?.user_image == null ?
            <Avatar onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${params.row.user_id}` }} sx={{ bgcolor: 'blue', mr: 1, height: 32, width: 32, cursor: 'pointer' }} aria-label="recipe">
            </Avatar> : <img onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${params.row.user_id}` }} style={{ borderRadius: '50%', marginRight: 6, cursor: 'pointer' }} alt="Nikel-icon" src={config.baseURL + params.row.user_image} height={32} width={32}></img>}
          <Stack direction="column" >
            <Typography
              className="w-700"
              sx={{ fontSize: 14, ml: 1, display: 'inline', wordBreak: "break-all", whiteSpace: 'normal' }}
            >
              {params.row.name}
            </Typography>

            <Typography
              className="w-500"

              sx={{ fontSize: 14, ml: 1, color: '#56617B', display: 'inline', wordBreak: "break-all", whiteSpace: 'normal' }}
            >
              {params.row.kota} {params.row.kota && params.row.provinsi !== null ? ',' : ''}  {params.row.provinsi}
            </Typography>
          </Stack>
        </Grid >
      ),
    },
    {
      headerClassName: 'super-app-theme--header', field: 'total_batubara', headerName: translate('status.jumlahlistingbatubara'), width: 160, renderCell: (params) => (
        <Grid
          container
          justifyContent="left"
          alignItems="center"
          direction="row"
          sx={{ cursor: 'pointer' }}
          onClick={(e) => {
            window.location.href = `/detailtrader?type=5&id_detail=${params.row.user_id}`
          }}

        >
          <Typography
            className="w-700"
            sx={{ fontSize: 14, ml: 1, }}
          >
            {params.row.total_batubara}
          </Typography>
        </Grid>
      ),
    },
    {
      headerClassName: 'super-app-theme--header', field: 'total_nikel', headerName: translate('status.jumlahlistingnikel'), width: 160, renderCell: (params) => (
        <Grid
          container
          justifyContent="left"
          alignItems="center"
          direction="row"
          sx={{ cursor: 'pointer' }}
          onClick={(e) => {
            window.location.href = `/detailtrader?type=5&id_detail=${params.row.user_id}`
          }}
        >
          <Typography
            className="w-700"
            sx={{ fontSize: 14, }}
          >
            {params.row.total_nikel}
          </Typography>
        </Grid>
      ),
    },
    {
      headerClassName: 'super-app-theme--header', field: 'total_tongkang', headerName: translate('status.jumlahlistingtongkang'), width: 190, renderCell: (params) => (
        <Grid
          container
          justifyContent="left"
          alignItems="center"
          direction="row"
          sx={{ cursor: 'pointer' }}
          onClick={(e) => {
            window.location.href = `/detailtrader?type=5&id_detail=${params.row.user_id}`
          }}
        >
          <Typography
            className="w-700"
            sx={{ fontSize: 14, }}
          >
            {params.row.total_tongkang}
          </Typography>
        </Grid>
      ),
    },
    {
      field: 'action', headerName: ' ', sortable: false, width: 230, renderCell: (params) =>
      (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          direction="row"
          sx={{ cursor: 'pointer' }}
        // onClick={(e) => {
        //   window.location.href = `/detailtrader?type=5&id_detail=${params.row.user_id}`
        // }}
        >
          <Box
            sx={{
              background: params.row.status_company_staff === 'Active' ? 'var(--tint-green, rgba(59, 181, 108, 0.25));' : 'var(--tint-yellow, rgba(247, 206, 70, 0.30));',
              borderRadius: '300px',
              p: '2px 12px',
              height: '24px'

            }}
          >
            <Stack direction={'row'} alignItems={'center'}>

              {params.row.status_company_staff === 'Active' ? (
                <>
                  <Box sx={{ borderRadius: '50%', background: '#3BB56C', width: '10px', height: '10px', mr: 1 }}>
                  </Box>
                  <Typography className="w-500" sx={{ color: '#081130', fontSize: 14 }} >
                    {translate('status.active')}
                  </Typography>
                </>

              ) : (
                <></>
              )}

              {params.row.status_company_staff === 'Pending' ? (
                <>
                  <Box sx={{ borderRadius: '50%', background: '#F7CE46', width: '10px', height: '10px', mr: 1 }}>
                  </Box>
                  <Typography className="w-500" sx={{ color: '#081130', fontSize: 14 }} >
                    {translate('status.pending')}
                  </Typography>
                </>
              ) : (
                <></>
              )}
            </Stack>
          </Box>
          <Stack direction={'row'}>
            {props.isOwnerCompany && <IconButton size="small">
              <MoreHorizIcon onClick={(e) => handleOpenShareMenu(e, params.row.user_id, params.row)} sx={{ fontSize: 18, cursor: 'pointer' }} />
              <ModalStaffOption id_company={id_company} name_company={name_company} datatrader={datatrader} userId={userId} anchorEl={anchorElShare} open={anchorElShare} onClose={handleCloseShareMenu}></ModalStaffOption>

            </IconButton>}
            <IconButton size="small" onClick={(e) => {
              window.location.href = `/detailtrader?type=5&id_detail=${params.row.user_id}`
            }}>
              <ArrowForwardIosIcon sx={{ fontSize: 18 }} />
            </IconButton>
          </Stack>
        </Grid>
      ),

    },
  ];

  const EmptyStateDefault = () => (
    <div style={{ height: '336px', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
      <Stack justifyContent={'center'} >
        <div style={{ display: 'flex', justifyContent: 'center' }}>  <img style={{ height: '120px', width: '120px' }} src={EmptyStaffIcon} alt="no-item" /></div>

        <Typography
          className="w-700"
          sx={{ fontSize: 18, color: '#424242', lineHeight: 'normal', textAlign: 'center' }}
        >
          {translate('status.staffempty')}
        </Typography>
        {props.isOwnerCompany && <Box><Typography
          className="w-500"
          sx={{ fontSize: 14, lineHeight: 'normal', color: 'rgba(66, 66, 66, 0.80)', textAlign: 'center' }}
        >
          {translate('status.substaffempty')}
        </Typography>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={(e) => {
                window.location.href = `/invite-staff?id_company=${id_company}`
              }}
              fullWidth
              variant="outlined"
              sx={{
                width: '166px', height: '40px',
                mt: 3, mb: 2, textTransform: "none", border: '1px solid var(--primarry-blue, #2457B9)',
                p: '12px 16px',
                borderRadius: '8px',
                color: '#2457B9'
              }}
            >
              <Typography
                className="w-500"
                sx={{ fontSize: 14, color: '#2457B9' }}
              >
                Invite Staff

              </Typography>
            </Button>
          </div> </Box>}
      </Stack>

    </div>

  );

  useEffect(() => {
    setListStaff(data)
  }, [data])

  return (
    <div>
      <Hidden smDown>
        <div style={{ height: listStaff?.length === 0 ? '400px' : '100%', }} className="ex-ui">
          <DataGrid
            sx={{
              borderRadius: '8px',
              '& .super-app-theme--header': {
                borderRight: 1,
                borderColor: '#E8EBF2',
                fontFamily: 'Satoshi-500 !important',
                color: '#081130 !important'
              },
              '.MuiDataGrid-iconButtonContainer': {
                visibility: 'visible',
              },
              '.MuiDataGrid-sortIcon': {
                opacity: 'inherit !important',
              },
              '.MuiDataGrid-columnSeparator': {
                display: 'none',
              },
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              "& .MuiDataGrid-renderingZone": {
                maxHeight: "none !important"
              },
              "& .MuiDataGrid-cell": {
                lineHeight: "unset !important",
                maxHeight: "none !important",
                whiteSpace: "normal"
              },
              "& .MuiDataGrid-row": {
                maxHeight: "none !important"
              }
            }}
            rows={listStaff}
            sortingOrder={['desc', 'asc']}
            disableColumnMenu
            disableSelectionOnClick={true}
            disableRowSelectionOnClick
            components={{
              NoRowsOverlay: EmptyStateDefault,
              ColumnSortedAscendingIcon: UnfoldMoreIcon,
              ColumnSortedDescendingIcon: UnfoldMoreIcon
            }}
            getRowId={(row) => row.user_id}
            columns={columns}
          />
        </div>

      </Hidden>
      <Hidden smUp>
        {listStaff?.length !== 0 ? <div>
          {listStaff.map((item) => (
            <Box key={item.id} sx={{ borderRadius: '8px', border: '1px solid #F4F6FA', p: 2, mb: 2 }}>
              <Grid container direction={'row'} spacing={1} justifyContent={'space-between'}>
                <Grid item xs={7.5} >
                  <Stack direction="row" >
                    {item.user_image == null ? <Avatar sx={{ bgcolor: 'blue', height: 40, width: 40 }} aria-label="recipe">
                    </Avatar> : <img style={{ borderRadius: '50%' }} alt="Nikel-icon" src={config.baseURL + item.user_image} height={40} width={40}></img>}
                    <Stack direction="column" sx={{ ml: 1 }}>
                      {item?.name && <Typography
                        className="w-700"
                        sx={{ fontSize: 14, color: '#081130' }}
                      >

                        {item && item?.name?.length > 20 ?
                          item?.name.slice(0, 20) + '...'
                          : item?.name}
                      </Typography>}
                      <Stack direction={'row'} sx={{ mt: '2px' }}>
                        <img alt="Nikel-icon" src={locationCompanyIcon} height={18} width={18}></img>
                        <Typography
                          className="w-500"
                          sx={{ fontSize: 14, color: '#56617B' }}
                        >
                          {item.kota}{item.kota && item.provinsi !== null ? ',' : ''}  {item.provinsi}
                        </Typography>
                      </Stack>

                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={4.5}>
                  <Stack direction={'row'}>
                    <Box
                      sx={{
                        background: item.status_company_staff === 'Active' ? 'var(--tint-green, rgba(59, 181, 108, 0.25));' : 'var(--tint-yellow, rgba(247, 206, 70, 0.30));',
                        borderRadius: '300px',
                        p: '2px 12px',
                        height: '24px'

                      }}
                    >
                      <Stack direction={'row'} alignItems={'center'}>

                        {item.status_company_staff === 'Active' ? (
                          <>
                            <Box sx={{ borderRadius: '50%', background: '#3BB56C', width: '10px', height: '10px', mr: 1 }}>
                            </Box>
                            <Typography className="w-500" sx={{ color: '#081130', fontSize: 14 }} >
                              {translate('status.active')}
                            </Typography>
                          </>

                        ) : (
                          <></>
                        )}
                        <Hidden smDown>
                          {item.status_company_staff === 'Pending' ? (
                            <>
                              <Box sx={{ borderRadius: '50%', background: '#F7CE46', width: '10px', height: '10px', mr: 1 }}>
                              </Box>
                              <Typography className="w-500" sx={{ color: '#081130', fontSize: 14 }} >
                                {translate('status.pending')}
                              </Typography>
                            </>
                          ) : (
                            <></>
                          )}
                        </Hidden>
                        <Hidden smUp>
                          {item.status_company_staff === 'Pending' ? (
                            <>
                              <Box sx={{ borderRadius: '50%', background: '#F7CE46', width: '10px', height: '10px', mr: 1 }}>
                              </Box>
                              <Typography className="w-500" sx={{ color: '#081130', fontSize: 14 }} >
                                {translate('status.pending-card')}
                              </Typography>
                            </>
                          ) : (
                            <></>
                          )}
                        </Hidden>
                      </Stack>
                    </Box>
                    {props.isOwnerCompany && <IconButton size="small">
                      <MoreHorizIcon onClick={(e) => handleOpenShareMenu(e, item.user_id, item)} sx={{ fontSize: 16, cursor: 'pointer', mr: '8px' }} />
                      <ModalStaffOption id_company={id_company} datatrader={datatrader} userId={userId} anchorEl={anchorElShare} open={anchorElShare} onClose={handleCloseShareMenu}></ModalStaffOption>

                    </IconButton>}
                  </Stack>

                </Grid>
              </Grid>
              <Grid container direction={'row'} sx={{ mt: '12px' }}>
                <Grid item xs>

                  <Typography
                    className="w-500"
                    sx={{ fontSize: 12, ml: 1, color: '#56617B' }}
                  >
                    {translate('detail-explore.listingbatubara')}
                  </Typography>
                  <Typography
                    className="w-700"
                    sx={{ fontSize: 14, ml: 1, color: '#081130' }}
                  >
                    {item.total_batubara}
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Typography
                    className="w-500"
                    sx={{ fontSize: 12, ml: 1, color: '#56617B' }}
                  >
                    {translate('detail-explore.listingnikel')}
                  </Typography>
                  <Typography
                    className="w-700"
                    sx={{ fontSize: 14, ml: 1, color: '#081130' }}
                  >
                    {item.total_nikel}
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Typography
                    className="w-500"
                    sx={{ fontSize: 12, ml: 1, color: '#56617B' }}
                  >
                    {translate('detail-explore.listingtongkang')}
                  </Typography>
                  <Typography
                    className="w-700"
                    sx={{ fontSize: 14, ml: 1, color: '#081130' }}
                  >
                    {item.total_tongkang}
                  </Typography>
                </Grid>
              </Grid>
              <Divider style={{ backgroundColor: "#E8EBF2", width: '100%', marginTop: '24px', marginBottom: '16px' }} />
              <Grid onClick={(e) => {
                window.location.href = `/detailtrader?type=5&id_detail=${item.user_id}`
              }} sx={{ mt: 2, }} className="pointer" container justifyContent="center" alignItems="center" direction="row">
                <Typography className="w-700" sx={{ fontSize: 14, color: "#2457B9", mr: 1 }}>
                  {translate('page_explore.lihatdetail')}
                </Typography>
                <ArrowForwardIosIcon sx={{ color: "#2457B9", height: '18px', width: '18px' }} />
              </Grid>
            </Box>
          ))}
        </div> : <div style={{ height: '336px', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
          <Stack justifyContent={'center'} >
            <div style={{ display: 'flex', justifyContent: 'center' }}>  <img style={{ height: '120px', width: '120px' }} src={EmptyStaffIcon} alt="no-item" /></div>

            <Typography
              className="w-700"
              sx={{ fontSize: 18, color: '#424242', lineHeight: 'normal', textAlign: 'center' }}
            >
              {translate('status.staffempty')}
            </Typography>
            {props.isOwnerCompany && <Box><Typography
              className="w-500"
              sx={{ fontSize: 14, lineHeight: 'normal', color: 'rgba(66, 66, 66, 0.80)', textAlign: 'center' }}
            >
              {translate('status.substaffempty')}
            </Typography>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  onClick={(e) => {
                    window.location.href = `/invite-staff?id_company=${id_company}`
                  }}
                  fullWidth
                  variant="outlined"
                  sx={{
                    width: '166px', height: '40px',
                    mt: 3, mb: 2, textTransform: "none", border: '1px solid var(--primarry-blue, #2457B9)',
                    p: '12px 16px',
                    borderRadius: '8px',
                    color: '#2457B9'
                  }}
                >
                  <Typography
                    className="w-500"
                    sx={{ fontSize: 14, color: '#2457B9' }}
                  >
                    Invite Staff

                  </Typography>
                </Button>
              </div> </Box>}
          </Stack>

        </div>}

      </Hidden>
    </div>
  )
}

TableStaff.propTypes = {
  data: PropTypes.any,
};
