import { Grid } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { Box, Button, CircularProgress, Collapse, Hidden, Stack, TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import likeIcon from "../assets/like.png";
import profileDefault from "../assets/profile-default.jpg";
import ProfileImg from "../assets/profile-img.png";
import sendIcon from "../assets/send-ic.png";
import { addComment, addLikeComment, addReply, deleteLikeComment, getComment, getReply } from "../services/api";
import { config } from "../services/constants";

function KomentarComponent(props) {
  let { comment_id, name, value, image, total, created_at, is_like, id_like } = props;
  const [translate] = useTranslation("global");
  const [open, setOpen] = useState(false);
  const [totalLike, setTotalLike] = useState(total);
  const [isLike, setIsLike] = useState(is_like);
  const [idLike, setIdLike] = useState(id_like);
  const [pageValue, setPageValue] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [dataReply, setDataReply] = useState([])
  const [valueReply, setValueReply] = useState('')
  const [isLoadingReply, setIsLoadingReply] = useState(false);
  const [isLoadingReplyMore, setIsLoadingReplyMore] = useState(false);

  const getDataReply = async (page) => {
    try {
      setPageValue(page)
      if (page === 1) {
        setIsLoadingReply(true)
        let params = {
          page: 1,
          id_comment: comment_id
        }
        const responAPI = await getReply(params)

        setTotalPage(responAPI.data.data.pageCount)
        let dataArray = responAPI.data.data.data
        if (dataArray.length !== 0) {
          dataArray = dataArray.reverse()
        }
        setDataReply(dataArray)
        setIsLoadingReply(false)
      } else {
        setIsLoadingReplyMore(true)
        let params = {
          page: page,
          id_comment: comment_id
        }
        const responAPI = await getReply(params)
        setTotalPage(responAPI.data.data.pageCount)
        let dataArray = responAPI.data.data.data
        if (dataArray.length !== 0) {
          dataArray = dataArray.reverse()
        }
        let oldArray = dataReply
        let newArray = dataArray.concat(oldArray)
        setDataReply(newArray)
        setIsLoadingReplyMore(false)
      }
    } catch (error) {

    }
  }

  const sendReply = async () => {
    try {

      let dataForm = {
        value: valueReply,
        comment_id: comment_id,
      }
      let respone = await addReply(dataForm)

      if (respone.status === 200) {
        await getDataReply(1)
      }

      if (respone.status === 403) {
        window.location.href = "/signin"
      }
      setValueReply('')
    } catch (error) {

    }
  }

  const likeComment = async () => {
    try {
      if (isLike) {
        let params = {
          id: idLike
        }
        let respone = await deleteLikeComment(params)

        if (respone.status === 200) {
          setTotalLike(Number(totalLike) - 1)
          setIsLike(!isLike)
        }
      } else {
        let dataForm = {
          comment_id: comment_id,
        }
        let respone = await addLikeComment(dataForm)

        if (respone.status === 200) {
          setTotalLike(Number(totalLike) + 1)
          setIdLike(respone.data.data.id)
          setIsLike(!isLike)
        } else {
          if (respone.status === 403) {
            window.location.href = "/signin"
          }
        }
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    getDataReply(1)
  }, [])

  return (
    <>
      <div>
        <Stack
          className="tesssfdsfds"
          sx={{ mt: '24px' }}
          direction="row"
          alignItems="center"
          justifyContent="left"
        >
          <img style={{ borderRadius: '50%' }} src={image !== null ? `${config.baseURL + image}` : profileDefault} height={44} width={44}></img>

          <Stack sx={{ textAlign: "left", ml: '12px' }} >
            <Typography
              sx={{ fontSize: '16px', color: '#081130' }}
              className="w-700"
            >
              {name}
            </Typography>
            <Stack direction={'row'} alignItems={'center'}>
              <Typography
                sx={{ fontSize: '14px', color: '#56617B' }}
                className="w-500"
              >
                {created_at?.date === undefined ? created_at : created_at?.date}
              </Typography>
              {created_at?.time === undefined ? (
                <></>
              ) : (
                <>
                  <Box width={'4px'} height={'4px'} sx={{ background: '#56617B', borderRadius: '50%', ml: 1, mr: 1 }}>

                  </Box>
                  <Typography
                    sx={{ fontSize: '14px', color: '#56617B' }}
                    className="w-500"
                  >
                    {created_at?.time}
                  </Typography>
                </>
              )}
            </Stack>
          </Stack>
        </Stack>
        <Typography
          sx={{ fontSize: '14px', color: '#081130' }}
          className="w-500"
        >
          {value}
        </Typography>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ mt: '12px' }}>
          <Stack className="pointer" onClick={() => { likeComment() }} direction={'row'} alignItems={'center'} sx={{ mt: '12px' }}>
            {isLike === true ? (
              <ThumbUpIcon sx={{ fontSize: 20, color: "#2457B9" }} />
            ) : (
              <img src={likeIcon} height={20} width={20}></img>
            )}
            <Typography
              sx={{ fontSize: '14px', color: '#56617B', ml: 1 }}
              className="w-500"
            >
              {`${totalLike !== undefined ? totalLike : 0} suka`}
            </Typography>
          </Stack>
          <Stack className="pointer" onClick={() => setOpen(!open)} direction={'row'} alignItems={'center'} sx={{ mt: '12px' }}>
            {open ? <Typography
              sx={{ fontSize: '14px', color: '#56617B', ml: 1 }}
              className="w-500"
            >
              {translate('detail-explore.tutupbalasan')}
            </Typography> : <Typography
              sx={{ fontSize: '14px', color: '#56617B', ml: 1 }}
              className="w-500"
            >
              {translate('detail-explore.lihatbalasan')}
            </Typography>}
            {open ? <KeyboardArrowUpIcon sx={{ color: '#56617B' }} />
              : <KeyboardArrowDownIcon sx={{ color: '#56617B' }} />}
          </Stack>
        </Stack>
      </div>
      <Collapse in={open} timeout="auto"
        unmountOnExit>
        <div style={{ marginLeft: '20px' }}>
          {isLoadingReply ? (
            <div style={{ paddingTop: "40px", textAlign: "center" }}>
              <CircularProgress
                sx={{
                  color: (theme) =>
                    theme.palette.grey[theme.palette.mode === 'dark' ? 200 : 800],
                }}
                size={30}
                thickness={4}
                value={100}
              />
            </div>
          ) : (
            <>
              {pageValue >= totalPage ? (
                <></>
              ) : (
                <>
                  {isLoadingReplyMore ? (
                    <div style={{ paddingTop: "40px", textAlign: "center" }}>
                      <CircularProgress
                        sx={{
                          color: (theme) =>
                            theme.palette.grey[theme.palette.mode === 'dark' ? 200 : 800],
                        }}
                        size={30}
                        thickness={4}
                        value={100}
                      />
                    </div>

                  ) : (
                    <Grid sx={{ mt: 2, mb: 8 }} container justifyContent="center" alignItems="center" className="pointer" direction="row"
                      onClick={(e) => getDataReply(pageValue + 1)}
                    >
                      <Typography className="w-500" sx={{ fontSize: 16, color: "#2457B9", mr: 1 }}>
                        {translate('page_explore.lihatlebihbanyak')}
                      </Typography>
                      <KeyboardArrowUpIcon sx={{ fontSize: 16, color: "#2457B9" }} />
                    </Grid>
                  )}
                </>
              )}
              {dataReply.map((item, i) => (
                <>
                  <Stack
                    sx={{ mt: '24px' }}
                    direction="row"
                    alignItems="center"
                    justifyContent="left"

                  >

                    <img style={{ borderRadius: '50%' }} src={item?.user_url_image !== null ? `${config.baseURL + item?.user_url_image}` : profileDefault} height={44} width={44}></img>

                    <Stack sx={{ textAlign: "left", ml: '12px' }} >
                      <Typography
                        sx={{ fontSize: '16px', color: '#081130' }}
                        className="w-700"
                      >
                        {item?.user_name}
                      </Typography>
                      <Stack direction={'row'} alignItems={'center'}>
                        <Typography
                          sx={{ fontSize: '14px', color: '#56617B' }}
                          className="w-500"
                        >
                          {item?.created_at?.date === undefined ? item?.created_at : item?.created_at?.date}
                        </Typography>
                        {item?.created_at?.time === undefined ? (
                          <></>
                        ) : (
                          <>
                            <Box width={'4px'} height={'4px'} sx={{ background: '#56617B', borderRadius: '50%', ml: 1, mr: 1 }}>

                            </Box>
                            <Typography
                              sx={{ fontSize: '14px', color: '#56617B' }}
                              className="w-500"
                            >
                              {item?.created_at?.time}
                            </Typography>
                          </>
                        )}

                      </Stack>

                    </Stack>

                  </Stack>
                  <Typography
                    sx={{ fontSize: '14px', color: '#081130' }}
                    className="w-500"
                  >
                    {item?.value}
                  </Typography>
                </>
              ))}
            </>
          )}

          <Stack direction="row" justifyContent={'end'} spacing={2} sx={{ mt: 1 }}>
            <TextField

              id="outlined-multiline-static"
              sx={{
                width: '100%', input: {
                  fontSize: '14px', pt: '12px', pb: '12px', fontFamily: 'Satoshi-500'

                },

              }}
              InputProps={{ disableUnderline: true }}
              placeholder={translate('detail-explore.balasananda')}
              size="small"
              variant="outlined"
              value={valueReply}
              onChange={(e) => {
                setValueReply(e.target.value);
              }}
            />
            <Button
              // href="/"
              onClick={() => {
                sendReply();
              }}
              size="small"
              className="w-500"
              variant="contained"
              style={{
                background: 'linear-gradient(270deg, #2457B9 15%, #2860CD 100%)',
                borderRadius: '8px'

              }}
            >
              {isLoadingReply ? (
                <CircularProgress
                  sx={{
                    color: (theme) =>
                      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                  }}
                  size={20}
                  thickness={4}
                  value={100}
                />
              ) : (<img src={sendIcon} height={20} width={20}></img>)}
            </Button>
          </Stack>


        </div>
      </Collapse>
      <Divider style={{ backgroundColor: "#E8EBF2", width: '100%', marginTop: '24px', marginBottom: '24px' }} />
    </>
  )
}

KomentarComponent.propTypes = {
  comment_id: PropTypes.number,
  name: PropTypes.any,
  value: PropTypes.any,
  image: PropTypes.any,
  total: PropTypes.any,
  created_at: PropTypes.any,
  is_like: PropTypes.any,
  id_like: PropTypes.any,
};
function Komentar(props) {
  let { id_product, type_product } = props;
  const [translate,] = useTranslation("global");
  const [valueComment, setValueComment] = useState('')
  const [isLoadingComment, setIsLoadingComment] = useState(false)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [pageValue, setPageValue] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [totalComment, setTotalComment] = useState(0)
  const [dataComment, setDataComment] = useState([])

  const getDataComment = async (page) => {
    try {
      setPageValue(page)
      if (page === 1) {
        setIsLoadingComment(true)
        let params = {
          page: 1,
          id_product: id_product,
          type_product: type_product
        }
        const responAPI = await getComment(params)

        setTotalComment(responAPI.data.data.itemCount)
        setTotalPage(responAPI.data.data.pageCount)
        let dataArray = responAPI.data.data.data
        setDataComment(dataArray)
        setIsLoadingComment(false)
      } else {
        setIsLoadingMore(true)
        let params = {
          page: page,
          id_product: id_product,
          type_product: type_product
        }
        const responAPI = await getComment(params)
        setTotalComment(responAPI.data.data.itemCount)
        setTotalPage(responAPI.data.data.pageCount)
        let dataArray = responAPI.data.data.data
        let oldArray = dataComment
        let newArray = oldArray.concat(dataArray)
        setDataComment(newArray)
        setIsLoadingMore(false)
      }
    } catch (error) {
    }
  }

  const sendComment = async () => {
    try {

      let dataForm = {
        value: valueComment,
        type_product: type_product,
        id_product: id_product,
      }
      let respone = await addComment(dataForm)

      if (respone.status === 200) {
        await getDataComment(1)
      }

      if (respone.status === 403) {
        window.location.href = "/signin"
      }
      setValueComment('')
    } catch (error) {

    }
  }

  useEffect(() => {
    getDataComment(1)
  }, [])

  return (
    <>
      <Button
        className={'w-700'}

        sx={{
          background: '', textTransform: 'none',
          color: '#081130', fontSize: 18, "&:hover": {
            // backgroundColor: "#56617B"
          }
        }}
        variant={'text'}
        endIcon={<Box
          sx={{
            background: '#D1D7E6',
            borderRadius: 1,
            pl: 1,
            pr: 1,
            ml: 1,
          }}
        >
          <Typography
            className="w-500"
            sx={{ color: '#56617B', fontSize: 14 }}
          >
            {totalComment}
          </Typography>
        </Box>}>
        {translate('detail-explore.diskusitrader')}
      </Button>

      <Box >
        <Box sx={{ height: '130px', border: '1px solid #E8EBF2', p: '16px', borderRadius: '8px' }}>
          <TextField
            className="box-text-komentar"
            sx={{
              width: '100%', input: {
                fontSize: '14px', fontFamily: 'Satoshi-500'
              },
            }}
            id="outlined-multiline-static"
            InputProps={{ disableUnderline: true }}
            placeholder={translate('detail-explore.tuliskomentaranda')}
            value={valueComment}
            multiline
            rows={4}
            variant="standard"
            onChange={(e) => {
              setValueComment(e.target.value);
            }}
          />
          <Stack direction="row" justifyContent={'end'}>
            <Hidden smUp>
              <Button
                onClick={() => {
                  sendComment();
                }}
                size="small"
                className="w-500"

                variant="contained"
                style={{
                  width: '44px',
                  height: '44px',
                  background: 'linear-gradient(270deg, #2457B9 15%, #2860CD 100%)',
                  padding: '12px 12px',
                  borderRadius: '8px',
                }}
              >
                {isLoadingComment ? (<CircularProgress
                  sx={{
                    color: (theme) =>
                      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                  }}
                  size={20}
                  thickness={4}
                  value={100}
                />) : (<img src={sendIcon} height={20} width={20}></img>)}

              </Button>
            </Hidden>
            <Hidden smDown>
              <Button
                onClick={() => {
                  sendComment();
                }}
                size="small"
                className="w-500"

                variant="contained"
                style={{
                  background: 'linear-gradient(270deg, #2457B9 15%, #2860CD 100%)',
                  padding: '10px 12px',
                  borderRadius: '8px',
                  width: '100px',
                  textTransform: "none",
                }}
              >
                {isLoadingComment ? (<CircularProgress
                  sx={{
                    color: (theme) =>
                      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                  }}
                  size={20}
                  thickness={4}
                  value={100}
                />) : <>{translate('detail-explore.kirim')}</>}

              </Button>
            </Hidden>
          </Stack>
        </Box>

        {isLoadingComment ? (
          <div>
            <div style={{ paddingTop: "40px", textAlign: "center" }}>
              <CircularProgress
                sx={{
                  color: (theme) =>
                    theme.palette.grey[theme.palette.mode === 'dark' ? 200 : 800],
                }}
                size={30}
                thickness={4}
                value={100}
              />
            </div>

          </div>
        ) : (
          <>
            {
              dataComment.map((item, i) => (
                <div key={item?.comment_id}>
                  <KomentarComponent comment_id={item?.comment_id} image={item?.user_image} name={item?.user_name} value={item?.value} total={item?.total_likes} created_at={item?.created_at} is_like={item?.is_like === undefined ? false : item?.is_like} id_like={item?.id_like === undefined ? null : item?.id_like} />
                </div>
              ))
            }
            {pageValue >= totalPage ? (
              <></>
            ) : (
              <div className="test-comment">
                {isLoadingMore ? (
                  <div style={{ paddingTop: "40px", textAlign: "center" }}>
                    <CircularProgress
                      sx={{
                        color: (theme) =>
                          theme.palette.grey[theme.palette.mode === 'dark' ? 200 : 800],
                      }}
                      size={30}
                      thickness={4}
                      value={100}
                    />
                  </div>
                ) : (
                  <Grid sx={{ mt: 2, mb: 8 }} className='pointer' container justifyContent="center" alignItems="center" direction="row"
                    onClick={(e) => getDataComment(pageValue + 1)}
                  >
                    <Typography className="w-500" sx={{ fontSize: 16, color: "#2457B9", mr: 1 }}>
                      {translate('page_explore.lihatlebihbanyak')}
                    </Typography>
                    <KeyboardArrowDownIcon sx={{ fontSize: 16, color: "#2457B9" }} />
                  </Grid>)}
              </div>
            )}
          </>
        )}
      </Box>
    </>
  )
}

Komentar.propTypes = {
  id_product: PropTypes.number,
  type_product: PropTypes.number,
};

function KomentarSection() {
  const [translate,] = useTranslation("global");
  return (
    <Box >
      <Box sx={{ height: '130px', border: '1px solid #E8EBF2', p: '16px', borderRadius: '8px' }}>
        <TextField
          className="box-text-komentar"
          sx={{
            width: '100%', input: {
              fontSize: '14px', fontFamily: 'Satoshi-500'
            },
          }}
          id="outlined-multiline-static"
          InputProps={{ disableUnderline: true }}
          placeholder={translate('detail-explore.tuliskomentaranda')}
          multiline
          rows={4}
          variant="standard"
        />
        <Stack direction="row" justifyContent={'end'}>
          <Button
            href="/"
            size="small"
            className="w-500"
            variant="contained"
            style={{
              background: 'linear-gradient(270deg, #2457B9 15%, #2860CD 100%)',
              paddingLeft: '33px',
              paddingRight: '33px',

              textTransform: "none",
            }}
          >
            {translate('detail-explore.kirim')}
          </Button>
        </Stack>

      </Box>
      <div>
        <Stack
          sx={{ mt: '24px' }}
          direction="row"
          alignItems="center"
          justifyContent="left"

        >

          <img style={{ borderRadius: '50%' }} src={ProfileImg} height={44} width={44}></img>

          <Stack sx={{ textAlign: "left", ml: '12px' }} >
            <Typography
              sx={{ fontSize: '16px', color: '#081130' }}
              className="w-700"
            >
              Aril Noah
            </Typography>
            <Stack direction={'row'} alignItems={'center'}>
              <Typography
                sx={{ fontSize: '14px', color: '#56617B' }}
                className="w-500"
              >
                07 Desember
              </Typography>
              <Box width={'4px'} height={'4px'} sx={{ background: '#56617B', borderRadius: '50%', ml: 1, mr: 1 }}>

              </Box>
              <Typography
                sx={{ fontSize: '14px', color: '#56617B' }}
                className="w-500"
              >
                08:12
              </Typography>
            </Stack>

          </Stack>

        </Stack>
        <Typography
          sx={{ fontSize: '14px', color: '#081130' }}
          className="w-500"
        >
          A magna luctus libero ultricies velit tellus. apien et faucibus accumsan suspendisse eros. alesuada arcu tincidunt neque vel in amet neque tortor nisl. olutpat pharetra nibh elementum et nunc tortor quam. In sit viverra pulvinar tellus etiam
        </Typography>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ mt: '12px' }}>
          <Stack direction={'row'} alignItems={'center'} sx={{ mt: '12px' }}>
            <img src={likeIcon} height={20} width={20}></img>
            <Typography
              sx={{ fontSize: '14px', color: '#56617B', ml: 1 }}
              className="w-500"
            >
              0 suka
            </Typography>
          </Stack>
          <Stack direction={'row'} alignItems={'center'} sx={{ mt: '12px' }}>
            <Typography
              sx={{ fontSize: '14px', color: '#56617B', ml: 1 }}
              className="w-500"
            >
              {translate('detail-explore.lihatbalasan')}
            </Typography>
            <KeyboardArrowDownIcon sx={{ color: '#56617B' }} />


          </Stack>
        </Stack>
      </div>

      <Divider style={{ backgroundColor: "#E8EBF2", width: '100%', marginTop: '24px', marginBottom: '24px' }} />

      <div>
        <Stack
          sx={{ mt: '24px' }}
          direction="row"
          alignItems="center"
          justifyContent="left"

        >

          <img style={{ borderRadius: '50%' }} src={ProfileImg} height={44} width={44}></img>

          <Stack sx={{ textAlign: "left", ml: '12px' }} >
            <Typography
              sx={{ fontSize: '16px', color: '#081130' }}
              className="w-700"
            >
              Roy Kiyosi
            </Typography>
            <Stack direction={'row'} alignItems={'center'}>
              <Typography
                sx={{ fontSize: '14px', color: '#56617B' }}
                className="w-500"
              >
                07 Desember
              </Typography>
              <Box width={'4px'} height={'4px'} sx={{ background: '#56617B', borderRadius: '50%', ml: 1, mr: 1 }}>

              </Box>
              <Typography
                sx={{ fontSize: '14px', color: '#56617B' }}
                className="w-500"
              >
                08:12
              </Typography>
            </Stack>

          </Stack>

        </Stack>
        <Typography
          sx={{ fontSize: '14px', color: '#081130' }}
          className="w-500"
        >
          A magna luctus libero ultricies velit tellus. apien et faucibus accumsan suspendisse eros. alesuada arcu tincidunt neque vel in amet neque tortor nisl. olutpat pharetra nibh elementum et nunc tortor quam. In sit viverra pulvinar tellus etiam
        </Typography>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ mt: '12px' }}>
          <Stack direction={'row'} alignItems={'center'} sx={{ mt: '12px' }}>
            <img src={likeIcon} height={20} width={20}></img>
            <Typography
              sx={{ fontSize: '14px', color: '#56617B', ml: 1 }}
              className="w-500"
            >
              12 suka
            </Typography>
          </Stack>
          <Stack direction={'row'} alignItems={'center'} sx={{ mt: '12px' }}>
            <Typography
              sx={{ fontSize: '14px', color: '#56617B', ml: 1 }}
              className="w-500"
            >
              {translate('detail-explore.tutupbalasan')}
            </Typography>

            <KeyboardArrowUpIcon sx={{ color: '#56617B' }} />

          </Stack>
        </Stack>
      </div>
      <div style={{ marginLeft: '20px' }}>
        <Stack
          sx={{ mt: '24px' }}
          direction="row"
          alignItems="center"
          justifyContent="left"

        >

          <img style={{ borderRadius: '50%' }} src={ProfileImg} height={44} width={44}></img>

          <Stack sx={{ textAlign: "left", ml: '12px' }} >
            <Typography
              sx={{ fontSize: '16px', color: '#081130' }}
              className="w-700"
            >
              Steven William
            </Typography>
            <Stack direction={'row'} alignItems={'center'}>
              <Typography
                sx={{ fontSize: '14px', color: '#56617B' }}
                className="w-500"
              >
                07 Desember
              </Typography>
              <Box width={'4px'} height={'4px'} sx={{ background: '#56617B', borderRadius: '50%', ml: 1, mr: 1 }}>

              </Box>
              <Typography
                sx={{ fontSize: '14px', color: '#56617B' }}
                className="w-500"
              >
                08:12
              </Typography>
            </Stack>

          </Stack>

        </Stack>
        <Typography
          sx={{ fontSize: '14px', color: '#081130' }}
          className="w-500"
        >
          A magna luctus libero ultricies velit tellus. apien et faucibus accumsan suspendisse eros. alesuada arcu tincidunt neque vel in amet neque tortor nisl. olutpat pharetra nibh elementum et nunc tortor quam. In sit viverra pulvinar tellus etiam
        </Typography>
        <Stack
          sx={{ mt: '24px' }}
          direction="row"
          alignItems="center"
          justifyContent="left"

        >

          <img style={{ borderRadius: '50%' }} src={ProfileImg} height={44} width={44}></img>

          <Stack sx={{ textAlign: "left", ml: '12px' }} >
            <Typography
              sx={{ fontSize: '16px', color: '#081130' }}
              className="w-700"
            >
              Aril Noah
            </Typography>
            <Stack direction={'row'} alignItems={'center'}>
              <Typography
                sx={{ fontSize: '14px', color: '#56617B' }}
                className="w-500"
              >
                07 Desember
              </Typography>
              <Box width={'4px'} height={'4px'} sx={{ background: '#56617B', borderRadius: '50%', ml: 1, mr: 1 }}>

              </Box>
              <Typography
                sx={{ fontSize: '14px', color: '#56617B' }}
                className="w-500"
              >
                08:12
              </Typography>
            </Stack>

          </Stack>

        </Stack>
        <Typography
          sx={{ fontSize: '14px', color: '#081130' }}
          className="w-500"
        >
          A magna luctus libero ultricies velit tellus. apien et faucibus accumsan suspendisse eros. alesuada arcu tincidunt neque vel in amet neque tortor nisl. olutpat pharetra nibh elementum et nunc tortor quam. In sit viverra pulvinar tellus etiam
        </Typography>



        <Stack direction="row" justifyContent={'end'} spacing={2} sx={{ mt: 1 }}>
          <TextField
            className="box-text-komentar"
            id="outlined-multiline-static"
            sx={{
              width: '100%', input: {
                fontSize: '14px', pt: '12px', pb: '12px',
                fontFamily: 'Satoshi-500'

              },

            }}
            InputProps={{ disableUnderline: true }}
            placeholder={translate('detail-explore.balasananda')}
            size="small"
            variant="outlined"
          />
          <Button
            href="/"
            size="small"
            className="w-500"
            variant="contained"
            style={{
              background: 'linear-gradient(270deg, #2457B9 15%, #2860CD 100%)',

            }}
          >
            <img src={sendIcon} height={20} width={20}></img>
          </Button>
        </Stack>


      </div>
      <Divider style={{ backgroundColor: "#E8EBF2", width: '100%', marginTop: '24px', marginBottom: '24px' }} />

      <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{ mt: '12px' }}>
        <Typography
          sx={{ fontSize: '16px', color: '#2457B9', ml: 1 }}
          className="w-500"
        >
          {translate('detail-explore.lihatsemuadiskusi')}
        </Typography>
        <KeyboardArrowDownIcon sx={{ color: '#2457B9' }} />
      </Stack>
    </Box>
  )
}

export { Komentar, KomentarSection };
