import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Grid, IconButton, Modal, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import deleteListingIcon from "../assets/delete-listing-ic.svg";
import { editBatubara, editNikel, editTongkang } from "../services/api";

export default function ModalHapusListing(props) {
    const [translate,] = useTranslation("global");
    const navigate = useNavigate()

    const deleteListing = async (id, page_detail) => {

        if (page_detail === "coal") {
            try {
                let params = {
                    is_deleted: "1",
                    token: localStorage.getItem("token")
                }
                const responAPI = await editBatubara(params, id)
                if (responAPI.status === 200) {
                    navigate(`/listing`, { state: { detail: 'coal' } })
                    window.location.reload();

                } else {
                    Swal.fire({
                        icon: 'error',
                        title: translate('status.failed'),
                        text: translate('status.subtitlefailed'),
                    })
                }

            } catch (error) {

            }
        } else if (page_detail === "nikel") {
            try {
                let params = {
                    is_deleted: "1",
                    token: localStorage.getItem("token")
                }
                const responAPI = await editNikel(params, id)
                if (responAPI.status === 200) {
                    navigate(`/listing`, { state: { detail: 'nikel' } })
                    window.location.reload();

                } else {
                    Swal.fire({
                        icon: 'error',
                        title: translate('status.failed'),
                        text: translate('status.subtitlefailed'),
                    })
                }


            } catch (error) {

            }
        } else {

            try {
                let params = {
                    is_deleted: "1",
                    token: localStorage.getItem("token")
                }
                const responAPI = await editTongkang(params, id, [], [])
                if (responAPI.status === 200) {
                    navigate(`/listing`, { state: { detail: 'tongkang' } })
                    window.location.reload();

                } else {
                    Swal.fire({
                        icon: 'error',
                        title: translate('status.failed'),
                        text: translate('status.subtitlefailed'),
                    })
                }

            } catch (error) {

            }
        }
    }
    return (
        <div>
            <Modal
                open={props.open}
                onClose={props.close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    width: { xs: '304px', sm: 343, md: 343, lg: 415, xl: 415 },
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: '8px',
                    bgcolor: 'background.paper',
                    "&:focus": {
                        outline: "none"
                    },
                    boxShadow: 24,
                    p: { xs: '16px', sm: '24px', md: '24px', lg: '24px', xl: '24px' },
                }}>
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <IconButton onClick={() => {
                            props.close();
                        }} size="small" >
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div style={{ justifyContent: 'center', display: 'flex' }}>
                        <img alt="hubungitrader-icon" style={{ width: '140px', height: '140px', }} src={deleteListingIcon}></img>
                    </div>
                    <Typography id="modal-modal-title" className='w-700' sx={{ fontSize: '20px', color: '#081130', textAlign: 'center', mt: '24px' }}>
                        {translate('status.hapuslisting')}
                    </Typography>
                    <Typography id="modal-modal-description" className='w-500' sx={{ mt: '16px', color: '#56617B', fontSize: '16px', textAlign: 'center' }}>
                        {translate('status.titlehapuslisting')}
                    </Typography>
                    <Grid spacing={2} container justifyContent='space-between' direction='row' sx={{ mb: '16px', mt: '36px' }}>
                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                onClick={(e) => {
                                    props.close();
                                }}
                                size="large"
                                className="w-500"
                                variant="outlined"
                                style={{
                                    color: '#2457B9',
                                    textTransform: 'none',
                                    paddingTop: '12px',
                                    paddingBottom: '12px'

                                }}
                            >
                                {translate('jual-beli.btn-cancel')}
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                onClick={(e) => {
                                    props.close();
                                    deleteListing(props.id, props.page_detail)


                                }}
                                size="large"
                                className="w-500"
                                variant="contained"
                                style={{
                                    background: '#ED3131',
                                    textTransform: 'none',
                                    paddingTop: '12px',
                                    paddingBottom: '12px'

                                }}
                            >
                                {translate('status.hapus')}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}