import { Box, Button, Hidden, Stack, Typography } from "@mui/material";
import { React, useState } from 'react';
import { useTranslation } from "react-i18next";
import BoxGreenCard from "../assets/detail/box-green-card.svg";
import DrawerLogin from "./DrawerLogin";
import ModalLogin from "./ModalLoginComponent";

export default function CardBeforeLogin(props) {
    const [isLogin,] = useState(localStorage.getItem('token') !== null ? true : false);
    const [isModalLogin, setisModalLogin] = useState(false)
    const [translate,] = useTranslation("global");

    return (
        <div>
            {!isLogin ?
                <Box sx={{ width: { xs: '100%', sm: '100%', md: '100%', lg: 355, xl: 355 }, mb: '12px' }}>

                    <Box sx={{ borderRadius: '12px', height: '144px', backgroundImage: `url(${BoxGreenCard})`, p: '20px', backgroundRepeat: `no-repeat`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                        <Stack>
                            <Typography sx={{ fontSize: { xs: 18, sm: 18, md: 18, lg: 20, xl: 20 }, color: '#FFF', }} className="w-700"> {translate('status.card-beforelogin')}</Typography>

                            <Button
                                size="small"
                                className="w-500"
                                variant="contained"
                                onClick={() => setisModalLogin(true)}
                                sx={{
                                    mt: 2,
                                    borderRadius: '8px',
                                    color: '#081130',
                                    background: ' #FFF',
                                    textTransform: 'none',
                                    fontSize: 14,
                                    p: '10px 12px',
                                    width: '150px',
                                    height: '40px',

                                }}
                            >
                                {translate('navbar.daftar')}
                            </Button>
                        </Stack>

                    </Box>
                </Box>
                : <></>}
            <Hidden mdDown>
                <ModalLogin type={props.type} open={isModalLogin} close={() => { setisModalLogin(false) }}></ModalLogin>
            </Hidden>
            <Hidden mdUp>
                <DrawerLogin type={props.type} open={isModalLogin} close={() => { setisModalLogin(false) }}></DrawerLogin>
            </Hidden>
        </div>
    )
}
