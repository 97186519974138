import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { getPortFilterLocation } from '../services/api';

export default function FormLoadingPort(props) {

    const [companyList, setCompanyList] = useState([])
    const [companySelect, setCompanySelect] = useState({ port_id: parseInt(props.currentValue) })
    const [page, setPage] = useState(1)
    const [value, setValue] = useState("")

    async function handleChangeInput(e) {
        try {
            setValue(e)
            setPage(1)
            await getAllPort(e)
        } catch (error) {

        }
    }

    async function getAllPort(name, pageQ) {
        try {
            let valuePage = page
            if (pageQ === "" || pageQ === undefined) {
                valuePage = 1
            } else {
                valuePage = pageQ
                setPage(pageQ)
            }

            const data = {
                name: name,
                page: valuePage,
                id_location: props.id_location
            }

            if (valuePage !== 1) {

                const response = await getPortFilterLocation(data)
                let currentCompany = companyList
                setCompanyList(currentCompany.concat(response.data?.data.data));
            } else {
                const response = await getPortFilterLocation(data)
                setCompanyList(response.data?.data.data);
            }
        } catch (e) {

        }
    }

    async function changeValue(event, values) {
        try {
            if (values === null) {
                setCompanySelect(values)
                props.select({ port_id: "" })
                props.selectName("")
            } else {
                setCompanySelect(values)
                props.select(values)
                props.selectName(values.port_name)
            }
        } catch (e) {

        }
    }

    useEffect(() => {
        getAllPort()
        setCompanySelect({ port_id: props.currentValue })
    }, [props.currentValue, props.id_location])

    useEffect(() => {
        getAllPort(props.nameValue)
    }, [])


    return (

        <Autocomplete
            style={{
                width: "100%",
                border: "1px solid var(--grey-stroke, #e8ebf2)",
                marginTop: "4px",
                borderRadius: "8px"
            }}
            disabled={props.disabled ? props.disabled : false}
            onChange={changeValue}
            options={companyList.map((x, i) => (x))}
            onInputChange={async (event, newInputValue) => await handleChangeInput(newInputValue)}
            getOptionLabel={(option) => option.port_name}
            renderOption={(props, option) => {

                return (
                    <div {...props}>
                        {option.port_name}
                    </div>)
            }
            }
            ListboxProps={{
                role: "list-box",
                onScroll: async (event) => {
                    const listboxNode = event.currentTarget;
                    if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                        await getAllPort(value, Number(page) + 1)
                    }
                }
            }}
            popupIcon={<KeyboardArrowDownIcon sx={{ width: '20px', height: '20px', fill: '#56617B' }} />}
            renderInput={(params) => {
                if (companySelect?.port_id) {

                    companyList.map(x => {
                        if (x.port_id === companySelect.port_id) {
                            params.inputProps.value = x.port_name
                        }
                    })
                    return <TextField {...params} variant="outlined" />
                } else {

                    return <TextField {...params} variant="outlined" placeholder={props.placeholder} sx={{
                        fontSize: "14px", input: {
                            fontSize: '14px', fontFamily: 'Satoshi-500'
                        },
                    }} />
                }

            }}
        />

    );
}