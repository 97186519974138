
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Avatar, Box, Button, Card, Collapse, FormControlLabel, Grid, Hidden, IconButton, InputAdornment, MenuItem, Radio, RadioGroup, Select, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import delete_doc from "../assets/hapus-doc-ic.svg";
import tongkang_icon from "../assets/tongkang.svg";
import { DisplayPDFFile } from '../components/DisplayPDF';
import DrawerHapusDoc from '../components/DrawerHapusDoc';
import DrawerUploadDoc from '../components/DrawerUploadDocLayer';
import DrawerUploadImage from '../components/DrawerUploadImage';
import DrawerpublishListing from "../components/DrawerpublishListing";
import DropZoneUnggahImage from '../components/DropZoneImage';
import DropZoneUnggah from '../components/DropZoneUnggah';
import Footer from "../components/Footer";
import FormLoadingPort from '../components/FormLoadingPort';
import FormProvince from '../components/FormProvince';
import ModalBatalForm from '../components/ModalBatalForm';
import ModalUnggahDoc from '../components/ModalUnggahDocBaruLayer';
import ModalUnggahImageBaruLayer from '../components/ModalUnggahImageBaruLayer';
import NavBar from "../components/Navbar";
import SnackBarDraft from '../components/SnackBarDraft';
import { GetValidationPriceQuantity, editTongkang, getCompanyListing, getInformationAdmin, postTongkang } from "../services/api.js";
import { config } from '../services/constants';
import { GlobalState } from "../services/global";
import { VerticalDividerPrice, VerticalDividerQuantity } from '../services/primary';

export default function MenyewakanTongkang() {
  const location = useLocation();
  const navigate = useNavigate()
  const [date, setDate] = useState('')
  const [translate,] = useTranslation("global");
  const [id_company_listing, setCompany] = useState("")
  const [companyList, setCompanyList] = useState([]);
  const [nama_tug_boat, setNama_tug_boat] = useState("")
  const [nama_tongkang, setNama_tongkang] = useState("")
  const [quantity_muatan, setQuantity_muatan] = useState("")
  const [type_tongkang, setType_tongkang] = useState('2')
  const [price, setPrice] = useState("")
  const [tahun_buat, setTahun_buat] = useState("")
  const [id_bendera, setId_bendera] = useState("")
  const [description, setDescription] = useState("")

  const [jenis_muatan, setJenis_muatan] = useState("")
  const [ukuran_tongkang, setUkuran_tongkang] = useState("")
  const [image, setImage] = useState([])
  const [status_sewa, setStatus_sewa] = useState(true)
  const [id, setId] = useState("")
  const [selectukurantongkang, setselectukurantongkang] = useState('')
  const [selectjenismuatan, setselectjenismuatan] = useState('')
  const [selectrenttype, setselectrenttype] = useState('')
  const [openUkuran, setOpenUkuran] = useState(false);
  const [openJenis, setOpenJenis] = useState(false);
  const [openStartDate, setopenStartDate] = useState(false);
  const [openModalBatalForm, setOpenModalBatalForm] = useState(false);
  const [validationNamatug, setValidationNamatug] = useState(false)
  const [validationNamaTongkang, setValidationNamaTongkang] = useState(false)
  const [validationTypeTongkang, setValidationTypeTongkang] = useState(false)
  const [validationqty, setvalidationqty] = useState(false)
  const [validationprice, setvalidationprice] = useState(false)
  const [validationtahunbuat, setvalidationtahunbuat] = useState(false)
  const [validationlokasitongkang, setvalidationlokasitongkang] = useState(false)

  const [validationjenismuatan, setvalidationjenismuatan] = useState(false)
  const [validationukurantongkang, setvalidationukurantongkang] = useState(false)
  const [validationlaycandate, setvalidationlaycandate] = useState(null)
  const [validationupload, setvalidationupload] = useState(false)
  const [validationloadingarea, setvalidationloadingarea] = useState(false)
  const [validationdischargearea, setvalidationdischargearea] = useState(false)

  const [isOpenModal, setOpenModal] = React.useState(false)
  const [file, setFile] = useState([]);

  const [isOpenDrawerDoc, setOpenDrawerDoc] = React.useState(false)
  const [isOpenUploadDoc, setOpenUploadDoc] = React.useState(false)
  const [x, setX] = useState(location?.state.data === undefined ? null : location.state.data)
  const [page] = useState(location?.state.page === undefined ? null : location.state.page)
  const [validationPriceQty, setvalidationPriceQty] = useState('')
  const [dataAdmin, setdataAdmin] = useState('')
  const [id_loading_port, setPort] = useState('')
  const [selectcurrency, setselectcurrency] = React.useState('idr');
  const [listCurrency] = useState([{ id: 'idr', name: 'Rp' }, { id: 'usd', name: '$' }])
  const [isSuccessDraft, setisSuccessDraft] = useState(false);
  const [position, setPosition] = useState("center")
  const { currency, setcurrency } = React.useContext(GlobalState)
  const [isOpenUploadImage, setOpenUploadImage] = React.useState(false)
  const [activeTab, setActiveTab] = useState(location.state?.detail === undefined ? "time-charter" : location.state?.detail);

  const size_list = ["180 FT", "200 FT", "250 FT", "230 FT", "270 FT", "300 FT", "310 FT", "320 FT", "330 FT", translate('status.lainnya')]
  const payload_type = [translate('page_explore.batubara'), translate('page_explore.nikel'), translate('page_explore.bauksit'), translate('page_explore.pasirsilka'), translate('page_explore.batusplit'), translate('page_explore.batukapur'), translate('page_explore.pasirbesi'), translate('status.nopreference')]
  const price_list = ["Time charter", "Freight charter"]
  const currentYear = (new Date()).getFullYear();
  const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
  const options = range(currentYear, currentYear - 25, -1)
  const [id_tongkang_location, setid_tongkang_location] = useState('')
  const [id_pelabuhan_muat, setid_pelabuhan_muat] = useState('')
  const [id_pelabuhan_bongkar, setid_pelabuhan_bongkar] = useState('')
  const [id_area_muat, setid_area_muat] = useState('')
  const [id_area_bongkar, setid_area_bongkar] = useState('')
  const [pelabuhan_muat_name, setpelabuhan_muat_name] = useState('')
  const [pelabuhan_bongkar_name, setpelabuhan_bongkar_name] = useState('')

  const handleSuccessDraft = () => {
    setisSuccessDraft(true)
  };

  const handleOpenModalUpload = () => {
    setOpenUploadDoc(true)
  };
  const handleOpenImageUpload = () => {
    setOpenUploadImage(true)
  };

  const handleOpenBatalForm = () => {
    setOpenModalBatalForm(true)
  };

  const handleCloseModalBatalForm = () => {
    setOpenModalBatalForm(false);
  };

  const handleCloseModalUpload = (params, fileQ) => {
    if (params === 'submit') {
      let fileArrayCombine = file.concat(fileQ)
      setFile(fileArrayCombine)
    }
    setOpenUploadDoc(false);
  };

  const handleCloseImageUpload = (params, fileQ) => {
    if (params === 'submit') {
      let fileArrayCombine = image.concat(fileQ)
      setImage(fileArrayCombine)
    }
    setOpenUploadImage(false);
  };

  const deleteFileonArrayDrawer = (index) => {
    let newFileDocument = file.concat([])
    if (index > -1) {
      newFileDocument.splice(index, 1);
    }
    setFile(newFileDocument);
  }

  const deleteImageonArrayDrawer = (index) => {
    let newFileDocument = image.concat([])
    if (index > -1) {
      newFileDocument.splice(index, 1);
    }
    setImage(newFileDocument);
  }

  function handleChangeFileFromDeleteResponsive(fileQ, y) {
    setFile(fileQ);
  }

  const handleOpenDrawerDoc = (file) => {
    // setDocument(file)
    setOpenDrawerDoc(true);
  };
  const handleCloseDrawerDoc = () => {
    setOpenDrawerDoc(false);
  };

  const handleChangeRadioUkuran = (event, i) => {

    if (event.target.value === 'Lainnya' || event.target.value === 'Other') {
      setOpenUkuran(true)
      setselectukurantongkang(event.target.value)
      setUkuran_tongkang(event.target.value)
    } else {
      setselectukurantongkang(event.target.value)
      setUkuran_tongkang(event.target.value)
      setOpenUkuran(false)
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setisSuccessDraft(false);

  };

  const SubmitResponsivePublishTC = () => {
    submitTimeCharter(1)
  };

  const SubmitResponsivePublishFC = () => {
    submitFreightCharter(1)
  };

  const SubmitResponsiveDraftTC = () => {
    setPosition('top')
    submitDraftTimeCharter(3);
  };

  const SubmitResponsiveDraftFC = () => {
    setPosition('top')
    submitDraftFreightCharter(3);
  };

  const handleChangeRadioJenis = (event, i) => {

    if (event.target.value === 'Tanpa preferensi' || event.target.value === 'No preference') {
      setselectjenismuatan(event.target.value)
      setOpenJenis(true)
      setJenis_muatan(event.target.value);
    } else {
      setselectjenismuatan(event.target.value)
      setOpenJenis(false)
      setJenis_muatan(event.target.value);

    }
  };


  // function handleSelectbendera(e) {
  //   setId_bendera(e.id)
  // }

  // function handleSelectPort(e) {
  //   setPort(e.port_id)
  // }

  useEffect(() => {
    getCompany()
    typePage()
    updateField()
    GetDataAdmin()
  }, []);

  function typePage() {
    if (location.state?.detail === undefined || location.state.detail === "time-charter") {
      if (location.state.data?.type_tongkang === 3) {
        setselectrenttype("Freight charter")
        setType_tongkang(3)
        setActiveTab("freight-charter")
      } else {
        setType_tongkang(2)
        setselectrenttype("Time charter")
        setActiveTab("time-charter")
      }
    } else {
      setselectrenttype("Freight charter")
      setType_tongkang(3)
      setActiveTab("freight-charter")
    }
  }

  async function GetDataAdmin() {

    try {
      const response = await getInformationAdmin()
      setdataAdmin(response.data?.data);

    } catch (e) {
      console.log(e);
    }
  }

  function updateField() {
    if (x !== null) {
      setImage(x.path_tongkang_pictures === null ? [] : x.path_tongkang_pictures)
      setNama_tug_boat(x.nama_tug_boat)
      setNama_tongkang(x.nama_tongkang)
      setQuantity_muatan(x.quantity_muatan)
      setPrice(x.price)
      setType_tongkang(x.type_tongkang)
      setUkuran_tongkang(x.ukuran_tongkang_ft)
      setselectukurantongkang(x.ukuran_tongkang_ft)
      setTahun_buat(x.tahun_buat)
      setDescription(x.description)
      setId_bendera(x.bendera?.id)
      setDate(x.laycan_date)
      setCompany(x.company_listing?.id)
      setJenis_muatan(x.jenis_muatan)
      setselectjenismuatan(x.jenis_muatan)
      setId(x.id)
      setFile(x.tongkang_documents)
      setPort(x.pelabuhan_muat?.id)
      setcurrency(x.currency);
      setselectcurrency(x.currency);
      setid_tongkang_location(x.tongkang_location?.id)
      setid_area_muat(x.area_muat?.id ?? null)
      setid_area_bongkar(x.area_bongkar?.id ?? null)
      setid_pelabuhan_bongkar(x.pelabuhan_bongkar?.id ?? null)
      setid_pelabuhan_muat(x.pelabuhan_muat?.id ?? null)
      setpelabuhan_bongkar_name(x.pelabuhan_bongkar?.name ?? null)
      setpelabuhan_muat_name(x.pelabuhan_muat?.name ?? null)
      GetValidation(x.currency)
    } else {
      GetValidation(selectcurrency)
    }
  }

  async function getCompany() {
    try {
      const response = await getCompanyListing();
      setCompanyList(response.data?.data.data);
    } catch (e) {
      console.log(e);
    }
  }


  async function GetValidation(datacurrency) {
    const data = {
      currency: datacurrency
    }
    try {
      const response = await GetValidationPriceQuantity(data)
      setvalidationPriceQty(response.data?.data);
    } catch (e) {
      console.log(e);
    }
  }
  const handleChangeCurrency = (e) => {
    if (e.target.value === 'idr') {
      setPrice((price * validationPriceQty.usd_idr));
    } else {
      setPrice((price / validationPriceQty.usd_idr))
    }
    setselectcurrency(e.target.value)
    GetValidation(e.target.value)
  };

  async function submitTimeCharter(e) {

    const data = {
      nama_tug_boat: nama_tug_boat,
      nama_tongkang: nama_tongkang,
      // quantity_muatan: quantity_muatan,
      type_tongkang: type_tongkang,
      price: price,
      tahun_buat: tahun_buat,
      // id_bendera: id_bendera,
      description: description,
      id_area_muat: id_area_muat,
      id_area_bongkar: id_area_bongkar,
      id_tongkang_location: id_tongkang_location,
      id_pelabuhan_muat: id_pelabuhan_muat,
      id_pelabuhan_bongkar: id_pelabuhan_bongkar,
      id_company_listing: id_company_listing,
      jenis_muatan: jenis_muatan,
      ukuran_tongkang: ukuran_tongkang,
      laycan_date: date,
      status_listing: e,
      token: localStorage.getItem("token"),
      path_tongkang_pictures: JSON.stringify(image),
      path_documents: JSON.stringify(file),
      currency: selectcurrency
    }
    if (x === null) {

      if (data.path_documents === '[]' || data.nama_tug_boat === "" || data.nama_tongkang === "" ||
        data.type_tongkang === "" || data.price === "" || data.tahun_buat === "" || data.id_tongkang_location === "" ||
        data.ukuran_tongkang === "" || image?.length === 0) {
        data.nama_tug_boat === '' ? setValidationNamatug(true) : setValidationNamatug(false)
        data.nama_tongkang === '' ? setValidationNamaTongkang(true) : setValidationNamaTongkang(false)
        // data.quantity_muatan === '' ? setvalidationqty(true) : setvalidationqty(false)
        data.type_tongkang === '' ? setValidationTypeTongkang(true) : setValidationTypeTongkang(false)
        data.price === '' ? setvalidationprice(true) : setvalidationprice(false)
        data.tahun_buat === '' ? setvalidationtahunbuat(true) : setvalidationtahunbuat(false)
        data.id_tongkang_location === '' ? setvalidationlokasitongkang(true) : setvalidationlokasitongkang(false)
        // data.jenis_muatan === '' ? setvalidationjenismuatan(true) : setvalidationjenismuatan(false)
        data.ukuran_tongkang === '' ? setvalidationukurantongkang(true) : setvalidationukurantongkang(false)
        // data.laycan_date === '' ? setvalidationlaycandate('') : setvalidationlaycandate(null)
        data.path_documents === '[]' ? setvalidationupload(true) : setvalidationupload(false)

        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('status.Fieldkosong'),
        })


      }
      else if (data.ukuran_tongkang > 500) {
        setvalidationukurantongkang(true)
        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('title.limitukurantongkang'),
        })
      }

      else if (price > validationPriceQty.maximum_prices_for_tongkang || price < validationPriceQty.minimum_prices_for_tongkang) {
        setvalidationprice(true)

        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('status.errorpricelimit'),
        })

      } else {
        const res = await postTongkang(data, [], image)
        if (res.status === 200) {
          navigate(`/listing`, { state: { detail: 'tongkang' } })
        } else {
          Swal.fire({
            icon: 'error',
            title: translate('status.failed'),
            text: translate('status.subtitlefailed'),
          })
        }
      }
    } else {
      if (data.path_documents === '[]' || data.nama_tug_boat === "" || data.nama_tongkang === "" || data.type_tongkang === "" ||
        data.price === "" || data.tahun_buat === "" ||
        // data.id_pelabuhan_muat === "" || 
        data.ukuran_tongkang === "" || image?.length === 0) {

        data.nama_tug_boat === '' ? setValidationNamatug(true) : setValidationNamatug(false)
        data.nama_tongkang === '' ? setValidationNamaTongkang(true) : setValidationNamaTongkang(false)
        // data.quantity_muatan === '' ? setvalidationqty(true) : setvalidationqty(false)
        data.type_tongkang === '' ? setValidationTypeTongkang(true) : setValidationTypeTongkang(false)
        data.price === '' ? setvalidationprice(true) : setvalidationprice(false)
        data.tahun_buat === '' ? setvalidationtahunbuat(true) : setvalidationtahunbuat(false)
        data.id_tongkang_location === '' ? setvalidationlokasitongkang(true) : setvalidationlokasitongkang(false)

        // data.jenis_muatan === '' ? setvalidationjenismuatan(true) : setvalidationjenismuatan(false)
        data.ukuran_tongkang === '' ? setvalidationukurantongkang(true) : setvalidationukurantongkang(false)
        // data.laycan_date === '' ? setvalidationlaycandate('') : setvalidationlaycandate(null)
        data.path_documents === '[]' ? setvalidationupload(true) : setvalidationupload(false)

        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('status.Fieldkosong'),
        })


      }
      else if (data.ukuran_tongkang > 500) {
        setvalidationukurantongkang(true)
        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('title.limitukurantongkang'),
        })
      }
      else if (price > validationPriceQty.maximum_prices_for_tongkang || price < validationPriceQty.minimum_prices_for_tongkang) {
        setvalidationprice(true)

        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('status.errorpricelimit'),
        })

      } else {
        const res = await editTongkang(data, id, file, image)
        if (res.data?.data !== null) {
          navigate(`/listing`, { state: { detail: 'tongkang' } })
        } else {
          Swal.fire({
            icon: 'error',
            title: translate('status.failed'),
            text: translate('status.subtitlefailed'),
          })
        }
      }
    }

  }

  async function submitDraftTimeCharter(e) {

    const data = {
      nama_tug_boat: nama_tug_boat,
      nama_tongkang: nama_tongkang,
      type_tongkang: type_tongkang === '0' ? '2' : type_tongkang,
      // quantity_muatan: quantity_muatan === "" ? 0 : quantity_muatan,
      price: price === "" ? 0 : price,
      tahun_buat: tahun_buat === "" ? 0 : tahun_buat,
      // id_bendera: id_bendera,
      description: description,
      id_area_muat: id_area_muat,
      id_area_bongkar: id_area_bongkar,
      id_tongkang_location: id_tongkang_location,
      id_pelabuhan_muat: id_pelabuhan_muat,
      id_pelabuhan_bongkar: id_pelabuhan_bongkar,
      id_company_listing: id_company_listing,
      jenis_muatan: jenis_muatan,
      ukuran_tongkang: ukuran_tongkang,
      laycan_date: date === '' ? new Date(Date.now) : date,
      status_listing: e,
      token: localStorage.getItem("token"),
      path_tongkang_pictures: JSON.stringify(image),
      path_documents: JSON.stringify(file),
      currency: selectcurrency
    }
    if (x === null) {
      if (data.ukuran_tongkang > 500) {
        setvalidationukurantongkang(true)
        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('title.limitukurantongkang'),
        })
      }
      else if (price > validationPriceQty.maximum_prices_for_tongkang || price < validationPriceQty.minimum_prices_for_tongkang) {
        setvalidationprice(true)

        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('status.errorpricelimit'),
        })

      } else {
        const res = await postTongkang(data, [], image)
        if (res.status === 200) {
          handleSuccessDraft()
          setImage([])
          setid_tongkang_location("")
          setNama_tug_boat('')
          setNama_tongkang('')
          setQuantity_muatan('')
          setPrice('')
          setType_tongkang('')
          setUkuran_tongkang('')
          setselectukurantongkang('')
          setTahun_buat('')
          setDescription('')
          // setId_bendera('')
          setDate('')
          setCompany('')
          setJenis_muatan('')
          setselectjenismuatan('')
          setId('')
          setFile('')
          setPort('')
          setX(null)
        } else {
          Swal.fire({
            icon: 'error',
            title: translate('status.failed'),
            text: translate('status.subtitlefailed'),
          })
        }
      }
    } else {
      if (data.ukuran_tongkang > 500) {
        setvalidationukurantongkang(true)
        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('title.limitukurantongkang'),
        })
      }
      else if (price > validationPriceQty.maximum_prices_for_tongkang || price < validationPriceQty.minimum_prices_for_tongkang) {
        setvalidationprice(true)

        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('status.errorpricelimit'),
        })

      } else {
        const res = await editTongkang(data, id, file, image)
        if (res.data?.data !== null) {
          handleSuccessDraft()
          setImage([])
          setid_tongkang_location("")
          setNama_tug_boat('')
          setNama_tongkang('')
          // setQuantity_muatan('')
          setPrice('')
          setType_tongkang('')
          setUkuran_tongkang('')
          setselectukurantongkang('')
          setTahun_buat('')
          setDescription('')
          // setId_bendera('')
          setDate('')
          setCompany('')
          setJenis_muatan('')
          setselectjenismuatan('')
          setId('')
          setFile('')
          setPort('')
          setX(null)
        } else {
          Swal.fire({
            icon: 'error',
            title: translate('status.failed'),
            text: translate('status.subtitlefailed'),
          })
        }
      }
    }

  }

  async function submitFreightCharter(e) {

    const data = {
      nama_tug_boat: nama_tug_boat,
      nama_tongkang: nama_tongkang,
      // quantity_muatan: quantity_muatan,
      type_tongkang: type_tongkang,
      price: price,
      tahun_buat: tahun_buat,

      description: description,
      id_area_muat: id_area_muat,
      id_area_bongkar: id_area_bongkar,
      id_tongkang_location: id_tongkang_location,
      id_pelabuhan_muat: id_pelabuhan_muat,
      id_pelabuhan_bongkar: id_pelabuhan_bongkar,
      id_company_listing: id_company_listing,
      jenis_muatan: jenis_muatan,
      ukuran_tongkang: ukuran_tongkang,
      laycan_date: date,
      status_listing: e,
      token: localStorage.getItem("token"),
      path_tongkang_pictures: JSON.stringify(image),
      path_documents: JSON.stringify(file),
      currency: selectcurrency
    }
    if (x === null) {

      if (data.path_documents === '[]' || data.nama_tug_boat === "" || data.nama_tongkang === "" || data.type_tongkang === "" || data.price === "" || data.tahun_buat === "" || data.id_area_muat === "" || data.jenis_muatan === "" || data.ukuran_tongkang === "" || image?.length === 0) {
        data.nama_tug_boat === '' ? setValidationNamatug(true) : setValidationNamatug(false)
        data.nama_tongkang === '' ? setValidationNamaTongkang(true) : setValidationNamaTongkang(false)
        data.id_area_muat === '' ? setvalidationloadingarea(true) : setvalidationloadingarea(false)
        // data.id_area_bongkar === '' ? setvalidationdischargearea(true) : setvalidationdischargearea(false)
        data.type_tongkang === '' ? setValidationTypeTongkang(true) : setValidationTypeTongkang(false)
        data.price === '' ? setvalidationprice(true) : setvalidationprice(false)
        data.tahun_buat === '' ? setvalidationtahunbuat(true) : setvalidationtahunbuat(false)
        // data.id_bendera === '' ? setvalidationbendera(true) : setvalidationbendera(false)
        data.jenis_muatan === '' ? setvalidationjenismuatan(true) : setvalidationjenismuatan(false)
        data.ukuran_tongkang === '' ? setvalidationukurantongkang(true) : setvalidationukurantongkang(false)
        // data.laycan_date === '' ? setvalidationlaycandate('') : setvalidationlaycandate(null)
        data.path_documents === '[]' ? setvalidationupload(true) : setvalidationupload(false)

        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('status.Fieldkosong'),
        })


      }
      else if (data.ukuran_tongkang > 500) {
        setvalidationukurantongkang(true)
        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('title.limitukurantongkang'),
        })
      }
      else if (price && (price > validationPriceQty.maximum_prices_for_tongkang || price < validationPriceQty.minimum_prices_for_tongkang)) {
        setvalidationprice(true)

        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('status.errorpricelimit'),
        })

      } else {
        const res = await postTongkang(data, [], image)
        if (res.status === 200) {
          navigate(`/listing`, { state: { detail: 'tongkang' } })
        } else {
          Swal.fire({
            icon: 'error',
            title: translate('status.failed'),
            text: translate('status.subtitlefailed'),
          })
        }
      }
    } else {
      if (data.path_documents === '[]' || data.nama_tug_boat === "" || data.nama_tongkang === "" || data.type_tongkang === "" || data.price === "" || data.tahun_buat === "" || data.id_area_muat === "" || data.jenis_muatan === "" || data.ukuran_tongkang === "" || image?.length === 0) {
        data.nama_tug_boat === '' ? setValidationNamatug(true) : setValidationNamatug(false)
        data.nama_tongkang === '' ? setValidationNamaTongkang(true) : setValidationNamaTongkang(false)
        data.type_tongkang === '' ? setValidationTypeTongkang(true) : setValidationTypeTongkang(false)
        data.price === '' ? setvalidationprice(true) : setvalidationprice(false)
        data.id_area_muat === '' ? setvalidationloadingarea(true) : setvalidationloadingarea(false)
        // data.id_area_bongkar === '' ? setvalidationdischargearea(true) : setvalidationdischargearea(false)
        data.tahun_buat === '' ? setvalidationtahunbuat(true) : setvalidationtahunbuat(false)
        data.jenis_muatan === '' ? setvalidationjenismuatan(true) : setvalidationjenismuatan(false)
        data.ukuran_tongkang === '' ? setvalidationukurantongkang(true) : setvalidationukurantongkang(false)
        // data.laycan_date === '' ? setvalidationlaycandate('') : setvalidationlaycandate(null)
        data.path_documents === '[]' ? setvalidationupload(true) : setvalidationupload(false)

        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('status.Fieldkosong'),
        })


      }
      else if (data.ukuran_tongkang > 500) {
        setvalidationukurantongkang(true)
        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('title.limitukurantongkang'),
        })
      }

      else if (price && (price > validationPriceQty.maximum_prices_for_tongkang || price < validationPriceQty.minimum_prices_for_tongkang)) {
        setvalidationprice(true)

        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('status.errorpricelimit'),
        })

      } else {
        const res = await editTongkang(data, id, file, image)
        if (res.data?.data !== null) {
          navigate(`/listing`, { state: { detail: 'tongkang' } })
        } else {
          Swal.fire({
            icon: 'error',
            title: translate('status.failed'),
            text: translate('status.subtitlefailed'),
          })
        }
      }
    }

  }

  async function submitDraftFreightCharter(e) {

    const data = {
      nama_tug_boat: nama_tug_boat,
      nama_tongkang: nama_tongkang,
      type_tongkang: type_tongkang === '0' ? '2' : type_tongkang,
      price: price === "" ? 0 : price,
      tahun_buat: tahun_buat === "" ? 0 : tahun_buat,
      description: description,
      id_area_muat: id_area_muat,
      id_area_bongkar: id_area_bongkar,
      id_tongkang_location: id_tongkang_location,
      id_pelabuhan_muat: id_pelabuhan_muat,
      id_pelabuhan_bongkar: id_pelabuhan_bongkar,
      id_company_listing: id_company_listing,
      jenis_muatan: jenis_muatan,
      ukuran_tongkang: ukuran_tongkang,
      laycan_date: date === '' ? new Date(Date.now) : date,
      status_listing: e,
      token: localStorage.getItem("token"),
      path_tongkang_pictures: JSON.stringify(image),
      path_documents: JSON.stringify(file),
      currency: selectcurrency
    }
    if (x === null) {
      if (data.ukuran_tongkang > 500) {
        setvalidationukurantongkang(true)
        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('title.limitukurantongkang'),
        })
      }
      else if (price && (price > validationPriceQty.maximum_prices_for_tongkang || price < validationPriceQty.minimum_prices_for_tongkang)) {
        setvalidationprice(true)

        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('status.errorpricelimit'),
        })

      } else {
        const res = await postTongkang(data, [], image)
        if (res.status === 200) {
          handleSuccessDraft()
          setImage([])
          setNama_tug_boat('')
          setNama_tongkang('')
          setQuantity_muatan('')
          setPrice('')
          setType_tongkang('')
          setUkuran_tongkang('')
          setselectukurantongkang('')
          setTahun_buat('')
          setDescription('')
          setDate('')
          setCompany('')
          setJenis_muatan('')
          setselectjenismuatan('')
          setId('')
          setFile('')
          setid_area_bongkar('')
          setid_pelabuhan_muat('')
          setid_area_muat('')
          setid_pelabuhan_bongkar('')
          setX(null)
        } else {
          Swal.fire({
            icon: 'error',
            title: translate('status.failed'),
            text: translate('status.subtitlefailed'),
          })
        }
      }
    } else {
      if (data.ukuran_tongkang > 500) {
        setvalidationukurantongkang(true)
        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('title.limitukurantongkang'),
        })
      }
      else if (price && (price > validationPriceQty.maximum_prices_for_tongkang || price < validationPriceQty.minimum_prices_for_tongkang)) {
        setvalidationprice(true)

        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('status.errorpricelimit'),
        })

      } else {
        const res = await editTongkang(data, id, file, image)
        if (res.data?.data !== null) {
          handleSuccessDraft()
          setImage([])
          setNama_tug_boat('')
          setNama_tongkang('')
          // setQuantity_muatan('')
          setPrice('')
          setType_tongkang('')
          setUkuran_tongkang('')
          setselectukurantongkang('')
          setTahun_buat('')
          setDescription('')
          // setId_bendera('')
          setDate('')
          setCompany('')
          setJenis_muatan('')
          setselectjenismuatan('')
          setId('')
          setFile('')
          setid_area_bongkar('')
          setid_pelabuhan_muat('')
          setid_area_muat('')
          setid_pelabuhan_bongkar('')
          setX(null)
        } else {
          Swal.fire({
            icon: 'error',
            title: translate('status.failed'),
            text: translate('status.subtitlefailed'),
          })
        }
      }
    }

  }



  return (
    <div>
      <div className='fixed-header'>
        <NavBar /></div>
      <Box className="flex-wrap-center width-100" >
        <Box className="content-inner" sx={{ mt: { xs: 6, sm: 6, md: 11, lg: 11, xl: 11 }, ml: { xs: '24px', lg: '80px', }, mr: { xs: '24px', lg: '80px', }, }}>
          {/* navigation Section */}
          {page === 'edit-tongkang' ? <Grid container direction="row" alignItems='center' className='mt-18'>
            <Button href="/" className="w-500" sx={{ fontSize: { xs: 14, sm: 14, md: 16, lg: 16 }, mb: 1, color: '#2457B9', textTransform: 'none', pl: 0 }} variant="text"> {translate('navbar.home')}</Button>

            <Typography className="w-500" sx={{ fontSize: { xs: 14, sm: 14, md: 16, lg: 16 }, mb: 1, color: '#56617B' }}>
              /
            </Typography>
            <Button href="/listing" className="w-500" sx={{ fontSize: { xs: 14, sm: 14, md: 16, lg: 16 }, mb: 1, color: '#2457B9', textTransform: 'none' }} variant="text">{translate('navbar.mylisting')}</Button>
            <Typography className="w-500" sx={{ fontSize: { xs: 14, sm: 14, md: 16, lg: 16 }, mb: 1, color: '#56617B', mr: 1 }}>
              /
            </Typography>
            <Typography className="w-500" sx={{ fontSize: { xs: 14, sm: 14, md: 16, lg: 16 }, mb: 1, color: '#56617B' }}>
              {translate('detail-explore.detailtongkang')}
            </Typography>
          </Grid> :
            <Grid container direction="row" alignItems='center' className='mt-18'>
              <Button href="/" className="w-500" sx={{ fontSize: { xs: 14, sm: 14, md: 16, lg: 16 }, mb: 1, color: '#2457B9', textTransform: 'none', pl: 0 }} variant="text"> {translate('navbar.home')}</Button>

              <Typography className="w-500" sx={{ fontSize: { xs: 14, sm: 14, md: 16, lg: 16 }, mb: 1, color: '#56617B' }}>
                /
              </Typography>
              <Button href="/explore" className="w-500" sx={{ fontSize: { xs: 14, sm: 14, md: 16, lg: 16 }, mb: 1, color: '#2457B9', textTransform: 'none' }} variant="text">{translate('navbar.explore')}</Button>
              <Typography className="w-500" sx={{ fontSize: { xs: 14, sm: 14, md: 16, lg: 16 }, mb: 1, color: '#56617B', mr: 1 }}>
                /
              </Typography>

              <Typography className="w-500" sx={{ fontSize: { xs: 14, sm: 14, md: 16, lg: 16 }, mb: 1, color: '#56617B' }}>
                {translate('page_explore.btn-menyewakan-tongkang')}
              </Typography>
            </Grid>}
          {/* header Section */}
          <Grid container direction="row" justifyContent="space-between" className='mt-18'>
            <Grid item xs={12} sm={12} md={3} lg={3}>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} sx={{ display: "flex", justifyContent: "center", textAlign: "center", flexDirection: "column" }} >
              <Box style={{ textAlign: "center", justifyContent: "center", display: "flex" }}>
                <img src={tongkang_icon} width={42} alt='' />
              </Box>

              <h3 className='w-700 c-08'>   {translate('page_explore.btn-menyewakan-tongkang')} </h3>

              <p style={{ textAlign: "center" }} className='s-1622 mt-12 w-500 c-56'> {activeTab === "time-charter" ? "Time Charter" : "Freight Charter"}</p>
              <p style={{ textAlign: "center" }} className='s-1622 mt-12 w-500 c-56 mb-52'>{translate('title.sub-form-jual')}</p>
            </Grid>
            <Grid item xs={3} className='wd-252'>
            </Grid>
          </Grid>

          {/* Time Charter Section */}
          {activeTab === "time-charter" && <div>
            {/* Form Spesifikasi Tongkang Section */}
            <Grid container direction='row' justifyContent='space-between'>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Typography className="w-700" sx={{ fontSize: 24, mb: '16px', color: '#081130' }}>
                  {translate('jual-beli.spesifikasi-tongkang')}
                </Typography>
                <p className='c-56 w-400 s-1421 mb-16'>{translate('jual-beli.subtitle-spesifikasi-tongkang')}</p>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} className='form-input'>
                {/* Form Image Tongkang Section */}
                <Grid container direction='row' justifyContent='space-between'>

                  {image.length > 0 && image.length < 3 &&
                    <label for="upload2">
                      <p onClick={(e) => {
                        handleOpenImageUpload();
                      }} className='c-24 s-1419 w-500 mb4'>{translate('status.unggahimage')}</p>

                    </label>
                  }
                </Grid>
                {image.length > 0 &&
                  <Grid container direction='row' justifyContent='space-between' className='mt-10'>
                    {image.map((item, i) => (
                      <div style={{ position: 'relative' }}>
                        <div style={{ position: 'absolute', display: 'flex', right: '0%' }}>
                          <IconButton onClick={() => {
                            deleteImageonArrayDrawer(i)

                          }} alt='' style={{ marginRight: '8px', cursor: 'pointer', background: 'transparent' }} aria-label="settings " sx={{ background: 'rgba(0, 0, 0, 0.1)', }}>
                            <img style={{ height: '24px', width: '24px' }} src={delete_doc} alt='' />
                          </IconButton>
                        </div>
                        <img src={config.baseURL + item.url} key={item} alt='' className='img-tongkang mb-12' />
                      </div>
                    ))}
                  </Grid>}

                {image.length === 0 &&
                  <DropZoneUnggahImage handleUpload={handleCloseImageUpload} />
                }


                <Hidden lgDown><ModalUnggahImageBaruLayer open={isOpenUploadImage} close={handleCloseImageUpload}></ModalUnggahImageBaruLayer>
                </Hidden>

                <Hidden lgUp><DrawerUploadImage open={isOpenUploadImage} close={handleCloseImageUpload}></DrawerUploadImage>
                </Hidden>

                <Grid container justifyContent='space-between' direction='row'>
                  <Grid item style={{ width: "calc(50% - 10px)" }}><p className='c-08 s-1419 w-500 mb4 mt-24'>{translate('jual-beli.nama-tug-boat')}</p></Grid>
                  <Grid item style={{ width: "calc(50% - 10px)" }}><p className='c-08 s-1419 w-500 mb4 mt-24 '>{translate('jual-beli.nama-tongkang')}</p></Grid>
                </Grid>
                {/* Form Nama Tongkang Section */}
                <Grid container justifyContent='space-between' direction='row'>
                  <TextField error={validationNamatug} sx={{
                    width: "calc(50% - 10px)", input: {
                      fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                  }} value={nama_tug_boat} onChange={(e) => setNama_tug_boat(e.target.value)} variant="outlined" placeholder={translate('jual-beli.placeholder-tug-boat')} />
                  <TextField error={validationNamaTongkang} sx={{
                    width: "calc(50% - 10px)", input: {
                      fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                  }} value={nama_tongkang} onChange={(e) => setNama_tongkang(e.target.value)} variant="outlined" placeholder={translate('jual-beli.placeholder-nama-tongkang')} />
                </Grid>
                {/* Form Ukuran Tongkang Section */}
                <p className='c-08 s-1419 w-500 mb4 mt-24'>{translate('page_explore.ukurantongkang')}</p>
                <Grid container spacing={2} direction={'row'} className='box-group-select'>
                  {size_list.map((item, i) => (
                    <Grid item xs={6}>
                      <div className={selectukurantongkang.includes(item) && validationukurantongkang === false ? 'form-radio-select' : validationukurantongkang === true ? 'form-radio-error' : 'form-radio'}>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                        >

                          <FormControlLabel key={i}
                            onChange={(e) => handleChangeRadioUkuran(e, i)} value={item} control={<Radio error={validationukurantongkang} checked={selectukurantongkang.includes(item)} sx={{
                              color: "#E8EBF2",

                              '&.Mui-checked': { color: "#3BB56C", },
                            }} />} label={<Typography className='w-400' sx={{ fontSize: 14, color: '#081130' }}>{item}</Typography>} className='w-500 s-1419' />


                        </RadioGroup>
                      </div>
                    </Grid>
                  ))}
                </Grid>

                <Collapse in={openUkuran} timeout="auto"
                  unmountOnExit>
                  <TextField
                    error={validationukurantongkang}
                    className="box-input-filter"
                    onChange={(e) => {
                      const value = parseInt(e.target.value);
                      setUkuran_tongkang(e.target.value)
                      if (value > 500) {
                        setvalidationukurantongkang(true)
                      } else {
                        setvalidationukurantongkang(false)
                      }
                    }}
                    sx={{
                      width: '100%', marginBottom: "14px", input: {
                        fontSize: '14px', fontFamily: 'Satoshi-500'
                      },
                    }}
                    aria-describedby="outlined-weight-helper-text"
                    placeholder={translate('status.placeholderukurantongkang')}

                  />
                </Collapse>
                {/* <p className='c-08 s-1419 w-500 mb4 mt-10'>{translate('jual-beli.jenismuatan')}</p>
                <Grid container spacing={2} direction={'row'} className='box-group-select'>
                  {payload_type.map((item, i) => (
                    <Grid item xs={6}>
                      <div className={selectjenismuatan.includes(item) && validationjenismuatan === false ? 'form-radio-select' : validationjenismuatan === true ? 'form-radio-error' : 'form-radio'}>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"

                        >

                          <FormControlLabel key={i}
                            onChange={(e) => handleChangeRadioJenis(e, i)} value={item} control={<Radio checked={selectjenismuatan.includes(item)} sx={{
                              color: "#E8EBF2",

                              '&.Mui-checked': { color: "#3BB56C", },
                            }} />} label={<Typography className='w-400' sx={{ fontSize: 14, color: '#081130' }}>{item}</Typography>} className='w-500 s-1419' />


                        </RadioGroup>
                      </div>
                    </Grid>
                  ))}
                </Grid>
                <Collapse in={openJenis} timeout="auto"
                  unmountOnExit>
                  <TextField
                    error={validationjenismuatan}
                    className="box-input-filter"
                    onChange={(e) => { setJenis_muatan(e.target.value) }}
                    sx={{
                      width: '100%', marginBottom: "14px", input: {
                        fontSize: '14px', fontFamily: 'Satoshi-500'
                      },
                    }}
                    aria-describedby="outlined-weight-helper-text"
                    placeholder={translate('status.placeholderjenismuatan')}
                  />
                </Collapse> */}

                {/* <p className='c-08 s-1419 w-500 mb4 mt-10'>{translate('page_explore.quantitymuatan')}</p>
                <FormControl sx={{ width: '100%' }} variant="outlined">
                  <TextField sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                      display: "none",
                    },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    }, input: {
                      fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                  }}
                    error={validationqty}
                    helperText={validationqty ? translate('status.limitqty') + ' ' + validationPriceQty.minimum_quantity_for_tongkang.toString() + ' - ' + validationPriceQty.maximum_quantity_for_tongkang.toString() : ''}
                    type='number' className='box-input-filter' value={quantity_muatan}
                    onChange={(e) => {
                      const value = parseInt(e.target.value);
                      setQuantity_muatan(value);
                      if (value > parseInt(validationPriceQty.maximum_quantity_for_tongkang)) {
                        setvalidationqty(true)
                      } else if (value < parseInt(validationPriceQty.minimum_quantity_for_tongkang)) {
                        setvalidationqty(true)
                      } else {
                        setvalidationqty(false)
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" >
                          <VerticalDividerQuantity />
                          <Typography className="w-700" sx={{ fontSize: 14, color: '#56617B' }}>
                            MT
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    aria-describedby="outlined-weight-helper-text" placeholder={translate('jual-beli.placeholder-quantitymuatan')}
                  />
                </FormControl> */}
              </Grid>
              <Grid item xs={3} className='wd-252'>
              </Grid>
            </Grid>

            <Grid container className='line mt-24 mb-32'>

            </Grid>
            {/* Form Detail Tongkang Section */}
            <Grid container direction='row' justifyContent='space-between'>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Typography className="w-700" sx={{ fontSize: 24, mb: '16px', color: '#081130' }}>{translate('jual-beli.detail-tongkang')}</Typography>
                <p className='c-56 w-400 s-1421 mb-16'>{translate('jual-beli.subtitle-detail-tongkang')}</p>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} className='form-input'>

                <p className='c-08 s-1419 w-500 mb4 mt-10'>{translate('jual-beli.hargasewatongkang')}</p>
                {/* <Grid container spacing={2} direction={'row'} className='box-group-select'>
                  {price_list.map((item, i) => (
                    <Grid item xs={6}>
                      <div className={selectrenttype.includes(item) ? 'form-radio-select' : 'form-radio'}>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                        >
                          <FormControlLabel disabled
                            value={item} control={<Radio checked={selectrenttype.includes(item)} sx={{
                              color: "#E8EBF2",
                              '&.Mui-checked': { color: "#3BB56C", },
                            }} />} label={<Typography className='w-400' sx={{ fontSize: 14, color: '#081130' }}>{item}</Typography>} className='w-500 s-1419' />
                        </RadioGroup>
                      </div>
                    </Grid>
                  ))}
                </Grid> */}
                {/* Form Harga Sewa Tongkang Section */}
                <TextField className='box-input-form' error={validationprice} placeholder={validationPriceQty.minimum_prices_for_tongkang + '-' + validationPriceQty.maximum_prices_for_tongkang} sx={{

                  width: '100%',
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                    display: "none",
                  },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                  input: {
                    fontSize: '14px', fontFamily: 'Satoshi-500'
                  },
                }}
                  helperText={validationprice ? translate('status.limitharga') + ' ' + validationPriceQty.minimum_prices_for_tongkang.toString() + ' - ' + validationPriceQty.maximum_prices_for_tongkang.toString() : ''}
                  type='number' value={price} onChange={(e) => {
                    const value = parseInt(e.target.value);
                    setPrice(value);
                    if (value > parseInt(validationPriceQty.maximum_prices_for_tongkang)) {
                      setvalidationprice(true)
                    } else if (value < parseInt(validationPriceQty.minimum_prices_for_tongkang)) {
                      setvalidationprice(true)
                    } else {
                      setvalidationprice(false)
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Select
                          disableUnderline
                          variant='standard'
                          className='box-form-currency'
                          // SelectDisplayProps={{ style: { padding: 0 } }}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                height: '120px',
                                '& .MuiMenuItem-root': {
                                },
                              },
                            },
                          }}
                          sx={{
                            "& fieldset": { border: 'none' },
                            boxShadow: "none",
                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                          }}
                          IconComponent={() => (
                            <KeyboardArrowDownIcon sx={{ width: '20px', height: '20px', fill: '#56617B', position: 'absolute', right: 0, pointerEvents: 'none' }} />
                          )}
                          value={selectcurrency} onChange={(e) => handleChangeCurrency(e)} displayEmpty >
                          {listCurrency.map((item, i) => (
                            <MenuItem key={item.id} value={item.id}>{item.name} </MenuItem>
                          ))}
                        </Select>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end" >
                        <VerticalDividerPrice />
                        <Typography className="w-700" sx={{ fontSize: 14, color: '#56617B', mr: 1 }}>
                          /month
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
                {/* Form Tahun buat Tongkang Section */}
                <p className='c-08 s-1419 w-500 mb4 mt-24'>{translate('permintaan.tahunbuat')}</p>
                <Select
                  error={validationtahunbuat}
                  InputProps={{

                    startAdornment: (
                      <InputAdornment position="start" >
                        <VerticalDividerPrice />
                        <Typography className="w-700" sx={{ fontSize: 14, color: '#56617B', mr: 1 }}>
                          /month
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  IconComponent={() => (
                    <KeyboardArrowDownIcon sx={{ mr: '16px', width: '20px', height: '20px', fill: '#56617B' }} />
                  )}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        height: '352px',
                        '& .MuiMenuItem-root': {
                        },
                      },
                    },
                  }}
                  style={{ border: '1px solid var(--grey-stroke, #e8ebf2)', marginTop: '4px', borderRadius: '8px', width: '100%', }} value={tahun_buat} onChange={(e) => setTahun_buat(e.target.value)} displayEmpty >

                  <MenuItem value="" style={{ display: "none" }}>
                    <p className='w-400 s-1420 c-87'> {translate('permintaan.placeholder-tahunbuat')}</p>
                  </MenuItem>
                  {options.map((item, i) => (
                    <MenuItem value={item} key={item}>{item} </MenuItem>))}
                </Select>

                {/* Form Lokasi Tongkang Section */}
                <p className='c-08 s-1419 w-500 mb4 mt-24'>Lokasi tongkang</p>
                <FormProvince validation={validationlokasitongkang} select={(e) => setid_tongkang_location(e.id)} selectName={(e) => { }} currentValue={id_tongkang_location} placeholder={translate('title.placeholder-select-location')} />


              </Grid>
              <Grid item xs={3} className='wd-252'>
              </Grid>
            </Grid>

            <Grid container className='line mt-24 mb-32'>

            </Grid>
            {/* Form Dokumen Tongkang Section */}
            <Grid container direction='row' justifyContent='space-between'>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Typography className="w-700" sx={{ fontSize: 24, mb: '16px', color: '#081130' }}>{translate('jual-beli.dokumen-tongkang')}</Typography>
                <p className='c-56 w-400 s-1421'>{translate('jual-beli.subtitle-dokumen-tongkang')}</p>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} >
                <Grid container direction='row' justifyContent='space-between'>
                  <p className='c-08 s-1419 w-500 mb4'>{translate('jual-beli.dokumen')}</p>
                  {file.length > 0 &&
                    <label for="upload2">
                      <p onClick={(e) => {
                        handleOpenModalUpload();
                      }} className='c-24 s-1419 w-500 mb4'>{translate('jual-beli.unggah-dokument')}</p>

                    </label>
                  }
                </Grid>
                {/* <DisplayPDF responsive /> */}
                <Hidden smUp>
                  {file.length === 0 ? (
                    <></>
                  ) : file.length > 2 ? (
                    <Grid style={{ marginTop: "5px" }} container direction={'row'} spacing={2}>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Card sx={{ borderRadius: 2, height: '150px', width: '150px' }}>
                          <DisplayPDFFile
                            url={`${file[0].url}`}
                            key={0}
                            ext={`${file[0].ext}`}
                            name={`${file[0].name}`}
                          />
                        </Card>
                      </Grid>

                      <Grid onClick={(e) => {
                        handleOpenDrawerDoc(file)

                      }} item xs={6} sm={6} md={6} lg={6}>
                        <Card sx={{ position: 'absolute', background: 'var(--overlay, rgba(5, 9, 16, 0.56));', zIndex: 9999, borderRadius: '8px', height: '150px', width: '150px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                          <Typography sx={{ fontSize: 36, color: '#FFFFFF' }} className='w-700'>+{file.length}</Typography>
                        </Card>
                        <Card sx={{ borderRadius: 2, position: 'relative', height: '150px', width: '150px' }}>
                          <DisplayPDFFile
                            url={`${file[1].url}`}
                            key={1}
                            ext={`${file[1].ext}`}
                            name={`${file[1].name}`}
                          />
                        </Card>
                      </Grid>

                    </Grid>
                  ) : (
                    <Grid style={{ marginTop: "5px" }} container direction={'row'} spacing={2}>
                      {file.map((item, i) => (

                        <Grid item xs={6} sm={6} md={6} lg={6}>

                          <Card sx={{ borderRadius: 2 }}>
                            <DisplayPDFFile
                              url={`${config.baseURL}${item.url}`}
                              key={i}
                              ext={`${item.ext}`}
                              name={`${item.name}`}
                            />
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Hidden>

                {/* <DisplayPDF dekstop /> */}
                <Hidden smDown>
                  {file.length === 0 ? (
                    <></>
                  ) : (
                    <Grid style={{ marginTop: "5px" }} container direction={'row'} spacing={2}>
                      {file.map((item, i) => (

                        <Grid item xs={6} sm={6} md={6} lg={6}>

                          <Card sx={{ borderRadius: 2, position: 'relative' }}>
                            <div style={{ position: 'absolute', right: '0%', display: 'flex', }}>
                              <IconButton onClick={() => {
                                deleteFileonArrayDrawer(i)

                              }} alt='' style={{ marginRight: '8px', cursor: 'pointer', background: 'transparent' }} aria-label="settings " sx={{ background: 'rgba(0, 0, 0, 0.1)', }}>
                                <img style={{ height: '24px', width: '24px' }} src={delete_doc} alt='' />
                              </IconButton>
                            </div>
                            <DisplayPDFFile
                              url={`${config.baseURL}${item.url}`}
                              key={i}
                              ext={`${item.ext}`}
                              name={`${item.name}`}
                            />
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Hidden>
                {file.length === 0 &&

                  <DropZoneUnggah handleUpload={handleCloseModalUpload} />
                }

                <Hidden lgDown><ModalUnggahDoc open={isOpenUploadDoc} close={handleCloseModalUpload}></ModalUnggahDoc>
                </Hidden>

                <Hidden lgUp><DrawerUploadDoc open={isOpenUploadDoc} close={handleCloseModalUpload}></DrawerUploadDoc>
                </Hidden>
                {/* <p className='c-08 s-1419 w-500 mb4 mt-24'>{translate('detail-explore.deskripsi')}</p>
                <TextField className='box-text-area' multiline rows={3} value={description} onChange={(e) => setDescription(e.target.value)}

                  sx={{
                    width: '100%', input: {
                      fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                  }} placeholder={translate('jual-beli.placeholder-deskripsi')}
                /> */}

                {companyList.length > 0 && <p className='c-56 s-1419 w-500 mb4 mt-24 '>{translate('jual-beli.sublabel-checkbox')}</p>}
                {companyList.length > 0 ?
                  <Grid container direction='column' className='selected-company'>
                    {
                      companyList.map((item, i) => (
                        <Grid key={item.id} direction='row' alignItems='center' justifyContent='space-between' style={{ width: "100%", display: "flex" }} className='box'>
                          <div className='flex alignC'>
                            {item.url_image_company === '' || item.url_image_company === null ? <Avatar sx={{ bgcolor: 'blue', mr: 2 }} aria-label="recipe">PT</Avatar> :
                              <img src={config.baseURL + item.url_image_company} alt='' className='ic-company' />}
                            <p className='w-700 s-1824 c-08'>{item.name} </p>
                          </div>
                          <Radio sx={{
                            color: "#E8EBF2",
                            '&.Mui-checked': {
                              color: "#3BB56C",
                            },
                          }} value={item.id} checked={item.id == id_company_listing ? true : false} name="radio-buttons" onChange={(e) => setCompany(e.target.value)} inputProps={{ 'aria-label': 'A' }} />
                        </Grid>))}

                  </Grid> : <Typography className="w-500" sx={{ fontSize: 16, mt: '8px', }}>{translate('status.belumtergabungperusahaan')}</Typography>}
              </Grid>
              <Grid item xs={3} className='wd-252'>
              </Grid>
            </Grid>

            <Grid container className='line mt-24 mb-56'>

            </Grid>
            {/* Button Section */}
            <Hidden smDown>
              <Grid className='mb-104 in-container' container justifyContent='space-between' direction='row'>
                <Button
                  onClick={() => {
                    handleOpenBatalForm();
                  }}
                  size="large"
                  className="w-500 pointer"
                  variant="text"
                  style={{
                    marginBottom: '16px',
                    color: ' #2457B9',
                    textTransform: 'none'

                  }}
                >
                  {translate('jual-beli.btn-cancel')}
                </Button>

                <Stack direction={'row'}>
                  <Button

                    onClick={(e) => { submitDraftTimeCharter(3); setPosition('center') }}
                    size="large"
                    className="w-500"
                    variant="outlined"
                    style={{
                      marginRight: '16px',
                      marginBottom: '16px',
                      borderColor: ' #2457B9',
                      textTransform: 'none',

                    }}
                  >
                    {translate('jual-beli.simpandraft')}
                  </Button>
                  <Button

                    onClick={(e) => { submitTimeCharter(1) }}
                    size="large"
                    className="w-500"
                    variant="contained"
                    style={{
                      marginBottom: '16px',
                      background: ' #2457B9',
                      textTransform: 'none',

                    }}
                  >
                    Publish
                  </Button>

                </Stack>

              </Grid>
            </Hidden>
            <Hidden smUp>
              <Grid spacing={1} container justifyContent='space-between' direction='row' style={{ marginBottom: '16px' }}>
                <Grid item xs={6}>
                  <Button
                    onClick={() => {
                      handleOpenBatalForm();
                    }}
                    fullWidth
                    size="large"
                    className="w-500"
                    variant="outlined"
                    style={{
                      color: '#2457B9',
                      textTransform: 'none'

                    }}
                  >
                    {translate('jual-beli.btn-cancel')}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    onClick={(e) => {
                      handleOpenModal()

                    }}
                    size="large"
                    className="w-500"
                    variant="contained"
                    style={{
                      background: ' #2457B9',
                      textTransform: 'none'

                    }}
                  >
                    {translate('jual-beli.simpan')}
                  </Button>
                </Grid>


              </Grid>
              <DrawerpublishListing SubmitResponsivePublish={SubmitResponsiveDraftTC} SubmitResponsiveDraft={SubmitResponsiveDraftTC} open={isOpenModal} close={handleCloseModal}></DrawerpublishListing>
              <DrawerHapusDoc title={'jual-beli.dokumen-tongkang'} file_doc={file} submit={(e, y) => { handleChangeFileFromDeleteResponsive(e, y) }} open={isOpenDrawerDoc} close={handleCloseDrawerDoc} ></DrawerHapusDoc>
            </Hidden>
          </div>}

          {/* Freight Charter Section */}
          {activeTab === "freight-charter" && <div>
            {/* Form Spesifikasi Tongkang Section */}
            <Grid container direction='row' justifyContent='space-between'>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Typography className="w-700" sx={{ fontSize: 24, mb: '16px', color: '#081130' }}>
                  {translate('jual-beli.spesifikasi-tongkang')}
                </Typography>
                <p className='c-56 w-400 s-1421 mb-16'>{translate('jual-beli.subtitle-spesifikasi-tongkang')}</p>
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4} className='form-input'>
                {/* Form Image Tongkang Section */}
                <Grid container direction='row' justifyContent='space-between'>

                  {image.length > 0 && image.length < 3 &&
                    <label for="upload2">
                      <p onClick={(e) => {
                        handleOpenImageUpload();
                      }} className='c-24 s-1419 w-500 mb4'>{translate('status.unggahimage')}</p>

                    </label>
                  }
                </Grid>
                {image.length > 0 &&
                  <Grid container direction='row' justifyContent='space-between' className='mt-10'>
                    {image.map((item, i) => (
                      <div style={{ position: 'relative' }}>
                        <div style={{ position: 'absolute', display: 'flex', right: '0%' }}>
                          <IconButton onClick={() => {
                            deleteImageonArrayDrawer(i)

                          }} alt='' style={{ marginRight: '8px', cursor: 'pointer', background: 'transparent' }} aria-label="settings " sx={{ background: 'rgba(0, 0, 0, 0.1)', }}>
                            <img style={{ height: '24px', width: '24px' }} src={delete_doc} alt='' />
                          </IconButton>
                        </div>
                        <img src={config.baseURL + item.url} key={item} alt='' className='img-tongkang mb-12' />
                      </div>
                    ))}
                  </Grid>}

                {image.length === 0 &&
                  <DropZoneUnggahImage handleUpload={handleCloseImageUpload} />
                }


                <Hidden lgDown><ModalUnggahImageBaruLayer open={isOpenUploadImage} close={handleCloseImageUpload}></ModalUnggahImageBaruLayer>
                </Hidden>

                <Hidden lgUp><DrawerUploadImage open={isOpenUploadImage} close={handleCloseImageUpload}></DrawerUploadImage>
                </Hidden>
                {/* Form Nama Tongkang Section */}
                <Grid container justifyContent='space-between' direction='row'>
                  <Grid item style={{ width: "calc(50% - 10px)" }}><p className='c-08 s-1419 w-500 mb4 mt-24'>{translate('jual-beli.nama-tug-boat')}</p></Grid>
                  <Grid item style={{ width: "calc(50% - 10px)" }}><p className='c-08 s-1419 w-500 mb4 mt-24 '>{translate('jual-beli.nama-tongkang')}</p></Grid>
                </Grid>
                <Grid container justifyContent='space-between' direction='row'>
                  <TextField error={validationNamatug} sx={{
                    width: "calc(50% - 10px)", input: {
                      fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                  }} value={nama_tug_boat} onChange={(e) => setNama_tug_boat(e.target.value)} variant="outlined" placeholder={translate('jual-beli.placeholder-tug-boat')} />
                  <TextField error={validationNamaTongkang} sx={{
                    width: "calc(50% - 10px)", input: {
                      fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                  }} value={nama_tongkang} onChange={(e) => setNama_tongkang(e.target.value)} variant="outlined" placeholder={translate('jual-beli.placeholder-nama-tongkang')} />
                </Grid>
                {/* Form Ukuran Tongkang Section */}
                <p className='c-08 s-1419 w-500 mb4 mt-24'>{translate('page_explore.ukurantongkang')}</p>
                <Grid container spacing={2} direction={'row'} className='box-group-select'>
                  {size_list.map((item, i) => (
                    <Grid item xs={6}>
                      <div className={selectukurantongkang.includes(item) && validationukurantongkang === false ? 'form-radio-select' : validationukurantongkang === true ? 'form-radio-error' : 'form-radio'}>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                        >

                          <FormControlLabel key={i}
                            onChange={(e) => handleChangeRadioUkuran(e, i)} value={item} control={<Radio error={validationukurantongkang} checked={selectukurantongkang.includes(item)} sx={{
                              color: "#E8EBF2",

                              '&.Mui-checked': { color: "#3BB56C", },
                            }} />} label={<Typography className='w-400' sx={{ fontSize: 14, color: '#081130' }}>{item}</Typography>} className='w-500 s-1419' />


                        </RadioGroup>
                      </div>
                    </Grid>
                  ))}
                </Grid>

                <Collapse in={openUkuran} timeout="auto"
                  unmountOnExit>
                  <TextField
                    error={validationukurantongkang}
                    className="box-input-filter"
                    onChange={(e) => {
                      const value = parseInt(e.target.value);
                      setUkuran_tongkang(e.target.value)
                      if (value > 500) {
                        setvalidationukurantongkang(true)
                      } else {
                        setvalidationukurantongkang(false)
                      }
                    }}
                    sx={{
                      width: '100%', marginBottom: "14px", input: {
                        fontSize: '14px', fontFamily: 'Satoshi-500'
                      },
                    }}
                    aria-describedby="outlined-weight-helper-text"
                    placeholder={translate('status.placeholderukurantongkang')}

                  />
                </Collapse>
                {/* Form Jenis muatan Tongkang Section */}
                <p className='c-08 s-1419 w-500 mb4 mt-10'>{translate('jual-beli.jenismuatan')}</p>
                <Grid container spacing={2} direction={'row'} className='box-group-select'>
                  {payload_type.map((item, i) => (
                    <Grid item xs={6}>
                      <div className={selectjenismuatan.includes(item) && validationjenismuatan === false ? 'form-radio-select' : validationjenismuatan === true ? 'form-radio-error' : 'form-radio'}>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"

                        >

                          <FormControlLabel key={i}
                            onChange={(e) => handleChangeRadioJenis(e, i)} value={item} control={<Radio checked={selectjenismuatan.includes(item)} sx={{
                              color: "#E8EBF2",

                              '&.Mui-checked': { color: "#3BB56C", },
                            }} />} label={<Typography className='w-400' sx={{ fontSize: 14, color: '#081130' }}>{item}</Typography>} className='w-500 s-1419' />


                        </RadioGroup>
                      </div>
                    </Grid>
                  ))}
                </Grid>
                <Collapse in={openJenis} timeout="auto"
                  unmountOnExit>
                  <TextField
                    error={validationjenismuatan}
                    className="box-input-filter"
                    onChange={(e) => { setJenis_muatan(e.target.value) }}
                    sx={{
                      width: '100%', marginBottom: "14px", input: {
                        fontSize: '14px', fontFamily: 'Satoshi-500'
                      },
                    }}
                    aria-describedby="outlined-weight-helper-text"
                    placeholder={translate('status.placeholderjenismuatan')}
                  />
                </Collapse>

                {/* <p className='c-08 s-1419 w-500 mb4 mt-10'>{translate('page_explore.quantitymuatan')}</p>
                <FormControl sx={{ width: '100%' }} variant="outlined">
                  <TextField sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                      display: "none",
                    },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    }, input: {
                      fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                  }}
                    error={validationqty}
                    helperText={validationqty ? translate('status.limitqty') + ' ' + validationPriceQty.minimum_quantity_for_tongkang.toString() + ' - ' + validationPriceQty.maximum_quantity_for_tongkang.toString() : ''}
                    type='number' className='box-input-filter' value={quantity_muatan}
                    onChange={(e) => {
                      const value = parseInt(e.target.value);
                      setQuantity_muatan(value);
                      if (value > parseInt(validationPriceQty.maximum_quantity_for_tongkang)) {
                        setvalidationqty(true)
                      } else if (value < parseInt(validationPriceQty.minimum_quantity_for_tongkang)) {
                        setvalidationqty(true)
                      } else {
                        setvalidationqty(false)
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" >
                          <VerticalDividerQuantity />
                          <Typography className="w-700" sx={{ fontSize: 14, color: '#56617B' }}>
                            MT
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    aria-describedby="outlined-weight-helper-text" placeholder={translate('jual-beli.placeholder-quantitymuatan')}
                  />
                </FormControl> */}
              </Grid>
              <Grid item xs={3} className='wd-252'>
              </Grid>
            </Grid>

            <Grid container className='line mt-24 mb-32'>

            </Grid>
            {/* Form Detail Tongkang Section */}
            <Grid container direction='row' justifyContent='space-between'>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Typography className="w-700" sx={{ fontSize: 24, mb: '16px', color: '#081130' }}>{translate('jual-beli.detail-tongkang')}</Typography>
                <p className='c-56 w-400 s-1421 mb-16'>{translate('jual-beli.subtitle-detail-tongkang')}</p>
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={4} className='form-input'>

                <Collapse in={true} timeout="auto"
                  unmountOnExit>
                  <Grid container spacing={2} direction={'row'} className='box-group-select'>
                    {/* {price_list.map((item, i) => (
                      <Grid key={item} item xs={6}>
                        <div className={selectrenttype.includes(item) ? 'form-radio-select' : 'form-radio'}>
                          <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                          >
                            <FormControlLabel disabled
                              value={item} control={<Radio checked={selectrenttype.includes(item)} sx={{
                                color: "#E8EBF2",
                                '&.Mui-checked': { color: "#3BB56C", },
                              }} />} label={<Typography className='w-400' sx={{ fontSize: 14, color: '#081130' }}>{item}</Typography>} className='w-500 s-1419' />
                          </RadioGroup>
                        </div>
                      </Grid>
                    ))} */}
                  </Grid>
                </Collapse>

                {/* Form Loading Area Section */}
                <p className='c-08 s-1419 w-500 mb4 mt-10'>Loading Area</p>
                <FormProvince validation={validationloadingarea} select={(e) => { setid_area_muat(e.id); setid_pelabuhan_muat("") }} selectName={(e) => { }} currentValue={id_area_muat} placeholder={translate('title.placeholder-select-location')} />
                {/* Form Discharge Area Section */}
                <p className='c-08 s-1419 w-500 mb4 mt-24'>Discharge Area (optional)</p>

                <FormProvince select={(e) => { setid_area_bongkar(e.id); setid_pelabuhan_bongkar("") }} selectName={(e) => { }} currentValue={id_area_bongkar} placeholder={translate('title.placeholder-select-location')} />
                {/* Form harga tongkang Section */}
                {(id_area_muat) && <div>
                  <p className='c-08 s-1419 w-500 mt-10'>{translate('jual-beli.hargasewatongkang')}</p>

                  <TextField className='box-input-form' error={validationprice} placeholder={validationPriceQty.minimum_prices_for_tongkang + '-' + validationPriceQty.maximum_prices_for_tongkang} sx={{

                    width: '100%',
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                      display: "none",
                    },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                    input: {
                      fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                  }}
                    helperText={validationprice ? translate('status.limitharga') + ' ' + validationPriceQty.minimum_prices_for_tongkang.toString() + ' - ' + validationPriceQty.maximum_prices_for_tongkang.toString() : ''}
                    type='number' value={price} onChange={(e) => {
                      const value = parseInt(e.target.value);
                      setPrice(value);
                      if (value > parseInt(validationPriceQty.maximum_prices_for_tongkang)) {
                        setvalidationprice(true)
                      } else if (value < parseInt(validationPriceQty.minimum_prices_for_tongkang)) {
                        setvalidationprice(true)
                      } else {
                        setvalidationprice(false)
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Select
                            disableUnderline
                            variant='standard'
                            className='box-form-currency'
                            // SelectDisplayProps={{ style: { padding: 0 } }}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  height: '120px',
                                  '& .MuiMenuItem-root': {
                                  },
                                },
                              },
                            }}
                            sx={{
                              "& fieldset": { border: 'none' },
                              boxShadow: "none",
                              ".MuiOutlinedInput-notchedOutline": { border: 0 },
                              "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                              {
                                border: 0,
                              },
                              "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                border: 0,
                              },
                            }}
                            IconComponent={() => (
                              <KeyboardArrowDownIcon sx={{ width: '20px', height: '20px', fill: '#56617B', position: 'absolute', right: 0, pointerEvents: 'none' }} />
                            )}
                            value={selectcurrency} onChange={(e) => handleChangeCurrency(e)} displayEmpty >
                            {listCurrency.map((item, i) => (
                              <MenuItem key={item.id} value={item.id}>{item.name} </MenuItem>
                            ))}
                          </Select>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end" >
                          <VerticalDividerQuantity />
                          <Typography className="w-700" sx={{ fontSize: 14, color: '#56617B', mr: 1 }}>
                            /MT
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>}
                {/* Form Tahun buat Section */}
                <p className='c-08 s-1419 w-500 mb4 mt-24'>{translate('permintaan.tahunbuat')}</p>
                <Select
                  error={validationtahunbuat}
                  IconComponent={() => (
                    <KeyboardArrowDownIcon sx={{ mr: '16px', width: '20px', height: '20px', fill: '#56617B' }} />
                  )}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        height: '352px',
                        '& .MuiMenuItem-root': {
                        },
                      },
                    },
                  }}
                  style={{ border: '1px solid var(--grey-stroke, #e8ebf2)', marginTop: '4px', borderRadius: '8px', width: '100%' }} value={tahun_buat} onChange={(e) => setTahun_buat(e.target.value)} displayEmpty >
                  <MenuItem value="" style={{ display: "none" }}>
                    <p className='w-400 s-1420 c-87'>{translate('permintaan.placeholder-tahunbuat')}</p>
                  </MenuItem>
                  {options.map((item, i) => (
                    <MenuItem value={item} key={item}>{item} </MenuItem>))}
                </Select>

                {/* Form Loading port Section */}
                <p className='c-08 s-1419 w-500 mb4 mt-24'>Loading Port (optional)</p>

                <FormLoadingPort disabled={id_area_muat ? false : true} select={(e) => setid_pelabuhan_muat(e.port_id)} setNameValue={(e) => { setpelabuhan_muat_name(e) }} nameValue={pelabuhan_muat_name} selectName={(e) => { }} currentValue={id_pelabuhan_muat} placeholder={translate('jual-beli.placeholder-loadingport')} id_location={id_area_muat} />

                {/* Form Discharge Port Section */}
                <p className='c-08 s-1419 w-500 mb4 mt-24'>Discharge Port (optional) </p>

                <FormLoadingPort disabled={id_area_bongkar ? false : true} select={(e) => setid_pelabuhan_bongkar(e.port_id)} setNameValue={(e) => { setpelabuhan_bongkar_name(e) }} nameValue={pelabuhan_bongkar_name} selectName={(e) => { }} currentValue={id_pelabuhan_bongkar} placeholder={"Pilih discharge port"} id_location={id_area_bongkar} />


              </Grid>
              <Grid item xs={3} className='wd-252'>
              </Grid>
            </Grid>

            <Grid container className='line mt-24 mb-32'>

            </Grid>
            {/* Form Dokumen Tongkang Section */}
            <Grid container direction='row' justifyContent='space-between'>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Typography className="w-700" sx={{ fontSize: 24, mb: '16px', color: '#081130' }}>{translate('jual-beli.dokumen-tongkang')}</Typography>
                <p className='c-56 w-400 s-1421'>{translate('jual-beli.subtitle-dokumen-tongkang')}</p>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} >
                <Grid container direction='row' justifyContent='space-between'>
                  <p className='c-08 s-1419 w-500 mb4'>{translate('jual-beli.dokumen')}</p>
                  {file.length > 0 &&
                    <label for="upload2">
                      <p onClick={(e) => {
                        handleOpenModalUpload();
                      }} className='c-24 s-1419 w-500 mb4'>{translate('jual-beli.unggah-dokument')}</p>

                    </label>
                  }
                </Grid>
                {/* <DisplayPDF responsive /> */}
                <Hidden smUp>
                  {file.length === 0 ? (
                    <></>
                  ) : file.length > 2 ? (
                    <Grid style={{ marginTop: "5px" }} container direction={'row'} spacing={2}>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Card sx={{ borderRadius: 2, height: '150px', width: '150px' }}>
                          <DisplayPDFFile
                            url={`${file[0].url}`}
                            key={0}
                            ext={`${file[0].ext}`}
                            name={`${file[0].name}`}
                          />
                        </Card>
                      </Grid>

                      <Grid onClick={(e) => {
                        handleOpenDrawerDoc(file)

                      }} item xs={6} sm={6} md={6} lg={6}>
                        <Card sx={{ position: 'absolute', background: 'var(--overlay, rgba(5, 9, 16, 0.56));', zIndex: 9999, borderRadius: '8px', height: '150px', width: '150px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                          <Typography sx={{ fontSize: 36, color: '#FFFFFF' }} className='w-700'>+{file.length}</Typography>
                        </Card>
                        <Card sx={{ borderRadius: 2, position: 'relative', height: '150px', width: '150px' }}>
                          <DisplayPDFFile
                            url={`${file[1].url}`}
                            key={1}
                            ext={`${file[1].ext}`}
                            name={`${file[1].name}`}
                          />
                        </Card>
                      </Grid>

                    </Grid>
                  ) : (
                    <Grid style={{ marginTop: "5px" }} container direction={'row'} spacing={2}>
                      {file.map((item, i) => (

                        <Grid item xs={6} sm={6} md={6} lg={6}>

                          <Card sx={{ borderRadius: 2 }}>
                            <DisplayPDFFile
                              url={`${config.baseURL}${item.url}`}
                              key={i}
                              ext={`${item.ext}`}
                              name={`${item.name}`}
                            />
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Hidden>

                {/* <DisplayPDF dekstop /> */}
                <Hidden smDown>
                  {file.length === 0 ? (
                    <></>
                  ) : (
                    <Grid style={{ marginTop: "5px" }} container direction={'row'} spacing={2}>
                      {file.map((item, i) => (

                        <Grid item xs={6} sm={6} md={6} lg={6}>

                          <Card sx={{ borderRadius: 2, position: 'relative' }}>
                            <div style={{ position: 'absolute', right: '0%', display: 'flex', }}>
                              <IconButton onClick={() => {
                                deleteFileonArrayDrawer(i)

                              }} alt='' style={{ marginRight: '8px', cursor: 'pointer', background: 'transparent' }} aria-label="settings " sx={{ background: 'rgba(0, 0, 0, 0.1)', }}>
                                <img style={{ height: '24px', width: '24px' }} src={delete_doc} alt='' />
                              </IconButton>
                            </div>
                            <DisplayPDFFile
                              url={`${config.baseURL}${item.url}`}
                              key={i}
                              ext={`${item.ext}`}
                              name={`${item.name}`}
                            />
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Hidden>
                {file.length === 0 &&

                  <DropZoneUnggah handleUpload={handleCloseModalUpload} />
                }

                <Hidden lgDown><ModalUnggahDoc open={isOpenUploadDoc} close={handleCloseModalUpload}></ModalUnggahDoc>
                </Hidden>

                <Hidden lgUp><DrawerUploadDoc open={isOpenUploadDoc} close={handleCloseModalUpload}></DrawerUploadDoc>
                </Hidden>
                {/* <p className='c-08 s-1419 w-500 mb4 mt-24'>{translate('detail-explore.deskripsi')}</p>
                <TextField className='box-text-area' multiline rows={3} value={description} onChange={(e) => setDescription(e.target.value)}

                  sx={{
                    width: '100%', input: {
                      fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                  }} placeholder={translate('jual-beli.placeholder-deskripsi')}
                /> */}

                {companyList.length > 0 && <p className='c-56 s-1419 w-500 mb4 mt-24 '>{translate('jual-beli.sublabel-checkbox')}</p>}
                {companyList.length > 0 ?
                  <Grid container direction='column' className='selected-company'>
                    {
                      companyList.map((item, i) => (
                        <Grid key={item.id} direction='row' alignItems='center' justifyContent='space-between' style={{ width: "100%", display: "flex" }} className='box'>
                          <div className='flex alignC'>
                            {item.url_image_company === '' || item.url_image_company === null ? <Avatar sx={{ bgcolor: 'blue', mr: 2 }} aria-label="recipe">PT</Avatar> :
                              <img src={config.baseURL + item.url_image_company} alt='' className='ic-company' />}
                            <p className='w-700 s-1824 c-08'>{item.name} </p>
                          </div>
                          <Radio sx={{
                            color: "#E8EBF2",
                            '&.Mui-checked': {
                              color: "#3BB56C",
                            },
                          }} value={item.id} checked={item.id == id_company_listing ? true : false} name="radio-buttons" onChange={(e) => setCompany(e.target.value)} inputProps={{ 'aria-label': 'A' }} />
                        </Grid>))}

                  </Grid> : <Typography className="w-500" sx={{ fontSize: 16, mt: '8px', }}>{translate('status.belumtergabungperusahaan')}</Typography>}
              </Grid>
              <Grid item xs={3} className='wd-252'>
              </Grid>
            </Grid>

            <Grid container className='line mt-24 mb-56'>

            </Grid>
            {/* Button Section */}
            <Hidden smDown>
              <Grid className='mb-104 in-container' container justifyContent='space-between' direction='row'>
                <Button
                  onClick={() => {
                    handleOpenBatalForm();
                  }}
                  size="large"
                  className="w-500 pointer"
                  variant="text"
                  style={{
                    marginBottom: '16px',
                    color: ' #2457B9',
                    textTransform: 'none'

                  }}
                >
                  {translate('jual-beli.btn-cancel')}
                </Button>

                <Stack direction={'row'}>
                  <Button

                    onClick={(e) => { submitDraftFreightCharter(3); setPosition('center') }}
                    size="large"
                    className="w-500"
                    variant="outlined"
                    style={{
                      marginRight: '16px',
                      marginBottom: '16px',
                      borderColor: ' #2457B9',
                      textTransform: 'none',

                    }}
                  >
                    {translate('jual-beli.simpandraft')}
                  </Button>
                  <Button

                    onClick={(e) => { submitFreightCharter(1) }}
                    size="large"
                    className="w-500"
                    variant="contained"
                    style={{
                      marginBottom: '16px',
                      background: ' #2457B9',
                      textTransform: 'none',

                    }}
                  >
                    Publish
                  </Button>

                </Stack>

              </Grid>
            </Hidden>
            <Hidden smUp>
              <Grid spacing={1} container justifyContent='space-between' direction='row' style={{ marginBottom: '16px' }}>
                <Grid item xs={6}>
                  <Button
                    onClick={() => {
                      handleOpenBatalForm();
                    }}
                    fullWidth
                    size="large"
                    className="w-500"
                    variant="outlined"
                    style={{
                      color: '#2457B9',
                      textTransform: 'none'

                    }}
                  >
                    {translate('jual-beli.btn-cancel')}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    onClick={(e) => {
                      handleOpenModal()

                    }}
                    size="large"
                    className="w-500"
                    variant="contained"
                    style={{
                      background: ' #2457B9',
                      textTransform: 'none'

                    }}
                  >
                    {translate('jual-beli.simpan')}
                  </Button>
                </Grid>


              </Grid>
              <DrawerpublishListing SubmitResponsivePublish={SubmitResponsivePublishFC} SubmitResponsiveDraft={SubmitResponsiveDraftFC} open={isOpenModal} close={handleCloseModal}></DrawerpublishListing>
              <DrawerHapusDoc title={'jual-beli.dokumen-tongkang'} file_doc={file} submit={(e, y) => { handleChangeFileFromDeleteResponsive(e, y) }} open={isOpenDrawerDoc} close={handleCloseDrawerDoc} ></DrawerHapusDoc>
            </Hidden>
          </div>}
        </Box>

      </Box>
      <Footer />
      <ModalBatalForm open={openModalBatalForm} close={handleCloseModalBatalForm}></ModalBatalForm>
      <SnackBarDraft position={position} open={isSuccessDraft} close={handleCloseModal}></SnackBarDraft>

    </div>
  )
}
