import "firebase/analytics"
import firebase from "firebase/app"
import "firebase/auth"

const pathname = window.location.pathname.toString().toLowerCase()
let url = "https://indomine-cms.cranium.id/api/auth-firebase/login"
if (pathname === "/signup") {
  url = "https://indomine-cms.cranium.id/api/auth-firebase/register"
}

export const firebaseConfigLogin = {
  apiKey: "AIzaSyCvUS8EUsE8uoHpzFXtjLtw_xziRaIRftc",
  authDomain: "indomine-47748.firebaseapp.com",
  databaseURL: url,
  projectId: "indomine-47748",
  storageBucket: "indomine-47748.appspot.com",
  messagingSenderId: "938953165642",
  appId: "1:938953165642:web:5fb4bb9bb6517c83088fb3",
  measurementId: "G-PZM38R9TNS",
};

firebase.initializeApp(firebaseConfigLogin);
firebase.analytics()