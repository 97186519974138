
import { Box, Button, CircularProgress, FormControl, Grid, Hidden, InputAdornment, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import nikel_icon from "../assets/nikel_icon.svg";
import DateRangePickerCustom from '../components/DateRangePickerCustom';
import DrawerHapusDoc from '../components/DrawerHapusDoc';
import Footer from "../components/Footer";
import FormCompany from "../components/FormCompany";
import FormLoadingPort from '../components/FormLoadingPort';
import ModalBatalForm from '../components/ModalBatalForm';
import NavBar from "../components/Navbar";
import { GetValidationPriceQuantity, addBuatPermintaanNikel, getAllUserTrader } from '../services/api';
import { GlobalState } from "../services/global";
import { ArrowDownPriceComponent, VerticalDividerQuantity } from '../services/primary';
import Value from "../services/value.json";

export default function BeliNikel() {
  const navigate = useNavigate();
  const [translate,] = useTranslation("global");
  const [isLoading, setIsLoading] = useState(false)
  const [kadarNi, setKadarNi] = useState('')
  const [kadarFe, setKadarFe] = useState('')
  const [kadarAir, setKadarAir] = useState('')
  const [siMgRatio, setSiMgRatio] = useState('')
  const [quantity, setQuantity] = useState('')
  const [price, setPrice] = useState('')
  const [id_loading_port, setPort] = useState('')
  const [namePort, setNamePort] = useState('')
  const [namaTambang, setNamaTambang] = useState('')
  const [namaTrader, setNamaTrader] = useState('')
  const [description] = useState('')
  const [traderList, setTraderList] = useState([])
  const [value, setValue] = React.useState('');
  const [value2, setValue2] = React.useState('');
  const [isOpenDrawerDoc, setOpenDrawerDoc] = React.useState(false)
  const [validationPriceQty, setvalidationPriceQty] = useState('')
  const [openModalBatalForm, setOpenModalBatalForm] = useState(false);
  const [selectcurrency, setselectcurrency] = React.useState('idr');
  const [listCurrency] = useState([{ id: 'idr', name: 'Rp' }, { id: 'usd', name: '$' }])
  const { currency, setcurrency } = React.useContext(GlobalState)


  const handleOpenBatalForm = () => {
    setOpenModalBatalForm(true)
  };

  const handleCloseModal = () => {
    setOpenModalBatalForm(false);
  };

  useEffect(() => {

    getTraderAll()
    GetValidation()

  }, []);

  async function getTraderAll() {
    try {
      const response = await getAllUserTrader("");

      setTraderList(response.data.data.data.data);
    } catch (e) {
      console.log(e);
    }
  }

  async function GetValidation(currency) {
    const data = {
      currency: currency
    }
    try {
      const response = await GetValidationPriceQuantity(data)
      setvalidationPriceQty(response.data?.data);
    } catch (e) {
      console.log(e);
    }
  }

  const handleChangeCurrency = (e) => {
    if (e.target.value === 'idr') {
      setPrice((price * validationPriceQty.usd_idr));
    } else {
      setPrice((price / validationPriceQty.usd_idr))
    }
    setselectcurrency(e.target.value)
    GetValidation(e.target.value)
  };

  function handleSelectPort(e) {
    setPort(e.port_id)
  }

  const [file, setFile] = useState([]);
  function handleChangeFileFromDeleteResponsive(fileQ, y) {
    setFile(fileQ)
  }

  const handleCloseDrawerDoc = () => {
    setOpenDrawerDoc(false);
  };

  const [validationKadarNi, setValidationKadarNi] = useState(false);
  const [validationKadarFe, setValidationKadarFe] = useState(false);
  const [validationKadarAir, setValidationKadarAir] = useState(false);
  const [validationSiMgRatio, setValidationSiMgRatio] = useState(false);
  const [validationqty, setvalidationqty] = useState(false)
  const [validationprice, setvalidationprice] = useState(false)

  const submit = async () => {

    if (kadarNi === '' || kadarFe === '' || kadarAir === '' || siMgRatio === '' || quantity === '' || price === '') {
      kadarNi === '' ? setValidationKadarNi(true) : setValidationKadarNi(false)
      kadarFe === '' ? setValidationKadarFe(true) : setValidationKadarFe(false)
      kadarAir === '' ? setValidationKadarAir(true) : setValidationKadarAir(false)
      siMgRatio === '' ? setValidationSiMgRatio(true) : setValidationSiMgRatio(false)
      quantity === '' ? setvalidationqty(true) : setvalidationqty(false)
      price === '' ? setvalidationprice(true) : setvalidationprice(false)
      Swal.fire({
        icon: 'error',
        title: translate('status.sorry'),
        text: translate('status.Fieldkosong'),
      })
    } else if (quantity > validationPriceQty.maximum_quantity || quantity < validationPriceQty.minimum_quantity) {
      setvalidationqty(true)
      Swal.fire({
        icon: 'error',
        title: translate('status.sorry'),
        text: translate('status.errorqtylimit'),
      })

    } else if (price > validationPriceQty.maximum_prices || price < validationPriceQty.minimum_prices) {
      setvalidationprice(true)

      Swal.fire({
        icon: 'error',
        title: translate('status.sorry'),
        text: translate('status.errorpricelimit'),
      })

    } else {
      setIsLoading(true)
      let startDate = value ? (new Date(value)).toLocaleDateString('sv').substring(0, 10) : null
      let endDate = value2 ? (new Date(value2)).toLocaleDateString('sv').substring(0, 10) : null
      let dataForm = {
        kadar_ni: kadarNi,
        kadar_fe: kadarFe,
        kadar_air: kadarAir,
        si_mg_ratio: siMgRatio,
        quantity: quantity,
        price: price,
        id_loading_port: id_loading_port,
        id_company_listing: namaTambang,
        description: description,
        start_laycan_date: startDate,
        end_laycan_date: endDate,
        id_user_trader_select: namaTrader,
        path_documents: JSON.stringify(file),
        currency: selectcurrency
      }
      let respone = await addBuatPermintaanNikel(dataForm, [])

      if (respone.status === 200) {
        setIsLoading(false)
        setcurrency(selectcurrency);
        localStorage.setItem('currency', selectcurrency);
        navigate(`/penawaran`, {
          state: {
            detail: 'nikel',
            request_data: {
              kadar_ni: kadarNi,
              kadar_fe: kadarFe,
              kadar_air: kadarAir,
              si_mg_ratio: siMgRatio,
              quantity: quantity,
              price: price,
              name_loading_port: namePort,
              start_laycan_date: startDate === null ? '' : startDate,
              end_laycan_date: endDate === null ? '' : endDate,
              id_user_trader_select: namaTrader,
              currency: selectcurrency
            },
            request: true
          }
        })
      } else if (respone.status === 403) {
        setIsLoading(false)
        window.location.href = "/signin"
      } else {
        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('status.subtitlefailed'),
        })
      }
      setIsLoading(false)
    }
  };

  return (
    <div>
      <div className='fixed-header'>
        <NavBar /></div>
      <Box className="flex-wrap-center width-100" >
        <Box className="content-inner" sx={{ mt: { xs: 6, sm: 6, md: 11, lg: 11, xl: 11 }, ml: { xs: '24px', lg: '80px', }, mr: { xs: '24px', lg: '80px', }, }}>
          {/* navigation Section */}
          <Grid container direction="row" alignItems='center' className='mt-18'>
            <Button href="/" className="w-500 mb8 c-24 txt-none-tranform" sx={{ fontSize: { xs: 14, sm: 14, md: 16, lg: 16 } }} variant="text"> {translate('page_explore.home')}</Button>

            <Typography className="w-500 mb8 c-56" sx={{ fontSize: { xs: 14, sm: 14, md: 16, lg: 16 } }}>
              /
            </Typography>
            <Button href="/explore" className="w-500 mb8 c-24 txt-none-tranform" sx={{ fontSize: { xs: 14, sm: 14, md: 16, lg: 16 } }} variant="text"> {translate('navbar.explore')}</Button>

            <Typography className="w-500 mb8 mr8 c-56" sx={{ fontSize: { xs: 14, sm: 14, md: 16, lg: 16 } }}>
              /
            </Typography>
            <Typography className="w-500 mb8 c-56" sx={{ fontSize: { xs: 14, sm: 14, md: 16, lg: 16 } }}>
              {translate('permintaan.btn-beli-nikel')}
            </Typography>
          </Grid>
          {/* header Section */}
          <Grid container direction="column" alignItems="center" justifyContent="center" className='mt-18'>
            <img src={nikel_icon} alt='' />
            <h3 className='w-700 c-08'>{translate('page_explore.search-nikel')}</h3>
            <p className='s-1622 mt-12 w-500 c-56 mb-52'></p>
          </Grid>
          {/* Form Spesifikasi Nikel Section */}
          <Grid container direction='row' justifyContent='space-between'>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Typography className="w-700" sx={{ fontSize: 24, mb: '16px', color: '#081130' }}>
                {translate('jual-beli.spesifikasi-nikel')}
              </Typography>
              <p className='c-56 w-400 s-1421 mb-16'>{translate('jual-beli.subtitle-spesifikasi-nikel')}</p>
            </Grid>
            {/* Form Section */}
            <Grid item xs={12} sm={12} md={4} lg={4} className='form-input'>
              {/* Form Kadar Ni Section */}
              <p className='c-08 s-1419 w-500 mb4'>{translate('page_explore.kadar-ni')}</p>
              <FormControl sx={{ width: '100%' }} variant="outlined">
                <TextField
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                      display: "none",
                    },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    }, input: {
                      fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                  }}
                  type='number'
                  className='box-input-filter'
                  error={validationKadarNi}
                  helperText={validationKadarNi ? translate('status.limitvalue') + ' ' + Value.Ni.min.toString() + ' - ' + Value.Ni.maks.toString() : ''}
                  value={kadarNi}
                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    setKadarNi(e.target.value)
                    if (value > parseInt(Value.Ni.maks)) {
                      setValidationKadarNi(true)
                    } else if (value < parseInt(Value.Ni.min)) {
                      setValidationKadarNi(true)
                    } else {
                      setValidationKadarNi(false)
                    }
                  }}
                  InputProps={{

                    endAdornment: (
                      <InputAdornment position="end" >
                        <VerticalDividerQuantity />
                        <Typography className="w-700 s-1420 c-56 txt-align-center lineheight-normal">
                          %
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  aria-describedby="outlined-weight-helper-text" placeholder={translate('jual-beli.placeholder-ni')}
                />
              </FormControl>
              {/* Form Kadar Fe Section */}
              <p className='c-08 s-1419 w-500 mb4 mt-24'>{translate('page_explore.kadar-fe')}</p>
              <FormControl sx={{ width: '100%' }} variant="outlined">
                <TextField
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                      display: "none",
                    },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    }, input: {
                      fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                  }}
                  type='number'
                  className='box-input-filter'
                  value={kadarFe}
                  error={validationKadarFe}
                  helperText={validationKadarFe ? translate('status.limitvalue') + ' ' + Value.Fe.min.toString() + ' - ' + Value.Fe.maks.toString() : ''}
                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    setKadarFe(e.target.value)
                    if (value > parseInt(Value.Fe.maks)) {
                      setValidationKadarFe(true)
                    } else if (value < parseInt(Value.Fe.min)) {
                      setValidationKadarFe(true)
                    } else {
                      setValidationKadarFe(false)
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" >
                        <VerticalDividerQuantity />
                        <Typography className="w-700 s-1420 c-56 mr8">
                          %
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  aria-describedby="outlined-weight-helper-text" placeholder={translate('jual-beli.placeholder-fe')}
                />
              </FormControl>
              {/* Form Kadar Air Section */}
              <p className='c-08 s-1419 w-500 mb4 mt-24'>{translate('page_explore.kadar-air')}</p>
              <FormControl sx={{ width: '100%' }} variant="outlined">
                <TextField
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                      display: "none",
                    },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    }, input: {
                      fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                  }}
                  type='number'
                  className='box-input-filter'
                  value={kadarAir}
                  error={validationKadarAir}
                  helperText={validationKadarAir ? translate('status.limitvalue') + ' ' + Value.Moisture.min.toString() + ' - ' + Value.Moisture.maks.toString() : ''}
                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    setKadarAir(e.target.value)
                    if (value > parseInt(Value.Moisture.maks)) {
                      setValidationKadarAir(true)
                    } else if (value < parseInt(Value.Moisture.min)) {
                      setValidationKadarAir(true)
                    } else {
                      setValidationKadarAir(false)
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" >
                        <VerticalDividerQuantity />
                        <Typography className="w-700 s-1420 c-56 mr8">
                          %
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  aria-describedby="outlined-weight-helper-text" placeholder={translate('jual-beli.placeholder-air')}
                />
              </FormControl>
              {/* Form Si/Mg Section */}
              <p className='c-08 s-1419 w-500 mb4 mt-24'>Si/Mg </p>
              <TextField
                sx={{
                  width: '100%',
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                    display: "none",
                  },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  }, input: {
                    fontSize: '14px', fontFamily: 'Satoshi-500'
                  },
                }}
                type='number'
                className='box-input-filter' variant="outlined" value={siMgRatio}
                error={validationSiMgRatio}
                helperText={validationSiMgRatio ? translate('status.limitvalue') + ' ' + Value['Si/Mg'].min.toString() + ' - ' + Value['Si/Mg'].maks.toString() : ''}
                onChange={(e) => {
                  const value = parseInt(e.target.value);
                  setSiMgRatio(e.target.value)
                  if (value > parseInt(Value['Si/Mg'].maks)) {
                    setValidationSiMgRatio(true)
                  } else if (value < parseInt(Value['Si/Mg'].min)) {
                    setValidationSiMgRatio(true)
                  } else {
                    setValidationSiMgRatio(false)
                  }
                }} placeholder={translate('jual-beli.placeholder-ratio')} />
            </Grid>
            <Grid item xs={3} className='wd-252'>
            </Grid>
          </Grid>
          <Grid container className='line mt-24 mb-32' >
          </Grid>
          {/* Form Detail Nikel Section */}
          <Grid container direction='row' justifyContent='space-between'>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Typography className="w-700 s-2422 mb-16 c-08">{translate('jual-beli.detail-nikel')}</Typography>
              <p className='c-56 w-400 s-1421 mb-16'>{translate('jual-beli.subtitle-detail-nikel')}</p>
            </Grid>
            {/* Form Section */}
            <Grid item xs={12} sm={12} md={4} lg={4} className='form-input'>
              {/* Form Quantity Section */}
              <p className='c-08 s-1419 w-500 mb4'>Quantity</p>
              <FormControl sx={{ width: '100%' }} variant="outlined">
                <TextField
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                      display: "none",
                    },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    }, input: {
                      fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                  }}
                  type='number'
                  className='box-input-filter'
                  value={quantity}
                  error={validationqty}
                  helperText={validationqty ? translate('status.limitqty') + ' ' + validationPriceQty.minimum_quantity.toString() + ' - ' + validationPriceQty.maximum_quantity.toString() : ''}
                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    setQuantity(value);
                    if (value > parseInt(validationPriceQty.maximum_quantity)) {
                      setvalidationqty(true)
                    } else if (value < parseInt(validationPriceQty.minimum_quantity)) {
                      setvalidationqty(true)
                    } else {
                      setvalidationqty(false)
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" >
                        <VerticalDividerQuantity />
                        <Typography className="w-700 s-1420 c-56">
                          MT
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  aria-describedby="outlined-weight-helper-text" placeholder={translate('jual-beli.placeholder-quantity')}
                />
              </FormControl>
              {/* Form Laycane Section */}
              <p className='c-08 s-1419 w-500 mb4 mt-24'>Laycan Date</p>
              <DateRangePickerCustom setStart={(e) => setValue(e)} setEnd={(e) => setValue2(e)} />
              {/* Form Harga Section */}
              <p className='c-08 s-1419 w-500 mb4 mt-24'>{translate('page_explore.harga-nikel')}</p>
              <TextField
                sx={{
                  width: '100%',
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                    display: "none",
                  },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  }, input: {
                    fontSize: '14px', fontFamily: 'Satoshi-500'
                  },
                }}
                type='number'
                className='box-input-form'
                value={price}
                error={validationprice}
                helperText={validationprice ? translate('status.limitharga') + ' ' + validationPriceQty.minimum_prices.toString() + ' - ' + validationPriceQty.maximum_prices.toString() : ''}
                onChange={(e) => {
                  const value = parseInt(e.target.value);
                  setPrice(value);
                  if (value > parseInt(validationPriceQty.maximum_prices)) {
                    setvalidationprice(true)
                  } else if (value < parseInt(validationPriceQty.minimum_prices)) {
                    setvalidationprice(true)
                  } else {
                    setvalidationprice(false)
                  }
                }}
                placeholder={validationPriceQty.minimum_prices + '-' + validationPriceQty.maximum_prices}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Select
                        disableUnderline
                        variant='standard'
                        className='box-form-currency'
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              height: '120px',
                              '& .MuiMenuItem-root': {
                              },
                            },
                          },
                        }}
                        sx={{
                          "& fieldset": { border: 'none' },
                          boxShadow: "none",
                          ".MuiOutlinedInput-notchedOutline": { border: 0 },
                          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            border: 0,
                          },
                          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            border: 0,
                          },
                        }}
                        IconComponent={ArrowDownPriceComponent}
                        value={selectcurrency} onChange={(e) =>
                          handleChangeCurrency(e)
                        } displayEmpty >
                        {listCurrency.map((item, i) => (
                          <MenuItem key={item.id} value={item.id}>{item.name} </MenuItem>
                        ))}
                      </Select>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end" >
                      <VerticalDividerQuantity />
                      <Typography className="w-700 s-1420 c-56">
                        {translate('status.hargapermt')}
                      </Typography>
                    </InputAdornment>
                  ),
                }} />
              {/* Form Loading Port Section */}
              <p className='c-08 s-1419 w-500 mb4 mt-24'>Loading Port (optional)</p>
              <FormLoadingPort select={(e) => handleSelectPort(e)} selectName={(e) => { }} currentValue={id_loading_port} placeholder={translate('jual-beli.placeholder-loadingport')} id_location={''} />

              {/* Form Nama Tambang Section */}
              <p className='c-08 s-1419 w-500 mb4 mt-24'>{translate('status.sumberbatu')} (optional)</p>
              <FormCompany select={(e) => {
                setNamaTambang(e.id)
              }} selectName={(e) => { }} currentValue={namaTambang} placeholder={translate('page_explore.pilihnamaperusahaan')} />


            </Grid>
            <Grid item xs={3} className='wd-252'>
            </Grid>
          </Grid>

          <Grid container className='line mt-24 mb-56' >
          </Grid>
          {/* Button Section */}
          <Hidden smDown>
            <Grid className='mb-104 in-container bt-explore' container justifyContent='flex-end' direction='row'>
              <button onClick={() => {
                handleOpenBatalForm();
              }} className=' w-500 s-1622 mr-16 wd-160 c-24 bg-ff'>{translate('jual-beli.btn-cancel')}</button>
              <button onClick={(e) => {
                submit()
              }} className='pointer w-500 s-1622 wd-180 c-ff'>
                {isLoading ? (<CircularProgress
                  sx={{
                    color: (theme) =>
                      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                  }}
                  size={20}
                  thickness={4}
                  value={100}
                />) : <>{translate('page_explore.search-nikel')}</>}

              </button>
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Grid spacing={1} container justifyContent='space-between' direction='row' style={{ marginBottom: '16px' }}>
              <Grid item xs={6}>
                <Button
                  onClick={() => {
                    handleOpenBatalForm();
                  }}
                  fullWidth
                  size="large"
                  className="button-blue-outlined w-500 wd-180 pointer"
                  variant="outlined"
                >
                  {translate('jual-beli.btn-cancel')}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  onClick={(e) => {
                    submit()
                  }}
                  size="large"
                  className="button-blue-contained w-500 pointer"
                  variant="contained"
                >
                  {isLoading ? (<CircularProgress
                    sx={{
                      color: (theme) =>
                        theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                    }}
                    size={20}
                    thickness={4}
                    value={100}
                  />) : <>{translate('navbar.btn-buat-permintaan')}</>}
                </Button>
              </Grid>
            </Grid>
          </Hidden>
          <DrawerHapusDoc title={'jual-beli.dokumen-nikel'} file_doc={file} submit={(e, y) => { handleChangeFileFromDeleteResponsive(e, y) }} open={isOpenDrawerDoc} close={handleCloseDrawerDoc} ></DrawerHapusDoc>
        </Box>
      </Box>
      <Footer />
      <ModalBatalForm open={openModalBatalForm} close={handleCloseModal}></ModalBatalForm>
    </div>
  )
}
