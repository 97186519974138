import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CompanyDefault from '@mui/icons-material/Business';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Avatar, Box, Card, CardContent, CardHeader, Grid, IconButton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import callCompanyIcon from "../assets/call-company-ic.png";
import staffIcon from "../assets/icons/staff-ic.svg";
import mailCompanyIcon from "../assets/mail-company-ic.png";
import pelayarIcon from "../assets/pelayar-ic.png";
import penambangIcon from "../assets/penambang-ic.png";
import traderIcon from "../assets/trader-ic.png";
import { config } from "../services/constants";
import { capitalizeEachWord, convertText, format } from '../services/primary';
import ModalShare from "./ModalShare";

export default function CardCompanyDetail(props) {

  const navigate = useNavigate()
  const [translate,] = useTranslation("global");
  const [anchorElShare, setAnchorElShare] = useState(null);
  const [product_id, setProduct_id] = useState(null);
  const [user_trader_phone, setuser_trader_phone] = useState(null);
  const [dataperusahaan, setdataperusahaan] = useState(null);
  const [userid] = useState(localStorage.getItem("id_user") !== null ? localStorage.getItem("id_user") : null);

  const handleCloseShareMenu = () => {
    setAnchorElShare(null);
  };

  const handleOpenShareMenu = (event, id, traderphone, data) => {
    setuser_trader_phone(traderphone);
    setProduct_id(id);
    setdataperusahaan(data);
    setAnchorElShare(event.currentTarget);

  };

  const GotoDetailCompany = (id_company, name_company) => {
    let filter_name = capitalizeEachWord(convertText(name_company))
    window.location.href = `/detail/${filter_name}`
  };

  return (

    <React.Fragment>
      <Box
        sx={{
          mb: '16px'
        }}
      >
        <Card>
          <CardHeader
            avatar={<Box>
              {props.data.status_company === 'Approve' ? (
                <Box sx={{
                  background: "var(--tint-green, rgba(59, 181, 108, 0.25));",
                  borderRadius: '300px',
                  p: '2px 12px',

                  height: '24px'

                }}>
                  <Stack direction={'row'} alignItems={'center'}>
                    <Box sx={{ borderRadius: '50%', background: '#3BB56C', width: '10px', height: '10px', mr: 1 }}>
                    </Box>
                    <Typography className="w-500" sx={{ color: '#081130', fontSize: 14 }} >
                      {translate('status.active')}
                    </Typography>
                  </Stack>
                </Box>
              ) : (
                <></>
              )}
              {props.data.status_company === 'Dalam Review' ? (
                <Box sx={{
                  background: "var(--tint-yellow, rgba(247, 206, 70, 0.30));",
                  borderRadius: '300px',
                  p: '2px 12px',
                  height: '24px'
                }}>
                  <Stack direction={'row'} alignItems={'center'}>
                    <Box sx={{ borderRadius: '50%', background: '#F7CE46', width: '10px', height: '10px', mr: 1 }}>
                    </Box>
                    <Typography className="w-500" sx={{ color: '#081130', fontSize: 14 }} >
                      {translate('status.pending')}
                    </Typography>
                  </Stack>
                </Box>

              ) : (
                <></>
              )}

              {props.data.status_company === 'Reject' ? (
                <Box sx={{
                  background: "var(--tint-orange, rgba(255, 90, 82, 0.20));",
                  borderRadius: '300px',
                  p: '2px 12px',

                  height: '24px'

                }}>
                  <Stack direction={'row'} alignItems={'center'}>
                    <Box sx={{ borderRadius: '50%', background: '#F55C24', width: '10px', height: '10px', mr: 1 }}>
                    </Box>
                    <Typography className="w-500" sx={{ color: '#081130', fontSize: 14 }} >
                      {translate('status.reject')}
                    </Typography>
                  </Stack>
                </Box>

              ) : (
                <></>
              )}
            </Box>}


            action={
              <Stack direction={'row'}>
                <IconButton aria-label="settings">
                  {userid.includes(props.data?.company_user_id) ? <></> : <MoreHorizIcon onClick={(e) => handleOpenShareMenu(e, props.data.company_id, props.data.company_phone_number, props.data)} sx={{ fontSize: 18, }} />}
                  <ModalShare page={'mycompany'} type={4} id={product_id} data={dataperusahaan} user_trader_phone={user_trader_phone} anchorEl={anchorElShare} open={anchorElShare} onClose={handleCloseShareMenu}></ModalShare>
                </IconButton>
                <IconButton aria-label="settings" onClick={(e) => {
                  GotoDetailCompany(props.data?.company_id, props.data?.company_name)
                }}>
                  <ArrowForwardIosIcon sx={{ fontSize: 18 }} />
                </IconButton>
              </Stack>
            }
            titleTypographyProps={{
              fontSize: 16,
              fontFamily: 'Satoshi',
              fontWeight: 700,
              color: '#081130'
            }}
            subheaderTypographyProps={{
              fontSize: 14,
              fontFamily: 'Satoshi',
              fontWeight: 500,
              color: '#56617B'
            }}


          />

          <CardContent sx={{ pt: 0 }}>
            <Stack direction={'row'} sx={{ mb: 1 }}>
              {props.data.company_url_image == "" || props.data.company_url_image == null ? <Avatar sx={{ bgcolor: '#1976d2', mr: 1, height: 46.5, width: 46.5, }} aria-label="recipe">
                <CompanyDefault></CompanyDefault>
              </Avatar> :
                <img style={{ borderRadius: '50%', marginRight: 6 }} alt="company_image-icon" src={config.baseURL + props.data.company_url_image} width={46.5} height={46.5}></img>}
              <Stack direction={'column'}>
                <Typography
                  className="w-700"
                  sx={{ fontSize: 16, ml: 1, color: '#081130' }}
                >{props?.data?.company_name}</Typography>
                {props.data.status_company === 'Approve' &&
                  <>
                    {props?.data?.company_city === null && props?.data?.company_provinsi === null ?
                      <Typography
                        className="w-500"
                        sx={{ fontSize: 14, ml: 1, color: '#56617B' }}
                      >{props.data.alamat}
                      </Typography> :
                      <Typography
                        className="w-500"
                        sx={{ fontSize: 14, ml: 1, color: '#56617B' }}
                      >{props?.data?.company_city} {props?.data?.company_city !== null && props?.data?.company_provinsi !== null ? ',' : ''} {props?.data?.company_provinsi}</Typography>
                    }
                  </>
                }

                {props.data.status_company === 'Dalam Review' ?
                  <>
                    {props.data.type === 'join' ? (
                      <>
                        <Typography
                          className="w-500"
                          sx={{ fontSize: 14, ml: 1, color: '#56617B' }}
                        >
                          {translate('status.bergabungpada')} {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(props.data.date_information))}
                        </Typography>
                      </>
                    ) : (
                      <></>
                    )}
                    {props.data.type === 'created' ? (
                      <>
                        {userid.includes(props.data.company_user_id) ? <Typography
                          className="w-500"
                          sx={{ fontSize: 14, ml: 1, color: '#56617B' }}
                        >
                          {translate('status.dibuatanda')} {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(props.data.date_information))}
                        </Typography>
                          : <Typography
                            className="w-500"
                            sx={{ fontSize: 14, ml: 1, color: '#56617B' }}
                          >

                            {translate('status.dibuatpada')}{new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(props.data.date_information))}
                          </Typography>}

                      </>
                    ) : (
                      <></>
                    )}
                    {props.data.type === 'request' ? (
                      <>
                        <Typography
                          className="w-500"
                          sx={{ fontSize: 14, ml: 1, color: '#56617B' }}
                        >
                          {translate('status.mengajukanpada')}  {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(props.data.date_information))}
                        </Typography>
                      </>
                    ) : (
                      <></>
                    )}

                  </>

                  : <></>}

                {props.data.status_company === 'Reject' ? <>
                  {props.data.type === 'join' ? (
                    <>
                      <Typography
                        className="w-500"
                        sx={{ fontSize: 14, ml: 1, color: '#56617B' }}
                      >
                        {translate('status.bergabungpada')} {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(props.data.date_information))}
                      </Typography>
                    </>
                  ) : (
                    <></>
                  )}
                  {props.data.type === 'created' ? (
                    <>
                      <Typography
                        className="w-500"
                        sx={{ fontSize: 14, ml: 1, color: '#56617B' }}
                      >
                        {translate('status.dibuatanda')} {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(props.data.date_information))}
                      </Typography>
                    </>
                  ) : (
                    <></>
                  )}
                  {props.data.type === 'request' ? (
                    <>
                      <Typography
                        className="w-500"
                        sx={{ fontSize: 14, ml: 1, color: '#56617B' }}
                      >
                        {translate('status.mengajukanpada')} {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(props.data.date_information))}
                      </Typography>
                    </>
                  ) : (
                    <></>
                  )}

                </> : <></>}

              </Stack>
            </Stack>
            <Box sx={{ background: "#F4F6FA", borderRadius: 4, p: 2 }}>
              <Stack style={{ textAlign: "left", color: "#56617B" }} spacing={1}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="left"
                >

                  {props?.data?.bidang_perusahaan === 1 ? <img alt="Nikel-icon" src={penambangIcon} height={18}></img> : ''}
                  {props?.data?.bidang_perusahaan === 2 ? <img alt="Nikel-icon" src={traderIcon} height={18}></img> : ''}
                  {props?.data?.bidang_perusahaan === 3 ? <img alt="Nikel-icon" src={pelayarIcon} height={18}></img> : ''}

                  <Typography
                    className="w-500"
                    sx={{ fontSize: 14, ml: 1 }}
                  >
                    {props?.data?.bidang_perusahaan === 1 ? translate('page_explore.penambang') : ''}
                    {props?.data?.bidang_perusahaan === 2 ? translate('page_explore.trader') : ''}
                    {props?.data?.bidang_perusahaan === 3 ? translate('page_explore.pelayaran') : ''}

                  </Typography>
                </Grid>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="left"
                >
                  <img alt="Nikel-icon" src={callCompanyIcon} height={16}></img>
                  <Typography className="w-500" sx={{ fontSize: 14, ml: 1 }}>
                    {props.data?.company_phone_number ? format(props?.data?.company_phone_number) : '-'}
                  </Typography>
                </Grid>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="left"
                >
                  <img alt="Nikel-icon" src={mailCompanyIcon} height={16}></img>
                  <Typography className="w-500" sx={{ fontSize: 14, ml: 1 }}>
                    {props?.data?.company_email ? props.data?.company_email : '-'}
                  </Typography>
                </Grid>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="left"
                >
                  <img alt="Nikel-icon" src={staffIcon} height={16}></img>
                  <Typography className="w-500" sx={{ fontSize: 14, ml: 1 }}>
                    {props?.data?.total_staff} Staff
                  </Typography>
                </Grid>
              </Stack>
            </Box>
          </CardContent>
        </Card>
      </Box >
    </React.Fragment >
  )
}
