import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import * as React from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import jualBatubaraIcon from "../assets/klaim-company-cancel.png";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: 343, lg: 492 },
    borderRadius: '8px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    paddingBottom: '25px',
};

export default function ModalPengajuanDitolak(props) {
    const navigate = useNavigate();
    const [translate,] = useTranslation("global");
    return (
        <div>
            <Modal
                open={props.open}
                onClose={props.close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ zIndex: 100000 }}
            >
                <Box sx={style}>

                    <Box sx={{ display: 'flex', justifyContent: 'right', mr: '16px', mt: '16px' }}>
                        <IconButton size="small" >
                            <CloseIcon sx={{ width: '24px', height: '24px' }} onClick={() => {
                                props.close();
                            }} />
                        </IconButton>
                    </Box>

                    <Box sx={{ mt: '4px', justifyContent: 'center', display: 'flex' }}>
                        <img alt="Batubara-icon" style={{ width: { xs: '100px', lg: '120px' }, height: { xs: '100px', lg: '120px' }, }} src={jualBatubaraIcon}></img>
                    </Box>

                    <Box sx={{ mt: '24px', mb: { xs: '16px', lg: '52px' }, justifyContent: 'center', display: 'flex' }}><Typography className='w-700' sx={{ height: '48px', width: '342px', fontSize: '18px', color: '#081130', textAlign: 'center' }}>
                        {translate('status.titleperusahaanditolak')}
                    </Typography></Box>

                    <Box sx={{ ml: '24px', mr: '24px', mb: { xs: '16px', lg: '32px' } }}>
                        <Typography className='w-400' sx={{ height: '66px', fontSize: { xs: '14px', lg: '16px' }, color: '#081130', mb: { xs: '28px', lg: '16px' } }}>
                            {translate('status.subperusahaanditolak')}
                        </Typography>

                        <Box sx={{ display: 'flex' }}>

                            <Typography className='w-400' sx={{ fontSize: { xs: '14px', lg: '16px' }, width: '100%' }}>{props.detailCompany.reject_reason}</Typography>
                        </Box>
                    </Box>

                    <Box display="flex" justifyContent="flex-end" sx={{ mr: '21px' }}>
                        <Button onClick={(e) => navigate('/buat-perusahaan', { state: { page: 'edit-perusahaan', data: props.detailCompany } })} variant="contained" bgcolor="#2457B9" sx={{ p: '12px 16px', borderRadius: '8px', textTransform: 'none', height: { xs: '44px', lg: '48px' }, width: { xs: '148px', lg: '186px' } }}>
                            <Typography className='w-500' sx={{ height: '22px', fontSize: '16px', color: '#FFFFFF', textAlign: 'center' }}>
                                {translate('status.editperusahaan')}
                            </Typography>
                        </Button>
                    </Box>

                </Box>
            </Modal>
        </div>
    );
}