import Divider from "@material-ui/core/Divider";
import { CameraAlt } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CompanyDefault from '@mui/icons-material/Business';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import VerifiedIcon from '@mui/icons-material/Verified';
import { Avatar, Box, Button, CircularProgress, Grid, Hidden, InputAdornment, Menu, MenuItem, Stack, TextField, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-multi-carousel/lib/styles.css";
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  FacebookMessengerShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";
import Slider from "react-slick";
import Swal from 'sweetalert2';
import { default as callCompanyIcon } from "../assets/call-company-ic.png";
import ClaimCompanyRejectIc from "../assets/company/claim-company-reject-ic.svg";
import ClaimCompanyReviewIc from "../assets/company/claim-company-review-ic.svg";
import mailProfileCompanyIcon from "../assets/company/mail-profile-company-ic.svg";
import networkCompanyIcon from "../assets/company/network-profile-company-ic.svg";
import phoneCompanyIcon from "../assets/company/phone-profile-company-ic.svg";
import ClaimCompanyUnverifiedIc from "../assets/company/unverified-claim-ic.svg";
import copyIc from "../assets/copy-ic.png";
import defaultbannericon from "../assets/default-img-profile.png";
import EditIcon from "../assets/editprofile-ic.png";
import fbIcon from "../assets/fb-circle-grey-ic.png";
import LeaveCompanyIcon from "../assets/icons/leave-company.svg";
import locationCompanyIcon from "../assets/location-company-ic.png";
import LogoCompanyImg from "../assets/logo-company.png";
import { default as MailCompanyIcon, default as mailCompanyIcon } from "../assets/mail-company-ic.png";
import pelayarIcon from "../assets/pelayar-ic.png";
import { default as penambangIcon } from "../assets/penambang-ic.png";
import reporticon from "../assets/reportflag-ic.png";
import searchIcon from "../assets/search-explore-ic.png";
import telegramIcon from "../assets/telegram-circle-grey-ic.png";
import traderIcon from "../assets/trader-ic.png";
import trash from "../assets/trash.png";
import twitterIcon from "../assets/twitter-circle-grey-ic.png";
import unggah from "../assets/unggah.png";
import waIcon from "../assets/wa-circle-grey-ic.png";
import CardBeforeLogin from "../components/CardBeforeLogin";
import CardCompanyRecomendation from "../components/CardCompanyRecomendation";
import CardListCoal from "../components/CardListCoal";
import CardListNikel from "../components/CardListNikel";
import CardTongkangListDefault from "../components/CardTongkangListDefault";
import DrawerMore from "../components/DrawerMore";
import Footer from "../components/Footer";
import ModalAktePerusahaan from "../components/ModalAktePerusahaan";
import ModalBatalkanKlaimPerusahaan from "../components/ModalBatalKlaimPerusahaan";
import ModalDetailNPWP from "../components/ModalDetailNPWP";
import ModalDetailPerizinan from "../components/ModalDetailPerizinan";
import ModalHubungiTrader from "../components/ModalHubungiTrader";
import ModalJoinCompany from "../components/ModalJoinCompany";
import ModalPengajuanDitolak from "../components/ModalPengajuanDitolak";
import ModalReportListing from "../components/ModalReportListing";
import ModalShareListingCompany from "../components/ModalShareListingCompany";
import NavBar from "../components/Navbar";
import TabelCoalCompany from "../components/TableCoalCompany";
import TabelNikelCompany from "../components/TableNikelCompany";
import TabelStaff from "../components/TableStaff";
import { ActionOwnerCompany, GetAllRequestJoinbyIDCompany, deleteCompanyBackground, getBatubaraCompany, getDetailCompany, getNikelCompany, getStaffCompany, getTongkangCompany, searchCompany } from "../services/api";
import { config } from "../services/constants";
import { capitalizeEachWord, convertText } from '../services/primary';

function DetailPerusahaan() {
  const { state } = useLocation();
  const { companyname } = useParams()
  const [location, setLocation] = useState({
    state: {
      id: '',
      detail: '',
      name: ''
    }
  })
  const navigate = useNavigate()
  const [id_user] = useState(localStorage.getItem('id_user') !== null ? localStorage.getItem('id_user') : '');
  const [modalHubungi, setModalHubungi] = useState(false)
  const [limitdescription, setLimitdescription] = useState(true)
  const [detailCompany, setDetailCompany] = useState([])
  const [position, setPosition] = useState(true)
  const [searchParams,] = useSearchParams();
  const [isOpenMore, setOpenMore] = React.useState(false)
  const [isOpenModalShare, setOpenModalShare] = React.useState(false)
  const [isOpenModal, setOpenModal] = React.useState(false)
  const [companyRecomendation, setCompanyRecomendation] = useState([])
  const [ismodalAktePerusahaan, setModalAktePerusahaan] = useState(false)
  const [ismodalNPWP, setModalNPWP] = useState(false)
  const [ismodalPengajuanDitolak, setModalPengajuanDitolak] = useState(false)
  const [isModalPerizinan, setModalPerizinan] = useState(false)
  const [isModalJoinCompany, setModalJoinCompany] = useState(false)
  const [isJoinCompany, setisJoinCompany] = useState(false)
  const [isModalCancelClaim, setModalCancelClaim] = useState(false)
  const [isLogin] = useState(localStorage.getItem('token') !== null ? true : false);
  //informasi perusahaan
  const [showallalamat, setShowallalamat] = useState(false)
  const [limitalamat, setlimitalamat] = useState(1)
  const [showallperizinan, setShowallperizinan] = useState(false)
  const [limitperizinan, setlimitperizinan] = useState(3)
  const [showalldireksi, setShowalldireksi] = useState(false)
  const [limitdireksi, setlimitdireksi] = useState(8)
  const [showallpemegangsaham, setShowallpemegangsaham] = useState(false)
  const [limitpemegangsaham, setlimitpemegangsaham] = useState(3)
  const [showallrequestjoin, setshowallrequestjoin] = useState(false)
  const [limitrequestjoin, setlimitrequestjoin] = useState(3)
  const [isOwnerCompany, setisOwnerCompany] = useState(false)
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [isloadingbtn, setisloadingbtn] = useState(false)
  const [url_website, seturl_website] = useState([])
  const [openModalReport, setOpenModalReport] = useState(false);

  const handleOpenModal = (state) => {
    if (state === 'more') {
      setOpenMore(true);
    } else {
      setOpenModal(true);
    }
  };

  // const handleOpenModalReport = () => {
  //   setModalReport(true);
  // }

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleShareModal = () => {
    setOpenModalShare(true);
  }
  const closeShareModal = () => {
    setOpenModalShare(false);
    // setModalReport(false);
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setOpenMore(false);
    setOpenModalReport(false)
  };

  function uploadImage(event) {
    const file = event.target.files[0]
    handleCloseUserMenu()
    let formData = new FormData();
    formData.append('files', file);
    formData.append('ref', "api::new-company.new-company");
    formData.append('refId', location.state.id);
    formData.append('field', 'background_picture_path');
    formData.append('source', 'new-company');
    axios.post(`${config.baseURL}/api/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      }
    }).then((res) => {

      Swal.fire({
        icon: 'success',
        title: translate('status.success'),
        text: `${res?.message}`,
      }).then(function () {
        window.location.href = `/detail/${capitalizeEachWord(convertText(location.state.name))}`

      });

    }).catch((err) => {
      handleCloseUserMenu()
      Swal.fire({
        icon: 'error',
        title: translate('status.failed'),
        text: translate('status.subtitlefailed'),
      })
    })
  }

  async function deleteBackground() {
    handleCloseUserMenu()
    const res = await deleteCompanyBackground(location.state.id)
    if (res.status === 200) {

      Swal.fire({
        icon: 'success',
        title: translate('status.success'),
        text: `${res?.message}`,
      }).then(function () {

        window.location.href = `/detail/${capitalizeEachWord(convertText(location.state.name))}`
      });

    } else {
      handleCloseUserMenu()
      Swal.fire({
        icon: 'error',
        title: translate('status.failed'),
        text: translate('status.subtitlefailed'),
      })
    }
  }

  useEffect(() => {
    let newcompanyname = companyname.replaceAll("-", "")
    setActiveTab("company")
    getDataDetailCompany(newcompanyname)
  }, [])


  useEffect(() => {
    let newcompanyname = companyname.replaceAll("-", "")
    setActiveTab("company")
    getDataDetailCompany(newcompanyname)
  }, [localStorage.getItem('lang')])

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler)
    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
  })

  const scrollHandler = (event) => {
    let offset = window.pageYOffset
    if (offset > 200) {
      setPosition(true)
    } else {
      setPosition(false)
    }
  };

  const getDataDetailCompany = async (companyname) => {
    try {
      let lang = localStorage.getItem('lang') === 'null' || localStorage.getItem('lang') === 'id' ? 'id' : localStorage.getItem('lang')
      const responAPI = await getDetailCompany(companyname, lang)
      setDetailCompany(responAPI?.data?.data)
      getDataCompanyRecomendation(responAPI?.data.data.bidang_perusahaan)
      getCompanyListing(responAPI?.data?.data.id)
      setLocation({
        state: {
          id: responAPI?.data?.data.id,
          // detail: state.detail,
          name: responAPI?.data?.data.name
        }
      })
      if (responAPI?.data?.data.user_id.toString() === id_user) {
        if (isLogin) {
          setisOwnerCompany(true);

        }

      }
    } catch (error) {
      setDetailCompany("")
      console.log(error)
    }
  }
  const [listStaffCompany, setListStaffCompany] = useState([]);
  const [totalPageListStaffCompany, setTotalPageListStaffCompany] = useState();
  const [pageListStaffCompany, setPageListStaffCompany] = useState(1);
  const [isLoadingStaff, setIsLoadingStaff] = useState(false);
  const [searchTextStaff, setSearchTextStaff] = useState('');
  const [listBatubaraCompany, setListBatubaraCompany] = useState([]);
  const [totalPageListBatubaraCompany, setTotalPageListBatubaraCompany] = useState();
  const [pageListBatubaraCompany, setPageListBatubaraCompany] = useState(1);
  const [isLoadingBatubara, setIsLoadingBatubara] = useState(false);
  const [searchTextBatubara, setSearchTextBatubara] = useState('');
  const [listNikelCompany, setListNikelCompany] = useState([]);
  const [totalPageListNikelCompany, setTotalPageListNikelCompany] = useState();
  const [pageListNikelCompany, setPageListNikelCompany] = useState(1);
  const [isLoadingNikel, setIsLoadingNikel] = useState(false);
  const [searchTextNikel, setSearchTextNikel] = useState('');
  const [listTongkangCompany, setListTongkangCompany] = useState([]);
  const [totalPageListTongkangCompany, setTotalPageListTongkangCompany] = useState();
  const [pageListTongkangCompany, setPageListTongkangCompany] = useState(1);
  const [isLoadingTongkang, setIsLoadingTongkang] = useState(false);
  const [searchTextTongkang, setSearchTextTongkang] = useState('');
  const [listingCompany, setListingCompany] = useState([]);
  const [isLoadingCompanyListing, setIsLoadingCompanyListing] = useState(false);
  const [isShowAllPemegangSaham, setisShowAllPemegangSaham] = useState(false);
  const [listJoinCompany, setlistJoinCompany] = useState([]);
  const [totalPagelistJoinCompany, setTotalPagelistJoinCompany] = useState();
  const [pagelistJoinCompany, setPagelistJoinCompany] = useState(1);
  const [isloadingJoinCompany, setisloadingJoinCompany] = useState(false);

  const getCompanyListing = async (id) => {
    try {
      setIsLoadingCompanyListing(true)
      let params = {
        page: 1,
        id_company: id
      }
      const responAPIStaff = await getStaffCompany(params)
      const responAPIBatubara = await getBatubaraCompany(params)
      const responAPINikel = await getNikelCompany(params)
      const responAPITongkang = await getTongkangCompany(params)
      if (localStorage.getItem('token')) {
        const responAPIRequestJoinCompany = await GetAllRequestJoinbyIDCompany(params)
        setlistJoinCompany(responAPIRequestJoinCompany.data)

        setTotalPagelistJoinCompany(responAPIRequestJoinCompany.data.pageCount)
      }
      setListingCompany(responAPIStaff.data.data)
      setListStaffCompany(responAPIStaff.data.data.data.data)
      setTotalPageListStaffCompany(responAPIStaff.data.data.data.pageCount)
      setListBatubaraCompany(responAPIBatubara.data.data.data.data)
      setTotalPageListBatubaraCompany(responAPIBatubara.data.data.data.pageCount)
      setListNikelCompany(responAPINikel.data.data.data.data)
      setTotalPageListNikelCompany(responAPINikel.data.data.data.pageCount)
      setListTongkangCompany(responAPITongkang.data.data.data.data)
      setTotalPageListTongkangCompany(responAPITongkang.data.data.data.pageCount)
      setIsLoadingCompanyListing(false)
    } catch (error) {
      console.log(error)
    }
  }

  const getStaffCompanyListing = async (e, search) => {
    try {
      if (e === 1) {
        setIsLoadingCompanyListing(true)
      }
      setIsLoadingStaff(true)
      let params = {
        page: Number(e),
        id_company: location.state.id,
        search_text: search
      }
      const responAPIStaff = await getStaffCompany(params)
      if (responAPIStaff.data.status === 200) {
        if (e === 1) {
          setTotalPageListStaffCompany(responAPIStaff.data.data.data.pageCount)
          setPageListStaffCompany(1)
          setIsLoadingCompanyListing(false)
        }
        setIsLoadingStaff(false)

        return responAPIStaff.data.data.data.data
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getBatubaraCompanyListing = async (e, search) => {
    try {
      if (e === 1) {
        setIsLoadingCompanyListing(true)
      }
      setIsLoadingBatubara(true)
      let params = {
        page: Number(e),
        id_company: location.state.id,
        search_text: search
      }
      const responAPIBatubara = await getBatubaraCompany(params)
      if (responAPIBatubara.data.status === 200) {
        if (e === 1) {
          setTotalPageListBatubaraCompany(responAPIBatubara.data.data.data.pageCount)
          setPageListBatubaraCompany(1)
          setIsLoadingCompanyListing(false)
        }
        setIsLoadingBatubara(false)
        return responAPIBatubara.data.data.data.data
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getNikelCompanyListing = async (e, search) => {
    try {
      if (e === 1) {
        setIsLoadingCompanyListing(true)
      }
      setIsLoadingNikel(true)
      let params = {
        page: Number(e),
        id_company: location.state.id,
        search_text: search
      }
      const responAPINikel = await getNikelCompany(params)
      if (responAPINikel.data.status === 200) {
        if (e === 1) {
          setTotalPageListNikelCompany(responAPINikel.data.data.data.pageCount)
          setPageListNikelCompany(1)
          setIsLoadingCompanyListing(false)
        }
        setIsLoadingNikel(false)
        return responAPINikel.data.data.data.data
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getTongkangCompanyListing = async (e, search) => {
    try {
      if (e === 1) {
        setIsLoadingCompanyListing(true)
      }
      setIsLoadingTongkang(true)
      let params = {
        page: Number(e),
        id_company: location.state.id,
        search_text: search
      }
      const responAPITongkang = await getTongkangCompany(params)
      if (responAPITongkang.data.status === 200) {
        if (e === 1) {
          setTotalPageListTongkangCompany(responAPITongkang.data.data.data.pageCount)
          setPageListTongkangCompany(1)
          setIsLoadingCompanyListing(false)
        }
        setIsLoadingTongkang(false)
        return responAPITongkang.data.data.data.data
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getDataCompanyRecomendation = async (id_bidang_perusahaan) => {

    let bidang_perusahaan = id_bidang_perusahaan
    try {
      let params = {
        page: 1,
        bidang_usaha: bidang_perusahaan,
        id_location: []
      }
      const responAPI = await searchCompany(params)
      setCompanyRecomendation(responAPI.data.data.data.data)
    } catch (error) {
      setCompanyRecomendation("")
      console.log(error)
    }
  }

  const [activeTab, setActiveTab] = useState(location.state?.detail);
  const [limitcompany, setlimitCompany] = useState(6)
  const [activeTabCompany, setActiveTabCompany] = useState('about');
  const [copyUrl, setCopyUrl] = useState(false)
  const [translate,] = useTranslation("global");
  //  Functions to handle Tab Switching
  const handleTab = (state) => {
    setActiveTabCompany(state);
  };

  function copyLink(e) {
    setCopyUrl(true)
    setTimeout(() => {
      setCopyUrl(false)
    }, 1000);
  }

  const copyClipboard = <div className="copyToClipboard" style={{ opacity: copyUrl ? "1" : "0" }}>✔ Copied to clipboard</div>

  const SubmitReportListing = async () => {
    seturl_website(`${config.staging}/detail/${capitalizeEachWord(convertText(location.state.name))}`)
    setOpenModalReport(true)

  }

  const SubmitActionOwner = async (id_user, action, type) => {
    setisloadingbtn(true)
    let params
    try {
      if (type === 'all') {
        let result = listJoinCompany.data.map(({ id_user }) => id_user)
        let result2 = JSON.stringify(result)
        params = {
          id_company: location.state.id,
          id_user_request: result2.slice(1, result2.length - 1),
          action: action
        }
      } else {
        params = {
          id_company: location.state.id,
          id_user_request: JSON.stringify(id_user),
          action: action
        }
      }


      const responAPI = await ActionOwnerCompany(params)
      if (responAPI.status === 200) {
        setisloadingbtn(false)
        Swal.fire({
          icon: 'success',
          title: translate('status.success'),
          text: `${responAPI?.message}`,
        }).then(function () {
          window.location.href = `/detail/${capitalizeEachWord(convertText(location.state.name))}`
        });
      } else {
        setisloadingbtn(false)
        Swal.fire({
          icon: 'error',
          title: translate('status.failed'),
          text: translate('status.subtitlefailed'),
        })
      }
    } catch (error) {
      setisloadingbtn(false)
      console.log(error)
    }
  }

  let company = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: false,
    speed: 500,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 1821,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 1320,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 900,
        settings: {
          arrows: false,
          // centerMode: true,
          slidesToShow: 1
        }
      },

    ]
  };

  return (
    <div className="detail-explore width-100" >
      <div className="flex-wrap-center relative width-100">
        <div className="container-nav relative width-100">
          <div className='fixed-header'>
            <NavBar></NavBar>
          </div>
        </div>
        <Box className="flex-wrap-center width-100" sx={{ mt: { xs: 6, sm: 6, md: 11, lg: 11, xl: 11 }, }}>
          <div className="content-inner">
            <Grid
              sx={{ mt: '16px' }}
              container
              direction="row"
              alignItems={'center'}
            >
              <Button href="/" className="w-500" sx={{ fontSize: 16, mb: 1, color: '#2457B9', textTransform: 'none', pl: 0 }} variant="text">  {translate('page_explore.home')}</Button>
              <Typography className="w-500" sx={{ fontSize: 16, mb: 1, color: '#56617B' }}>
                /
              </Typography>
              <Button href="/explore" className="w-500" sx={{ fontSize: 16, mb: 1, color: '#2457B9', textTransform: 'none' }} variant="text">       Explore</Button>
              <Typography className="w-500" sx={{ fontSize: 16, mb: 1, mr: 1, color: '#2457B9' }}>
                /
              </Typography>
              {activeTab === 'company' ? <Typography className="w-500" sx={{ fontSize: 16, mb: 1 }}>
                {translate('detail-explore.detailperusahaan')}
              </Typography> : null}

            </Grid>
            <Grid
              container
              direction="row"
              rowSpacing={{ xs: 0, lg: 7 }}
              columnSpacing={{ xs: 5, lg: 7 }}
            >
              {/* leftside Section */}
              <Grid lg={8.4} xs={12} item >
                <Box sx={{ mb: 2 }}>
                  <Box sx={{ border: 1, color: '#E8EBF2', borderRadius: 2, pb: 3 }}>
                    <Box
                      sx={{
                        position: "relative", display: "flex", flexDirection: "column",
                      }}
                    >
                      {detailCompany.background_picture_path?.url === undefined || detailCompany.background_picture_path === null ? (
                        <Box
                          sx={{
                            width: "100%",
                            height: { xs: 120, sm: 120, md: 200, lg: 350 },
                            objectFit: "cover",
                            backgroundColor: "#F4F6FA",
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <Hidden mdDown> <Stack
                            spacing={2}
                            sx={{
                              width: "auto",
                              height: "200px",
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}>
                            <Avatar
                              alt="Avatar"
                              variant="circular"
                              src={defaultbannericon}
                              sx={{ width: 100, height: 100 }}
                            />
                            <Typography
                              className="w-700"
                              sx={{ fontSize: 18, color: '#424242' }}
                            >     {translate('status.imageempty')}
                            </Typography>
                          </Stack>
                          </Hidden>
                        </Box>
                      ) : (
                        <Box
                          component="img"
                          src={config.baseURL + detailCompany.background_picture_path?.url}
                          sx={{
                            width: "100%",
                            height: { xs: 120, sm: 120, md: 200, lg: 350 },
                            objectFit: "cover"
                          }}
                        />
                      )}

                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          position: "absolute", bottom: -80, left: "15px", width: "160px", height: "160px",
                        }}
                      >
                        <Grid container sx={{}}>
                          <Grid item xs={8} md={10} lg={12}>
                            <Avatar
                              alt="Avatar"
                              variant="circular"
                              src={detailCompany?.picture_path?.url !== undefined ? `${config.baseURL + detailCompany?.picture_path?.url}` : LogoCompanyImg}
                              sx={{ width: "120px", height: "120px" }}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                      <Box
                        sx={{
                          position: "absolute",
                          top: "4%",
                          right: "2%",
                        }}
                      >

                        {isOwnerCompany && <Button
                          size="medium"
                          //  onClick={() => window.location.href = "/setting"}
                          variant="contained" onClick={handleOpenUserMenu}
                          sx={{ backgroundColor: '#FFFFFF', p: '10px', minWidth: '36px', height: '36px', textTransform: 'none', borderRadius: '8px' }}
                        >
                          <CameraAlt sx={{ color: '#8792AB', width: '16px', height: '16px' }} />

                        </Button>}
                        <Menu sx={{ mt: '45px', zIndex: 9, transition: "0.3s" }}
                          id="menu-appbar" anchorEl={anchorElUser}
                          anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                          keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right', }}
                          open={Boolean(anchorElUser)} onClose={handleCloseUserMenu}>
                          <MenuItem sx={{
                            pb: .6, pt: .6, "&:hover": {
                              backgroundColor: "#F4F6FA !important", borderRadius: "0px !important"
                            }
                          }} style={{ backgroundColor: "white", borderBottom: "1px solid #E8EBF2", borderRadius: "5px 5px 0px 0px" }}>
                            <label for="upload">
                              <Stack direction={'row'} alignItems={'center'}>
                                <img alt="img-icon" src={unggah} height={14} width={14}></img>
                                <Typography className="w-500" sx={{ fontSize: '14px', ml: 0.2 }} textAlign="center">{translate('status.unggahfoto')}</Typography>
                              </Stack>
                            </label>
                          </MenuItem>
                          <MenuItem onClick={(e) => deleteBackground()} sx={{
                            pb: .6, pt: .6, "&:hover": {
                              backgroundColor: "#F4F6FA !important", borderRadius: "0px !important"
                            }
                          }} style={{ backgroundColor: "white", borderRadius: "0px 0px 5px 5px" }}>
                            <Stack direction={'row'} alignItems={'center'}>
                              <img alt="img-icon" src={trash} height={14} width={14}></img>
                              <Typography className="w-500" sx={{ fontSize: '14px', ml: 0.2 }} textAlign="center">{translate('status.hapusfoto')}</Typography>
                            </Stack>
                          </MenuItem>
                        </Menu>

                        <input type="file" id="upload" style={{ display: "none" }} accept="image/png, image/gif, image/jpeg" onChange={(e) => uploadImage(e)} />
                      </Box>
                    </Box>
                    {isOwnerCompany && <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "16px",
                        marginRight: "16px",
                      }}
                    >
                      <Button
                        size="medium"
                        className="button-blue-outlined w-500"
                        onClick={(e) => navigate('/buat-perusahaan', { state: { page: 'edit-perusahaan', data: detailCompany } })}
                        variant="outlined"
                        sx={{ p: '10px 12px ', minWidth: '36px', height: '40px', }}
                      >
                        <img src={EditIcon} height={20} width={20} />
                        <Hidden smDown><Typography className="w-500" sx={{ ml: 1, fontSize: 14, color: '#2457B9' }}>
                          {translate('status.editperusahaan')}
                        </Typography></Hidden>
                      </Button>
                    </Box>}
                    <Stack direction={'row'} alignItems={'center'} sx={{ mt: 8, pl: 2, mb: '12px' }}>
                      <Typography
                        sx={{ fontSize: '24px', color: '#081130', mr: '8px' }}
                        className="w-700"
                      >

                        {capitalizeEachWord(detailCompany?.name)}

                      </Typography>
                      {(detailCompany?.verify_status === 'Verified' && detailCompany?.status === 'Approve') &&
                        <VerifiedIcon sx={{ fill: ' #38B3E3' }}></VerifiedIcon>
                      }
                    </Stack>


                    <Grid
                      container
                      justifyContent="left"
                      direction={{ xs: 'column', sm: 'column', md: 'row' }}
                      sx={{ pl: '24px', mb: '12px' }}
                    >
                      <Stack direction={'row'} sx={{ mb: 1 }}>
                        {detailCompany?.bidang_perusahaan === 1 ? <img alt="Nikel-icon" src={penambangIcon} height={18}></img> : ''}
                        {detailCompany?.bidang_perusahaan === 2 ? <img alt="Nikel-icon" src={traderIcon} height={18}></img> : ''}
                        {detailCompany?.bidang_perusahaan === 3 ? <img alt="Nikel-icon" src={pelayarIcon} height={18}></img> : ''}
                        <Typography
                          className="w-700"
                          sx={{ fontSize: 14, color: "#56617B", mr: 1, ml: 1 }}
                        >
                          {detailCompany?.bidang_perusahaan === 1 ? translate('page_explore.penambang') : ''}
                          {detailCompany?.bidang_perusahaan === 2 ? translate('page_explore.trader') : ''}
                          {detailCompany?.bidang_perusahaan === 3 ? translate('page_explore.pelayaran') : ''}
                        </Typography>
                      </Stack>
                      <Stack direction={'row'} sx={{ mb: 1 }}>
                        <img alt="Nikel-icon" src={locationCompanyIcon} height={18}></img>
                        {detailCompany.kota !== null || detailCompany.provinsi !== null ? <Typography
                          className="w-700"
                          sx={{ fontSize: 14, color: "#56617B", mr: 1, ml: 1 }}
                        >
                          {`${detailCompany?.kota}, ${detailCompany?.provinsi}`}
                        </Typography> : <Typography
                          className="w-700"
                          sx={{ fontSize: 14, color: "#56617B", mr: 1, ml: 1 }}
                        >
                          {detailCompany?.alamat}
                        </Typography>}
                      </Stack>
                      {detailCompany?.phone_number && <Stack direction={'row'} sx={{ mb: 1 }}>
                        <img alt="Nikel-icon" src={callCompanyIcon} height={18}></img>
                        <Typography
                          className="w-700"
                          sx={{ fontSize: 14, color: "#56617B", mr: 1, ml: 1 }}
                        >
                          {detailCompany?.phone_number}
                        </Typography>
                      </Stack>}
                      {detailCompany?.email && <Stack direction={'row'} >
                        <img alt="Nikel-icon" src={mailCompanyIcon} height={18}></img>
                        <Typography
                          className="w-700"
                          sx={{ fontSize: 14, color: "#56617B", mr: 1, ml: 1 }}
                        >
                          {detailCompany?.email}
                        </Typography>
                      </Stack>}
                    </Grid>
                    {limitdescription && detailCompany?.description?.length > 146 ?
                      <p className="w-700" style={{ color: '#081130', fontSize: 14, lineHeight: '26px', marginTop: '8', paddingLeft: '16px', paddingRight: '16px' }} id="child-modal-description">
                        {detailCompany.description.slice(0, 146)}...<b onClick={() => {
                          setLimitdescription(false);
                        }} className="w-700" style={{ cursor: 'pointer', color: '#2457B9', fontSize: '14px', lineHeight: 'normal', marginLeft: '8px' }}>   {translate('status.lihatdataselengkapnya')}</b>
                      </p> : <Typography sx={{ mt: 1, pl: 2, pr: 2, fontSize: 14, color: '#081130' }}
                        className="w-700"> {detailCompany.description}</Typography>}
                  </Box>
                  {/* Tab Button Section */}
                  {isOwnerCompany && detailCompany.status === 'Dalam Review' ?
                    <Box sx={{ background: '#F4F6FA', borderRadius: '8px', border: '1px solid #F4F6FA', p: '24px', mt: '32px' }}>
                      <Grid container direction={'row'}>
                        <Grid item xs={2} md={1} lg={1}>
                          <img alt="img-icon" src={ClaimCompanyReviewIc} height={40} width={40}></img>
                        </Grid>
                        <Grid item xs={10} md={11} lg={11}>
                          <Typography sx={{ fontSize: { xs: 14, lg: 20 }, color: '#081130' }}
                            className="w-700">  {translate('status.pengajuansedangdireview')}</Typography>
                          <Typography sx={{ fontSize: { xs: 14, lg: 16 }, color: '#56617B' }}
                            className="w-400"> {translate('status.subsedangdireview')}</Typography>
                        </Grid>


                      </Grid>
                    </Box> : <></>}

                  {!isOwnerCompany && isLogin && detailCompany.verify_status === 'Unverified' && detailCompany.user_id === "" ?
                    <Box sx={{ background: '#F4F6FA', borderRadius: '8px', border: '1px solid #F4F6FA', p: '24px', mt: '32px' }}>
                      <Hidden smDown>
                        <Grid container direction={'row'}>
                          <Grid item xs={1}>
                            <img alt="img-icon" src={ClaimCompanyUnverifiedIc} height={40} width={40}></img>
                          </Grid>
                          <Grid item xs={8.6}>
                            <Typography sx={{ fontSize: 20, color: '#081130' }}
                              className="w-700">{translate('status.perusahaanbelumterclaim')}</Typography>
                            <Typography sx={{ fontSize: 16, color: '#56617B' }}
                              className="w-400"> {translate('status.subbelumterklaim')}</Typography>
                          </Grid>
                          <Grid item xs sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Button
                              onClick={() => {
                                window.location.href = `/claim-perusahaan?id_company=${location.state.id}`
                              }}
                              type="submit"
                              fullWidth
                              variant="contained"
                              sx={{ p: '10px 12px', height: '40px', mr: '10px', borderRadius: '8px', backgroundColor: '#FFF' }}
                            >
                              <Typography className="w-500" sx={{ textTransform: 'none', fontSize: 14, color: '#2457B9' }}> {translate('status.claimperusahaan')}
                              </Typography>
                            </Button>
                          </Grid>

                        </Grid>
                      </Hidden>
                      <Hidden smUp>
                        <Grid container direction={'row'}>
                          <Grid item xs={2}>
                            <img alt="img-icon" src={ClaimCompanyUnverifiedIc} height={40} width={40}></img>
                          </Grid>
                          <Grid item xs={10}>
                            <Typography sx={{ fontSize: 14, color: '#081130', mb: '10px' }}
                              className="w-700">{translate('status.perusahaanbelumterclaim')}</Typography>
                            <Typography sx={{ fontSize: 14, color: '#081130' }}
                              className="w-400"> {translate('status.subbelumterklaim')}</Typography>
                            <Button
                              onClick={() => {
                                window.location.href = `/claim-perusahaan?id_company=${location.state.id}`
                              }}
                              type="submit"
                              fullWidth
                              className="w-500 button-blue-outlined"
                              variant="outlined"
                              sx={{ p: '8px 12px', width: '150px', mt: 2 }}
                            >
                              <Typography className="w-500" sx={{ textTransform: 'none', fontSize: 14, color: '#2457B9' }}> {translate('status.claimperusahaan')}
                              </Typography>
                            </Button>
                          </Grid>




                        </Grid>
                      </Hidden>
                    </Box> : <></>}

                  {isOwnerCompany && detailCompany.status === 'Reject' ?
                    <Box sx={{ background: '#FCEEC5', borderRadius: '8px', border: '1px solid #F7CE46', p: '24px', mt: '32px' }}>
                      <Hidden smDown>
                        <Grid container direction={'row'}>
                          <Grid item xs={1}>
                            <img alt="img-icon" src={ClaimCompanyRejectIc} height={40} width={40}></img>
                          </Grid>
                          <Grid item xs={9}>
                            <Typography sx={{ fontSize: 20, color: '#081130' }}
                              className="w-700"> {translate('status.pengajuanditolak')}</Typography>
                            <Typography sx={{ fontSize: 16, color: '#56617B' }}
                              className="w-400"> {detailCompany.reject_reason}</Typography>
                          </Grid>
                          <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Button
                              onClick={() => {
                                setModalPengajuanDitolak(true);
                              }}
                              type="submit"
                              fullWidth
                              variant="contained"
                              sx={{ p: '10px 12px', width: { xs: '100%', sm: '129px' }, height: '40px', mr: '10px', borderRadius: '8px', backgroundColor: '#FFF' }}
                            >
                              <Typography className="w-500" sx={{ textTransform: 'none', fontSize: 14, color: '#081130' }}>  {translate('status.ajukankembali')}
                              </Typography>
                            </Button>
                          </Grid>

                        </Grid>
                      </Hidden>
                      <Hidden smUp>
                        <Grid container direction={'row'}>
                          <Grid item xs={2}>
                            <img alt="img-icon" src={ClaimCompanyRejectIc} height={40} width={40}></img>
                          </Grid>
                          <Grid item xs={10}>
                            <Typography sx={{ fontSize: 14, color: '#081130', mb: '10px' }}
                              className="w-700"> {translate('status.pengajuanditolak')}</Typography>
                            <Typography sx={{ fontSize: 14, color: '#081130' }}
                              className="w-400"> {detailCompany.reject_reason}</Typography>
                            <Button
                              onClick={() => {
                                setModalPengajuanDitolak(true);
                              }}
                              type="submit"
                              fullWidth
                              variant="contained"
                              sx={{ p: '10px 12px', width: '150px', height: '40px', mt: 2, borderRadius: '8px', backgroundColor: '#FFF' }}
                            >
                              <Typography className="w-500" sx={{ textTransform: 'none', fontSize: 14, color: '#081130' }}> {translate('status.ajukankembali')}
                              </Typography>
                            </Button>
                          </Grid>

                        </Grid>
                      </Hidden>
                    </Box> : <></>}

                  {detailCompany.user_information_klaim === 2 || detailCompany.user_information_klaim === 3 ?
                    <Box sx={{ background: '#FCEEC5', borderRadius: '8px', border: '1px solid #F7CE46', p: '24px', mt: '32px' }}>
                      <Hidden smDown>
                        <Grid container direction={'row'}>
                          <Grid item xs={1}>
                            <img alt="img-icon" src={ClaimCompanyReviewIc} height={40} width={40}></img>
                          </Grid>

                          <Grid item xs={9}>
                            {detailCompany.user_information_klaim === 2 && <Typography sx={{ fontSize: 20, color: '#081130' }}
                              className="w-700"> {translate('status.klaiminreviewowner')}</Typography>}
                            <Typography sx={{ fontSize: 20, color: '#081130' }}
                              className="w-700"> {translate('status.klaiminreview')}</Typography>
                            {detailCompany.user_information_klaim === 2 && <Typography sx={{ fontSize: 16, color: '#56617B' }}
                              className="w-400"> {translate('status.subklaiminreview')}</Typography>}
                          </Grid>
                          <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {detailCompany.user_information_klaim === 2 && <Button
                              onClick={() => {
                                setModalCancelClaim(true);
                              }}
                              type="submit"
                              fullWidth
                              variant="contained"
                              sx={{ p: '10px 12px', width: { xs: '100%', sm: '129px' }, height: '40px', mr: '10px', borderRadius: '8px', backgroundColor: '#FFF' }}
                            >
                              <Typography className="w-500" sx={{ textTransform: 'none', fontSize: 14, color: '#081130' }}>  {translate('status.batalkanklaim')}
                              </Typography>
                            </Button>}
                          </Grid>

                        </Grid>
                      </Hidden>
                      <Hidden smUp>
                        <Grid container direction={'row'}>
                          <Grid item xs={2}>
                            <img alt="img-icon" src={ClaimCompanyReviewIc} height={40} width={40}></img>
                          </Grid>
                          <Grid item xs={10}>
                            <Typography sx={{ fontSize: 14, color: '#081130', mb: '10px' }}
                              className="w-700">{translate('status.klaiminreview')}</Typography>
                            <Typography sx={{ fontSize: 14, color: '#081130' }}
                              className="w-400"> {translate('status.subklaiminreview')}.</Typography>
                            {detailCompany.user_information_klaim === 2 && <Button
                              onClick={() => {
                                setModalCancelClaim(true);
                              }}
                              type="submit"
                              fullWidth
                              variant="contained"
                              sx={{ p: '10px 12px', width: '150px', height: '40px', mt: 2, borderRadius: '8px', backgroundColor: '#FFF' }}
                            >
                              <Typography className="w-500" sx={{ textTransform: 'none', fontSize: 14, color: '#081130' }}> {translate('status.batalkanklaim')}
                              </Typography>
                            </Button>}
                          </Grid>

                        </Grid>
                      </Hidden>
                    </Box> : <></>}
                  <div style={{ marginTop: '10px', marginBottom: '-10px' }} className="tab-scroll-x">
                    <Stack direction="row" justifyContent="left" sx={{ mt: '32px' }}>
                      <Button
                        className='w-500'
                        sx={{
                          width: '200px',
                          borderRadius: '8px',
                          p: '8px 12px',
                          lineHeight: '22px',
                          background: activeTabCompany === 'about' ? "#081130" : '', textTransform: 'none',
                          color: activeTabCompany === 'about' ? "white" : '#081130', fontSize: 16, "&:hover": {
                            backgroundColor: "#56617B"
                          }
                        }}
                        onClick={() => {
                          handleTab("about");
                        }}
                        variant={activeTabCompany === 'about' ? "contained" : 'text'}>
                        {translate('status.informasicompany')}
                      </Button>
                      <Divider orientation="vertical" style={{ marginTop: 10, marginBottom: 10, marginLeft: 10, marginRight: 10 }} flexItem />
                      <Button
                        className='w-500'
                        sx={{
                          width: '100px',
                          borderRadius: '8px',
                          p: '8px 12px',
                          lineHeight: '22px',
                          background: activeTabCompany === 'staff' ? "#081130" : '', textTransform: 'none',
                          color: activeTabCompany === 'staff' ? "white" : '#081130', fontSize: 16, "&:hover": {
                            backgroundColor: activeTabCompany === 'staff' ? "#081130" : "#56617B"
                          }
                        }}
                        onClick={() => {
                          handleTab("staff");
                        }}
                        variant={activeTabCompany === 'staff' ? "contained" : 'text'}
                        endIcon={<Box
                          sx={{
                            background: activeTabCompany === 'staff' ? "white" : '#D1D7E6',
                            borderRadius: '6px',
                            p: '4px',
                            ml: '8px',
                          }}
                        >
                          <Typography
                            className="w-500"
                            sx={{ color: activeTabCompany === 'staff' ? "#081130" : '#56617B', fontSize: 12 }}
                          >
                            {listingCompany.totalListStaff}
                          </Typography>
                        </Box>}>
                        {translate('detail-explore.staff')}
                      </Button>
                      <Divider orientation="vertical" style={{ marginTop: 10, marginBottom: 10, marginLeft: 10, marginRight: 10 }} flexItem />
                      <Button
                        className="w-500"
                        onClick={() => {
                          handleTab("listcoal");
                        }}
                        sx={{
                          width: '200px',
                          borderRadius: '8px',
                          p: '8px 12px',
                          lineHeight: '22px',
                          background: activeTabCompany === 'listcoal' ? "#081130" : '', textTransform: 'none',
                          color: activeTabCompany === 'listcoal' ? "white" : '#081130', fontSize: 16, "&:hover": {
                            backgroundColor: activeTabCompany === 'listcoal' ? "#081130" : "#56617B"
                          }
                        }}
                        variant={activeTabCompany === 'listcoal' ? "contained" : 'text'}
                        endIcon={<Box
                          sx={{
                            background: activeTabCompany === 'listcoal' ? "white" : '#D1D7E6',
                            borderRadius: '6px',
                            p: '4px',
                            ml: '8px',
                          }}
                        >
                          <Typography
                            className="w-500"
                            sx={{ color: activeTabCompany === 'listcoal' ? "#081130" : '#56617B', fontSize: 12 }}
                          >
                            {listingCompany.totalListBatubara}
                          </Typography>
                        </Box>}>
                        {translate('detail-explore.listingbatubara')}
                      </Button>
                      <Divider orientation="vertical" style={{ marginTop: 10, marginBottom: 10, marginLeft: 10, marginRight: 10 }} flexItem />
                      <Button
                        className="w-500"
                        onClick={() => {
                          handleTab("listnikel");
                        }}
                        sx={{
                          width: '160px',
                          borderRadius: '8px',
                          p: '8px 12px',
                          lineHeight: '22px',
                          background: activeTabCompany === 'listnikel' ? "#081130" : '', textTransform: 'none',
                          color: activeTabCompany === 'listnikel' ? "white" : '#081130', fontSize: 16, "&:hover": {
                            backgroundColor: activeTabCompany === 'listnikel' ? "#081130" : "#56617B"
                          }
                        }}
                        variant={activeTabCompany === 'listnikel' ? "contained" : 'text'}
                        endIcon={<Box
                          sx={{
                            background: activeTabCompany === 'listnikel' ? "white" : '#D1D7E6',
                            borderRadius: '6px',
                            p: '4px',
                            ml: '8px',
                          }}
                        >
                          <Typography
                            className="w-500"
                            sx={{ color: activeTabCompany === 'listnikel' ? "#081130" : '#56617B', fontSize: 12 }}
                          >
                            {listingCompany.totalListNikel}
                          </Typography>
                        </Box>}>
                        {translate('detail-explore.listingnikel')}
                      </Button>
                      <Divider orientation="vertical" style={{ marginTop: 10, marginBottom: 10, marginLeft: 10, marginRight: 10 }} flexItem />
                      <Button
                        className="w-500"
                        onClick={() => {
                          handleTab("listtongkang");
                        }}
                        sx={{
                          width: '210px',
                          borderRadius: '8px',
                          p: '8px 12px',
                          lineHeight: '22px',
                          background: activeTabCompany === 'listtongkang' ? "#081130" : '', textTransform: 'none',
                          color: activeTabCompany === 'listtongkang' ? "white" : '#081130', fontSize: 16, "&:hover": {
                            backgroundColor: activeTabCompany === 'listtongkang' ? "#081130" : "#56617B"
                          }
                        }}
                        variant={activeTabCompany === 'listtongkang' ? "contained" : 'text'}
                        endIcon={<Box
                          sx={{
                            background: activeTabCompany === 'listtongkang' ? "white" : '#D1D7E6',
                            borderRadius: '6px',
                            p: '4px',
                            ml: '8px',
                          }}
                        >
                          <Typography
                            className="w-500"
                            sx={{ color: activeTabCompany === 'listtongkang' ? "#081130" : '#56617B', fontSize: 12 }}
                          >
                            {listingCompany.totalListTongkang}
                          </Typography>
                        </Box>}>
                        {translate('detail-explore.listingtongkang')}
                      </Button>
                    </Stack>
                  </div>
                  {/* Informasi Perusahaan Section */}
                  {activeTabCompany === 'about' ? <Box>
                    <Stack direction={'row'} alignItems={'center'} sx={{ mt: '24px', mb: '10px' }}>
                      <Typography
                        sx={{ fontSize: 18, color: '#222525', mr: '10px' }}
                        className="w-700"
                      >
                        {translate('status.profilecompany')}
                      </Typography>
                      {isOwnerCompany &&
                        <img style={{ cursor: 'pointer' }}
                          onClick={(e) => navigate('/edit-perusahaan', { state: { page: 'profile', data: detailCompany } })}

                          src={EditIcon} height={20} width={20} />}
                    </Stack>
                    <Stack flexDirection={'column'} sx={{ mb: 2 }}>
                      <Typography
                        className="w-400"
                        sx={{ color: '#56617B', fontSize: 12 }}
                      >
                        Alternative Name
                      </Typography>
                      <Typography
                        className="w-500"
                        sx={{ color: '#081130', fontSize: 14 }}
                      >
                        {detailCompany?.alternative_name ? detailCompany.alternative_name : "-"}
                      </Typography>
                    </Stack>

                    <Grid container flexDirection={{ xs: 'column', sm: 'row', md: 'row', lg: 'row' }}>
                      <Grid item xs >
                        <Stack flexDirection={'row'} sx={{ mb: '14px' }}>
                          <img style={{ marginRight: '10px' }} alt="code-company-icon" src={phoneCompanyIcon} height={16} width={16}></img>
                          <Stack flexDirection={'column'}>
                            <Typography
                              className="w-400"
                              sx={{ color: '#56617B', fontSize: 12 }}
                            >
                              {translate('status.nib')}
                            </Typography>
                            <Typography
                              className="w-500"
                              sx={{ color: '#081130', fontSize: 14 }}
                            >
                              {detailCompany?.nomor_induk_berusaha === null ? '-' : detailCompany?.nomor_induk_berusaha}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Stack flexDirection={'row'} sx={{ mb: '14px' }}>
                          <img style={{ marginRight: '10px' }} alt="code-company-icon" src={phoneCompanyIcon} height={16} width={16}></img>
                          <Stack flexDirection={'column'}>
                            <Typography
                              className="w-400"
                              sx={{ color: '#56617B', fontSize: 12 }}
                            >
                              {translate('page_explore.namaperusahaan')}
                            </Typography>
                            <Typography
                              className="w-500"
                              sx={{ color: '#081130', fontSize: 14 }}
                            >
                              {detailCompany?.name}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Stack flexDirection={'row'} sx={{ mb: '14px' }}>
                          <img style={{ marginRight: '10px' }} alt="code-company-icon" src={phoneCompanyIcon} height={16} width={16}></img>
                          <Stack flexDirection={'column'}>

                            <Typography
                              className="w-400"
                              sx={{ color: '#56617B', fontSize: 12 }}
                            >
                              {translate('status.jenisbadanusaha')}
                            </Typography>
                            <Typography
                              className="w-500"
                              sx={{ color: '#081130', fontSize: 14 }}
                            >
                              {detailCompany.jenis_perusahaan ? detailCompany.jenis_perusahaan.toUpperCase() : ""}
                            </Typography>
                          </Stack>
                        </Stack>


                      </Grid>
                      <Grid item xs>
                        <Stack flexDirection={'row'} sx={{ mb: '14px' }}>
                          <img style={{ marginRight: '10px' }} alt="code-company-icon" src={mailProfileCompanyIcon} height={16} width={16}></img>
                          <Stack flexDirection={'column'}>

                            <Typography
                              className="w-400"
                              sx={{ color: '#56617B', fontSize: 12 }}
                            >
                              {translate('status.noakta')}
                            </Typography>
                            <Typography
                              onClick={() => {
                                setModalAktePerusahaan(true);
                              }}
                              className="w-500"
                              sx={{ color: 'var(--primarry-blue, #2457B9)', fontSize: 14, lineHeight: 'normal', textDecorationLine: 'underline', cursor: 'pointer' }}
                            >
                              {detailCompany?.akta_nomor}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Stack flexDirection={'row'} sx={{ mb: '14px' }}>
                          <img style={{ marginRight: '10px' }} alt="code-company-icon" src={networkCompanyIcon} height={16} width={16}></img>
                          <Stack flexDirection={'column'}>
                            <Typography
                              className="w-400"
                              sx={{ color: '#56617B', fontSize: 12 }}
                            >
                              {translate('status.tglakta')}
                            </Typography>
                            {detailCompany.akta_date && <Typography
                              className="w-500"
                              sx={{ color: '#081130', fontSize: 14 }}
                            >
                              {new Intl.DateTimeFormat('id', { year: 'numeric', month: 'numeric', day: '2-digit' }).format(new Date(detailCompany.akta_date))}
                            </Typography>}
                            {detailCompany.akta_date === null && <Typography
                              className="w-500"
                              sx={{ color: '#081130', fontSize: 14 }}
                            >
                              -
                            </Typography>}
                          </Stack>
                        </Stack>
                        <Stack flexDirection={'row'} sx={{ mb: '14px' }}>
                          <img style={{ marginRight: '10px' }} alt="code-company-icon" src={networkCompanyIcon} height={16} width={16}></img>
                          <Stack flexDirection={'column'}>
                            <Typography
                              className="w-400"
                              sx={{ color: '#56617B', fontSize: 12 }}
                            >
                              {translate('status.suratnpwp')}
                            </Typography>
                            <Typography
                              onClick={() => {
                                setModalNPWP(true);
                              }}
                              className="w-500"
                              sx={{ color: 'var(--primarry-blue, #2457B9)', fontSize: 14, lineHeight: 'normal', textDecorationLine: 'underline', cursor: 'pointer' }}
                            >
                              {detailCompany.npwp_nomor ? detailCompany.npwp_nomor : '-'}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Grid>
                    </Grid>
                    <Stack direction={'row'} alignItems={'center'} sx={{ mt: '24px', mb: '10px' }}>
                      <Typography
                        sx={{ fontSize: 18, color: '#222525', mr: '10px' }}
                        className="w-700"
                      >
                        {translate('profile.address')}
                      </Typography>
                      {isOwnerCompany && <img style={{ cursor: 'pointer' }}
                        onClick={(e) => navigate('/edit-perusahaan', { state: { page: 'alamat', data: detailCompany } })}

                        src={EditIcon} height={20} width={20} />}
                    </Stack>
                    {detailCompany.alamat_list &&
                      <Box>
                        {detailCompany.alamat_list.map((item, i) => {
                          if (i < limitalamat) return (
                            <Box sx={{ mb: '12px' }}>
                              <Typography
                                className="w-500"
                                sx={{ color: '#081130', fontSize: 14, mb: '10px' }}
                              >
                                {item.detail_alamat}
                              </Typography>
                              <Stack flexDirection={'row'} alignItems={'center'}>
                                {item.label_alamat && <Box
                                  sx={{
                                    display: 'inline-flex',
                                    background: "#D1D7E6",
                                    borderRadius: '8px',
                                    p: '2px 4px',
                                    mr: '8px',
                                  }}
                                >
                                  <Typography className="w-500" sx={{ color: '#56617B', fontSize: 12 }} >
                                    {item.label_alamat}
                                  </Typography>
                                </Box>}
                                {item.createdAt && <Typography
                                  className="w-400"
                                  sx={{ color: '#56617B', fontSize: 12 }}
                                >
                                  {translate('status.terdaftar')} {new Intl.DateTimeFormat('id', { year: 'numeric', month: 'short', day: '2-digit' }).format(new Date(item.createdAt))}
                                </Typography>}
                              </Stack>
                            </Box>
                          )
                        })}

                        {detailCompany.alamat_list.length > 1 &&
                          <Box>
                            {!showallalamat ?
                              <Grid sx={{ mt: '16px' }} className="pointer"
                                container
                                direction="row"
                                alignItems={'center'}
                                onClick={() => {
                                  setShowallalamat(true);
                                  setlimitalamat(detailCompany.alamat_list.length)
                                }}
                              >
                                <Typography
                                  className="w-700"
                                  sx={{ fontSize: 14, color: "#2457B9", mr: 1, }}
                                >
                                  {translate('status.lihatdataselengkapnya')}
                                </Typography>
                                <KeyboardArrowDownIcon sx={{ color: "#2457B9", height: '20px', width: '20px' }} />
                              </Grid> : <Grid sx={{ mt: '16px' }} className="pointer"
                                container
                                direction="row"
                                alignItems={'center'}
                                onClick={() => {
                                  setShowallalamat(false);
                                  setlimitalamat(1)
                                }}
                              >
                                <Typography
                                  className="w-700"
                                  sx={{ fontSize: 14, color: "#2457B9", mr: 1, }}
                                >
                                  {translate('status.Tampilkandatalebihsedikit')}
                                </Typography>
                                <KeyboardArrowUpIcon sx={{ color: "#2457B9", height: '20px', width: '20px' }} />
                              </Grid>

                            }
                          </Box>}

                      </Box>}
                    <Stack direction={'row'} alignItems={'center'} sx={{ mt: '24px', mb: '10px' }}>
                      <Typography
                        sx={{ fontSize: 18, color: '#222525', mr: '10px' }}
                        className="w-700"
                      >
                        {translate('status.perizinan')}
                      </Typography>
                      {isOwnerCompany && <img style={{ cursor: 'pointer' }}
                        onClick={(e) => navigate('/edit-perusahaan', { state: { page: 'perizinan', data: detailCompany } })}

                        src={EditIcon} height={20} width={20} />}
                    </Stack>
                    {detailCompany.perizinan_list &&
                      <Box>
                        <Grid container flexDirection={'row'} spacing={2} sx={{ mb: '12px' }}>
                          {detailCompany.perizinan_list.map((item, i) => {
                            if (i < limitperizinan) return (
                              <>
                                <Grid item xs={12} sm={4} md={4} lg={4} >
                                  <Box sx={{ border: '1px solid var(--grey-stroke, #E8EBF2)', borderRadius: '8px', p: '16px' }}>
                                    <Typography
                                      sx={{ fontSize: 14, color: '#081130' }}
                                      className="w-500"
                                    >
                                      {item.nomor_perizinan}
                                    </Typography>
                                    {item.createdAt && <Typography
                                      sx={{ fontSize: 12, color: '#56617B', mt: '6px' }}
                                      className="w-400"
                                    >
                                      {translate('status.terdaftar')} {new Intl.DateTimeFormat('id', { year: 'numeric', month: 'short', day: '2-digit' }).format(new Date(item.createdAt))}
                                    </Typography>}
                                    <Grid sx={{ mt: '16px' }} className="pointer"
                                      container
                                      onClick={() => {
                                        setModalPerizinan(true);
                                      }}
                                      direction="row"
                                    >
                                      <Typography
                                        className="w-700"
                                        sx={{ fontSize: 14, color: "#2457B9", mr: 1, }}
                                      >
                                        {translate('page_explore.lihatdetail')}
                                      </Typography>
                                      <ArrowForwardIosIcon sx={{ color: "#2457B9", height: '20px', width: '20px' }} />

                                    </Grid>
                                  </Box>
                                </Grid>
                                <ModalDetailPerizinan detailPerizinan={item} open={isModalPerizinan} close={() => { setModalPerizinan(false) }}></ModalDetailPerizinan>
                              </>
                            )

                          })}
                        </Grid>
                        {detailCompany.perizinan_list.length > 3 &&
                          <Box>
                            {!showallperizinan ? <Grid sx={{ mt: '16px' }} className="pointer"
                              container
                              direction="row"
                              alignItems={'center'}
                              onClick={() => {
                                setShowallperizinan(true);
                                setlimitperizinan(detailCompany.perizinan_list.length)
                              }}
                            >
                              <Typography
                                className="w-700"
                                sx={{ fontSize: 14, color: "#2457B9", mr: 1, }}
                              >
                                {translate('status.lihatdataselengkapnya')}
                              </Typography>
                              <KeyboardArrowDownIcon sx={{ color: "#2457B9", height: '20px', width: '20px' }} />
                            </Grid> : <Grid sx={{ mt: '16px' }} className="pointer"
                              container
                              direction="row"
                              alignItems={'center'}
                              onClick={() => {
                                setShowallperizinan(false);
                                setlimitperizinan(3)
                              }}
                            >
                              <Typography
                                className="w-700"
                                sx={{ fontSize: 14, color: "#2457B9", mr: 1, }}
                              >
                                {translate('status.Tampilkandatalebihsedikit')}
                              </Typography>
                              <KeyboardArrowUpIcon sx={{ color: "#2457B9", height: '20px', width: '20px' }} />
                            </Grid>}


                          </Box>}
                      </Box>}
                    {detailCompany?.direksi_list !== undefined && (detailCompany?.direksi_list.length !== 0) && <Stack direction={'row'} alignItems={'center'} sx={{ mt: '24px', mb: '10px' }}>
                      <Typography
                        sx={{ fontSize: 18, color: '#222525', mr: '10px' }}
                        className="w-700"
                      >
                        {translate('status.direksi')}
                      </Typography>
                      {isOwnerCompany && <img style={{ cursor: 'pointer' }}

                        onClick={(e) => navigate('/edit-perusahaan', { state: { page: 'direksi', data: detailCompany } })}
                        src={EditIcon} height={20} width={20} />}
                    </Stack>}
                    {detailCompany.direksi_list &&
                      <Box>
                        <Grid container flexDirection={{ xs: 'column', sm: 'row', md: 'row', lg: 'row' }} sx={{ mb: '12px' }}>

                          {detailCompany.direksi_list.map((item, i) => {
                            if (i < limitdireksi) return (
                              <Grid item xs={12} sm={3} md={3} lg={3} sx={{ mt: 1 }} >
                                <Stack flexDirection={'row'} >
                                  <Avatar
                                    alt="Avatar"
                                    variant="circular"
                                    src={item.profile_picture !== null ? `${config.baseURL + item.profile_picture}` : LogoCompanyImg}
                                    sx={{ width: "40px", height: "40px", mr: 1 }}
                                  />
                                  <Stack >
                                    <Typography
                                      sx={{ fontSize: 14, color: '#081130' }}
                                      className="w-500"
                                    >
                                      {item.name}
                                    </Typography>
                                    <Typography
                                      sx={{ fontSize: 12, color: '#56617B', mt: '6px' }}
                                      className="w-400"
                                    >
                                      {item.position === null ? '-' : item.position}
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </Grid>
                            )
                          })}
                        </Grid>

                        {detailCompany.direksi_list.length > 8 &&
                          <Box>
                            {!showalldireksi ? <Grid sx={{ mt: '16px' }} className="pointer"
                              container
                              direction="row"
                              alignItems={'center'}
                              onClick={() => {
                                setShowalldireksi(true);
                                setlimitdireksi(detailCompany.direksi_list.length)
                              }}
                            >
                              <Typography
                                className="w-700"
                                sx={{ fontSize: 14, color: "#2457B9", mr: 1, }}
                              >
                                {translate('status.lihatdataselengkapnya')}
                              </Typography>
                              <KeyboardArrowDownIcon sx={{ color: "#2457B9", height: '20px', width: '20px' }} />
                            </Grid> : <Grid sx={{ mt: '16px' }} className="pointer"
                              container
                              direction="row"
                              alignItems={'center'}
                              onClick={() => {
                                setShowalldireksi(false);
                                setlimitdireksi(8)
                              }}
                            >
                              <Typography
                                className="w-700"
                                sx={{ fontSize: 14, color: "#2457B9", mr: 1, }}
                              >
                                {translate('status.Tampilkandatalebihsedikit')}
                              </Typography>
                              <KeyboardArrowUpIcon sx={{ color: "#2457B9", height: '20px', width: '20px' }} />
                            </Grid>
                            }
                          </Box>}
                      </Box>}
                    {detailCompany?.pemilik_saham_list !== undefined && (detailCompany?.pemilik_saham_list.length !== 0) && <Stack direction={'row'} alignItems={'center'} sx={{ mt: '24px', mb: '10px' }}>
                      <Typography
                        sx={{ fontSize: 18, color: '#222525', mr: '10px' }}
                        className="w-700"
                      >
                        {translate('status.pemegangsaham')}
                      </Typography>
                      {isOwnerCompany && <img style={{ cursor: 'pointer' }}
                        onClick={(e) => navigate('/edit-perusahaan', { state: { page: 'pemegangsaham', data: detailCompany } })}

                        src={EditIcon} height={20} width={20} />}
                    </Stack>}
                    {detailCompany.pemilik_saham_list &&
                      <Box>
                        <Grid container flexDirection={{ xs: 'column', sm: 'row', md: 'row', lg: 'row' }} spacing={2} sx={{ mb: '12px' }}>
                          {detailCompany.pemilik_saham_list.map((item, i) => {

                            if (i < limitpemegangsaham) return (
                              <Grid item xs={12} sm={4} md={4} lg={4} >
                                <Box sx={{ border: '1px solid var(--grey-stroke, #E8EBF2)', borderRadius: '8px', p: '16px' }}>
                                  <Typography
                                    sx={{ fontSize: 14, color: '#081130' }}
                                    className="w-500"
                                  >
                                    {item.name}
                                  </Typography>
                                  <Typography
                                    sx={{ fontSize: 12, color: '#56617B', mt: '6px' }}
                                    className="w-400"
                                  >

                                    {item.jenis_saham}
                                  </Typography>
                                  {isShowAllPemegangSaham === true ? <Box>
                                    <Grid container direction={'row'} justifyContent={'space-between'}>
                                      <Grid item xs>
                                        <Typography
                                          sx={{ fontSize: 12, color: '#56617B', mt: '6px' }}
                                          className="w-400"
                                        >
                                          {translate('status.jenis')}
                                        </Typography>
                                        <Typography
                                          sx={{ fontSize: 14, color: '#081130', mt: '6px' }}
                                          className="w-400"
                                        >
                                          {item.jenis_saham}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs>
                                        <Typography
                                          sx={{ fontSize: 12, color: '#56617B', mt: '6px' }}
                                          className="w-400"
                                        >
                                          {translate('status.asalnegara')}
                                        </Typography>
                                        <Typography
                                          sx={{ fontSize: 14, color: '#081130', mt: '6px' }}
                                          className="w-400"
                                        >
                                          {item.negara.name}
                                        </Typography>
                                      </Grid>


                                    </Grid>
                                    <Grid container direction={'row'} justifyContent={'space-between'}>
                                      <Grid item xs>
                                        <Typography
                                          sx={{ fontSize: 12, color: '#56617B', mt: '6px' }}
                                          className="w-400"
                                        >
                                          {translate('status.percentase')}
                                        </Typography>
                                        <Typography
                                          sx={{ fontSize: 14, color: '#081130', mt: '6px' }}
                                          className="w-400"
                                        >
                                          {item.persentase}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs>

                                      </Grid>


                                    </Grid>
                                  </Box> : <></>}
                                  {isShowAllPemegangSaham === false ? <Grid sx={{ mt: '16px' }} className="pointer"
                                    container
                                    direction="row"
                                    onClick={() => {
                                      setisShowAllPemegangSaham(true);
                                      setlimitpemegangsaham(detailCompany.pemilik_saham_list.length)
                                    }}
                                  >
                                    <Typography
                                      className="w-700"
                                      sx={{ fontSize: 14, color: "#2457B9", mr: 1, }}
                                    >
                                      {translate('page_explore.lihatdetail')}
                                    </Typography>
                                    <ArrowForwardIosIcon sx={{ color: "#2457B9", height: '20px', width: '20px' }} />

                                  </Grid> : <Grid sx={{ mt: '16px' }} onClick={() => {
                                    setisShowAllPemegangSaham(false);
                                    setlimitpemegangsaham(3)

                                  }} className="pointer"
                                    container
                                    direction="row"
                                  >
                                    <Typography
                                      className="w-700"
                                      sx={{ fontSize: 14, color: "#2457B9", mr: 1, }}
                                    >
                                      {translate('status.hide')}
                                    </Typography>
                                    <ArrowForwardIosIcon sx={{ color: "#2457B9", height: '20px', width: '20px' }} />

                                  </Grid>}
                                </Box>
                              </Grid>


                            )
                          })}
                        </Grid>
                        {detailCompany.pemilik_saham_list.length > 3 &&
                          <Box>
                            {!showallpemegangsaham ? <Grid sx={{ mt: '16px' }} className="pointer"
                              container
                              direction="row"
                              alignItems={'center'}
                              onClick={() => {
                                setShowallpemegangsaham(true);
                                setlimitpemegangsaham(detailCompany.pemilik_saham_list.length)
                              }}
                            >
                              <Typography
                                className="w-700"
                                sx={{ fontSize: 14, color: "#2457B9", mr: 1, }}
                              >
                                {translate('status.lihatdataselengkapnya')}
                              </Typography>
                              <KeyboardArrowDownIcon sx={{ color: "#2457B9", height: '20px', width: '20px' }} />
                            </Grid> : <Grid sx={{ mt: '16px' }} className="pointer"
                              container
                              direction="row"
                              alignItems={'center'}
                              onClick={() => {
                                setShowallpemegangsaham(false);
                                setlimitpemegangsaham(3)
                              }}
                            >
                              <Typography
                                className="w-700"
                                sx={{ fontSize: 14, color: "#2457B9", mr: 1, }}
                              >
                                {translate('status.Tampilkandatalebihsedikit')}
                              </Typography>
                              <KeyboardArrowUpIcon sx={{ color: "#2457B9", height: '20px', width: '20px' }} />
                            </Grid>}
                          </Box>}
                      </Box>}
                  </Box> : ''}

                  {activeTabCompany === 'staff' ? <Box>
                    {isOwnerCompany && listJoinCompany.itemCount > 0 ?
                      <Box>
                        <Stack flexDirection={'row'} justifyContent={'space-between'} sx={{ mt: '32px' }}>
                          <Hidden smUp>
                            <Typography
                              sx={{ fontSize: 16, color: '#222525', mb: '12px' }}
                              className="w-700"
                            >
                              {translate('status.permintaangabung')}  ({listJoinCompany.itemCount})
                            </Typography>
                          </Hidden>
                          <Hidden smDown>
                            <Typography
                              sx={{ fontSize: '18px', color: '#222525', mb: '12px' }}
                              className="w-700"
                            >
                              {translate('status.daftarpermintaangabung')}  ({listJoinCompany.itemCount})
                            </Typography>
                          </Hidden>
                          <Typography
                            onClick={() => {
                              SubmitActionOwner('', 'accept', 'all')
                            }}
                            sx={{ fontSize: '16px', color: '#2457B9', mb: '12px', lineHeight: '22px', cursor: 'pointer' }}
                            className="w-500"
                          >
                            {translate('status.setujuisemua')}
                          </Typography>
                        </Stack>
                        {listJoinCompany.data.map((item, i) => {
                          if (i < limitrequestjoin) return (
                            <Box sx={{ border: '1px solid var(--grey-stroke, #E8EBF2);', borderRadius: '8px', p: '16px 24px', mb: '16px' }}>
                              <Stack flexDirection={{ xs: 'column', sm: 'row' }} justifyContent={'space-between'} alignItems={{ xs: 'normal', sm: 'center' }}>
                                <Grid sx={{ display: 'flex' }} justifyContent={'left'} alignItems={'center'}>
                                  <Avatar sx={{ bgcolor: 'blue', mr: '6px', height: 48, width: 48 }} src={config.baseURL + item.url_image_user} aria-label="recipe">
                                  </Avatar>
                                  <Stack direction="column">
                                    <Typography
                                      className="w-700"
                                      sx={{ fontSize: 14, ml: 1, }}
                                    >
                                      {item.name}
                                    </Typography>
                                    <Stack direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }} sx={{ ml: 1, }} alignItems={'center'} >
                                      <Stack direction="row"  >
                                        <img alt="Nikel-icon" src={locationCompanyIcon} height={20} width={20}></img>
                                        <Typography
                                          className="w-500"
                                          sx={{ fontSize: 14, ml: 1, color: '#56617B' }}
                                        >
                                          {item.kota},  {item.provinsi}
                                        </Typography>
                                      </Stack>
                                      <Hidden smDown>
                                        <Box sx={{ width: '3px', height: '3px', background: '#8792AB', borderRadius: '100%', ml: '6px', mr: '6px' }}>

                                        </Box>
                                      </Hidden>
                                      <Stack direction="row" >
                                        <img alt="Nikel-icon" src={MailCompanyIcon} height={20} width={20}></img>
                                        <Typography
                                          className="w-500"
                                          sx={{ fontSize: 14, ml: 1, color: '#56617B' }}
                                        >
                                          {item.email_user}
                                        </Typography>
                                      </Stack>
                                    </Stack>
                                  </Stack>
                                </Grid>
                                <Hidden smDown>
                                  <Grid direction={'row'}>
                                    <Button
                                      disabled={isloadingbtn}
                                      onClick={() => {
                                        SubmitActionOwner(item.id_user, 'accept', '')
                                      }}
                                      type="submit"
                                      fullWidth
                                      className="button-blue-contained w-500"
                                      variant="contained"
                                      sx={{ p: '8px 12px', width: { xs: '100%', sm: '67px' }, height: '35px', mr: '10px', }}
                                    >
                                      <Typography className="w-500" sx={{ textTransform: 'none', fontSize: 14 }}>    {translate('status.setujui')}
                                      </Typography>
                                    </Button>
                                    <Button
                                      disabled={isloadingbtn}
                                      onClick={() => {
                                        SubmitActionOwner(item.id_user, 'reject', '')
                                      }}
                                      size="large"
                                      type="submit"
                                      sx={{ width: { xs: '100%', sm: '67px' }, height: '35px' }}
                                      className=" button-blue-outlined w-500"
                                      variant="outlined"
                                    >
                                      <Typography className="w-500" sx={{ fontSize: 14, color: "#2457B9", lineHeight: '22px', }}>
                                        {translate('status.tolak')}
                                      </Typography>

                                    </Button>
                                  </Grid>
                                </Hidden>
                                <Hidden smUp >
                                  <Stack direction={'row'} sx={{ mt: '12px' }}>
                                    <Button
                                      disabled={isloadingbtn}
                                      onClick={() => {
                                        SubmitActionOwner(item.id_user, 'accept', '');
                                      }}
                                      type="submit"
                                      fullWidth
                                      className="button-blue-contained w-500"
                                      variant="contained"
                                      sx={{ p: '8px 12px', width: '100%', height: '35px', mr: '10px', }}
                                    >
                                      <Typography className="w-500" sx={{ textTransform: 'none', fontSize: 14 }}>  {translate('status.setujui')}
                                      </Typography>
                                    </Button>
                                    <Button
                                      disabled={isloadingbtn}
                                      onClick={() => {
                                        SubmitActionOwner(item.id_user, 'reject', '')
                                      }}
                                      size="large"
                                      type="submit"
                                      sx={{ width: '100%', height: '35px' }}
                                      className=" button-blue-outlined w-500"
                                      variant="outlined"
                                    >
                                      <Typography className="w-500" sx={{ fontSize: 14, color: "#2457B9", lineHeight: '22px', }}>
                                        {translate('status.tolak')}
                                      </Typography>

                                    </Button>
                                  </Stack>
                                </Hidden>
                              </Stack>

                            </Box>
                          )
                        })}
                        {
                          listJoinCompany.itemCount > 3 &&
                          <Box>
                            {!showallrequestjoin ?
                              <Grid sx={{ mt: '16px' }} className="pointer"
                                container
                                justifyContent={{ xs: 'center', sm: 'left' }}
                                direction="row"
                                alignItems={'center'}
                                onClick={() => {
                                  setshowallrequestjoin(true);
                                  setlimitrequestjoin(listJoinCompany.itemCount)
                                }}
                              >
                                <Typography
                                  className="w-700"
                                  sx={{ fontSize: 14, color: "#2457B9", mr: 1, }}
                                >
                                  {translate('status.lihatsemua')}
                                </Typography>
                                <KeyboardArrowDownIcon sx={{ color: "#2457B9", height: '20px', width: '20px' }} />
                              </Grid> :
                              <Grid sx={{ mt: '16px' }} className="pointer"
                                container
                                direction="row"
                                justifyContent={{ xs: 'center', sm: 'left' }}
                                alignItems={'center'}
                                onClick={() => {
                                  setshowallrequestjoin(false);
                                  setlimitrequestjoin(3)
                                }}
                              >
                                <Typography
                                  className="w-700"
                                  sx={{ fontSize: 14, color: "#2457B9", mr: 1, }}
                                >
                                  {translate('status.lihatlebihsedikit')}
                                </Typography>
                                <KeyboardArrowUpIcon sx={{ color: "#2457B9", height: '20px', width: '20px' }} />
                              </Grid>
                            }
                          </Box>
                        }
                      </Box> : <></>}

                    {/* Responsive staff Section */}
                    <Hidden smUp>
                      <Stack alignItems={'center'} direction={'row'} justifyContent={'space-between'} sx={{ mt: 2, mb: 1, }}>
                        <Grid className="searchtrader-explore-page" item xs>
                          <TextField
                            className="box-input-filter"
                            type="text"
                            size="small"
                            value={searchTextStaff}
                            placeholder={translate('status.cari')}
                            onChange={async (e) => {
                              setSearchTextStaff(e.target.value)
                              let staff = await getStaffCompanyListing(1, e.target.value)
                              setListStaffCompany(staff)
                            }
                            }
                            sx={{
                              width: { xs: 'calc(100% - 16px)', sm: 'calc(100% - 16px)', md: '100%', lg: '100%', xl: '100%' }, input: {
                                fontSize: '14px', pt: '12px', pb: '12px'

                              },
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img alt="search-icon" src={searchIcon} height={20} width={20}></img>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        {isOwnerCompany && <Grid item>
                          <Button
                            size="medium"
                            className="button-blue-outlined w-500"
                            onClick={(e) => {
                              window.location.href = `/invite-staff?id_company=${location.state.id}`
                            }}
                            variant="outlined"
                            sx={{ p: '12px ', minWidth: '36px', maxWidth: '160px', }}
                          >
                            <AddIcon sx={{ width: '20', height: '20' }}></AddIcon>

                          </Button>
                        </Grid>}
                      </Stack>
                    </Hidden>
                    {/* Dekstop staff Section */}
                    <Hidden smDown>
                      <Stack alignItems={'center'} direction={'row'} justifyContent={'space-between'} sx={{ mt: 2, mb: '24px', }}>
                        <Grid className="searchtrader-explore-page" item xs>
                          {isOwnerCompany && listStaffCompany.length > 0 ? <TextField
                            className="box-input-filter"
                            type="text"
                            size="small"
                            value={searchTextStaff}
                            placeholder={translate('detail-explore.search-staff')}
                            onChange={async (e) => {
                              setSearchTextStaff(e.target.value)
                              let staff = await getStaffCompanyListing(1, e.target.value)
                              setListStaffCompany(staff)
                            }
                            }
                            sx={{
                              width: 'calc(100% - 16px)', input: {
                                fontSize: '14px', pt: '12px', pb: '12px', fontFamily: 'Satoshi-500'

                              },
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img alt="search-icon" src={searchIcon} height={20} width={20}></img>
                                </InputAdornment>
                              ),
                            }}
                          /> : <TextField
                            className="box-input-filter"
                            type="text"
                            size="small"
                            value={searchTextStaff}
                            placeholder={translate('detail-explore.search-staff')}
                            onChange={async (e) => {
                              setSearchTextStaff(e.target.value)
                              let staff = await getStaffCompanyListing(1, e.target.value)
                              setListStaffCompany(staff)
                            }
                            }
                            sx={{
                              width: '100%', input: {
                                fontSize: '14px', pt: '12px', pb: '12px', fontFamily: 'Satoshi-500'

                              },
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img alt="search-icon" src={searchIcon} height={20} width={20}></img>
                                </InputAdornment>
                              ),
                            }}
                          />}
                        </Grid>
                        {isOwnerCompany && listStaffCompany.length > 0 ? <Grid item>
                          <Button
                            onClick={(e) => {
                              window.location.href = `/invite-staff?id_company=${location.state.id}`
                            }}
                            size="large"
                            type="submit"
                            sx={{ width: '152px', height: '48px', p: '12px 16px' }}
                            className=" button-blue-outlined w-500"
                            variant="outlined"
                          >
                            <Typography className="w-500" sx={{ fontSize: 16, color: "#2457B9", lineHeight: '22px', }}>
                              Invite Staff
                            </Typography>

                          </Button>
                        </Grid> : <></>}
                      </Stack>

                    </Hidden>
                    {isLoadingCompanyListing ? (
                      <div style={{ paddingTop: "40px", textAlign: "center" }}>
                        <CircularProgress
                          sx={{
                            color: (theme) =>
                              theme.palette.grey[theme.palette.mode === 'dark' ? 200 : 800],
                          }}
                          size={30}
                          thickness={4}
                          value={100}
                        />
                      </div>
                    ) : (
                      <>
                        <TabelStaff id_company={location.state.id} name_company={location.state.name} data={listStaffCompany} isOwnerCompany={isOwnerCompany} ></TabelStaff>
                        {totalPageListStaffCompany <= pageListStaffCompany || totalPageListStaffCompany === 0 ? (
                          <></>
                        ) : (
                          <>
                            {isLoadingStaff ? (
                              <div style={{ paddingTop: "40px", textAlign: "center" }}>
                                <CircularProgress
                                  sx={{
                                    color: (theme) =>
                                      theme.palette.grey[theme.palette.mode === 'dark' ? 200 : 800],
                                  }}
                                  size={30}
                                  thickness={4}
                                  value={100}
                                />
                              </div>
                            ) : (
                              <>
                                <Stack onClick={async () => {
                                  setPageListStaffCompany(pageListStaffCompany + 1)
                                  let newStaff = await getStaffCompanyListing(pageListStaffCompany + 1, searchTextStaff)
                                  let a = listStaffCompany.concat(newStaff)
                                  setListStaffCompany(a)
                                }} direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{ mt: '12px' }} className="pointer">
                                  <Typography
                                    sx={{ fontSize: '16px', color: '#2457B9', ml: 1 }}
                                    className="w-500"
                                  >

                                    {translate('title.lihatsemuastaff')}
                                  </Typography>
                                  <KeyboardArrowDownIcon sx={{ color: '#2457B9' }} />
                                </Stack>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Box> : ''}
                  {activeTabCompany === 'listcoal' ? <Box>
                    <Hidden smUp>
                      <TextField
                        className="box-input-filter"
                        type="text"
                        size="small"
                        placeholder={translate('status.cari')}
                        value={searchTextBatubara}
                        onChange={async (e) => {
                          setSearchTextBatubara(e.target.value)
                          let batubara = await getBatubaraCompanyListing(1, e.target.value)
                          setListBatubaraCompany(batubara)
                        }}
                        sx={{
                          width: '100%', mt: 2, mb: 1, input: {
                            fontSize: '14px', pt: '12px', pb: '12px', fontFamily: 'Satoshi-500'
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img alt="search-icon" src={searchIcon} height={20} width={20}></img>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Hidden>
                    <Hidden smDown>
                      <TextField
                        className="box-input-filter"
                        type="text"
                        size="small"
                        placeholder={translate('page_explore.search-batubara')}
                        value={searchTextBatubara}
                        onChange={async (e) => {
                          setSearchTextBatubara(e.target.value)
                          let batubara = await getBatubaraCompanyListing(1, e.target.value)
                          setListBatubaraCompany(batubara)
                        }}
                        sx={{
                          width: '100%', mt: 2, mb: 1, input: {
                            fontSize: '14px', pt: '12px', pb: '12px', fontFamily: 'Satoshi-500'

                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img alt="search-icon" src={searchIcon} height={20} width={20}></img>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Hidden>
                    {isLoadingCompanyListing ? (
                      <div style={{ paddingTop: "40px", textAlign: "center" }}>
                        <CircularProgress
                          sx={{
                            color: (theme) =>
                              theme.palette.grey[theme.palette.mode === 'dark' ? 200 : 800],
                          }}
                          size={30}
                          thickness={4}
                          value={100}
                        />
                      </div>
                    ) : (
                      <>
                        <TabelCoalCompany data={listBatubaraCompany}></TabelCoalCompany>
                        <Hidden mdUp>
                          {listBatubaraCompany?.length !== 0 ?
                            <Grid columnSpacing={2.5} container direction={'row'}>
                              {listBatubaraCompany?.map(data => (
                                <CardListCoal page={'detailcompany'} coalList={data} />
                              ))}
                            </Grid> :

                            <Typography
                              sx={{ fontSize: '16px', color: '#56617B', mt: '24px', textAlign: 'center', mb: '24px' }}
                              className="w-500"
                            >
                              {translate('status.emptylistcoal')}
                            </Typography>}
                        </Hidden>
                        {totalPageListBatubaraCompany <= pageListBatubaraCompany || totalPageListBatubaraCompany === 0 ? (
                          <></>
                        ) : (
                          <>
                            {isLoadingBatubara ? (
                              <div style={{ paddingTop: "40px", textAlign: "center" }}>
                                <CircularProgress
                                  sx={{
                                    color: (theme) =>
                                      theme.palette.grey[theme.palette.mode === 'dark' ? 200 : 800],
                                  }}
                                  size={30}
                                  thickness={4}
                                  value={100}
                                />
                              </div>
                            ) : (
                              <>
                                <Stack onClick={async () => {
                                  setPageListBatubaraCompany(pageListBatubaraCompany + 1)
                                  let newBatubara = await getBatubaraCompanyListing(pageListBatubaraCompany + 1, searchTextBatubara)
                                  let a = listBatubaraCompany.concat(newBatubara)
                                  setListBatubaraCompany(a)
                                }} direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{ mt: '12px' }} className="pointer">
                                  <Typography
                                    sx={{ fontSize: '16px', color: '#2457B9', ml: 1 }}
                                    className="w-500"
                                  >
                                    {translate('page_explore.lihatlebihbanyak')}
                                  </Typography>
                                  <KeyboardArrowDownIcon sx={{ color: '#2457B9' }} />
                                </Stack>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Box> : ''}
                  {activeTabCompany === 'listnikel' ? <Box>
                    <Hidden smUp>
                      <TextField
                        className="box-input-filter"
                        type="text"
                        size="small"
                        placeholder={translate('status.cari')}
                        value={searchTextNikel}
                        onChange={async (e) => {
                          setSearchTextNikel(e.target.value)
                          let nikel = await getNikelCompanyListing(1, e.target.value)
                          setListNikelCompany(nikel)
                        }}
                        sx={{
                          width: '100%', mt: 2, mb: 1, input: {
                            fontSize: '14px', pt: '12px', pb: '12px', fontFamily: 'Satoshi-500'

                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img alt="search-icon" src={searchIcon} height={20} width={20}></img>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Hidden>
                    <Hidden smDown>
                      <TextField
                        className="box-input-filter"
                        type="text"
                        size="small"
                        placeholder={translate('page_explore.search-nikel')}
                        value={searchTextNikel}
                        onChange={async (e) => {
                          setSearchTextNikel(e.target.value)
                          let nikel = await getNikelCompanyListing(1, e.target.value)
                          setListNikelCompany(nikel)
                        }}
                        sx={{
                          width: '100%', mt: 2, mb: 1, input: {
                            fontSize: '14px', pt: '12px', pb: '12px', fontFamily: 'Satoshi-500'

                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img alt="search-icon" src={searchIcon} height={20} width={20}></img>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Hidden>
                    {isLoadingCompanyListing ? (
                      <div style={{ paddingTop: "40px", textAlign: "center" }}>
                        <CircularProgress
                          sx={{
                            color: (theme) =>
                              theme.palette.grey[theme.palette.mode === 'dark' ? 200 : 800],
                          }}
                          size={30}
                          thickness={4}
                          value={100}
                        />
                      </div>
                    ) : (
                      <>
                        <TabelNikelCompany data={listNikelCompany}></TabelNikelCompany>
                        <Hidden mdUp>
                          {listNikelCompany?.length !== 0 ?
                            <Grid columnSpacing={2.5} container direction={'row'}>
                              {listNikelCompany.map(data => (
                                <CardListNikel key={data} page={'detailcompany'} nickelList={data} />
                              ))}
                            </Grid> : <Typography
                              sx={{ fontSize: '16px', color: '#56617B', mt: '24px', textAlign: 'center', mb: '24px' }}
                              className="w-500"
                            >
                              {translate('status.emptylistnikel')}

                            </Typography>}
                        </Hidden>
                        {totalPageListNikelCompany <= pageListNikelCompany || totalPageListNikelCompany === 0 ? (
                          <></>
                        ) : (
                          <>
                            {isLoadingNikel ? (
                              <div style={{ paddingTop: "40px", textAlign: "center" }}>
                                <CircularProgress
                                  sx={{
                                    color: (theme) =>
                                      theme.palette.grey[theme.palette.mode === 'dark' ? 200 : 800],
                                  }}
                                  size={30}
                                  thickness={4}
                                  value={100}
                                />
                              </div>
                            ) : (
                              <>
                                <Stack onClick={async () => {
                                  setPageListNikelCompany(pageListNikelCompany + 1)
                                  let newNikel = await getNikelCompanyListing(pageListNikelCompany + 1, searchTextNikel)
                                  let a = listNikelCompany.concat(newNikel)
                                  setListNikelCompany(a)
                                }} direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{ mt: '12px' }} className="pointer">
                                  <Typography
                                    sx={{ fontSize: '16px', color: '#2457B9', ml: 1 }}
                                    className="w-500"
                                  >
                                    {translate('page_explore.lihatlebihbanyak')}
                                  </Typography>
                                  <KeyboardArrowDownIcon sx={{ color: '#2457B9' }} />
                                </Stack>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}

                  </Box> : ''}
                  {activeTabCompany === 'listtongkang' ? <Box>
                    <Hidden smUp>
                      <TextField
                        className="box-input-filter"
                        type="text"
                        size="small"
                        value={searchTextTongkang}
                        placeholder={translate('status.cari')}
                        onChange={async (e) => {
                          setSearchTextTongkang(e.target.value)
                          let tongkang = await getTongkangCompanyListing(1, e.target.value)
                          setListTongkangCompany(tongkang)
                        }}
                        sx={{
                          width: '100%', mt: 2, mb: 1, input: {
                            fontSize: '14px', pt: '12px', pb: '12px', fontFamily: 'Satoshi-500'

                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img alt="search-icon" src={searchIcon} height={20} width={20}></img>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Hidden>
                    <Hidden smDown>
                      <TextField
                        className="box-input-filter"
                        type="text"
                        size="small"
                        value={searchTextTongkang}
                        placeholder={translate('page_explore.search-tongkang')}
                        onChange={async (e) => {
                          setSearchTextTongkang(e.target.value)
                          let tongkang = await getTongkangCompanyListing(1, e.target.value)
                          setListTongkangCompany(tongkang)
                        }}
                        sx={{
                          width: '100%', mt: 2, mb: 1, input: {
                            fontSize: '14px', pt: '12px', pb: '12px', fontFamily: 'Satoshi-500'

                          },

                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img alt="search-icon" src={searchIcon} height={20} width={20}></img>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Hidden>

                    {isLoadingCompanyListing ? (
                      <div style={{ paddingTop: "40px", textAlign: "center" }}>
                        <CircularProgress
                          sx={{
                            color: (theme) =>
                              theme.palette.grey[theme.palette.mode === 'dark' ? 200 : 800],
                          }}
                          size={30}
                          thickness={4}
                          value={100}
                        />
                      </div>
                    ) : (
                      <>{listTongkangCompany?.length !== 0 ?
                        <Grid container direction={'row'} spacing={2}>
                          {listTongkangCompany.map((item, i) => (
                            <>
                              <Grid item md={4} sm={6} xs={12}>

                                <CardTongkangListDefault page={'detailperusahaan'} data={item}>
                                </CardTongkangListDefault>
                              </Grid>
                            </>
                          ))}

                        </Grid> : <Typography
                          sx={{ fontSize: '16px', color: '#56617B', mt: '24px', textAlign: 'center', mb: '24px' }}
                          className="w-500"
                        >
                          {translate('status.emptylisttongkang')}
                        </Typography>}
                        {totalPageListTongkangCompany <= pageListTongkangCompany || totalPageListTongkangCompany === 0 ? (
                          <></>
                        ) : (
                          <>
                            {isLoadingTongkang ? (
                              <div style={{ paddingTop: "40px", textAlign: "center" }}>
                                <CircularProgress
                                  sx={{
                                    color: (theme) =>
                                      theme.palette.grey[theme.palette.mode === 'dark' ? 200 : 800],
                                  }}
                                  size={30}
                                  thickness={4}
                                  value={100}
                                />
                              </div>
                            ) : (
                              <>
                                <Stack onClick={async () => {
                                  setPageListTongkangCompany(pageListTongkangCompany + 1)
                                  let newTongkang = await getTongkangCompanyListing(pageListTongkangCompany + 1, searchTextTongkang)
                                  let a = listTongkangCompany.concat(newTongkang)
                                  setListTongkangCompany(a)
                                }} direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{ mt: '12px' }} className="pointer">
                                  <Typography
                                    sx={{ fontSize: '16px', color: '#2457B9', ml: 1 }}
                                    className="w-500"
                                  >
                                    {translate('page_explore.lihatlebihbanyak')}
                                  </Typography>
                                  <KeyboardArrowDownIcon sx={{ color: '#2457B9' }} />
                                </Stack>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Box> : ''}
                </Box>
              </Grid>
              {/* rightside Section */}
              <Grid lg={3.6} xs={12} item sx={{ pt: 0 }}>
                <CardBeforeLogin type={4}></CardBeforeLogin>
                <Box>
                  <Hidden lgDown>
                    {!isOwnerCompany &&
                      <Box sx={{ width: { xs: '100%', sm: '100%', md: '100%', lg: 355, xl: 355 }, }}>
                        {detailCompany.user_information_staff === 3 && <Box sx={{
                          borderRadius: '8px', mb: 2, background: 'var(--tint-blue, rgba(36, 87, 185, 0.22));', height: '60px',
                          p: '12px 34px 3px 12px',
                        }}>
                          <Typography
                            className="w-500"
                            sx={{ fontSize: 14, color: "#081130", }}
                          >
                            {translate('status.permintaanbergabungsedangdireview')}
                          </Typography>
                          {detailCompany.date_ask_join && <Typography
                            className="w-400"
                            sx={{ fontSize: 12, color: "#56617B", }}
                          >
                            {translate('status.datebergabung')}    {new Intl.DateTimeFormat('id', { year: 'numeric', month: 'short', day: '2-digit' }).format(new Date(detailCompany.date_ask_join))}
                          </Typography>}
                        </Box>}

                        {isLogin && <Box sx={{ border: 1, color: '#E8EBF2', borderRadius: 2, mb: 2, width: { xs: '100%', sm: '100%', md: '100%', lg: 355, xl: 355 }, }}>

                          <Box sx={{ pl: 2, pr: 2, mt: '16px', mb: 2 }}>
                            {detailCompany.user_information_staff === 1 && <Button
                              onClick={() => {
                                setisJoinCompany(false)
                                setModalJoinCompany(true);
                              }}

                              className="w-500" size="small" sx={{ background: '#2457B9', mb: 1, pt: '12px', pb: '12px', textTransform: 'none', borderRadius: '8px' }} variant="contained" fullWidth >
                              {translate('detail-explore.gabungperusahaan')}
                            </Button>}

                            {/* <Button className="w-500" target="_blank" href={`https://api.whatsapp.com/send?phone=${detailCompany?.phone_number?.toString().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')}`} size="small" variant="outlined" sx={{ pt: '12px', pb: '12px', textTransform: 'none', borderRadius: '8px' }} fullWidth startIcon={<CallIcon />}>
                              {translate('detail-explore.hubungiperusahaan')}
                            </Button> */}
                          </Box>

                          <Box sx={{ pl: 2, pr: 2, mt: 1, mb: 2 }}>
                            <Grid
                              container
                              justifyContent="left"
                              alignItems="center"
                              direction="row"
                              sx={{ cursor: 'pointer' }}
                              onClick={() => {
                                SubmitReportListing()
                              }}
                            >
                              <img alt="Nikel-icon" src={reporticon} height={24}></img>
                              <Typography
                                className="w-500"
                                sx={{ fontSize: 14, color: "#56617B", ml: 1 }}
                              >
                                {translate('status.laporkanperusahaan')}
                              </Typography>
                            </Grid>
                          </Box>
                          {detailCompany.user_information_staff === 2 && <Box sx={{ pl: 2, pr: 2, mt: 1, mb: 2 }}>
                            <Grid
                              container
                              justifyContent="left"
                              alignItems="center"
                              direction="row"
                              sx={{ cursor: 'pointer' }}
                              onClick={() => {
                                setModalJoinCompany(true);
                                setisJoinCompany(true)
                              }}
                            >
                              <CloseIcon sx={{ height: '24px', width: '24px', fill: '#56617B' }}></CloseIcon>
                              <Typography
                                className="w-500"
                                sx={{ fontSize: 14, color: "#56617B", ml: 1 }}
                              >
                                {translate('status.keluarperusahaan')}
                              </Typography>
                            </Grid>
                          </Box>}
                        </Box>}
                      </Box>
                    }
                  </Hidden>
                  {companyRecomendation && <Hidden lgDown>
                    <Box sx={{ width: { xs: '100%', sm: '100%', md: '100%', lg: 355, xl: 355 }, border: 1, color: '#E8EBF2', borderRadius: 2, }}>
                      <Typography className="w-700" sx={{ color: '#081130', fontSize: 18, mt: 1, pl: 2, mb: '24px' }}>
                        {translate('detail-explore.rekomendasiperusahaan')}
                      </Typography>
                      {companyRecomendation.map((item, i) => {
                        if (i < limitcompany) return (
                          <Box sx={{ pl: 2, pr: 2, mt: 1, mb: 2 }}>
                            <Grid
                              container
                              justifyContent="left"
                              alignItems="center"
                              direction="row"
                              onClick={(e) => {
                                window.location.href = `/detail/${capitalizeEachWord(convertText(item?.name))}`

                              }}
                              sx={{ mb: 2, cursor: 'pointer' }}
                            >
                              <Avatar src={item?.company_image !== null ? `${config.baseURL + item?.company_image}` : ''} sx={{ bgcolor: "blue", mr: 2 }} aria-label="recipe">
                                <CompanyDefault></CompanyDefault>
                              </Avatar>
                              <Stack direction={'column'}>
                                <Typography
                                  className="w-700"
                                  sx={{ fontSize: 14, ml: 1, color: '#081130' }}
                                >
                                  {capitalizeEachWord(item?.name)}

                                </Typography>
                                {item.kota !== null || item.provinsi !== null && <Stack direction={'row'}>
                                  <img alt="Nikel-icon" src={locationCompanyIcon} height={18} width={18}></img>

                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, ml: '2px', fontWeight: 500, color: '#56617B' }}
                                  >
                                    {item.kota}, {item.provinsi}
                                  </Typography>
                                </Stack>}
                              </Stack>
                            </Grid>
                            <Divider orientation="horizontal" style={{ marginTop: 10, marginBottom: 10, height: '1px' }} flexItem />
                          </Box>
                        );
                      })}
                      {limitcompany < companyRecomendation?.length &&
                        <Grid
                          sx={{ mt: '24px', mb: '24px', cursor: "pointer" }}
                          container
                          justifyContent="center"
                          alignItems="center"
                          direction="row"
                        >
                          <Typography onClick={(e) => {
                            setlimitCompany(companyRecomendation?.length)
                          }}
                            className="w-700"
                            sx={{ fontSize: 16, color: "#2457B9", mr: 1, }}
                          >
                            {translate('status.lihatsemua')}
                          </Typography>
                          <ArrowForwardIosIcon sx={{ fontSize: 14, color: "#2457B9" }} />
                        </Grid>}
                    </Box>
                  </Hidden>}
                  {detailCompany && <Hidden lgDown>
                    <Box sx={{ width: { xs: '100%', sm: '100%', md: '100%', lg: 355, xl: 355 }, }}>
                      <Grid container display="flex" justifyContent="space-between" direction={"row"} xs={12}>
                        <Typography
                          className="w-700"
                          sx={{ fontSize: 18, color: '#081130', mt: '24px', mb: '12px' }}
                        >
                          {translate('detail-explore.bagikanperusahaan')}
                        </Typography>
                        {copyClipboard}
                      </Grid>
                      <Box sx={{ background: '#F4F6FA', border: '1px solid #E8EBF2', borderRadius: '8px', pt: '16px', pl: '16px', pr: '16px', pb: '16px' }}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                          <Typography className="w-700" sx={{ color: '#56617B', fontSize: 14, }}>

                            {(`${config.staging}/${capitalizeEachWord(convertText(detailCompany?.name))}`).slice(0, 42) + '...'}
                          </Typography>
                          <img alt="copy-icon" src={copyIc} className="pointer" height={20} onClick={(e) => {
                            navigator.clipboard.writeText(`${config.staging}/detail/${capitalizeEachWord(convertText(detailCompany?.name))}`)
                            copyLink()
                          }}></img>
                        </Stack>
                      </Box>
                      <Stack sx={{ mt: '16px' }} direction={'row'} justifyContent={'space-evenly'} alignItems={'center'}>
                        <TwitterShareButton url={`${config.staging}/detail/${capitalizeEachWord(convertText(detailCompany?.name))}`}>
                          <img alt="twitter-icon" src={twitterIcon} height={'40px'} width={'40px'}></img>
                        </TwitterShareButton>
                        <WhatsappShareButton url={`${config.staging}/detail/${capitalizeEachWord(convertText(detailCompany?.name))}`}>
                          <img alt="wa-icon" src={waIcon} height={'40px'} width={'40px'}></img>
                        </WhatsappShareButton>
                        <TelegramShareButton url={`${config.staging}/detail/${capitalizeEachWord(convertText(detailCompany?.name))}`}>
                          <img alt="tele-icon" src={telegramIcon} height={'40px'} width={'40px'}></img>
                        </TelegramShareButton>
                        <FacebookMessengerShareButton appId={`${config.appId}`} url={`${config.staging}/detail/${capitalizeEachWord(convertText(detailCompany?.name))}`}>
                          <img alt="fb-icon" src={fbIcon} height={'40px'} width={'40px'}></img>
                        </FacebookMessengerShareButton>
                      </Stack>
                    </Box>
                  </Hidden>}
                </Box>
              </Grid>
            </Grid>

            <Hidden smUp>
              <Box className="box-slide" >
                <Box className="width-100"
                  sx={{
                    pb: 2
                  }}
                >
                  <Typography
                    className="w-700"
                    sx={{ fontSize: 18, color: "#081130", }}
                  >
                    {translate('detail-explore.rekomendasiperusahaan')}
                  </Typography>
                </Box>
                <Box
                  sx={{ mr: '4px', mb: '32px' }}
                >
                  <Slider {...company}>
                    {companyRecomendation.map((company, i) => (
                      <Box key={company.id} sx={{ mr: '4px', ml: '4px' }}>
                        <CardCompanyRecomendation page={'home'} data={company} />
                      </Box>
                    )
                    )}
                  </Slider>
                </Box>

              </Box>
            </Hidden>
          </div>
          {/* Floating Action Button Section */}
          {isLogin && <Hidden lgUp>
            {!isOwnerCompany &&
              <Grid sx={{ pl: { xs: '16px', sm: '46px', md: '78px', }, pr: { xs: '16px', sm: '46px', md: '78px', } }} onScroll={scrollHandler} container direction="row" className={`${position ? "disable-freeze-grid-new" : "freeze-grid-new"}`}>
                <Grid alignItems={'center'} lg={8} xs={12} item sx={{ width: '840px' }}>

                  {activeTab === 'company' ?
                    <Box >
                      <Hidden lgUp>
                        {detailCompany.user_information_staff === 1 && <Grid container direction={'row'} spacing={1} alignItems={'center'}>
                          <Grid item xs={10}>
                            <Button
                              onClick={() => {
                                setisJoinCompany(false)
                                setModalJoinCompany(true);

                              }}
                              fullWidth
                              className="w-500"
                              size="large" sx={{ background: '#2457B9', textTransform: 'none', fontSize: '14px', padding: '12px 16px', borderRadius: '8px' }}
                              variant="contained" >
                              {translate('status.join')}
                            </Button>
                          </Grid>

                          <Grid item xs={2} >
                            <Box onClick={(e) => handleOpenModal('more')} sx={{ border: '1px solid #56617B', cursor: 'pointer', borderRadius: '8px', p: '12px', display: 'flex', justifyContent: 'center' }} > <MoreHorizIcon sx={{ width: '20px', height: '20px' }} /> </Box>
                            <DrawerMore page="company" data={detailCompany} type={4} id={detailCompany?.id} share={handleShareModal} open={isOpenMore} close={handleCloseModal}>
                            </DrawerMore>
                            <ModalShareListingCompany data={detailCompany} open={isOpenModalShare} close={closeShareModal}></ModalShareListingCompany>
                          </Grid>
                        </Grid>}
                        {detailCompany.user_information_staff === 2 && <Grid container direction={'row'} spacing={1} alignItems={'center'}>

                          <Grid item xs={10}>
                            <Button
                              onClick={() => {
                                setModalJoinCompany(true);
                                setisJoinCompany(true)
                              }}
                              startIcon={<img width={20} height={20} src={LeaveCompanyIcon} />}
                              fullWidth
                              className="w-500"
                              size="large" sx={{ color: '#ED3131', border: '1px solid var(--code-red, #ED3131);', textTransform: 'none', fontSize: '14px', padding: '12px 16px', borderRadius: '8px' }}
                              variant="outlined" >
                              {translate('status.tinggalkan')}
                            </Button>
                          </Grid>
                          <Grid item xs={2} >
                            <Box onClick={(e) => handleOpenModal('more')} sx={{ border: '1px solid #56617B', cursor: 'pointer', borderRadius: '8px', p: '12px', display: 'flex', justifyContent: 'center' }} > <MoreHorizIcon sx={{ width: '20px', height: '20px' }} /> </Box>
                            <DrawerMore data={detailCompany} page="permintaan" type={4} id={detailCompany?.id} share={handleShareModal} open={isOpenMore} close={handleCloseModal}>
                            </DrawerMore>
                            <ModalShareListingCompany data={detailCompany} open={isOpenModalShare} close={closeShareModal}></ModalShareListingCompany>
                          </Grid>
                        </Grid>}
                        {detailCompany.user_information_staff === 3 && <Grid container direction={'row'} spacing={1} alignItems={'center'}>

                          <Grid item xs>
                            <Box onClick={(e) => handleOpenModal('more')} sx={{ border: '1px solid #56617B', cursor: 'pointer', borderRadius: '8px', p: '12px', display: 'flex', justifyContent: 'center' }} > <MoreHorizIcon sx={{ width: '20px', height: '20px' }} /> </Box>
                            <DrawerMore page="permintaan" type={4} id={detailCompany?.id} share={handleShareModal} open={isOpenMore} data={detailCompany} close={handleCloseModal}>
                            </DrawerMore>
                            <ModalShareListingCompany data={detailCompany} open={isOpenModalShare} close={closeShareModal}></ModalShareListingCompany>
                          </Grid>
                        </Grid>}
                      </Hidden>
                    </Box> : null}
                </Grid>
              </Grid>}
          </Hidden>}
          <Footer></Footer>
        </Box>
      </div>
      <ModalReportListing url_website={url_website} open={openModalReport} close={handleCloseModal} page={4} type={4} id={location.state.id} ></ModalReportListing>

      <ModalHubungiTrader type={activeTab} id={location.state.id} open={modalHubungi} close={() => { setModalHubungi(false) }} />
      <ModalAktePerusahaan detailCompany={detailCompany} open={ismodalAktePerusahaan} close={() => { setModalAktePerusahaan(false) }}></ModalAktePerusahaan>
      <ModalDetailNPWP detailCompany={detailCompany} open={ismodalNPWP} close={() => { setModalNPWP(false) }}></ModalDetailNPWP>
      <ModalPengajuanDitolak detailCompany={detailCompany} open={ismodalPengajuanDitolak} close={() => { setModalPengajuanDitolak(false) }}></ModalPengajuanDitolak>
      <ModalJoinCompany isJoinCompany={isJoinCompany} id_company={location.state.id} name_company={location.state.name} open={isModalJoinCompany} close={() => { setModalJoinCompany(false) }}></ModalJoinCompany>
      <ModalBatalkanKlaimPerusahaan id_company={location.state.id} name_company={location.state.name} open={isModalCancelClaim} close={() => { setModalCancelClaim(false) }}></ModalBatalkanKlaimPerusahaan>

      {/* <ModalSuccessReport open={openModalReport} close={handleCloseModal}></ModalSuccessReport> */}
    </div>
  )
}

export default DetailPerusahaan
