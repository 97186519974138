import Divider from "@material-ui/core/Divider";
import { Person } from "@material-ui/icons";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Avatar, Box, Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Feicon from "..//assets/fe-ic.png";
import Airicon from "../assets/air-ic.png";
import Niicon from "../assets/ni-ic.png";
import Ratioicon from "../assets/ratio-ic.png";
import DrawerMore from "../components/DrawerMore";
import { addFavorite, deleteFavorite } from "../services/api.js";
import { config } from '../services/constants';
import { GlobalState } from "../services/global";
import { numberConvert } from '../services/primary';
import Love from "./Love";

export default function CardListNikel(props) {
  const navigate = useNavigate()
  const [translate,] = useTranslation("global");
  const data = props.nickelList ? props.nickelList : []
  const [product_id, setProduct_id] = useState(null);
  const [user_trader_phone, setuser_trader_phone] = useState(null);
  const [anchorElShare, setAnchorElShare] = useState(null);
  const [status_listing, setstatus_listing] = useState(null);
  const [isLogin,] = useState(localStorage.getItem('token') !== null ? true : false);
  const { currency } = React.useContext(GlobalState)
  const [isSold, setisSold] = useState(0);

  const setFavorite = (e, y) => {
    if (props.nickelList !== undefined) {
      addFavorite({ type: 2, id_relation: parseInt(y) })
      // props.getNikel();
    }
  }

  const removeFavorite = (e, y) => {
    deleteFavorite({ type_product: 2, id_product: parseInt(y) })
    // props.getNikel();
  }

  const handleOpenShareMenu = (event, id, traderphone) => {
    setuser_trader_phone(traderphone);
    setProduct_id(id);
    setAnchorElShare(event.currentTarget);
  };

  const handleOpenShareMenuListing = (event, id, statuslisting, isSold) => {

    setisSold(isSold);
    setstatus_listing(statuslisting);
    setProduct_id(id);
    setAnchorElShare(event.currentTarget);
  };

  const handleCloseShareMenu = () => {
    setAnchorElShare(null);
  };

  return (
    <React.Fragment>
      {props.page === 'penawaran' || props.page === 'detailcompany' ?
        <Grid sx={{ mt: 2 }} item md={6} xs={12}>
          <Card sx={{ borderRadius: 2, mb: 2, border: 1, borderColor: '#E8EBF2' }}>
            <CardContent>
              <Box>
                <Grid
                  container
                  direction="row"
                  alignItems="left"
                  justifyContent="left"
                  spacing={0}
                >
                  <Grid item xs={8}>
                    <Typography
                      sx={{ fontSize: 10, mb: 1, color: '#818CA7', textTransform: 'uppercase', lineHeight: '14px' }}
                      className="w-500"
                    >
                      {translate('page_explore.listingoleh')}
                    </Typography>
                    <Stack

                      direction="row"
                      alignItems="center"
                      justifyContent="left"
                      spacing={0}
                    >

                      <Avatar alt="user-img" onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${data.user_id}` }} sx={{ bgcolor: 'blue', height: '32px', width: '32px', mr: '8px', cursor: 'pointer' }} aria-label="user-img" src={config.baseURL + data.user_url_image}>
                        <Person />
                      </Avatar>

                      <Stack style={{ textAlign: "left", color: "white" }} spacing={0}>
                        <Typography
                          onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${data.user_id}` }}
                          sx={{ fontSize: 16, color: '#081130', cursor: 'pointer' }}
                          className="w-700"
                        >
                          {data.user_name}
                        </Typography>
                        <Typography
                          sx={{ fontSize: 12, color: '#56617B' }}
                          className="w-500"
                        >
                          {data.company_name_listing === null ? 'Freelance' : data.company_name_listing}
                        </Typography>
                      </Stack>
                    </Stack>

                  </Grid>
                  <Grid sx={{ textAlign: "right", display: 'flex', alignItems: 'center', justifyContent: 'end' }} item xs={4}>
                    {isLogin && props.page !== 'detailcompany' && <Love type='card' condition={data?.user_favorite_id} remove={() => removeFavorite(2, data?.product_id)} favorite={() => setFavorite(2, data?.product_id)} />}
                    <MoreHorizIcon onClick={(e) => handleOpenShareMenu(e, data.product_id, data.phone_number)} sx={{ width: '24px', height: '24px', cursor: 'pointer' }} />
                  </Grid>
                </Grid>

                <Grid
                  marginTop={2}
                  container
                  direction="row"
                  alignItems="left"
                  justifyContent="left"
                  spacing={0}
                >
                  <Grid item xs={3}>
                    <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                      {translate('page_explore.kadar-ni')}
                    </Typography>
                  </Grid>
                  <Grid sx={{ textAlign: "left" }} item xs={4}>
                    <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                      {translate('page_explore.kadar-fe')}
                    </Typography>
                  </Grid>
                  <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                      {translate('page_explore.kadar-air')}
                    </Typography>
                  </Grid>

                </Grid>
                <Grid
                  container
                  direction="row"
                  alignItems="left"
                  justifyContent="left"
                  spacing={0}
                >
                  <Grid
                    item xs={3} >
                    <Stack direction={'row'} alignItems="center">
                      <img alt="Nikel-icon" src={Niicon} height={16}></img>
                      <Typography className="w-700" sx={{ color: '#081130', fontSize: 14, }}>
                        {(data.kadar_ni).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                      </Typography>
                    </Stack>

                  </Grid>
                  <Grid
                    justifyContent="left"
                    item xs={4}>
                    <Stack direction={'row'} alignItems="center">
                      <img alt="Nikel-icon" src={Feicon} height={16}></img>
                      <Typography className="w-700" sx={{ color: '#081130', fontSize: 14, }}>
                        {(data.kadar_fe).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid
                    justifyContent="left"

                    direction="row" item xs={5}>
                    <Stack direction={'row'} alignItems="center">
                      <img alt="Nikel-icon" src={Airicon} height={16}></img>
                      <Typography className="w-700" sx={{ color: '#081130', fontSize: 14, }}>
                        {(data.kadar_air).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid
                  marginTop={2}
                  container
                  direction="row"
                  alignItems="left"
                  justifyContent="left"
                  spacing={0}
                >
                  <Grid sx={{ textAlign: "left" }} item xs={3}>
                    <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                      Si/Mg
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                      Quantity
                    </Typography>
                  </Grid>
                  <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                      {translate('page_explore.harga')}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  alignItems="left"
                  justifyContent="left"
                  spacing={0}
                >
                  <Grid
                    justifyContent="left"

                    direction="row" item xs={3}>
                    <Stack direction={'row'} alignItems="center">
                      <img alt="Nikel-icon" src={Ratioicon} height={16}></img>
                      <Typography className="w-700" sx={{ color: '#081130', fontSize: 14, }}>
                        {(data.si_mg_ratio).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      className="w-700"
                      sx={{
                        display: 'inline',
                        color: '#081130',
                        wordBreak: "break-all",
                        mr: '4px',
                        fontSize: 14
                      }}
                    >

                      {numberConvert(data.quantity)}
                    </Typography>

                    <Typography
                      className="w-500"
                      sx={{
                        display: 'inline',
                        color: '#56617B', fontSize: 12, pr: '4px'
                      }}
                    >
                      MT
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    {currency === 'idr' && <Typography
                      className="w-700"
                      sx={{
                        display: 'inline',
                        color: '#081130',
                        mr: '4px',
                        fontSize: 14
                      }}
                    >
                      Rp {numberConvert(data.price)}
                    </Typography>}
                    {currency === 'usd' && <Typography
                      className="w-700"
                      sx={{
                        display: 'inline',
                        color: '#081130',
                        mr: '4px',
                        fontSize: 14
                      }}
                    >
                      $ {numberConvert(data.price)}
                    </Typography>}

                    <Typography
                      className="w-500"
                      sx={{
                        display: 'inline',
                        color: '#56617B', fontSize: 12
                      }}
                    >
                      /ton
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Divider orientation="horizontal" style={{ marginTop: 10, marginBottom: 10 }} />
                <Grid className="pointer"
                  container
                  justifyContent="center"
                  alignItems="center"
                  direction="row"
                  onClick={(e) => { navigate(`/penawaran/detail?type=2&id_detail=${data.product_id}`) }}

                >
                  <Typography
                    className="w-500"
                    sx={{ fontSize: 14, color: "#2457B9", mr: 1, }}
                  >
                    {translate('page_explore.lihatdetail')}
                  </Typography>
                  <ArrowForwardIosIcon sx={{ fontSize: 14, color: "#2457B9" }} />
                </Grid>
              </Box>
            </CardContent>
          </Card>

          <DrawerMore type={2} label={'penawaran'} page="explore" id={product_id} user_trader_phone={user_trader_phone} open={anchorElShare} close={handleCloseShareMenu}>

          </DrawerMore>
        </Grid>
        : props.page === 'home' ?
          <Grid sx={{ mt: 2 }} item md={6} xs={12}>
            {data.map((item) => (
              <Card key={item.user_id} sx={{ borderRadius: 2, mb: 2, border: 1, borderColor: '#E8EBF2' }}>
                <CardContent>
                  <Box>
                    <Grid
                      container
                      direction="row"
                      alignItems="left"
                      justifyContent="left"
                      spacing={0}
                    >
                      <Grid item xs={8}>
                        <Typography
                          sx={{ fontSize: 10, mb: 1, color: '#818CA7', textTransform: 'uppercase', lineHeight: '14px' }}
                          className="w-500"
                        >
                          {translate('page_explore.listingoleh')}
                        </Typography>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="left"
                          spacing={0}
                        >

                          <Avatar alt="user-img" onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${item.user_id}` }} sx={{ bgcolor: 'blue', height: '32px', width: '32px', mr: '8px', cursor: 'pointer' }} aria-label="user-img" src={config.baseURL + item.user_url_image}>
                            <Person />
                          </Avatar>

                          <Stack style={{ textAlign: "left", color: "white" }} spacing={0}>
                            <Typography
                              onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${item.user_id}` }}
                              sx={{ fontSize: 16, color: '#081130', cursor: 'pointer' }}
                              className="w-700"
                            >
                              {item.user_name}
                            </Typography>
                            <Typography
                              sx={{ fontSize: 12, color: '#56617B' }}
                              className="w-500"
                            >
                              {item.company_name_listing === null ? 'No company listed' : item.company_name_listing}
                            </Typography>
                          </Stack>
                        </Stack>

                      </Grid>
                      <Grid sx={{ textAlign: "right", display: 'flex', alignItems: 'center', justifyContent: 'end' }} item xs={4}>
                        {isLogin && <Love type='card' condition={item?.user_favorite_id} remove={() => removeFavorite(2, item?.product_id)} favorite={() => setFavorite(2, item?.product_id)} />}

                        <MoreHorizIcon onClick={(e) => handleOpenShareMenu(e, item.product_id, item.phone_number)} sx={{ width: '24px', height: '24px', cursor: 'pointer' }} />
                      </Grid>
                    </Grid>

                    <Grid
                      marginTop={2}
                      container
                      direction="row"
                      alignItems="left"
                      justifyContent="left"
                      spacing={0}
                    >
                      <Grid item xs={3}>
                        <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                          {translate('page_explore.kadar-ni')}
                        </Typography>
                      </Grid>
                      <Grid sx={{ textAlign: "left" }} item xs={4}>
                        <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                          {translate('page_explore.kadar-fe')}
                        </Typography>
                      </Grid>
                      <Grid sx={{ textAlign: "left" }} item xs={5}>
                        <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                          {translate('page_explore.kadar-air')}
                        </Typography>
                      </Grid>

                    </Grid>
                    <Grid
                      container
                      direction="row"
                      alignItems="left"
                      justifyContent="left"
                      spacing={0}
                    >
                      <Grid
                        justifyContent="left" item xs={3}>
                        <Stack direction={'row'} alignItems="center">
                          <img alt="Nikel-icon" src={Niicon} height={16}></img>
                          <Typography className="w-700" sx={{ color: '#081130', fontSize: 14, }}>
                            {(item.kadar_ni).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid
                        justifyContent="left"
                        alignItems="center"
                        item xs={4}>
                        <Stack direction={'row'} alignItems="center">
                          <img alt="Nikel-icon" src={Feicon} height={16}></img>
                          <Typography className="w-700" sx={{ color: '#081130', fontSize: 14, }}>
                            {(item.kadar_fe).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid
                        justifyContent="left"
                        alignItems="center"
                        item xs={5}>
                        <Stack direction={'row'} alignItems="center">
                          <img alt="Nikel-icon" src={Airicon} height={16}></img>
                          <Typography className="w-700" sx={{ color: '#081130', fontSize: 14, }}>
                            {(item.kadar_air).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                    <Grid
                      marginTop={2}
                      container
                      direction="row"
                      alignItems="left"
                      justifyContent="left"
                      spacing={0}
                    >
                      <Grid sx={{ textAlign: "left" }} item xs={3}>
                        <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                          Si/Mg
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                          Quantity
                        </Typography>
                      </Grid>
                      <Grid sx={{ textAlign: "left" }} item xs={5}>
                        <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                          {translate('page_explore.harga')}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      alignItems="left"
                      justifyContent="left"
                      spacing={0}
                    >
                      <Grid
                        justifyContent="left"
                        alignItems="center"
                        item xs={3}>
                        <Stack direction={'row'} alignItems="center">
                          <img alt="Nikel-icon" src={Ratioicon} height={16}></img>
                          <Typography className="w-700" sx={{ color: '#081130', fontSize: 14, }}>
                            {(item.si_mg_ratio).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                          </Typography>
                        </Stack>

                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          className="w-700"
                          sx={{
                            display: 'inline',
                            color: '#081130',
                            wordBreak: "break-all",
                            mr: '4px',
                            fontSize: 14
                          }}
                        >
                          {numberConvert(item.quantity)}
                        </Typography>
                        <Typography
                          className="w-500"
                          sx={{
                            display: 'inline',
                            color: '#56617B', fontSize: 12, pr: '4px'
                          }}
                        >
                          MT
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        {currency === 'idr' && <Typography
                          className="w-700"
                          sx={{
                            display: 'inline',
                            color: '#081130',
                            mr: '4px',
                            fontSize: 14
                          }}
                        >
                          Rp {numberConvert(item.price)}
                        </Typography>}
                        {currency === 'usd' && <Typography
                          className="w-700"
                          sx={{
                            display: 'inline',
                            color: '#081130',
                            mr: '4px',
                            fontSize: 14
                          }}
                        >
                          $ {numberConvert(item.price)}
                        </Typography>}

                        <Typography
                          className="w-500"
                          sx={{
                            display: 'inline',
                            color: '#56617B', fontSize: 12
                          }}
                        >
                          /ton
                        </Typography>
                      </Grid>

                    </Grid>
                  </Box>
                  <Box>
                    <Divider orientation="horizontal" style={{ marginTop: 10, marginBottom: 10 }} />
                    <Grid className="pointer"
                      container
                      justifyContent="center"
                      alignItems="center"
                      direction="row"
                      onClick={(e) => { navigate(`/penawaran/detail?type=2&id_detail=${item.product_id}`) }}

                    >
                      <Typography
                        className="w-500"
                        sx={{ fontSize: 14, color: "#2457B9", mr: 1, }}
                      >
                        {translate('page_explore.lihatdetail')}
                      </Typography>
                      <ArrowForwardIosIcon sx={{ fontSize: 14, color: "#2457B9" }} />
                    </Grid>
                  </Box>
                </CardContent>
              </Card>))
            }
            <DrawerMore type={2} label={'penawaran'} page="explore" id={product_id} user_trader_phone={user_trader_phone} open={anchorElShare} close={handleCloseShareMenu}>

            </DrawerMore>
          </Grid> :
          <Grid sx={{ mt: 2 }} item md={6} xs={12}>

            <Card sx={{ borderRadius: 2, mb: 2, border: 1, borderColor: '#E8EBF2' }}>

              <CardContent>
                <Box>
                  <Grid
                    container
                    direction="row"
                    alignItems="left"
                    justifyContent="left"
                    spacing={0}
                  >
                    <Grid item xs={8}>
                      <Box
                        sx={{
                          background: "#F4F6FA",
                          borderRadius: 4,
                          p: '2px 12px',
                          mr: '4px',
                          width: '80px'
                        }}
                      >
                        {data.status_listing === 1 ?
                          <Stack direction={'row'} alignItems={'center'} >

                            <Box sx={{ borderRadius: '50%', background: '#3BB56C', width: '10px', height: '10px', mr: '4px' }}>
                            </Box>
                            <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 14 }} >
                              Publish
                            </Typography>
                          </Stack>
                          : ''}

                        {data.status_listing === 2 ?
                          <Stack direction={'row'} alignItems={'center'} >

                            <Box sx={{ borderRadius: '50%', background: '#F7CE46', width: '10px', height: '10px', mr: '4px' }}>
                            </Box>
                            <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 14 }} >
                              Unpublish
                            </Typography>
                          </Stack>
                          : ' '}
                        {data.status_listing === 3 ?
                          <Stack direction={'row'} alignItems={'center'} >

                            <Box sx={{ borderRadius: '50%', background: '#56617B', width: '10px', height: '10px', mr: '4px' }}>
                            </Box>
                            <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 14 }} >
                              Draft
                            </Typography>
                          </Stack>
                          : ' '}
                      </Box>

                    </Grid>
                    <Grid sx={{ textAlign: "right", display: 'flex', alignItems: 'center', justifyContent: 'end' }} item xs={4}>
                      {isLogin && <Love type='card' condition={data?.user_favorite_id} remove={() => removeFavorite(2, data?.product_id)} favorite={() => setFavorite(2, data?.product_id)} />}
                      <MoreHorizIcon onClick={(e) => handleOpenShareMenuListing(e, data.product_id, data.status_listing, data.is_sold)} sx={{ width: '24px', height: '24px', cursor: 'pointer' }} />
                    </Grid>
                  </Grid>

                  <Grid
                    marginTop={2}
                    container
                    direction="row"
                    alignItems="left"
                    justifyContent="left"
                    spacing={0}
                  >
                    <Grid item xs={3}>
                      <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                        {translate('page_explore.kadar-ni')}
                      </Typography>
                    </Grid>
                    <Grid sx={{ textAlign: "left" }} item xs={4}>
                      <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                        {translate('page_explore.kadar-fe')}
                      </Typography>
                    </Grid>
                    <Grid sx={{ textAlign: "left" }} item xs={5}>
                      <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                        {translate('page_explore.kadar-air')}
                      </Typography>
                    </Grid>

                  </Grid>
                  <Grid
                    container
                    direction="row"
                    alignItems="left"
                    justifyContent="left"
                    spacing={0}
                  >
                    <Grid
                      justifyContent="left"
                      alignItems="center"
                      item xs={3}>
                      <Stack direction={'row'} alignItems="center">
                        <img alt="Nikel-icon" src={Niicon} height={16}></img>
                        <Typography className="w-700" sx={{ color: '#081130', fontSize: 14, }}>
                          {(data.kadar_ni).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid
                      justifyContent="left"
                      alignItems="center"
                      item xs={4}>
                      <Stack direction={'row'} alignItems="center">
                        <img alt="Nikel-icon" src={Feicon} height={16}></img>
                        <Typography className="w-700" sx={{ color: '#081130', fontSize: 14, }}>
                          {(data.kadar_fe).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                        </Typography>
                      </Stack>

                    </Grid>
                    <Grid
                      justifyContent="left"
                      alignItems="center"
                      item xs={5} >
                      <Stack direction={'row'} alignItems="center">
                        <img alt="Nikel-icon" src={Airicon} height={16}></img>
                        <Typography className="w-700" sx={{ color: '#081130', fontSize: 14, }}>
                          {(data.kadar_air).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                        </Typography>
                      </Stack>

                    </Grid>
                  </Grid>
                  <Grid
                    marginTop={2}
                    container
                    direction="row"
                    alignItems="left"
                    justifyContent="left"
                    spacing={0}
                  >
                    <Grid sx={{ textAlign: "left" }} item xs={3}>
                      <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                        Si/Mg
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                        Quantity
                      </Typography>
                    </Grid>
                    <Grid sx={{ textAlign: "left" }} item xs={5}>
                      <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                        {translate('page_explore.harga')}
                      </Typography>
                    </Grid>


                  </Grid>
                  <Grid
                    container
                    direction="row"
                    alignItems="left"
                    justifyContent="left"
                    spacing={0}
                  >
                    <Grid
                      justifyContent="left"
                      alignItems="center"
                      item xs={3}>
                      <Stack direction={'row'} alignItems="center">
                        <img alt="Nikel-icon" src={Ratioicon} height={16}></img>
                        <Typography className="w-700" sx={{ color: '#081130', fontSize: 14, }}>
                          {(data.si_mg_ratio).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                        </Typography>
                      </Stack>

                    </Grid>
                    <Grid item xs={4}>

                      <Typography
                        className="w-700"
                        sx={{
                          display: 'inline',
                          color: '#081130',
                          wordBreak: "break-all",
                          mr: '4px',
                          fontSize: 14
                        }}
                      >

                        {numberConvert(data.quantity)}
                      </Typography>

                      <Typography
                        className="w-500"
                        sx={{
                          display: 'inline',
                          color: '#56617B', fontSize: 12, pr: '4px'
                        }}
                      >
                        MT
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      {currency === 'idr' && <Typography
                        className="w-700"
                        sx={{
                          display: 'inline',
                          color: '#081130',
                          mr: '4px',
                          fontSize: 14
                        }}
                      >
                        Rp {numberConvert(data.price)}
                      </Typography>}
                      {currency === 'usd' && <Typography
                        className="w-700"
                        sx={{
                          display: 'inline',
                          color: '#081130',
                          mr: '4px',
                          fontSize: 14
                        }}
                      >
                        $ {numberConvert(data.price)}
                      </Typography>}

                      <Typography
                        className="w-500"
                        sx={{
                          display: 'inline',
                          //wordBreak: "break-all",
                          color: '#56617B', fontSize: 12
                        }}
                      >
                        /ton
                      </Typography>
                    </Grid>

                  </Grid>
                </Box>
                <Box

                >
                  <Divider orientation="horizontal" style={{ marginTop: 10, marginBottom: 10 }} />

                  <Grid className="pointer"
                    container
                    justifyContent="center"
                    alignItems="center"
                    direction="row"
                    onClick={(e) => { navigate(`/listing/detail?type=2&id_detail=${data.product_id}`) }}

                  >
                    <Typography
                      className="w-500"
                      sx={{ fontSize: 14, color: "#2457B9", mr: 1, }}
                    >
                      {translate('page_explore.lihatdetail')}
                    </Typography>
                    <ArrowForwardIosIcon sx={{ fontSize: 14, color: "#2457B9" }} />
                  </Grid>
                </Box>
              </CardContent>
            </Card>

            <DrawerMore is_sold={isSold} page_status="2" page_detail='nikel' page={'listing'} type={'listing'} status_listing={status_listing} id={product_id} open={anchorElShare} close={handleCloseShareMenu}>

            </DrawerMore>
          </Grid>}

    </React.Fragment>
  )
}
