import Divider from "@material-ui/core/Divider";
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import Drawer from '@mui/material/Drawer';
import { React } from 'react';
import { useTranslation } from "react-i18next";
import searchIcon from "../assets/search-explore-ic.png";

export default function DrawerFilterLocation(props) {
  const [translate,] = useTranslation("global");

  return (
    <Box sx={{ flexGrow: 1, display: { xs: "flex", sm: 'flex', md: "flex" }, }}>
      <Drawer
        className="box-filter-drawer"
        anchor="bottom"
        open={Boolean(props.open)}
        onClose={props.close}
      >
        <Box sx={{ pl: '16px', pt: '19px', pr: 2, pb: '19px' }}>
          <Stack
            direction="row"
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{ mb: '16px' }}
          >
            <Typography className="w-700" sx={{ fontSize: 18, color: '#081130' }}>
              {translate('page_explore.lokasi')}
            </Typography>
            <Stack direction={'row'} alignItems={'center'}>
              <Button className="w-500" onClick={(e) => props.handleLocationReset(props.type)} sx={{ fontSize: 16, color: '#2457B9', textTransform: 'none' }} variant="text"> Reset filter</Button>
              <Divider orientation="vertical" style={{ marginTop: 10, marginBottom: 10, marginLeft: 10, marginRight: 10 }} flexItem />
              <CloseIcon
                onClick={(e) => {
                  props.handleLocationReset(props.type)
                  props.close()
                }}
              />
            </Stack>
          </Stack>

          <TextField
            className="box-input-filter"
            type="text"
            size="small"
            placeholder={translate('page_explore.search-lokasi')}
            onChange={(e) => props.handleSearchValueLocation(e.target.value)}
            value={props.searchValueLocation}
            onKeyDown={(e) => props.handleKeyDownLocation(e, props.type)}
            sx={{
              width: '100%', input: {
                fontSize: '14px', fontFamily: 'Satoshi-500',

              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img alt="search-icon" src={searchIcon} height={20} width={20}></img>
                </InputAdornment>
              ),
            }}
          />
          <div style={{ marginTop: '16px', height: '600px', overflowY: 'scroll' }}>
            <FormGroup >
              {props.dataProvince.length > 0 ?
                props.dataProvince.map((option, i) => (
                  <FormControlLabel onChange={(e) => props.handleFilter(e, "popuplocation")} key={i} sx={{ fontSize: 14, color: '#081130' }} control={<Checkbox checked={props.selectedItems.includes(option.id)} sx={{
                    color: "#E8EBF2",
                    '&.Mui-checked': {
                      color: "#3BB56C",
                    },
                  }} />} label={option.attributes.name} value={option.id} />)
                )
                : translate('status.lokasinotfound')}
            </FormGroup>
          </div>
        </Box>
        <Divider orientation="horizontal" style={{ marginTop: 10, marginBottom: 10, marginLeft: 16, marginRight: 16 }} />
        <Stack direction={'row'} spacing={1} sx={{ ml: 2, mr: 2, mb: 2 }}>
          <Button
            className="w-500"
            onClick={(e) => {
              props.handleLocationReset(props.type)
              props.close()
            }}
            type="submit"
            fullWidth
            variant="outlined"
            sx={{
              textTransform: "none", border: '1px solid var(--primarry-blue, #2457B9)',
              p: '12px 16px',
              borderRadius: '8px',
              color: '#2457B9',
              fontSize: '14px'
            }}
          >
            {translate('jual-beli.btn-cancel')}
          </Button>
          <Button
            className="w-500"
            onClick={(e) => {
              props.handleLocationSumbit(props.type)
              props.close()
            }}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ background: ' linear-gradient(270deg, #2457B9 15%, #2860CD 100%)', borderRadius: '8px', boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.12), 0px -1px 0px 0px rgba(0, 0, 0, 0.20) inset', padding: '12px 16px', fontSize: '14px', textTransform: 'none' }}
          >
            {translate('page_explore.btn-filter')}
          </Button>
        </Stack>
      </Drawer>
    </Box>
  );
}