import { IconButton, InputAdornment } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, Hidden, MenuItem, Select, Stack, Switch, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import { styled } from '@mui/material/styles';
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-multi-carousel/lib/styles.css";
import Swal from 'sweetalert2';
import visibilityOffIcon from "../assets/eye-icon-close.svg";
import visibilityIcon from "../assets/eye-icon.svg";
import mailIconActive from "../assets/mail-active-ic.png";
import mailIconInActive from "../assets/mail-company-ic.png";
import personIconActive from "../assets/person-active-ic.png";
import personIconInActive from "../assets/person-inactive-ic.png";
import profiledefault from "../assets/profile-default.jpg";
import securityIconActive from "../assets/security-active-ic.png";
import securityIconInActive from "../assets/security-inactive-ic.png";
import UploadProfileIcon from "../assets/uploadprofile-ic.png";
import Footer from "../components/Footer";
import ModalOtentikasiTwoFactor from "../components/ModalOtentikasiTwoFactor";
import ModalSuccessEditProfile from "../components/ModalSuccessEditProfile";
import NavBar from "../components/Navbar";
import { changePasswordUser, editUser, getCity, getDetailTrader, getDetailUserSetting, getProvince, getSecurityQuestion } from "../services/api";
import { config } from "../services/constants";

function SettingPage() {
  const [translate,] = useTranslation("global");
  const [activeTabSetting, setActiveTabSetting] = useState('akun');
  const [showPasswordLama, setShowPasswordLama] = useState(false);
  const [showPasswordBaru, setShowPasswordBaru] = useState(false);
  const [showKonfirmasiPassword, setShowKonfirmasiPassword] = useState(false);
  const [selectProvinsi,] = React.useState('');
  const [selectKota,] = React.useState('');
  const [isOpenModal, setOpenModal] = React.useState(false)
  const [isOpenOtentikasi, setOpenOtentikasi] = React.useState(false)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [about, setAbout] = useState("")
  const [email, setEmail] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [address, setAddress] = useState("")
  const [province, setProvince] = useState("")
  const [city, setCity] = useState("")
  const [listOfProvince, setListProvince] = useState([])
  const [listOfcity, setListCity] = useState([])
  const [postCode, setPostCode] = useState("")
  const [dataUser, setDataUser] = useState([]);
  const [isLoadingChangePassword, setisLoadingChangePassword] = useState(false);
  const [isOtentikasi, setOtentikasi] = useState(false);
  const [selectquestion, setquestion] = useState("")
  const [answer, setanswer] = useState("")
  const [accountActivity, setAccountActivity] = useState(false)
  const [showquestionform, setShowQuestionForm] = useState(false);
  const [requestBuyBatubara, setrequestBuyBatubara] = useState(false);
  const [requestBuyNikel, setrequestBuyNikel] = useState(false);
  const [requestBuyTongkang, setrequestBuyTongkang] = useState(false);
  const [requestServiceTongkang, setrequestServiceTongkang] = useState(false);
  const [reply, setreply] = useState(false);
  const [comment, setcomment] = useState(false);
  const [companyInvitation, setcompanyInvitation] = useState(false);
  const [companyRequestAccepted, setcompanyRequestAccepted] = useState(false);
  const [listOfQuestion, setListofQuestion] = useState([])
  let lang = localStorage.getItem('lang') === 'null' || localStorage.getItem('lang') === 'id' ? 'id' : localStorage.getItem('lang')
  const [requestHideEmail, setrequestHideEmail] = useState(false);
  const [requestHidePhone, setrequestHidePhone] = useState(false);

  useEffect(() => {
    getDataUser();
    getDataProvince();
    getDataSecurityQuestion();
  }, []);

  async function getDataUser(event) {
    try {
      let lang = localStorage.getItem('lang') === 'null' || localStorage.getItem('lang') === 'id' ? 'id' : localStorage.getItem('lang')
      const response = await getDetailTrader(localStorage.getItem("id_user"), lang)
      const setting = await getDetailUserSetting()
      const data = response.data.data ? response.data.data : ""
      const dataSetting = setting.data.data ? setting.data.data : ""
      setAbout(data.about)
      setAddress(data.alamat)
      setEmail(data.email)
      setPhoneNumber(data.phone_number)
      setPostCode(data.kode_pos)
      setFirstName(data.name.split(" ")[0])
      setLastName(data.name.split(" ")[1])
      setProvince(data.provinsi_id)
      setrequestHideEmail(data.show_email)
      setrequestHidePhone(data.show_phone)
      setDataUser(response.data.data ? response.data.data : "");
      getDataCity(data.provinsi_id)
      setCity(data.city_id)
      setOtentikasi(dataSetting.oauth_verify);
      setquestion(dataSetting.question_verify);
      setanswer(dataSetting.answer_verify);
      setShowQuestionForm(dataSetting.security_question);
      setAccountActivity(JSON.parse(dataSetting.email_notification).accountActivity)
      setrequestBuyBatubara(JSON.parse(dataSetting.email_notification).requestBuyBatubara)
      setrequestBuyNikel(JSON.parse(dataSetting.email_notification).requestBuyNikel)
      setrequestBuyTongkang(JSON.parse(dataSetting.email_notification).requestBuyTongkang)
      setrequestServiceTongkang(JSON.parse(dataSetting.email_notification).requestServiceTongkang)
      setcomment(JSON.parse(dataSetting.email_notification).comment)
      setreply(JSON.parse(dataSetting.email_notification).reply)
      setcompanyInvitation(JSON.parse(dataSetting.email_notification).companyInvitation)
      setcompanyRequestAccepted(JSON.parse(dataSetting.email_notification).companyRequestAccepted)
    } catch (e) {
      console.log(e);
    }
  }

  async function getDataProvince() {
    const response = await getProvince();
    setListProvince(response.data.data)
  }

  async function getDataSecurityQuestion() {
    const response = await getSecurityQuestion(lang);
    setListofQuestion(response.data?.data)
  }

  async function getDataCity(e) {
    const response = await getCity(parseInt(e));
    setListCity(response.data?.data)
  }

  const changeProvince = (e) => {
    setProvince(e)
    getDataCity(e)
  }

  const changeQuestion = (e) => {
    setquestion(e)
  }

  const ChangeAnswer = (e) => {
    setanswer(e)
  }

  async function submitProfile(e) {

    const data = {
      name: `${firstName + " " + lastName}`,
      email: email,
      show_email: requestHideEmail,
      provinsi: province,
      city: city,
      about: about,
      show_phone: requestHidePhone,
      phone_number: phoneNumber,
      alamat: address,
      kode_pos: postCode,
    }
    const res = await editUser(dataUser.id, data)
    if (res.status === 200) {
      getDataUser()
      handleOpenModal()
    } else {
      Swal.fire({
        icon: 'error',
        title: translate('status.failed'),
        text: translate('status.subtitlefailed'),
      })
    }
  }

  async function HandleOtentikasi(is_Otentikasi) {

    const data = {
      oauth_verify: is_Otentikasi,
    }
    const res = await editUser(dataUser.id, data)
    if (res.status === 200) {
      getDataUser()
    } else {
      Swal.fire({
        icon: 'error',
        title: translate('status.failed'),
        text: translate('status.subtitlefailed'),
      })
    }
  }

  async function HandleSecurityQuestion(state) {

    const data = {
      security_question: state,
    }
    const res = await editUser(dataUser.id, data)
    if (res.status === 200) {
      getDataUser()
    } else {
      Swal.fire({
        icon: 'error',
        title: translate('status.failed'),
        text: translate('status.subtitlefailed'),
      })
    }
  }

  async function handleToggle() {
    getDataUser();
  }

  async function submitEmailNotification() {
    const data = {
      "accountActivity": accountActivity, "requestBuyBatubara": requestBuyBatubara, "requestBuyNikel": requestBuyNikel, "requestBuyTongkang": requestBuyTongkang, "requestServiceTongkang": requestServiceTongkang, "comment": comment, "reply": reply, "companyInvitation": companyInvitation, "companyRequestAccepted": companyRequestAccepted
    }

    const params = {
      email_notification: JSON.stringify(data)
    }

    const res = await editUser(dataUser.id, params);
    if (res.status === 200) {
      Swal.fire({
        icon: 'success',
        title: translate('status.success'),
        text: translate('status.successsave'),
      }).then(() => {
        getDataUser()
      })

    } else {
      Swal.fire({
        icon: 'error',
        title: translate('status.failed'),
        text: translate('status.subtitlefailed'),
      })
    }
  }

  async function HandleOtentikasiActive() {

    const data = {
      oauth_verify: true,
    }
    const res = await editUser(dataUser.id, data)
    if (res.status === 200) {
      getDataUser()
    } else {
      Swal.fire({
        icon: 'error',
        title: translate('status.failed'),
        text: translate('status.subtitlefailed'),
      })
    }
  }

  async function HandleSendQuestion(question_verify, answer_verify) {

    const data = {
      question_verify: question_verify,
      answer_verify: answer_verify
    }

    const res = await editUser(dataUser.id, data)
    if (res.status === 200) {
      Swal.fire({
        icon: 'success',
        title: translate('status.success'),
        text: translate('status.successaddquestion'),
      }).then(() => {
        getDataUser()
      })
    } else {
      Swal.fire({
        icon: 'error',
        title: translate('status.failed'),
        text: translate('status.subtitlefailed'),
      })
    }
  }


  function uploadImage(event) {
    const file = event.target.files[0]

    let formData = new FormData();
    formData.append('files', file);
    formData.append('ref', "plugin::users-permissions.user");
    formData.append('refId', dataUser.id);
    formData.append('field', 'picture_path');
    formData.append('source', 'users-permissions');
    axios.post(`${config.baseURL}/api/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      }
    }).then((res) => {
      getDataUser()
    }).catch((err) => {
      console.log(err);
    })
  }

  const ChangePassword = async (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)

    const params = {
      currentPassword: data.get("currentPassword"),
      password: data.get("password"),
      passwordConfirmation: data.get("passwordConfirmation")
    }
    setisLoadingChangePassword(true)
    const response = await changePasswordUser(params);
    setisLoadingChangePassword(false)
    if (response.status === 200) {

      Swal.fire({
        icon: 'success',
        title: translate('status.success'),
        text: translate('status.successubahpassword'),
      }).then(() => {
        window.location.reload('/setting');
      })
    } else {

      Swal.fire({
        icon: 'error',
        title: translate('status.failed'),
        text: translate('status.subtitlefailed'),
      })
    }
  }

  const handleTabSetting = (state) => {
    setActiveTabSetting(state);
  };

  const handleOpenModal = (state) => {
    if (state === 'otentikasi') {
      setOpenOtentikasi(true)

    } else if (state === 'disableotentikasi') {
      HandleOtentikasi(false)
    } else if (state === 'securitydisable') {
      HandleSecurityQuestion(false)
    } else if (state === 'securityactive') {
      HandleSecurityQuestion(true)
    } else {
      setOpenModal(true);
    }

  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setOpenOtentikasi(false);
  };

  const handleClickShowPasswordLama = () => setShowPasswordLama(!showPasswordLama);
  const handleMouseDownPasswordLama = () => setShowPasswordLama(!showPasswordLama);
  const handleClickShowPasswordBaru = () => setShowPasswordBaru(!showPasswordBaru);
  const handleMouseDownPasswordBaru = () => setShowPasswordBaru(!showPasswordBaru);
  const handleClickShowKonfirmasiPassword = () => setShowKonfirmasiPassword(!showKonfirmasiPassword);
  const handleMouseDownKonfirmasiPassword = () => setShowKonfirmasiPassword(!showKonfirmasiPassword);

  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  const theme = useTheme();
  const isXsOrSm = useMediaQuery(theme.breakpoints.down('md'));

  const stylesTextField = {
    borderRadius: 8, // Sesuaikan dengan tingkat border radius yang Anda inginkan
    fontSize: '14px',
    fontWeight: 400,
    //className: "w-400",
    height: '48px',
    //font: 'Satoshi !important',
  };

  return (
    <div>
      <div className='fixed-header'>
        <NavBar></NavBar>
      </div>
      <Box className="flex-wrap-center width-100" >
        <Box className="content-inner" sx={{ mt: 11, mb: { xs: '24px', sm: '24px', md: '80px', lg: '80px', xl: '80px' } }}>
          <Typography className="w-700" sx={{ color: '#081130', fontSize: 32, mt: 1, mb: '24px' }}>
            {translate('navbar.setting')}
          </Typography>
          <Grid container direction={'row'} spacing={2} >
            <Grid item md={3} lg={4} xl={3} xs={false}>
              {/* leftside Section */}
              <Hidden mdDown>
                <Stack direction={'column'} spacing={1}>
                  <Button
                    className="w-700"
                    sx={{
                      borderRadius: '8px', width: { lg: '285px' }, height: '52px', boxShadow: 'none',
                      background: activeTabSetting === 'akun' ? "#EDF2F9" : '', textTransform: 'none', justifyContent: 'left',
                      color: activeTabSetting === 'akun' ? "#081130" : '#081130', fontSize: 14, "&:hover": {
                        backgroundColor: "#EDF2F9",
                        boxShadow: 'none',
                      }
                    }}
                    onClick={() => {
                      handleTabSetting("akun");
                    }}
                    variant={activeTabSetting === 'akun' ? "contained" : ''}
                    startIcon={<Box

                      sx={{
                        alignItems: 'center',
                        backgroundColor: '',
                        borderRadius: 0,
                        pl: 0,
                        pr: 0,
                        ml: '-20px',
                        height: '100%',
                      }}

                    >
                      <Stack direction={"row"} spacing={1} height={"100%"} alignItems="center">
                        <Box sx={{ borderRadius: '8px', backgroundColor: activeTabSetting === 'akun' ? '#2457B9' : '', width: '18px', height: '8px' }}></Box>
                        <Box component="img" src={activeTabSetting === 'akun' ? personIconActive : personIconInActive} sx={{ backgroundColor: '', width: 20, height: 20 }}></Box>
                      </Stack>

                    </Box>
                    }>
                    {translate('status.pengaturanakun')}
                  </Button>
                  <Button
                    className="w-700"
                    sx={{
                      borderRadius: '8px',
                      height: '52px',
                      width: { lg: '285px' },
                      boxShadow: 'none',
                      background: activeTabSetting === 'mail' ? "#EDF2F9" : '', textTransform: 'none', justifyContent: 'left',
                      color: activeTabSetting === 'mail' ? "#081130" : '#081130', fontSize: 14, "&:hover": {
                        backgroundColor: "#EDF2F9",
                        boxShadow: 'none',
                      }
                    }}
                    onClick={() => {
                      handleTabSetting("mail");
                    }}
                    variant={activeTabSetting === 'mail' ? "contained" : ''}
                    startIcon={<Box

                      sx={{
                        alignItems: 'center',
                        backgroundColor: '',
                        borderRadius: 0,
                        pl: 0,
                        pr: 0,
                        ml: '-20px',
                        height: '100%',
                      }}

                    >
                      <Stack direction={"row"} spacing={1} height={"100%"} alignItems="center">
                        <Box sx={{ borderRadius: '8px', backgroundColor: activeTabSetting === 'mail' ? '#2457B9' : '', width: '18px', height: '8px' }}></Box>

                        <Box component="img" src={activeTabSetting == 'mail' ? mailIconActive : mailIconInActive} sx={{ backgroundColor: '', width: 20, height: 20 }}></Box>
                      </Stack>
                    </Box>}>
                    {translate('status.emailnotifikasi')}
                  </Button>
                  <Button
                    className="w-700"
                    sx={{
                      borderRadius: '8px',
                      height: '52px',
                      width: { lg: '285px' },
                      background: activeTabSetting === 'security' ? "#EDF2F9" : '', textTransform: 'none', justifyContent: 'left',
                      boxShadow: 'none',
                      color: activeTabSetting === 'security' ? "#081130" : '#081130', fontSize: 14, "&:hover": {
                        backgroundColor: "#EDF2F9",
                        boxShadow: 'none',
                      }
                    }}
                    onClick={() => {
                      handleTabSetting("security");
                    }}
                    variant={activeTabSetting === 'security' ? "contained" : ''}
                    startIcon={<Box

                      sx={{
                        alignItems: 'center',
                        backgroundColor: '',
                        borderRadius: 0,
                        pl: 0,
                        pr: 0,
                        ml: '-20px',
                        height: '100%',
                      }}

                    >
                      <Stack direction={"row"} spacing={1} height={"100%"} alignItems="center">
                        <Box sx={{ borderRadius: '8px', backgroundColor: activeTabSetting === 'security' ? '#2457B9' : '', width: '18px', height: '8px' }}></Box>

                        <Box component="img" src={activeTabSetting === 'security' ? securityIconActive : securityIconInActive} sx={{ backgroundColor: '', width: 20, height: 20 }}></Box>
                      </Stack>
                    </Box>}>
                    {translate('status.passwordkeamanan')}

                  </Button>
                </Stack>
              </Hidden>
              {/* leftside Section */}
            </Grid>
            <Grid item xs={12} md={9} lg={8} xl={9}>
              {/* Tab Button Section */}
              <Hidden mdUp>
                <Box sx={{ mb: { xs: 0, sm: '22px' } }} className="tab-scroll-x">
                  <Stack
                    spacing={0}
                    direction="row"
                    divider={<Divider orientation="vertical" style={{ marginTop: 10, marginBottom: 10, marginLeft: 10, marginRight: 10 }} flexItem />}
                    sx={{
                      mb: 1,
                      '&::-webkit-scrollbar': {
                        display: 'none',
                      },
                    }}
                  >
                    <Button
                      className="w-500"
                      sx={{
                        flexShrink: 0,
                        p: '6px 12px',
                        height: '36px',
                        borderRadius: '8px',
                        background: activeTabSetting === 'akun' ? "#081130" : '',
                        fontSize: { xs: 15, sm: 16 },

                        color: activeTabSetting === 'akun' ? "white" : '#081130'
                        , textTransform: "none",
                        "&:hover": {
                          backgroundColor: activeTabSetting === 'akun' ? "#081130" : "#56617B",
                        },
                      }}
                      onClick={() => {
                        handleTabSetting("akun");
                      }}
                      variant={activeTabSetting === 'akun' ? "contained" : 'text'}
                    >     {translate('status.pengaturanakun')}
                    </Button>
                    <Button
                      className="w-500"
                      onClick={() => {
                        handleTabSetting("mail");
                      }}
                      sx={{
                        fontSize: { xs: 15, sm: 16 },
                        flexShrink: 0,
                        p: '6px 12px',
                        height: '36px',
                        borderRadius: '8px',
                        background: activeTabSetting === 'mail' ? "#081130" : '', textTransform: 'none',
                        color: activeTabSetting === 'mail' ? "white" : '#081130', "&:hover": {
                          backgroundColor: activeTabSetting === 'mail' ? "#081130" : "#56617B"
                        }
                      }}
                      variant={activeTabSetting === 'mail' ? "contained" : 'text'}
                    > {translate('status.emailnotifikasi')}
                    </Button>
                    <Button
                      className="w-500"
                      onClick={() => {
                        handleTabSetting("security");
                      }}
                      sx={{
                        fontSize: { xs: 15, sm: 16 },
                        flexShrink: 0,
                        p: '6px 12px',
                        height: '36px',
                        borderRadius: '8px',
                        background: activeTabSetting === 'security' ? "#081130" : '', textTransform: 'none',
                        color: activeTabSetting === 'security' ? "white" : '#081130', "&:hover": {
                          backgroundColor: activeTabSetting === 'security' ? "#081130" : "#56617B"
                        }
                      }}
                      variant={activeTabSetting === 'security' ? "contained" : 'text'}
                    >{translate('status.passwordkeamanan')}
                    </Button>
                  </Stack>
                </Box>
              </Hidden>

              {/* pengaturan akun Section */}

              {activeTabSetting === 'akun' ?
                <Grid item xs={12} sx={{ border: 1, color: '#E8EBF2', borderRadius: 2, p: '24px !important', }}>
                  <Box>
                    <Typography className="w-700" sx={{ color: '#081130', fontSize: 24, mb: '24px', }}>
                      {translate('status.pengaturanakun')}
                    </Typography>
                    <Stack direction={"row"} sx={{ alignItems: "center", }}>
                      <Typography className="w-700" sx={{ color: '#081130', fontSize: 18, mr: "8px" }}>
                        {translate('navbar.profile')}
                      </Typography>
                      <Typography className="w-500" sx={{ color: '#081130', fontSize: 14, }}>
                        (160 x 160px)
                      </Typography>
                    </Stack>

                  </Box>
                  <div>

                    <div
                      style={{
                        backgroundImage: `url(${dataUser.picture_path?.url ? config.baseURL + dataUser.picture_path?.url : profiledefault})`,
                        height: '140px', width: '140px',
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        position: "relative", transition: ".3s",
                        borderRadius: '1000px',
                      }}
                    >
                    </div>
                    <label for="upload">
                      <img style={{ position: 'relative', bottom: 40, right: -100, cursor: 'pointer' }} alt="Nikel-icon" src={UploadProfileIcon} height={36}></img>
                    </label>
                    <input type="file" id="upload" style={{ display: "none" }} accept="image/png, image/gif, image/jpeg" onChange={(e) => uploadImage(e)} />

                  </div>
                  <Grid container spacing={2} direction={isXsOrSm ? 'column' : 'row'} sx={{ mb: 2 }} >
                    <Grid item xs={6} sx={{ pr: 0, flexDirection: 'column' }} >
                      <Typography className="w-500" sx={{ color: '#081130', fontSize: 14, }}>
                        {translate('status.namadepan')}
                      </Typography>
                      <TextField
                        InputProps={{
                          style: stylesTextField,
                          className: "w-400"
                        }}
                        margin="normal"
                        fullWidth
                        sx={{
                          mt: '4px', borderRadius: '24px', input: {
                            fontSize: '14px', fontFamily: 'Satoshi-500'
                          },
                        }}
                        id="namadepan"
                        placeholder={translate('status.placeholder-namadepan')}
                        value={firstName} onChange={(e) => setFirstName(e.target.value)}
                        name="namadepan"
                        autoComplete="namadepan"
                      />
                    </Grid>
                    <Grid item xs={6} sx={{ pl: 0, flexDirection: 'column' }}>
                      <Typography className="w-500" sx={{ color: '#081130', fontSize: 14, }}>
                        {translate('status.namabelakang')}
                      </Typography>
                      <TextField

                        InputProps={{
                          style: stylesTextField,
                          className: "w-400"
                        }}
                        margin="normal"
                        fullWidth
                        id="namabelakang"
                        sx={{
                          mt: '4px', input: {
                            fontSize: '14px', fontFamily: 'Satoshi-500'
                          },
                        }}
                        placeholder={translate('status.placeholder-namabelakang')}
                        value={lastName} onChange={(e) => setLastName(e.target.value)}
                        name="email"
                        autoComplete="email"

                      />

                    </Grid>
                  </Grid>
                  <Grid container spacing={2} direction={isXsOrSm ? 'column' : 'row'} sx={{ mb: 2 }} >
                    <Grid item xs={6} sx={{ pr: 0, flexDirection: 'column' }} >
                      <Typography className="w-500" sx={{ color: '#081130', fontSize: 14, }}>
                        Email
                      </Typography>
                      <TextField
                        //className="box-input-email"
                        InputProps={{
                          style: stylesTextField,
                          className: "w-400"
                        }}
                        margin="normal"
                        fullWidth
                        sx={{
                          mt: '4px', input: {
                            fontSize: '14px', fontFamily: 'Satoshi-500'
                          },
                        }}
                        id="email"
                        placeholder={translate('login-register.placeholder-email')}
                        value={email} onChange={(e) => setEmail(e.target.value)}
                        name="email"
                        autoComplete="email"

                      />
                      <FormControlLabel control={<Checkbox onChange={() => setrequestHideEmail(!requestHideEmail)} checked={requestHideEmail} sx={{
                        color: "#E8EBF2",
                        '&.Mui-checked': {
                          color: "#3BB56C",
                        },
                      }} />} label={<Typography className="w-500" sx={{ color: '#081130', fontSize: 14, }}>
                        {translate('title.showemail')}
                      </Typography>} />
                    </Grid>
                    <Grid item xs={6} sx={{ pl: 2, flexDirection: 'column' }}>
                      <Typography className="w-500" sx={{ color: '#081130', fontSize: 14, }}>
                        {translate('login-register.phone')}
                      </Typography>
                      <TextField

                        InputProps={{
                          className: "w-400",
                          startAdornment: <InputAdornment position="start"> <Typography alignContent="center" className="w-500" sx={{
                            color: '#56617B', fontSize: 14, mr: '-4px', input: {
                              fontSize: '14px', fontFamily: 'Satoshi-500'
                            },
                          }}>
                            (+62)
                          </Typography> </InputAdornment>,
                          style: stylesTextField
                        }}
                        margin="normal"
                        fullWidth
                        id="nohp"
                        sx={{
                          mt: '4px', input: {
                            fontSize: '14px', fontFamily: 'Satoshi-500'
                          },
                        }}
                        placeholder={translate('status.placeholder-phone')}
                        value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}
                        name="phone"
                        autoComplete="phone"

                      />
                      <FormControlLabel className="w-500" sx={{ fontSize: 14, color: '#081130' }} control={<Checkbox onChange={() => setrequestHidePhone(!requestHidePhone)} checked={requestHidePhone} sx={{
                        color: "#E8EBF2",
                        '&.Mui-checked': {
                          color: "#3BB56C",
                        },
                      }} />} label={<Typography className="w-500" sx={{ color: '#081130', fontSize: 14, }}>
                        {translate('title.showphone')}
                      </Typography>} />
                    </Grid>
                  </Grid>
                  <Box>
                    <Typography className="w-500" sx={{ color: '#081130', fontSize: 14, }}>
                      {translate('status.aboutme')}
                    </Typography>
                    <TextField
                      InputProps={{
                        style: stylesTextField,
                        className: "w-400"

                      }}
                      margin="normal"
                      fullWidth
                      id="email"
                      sx={{
                        mt: '4px', input: {
                          fontSize: '14px', fontFamily: 'Satoshi-500'
                        },
                      }}
                      placeholder={translate('title.placeholder-aboutme')}
                      value={about} onChange={(e) => setAbout(e.target.value)}
                      name="aboutme"
                      autoComplete="aboutme"
                    />
                    <Divider orientation="horizontal" style={{ marginTop: '32px', marginBottom: '32px', height: '1px' }} flexItem />
                    <Typography className="w-700" sx={{ color: '#081130', fontSize: 24, mb: '24px', mt: '34px', fontWeight: 700 }}>
                      {translate('profile.address')}
                    </Typography>
                    <Typography className="w-500" sx={{ color: '#081130', fontSize: 14, }}>
                      {translate('status.alamatlengkap')}
                    </Typography>

                    <TextField
                      InputProps={{
                        style: stylesTextField,
                        className: "w-400",
                      }}

                      margin="normal"
                      fullWidth
                      id="alamat"
                      placeholder={translate('login-register.placeholder-address')}
                      value={address} onChange={(e) => setAddress(e.target.value)}
                      name="email"
                      autoComplete="email"
                      sx={{
                        mt: '4px', mb: '24px', input: {
                          fontSize: '14px', fontFamily: 'Satoshi-500'
                        },
                      }}
                    />
                  </Box>
                  <Grid container spacing={2} direction={isXsOrSm ? 'column' : 'row'}  >
                    <Grid item xs={6} sx={{ pr: 0, mb: '0px', flexDirection: 'column' }} >
                      <Typography className="w-500" sx={{ color: '#081130', fontSize: 14, }}>
                        {translate('login-register.provinsi')}
                      </Typography>
                      <FormControl sx={{ mt: '4px', width: '100%' }}>
                        <Select
                          IconComponent={() => (
                            <KeyboardArrowDownIcon sx={{ mr: '16px', width: '20px', height: '20px', fill: '#56617B' }} />
                          )}
                          style={stylesTextField}
                          value={province}
                          onChange={(e) => changeProvince(e.target.value)}
                          displayEmpty

                          inputProps={{
                            className: "w-400",
                            'aria-label': 'Without label'
                          }}
                        >
                          <MenuItem value={selectProvinsi}>
                            {translate('login-register.placeholder-provinsi')}
                          </MenuItem>
                          {listOfProvince.map((item, i) => (
                            <MenuItem key={i} value={item.id}>{item.attributes?.name} </MenuItem>
                          ))}
                        </Select>

                      </FormControl>

                    </Grid>
                    <Grid item xs={6} sx={{ pl: 0, mb: '24px', flexDirection: 'column' }}>
                      <Typography className="w-500" sx={{ color: '#081130', fontSize: 14, }}>
                        {translate('login-register.kota')}
                      </Typography>
                      <FormControl sx={{ mt: '4px', width: '100%' }} >
                        <Select
                          IconComponent={() => (
                            <KeyboardArrowDownIcon sx={{ mr: '16px', width: '20px', height: '20px', fill: '#56617B' }} />
                          )}
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                          displayEmpty

                          style={stylesTextField}
                          inputProps={{
                            className: "w-400",
                            'aria-label': 'Without label'
                          }}
                        >
                          <MenuItem value={selectKota}>
                            {translate('login-register.placeholder-city')}
                          </MenuItem>
                          {listOfcity.map((item, i) => (
                            <MenuItem key={i} value={item.id}>{item.attributes?.name} </MenuItem>
                          ))}
                        </Select>

                      </FormControl>

                    </Grid>

                  </Grid>
                  <Grid container direction="row" spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6} sx={{ pr: 0, flexDirection: 'column' }} >
                      <Typography className="w-500" sx={{ color: '#081130', fontSize: 14, }}>
                        {translate('login-register.kodepos')}
                      </Typography>
                      <TextField
                        InputProps={{
                          style: stylesTextField,
                          className: "w-400"
                        }}
                        margin="normal"
                        fullWidth
                        sx={{
                          mt: '4px', input: {
                            fontSize: '14px', fontFamily: 'Satoshi-500'
                          },
                        }}
                        id="kodepos"
                        placeholder={translate('login-register.placeholder-kodepos')}
                        value={postCode} onChange={(e) => setPostCode(e.target.value)}
                        name="email"
                        autoComplete="email"

                      />

                    </Grid>
                    <Grid item xs={6} sx={{ pl: 2 }}>


                    </Grid>
                  </Grid>
                  <Box sx={{ justifyContent: 'right', alignItems: 'end', display: 'flex' }}>

                    <Button
                      type="submit"
                      className="w-500"
                      variant="contained"

                      onClick={(e) => {
                        submitProfile(e)
                      }}
                      sx={{ p: '12px 16px 12px 16px', fontSize: '16px', width: '140px', height: '48px', textTransform: 'none', background: 'linear-gradient(270deg, #2457B9 15%, #2860CD 100%)', color: 'white', mt: '24px', borderRadius: '8px' }}
                    >
                      {translate('jual-beli.simpan')}
                    </Button>
                    <ModalSuccessEditProfile open={isOpenModal} close={handleCloseModal}></ModalSuccessEditProfile>
                  </Box>

                </Grid> : ''}
              {/* Email & Notifikasi Section */}
              {activeTabSetting === 'mail' ?
                <Grid item xs={12} sx={{ border: 1, color: '#E8EBF2', borderRadius: 2, p: '24px  !important', }}>
                  <Stack direction={'row'} alignItems="center" justifyContent={'space-between'}>
                    <Typography className="w-700" sx={{ color: '#081130', fontSize: { xs: 18, sm: 24 }, mb: { xs: '18px', sm: '24px' } }}>
                      {translate('status.emailnotifikasi')}
                    </Typography>
                    <Typography className="w-700" onClick={(e) => {
                      handleToggle()
                    }} sx={{ color: '#2457B9', fontSize: { xs: 14, sm: 16 }, mb: '24px', cursor: 'pointer' }}>
                      Reset default
                    </Typography>
                  </Stack>
                  <Typography className="w-700" sx={{ color: '#081130', fontSize: 18, mt: 1, mb: '24px', fontWeight: 700 }}>
                    {translate('status.akunsaya')}
                  </Typography>
                  <FormGroup>
                    <Stack alignItems="center" spacing={1} direction={'row'} justifyContent={'space-between'} sx={{ mb: '24px' }}>
                      <Typography className="w-500" sx={{ color: '#081130', fontSize: 16, }}>
                        {translate('status.aktivitasakun')}
                      </Typography>
                      <FormControlLabel
                        control={<IOSSwitch checked={accountActivity} onChange={() => setAccountActivity(!accountActivity)} sx={{ m: 1 }} />}

                      />
                    </Stack>
                    <Stack spacing={1} alignItems="center" direction={'row'} justifyContent={'space-between'} sx={{ mb: '24px' }}>
                      <Typography className="w-500" sx={{ color: '#081130', fontSize: 16, fontWeight: 500 }}>
                        {translate('status.traderrequestcoal')}
                      </Typography>
                      <FormControlLabel

                        control={<IOSSwitch onChange={() => setrequestBuyBatubara(!requestBuyBatubara)} checked={requestBuyBatubara} sx={{ m: 1 }} />}

                      />
                    </Stack>
                    <Stack spacing={1} alignItems="center" direction={'row'} justifyContent={'space-between'} sx={{ mb: '24px' }}>
                      <Typography className="w-500" sx={{ color: '#081130', fontSize: 16, }}>
                        {translate('status.traderrequesttongkang')}
                      </Typography>
                      <FormControlLabel
                        control={<IOSSwitch onChange={() => setrequestBuyTongkang(!requestBuyTongkang)} checked={requestBuyTongkang} sx={{ m: 1 }} />}

                      />
                    </Stack>

                    <Stack spacing={1} alignItems="center" direction={'row'} justifyContent={'space-between'} sx={{ mb: '24px' }}>
                      <Typography className="w-500" sx={{ color: '#081130', fontSize: 16, fontWeight: 500 }}>
                        {translate('status.traderrequestjasatongkang')}
                      </Typography>
                      <FormControlLabel
                        control={<IOSSwitch onChange={() => setrequestServiceTongkang(!requestServiceTongkang)} checked={requestServiceTongkang} sx={{ m: 1 }} />}

                      />
                    </Stack>

                    <Stack spacing={1} alignItems="center" direction={'row'} justifyContent={'space-between'} sx={{ mb: '24px' }}>
                      <Typography className="w-500" sx={{ color: '#081130', fontSize: 16, fontWeight: 500 }}>
                        {translate('status.commentnotif')}
                      </Typography>
                      <FormControlLabel
                        control={<IOSSwitch onChange={() => setcomment(!comment)} sx={{ m: 1 }} checked={comment} />}

                      />
                    </Stack>

                    <Stack spacing={1} alignItems="center" direction={'row'} justifyContent={'space-between'} sx={{ mb: '24px' }}>
                      <Typography className="w-500" sx={{ color: '#081130', fontSize: 16, fontWeight: 500 }}>
                        {translate('status.replycomment')}
                      </Typography>
                      <FormControlLabel
                        control={<IOSSwitch onChange={() => setreply(!reply)} sx={{ m: 1 }} checked={reply} />}

                      />
                    </Stack>


                  </FormGroup>
                  <Divider orientation="horizontal" style={{ backgroundColor: '#E8EBF2', marginTop: '24px', marginBottom: '24px', height: '1px' }} flexItem />

                  <Typography className="w-700" sx={{ color: '#081130', fontSize: 18, mt: 1, mb: '24px', fontWeight: 700 }}>
                    {translate('page_explore.perusahaan')}
                  </Typography>

                  <FormGroup>
                    <Stack spacing={1} alignItems="center" direction={'row'} justifyContent={'space-between'} sx={{ mb: '24px' }}>
                      <Typography className="w-500" sx={{ color: '#081130', fontSize: 16, fontWeight: 500 }}>
                        {translate('status.invitationnotif')}
                      </Typography>
                      <FormControlLabel
                        control={<IOSSwitch onChange={() => setcompanyInvitation(!companyInvitation)} sx={{ m: 1 }} checked={companyInvitation} />}

                      />
                    </Stack>

                    <Stack spacing={1} alignItems="center" direction={'row'} justifyContent={'space-between'} sx={{ mb: '24px' }}>
                      <Typography className="w-500" sx={{ color: '#081130', fontSize: 16, fontWeight: 500 }}>
                        {translate('status.requestjoinnotif')}
                      </Typography>
                      <FormControlLabel
                        control={<IOSSwitch onChange={() => setcompanyRequestAccepted(!companyRequestAccepted)} sx={{ m: 1 }} checked={companyRequestAccepted} />}

                      />
                    </Stack>
                    <Box sx={{ justifyContent: 'right', alignItems: 'end', display: 'flex' }}>

                      <Button
                        type="submit"
                        className="w-500"
                        variant="contained"

                        onClick={() => {
                          submitEmailNotification();
                        }}
                        sx={{ fontSize: 16, p: '12px 16px 12px 16px', textTransform: 'none', background: 'linear-gradient(270deg, #2457B9 15%, #2860CD 100%)', color: 'white', width: '160px', height: '48px', mt: '24px', borderRadius: '8px' }}
                      >
                        {translate('jual-beli.simpan')}
                      </Button>
                    </Box>
                  </FormGroup>

                </Grid> : ''}
              {/* Password & keamanan Section */}
              {activeTabSetting === 'security' ?
                <Grid item xs={12} sx={{ border: 1, color: '#E8EBF2', borderRadius: 2, p: '24px  !important', flexDirection: 'column' }} >
                  <Typography className="w-700" sx={{ color: '#081130', fontSize: 24, mb: '24px', }}>
                    {translate('status.passwordkeamanan')}
                  </Typography>
                  <Box component="form" noValidate onSubmit={(e) => ChangePassword(e)}>
                    <Grid container   >
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Typography className="w-500" sx={{ color: '#081130', fontSize: 14, lineHeight: 'normal' }}>
                          {translate('status.passwordlama')}
                        </Typography>
                        <TextField
                          margin="normal"
                          fullWidth
                          className="box-input-password"
                          sx={{
                            mt: '4px', mb: '24px', input: {
                              fontSize: '14px', fontFamily: 'Satoshi-500'
                            },
                          }}
                          name="currentPassword"

                          type={showPasswordLama ? "text" : "password"} // <-- This is where the magic happens
                          id="currentPassword"
                          autoComplete="current-password"
                          InputProps={{ // <-- This is where the toggle button is added.
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPasswordLama}
                                  onMouseDown={handleMouseDownPasswordLama}
                                >
                                  {showPasswordLama ? <img alt="eye-icon" style={{ width: '22px', height: '22px', paddingRight: 0 }} src={visibilityIcon}></img> : ''}
                                  {!showPasswordLama ? <img alt="eyeclose-icon" style={{ width: '20px', height: '20px', }} src={visibilityOffIcon}></img> : ''}

                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />

                        <Typography className="w-500" sx={{ color: '#081130', fontSize: 14, lineHeight: 'normal' }}>
                          {translate('status.passwordbaru')}
                        </Typography>
                        <TextField
                          margin="normal"
                          className="box-input-password"
                          fullWidth
                          sx={{
                            mt: '4px', mb: '24px', input: {
                              fontSize: '14px', fontFamily: 'Satoshi-500'
                            },
                          }}
                          name="password"

                          type={showPasswordBaru ? "text" : "password"} // <-- This is where the magic 
                          id="password"
                          autoComplete="current-password"
                          InputProps={{ // <-- This is where the toggle button is added.
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPasswordBaru}
                                  onMouseDown={handleMouseDownPasswordBaru}
                                >
                                  {showPasswordBaru ? <img alt="eye-icon" style={{ width: '22px', height: '22px', paddingRight: 0 }} src={visibilityIcon}></img> : ''}
                                  {!showPasswordBaru ? <img alt="eyeclose-icon" style={{ width: '20px', height: '20px', }} src={visibilityOffIcon}></img> : ''}

                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />

                        <Typography className="w-500" sx={{ color: '#081130', fontSize: 14, lineHeight: 'normal' }}>
                          {translate('status.konfirmasipasswordbaru')}
                        </Typography>
                        <TextField
                          className="box-input-password"
                          fullWidth
                          margin="normal"

                          sx={{
                            mt: '4px', input: {
                              fontSize: '14px', fontFamily: 'Satoshi-500'
                            },
                          }}
                          name="passwordConfirmation"

                          type={showKonfirmasiPassword ? "text" : "password"} // <-- This is where the magic happens
                          id="passwordConfirmation"
                          autoComplete="current-password"
                          InputProps={{ // <-- This is where the toggle button is added.
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowKonfirmasiPassword}
                                  onMouseDown={handleMouseDownKonfirmasiPassword}
                                >
                                  {showKonfirmasiPassword ? <img alt="eye-icon" style={{ width: '22px', height: '22px', paddingRight: 0 }} src={visibilityIcon}></img> : ''}
                                  {!showKonfirmasiPassword ? <img alt="eyeclose-icon" style={{ width: '20px', height: '20px', }} src={visibilityOffIcon}></img> : ''}

                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>

                    </Grid>
                    <Box>
                      <Button
                        className="w-500"
                        type="submit"
                        variant="contained"

                        sx={{
                          textTransform: 'none',
                          p: '12px 16px',
                          background: 'linear-gradient(270deg, #2457B9 15%, #2860CD 100%)',
                          color: 'white', width: '160px', height: '48px', mt: '24px', borderRadius: '8px'
                        }}
                      >
                        {isLoadingChangePassword ? <CircularProgress
                          sx={{
                            color: (theme) =>
                              theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                          }}
                          size={20}
                          thickness={4}
                          value={100}
                        /> : translate('status.perbaharui')}
                      </Button>
                    </Box>
                  </Box>
                  <Divider orientation="horizontal" style={{ marginTop: '32px', marginBottom: '32px', height: '1px' }} flexItem />
                  <Typography className="w-700" sx={{ color: '#081130', fontSize: 24, mb: '24px' }}>
                    {translate('status.keamanan')}
                  </Typography>
                  <Grid container direction={'row'} sx={{ mt: '24px', spacing: { sm: 4, xs: 2 } }}>
                    <Grid item xs={12} sm={9} sx={{ flexDirection: 'column' }}>
                      <Typography className="w-700" sx={{ color: '#081130', fontSize: 16, mb: 1 }}>
                        {translate('status.titleotentikasi')}
                      </Typography>
                      <Typography className="w-500" sx={{ color: '#56617B', fontSize: 14, }}>
                        {translate('status.otentikasi-note')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Stack
                        direction="row"
                        sx={{ justifyContent: { sm: "end", xs: "start" } }}
                      >
                        {isOtentikasi ?
                          <Button
                            type="submit"
                            variant="outlined"
                            onClick={(e) => handleOpenModal('disableotentikasi')}
                            sx={{
                              "&:hover": {
                                borderColor: '#ED3131'
                              },
                              border: '1px solid var(--code-red, #ED3131)',
                              p: '12px 16px',
                              borderRadius: '8px',
                              color: '#ED3131',
                              textTransform: 'none',
                              width: { sm: '100%', xs: '30%' },
                              minWidth: '110px',
                              maxWidth: '130px',
                              height: '40px',
                              mt: '24px'
                            }}
                          >
                            Disable
                          </Button> : <Button
                            type="submit"
                            variant="outlined"
                            className="w-500"
                            onClick={(e) => handleOpenModal('otentikasi')}
                            sx={{

                              border: '1px solid var(--primary-blue, #2457B9)',
                              p: '12px 16px',
                              borderRadius: '8px',
                              color: '#2457B9',
                              textTransform: 'none',

                              width: { sm: '100%', xs: '30%' },
                              minWidth: '110px',
                              maxWidth: '130px',
                              height: '40px',
                              mt: '24px'
                            }}
                          >
                            {translate('status.atur')}
                          </Button>}
                      </Stack>
                      <ModalOtentikasiTwoFactor HandleOtentikasi={HandleOtentikasiActive} open={isOpenOtentikasi} close={handleCloseModal}></ModalOtentikasiTwoFactor>

                    </Grid>
                  </Grid>
                  <Grid container direction={'row'} sx={{ mt: '24px', spacing: { sm: 4, xs: 2 }, }}>
                    <Grid item xs={12} sm={9} sx={{ flexDirection: 'column' }}>
                      <Typography className="w-700" sx={{ color: '#081130', fontSize: 16, mb: 1 }}>
                        {translate('status.pertanyaankeamanan')}
                      </Typography>
                      <Typography className="w-500" sx={{ color: '#56617B', fontSize: 14, }}>
                        {translate('status.subpertanyaankeamanan')}

                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Stack
                        direction="row"
                        sx={{ justifyContent: { sm: "end", xs: "start" } }}
                      >
                        {showquestionform ?
                          <Button
                            type="submit"
                            variant="outlined"
                            onClick={(e) => handleOpenModal('securitydisable')}
                            sx={{
                              "&:hover": {
                                borderColor: '#ED3131'
                              },
                              border: '1px solid var(--code-red, #ED3131)',
                              p: '12px 16px',
                              borderRadius: '8px',
                              color: '#ED3131',
                              textTransform: 'none',
                              width: { sm: '100%', xs: '30%' },
                              minWidth: '110px',
                              maxWidth: '130px',
                              height: '40px',
                              mt: '24px'
                            }}
                          >
                            Disable
                          </Button> : <Button
                            type="submit"
                            variant="outlined"
                            className="w-500"
                            onClick={(e) => handleOpenModal('securityactive')}
                            sx={{

                              border: '1px solid var(--primary-blue, #2457B9)',
                              p: '12px 16px',
                              borderRadius: '8px',
                              color: '#2457B9',
                              textTransform: 'none',

                              width: { sm: '100%', xs: '30%' },
                              minWidth: '110px',
                              maxWidth: '130px',
                              height: '40px',
                              mt: '24px'
                            }}
                          >
                            {translate('status.atur')}
                          </Button>}

                      </Stack>
                    </Grid>
                  </Grid>
                  {showquestionform ? <Box component="form" noValidate onSubmit={(e) => ChangePassword(e)}>
                    <Grid container spacing={3} direction={isXsOrSm ? 'column' : 'row'}  >
                      <Grid item xs={6} sx={{ pr: 0, mb: '0px', mt: '24px', flexDirection: 'column' }} >
                        <Typography className="w-500" sx={{ color: '#081130', fontSize: 14, }}>
                          {translate('status.pertanyaan')}
                        </Typography>
                        <FormControl sx={{ mb: '16px', width: '100%' }}>
                          <Select
                            IconComponent={() => (
                              <KeyboardArrowDownIcon sx={{ mr: '16px', width: '20px', height: '20px', fill: '#56617B' }} />
                            )}

                            style={{ border: '1px solid var(--grey-stroke, #e8ebf2)', marginTop: '4px', borderRadius: '8px', }}
                            value={selectquestion}
                            onChange={(e) => changeQuestion(e.target.value)}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                          >
                            <MenuItem value={selectquestion}>
                              <Typography className="w-400" style={{ color: '#8792AB', fontSize: 14 }}>  {translate('status.placeholder-pertanyaan')}</Typography>

                            </MenuItem>
                            {listOfQuestion.map((item, i) => (
                              <MenuItem key={i} value={item?.attributes.question}>{item?.attributes.question} </MenuItem>
                            ))}
                          </Select>

                        </FormControl>
                        <Typography className="w-500" sx={{ color: '#081130', fontSize: 14, }}>
                          {translate('status.jawaban')}
                        </Typography>
                        <TextField
                          margin="normal"
                          fullWidth
                          className="box-input-password"
                          sx={{
                            mt: '4px', input: {
                              fontSize: '14px', fontFamily: 'Satoshi-500'
                            },
                          }}
                          type={"text"} // <-- This is where the magic happens
                          value={answer}
                          onChange={(e) => ChangeAnswer(e.target.value)}
                          autoComplete="current-password"

                        />
                      </Grid>

                    </Grid>
                    <Grid container justifyContent='start' direction='row' sx={{ mb: '16px' }}>

                      <Button
                        onClick={(e) => handleOpenModal('securitydisable')}
                        size="large"
                        className="w-500"
                        variant="outlined"
                        sx={{
                          border: '1px solid var(--primary-blue, #2457B9)',
                          p: '12px 16px',
                          borderRadius: '8px',
                          color: '#2457B9',
                          textTransform: 'none',

                          width: { sm: '100%', xs: '30%' },
                          minWidth: '110px',
                          maxWidth: '130px',
                          height: '40px',
                          mr: '8px'
                        }}
                      >
                        {translate('jual-beli.btn-cancel')}
                      </Button>

                      <Button
                        onClick={() => {
                          HandleSendQuestion(selectquestion, answer)
                        }}
                        size="large"
                        className="w-500"
                        variant="contained"
                        sx={{

                          p: '12px 16px',
                          borderRadius: '8px',
                          background: '#2457B9',
                          textTransform: 'none',

                          width: { sm: '100%', xs: '30%' },
                          minWidth: '110px',
                          maxWidth: '130px',
                          height: '40px',

                        }}
                      >
                        OK
                      </Button>
                    </Grid>
                  </Box> : ''}

                </Grid> : ''}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Footer />
    </div >
  )
}

export default SettingPage
