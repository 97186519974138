import CompanyDefault from '@mui/icons-material/Business'
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import { Avatar, Box, Button, Card, CardContent, CardHeader, Grid, IconButton, Stack, Typography } from "@mui/material"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import callCompanyIcon from "../assets/call-company-ic.png"
import staffIcon from "../assets/icons/staff-ic.svg"
import mailCompanyIcon from "../assets/mail-company-ic.png"
import pelayarIcon from "../assets/pelayar-ic.png"
import penambangIcon from "../assets/penambang-ic.png"
import traderIcon from "../assets/trader-ic.png"
import { config } from "../services/constants"
import { capitalizeEachWord, convertText, format } from '../services/primary'
import ModalShare from "./ModalShare"

export default function CardCompanyList({ data, page }) {
  const [translate,] = useTranslation("global");
  const [product_id, setProduct_id] = useState(null);
  const [dataperusahaan, setdataperusahaan] = useState(null);
  const [user_trader_phone, setuser_trader_phone] = useState(null);
  const [anchorElShare, setAnchorElShare] = useState(null);
  const [isLogin] = useState(localStorage.getItem('token') !== null ? true : false);

  let data_
  if (Array.isArray(data) === false) {
    data_ = [data]
  } else {
    data_ = data
  }

  const handleOpenShareMenu = (event, id, traderphone, data_perusahaan) => {
    setuser_trader_phone(traderphone);
    setProduct_id(id);
    setdataperusahaan(data_perusahaan);
    setAnchorElShare(event.currentTarget);
  };
  const handleCloseShareMenu = () => {
    setAnchorElShare(null);
  };

  const GotoClaimCompany = (idcompany) => {
    if (isLogin) {
      window.location.href = `/claim-perusahaan?id_company=${idcompany}`
    } else {
      window.location.href = `/signin`
    }

  };

  const GotoDetailCompany = (id_company, name_company) => {
    let filter_name = capitalizeEachWord(convertText(name_company))
    window.location.href = `/detail/${filter_name}`
  };

  return (
    <React.Fragment>
      {data_.map((item) => (
        <Grid sx={{ mt: 2 }} item md={6} xs={12}>
          <Card>
            <CardHeader
              avatar={<Box>
                {item.verify_status === 'Unverified' ? (
                  <Box sx={{
                    background: "#F4F6FA",
                    borderRadius: '300px',
                    p: '2px 12px',
                    display: "flex",
                    alignItems: "center",
                    height: '24px',
                    mr: '6px'
                  }}>
                    <Stack direction={'row'} alignItems={'center'}>
                      <Box sx={{ borderRadius: '50%', background: '#56617B', width: '10px', height: '10px', mr: 1 }}>
                      </Box>
                      <Typography className="w-500" sx={{ color: '#081130', fontSize: 14 }} >
                        {translate('status.' + item.verify_status)}
                      </Typography>
                    </Stack>
                  </Box>
                ) : (
                  <></>
                )}
                {item.verify_status === 'Verifikasi Sedang Direview' ? (
                  <Box sx={{
                    background: "var(--tint-yellow, rgba(247, 206, 70, 0.30));",
                    borderRadius: '300px',
                    display: "flex",
                    alignItems: "center",
                    p: '2px 12px',
                    height: '24px',
                    mr: '12px'
                  }}>
                    <Stack direction={'row'} alignItems={'center'}>
                      <Box sx={{ borderRadius: '50%', background: '#F7CE46', width: '10px', height: '10px', mr: 1 }}>
                      </Box>
                      <Typography className="w-500" sx={{ color: '#081130', fontSize: 14 }} >
                        {translate('status.' + item.verify_status)}
                      </Typography>
                    </Stack>
                  </Box>

                ) : (
                  <></>
                )}

                {item.verify_status === 'Verified' ? (
                  <Box sx={{
                    background: 'var(--tint-green, rgba(59, 181, 108, 0.25));',
                    borderRadius: '300px',
                    p: '2px 12px',
                    display: "flex",
                    alignItems: "center",
                    height: '24px',
                    mr: '12px'

                  }}>
                    <Stack direction={'row'} alignItems={'center'}>
                      <Box sx={{ borderRadius: '50%', background: '#3BB56C', width: '10px', height: '10px', mr: 1 }}>
                      </Box>
                      <Typography className="w-500" sx={{ color: '#081130', fontSize: 14 }} >
                        {translate('status.' + item.verify_status)}
                      </Typography>
                    </Stack>
                  </Box>

                ) : (
                  <></>
                )}
              </Box>}
              action={
                <IconButton aria-label="settings">
                  <MoreHorizIcon onClick={(e) => handleOpenShareMenu(e, item.company_id, item.phone_number, item)} />
                  <ModalShare page={'company'} type={4} id={product_id} data={dataperusahaan} user_trader_phone={user_trader_phone} anchorEl={anchorElShare} open={anchorElShare} onClose={handleCloseShareMenu} />
                </IconButton>
              }

            />

            <CardContent
              sx={{ pt: 0, cursor: 'pointer' }}
              onClick={(e) => {
                GotoDetailCompany(item?.company_id, item?.name)
              }}

            >
              <Stack direction={'row'} sx={{ mb: 1 }}>
                {item.company_image ? <img style={{ borderRadius: '50%', marginRight: 6 }} alt="company_image-icon" src={config.baseURL + item.company_image} width={46.5} height={46.5}></img> : <Avatar sx={{ bgcolor: '#1976d2', mr: 1, height: 46.5, width: 46.5, }} aria-label="recipe">
                  <CompanyDefault></CompanyDefault>
                </Avatar>}
                <Stack direction={'column'}>
                  {item.name && <Typography
                    className="w-700"
                    sx={{ fontSize: 16, ml: 1, color: '#081130' }}
                  >  {item?.name?.length > 25 ? capitalizeEachWord(item?.name).slice(0, 25) + '...' : capitalizeEachWord(item?.name)}</Typography>}


                  {item.kota === null && item.provinsi === null ?
                    <Typography
                      className="w-500"
                      sx={{ fontSize: 14, ml: 1, color: '#56617B' }}
                    >{item.alamat}
                    </Typography> :
                    <Typography
                      className="w-500"
                      sx={{ fontSize: 14, ml: 1, color: '#56617B' }}
                    >{item.kota} {item.kota !== null && item.provinsi !== null ? ',' : ''} {item.provinsi}</Typography>

                  }
                </Stack>
              </Stack>
              <Box sx={{ background: "#F4F6FA", borderRadius: 4, p: 2 }}>
                <Stack style={{ textAlign: "left", color: "#56617B" }} spacing={1}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="left"
                  >
                    {item.bidang_perusahaan === 1 ? <img alt="Nikel-icon" src={penambangIcon} height={18}></img> : ''}
                    {item.bidang_perusahaan === 2 ? <img alt="Nikel-icon" src={traderIcon} height={18}></img> : ''}
                    {item.bidang_perusahaan === 3 ? <img alt="Nikel-icon" src={pelayarIcon} height={18}></img> : ''}
                    <Typography
                      className="w-500 s-1422"
                      sx={{ ml: 1 }}
                    >
                      {item.bidang_perusahaan === 1 ? translate('page_explore.penambang') : ''}
                      {item.bidang_perusahaan === 2 ? translate('page_explore.trader') : ''}
                      {item.bidang_perusahaan === 3 ? translate('page_explore.pelayaran') : ''}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="left"
                  >
                    <img alt="Nikel-icon" src={callCompanyIcon} height={16}></img>
                    <Typography className="w-500 " sx={{ fontSize: 14, ml: 1 }}>
                      {item.phone_number === null || item.phone_number === "" ? translate('status.nomor-empty') : format(item.phone_number)}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="left"
                  >
                    <img alt="Nikel-icon" src={mailCompanyIcon} height={16}></img>
                    <Typography className="w-500 " sx={{ fontSize: 14, ml: 1 }}>
                      {item.email === null || item.email === "" ? translate('status.email-empty') : item.email}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="left"
                  >
                    <img alt="Nikel-icon" src={staffIcon} height={16}></img>
                    <Typography className="w-500" sx={{ fontSize: 14, ml: 1 }}>
                      {item.total_staff} Staff
                    </Typography>
                  </Grid>
                </Stack>
              </Box>
              {item.verify_status === 'Unverified' && <Button fullWidth className="button-blue-outlined w-500"
                onClick={(e) => {
                  GotoClaimCompany(item.company_id)
                }}
                sx={{ fontSize: 14, p: '4px 8px', mt: '12px' }} variant="outlined"> {translate('status.claimperusahaan')}</Button>}
            </CardContent>
          </Card>
        </Grid>

      ))}
    </React.Fragment>
  )
}
