import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';

import { IconButton } from "@mui/material";
import { useState } from "react";
import favoritetongkangIC from "../assets/icons/favorite-tongkang-ic.svg";
export default function Love(props) {
    const [checkingFavorite, setCheckingFavorite] = useState(props.condition === null ? false : true);
    const addFavorite = async () => {
        await props.favorite()
        setCheckingFavorite(true)
    }
    const removeFavorite = async () => {
        await props.remove()
        setCheckingFavorite(false)
    }

    return (
        <>
            {props.type === 'table' ? (
                <>
                    {checkingFavorite ? <IconButton sx={{ p: 0 }} aria-label="love" onClick={() => removeFavorite()} size="small"><FavoriteTwoToneIcon sx={{ fontSize: 18, fill: "red" }} /> </IconButton> : <IconButton sx={{ p: 0 }} aria-label="love" onClick={() => addFavorite()} size="small"> <FavoriteBorderIcon sx={{ fontSize: 18, fill: props.page === 'tongkang' ? "#fff" : '' }} /> </IconButton>}
                </>
            ) :
                props.type === 'tongkang' ?
                    (
                        <>
                            {checkingFavorite ? <IconButton aria-label="love" onClick={() => removeFavorite()} sx={{ background: 'rgba(0, 0, 0, 0.1)', position: 'absolute', right: '4%', top: '1% !important', zIndex: 1, }} size="small">
                                <img alt="img-icon" src={favoritetongkangIC} height={24} width={24}></img>
                            </IconButton> : <IconButton aria-label="love" onClick={() => addFavorite()} sx={{ background: 'rgba(0, 0, 0, 0.1)', position: 'absolute', right: '4%', top: '1% !important', zIndex: 1, }} size="small"> <FavoriteBorderIcon sx={{ fontSize: 18, fill: "#fff", width: '24px', height: '24px', }} /> </IconButton>}
                        </>
                    )
                    :

                    (
                        <>
                            {checkingFavorite ? <IconButton aria-label="love" onClick={() => removeFavorite()} sx={{ p: 0 }} size="small">

                                <FavoriteTwoToneIcon sx={{ fontSize: 18, width: '24px', height: '24px', fill: 'red' }} />

                            </IconButton> : <IconButton aria-label="love" onClick={() => addFavorite()} sx={{ p: 0 }} size="small"> <FavoriteBorderIcon sx={{ fontSize: 18, width: '24px', height: '24px', }} /> </IconButton>}
                        </>
                    )}
        </>
    )
}