import { Box, Button, CircularProgress, Modal, Stack, Typography } from "@mui/material";
import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import ReactInputVerificationCode from 'react-input-verification-code';
import verifikasiIcon from "../assets/verifikasi-ic-phone.png";

export default function ModalOtp(props) {
    const [isLoadingVerification, setIsLoadingVerification] = useState(false)
    const [translate,] = useTranslation("global");
    return (
        <div>
            <Modal
                open={props.open}
                onClose={props.close}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '320px', sm: '446px', md: '446px', lg: '446px' },
                    bgcolor: 'background.paper',
                    borderRadius: '8px',
                    boxShadow: 24,
                    p: { xs: '16px', sm: '40px', md: '40px', lg: '40px' },
                }}>
                    <Box sx={{ textAlign: 'center' }}>
                        <Box component="img" src={verifikasiIcon} sx={{ width: { xs: '100px', sm: '120px', lg: '120px' }, height: { xs: '100px', sm: '120px', lg: '120px' }, }}>

                        </Box>
                    </Box>


                    <Typography className="w-700" sx={{ marginTop: '24px', textAlign: 'center', marginBottom: '8px', lineHeight: 'normal', fontSize: { xs: 18, sm: 18, md: 24, lg: 24 } }} > {translate('login-register.verifikasi-phone')}</Typography>
                    <p className="w-500" style={{ textAlign: 'center', color: '#56617B', fontSize: 14, lineHeight: '26px' }} id="child-modal-description">
                        {translate('login-register.label-verifikasi1')} <br></br> <b className="w-700" style={{ color: '#081130', fontSize: '14px', lineHeight: '26px' }}>{`+62${props.phoneNumber}`} </b>  {translate('login-register.label-verifikasi2')}
                    </p>
                    <Stack direction={'row'} spacing={1} className="signup" marginTop={1}>
                        <ReactInputVerificationCode placeholder={""} onChange={(e) => props.setOtp(e)} length={6} />
                    </Stack>
                    <p className="w-500" style={{ fontSize: 16, lineHeight: '22px', textAlign: 'center', color: '#56617B', marginTop: '24px' }} id="child-modal-description">
                        {translate('login-register.resend-code')} <b className="w-700" onClick={() => { props.loginWithPhoneNumberFirebase(); props.setIsLoadingConfirmation(true) }} style={{ color: '#2457B9', fontSize: 16, lineHeight: 'normal', cursor: 'pointer' }}> {translate('login-register.btn-resend')}</b>
                    </p>
                    <Stack sx={{ mt: { xs: '32px', sm: '32px', md: '52px', lg: '52px', xl: '52px' } }} direction={'row'} justifyContent={'center'} >
                        <Button
                            className="w-500"
                            fullWidth
                            onClick={props.close}
                            variant="outlined"
                            sx={{
                                mr: '16px',
                                width: '160px',
                                height: '48px',
                                fontSize: 16,
                                lineHeight: '22px',
                                textTransform: "none", border: '1px solid var(--primarry-blue, #2457B9)',
                                p: { xs: '10px 12px', lg: '12px 16px', md: '12px 16px' },
                                borderRadius: '8px',
                                color: '#2457B9'
                            }}
                        >
                            {translate('jual-beli.btn-cancel')}
                        </Button>
                        <Button
                            className="w-500"
                            type="submit"
                            fullWidth
                            variant="contained"
                            onClick={(e) => {
                                console.log("send otp")
                                props.confirmPhoneNumber()
                                console.log("send otp")
                            }}
                            sx={{
                                p: { xs: '10px 12px', lg: '12px 16px', md: '12px 16px' },
                                width: '160px',
                                height: '48px',
                                fontSize: 16, lineHeight: '22px', textTransform: 'none',
                                background: ' linear-gradient(270deg, #2457B9 15%, #2860CD 100%)', borderRadius: '8px', boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.12), 0px -1px 0px 0px rgba(0, 0, 0, 0.20) inset',
                            }}
                        >
                            {props.isLoadingConfirmation ? <CircularProgress
                                sx={{
                                    color: (theme) =>
                                        theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                                }}
                                size={20}
                                thickness={4}
                                value={100}
                            /> : translate('login-register.btn-verifikasi')}
                        </Button>
                    </Stack>
                </Box>
            </Modal>
        </div>
    );
}