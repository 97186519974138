import CloseIcon from '@mui/icons-material/Close';
import { Button, Card, Grid, IconButton, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from "react";
import { useDropzone } from 'react-dropzone';
import { useTranslation } from "react-i18next";
import doc_icon from "../assets/doc.svg";
import add_icon from "../assets/icons/add-circle.svg";
import { DisplayPDFUpload } from './DisplayPDFUpload';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '792px', // Lebar maksimum
    borderRadius: '8px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    "&:focus": {
        outline: "none"
    },
};

export default function ModalUnggahDocBaru(props) {

    const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'application/pdf': ['.pdf'],
            // 'application/msword': ['.doc', '.docx', '.xlsx']
        },
        minSize: 0,
        // maxSize: 24000000
    });

    useEffect(() => {
        if (props.open === true) {
            handleChangeFileDocument(props.file)
        } else {
            setFileDocument([])
            setFileUploadDocument([])
        }
    }, [props.open])

    const [translate,] = useTranslation("global");
    const [fileDocument, setFileDocument] = useState([]);
    const [fileUploadDocument, setFileUploadDocument] = useState([]);
    const [disableSubmit, setDisableSubmit] = useState(false)

    const uploadForm = async () => {
        let id_file = ""
        for (let i = 0; i < fileDocument.length; i++) {
            const element = fileDocument[i];
            if (Number(element?.id_upload ?? 0) > 0) {
                if (i === 0) {
                    id_file = id_file + `${element.id_upload}`
                } else {
                    id_file = id_file + `,${element.id_upload}`
                }
            }
        }
        // await uploadUserDocuments({ id_file: id_file })
        props.close('submit', fileDocument);
        props.closeModal()
    };

    const deleteFileonArray = (index) => {
        let newFileDocument = fileDocument.concat([])
        let newFileUploadDocument = fileUploadDocument.concat([])
        if (index > -1) {
            newFileDocument.splice(index, 1);
            newFileUploadDocument.splice(index, 1);
        }
        setFileDocument(newFileDocument);
        setFileUploadDocument(newFileUploadDocument);
    }

    const handleChangeFile = (file, index) => {
        let allFile = fileUploadDocument
        allFile[index] = file;
        setFileDocument(allFile);
    }

    const handleChangeFileDocument = (e) => {
        let arrayFile = e
        let arrayNewFile = []
        let arrayNewFileUpload = []
        for (let i = 0; i < arrayFile.length; i++) {
            let nameExt = (arrayFile[i].name).split(".")
            let newFile = {
                url_display: "",
                url: arrayFile[i],
                name: arrayFile[i].name,
                size: niceBytes(arrayFile[i].size),
                ext: `.${nameExt[nameExt.length - 1]}`,
                progress: 0
            }
            let newFileUpload = arrayFile[i]
            arrayNewFile.push(newFile)
            arrayNewFileUpload.push(newFileUpload)
        }
        let allFile = fileDocument.concat(arrayNewFile)
        let allFileUpload = fileUploadDocument.concat(arrayNewFileUpload)
        setFileDocument(allFile);
        setFileUploadDocument(allFileUpload)
    }

    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const niceBytes = (e) => {
        let l = 0, n = parseInt(e, 10) || 0;
        while (n >= 1024 && ++l) {
            n = n / 1024;
        }
        return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
    }

    useEffect(() => {
        if (acceptedFiles?.length !== 0) {
            handleChangeFileDocument(acceptedFiles)
        }
    }, [acceptedFiles])

    useEffect(() => {
        setDisableSubmit(false)
        let count = 0
        for (let i = 0; i < fileDocument.length; i++) {
            const e = fileDocument[i];
            if (e.size > 24000000) {
                count = count + 1
            }
        }
        if (count === 0) {
            setDisableSubmit(false)
        } else {
            setDisableSubmit(true)
        }
    }, [fileDocument])

    return (
        <div>
            <Modal
                open={props.open}
                onClose={props.closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                        <Typography id="modal-modal-title" className='w-700' sx={{ fontSize: '18px', color: '#081130', textAlign: 'center', }}>
                            {translate('status.tambahdokumen')}
                        </Typography>
                        <IconButton onClick={() => {
                            props.close();
                            props.closeModal()
                        }} size="small" >
                            <CloseIcon />
                        </IconButton>
                    </Stack>

                    {fileDocument.length === 0 && <div {...getRootProps()}>
                        <Grid style={{ border: `${isDragActive ? "3px dashed #2457B9" : "3px dashed #E8EBF2"}` }} className='upload-doc mt-24' container direction='column' justifyContent='center' alignItems='center' alignContent='center'>
                            <img src={doc_icon} alt='' />
                            <p className='s-1419 w-700 mt-12 c-24 mb4'>{translate('jual-beli.placeholder-dokumen')}</p>
                            <p className='s-1216 w-500 c-56'>{translate('jual-beli.note-dokumen')}</p>
                        </Grid>
                        <input {...getInputProps()}
                        // onChange={(e) => handleChangeFileDocument(e)}
                        // type="file" style={{ display: "none" }} accept=".doc,.docx,.xlsx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
                        />
                    </div>}


                    <div style={{ maxHeight: '300px', overflow: 'scroll', overflowX: 'unset' }}>
                        <Grid style={{ marginTop: "5px" }} container direction={'row'} spacing={2}>
                            {fileDocument?.map((item, i) => (
                                <>
                                    <Grid item xs={3} sm={3} md={3} lg={3}>
                                        <Card sx={{ borderRadius: 2, }}>
                                            <DisplayPDFUpload
                                                data={item}
                                                dataUpload={fileUploadDocument[i]}
                                                indexed={i}
                                                delete={() => deleteFileonArray(i)}
                                                update={handleChangeFile}
                                            />
                                        </Card>
                                    </Grid>
                                </>
                            ))}
                            {fileDocument.length > 0 &&
                                <Grid item xs={3} sm={3} md={3} lg={3} sx={{ height: "100%" }}>
                                    <div {...getRootProps()}>
                                        <Grid item direction='column' justifyContent='center' alignItems='center' alignContent='center'>
                                            <Card sx={{ borderRadius: 2, width: '100%', height: '152px', background: '#F4F6FA', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <img width={48} height={48} src={add_icon} alt='' />
                                                {/* <p className='s-1419 w-700 mt-12 c-24 mb4'>{translate('jual-beli.placeholder-dokumen')}</p>
                                    <p className='s-1216 w-500 c-56'>{translate('jual-beli.note-dokumen')}</p> */}
                                            </Card>
                                        </Grid>
                                        <input {...getInputProps()}
                                        // onChange={(e) => handleChangeFileDocument(e)}
                                        // type="file" style={{ display: "none" }} accept=".doc,.docx,.xlsx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
                                        />
                                    </div>
                                </Grid>}
                        </Grid>
                    </div>


                    <Stack direction={'row'} alignItems={'center'} justifyContent={'right'} spacing={2} sx={{ mt: 2 }}>
                        <Button
                            onClick={() => {
                                props.close();
                                props.closeModal()
                            }}
                            className="w-500"
                            variant="outlined"
                            sx={{ p: '12px 16px 12px 16px', height: '48px', width: '140px', fontSize: 16, borderRadius: '8px', textTransform: 'none' }}>
                            {translate('jual-beli.btn-cancel')}
                        </Button>

                        <Button
                            disabled={disableSubmit}
                            onClick={() => { uploadForm() }}
                            className="w-500"
                            variant="contained"
                            sx={{ p: '12px 16px 12px 16px', height: '48px', width: '140px', fontSize: 16, borderRadius: '8px', textTransform: 'none', background: ' #2457B9' }}>
                            Submit
                        </Button>
                    </Stack>
                </Box>
            </Modal>
        </div >
    );
}