
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Card, Drawer, Grid, IconButton, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDropzone } from 'react-dropzone';
import { useTranslation } from "react-i18next";
import doc_icon from "../assets/doc.svg";
import add_icon from "../assets/icons/add-circle.svg";
import { DisplayPDFUpload } from './DisplayPDFUpload';

export default function DrawerUploadDoc(props) {

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
      // 'application/msword': ['.doc', '.docx', '.xlsx']
    },
    minSize: 0,
    // maxSize: 24000000
  });

  useEffect(() => {
    if (props.open === true) {
      handleChangeFileDocument(props.file)
    } else {
      setFileDocument([])
      setFileUploadDocument([])
    }
  }, [props.open])

  const [translate,] = useTranslation("global");
  const [fileDocument, setFileDocument] = useState([]);
  const [fileUploadDocument, setFileUploadDocument] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(false)

  const uploadForm = async () => {
    let id_file = ""
    for (let i = 0; i < fileDocument.length; i++) {
      const element = fileDocument[i];
      if (Number(element?.id_upload ?? 0) > 0) {
        if (i === 0) {
          id_file = id_file + `${element.id_upload}`
        } else {
          id_file = id_file + `,${element.id_upload}`
        }
      }
    }
    // await uploadUserDocuments({ id_file: id_file })
    props.close('submit', fileDocument);
    props.closeModal()
  };

  const deleteFileonArray = (index) => {
    let newFileDocument = fileDocument.concat([])
    let newFileUploadDocument = fileUploadDocument.concat([])
    if (index > -1) {
      newFileDocument.splice(index, 1);
      newFileUploadDocument.splice(index, 1);
    }
    setFileDocument(newFileDocument);
    setFileUploadDocument(newFileUploadDocument);
  }

  const handleChangeFile = (file, index) => {
    let allFile = fileUploadDocument
    allFile[index] = file;
    setFileDocument(allFile);
  }

  const handleChangeFileDocument = (e) => {
    let arrayFile = e ? e : []
    console.log(arrayFile)
    let arrayNewFile = []
    let arrayNewFileUpload = []
    for (let i = 0; i < arrayFile.length; i++) {
      let nameExt = (arrayFile[i].name).split(".")
      let newFile = {
        url_display: arrayFile[i],
        url: arrayFile[i],
        name: arrayFile[i].name,
        size: niceBytes(arrayFile[i].size),
        ext: `.${nameExt[nameExt.length - 1]}`,
        progress: 0
      }
      let newFileUpload = arrayFile[i]
      arrayNewFile.push(newFile)
      arrayNewFileUpload.push(newFileUpload)
    }
    let allFile = fileDocument.concat(arrayNewFile)
    let allFileUpload = fileUploadDocument.concat(arrayNewFileUpload)
    setFileDocument(allFile);
    setFileUploadDocument(allFileUpload)
  }

  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const niceBytes = (e) => {
    let l = 0, n = parseInt(e, 10) || 0;
    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
  }

  useEffect(() => {
    if (acceptedFiles?.length !== 0) {
      handleChangeFileDocument(acceptedFiles)
    }
  }, [acceptedFiles])

  useEffect(() => {
    setDisableSubmit(false)
    let count = 0
    for (let i = 0; i < fileDocument.length; i++) {
      const e = fileDocument[i];
      if (e.size > 24000000) {
        count = count + 1
      }
    }
    if (count === 0) {
      setDisableSubmit(false)
    } else {
      setDisableSubmit(true)
    }
  }, [fileDocument])

  return (
    <Drawer
      className="box-drawer-upload-doc"
      sx={{ zIndex: 999999, }}
      anchor="bottom"
      open={Boolean(props.open)}
      onClose={props.closeModal}
    >
      <Box sx={{ pl: 2, pr: 2, pt: 2 }}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography id="modal-modal-title" className='w-700' sx={{ fontSize: '18px', color: '#081130', textAlign: 'start' }}>
            {translate('jual-beli.unggah-dokument')}
          </Typography>
          <IconButton size="small" >
            <CloseIcon onClick={() => {
              props.close();
              props.closeModal()
            }} />
          </IconButton>
        </Stack>
      </Box>

      <Box sx={{ ml: '16px', mr: '16px' }}>
        {/* <label for="upload-document">
          <Grid className='upload-doc mt-24' container xs={12} direction='column' justifyContent='center' alignItems='center' alignContent='center'>
            <img src={doc_icon} alt='' />
            <p className='s-1419 w-700 mt-12 c-24 mb4'>{translate('jual-beli.placeholder-dokumen-profile')}</p>
            <p className='s-1216 w-500 c-56'>{translate('jual-beli.note-dokumen')}</p>
          </Grid>
          <input onChange={(e) => handleChangeFileDocument(e)} type="file" id="upload-document" style={{ display: "none" }} accept=".doc,.docx,.xlsx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf" />
        </label> */}
        {fileDocument.length === 0 && <div {...getRootProps()}>
          <Grid className='upload-doc mt-24' container xs={12} direction='column' justifyContent='center' alignItems='center' alignContent='center'>
            <img src={doc_icon} alt='' />
            <p className='s-1419 w-700 mt-12 c-24 mb4'>{translate('jual-beli.placeholder-dokumen-profile')}</p>
            <p className='s-1216 w-500 c-56'>{translate('jual-beli.note-dokumen')}</p>
          </Grid>
          <input {...getInputProps()}
          // onChange={(e) => handleChangeFileDocument(e)}
          // type="file" style={{ display: "none" }} accept=".doc,.docx,.xlsx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
          />
        </div>}
      </Box>

      <Box sx={{ height: '100%', overflow: "auto", mt: 2, pl: 2, pr: 2, pb: "85px" }}>
        <Grid container direction={'row'} spacing={2}>
          {fileDocument?.map((item, i) => (
            <>
              <Grid item xs={6} sm={6} md={6} lg={6} sx={{ height: "100%" }}>
                <Card sx={{ borderRadius: 2 }}>
                  <DisplayPDFUpload
                    data={item}
                    dataUpload={fileUploadDocument[i]}
                    indexed={i}
                    delete={() => deleteFileonArray(i)}
                    update={handleChangeFile}
                  />
                </Card>
              </Grid>
            </>
          ))}
          {fileDocument.length > 0 &&
            <Grid item xs={6} sm={6} md={6} lg={6} sx={{ height: "100%" }}>
              <div {...getRootProps()}>
                <Grid direction='column' justifyContent='center' alignItems='center' alignContent='center' sx={{ height: "100%" }}>
                  <Card sx={{ borderRadius: 2, width: '100%', height: '152px !important', background: '#F4F6FA', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img width={48} height={48} src={add_icon} alt='' />
                    {/* <p className='s-1419 w-700 mt-12 c-24 mb4'>{translate('jual-beli.placeholder-dokumen')}</p>
                                    <p className='s-1216 w-500 c-56'>{translate('jual-beli.note-dokumen')}</p> */}
                  </Card>
                </Grid>
                <input {...getInputProps()}
                // onChange={(e) => handleChangeFileDocument(e)}
                // type="file" style={{ display: "none" }} accept=".doc,.docx,.xlsx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
                />
              </div>
            </Grid>
          }
        </Grid>
      </Box>

      {/* Button section */}
      <Box sx={{ backgroundColor: 'white', position: 'fixed', bottom: 0, width: '100%' }}>
        <Stack spacing={2} direction={'row'} sx={{ mt: 0, p: 2 }}>
          <Button
            //size="large"
            fullWidth
            onClick={() => {
              props.close();
              props.closeModal()
            }}
            className="w-500"
            variant="outlined"
            sx={{
              //p: '12px 16px 12px 16px', 
              height: '44px', fontSize: 14, borderRadius: '8px', textTransform: 'none'
            }}>
            {translate('jual-beli.btn-cancel')}
          </Button>
          <Button
            //size="large"
            fullWidth
            disabled={disableSubmit}
            onClick={() => { uploadForm() }}
            className="w-500"
            variant="contained"
            sx={{
              //p: '12px 16px 12px 16px', 
              height: '44px', fontSize: 14, borderRadius: '8px', textTransform: 'none', background: ' #2457B9'
            }}>
            Submit
          </Button>
        </Stack>
      </Box>
    </Drawer>
  );
}