import { Hidden, IconButton, InputAdornment } from "@material-ui/core"
import Divider from "@material-ui/core/Divider"
import { Box, Button, CircularProgress, Grid, Link, Stack, TextField, Typography } from '@mui/material'
import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider, createTheme } from "@mui/material/styles"
import "firebase/analytics"
import firebase from "firebase/app"
import "firebase/auth"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import Swal from 'sweetalert2'
import visibilityOffIcon from "../assets/eye-icon-close.svg"
import visibilityIcon from "../assets/eye-icon.svg"
import Facebookicon from "../assets/facebook-ic-color.png"
import Googleicon from "../assets/google-ic-color.png"
import heroLoginImg from "../assets/hero-login.webp"
import LogoIndomine from "../assets/logo-color.png"
import ModalOtp from "../components/ModalOtp"
import { styled } from '@mui/system';
import { login, loginFromFirebase, loginFromPhoneNumber, loginFromPhoneNumberFirebase, sendCodeResetPassword, signUpFromFirebase } from "../services/api"
import "../services/firebase"
const theme = createTheme()

const VerticalDivider = styled('div')({
  width: '1px',
  backgroundColor: 'rgba(0, 0, 0, 0.12)',
  height: '100%',
  position: 'absolute',
  //left: 'calc(20% + 2px)',
  left: '46px',
  top: '0',
  bottom: '0',
  zIndex: '1',
});

export default function Signin() {
  const { state } = useLocation();
  let path = window.location.pathname

  const [translate] = useTranslation("global");
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  const [isLoadingConfirmation, setIsLoadingConfirmation] = useState(false);
  const [loginWith, setLoginWith] = useState("email");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isLoadingForgotPassword, setIsLoadingForgotPassword] = useState(false);
  const [otp, setOtp] = useState("")
  const [openOTP, setOpenOtp] = React.useState(false);

  const handleChangeNoHp = (event) => {
    const newValue = event.replace(/[^0-9]/g, "");
    // Cek apakah karakter pertama adalah angka nol
    if (newValue.length === 0 || (newValue.length === 1 && newValue === '0')) {
      setPhoneNumber('');
    } else {
      setPhoneNumber(newValue);
    }
  };

  const handleClose = () => {
    setOpenOtp(false);
  };

  const handleSubmit = async (event, condition) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    if (condition === "login") {
      const dataLogin = {
        identifier: data.get("identifier"),
        password: data.get("password"),
      }
      setIsLoadingLogin(true)
      const response = await login(dataLogin);
      setIsLoadingLogin(false)
      if (response.status === 200) {
        localStorage.setItem("token", response.jwt)
        localStorage.setItem("profilePicture", response.profile_picture?.url ?? 'null')
        localStorage.setItem("id_user", response.data?.id ?? 'null')
        window.location.href = "/"
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Failed Login',
          text: `${response?.message}`,
        })
      }
    } else if (condition === "verification") {
      setIsLoadingForgotPassword(true)
      const response = await sendCodeResetPassword({ email: data.get("email") });
      setIsLoadingForgotPassword(false)
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: `${response?.message}`,
        })
        // window.location.href = "/"
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: `${response?.message}`,
        })
      }
    }
  }

  const registerWithFirebase = async (e) => {
    const responAPI = await signUpFromFirebase(e)
    if (responAPI !== undefined && responAPI.status === 200) {
      loginWithTokenFirebase(e)
    } else if (responAPI.message !== 'Internal Server Error' && responAPI.status === 500) {
      loginWithTokenFirebase(e)
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: "Can't Registration",
      })
    }
  }

  const loginWithTokenFirebase = async (e) => {
    const responAPI = await loginFromFirebase(e)
    if (responAPI.status === 200) {
      localStorage.setItem("token", responAPI.jwt)
      localStorage.setItem("profilePicture", responAPI.data?.profile_picture?.url ?? 'null')
      localStorage.setItem("id_user", responAPI.data?.id ?? 'null')
      if (state?.detail === 'listing') {
        window.location.href = `/listing/detail?type=${state.type}&id_detail=${state.id_relation}`
      } else {
        window.location.href = "/"
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Failed Login',
        text: `${responAPI?.message}`,
      })
    }
  }

  const loginWithGoogle = async () => {
    firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider()).then((result) => {
      let token = result.user.Aa
      registerWithFirebase({ token_firebase: token })
    }).catch(error => {

    })
  }

  const loginWithFacebook = async () => {
    firebase.auth().signInWithPopup(new firebase.auth.FacebookAuthProvider()).then((result) => {
      let token = result.user.Aa
      registerWithFirebase({ token_firebase: token })
    }).catch(error => {

    })
  }

  const loginWithPhoneNumberVerify = async () => {
    setIsLoadingLogin(true)
    const responAPI = await loginFromPhoneNumber({ phone_number: phoneNumber })
    if (responAPI.status === 200) {
      localStorage.setItem("token", responAPI.jwt)
      localStorage.setItem("profilePicture", responAPI.data?.profile_picture?.url ?? 'null')
      localStorage.setItem("id_user", responAPI.data?.id ?? 'null')
      if (state?.detail === 'listing') {
        window.location.href = `/listing/detail?type=${state.type}&id_detail=${state.id_relation}`
      } else {
        window.location.href = "/"
      }
    } else {
      if (responAPI.status === 400) {
        setIsLoadingLogin(false)
        loginWithPhoneNumberFirebase()
      } else {
        setIsLoadingLogin(false)
        Swal.fire({
          icon: 'error',
          title: 'Failed Login',
          text: `${responAPI?.message}`,
        })
      }
    }
  }

  const onCaptchVerify = () => {
    if (!window.recaptchaVerifier) {
      const box = document.getElementById('recaptcha-container');
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        box,
        {
          size: "invisible",
          // callback: (response) => {
          //   loginWithPhoneNumberFirebase();
          // },
          "expired-callback": () => { },
        }
      );
      const appVerifier = window.recaptchaVerifier;
      appVerifier.render()
    }
  }

  const loginWithPhoneNumberFirebase = async () => {

    setIsLoadingLogin(true)

    onCaptchVerify()

    if (window.recaptchaVerifier) {
      const appVerifier = window.recaptchaVerifier;
      appVerifier.render()

      firebase.auth().signInWithPhoneNumber(`+62${phoneNumber}`, appVerifier)
        .then((confirmationResult) => {
          console.log(confirmationResult)
          window.confirmationResult = confirmationResult;
          setOpenOtp(true);
          setIsLoadingLogin(false)
          setIsLoadingConfirmation(false)
        })
        .catch((error) => {
          setIsLoadingLogin(false)
          setIsLoadingConfirmation(false)
          Swal.fire({
            icon: 'error',
            title: 'Failed Login',
            text: `Konfirmasi nomor handphone gagal. Coba lagi nanti.`,
          })
        });
    }
  }

  const confirmPhoneNumber = async () => {
    try {
      setIsLoadingConfirmation(true)
      await window.confirmationResult.confirm(otp)
      firebase.auth().currentUser.getIdToken(true).then(async (idToken) => {
        const responAPI = await loginFromPhoneNumberFirebase({ token_firebase: idToken })
        setIsLoadingConfirmation(false)
        setOpenOtp(false);
        if (responAPI.status === 200) {
          localStorage.setItem("token", responAPI.jwt)
          localStorage.setItem("profilePicture", responAPI.data?.profile_picture?.url ?? 'null')
          localStorage.setItem("id_user", responAPI.data?.id ?? 'null')
          if (state?.detail === 'listing') {
            window.location.href = `/listing/detail?type=${state.type}&id_detail=${state.id_relation}`
          } else {
            window.location.href = "/"
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Failed Login',
            text: `${responAPI?.message}`,
          })
        }
      })
    } catch (error) {
      setIsLoadingConfirmation(false)
      setOpenOtp(false);
      Swal.fire({
        icon: 'error',
        title: 'Failed Login',
        text: `${error?.message}`,
      })
    }
  }

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main">
        <CssBaseline />
        <ModalOtp setIsLoadingConfirmation={setIsLoadingConfirmation} isLoadingConfirmation={isLoadingConfirmation} loginWithPhoneNumberFirebase={loginWithPhoneNumberFirebase} phoneNumber={phoneNumber} confirmPhoneNumber={confirmPhoneNumber} setOtp={setOtp} open={openOTP} close={handleClose}></ModalOtp>
        <Grid item xs={12} sm={12} md={6} lg={6} elevation={6} square>
          {path.includes('signin') ? <Box
            sx={{
              mt: { xs: '24px', sm: '24px', md: '52px', lg: '52px' },
              ml: { xs: '16px', sm: '32px', md: '60px', lg: '120px' },
              mr: { xs: '16px', sm: '32px', },
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div id="recaptcha-container"></div>
            <Grid container direction="row" sx={{ mb: 8, }} alignItems="center" justifyContent={'space-between'}>
              <Stack direction={'row'} alignItems={'center'}>
                <Box
                  component="img"
                  sx={{
                    width: 26,
                    height: 28,
                  }}
                  alt="logo-img"
                  src={LogoIndomine}
                />
                <Typography className="w-700" sx={{ color: '#081130', fontSize: 24, ml: '10px' }}>
                  IndoMine
                </Typography>

              </Stack>

              <Hidden smDown>
                <Link href="/" className="w-500" sx={{ color: '#2457B9', fontSize: 16, lineHeight: '22px', textDecoration: 'none', mr: '40px' }}>
                  {translate('login-register.btn-back-home')}
                </Link>
              </Hidden>
            </Grid>

            <Typography className="w-700" sx={{ color: '#081130', fontSize: { lg: 32, xs: 32 }, width: '360px', lineHeight: 'normal' }}>
              {translate('login-register.title-signin')}
            </Typography>

            {loginWith === "email" ? (
              <Typography className="w-500" sx={{ color: '#56617B', fontSize: { lg: 16, xs: 14 }, mt: '12px', maxWidth: "430px" }}>
                {translate('login-register.subtitle-signin')}. <span onClick={() => setLoginWith("phone_number")} style={{ color: "#1e90ff", cursor: "pointer" }}>Atau login menggunakan nomor handphone</span>
              </Typography>
            ) : (
              <Typography className="w-500" sx={{ color: '#56617B', fontSize: { lg: 16, xs: 14 }, mt: '12px', maxWidth: "430px" }}>
                Silahkan masukan nomor handphone anda. <span onClick={() => setLoginWith("email")} style={{ color: "#1e90ff", cursor: "pointer" }}>Atau login menggunakan email dan password</span>
              </Typography>
            )}

            {loginWith === "email" ? (
              <Box
                component="form"
                noValidate
                onSubmit={(e) => handleSubmit(e, "login")}
                sx={{ mt: '32px', maxWidth: { lg: '430px', sm: '100%', xs: '100%' } }}
              >
                <Typography className="w-500" sx={{ color: '#081130', fontSize: 14 }}>
                  Email
                </Typography>
                <TextField
                  sx={{
                    input: {
                      fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                  }}
                  className="box-input-email"
                  fullWidth
                  id="identifier"
                  placeholder={translate('login-register.placeholder-email')}
                  name="identifier"
                  autoComplete="email"
                  autoFocus
                />
                <Typography className="w-500" sx={{ color: '#081130', fontSize: 14, mt: '24px' }}>
                  Password
                </Typography>
                <TextField
                  sx={{
                    input: {
                      fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                  }}
                  className="box-input-password"
                  fullWidth
                  name="password"
                  placeholder={translate('login-register.placeholder-password')}
                  type={showPassword ? "text" : "password"} // <-- This is where the magic happens
                  id="password"
                  autoComplete="current-password"
                  InputProps={{ // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          style={{ padding: 0 }}

                        >
                          {showPassword ? <img alt="eye-icon" style={{ width: '22px', height: '22px', paddingRight: 0 }} src={visibilityIcon}></img> : ''}
                          {!showPassword ? <img alt="eyeclose-icon" style={{ width: '20px', height: '20px', }} src={visibilityOffIcon}></img> : ''}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <Link onClick={() => {
                  window.location.href = `/forgetpassword`
                }} className="w-500" sx={{ color: '#2457B9', fontSize: 16, lineHeight: '22px', textDecoration: 'none', cursor: 'pointer' }}>
                  {translate('login-register.forget-password')}
                </Link>

                <Button
                  type="submit"
                  fullWidth
                  className="w-500"
                  // href="#"
                  variant="contained"
                  sx={{ mt: '52px', background: ' linear-gradient(270deg, #2457B9 15%, #2860CD 100%)', borderRadius: '8px', boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.12), 0px -1px 0px 0px rgba(0, 0, 0, 0.20) inset', padding: '12px 16px' }}
                >
                  <Typography className="w-500" style={{ textTransform: 'capitalize' }}>
                    {isLoadingLogin ? <CircularProgress
                      sx={{
                        color: (theme) =>
                          theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                      }}
                      size={20}
                      thickness={4}
                      value={100}
                    /> : translate('login-register.btn-masuk')}
                  </Typography>
                </Button>

                <Stack justifyContent={'center'} direction={'row'} sx={{ mt: '32px', mb: '32px' }} alignItems={'center'}>
                  <Divider style={{ backgroundColor: "#E8EBF2", width: '80px', height: '1px', }} />
                  <Typography className="w-500" align="center" sx={{ mr: '12px', ml: '12px', color: '#56617B', fontSize: '14px' }}> {translate('login-register.or')}
                  </Typography>
                  <Divider style={{ backgroundColor: "#E8EBF2", width: '80px', height: '1px', }} />
                </Stack>

                <Grid container spacing={2}>
                  <Grid item xs>
                    <Button
                      // type="submit"
                      onClick={(e) => loginWithGoogle()}
                      fullWidth
                      variant="outlined"
                      startIcon={<Box
                        component="img"
                        sx={{
                          width: 20,
                          height: 20,
                        }}
                        alt="logo-img"
                        src={Googleicon}
                      />}
                      sx={{
                        mt: 3, mb: 2, textTransform: "none", border: '1px solid var(--primarry-blue, #2457B9)',
                        p: '12px 16px',
                        borderRadius: '8px',
                        color: '#2457B9'
                      }}
                    >
                      <Typography
                        className="w-500"
                        sx={{ fontSize: 14, color: '#2457B9' }}

                      >
                        Google

                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item xs>
                    <Button
                      // type="submit"
                      onClick={(e) => loginWithFacebook()}
                      fullWidth
                      variant="outlined"
                      startIcon={<Box
                        component="img"
                        sx={{
                          width: 20,
                          height: 20,
                        }}
                        alt="logo-img"
                        src={Facebookicon}
                      />}
                      sx={{
                        mt: 3, mb: 2, textTransform: "none", border: '1px solid var(--primarry-blue, #2457B9)',
                        p: '12px 16px',
                        borderRadius: '8px',
                        color: '#2457B9'
                      }}
                    >
                      <Typography
                        className="w-500"
                        sx={{ fontSize: 14, color: '#2457B9' }}

                      >
                        Facebook

                      </Typography>

                    </Button>
                  </Grid>
                </Grid>
                <Stack direction={'row'} sx={{ mt: '50px', mb: '32px' }} justifyContent={{ xs: 'center', sm: 'center', md: 'left' }} alignItems={'center'}>
                  <Typography
                    className="w-500"
                    sx={{ fontSize: 14, color: '#56617B', mr: '8px' }}

                  >
                    {translate('login-register.btn-to-daftar')}

                  </Typography>
                  <Link href="/signup" className="w-700" sx={{ color: '#2457B9', fontSize: 14, textDecoration: 'none' }}>
                    {translate('login-register.btn-daftar')}
                  </Link>{" "}
                </Stack>

              </Box>
            ) : (
              <Box
                component="form"
                noValidate
                // onSubmit={(e) => loginWithPhoneNumber()}
                sx={{ mt: '32px', maxWidth: { lg: '430px', sm: '100%', xs: '100%' } }}
              >
                <Typography className="w-500" sx={{ color: '#081130', fontSize: 14, }}>
                  {translate('login-register.phone')}
                </Typography>
                <TextField
                  sx={{
                    mt: '4px', input: {
                      fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                  }}
                  className="box-input-email"
                  value={phoneNumber}
                  onChange={(e) => {
                    handleChangeNoHp(e.target.value)
                  }}
                  fullWidth
                  id="phone"
                  placeholder="xxxx-xxxx-xxxx"
                  name="phone"
                  autoComplete="phone"

                  InputProps={{
                    startAdornment: <InputAdornment position="start"> <Typography className="w-700" sx={{ color: '#56617B', fontSize: 14, mr: '24px' }}>
                      +62
                    </Typography> <VerticalDivider sx={{ ml: '12px' }} /> </InputAdornment>
                  }}
                />

                <Link onClick={() => {
                  window.location.href = `/forgetpassword`
                }} className="w-500" sx={{ color: '#2457B9', fontSize: 16, lineHeight: '22px', textDecoration: 'none', cursor: 'pointer' }}>
                  {translate('login-register.forget-password')}
                </Link>

                <Button
                  // type="submit1"
                  fullWidth
                  className="w-500"
                  // href="#"
                  onClick={() => loginWithPhoneNumberVerify()}
                  variant="contained"
                  sx={{ mt: '52px', background: ' linear-gradient(270deg, #2457B9 15%, #2860CD 100%)', borderRadius: '8px', boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.12), 0px -1px 0px 0px rgba(0, 0, 0, 0.20) inset', padding: '12px 16px' }}
                >
                  <Typography className="w-500" style={{ textTransform: 'capitalize' }}>
                    {isLoadingLogin ? <CircularProgress
                      sx={{
                        color: (theme) =>
                          theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                      }}
                      size={20}
                      thickness={4}
                      value={100}
                    /> : translate('login-register.btn-masuk')}
                  </Typography>
                </Button>

                <Stack justifyContent={'center'} direction={'row'} sx={{ mt: '32px', mb: '32px' }} alignItems={'center'}>
                  <Divider style={{ backgroundColor: "#E8EBF2", width: '80px', height: '1px', }} />
                  <Typography className="w-500" align="center" sx={{ mr: '12px', ml: '12px', color: '#56617B', fontSize: '14px' }}> {translate('login-register.or')}
                  </Typography>
                  <Divider style={{ backgroundColor: "#E8EBF2", width: '80px', height: '1px', }} />
                </Stack>

                <Grid container spacing={2}>
                  <Grid item xs>
                    <Button
                      // type="submit"
                      onClick={(e) => loginWithGoogle()}
                      fullWidth
                      variant="outlined"
                      startIcon={<Box
                        component="img"
                        sx={{
                          width: 20,
                          height: 20,
                        }}
                        alt="logo-img"
                        src={Googleicon}
                      />}
                      sx={{
                        mt: 3, mb: 2, textTransform: "none", border: '1px solid var(--primarry-blue, #2457B9)',
                        p: '12px 16px',
                        borderRadius: '8px',
                        color: '#2457B9'
                      }}
                    >
                      <Typography
                        className="w-500"
                        sx={{ fontSize: 14, color: '#2457B9' }}

                      >
                        Google

                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item xs>
                    <Button
                      // type="submit"
                      onClick={(e) => loginWithFacebook()}
                      fullWidth
                      variant="outlined"
                      startIcon={<Box
                        component="img"
                        sx={{
                          width: 20,
                          height: 20,
                        }}
                        alt="logo-img"
                        src={Facebookicon}
                      />}
                      sx={{
                        mt: 3, mb: 2, textTransform: "none", border: '1px solid var(--primarry-blue, #2457B9)',
                        p: '12px 16px',
                        borderRadius: '8px',
                        color: '#2457B9'
                      }}
                    >
                      <Typography
                        className="w-500"
                        sx={{ fontSize: 14, color: '#2457B9' }}

                      >
                        Facebook

                      </Typography>

                    </Button>
                  </Grid>
                </Grid>
                <Stack direction={'row'} sx={{ mt: '163px', mb: '32px' }} justifyContent={{ xs: 'center', sm: 'center', md: 'left' }} alignItems={'center'}>
                  <Typography
                    className="w-500"
                    sx={{ fontSize: 14, color: '#56617B', mr: '8px' }}

                  >
                    {translate('login-register.btn-to-daftar')}

                  </Typography>
                  <Link href="/signup" className="w-700" sx={{ color: '#2457B9', fontSize: 14, textDecoration: 'none' }}>
                    {translate('login-register.btn-daftar')}
                  </Link>{" "}
                </Stack>

              </Box>
            )}


          </Box> : null}
          {path.includes('forgetpassword') ? <Box
            sx={{
              mt: { xs: '24px', sm: '24px', md: '52px', lg: '52px' },
              ml: { xs: '16px', sm: '32px', md: '60px', lg: '120px' },
              mr: { xs: '16px', sm: '32px', },
              display: "flex",
              flexDirection: "column",

            }}
          >

            <Stack sx={{ mb: { lg: '141px', xs: '52px', sm: '52px', md: '52px' }, }} direction={'row'} alignItems={'center'}>
              <Box
                component="img"
                sx={{

                  height: 28,
                }}
                alt="logo-img"
                src={LogoIndomine}
              />
              <Typography className="w-700" sx={{ color: '#081130', fontSize: 24, ml: '10px' }}>
                IndoMine
              </Typography>

            </Stack>

            <Typography className="w-700" sx={{ color: '#081130', fontSize: 32, }}>
              {translate('login-register.forget-password')}
            </Typography>
            <Typography className="w-500" sx={{ color: '#56617B', fontSize: 16, mt: '12px', maxWidth: { lg: '430px', sm: '100%', xs: '100%' } }}>
              {translate('login-register.subtitle-forget')}
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={(e) => handleSubmit(e, "verification")}
              sx={{ mt: '32px', maxWidth: { lg: '430px', sm: '100%', xs: '100%' } }}
            >

              <Typography className="w-500" sx={{ color: '#081130', fontSize: 14, }} >
                Email
              </Typography>
              <TextField
                className="box-input-email"
                sx={{
                  mt: '4px', input: {
                    fontSize: '14px', fontFamily: 'Satoshi-500'
                  },
                }}
                fullWidth
                id="email"
                placeholder={translate('login-register.placeholder-email')}
                name="email"
                autoComplete="email"
                autoFocus
              />


              <Button
                type="submit"
                fullWidth
                variant="contained"
                className="w-500"
                sx={{
                  mt: 3, mb: 2, background: ' linear-gradient(270deg, #2457B9 15%, #2860CD 100%)', borderRadius: '8px', boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.12), 0px -1px 0px 0px rgba(0, 0, 0, 0.20) inset', padding: '12px 16px', textTransform: 'none'
                }}
                onClick={() => {
                  // handleUIcondition("newpassword");
                }}
              >
                {isLoadingForgotPassword ? <CircularProgress
                  sx={{
                    color: (theme) =>
                      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                  }}
                  size={20}
                  thickness={4}
                  value={100}
                /> : translate('login-register.btn-submit-email')}

              </Button>


              <Stack direction={'row'} sx={{ mt: { lg: '387px', xs: '357px', sm: '357px', md: '357px' }, mb: '32px' }} justifyContent={{ xs: 'center', sm: 'center', md: 'left' }} alignItems={'center'}>
                <Typography
                  className="w-500"
                  sx={{ fontSize: 14, color: '#56617B', mr: '8px' }}

                >
                  {translate('login-register.btn-to-daftar')}

                </Typography>
                <Link href="/signup" className="w-700" sx={{ color: '#2457B9', fontSize: 14, textDecoration: 'none' }}>
                  {translate('login-register.btn-daftar')}
                </Link>{" "}
              </Stack>

            </Box>
          </Box> : null}

        </Grid>

        <Hidden smDown>
          <Grid
            item
            xs={false}
            sm={false}
            md={6}
            lg={6}
            sx={{
              height: '100vh',
              backgroundImage: `linear-gradient(180deg, rgba(8, 34, 86, 0.6) 16.83%, rgba(8, 34, 86, 0) 60.9%), url(${heroLoginImg})`,
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <Typography className="w-700" sx={{ color: 'white', fontSize: 48, mt: '186px', ml: '61px', width: '600px', lineHeight: '72px' }}>
              {translate('login-register.hero-text1')} <br />  {translate('login-register.hero-text2')}
            </Typography>
            <Divider style={{ marginLeft: '61px', backgroundColor: "#E8EBF2", width: '80px', height: '3px', marginTop: '24px', }} />
          </Grid>
        </Hidden>
        {/* <div id="recaptcha-container"></div> */}
      </Grid>
    </ThemeProvider>
  )
}
