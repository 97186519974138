import { Box, Card, Grid, Hidden, Stack, TextField, Typography } from "@mui/material";
import { React, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import DrawerUploadDoc from '../../components/DrawerUploadDocSingleLayer';
import { DisplayPDFCompany } from '../DisplayPDFCompany';
import DropZoneUnggah from "../DropZoneUnggahSingle";
import ModalUnggahDoc from '../ModalUnggahDocBaruSingleLayer';
import IconInfo from "../../assets/icon-info.png";

export default function MenuFormAktaCompany(props) {
  const [translate] = useTranslation("global");

  console.log(props)

  const [validationNoAkta, setValidationNoAkta] = useState(false);
  const [validationDesc, setValidationDesc] = useState(false);
  const [validationFile, setValidationFile] = useState(false);

  useEffect(() => {

    if (props.triggerValidation >= 1) {

      if (!props.akta_nomor) {
        setValidationNoAkta(true)
      }

      if (!props.akta_description) {
        setValidationDesc(true)
      }

    }

  }, [props.triggerValidation])

  useEffect(() => {
    if (props.triggerValidation >= 1) {
      if (props.akta.length === 0) {
        setValidationFile(true)
      } else {
        setValidationFile(false)
      }
    }
    console.log(props)
  }, [props.triggerValidation, props.akta])

  return (
    <Grid item xs={12} sm={12} md={4} lg={4} className='form-input'>
      {props.akta.length === 0 ? (
        <></>
      ) : (
        <Box >
          <Stack direction={'row'} justifyContent={'space-between'}>
            <p className='c-08 s-1419 w-500 mb4 '>{translate('status.akta')}</p>

            <Typography sx={{ cursor: 'pointer' }} onClick={(e) => {
              props.handleOpenModalUploadAkta();
            }} className='s-1419 w-500 c-24 mb4 mr4 '>{translate('jual-beli.unggah-dokument')}</Typography>
          </Stack>

          <Grid style={{ marginTop: "5px", marginBottom: "10px" }} container direction={'row'}>
            {props.akta.map((item, i) => (
              <Grid key={item} item xs={12} sm={12} md={12} lg={12} >
                <Card sx={{ borderRadius: 2 }}>
                  <DisplayPDFCompany
                    url={`${item.url_display}`}
                    key={item}
                    ext={`${item.ext}`}
                    name={`${item.name}`}
                    delete={() => props.setUploadAkta([])}
                  />
                </Card>
              </Grid>
            ))}
          </Grid>
          <p className='c-08 s-1419 w-500 mb4'>{translate('status.noakta')}</p>
          <TextField type='number'
            sx={{
              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                display: "none",
              },
              "& input[type=number]": {
                MozAppearance: "textfield",
              }, width: '100%', mb: '24px', input: {
                fontSize: '14px', fontFamily: 'Satoshi-500'
              },
            }}
            placeholder={translate('status.noakta')}
            value={props.akta_nomor}
            // onChange={(e) => props.setakta_nomor(e.target.value)}
            error={validationNoAkta}
            onChange={(e) => {
              if (e.target.value && e.target.value !== "") {
                props.setakta_nomor(e.target.value)
                setValidationNoAkta(false);
              } else {
                props.setakta_nomor(e.target.value)
                setValidationNoAkta(true);
              }
            }}
          />
          <p className='c-08 s-1419 w-500 mb4 '>{translate('detail-explore.deskripsi')}</p>
          <TextField className='box-deskripsi-company' multiline rows={3}
            error={validationDesc}
            onChange={(e) => {
              if (e.target.value && e.target.value !== "") {
                props.setakta_description(e.target.value)
                setValidationDesc(false);
              } else {
                props.setakta_description(e.target.value)
                setValidationDesc(true);
              }
            }}
            value={props.akta_description}
            sx={{
              width: '100%', p: 0, input: {
                fontSize: '14px', fontFamily: 'Satoshi-500'
              },
            }} placeholder={"Tulis deskripsi..."}
          />
        </Box>
      )}
      {props.akta.length === 0 &&
        <>
          <Box>
            <p className='c-08 s-1419 w-500 mb4 '>{translate('status.akta')}</p>
            <DropZoneUnggah handleUpload={props.handleCloseModalUploadAkta} />
          </Box>
          {validationFile ? (
            <Box sx={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
              <img alt="Nikel-icon" src={IconInfo} height={20}></img>
              <p className="s-1419 w-500" style={{ marginLeft: "5px", color: "#D32F2F" }}>
                {translate("title.validation_file")}
              </p>
            </Box>
          ) : (
            <></>
          )}

        </>
      }
      <Hidden lgDown><ModalUnggahDoc open={props.isOpenUploadAkta} close={props.handleCloseModalUploadAkta}></ModalUnggahDoc>
      </Hidden>
      <Hidden lgUp><DrawerUploadDoc open={props.isOpenUploadAkta} close={props.handleCloseModalUploadAkta}></DrawerUploadDoc>
      </Hidden>

    </Grid>
  );
}