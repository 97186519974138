import CloseIcon from '@mui/icons-material/Close';
import { Button, Grid, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import unpublishListingIcon from "../assets/unpublish-listing-ic.svg";
import { editBatubara, editNikel, editTongkang } from "../services/api";

export default function ModalUnpublish(props) {
    const [translate,] = useTranslation("global");
    const navigate = useNavigate()

    const Unpublish = async (id, page_detail) => {
        console.log(page_detail)
        if (page_detail === "coal") {
            try {
                let params = {
                    status_listing: "2",
                    token: localStorage.getItem("token")
                }
                const responAPI = await editBatubara(params, id)
                if (responAPI.status === 200) {
                    navigate(`/listing`, { state: { detail: 'coal' } })
                    window.location.reload()
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: translate('status.failed'),
                        text: translate('status.subtitlefailed'),
                    })
                }
            } catch (error) {
                console.log(error)
            }
        } else if (page_detail === 'nikel') {
            try {
                let params = {
                    status_listing: "2",
                    token: localStorage.getItem("token")
                }
                const responAPI = await editNikel(params, id)
                if (responAPI.status === 200) {
                    navigate(`/listing`, { state: { detail: 'nikel' } })
                    window.location.reload();
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: translate('status.failed'),
                        text: translate('status.subtitlefailed'),
                    })
                }


            } catch (error) {
                console.log(error)
            }
        } else {
            try {
                let params = {
                    status_listing: "2",
                    token: localStorage.getItem("token")
                }
                const responAPI = await editTongkang(params, id, [], [])
                if (responAPI.status === 200) {
                    navigate(`/listing`, { state: { detail: 'tongkang' } })
                    window.location.reload();

                } else {
                    Swal.fire({
                        icon: 'error',
                        title: translate('status.failed'),
                        text: translate('status.subtitlefailed'),
                    })
                }

            } catch (error) {
                console.log(error)
            }
        }

    }
    const Publish = async (id, page_detail) => {
        if (page_detail === "coal") {
            try {
                let params = {
                    status_listing: "1",
                    token: localStorage.getItem("token")
                }
                const responAPI = await editBatubara(params, id)


                if (responAPI.status === 200) {
                    navigate(`/listing`, { state: { detail: 'coal' } })
                    window.location.reload();

                } else {
                    Swal.fire({
                        icon: 'error',
                        title: translate('status.failed'),
                        text: translate('status.subtitlefailed'),
                    })
                }

            } catch (error) {
                console.log(error)
            }
        } else if (page_detail === 'nikel') {
            try {
                let params = {
                    status_listing: "1",
                    token: localStorage.getItem("token")
                }
                const responAPI = await editNikel(params, id)


                if (responAPI.status === 200) {
                    navigate(`/listing`, { state: { detail: 'nikel' } })
                    window.location.reload();

                } else {
                    Swal.fire({
                        icon: 'error',
                        title: translate('status.failed'),
                        text: translate('status.subtitlefailed'),
                    })
                }

            } catch (error) {
                console.log(error)
            }
        } else {
            try {
                let params = {
                    status_listing: "1",
                    token: localStorage.getItem("token")
                }
                const responAPI = await editTongkang(params, id, [], [])

                if (responAPI.status === 200) {
                    navigate(`/listing`, { state: { detail: 'tongkang' } })
                    window.location.reload();

                } else {
                    Swal.fire({
                        icon: 'error',
                        title: translate('status.failed'),
                        text: translate('status.subtitlefailed'),
                    })
                }

            } catch (error) {
                console.log(error)
            }
        }
    }
    return (
        <div>
            <Modal
                open={props.open}
                onClose={props.close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    width: { xs: '304px', sm: 343, md: 343, lg: 415, xl: 415 },
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: '8px',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: { xs: '16px', sm: '24px', md: '24px', lg: '24px', xl: '24px' },
                }}>
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <IconButton onClick={() => {
                            props.close();
                        }} size="small" >
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div style={{ justifyContent: 'center', display: 'flex' }}>
                        <img alt="hubungitrader-icon" style={{ width: '140px', height: '140px', }} src={unpublishListingIcon}></img>
                    </div>
                    {props.status === 'unpublish' ? <Typography id="modal-modal-title" className='w-700' sx={{ fontSize: '20px', color: '#081130', textAlign: 'center', mt: '24px' }}>
                        Unpublish Listing
                    </Typography> : <Typography id="modal-modal-title" className='w-700' sx={{ fontSize: '20px', color: '#081130', textAlign: 'center', mt: '24px' }}>
                        Publish Listing
                    </Typography>}
                    {props.status === 'unpublish' ?
                        <Typography id="modal-modal-description" className='w-500' sx={{ mt: '16px', color: '#56617B', fontSize: '16px', textAlign: 'center' }}>
                            {translate('status.titleunpublishlisting')}
                        </Typography> : <Typography id="modal-modal-description" className='w-500' sx={{ mt: '16px', color: '#56617B', fontSize: '16px', textAlign: 'center' }}>
                            {translate('status.titlepublishlisting')}
                        </Typography>}
                    <Grid spacing={2} container justifyContent='space-between' direction='row' sx={{ mb: '16px', mt: '36px' }}>
                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                size="large"
                                className="w-500"
                                variant="outlined"
                                onClick={() => {
                                    props.close();
                                }}
                                style={{
                                    color: '#2457B9',
                                    textTransform: 'none',
                                    paddingTop: '12px',
                                    paddingBottom: '12px'
                                }}
                            >
                                {translate('jual-beli.btn-cancel')}
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            {props.status === 'unpublish' ? <Button
                                fullWidth
                                onClick={(e) => {
                                    props.close();
                                    Unpublish(props.id, props?.page_detail ?? props?.type)
                                }}
                                size="large"
                                className="w-500"
                                variant="contained"
                                style={{
                                    background: '#ED3131',
                                    textTransform: 'none',
                                    paddingTop: '12px',
                                    paddingBottom: '12px'
                                }}
                            >
                                Unpublish
                            </Button> : <Button
                                fullWidth
                                onClick={(e) => {
                                    props.close();
                                    Publish(props.id, props.page_detail)
                                }}
                                size="large"
                                className="w-500"
                                variant="contained"
                                style={{
                                    background: '#2457B9',
                                    textTransform: 'none',
                                    paddingTop: '12px',
                                    paddingBottom: '12px'
                                }}
                            >
                                Publish
                            </Button>}
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}