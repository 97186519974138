import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Button, CircularProgress, Collapse, FormControlLabel, Grid, Hidden, InputAdornment, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import tongkang_icon from "../assets/tongkang.svg";
import DrawerHapusDoc from '../components/DrawerHapusDoc';
import Footer from "../components/Footer";
import FormProvince from '../components/FormProvince';
import ModalBatalForm from '../components/ModalBatalForm';
import NavBar from "../components/Navbar";
import { GetValidationPriceQuantity, addBuatPermintaanTongkang } from '../services/api';
import { GlobalState } from "../services/global";
import { ArrowDownPriceComponent, VerticalDividerQuantity } from '../services/primary';

export default function BeliTongkang() {
  const navigate = useNavigate();
  const [translate,] = useTranslation("global");
  const [isLoading, setIsLoading] = useState(false)
  const [openUkuran, setOpenUkuran] = useState(false);
  const [ukuranTongkangValue, setUkuranTongkangValue] = useState('');
  const [openJenis, setOpenJenis] = useState(false);
  const [jenisTongkangValue, setJenisTongkangValue] = useState('');
  const [typeTongkangValue, setTypeTongkangValue] = useState(4);
  const [openType, setOpenType] = useState(false);
  let path = window.location.pathname
  // const [id_loading_port_muat, setPortMuat] = useState('')
  const [id_tongkang_location, setid_tongkang_location] = useState('')
  // const [id_loading_port_bongkar, setPortBongkar] = useState('')
  const { currency, setcurrency } = React.useContext(GlobalState)
  // const [id_bendera, setBendera] = useState('')

  const [selectukurantongkang, setselectukurantongkang] = useState('')
  const [selectjenismuatan, setselectjenismuatan] = useState('')
  const [selectrenttype, setselectrenttype] = useState('')

  const [isOpenDrawerDoc, setOpenDrawerDoc] = React.useState(false)
  const [validationPriceQty, setvalidationPriceQty] = useState('')
  const [openModalBatalForm, setOpenModalBatalForm] = useState(false);
  const [selectcurrency, setselectcurrency] = React.useState('idr');
  const [listCurrency] = useState([{ id: 'idr', name: 'Rp' }, { id: 'usd', name: '$' }])

  const handleOpenBatalForm = () => {
    setOpenModalBatalForm(true)
  };

  const handleCloseModal = () => {
    setOpenModalBatalForm(false);
  };

  useEffect(() => {
    GetValidation()
    // typePage()
  }, []);

  // function typePage() {
  //   if (path.includes('beli')) {
  //     setOpenType(false)
  //     setTypeTongkangValue(4)
  //   } else {
  //     setOpenType(true)
  //     setTypeTongkangValue(2)
  //   }
  // }


  async function GetValidation(currency) {
    const data = {
      currency: currency
    }
    try {
      const response = await GetValidationPriceQuantity(data)
      setvalidationPriceQty(response.data?.data);
    } catch (e) {
      console.log(e);
    }
  }

  // const handleChangeBendera = (e) => {
  //   setBendera(e.id)
  // }

  const handleChangeCurrency = (e) => {
    if (e.target.value === 'idr') {
      setPrice((price * validationPriceQty.usd_idr));
    } else {
      setPrice((price / validationPriceQty.usd_idr))
    }
    setselectcurrency(e.target.value)
    GetValidation(e.target.value)
  };

  const handleChangeRadioUkuran = (event, i) => {
    if (event.target.value === 'Lainnya') {
      setOpenUkuran(true)
      setselectukurantongkang(event.target.value)
    } else {

      setselectukurantongkang(event.target.value)
      setOpenUkuran(false)
      setUkuranTongkangValue(event.target.value)
    }
  };

  const currencies = [
    {
      value: 'USD',
      label: '$',
    },
    {
      value: 'EUR',
      label: '€',
    },
    {
      value: 'BTC',
      label: '฿',
    },
    {
      value: 'JPY',
      label: '¥',
    },
  ];


  const handleChangeRadioJenis = (event, i) => {
    if (event.target.value === 'Lainnya') {
      setselectjenismuatan(event.target.value)
      setOpenJenis(true)

    } else {
      setselectjenismuatan(event.target.value)
      setOpenJenis(false)

      setJenisTongkangValue(event.target.value)
    }
  };

  const handleChangeRadioTypeSewa = (event, i) => {
    if (event.target.value === 'Time charter') {
      setselectrenttype(event.target.value)
      setTypeTongkangValue(2)
    } else {
      setselectrenttype(event.target.value)
      setTypeTongkangValue(3)
    }
  };

  const [file, setFile] = useState([]);
  function handleChangeFileFromDeleteResponsive(fileQ, y) {
    setFile(fileQ)
  }


  const handleCloseDrawerDoc = () => {
    setOpenDrawerDoc(false);
  };

  const [price, setPrice] = useState('');
  const [description] = useState('');
  const [tahun_buat, setTahun_buat] = useState('');
  const [quantity_muatan, setQuantityMuatan] = useState('');
  // const [benderaName, setBenderaName] = useState('');
  const [pelabuhanMuat, setPelabuhanMuat] = useState('');
  const [pelabuhanBongkar, setPelabuhanBongkar] = useState('');
  const [validationUkuranTongkang, setValidationUkuranTongkang] = useState(false);
  const [validationJenisMuatan, setValidationJenisMuatan] = useState(false);
  const [validationQuantity, setValidationQuantity] = useState(false);
  const [validationprice, setvalidationprice] = useState(false)
  const [LokasiTongkangName, setLokasiTongkangName] = useState('');

  const submit = async () => {
    if (ukuranTongkangValue === '' || price === '') {
      ukuranTongkangValue === '' ? setValidationUkuranTongkang(true) : setValidationUkuranTongkang(false)

      price === '' ? setvalidationprice(true) : setvalidationprice(false)
      Swal.fire({
        icon: 'error',
        title: translate('status.sorry'),
        text: translate('status.Fieldkosong'),
      })
    }

    else if (price > validationPriceQty.maximum_prices_for_tongkang || price < validationPriceQty.minimum_prices_for_tongkang) {
      setvalidationprice(true)

      Swal.fire({
        icon: 'error',
        title: translate('status.sorry'),
        text: translate('status.errorpricelimit'),
      })

    } else {
      setIsLoading(true)
      let dataForm = {
        quantity_muatan: "",
        type_tongkang: 4,
        price: price,
        id_pelabuhan_muat: "",
        id_pelabuhan_bongkar: "",
        id_tongkang_location: id_tongkang_location,
        tahun_buat: tahun_buat,
        jenis_muatan: "",
        ukuran_tongkang: ukuranTongkangValue,
        // path_documents: JSON.stringify(file),
        currency: selectcurrency
      }

      let respone = await addBuatPermintaanTongkang(dataForm, [])

      if (respone.status === 200) {
        setIsLoading(false)
        setcurrency(selectcurrency);
        localStorage.setItem('currency', selectcurrency);
        navigate(`/penawaran`, {
          state: {
            detail: 'tongkang',
            request_data: {
              tongkang_location_name: LokasiTongkangName,
              // quantity_muatan: "",
              type_tongkang: typeTongkangValue,
              price: price,
              // id_pelabuhan_muat: "",
              // id_pelabuhan_bongkar: "",
              // pelabuhan_muat_name: pelabuhanMuat,
              // pelabuhan_bongkar_name: pelabuhanBongkar,
              // description: description,
              tahun_buat: tahun_buat,
              // jenis_muatan: jenisTongkangValue,
              ukuran_tongkang: ukuranTongkangValue.replace("FT", ""),
              currency: selectcurrency
            },
            request: true
          }
        })
      } else if (respone.status === 403) {
        setIsLoading(false)
        window.location.href = "/signin"
      } else {
        Swal.fire({
          icon: 'error',
          title: translate('status.sorry'),
          text: translate('status.subtitlefailed'),
        })
      }

      setIsLoading(false)
    }

  };

  const size_list = ["180 FT", "200 FT", "250 FT", "230 FT", "270 FT", "300 FT", "310 FT", "320 FT", "330 FT", translate('status.lainnya')]
  // const payload_type = [translate('page_explore.batubara'), translate('page_explore.nikel'), translate('page_explore.bauksit'), translate('page_explore.pasirsilka'), translate('page_explore.batusplit'), translate('page_explore.batukapur'), translate('page_explore.pasirbesi'), translate('status.lainnya')]
  const price_list = ["Time charter", "Freight charter"]
  const currentYear = (new Date()).getFullYear();
  const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));
  const options = range(currentYear, currentYear - 25, -1)

  return (
    <div>
      <div className='fixed-header'>
        <NavBar /></div>
      <Box className="flex-wrap-center width-100" >
        <Box className="content-inner" sx={{ mt: { xs: 6, sm: 6, md: 11, lg: 11, xl: 11 }, ml: { xs: '24px', lg: '80px', }, mr: { xs: '24px', lg: '80px', }, }}>
          {/* navigation Section */}
          <Grid container direction="row" alignItems='center' className='mt-18'>
            <Button href="/" className="w-500 mb8 c-24 txt-none-tranform" sx={{ fontSize: { xs: 14, sm: 14, md: 16, lg: 16 } }} variant="text"> {translate('navbar.home')}</Button>

            <Typography className="w-500 mb8 c-56" sx={{ fontSize: { xs: 14, sm: 14, md: 16, lg: 16 } }}>
              /
            </Typography>
            <Button href="/explore" className="w-500 mb8 c-24 txt-none-tranform" sx={{ fontSize: { xs: 14, sm: 14, md: 16, lg: 16 } }} variant="text"> {translate('navbar.explore')}</Button>

            <Typography className="w-500 mb8 c-56 mr8" sx={{ fontSize: { xs: 14, sm: 14, md: 16, lg: 16 } }}>
              /
            </Typography>
            {path.includes('beli') ? <Typography className="w-500 c-56 mb8" sx={{ fontSize: { xs: 14, sm: 14, md: 16, lg: 16 } }}>
              {translate('permintaan.btn-beli-tongkang')}
            </Typography> : <></>}
            {/* {path.includes('sewa') ? <Typography className="w-500 c-56 mb8" sx={{ fontSize: { xs: 14, sm: 14, md: 16, lg: 16 } }}>
              {translate('permintaan.btn-sewa-tongkang')}
            </Typography> : <></>} */}
          </Grid>
          {/* header Section */}
          <Grid container direction="column" alignItems="center" justifyContent="center" className='mt-18'>
            <img src={tongkang_icon} alt='' />
            {path.includes('beli') ? <h3 className='w-700 c-08 mt-12'>  {translate('permintaan.btn-beli-tongkang')}</h3> : <></>}
            {/* {path.includes('sewa') ? <h3 className='w-700 c-08 mt-12'>{translate('permintaan.btn-sewa-tongkang')}</h3> : <></>} */}

            <p className='s-1622 mt-12 w-500 c-56 mb-52'></p>
          </Grid>
          {/* Form Spesifikasi Tongkang Section */}
          <Grid container direction='row' justifyContent='space-between'>
            <Grid item xs={12} sm={12} md={3} lg={3} >
              <Typography className="w-700" sx={{ fontSize: 24, mb: '16px', color: '#081130' }}>
                {translate('jual-beli.spesifikasi-tongkang')}
              </Typography>
              <p className='c-56 w-400 s-1421 mb-16'>{translate('jual-beli.subtitle-spesifikasi-tongkang')}</p>
            </Grid>
            {/* Form Section */}
            <Grid item xs={12} sm={12} md={4} lg={4} className='form-input'>
              {/* Form Ukuran Tongkang Section */}
              <p className='c-08 s-1419 w-500 mb4'>{translate('page_explore.ukurantongkang')}</p>
              <Grid container spacing={2} direction={'row'} className='box-group-select'>
                {size_list.map((item, i) => (
                  <Grid key={item} item xs={6}>
                    <div className={selectukurantongkang.includes(item) && validationUkuranTongkang === false ? 'form-radio-select' : validationUkuranTongkang === true ? 'form-radio-error' : 'form-radio'}>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group">
                        <FormControlLabel key={i}
                          onChange={(e) => handleChangeRadioUkuran(e, i)} value={item} control={<Radio error={validationUkuranTongkang} checked={selectukurantongkang.includes(item)} sx={{
                            color: "#E8EBF2",
                            '&.Mui-checked': { color: "#3BB56C", },
                          }} />} label={<Typography className='w-400' sx={{ fontSize: 14, color: '#081130' }}>{item}</Typography>} className='w-500 s-1419' />
                      </RadioGroup>
                    </div>
                  </Grid>
                ))}
              </Grid>

              <Collapse in={openUkuran} timeout="auto"
                unmountOnExit>
                <TextField
                  className="box-input-filter"

                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    setUkuranTongkangValue(e.target.value)
                    // if (value > 500) {
                    //   setValidationUkuranTongkang(true)
                    // } else {
                    //   setValidationUkuranTongkang(false)
                    // }
                  }}
                  sx={{
                    width: '100%', marginBottom: "14px", input: {
                      fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                  }}
                  id="outlined-adornment-weight"
                  aria-describedby="outlined-weight-helper-text"
                  placeholder={translate('status.placeholderukurantongkang')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" >
                        <VerticalDividerQuantity />
                        <Typography className="w-700 s-1420 c-56">
                          FT
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Collapse>

            </Grid>
            <Grid item xs={3} className='wd-252'>
            </Grid>
          </Grid>

          <Grid container className='line mt-24 mb-32'>

          </Grid>
          {/* Form Detail Tongkang Section */}
          <Grid container direction='row' justifyContent='space-between'>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Typography className="w-700 s-2422 mb-16 c-08">{translate('jual-beli.detail-tongkang')}</Typography>
              <p className='c-56 w-400 s-1421 mb-16'>{translate('jual-beli.subtitle-detail-tongkang')}</p>
            </Grid>
            {/* Form Section */}
            <Grid item xs={12} sm={12} md={4} lg={4} className='form-input'>
              {/* Form Harga Section */}
              {openType === true ? (
                <p className='c-08 s-1419 w-500 mb4 mt-10'>{translate('jual-beli.hargasewatongkang')}</p>
              ) : (
                <p className='c-08 s-1419 w-500 mb4 mt-10'>{translate('page_explore.hargatongkang')}</p>
              )}

              <Collapse in={openType} timeout="auto"
                unmountOnExit>
                <Grid container spacing={2} direction={'row'} className='box-group-select'>
                  {price_list.map((item, i) => (
                    <Grid key={item} item xs={6}>
                      <div className={selectrenttype.includes(item) ? 'form-radio-select' : 'form-radio'}>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                        >
                          <FormControlLabel key={i}
                            onChange={(e) => handleChangeRadioTypeSewa(e, i)} value={item} control={<Radio checked={selectrenttype.includes(item)} sx={{
                              color: "#E8EBF2",

                              '&.Mui-checked': { color: "#3BB56C", },
                            }} />} label={<Typography className='w-400' sx={{ fontSize: 14, color: '#081130' }}>{item}</Typography>} className='w-500 s-1419' />
                        </RadioGroup>
                      </div>
                    </Grid>
                  ))}
                </Grid>

              </Collapse>
              <TextField
                className='box-input-form'
                id="outlined-start-adornment" sx={{
                  width: '100%',
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                    display: "none",
                  },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  }, input: {
                    fontSize: '14px', fontFamily: 'Satoshi-500'
                  },
                }}
                type='number'
                value={price}
                error={validationprice}
                helperText={validationprice ? translate('status.limitharga') + ' ' + validationPriceQty.minimum_prices_for_tongkang.toString() + ' - ' + validationPriceQty.maximum_prices_for_tongkang.toString() : ''}
                onChange={(e) => {
                  const value = parseInt(e.target.value);
                  setPrice(value);
                  if (value > parseInt(validationPriceQty.maximum_prices_for_tongkang)) {
                    setvalidationprice(true)
                  } else if (value < parseInt(validationPriceQty.minimum_prices_for_tongkang)) {
                    setvalidationprice(true)
                  } else {
                    setvalidationprice(false)
                  }
                }}
                placeholder={validationPriceQty.minimum_prices_for_tongkang + '-' + validationPriceQty.maximum_prices_for_tongkang}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Select
                        disableUnderline
                        variant='standard'
                        className='box-form-currency'
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              height: '120px',
                              '& .MuiMenuItem-root': {
                              },
                            },
                          },
                        }}
                        sx={{
                          "& fieldset": { border: 'none' },
                          boxShadow: "none",
                          ".MuiOutlinedInput-notchedOutline": { border: 0 },
                          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            border: 0,
                          },
                          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            border: 0,
                          },
                        }}
                        IconComponent={ArrowDownPriceComponent}
                        value={selectcurrency} onChange={(e) =>
                          handleChangeCurrency(e)
                        } displayEmpty >
                        {listCurrency.map((item, i) => (
                          <MenuItem key={item.id} value={item.id}>{item.name} </MenuItem>
                        ))}
                      </Select>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end" >
                      <VerticalDividerQuantity />
                      <Typography className="w-700 s-1420 c-56">
                        /Set
                      </Typography>
                    </InputAdornment>
                  ),
                }}
              />
              {/* Form Loading Port Muat Section */}
              <p className='c-08 s-1419 w-500 mb4 mt-24'>{translate('title.location-tongkang')}</p>
              <FormProvince select={(e) => {
                setid_tongkang_location(e.id)
                setLokasiTongkangName(e.attributes.name)

              }
              } selectName={(e) => { }} currentValue={id_tongkang_location} placeholder={translate('title.placeholder-select-location')} />

              {/* Form Tahun Buat Section */}
              <p className='c-08 s-1419 w-500 mb4 mt-24'>{translate('permintaan.tahunbuat')}</p>

              <div>
                <TextField
                  className='box-input-form'
                  displayEmpty={true}

                  id="standard-select-currency" sx={{
                    width: '100%',
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                      display: "none",
                    },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    }, input: {
                      fontSize: '14px', fontFamily: 'Satoshi-500'
                    },
                  }}
                  placeholder={translate('permintaan.placeholder-tahunbuat')}
                  select

                  SelectProps={{
                    MenuProps: {
                      sx: { maxHeight: 200 }
                    },
                    IconComponent: () => null
                  }}
                  value={tahun_buat} onChange={(e) => setTahun_buat(e.target.value)}
                  defaultValue={0}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" >

                        <Typography sx={{ ml: 2 }} className="w-700 s-1420 c-56">
                          {translate('title.lebihdari')}
                        </Typography>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end" >
                        <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
                      </InputAdornment>
                    ),

                  }}
                >



                  {options.map((item, i) => (
                    <MenuItem value={item} key={i}>{item} </MenuItem>))}
                </TextField>
                {tahun_buat === "" && <p style={{ position: "absolute", top: "53.5%", left: "46%", zIndex: 999 }} className='w-400 s-1420 c-87'>{translate('permintaan.tahun')}</p>}
              </div>


            </Grid>

            <Grid item xs={3} className='wd-252'>
            </Grid>
          </Grid>

          <Grid container className='line mt-24 mb-56'>

          </Grid>
          {/* Button Section */}
          <Hidden smDown>
            <Grid className='mb-104 in-container bt-explore' container justifyContent='flex-end' direction='row'>
              <button onClick={() => {
                handleOpenBatalForm();
              }} className='pointer w-500 s-1622 mr-16 wd-160 c-24 bg-ff'>{translate('jual-beli.btn-cancel')}</button>
              <button onClick={(e) => { submit() }} className='pointer w-500 s-1622 wd-180 c-ff'>
                {isLoading ? (<CircularProgress
                  sx={{
                    color: (theme) =>
                      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                  }}
                  size={20}
                  thickness={4}
                  value={100}
                />) : <>{translate('page_explore.search-tongkang')}</>}
              </button>
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Grid spacing={1} container justifyContent='space-between' direction='row' style={{ marginBottom: '16px' }}>
              <Grid item xs={6}>
                <Button
                  onClick={() => {
                    handleOpenBatalForm();
                  }}
                  fullWidth
                  size="large"
                  className="button-blue-outlined w-500 wd-180 pointer"
                  variant="outlined"

                >
                  {translate('jual-beli.btn-cancel')}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  onClick={(e) => { submit() }}
                  size="large"
                  className="button-blue-contained w-500 pointer"
                  variant="contained"

                >
                  {isLoading ? (<CircularProgress
                    sx={{
                      color: (theme) =>
                        theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                    }}
                    size={20}
                    thickness={4}
                    value={100}
                  />) : <>{translate('page_explore.search-tongkang')}</>}

                </Button>
              </Grid>
            </Grid>
          </Hidden>
          <DrawerHapusDoc title={'jual-beli.dokumen-tongkang'} file_doc={file} submit={(e, y) => { handleChangeFileFromDeleteResponsive(e, y) }} open={isOpenDrawerDoc} close={handleCloseDrawerDoc} ></DrawerHapusDoc>
        </Box>
      </Box>
      <Footer />
      <ModalBatalForm open={openModalBatalForm} close={handleCloseModal}></ModalBatalForm>
    </div>
  )
}
