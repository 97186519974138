import { Grid, Hidden, Stack } from '@mui/material';
import { useEffect, useState } from "react";
import { useDropzone } from 'react-dropzone';
import { useTranslation } from "react-i18next";
import doc_icon from "../assets/doc.svg";
import DrawerUploadDoc from './DrawerUploadDocSingle';
import ModalUnggahDoc from './ModalUnggahDocBaruSingle';
// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: '792px', // Lebar maksimum
//     borderRadius: '8px',
//     bgcolor: 'background.paper',
//     boxShadow: 24,
//     p: 4,
//     "&:focus": {
//         outline: "none"
//     },
// };

export default function DropZoneUnggahSingle(props) {

    const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'application/pdf': ['.pdf'],
            // 'application/msword': ['.doc', '.docx', '.xlsx']
        },
        minSize: 0,
        maxSize: 24000000,
        multiple: false
    });

    useEffect(() => {
        setFileDocument([]);
    }, [])

    const [translate,] = useTranslation("global");
    const [fileDocument, setFileDocument] = useState([]);
    const [isOpenModal, setIsOpenModal] = useState(false);

    useEffect(() => {
        if (acceptedFiles?.length !== 0) {
            setFileDocument(acceptedFiles);
            setIsOpenModal(true)
        }
    }, [acceptedFiles])

    return (
        <div>
            <div {...getRootProps()}>
                <Grid style={{ border: `${isDragActive ? "3px dashed #2457B9" : "3px dashed #E8EBF2"}` }} className='upload-doc pointer' container direction='column' justifyContent='center' alignItems='center' alignContent='center'>
                    <img src={doc_icon} alt='' />
                    <Stack direction='row'>
                        <p className='s-1419 w-500 mt-12 c-24 mb4 mr4 '>Unggah dokumen</p>
                        <p className='s-1419 w-500 mt-12 c-08 mb4 '> atau drag and drop</p>
                    </Stack>
                    <p className='s-1216 w-500 c-56'>{translate('jual-beli.note-dokumen')}</p>
                </Grid>
                <input {...getInputProps()}
                // onChange={(e) => handleChangeFileDocument(e)}
                // type="file" style={{ display: "none" }} accept=".doc,.docx,.xlsx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
                />
            </div>
            <Hidden lgDown><ModalUnggahDoc file={fileDocument} open={isOpenModal} close={props.handleUpload} closeModal={() => setIsOpenModal(false)}></ModalUnggahDoc>
            </Hidden>

            <Hidden lgUp><DrawerUploadDoc file={fileDocument} open={isOpenModal} close={props.handleUpload} closeModal={() => setIsOpenModal(false)}></DrawerUploadDoc>
            </Hidden>
        </div >

    );
}