import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Button, FormControl, Grid, InputAdornment, MenuItem, Select, TextField, Typography } from "@mui/material";
import React from 'react';
import { useTranslation } from "react-i18next";
import { GlobalState } from "../services/global";
import { VerticalDividerLeft } from '../services/primary';

export default function MenuPenawaranFilterTongkang(props) {
  const [translate,] = useTranslation("global");
  const { currency } = React.useContext(GlobalState)


  return (
    <Box>
      <Box sx={{ border: 1, color: '#E8EBF2', borderRadius: 2, }}>
        <Grid
          container
          direction="row"
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{ pl: '16px', pt: '19px', pr: '16px', pb: '19px' }}
        >
          <Typography className="w-700" sx={{ fontSize: 18, color: '#081130' }}>
            Filter
          </Typography>
          <Button className="w-500" onClick={(e) => props.resetFilterTongkang(e)} sx={{ fontSize: 16, color: '#56617B', textTransform: 'none' }} variant="text"> Reset filter</Button>
        </Grid>

        <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
          <Typography className="w-500" sx={{ fontSize: 16, color: '#081130', mb: '2px' }}>
            {translate('page_explore.spesifikasi')}
          </Typography>
          <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
            {translate('page_explore.ukurantongkang')}
          </Typography>
          <FormControl sx={{ mt: 1, width: '100%' }}>
            <Select
              IconComponent={() => (
                <KeyboardArrowDownIcon sx={{ mr: '16px', width: '20px', height: '20px', fill: '#56617B' }} />
              )}
              style={{ border: '1px solid var(--grey-stroke, #e8ebf2)', marginTop: '4px', borderRadius: '8px', }}
              onChange={(e) => props.handleFilterTongkang(e.target.value, "ukuran_tongkang")}
              value={props.filterTongkang["ukuran_tongkang"]}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="">
                <Typography className="w-400" style={{ color: '#8792AB', fontSize: 14 }}> {translate('page_explore.pilihukurantongkang')}</Typography>
              </MenuItem>
              <MenuItem value={"180 FT"}>180 feet</MenuItem>
              <MenuItem value={"200 FT"}>200 feet</MenuItem>
              <MenuItem value={"230 FT"}>230 feet</MenuItem>
              <MenuItem value={"250 FT"}>250 feet</MenuItem>
              <MenuItem value={"300 FT"}>300 feet</MenuItem>
              <MenuItem value={"310 FT"}>310 feet</MenuItem>
              <MenuItem value={"320 FT"}>320 feet</MenuItem>
              <MenuItem value={"330 FT"}>330 feet</MenuItem>
              <MenuItem value={0}>{translate('status.lainnya')}</MenuItem>
            </Select>
            {props.inputukurangtongkang === true || props.filterTongkang["ukuran_tongkang_lainnya"] === true ? <TextField
              className="box-input-filter"
              type="text"
              size="small"
              placeholder={translate('status.placeholderukurantongkang')}
              onChange={(e) => props.handleFilterUkuranTongkang(e.target.value, "ukuran_tongkang")}
              value={props.filterTongkang["ukuran_tongkang"]}
              sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                  display: "none",
                },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
                width: '100%', input: {
                  fontSize: '14px', fontFamily: 'Satoshi-500'
                },
              }}
            /> : ''}
          </FormControl>
        </Box>

        {/* <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
          <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '6px' }}>
            {translate('page_explore.quantitymuatan')}
          </Typography>
          <Grid
            container
            direction="row"
            justifyContent={'space-between'}
            spacing={2}
          >
            <Grid item xs={6}>
              <TextField
                className="box-input-filter"
                type="number"
                size="small"
                placeholder="Min"
                onChange={(e) => props.handleFilterTongkang(e.target.value, "min_quantity_muatan")}
                value={props.filterTongkang["min_quantity_muatan"]}
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                    display: "none",
                  },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                  width: '100%', input: {
                    fontSize: '14px', fontFamily: 'Satoshi-500'
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="box-input-filter"
                type="number"
                size="small"
                placeholder="Maks"
                onChange={(e) => props.handleFilterTongkang(e.target.value, "max_quantity_muatan")}
                value={props.filterTongkang["max_quantity_muatan"]}
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                    display: "none",
                  },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                  width: '100%', input: {
                    fontSize: '14px', fontFamily: 'Satoshi-500'
                  },
                }}
              />
            </Grid>
          </Grid>
        </Box> */}

        <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
          <Typography className="w-500" sx={{ fontSize: 14, color: '#081130', mb: '2px' }}>
            {translate('page_explore.hargatongkang')}
          </Typography>
          <TextField
            className="box-input-filter"
            type="number"
            size="small"
            placeholder={translate('status.min-harga')}
            onChange={(e) => props.handleFilterTongkang(e.target.value, "min_price")}
            value={props.filterTongkang["min_price"]}
            sx={{
              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                display: "none",
              },
              "& input[type=number]": {
                MozAppearance: "textfield",
              },
              width: '100%', input: {
                fontSize: '14px', fontFamily: 'Satoshi-500'
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <VerticalDividerLeft sx={{ ml: '12px' }} />
                  {currency === 'idr' && <Typography className="w-700" sx={{ fontSize: 14, color: '#56617B', mr: '24px' }}>
                    Rp
                  </Typography>}
                  {currency === 'usd' && <Typography className="w-700" sx={{ fontSize: 16, color: '#56617B', mr: '24px' }}>
                    $
                  </Typography>}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            className="box-input-filter"
            type="number"
            size="small"
            placeholder={translate('status.max-harga')}
            onChange={(e) => props.handleFilterTongkang(e.target.value, "max_price")}
            value={props.filterTongkang["max_price"]}
            sx={{
              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                display: "none",
              },
              "& input[type=number]": {
                MozAppearance: "textfield",
              },
              width: '100%', input: {
                fontSize: '14px', fontFamily: 'Satoshi-500'
              }, mt: 1
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <VerticalDividerLeft sx={{ ml: '12px' }} />
                  {currency === 'idr' && <Typography className="w-700" sx={{ fontSize: 14, color: '#56617B', mr: '24px' }}>
                    Rp
                  </Typography>}
                  {currency === 'usd' && <Typography className="w-700" sx={{ fontSize: 16, color: '#56617B', mr: '24px' }}>
                    $
                  </Typography>}
                </InputAdornment>
              ),
            }}
          />
        </Box>

        {/* Date Picker Terbaru */}
        {/* <Box sx={{ pl: '16px', pr: '16px', pb: '19px' }}>
          <p className='c-08 s-1419 w-500 mb4 mt-24'>Laycane Date</p>
          <DateRangePickerCustom setStart={(e) =>

            props.handleFilterTongkang(e ? formatDate(e) : e, "start_laycan_date")


          } setEnd={(e) =>

            props.handleFilterTongkang(e ? formatDate(e) : e, "end_laycan_date")
          } />
        </Box> */}

      </Box>
    </Box>
  );
}