import { Box, Button, Card, CircularProgress, Grid, Hidden, Stack, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import claimCompanyIcon from "../assets/company/claim-company-ic.svg";
import inviteStaffIcon from "../assets/company/invite-staff-ic.svg";
import { DisplayPDFClaimCompany } from '../components/DisplayPDFClaimCompany';
import DropZoneUnggahFileClaim from '../components/DropZoneFileClaim';
import Footer from "../components/Footer";
import ModalComponent from '../components/ModalKlaimPerusahaan';
import NavBar from "../components/Navbar";
import MenuInviteStaff from '../components/company/MenuInviteStaff.js';
import { createClaimCompany, getDetailCompanyById, invitedTraderToJoin } from "../services/api.js";
import { config } from '../services/constants';
import { capitalizeEachWord, convertText } from '../services/primary';

export default function Company() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();
  const [translate] = useTranslation("global");
  let path = window.location.pathname
  const [staff, setStaff] = useState([{ name_staff: "", email_staff: "" }])
  const [urlclaimcompany, seturlClaimCompany] = useState([])
  const [claimcompany, setclaimcompany] = useState([])
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [disable, setDisable] = useState(false)
  const [isLoadingSubmitClaim, setIsLoadingSubmitClaim] = useState(false);
  const [disableClaim, setDisableClaim] = useState(false)
  const [id, setId] = useState(false)
  const [isOpenModalSuccess, setIsOpenModalSuccess] = useState(false)
  const [detailCompany, setDetailCompany] = useState([])

  useEffect(() => {
    let id_company = searchParams.get("id_company")
    setId(id_company)
    getDataDetailCompany(id_company)
  }, []);


  const getDataDetailCompany = async (id) => {
    try {
      let lang = localStorage.getItem('lang') === 'null' || localStorage.getItem('lang') === 'id' ? 'id' : localStorage.getItem('lang')
      const responAPI = await getDetailCompanyById(id, lang)
      setDetailCompany(responAPI?.data?.data)

    } catch (error) {
      setDetailCompany("")
      console.log(error)
    }
  }

  //Function Invite Staff
  let handleChangeNameStaff = (e, i) => {
    let newStaffValues = [...staff];
    newStaffValues[i].name_staff = e.target.value;
    setStaff(newStaffValues);
  }

  let handleChangeEmailStaff = (e, i) => {
    let newStaffValues = [...staff];
    newStaffValues[i].email_staff = e.target.value;
    setStaff(newStaffValues);
  }

  let handleAddStaff = () => {
    const dataStaff = [...staff, { name_staff: "", email_staff: "" }]
    setStaff(dataStaff)
  }

  let handleDeleteStaff = (i) => {
    const deletestaff = [...staff]
    deletestaff.splice(i, 1)
    setStaff(deletestaff)
  }

  async function submitInviteStaff(e) {
    const data = {
      id_company: id,
      invite_staff: staff.length === 0 ? '[]' : JSON.stringify(staff)
    }
    if (staff.length === 0) {
      Swal.fire({
        icon: 'error',
        title: translate('status.sorry'),
        text: translate('status.Fieldkosong'),
      })
    } else {
      setIsLoadingSubmit(true)
      setDisable(true)

      const res = await invitedTraderToJoin(data)
      if (res.status === 200) {
        Swal.fire({
          icon: 'success',
          title: translate('status.success'),
          text: translate('status.successaddstaff'),
        }).then(function () {
          window.location.href = `/detail/${capitalizeEachWord(convertText(detailCompany?.name))}`
        });


      } else {
        if (res.message.name) {
          Swal.fire({
            icon: 'error',
            title: translate('status.failed'),
            text: `${res?.message.name}`,
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: translate('status.failed'),
            text: `${res?.message}`,
          })
        }
      }
      setIsLoadingSubmit(false)
      setDisable(false)
    }
  }



  async function handleChangeFileClaim(e) {
    let tokenValue = localStorage.getItem("token")

    const file = e[0]
    let formData = new FormData();
    formData.append('files', file);
    let response = await axios.post(`${config.baseURL}/api/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${tokenValue}`
      },
    })

    let newFile = [{
      url: `${response?.data[0].url}`,
      name: response?.data[0].name,
      ext: response?.data[0].ext,
    }]

    let newFileDisplay = [{
      url: `${config.baseURL}${response?.data[0].url}`,
      name: response?.data[0].name,
      ext: response?.data[0].ext,
    }]

    setclaimcompany(newFile)
    seturlClaimCompany(newFileDisplay)
  }

  async function handleCloseSuccessClaim() {
    setIsOpenModalSuccess(false)
    window.location.href = `/detail/${capitalizeEachWord(convertText(detailCompany?.name))}`
  }

  async function submitClaim() {

    if (claimcompany.length === 0) {
      Swal.fire({
        icon: 'error',
        title: translate('status.failed'),
        text: translate('status.errornib'),
      })
      return
    }

    const data = {
      id_company: id,
      nib_path: JSON.stringify(claimcompany)
    }
    setDisableClaim(true)
    setIsLoadingSubmitClaim(true)
    const res = await createClaimCompany(data)
    setDisableClaim(false)
    setIsLoadingSubmitClaim(false)
    if (res.status === 200) {
      setIsOpenModalSuccess(true)
    } else {
      Swal.fire({
        icon: 'error',
        title: translate('status.failed'),
        text: translate('status.subtitlefailed'),
      })
    }
  }


  return (
    <div>
      <div className='fixed-header'>
        <NavBar /></div>
      <Box className="flex-wrap-center width-100" >
        {/* Invite Staff */}
        {path.includes('invite-staff') &&
          <Box className="content-inner" sx={{ mt: { xs: 8, sm: 8, md: 11, lg: 11, xl: 11 }, ml: { xs: '24px', lg: '80px', }, mr: { xs: '24px', lg: '80px', }, }}>
            {/* navigation Section */}
            <Hidden only={['xs', 'sm']}>
              <Grid container direction="row" alignItems='center' className='mt-18'>
                <Button href="/profile" className="w-500 mb8 c-24 txt-none-tranform" variant="text"> {translate('navbar.profile')}</Button>
                <Typography className="w-500 mb8 c-56 mr8 ">
                  /
                </Typography>
                <Button onClick={() => navigate('/explore', { state: { detail: 'company' } })} className="w-500 mb8 c-24 txt-none-tranform" variant="text"> {translate('page_explore.perusahaan')}</Button>
                <Typography className="w-500 mb8 c-56 mr8 ">
                  /
                </Typography>
                <Typography className="w-500 mb8 c-56  ">
                  Invite Staff
                </Typography>
              </Grid>
            </Hidden>
            <Hidden only={['md', 'lg', 'xl']}>
              <Grid container direction="row" alignItems='center' className='mt-18'>
                <Button href="/profile" className="w-500 mb8 c-24 txt-none-tranform" variant="text"> {translate('navbar.profile')}</Button>
                <Typography cclassName="w-500 mb8 c-56 mr8 ">
                  /
                </Typography>
                <Button onClick={() => navigate('/explore', { state: { detail: 'company' } })} className="w-500 mb8 c-24 txt-none-tranform" variant="text"> {translate('page_explore.perusahaan')}</Button>
                <Typography className="w-500 mb8 c-56 mr8 ">
                  /
                </Typography>
                <Typography className="w-500 mb8 c-56  ">
                  Invite Staff
                </Typography>
              </Grid>
            </Hidden>

            <div>
              <Grid container direction="column" alignItems="center" justifyContent="center" className='mt-18'>
                <img src={inviteStaffIcon} alt='perusahaan-icon' />
                <Typography className="w-700 s-3222 mt-16 mb8 c-08" >
                  Invite Staff
                </Typography>
                <p className='c-56 w-400 s-1421 mb-16 txt-align-center'> {translate('status.subinvitestaff')}</p>
              </Grid>


              <Grid container direction='row' justifyContent='center' >
                <Grid item xs={3} className='wd-252'>
                </Grid>
                <MenuInviteStaff staff={staff} handleDeleteStaff={handleDeleteStaff} handleChangeNameStaff={handleChangeNameStaff} handleChangeEmailStaff={handleChangeEmailStaff} handleAddStaff={handleAddStaff}></MenuInviteStaff>
                <Grid item xs={3} className='wd-252'>
                </Grid>
              </Grid>
              <Grid container>
                <Grid xs={12} className='line mt-24 mb-32' />
              </Grid>
              <Hidden smDown>
                <Grid className='mb-104 in-container' container justifyContent='space-between' direction='row'>
                  <Button
                    onClick={() => navigate(-1)}
                    size="large"
                    className="w-500"
                    variant="text"
                    style={{
                      color: '#2457B9',
                      textTransform: 'none',
                      padding: '12px 16px',
                      borderRadius: '8px',
                      height: '44px'
                    }}
                  >
                    {translate('jual-beli.btn-cancel')}
                  </Button>

                  <Button
                    onClick={(e) => {
                      submitInviteStaff(e)
                    }}
                    disabled={disable}
                    size="large"
                    className="button-blue-contained w-500 mb-16"
                    sx={{
                      width: '180px',
                    }}
                  >
                    {isLoadingSubmit ? (<CircularProgress
                      sx={{
                        color: (theme) =>
                          theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                      }}
                      size={20}
                      thickness={4}
                      value={100}
                    />) : <>     {translate('jual-beli.simpan')}</>}
                  </Button>

                </Grid>
              </Hidden>
              <Hidden smUp>
                <Grid spacing={1} container justifyContent='space-between' direction='row' style={{ marginBottom: '80px' }}>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      onClick={() => navigate(-1)}
                      size="small"
                      className="button-blue-outlined w-500 mb-16"
                      variant="outlined"

                    >
                      {translate('jual-beli.btn-cancel')}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      onClick={(e) => {
                        submitInviteStaff(e)
                      }}
                      disabled={disable}
                      size="small"
                      className="button-blue-contained w-500 mb-16"
                      variant="contained"

                    >
                      {isLoadingSubmit ? (<CircularProgress
                        sx={{
                          color: (theme) =>
                            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                        }}
                        size={20}
                        thickness={4}
                        value={100}
                      />) : <> {translate('status.selanjutnya')}</>}
                    </Button>

                  </Grid>
                </Grid>
              </Hidden>
            </div>
          </Box>}
        {/* Claim Perusahaan */}
        {path.includes('claim') &&
          <Box className="content-inner" sx={{ mt: { xs: 8, sm: 8, md: 11, lg: 11, xl: 11 }, ml: { xs: '24px', lg: '80px', }, mr: { xs: '24px', lg: '80px', }, }}>
            {/* navigation Section */}
            <Hidden only={['xs', 'sm']}>
              <Grid container direction="row" alignItems='center' className='mt-18'>
                <Button href="/" className="w-500" sx={{ fontSize: 16, mb: 1, color: '#2457B9', textTransform: 'none' }} variant="text"> {translate('navbar.home')}</Button>
                <Typography className="w-500" sx={{ fontSize: 16, mb: 1, mr: 1, color: '#56617B' }}>
                  /
                </Typography>
                <Typography className="w-500" sx={{ fontSize: 16, mb: 1, color: '#56617B' }}>
                  {translate('navbar.explore')}
                </Typography>
              </Grid>
            </Hidden>
            <Hidden only={['md', 'lg', 'xl']}>
              <Grid container direction="row" alignItems='center' className='mt-18'>
                <Button href="/" className="w-500" sx={{ fontSize: 14, mb: 1, color: '#2457B9', textTransform: 'none' }} variant="text"> {translate('navbar.home')}</Button>
                <Typography className="w-500" sx={{ fontSize: 14, mb: 1, color: '#56617B' }}>
                  /
                </Typography>
                <Button onClick={(e) => { navigate(`/explore`, { state: { detail: 'company' } }) }} className="w-500" sx={{ fontSize: 14, mb: 1, color: '#2457B9', textTransform: 'none' }} variant="text">      {translate('navbar.explore')}</Button>

              </Grid>
            </Hidden>

            <Grid container direction="column" alignItems="center" justifyContent="center" className='mt-18'>
              <img src={claimCompanyIcon} alt='perusahaan-icon' height={72} width={72} />
              <Typography className="w-700" sx={{ fontSize: 32, mt: '16px', color: '#081130', mb: 1 }}>
                {translate('status.klaimperusahaan')}
              </Typography>
              <p style={{ textAlign: 'center' }} className='c-56 w-400 s-1421 mb-16'> {translate('status.subklaim')}</p>
            </Grid>


            <Grid container direction='row' justifyContent='space-between' >
              <Grid item xs={12} sm={12} md={3} lg={3} className='wd-252'>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} className='form-input'>
                <Box sx={{ borderRadius: '8px', border: '1px solid var(--grey-stroke, #E8EBF2)', p: 2, mb: 2 }}>
                  <Typography className="w-700" sx={{ fontSize: 14, color: '#081130', mb: 1 }}>
                    {translate('status.intruksiklaim')}
                  </Typography>
                  <Typography className="w-400" sx={{ fontSize: 14, color: '#56617B' }}>
                    {translate('status.subintruksi')}
                  </Typography>
                </Box>
                {urlclaimcompany.length === 0 ? (
                  <></>
                ) : (
                  <Box >
                    <Stack direction={'row'} justifyContent={'space-between'}>
                      <p className='c-08 s-1419 w-500 mb4 '> {translate('status.uploaddokumen')}</p>
                    </Stack>

                    <Grid sx={{ mt: "5px", mb: "10px", }} container direction={'row'}>
                      {urlclaimcompany.map((item, i) => (
                        <Grid key={item} item xs={12} sm={12} md={12} lg={12} >
                          <Card sx={{ borderRadius: 2 }}>
                            <DisplayPDFClaimCompany
                              url={`${item.url}`}
                              key_name={`${i}`}
                              ext={`.pdf`}
                              name={`${item.name}`}
                              delete={setclaimcompany}
                              delete_url={seturlClaimCompany}
                            />
                          </Card>
                        </Grid>
                      ))}
                    </Grid>

                  </Box>
                )}
                {urlclaimcompany.length === 0 &&
                  <Box>
                    <p className='c-08 s-1419 w-500 mb4 '>{translate('status.uploaddokumen')}</p>
                    <DropZoneUnggahFileClaim changeFile={(e) => handleChangeFileClaim(e)} />
                  </Box>
                }
              </Grid>
              <Grid item xs={3} className='wd-252'>
              </Grid>
            </Grid>
            <Grid container>
              <Grid xs={12} className='line mt-24 mb-32' />
            </Grid>
            <Hidden smDown>
              <Grid className='mb-104 in-container' container justifyContent='space-between' direction='row'>
                <Button
                  onClick={() => navigate(-1)}
                  size="large"
                  className="w-500"
                  variant="text"
                  style={{
                    color: '#2457B9',
                    textTransform: 'none',
                    padding: '12px 16px',
                    borderRadius: '8px',
                    height: '44px'
                  }}
                >
                  {translate('jual-beli.btn-cancel')}
                </Button>
                <Button
                  onClick={(e) => {
                    submitClaim()
                  }}
                  size="large"
                  disabled={disableClaim}
                  className="button-blue-contained w-500 mb-16"
                  variant="contained"
                  sx={{
                    width: '180px',
                  }}
                >
                  {isLoadingSubmitClaim ? (<CircularProgress
                    sx={{
                      color: (theme) =>
                        theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                    }}
                    size={20}
                    thickness={4}
                    value={100}
                  />) : <>Submit</>}
                </Button>

              </Grid>
            </Hidden>
            <Hidden smUp>
              <Grid spacing={1} container justifyContent='space-between' direction='row' style={{ marginBottom: '80px' }}>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    onClick={() => navigate(-1)}
                    size="small"
                    className="button-blue-outlined w-500"
                    variant="outlined"
                  >
                    {translate('jual-beli.btn-cancel')}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    disabled={disableClaim}
                    onClick={(e) => {
                      submitClaim()
                    }}

                    size="small"
                    className="button-blue-contained w-500 mb-16"
                    variant="contained"
                  >
                    {isLoadingSubmitClaim ? (<CircularProgress
                      sx={{
                        color: (theme) =>
                          theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                      }}
                      size={20}
                      thickness={4}
                      value={100}
                    />) : <>Submit</>}
                  </Button>

                </Grid>
              </Grid>
            </Hidden>

          </Box>}
      </Box>
      <Footer />

      <ModalComponent open={isOpenModalSuccess} close={() => { handleCloseSuccessClaim() }} />
    </div >
  )
}
