import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PercentIcon from '@mui/icons-material/Percent';
import { Box, Grid, InputAdornment, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { React, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { VerticalDivider } from '../../services/primary';
import FormNegara from '../FormNegara';
import IconInfo from "../../assets/icon-info.png";

function MenuPemegangSahamChild(props) {
  const [translate,] = useTranslation("global");

  const [validationSahamType, setValidationSahamType] = useState(false);
  const [validationName, setValidationName] = useState(false);
  const [validationNation, setValidationNation] = useState(false);
  const [validationPercentage, setValidationPercentage] = useState(false);

  useEffect(() => {

    if (props.triggerValidation >= 1) {

      if (!props.item.name) {
        setValidationName(true)
      }

      if (!props.item.jenis_saham) {
        setValidationSahamType(true)
      }

      if (!props.item.persentase) {
        setValidationPercentage(true)
      }
    }

  }, [props.triggerValidation])

  useEffect(() => {

    if (props.triggerValidation >= 1) {

      if (!props.item.id_negara) {
        setValidationNation(true)
      } else {
        setValidationNation(false)
      }
    }

  }, [props.triggerValidation, props.item.id_negara])

  return (
    <Box key={props.item.id} sx={{ border: '1px solid var(--grey-stroke, #E8EBF2)', borderRadius: '12px', p: '16px', mb: '16px' }}>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <h6 style={{ fontSize: 16 }} className=' w-700 mb-16'>{translate('status.pemegangsaham')}</h6>
        {props.i !== 0 && <CloseIcon onClick={(e) => props.handleDeletePemegangSaham(props.i)} sx={{ height: 24, width: 24, cursor: 'pointer' }} ></CloseIcon>}
      </Stack>

      <Grid container justifyContent='space-between' direction='row' spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <p className='c-08 s-1419 w-500 mb4'>{translate('status.jenissaham')}</p>
          <Select IconComponent={() => (
            <KeyboardArrowDownIcon sx={{ mr: '16px', width: '20px', height: '20px', fill: '#56617B' }} />
          )}
            MenuProps={{
              PaperProps: {
                sx: {
                  height: '352px',
                  '& .MuiMenuItem-root': {
                  },
                },
              },
            }}
            style={{ border: '1px solid var(--grey-stroke, #e8ebf2)', borderRadius: '8px', width: '100%' }}
            value={props.item.jenis_saham}
            // onChange={(e) => props.handleChangeJenisSaham(e, i)}
            error={validationSahamType}
            onChange={(e) => {
              props.handleChangeJenisSaham(e, props.i)
              setValidationSahamType(false);
            }}
            displayEmpty >
            <MenuItem value="" style={{ display: "none" }}>
              <p className='w-400 s-1420 c-87'>{translate('status.jenissaham')}</p>
            </MenuItem>
            {props.jenissaham.map((item, i) => (
              <MenuItem key={item.id} value={item.attributes.value}>{item.attributes.value} </MenuItem>))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <p className='c-08 s-1419 w-500 mb4'>{translate('login-register.nama')} </p>
          <TextField className='w-400 s-1420' id="outlined-start-adornment"
            placeholder={translate('login-register.placeholder-name')}
            value={props.item.name}
            error={validationName}
            // onChange={(e) => {
            //   props.handleChangePemegangSaham(e, props.i)
            //   setValidationName(false);
            // }}
            onChange={(e) => {
              if (e.target.value && e.target.value !== "") {
                props.handleChangePemegangSaham(e, props.i)
                setValidationName(false);
              } else {
                props.handleChangePemegangSaham(e, props.i)
                setValidationName(true);
              }
            }}
            // onChange={(e) => props.handleChangePemegangSaham(e, props.i)}
            sx={{
              width: '100%', mb: '24px', input: {
                fontSize: '14px', fontFamily: 'Satoshi-400'
              },
            }}
          />
        </Grid>

      </Grid>

      <Grid container justifyContent={'space-between'} spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <p className='c-08 s-1419 w-500 mb4'>{translate('status.negara')}</p>
          <FormNegara select={(e) => props.handleSelectbendera(e, props.i)} selectName={(e) => { }} currentValue={props.item.id_negara} />
          {validationNation ? (
            <Box sx={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
              <img alt="Nikel-icon" src={IconInfo} height={20}></img>
              <p className="s-1419 w-500" style={{ marginLeft: "5px", color: "#D32F2F" }}>
                {translate("title.validation")}
              </p>
            </Box>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box>
            <p className='c-08 s-1419 w-500 mb4'>{translate('status.percentase')}</p>
            <TextField
              className="box-input-filter"
              type="number"
              size="small"
              placeholder={translate('status.percentasesaham')}
              // onChange={(e) => props.handleChangePercentaseSaham(e, props.i)}
              value={props.item.persentase}
              error={validationPercentage}
              // onChange={(e) => {
              //   props.handleChangePercentaseSaham(e, props.i)
              //   setValidationPercentage(false);
              // }}
              onChange={(e) => {
                if (e.target.value && e.target.value !== "") {
                  props.handleChangePercentaseSaham(e, props.i)
                  setValidationPercentage(false);
                } else {
                  props.handleChangePercentaseSaham(e, props.i)
                  setValidationPercentage(true);
                }
              }}
              sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                  display: "none",
                },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
                width: '100%', input: {
                  fontSize: '14px', fontFamily: 'Satoshi-500'
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <VerticalDivider />
                    <PercentIcon sx={{ color: '#56617B', fontSize: '16px' }} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default function MenuPemegangSaham(props) {
  const [translate,] = useTranslation("global");

  return (
    <Grid item xs={12} sm={12} md={4} lg={4} className='form-input'>
      {props.pemegangsaham.map((item, i) => (
        <MenuPemegangSahamChild item={item} i={i} triggerValidation={props.triggerValidation} pemegangsaham={props.pemegangsaham} handleDeletePemegangSaham={props.handleDeletePemegangSaham} jenissaham={props.jenissaham} handleChangePemegangSaham={props.handleChangePemegangSaham} id_bendera={props.id_bendera} handleSelectbendera={props.handleSelectbendera} flagList={props.flagList} handleAddPemegangSaham={props.handleAddPemegangSaham} handleChangeJenisSaham={props.handleChangeJenisSaham} handleChangePercentaseSaham={props.handleChangePercentaseSaham} />
      ))}
      <Stack onClick={() => props.handleAddPemegangSaham()} direction={'row'} alignItems={'center'} sx={{ cursor: 'pointer', mt: 2 }}>
        <AddIcon sx={{
          fontSize: 20, color: "#2457B9"
        }} />
        <Typography className="w-700" style={{ fontSize: 16, color: '#2457B9' }}>
          {translate('status.tambahdata')}
        </Typography>
      </Stack>
    </Grid>
  );
}