import Divider from "@material-ui/core/Divider";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Avatar, Box, Button, Card, Grid, Hidden, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-multi-carousel/lib/styles.css";
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { FacebookShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import Slider from "react-slick";
import Swal from 'sweetalert2';
import Airicon from "../assets/air-ic.png";
import Ashicon from "../assets/ash-ic.png";
import { default as callCompanyIcon } from "../assets/call-company-ic.png";
import Calorieicon from "../assets/calorie-ic.png";
import copyIc from "../assets/copy-ic.png";
import editIcon from "../assets/edit-listing-ic.png";
import visibilityIcon from "../assets/eye-icon.svg";
import fbIcon from "../assets/fb-circle-grey-ic.png";
import Feicon from "../assets/fe-ic.png";
import laycanIcon from "../assets/laycan-ic.png";
import locationCompanyIcon from "../assets/location-company-ic.png";
import { default as mailCompanyIcon } from "../assets/mail-company-ic.png";
import Niicon from "../assets/ni-ic.png";
import pelayarIcon from "../assets/pelayar-ic.png";
import { default as PenambangIcon } from "../assets/penambang-ic.png";
import Ratioicon from "../assets/ratio-ic.png";
import SizetongkangIcon from "../assets/sizetongkang-ic.png";
import soldIcon from "../assets/sold-ic.png";
import Sulfuricon from "../assets/sulfur-ic.png";
import telegramIcon from "../assets/telegram-circle-grey-ic.png";
import traderIcon from "../assets/trader-ic.png";
import deleteIcon from "../assets/trash-ic.png";
import twitterIcon from "../assets/twitter-circle-grey-ic.png";
import unpublishIcon from "../assets/unpublish-ic.png";
import waIcon from "../assets/wa-circle-grey-ic.png";
import { DisplayPDFFile } from '../components/DisplayPDF';
import DrawerMore from "../components/DrawerMore";
import Footer from "../components/Footer";
import { Komentar } from "../components/KomentarSection";
import ModalHapusListing from "../components/ModalHapusListing";
import ModalShareListing from "../components/ModalShareListing";
import ModalUnpublish from "../components/ModalUnpublish";
import NavBar from "../components/Navbar";
import { addFavorite, checkFavorite, deleteFavorite, editBatubara, editNikel, editTongkang, getDetailBatubara, getDetailNikel, getDetailTongkang } from "../services/api";
import { config } from "../services/constants";
import { GlobalState } from "../services/global";
import { capitalizeEachWord, convertText, numberConvert, settings } from '../services/primary';

function DetailListingpage() {
  const { state } = useLocation();
  const [location, setLocation] = useState({
    state: {
      id: '',
      detail: ''
    }
  })
  const [searchParams,] = useSearchParams();
  const [detailTongkang, setDetailTongkang] = useState([])
  const [materialTongkang, setMaterialTongkang] = useState([])
  const [detailNikel, setDetailNikel] = useState([])
  const [detailBatubara, setDetailBatubara] = useState([])
  const [imageTongkang, setImageTongkang] = useState([])
  const [docNikel, setDocNikel] = useState([])
  const [docBatubara, setDocBatubara] = useState([])
  const [docTongkang, setDocTongkang] = useState([])
  const navigate = useNavigate()
  const [isOpenModalHapus, setOpenModalHapus] = React.useState(false)
  const [isOpenModalUnpublish, setOpenModalUnpublish] = React.useState(false)
  const [isOpenMore, setOpenMore] = React.useState(false)
  const [isOpenModalShare, setOpenModalShare] = React.useState(false)
  const [copyUrl, setCopyUrl] = useState(false)
  const [statuslisting, setstatuslisting] = React.useState(false)
  const { currency } = React.useContext(GlobalState)

  useEffect(() => {
    let type = searchParams.get("type")
    let id_detail = searchParams.get("id_detail")

    if (state?.detail === 'coal' || type === '1') {
      if (id_detail !== null) {
        setLocation({
          state: {
            id: id_detail,
            detail: 'coal'
          }
        })
        setActiveTab('coal')
        getDataDetailBatubara(id_detail)
      } else {
        setLocation({
          state: {
            id: state.id,
            detail: state.detail
          }
        })
        setActiveTab(state.detail)
        getDataDetailBatubara(state.id)
      }
    }

    if (state?.detail === 'nikel' || type === '2') {
      if (id_detail !== null) {
        setLocation({
          state: {
            id: id_detail,
            detail: 'nikel'
          }
        })
        setActiveTab('nikel')
        getDataDetailNikel(id_detail)
      } else {
        setLocation({
          state: {
            id: state.id,
            detail: state.detail
          }
        })
        setActiveTab(state.detail)
        getDataDetailNikel(state.id)
      }
    }

    if (state?.detail === 'tongkang' || type === '3') {
      if (id_detail !== null) {
        setLocation({
          state: {
            id: id_detail,
            detail: 'tongkang'
          }
        })
        setActiveTab('tongkang')
        getDataDetailTongkang(id_detail)
      } else {
        setLocation({
          state: {
            id: state.id,
            detail: state.detail
          }
        })
        setActiveTab(state.detail)
        getDataDetailTongkang(state.id)
      }
    }

  }, [])
  

  useEffect(() => {
    let type = searchParams.get("type")
    let id_detail = searchParams.get("id_detail")

    if (state?.detail === 'coal' || type === '1') {
      if (id_detail !== null) {
        setLocation({
          state: {
            id: id_detail,
            detail: 'coal'
          }
        })
        setActiveTab('coal')
        getDataDetailBatubara(id_detail)
      } else {
        setLocation({
          state: {
            id: state.id,
            detail: state.detail
          }
        })
        setActiveTab(state.detail)
        getDataDetailBatubara(state.id)
      }
    }

    if (state?.detail === 'nikel' || type === '2') {
      if (id_detail !== null) {
        setLocation({
          state: {
            id: id_detail,
            detail: 'nikel'
          }
        })
        setActiveTab('nikel')
        getDataDetailNikel(id_detail)
      } else {
        setLocation({
          state: {
            id: state.id,
            detail: state.detail
          }
        })
        setActiveTab(state.detail)
        getDataDetailNikel(state.id)
      }
    }

    if (state?.detail === 'tongkang' || type === '3') {
      if (id_detail !== null) {
        setLocation({
          state: {
            id: id_detail,
            detail: 'tongkang'
          }
        })
        setActiveTab('tongkang')
        getDataDetailTongkang(id_detail)
      } else {
        setLocation({
          state: {
            id: state.id,
            detail: state.detail
          }
        })
        setActiveTab(state.detail)
        getDataDetailTongkang(state.id)
      }
    }
  }, [localStorage.getItem('lang')])

  const handleOpenModal = (state, listing) => {
    let status = listing === 1 ? 'unpublish' : 'publish'
    setstatuslisting(status);
    if (state === 'hapus') {
      setOpenModalHapus(true);
    } else if (state === 'more') {
      setOpenMore(true);
    } else if (state === 'unpublish') {
      setOpenModalUnpublish(true);

    } else if (state === 'publish') {
      setOpenModalUnpublish(true);

    }

  };
  const handleCloseModalHapus = () => {
    setOpenModalHapus(false);
  }

  const handleCloseModalUnpublish = () => {
    setOpenModalUnpublish(false);
  }

  const handleCloseModal = () => {
    setOpenMore(false);
  };

  const getDataDetailBatubara = async (id) => {
    try {
      let lang = localStorage.getItem('lang') === 'null' || localStorage.getItem('lang') === 'id' ? 'id' : localStorage.getItem('lang')
      let id_user = localStorage.getItem("id_user")
      const responAPI = await getDetailBatubara(id, lang)
      if (responAPI.data.status === 400) {
        if (id_user) {
          Swal.fire({
            icon: 'error',
            title: translate('status.failed'),
            text: translate('status.subtitlefailed'),
          }).then(function () {
            window.location.href = `/`
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: translate('status.failed'),
            text: translate('status.subtitlefailed'),
          }).then(function () {
            window.location.href = `/signin`
          });
        }
      }

      if (responAPI.data.status === 200) {
        if (Number(id_user) === Number(responAPI.data.data.user_id)) {
          await favoriteCheck(1, id)
          setDetailBatubara(responAPI.data.data)
          setDocBatubara(responAPI.data.data.batubara_documents === null || responAPI.data.data.batubara_documents === "" ? [] : responAPI.data.data.batubara_documents)
        } else {
          window.location.href = `/penawaran/detail?type=1&id_detail=${id}`
        }
      }
    } catch (error) {
      setDetailBatubara("")
      console.log(error)
    }
  }

  const getDataDetailNikel = async (id) => {
    try {
      let lang = localStorage.getItem('lang') === 'null' || localStorage.getItem('lang') === 'id' ? 'id' : localStorage.getItem('lang')
      let id_user = localStorage.getItem("id_user")
      const responAPI = await getDetailNikel(id, lang)

      if (responAPI.data.status === 400) {
        if (id_user) {
          Swal.fire({
            icon: 'error',
            title: translate('status.failed'),
            text: translate('status.subtitlefailed'),
          }).then(function () {
            window.location.href = `/`
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: translate('status.failed'),
            text: translate('status.subtitlefailed'),
          }).then(function () {
            window.location.href = `/signin`
          });
        }
      }

      if (responAPI.data.status === 200) {
        if (Number(id_user) === Number(responAPI.data.data.user_id)) {
          await favoriteCheck(2, id)
          setDetailNikel(responAPI.data.data)
          setDocNikel(responAPI.data.data.nikel_documents === null || responAPI.data.data.nikel_documents === "" ? [] : responAPI.data.data.nikel_documents)
        } else {
          window.location.href = `/penawaran/detail?type=2&id_detail=${id}`
        }
      }
    } catch (error) {
      setDetailNikel("")
      console.log(error)
    }
  }

  const getDataDetailTongkang = async (id) => {
    try {
      let lang = localStorage.getItem('lang') === 'null' || localStorage.getItem('lang') === 'id' ? 'id' : localStorage.getItem('lang')
      let id_user = localStorage.getItem("id_user")
      const responAPI = await getDetailTongkang(id, lang)
      if (responAPI.data.status === 400) {
        if (id_user) {
          Swal.fire({
            icon: 'error',
            title: translate('status.failed'),
            text: translate('status.subtitlefailed'),
          }).then(function () {
            window.location.href = `/`
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: translate('status.failed'),
            text: translate('status.subtitlefailed'),
          }).then(function () {
            window.location.href = `/signin`
          });
        }
      }

      if (responAPI.data.status === 200) {
        if (Number(id_user) === Number(responAPI.data.data.user_id)) {
          await favoriteCheck(3, id)
          setDetailTongkang(responAPI.data.data)
          setMaterialTongkang(responAPI.data.data.jenis_muatan === null || responAPI.data.data.jenis_muatan === "" ? [] : responAPI.data.data.jenis_muatan.split(","))
          setImageTongkang(responAPI.data.data.path_tongkang_pictures === null || responAPI.data.data.path_tongkang_pictures === "" ? [] : responAPI.data.data.path_tongkang_pictures)
          setDocTongkang(responAPI.data.data.tongkang_documents === null || responAPI.data.data.tongkang_documents === "" ? [] : responAPI.data.data.tongkang_documents)
        } else {
          window.location.href = `/penawaran/detail?type=3&id_detail=${id}`
        }
      }
    } catch (error) {
      setDetailTongkang("")
      console.log(error)
    }
  }

  const [checkingFavorite, setCheckingFavorite] = useState(false);

  const favoriteCheck = async (type_product, id) => {
    try {
      let params = {
        id_product: id,
        type_product: type_product
      }
      const responAPI = await checkFavorite(params)
      let is_favorite = responAPI.data.is_favorite
      setCheckingFavorite(is_favorite)
    } catch (error) {
      console.log(error)
    }
  }

  const setFavorite = async (e) => {
    let response = await addFavorite({ type: e, id_relation: parseInt(location.state.id) })

    if (response.status === 200) {
      setCheckingFavorite(true)
    } else {
      window.location.href = "/signin"
    }
  }

  const removeFavorite = async (e) => {
    let response = await deleteFavorite({ type_product: parseInt(e), id_product: parseInt(location.state.id) })

    if (response.status === 200) {
      setCheckingFavorite(false)
    } else {
      window.location.href = "/signin"
    }
  }

  const [activeTab, setActiveTab] = useState(location.state?.detail);
  const [translate,] = useTranslation("global");

  const handleShareModal = () => {
    setOpenModalShare(true);
  }
  const closeShareModal = () => {
    setOpenModalShare(false);
  }

  const handleUnpublishModal = () => {
    setOpenModalUnpublish(true);
  }

  const handlehapuslisting = () => {
    setOpenModalHapus(true);
  }

  const ActionTandaiTerjual = async (id, page_detail) => {
    if (page_detail === 'coal') {
      try {
        let params = {
          is_sold: "1",
          token: localStorage.getItem("token")
        }

        const responAPI = await editBatubara(params, id)

        if (responAPI.status === 200) {

          window.location.reload()
        } else {
          Swal.fire({
            icon: 'error',
            title: translate('status.failed'),
            text: translate('status.subtitlefailed'),
          })
        }

      } catch (error) {
        console.log(error)
      }
    } else if (page_detail === 'nikel') {
      try {
        let params = {
          is_sold: "1",
          token: localStorage.getItem("token")
        }

        const responAPI = await editNikel(params, id)
        if (responAPI.status === 200) {

          window.location.reload()
        } else {
          Swal.fire({
            icon: 'error',
            title: translate('status.failed'),
            text: translate('status.subtitlefailed'),
          })
        }

      } catch (error) {
        console.log(error)
      }
    } else {
      try {
        let params = {
          is_sold: "1",
          token: localStorage.getItem("token")
        }

        const responAPI = await editTongkang(params, id)
        if (responAPI.status === 200) {

          window.location.reload()
        } else {
          Swal.fire({
            icon: 'error',
            title: translate('status.failed'),
            text: translate('status.subtitlefailed'),
          })
        }


      } catch (error) {
        console.log(error)
      }
    }

  }

  const handleNavigate = (id_company, name_company) => {
    let filter_name = capitalizeEachWord(convertText(name_company))
    window.location.href = `/detail/${filter_name}`
  };


  function copyLink(e) {
    setCopyUrl(true)
    setTimeout(() => {
      setCopyUrl(false)
    }, 1000);
  }

  const url_copy = window.location.href
  const copyClipboard = <div className="copyToClipboard" style={{ opacity: copyUrl ? "1" : "0" }}>✔ Copied to clipboard</div>

  return (
    <div className="detail-explore width-100">
      <div className="flex-wrap-center relative width-100">
        <div className="container-nav relative width-100">
          <div className='fixed-header'>
            <NavBar></NavBar>
          </div>
        </div>
        <Box className="flex-wrap-center width-100" sx={{ mt: { xs: 6, sm: 6, md: 11, lg: 11, xl: 11 } }}>
          <div className="content-inner">
            <Stack
              sx={{ mt: '16px' }}
              direction="row"
              alignItems={'center'}
            >
              <Button href="/" className="w-500" sx={{ fontSize: 16, mb: 1, color: '#2457B9', textTransform: 'none', pl: 0 }} variant="text">  {translate('page_explore.home')}</Button>

              <Typography className="w-500" sx={{ fontSize: 16, mb: 1, color: '#56617B' }}>
                /
              </Typography>
              <Button href="/listing" className="w-500" sx={{ fontSize: 16, mb: 1, color: '#2457B9', textTransform: 'none' }} variant="text">         {translate('navbar.mylisting')}</Button>

              <Typography className="w-500" sx={{ fontSize: 16, mb: 1, mr: 1, color: '#2457B9' }}>
                /
              </Typography>
              {activeTab === 'coal' ? <Typography className="w-500" sx={{ fontSize: 16, mb: 1 }}>
                {translate('detail-explore.detailbatubara')}
              </Typography> : null}
              {activeTab === 'nikel' ? <Typography className="w-500" sx={{ fontSize: 16, mb: 1 }}>
                {translate('detail-explore.detailnikel')}
              </Typography> : null}
              {activeTab === 'tongkang' ? <Typography className="w-500" sx={{ fontSize: 16, mb: 1 }}>
                {translate('detail-explore.detailtongkang')}
              </Typography> : null}
            </Stack>
            <Grid
              className="test-grid"
              container
              direction="row"
              rowSpacing={{ xs: 5, lg: 7 }}
              columnSpacing={{ xs: 5, lg: 7 }}
              sx={{ mb: 2 }}
            >
              {/* leftside Section */}
              <Grid lg={8.4} xs={12} item sx={{ width: '840px' }}>
                {/* coal Section */}
                {activeTab === 'coal' ?
                  <Box sx={{ mb: 2, }}>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={0}
                    >
                      <Grid item >
                        {detailBatubara?.code_product &&
                          <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            <Typography
                              sx={{ fontSize: { xs: 18, sm: 18, lg: 28 }, color: '#081130' }}
                              className="w-700"
                            >
                              {detailBatubara?.code_product}
                            </Typography>
                            <Box
                              sx={{
                                top: '10%',
                                background: "#F4F6FA",
                                borderRadius: 4,
                                pr: 2,
                                pl: 2,
                                pb: '2px',
                                pt: '2px',

                              }}
                            >
                              <Stack direction={'row'} alignItems={'center'}>

                                {detailBatubara.status_listing === 1 && detailBatubara.is_sold === false ? (
                                  <>
                                    <Box sx={{ borderRadius: '50%', background: '#3BB56C', width: '10px', height: '10px', mr: 1 }}>
                                    </Box>
                                    <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 14 }} >
                                      Publish
                                    </Typography>
                                  </>

                                ) : (
                                  <></>
                                )}

                                {detailBatubara.status_listing === 2 || detailBatubara.is_sold === true ? (
                                  <>
                                    <Box sx={{ borderRadius: '50%', background: '#F7CE46', width: '10px', height: '10px', mr: 1 }}>
                                    </Box>
                                    <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 14 }} >
                                      Unpublish
                                    </Typography>
                                  </>
                                ) : (
                                  <></>
                                )}
                                {detailBatubara.status_listing === 3 ? (
                                  <>
                                    <Box sx={{ borderRadius: '50%', background: '#56617B', width: '10px', height: '10px', mr: 1 }}>
                                    </Box>
                                    <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 14 }} >
                                      Draft
                                    </Typography>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Stack>
                            </Box>
                            {detailBatubara.is_sold === true && <Box
                              sx={{
                                top: '10%',
                                background: "#F4F6FA",
                                borderRadius: 4,
                                pr: 2,
                                pl: 2,
                                pb: '2px',
                                pt: '2px',

                              }}
                            >

                              <Stack direction={'row'} alignItems={'center'}>



                                <Box sx={{ borderRadius: '50%', background: '#F7CE46', width: '10px', height: '10px', mr: 1 }}>
                                </Box>
                                <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 14 }} >

                                  {translate('status.soldout')}
                                </Typography>

                              </Stack>
                            </Box>}
                          </Stack>
                        }
                        <Grid
                          container
                          justifyContent="left"
                          alignItems="center"
                          direction="row"
                        >
                          <Typography
                            className="w-500"
                            sx={{ fontSize: 14, color: "#56617B", mr: 1 }}
                          >

                            {translate('detail-explore.postdate')}
                          </Typography>
                          {detailBatubara.createdAt &&
                            <Typography
                              className="w-500"
                              sx={{ fontSize: 14, color: "#081130", mr: 1 }}
                            >
                              {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(detailBatubara.createdAt))}
                            </Typography>}
                        </Grid>
                      </Grid>
                      <Grid sx={{ textAlign: "left", alignItems: "center", display: 'flex', mt: 1, mb: 1 }} item >

                        <Typography
                          sx={{ fontSize: '24px', color: '#081130', mr: 1 }}
                          className="w-700"
                        >
                          {currency === 'idr' ? 'Rp' : '$'}  {numberConvert(parseInt(detailBatubara.price))}
                        </Typography>
                        <Typography
                          sx={{ fontSize: 14, color: '#56617B' }}
                          className="w-500"
                        >
                          /ton
                        </Typography>
                      </Grid>

                    </Grid>
                    <Divider style={{ backgroundColor: "#E8EBF2", width: '100%', marginTop: '24px', marginBottom: '24px' }} />

                    <Grid
                      container
                      direction="row"
                      alignItems="left"
                      justifyContent="left"
                      spacing={2}
                    >

                      <Grid item md={3} xs={6}>
                        <Box sx={{ background: "#F4F6FA", borderRadius: 4, padding: 2 }}>
                          <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>

                            <Typography className="w-700" sx={{ color: '#081130', fontSize: { md: '20px', xs: '18px' }, }}>
                              {numberConvert(detailBatubara?.calorie)}

                            </Typography>
                            <img alt="Nikel-icon" src={Calorieicon} height={20}></img>
                          </Stack>
                          <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                            Gar
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item md={3} xs={6}>
                        <Box sx={{ background: "#F4F6FA", borderRadius: 4, padding: 2 }}>
                          <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>

                            <Typography className="w-700" sx={{ color: '#081130', fontSize: { md: '20px', xs: '18px' }, }}>
                              {numberConvert(detailBatubara?.total_sulfur)}

                            </Typography>
                            <img alt="Nikel-icon" src={Sulfuricon} height={20}></img>
                          </Stack>
                          <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                            {translate('page_explore.total-sulfur')}
                          </Typography>
                        </Box>


                      </Grid>

                      <Grid item md={3} xs={6}>
                        <Box sx={{ background: "#F4F6FA", borderRadius: 4, padding: 2 }}>
                          <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>

                            <Typography className="w-700" sx={{ color: '#081130', fontSize: { md: '20px', xs: '18px' }, }}>

                              {numberConvert(detailBatubara?.ASH)}
                            </Typography>
                            <img alt="Nikel-icon" src={Ashicon} height={20}></img>
                          </Stack>
                          <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                            Ash
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item md={3} xs={6}>
                        <Box sx={{ background: "#F4F6FA", borderRadius: 4, padding: 2 }}>
                          <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>

                            <Typography className="w-700" sx={{ color: '#081130', fontSize: { md: '20px', xs: '18px' }, mr: '8px' }}>

                              {numberConvert(detailBatubara?.quantity)}
                            </Typography>
                            <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                              MT
                            </Typography>
                          </Stack>
                          <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                            Quantity
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>

                    {/* SumberBatu Section */}
                    <Typography
                      sx={{ fontSize: 14, color: '#818CA7', mt: '16px' }}
                      className="w-500"
                    >
                      {translate('status.sumberbatu')}
                    </Typography>
                    {detailBatubara.sumber_batu ? <Typography
                      sx={{ fontSize: 14, color: '#081130', }}
                      className="w-700"
                    >
                      {detailBatubara.sumber_batu?.jenis_perusahaan} {capitalizeEachWord(detailBatubara.sumber_batu?.name)}
                    </Typography> : <Typography
                      sx={{ fontSize: 14, color: '#081130', }}
                      className="w-700"
                    >
                      -
                    </Typography>}
                    {/* Loading Port Section */}
                    <Typography
                      sx={{ fontSize: 14, color: '#818CA7', mt: '16px' }}
                      className="w-500"
                    >
                      {translate('detail-explore.lokasiloadingport')}
                    </Typography>
                    <Typography
                      sx={{ fontSize: 14, color: '#081130', }}
                      className="w-700"
                    >
                      {detailBatubara.loading_port !== null ? capitalizeEachWord(detailBatubara.loading_port?.name) : '-'}
                    </Typography>
                    {/* provinsi Section */}
                    <Typography
                      sx={{ fontSize: 14, color: '#818CA7', mt: '16px' }}
                      className="w-500"
                    >
                      {translate('login-register.provinsi')}
                    </Typography>
                    <Typography
                      sx={{ fontSize: 14, color: '#081130', }}
                      className="w-700"
                    >
                      {detailBatubara.location !== null ? detailBatubara.location?.name : '-'}

                    </Typography>
                    {/* Laycandate Section */}
                    <Typography
                      sx={{ fontSize: 14, color: '#818CA7', mt: '16px' }}
                      className="w-500"
                    >
                      Laycan date
                    </Typography>
                    <Typography
                      sx={{ fontSize: 14, color: '#081130', }}
                      className="w-700"
                    >
                      {detailBatubara.laycan_date !== null ? `${new Date(detailBatubara.laycan_date).toLocaleDateString('id')}` : '-'}

                    </Typography>

                    <Typography
                      sx={{ fontSize: 14, mt: 1, color: '#818CA7' }}
                      className="w-500"
                    >
                      {translate('detail-explore.deskripsi')}
                    </Typography>

                    <Typography
                      sx={{ fontSize: 14, color: '#081130', }}
                      className="w-700"
                    >
                      {detailBatubara.description ? detailBatubara.description : '-'}
                    </Typography>
                    <Divider style={{ backgroundColor: "#E8EBF2", width: '100%', marginTop: '24px', marginBottom: '24px' }} />
                    <Typography
                      sx={{ fontSize: 18, color: '#081130' }}
                      className="w-700"
                    >
                      {translate('page_explore.document')}
                    </Typography>
                    {docBatubara !== undefined && (docBatubara.length !== 0) ?
                      <Grid sx={{ mt: '16px' }} container direction={'row'} spacing={2}>
                        {docBatubara.map((item, i) => (
                          <Grid item lg={3}>
                            <Card sx={{ borderRadius: 2 }}>
                              <DisplayPDFFile
                                url={`${config.baseURL}${item.url}`}
                                key={i}
                                ext={`${item.ext}`}
                                name={`${item.name}`}
                              />
                            </Card>
                          </Grid>
                        ))}
                      </Grid> : '-'}

                    <Divider style={{ backgroundColor: "#E8EBF2", width: '100%', marginTop: '24px', marginBottom: '24px' }} />
                  </Box> : null}
                {/* nikel Section */}
                {activeTab === 'nikel' ?
                  <Box sx={{ mb: 2 }}>

                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={0}
                    >
                      <Grid item >
                        {detailNikel?.code_product &&
                          <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            <Typography
                              sx={{ fontSize: { xs: 18, sm: 18, lg: 28 }, color: '#081130' }}
                              className="w-700"
                            >
                              {detailNikel?.code_product}
                            </Typography>
                            <Box
                              sx={{
                                top: '10%',
                                background: "#F4F6FA",
                                borderRadius: 4,
                                pr: 2,
                                pl: 2,
                                pb: '2px',
                                pt: '2px',

                              }}
                            >

                              <Stack direction={'row'} alignItems={'center'}>

                                {detailNikel.status_listing === 1 && detailNikel.is_sold === false ? (
                                  <>
                                    <Box sx={{ borderRadius: '50%', background: '#3BB56C', width: '10px', height: '10px', mr: 1 }}>
                                    </Box>
                                    <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 14 }} >
                                      Publish
                                    </Typography>
                                  </>

                                ) : (
                                  <></>
                                )}

                                {detailNikel.status_listing === 2 || detailNikel.is_sold === true ? (
                                  <>
                                    <Box sx={{ borderRadius: '50%', background: '#F7CE46', width: '10px', height: '10px', mr: 1 }}>
                                    </Box>
                                    <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 14 }} >
                                      Unpublish
                                    </Typography>
                                  </>
                                ) : (
                                  <></>
                                )}
                                {detailNikel.status_listing === 3 ? (
                                  <>
                                    <Box sx={{ borderRadius: '50%', background: '#56617B', width: '10px', height: '10px', mr: 1 }}>
                                    </Box>
                                    <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 14 }} >
                                      Draft
                                    </Typography>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Stack>
                            </Box>
                            {detailNikel.is_sold === true && <Box
                              sx={{
                                top: '10%',
                                background: "#F4F6FA",
                                borderRadius: 4,
                                pr: 2,
                                pl: 2,
                                pb: '2px',
                                pt: '2px',

                              }}
                            >

                              <Stack direction={'row'} alignItems={'center'}>

                                <Box sx={{ borderRadius: '50%', background: '#F7CE46', width: '10px', height: '10px', mr: 1 }}>
                                </Box>
                                <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 14 }} >
                                  {translate('status.soldout')}
                                </Typography>


                              </Stack>
                            </Box>}
                          </Stack>
                        }
                        <Grid
                          container
                          justifyContent="left"
                          alignItems="center"
                          direction="row"
                        >

                          <Typography
                            className="w-500"
                            sx={{ fontSize: 14, color: "#56617B", mr: 1 }}
                          >
                            {translate('page_explore.listingoleh')} :
                          </Typography>
                          {
                            detailNikel?.user_picture?.url !== undefined ? (
                              <img style={{ marginRight: '8px', borderRadius: "100%" }} alt="img-icon" src={config.baseURL + detailNikel?.user_picture.url} height={20} width={20}></img>
                            )
                              : (
                                <Avatar sx={{ bgcolor: 'blue', width: '20px', height: '20px', mr: 1 }} aria-label="recipe">
                                </Avatar>
                              )
                          }
                          <Typography
                            className="w-500"
                            sx={{ fontSize: 14, color: "#081130", mr: 1 }}
                          >
                            {detailNikel?.user_name}
                          </Typography>
                          <Typography
                            className="w-500"
                            sx={{ fontSize: 14, color: "#56617B", mr: 1 }}
                          >
                            {translate('detail-explore.postdate')}
                          </Typography>
                          {detailNikel.createdAt && <Typography
                            className="w-500"
                            sx={{ fontSize: 14, color: "#081130", mr: 1 }}
                          >
                            {`${new Date(detailNikel.createdAt).toLocaleDateString('en-AU')}`}
                          </Typography>}
                        </Grid>
                      </Grid>
                      <Grid direction={'row'} sx={{ textAlign: "left", alignItems: "center", display: 'flex', mt: 1, mb: 1 }} item >
                        {detailNikel.price && <Typography
                          sx={{ fontSize: '24px', color: '#081130', mr: 1 }}
                          className="w-700"
                        >
                          {currency === 'idr' ? 'Rp' : '$'}   {numberConvert(parseInt(detailNikel.price))}
                        </Typography>}
                        <Typography
                          sx={{ fontSize: 14, color: '#56617B' }}
                          className="w-500"
                        >
                          /ton
                        </Typography>
                      </Grid>

                    </Grid>
                    <Divider style={{ backgroundColor: "#E8EBF2", width: '100%', marginTop: '24px', marginBottom: '24px' }} />
                    <Grid
                      container
                      direction="row"
                      alignItems="left"
                      justifyContent="left"
                      spacing={2}
                    >
                      <Grid item xs={6} md={3} >
                        <Box sx={{ background: "#F4F6FA", borderRadius: 4, padding: 2 }}>
                          <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>

                            <Typography className="w-700" sx={{ color: '#081130', fontSize: { md: '20px', xs: '18px' }, }}>
                              {numberConvert(detailNikel?.kadar_ni)}

                            </Typography>
                            <img alt="Nikel-icon" src={Niicon} height={20}></img>
                          </Stack>
                          <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                            {translate('page_explore.kadar-ni')}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6} md={3}  >
                        <Box sx={{ background: "#F4F6FA", borderRadius: 4, padding: 2 }}>
                          <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>

                            <Typography className="w-700" sx={{ color: '#081130', fontSize: { md: '20px', xs: '18px' }, }}>
                              {numberConvert(detailNikel?.kadar_fe)}

                            </Typography>
                            <img alt="Nikel-icon" src={Feicon} height={20}></img>
                          </Stack>
                          <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                            {translate('page_explore.kadar-fe')}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6} md={3}  >
                        <Box sx={{ background: "#F4F6FA", borderRadius: 4, padding: 2 }}>
                          <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>

                            <Typography className="w-700" sx={{ color: '#081130', fontSize: { md: '20px', xs: '18px' }, }}>
                              {numberConvert(detailNikel?.kadar_air)}

                            </Typography>
                            <img alt="Nikel-icon" src={Airicon} height={20}></img>
                          </Stack>
                          <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                            {translate('page_explore.kadar-air')}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6} md={3}  >
                        <Box sx={{ background: "#F4F6FA", borderRadius: 4, padding: 2 }}>
                          <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>

                            <Typography className="w-700" sx={{ color: '#081130', fontSize: { md: '20px', xs: '18px' }, }}>
                              {numberConvert(detailNikel?.si_mg_ratio)}

                            </Typography>
                            <img alt="Nikel-icon" src={Ratioicon} height={20}></img>
                          </Stack>
                          <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                            Si/Mg
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6} md={3} >
                        <Box sx={{ background: "#F4F6FA", borderRadius: 4, padding: 2 }}>
                          <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>

                            <Typography className="w-700" sx={{ color: '#081130', fontSize: { md: '20px', xs: '18px' }, mr: '8px' }}>
                              {numberConvert(detailNikel?.quantity)}
                            </Typography>
                            <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                              MT
                            </Typography>
                          </Stack>
                          <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                            Quantity
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>

                    {/* SumberBatu Section */}
                    <Typography
                      sx={{ fontSize: 14, color: '#818CA7', mt: '16px' }}
                      className="w-500"
                    >
                      {translate('status.sumberbatu')}
                    </Typography>
                    {detailNikel.sumber_batu ? <Typography
                      sx={{ fontSize: 14, color: '#081130', }}
                      className="w-700"
                    >
                      {detailNikel.sumber_batu?.jenis_perusahaan} {capitalizeEachWord(detailNikel.sumber_batu?.name)}
                    </Typography> : <Typography
                      sx={{ fontSize: 14, color: '#081130', }}
                      className="w-700"
                    >
                      -
                    </Typography>}
                    {/* Loading Port Section */}
                    <Typography
                      sx={{ fontSize: 14, color: '#818CA7', mt: '16px' }}
                      className="w-500"
                    >
                      {translate('detail-explore.lokasiloadingport')}
                    </Typography>
                    <Typography
                      sx={{ fontSize: 14, color: '#081130', }}
                      className="w-700"
                    >
                      {detailNikel.loading_port !== null ? capitalizeEachWord(detailNikel.loading_port?.name) : '-'}
                    </Typography>
                    {/* provinsi Section */}
                    <Typography
                      sx={{ fontSize: 14, color: '#818CA7', mt: '16px' }}
                      className="w-500"
                    >
                      {translate('login-register.provinsi')}
                    </Typography>
                    <Typography
                      sx={{ fontSize: 14, color: '#081130', }}
                      className="w-700"
                    >
                      {detailNikel.location !== null ? detailNikel.location?.name : '-'}

                    </Typography>
                    {/* Laycandate Section */}
                    <Typography
                      sx={{ fontSize: 14, color: '#818CA7', mt: '16px' }}
                      className="w-500"
                    >
                      Laycan date
                    </Typography>
                    <Typography
                      sx={{ fontSize: 14, color: '#081130', }}
                      className="w-700"
                    >
                      {detailNikel.laycan_date !== null ? `${new Date(detailNikel.laycan_date).toLocaleDateString('id')}` : '-'}

                    </Typography>

                    <Typography
                      sx={{ fontSize: 14, mt: 1, color: '#818CA7' }}
                      className="w-500"
                    >
                      {translate('detail-explore.deskripsi')}
                    </Typography>

                    <Typography
                      sx={{ fontSize: 14, color: '#081130', }}
                      className="w-700"
                    >
                      {detailNikel.description ? detailNikel.description : '-'}
                    </Typography>

                    <Divider style={{ backgroundColor: "#E8EBF2", width: '100%', marginTop: '24px', marginBottom: '24px' }} />
                    <Typography
                      sx={{ fontSize: 18, color: '#081130', }}
                      className="w-700"
                    >
                      {translate('page_explore.document')}
                    </Typography>
                    {docNikel !== undefined && (docNikel.length !== 0) ?
                      <Grid sx={{ mt: '16px' }} container direction={'row'} spacing={2}>
                        {docNikel.map((item, i) => (
                          <Grid item lg={3}>
                            <Card sx={{ borderRadius: 2 }}>
                              <DisplayPDFFile
                                url={`${config.baseURL}${item.url}`}
                                key={i}
                                ext={`${item.ext}`}
                                name={`${item.name}`}
                              />
                            </Card>
                          </Grid>
                        ))}
                      </Grid> : '-'}

                    <Divider style={{ backgroundColor: "#E8EBF2", width: '100%', marginTop: '24px', marginBottom: '24px' }} />
                  </Box> : null}
                {/* tongkang Section */}
                {activeTab === 'tongkang' ?
                  <Box sx={{ mb: 2 }}>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={0}
                    >
                      <Grid item >
                        {detailTongkang?.code_product &&
                          <Stack direction={'row'} alignItems={'center'} spacing={1}>
                            <Typography
                              sx={{ fontSize: { xs: 18, sm: 18, lg: 28 }, color: '#081130' }}
                              className="w-700"
                            >
                              {detailTongkang?.code_product}
                            </Typography>
                            <Box
                              sx={{
                                top: '10%',
                                background: "#F4F6FA",
                                borderRadius: 4,
                                pr: 2,
                                pl: 2,
                                pb: '2px',
                                pt: '2px',

                              }}
                            >  <Stack direction={'row'} alignItems={'center'}>

                                {detailTongkang.status_listing === 1 && detailTongkang.is_sold === false ? (
                                  <>
                                    <Box sx={{ borderRadius: '50%', background: '#3BB56C', width: '10px', height: '10px', mr: 1 }}>
                                    </Box>
                                    <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 14 }} >
                                      Publish
                                    </Typography>
                                  </>

                                ) : (
                                  <></>
                                )}

                                {detailTongkang.status_listing === 2 || detailTongkang.is_sold === true ? (
                                  <>
                                    <Box sx={{ borderRadius: '50%', background: '#F7CE46', width: '10px', height: '10px', mr: 1 }}>
                                    </Box>
                                    <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 14 }} >
                                      Unpublish
                                    </Typography>
                                  </>
                                ) : (
                                  <></>
                                )}
                                {detailTongkang.status_listing === 3 ? (
                                  <>
                                    <Box sx={{ borderRadius: '50%', background: '#56617B', width: '10px', height: '10px', mr: 1 }}>
                                    </Box>
                                    <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 14 }} >
                                      Draft
                                    </Typography>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Stack>
                            </Box>
                            <Box
                              sx={{
                                top: '10%',
                                background: "#F4F6FA",
                                borderRadius: 4,
                                pr: 2,
                                pl: 2,
                                pb: '2px',
                                pt: '2px',

                              }}
                            >  <Stack direction={'row'} alignItems={'center'}>
                                {detailTongkang.type_tongkang === 1 ? (
                                  <>
                                    <Box sx={{ borderRadius: '50%', background: '#F7CE46', width: '10px', height: '10px', mr: 1 }}>
                                    </Box>
                                    <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 14 }} >
                                      {translate('status.jual')}
                                    </Typography>
                                  </>
                                ) : (
                                  <></>
                                )}

                                {detailTongkang.type_tongkang === 2 ? (
                                  <>
                                    <Box sx={{ borderRadius: '50%', background: '#F55C24', width: '10px', height: '10px', mr: 1 }}>
                                    </Box>
                                    <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 14 }} >
                                      Time Charter
                                    </Typography>
                                  </>
                                ) : (
                                  <></>
                                )}
                                {detailTongkang.type_tongkang === 3 ? (
                                  <>
                                    <Box sx={{ borderRadius: '50%', background: '#883BE7', width: '10px', height: '10px', mr: 1 }}>
                                    </Box>
                                    <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 14 }} >
                                      Freight Charter
                                    </Typography>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Stack>
                            </Box>
                            {detailTongkang.is_sold === true && <Box
                              sx={{
                                top: '10%',
                                background: "#F4F6FA",
                                borderRadius: 4,
                                pr: 2,
                                pl: 2,
                                pb: '2px',
                                pt: '2px',

                              }}
                            >
                              <Stack direction={'row'} alignItems={'center'}>
                                <Box sx={{ borderRadius: '50%', background: '#F7CE46', width: '10px', height: '10px', mr: 1 }}>
                                </Box>
                                <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 14 }} >
                                  {translate('status.soldout')}
                                </Typography>

                              </Stack>
                            </Box>}
                          </Stack>
                        }
                        <Grid
                          container
                          justifyContent="left"
                          alignItems="center"
                          direction="row"
                        >

                          <Typography
                            className="w-500"
                            sx={{ fontSize: 14, color: "#56617B", mr: 1 }}
                          >
                            {translate('page_explore.listingoleh')} :
                          </Typography>
                          {
                            detailTongkang?.user_picture?.url !== undefined ? (
                              <img style={{ marginRight: '8px', borderRadius: "100%" }} alt="img-icon" src={config.baseURL + detailTongkang.user_picture.url} height={20} width={20}></img>
                            )
                              : (
                                <Avatar sx={{ bgcolor: 'blue', width: '20px', height: '20px', mr: 1 }} aria-label="recipe">
                                </Avatar>
                              )
                          }
                          <Typography
                            className="w-500"
                            sx={{ fontSize: 14, color: "#081130", mr: 1 }}
                          >
                            {detailTongkang.user_name}
                          </Typography>
                          <Typography
                            className="w-500"
                            sx={{ fontSize: 14, color: "#56617B", mr: 1 }}
                          >
                            {translate('detail-explore.postdate')} :
                          </Typography>
                          {detailTongkang.createdAt && <Typography
                            className="w-500"
                            sx={{ fontSize: { xs: 12, sm: 12, md: 14, lg: 14, xl: 14 }, color: "#081130", mr: '6px' }}
                          >
                            {`${new Date(detailTongkang.createdAt).toLocaleDateString('id')}`}
                          </Typography>}
                        </Grid>
                      </Grid>

                      <Grid direction={'row'} sx={{ textAlign: "left", alignItems: "center", display: 'flex', mt: 1, mb: 1 }} item >
                        <Typography
                          sx={{ fontSize: '24px', color: '#081130', mr: 1 }}
                          className="w-700"
                        >
                          {currency === 'idr' ? 'Rp' : '$'}  {numberConvert(parseInt(detailTongkang.price))}

                        </Typography>
                        <Typography
                          sx={{ fontSize: 14, color: '#56617B' }}
                          className="w-500"
                        >
                          {detailTongkang.type_tongkang === 1 && "/set"}
                          {detailTongkang.type_tongkang === 2 && "/month"}
                          {detailTongkang.type_tongkang === 3 && "/MT"}
                        </Typography>
                      </Grid>

                    </Grid>
                    <Box>
                      <Divider style={{ backgroundColor: "#E8EBF2", width: '100%', marginTop: '24px', marginBottom: '24px' }} />
                      <Typography
                        sx={{ fontSize: '24px', color: '#081130' }}
                        className="w-700"
                      >
                        {detailTongkang.nama_tug_boat}
                      </Typography>
                      <Typography
                        sx={{ fontSize: 18, color: '#56617B', mb: '24px' }}
                        className="w-700"
                      >
                        {detailTongkang.nama_tongkang}
                      </Typography>
                    </Box>
                    <Box className="box-slide">
                      <Slider {...settings}>
                        {
                          imageTongkang.map((item) => (
                            <Grid item sx={{ width: { xs: '253px' }, }}>
                              <Box sx={{ padding: { sm: '2', xs: 1 } }}>
                                <img height={154} style={{ borderRadius: '8px' }} alt="Nikel-icon" src={config.baseURL + item.url} className="img-fluid"></img>
                              </Box>
                            </Grid>
                          ))
                        }
                        {
                          imageTongkang.map((item) => (
                            <Grid item>
                              <Box sx={{ padding: { sm: '2', xs: 1 } }}>
                                <img height={154} style={{ borderRadius: '8px' }} alt="Nikel-icon" src={config.baseURL + item.url} className="img-fluid"></img>
                              </Box>
                            </Grid>
                          ))
                        }
                        {
                          imageTongkang.map((item) => (
                            <Grid item>
                              <Box sx={{ padding: { sm: '2', xs: 1 } }}>
                                <img height={154} style={{ borderRadius: '8px' }} alt="Nikel-icon" src={config.baseURL + item.url} className="img-fluid"></img>
                              </Box>
                            </Grid>
                          ))
                        }
                        {
                          imageTongkang.map((item) => (
                            <Grid item>
                              <Box sx={{ padding: { sm: '2', xs: 1 } }}>
                                <img height={154} style={{ borderRadius: '8px' }} alt="Nikel-icon" src={config.baseURL + item.url} className="img-fluid"></img>
                              </Box>
                            </Grid>
                          ))
                        }
                      </Slider>

                    </Box>
                    <Grid
                      marginTop={'24px'}
                      marginBottom={'24px'}
                      container
                      direction="row"
                      alignItems="left"
                      justifyContent="left"
                      spacing={2}
                    >
                      <Grid item xs={6} md={3}>
                        <Box sx={{ background: "#F4F6FA", borderRadius: 4, padding: 2 }}>
                          <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>
                            <Stack direction={'row'} alignItems={'center'}>
                              <Typography className="w-700" sx={{ color: '#081130', fontSize: { md: '20px', xs: '18px' } }}>
                                {detailTongkang?.ukuran_tongkang === '' ? '-' : numberConvert(detailTongkang?.ukuran_tongkang)}

                              </Typography>
                              <Typography className="w-700" sx={{ color: '#56617B', fontSize: { md: '12px', xs: '12px' }, ml: 1 }}>
                                FT
                              </Typography>
                            </Stack>

                            <img alt="Nikel-icon" src={SizetongkangIcon} height={20}></img>
                          </Stack>
                          <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                            {translate('page_explore.ukurantongkang')}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6} md={3} >
                        <Box sx={{ background: "#F4F6FA", borderRadius: 4, padding: 2 }}>
                          <Stack direction={'row'} sx={{ justifyContent: 'space-between' }} spacing={1}>

                            <Typography className="w-700" sx={{ color: '#081130', fontSize: { md: '20px', xs: '18px' } }}>
                              {detailTongkang.tahun_buat !== 0 ? detailTongkang.tahun_buat : '-'}
                            </Typography>
                            <img alt="Nikel-icon" src={laycanIcon} height={20}></img>
                          </Stack>
                          <Typography className="w-500" sx={{ color: '#56617B', fontSize: { md: '14px', xs: '12px' } }} >
                            {translate('permintaan.tahunbuat')}
                          </Typography>
                        </Box>
                      </Grid>

                    </Grid>

                    <Box>
                      {/* Lokasi tongkang Section */}
                      {(detailTongkang.type_tongkang === 2 || detailTongkang?.type_tongkang === 1) && <div>
                        <Typography
                          sx={{ fontSize: 14, color: '#56617B' }}
                          className="w-500"
                        >
                          {translate('title.location-tongkang')}
                        </Typography>
                        <Typography
                          sx={{ fontSize: 14, color: '#081130', mt: '4px' }}
                          className="w-700"
                        >
                          {detailTongkang?.tongkang_location ? detailTongkang?.tongkang_location?.name : "-"}
                        </Typography>

                      </div>}
                      {/* Lokasi tongkang Freight Charter Section */}
                      {detailTongkang.type_tongkang === 3 &&
                        <div>
                          <Typography
                            sx={{ fontSize: 14, color: '#56617B' }}
                            className="w-500"
                          >
                            Loading Area
                          </Typography>
                          <Typography
                            sx={{ fontSize: 14, color: '#081130', mt: '4px' }}
                            className="w-700"
                          >
                            {detailTongkang?.area_muat ? detailTongkang?.area_muat?.name : "-"}
                          </Typography>

                          <Typography
                            sx={{ fontSize: 14, color: '#56617B' }}
                            className="w-500"
                          >
                            Discharge Area
                          </Typography>
                          <Typography
                            sx={{ fontSize: 14, color: '#081130', mt: '4px' }}
                            className="w-700"
                          >
                            {detailTongkang?.area_bongkar ? detailTongkang?.area_bongkar?.name : "-"}
                          </Typography>


                          <Typography
                            sx={{ fontSize: 14, color: '#56617B', mt: '4px' }}
                            className="w-500"
                          >
                            Loading Port
                          </Typography>
                          <Typography
                            sx={{ fontSize: 14, color: '#081130', mt: '4px' }}
                            className="w-700"
                          >
                            {detailTongkang?.pelabuhan_muat ? detailTongkang?.pelabuhan_muat?.name : "-"}
                          </Typography> <Typography
                            sx={{ fontSize: 14, color: '#56617B' }}
                            className="w-500"
                          >
                            Discharge Port
                          </Typography>
                          <Typography
                            sx={{ fontSize: 14, color: '#081130', mt: '4px' }}
                            className="w-700"
                          >
                            {detailTongkang?.pelabuhan_bongkar ? detailTongkang?.pelabuhan_bongkar?.name : "-"}
                          </Typography>

                        </div>}

                      <Typography
                        sx={{ fontSize: 14, color: '#56617B', mt: '4px' }}
                        className="w-500"
                      >
                        {translate('detail-explore.deskripsi')}
                      </Typography>
                      <Typography
                        sx={{ fontSize: 14, color: '#081130', mt: '4px' }}
                        className="w-700"
                      >
                        {detailTongkang?.description ? detailTongkang?.description : '-'}

                      </Typography>
                      <Divider style={{ backgroundColor: "#E8EBF2", width: '100%', marginTop: '24px', marginBottom: '24px' }} />

                      <Typography
                        sx={{ fontSize: 18, color: '#081130' }}
                        className="w-700"
                      >
                        {translate('page_explore.document')}
                      </Typography>
                      {docTongkang !== undefined && (docTongkang.length !== 0) ?
                        <Grid sx={{ mt: '16px' }} container direction={'row'} spacing={2}>
                          {docTongkang.map((item, i) => (
                            <Grid item lg={3}>
                              <Card sx={{ borderRadius: 2 }}>
                                <DisplayPDFFile
                                  url={`${config.baseURL}${item.url}`}
                                  key={i}
                                  ext={`${item.ext}`}
                                  name={`${item.name}`}
                                />
                              </Card>
                            </Grid>
                          ))}
                        </Grid> : '-'}
                    </Box>
                  </Box> : null}
              </Grid>

              {/* rightside Section */}

              <Grid lg={3.6} xs={12} item>

                {activeTab === 'coal' ?
                  <Box >

                    <Box sx={{ border: 1, color: '#E8EBF2', borderRadius: 2 }}>
                      {(detailBatubara.company_listing !== undefined && detailBatubara.company_listing !== null) &&
                        <Box>
                          <Typography className="w-700" sx={{ color: '#081130', fontSize: 18, mt: 1, pl: 2 }}>
                            {translate('detail-explore.tentangperusahaan')}
                          </Typography>

                          <Box sx={{ pl: 2, pr: 2, mt: 2, }}>
                            <Grid
                              container
                              direction="row"
                              alignItems="left"
                              justifyContent="left"
                            >
                              {detailBatubara.company_listing?.picture_path?.url !== undefined &&

                                <Avatar sx={{ bgcolor: 'blue', mr: '16px' }} aria-label="recipe" src={config.baseURL + detailBatubara.company_listing?.picture_path?.url}>

                                </Avatar>
                              }
                              <Stack style={{ textAlign: "left", color: "white" }} spacing={0}>

                                <Typography
                                  sx={{ fontSize: 16, color: '#081130' }}
                                  className="w-700"
                                >
                                  {detailBatubara.company_listing.jenis_perusahaan} {capitalizeEachWord(detailBatubara.company_listing.name)}

                                </Typography>
                                <Grid className="pointer" onClick={(e) => handleNavigate(detailBatubara.company_listing.id, detailBatubara.company_listing.name)}
                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 16, color: "#2457B9", mr: 1 }}
                                  >
                                    {translate('detail-explore.lihatdetailperusahaan')}
                                  </Typography>
                                  <ArrowForwardIosIcon sx={{ fontSize: 12, color: "#2457B9", pt: '3px' }} />
                                </Grid>
                              </Stack>
                            </Grid>
                          </Box>

                          <Box sx={{ ml: 2, mr: 2, mt: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0 }, mb: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0 }, background: { xs: "#F4F6FA", sm: "#F4F6FA", md: "#F4F6FA", lg: "none", xl: "none" }, borderRadius: 4, padding: 2 }}>
                            <Box sx={{ pr: 2, mb: 2 }}>
                              {detailBatubara.company_listing.bidang_perusahaan === 1 ? (
                                <Grid
                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <img alt="penambang-icon" src={PenambangIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                  >
                                    {translate('page_explore.penambang')}
                                  </Typography>

                                </Grid>
                              ) : detailBatubara.company_listing.bidang_perusahaan === 2 ? (
                                <Grid

                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <img alt="penambang-icon" src={traderIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                  >
                                    Trader
                                  </Typography>

                                </Grid>
                              ) : detailBatubara.company_listing.bidang_perusahaan === 3 ? (
                                <Grid
                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <img alt="penambang-icon" src={pelayarIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                  >
                                    {translate('page_explore.pelayaran')}
                                  </Typography>

                                </Grid>
                              ) : (
                                <></>
                              )}
                            </Box>

                            <Box sx={{ pr: 2, mt: 1, mb: 2 }}>
                              {detailBatubara.company_listing.alamat &&
                                <Grid

                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <img alt="call-icon" src={locationCompanyIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                  >
                                    {detailBatubara.company_listing.alamat[0].label_alamat}
                                  </Typography>

                                </Grid>}
                            </Box>
                            <Box sx={{ pr: 2, mt: 1, mb: 2 }}>
                              {detailBatubara.company_listing.phone_number &&
                                <Grid

                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <img alt="Nikel-icon" src={callCompanyIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                  >
                                    {detailBatubara.company_listing.phone_number}
                                  </Typography>

                                </Grid>}
                            </Box>
                            <Box sx={{ pr: 2, mt: 1, mb: 2 }}>
                              {detailBatubara.company_listing.email &&
                                <Grid

                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <img alt="Nikel-icon" src={mailCompanyIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                  >
                                    {detailBatubara.company_listing.email}
                                  </Typography>

                                </Grid>}
                            </Box>
                          </Box>
                        </Box>}
                      <Hidden lgDown>
                        <Divider orientation="horizontal" style={{ backgroundColor: "#E8EBF2", marginLeft: '16px', marginRight: '16px', marginBottom: '24px' }} />

                        <Box sx={{ pl: 2, pr: 2, mt: 1, mb: 2 }}>
                          {detailBatubara?.is_sold === false && detailBatubara?.status_listing !== 1 ? <Button className="w-500" size="medium" onClick={(e) => navigate('/jual-batubara', { state: { page: 'edit-batubara', data: detailBatubara } })} sx={{
                            fontSize: 16,
                            p: '12px 16px',
                            border: '1px solid var(--primarry-blue, #2457B9)',
                            lineHeight: '22px',
                            background: '#2457B9', mb: 2,
                            borderRadius: '8px', boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.12), 0px -1px 0px 0px rgba(0, 0, 0, 0.20) inset;',
                            textTransform: 'none'
                          }} variant="contained" fullWidth startIcon={<img alt="Nikel-icon" src={editIcon} height={24}></img>}>
                            Edit listing
                          </Button> : <></>}
                          {detailBatubara?.is_sold === 0 || detailBatubara?.is_sold === false && <Button className="w-500" onClick={() => ActionTandaiTerjual(detailBatubara.id, 'coal')} sx={{
                            textTransform: "none", border: '1px solid var(--primarry-blue, #2457B9)',
                            p: '12px 16px',
                            borderRadius: '8px',
                            fontSize: 16,
                            lineHeight: '22px',
                            mb: 2,
                            color: '#2457B9'
                          }} size="medium" variant="outlined" fullWidth startIcon={<img alt="Nikel-icon" src={soldIcon} height={24}></img>}>
                            {translate('status.tandaiterjual')}
                          </Button>}
                          {detailBatubara?.is_sold === false &&
                            <>
                              {checkingFavorite === true ?
                                <Button className="w-500" onClick={() => removeFavorite(1)} sx={{
                                  textTransform: "none", border: '1px solid var(--primarry-blue, #2457B9)',
                                  p: '12px 16px',
                                  borderRadius: '8px',
                                  fontSize: 16,
                                  lineHeight: '22px',
                                  mb: 2,
                                  color: '#2457B9'
                                }} size="medium" variant="outlined" fullWidth startIcon={<FavoriteTwoToneIcon sx={{ fill: "red" }} />}>
                                  {translate('status.hapusfavorite')}
                                </Button>
                                :
                                <Button className="w-500" onClick={() => setFavorite(1)} sx={{
                                  textTransform: "none", border: '1px solid var(--primarry-blue, #2457B9)',
                                  p: '12px 16px',
                                  borderRadius: '8px',
                                  fontSize: 16,
                                  lineHeight: '22px',
                                  mb: 2,
                                  color: '#2457B9'
                                }} size="medium" variant="outlined" fullWidth startIcon={<FavoriteBorderIcon />}>
                                  {translate('detail-explore.tambahkanfavorit')}
                                </Button>
                              }
                            </>}
                          {detailBatubara?.is_sold === 1 || detailBatubara?.is_sold === true && <Button className="w-500" onClick={() => {
                            handleOpenModal("hapus", detailBatubara?.status_listing);
                          }} sx={{
                            "&:hover": {
                              borderColor: '#ED3131',
                            },
                            textTransform: "none", border: '1px solid var(--primarry-blue, #ED3131)',
                            p: '12px 16px',
                            borderRadius: '8px',
                            fontSize: 16,
                            lineHeight: '22px',
                            color: '#ED3131'
                          }} size="medium" variant="outlined" fullWidth startIcon={<img alt="Nikel-icon" src={deleteIcon} height={24}></img>}>
                            {translate('status.hapus')}
                          </Button>}
                          <ModalHapusListing type={'coal'} id={detailBatubara.id} open={isOpenModalHapus} close={handleCloseModalHapus}></ModalHapusListing>

                        </Box>
                        {detailBatubara?.is_sold === 0 || detailBatubara?.is_sold === false &&
                          <Box sx={{ pl: 2, pr: 2, mt: 1, mb: 2 }}>
                            <Stack
                              justifyContent="space-evenly"
                              alignItems="center"
                              direction="row"
                            >
                              <Stack onClick={() => {
                                handleOpenModal("hapus", detailBatubara?.status_listing);
                              }} direction={'row'} alignItems={'center'} sx={{ cursor: 'pointer' }}>
                                <img alt="Nikel-icon" src={deleteIcon} height={24}></img>
                                <Typography
                                  className="w-500"
                                  sx={{ fontSize: 14, color: "#ED3131", ml: 1, }}
                                >
                                  {translate('status.hapus')}
                                </Typography>
                              </Stack>
                              <ModalHapusListing type={'coal'} id={detailBatubara.id} open={isOpenModalHapus} close={handleCloseModalHapus}></ModalHapusListing>
                              <Divider orientation="vertical" style={{ width: '1px' }} flexItem></Divider>


                              {detailBatubara.status_listing === 1 &&
                                <Stack onClick={() => {
                                  handleOpenModal("unpublish", detailBatubara?.status_listing);
                                }} direction={'row'} alignItems={'center'} sx={{ cursor: 'pointer' }}>
                                  <img alt="Nikel-icon" src={unpublishIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#56617B", ml: 1, }}
                                  >
                                    Unpublish
                                  </Typography>
                                </Stack>}
                              {detailBatubara.status_listing === 2 &&
                                <Stack onClick={() => {
                                  handleOpenModal("publish", detailBatubara?.status_listing);
                                }} direction={'row'} alignItems={'center'} sx={{ cursor: 'pointer' }}>
                                  <img alt="Nikel-icon" src={visibilityIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#56617B", ml: 1, }}
                                  >
                                    Publish
                                  </Typography>
                                </Stack>
                              }
                              {detailBatubara.status_listing === 3 && <Stack onClick={() => {
                                navigate('/jual-batubara', { state: { page: 'edit-batubara', data: detailBatubara } })
                              }} direction={'row'} alignItems={'center'} sx={{ cursor: 'pointer' }}>
                                <img alt="Nikel-icon" src={visibilityIcon} height={24}></img>
                                <Typography
                                  className="w-500"
                                  sx={{ fontSize: 14, color: "#56617B", ml: 1, }}
                                >
                                  Publish
                                </Typography>
                              </Stack>}
                              <ModalUnpublish type={'coal'} status={statuslisting} id={detailBatubara?.id} open={isOpenModalUnpublish} close={handleCloseModalUnpublish}></ModalUnpublish>
                            </Stack>
                          </Box>}
                      </Hidden>
                    </Box>
                    <Hidden lgDown>
                      <Grid container display="flex" justifyContent="space-between" direction={"row"}>
                        <Typography
                          className="w-700"
                          sx={{ fontSize: 18, color: '#081130', mt: '24px', mb: '12px' }}
                        >
                          {translate('detail-explore.bagikanlisting')}
                        </Typography>
                        {copyClipboard}
                      </Grid>
                      <Box sx={{ background: '#F4F6FA', border: '1px solid #E8EBF2', borderRadius: '8px', pt: '16px', pl: '16px', pr: '16px', pb: '16px' }}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                          <Typography className="w-700" sx={{ color: '#56617B', fontSize: 14, }}>
                            {(`${config.staging}/listing/detail?type=1&id_detail=${location.state.id}`).slice(0, 42) + '...'}
                          </Typography>
                          <img alt="copy-icon" src={copyIc} className="pointer" height={20} onClick={(e) => {
                            navigator.clipboard.writeText(`${config.staging}/listing/detail?type=1&id_detail=${location.state.id}`)
                            copyLink()
                          }}></img>
                        </Stack>
                      </Box>
                      <Stack sx={{ mt: '16px' }} direction={'row'} justifyContent={'space-evenly'} alignItems={'center'}>
                        <TwitterShareButton url={`${config.staging}/listing/detail?type=1&id_detail=${location.state.id}`}><img alt="twitter-icon" src={twitterIcon} height={'40px'} width={'40px'} /></TwitterShareButton>
                        <WhatsappShareButton url={`${config.staging}/listing/detail?type=1&id_detail=${location.state.id}`}><img alt="wa-icon" src={waIcon} height={'40px'} width={'40px'} /> </WhatsappShareButton>
                        <TelegramShareButton url={`${config.staging}/listing/detail?type=1&id_detail=${location.state.id}`}> <img alt="tele-icon" src={telegramIcon} height={'40px'} width={'40px'} /></TelegramShareButton>
                        <FacebookShareButton url={`${config.staging}/listing/detail?type=1&id_detail=${location.state.id}`} ><img alt="fb-icon" src={fbIcon} height={'40px'} width={'40px'} /></FacebookShareButton>
                      </Stack>
                    </Hidden>
                  </Box>
                  : ''}
                {activeTab === 'nikel' ?
                  <Box >

                    <Box sx={{ border: 1, color: '#E8EBF2', borderRadius: 2 }}>
                      {(detailNikel.company_listing !== null && detailNikel.company_listing !== undefined) &&
                        <Box>
                          <Typography className="w-700" sx={{ color: '#081130', fontSize: 18, mt: 1, pl: 2 }}>
                            {translate('detail-explore.tentangperusahaan')}
                          </Typography>

                          <Box sx={{ pl: 2, pr: 2, mt: 2, }}>
                            <Grid
                              container
                              direction="row"
                              alignItems="left"
                              justifyContent="left"

                            >
                              {detailNikel.company_listing?.picture_path?.url !== undefined &&
                                <Avatar sx={{ bgcolor: 'blue', mr: '16px' }} aria-label="recipe" src={config.baseURL + detailNikel.company_listing?.picture_path?.url}>
                                </Avatar>
                              }

                              <Stack style={{ textAlign: "left", color: "white" }} spacing={0}>
                                <Typography
                                  sx={{ fontSize: 16, color: '#081130' }}
                                  className="w-700"
                                >
                                  {detailNikel.company_listing.jenis_perusahaan} {capitalizeEachWord(detailNikel.company_listing.name)}

                                </Typography>
                                <Grid className="pointer"
                                  container onClick={(e) => handleNavigate(detailNikel.company_listing.id, detailNikel.company_listing.name)}
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 16, color: "#2457B9", mr: 1 }}
                                  >
                                    {translate('detail-explore.lihatdetailperusahaan')}
                                  </Typography>
                                  <ArrowForwardIosIcon sx={{ fontSize: 12, color: "#2457B9", pt: '3px' }} />
                                </Grid>
                              </Stack>
                            </Grid>
                          </Box>
                          <Box sx={{ ml: 2, mr: 2, mt: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0 }, mb: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0 }, background: { xs: "#F4F6FA", sm: "#F4F6FA", md: "#F4F6FA", lg: "none", xl: "none" }, borderRadius: 4, padding: 2 }}>

                            <Box sx={{ pr: 2, mb: 2 }}>
                              {detailNikel.company_listing.bidang_perusahaan === 1 ? (
                                <Grid
                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <img alt="penambang-icon" src={PenambangIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                  >
                                    {translate('page_explore.penambang')}
                                  </Typography>
                                </Grid>
                              ) : detailNikel.company_listing.bidang_perusahaan === 2 ? (
                                <Grid

                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <img alt="penambang-icon" src={traderIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                  >
                                    Trader
                                  </Typography>

                                </Grid>
                              ) : detailNikel.company_listing.bidang_perusahaan === 3 ? (
                                <Grid
                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <img alt="penambang-icon" src={pelayarIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                  >
                                    {translate('page_explore.pelayaran')}
                                  </Typography>

                                </Grid>
                              ) : (
                                <></>
                              )}
                            </Box>

                            <Box sx={{ pr: 2, mt: 1, mb: 2 }}>
                              {detailNikel.company_listing.alamat &&
                                <Grid
                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <img alt="call-icon" src={locationCompanyIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                  >
                                    {detailNikel.company_listing.alamat[0].label_alamat}
                                  </Typography>
                                </Grid>}
                            </Box>
                            <Box sx={{ pr: 2, mt: 1, mb: 2 }}>
                              {detailNikel.company_listing.phone_number &&
                                <Grid
                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <img alt="Nikel-icon" src={callCompanyIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                  >
                                    {detailNikel.company_listing.phone_number}
                                  </Typography>
                                </Grid>}
                            </Box>
                            <Box sx={{ pr: 2, mt: 1, mb: 2 }}>
                              {detailNikel.company_listing.email &&
                                <Grid
                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <img alt="Nikel-icon" src={mailCompanyIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                  >
                                    {detailNikel.company_listing.email}
                                  </Typography>

                                </Grid>}
                            </Box>
                          </Box>
                        </Box>}
                      <Hidden lgDown>
                        <Divider orientation="horizontal" style={{ backgroundColor: "#E8EBF2", marginLeft: '16px', marginRight: '16px', marginBottom: '24px' }} />

                        <Box sx={{ pl: 2, pr: 2, mt: 1, mb: 2 }}>
                          {detailNikel?.is_sold === false && detailNikel?.status_listing !== 1 ? <Button size="medium" onClick={(e) => navigate('/jual-nikel', { state: { page: 'edit-nikel', data: detailNikel } })} sx={{
                            fontSize: 16,
                            p: '12px 16px',
                            border: '1px solid var(--primarry-blue, #2457B9)',
                            lineHeight: '22px',
                            background: '#2457B9', mb: 2,
                            borderRadius: '8px', boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.12), 0px -1px 0px 0px rgba(0, 0, 0, 0.20) inset;',
                            textTransform: 'none'
                          }} variant="contained" fullWidth startIcon={<img alt="Nikel-icon" src={editIcon} height={24}></img>}>
                            Edit listing
                          </Button> : <></>}
                          {detailNikel?.is_sold === 0 || detailNikel?.is_sold === false && <Button onClick={() => ActionTandaiTerjual(detailNikel.id, 'nikel')} sx={{
                            textTransform: "none", border: '1px solid var(--primarry-blue, #2457B9)',
                            p: '12px 16px',
                            borderRadius: '8px',
                            fontSize: 16,
                            lineHeight: '22px',
                            mb: 2,
                            color: '#2457B9'
                          }} size="medium" variant="outlined" fullWidth startIcon={<img alt="Nikel-icon" src={soldIcon} height={24}></img>}>
                            {translate('status.tandaiterjual')}
                          </Button>}
                          {detailNikel?.is_sold === false &&
                            <>
                              {checkingFavorite === true ?
                                <Button onClick={() => removeFavorite(2)} sx={{
                                  textTransform: "none", border: '1px solid var(--primarry-blue, #2457B9)',
                                  p: '12px 16px',
                                  borderRadius: '8px',
                                  fontSize: 16,
                                  lineHeight: '22px',
                                  mb: 2,
                                  color: '#2457B9'
                                }} size="medium" variant="outlined" fullWidth startIcon={<FavoriteTwoToneIcon sx={{ fill: "red" }} />}>
                                  {translate('status.hapusfavorite')}
                                </Button>
                                :
                                <Button onClick={() => setFavorite(2)} sx={{
                                  textTransform: "none", border: '1px solid var(--primarry-blue, #2457B9)',
                                  p: '12px 16px',
                                  borderRadius: '8px',
                                  fontSize: 16,
                                  lineHeight: '22px',
                                  mb: 2,
                                  color: '#2457B9'
                                }} size="medium" variant="outlined" fullWidth startIcon={<FavoriteBorderIcon />}>
                                  {translate('detail-explore.tambahkanfavorit')}
                                </Button>
                              }
                            </>}
                          {detailNikel?.is_sold === 1 || detailNikel?.is_sold === true && <Button className="w-500" onClick={() => {
                            handleOpenModal("hapus", detailNikel?.status_listing);
                          }} sx={{
                            "&:hover": {
                              borderColor: '#ED3131',
                            },
                            textTransform: "none", border: '1px solid var(--primarry-blue, #ED3131)',
                            p: '12px 16px',
                            borderRadius: '8px',
                            fontSize: 16,
                            lineHeight: '22px',
                            color: '#ED3131'
                          }} size="medium" variant="outlined" fullWidth startIcon={<img alt="Nikel-icon" src={deleteIcon} height={24}></img>}>
                            {translate('status.hapus')}
                          </Button>}


                        </Box>
                        {detailNikel?.is_sold === 0 || detailNikel?.is_sold === false &&
                          <Box sx={{ pl: 2, pr: 2, mt: 1, mb: 2 }}>
                            <Stack
                              justifyContent="space-evenly"
                              alignItems="center"
                              direction="row"
                            >
                              <Stack onClick={() => {
                                handleOpenModal("hapus");
                              }} direction={'row'} alignItems={'center'} sx={{ cursor: 'pointer' }}>
                                <img alt="Nikel-icon" src={deleteIcon} height={24}></img>
                                <Typography
                                  className="w-500"
                                  sx={{ fontSize: 14, color: "#ED3131", ml: 1, }}
                                >
                                  {translate('status.hapus')}
                                </Typography>
                              </Stack>
                              <ModalHapusListing type={'nikel'} id={detailNikel.id} open={isOpenModalHapus} close={handleCloseModalHapus}></ModalHapusListing>
                              <Divider orientation="vertical" style={{ width: '1px' }} flexItem></Divider>
                              {
                                detailNikel.status_listing === 1 &&
                                <Stack onClick={() => {
                                  handleOpenModal("unpublish", detailNikel?.status_listing);
                                }} direction={'row'} alignItems={'center'} sx={{ cursor: 'pointer' }}>
                                  <img alt="Nikel-icon" src={unpublishIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#56617B", ml: 1, }}
                                  >
                                    Unpublish
                                  </Typography>
                                </Stack>}
                              {
                                detailNikel.status_listing === 2 &&
                                <Stack onClick={() => {
                                  handleOpenModal("publish", detailNikel?.status_listing);
                                }} direction={'row'} alignItems={'center'} sx={{ cursor: 'pointer' }}>
                                  <img alt="Nikel-icon" src={visibilityIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#56617B", ml: 1, }}
                                  >
                                    Publish
                                  </Typography>
                                </Stack>}
                              {
                                detailNikel.status_listing === 3 &&
                                <Stack onClick={() => {
                                  navigate('/jual-nikel', { state: { page: 'edit-nikel', data: detailNikel } })
                                }} direction={'row'} alignItems={'center'} sx={{ cursor: 'pointer' }}>
                                  <img alt="Nikel-icon" src={visibilityIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#56617B", ml: 1, }}
                                  >
                                    Publish
                                  </Typography>
                                </Stack>}
                              <ModalUnpublish type={'nikel'} status={statuslisting} id={detailNikel?.id} open={isOpenModalUnpublish} close={handleCloseModalUnpublish}></ModalUnpublish>
                            </Stack>
                          </Box>}
                      </Hidden>
                    </Box>
                    <Hidden lgDown>
                      <Grid container display="flex" justifyContent="space-between" direction={"row"}>
                        <Typography
                          className="w-700"
                          sx={{ fontSize: 18, color: '#081130', mt: '24px', mb: '12px' }}
                        >
                          {translate('detail-explore.bagikanlisting')}
                        </Typography>
                        {copyClipboard}
                      </Grid>
                      <Box sx={{ background: '#F4F6FA', border: '1px solid #E8EBF2', borderRadius: '8px', pt: '16px', pl: '16px', pr: '16px', pb: '16px' }}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                          <Typography className="w-700" sx={{ color: '#56617B', fontSize: 14, }}>
                            {(`${config.staging}/listing/detail?type=2&id_detail=${location.state.id}`).slice(0, 42) + '...'}
                          </Typography>
                          <img alt="copy-icon" src={copyIc} className="pointer" height={20} onClick={(e) => {
                            navigator.clipboard.writeText(`${config.staging}/listing/detail?type=2&id_detail=${location.state.id}`)
                            copyLink()
                          }}></img>
                        </Stack>
                      </Box>
                      <Stack sx={{ mt: '16px' }} direction={'row'} justifyContent={'space-evenly'} alignItems={'center'}>
                        <TwitterShareButton url={`${config.staging}/listing/detail?type=2&id_detail=${location.state.id}`}><img alt="twitter-icon" src={twitterIcon} height={'40px'} width={'40px'} /></TwitterShareButton>
                        <WhatsappShareButton url={`${config.staging}/listing/detail?type=2&id_detail=${location.state.id}`}><img alt="wa-icon" src={waIcon} height={'40px'} width={'40px'} /> </WhatsappShareButton>
                        <TelegramShareButton url={`${config.staging}/listing/detail?type=2&id_detail=${location.state.id}`}> <img alt="tele-icon" src={telegramIcon} height={'40px'} width={'40px'} /></TelegramShareButton>
                        <FacebookShareButton url={`${config.staging}/listing/detail?type=2&id_detail=${location.state.id}`} ><img alt="fb-icon" src={fbIcon} height={'40px'} width={'40px'} /></FacebookShareButton>
                      </Stack>
                    </Hidden>
                  </Box>
                  : ''}
                {activeTab === 'tongkang' ?
                  <Box >

                    <Box sx={{ border: 1, color: '#E8EBF2', borderRadius: 2 }}>
                      {(detailTongkang.company_listing !== null && detailTongkang.company_listing !== undefined) &&
                        <Box>
                          <Typography className="w-700" sx={{ color: '#081130', fontSize: 18, mt: 1, pl: 2 }}>
                            {translate('detail-explore.tentangperusahaan')}
                          </Typography>

                          <Box sx={{ pl: 2, pr: 2, mt: 2, }}>
                            <Grid
                              container
                              direction="row"
                              alignItems="left"
                              justifyContent="left"

                            >
                              {detailTongkang.company_listing?.picture_path?.url !== undefined &&
                                <Avatar sx={{ bgcolor: 'blue', mr: '16px' }} aria-label="recipe" src={config.baseURL + detailTongkang.company_listing?.picture_path?.url}>
                                </Avatar>
                              }

                              <Stack style={{ textAlign: "left", color: "white" }} spacing={0}>
                                {detailTongkang.company_listing.name &&
                                  <Typography
                                    sx={{ fontSize: 16, color: '#081130' }}
                                    className="w-700"
                                  >
                                    {detailTongkang.company_listing.jenis_perusahaan} {capitalizeEachWord(detailTongkang.company_listing.name)}

                                  </Typography>}
                                <Grid className="pointer"
                                  container onClick={(e) => handleNavigate(detailTongkang.company_listing.id, detailTongkang.company_listing.name)}
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 16, color: "#2457B9", mr: 1 }}
                                  >
                                    {translate('detail-explore.lihatdetailperusahaan')}
                                  </Typography>
                                  <ArrowForwardIosIcon sx={{ fontSize: 12, color: "#2457B9", pt: '3px' }} />
                                </Grid>
                              </Stack>
                            </Grid>
                          </Box>

                          <Box sx={{ ml: 2, mr: 2, mt: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0 }, mb: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0 }, background: { xs: "#F4F6FA", sm: "#F4F6FA", md: "#F4F6FA", lg: "none", xl: "none" }, borderRadius: 4, padding: 2 }}>
                            <Box sx={{ pr: 2, mb: 2 }}>
                              {detailTongkang.company_listing.bidang_perusahaan === 1 ? (
                                <Grid

                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <img alt="penambang-icon" src={PenambangIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                  >
                                    {translate('page_explore.penambang')}
                                  </Typography>

                                </Grid>
                              ) : detailTongkang.company_listing.bidang_perusahaan === 2 ? (
                                <Grid

                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <img alt="penambang-icon" src={traderIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                  >
                                    Trader
                                  </Typography>

                                </Grid>
                              ) : detailTongkang.company_listing.bidang_perusahaan === 3 ? (
                                <Grid
                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <img alt="penambang-icon" src={pelayarIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                  >
                                    {translate('page_explore.pelayaran')}
                                  </Typography>

                                </Grid>
                              ) : (
                                <></>
                              )}
                            </Box>

                            <Box sx={{ pr: 2, mt: 1, mb: 2 }}>
                              {detailTongkang.company_listing.alamat &&
                                <Grid

                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <img alt="call-icon" src={locationCompanyIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                  >
                                    {detailTongkang.company_listing.alamat[0].label_alamat}
                                  </Typography>

                                </Grid>}
                            </Box>
                            <Box sx={{ pr: 2, mt: 1, mb: 2 }}>
                              {detailTongkang.company_listing.phone_number &&
                                <Grid

                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <img alt="Nikel-icon" src={callCompanyIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                  >
                                    {detailTongkang.company_listing.phone_number}
                                  </Typography>

                                </Grid>}
                            </Box>
                            <Box sx={{ pr: 2, mt: 1, mb: 2 }}>
                              {detailTongkang.company_listing.email &&
                                <Grid

                                  container
                                  justifyContent="left"
                                  alignItems="center"
                                  direction="row"
                                >
                                  <img alt="Nikel-icon" src={mailCompanyIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#081130", ml: 1 }}
                                  >
                                    {detailTongkang.company_listing.email}
                                  </Typography>

                                </Grid>}
                            </Box>
                          </Box>
                        </Box>}
                      <Hidden lgDown>
                        <Divider orientation="horizontal" style={{ backgroundColor: "#E8EBF2", marginLeft: '16px', marginRight: '16px', marginBottom: '24px' }} />

                        <Box sx={{ pl: 2, pr: 2, mt: 1, mb: 2 }}>

                          {detailTongkang?.is_sold === false && detailTongkang?.status_listing !== 1 && detailTongkang?.type_tongkang === 1 ? <Button size="medium" onClick={(e) => navigate('/jual-tongkang', { state: { page: 'edit-tongkang', data: detailTongkang } })} sx={{
                            fontSize: 16,
                            p: '12px 16px',
                            border: '1px solid var(--primarry-blue, #2457B9)',
                            lineHeight: '22px',
                            background: '#2457B9', mb: 2,
                            borderRadius: '8px', boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.12), 0px -1px 0px 0px rgba(0, 0, 0, 0.20) inset;',
                            textTransform: 'none'
                          }} variant="contained" fullWidth startIcon={<img alt="Nikel-icon" src={editIcon} height={24}></img>}>
                            Edit listing
                          </Button> : <></>}

                          {detailTongkang?.is_sold === false && detailTongkang?.status_listing !== 1 && detailTongkang?.type_tongkang !== 1 ? <Button size="medium" onClick={(e) => navigate('/tongkang-disewakan', { state: { page: 'edit-tongkang', data: detailTongkang } })} sx={{
                            fontSize: 16,
                            p: '12px 16px',
                            border: '1px solid var(--primarry-blue, #2457B9)',
                            lineHeight: '22px',
                            background: '#2457B9', mb: 2,
                            borderRadius: '8px', boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.12), 0px -1px 0px 0px rgba(0, 0, 0, 0.20) inset;',
                            textTransform: 'none'
                          }} variant="contained" fullWidth startIcon={<img alt="Nikel-icon" src={editIcon} height={24}></img>}>
                            Edit listing
                          </Button> : <></>}


                          {detailTongkang?.is_sold === 0 || detailTongkang?.is_sold === false && <Button onClick={() => ActionTandaiTerjual(detailTongkang.id, 'tongkang')} sx={{
                            textTransform: "none", border: '1px solid var(--primarry-blue, #2457B9)',
                            p: '12px 16px',
                            borderRadius: '8px',
                            fontSize: 16,
                            lineHeight: '22px',
                            mb: 2,
                            color: '#2457B9'
                          }} size="medium" variant="outlined" fullWidth startIcon={<img alt="Nikel-icon" src={soldIcon} height={24}></img>}>
                            {translate('status.tandaiterjual')}
                          </Button>}
                          {detailTongkang?.is_sold === false &&
                            <>
                              {checkingFavorite === true ?
                                <Button onClick={() => removeFavorite(3)} sx={{
                                  textTransform: "none", border: '1px solid var(--primarry-blue, #2457B9)',
                                  p: '12px 16px',
                                  borderRadius: '8px',
                                  fontSize: 16,
                                  lineHeight: '22px',
                                  mb: 2,
                                  color: '#2457B9'
                                }} size="medium" variant="outlined" fullWidth startIcon={<FavoriteTwoToneIcon sx={{ fill: "red" }} />}>
                                  {translate('status.hapusfavorite')}
                                </Button>
                                :
                                <Button onClick={() => setFavorite(3)} sx={{
                                  textTransform: "none", border: '1px solid var(--primarry-blue, #2457B9)',
                                  p: '12px 16px',
                                  borderRadius: '8px',
                                  fontSize: 16,
                                  lineHeight: '22px',
                                  mb: 2,
                                  color: '#2457B9'
                                }} size="medium" variant="outlined" fullWidth startIcon={<FavoriteBorderIcon />}>
                                  {translate('detail-explore.tambahkanfavorit')}
                                </Button>
                              }
                            </>}
                          {detailTongkang?.is_sold === 1 || detailTongkang?.is_sold === true && <Button className="w-500" onClick={() => {
                            handleOpenModal("hapus", detailTongkang?.status_listing);
                          }} sx={{
                            "&:hover": {
                              borderColor: '#ED3131',
                            },
                            textTransform: "none", border: '1px solid var(--primarry-blue, #ED3131)',
                            p: '12px 16px',
                            borderRadius: '8px',
                            fontSize: 16,
                            lineHeight: '22px',
                            color: '#ED3131'
                          }} size="medium" variant="outlined" fullWidth startIcon={<img alt="Nikel-icon" src={deleteIcon} height={24}></img>}>
                            {translate('status.hapus')}
                          </Button>}
                        </Box>
                        {detailTongkang?.is_sold === 0 || detailTongkang?.is_sold === false &&
                          <Box sx={{ pl: 2, pr: 2, mt: 1, mb: 2 }}>
                            <Stack
                              justifyContent="space-evenly"
                              alignItems="center"
                              direction="row"
                            >
                              <Stack onClick={() => {
                                handleOpenModal("hapus", detailTongkang?.status_listing
                                );
                              }} direction={'row'} alignItems={'center'} sx={{ cursor: 'pointer' }}>
                                <img alt="Nikel-icon" src={deleteIcon} height={24}></img>
                                <Typography
                                  className="w-500"
                                  sx={{ fontSize: 14, color: "#ED3131", ml: 1, }}
                                >
                                  {translate('status.hapus')}
                                </Typography>
                              </Stack>
                              <ModalHapusListing type={'tongkang'} id={detailTongkang.id} open={isOpenModalHapus} close={handleCloseModalHapus}></ModalHapusListing>
                              <Divider orientation="vertical" style={{ width: '1px' }} flexItem></Divider>
                              {
                                detailTongkang.status_listing === 1 &&
                                <Stack onClick={() => {
                                  handleOpenModal("unpublish", detailTongkang?.status_listing);
                                }} direction={'row'} alignItems={'center'} sx={{ cursor: 'pointer' }}>
                                  <img alt="Nikel-icon" src={unpublishIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#56617B", ml: 1, }}
                                  >
                                    Unpublish
                                  </Typography>
                                </Stack>}
                              {
                                detailTongkang.status_listing === 2 &&
                                <Stack onClick={() => {
                                  handleOpenModal("publish", detailTongkang?.status_listing);
                                }} direction={'row'} alignItems={'center'} sx={{ cursor: 'pointer' }}>
                                  <img alt="Nikel-icon" src={visibilityIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#56617B", ml: 1, }}
                                  >
                                    Publish
                                  </Typography>
                                </Stack>}
                              {
                                detailTongkang.status_listing === 3 && detailTongkang.type_tongkang === 1 ?
                                  <Stack onClick={() => {
                                    navigate('/jual-tongkang', { state: { page: 'edit-tongkang', data: detailTongkang } })
                                  }} direction={'row'} alignItems={'center'} sx={{ cursor: 'pointer' }}>
                                    <img alt="Nikel-icon" src={visibilityIcon} height={24}></img>
                                    <Typography
                                      className="w-500"
                                      sx={{ fontSize: 14, color: "#56617B", ml: 1, }}
                                    >
                                      Publish
                                    </Typography>
                                  </Stack> : <></>}
                              {detailTongkang.status_listing === 3 && detailTongkang.type_tongkang !== 1 ?
                                <Stack onClick={() => {
                                  navigate('/tongkang-disewakan', { state: { page: 'edit-tongkang', data: detailTongkang } })
                                }} direction={'row'} alignItems={'center'} sx={{ cursor: 'pointer' }}>
                                  <img alt="Nikel-icon" src={visibilityIcon} height={24}></img>
                                  <Typography
                                    className="w-500"
                                    sx={{ fontSize: 14, color: "#56617B", ml: 1, }}
                                  >
                                    Publish
                                  </Typography>
                                </Stack> : <></>}
                              <ModalUnpublish status={statuslisting} type={'tongkang'} id={detailTongkang?.id} open={isOpenModalUnpublish} close={handleCloseModalUnpublish}></ModalUnpublish>
                            </Stack>
                          </Box>}
                      </Hidden>
                    </Box>
                    <Hidden lgDown>
                      <Grid container display="flex" justifyContent="space-between" direction={"row"}>
                        <Typography
                          className="w-700"
                          sx={{ fontSize: 18, color: '#081130', mt: '24px', mb: '12px' }}
                        >
                          {translate('detail-explore.bagikanlisting')}
                        </Typography>
                        {copyClipboard}
                      </Grid>
                      <Box sx={{ background: '#F4F6FA', border: '1px solid #E8EBF2', borderRadius: '8px', pt: '16px', pl: '16px', pr: '16px', pb: '16px' }}>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                          <Typography className="w-700" sx={{ color: '#56617B', fontSize: 14, }}>
                            {(`${config.staging}/listing/detail?type=3&id_detail=${location.state.id}`).slice(0, 42) + '...'}
                          </Typography>
                          <img alt="copy-icon" src={copyIc} className="pointer" height={20} onClick={(e) => {
                            navigator.clipboard.writeText(`${config.staging}/listing/detail?type=3&id_detail=${location.state.id}`)
                            copyLink()
                          }}></img>
                        </Stack>
                      </Box>
                      <Stack sx={{ mt: '16px' }} direction={'row'} justifyContent={'space-evenly'} alignItems={'center'}>
                        <TwitterShareButton url={`${config.staging}/listing/detail?type=3&id_detail=${location.state.id}`}><img alt="twitter-icon" src={twitterIcon} height={'40px'} width={'40px'} /></TwitterShareButton>
                        <WhatsappShareButton url={`${config.staging}/listing/detail?type=3&id_detail=${location.state.id}`}><img alt="wa-icon" src={waIcon} height={'40px'} width={'40px'} /> </WhatsappShareButton>
                        <TelegramShareButton url={`${config.staging}/listing/detail?type=3&id_detail=${location.state.id}`}> <img alt="tele-icon" src={telegramIcon} height={'40px'} width={'40px'} /></TelegramShareButton>
                        <FacebookShareButton url={`${config.staging}/listing/detail?type=3&id_detail=${location.state.id}`} ><img alt="fb-icon" src={fbIcon} height={'40px'} width={'40px'} /></FacebookShareButton>
                      </Stack>
                    </Hidden>
                  </Box>
                  : ''}
              </Grid>
            </Grid>
            <Grid
              className="test-grid-new"
              container
              direction="row"

              sx={{ mb: 2 }}
            >
              <Grid lg={8} xs={12} className="test-class" item sx={{ width: '840px' }}>
                {/* coal Section */}
                {activeTab === 'coal' ?
                  <Box sx={{ mb: 2, }}>
                    <Komentar id_product={parseInt(location.state.id)} type_product={1} />
                    <Hidden lgUp>
                      {detailBatubara?.is_sold === false && <Grid sx={{ mt: 2 }} container direction={'row'} spacing={1} alignItems={'center'}>
                        <Grid item xs={8}>
                          <Button
                            disabled={detailBatubara?.status_listing === 1 ? true : false}
                            onClick={(e) => navigate('/jual-batubara', { state: { page: 'edit-batubara', data: detailBatubara } })}
                            fullWidth
                            className="w-500"
                            size="large" sx={{ background: '#2457B9', textTransform: 'none', fontSize: '14px', borderRadius: '8px', padding: '12px 16px' }}
                            variant="contained" startIcon={<img alt="Nikel-icon" src={editIcon} height={24}></img>}>
                            Edit listing
                          </Button>

                        </Grid>
                        <Grid item xs={2} >

                          <Box sx={{ border: '1px solid #56617B', cursor: 'pointer', borderRadius: '8px', p: '12px', display: 'flex', justifyContent: 'center' }} > {checkingFavorite === true ?
                            <FavoriteTwoToneIcon onClick={() => removeFavorite(1)} sx={{ fill: "red", width: '20px', height: '20px', cursor: 'pointer' }} />
                            :
                            <FavoriteBorderIcon onClick={() => setFavorite(1)} sx={{ width: '20px', height: '20px', cursor: 'pointer' }} />
                          }</Box>
                        </Grid>
                        <Grid item xs={2} >
                          <Box
                            onClick={(e) => handleOpenModal('more', detailBatubara?.status_listing)}
                            sx={{ border: '1px solid #56617B', cursor: 'pointer', borderRadius: '8px', p: '12px', display: 'flex', justifyContent: 'center' }} > <MoreHorizIcon sx={{ width: '20px', height: '20px' }} /> </Box>
                          <DrawerMore type={'coal'} is_sold={detailBatubara.is_sold} id={detailBatubara.id} page="listing" status_listing={detailBatubara.status_listing} hapus={handlehapuslisting} unpublish={handleUnpublishModal} share={handleShareModal} open={isOpenMore} close={handleCloseModal}>

                          </DrawerMore>
                          <ModalShareListing open={isOpenModalShare} close={closeShareModal}></ModalShareListing>
                          <ModalHapusListing type={'coal'} id={detailBatubara.id} open={isOpenModalHapus} close={handleCloseModalHapus}></ModalHapusListing>
                          <ModalUnpublish type={'coal'} id={detailBatubara?.id} open={isOpenModalUnpublish} close={handleCloseModalUnpublish}></ModalUnpublish>
                        </Grid>
                      </Grid>
                      }

                      {detailBatubara?.is_sold === true && <Grid sx={{ mt: 2 }} container direction={'row'} spacing={1} alignItems={'center'}>
                        <Grid item xs={10}>
                          <Button
                            disabled

                            fullWidth
                            className="w-500"
                            size="large" sx={{ background: '#2457B9', textTransform: 'none', fontSize: '14px', borderRadius: '8px', padding: '12px 16px' }}
                            variant="contained" startIcon={<img alt="Nikel-icon" src={editIcon} height={24}></img>}>
                            Edit listing
                          </Button>

                        </Grid>
                        <Grid item xs={2} >
                          <Box
                            onClick={(e) => handleOpenModal('more', detailBatubara?.status_listing)}
                            sx={{ border: '1px solid #56617B', cursor: 'pointer', borderRadius: '8px', p: '12px', display: 'flex', justifyContent: 'center' }} > <MoreHorizIcon sx={{ width: '20px', height: '20px' }} /> </Box>
                          <DrawerMore type={'coal'} is_sold={detailBatubara.is_sold} id={detailBatubara.id} page="listing" status_listing={detailBatubara.status_listing} hapus={handlehapuslisting} unpublish={handleUnpublishModal} share={handleShareModal} open={isOpenMore} close={handleCloseModal}>

                          </DrawerMore>
                          <ModalShareListing open={isOpenModalShare} close={closeShareModal}></ModalShareListing>
                          <ModalHapusListing type={'coal'} id={detailBatubara.id} open={isOpenModalHapus} close={handleCloseModalHapus}></ModalHapusListing>
                          <ModalUnpublish type={'coal'} id={detailBatubara?.id} open={isOpenModalUnpublish} close={handleCloseModalUnpublish}></ModalUnpublish>
                        </Grid>

                      </Grid>}
                    </Hidden>
                  </Box> : null}
                {/* nikel Section */}
                {activeTab === 'nikel' ?
                  <Box sx={{ mb: 2, }}>
                    <Komentar id_product={parseInt(location.state.id)} type_product={2} />
                    <Hidden lgUp>
                      {detailNikel?.is_sold === false && <Grid sx={{ mt: 2 }} container direction={'row'} spacing={1} alignItems={'center'}>
                        <Grid item xs={8}>
                          <Button
                            disabled={detailNikel?.status_listing === 1 ? true : false}
                            onClick={(e) => navigate('/jual-nikel', { state: { page: 'edit-nikel', data: detailNikel } })}

                            fullWidth
                            className="w-500"
                            size="large" sx={{ background: '#2457B9', textTransform: 'none', fontSize: '14px', borderRadius: '8px', padding: '12px 16px' }}
                            variant="contained" startIcon={<img alt="Nikel-icon" src={editIcon} height={24}></img>}>
                            Edit listing
                          </Button>

                        </Grid>
                        <Grid item xs={2} >

                          <Box sx={{ border: '1px solid #56617B', cursor: 'pointer', borderRadius: '8px', p: '12px', display: 'flex', justifyContent: 'center' }} > {checkingFavorite === true ?
                            <FavoriteTwoToneIcon onClick={() => removeFavorite(1)} sx={{ fill: "red", width: '20px', height: '20px', cursor: 'pointer' }} />
                            :
                            <FavoriteBorderIcon onClick={() => setFavorite(1)} sx={{ width: '20px', height: '20px', cursor: 'pointer' }} />
                          } </Box>
                        </Grid>
                        <Grid item xs={2} >
                          <Box
                            onClick={(e) => handleOpenModal('more', detailNikel?.status_listing)}
                            sx={{ border: '1px solid #56617B', cursor: 'pointer', borderRadius: '8px', p: '12px', display: 'flex', justifyContent: 'center' }} > <MoreHorizIcon sx={{ width: '20px', height: '20px' }} /> </Box>
                          <DrawerMore type={'nikel'} is_sold={detailNikel.is_sold} id={detailNikel.id} page="listing" status_listing={detailNikel.status_listing} hapus={handlehapuslisting} unpublish={handleUnpublishModal} share={handleShareModal} open={isOpenMore} close={handleCloseModal}>

                          </DrawerMore>
                          <ModalShareListing open={isOpenModalShare} close={closeShareModal}></ModalShareListing>
                          <ModalHapusListing type={'nikel'} id={detailNikel.id} open={isOpenModalHapus} close={handleCloseModalHapus}></ModalHapusListing>
                          <ModalUnpublish type={'nikel'} id={detailNikel?.id} open={isOpenModalUnpublish} close={handleCloseModalUnpublish}></ModalUnpublish>
                        </Grid>
                      </Grid>}
                      {detailNikel?.is_sold === true && <Grid sx={{ mt: 2 }} container direction={'row'} spacing={1} alignItems={'center'}>
                        <Grid item xs={10}>
                          <Button
                            disabled


                            fullWidth
                            className="w-500"
                            size="large" sx={{ background: '#2457B9', textTransform: 'none', fontSize: '14px', borderRadius: '8px', padding: '12px 16px' }}
                            variant="contained" startIcon={<img alt="Nikel-icon" src={editIcon} height={24}></img>}>
                            Edit listing
                          </Button>

                        </Grid>

                        <Grid item xs={2} >
                          <Box
                            onClick={(e) => handleOpenModal('more', detailNikel?.status_listing)}
                            sx={{ border: '1px solid #56617B', cursor: 'pointer', borderRadius: '8px', p: '12px', display: 'flex', justifyContent: 'center' }} > <MoreHorizIcon sx={{ width: '20px', height: '20px' }} /> </Box>
                          <DrawerMore type={'nikel'} is_sold={detailNikel.is_sold} id={detailNikel.id} page="listing" status_listing={detailNikel.status_listing} hapus={handlehapuslisting} unpublish={handleUnpublishModal} share={handleShareModal} open={isOpenMore} close={handleCloseModal}>

                          </DrawerMore>
                          <ModalShareListing open={isOpenModalShare} close={closeShareModal}></ModalShareListing>
                          <ModalHapusListing type={'nikel'} id={detailNikel.id} open={isOpenModalHapus} close={handleCloseModalHapus}></ModalHapusListing>
                          <ModalUnpublish type={'nikel'} id={detailNikel?.id} open={isOpenModalUnpublish} close={handleCloseModalUnpublish}></ModalUnpublish>
                        </Grid>
                      </Grid>}
                    </Hidden>
                  </Box> : null}
                {/* tongkang Section */}
                {activeTab === 'tongkang' ?
                  <Box sx={{ mb: 2 }}>
                    <Komentar id_product={parseInt(location.state.id)} type_product={3} />
                    <Hidden lgUp>
                      {detailTongkang?.is_sold === false && <Grid sx={{ mt: 2 }} container direction={'row'} spacing={1} alignItems={'center'}>
                        {detailTongkang?.status_listing !== 1 && detailTongkang?.type_tongkang === 1 ? <Grid item xs={8}>
                          <Button
                            disabled={detailTongkang?.status_listing === 1 ? true : false}
                            onClick={(e) => navigate('/jual-tongkang', { state: { page: 'edit-tongkang', data: detailTongkang } })}

                            fullWidth
                            className="w-500"
                            size="large" sx={{ background: '#2457B9', textTransform: 'none', fontSize: '14px', borderRadius: '8px', padding: '12px 16px' }}
                            variant="contained" startIcon={<img alt="Nikel-icon" src={editIcon} height={24}></img>}>
                            Edit listing
                          </Button>
                        </Grid> : <></>}
                        {detailTongkang?.status_listing !== 1 && detailTongkang?.type_tongkang !== 1 ? <Grid item xs={8}>
                          <Button
                            disabled={detailTongkang?.status_listing === 1 ? true : false}
                            onClick={(e) => navigate('tongkang-disewakan', { state: { page: 'edit-tongkang', data: detailTongkang } })}

                            fullWidth
                            className="w-500"
                            size="large" sx={{ background: '#2457B9', textTransform: 'none', fontSize: '14px', borderRadius: '8px', padding: '12px 16px' }}
                            variant="contained" startIcon={<img alt="Nikel-icon" src={editIcon} height={24}></img>}>
                            Edit listing
                          </Button>
                        </Grid> : <></>}
                        <Grid item xs={2} >

                          <Box sx={{ border: '1px solid #56617B', cursor: 'pointer', borderRadius: '8px', p: '12px', display: 'flex', justifyContent: 'center' }} > {checkingFavorite === true ?
                            <FavoriteTwoToneIcon onClick={() => removeFavorite(1)} sx={{ fill: "red", width: '20px', height: '20px', cursor: 'pointer' }} />
                            :
                            <FavoriteBorderIcon onClick={() => setFavorite(1)} sx={{ width: '20px', height: '20px', cursor: 'pointer' }} />
                          } </Box>
                        </Grid>
                        <Grid item xs={2} >
                          <Box
                            onClick={(e) => handleOpenModal('more', detailTongkang?.status_listing)}
                            sx={{ border: '1px solid #56617B', cursor: 'pointer', borderRadius: '8px', p: '12px', display: 'flex', justifyContent: 'center' }} > <MoreHorizIcon sx={{ width: '20px', height: '20px' }} /> </Box>
                          <DrawerMore type={'tongkang'} is_sold={detailTongkang.is_sold} id={detailTongkang.id} page="listing" hapus={handlehapuslisting} unpublish={handleUnpublishModal} status_listing={detailTongkang.status_listing} share={handleShareModal} open={isOpenMore} close={handleCloseModal}>

                          </DrawerMore>
                          <ModalShareListing open={isOpenModalShare} close={closeShareModal}></ModalShareListing>
                          <ModalHapusListing type={'tongkang'} id={detailTongkang.id} open={isOpenModalHapus} close={handleCloseModalHapus}></ModalHapusListing>
                          <ModalUnpublish type={'tongkang'} id={detailTongkang?.id} open={isOpenModalUnpublish} close={handleCloseModalUnpublish}></ModalUnpublish>
                        </Grid>
                      </Grid>}

                      {detailTongkang?.is_sold === true && <Grid sx={{ mt: 2 }} container direction={'row'} spacing={1} alignItems={'center'}>
                        <Grid item xs={10}>
                          <Button
                            disabled
                            fullWidth
                            className="w-500"
                            size="large" sx={{ background: '#2457B9', textTransform: 'none', fontSize: '14px', borderRadius: '8px', padding: '12px 16px' }}
                            variant="contained" startIcon={<img alt="Nikel-icon" src={editIcon} height={24}></img>}>
                            Edit listing
                          </Button>
                        </Grid>

                        <Grid item xs={2} >
                          <Box
                            onClick={(e) => handleOpenModal('more', detailTongkang?.status_listing)}
                            sx={{ border: '1px solid #56617B', cursor: 'pointer', borderRadius: '8px', p: '12px', display: 'flex', justifyContent: 'center' }} > <MoreHorizIcon sx={{ width: '20px', height: '20px' }} /> </Box>
                          <DrawerMore type={'tongkang'} is_sold={detailTongkang.is_sold} id={detailTongkang.id} page="listing" hapus={handlehapuslisting} unpublish={handleUnpublishModal} status_listing={detailTongkang.status_listing} share={handleShareModal} open={isOpenMore} close={handleCloseModal}>

                          </DrawerMore>
                          <ModalShareListing open={isOpenModalShare} close={closeShareModal}></ModalShareListing>
                          <ModalHapusListing type={'tongkang'} id={detailTongkang.id} open={isOpenModalHapus} close={handleCloseModalHapus}></ModalHapusListing>
                          <ModalUnpublish type={'tongkang'} id={detailTongkang?.id} open={isOpenModalUnpublish} close={handleCloseModalUnpublish}></ModalUnpublish>
                        </Grid>
                      </Grid>}
                    </Hidden>
                  </Box> : null}

              </Grid>
            </Grid>
          </div>
          <Footer></Footer>
        </Box>
      </div >
    </div >
  )
}

export default DetailListingpage
