import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Avatar, Box, Card, CardContent, CardHeader, Grid, IconButton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import defaultbannericon from "../assets/icons/default-tongkang.png";
import { addFavorite, deleteFavorite } from "../services/api.js";
import { config } from '../services/constants';
import { numberConvert } from '../services/primary';
import Love from "./Love";
import ModalShare from "./ModalShare";
import { GlobalState } from "../services/global";

export default function CardRecomendationTongkangList({ data }) {
  const navigate = useNavigate()
  const [product_id, setProduct_id] = useState(null);
  const [user_trader_phone, setuser_trader_phone] = useState(null);
  const [translate,] = useTranslation("global");
  const [anchorElShare, setAnchorElShare] = useState(null);
  const [isLogin,] = useState(localStorage.getItem('token') !== null ? true : false);
  const handleOpenShareMenu = (event, id, traderphone) => {
    setuser_trader_phone(traderphone);
    setProduct_id(id);
    setAnchorElShare(event.currentTarget);
  };
  const { currency } = React.useContext(GlobalState)

  const setFavorite = (e, y) => {

    addFavorite({ type: 3, id_relation: parseInt(y) })


  }

  const removeFavorite = (e, y) => {
    deleteFavorite({ type_product: 3, id_product: parseInt(y) })
    // props.getTongkang();
  }

  const handleCloseShareMenu = () => {
    setAnchorElShare(null);
  };


  return (
    <Box sx={{ pr: '8px' }}>
      <Card sx={{ width: '100%', borderRadius: 2, position: 'relative' }}>
        {
          isLogin &&
          <Love type='tongkang' condition={data?.user_favorite_id} remove={() => removeFavorite(3, data?.product_id)} favorite={() => setFavorite(3, data?.product_id)} />
        }
        <CardHeader
          onClick={(e) => {
            window.location.href = `/penawaran/detail?type=3&id_detail=${data.product_id}`
          }}
          sx={{
            backgroundImage: data.product_image === null ? `url(${defaultbannericon})` : `linear-gradient(180deg, rgba(8, 34, 86, 0.6) 16.83%, rgba(8, 34, 86, 0) 60.9%), url(${config.baseURL}${data.product_image})`,
            height: { xs: '113px', lg: '139px' },
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            position: "relative",
            cursor: 'pointer',
          }}
          avatar={
            <Box sx={{
              position: "absolute",
              top: '10%'
            }}>
              <Stack direction={'row'} >
                <Box
                  sx={{
                    background: "#F4F6FA",
                    borderRadius: 4,
                    p: '2px 12px',
                    mr: '4px'
                  }}
                >
                  <Stack direction={'row'} alignItems={'center'}>
                    {data.type_tongkang === 1 ? (
                      <>
                        <Box sx={{ borderRadius: '50%', background: '#F7CE46', width: '10px', height: '10px', mr: '4px' }}>
                        </Box>
                        <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 12 }} >
                          {translate('status.jual')}
                        </Typography>
                      </>

                    ) : data.type_tongkang === 2 ? (
                      <>
                        <Box sx={{ borderRadius: '50%', background: '#F55C24', width: '10px', height: '10px', mr: '4px' }}>
                        </Box>
                        <Typography className="w-500" sx={{ color: '#818CA7', fontSize: 12 }} >
                          Time Charter
                        </Typography>
                      </>
                    ) : data.type_tongkang === 3 ? (
                      <>
                        <Box sx={{ borderRadius: '50%', background: '#883BE7', width: '10px', height: '10px', mr: '4px' }}>
                        </Box>
                        <Typography className="w-500" sx={{ color: '#818CA7', fontSize: '12px' }} >
                          Freight Charter
                        </Typography>
                      </>
                    ) : (
                      <></>
                    )}
                  </Stack>
                </Box>
              </Stack>
            </Box>
          }
          action={
            <IconButton aria-label="settings " sx={{ background: 'rgba(0, 0, 0, 0.1)' }}>
              <FavoriteBorderIcon sx={{ color: "white" }} />
            </IconButton>
          }
        />
        <CardContent sx={{ pb: '16px !important' }}>
          <Box>
            <Grid
              container
              direction="row"
              alignItems="left"
              justifyContent="left"
              spacing={0}
            >
              <Grid sx={{ cursor: 'pointer' }} item xs={8} onClick={(e) => {
                window.location.href = `/penawaran/detail?type=3&id_detail=${data.product_id}`
              }}>
                <Typography className="w-700" sx={{ color: '#081130', fontSize: 16 }}>
                  {data.nama_tug_boat}
                </Typography>
              </Grid>
              <Grid sx={{ textAlign: "right", cursor: 'pointer' }} item xs={4}>
                <MoreHorizIcon onClick={(e) => handleOpenShareMenu(e, data.product_id, data.phone_number)} />
                <ModalShare page={'tongkang'} type={3} id={product_id} user_trader_phone={user_trader_phone} anchorEl={anchorElShare} open={anchorElShare} onClose={handleCloseShareMenu}></ModalShare>
              </Grid>
            </Grid>
            <Box sx={{ cursor: 'pointer' }} onClick={(e) => navigate('/penawaran/detail', { state: { detail: 'tongkang', id: data.product_id, data: data } })} >
              <Typography className="w-500" sx={{ color: '#56617B', fontSize: 14, mb: 1 }} >
                {data.nama_tongkang}
              </Typography>
              <Grid
                container
                direction="row"
                alignItems="left"
                justifyContent="left"
                spacing={0}
              >
                {/* <Grid item xs={7}>
                  <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                    {translate('page_explore.quantitymuatan')}
                  </Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                  <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                    {translate('page_explore.harga')}
                  </Typography>
                </Grid> */}
                <Grid item xs={7}>
                  <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                    {translate('page_explore.harga')}
                  </Typography>
                </Grid>
                <Grid sx={{ textAlign: "left" }} item xs={5}>
                  <Typography className="w-400" sx={{ color: '#56617B', fontSize: 12, }} >
                    {translate('page_explore.ukurantongkang')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                alignItems="left"
                justifyContent="left"
                spacing={0}
              >
                {/* <Grid item xs={7}>
                  <Stack direction="row" spacing={1}>
                    <Typography className="w-700" sx={{ color: '#081130', fontSize: 14 }}>
                      {(data.quantity_muatan).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                    </Typography>
                    <Typography className="w-500 s-1420" sx={{ color: '#56617B' }}>
                      MT
                    </Typography>
                  </Stack>
                </Grid> */}
                <Grid item xs={7}>
                  {currency === 'idr' && <Typography className="w-700" sx={{ color: '#081130', fontSize: 14, }}>
                    Rp {numberConvert(data.price)}
                  </Typography>}
                  {currency === 'usd' && <Typography className="w-700" sx={{ color: '#081130', fontSize: 14, }}>
                    $ {numberConvert(data.price)}
                  </Typography>}
                </Grid>
                <Grid item xs={5}>
                  <Typography className="w-700" sx={{ color: '#081130', fontSize: 14, }}>
                    {data.ukuran_name}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box
            sx={{
              background: "#F4F6FA",
              borderRadius: "6px",
              padding: "8px 12px",
              mt: 2,
              height: '65px'
            }}
          >
            <Typography
              sx={{ fontSize: 10, mb: 1, color: '#818CA7', textTransform: 'uppercase' }}
              className="w-500"
            >
              {translate('page_explore.listingoleh')}
            </Typography>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="left"
              spacing={0}
            >
              <Grid item xs={2.5}>
                {data.user_url_image === null ?
                  <Avatar onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${data.user_id}` }} sx={{ bgcolor: 'blue', cursor: 'pointer' }} aria-label="recipe"></Avatar> : <img onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${data.user_id}` }} style={{ borderRadius: '50%', marginRight: 6, cursor: 'pointer' }} src={config.baseURL + data.user_url_image} height={32} width={32}></img>}
              </Grid>
              <Grid item xs={8.5}>
                <Stack style={{ textAlign: "left", color: "white" }} spacing={0}>
                  <Typography
                    onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${data.user_id}` }}
                    sx={{ fontSize: { xs: 12, md: 16 }, color: '#081130', cursor: 'pointer' }}
                    className="w-700"
                  >
                    {data && data?.user_name?.length > 20 ?
                      data?.user_name.slice(0, 20) + '...'
                      : data?.user_name}
                  </Typography>
                  <Typography
                    sx={{ fontSize: { xs: 10, md: 12 }, color: '#56617B' }}
                    className="w-500"
                  >
                    {data.company_name_listing}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}
