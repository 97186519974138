import i18next from "i18next";
import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import "../src/styles/responsive.css";
import global_en from "../src/translations/en/global.json";
import App from './App';
import './index.css';
import { config } from './services/constants';
import global_id from "./translations/id/global.json";

i18next.init({
  interpolation: { escapeValue: false },
  lng: localStorage.getItem('lang') === null ? 'id' : localStorage.getItem('lang'),
  resources: {
    en: {
      global: global_en,
    },
    id: {
      global: global_id
    },
  },
})


if (config.env === 'PROD') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
  console.info = () => { }
  console.warn = () => { }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <I18nextProvider i18n={i18next}>
    <App />
  </I18nextProvider>

  // </React.StrictMode>
);


