import Divider from "@material-ui/core/Divider";
import { Person } from "@material-ui/icons";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CompanyDefault from '@mui/icons-material/Business';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Avatar, Box, Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Ashicon from "../assets/ash-ic.png";
import Calorieicon from "../assets/calorie-ic.png";
import PenawaranIcon from "../assets/icons/penawaran-ic.svg";
import PermintaanIcon from "../assets/icons/permintaan-ic.svg";
import Sulfuricon from "../assets/sulfur-ic.png";
import { addFavorite, deleteFavorite, searchCoal } from "../services/api.js";
import { config } from '../services/constants';
import { GlobalState } from "../services/global";
import { numberConvert } from '../services/primary';
import DrawerMore from "./DrawerMore";
import Love from "./Love";

export default function CardListCoalExplore(props) {
  const navigate = useNavigate()
  const [translate,] = useTranslation("global");
  const [product_id, setProduct_id] = useState(null);
  const [user_trader_phone, setuser_trader_phone] = useState(null);
  const [anchorElShare, setAnchorElShare] = useState(null);
  const [productList, setProductList] = useState([]);
  const [isLogin,] = useState(localStorage.getItem('token') !== null ? true : false);
  const { currency } = React.useContext(GlobalState)
  const [label, setLabel] = useState('penawaran');

  const handleOpenShareMenu = (event, id, traderphone, label) => {
    setLabel(label)
    setuser_trader_phone(traderphone);
    setProduct_id(id);
    setAnchorElShare(event.currentTarget);
  };

  const handleCloseShareMenu = () => {
    setAnchorElShare(null);
  };

  const setFavorite = (e, y) => {
    if (props.getCoalList !== undefined) {
      addFavorite({ type: e, id_relation: parseInt(y) })
      if (props.page === 'profile') {
        props.getCoalList();
      }
    }
  }

  const removeFavorite = (e, y) => {
    deleteFavorite({ type_product: e, id_product: parseInt(y) })
    if (props.page === 'profile') {
      props.getCoalList();
    }
  }

  useEffect(() => {
    getProduct();
  }, []);

  async function getProduct() {
    try {
      const response = await searchCoal({
        page: 1,
        min_calorie: '', max_calorie: '', min_total_sulfur: '', max_total_sulfur: '', min_ash: '',
        max_ash: '', quantity: '', min_price: '', max_price: '', id_location: "", search_text: ""
      });
      setProductList(response.data.data.data.data);
    } catch (e) {

    }
  }
  const data = props.coalList ? props.coalList : productList


  return (
    <React.Fragment>

      <Grid sx={{ mt: 2 }} item md={6} xs={12}>
        {data.label === 'penawaran' &&
          <Card sx={{ borderRadius: 2, mb: 2, border: 1, borderColor: '#E8EBF2' }}>
            <CardContent
              sx={{ p: 2 }}
            >
              <Box>
                <Grid
                  container
                  direction="row"
                  alignItems="left"
                  justifyContent="left"
                  spacing={0}

                >
                  <Grid item xs={8}>
                    <Box
                      sx={{
                        background: "rgba(180, 202, 243, 0.22);",
                        borderRadius: '300px',
                        p: '2px 12px',
                        width: '100px',
                        height: '24px'

                      }}
                    >
                      <Stack direction={'row'} alignItems={'center'}>
                        <img alt="icon" src={PenawaranIcon} height={18}></img>
                        <Typography className="w-500" sx={{ color: '#2457B9', fontSize: 14, ml: '4px' }} >
                          {translate('navbar.penawaran')}
                        </Typography>

                      </Stack>
                    </Box>

                  </Grid>
                  <Grid sx={{ textAlign: "right", display: 'flex', alignItems: 'center', justifyContent: 'end' }} item xs={4}>
                    {isLogin && props.page !== 'detailcompany' &&
                      <Love type='card' condition={data?.user_favorite_id} remove={() => removeFavorite(1, data?.product_id)} favorite={() => setFavorite(1, data.product_id)} />}

                    <MoreHorizIcon onClick={(e) => handleOpenShareMenu(e, data.product_id, data.phone_number, data.label)} sx={{ width: '24px', height: '24px', cursor: 'pointer' }} />
                  </Grid>
                </Grid>
                <Grid container direction={'column'} sx={{ mt: 1 }}>
                  <Typography
                    sx={{ fontSize: 10, mb: 1, color: '#818CA7', textTransform: 'uppercase', lineHeight: '14px' }}
                    className="w-500"
                  >
                    {translate('page_explore.listingoleh')}
                  </Typography>
                  <Stack

                    direction="row"
                    alignItems="center"
                    justifyContent="left"
                    spacing={0}
                  >

                    {data.user_url_image ?

                      <img style={{ borderRadius: '50%', marginRight: 6 }} alt="company_image-icon" onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${data.user_id}` }} src={config.baseURL + data.user_url_image} width={32} height={32}></img> :

                      <Avatar onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${data.user_id}` }} sx={{ bgcolor: '#1976d2', height: '32px', width: '32px', mr: '8px', cursor: 'pointer' }} aria-label="recipe">
                        <Person />
                      </Avatar>}

                    <Stack style={{ textAlign: "left", color: "white" }} spacing={0}>
                      <Typography
                        onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${data.user_id}` }}
                        sx={{ fontSize: 16, color: '#081130', cursor: 'pointer' }}
                        className="w-700"
                      >
                        {data.user_name}
                      </Typography>

                      {data.company_list.length > 0 ?
                        <Stack direction={'row'}>
                          {data.company_list.map((item, index) => {
                            if (index < 4) return (
                              <>
                                {index === 0 ? (
                                  item?.company_image ?
                                    <Avatar src={`${config.baseURL + item?.company_image}`} sx={{ bgcolor: '#1976d2', width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                                      <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
                                    </Avatar> :
                                    <Avatar sx={{ bgcolor: '#1976d2', width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                                      <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
                                    </Avatar>
                                ) : (
                                  item?.company_image ?
                                    <Avatar src={`${config.baseURL + item?.company_image}`} sx={{ bgcolor: '#1976d2', ml: -1, width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                                      <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
                                    </Avatar> :
                                    <Avatar sx={{ bgcolor: '#1976d2', ml: -1, width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                                      <CompanyDefault sx={{ fontSize: 16 }}></CompanyDefault>
                                    </Avatar>
                                )}
                              </>
                            )
                          })}
                          {data.company_list.length > 4 && <Avatar className="w-500" style={{ marginLeft: -6, width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                            +{data.company_list.length - 4}
                          </Avatar>}
                        </Stack> :

                        <Typography className="w-500" sx={{ fontSize: 14, ml: 1, color: '#818CA7', }}>
                          No company listed
                        </Typography>}
                    </Stack>

                  </Stack>
                </Grid>
                <Grid
                  marginTop={2}
                  container
                  direction="row"
                  alignItems="left"
                  justifyContent="left"
                  spacing={0}
                >
                  <Grid item xs={4}>
                    <Typography className="w-500" sx={{ color: '#56617B', fontSize: 12 }} >
                      Gar
                    </Typography>
                  </Grid>
                  <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography className="w-500" sx={{ color: '#56617B', fontSize: 12 }} >
                      {translate('page_explore.total-sulfur')}
                    </Typography>
                  </Grid>
                  <Grid sx={{ textAlign: "left" }} item xs={3}>
                    <Typography className="w-500" sx={{ color: '#56617B', fontSize: 12, }} >
                      Ash
                    </Typography>
                  </Grid>

                </Grid>
                <Grid
                  container
                  direction="row"
                  alignItems="left"
                  justifyContent="left"
                  spacing={0}
                >
                  <Grid
                    justifyContent="left"
                    item xs={4}>
                    <Stack direction="row" alignItems="center">
                      <img alt="Nikel-icon" src={Calorieicon} height={16}></img>
                      <Typography className="w-700" sx={{ color: '#081130', fontSize: 13 }}>
                        {(data.calorie).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid
                    justifyContent="left"
                    alignItems="center"
                    item xs={5}>
                    <Stack direction="row" alignItems="center">
                      <img alt="Nikel-icon" src={Sulfuricon} height={16}></img>
                      <Typography className="w-700" sx={{ color: '#081130', fontSize: 13 }}>
                        {(data.total_sulfur).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid
                    justifyContent="left"
                    alignItems="center"
                    item xs={3}>
                    <Stack direction="row" alignItems="center">
                      <img alt="Nikel-icon" src={Ashicon} height={16}></img>
                      <Typography className="w-700" sx={{ color: '#081130', fontSize: 13 }}>
                        {(data.ash).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid
                  marginTop={2}
                  container
                  direction="row"
                  alignItems="left"
                  justifyContent="left"
                  spacing={0}
                >
                  <Grid item xs={4}>
                    <Typography className="w-500" sx={{ color: '#56617B', fontSize: 12 }} >
                      Quantity
                    </Typography>
                  </Grid>
                  <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography className="w-500" sx={{ color: '#56617B', fontSize: 12, }} >
                      {translate('page_explore.harga')}
                    </Typography>
                  </Grid>
                  <Grid sx={{ textAlign: "left" }} item xs={3}>
                    <Typography className="w-500" sx={{ color: '#56617B', fontSize: 12 }} >

                    </Typography>
                  </Grid>

                </Grid>
                <Grid
                  container
                  direction="row"
                  spacing={0}
                >
                  <Grid item xs={4}>

                    <Typography
                      className="w-700"
                      sx={{
                        display: 'inline',
                        color: '#081130',
                        wordBreak: "break-all",
                        mr: '4px',
                        fontSize: 13
                      }}
                    >
                      {numberConvert(data.quantity)}
                    </Typography>

                    <Typography
                      className="w-500"
                      sx={{
                        display: 'inline',
                        color: '#56617B', fontSize: 12, pr: '4px'
                      }}
                    >
                      MT
                    </Typography>

                  </Grid>
                  <Grid item xs={5} width='100%'>
                    {currency === 'idr' && <Typography
                      className="w-700"
                      sx={{
                        display: 'inline',
                        color: '#081130',
                        mr: '4px',
                        fontSize: 13
                      }}
                    >
                      Rp {numberConvert(data.price)}
                    </Typography>}
                    {currency === 'usd' && <Typography
                      className="w-700"
                      sx={{
                        display: 'inline',
                        color: '#081130',
                        mr: '4px',
                        fontSize: 13
                      }}
                    >
                      $ {numberConvert(data.price)}
                    </Typography>}

                    <Typography
                      className="w-500"
                      sx={{
                        display: 'inline',
                        color: '#56617B', fontSize: 12,
                      }}
                    >
                      /ton
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="w-700" sx={{ color: '#081130', fontSize: 14 }}>

                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Divider orientation="horizontal" style={{ marginTop: 16, marginBottom: 16 }} />
                <Grid className="pointer"
                  container
                  justifyContent="center"
                  alignItems="center"
                  direction="row" onClick={(e) => { navigate(`/penawaran/detail?type=1&id_detail=${data.product_id}`) }}
                >
                  <Typography
                    className="w-700"
                    sx={{ fontSize: 14, color: "#2457B9", mr: 1, }}
                  >
                    {translate('page_explore.lihatdetail')}
                  </Typography>
                  <ArrowForwardIosIcon sx={{ color: "#2457B9", height: '20px', width: '20px' }} />

                </Grid>

              </Box>
            </CardContent>
          </Card>}
        {data.label === 'permintaan' &&
          <Card sx={{ borderRadius: 2, mb: 2, border: 1, borderColor: '#E8EBF2' }}>
            <CardContent
              sx={{ p: 2 }}
            >
              <Box>
                <Grid
                  container
                  direction="row"
                  alignItems="left"
                  justifyContent="left"
                  spacing={0}

                >
                  <Grid item xs={8}>

                    <Box
                      sx={{
                        background: "rgba(139, 239, 179, 0.25);",
                        borderRadius: '300px',
                        p: '2px 12px',
                        width: '100px',
                        height: '24px'
                      }}
                    >
                      <Stack direction={'row'} alignItems={'center'}>
                        <img alt="icon" src={PermintaanIcon} height={18}></img>
                        <Typography className="w-500" sx={{ color: '#3BB56C', fontSize: 14, ml: '4px' }} >
                          {translate('navbar.permintaan')}
                        </Typography>

                      </Stack>
                    </Box>
                  </Grid>
                  <Grid sx={{ textAlign: "right", display: 'flex', alignItems: 'center', justifyContent: 'end' }} item xs={4}>
                    {isLogin && props.page !== 'detailcompany' &&
                      <Love type='card' condition={data?.user_favorite_id} remove={() => removeFavorite(5, data?.product_id)} favorite={() => setFavorite(5, data?.product_id)} />}

                    <MoreHorizIcon onClick={(e) => handleOpenShareMenu(e, data.product_id, data.phone_number, data.label)} sx={{ width: '24px', height: '24px', cursor: 'pointer' }} />
                  </Grid>
                </Grid>
                <Grid container direction={'column'} sx={{ mt: 1 }}>
                  <Typography
                    sx={{ fontSize: 10, mb: 1, color: '#818CA7', textTransform: 'uppercase', lineHeight: '14px' }}
                    className="w-500"
                  >
                    {translate('page_explore.listingoleh')}
                  </Typography>
                  <Stack

                    direction="row"
                    alignItems="center"
                    justifyContent="left"
                    spacing={0}
                  >
                    {data.user_url_image ?

                      <img style={{ borderRadius: '50%', marginRight: 6 }} alt="company_image-icon" onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${data.user_id}` }} src={config.baseURL + data.user_url_image} width={32} height={32}></img> :

                      <Avatar onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${data.user_id}` }} sx={{ bgcolor: '#1976d2', height: '32px', width: '32px', mr: '8px', cursor: 'pointer' }} aria-label="recipe">
                        <Person />
                      </Avatar>}


                    <Stack style={{ textAlign: "left", color: "white" }} spacing={0}>
                      <Typography
                        onClick={(e) => { window.location.href = `/detailtrader?type=5&id_detail=${data.user_id}` }}
                        sx={{ fontSize: 16, color: '#081130', cursor: 'pointer' }}
                        className="w-700"
                      >
                        {data.user_name}
                      </Typography>

                      {data.company_list.length > 0 ?
                        <Stack direction={'row'}>
                          {data.company_list.map((item, index) => {
                            if (index < 4) return (
                              <>
                                {index === 0 ? (
                                  item?.company_image ?
                                    <Avatar src={`${config.baseURL + item?.company_image}`} sx={{ bgcolor: "blue", width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                                      <CompanyDefault></CompanyDefault>
                                    </Avatar> : <Avatar sx={{ bgcolor: "blue", width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                                      <CompanyDefault></CompanyDefault>
                                    </Avatar>
                                ) : (
                                  item?.company_image ?
                                    <Avatar src={`${config.baseURL + item?.company_image}`} sx={{ bgcolor: "blue", ml: -1, width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                                      <CompanyDefault></CompanyDefault>
                                    </Avatar> : <Avatar sx={{ bgcolor: "blue", ml: -1, width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                                      <CompanyDefault></CompanyDefault>
                                    </Avatar>
                                )}
                              </>
                            )
                          })}
                          {data.company_list.length > 4 && <Avatar className="w-500" style={{ marginLeft: -6, width: '24px', height: '24px', fontSize: 11 }} aria-label="recipe">
                            +{data.company_list.length - 4}
                          </Avatar>}
                        </Stack> :

                        <Typography className="w-500" sx={{ fontSize: 14, ml: 1, color: '#818CA7', }}>
                          No company listed
                        </Typography>}
                    </Stack>

                  </Stack>
                </Grid>
                <Grid
                  marginTop={2}
                  container
                  direction="row"
                  alignItems="left"
                  justifyContent="left"
                  spacing={0}
                >
                  <Grid item xs={4}>
                    <Typography className="w-500" sx={{ color: '#56617B', fontSize: 12 }} >
                      Gar
                    </Typography>
                  </Grid>
                  <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography className="w-500" sx={{ color: '#56617B', fontSize: 12 }} >
                      {translate('page_explore.total-sulfur')}
                    </Typography>
                  </Grid>
                  <Grid sx={{ textAlign: "left" }} item xs={3}>
                    <Typography className="w-500" sx={{ color: '#56617B', fontSize: 12, }} >
                      Ash
                    </Typography>
                  </Grid>

                </Grid>
                <Grid
                  container
                  direction="row"
                  alignItems="left"
                  justifyContent="left"
                  spacing={0}
                >
                  <Grid
                    justifyContent="left"
                    alignItems="center"
                    item xs={4}>
                    <Stack direction="row" alignItems="center">
                      <img alt="Nikel-icon" src={Calorieicon} height={16}></img>
                      <Typography className="w-700" sx={{ color: '#081130', fontSize: 13 }}>
                        {(data.calorie).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid
                    justifyContent="left"
                    alignItems="center"
                    item xs={5}>
                    <Stack direction="row" alignItems="center">
                      <img alt="Nikel-icon" src={Sulfuricon} height={16}></img>
                      <Typography className="w-700" sx={{ color: '#081130', fontSize: 13 }}>
                        {(data.total_sulfur).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid
                    justifyContent="left"
                    alignItems="center"
                    item xs={3}>
                    <Stack direction="row" alignItems="center">
                      <img alt="Nikel-icon" src={Ashicon} height={16}></img>
                      <Typography className="w-700" sx={{ color: '#081130', fontSize: 13 }}>
                        {(data.ash).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid
                  marginTop={2}
                  container
                  direction="row"
                  alignItems="left"
                  justifyContent="left"
                  spacing={0}
                >
                  <Grid item xs={4}>
                    <Typography className="w-500" sx={{ color: '#56617B', fontSize: 12 }} >
                      Quantity
                    </Typography>
                  </Grid>
                  <Grid sx={{ textAlign: "left" }} item xs={5}>
                    <Typography className="w-500" sx={{ color: '#56617B', fontSize: 12, }} >
                      {translate('page_explore.harga')}
                    </Typography>
                  </Grid>
                  <Grid sx={{ textAlign: "left" }} item xs={3}>
                    <Typography className="w-500" sx={{ color: '#56617B', fontSize: 12 }} >

                    </Typography>
                  </Grid>

                </Grid>
                <Grid
                  container
                  direction="row"
                  spacing={0}
                >
                  <Grid item xs={4}>

                    <Typography
                      className="w-700"
                      sx={{
                        display: 'inline',
                        color: '#081130',
                        wordBreak: "break-all",
                        mr: '4px',
                        fontSize: 13
                      }}
                    >
                      {numberConvert(data.quantity)}
                    </Typography>

                    <Typography
                      className="w-500"
                      sx={{
                        display: 'inline',
                        color: '#56617B', fontSize: 12, pr: '4px'
                      }}
                    >
                      MT
                    </Typography>

                  </Grid>
                  <Grid item xs={5} width='100%'>
                    {currency === 'idr' && <Typography
                      className="w-700"
                      sx={{
                        display: 'inline',
                        color: '#081130',
                        mr: '4px',
                        fontSize: 13
                      }}
                    >
                      Rp. {numberConvert(data.price)}
                    </Typography>}
                    {currency === 'usd' && <Typography
                      className="w-700"
                      sx={{
                        display: 'inline',
                        color: '#081130',
                        mr: '4px',
                        fontSize: 13
                      }}
                    >
                      $ {numberConvert(data.price)}
                    </Typography>}

                    <Typography
                      className="w-500"
                      sx={{
                        display: 'inline',
                        color: '#56617B', fontSize: 12,
                      }}
                    >
                      /ton
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>

                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Divider orientation="horizontal" style={{ marginTop: 16, marginBottom: 16 }} />
                {data.label === 'penawaran' && <Grid className="pointer"
                  container
                  justifyContent="center"
                  alignItems="center"
                  direction="row" onClick={(e) => { navigate(`/penawaran/detail?type=1&id_detail=${data.product_id}`) }}
                >
                  <Typography
                    className="w-700"
                    sx={{ fontSize: 14, color: "#2457B9", mr: 1, }}
                  >
                    {translate('page_explore.lihatdetail')}
                  </Typography>
                  <ArrowForwardIosIcon sx={{ color: "#2457B9", height: '20px', width: '20px' }} />

                </Grid>}
                {data.label === 'permintaan' && <Grid className="pointer"
                  container
                  justifyContent="center"
                  alignItems="center"
                  direction="row" onClick={(e) => { navigate(`/permintaan/detail?type=1&id_detail=${data.product_id}`) }}
                >
                  <Typography
                    className="w-700"
                    sx={{ fontSize: 14, color: "#2457B9", mr: 1, }}
                  >
                    {translate('page_explore.lihatdetail')}
                  </Typography>
                  <ArrowForwardIosIcon sx={{ color: "#2457B9", height: '20px', width: '20px' }} />

                </Grid>}
              </Box>
            </CardContent>
          </Card>}
        <DrawerMore type={1} page={'explore'} label={label} id={product_id} user_trader_phone={user_trader_phone} open={anchorElShare} close={handleCloseShareMenu}>
        </DrawerMore>
      </Grid>

    </React.Fragment>
  )
}
