import { Grid, Hidden, Stack } from '@mui/material';
import { useEffect, useState } from "react";
import { useDropzone } from 'react-dropzone';
import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2';
import doc_icon from "../assets/doc.svg";
import DrawerUploadDoc from './DrawerUploadDoc';
import ModalUnggahDoc from './ModalUnggahDocBaru';


export default function DropZoneUnggah(props) {

    const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'application/pdf': ['.pdf'],
            // 'application/msword': ['.doc', '.docx', '.xlsx']
        },
        minSize: 0,
        // maxSize: 24000000
    });

    useEffect(() => {
        setFileDocument(props?.temporaryDoc ?? []);
    }, [])

    const [translate,] = useTranslation("global");
    const [fileDocument, setFileDocument] = useState([]);
    const [isOpenModal, setIsOpenModal] = useState(false);

    useEffect(() => {

        for (let i = 0; i < acceptedFiles.length; i++) {
            const element = acceptedFiles[i];
            if (acceptedFiles.length !== 0 && element.size < 24000000) {
            } else {
                Swal.fire({
                    icon: 'error',
                    title: translate('status.sorry'),
                    text: translate('status.filetobig')
                })
                return
            }
        }
        for (let i = 0; i < acceptedFiles.length; i++) {
            const element = acceptedFiles[i];
            setFileDocument(acceptedFiles);
            setIsOpenModal(true)
        }

    }, [acceptedFiles])

    return (
        <div>
            <div {...getRootProps()}>
                <Grid style={{ border: `${isDragActive ? "3px dashed #2457B9" : "3px dashed #E8EBF2"}` }} className='upload-doc pointer' container direction='column' justifyContent='center' alignItems='center' alignContent='center'>
                    <img src={doc_icon} alt='' />
                    <Stack direction='row'>
                        <p className='s-1419 w-500 mt-12 c-24 mb4 mr4 '>{translate('jual-beli.placeholder-dokumen')}</p>
                        <p className='s-1419 w-500 mt-12 c-08 mb4 '> {translate('jual-beli.dragndrop')}</p>
                    </Stack>
                    <p className='s-1216 w-500 c-56'>{translate('jual-beli.note-dokumen')}</p>
                </Grid>
                <input {...getInputProps()}
                // onChange={(e) => handleChangeFileDocument(e)}
                // type="file" style={{ display: "none" }} accept=".doc,.docx,.xlsx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
                />
            </div>
            <Hidden lgDown><ModalUnggahDoc file={fileDocument} open={isOpenModal} close={props.handleUpload} closeModal={() => setIsOpenModal(false)}></ModalUnggahDoc>
            </Hidden>

            <Hidden lgUp><DrawerUploadDoc file={fileDocument} open={isOpenModal} close={props.handleUpload} closeModal={() => { setIsOpenModal(false) }}></DrawerUploadDoc>
            </Hidden>
        </div >

    );
}