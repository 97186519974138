import { InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function SearchBarExplore(props) {
  const [translate,] = useTranslation("global");

  const handleChange = (event) => {
    setSearchValue(event.target.value)
    if (event.target.value === "") {
      props.handleSearchValue(event.target.value)
    }
    // props.handleSearchValue(event.target.value)
  }

  const [searchValue, setSearchValue] = useState(props.searchValue);

  return (

    <TextField
      className="search-filter-box"
      type="search"
      size="small"
      placeholder={props.text}
      onChange={(e) => handleChange(e)}
      value={searchValue}
      onKeyDown={(e) => props.handleKeyDown(e, searchValue)}
      sx={{
        width: { xs: 'calc(100% - 16px)', sm: 'calc(100% - 16px)', md: 'calc(100%-16px)', lg: '100%', xl: '100%' }, input: {
          fontSize: '14px', fontFamily: 'Satoshi-500'
        },
      }}
      InputProps={{
        style: {
          paddingRight: '10px',
        },
        startAdornment: (
          <InputAdornment position="start">
            <img alt="search-icon" src={props.searchIcon} height={20} width={20}></img>
          </InputAdornment>
        ),
      }}
    />
  )
}
