import CloseIcon from '@mui/icons-material/Close';
import { Box, Card, Divider, Grid, IconButton, Modal, Stack, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";
import { DisplayPDFFile } from "../components/DisplayPDFDetail";
import { config } from "../services/constants";
export default function ModalAktePerusahaan(props) {
    const [translate,] = useTranslation("global");
    return (
        <div>
            <Modal
                open={props.open}
                onClose={props.close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ zIndex: 100000 }}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '304px', sm: 343, md: 343, lg: 500, xl: 500 },
                    borderRadius: '8px',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 0,
                    "&:focus": {
                        outline: "none"
                    },
                }}>
                    <Box sx={{ height: { xs: '400px', sm: '400px', md: '100%', lg: '100%', xl: '100%' }, overflowY: { xs: 'scroll', sm: 'scroll', md: 'hidden', lg: 'hidden', xl: 'hidden' } }}>
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ p: '20px' }}>
                            <Typography id="modal-modal-title" className='w-700' sx={{ fontSize: '18px', color: '##222525', textAlign: 'center', }}>
                                {translate('status.akta')}
                            </Typography>
                            <IconButton size="small" >
                                <CloseIcon onClick={() => {
                                    props.close();
                                }} />
                            </IconButton>
                        </Stack>

                        <Divider orientation="horizontal" sx={{ backgroundColor: '#E8EBF2' }} />

                        <Stack direction={'column'} sx={{ ml: '20px', mb: '24px', mt: '20px' }}>
                            <Typography id="modal-modal-title" className='w-400' sx={{ fontSize: '12px', color: '#56617B' }}>
                                {translate('status.noakta')}
                            </Typography>
                            <Typography id="modal-modal-title" className='w-500' sx={{ fontSize: '14px', color: '#081130' }}>
                                {props.detailCompany.akta_nomor}
                            </Typography>
                        </Stack>

                        <Stack direction={'column'} sx={{ ml: '20px', mb: '24px' }}>
                            <Typography id="modal-modal-title" className='w-400' sx={{ fontSize: '12px', color: '#56617B' }}>
                                {translate('status.tglakta')}
                            </Typography>
                            {props.detailCompany.akta_date && <Typography id="modal-modal-title" className='w-500' sx={{ fontSize: '14px', color: '#081130' }}>

                                {props.detailCompany.akta_date !== null ? new Intl.DateTimeFormat('id', { year: 'numeric', month: 'numeric', day: '2-digit' }).format(new Date(props.detailCompany.akta_date)) : '-'}
                            </Typography>}
                        </Stack>

                        <Stack direction={'column'} sx={{ ml: '20px', mb: '24px' }}>
                            <Typography id="modal-modal-title" className='w-400' sx={{ fontSize: '12px', color: '#56617B' }}>
                                {translate('status.keterangan')}
                            </Typography>
                            <Typography id="modal-modal-title" className='w-500' sx={{ fontSize: '14px', color: '#081130' }}>
                                {props.detailCompany.akta_description}
                            </Typography>
                        </Stack>

                        <Stack direction={'column'} sx={{ ml: '20px', mb: '24px' }}>
                            <Typography id="modal-modal-title" className='w-400' sx={{ fontSize: '12px', color: '#56617B' }}>
                                {translate('status.documentakta')}
                            </Typography>

                            {props.detailCompany.akta_path_documents && <Grid container direction={'row'} spacing={2} sx={{ mt: 0 }}>
                                {props.detailCompany.akta_path_documents.map((item, i) => (
                                    <Grid item xs={6} sm={6} md={6} lg={6}>
                                        <Card sx={{ borderRadius: 2 }}>
                                            <DisplayPDFFile
                                                url={`${config.baseURL}${item.url}`}
                                                key={i}
                                                ext={`${item.ext}`}
                                                name={`${item.name}`}
                                            />
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>}
                        </Stack>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}